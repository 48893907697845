// Generated by CoffeeScript 1.12.7
(function () {
  var PermissionsService,
    isAtLeast,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  isAtLeast = require("@CommonFrontendBackend/Permissions/Util").isAtLeast;

  module.exports = PermissionsService = (function () {
    function PermissionsService($http, apiBaseUrl) {
      /*
       * @deprecated Import and use `isAtLeast()` directly instead. There is no need to include `permissionsService`
       * from the di just to use this function!
       *
       * compares given permission with a base permission
       * and returns true if given permission is equal to or higher from base permission
       */
      this.isAllowed = function (permission, basePermission) {
        return isAtLeast(permission, basePermission);
      };
      this.getMinimumPermission = function (permissions) {
        if (indexOf.call(permissions, "none") >= 0) {
          return "none";
        } else if (indexOf.call(permissions, "view") >= 0) {
          return "view";
        } else if (indexOf.call(permissions, "edit") >= 0) {
          return "edit";
        } else if (indexOf.call(permissions, "all") >= 0) {
          return "all";
        } else {
          return "none";
        }
      };
      this.setLockEditingSettingForSavedBlock = function (savedBlockId, isAdminOnly) {
        var action, payload;
        action = isAdminOnly ? "edit" : "none";
        payload = {
          action: action,
          role: "admin",
          blockId: savedBlockId,
        };
        return $http.post(apiBaseUrl + "/permissions/saved-blocks", [payload]);
      };
      this.isBlockEditableOnlyByAdmins = function (block) {
        var adminOnlyPermission;
        adminOnlyPermission = _.find(block.$permissions, function (permission) {
          return permission.owner === "role/admin" && permission.action === "edit";
        });
        return adminOnlyPermission != null;
      };
    }

    return PermissionsService;
  })();
}.call(this));
