// Generated by CoffeeScript 1.12.7
(function () {
  var TimeInAppTracker;

  module.exports = TimeInAppTracker = (function () {
    function TimeInAppTracker($interval, trackEvent, $timeout) {
      var INTERVAL, MINUTE, counter, fireEvent, intervalsToTrack, minutesCounter;
      MINUTE = 60000;
      INTERVAL = 1 * MINUTE;
      intervalsToTrack = [2];
      minutesCounter = 0;
      fireEvent = function () {
        minutesCounter++;
        if (_.includes(intervalsToTrack, minutesCounter)) {
          trackEvent("Been in app for " + minutesCounter + " minutes");
        }
        if (minutesCounter === 2) {
          return $timeout(function () {
            return Intercom("update");
          }, 350);
        }
      };
      counter = $interval(fireEvent, INTERVAL);
    }

    return TimeInAppTracker;
  })();
}.call(this));
