"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contracts = exports.landingPages = exports.collateral = exports.proposals = exports.blankAlgorithm = void 0;
exports.blankAlgorithm = {
    name: "New algorithm",
    levelsConfiguration: {
        highly_engaged: null,
        engaged: null,
        disengaged: null,
        highly_disengaged: null,
    },
    description: "",
};
exports.proposals = {
    name: "Proposals",
    description: "An algorithm to determine engagement on a small number of views, but focused on time spent.",
    levelsConfiguration: {
        highly_engaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewCount",
                        threshold: 2,
                        operator: ">=",
                        timeUnits: "days",
                        timeValue: 2,
                    },
                },
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewDuration",
                        threshold: 10,
                        operator: ">=",
                        timeUnits: "days",
                        timeValue: 2,
                    },
                },
            ],
        },
        engaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewCount",
                        threshold: 2,
                        operator: ">=",
                        timeUnits: "days",
                        timeValue: 4,
                    },
                },
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewDuration",
                        threshold: 10,
                        operator: ">=",
                        timeUnits: "days",
                        timeValue: 4,
                    },
                },
            ],
        },
        disengaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewCount",
                        threshold: 2,
                        operator: "<=",
                        timeUnits: "days",
                        timeValue: 6,
                    },
                },
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewDuration",
                        threshold: 10,
                        operator: "<=",
                        timeUnits: "days",
                        timeValue: 6,
                    },
                },
            ],
        },
        highly_disengaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewCount",
                        threshold: 1,
                        operator: "<=",
                        timeUnits: "days",
                        timeValue: 6,
                    },
                },
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewDuration",
                        threshold: 5,
                        operator: "<=",
                        timeUnits: "days",
                        timeValue: 6,
                    },
                },
            ],
        },
    },
};
exports.collateral = {
    name: "Collateral",
    description: "Measures engagement based on a moderate number of views and time spent.",
    levelsConfiguration: {
        highly_engaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewCount",
                        threshold: 6,
                        operator: ">=",
                        timeUnits: "days",
                        timeValue: 2,
                    },
                },
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewDuration",
                        threshold: 20,
                        operator: ">=",
                        timeUnits: "days",
                        timeValue: 2,
                    },
                },
            ],
        },
        engaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewCount",
                        threshold: 4,
                        operator: ">=",
                        timeUnits: "days",
                        timeValue: 3,
                    },
                },
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewDuration",
                        threshold: 15,
                        operator: ">=",
                        timeUnits: "days",
                        timeValue: 3,
                    },
                },
            ],
        },
        disengaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewCount",
                        threshold: 4,
                        operator: "<=",
                        timeUnits: "days",
                        timeValue: 4,
                    },
                },
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewDuration",
                        threshold: 10,
                        operator: "<=",
                        timeUnits: "days",
                        timeValue: 4,
                    },
                },
            ],
        },
        highly_disengaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewCount",
                        threshold: 1,
                        operator: "<=",
                        timeUnits: "days",
                        timeValue: 4,
                    },
                },
                {
                    kind: "EXPRESSION",
                    values: {
                        metric: "PageViewDuration",
                        threshold: 5,
                        operator: "<=",
                        timeUnits: "days",
                        timeValue: 4,
                    },
                },
            ],
        },
    },
};
exports.landingPages = {
    name: "Landing pages",
    levelsConfiguration: {
        highly_engaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: { metric: "PageViewCount", threshold: 20, operator: ">=", timeUnits: "days", timeValue: 2 },
                },
            ],
        },
        engaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: { metric: "PageViewCount", threshold: 15, operator: ">=", timeUnits: "days", timeValue: 4 },
                },
            ],
        },
        disengaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: { metric: "PageViewCount", threshold: 8, operator: "<=", timeUnits: "days", timeValue: 4 },
                },
            ],
        },
        highly_disengaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: { metric: "PageViewCount", threshold: 5, operator: "<=", timeUnits: "days", timeValue: 5 },
                },
            ],
        },
    },
    description: "Measures engagement purely on the number of hits on the page.",
};
exports.contracts = {
    name: "Contracts",
    description: "Pages are considered engaged if they’ve been viewed at least once, and are more engaged the longer they’re viewed for.",
    levelsConfiguration: {
        highly_engaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: { metric: "PageViewCount", threshold: 1, operator: ">=", timeUnits: "days", timeValue: 7 },
                },
                {
                    kind: "EXPRESSION",
                    values: { metric: "PageViewDuration", threshold: 15, operator: ">=", timeUnits: "days", timeValue: 7 },
                },
            ],
        },
        engaged: {
            kind: "AND",
            values: [
                {
                    kind: "EXPRESSION",
                    values: { metric: "PageViewCount", threshold: 1, operator: ">=", timeUnits: "days", timeValue: 7 },
                },
            ],
        },
        disengaged: null,
        highly_disengaged: null,
    },
};
