import "Application/Analytics/Page/TimeSpentAndInteractions/TimeSpentAndInteractions.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Analytics/Page/TimeSpentAndInteractions/TimeSpentAndInteractions.css.ts.vanilla.css\",\"source\":\"LmoxbDVvdTAgewogIG92ZXJmbG93LXg6IGF1dG87Cn0KLmoxbDVvdTEgewogIG1pbi13aWR0aDogNjUwcHg7CiAgbWluLWhlaWdodDogNDAwcHg7Cn0KLmoxbDVvdTIgewogIG1hcmdpbjogYXV0byAwOwogIGZsZXg6IDEgMSBhdXRvOwp9Ci5qMWw1b3UzIHsKICBmbGV4OiAxIDEgNTUlOwp9Ci5qMWw1b3U0IHsKICBmbGV4OiAwIDEgNDAlOwogIGFzcGVjdC1yYXRpbzogMSAvIDE7CiAgbWFyZ2luOiBhdXRvIDA7Cn0KLmoxbDVvdTUgewogIHRhYmxlLWxheW91dDogZml4ZWQ7CiAgYm9yZGVyLWNvbGxhcHNlOiBjb2xsYXBzZTsKICB3aWR0aDogMTAwJTsKICB0ZXh0LWFsaWduOiBsZWZ0Owp9Ci5qMWw1b3U2IHsKICB0ZXh0LXRyYW5zZm9ybTogbm9uZTsKICBsZXR0ZXItc3BhY2luZzogbm9ybWFsOwogIHBhZGRpbmctbGVmdDogdmFyKC0tc3BhY2luZy14eHNfXzFibDdqajExaik7CiAgcGFkZGluZy1yaWdodDogdmFyKC0tc3BhY2luZy14eHNfXzFibDdqajExaik7CiAgbWFyZ2luLWJvdHRvbTogdmFyKC0tc3BhY2luZy14eHhzX18xYmw3amoxMWkpOwp9Ci5qMWw1b3U3IHsKICBib3JkZXItdG9wOiAxcHggc29saWQgdmFyKC0tY29sb3ItYm9yZGVyX18xYmw3amoxMTApOwogIGhlaWdodDogNTdweDsKICB0cmFuc2l0aW9uOiBiYWNrZ3JvdW5kLWNvbG9yIHZhcigtLWR1cmF0aW9uLXNfXzFibDdqajExdSkgZWFzZTsKfQouajFsNW91OCB7CiAgYmFja2dyb3VuZDogdmFyKC0tY29sb3Itc3VyZmFjZUhvdmVyX18xYmw3amoxbik7Cn0KLmoxbDVvdTkgewogIHZlcnRpY2FsLWFsaWduOiBtaWRkbGU7CiAgcGFkZGluZy1sZWZ0OiB2YXIoLS1zcGFjaW5nLXh4c19fMWJsN2pqMTFqKTsKfQouajFsNW91YSB7CiAgZmxleDogMCAwIGF1dG87Cn0KLmoxbDVvdWIgewogIHdpZHRoOiA1MCU7Cn0KLmoxbDVvdWMgewogIHdpZHRoOiA1MCU7Cn0KLmoxbDVvdWQgewogIG1pbi1oZWlnaHQ6IDQ1MHB4Owp9\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var timeSpentAndInteractionsTabPanels = 'j1l5ou0';
export var tabPanelContainer = 'j1l5ou1';
export var emptyState = 'j1l5ou2';
export var tableContainer = 'j1l5ou3';
export var chartContainer = 'j1l5ou4';
export var table = 'j1l5ou5';
export var tableHeaderButton = 'j1l5ou6';
export var tableRow = _7a468({defaultClassName:'j1l5ou7',variantClassNames:{active:{true:'j1l5ou8'}},defaultVariants:{},compoundVariants:[]});
export var tableCell = 'j1l5ou9';
export var firstColumnIcon = 'j1l5oua';
export var blockNameHeading = 'j1l5oub';
export var interactionNameHeading = 'j1l5ouc';
export var timeSpentAndInteractionsGhostBlock = 'j1l5oud';