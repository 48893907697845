// Generated by CoffeeScript 1.12.7

/*
  @TODO(ben, 2017-02-06) As good will for our users we ought add messaging when changing
  from an existing domain that the links from the previous domain
  will no longer work.
 */

(function () {
  var DomainSetter, FeatureFlag, validator;

  validator = require("validator");

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  /*
    A widget which allows a user set a custom domain for an account.
    Dynamically checks for validity of the custom domain,
    and whether the domain is taken or blacklisted.
  
    @param options [Object] includes:
    - `onSaved` [Function] invoked upon successful update of the custom domain.
    - `includeIcon` [Boolean] whether to include an SVG icon in the header.
    - `title` [String] the title text for the widget.
    - `description` [String] the description for the widget.
   */

  module.exports = DomainSetter = (function () {
    function DomainSetter(
      options,
      account,
      createUi,
      accountService,
      usingFeature,
      make,
      invoke,
      ActionButton,
      overlayStack,
      noop,
      $q,
      $log,
    ) {
      var domain, domainUnchanged, saveButton, statusChecker;
      if (options.includeIcon == null) {
        options.includeIcon = true;
      }
      if (options.title == null) {
        options.title = "Custom Domain";
      }
      if (options.description == null) {
        options.description = "";
      }
      if (options.onSaved == null) {
        options.onSaved = noop;
      }
      domainUnchanged = function (domain) {
        return accountService.getCustomDomain() === domain.name;
      };
      domain = {
        name: accountService.getCustomDomain() || "",
        type: "domain",
      };
      statusChecker = make(require("./../StatusChecker/StatusChecker"), {
        domain: domain,
        validityChecker: validator.isFQDN,
      });
      saveButton = make(ActionButton, {
        config: {
          standard: "Save Custom Domain",
          waiting: "Saving Custom Domain...",
          success: "Saved Custom Domain",
          error: "Error setting domain",
        },
        onClick: (function (_this) {
          return function () {
            if (statusChecker.getStatus() === "checking") {
              return $q.resolve();
            } else if (!domainUnchanged(domain) && domain.name !== "" && statusChecker.getStatus() !== "available") {
              _this.ui.error = "Please double check your domain and try again.";
              return $q.reject();
            } else {
              return accountService
                .setDomain(domain.name)
                .then(function () {
                  return options.onSaved(domain.name);
                })
                ["catch"](function (error) {
                  _this.ui.error = error;
                  return $log.error("Error updating custom domain", error);
                });
            }
          };
        })(this),
      });
      this.ui = createUi(require("./CustomDomainSetter.html"), {
        domain: domain,
        checkStatus: "",
        error: "",
        saveButton: saveButton.ui,
        options: options,
        statusChecker: statusChecker.ui,
        showUpdatedDnsCheckbox: function () {
          return usingFeature(FeatureFlag.LegacyCustomDomain);
        },
        settings: account.settings,
        updateStatus: (function (_this) {
          return function () {
            _this.ui.error = "";
            saveButton.setState("standard");
            return statusChecker.updateStatus();
          };
        })(this),
      });
      this.showInOverlay = (function (_this) {
        return function () {
          return overlayStack.add(_this.ui);
        };
      })(this);
    }

    return DomainSetter;
  })();
}.call(this));
