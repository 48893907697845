// Generated by CoffeeScript 1.12.7

/*
  Handles events for LI tag
 */

(function () {
  var ListHandler, h;

  h = require("../Helpers.js");

  module.exports = ListHandler = (function () {
    function ListHandler(editor, undo) {
      var convertToParagraph;
      this.onKey = function (e) {
        var joinPoint, list, nextList, point, prevList;
        point = editor.selection().getRange().anchor;
        if (e.keyCode === qed.keycodes.codes.ENTER) {
          e.preventDefault();
          if (point.node.textContent.trim() === "") {
            return convertToParagraph(point);
          } else {
            while (true) {
              point.splitRight(true);
              if (point.node.tagName != null && point.node.tagName === "LI") {
                break;
              }
            }
            h.ensureOpen(point.node);
            if (point.node.previousSibling != null) {
              h.ensureOpen(point.node.previousSibling);
            }
            point = qed.Point.start(point.node).rightNormalized();
            editor.selection().setCaret(point);
            return true;
          }
        } else if (e.keyType === "backspace" && !h.textToLeft(point)) {
          e.preventDefault();
          return convertToParagraph(point);
        } else if (e.keyType === "delete" && !h.textToRight(point)) {
          e.preventDefault();
          joinPoint = qed.Point.after(h.upToBlockElem(point));
          if (joinPoint.node.nextElementSibling == null) {
            if (joinPoint.node.parentElement.nextElementSibling != null) {
              if (h.isList(joinPoint.node.parentElement.nextElementSibling)) {
                list = joinPoint.node.parentElement.nextElementSibling;
                list.parentElement.insertBefore(list.firstChild, list);
                if (list.children.length === 0) {
                  list.parentElement.removeChild(list);
                }
                joinPoint.node.parentElement.appendChild(joinPoint.node.parentElement.nextElementSibling);
              } else if (
                h.isList(joinPoint.node.parentElement) &&
                h.isList(joinPoint.node.parentElement.nextElementSibling.nextElementSibling)
              ) {
                prevList = joinPoint.node.parentElement;
                nextList = joinPoint.node.parentElement.nextElementSibling.nextElementSibling;
                prevList.appendChild(prevList.nextElementSibling);
                if (nextList.tagName === prevList.tagName) {
                  qed.Point.before(nextList).joinLeft();
                }
              } else {
                joinPoint.node.parentElement.appendChild(joinPoint.node.parentElement.nextElementSibling);
              }
            } else {
              return true;
            }
          }
          joinPoint.joinLeft();
          return true;
        } else {
          return false;
        }
      };
      convertToParagraph = function (point) {
        var after, before, dest, node, parent;
        node = h.thisNode("LI", point);
        before = qed.Point.before(node);
        while (before.node.parentElement !== editor.currentElem()) {
          before.splitLeft(true);
        }
        after = qed.Point.after(node);
        while (after.node.parentElement !== editor.currentElem()) {
          after.splitRight(true);
        }
        if (before.node.childNodes.length === 0) {
          before.node.parentElement.removeChild(before.node);
        }
        dest = document.createElement("P");
        after.node.parentElement.insertBefore(dest, after.node);
        if (after.node.childNodes.length === 0) {
          after.node.parentElement.removeChild(after.node);
        }
        while (node.childNodes.length > 0) {
          dest.appendChild(node.firstChild);
        }
        parent = node.parentElement;
        parent.removeChild(node);
        if (parent.childNodes.length === 0) {
          parent.parentElement.removeChild(parent);
        }
        h.ensureOpen(dest);
        editor.selection().setCaret(qed.Point.start(dest));
        return true;
      };
    }

    return ListHandler;
  })();
}.call(this));
