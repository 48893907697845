"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTimePeriod = void 0;
var luxon_1 = require("luxon");
var Types_1 = require("Reports/Types");
function calculateTimePeriod(selectedTimePeriod, diff) {
    var _a, _b;
    if (diff === void 0) { diff = 0; }
    var timePeriod = selectedTimePeriod.timePeriod, timezone = selectedTimePeriod.timezone, customPeriod = selectedTimePeriod.customPeriod;
    if (timePeriod === Types_1.TimePeriod.CUSTOM && customPeriod && customPeriod.start && customPeriod.end) {
        var periodStart = luxon_1.DateTime.fromJSDate(customPeriod.start);
        var periodEnd = luxon_1.DateTime.fromJSDate(customPeriod.end);
        var diffObject = periodEnd.diff(periodStart, "hours").toObject();
        var diffInHours = (diffObject === null || diffObject === void 0 ? void 0 : diffObject.hours) ? diffObject.hours * diff : 0;
        return {
            periodStart: periodStart.plus({ hours: diffInHours }).toISO(),
            periodEnd: periodEnd.plus({ hours: diffInHours }).toISO(),
        };
    }
    var dateTimeNow = luxon_1.DateTime.local({ zone: timezone });
    return {
        periodStart: dateTimeNow
            .plus((_a = {}, _a["".concat(timePeriod, "s")] = diff, _a))
            .startOf(timePeriod)
            .toISO(),
        periodEnd: dateTimeNow
            .plus((_b = {}, _b["".concat(timePeriod, "s")] = diff, _b))
            .endOf(timePeriod)
            .toISO(),
    };
}
exports.calculateTimePeriod = calculateTimePeriod;
