"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformLink = void 0;
var lodash_1 = require("lodash");
var transformLink = function (link) {
    var newLink = (0, lodash_1.cloneDeep)(link);
    delete newLink.receipt;
    return newLink;
};
exports.transformLink = transformLink;
