"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidActionConfigValue = exports.buildActionConfig = exports.getMultiselectSelectedValues = exports.getManuallyAddedItemId = void 0;
var zod_1 = require("zod");
var actionConfigValueSchema = zod_1.z.array(zod_1.z
    .object({
    id: zod_1.z.string().min(1),
    name: zod_1.z.string().min(1),
})
    .or(zod_1.z.object({
    name: zod_1.z.string().min(1),
    isManuallyAdded: zod_1.z.literal(true),
})));
var isValidActionConfigValue = function (value) {
    return actionConfigValueSchema.safeParse(value).success;
};
exports.isValidActionConfigValue = isValidActionConfigValue;
var buildActionConfig = function (config, field, selectedIds, options) {
    var _a;
    var selectedOptions = selectedIds.map(function (id) {
        var selectedOption = options.get(id);
        if (!selectedOption) {
            var existingOptions = config[field.name];
            if (isValidActionConfigValue(existingOptions)) {
                var existingOption = existingOptions.find(function (o) { return "id" in o && o.id === id; });
                return { id: id, name: (existingOption === null || existingOption === void 0 ? void 0 : existingOption.name) || "" };
            }
            else {
                return { id: id, name: "" };
            }
        }
        if (selectedOption.isManuallyAdded) {
            return { name: selectedOption.label, isManuallyAdded: true };
        }
        return { id: id, name: selectedOption.label };
    });
    return _a = {},
        _a[field.name] = selectedOptions,
        _a;
};
exports.buildActionConfig = buildActionConfig;
var getMultiselectSelectedValues = function (config, field) {
    if (!(field.name in config)) {
        return [];
    }
    var configValues = config[field.name];
    if (!isValidActionConfigValue(configValues)) {
        return [];
    }
    return configValues;
};
exports.getMultiselectSelectedValues = getMultiselectSelectedValues;
var getManuallyAddedItemId = function (name) { return "__multiselectManuallyAddedItem::".concat(name); };
exports.getManuallyAddedItemId = getManuallyAddedItemId;
