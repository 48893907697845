"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var isomorphic_base64_1 = require("isomorphic-base64");
function encode(object) {
    var string = JSON.stringify(object);
    var encoded = (0, isomorphic_base64_1.btoa)(encodeURIComponent(string));
    return encoded;
}
function decode(string) {
    var decoded = decodeURIComponent((0, isomorphic_base64_1.atob)(string));
    var object = JSON.parse(decoded);
    return object;
}
function deserializeNode(string) {
    return decode(string);
}
function serializeNode(node) {
    return encode(node);
}
exports.default = {
    deserializeNode: deserializeNode,
    serializeNode: serializeNode,
};
