"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPageCount = void 0;
function getPageCount(velocityHistory) {
    var pageCount = 0;
    var history = velocityHistory.metrics.pageStatusMetrics;
    for (var status in history) {
        if (["accepted", "declined"].includes(status)) {
            pageCount = pageCount + history[status].periodCount;
        }
        else {
            pageCount = pageCount + history[status].totalCount;
        }
    }
    return pageCount;
}
exports.getPageCount = getPageCount;
