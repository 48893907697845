// Generated by CoffeeScript 1.12.7
(function () {
  var LESSCompiler;

  module.exports = LESSCompiler = (function () {
    function LESSCompiler($q, $log) {
      var less;
      less = require("less");
      this.render = (function (_this) {
        return function (raw) {
          var error, rendering;
          rendering = $q.defer();
          try {
            less.render(raw).then(
              function (result) {
                return rendering.resolve(result.css);
              },
              function (error) {
                return rendering.reject(error);
              },
            );
          } catch (error1) {
            error = error1;
            $log.info(error);
          }
          return rendering.promise;
        };
      })(this);
    }

    return LESSCompiler;
  })();
}.call(this));
