"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TokenViewStore = void 0;
var mobx_1 = require("mobx");
var lodash_1 = require("lodash");
var Types_1 = require("@CommonFrontendBackend/Tokens/Types");
var Types_2 = require("Tokens/Types");
var TokenApiErrors_1 = require("../Common/TokenApiErrors");
var PopularV3Tokens_1 = require("Project/Actions/Tokens/Common/PopularV3Tokens");
var TokenViewStore = (function () {
    function TokenViewStore(tokenStore, projectStore, modal, notifyUser) {
        var _this = this;
        this.tokenStore = tokenStore;
        this.projectStore = projectStore;
        this.modal = modal;
        this.notifyUser = notifyUser;
        this.debouncedFetchSearchResults = (0, lodash_1.debounce)(this.fetchSearchResults, 500);
        this.addListTokenProperty = function (listToken, name) { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                return [2, this.addToken({
                        name: name,
                        objectType: listToken.childObjectType,
                        dataType: Types_1.TokenDataType.String,
                    })];
            });
        }); };
        this.updateListTokenChildPropertyLabel = function (listToken, childPropertyLabel) { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.tokenStore.updateToken(listToken, { childPropertyLabel: childPropertyLabel })];
                    case 1:
                        _a.sent();
                        listToken.childPropertyLabel = childPropertyLabel;
                        return [2];
                }
            });
        }); };
        this.selectedTab = projectStore.isCrmTemplate ? Types_1.TokenType.Crm : Types_1.TokenType.Custom;
        this.searchTerm = "";
        this.selectedListToken = null;
        this.isRefreshing = false;
        this.refreshTokensWhenNeeded();
    }
    TokenViewStore.prototype.refreshTokensWhenNeeded = function () {
        var _this = this;
        this.dispose = (0, mobx_1.reaction)(function () {
            return {
                selectedTab: _this.selectedTab,
                searchTerm: _this.searchTerm,
                hasSelectedListToken: !!_this.selectedListToken,
                crmInfo: __assign({}, _this.projectStore.crmInfo),
            };
        }, function () {
            _this.setIsRefreshing(true);
            _this.debouncedFetchSearchResults();
        });
    };
    TokenViewStore.prototype.cleanup = function () {
        if (this.dispose) {
            this.dispose();
        }
    };
    Object.defineProperty(TokenViewStore.prototype, "connectedIntegrationProviderName", {
        get: function () {
            var _a;
            return ((_a = this.projectStore.crmInfo) === null || _a === void 0 ? void 0 : _a.connected) ? (0, lodash_1.capitalize)(this.projectStore.crmInfo.integration) : "CRM";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TokenViewStore.prototype, "connectedIntegrationFullName", {
        get: function () {
            var _a;
            return ((_a = this.projectStore.crmInfo) === null || _a === void 0 ? void 0 : _a.connected)
                ? "".concat((0, lodash_1.capitalize)(this.projectStore.crmInfo.integration), " ").concat((0, lodash_1.capitalize)(this.projectStore.crmInfo.object))
                : "";
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TokenViewStore.prototype, "showListTokenProperties", {
        get: function () {
            return this.selectedListToken !== null;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TokenViewStore.prototype, "tokens", {
        get: function () {
            var _this = this;
            if (!!this.selectedListToken) {
                return this.tokenStore.tokens.map(function (token) {
                    if (!!_this.selectedListToken && token.objectType === _this.selectedListToken.childObjectType) {
                        return __assign(__assign({}, token), { apiName: "".concat(_this.selectedListToken.apiName, ".").concat(token.apiName), name: "".concat(_this.selectedListToken.childPropertyLabel || _this.selectedListToken.name, ".").concat(token.name) });
                    }
                    return token;
                });
            }
            return this.tokenStore.tokens;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TokenViewStore.prototype, "popularCrmTokens", {
        get: function () {
            if (this.projectStore.isCrmTemplate && this.projectStore.crmInfo) {
                return (0, PopularV3Tokens_1.getPopularTokens)(this.projectStore.crmInfo.integration, this.projectStore.crmInfo.object);
            }
            else {
                return [];
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TokenViewStore.prototype, "showPopularCrmTokens", {
        get: function () {
            return !this.isLoading && !this.searchTerm && this.popularCrmTokens.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TokenViewStore.prototype, "isLoading", {
        get: function () {
            return this.tokenStore.isLoading || this.isRefreshing;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TokenViewStore.prototype, "activeTabIndex", {
        get: function () {
            return this.selectedTab === Types_1.TokenType.Custom ? 0 : 1;
        },
        enumerable: false,
        configurable: true
    });
    TokenViewStore.prototype.onTabChange = function (type) {
        this.selectedTab = type;
    };
    TokenViewStore.prototype.onSearchChange = function (searchTerm) {
        this.searchTerm = searchTerm;
    };
    TokenViewStore.prototype.onSearchClear = function () {
        this.searchTerm = "";
    };
    TokenViewStore.prototype.toggleSelectedListToken = function (token) {
        this.selectedListToken = token;
    };
    TokenViewStore.prototype.setIsRefreshing = function (isRefreshing) {
        this.isRefreshing = isRefreshing;
    };
    TokenViewStore.prototype.setErrorMessage = function (msg) {
        this.errorMessage = msg;
    };
    TokenViewStore.prototype.clearErrorMessage = function () {
        this.errorMessage = undefined;
    };
    TokenViewStore.prototype.syncTokens = function () {
        return __awaiter(this, void 0, Promise, function () {
            var integrationMetadata, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        integrationMetadata = Types_1.INTEGRATION_METADATA.get(this.projectStore.crmInfo.integration);
                        this.setIsRefreshing(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.tokenStore.syncTokens({
                                providerType: this.projectStore.crmInfo.integration,
                                objectType: this.projectStore.crmInfo.object,
                            })];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.notifyUser.error("Unable to sync ".concat(integrationMetadata === null || integrationMetadata === void 0 ? void 0 : integrationMetadata.displayName, " tokens. Please try again."));
                        this.setIsRefreshing(false);
                        return [2];
                    case 4: return [4, this.debouncedFetchSearchResults()];
                    case 5:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    TokenViewStore.prototype.fetchSearchResults = function () {
        return __awaiter(this, void 0, Promise, function () {
            var _a, selectedTab, searchTerm, selectedListToken, err_2;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = this, selectedTab = _a.selectedTab, searchTerm = _a.searchTerm, selectedListToken = _a.selectedListToken;
                        this.clearErrorMessage();
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 8, , 9]);
                        if (!!!selectedListToken) return [3, 3];
                        return [4, this.tokenStore.getTokens({
                                providerType: selectedListToken.providerType,
                                objectType: selectedListToken.childObjectType,
                            })];
                    case 2:
                        _b.sent();
                        return [3, 7];
                    case 3:
                        if (!(selectedTab === Types_1.TokenType.Custom)) return [3, 5];
                        return [4, this.tokenStore.getTokens({
                                providerType: Types_1.ProviderType.Custom,
                                query: searchTerm,
                            })];
                    case 4:
                        _b.sent();
                        return [3, 7];
                    case 5:
                        if (!(this.projectStore.isCrmTemplate && !!this.projectStore.crmInfo && selectedTab === Types_1.TokenType.Crm)) return [3, 7];
                        return [4, this.tokenStore.getTokens({
                                providerType: this.projectStore.crmInfo.integration,
                                objectType: this.projectStore.crmInfo.object,
                                query: searchTerm,
                            })];
                    case 6:
                        _b.sent();
                        _b.label = 7;
                    case 7: return [3, 9];
                    case 8:
                        err_2 = _b.sent();
                        this.setErrorMessage(err_2.message);
                        return [3, 9];
                    case 9:
                        this.setIsRefreshing(false);
                        return [2];
                }
            });
        });
    };
    TokenViewStore.prototype.addToken = function (toCreate) {
        return __awaiter(this, void 0, Promise, function () {
            var result, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        result = {
                            isError: false,
                        };
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.tokenStore.createToken(toCreate)];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        error_1 = _a.sent();
                        result = {
                            isError: true,
                            errorMessage: (0, TokenApiErrors_1.getCreateTokenErrorMessage)(error_1),
                        };
                        return [3, 4];
                    case 4: return [2, result];
                }
            });
        });
    };
    TokenViewStore.prototype.deleteToken = function (token) {
        return __awaiter(this, void 0, Promise, function () {
            var shouldDeleteToken;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.confirmDeleteToken()];
                    case 1:
                        shouldDeleteToken = _a.sent();
                        if (!shouldDeleteToken) return [3, 3];
                        return [4, this.tokenStore.deleteToken(token)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2];
                }
            });
        });
    };
    TokenViewStore.prototype.confirmDeleteToken = function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.modal.showDeleteModal({
                            containerClassName: "tokens-panel__modal",
                            title: "Delete Token",
                            explainer: "Don’t worry, your existing pages will still work.",
                            confirmLabel: "Delete token",
                        })];
                    case 1: return [2, _a.sent()];
                }
            });
        });
    };
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", typeof (_a = typeof Types_1.TokenType !== "undefined" && Types_1.TokenType) === "function" ? _a : Object)
    ], TokenViewStore.prototype, "selectedTab", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", String)
    ], TokenViewStore.prototype, "searchTerm", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], TokenViewStore.prototype, "selectedListToken", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], TokenViewStore.prototype, "isRefreshing", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], TokenViewStore.prototype, "errorMessage", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], TokenViewStore.prototype, "connectedIntegrationProviderName", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], TokenViewStore.prototype, "connectedIntegrationFullName", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], TokenViewStore.prototype, "showListTokenProperties", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_c = typeof Array !== "undefined" && Array) === "function" ? _c : Object),
        __metadata("design:paramtypes", [])
    ], TokenViewStore.prototype, "tokens", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_d = typeof Array !== "undefined" && Array) === "function" ? _d : Object),
        __metadata("design:paramtypes", [])
    ], TokenViewStore.prototype, "popularCrmTokens", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], TokenViewStore.prototype, "showPopularCrmTokens", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], TokenViewStore.prototype, "isLoading", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], TokenViewStore.prototype, "activeTabIndex", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_e = typeof Types_1.TokenType !== "undefined" && Types_1.TokenType) === "function" ? _e : Object]),
        __metadata("design:returntype", void 0)
    ], TokenViewStore.prototype, "onTabChange", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], TokenViewStore.prototype, "onSearchChange", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], TokenViewStore.prototype, "onSearchClear", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], TokenViewStore.prototype, "toggleSelectedListToken", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], TokenViewStore.prototype, "setIsRefreshing", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], TokenViewStore.prototype, "setErrorMessage", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], TokenViewStore.prototype, "clearErrorMessage", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", typeof (_g = typeof Promise !== "undefined" && Promise) === "function" ? _g : Object)
    ], TokenViewStore.prototype, "syncTokens", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", typeof (_h = typeof Promise !== "undefined" && Promise) === "function" ? _h : Object)
    ], TokenViewStore.prototype, "fetchSearchResults", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_j = typeof Types_2.CreateTokenParams !== "undefined" && Types_2.CreateTokenParams) === "function" ? _j : Object]),
        __metadata("design:returntype", typeof (_k = typeof Promise !== "undefined" && Promise) === "function" ? _k : Object)
    ], TokenViewStore.prototype, "addToken", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_l = typeof Types_2.Token !== "undefined" && Types_2.Token) === "function" ? _l : Object]),
        __metadata("design:returntype", typeof (_m = typeof Promise !== "undefined" && Promise) === "function" ? _m : Object)
    ], TokenViewStore.prototype, "deleteToken", null);
    return TokenViewStore;
}());
exports.TokenViewStore = TokenViewStore;
