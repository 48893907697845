"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FocusEnableButton = (function () {
    function FocusEnableButton(createUi, Button, integration, disable) {
        var internalDisableButton = new Button(function () {
            disable();
        }, "Disable", {
            theme: "secondary",
            size: "medium",
        });
        this.ui = createUi(require("./FocusDisableButton.html"), {
            integration: integration,
            internalDisableButton: internalDisableButton,
        });
    }
    return FocusEnableButton;
}());
exports.default = FocusEnableButton;
