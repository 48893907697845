// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = (function (_this) {
    return function (
      createUi,
      $http,
      $timeout,
      apiBaseUrl,
      user,
      account,
      userList,
      di,
      project,
      userIconFactory,
      getFullName,
      overlayStack,
      rolesService,
      permissionsService,
      teamsService,
      notifyUser,
      loadingIndicator,
      EditableHeading,
    ) {
      var canEditProjectName,
        createTeamMember,
        editableHeading,
        getUsers,
        getUsersWithoutTeams,
        onProjectNameChange,
        utils;
      utils = di.invoke(require("./Utils"));
      canEditProjectName = project.status !== "accepted" && permissionsService.isAllowed(project.$userAction, "edit");
      onProjectNameChange = function (updatedName) {
        return (project.name = updatedName);
      };
      editableHeading = new EditableHeading("", canEditProjectName, onProjectNameChange, project.name);
      createTeamMember = function (_user) {
        var isAdmin, member, permission;
        isAdmin = rolesService.isAdmin(_user);
        permission = _.find(_this.ui.permissions.userPermissions, {
          userId: _user._id,
        });
        member = {
          name: getFullName(_user),
          icon: userIconFactory.create(_user, {
            textColor: "#242C39",
            backgroundColor: "#B6B9BD",
          }),
          _id: _user._id,
          isAdmin: isAdmin,
          permission: permission,
          $selected: isAdmin || (permission != null && permission.action !== "none"),
          disabled: isAdmin || (permission != null ? permission.action : void 0) === "all",
          isOwner: function () {
            return (permission != null ? permission.action : void 0) === "all";
          },
          self: _user._id === user._id,
        };
        member.hideDropdown = function () {
          return !member.$selected && !member.disabled;
        };
        member.permissionsDropdown = utils.makeUserPermissionsDropdown(member, project);
        return member;
      };
      getUsersWithoutTeams = function () {
        var usersWithTeams, usersWithoutTeams;
        usersWithTeams = [];
        _.each(_this.ui.teams, function (team) {
          return (usersWithTeams = _.union(usersWithTeams, _.map(team.members, "_id")));
        });
        usersWithoutTeams = _.difference(_.map(userList, "_id"), usersWithTeams);
        return _.map(usersWithoutTeams, function (userId) {
          return createTeamMember(
            _.find(userList, {
              _id: userId,
            }),
          );
        });
      };
      getUsers = function () {
        return _.map(userList, function (user) {
          return createTeamMember(user);
        });
      };
      _this.ui = createUi(require("./Team.html"), {
        project: project,
        editableHeading: editableHeading,
        viewOnly: !rolesService.isAdmin(user) && project.user !== user._id,
        canEditProjectName: canEditProjectName,
        close: overlayStack.closeCurrent,
        isAdmin: rolesService.isAdmin(user),
        showSeparator: function () {
          var listContainer;
          listContainer = $(".team-member-container");
          return listContainer.prop("scrollHeight") > listContainer.height();
        },
        needsUpdate: function () {
          var needsUpdate, permissions;
          needsUpdate = false;
          permissions = _.concat(_this.ui.permissions.userPermissions, _this.ui.permissions.teamPermissions);
          _.each(permissions, function (permission) {
            if (!permission.originalAction && permission.action === "none") {
              return;
            }
            if (permission.action !== permission.originalAction) {
              needsUpdate = true;
              return false;
            }
          });
          return needsUpdate;
        },
        permissions: [],
        filterList: function () {
          _this.ui.filteredTeams = utils.filterList(_this.ui.teams, _this.ui.filter);
          return (_this.ui.filteredUsers = utils.filterList(_this.ui.users, _this.ui.filter));
        },
        togglePermission: function (owner) {
          var permission;
          if (!owner.permission) {
            if (owner.teamType) {
              permission = {
                teamId: owner._id,
              };
              _this.ui.permissions.teamPermissions.push(permission);
            } else {
              permission = {
                userId: owner._id,
              };
              _this.ui.permissions.userPermissions.push(permission);
            }
            owner.permission = permission;
          }
          if (owner.$selected) {
            return (owner.permission.action = owner.permissionsDropdown.selected.id);
          } else {
            return (owner.permission.action = "none");
          }
        },
        getPermissions: function () {
          return $http.get(apiBaseUrl + "/projects/" + project._id + "/permissions").then(function (response) {
            return (_this.ui.permissions = {
              userPermissions: utils.extendPermissionsWithOriginalAccess(response.data.userPermissions),
              teamPermissions: utils.extendPermissionsWithOriginalAccess(response.data.teamPermissions),
            });
          });
        },
        getTeams: function () {
          return teamsService.getTeamsForAccount().then(function (teams) {
            _this.ui.accountTeam = _.find(teams, {
              teamType: "account",
            });
            _this.ui.teams = _.filter(teams, {
              teamType: "normal",
            });
            _.each(_this.ui.teams, function (team, index) {
              team.permission = _.find(_this.ui.permissions.teamPermissions, {
                teamId: team._id,
              });
              team.$selected = team.permission != null && team.permission.action !== "none";
              team.permissionsDropdown = utils.makeTeamPermissionsDropdown(team);
              team.expanded = false;
            });
            _this.ui.accountTeam.permission = _.find(_this.ui.permissions.teamPermissions, {
              teamId: _this.ui.accountTeam._id,
            });
            _this.ui.accountTeam.$selected =
              _this.ui.accountTeam.permission != null && _this.ui.accountTeam.permission.action !== "none";
            return (_this.ui.accountTeam.permissionsDropdown = utils.makeAccountPermissionDropdown(
              _this.ui.accountTeam,
            ));
          });
        },
        updatePermissions: function () {
          var teamPermissions, userPermissions;
          userPermissions = _this.ui.permissions.userPermissions
            .filter(function (permission) {
              return !permission.originalAction || permission.originalAction !== permission.action;
            })
            .map(function (permission) {
              return {
                userId: permission.userId,
                action: permission.action,
              };
            });
          teamPermissions = _this.ui.permissions.teamPermissions
            .filter(function (permission) {
              return !permission.originalAction || permission.originalAction !== permission.action;
            })
            .map(function (permission) {
              return {
                teamId: permission.teamId,
                action: permission.action,
              };
            });
          return $http
            .post(apiBaseUrl + "/projects/" + project._id + "/permissions", {
              userPermissions: userPermissions,
              teamPermissions: teamPermissions,
            })
            .then(function (response) {
              _.each(
                _.concat(_this.ui.permissions.userPermissions, _this.ui.permissions.teamPermissions),
                function (permission) {
                  return (permission.originalAction = permission.action);
                },
              );
              return $timeout(function () {
                overlayStack.closeCurrent();
                return notifyUser.success("Successfully updated permissions");
              }, 500);
            });
        },
      });
      loadingIndicator.show("");
      return _this.ui
        .getPermissions()
        .then(function () {
          return _this.ui.getTeams();
        })
        .then(function () {
          var overlayOptions;
          _this.ui.users = getUsers();
          _this.ui.filterList();
          loadingIndicator.hide();
          overlayOptions = {
            centerContent: false,
            hideCloseButton: true,
          };
          return overlayStack.add({
            contentUi: _this.ui,
            options: overlayOptions,
          });
        });
    };
  })(this);
}.call(this));
