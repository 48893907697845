// Generated by CoffeeScript 1.12.7
(function () {
  var lessCompiler;

  lessCompiler = require("less");

  module.exports = function (style, accountColors) {
    var advancedStyle;
    advancedStyle = _.reduce(
      style.styleModel,
      function (styles, section) {
        if (section.rawStyles.length > 0) {
          if (section.element.length > 0) {
            return "" + styles + section.element + "{" + section.rawStyles + "}";
          } else {
            return styles + section.rawStyles;
          }
        }
        return styles;
      },
      "",
    );
    return lessCompiler.render("." + style.className + "{" + advancedStyle + "}").then(function (arg) {
      var css;
      css = arg.css;
      return css;
    });
  };
}.call(this));
