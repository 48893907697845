// Generated by CoffeeScript 1.12.7

/*
  This component is a vendor panel allowing you to select a font from Typekit.
  It hits the Typekit endpoint to get the font list. Unlike the Google Fonts
  panel, you can change the kitID, which will cause the component to load
  different fonts.

  It calls options.onSelected when the font is finished uploading.

  The font list will only be requested when .activate() is called so that
  loading the panel doesn't automatically fire off requests.
 */

(function () {
  var TypekitFontPanel, WebFont, cachedFontList, loadedKits;

  cachedFontList = [];

  loadedKits = {};

  WebFont = require("webfontloader");

  module.exports = TypekitFontPanel = (function () {
    function TypekitFontPanel(createUi, make, $http, $log, showAcknowledgeModal, account, noop, options) {
      var getFamily, loadKit, madeFontList, onSelected, ref, typekitId;
      onSelected = options.onSelected || noop;
      madeFontList = make(require("../../FontList/FontList"), {
        options: {
          onSelected: function (font) {
            return onSelected({
              friendlyName: font.name,
              name: font.family,
              typekitId: font.typekitId,
              vendor: "Typekit",
            });
          },
        },
      });
      getFamily = function (font) {
        if (font.css_names && font.css_names[0]) {
          return font.css_names[0];
        } else {
          return font.slug;
        }
      };
      loadKit = function (id) {
        if (!loadedKits[id]) {
          WebFont.load({
            typekit: {
              id: id,
            },
          });
        }
        madeFontList.reset();
        return $http
          .jsonp("https://typekit.com/api/v1/json/kits/" + id + "/published?callback=JSON_CALLBACK")
          .then(
            (function (_this) {
              return function (result) {
                if (result.data.errors) {
                  throw new Error(result.data.errors[0]);
                }
                cachedFontList = result.data.kit.families.map(function (font) {
                  return {
                    name: font.name,
                    family: getFamily(font),
                    typekitId: id,
                  };
                });
                return madeFontList.updateFonts(cachedFontList);
              };
            })(this),
          )
          ["catch"](
            (function (_this) {
              return function (err) {
                $log.warn(err);
                showAcknowledgeModal({
                  title: "Typekit loading failed",
                  explainer: "Please check your Typekit ID. If the problem persists contact help@qwilr.com",
                });
                return madeFontList.updateFonts([]);
              };
            })(this),
          );
      };
      typekitId =
        ((ref = _.find(account.settings.brand.fonts, {
          vendor: "Typekit",
        })) != null
          ? ref.typekitId
          : void 0) || "";
      this.ui = createUi(require("./Typekit.html"), {
        fontList: madeFontList.ui,
        typekitId: typekitId,
        idUpdated: (function (_this) {
          return function () {
            var id;
            id = _this.ui.typekitId.toLowerCase();
            return loadKit(id);
          };
        })(this),
        validKitId: (function (_this) {
          return function (id) {
            return id.match(/^\w{7}$/);
          };
        })(this),
      });
      this.activate = (function (_this) {
        return function () {
          if (!cachedFontList.length) {
            if (typekitId) {
              return loadKit(typekitId);
            } else {
              return madeFontList.updateFonts([]);
            }
          } else {
            return madeFontList.updateFonts(cachedFontList);
          }
        };
      })(this);
    }

    return TypekitFontPanel;
  })();
}.call(this));
