// Generated by CoffeeScript 1.12.7
(function () {
  var PaymentController;

  module.exports = PaymentController = (function () {
    function PaymentController(di, createUi, $q, account, overlayStack, paymentService, $log, AsyncButton) {
      var getInitialUIData, getPaymentDetails, init, updateBillingContactEmail, updateCreditCard;
      updateCreditCard = null;
      this.ui = null;
      init = (function (_this) {
        return function () {
          updateCreditCard = require("../CreditCard/UpdateCreditCard");
          _this.ui = createUi(require("SettingsAngular/Subscription/Payment/Payment.html"), getInitialUIData());
          return getPaymentDetails();
        };
      })(this);
      getPaymentDetails = (function (_this) {
        return function () {
          var ref;
          if ((account != null ? ((ref = account.stripe) != null ? ref.customerToken : void 0) : void 0) == null) {
            _this.ui.card = null;
            _this.ui.billingContact = null;
            _this.ui.loaded = true;
          }
          return paymentService
            .getPaymentDetails(account._id)
            .then(function (details) {
              if (details) {
                _this.ui.card = details.card;
                _this.ui.billingContact = details.billingContact;
              } else {
                _this.ui.error = true;
              }
              return (_this.ui.loaded = true);
            })
            ["catch"](function (error) {
              $log.error(error.message);
              _this.ui.loaded = true;
              return (_this.ui.error = true);
            });
        };
      })(this);
      updateBillingContactEmail = (function (_this) {
        return function () {
          _this.ui.billingContactEmailButton.state = "waiting";
          return paymentService
            .updateBillingContact(account._id, {
              email: _this.ui.billingContact.email,
            })
            .then(function () {
              return (_this.ui.billingContactEmailButton.state = "disabled");
            });
        };
      })(this);
      getInitialUIData = (function (_this) {
        return function () {
          return {
            loaded: false,
            error: false,
            card: null,
            billingContact: null,
            billingContactEmailButton: new AsyncButton(updateBillingContactEmail, "Update Email", {
              center: true,
              getErrorMessage: function (err) {
                return err.message;
              },
            }),
            updateCard: function () {
              var screen, updateDi;
              updateDi = di.child();
              screen = updateDi.instantiate(updateCreditCard, {
                isUpdating: true,
                onComplete: function () {
                  overlayStack.closeCurrent();
                  _this.ui.loaded = false;
                  return getPaymentDetails();
                },
              });
              return overlayStack.add({
                contentUi: screen.ui,
                options: {},
              });
            },
            onBillingContactEmailTouched: function () {
              return (_this.ui.billingContactEmailButton.state = "idle");
            },
          };
        };
      })(this);
      init();
    }

    return PaymentController;
  })();
}.call(this));
