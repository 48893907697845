"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.transformUserSettings = void 0;
var lodash_1 = require("lodash");
var transformUserSettings = function (settingsData) {
    var newSettings = (0, lodash_1.cloneDeep)(settingsData);
    newSettings === null || newSettings === void 0 ? true : delete newSettings.integrations;
    newSettings === null || newSettings === void 0 ? true : delete newSettings.notifications;
    return newSettings;
};
exports.transformUserSettings = transformUserSettings;
