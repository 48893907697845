"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Types_1 = require("@CommonFrontendBackend/Projects/Types");
var LockedPageModal_1 = require("Common/Modal/PageModals/LockedPageModal");
var PageQuoteTotalStore_1 = require("Project/Stores/PageQuoteTotalStore");
var GetSourceParam_1 = require("Project/Utils/GetSourceParam");
var Telemetry_1 = require("Telemetry");
require("ProjectView/ProjectView.ng.html");
var ProjectShareViewedOrigin = require("Telemetry").ProjectShareViewedOrigin;
module.exports = angular.module("qwilr.projectView", ["ui.router"]).config(function ($stateProvider) {
    return $stateProvider
        .state("projectView", {
        url: "/page-view/:id",
        params: {
            skipLockedPageModal: false,
            origin: null,
        },
        templateUrl: "ProjectView/ProjectView.ng.html",
        controller: function ($scope, $rootScope, $stateParams, $timeout) {
            var di = $rootScope.di.child();
            return di.invoke(function (make, $location, $http, genericHost, apiBaseUrl, projectService, projectAuditInspector, loadingIndicator, rootDataStore, $state, mountReactComponent, modal, accountQuoteSettings, variableService, discountService, billingScheduleOrchestrator, projectTelemetry, user) {
                return __awaiter(this, void 0, void 0, function () {
                    var _a, response_1, e_1;
                    return __generator(this, function (_b) {
                        switch (_b.label) {
                            case 0:
                                loadingIndicator.show("Project");
                                $scope.url = "".concat($location.protocol(), "://").concat(genericHost, "/api/projects/").concat($stateParams.id, "/render");
                                _b.label = 1;
                            case 1:
                                _b.trys.push([1, 3, , 4]);
                                return [4, Promise.all([
                                        $http.get("".concat(apiBaseUrl, "/projects/").concat($stateParams.id).concat((0, GetSourceParam_1.getSourceParam)($location))),
                                        rootDataStore.getAccountDataStore().getTeamDataStore().fetchData(),
                                        rootDataStore.getPermissionsDataStore().getPagePermissions($stateParams.id).fetchData(),
                                    ])];
                            case 2:
                                _a = __read.apply(void 0, [_b.sent(), 1]), response_1 = _a[0];
                                $scope.$apply(function () {
                                    var _a;
                                    var project = response_1.data;
                                    di.load(require("Project/ProjectWiring")(project, [], null, rootDataStore, false, { disableSync: true }));
                                    var projectStore = di.get("projectStore");
                                    projectTelemetry.projectOpened($stateParams, (_a = {},
                                        _a[Telemetry_1.ProjectOpenedFields.AuthUserId] = user._id,
                                        _a[Telemetry_1.ProjectOpenedFields.AccessLevel] = user._id === projectStore.user ? "owner" : projectStore.userAction,
                                        _a[Telemetry_1.ProjectOpenedFields.AccessInFolder] = Boolean(projectStore.parentFolder),
                                        _a));
                                    var pageQuoteTotalStore = new PageQuoteTotalStore_1.PageQuoteTotalStore(project, projectStore, accountQuoteSettings, variableService, discountService, billingScheduleOrchestrator, {
                                        $scope: $scope,
                                        $timeout: $timeout,
                                    });
                                    var mainNav = make(require("Project/TopNavigationBar/TopNavigationBar").default, {
                                        pageQuoteTotalStore: pageQuoteTotalStore,
                                    });
                                    $scope.mainNav = mainNav.ui;
                                    var isLockedPage = (0, Types_1.isPageLocked)(project.status);
                                    if (isLockedPage) {
                                        $scope.hasInfoBanner = true;
                                    }
                                    if (isLockedPage &&
                                        rootDataStore.userDataStore.useHistoryDataStore.shouldShowPageStateModal(project.status, project.subStatus)) {
                                        (0, LockedPageModal_1.showLockedPageModal)(modal, $state, rootDataStore.userDataStore.useHistoryDataStore, projectStore);
                                    }
                                    loadingIndicator.hide();
                                    if ($location.search().share) {
                                        return projectService.share(project, ProjectShareViewedOrigin.ProjectNavBar);
                                    }
                                    else if ($location.search().audit && project.status === "accepted") {
                                        return projectAuditInspector.inspect(projectStore);
                                    }
                                });
                                return [3, 4];
                            case 3:
                                e_1 = _b.sent();
                                $scope.$apply(function () {
                                    loadingIndicator.hide();
                                });
                                return [3, 4];
                            case 4: return [2];
                        }
                    });
                });
            });
        },
    })
        .state("projectViewOld", {
        url: "/project-view/:id",
        templateUrl: "ProjectView/ProjectView.ng.html",
        controller: function ($location, $stateParams) {
            return $location.path("/page-view/".concat($stateParams.id));
        },
    });
});
