// Generated by CoffeeScript 1.12.7

/*
  Singleton overlay for browsing and selecting images and videos
  Has a single entry and exit point with .show()

  USAGE:
  mediaBrowser.show()
  .then ({mediaSource: mediaSource, data: data}) -> someFunction()

  Expects view factories to implement:
  .setup() - return the ui of that view factory
 */

(function () {
  var FeatureFlag,
    MediaBrowser,
    OverlayMode,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    },
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  OverlayMode = require("OverlayStack/OverlayMode").OverlayMode;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = MediaBrowser = (function () {
    function MediaBrowser(make, createUi, overlayStack, $q, $log, di, mediaUsageReporter, assetLibraryLauncher) {
      var videoTopBar;
      this.mediaUsageReporter = mediaUsageReporter;
      this.assetLibraryLauncher = assetLibraryLauncher;
      this.changeView = bind(this.changeView, this);
      this.select = bind(this.select, this);
      this.getMediaBrowserOptions = bind(this.getMediaBrowserOptions, this);
      this.show = bind(this.show, this);
      this.overlayStack = overlayStack;
      this.q = $q;
      this.log = $log;
      videoTopBar = make(require("./VideoTopBar.js"), {
        changeViewFunction: this.changeView,
      });
      this.topBars = {
        youtube: videoTopBar,
        stockVideos: videoTopBar,
      };
      di.value("selectCallback", this.select);
      di.value("changeViewCallback", this.changeView);
      di.value("getMediaBrowserOptions", this.getMediaBrowserOptions);
      this.viewFactories = {
        stockVideos: make(require("./Factories/StockVideosFactory.js")),
        youtube: make(require("./Factories/YoutubeFactory.js")),
      };
      this.currentFactory = null;
      this.currentTopBar = null;
      this.selecting = null;
      this.ui = createUi(require("./MediaBrowser.html"), {
        topBarUi: null,
        mediaUi: null,
        error: null,
      });
    }

    /*
      Open up the browser
      @param viewName [string] the view factory to open up with
      @param mediaBrowserOptions [object] optional options that view factories can reference
      @return [Promise] that resolves with
        `mediaSource`: [string] where the media is from, e.g 'unsplash'
        `data`: [object] data that, at minimum, can uniquely identify the media at it's source
     */

    MediaBrowser.prototype.show = function (viewName, mediaBrowserOptions) {
      var viewsExcludedFromAssetLibrary;
      if (mediaBrowserOptions == null) {
        mediaBrowserOptions = null;
      }
      this.selecting = this.q.defer();
      this.mediaBrowserOptions = mediaBrowserOptions;
      viewsExcludedFromAssetLibrary = ["stockVideos", "youtube"];
      if (indexOf.call(viewsExcludedFromAssetLibrary, viewName) < 0) {
        /*
          @NOTE (som, 2022-01-18): we will ideally use the new `AssetLibraryOverlay` for opening the
          Asset Library here, but this MediaBrowser code is a bit too fragile for me to confidently attempt
          it right now.
         */
        this.overlayStack.add({
          contentUi: this.assetLibraryLauncher.launch(this.select, mediaBrowserOptions),
          options: {
            className: "assetlibrary-close-button",
            overlayMode: OverlayMode.Modal,
          },
        });
      } else {
        this.overlayStack.add({
          contentUi: this.ui,
          options: {
            className: "mediabrowser-close-button",
          },
        });
        this.changeView(viewName, {
          earlyExit: mediaBrowserOptions != null ? mediaBrowserOptions.earlyExit : void 0,
        });
      }
      return this.selecting.promise;
    };

    MediaBrowser.prototype.getMediaBrowserOptions = function () {
      return this.mediaBrowserOptions;
    };

    MediaBrowser.prototype.select = function (mediaSource, data, metricSource) {
      this.overlayStack.closeCurrent();
      this.mediaUsageReporter.reportUsage(mediaSource, data);
      return this.selecting.resolve({
        mediaSource: mediaSource,
        data: data,
        metricSource: metricSource,
      });
    };

    MediaBrowser.prototype.changeView = function (viewName, data) {
      var nextFactory, nextTopBar;
      if (data == null) {
        data = null;
      }
      nextFactory = this.viewFactories[viewName];
      nextTopBar = this.topBars[viewName];
      if (viewName != null && nextFactory != null && nextTopBar != null) {
        if (this.currentFactory === nextFactory && data == null) {
          return;
        }
        this.currentFactory = nextFactory;
        this.currentTopBar = nextTopBar;
        this.currentTopBar.setSelectedView(viewName, data != null ? data.earlyExit : void 0);
        this.ui.topBarUi = this.currentTopBar.ui;
        if (this.currentFactory != null) {
          return (this.ui.mediaUi = this.currentFactory.setup(data));
        }
      } else {
        return this.log.error("Tried to changeView() to invalid viewName: " + viewName);
      }
    };

    return MediaBrowser;
  })();
}.call(this));
