// Generated by CoffeeScript 1.12.7

/*
  This module displays a context menu and accepts a list of button descriptors
  with styling, text, and on-click callbacks.
 */

(function () {
  var ContextMenuController;

  module.exports = ContextMenuController = (function () {
    function ContextMenuController(make, createUi, $document, $window, $timeout, model, contextButtons) {
      var closeMenu, onLeftClick, onScroll;
      this.ui = createUi(require("Common/ContextMenu/ContextMenu.html"), {
        $init: (function (_this) {
          return function (scope) {
            return (_this.ngScope = scope);
          };
        })(this),
        model: model,
      });
      this.ui.showing = false;
      this.ui.x = null;
      this.ui.y = null;
      this.ui.contextButtons = null;
      closeMenu = (function (_this) {
        return function () {
          _this.ui.showing = false;
          _this.ui.x = null;
          _this.ui.y = null;
          _this.ui.contextButtons = null;
          $document.off("click", onLeftClick);
          return angular.element($window).off("scroll", onScroll);
        };
      })(this);
      onLeftClick = (function (_this) {
        return function (e) {
          var MS_RIGHT_CLICK_BUTTON, RIGHT_CLICK_WHICH, isRightClick;
          RIGHT_CLICK_WHICH = 3;
          MS_RIGHT_CLICK_BUTTON = 2;
          isRightClick = function (e) {
            if (e.which != null) {
              return e.which === RIGHT_CLICK_WHICH;
            } else if (e.button != null) {
              return e.button === MS_RIGHT_CLICK_BUTTON;
            }
          };
          if (!isRightClick(e)) {
            return $timeout(closeMenu);
          }
        };
      })(this);
      onScroll = (function (_this) {
        return function (event) {
          return $timeout(closeMenu);
        };
      })(this);
      this.showAt = (function (_this) {
        return function (showEvent, params) {
          _this.ui.x = showEvent.clientX;
          _this.ui.y = showEvent.clientY;
          _this.ui.showing = true;
          contextButtons.forEach(function (contextButton) {
            return (contextButton.curriedClick = function (event) {
              contextButton.onClick(params);
              event.preventDefault();
              return onLeftClick(showEvent);
            });
          });
          _this.ui.contextButtons = contextButtons;
          $document.on("click", onLeftClick);
          angular.element($window).on("scroll", onScroll);
        };
      })(this);
    }

    return ContextMenuController;
  })();
}.call(this));
