"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFormulaErrorCopy = exports.FormulaErrorType = void 0;
var FormulaErrorType;
(function (FormulaErrorType) {
    FormulaErrorType["VariableNotFound"] = "variablenotfound";
    FormulaErrorType["FormulaNotEvaluatable"] = "formulanotevaluatable";
})(FormulaErrorType || (exports.FormulaErrorType = FormulaErrorType = {}));
var getFormulaErrorCopy = function (detail) {
    if (detail.type === FormulaErrorType.VariableNotFound) {
        var variable = detail.variable;
        return "Variable {{".concat(variable, "}} specified in formula does not exist");
    }
    if (detail.type === FormulaErrorType.FormulaNotEvaluatable) {
        return "Formula could not be evaluated";
    }
    return undefined;
};
exports.getFormulaErrorCopy = getFormulaErrorCopy;
