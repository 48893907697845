"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.LinkPreviewSettingsStore = void 0;
var mobx_1 = require("mobx");
var LinkPreview_1 = require("@CommonFrontendBackend/Project/LinkPreview");
var ProjectTypes_1 = require("Project/ProjectTypes");
var LinkPreviewSettingsStore = (function () {
    function LinkPreviewSettingsStore(projectStore, account) {
        this.projectStore = projectStore;
        this.account = account;
    }
    Object.defineProperty(LinkPreviewSettingsStore.prototype, "previewImageUrl", {
        get: function () {
            var _a, _b;
            return (((_a = this.projectStore.customLinkPreview) === null || _a === void 0 ? void 0 : _a.imageUrl) ||
                ((_b = this.account.customLinkPreview) === null || _b === void 0 ? void 0 : _b.imageUrl) ||
                LinkPreview_1.LINK_PREVIEW_DEFAULT_URL);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(LinkPreviewSettingsStore.prototype, "previewDefaultImageUrl", {
        get: function () {
            var _a;
            return ((_a = this.account.customLinkPreview) === null || _a === void 0 ? void 0 : _a.imageUrl) || LinkPreview_1.LINK_PREVIEW_DEFAULT_URL;
        },
        enumerable: false,
        configurable: true
    });
    LinkPreviewSettingsStore.prototype.handleLinkPreviewImageChange = function (imageUrl) {
        if (imageUrl === this.previewDefaultImageUrl) {
            this.projectStore.updateLinkPreview(ProjectTypes_1.ProjectLinkPreviewData.Image, "");
        }
        else {
            this.projectStore.updateLinkPreview(ProjectTypes_1.ProjectLinkPreviewData.Image, imageUrl);
        }
    };
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], LinkPreviewSettingsStore.prototype, "handleLinkPreviewImageChange", null);
    return LinkPreviewSettingsStore;
}());
exports.LinkPreviewSettingsStore = LinkPreviewSettingsStore;
