import "Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css\",\"source\":\"Ll8xcnVhYmNyMCB7CiAgcGFkZGluZzogMCAhaW1wb3J0YW50OwogIG1hcmdpbjogMCAhaW1wb3J0YW50OwogIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDsKfQouXzFydWFiY3IyIHsKICBwb3NpdGlvbjogZml4ZWQ7CiAgd2lkdGg6IHZhcigtLV8xcnVhYmNyMSk7CiAgdG9wOiAwOwogIG91dGxpbmU6IDFweCBzb2xpZCB0cmFuc3BhcmVudDsKICBsZWZ0OiAyMDB2dzsKfQpfOjotd2Via2l0LWZ1bGwtcGFnZS1tZWRpYSwgXzpmdXR1cmUsIDpyb290IC5fMXJ1YWJjcjI6OmJlZm9yZSB7CiAgY29udGVudDogIiI7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHdpZHRoOiAxcHg7CiAgaGVpZ2h0OiAxcHg7CiAgbGVmdDogY2FsYygtMTAwdncgLSAxcHgpOwogIHRvcDogMDsKICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDsKfQouXzFydWFiY3IyID4gKjpmaXJzdC1jaGlsZCB7CiAgcGFkZGluZy10b3A6IDAgIWltcG9ydGFudDsKICBtYXJnaW4tdG9wOiAwICFpbXBvcnRhbnQ7Cn0KLl8xcnVhYmNyMiAuaW1hZ2Utd2lkZ2V0X19naG9zdCB7CiAgZGlzcGxheTogbm9uZTsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVXW/bIBR9z6+4ilQpXkNlO3HqUvVxf2Bv01RV2CYOLQEPcJt26n+fjHFicDqtm/xiLod7Lud+cPWQCPZWvFUJ/JoBNFIzw6TAoCgnhj3T2xnAjrJ6ZzAkcXxxO3ufXQ2HUowLupWK2sOlFIYKg2E+v/V8kUJL3hrri9OtwRA3B89x5gwvrDK7gQjAKCL0Vqo97n85MfT7All45EWywphsDVV/H8iRO3fcXmReICOetSX4aDcb726ZQS6SDvSjIoYgTTktu2CQYqK+78NtlZYKQyOZMFSdwHZJyi4N6E0Ker90TjodkJAVvZtTTvdUmLnvqqJb0vIRr4Mh89rQu7mhB/P17MFux7tSYbcRGtbEpaIhyl5svLc5yneyXZ+x5Ufb+dRMBAFgQlODoSS8XCyeiVqMGCK4BN+URxF8AZT4NVJ8vloLeUB6Ryr5giG2X9oHXkreRejTxsvAQKLIeiHlU61kKyrkzgUS2qWjtwjnp2oVscWiHx6Sgl8/PiZJG7lNSjQTNdKGiIqo6oTQ0XJC+Z8ORykIle5lUhVVSJGKtTqE3ISqbKIhL6olRalSCKqtYrrh5BWDkIJ6KSyn1ajqYpGuMkjXMaTraz/l1Xl8kt1AmqwhzdY+nn6A30CSrSDNUh9eB/DNcPdekG9WDzsElOQnPeto0iP5mR5Zd7YRG3Mj2jYFos9UGD1odEwPmpzyqt6h4vNlGVZv2AKTfDxN9bKu0J4dFkwAJ8XSKpjnkOSd4KsIkvhiOe4AX1P+Ly7TP7qUwds27nAjm/Dewt5b9c+DnTjdoJ8MmX0UsHx+vFjyPhfSGLl3i/4lGh5B95yE5B+1UH9Z2ZCSmVfncOAZRdsEMosQ8HMKQEkcFJcKQHsMmwCip5AkrNF2irEN8f4b00L4Xp8IAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var ringDataAttribute = 'data-selection-ring';
export var interactiveElementSelector = '[data-interactive-zone]';
export var textElementSelector = '[data-element-type="textElement"]';
export var childSelector = '[data-slate-node="element"]';
export var selectionRingWidth = 2;
export var outsideRingOffset = 8;
export var customRingColor = 'var(--_1nizbzd0)';
export var wrapper = _7a468({defaultClassName:'_1nizbzd1',variantClassNames:{extraSpaceBeforeFirstWidgetInBlock:{true:'_1nizbzd2'},extraSpaceAfterLastWidgetInBlock:{true:'_1nizbzd3'},widgetWidth:{'full-width':'_1nizbzd4','fit-widget':'_1nizbzd5'}},defaultVariants:{},compoundVariants:[]});
export var ringToWidgetOffset = 'var(--_1nizbzd7)';
export var ringToHitboxInset = 'var(--_1nizbzd8)';
export var customRingBorderRadius = 'var(--_1nizbzd9)';
export var ring = _7a468({defaultClassName:'_1nizbzdb',variantClassNames:{mouseOverWidget:{true:'_1nizbzdc'},mouseOverSelectableRegion:{true:'_1nizbzdd'},selected:{true:'_1nizbzde'},mode:{hugging:'_1nizbzdf',outside:'_1nizbzdg'},nodeType:{'void':'_1nizbzdh',textElement:'_1nizbzdi',layoutElement:'_1nizbzdj'}},defaultVariants:{},compoundVariants:[[{mouseOverSelectableRegion:true,nodeType:'textElement'},'_1nizbzdk'],[{selected:true,nodeType:'textElement'},'_1nizbzdl']]});
export var dragHandle = _7a468({defaultClassName:'_1nizbzdo',variantClassNames:{alignment:{widget:'_1nizbzdp','selection-ring':'_1nizbzdq'},mode:{hugging:'_1nizbzdr',outside:'_1nizbzds'},nodeType:{'void':'_1nizbzdt',textElement:'_1nizbzdu',layoutElement:'_1nizbzdv'}},defaultVariants:{},compoundVariants:[]});