// Generated by CoffeeScript 1.12.7
(function () {
  var ApiSettingsController;

  module.exports = ApiSettingsController = (function () {
    function ApiSettingsController(createUi, $timeout, account) {
      var key, substitutions, value;
      if (!account.settings.globalSubstitutions) {
        account.settings.globalSubstitutions = {};
      }
      substitutions = (function () {
        var ref, results;
        ref = account.settings.globalSubstitutions;
        results = [];
        for (key in ref) {
          value = ref[key];
          results.push({
            key: key,
            value: value,
            isEditing: false,
          });
        }
        return results;
      })();
      this.ui = createUi(require("./Variables.html"), {
        substitutions: function () {
          return substitutions;
        },
        messages: {
          success: "",
          error: "",
        },
        actions: [
          {
            label: "edit",
            onClick: function (sub) {
              sub.isEditing = true;
              return (sub.$oldKey = sub.key);
            },
            icon: "pencil2",
          },
          {
            label: "delete",
            onClick: function (sub) {
              delete account.settings.globalSubstitutions[sub.key];
              return _.remove(substitutions, {
                key: sub.key,
              });
            },
            icon: "cross",
          },
        ],
        updateSub: (function (_this) {
          return function (sub) {
            if (sub.key.length === 0) {
              return _this.ui.showError("Please specify a key name");
            } else {
              delete account.settings.globalSubstitutions[sub.$oldKey];
              account.settings.globalSubstitutions[sub.key] = sub.value;
              return (sub.isEditing = false);
            }
          };
        })(this),
        errorMessage: "",
        showError: (function (_this) {
          return function (message) {
            _this.ui.errorMessage = message;
            return $timeout(function () {
              return (_this.ui.errorMessage = null);
            }, 5000);
          };
        })(this),
        addPair: (function (_this) {
          return function () {
            if (!_this.ui.newKey || _this.ui.newKey.length === 0) {
              return _this.ui.showError("Please specify a key name");
            } else {
              account.settings.globalSubstitutions[_this.ui.newKey] = _this.ui.newValue;
              substitutions.push({
                key: _this.ui.newKey,
                value: _this.ui.newValue,
                isEditing: false,
              });
              _this.ui.newKey = "";
              return (_this.ui.newValue = "");
            }
          };
        })(this),
      });
    }

    return ApiSettingsController;
  })();
}.call(this));
