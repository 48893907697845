"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createImageNode = void 0;
var Text_1 = require("@CommonFrontend/ContentTokens/DataModels/Text");
var createImageNode = function (src, options) {
    var _a = options || {}, width = _a.width, alignment = _a.alignment, frame = _a.frame, uploadId = _a.uploadId, href = _a.href, variable = _a.variable, alt = _a.alt, role = _a.role;
    var json = {
        isVoid: true,
        type: "image",
        data: {
            src: src || "",
            width: width,
            alt: alt || "",
            role: role || "",
        },
        children: [Text_1.text.createSlateJson()],
    };
    if (href) {
        json.data.href = href;
    }
    if (alignment) {
        json.data.alignment = alignment;
    }
    if (frame) {
        json.data.frame = frame;
    }
    if (uploadId) {
        json.data.uploadId = uploadId;
    }
    if (variable) {
        json.data.variable = variable;
    }
    return json;
};
exports.createImageNode = createImageNode;
