// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = {
    Enter: {
      key: "enter",
      tests: [
        {
          name: "should insert a newline on Enter keypress",
          startContent: "<blockquote>a|b</blockquote>",
          selectionResult: "<blockquote>a</blockquote><blockquote>|b</blockquote>",
        },
        {
          name: "should insert a newline at the start of a blockquote with an open blockquote above",
          startContent: "<blockquote>|ab</blockquote>",
          selectionResult: "<blockquote><br></blockquote><blockquote>|ab</blockquote>",
        },
        {
          name: "should insert a paragraph when at the end of a blockquote",
          startContent: "<blockquote>ab|</blockquote>",
          selectionResult: "<blockquote>ab</blockquote><p>|<br></p>",
        },
        {
          name: "should split styling tags when making newline",
          startContent: "<blockquote>a<b>b<i>c|d</i>e</b>f</blockquote>",
          selectionResult: "<blockquote>a<b>b<i>c</i></b></blockquote><blockquote><b><i>|d</i>e</b>f</blockquote>",
        },
        {
          name: "should create empty paragraph when pressed in empty blockquote",
          startContent: "<blockquote><br>|</blockquote>",
          selectionResult: "<blockquote><br></blockquote><p>|<br></p>",
        },
        {
          name: "should create empty paragraph when pressed in empty blockquote (modified location)",
          startContent: "<blockquote>|<br></blockquote>",
          selectionResult: "<blockquote><br></blockquote><p>|<br></p>",
        },
      ],
    },
    Backspace: {
      key: "backspace",
      tests: [
        {
          name: "should join two blockquotes together if at start of second blockquote",
          startContent: "<blockquote>ab</blockquote><blockquote>|cd</blockquote>",
          selectionResult: "<blockquote>ab|cd</blockquote>",
        },
        {
          name: "should join blockquote content to end of other block type",
          startContent: "<h1>ab</h1><blockquote>|cd</blockquote>",
          selectionResult: "<h1>ab|cd</h1>",
        },
        {
          name: "should do nothing if first blockquote",
          startContent: "<blockquote>|ab</blockquote>",
          domResult: "<blockquote>ab</blockquote>",
          selectionResult: "<blockquote>|ab</blockquote>",
        },
        {
          name: "should not handle event if not at start of line",
          startContent: "<blockquote>ab|cd</blockquote>",
          domResult: "<blockquote>abcd</blockquote>",
          selectionResult: "<blockquote>ab|cd</blockquote>",
        },
        {
          name: "should not handle event if only whitespace beforehand",
          startContent: "<blockquote>ab</blockquote><blockquote>&nbsp;|cd</blockquote>",
          domResult: "<blockquote>ab</blockquote><blockquote>&nbsp;cd</blockquote>",
          selectionResult: "<blockquote>ab</blockquote><blockquote>&nbsp;|cd</blockquote>",
        },
        {
          name: "should not handle if at start of style tag with more content before",
          startContent: "<blockquote>abc<b>|def</b></blockquote>",
          domResult: "<blockquote>abc<b>def</b></blockquote>",
          selectionResult: "<blockquote>abc<b>|def</b></blockquote>",
        },
        {
          name: "should not handle if after style tag with content in style",
          startContent: "<blockquote><b>def</b>|abc</blockquote>",
          domResult: "<blockquote><b>def</b>abc</blockquote>",
          selectionResult: "<blockquote><b>def</b>|abc</blockquote>",
        },
        {
          name: "should join onto list correctly if there is no content in heading",
          startContent: "<ul><li>asdf</li></ul><blockquote>|<br></blockquote>",
          selectionResult: "<ul><li>asdf|</li></ul>",
        },
        {
          name: "should join onto list correctly if there is no content in heading (modified caret location)",
          startContent: "<ul><li>asdf</li></ul><blockquote><br>|</blockquote>",
          selectionResult: "<ul><li>asdf|</li></ul>",
        },
        {
          name: "should join together similar style inline tags on joining",
          startContent: "<blockquote>a<b>b<i>c</i></b></blockquote><blockquote><b><i>|d</i>e</b>f</blockquote>",
          selectionResult: "<blockquote>a<b>b<i>c|d</i>e</b>f</blockquote>",
        },
      ],
    },
    Delete: {
      key: "delete",
      tests: [
        {
          name: "should join next blockquote to this one if at end of line",
          startContent: "<blockquote>ab|</blockquote><blockquote>cd</blockquote>",
          selectionResult: "<blockquote>ab|cd</blockquote>",
        },
        {
          name: "should join next block's content to this blockquote if at end of line",
          startContent: "<blockquote>ab|</blockquote><h1>cd</h1>",
          selectionResult: "<blockquote>ab|cd</blockquote>",
        },
        {
          name: "should not handle event if visible whitespace afterwards",
          startContent: "<blockquote>ab|&nbsp;</blockquote><blockquote>cd</blockquote>",
          domResult: "<blockquote>ab&nbsp;</blockquote><blockquote>cd</blockquote>",
          selectionResult: "<blockquote>ab|&nbsp;</blockquote><blockquote>cd</blockquote>",
        },
        {
          name: "should not handle event if content afterwards",
          startContent: "<blockquote>ab|cd</blockquote><blockquote>ef</blockquote>",
          domResult: "<blockquote>abcd</blockquote><blockquote>ef</blockquote>",
          selectionResult: "<blockquote>ab|cd</blockquote><blockquote>ef</blockquote>",
        },
        {
          name: "should not handle if just before style tag with more content after",
          startContent: "<blockquote>abc|<b>def</b></blockquote>",
          domResult: "<blockquote>abc<b>def</b></blockquote>",
          selectionResult: "<blockquote>abc|<b>def</b></blockquote>",
        },
        {
          name: "should not handle if at end of style tag with more content before",
          startContent: "<blockquote>abc<b>def|</b>ghi</blockquote>",
          domResult: "<blockquote>abc<b>def</b>ghi</blockquote>",
          selectionResult: "<blockquote>abc<b>def|</b>ghi</blockquote>",
        },
        {
          name: "should join list element onto blockquote correctly",
          startContent: "<blockquote>ab|</blockquote><ol><li>cd</li></ol>",
          selectionResult: "<blockquote>ab|cd</blockquote>",
        },
        {
          name: "should join only first list element onto blockquote if multiple",
          startContent: "<blockquote>ab|</blockquote><ol><li>cd</li><li>ef</li></ol>",
          selectionResult: "<blockquote>ab|cd</blockquote><ol><li>ef</li></ol>",
        },
        {
          name: "should join together similar style inline tags on joining",
          startContent: "<blockquote>a<b>b<i>c|</i></b></blockquote><blockquote><b><i>d</i>e</b>f</blockquote>",
          selectionResult: "<blockquote>a<b>b<i>c|d</i>e</b>f</blockquote>",
        },
      ],
    },
  };
}.call(this));
