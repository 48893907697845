"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.withUniqueId = void 0;
var lodash_1 = require("lodash");
var withUniqueId = function (editor) {
    var e = editor;
    e.uniqueId = (0, lodash_1.uniqueId)();
    return e;
};
exports.withUniqueId = withUniqueId;
