// Generated by CoffeeScript 1.12.7

/*
  Shows the user dynamically whether a given domain or subdomain
  is usable (aka available and not black-listed).
  @param domain [Object] of the form:
    `name` [String] the domain name to check
    `type` [String] either `subdomain` or `domain`
  @param validityChecker [Function] Checker which returns Boolean to indicate
    if the supplied string conforms to a valid domain/subdomain etc.
 */

(function () {
  var StatusChecker;

  module.exports = StatusChecker = (function () {
    function StatusChecker(createUi, validityChecker, domain, invoke) {
      var isUsable;
      isUsable = invoke(require("./IsUsable"), null, {
        domain: domain,
        setStatus: (function (_this) {
          return function (status) {
            return (_this.ui.checkStatus = status);
          };
        })(this),
        setError: (function (_this) {
          return function (errorMsg) {
            return (_this.ui.error = errorMsg);
          };
        })(this),
      });
      this.ui = createUi(require("./StatusChecker.html"), {
        checkStatus: "",
        error: "",
        domain: domain,
        showInvalid: (function (_this) {
          return function () {
            if (!domain.name.length) {
              return false;
            } else {
              return !validityChecker(domain.name);
            }
          };
        })(this),
      });
      this.updateStatus = (function (_this) {
        return function () {
          _this.ui.checkStatus = "checking";
          return isUsable(domain);
        };
      })(this);
      this.getStatus = (function (_this) {
        return function () {
          return _this.ui.checkStatus;
        };
      })(this);
    }

    return StatusChecker;
  })();
}.call(this));
