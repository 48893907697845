"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.schema = void 0;
var EditorTypes_1 = require("../EditorTypes");
exports.schema = {
    editor: {
        validChildren: [],
    },
    blocks: {},
    inlines: (_a = {},
        _a[EditorTypes_1.InlineType.LineBreak] = {
            isVoid: true,
        },
        _a[EditorTypes_1.InlineType.Variable] = {
            isVoid: true,
            marks: false,
        },
        _a[EditorTypes_1.InlineType.TokenWidget] = {
            isVoid: true,
        },
        _a),
    name: "quotePlanCardFeatureList",
};
