// Generated by CoffeeScript 1.12.7

/*
  A single tile of a stock video used by StockVideosFactory
  A lot of these functions are based on VideoPicker
 */

(function () {
  var StockVideoTile;

  module.exports = StockVideoTile = (function () {
    function StockVideoTile(createUi, $rootScope, $timeout, item, selectHook, playVideoHook) {
      var domElement, getDomElement;
      this.createUi = createUi;
      this.selectHook = selectHook;
      this.playVideoHook = playVideoHook;
      this.ui = this.createUi(require("./StockVideoTile.html"), {
        id: item.id,
        videoUrl: item.videoUrl,
        thumbnailUrl: item.thumbnailUrl,
        $showVideo: false,
        $state: null,
        selectVideo: (function (_this) {
          return function () {
            return _this.selectHook(item.id, item.videoUrl);
          };
        })(this),
        tryPlayVideo: (function (_this) {
          return function () {
            return _this.playVideoHook(_this);
          };
        })(this),
        showVideo: (function (_this) {
          return function (e) {
            _this.ui.$showVideo = true;
            _this.ui.$state = "loading";
            return $timeout(function () {
              return $(getDomElement()).on("canplay", function () {
                _this.ui.$state = "canplay";
                return $rootScope.$apply();
              });
            });
          };
        })(this),
      });
      domElement = void 0;

      /*
        Get the <video> element within the DOM of this tile
        The <video> does not exist until the video has been played
        (i.e this returns undefined until $showVideo is true)
       */
      getDomElement = (function (_this) {
        return function () {
          if (domElement == null) {
            domElement = $("#" + item.id)
              .find(".html5-video")
              .get(0);
          }
          return domElement;
        };
      })(this);
      this.play = function () {
        var ref;
        return (ref = getDomElement()) != null ? ref.play() : void 0;
      };
      this.pause = function () {
        var ref;
        return (ref = getDomElement()) != null ? ref.pause() : void 0;
      };
      this.isPlaying = (function (_this) {
        return function () {
          return getDomElement() != null && !getDomElement().paused;
        };
      })(this);
    }

    return StockVideoTile;
  })();
}.call(this));
