// Generated by CoffeeScript 1.12.7

/*

  This is the multi-vendor font selector. It is basically a simple container
  for subpanels that handle all the real work of selecting a font.

  When a panel is selected, its .activate() function is called, allowing it to
  lazy-load whatever data it needs to display.

  The panel is passed an onSelected function which is expected to return data
  in the qwilr font object format. That format is as follows:

   - friendlyName: [String] the display name of the font, ie "Comic Sans" [optional]
   - name: [String] the font family as used in the CSS font-family
   - typeface: [String] the category of the font (eg: "sans-serif")
   - vendor: [String] "Typekit", "Google" or "HostedWebfont"

  The font selector expects to be called with .show() and returns a promise
  that resolves when any sub-panel is selected and returns a font
 */

(function () {
  var FontSelector, FontSquare;

  FontSquare = require("Common/Utils/ImageImports").FontSquare;

  module.exports = FontSelector = (function () {
    function FontSelector(createUi, make, overlayStack, usingFeature, upseller, $q, imageLocation, $timeout, noop) {
      var options, selecting;
      options = {
        onSelected: (function (_this) {
          return function (font) {
            return _this.selectFont(font);
          };
        })(this),
      };
      selecting = null;
      this.selectFont = (function (_this) {
        return function (fontData) {
          selecting.resolve(fontData);
          return overlayStack.closeCurrent();
        };
      })(this);
      this.show = (function (_this) {
        return function () {
          var vendors;
          vendors = {
            google: {
              panel: make(require("./Google/Google"), {
                options: options,
              }),
              name: "Google Fonts",
              icon: imageLocation.image("GoogleFontsLogoIcon.jpg"),
            },
            typekit: {
              panel: make(require("./Typekit/Typekit"), {
                options: options,
              }),
              name: "Typekit",
              icon: imageLocation.image("TypeKitLogoIcon.jpg"),
              upsellFlag: "hosted-webfonts",
              upsellFeatureName: "Hosted Webfonts",
            },
            custom: {
              panel: make(require("./Custom/Custom"), {
                options: options,
              }),
              name: "Custom",
              icon: FontSquare,
              upsellFlag: "custom-webfonts",
              upsellFeatureName: "Custom Webfonts",
            },
          };
          _this.ui = createUi(require("./FontSelector.html"), {
            vendors: vendors,
            currentVendor: null,
            updateVendor: function (vendorName) {
              var ref, vendor;
              vendor = vendors[vendorName];
              if (vendor.upsellFlag != null && !usingFeature(vendor.upsellFlag)) {
                return upseller.upsell({
                  feature: vendor.upsellFlag,
                  featureLabel: vendor.upsellFeatureName,
                });
              } else {
                _this.ui.currentVendor = vendorName;
                return (ref = vendors[vendorName].panel) != null
                  ? typeof ref.activate === "function"
                    ? ref.activate()
                    : void 0
                  : void 0;
              }
            },
          });
          _this.ui.updateVendor("google");
          overlayStack.add({
            contentUi: _this.ui,
            options: {
              className: "font-selector-overlay mini-close-button",
            },
          });
          selecting = $q.defer();
          $timeout(noop);
          return selecting.promise;
        };
      })(this);
    }

    return FontSelector;
  })();
}.call(this));
