"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageLocation = void 0;
var stockImagesUrl = "https://d2q7nwt0fhbb30.cloudfront.net/";
var stockVideosUrl = "https://d219lb0su8m9bb.cloudfront.net/";
var AssetsDir = "/Assets/";
var imageLocation = function (vaultUrl) {
    var baseObject = {};
    var locations = {
        local: function (fileName) {
            return AssetsDir + fileName;
        },
        image: function (fileName) {
            return "".concat(AssetsDir, "Images/").concat(fileName);
        },
        stockImageLarge: function (filename) {
            return "".concat(stockImagesUrl, "large/").concat(filename);
        },
        stockImageSmall: function (filename) {
            return "".concat(stockImagesUrl, "small/").concat(filename);
        },
        stockVideo: function (filename) {
            return stockVideosUrl + filename;
        },
        vault: function (filename) {
            return "".concat(vaultUrl).concat(filename);
        },
        logo: {
            color: "".concat(AssetsDir, "Images/LogoColor.svg"),
            outlinesThick: "".concat(AssetsDir, "Images/LogoThick.svg"),
        },
    };
    for (var functionName in locations) {
        baseObject[functionName] = locations[functionName];
    }
    return baseObject;
};
exports.imageLocation = imageLocation;
