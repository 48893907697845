// Generated by CoffeeScript 1.12.7
(function () {
  var StyleEditorController, getAdvancedStyle;

  getAdvancedStyle = require("./GetAdvancedStyle");

  module.exports = StyleEditorController = (function () {
    function StyleEditorController(
      createUi,
      make,
      overlayStack,
      styleWorker,
      styleService,
      notifyUser,
      style,
      block,
      account,
      onSaveHook,
      placeholderBlockFactory,
      $log,
      $q,
    ) {
      var accountColors, newStyle, onCancel, onChange, onSave, styleEditorControls, ui;
      newStyle = _.cloneDeep(style);
      accountColors = account.settings.brand.colors;
      onChange = function () {
        return getAdvancedStyle(newStyle, accountColors)
          .then(function (advancedStyle) {
            newStyle.css = advancedStyle;
            return styleService
              .getCompiledCss(newStyle, accountColors, ".style-editor-example-block")
              .then(function (css) {
                return styleWorker.addToHead(css, "style-editor");
              });
          })
          ["catch"](function (error) {
            if (error.type === "Parse") {
              return $q.resolve();
            } else {
              return $q.reject(error);
            }
          });
      };
      onSave = function () {
        return styleService.getCompiledCss(newStyle, accountColors).then(function (css) {
          _.assign(style, newStyle);
          delete style.$newTemporaryStyle;
          block.settings.style = style.className;
          styleWorker.addToHead(css, style.className);
          overlayStack.closeCurrent();
          notifyUser.success("Style saved");
          return typeof onSaveHook === "function" ? onSaveHook(style) : void 0;
        });
      };
      onCancel = function () {
        _.remove(account.styles, {
          $newTemporaryStyle: true,
        });
        return overlayStack.closeCurrent();
      };
      styleEditorControls = make(require("./Controls/Controls"), {
        style: newStyle,
        onChange: onChange,
        onSave: onSave,
        onCancel: onCancel,
      });
      ui = createUi(require("./StyleEditor.html"), {
        cssBlockName: block.cssBlockName,
        className: style.className,
        styleEditorControls: styleEditorControls.ui,
        placeholderBlock: placeholderBlockFactory.create("project-block"),
      });
      overlayStack.add(ui);
      overlayStack.hideCloseButton();
      onChange();
      $log.log("Style:", style);
    }

    return StyleEditorController;
  })();
}.call(this));
