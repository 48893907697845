// Generated by CoffeeScript 1.12.7
(function () {
  var TrialStartModal;

  module.exports = TrialStartModal = (function () {
    function TrialStartModal(createUi, modalAngular, initSubscription, user) {
      var fromPlan, getLastOption, ref;
      fromPlan = (ref = user.useHistory.signup.entryPoint.metadata) != null ? ref.fromPlan : void 0;
      this.show = (function (_this) {
        return function () {
          if (fromPlan == null || (fromPlan !== "starter" && fromPlan !== "pro")) {
            return;
          }
          return modalAngular.show(_this.ui, {
            customClass: "trial-modal",
            showCloseCross: false,
            enableEscapeKey: false,
          });
        };
      })(this);
      getLastOption = function () {
        if (fromPlan === "starter") {
          return "switch over to our free plan";
        } else if (fromPlan === "pro") {
          return "move to our Pro plan";
        }
      };
      this.ui = createUi(require("./TrialStartModal.html"), {
        close: function () {
          return modalAngular.hide();
        },
        explorePlans: function () {
          return initSubscription.init();
        },
        lastOption: getLastOption(),
      });
    }

    return TrialStartModal;
  })();
}.call(this));
