// Generated by CoffeeScript 1.12.7

/*
  Because we handle backspacing similarly for both Paragraphs and Headings,
  we unify it here. Just provides a handler function, returning true if it
  handles the event and false otherwise

  NOTE: This is specifically for collapsed key events, not when we have a
  selection of text
 */

(function () {
  var h, invisibleWhitespace, nodesShouldStitch, stitchUpJoin;

  h = require("../Helpers");

  invisibleWhitespace = h.invisibleWhitespace;

  module.exports = function (editor, tag, e) {
    var joinPoint, keyType, leadingWhitespace, list, nextSibling, node, parentElement, prevSibling, trailingWhitespace;
    keyType = qed.keycodes.computeKeyType(e);
    if (keyType === qed.keycodes.types.DELETE && e.which === qed.keycodes.codes.BACKSPACE && !h.textToLeft(e.point)) {
      node = h.upToBlockElem(e.point);
      e.preventDefault();
      if (node.previousElementSibling == null || node.previousElementSibling.tagName.toLowerCase() === "insertable") {
        return true;
      }
      h.cleanToLeft(node);
      h.clean(node);
      h.clean(node.previousElementSibling);
      if (h.isTextNode(e.point.node)) {
        leadingWhitespace = new RegExp("^[" + invisibleWhitespace + "]+");
        e.point.node.textContent = e.point.node.textContent.replace(leadingWhitespace, "");
      }
      e.point = qed.Point.before(node);
      if (e.point.node.previousSibling != null && h.isList(e.point.node.previousSibling)) {
        prevSibling = e.point.node.previousSibling;
        if (e.point.node.nextSibling != null && h.isList(e.point.node.nextSibling)) {
          nextSibling = e.point.node.nextSibling;
          prevSibling.appendChild(e.point.node);
          if (nextSibling.tagName === prevSibling.tagName) {
            qed.Point.before(nextSibling).joinLeft();
          }
        } else {
          prevSibling.appendChild(e.point.node);
        }
      }
      e.point[h.joinDirection(e.point, "left")]();
      stitchUpJoin(e.point);
      h.ensureOpen(h.upToBlockElem(e.point));
      editor.selection().setCaret(e.point);
      return true;
    } else if (
      keyType === qed.keycodes.types.DELETE &&
      e.which === qed.keycodes.codes.DELETE &&
      !h.textToRight(e.point)
    ) {
      node = h.upToBlockElem(e.point);
      e.preventDefault();
      if (node.nextElementSibling == null || node.nextElementSibling.tagName.toLowerCase() === "insertable") {
        return true;
      }
      h.cleanToRight(node);
      h.clean(node);
      h.clean(node.nextElementSibling);
      joinPoint = qed.Point.after(node);
      if (h.isList(joinPoint.node.nextElementSibling)) {
        if (h.isList(joinPoint.node.previousElementSibling) && joinPoint.node.textContent === "") {
          if (joinPoint.node.nextElementSibling.tagName === joinPoint.node.previousElementSibling.tagName) {
            qed.Point.before(joinPoint.node.nextElementSibling).joinLeft();
          }
        } else {
          list = joinPoint.node.nextElementSibling;
          joinPoint.node.parentElement.insertBefore(list.firstChild, list);
          if (list.children.length === 0) {
            joinPoint.node.parentElement.removeChild(list);
          }
        }
      }
      if (h.isTextNode(e.point.node)) {
        trailingWhitespace = new RegExp("[" + invisibleWhitespace + "]+$");
        e.point.node.textContent = e.point.node.textContent.replace(trailingWhitespace, "");
      }
      joinPoint[h.joinDirection(joinPoint, "right")]();
      stitchUpJoin(joinPoint);
      editor.selection().setCaret(joinPoint);
      return true;
    } else if (
      keyType === qed.keycodes.types.DELETE &&
      !h.textToRight(e.point) &&
      !h.textToLeft(e.point) &&
      e.point.offset === 1
    ) {
      /* @TODO: This is causing a bug in Safari where the first element is deleted and a lonely <br>
      tag is left there
       */
      parentElement = e.point.node.parentElement;
      parentElement.innerHTML = "<br>";
      editor.selection().setCaret(qed.Point.start(parentElement));
      e.preventDefault();
      return true;
    } else {
      return false;
    }
  };

  stitchUpJoin = function (point) {
    if (point.type === "before") {
      while (point.type === "before" && nodesShouldStitch(point.node.previousSibling, point.node)) {
        point.joinRight();
      }
    } else if (point.type === "after") {
      while (point.type === "after" && nodesShouldStitch(point.node.nextSibling, point.node)) {
        point.joinLeft();
      }
    }
  };

  nodesShouldStitch = function (nodeA, nodeB) {
    if (!(nodeA != null && nodeB != null)) {
      return false;
    } else if (h.isBlock(nodeA)) {
      return false;
    } else if (nodeA.tagName === "A" && nodeA.getAttribute("href") !== nodeB.getAttribute("href")) {
      return false;
    } else if (nodeA.tagName === nodeB.tagName) {
      return true;
    } else {
      return false;
    }
  };
}.call(this));
