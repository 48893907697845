"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var ExponentialBackoff_1 = require("./ExponentialBackoff");
var ActionRequired;
(function (ActionRequired) {
    ActionRequired[ActionRequired["None"] = 0] = "None";
    ActionRequired[ActionRequired["Warn"] = 1] = "Warn";
    ActionRequired[ActionRequired["Retry"] = 2] = "Retry";
})(ActionRequired || (ActionRequired = {}));
var DEFAULT_MAX_RETRIES = 10;
var PersistLogic = (function () {
    function PersistLogic($http, options, persistenceTracker) {
        this.$http = $http;
        this.persistenceTracker = persistenceTracker;
        this.latestPersistVersion = 0;
        this.lastSuccessfulSave = 0;
        this.maxRetries = DEFAULT_MAX_RETRIES;
        this.onPersisted = options.onPersisted;
        this.expectedSuccessMsg = options.expectedSuccessMsg;
        if (options.maxRetries != null) {
            this.maxRetries = options.maxRetries;
        }
    }
    PersistLogic.prototype.makeHttpRequest = function (apiRoute, sendData) {
        return __awaiter(this, void 0, void 0, function () {
            var thisRequestVersion, done, i, actionRequired, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        ++this.latestPersistVersion;
                        thisRequestVersion = this.latestPersistVersion;
                        this.persistenceTracker.saveStarted(this);
                        done = false;
                        i = 0;
                        _b.label = 1;
                    case 1:
                        if (!(i <= this.maxRetries && !done)) return [3, 10];
                        return [4, this.attemptRequest(apiRoute, sendData, thisRequestVersion)];
                    case 2:
                        actionRequired = _b.sent();
                        _a = actionRequired;
                        switch (_a) {
                            case ActionRequired.None: return [3, 3];
                            case ActionRequired.Warn: return [3, 4];
                            case ActionRequired.Retry: return [3, 5];
                        }
                        return [3, 9];
                    case 3:
                        done = true;
                        return [3, 9];
                    case 4:
                        this.persistenceTracker.warning(this, "Experiencing slow or unstable internet connection");
                        done = true;
                        return [3, 9];
                    case 5:
                        if (!(i >= this.maxRetries)) return [3, 6];
                        this.persistenceTracker.saveErrored(this);
                        return [3, 8];
                    case 6:
                        this.persistenceTracker.saveErrored(this, "There was a problem saving your data, retrying...");
                        return [4, (0, ExponentialBackoff_1.exponentialBackoffPromise)(i)];
                    case 7:
                        _b.sent();
                        if (thisRequestVersion <= this.lastSuccessfulSave) {
                            done = true;
                        }
                        _b.label = 8;
                    case 8: return [3, 9];
                    case 9:
                        ++i;
                        return [3, 1];
                    case 10: return [2];
                }
            });
        });
    };
    PersistLogic.prototype.onDestroy = function () {
        this.persistenceTracker.persistDestroyed(this);
    };
    PersistLogic.prototype.attemptRequest = function (apiRoute, sendData, thisRequestVersion) {
        return __awaiter(this, void 0, Promise, function () {
            var response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.$http.post(apiRoute, sendData)];
                    case 1:
                        response = _a.sent();
                        return [3, 3];
                    case 2:
                        error_1 = _a.sent();
                        return [2, this.errorOnSave(thisRequestVersion)];
                    case 3:
                        if (this.expectedSuccessMsg != null && this.expectedSuccessMsg !== response.data) {
                            return [2, this.errorOnSave(thisRequestVersion)];
                        }
                        if (thisRequestVersion > this.lastSuccessfulSave) {
                            this.lastSuccessfulSave = thisRequestVersion;
                        }
                        if (thisRequestVersion === this.latestPersistVersion) {
                            this.persistenceTracker.saveDone(this);
                        }
                        if (this.onPersisted != null) {
                            this.onPersisted();
                        }
                        return [2, ActionRequired.None];
                }
            });
        });
    };
    PersistLogic.prototype.errorOnSave = function (requestVersion) {
        if (requestVersion > this.lastSuccessfulSave) {
            if (requestVersion === this.latestPersistVersion) {
                return ActionRequired.Retry;
            }
            else {
                return ActionRequired.Warn;
            }
        }
        return ActionRequired.None;
    };
    return PersistLogic;
}());
exports.default = PersistLogic;
