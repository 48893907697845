(function () {
  var bindCalculateAndUpdateScrollbarHeight;

  require("./app.ng.html");

  performance.mark("appCoffeeModule");

  bindCalculateAndUpdateScrollbarHeight = require("./Utils/CalculateHorizontalScrollbarHeight")
    .bindCalculateAndUpdateScrollbarHeight;

  module.exports = angular
    .module("qwilr", [
      "ngSanitize",
      "ngAnimate",
      "ngResource",
      "ngCookies",
      "ui.router",
      "ui.jq",
      "create-ui",
      "angular-inview",
      "infinite-scroll",
      "ngTouch",
      "mgcrea.ngStrap.tooltip",
      "mgcrea.ngStrap.datepicker",
      "ui.codemirror",
      "ngFileUpload",
      "afkl.lazyImage",
      "angular-spinkit",
      "duScroll",
      "ngDraggable",
      "monospaced.elastic",
      require("Common/Common").name,
      require("Project/Modules/Splash/Controls/Background/VideoDirective").name,
      require("AcceptButtonv2/Directive").name,
      require("Dashboard/DashboardState")["default"].name,
      require("CreatePageApp/CreatePageAppMount")["default"].name,
      require("Project/Project")["default"].name,
      require("Gatekeeper/GatekeeperMount")["default"].name,
      require("TemplatesGallery/TemplatesGallery")["default"].name,
      require("ProjectView/ProjectView").name,
      require("SettingsAngular/Settings").name,
      require("SettingsAngular/Subscription/CreditCard/AddCreditCard").name,
      require("SamplePageCreator/SamplePageCreator")["default"].name,
      require("EntryPoint/EntryPoint").name,
      require("Hubspot/Hubspot").name,
      require("CommonUI/Slider/Directive").name,
      require("CommonUI/Checkbox/Checkbox").name,
    ])
    .config(function ($httpProvider, $urlRouterProvider, $tooltipProvider, $sceDelegateProvider, $compileProvider) {
      $httpProvider.defaults.useXDomain = true;
      $httpProvider.defaults.withCredentials = true;
      $httpProvider.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
      angular.extend($tooltipProvider.defaults, {
        trigger: "hover",
        container: "body",
        animation: true,
        delay: {
          show: 350,
          hide: 0,
        },
      });
      $sceDelegateProvider.resourceUrlWhitelist(["self", "http://fake-cdn.dev/**"]);
      if (QwilrConfig.environment === "production") {
        return $compileProvider.debugInfoEnabled(false);
      }
    })
    .run(function ($templateCache) {
      return (window.$templateCache = $templateCache);
    })
    .filter("trustAsResourceUrl", function ($sce) {
      return function (val) {
        return $sce.trustAsResourceUrl(val);
      };
    })
    .filter("percentage", function ($filter) {
      return function (input, decimals) {
        return $filter("number")(input * 100, decimals) + "%";
      };
    })
    .controller("QwilrClientAppController", function ($scope, $injector, $rootScope, $http, $cookies) {
      var Injector, RandomTips, bootstrapper, di;
      Injector = require("hxroin");
      di = new Injector($injector);
      $http.defaults.headers.common["X-XSRF-TOKEN"] = function () {
        return $cookies.get("XSRF-TOKEN");
      };
      RandomTips = require("./Common/Bootstrapper/RandomTips")["default"];
      $scope.ui = {
        randomTips: di.make(RandomTips),
      };
      bootstrapper = di.make(require("Common/Bootstrapper/Bootstrapper"), {
        scopeUi: $scope.ui,
      });
      $rootScope.$on("$viewContentLoaded", function () {
        return window.scrollTo(0, 0);
      });
      return bindCalculateAndUpdateScrollbarHeight();
    });
}.call(this));
