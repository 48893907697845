"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (text) {
    var textArea = document.createElement("textarea");
    positionAndHideElement(textArea);
    document.body.appendChild(textArea);
    textArea.value = text;
    textArea.focus();
    textArea.select();
    try {
        if (document.createRange && window.getSelection) {
            textArea.contentEditable = "true";
            textArea.readOnly = false;
            var range = document.createRange();
            range.selectNodeContents(textArea);
            var selection = window.getSelection();
            if (selection != null) {
                selection.removeAllRanges();
                selection.addRange(range);
            }
            textArea.setSelectionRange(0, 999999);
        }
    }
    catch (_a) {
    }
    var success;
    try {
        success = document.execCommand("copy");
    }
    catch (_b) {
        success = false;
    }
    document.body.removeChild(textArea);
    return success;
});
function positionAndHideElement(element) {
    var x = window.scrollX || window.pageXOffset;
    var y = window.scrollY || window.pageYOffset;
    element.style.position = "fixed";
    element.style.top = "".concat(y, "px");
    element.style.left = "".concat(x, "px");
    element.style.zIndex = "-1";
    element.style.opacity = "0.01";
}
