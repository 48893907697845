"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeLinkWidget = exports.updateLinkWidget = void 0;
var slate_react_1 = require("slate-react");
var slate_1 = require("slate");
var updateLinkWidget = function (editor, path, href) {
    slate_react_1.ReactEditor.blur(editor);
    var updatedData = {
        data: {
            href: href,
        },
    };
    slate_1.Transforms.setNodes(editor, updatedData, { at: path });
};
exports.updateLinkWidget = updateLinkWidget;
var removeLinkWidget = function (editor, path) {
    slate_1.Transforms.unwrapNodes(editor, { at: path });
};
exports.removeLinkWidget = removeLinkWidget;
