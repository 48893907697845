import "Application/Library/AutomationLibrary/SyncAccepterResponses/MappingTable.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/AutomationLibrary/SyncAccepterResponses/MappingTable.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VTwW7iMBC98xVzqVQOrhIW2q177R/0A5BjmzBgPJE9AarV/vsqhk1MoahoV+opkue9zMyb9x7iI6KbFPBrBGAwNk69S1g4u38ZQfoKg8FqRvISNLl2419Gv0cPB97ka7xAu+65omBsEBUx00ZC2ewhkkMDWxXuhdDkqKt2oPm8rNzTalWWxTjr9+O2OQF2aHgpYTq7ywYIWC/5lv4AtWrkERYbpdHXYh8H0DqBGmUM+vojMMO58X+quaHmD70p4lFs6xTj1navqzYyLt6FJs/WswRtPduQKTpNih5lmh1k+s49bvfgLN+gLIrTFYqPV8vPtsq99Xj+n8znvLxsPeWw9gLZbuKg7hXh/81K58CLUevnfkpDD95QVSTXcvLGMQWinDRpE6ZGglZO38+KOxDXjpg6p7jIk+ygJt/jmoSqlF7XgVpvxAVCbMNCadtz6vEQ09sC+jfaymAbJUy7nXoZfiYZPhn4rdXaxmE/zk3xfI35ilFVzpqeusup6tQwlSO9vnbaLAr0mcBs93zW1eRdqwsu/gMR21hU5QUAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var wrapper = 's6iil20';
export var table = 's6iil21';
export var tableRow = 's6iil22';
export var leftColumn = 's6iil23';
export var rightColumn = 's6iil24';
export var rightHeaderRow = 's6iil25';
export var integrationName = 's6iil26';
export var rightArrow = _7a468({defaultClassName:'s6iil27',variantClassNames:{highlight:{true:'s6iil28',false:'s6iil29'}},defaultVariants:{},compoundVariants:[]});
export var emptyFormState = 's6iil2a';
export var tokenText = 's6iil2b';