// Generated by CoffeeScript 1.12.7
(function () {
  var GoogleAdwordsIntegration;

  module.exports = GoogleAdwordsIntegration = (function () {
    function GoogleAdwordsIntegration(createUi, genericIntegration) {
      genericIntegration.build.call(
        this,
        "googleAdwords",
        "Google Adwords Conversion ID",
        require("./GoogleAdwords.html"),
      );
    }

    return GoogleAdwordsIntegration;
  })();
}.call(this));
