// Generated by CoffeeScript 1.12.7
(function () {
  var PageHandler,
    _,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  _ = require("lodash");

  /*
    PageHandler exposes an API for adding and removing page tiles to the carousel,
    as well as switching flags that change the visual positioning of the tile.
    It performs safe inserts, by verifying against some validation before adding or removing pages.
  
    Each Carousel has a PageHandler
  
    @class PageHandler
   */

  module.exports = PageHandler = (function () {
    function PageHandler(carousel, validator) {
      this.carousel = carousel;
      this.validator = validator;
      this.setState = bind(this.setState, this);
      this.addPage = bind(this.addPage, this);
      this.setFlags = bind(this.setFlags, this);
      this.switchFlags = bind(this.switchFlags, this);
      this.updatePageTile = bind(this.updatePageTile, this);
      this.clear = bind(this.clear, this);
      this.createPage = bind(this.createPage, this);
      this.setupPrefetchedData = bind(this.setupPrefetchedData, this);
      this.tileFactory = this.carousel.tileFactory;
      this.validator = this.validator || {};
      this.setupPrefetchedData();
    }

    /*
      Populates page data based on pre-fetched data. This takes input data, transforms it using
      the Factory to generate Tiles, and chunks it into an array of arrays, with the size of each
      array being the page size.
    
      @api public
     */

    PageHandler.prototype.setupPrefetchedData = function () {
      if (this.carousel.data != null && this.carousel.data.length > 0) {
        this.clear();
        _.chunk(this.carousel.data, this.carousel.PAGE_SIZE).map(
          (function (_this) {
            return function (tiles) {
              return _this.createPage(tiles, true, false, false, false, false);
            };
          })(this),
        );
        this.setFlags(0);
        if (this.carousel.pages.length > 0) {
          this.carousel.pages[0].isFirst = true;
        }
        this.carousel.totalCount = this.carousel.data.length;
        return this.carousel.recalculatePageNumber();
      }
    };

    /*
      Used to create and add a page tile to the DOM
      We can preset some internal settings to tell the frontend how this page should be positioned,
      i.e. isNext will appear as the next page, isPrev will appear before the current page
      If both of these are false, and the page is active, it is the current visible page.
    
      @param items [Array] - the list of data items that should be transformed to a Tile
      @param active [Boolean] - whether or not the page should be active, i.e. rendered in HTML
      @param isFirst [Boolean] - whether the page is first in the list
      @param isNext [Boolean] - whether the page is next in the view
      @param isPrev [Boolean] - whether the page is previous in the view
      @param isAfterNext [Boolean] - whether the page is after the next one in the view
      @api private
     */

    PageHandler.prototype.createPage = function (items, active, isFirst, isNext, isPrev, isAfterNext) {
      var page;
      if (!items || items.length === 0) {
        return;
      }
      page = {
        tiles: this.tileFactory.createTiles(items),
        active: active,
        isFirst: isFirst,
        next: isNext,
        prev: isPrev,
        afterNext: isAfterNext,
      };
      this.carousel.pages.push(page);
      return page;
    };

    /*
      Clears all pages.
    
      @api public
     */

    PageHandler.prototype.clear = function () {
      return (this.carousel.pages = []);
    };

    /*
      Updates an individual page with new data. This is useful for "prerendering" a page, so that
      some loading state is visible, and populating the data after its loaded.
    
      The TileFactory is responsible for performing this update.
    
      @param pageTile [Object] - the pageTile object that is to be updated
      @param items [Array] - the new data that is used to update the existing page
      @api public
     */

    PageHandler.prototype.updatePageTile = function (pageTile, items) {
      this.tileFactory.updateTiles(pageTile, items);
      return pageTile;
    };

    /*
      Manipulates the flags directly on the tile. This sets the flags for positioning so that the
      page is displayed in the correct place in the view
    
      @param page [Object] - the page to manipulate
      @param active [Boolean] - whether or not the DOM should be rendered
      @param isNext [Boolean] - whether or not the tile is next in order
      @param isPrev [Boolean] - whether or not the tile is previous in order
      @param afterNext [Boolean] - whether or not the tile is after next
      @api private
     */

    PageHandler.prototype.switchFlags = function (page, active, isNext, isPrev, afterNext) {
      page.active = active;
      page.next = isNext;
      page.prev = isPrev;
      return (page.afterNext = afterNext);
    };

    /*
      Sets the appropriate flags on the tile pages for CSS styling purposes.
      [ Previous Tile ] <-> [ Current Tile ] <-> [ Next tile ] <-> [ After next tile ]
    
      We wish to remove any pages outside of this "sliding window" for performance
      reasons.
    
      @param index [Number] - the position of the current tile in the sequence
      @api private
     */

    PageHandler.prototype.setFlags = function (currentIndex) {
      var i, index, len, page, ref, results;
      ref = this.carousel.pages;
      results = [];
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        page = ref[index];
        if (index === currentIndex) {
          results.push(this.switchFlags(page, true, false, false, false));
        } else if (index === currentIndex - 1) {
          results.push(this.switchFlags(page, true, false, true, false));
        } else if (index === currentIndex + 1) {
          results.push(this.switchFlags(page, true, true, false, false));
        } else if (index === currentIndex + 2) {
          results.push(this.switchFlags(page, true, false, false, true));
        } else {
          results.push(this.switchFlags(page, false, false, false, false));
        }
      }
      return results;
    };

    /*
      Adds a new page to the carousel
    
      @param data [Array] - the list of data objects that are to be added
      @param opts [Object] - (Optional) flags that can be passed in to set the position of the page
      @api public
     */

    PageHandler.prototype.addPage = function (data, opts) {
      var active, isAfterNext, isFirst, isNext, isPrev;
      if (opts == null) {
        opts = {};
      }
      active = opts.active || false;
      isFirst = opts.isFirst || false;
      isNext = opts.isNext || false;
      isPrev = opts.isPrev || false;
      isAfterNext = opts.isAfterNext || false;
      return this.createPage(data, active, isFirst, isNext, isPrev, isAfterNext);
    };

    /*
      Sets the state of the Carousel, given a query for the Tile to select it will adjust the Carousel
      to be positioned on the page of where the Tile is located
    
      @param query [Function] - a query to find the Tile to show
     */

    PageHandler.prototype.setState = function (query) {
      var page, pageIndex, tileIndex;
      tileIndex = -1;
      pageIndex = _.findIndex(this.carousel.pages, function (page) {
        tileIndex = _.findIndex(page.tiles, query);
        return tileIndex >= 0;
      });
      if (pageIndex >= 0 && tileIndex >= 0) {
        page = this.carousel.pages[pageIndex];
        this.carousel.pageIndex = pageIndex;
        this.setFlags(pageIndex);
        return {
          item: page.tiles[tileIndex],
          pageIndex: pageIndex,
          index: tileIndex,
          isFirst: page.isFirst,
          next: page.next,
          prev: page.prev,
        };
      }
    };

    return PageHandler;
  })();
}.call(this));
