// Generated by CoffeeScript 1.12.7

/*
  Sets up the selection Toolbar for Qwilr's Editor
  buttonNames is a list of requested buttons, can be ["*"] to choose all
  or a selection from
  ["bold", "italic", "orderedList", "unorderedList", "h1", "h2", "quote", "link"]
 */

(function () {
  var h,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  h = require("./Helpers");

  module.exports = function (editor, undo, buttonNames) {
    var InlineDecorator,
      Point,
      applyInline,
      blockAllMatchTags,
      blockChange,
      blockToggler,
      button,
      buttons,
      getBlockElem,
      getSelectedLeafBlocks,
      i,
      inlineCheck,
      leafBlocks,
      len,
      removeNode,
      swapTag,
      toolbar,
      util,
      wrap;
    toolbar = new qed.Toolbar(editor);
    InlineDecorator = qed.InlineDecorator;
    Point = qed.Point;
    util = qed.util;

    /*
      Inline Methods
     */
    inlineCheck = function (tagDetails, editor) {
      var i, iDec, len, styleName, styleVal, styleVals, styles;
      iDec = new InlineDecorator();
      styles = iDec.getRangeAttributes(editor.selection().getRange());
      styleName = tagDetails.style.styleName;
      styleVals = tagDetails.style.acceptableStyleVals;
      if (styles[styleName] != null) {
        for (i = 0, len = styleVals.length; i < len; i++) {
          styleVal = styleVals[i];
          if (_.includes(styles[styleName], styleVal)) {
            return true;
          }
        }
      }
      return false;
    };
    applyInline = function (elem, editor, checkVal) {
      return document.execCommand(elem.style.styleVal, false, null);
    };

    /*
      Block Methods
     */
    getBlockElem = function (point) {
      var node;
      node = point.node;
      if (node.nodeType === 3) {
        node = node.parentElement;
      }
      while (!h.isBlockLevelElem(node)) {
        node = node.parentElement;
      }
      return node;
    };
    blockAllMatchTags = function (tagStack, editor) {
      var cur, elem, elems, i, j, len, len1, range, tag, topDownTagStack;
      topDownTagStack = tagStack.slice();
      topDownTagStack.reverse();
      range = editor.selection().getRange().order();
      elems = getSelectedLeafBlocks(editor);
      for (i = 0, len = elems.length; i < len; i++) {
        elem = elems[i];
        cur = elem;
        for (j = 0, len1 = topDownTagStack.length; j < len1; j++) {
          tag = topDownTagStack[j];
          if (cur.tagName !== tag) {
            return false;
          }
          cur = cur.parentNode;
        }
      }
      return true;
    };
    swapTag = function (tag, elem) {
      var replacement;
      if (elem.tagName === tag) {
        return;
      }
      replacement = document.createElement(tag);
      elem.parentElement.insertBefore(replacement, elem);
      while (elem.childNodes.length > 0) {
        replacement.appendChild(elem.firstChild);
      }
      return replacement.parentElement.removeChild(elem);
    };
    wrap = function (tag, elem) {
      var wrapper;
      wrapper = document.createElement(tag);
      elem.parentElement.insertBefore(wrapper, elem);
      wrapper.appendChild(elem);
      return wrapper;
    };
    leafBlocks = ["P", "OL"];
    removeNode = function (node) {
      if (node.parentNode) {
        return node.parentNode.removeChild(node);
      }
    };
    getSelectedLeafBlocks = function (editor) {
      var range;
      range = editor.selection().getRange().order();
      return h.getLeafBlocksInRange(editor, range);
    };
    blockChange = function (tagStack, editor) {
      var buildStackFromContents, container, elem, elemStack, elems, i, len, next, range, sliced, zipWith;
      editor.selection().saveToMarkers();
      buildStackFromContents = function (tags, elem) {
        var first, i, last, len, tag;
        last = null;
        first = null;
        for (i = 0, len = tags.length; i < len; i++) {
          tag = tags[i];
          last = wrap(tag, elem);
          if (!first) {
            first = last;
          }
        }
        last.removeChild(elem);
        while (elem.childNodes.length > 0) {
          last.appendChild(elem.firstChild);
        }
        return first;
      };
      zipWith = function (depth, elem) {
        var nextElem;
        while (depth > 0) {
          nextElem = elem.firstChild;
          h.assert(nextElem === elem.lastChild, "tried to zip leaf");
          if (elem.previousSibling && elem.previousSibling.tagName === elem.tagName) {
            Point.before(elem).joinRight();
          }
          if (elem.nextSibling && elem.nextSibling.tagName === elem.tagName) {
            Point.after(elem).joinLeft();
          }
          elem = nextElem;
          depth = depth - 1;
        }
      };
      range = editor.selection().getRange().order();
      elems = getSelectedLeafBlocks(editor);
      container = editor.currentElem();
      for (i = 0, len = elems.length; i < len; i++) {
        elem = elems[i];
        if (!util.isOrHasChild(container, elem)) {
          console.warn("Skipping junk element", elem);
          continue;
        }
        sliced = h.sliceToTop(editor, elem);
        next = sliced.nextSibling;
        h.assert(sliced.parentNode === container);
        elemStack = buildStackFromContents(tagStack, elem);
        container.insertBefore(elemStack, next);
        removeNode(sliced);
        zipWith(tagStack.length - 1, elemStack);
      }
      editor.selection().loadFromMarkers();
    };
    blockToggler = function (tagStack) {
      return function (editor, tagsMatch) {
        if (tagsMatch) {
          return blockChange(["P"], editor);
        } else {
          return blockChange(tagStack, editor);
        }
      };
    };

    /*
      Actually adding toolbar buttons
     */
    buttons = [
      {
        label: '<span style="font-weight: bold;">B</span>',
        name: "bold",
        type: "inline",
        style: {
          styleName: "font-weight",
          styleVal: "bold",
          acceptableStyleVals: ["bold", "700"],
        },
      },
      {
        label: '<span style="font-style: italic; font-family: serif;">I</span>',
        type: "inline",
        name: "italic",
        style: {
          styleName: "font-style",
          styleVal: "italic",
          acceptableStyleVals: ["italic"],
        },
      },
      {
        label: '<span style="font-weight: bold;">H1</span>',
        name: "h1",
        type: "block",
        check: _.curry(blockAllMatchTags)(["H1"]),
        toggle: blockToggler(["H1"]),
      },
      {
        label: '<span style="font-weight: bold;">H2</span>',
        type: "block",
        name: "h2",
        check: _.curry(blockAllMatchTags)(["H2"]),
        toggle: blockToggler(["H2"]),
      },
      {
        label: '<span style="font-size: 22pt; font-weight: bold; line-height: 30px;">&ldquo;</span>',
        type: "block",
        name: "quote",
        check: _.curry(blockAllMatchTags)(["BLOCKQUOTE"]),
        toggle: blockToggler(["BLOCKQUOTE"]),
      },
      {
        label: '<span class="icon icon-list"></span>',
        type: "block",
        name: "unorderedList",
        check: _.curry(blockAllMatchTags)(["UL", "LI"]),
        toggle: blockToggler(["UL", "LI"]),
      },
      {
        label: '<span style="font-weight: bold;">1.</span>',
        type: "block",
        name: "orderedList",
        check: _.curry(blockAllMatchTags)(["OL", "LI"]),
        toggle: blockToggler(["OL", "LI"]),
      },
    ];
    buttons = _.filter(buttons, function (b) {
      var ref;
      return indexOf.call(buttonNames, "*") >= 0 || ((ref = b.name), indexOf.call(buttonNames, ref) >= 0);
    });
    for (i = 0, len = buttons.length; i < len; i++) {
      button = buttons[i];
      if (button.type === "inline") {
        toolbar.addButton(button.label, _.curry(inlineCheck)(button), _.curry(applyInline)(button));
      } else {
        toolbar.addButton(button.label, button.check, button.toggle);
      }
    }
    if (indexOf.call(buttonNames, "*") >= 0 || indexOf.call(buttonNames, "link") >= 0) {
      toolbar.addDefaultLinkButton('<span class="icon-links"></span>');
    }
    return toolbar;
  };
}.call(this));
