// Generated by CoffeeScript 1.12.7
(function () {
  var ItemSelect, SelectTemplate, emptyItem;

  SelectTemplate = require("./ItemSelect.html");

  emptyItem = {
    name: "Not selected",
    id: "NO_ID",
  };

  module.exports = ItemSelect = (function () {
    function ItemSelect(createUi, title, getItems, getSelected, setSelected) {
      this.selectedItem = getSelected();
      this.menuVisible = false;
      this.getSelectedItem = (function (_this) {
        return function () {
          if (_this.selectedItem != null) {
            return _this.selectedItem;
          } else {
            return emptyItem;
          }
        };
      })(this);
      this.ui = createUi(SelectTemplate, {
        getTitle: function () {
          return title;
        },
        menuIsVisible: (function (_this) {
          return function () {
            return _this.menuVisible;
          };
        })(this),
        toggleMenu: (function (_this) {
          return function () {
            return (_this.menuVisible = !_this.menuVisible);
          };
        })(this),
        select: (function (_this) {
          return function (item) {
            _this.selectedItem = item;
            return setSelected(item);
          };
        })(this),
        isSelected: (function (_this) {
          return function (item) {
            return _this.getSelectedItem().id === item.id;
          };
        })(this),
        items: [],
        selectedItem: (function (_this) {
          return function () {
            return _this.getSelectedItem();
          };
        })(this),
        $init: (function (_this) {
          return function () {
            return getItems()
              .then(function (items) {
                _this.ui.items = items;
                if (!_this.selectedItem && _.first(items) != null) {
                  return _this.ui.select(_.first(items));
                }
              })
              ["catch"](function (response) {
                _this.ui.items = [];
                return console.error("Bad response ", response);
              });
          };
        })(this),
      });
    }

    return ItemSelect;
  })();
}.call(this));
