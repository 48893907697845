// Generated by CoffeeScript 1.12.7

/*
  This component handles file uploads for custom fonts
 */

(function () {
  var CustomFontUploader;

  module.exports = CustomFontUploader = (function () {
    function CustomFontUploader(createUi, make, onUploaded, showAcknowledgeModal, Upload, apiBaseUrl) {
      var url;
      url = apiBaseUrl + "/upload/fonts";
      this.ui = createUi(require("./Uploader.html"), {
        file: null,
        uploading: false,
        data: {},
        labelText: (function (_this) {
          return function () {
            if (_this.ui.uploading) {
              return "Uploading...";
            } else {
              return "Upload";
            }
          };
        })(this),
        failed: (function (_this) {
          return function (err) {
            var ref, ref1;
            _this.ui.uploading = false;
            if (
              err != null &&
              err.status === 400 &&
              ((ref = err.data) != null ? ((ref1 = ref.error) != null ? ref1.message : void 0) : void 0) != null
            ) {
              return showAcknowledgeModal({
                title: "Whoops, something broke.",
                explainer: err.data.error.message,
              });
            } else {
              return showAcknowledgeModal({
                title: "Whoops, something broke.",
                explainer: "Please try uploading again, if the problem persists contact help@qwilr.com",
              });
            }
          };
        })(this),
        invalidType: (function (_this) {
          return function () {
            _this.ui.uploading = false;
            showAcknowledgeModal({
              title: "Is that a font?",
              explainer: "We only support font files in .ttf format",
            });
            return _this.ui.clearFile();
          };
        })(this),
        setFile: (function (_this) {
          return function (file) {
            _this.ui.file = file;
            return (_this.ui.data.name = file.name.replace(/\.[^.]*$/, ""));
          };
        })(this),
        clearFile: (function (_this) {
          return function () {
            return (_this.ui.file = null);
          };
        })(this),
        upload: (function (_this) {
          return function () {
            var data, file, ref;
            if (_this.ui.uploading) {
              return;
            }
            if (!_this.ui.file) {
              return;
            }
            if (_this.ui.file.name.slice(-4).toLowerCase() !== ".ttf") {
              return _this.ui.invalidType();
            }
            (ref = _this.ui), (file = ref.file), (data = ref.data);
            _this.ui.uploading = true;
            return Upload.upload({
              file: file,
              url: url,
              data: data,
            })
              .then(function (fileData) {
                _this.ui.uploading = false;
                if (typeof onUploaded === "function") {
                  onUploaded(fileData);
                }
                return delete _this.ui.file;
              })
              ["catch"](function (err) {
                _this.ui.failed(err);
                return delete _this.ui.file;
              });
          };
        })(this),
      });
    }

    return CustomFontUploader;
  })();
}.call(this));
