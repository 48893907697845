// Generated by CoffeeScript 1.12.7
(function () {
  var EditorModel,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = EditorModel = (function () {
    function EditorModel(model, editor) {
      this.updateWidget = bind(this.updateWidget, this);
      this.getContent = bind(this.getContent, this);
      this.updateHtml = bind(this.updateHtml, this);
      this.getHtml = bind(this.getHtml, this);
      this.isEquivalentToWidgetState = bind(this.isEquivalentToWidgetState, this);
      this.updateWidgets = bind(this.updateWidgets, this);
      this.getWidgets = bind(this.getWidgets, this);
      this.model = model;
      this.editor = editor;
    }

    EditorModel.prototype.isEditorModelClass = true;

    EditorModel.prototype.getWidgets = function () {
      if (this.model.widgets == null) {
        this.model.widgets = {};
      }
      return this.model.widgets;
    };

    EditorModel.prototype.updateWidgets = function (widgets) {
      var id, ref, widget;
      ref = this.model.widgets;
      for (id in ref) {
        widget = ref[id];
        if (!widgets[id]) {
          delete this.model.widgets[id];
        }
      }
      for (id in widgets) {
        widget = widgets[id];
        if (this.model.widgets[id] != null) {
          this.updateWidget(this.model.widgets[id], widget);
        } else {
          this.model.widgets[id] = widget;
        }
      }
      if (this.editor.reinitialiseWidgets != null) {
        return this.editor.reinitialiseWidgets(this.model.widgets);
      }
    };

    EditorModel.prototype.isEquivalentToWidgetState = function (widgets) {
      var areEquivalent, clearCursorPosition;
      clearCursorPosition = function (content) {
        return content.replace('<span data-marker="start"></span>', "").replace('<span data-marker="end"></span>', "");
      };
      areEquivalent = function (obj1, obj2) {
        var i, key, keys1, keys2, len;
        keys1 = _.keys(obj1);
        keys2 = _.keys(obj2);
        if (keys1.length !== keys2.length) {
          return false;
        }
        for (i = 0, len = keys1.length; i < len; i++) {
          key = keys1[i];
          if (_.includes(keys2, key)) {
            if (_.isPlainObject(obj1[key]) || _.isArray(obj1[key])) {
              if (!areEquivalent(obj1[key], obj2[key])) {
                return false;
              }
            } else if (_.isString(obj1[key]) && _.isString(obj2[key])) {
              if (!_.isEqual(clearCursorPosition(obj1[key]), clearCursorPosition(obj2[key]))) {
                return false;
              }
            } else {
              if (!_.isEqual(obj1[key], obj2[key])) {
                return false;
              }
            }
          } else {
            return false;
          }
        }
        return true;
      };
      return areEquivalent(widgets, this.model.widgets);
    };

    EditorModel.prototype.getHtml = function () {
      if (this.model.content != null) {
        return this.model.content;
      } else {
        return this.model;
      }
    };

    EditorModel.prototype.updateHtml = function (content) {
      return (this.model.content = content);
    };

    EditorModel.prototype.getContent = function () {
      return this.model;
    };

    EditorModel.prototype.updateWidget = function (currModel, newModel) {
      var i, j, key, keys, len, len1;
      keys = _.keys(currModel);
      for (i = 0, len = keys.length; i < len; i++) {
        key = keys[i];
        if (newModel[key] == null) {
          if (!_.isArray(currModel)) {
            delete currModel[key];
          } else {
            currModel.splice(key, currModel.length - key);
            break;
          }
        }
      }
      keys = _.keys(newModel);
      for (j = 0, len1 = keys.length; j < len1; j++) {
        key = keys[j];
        if (_.isPlainObject(currModel[key]) || _.isArray(currModel[key])) {
          this.updateWidget(currModel[key], newModel[key]);
        } else {
          currModel[key] = newModel[key];
        }
      }
      return currModel;
    };

    return EditorModel;
  })();
}.call(this));
