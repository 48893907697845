"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createComment = void 0;
var mobx_1 = require("mobx");
var createComment = function (commentThreadStore, startBlockElement, onCommentIdFinalized) {
    var commentStore = commentThreadStore.create(startBlockElement);
    var id = commentStore.id;
    (0, mobx_1.autorun)(function (reaction) {
        if (commentStore.id != id) {
            onCommentIdFinalized(commentStore.id);
            reaction.dispose();
        }
    });
    return id;
};
exports.createComment = createComment;
