"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Deserialise_1 = require("./Tokenization/Deserialise");
var Loader = (function () {
    function Loader() {
    }
    Loader.prototype.loadContent = function (tokens) {
        if (this.loadSlate != null) {
            var slateDoc = (0, Deserialise_1.deserialise)(tokens);
            this.loadSlate(slateDoc);
        }
        else {
            console.error("SlateEditor hasn't been attached");
        }
    };
    return Loader;
}());
exports.default = Loader;
