// Generated by CoffeeScript 1.12.7

/*
 * UI controls for Pipedrive integration settings
 */

(function () {
  var PipedriveIntegration,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = PipedriveIntegration = (function () {
    function PipedriveIntegration(make, createUi, $http, apiBaseUrl, account) {
      this.apiBaseUrl = apiBaseUrl;
      this.disable = bind(this.disable, this);
      this.enable = bind(this.enable, this);
      this.http = $http;
      this.account = account;
      this.enableUi = make(require("./PipedriveEnable"), {
        onSubmit: this.enable,
      });
      this.enablingUi = this.enableUi.ui;
      this.ui = createUi(require("SettingsAngular/Integrations/Pipedrive/Pipedrive.html"), {});
    }

    PipedriveIntegration.prototype.enable = function () {
      return this.enableUi.show().then(
        (function (_this) {
          return function (apiKey) {
            return _this.http
              .post(_this.apiBaseUrl + "/integration/pipedrive/enable", {
                apiKey: apiKey,
              })
              .then(function () {
                var base;
                if ((base = _this.account.integrations).pipedrive == null) {
                  base.pipedrive = {};
                }
                return (_this.account.integrations.pipedrive.enabled = true);
              });
          };
        })(this),
      );
    };

    PipedriveIntegration.prototype.disable = function () {
      return this.http.post(this.apiBaseUrl + "/integration/pipedrive/disable").then(
        (function (_this) {
          return function () {
            return (_this.account.integrations.pipedrive.enabled = false);
          };
        })(this),
      );
    };

    return PipedriveIntegration;
  })();
}.call(this));
