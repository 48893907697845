"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var maker = function (constructor, extraArgs) {
    if (extraArgs === void 0) { extraArgs = {}; }
    return function (make) { return make(constructor, extraArgs); };
};
module.exports = {
    savedLineItemsLibrary: maker(require("./SavedLineItems/SavedLineItemsLibrary").default),
    quoteAdapter: maker(require("QuoteV2/Adapter/Adapter")),
    quoteContent: maker(require("QuoteV2/View/QuoteContent")),
    quoteCurrencyFormatter: maker(require("Utils/QuoteCurrencyFormatter").default),
    currencyLocaleProvider: function (projectStore) {
        return projectStore;
    },
    isEditor: function (projectStore) {
        return projectStore.isEditable;
    },
    disableInteractives: false,
    clientViewComponents: {
        VariableRateInput: require("./ItemComponents/VariableRateInputContainer").default,
        InsertItem: require("./Buttons/InsertItem"),
        ItemSettings: require("./Buttons/ItemSettings"),
        TotalsSettings: require("./Buttons/TotalsSettings"),
        SectionSettings: require("./Buttons/SectionSettings"),
        LineItemButtons: require("./Buttons/LineItemButtons/LineItemButtons").default,
        MoveUpDown: require("./SettingsMenus/MoveUpDown"),
    },
};
