// Generated by CoffeeScript 1.12.7

/*
  Event handler for determining when to trigger undo and redo events
 */

(function () {
  var UndoKeyHandler;

  module.exports = UndoKeyHandler = (function () {
    function UndoKeyHandler(undo, $timeout) {
      /*
       * We listen to key events and undo or redo as appropriate
       */
      var isRedo, isUndo;
      this.onKey = function (e) {
        if (isUndo(e)) {
          e.preventDefault();
          $timeout(function () {
            return undo.undo();
          });
          return true;
        } else if (isRedo(e)) {
          e.preventDefault();
          $timeout(function () {
            return undo.redo();
          });
          return true;
        } else {
          return false;
        }
      };
      this.onContent = function (e) {
        undo.checkpoint();
        return false;
      };

      /*
       * There are a couple of ways an Undo or Redo can be triggered, and none of
       * them are sent as an event by the browser. So we check the keys as the only
       * recourse.
       */
      isUndo = (function (_this) {
        return function (e) {
          var justCtrlOrMetaModifier;
          justCtrlOrMetaModifier = function (e) {
            if (e.altKey || e.shiftKey) {
              return false;
            } else if ((e.ctrlKey && !e.metaKey) || (e.metaKey && !e.ctrlKey)) {
              return true;
            }
          };
          if (e.which === 90 && justCtrlOrMetaModifier(e)) {
            return true;
          } else {
            return false;
          }
        };
      })(this);
      isRedo = (function (_this) {
        return function (e) {
          var shiftCtrlOrMetaCombo;
          shiftCtrlOrMetaCombo = function (e) {
            if (e.altKey) {
              return false;
            } else if (e.shiftKey && ((e.ctrlKey && !e.metaKey) || (e.metaKey && !e.ctrlKey))) {
              return true;
            }
          };
          if (e.which === 90 && shiftCtrlOrMetaCombo(e)) {
            return true;
          } else {
            return false;
          }
        };
      })(this);
    }

    return UndoKeyHandler;
  })();
}.call(this));
