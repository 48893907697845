"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SeatsService = (function () {
    function SeatsService(account, userList) {
        this.account = account;
        this.userList = userList;
    }
    SeatsService.prototype.maxSeats = function () {
        if (this.account.plan.seats.max === 0) {
            return Infinity;
        }
        else {
            return this.account.plan.seats.max;
        }
    };
    SeatsService.prototype.minSeats = function () {
        return this.account.minimumSeats || this.account.plan.seats.min || 0;
    };
    SeatsService.prototype.seatsOnPlan = function () {
        return this.account.plan.seats.included;
    };
    SeatsService.prototype.additionalSeats = function () {
        return this.account.additionalSeats;
    };
    SeatsService.prototype.seatsOnAccount = function () {
        return this.account.plan.seats.included + this.account.additionalSeats;
    };
    SeatsService.prototype.currentlyOccupied = function () {
        return this.userList.length + this.account.pendingInvitations.length;
    };
    SeatsService.prototype.remainingSeats = function () {
        return this.seatsOnAccount() - this.currentlyOccupied();
    };
    SeatsService.prototype.canModifySeats = function () {
        var plan = this.account.plan;
        var maxSeat = plan.seats.max || Infinity;
        var isModifiableTrial = plan.displayName === "Trial" && maxSeat > plan.seats.included;
        return isModifiableTrial || (!!this.account.stripe && !!this.account.stripe.customerToken);
    };
    SeatsService.prototype.canAddSeats = function (extraSeats) {
        if (extraSeats === void 0) { extraSeats = 0; }
        return this.maxSeats() - (this.seatsOnAccount() + extraSeats) >= 0;
    };
    SeatsService.prototype.canRemoveSeats = function (seatsToRemove) {
        if (seatsToRemove === void 0) { seatsToRemove = 0; }
        return this.seatsOnAccount() - seatsToRemove >= this.minSeats() && seatsToRemove <= this.additionalSeats();
    };
    SeatsService.prototype.seatPrice = function () {
        return this.account.plan.seats.price;
    };
    SeatsService.prototype.timeUnit = function () {
        return this.account.plan.unit;
    };
    SeatsService.prototype.isPerSeatPricing = function () {
        return this.seatsOnPlan() === 1;
    };
    return SeatsService;
}());
exports.default = SeatsService;
