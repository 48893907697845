"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkEngagementPopover = void 0;
var react_1 = __importStar(require("react"));
var inversify_react_1 = require("inversify-react");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var UseEngagementAlgorithms_1 = require("Library/EngagementLibrary/UseEngagementAlgorithms");
var UseAnalyticsAccountSettings_1 = require("SettingsAngular/Reports/UseAnalyticsAccountSettings");
var Engagement_1 = require("Common/Engagement");
var Types_1 = require("@CommonFrontendBackend/Projects/Types");
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var BulkEngagementPopover = function (_a) {
    var selectedPages = _a.selectedPages, isAdmin = _a.isAdmin, iconButtonProps = _a.iconButtonProps;
    var analyticsAccountSettings = (0, UseAnalyticsAccountSettings_1.useAnalyticsAccountSettings)().data;
    var canAssignAlgorithms = isAdmin || (analyticsAccountSettings === null || analyticsAccountSettings === void 0 ? void 0 : analyticsAccountSettings.engagement.allowCreatorsToAssignPages);
    return (react_1.default.createElement(react_1.default.Fragment, null, canAssignAlgorithms && (react_1.default.createElement(kaleidoscope_1.Popover, { button: function (buttonProps) { return react_1.default.createElement(kaleidoscope_1.IconButton, __assign({}, buttonProps, iconButtonProps)); }, caret: true, autoStack: true, position: kaleidoscope_1.PopoverPosition.Bottom },
        react_1.default.createElement(Content, { selectedPages: selectedPages })))));
};
exports.BulkEngagementPopover = BulkEngagementPopover;
var Content = function (_a) {
    var e_1, _b;
    var selectedPages = _a.selectedPages;
    var modalService = (0, inversify_react_1.useInjection)("modal");
    var serverTelemetry = (0, inversify_react_1.useInjection)("serverTelemetry");
    var notifyUser = (0, inversify_react_1.useInjection)("notifyUser");
    var algorithms = (0, UseEngagementAlgorithms_1.useEngagementAlgorithms)().algorithms;
    var _c = (0, Engagement_1.useBulkAlgorithmAssignment)(), assignAlgorithmToPages = _c.assignAlgorithmToPages, unassignAlgorithmsFromPages = _c.unassignAlgorithmsFromPages;
    var _d = __read((0, react_1.useState)(false), 2), isAlgorithmUpdating = _d[0], setIsAlgorithmUpdating = _d[1];
    var _e = __read((0, react_1.useState)(undefined), 2), selectedAlgorithm = _e[0], setSelectedAlgorithm = _e[1];
    var assignable = [];
    var unassignableCount = 0;
    try {
        for (var selectedPages_1 = __values(selectedPages), selectedPages_1_1 = selectedPages_1.next(); !selectedPages_1_1.done; selectedPages_1_1 = selectedPages_1.next()) {
            var p = selectedPages_1_1.value;
            if (p.isBlueprint || p.isTemplate) {
                ++unassignableCount;
            }
            else {
                assignable.push(p);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (selectedPages_1_1 && !selectedPages_1_1.done && (_b = selectedPages_1.return)) _b.call(selectedPages_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    var hasNonLivePages = assignable.some(function (_a) {
        var status = _a.status;
        return status !== Types_1.ProjectStatus.Live;
    });
    var assignableIds = assignable.map(function (_a) {
        var _id = _a._id;
        return _id;
    });
    (0, react_1.useEffect)(function () {
        serverTelemetry.sendPageEngagementPopoverViewedTelemetry({
            eventProperties: {},
            eventOrigin: event_tracker_types_1.EventOrigin.BulkAction,
        });
    }, []);
    function onAssign() {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsAlgorithmUpdating(true);
                        return [4, modalService.showConfirmModal({
                                title: "Bulk assign algorithm?",
                                explainer: "This will override any algorithms currently assigned to the selected pages, and trigger a recalculation of engagement levels",
                                confirming: function () { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!(selectedAlgorithm == null)) return [3, 2];
                                                return [4, unassignAlgorithmsFromPages(assignableIds)];
                                            case 1:
                                                _a.sent();
                                                return [3, 4];
                                            case 2: return [4, assignAlgorithmToPages(selectedAlgorithm, assignableIds)];
                                            case 3:
                                                _a.sent();
                                                notifyUser.success("Algorithm applied. It may take some time for your pages' engagement levels to update.");
                                                _a.label = 4;
                                            case 4: return [2];
                                        }
                                    });
                                }); },
                                isEscapable: true,
                            })];
                    case 1:
                        _a.sent();
                        setIsAlgorithmUpdating(false);
                        return [2];
                }
            });
        });
    }
    return (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", gap: "m", style: { alignItems: "stretch" } },
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "center", align: "center", gap: "xs" },
            react_1.default.createElement(icons_1.Engagement, null),
            react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "s" }, "Assign engagement algorithm")),
        react_1.default.createElement(kaleidoscope_1.Text, { element: "div", size: "xs", align: "center" },
            react_1.default.createElement("p", null, "Assign an engagement algorithm to the ".concat(assignable.length, " selected pages.")),
            hasNonLivePages && react_1.default.createElement("p", null, "Note that monitoring will only occur on live pages."),
            unassignableCount > 0 && react_1.default.createElement("p", null, "Blueprints cannot be assigned an engagement algorithm, and will be skipped.")),
        algorithms ? (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(Engagement_1.EngagementSelect, { algorithms: algorithms, value: selectedAlgorithm, onChange: setSelectedAlgorithm, disabled: isAlgorithmUpdating }),
            react_1.default.createElement(kaleidoscope_1.Button, { width: "full", disabled: selectedAlgorithm === undefined, onClick: onAssign }, "Assign algorithm"))) : (react_1.default.createElement(kaleidoscope_1.Spinner, { primary: true }))));
};
