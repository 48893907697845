// Generated by CoffeeScript 1.12.7

/*
  Default sync settings for Xero Integration's use in Accept
 */

(function () {
  var XeroSyncSettings;

  module.exports = XeroSyncSettings = (function () {
    function XeroSyncSettings($http, createUi, account, apiBaseUrl) {
      this.account = account;
      this.apiBaseUrl = apiBaseUrl;
      this.http = $http;
      this.ui = createUi(require("SettingsAngular/Integrations/Xero/XeroSyncSettings.html"), {
        settings: this.account.integrations.xero.settings,
        toggleAutoPush: (function (_this) {
          return function (enabled) {
            if (enabled == null) {
              return;
            }
            return _this.http.post(_this.apiBaseUrl + "/accounts/" + _this.account._id, {
              "integrations.xero.settings.defaultAutoPush": enabled,
            });
          };
        })(this),
      });
    }

    return XeroSyncSettings;
  })();
}.call(this));
