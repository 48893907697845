// Generated by CoffeeScript 1.12.7

/*
  Load fonts into the browser for use in CSS styles

  Unlike LoadFonts.js, this module does not update the in-memory account styles
 */

(function () {
  var WebFont, filterFonts;

  WebFont = require("webfontloader");

  module.exports = function (userFilesUrl, account) {
    return function (fonts) {
      var config, googleFonts, hostedWebfonts, typeKit;
      config = {
        classes: false,
      };
      googleFonts = filterFonts(fonts, "GoogleFonts");
      if (googleFonts.length) {
        config.google = {
          families: _.map(googleFonts, function (fontConfig) {
            return fontConfig.name + ":300,400,700,400italic";
          }),
        };
      }
      typeKit = _.find(fonts, {
        vendor: "Typekit",
      });
      if (typeKit != null) {
        config.typekit = {
          id: typeKit.typekitId,
        };
      }
      hostedWebfonts = filterFonts(fonts, "HostedWebfont");
      if (hostedWebfonts.length) {
        config.custom = {
          families: _.map(hostedWebfonts, "name"),
          urls: _.map(hostedWebfonts, function (font) {
            return userFilesUrl + "/" + account.assetKey + "/fonts/" + font.filename + "/" + font.filename + ".css";
          }),
        };
      }
      WebFont.load(config);
      return config;
    };
  };

  filterFonts = function (fonts, vendor) {
    return _.filter(fonts, {
      vendor: vendor,
    });
  };
}.call(this));
