// Generated by CoffeeScript 1.12.7

/*
  This module defines the block menu actions for the Saved Content Navigator context.
 */

(function () {
  var ColorWheel, Settings, ref;

  (ref = require("Common/Utils/ImageImports")), (ColorWheel = ref.ColorWheel), (Settings = ref.Settings);

  module.exports = function (settings, make, toggleCurrentTab) {
    var actions, settingsPanel, styleSelector;
    styleSelector = make(require("./StyleSelector/StyleSelector"));
    settingsPanel = make(require("./Settings/Settings"), {
      settingItems: settings,
    });
    actions = [
      {
        name: "Block Styles",
        internal: "styles",
        displayType: "complex",
        description: "Change the style of this block",
        event: "Clicked on change styles",
        icon: ColorWheel,
        ui: styleSelector.ui,
        onClick: toggleCurrentTab,
      },
      {
        name: "Settings",
        internal: "settings",
        displayType: "complex",
        description: "Settings for this block",
        event: "Clicked on block settings",
        icon: Settings,
        ui: settingsPanel.ui,
        onClick: toggleCurrentTab,
      },
    ];
    return actions;
  };
}.call(this));
