"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.inPlaceReplacement = void 0;
var lodash_1 = require("lodash");
var inPlaceReplacement = function (original, updated, overwrites) {
    var e_1, _a;
    if (overwrites === void 0) { overwrites = {}; }
    try {
        for (var _b = __values(Object.keys(updated)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var key = _c.value;
            if (original[key] == undefined) {
                original[key] = updated[key];
            }
            else if ((0, lodash_1.isArray)(updated[key])) {
                if (updated[key].length < original[key].length) {
                    var lengthDifference = original[key].length - updated[key].length;
                    original[key].splice(updated[key].length - 1, lengthDifference);
                }
                (0, exports.inPlaceReplacement)(original[key], updated[key]);
            }
            else if ((0, lodash_1.isObject)(updated[key])) {
                (0, exports.inPlaceReplacement)(original[key], updated[key]);
            }
            else {
                original[key] = updated[key];
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    if (!(0, lodash_1.isArray)(original)) {
        deleteRemovedAttributes(original, updated);
    }
    applyOverwrites(original, overwrites);
};
exports.inPlaceReplacement = inPlaceReplacement;
var deleteRemovedAttributes = function (original, updated) {
    var e_2, _a;
    try {
        for (var _b = __values(Object.keys(original)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var key = _c.value;
            if (key[0] === "$" || key[0] === "_" || key === "lock") {
                continue;
            }
            if (updated[key] == undefined) {
                delete original[key];
            }
        }
    }
    catch (e_2_1) { e_2 = { error: e_2_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_2) throw e_2.error; }
    }
};
var applyOverwrites = function (original, overwrites) {
    var e_3, _a;
    try {
        for (var _b = __values(Object.keys(overwrites)), _c = _b.next(); !_c.done; _c = _b.next()) {
            var key = _c.value;
            original[key] = overwrites[key];
        }
    }
    catch (e_3_1) { e_3 = { error: e_3_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_3) throw e_3.error; }
    }
};
