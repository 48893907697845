"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubscriptionController = void 0;
var Alert_1 = require("./Alert/Alert");
var PlanInfo_1 = require("./PlanInfo/PlanInfo");
var SubscriptionController = (function () {
    function SubscriptionController(createUi, make, account, initSubscription, cancelSubscription, waitFor, manualTooltip, mountReactComponent, subscriptionApiService) {
        var _this = this;
        var _a, _b, _c;
        var createPaymentUI = function () {
            var _a, _b;
            var subscribed = ((_a = account.stripe) === null || _a === void 0 ? void 0 : _a.subToken) != null;
            var haveCard = ((_b = account.stripe) === null || _b === void 0 ? void 0 : _b.customerToken) != null;
            if (_this.ui.paymentUI == null && (subscribed || haveCard)) {
                return (_this.ui.paymentUI = make(require("./Payment/Payment")));
            }
        };
        var isSubscribed = !!((_a = account.stripe) === null || _a === void 0 ? void 0 : _a.subToken);
        var isCancelled = !!((_b = account.stripe) === null || _b === void 0 ? void 0 : _b.cancelAt);
        this.ui = createUi(require("./Subscription.html"), {
            loadingInvoices: true,
            invoices: null,
            isSubscribed: isSubscribed,
            isCancelled: isCancelled,
            haveCard: function () {
                var _a;
                return ((_a = account.stripe) === null || _a === void 0 ? void 0 : _a.customerToken) != null;
            },
            selectPlan: function () {
                return initSubscription.init();
            },
            alert: isCancelled
                ? (0, Alert_1.mountAlert)(mountReactComponent, {
                    cancelAt: (_c = account.stripe) === null || _c === void 0 ? void 0 : _c.cancelAt,
                })
                : undefined,
            planInfo: (0, PlanInfo_1.mountPlanInfo)(mountReactComponent, {
                planName: account.plan.displayName,
                isSubscribed: isSubscribed,
                selectPlan: initSubscription.init,
                isCancelled: isCancelled,
                undoCancelSubscription: subscriptionApiService.undoCancelSubscription,
            }),
            disableCancelSubscription: function () {
                var _a;
                return !(((_a = account.stripe) === null || _a === void 0 ? void 0 : _a.subToken) != null &&
                    account.plan.displayName !== "Enterprise" &&
                    !account.hasCustomPlanAgreement);
            },
            cancelSubscription: cancelSubscription.cancel,
            payment: function () {
                if (_this.ui.paymentUI != null) {
                    return _this.ui.paymentUI.ui;
                }
            },
            viewInvoices: function () {
                if (_this.ui.invoices != null) {
                    return _this.ui.invoices.open();
                }
            },
            $onElementRendered: function () {
                var _a;
                if (((_a = account.stripe) === null || _a === void 0 ? void 0 : _a.customerToken) != null) {
                    return waitFor({
                        type: "check",
                        checkFunction: function () {
                            return window.AccountDock != null;
                        },
                    }).then(function () {
                        _this.ui.loadingInvoices = false;
                        return (_this.ui.invoices = window.AccountDock.configure({
                            key: window.QwilrConfig.accountDockKey,
                            customer: account.stripe.customerToken,
                        }));
                    });
                }
            },
            maybeShowTooltip: function () {
                if (!_this.ui.disableCancelSubscription()) {
                    return;
                }
                var selector = ".settings-page .cancel-subscription-tooltip";
                var tooltipConfig = {
                    message: "Please contact support to cancel your subscription",
                    trigger: "manual",
                    placement: "bottom",
                    container: $(selector),
                    autoClose: true,
                };
                manualTooltip("Please contact support to cancel your subscription", selector, tooltipConfig);
            },
        });
        createPaymentUI();
        if (!isSubscribed) {
            this.ui.selectPlan();
        }
    }
    return SubscriptionController;
}());
exports.SubscriptionController = SubscriptionController;
exports.default = SubscriptionController;
