// Generated by CoffeeScript 1.12.7
(function () {
  var h;

  h = require("../Helpers");

  module.exports = function (elementTag) {
    return function (editor, e) {
      var point;
      if (e.keyCode !== 13) {
        return false;
      }
      e.preventDefault();
      point = editor.selection().getRange().anchor;
      if (h.nodeMatchesTag(point.node, elementTag)) {
        if (!h.textToRight(point)) {
          point.splitRight(document.createElement("P"));
        } else {
          point.splitRight();
        }
      } else {
        while (point.containingElement() !== editor.currentElem()) {
          if (h.nodeMatchesTag(point.containingElement(), elementTag) && !h.textToRight(point)) {
            point.splitRight(document.createElement("P"));
          } else {
            point.splitRight();
          }
        }
      }
      h.ensureOpen(point.node);
      h.ensureOpen(point.node.previousSibling);
      h.setCaretInside(editor, point);
      return true;
    };
  };
}.call(this));
