"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ZapierIntegration = (function () {
    function ZapierIntegration(createUi, needsUpselling) {
        var buttonLabel = needsUpselling ? "PREVIEW" : "USE THIS ZAP";
        this.ui = createUi(require("./Zapier.html"), {
            buttonLabel: buttonLabel,
        });
    }
    return ZapierIntegration;
}());
exports.default = ZapierIntegration;
