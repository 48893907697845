"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var slate_1 = require("slate");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var EditorTypes_1 = require("./../EditorTypes");
var TableNavigationActions = __importStar(require("./TableNavigationActions"));
var Utils_1 = require("../Utils");
var slate_react_1 = require("slate-react");
exports.default = (function (di) {
    return {
        onTableMoveDown: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("tableMoveDown")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.ArrowDown)
            .inTableCell()
            .doAction(function (event, editor) {
            if (shouldMoveToNextVerticalCell(editor, "forward")) {
                event.preventDefault();
                TableNavigationActions.moveDown(editor);
                return true;
            }
            return;
        }),
        onTableMoveUp: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("tableMoveUp")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.ArrowUp)
            .inTableCell()
            .doAction(function (event, editor) {
            if (shouldMoveToNextVerticalCell(editor, "backward")) {
                event.preventDefault();
                TableNavigationActions.moveUp(editor);
                return true;
            }
            return;
        }),
        onTableTab: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("tableTab")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Tab)
            .inTableCell()
            .doAction(function (event, editor) {
            event.preventDefault();
            TableNavigationActions.moveToNext(editor);
            return true;
        }),
        onTableShiftTab: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("tableShiftTab")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Tab)
            .withModifier(BehaviourBuilder_1.KeyModifier.Shift)
            .inTableCell()
            .doAction(function (event, editor) {
            event.preventDefault();
            TableNavigationActions.moveToPrevious(editor);
            return true;
        }),
    };
});
var TEXT_ELEMENT_ERROR_MARGIN = 5;
var shouldMoveToNextVerticalCell = function (editor, direction) {
    if (!editor.selection) {
        return;
    }
    var startPoint = slate_1.Range.start(editor.selection);
    var currentCellEntry = (0, Utils_1.checkAncestors)(editor, startPoint.path, function (curr) {
        return slate_1.Element.isElement(curr) && curr.type === "tableCell";
    });
    if (!currentCellEntry) {
        return;
    }
    var domRange = slate_react_1.ReactEditor.toDOMRange(editor, editor.selection);
    var domCellRange = slate_react_1.ReactEditor.toDOMRange(editor, slate_1.Editor.range(editor, currentCellEntry[1]));
    var rangeBox = domRange.getBoundingClientRect();
    var cellBox = domCellRange.getBoundingClientRect();
    if (direction === "forward") {
        return Math.abs(rangeBox.bottom - cellBox.bottom) < TEXT_ELEMENT_ERROR_MARGIN;
    }
    else {
        return Math.abs(rangeBox.top - cellBox.top) < TEXT_ELEMENT_ERROR_MARGIN;
    }
};
