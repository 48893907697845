// Generated by CoffeeScript 1.12.7
(function () {
  var Section;

  module.exports = Section = (function () {
    function Section(createUi, icon, info, isComplete, streamlined) {
      this.ui = createUi(require("./Section.html"), {
        icon: icon,
        info: info,
        isComplete: isComplete,
        streamlined: streamlined,
      });
    }

    return Section;
  })();
}.call(this));
