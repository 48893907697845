"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DonutChart = void 0;
var react_1 = __importStar(require("react"));
var react_dom_1 = require("react-dom");
var recharts_1 = require("recharts");
var dynamic_1 = require("@vanilla-extract/dynamic");
var tokens_1 = require("@qwilr/kaleidoscope/tokens");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var Colors_1 = require("@CommonFrontend/Styles/Colors");
var Utils_1 = require("../Utils");
var styles = __importStar(require("./DonutChart.css"));
var nameKey = "name";
var dataKey = "value";
var DonutChart = function (_a) {
    var data = _a.data, activeIndex = _a.activeIndex, onHover = _a.onHover;
    var refForTooltip = (0, react_1.useRef)(null);
    return (react_1.default.createElement("div", { className: styles.wrapper, ref: refForTooltip },
        react_1.default.createElement(recharts_1.ResponsiveContainer, { width: "100%", height: "100%" },
            react_1.default.createElement(recharts_1.PieChart, null,
                react_1.default.createElement(recharts_1.Pie, { data: data, dataKey: dataKey, nameKey: nameKey, innerRadius: "40%", outerRadius: "75%", paddingAngle: 0.5, label: renderCustomizedLabel, labelLine: false, isAnimationActive: false, onMouseEnter: function (data, index) { return onHover(index); }, onMouseLeave: function () { return onHover(null); }, activeIndex: activeIndex !== null && activeIndex !== void 0 ? activeIndex : undefined, activeShape: function (props) { return renderActiveShape(__assign(__assign({}, props), { refForTooltip: refForTooltip })); } }, data.map(function (entry, index) {
                    var baseColor = data[index].color;
                    var color = index === activeIndex ? (0, Colors_1.darken)(baseColor, 30) : baseColor;
                    return react_1.default.createElement(recharts_1.Cell, { key: "cell-".concat(index), fill: color });
                }))))));
};
exports.DonutChart = DonutChart;
var RADIAN = Math.PI / 180;
function renderActiveShape(_a) {
    var _b;
    var cy = _a.cy, cx = _a.cx, innerRadius = _a.innerRadius, outerRadius = _a.outerRadius, fill = _a.fill, startAngle = _a.startAngle, endAngle = _a.endAngle, payload = _a.payload.payload, tooltipPosition = _a.tooltipPosition, refForTooltip = _a.refForTooltip;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("g", null,
            react_1.default.createElement(recharts_1.Sector, { cx: cx, cy: cy, innerRadius: innerRadius - 2, outerRadius: outerRadius + 5, startAngle: startAngle, endAngle: endAngle, fill: fill })),
        refForTooltip.current &&
            (0, react_dom_1.createPortal)(react_1.default.createElement("div", { className: styles.tooltipPositioner, style: (0, dynamic_1.assignInlineVars)((_b = {},
                    _b[styles.tooltipLeft] = "".concat(tooltipPosition.x, "px"),
                    _b[styles.tooltipTop] = "".concat(tooltipPosition.y, "px"),
                    _b)) },
                react_1.default.createElement(kaleidoscope_1.Tooltip, { show: true, content: react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.tooltipContent }, payload.name) },
                    react_1.default.createElement("div", null))), refForTooltip.current)));
}
var renderCustomizedLabel = function (_a) {
    var cx = _a.cx, cy = _a.cy, midAngle = _a.midAngle, outerRadius = _a.outerRadius, percent = _a.percent;
    var radius = outerRadius * 1.07;
    var x = cx + radius * Math.cos(-midAngle * RADIAN);
    var y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (react_1.default.createElement("text", { x: x, y: y, fill: tokens_1.tokens.color.headingSecondary, textAnchor: x > cx ? "start" : "end", dominantBaseline: "central", fontWeight: 550 }, (0, Utils_1.toRoundedPercentage)(percent)));
};
