// Generated by CoffeeScript 1.12.7

/*
  A dropdown widget.

  Constructor requires some non-DI argument:
  - [Array[Object]] items
  - [Object] options A collection of optional arguments which can include:
    - [Object] descriptor A label for what the dropdown contains. Requires fields `single` and
       `plural`
    - [Array[Object]] items The items to be displayed in the dropdown
    - [String] labelProperty The property on an item to use for display
    - [String] valueProperty The property on an item that indicates the value
    - [Function] onClick Any special behaviour to perform when an item is selected or deselected
    - [String] icon An icon to display on the dropdown
    - [String] displayClass Any custom classes to attach to the top-level of the dropdown
    - [Array] initialSelection Array of item values to be selected
    - [Boolean] multiSelect Whether the user can select multiple items or not

  Example usage:

  dropdown = make Dropdown,
    items: [
      {
        name: "Staffy"
        value: "staffordshire"
      }
      {
        name: "Terrior"
        value: "terrior-hound"
      }
    ]
    options:
      descriptor:
        single: "dog"
        plural: "dogs"
      icon: "dog-icon"
      displayClass: "dog-breed-dropdown"
      initialSelection: ["staffordshire"]
 */

(function () {
  var QwilrDropdown,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = QwilrDropdown = (function () {
    function QwilrDropdown($timeout, createUi, make, tether, appDomSelector, items, options, utils, OnClickOutside) {
      this.getSelectedItems = bind(this.getSelectedItems, this);
      this.updateItems = bind(this.updateItems, this);
      var descriptor,
        displayClass,
        elementId,
        filterItems,
        icon,
        initialSelection,
        itemClickHandler,
        labelProperty,
        multiSelect,
        onClick,
        optionsDisplayClass;
      this.timeout = $timeout;
      this.items = items;
      this.tetherInstance = null;
      descriptor = options.descriptor || {
        single: "item",
        plural: "items",
      };
      labelProperty = options.labelProperty || "name";
      this.valueProperty = options.valueProperty || "value";
      onClick = options.onClick || function () {};
      icon = options.icon || "";
      displayClass = options.displayClass || "";
      optionsDisplayClass = options.optionsDisplayClass || "";
      initialSelection =
        options.initialSelection ||
        _.map(
          items,
          (function (_this) {
            return function (item) {
              return item[_this.valueProperty];
            };
          })(this),
        );
      elementId = utils.randomString();
      if (options.multiSelect != null) {
        multiSelect = options.multiSelect;
      } else {
        multiSelect = true;
      }
      this.clearSelections = (function (_this) {
        return function () {
          var i, item, len, ref, results;
          ref = _this.items;
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            item = ref[i];
            results.push((item.$selected = false));
          }
          return results;
        };
      })(this);
      itemClickHandler = (function (_this) {
        return function (item) {
          var selected;
          if (multiSelect) {
            item.$selected = !item.$selected;
            selected = _.filter(_this.items, function (item) {
              return item.$selected;
            });
            return onClick(selected);
          } else {
            _.map(_this.items, function (item) {
              return (item.$selected = false);
            });
            item.$selected = true;
            _this.ui.toggleVisible();
            return onClick(item);
          }
        };
      })(this);
      filterItems = (function (_this) {
        return function () {
          var newItems;
          newItems = [];
          if (!_this.ui.filter.length) {
            newItems = items;
          } else {
            newItems = _.filter(items, function (item) {
              return item[labelProperty].toLowerCase().indexOf(_this.ui.filter.toLowerCase()) >= 0;
            });
          }
          return $timeout(function () {
            return (_this.items = newItems);
          });
        };
      })(this);
      this.markSelection = (function (_this) {
        return function (items, selectionValues) {
          if (_.isArray(selectionValues)) {
            return items.forEach(function (item) {
              var existsInValues;
              existsInValues = _.find(selectionValues, function (selected) {
                return item[_this.valueProperty] === selected;
              });
              return (item.$selected = existsInValues != null);
            });
          } else {
            return items.forEach(function (item) {
              return (item.$selected = item[_this.valueProperty] === selectionValues);
            });
          }
        };
      })(this);
      if (initialSelection.length) {
        this.markSelection(items, initialSelection);
      }
      this.ui = createUi(require("./Dropdown.html"), {
        descriptor: descriptor,
        multiSelect: multiSelect,
        hasSelection: (function (_this) {
          return function () {
            return (
              !multiSelect ||
              _.filter(_this.items, function (item) {
                return item.$selected;
              }).length > 0
            );
          };
        })(this),
        currentItem: (function (_this) {
          return function () {
            var selected;
            selected = _.filter(_this.items, function (item) {
              return item.$selected;
            });
            switch (selected.length) {
              case 0:
                return "Any " + _this.ui.descriptor.single.toLowerCase();
              case 1:
                return selected[0][labelProperty];
              case items.length:
                return "All " + _this.ui.descriptor.plural.toLowerCase();
              default:
                return "Multiple " + _this.ui.descriptor.plural.toLowerCase();
            }
          };
        })(this),
        icon: icon,
        displayClass: displayClass,
        optionsDisplayClass: optionsDisplayClass,
        isActive: (function (_this) {
          return function (item) {
            return _this.ui.currentItem[labelProperty] === item[labelProperty];
          };
        })(this),
        onClick: _.throttle(itemClickHandler, 500),
        labelProperty: labelProperty,
        id: elementId,
        toggleVisible: (function (_this) {
          return function () {
            if (_this.ui.menuVisible) {
              return _this.ui.hide();
            } else {
              return _this.ui.show();
            }
          };
        })(this),
        show: (function (_this) {
          return function () {
            var onClickOutside;
            _this.ui.menuVisible = true;
            onClickOutside = make(OnClickOutside, {
              ignoreElems: [".dropdown-wrapper", ".dropdown-options"],
              onClickOutside: function () {
                onClickOutside.stopListening();
                return _this.ui.hide();
              },
            });
            $timeout(function () {
              return $(appDomSelector).on("click.dropdown", function (e) {
                var target;
                target = $(e.target);
                if (target.parents(".dropdown-wrapper.visible").length <= 0 || target.is(".dropdown-wrapper.visible")) {
                  return _this.ui.toggleVisible();
                }
              });
            });
            return $timeout(function () {
              return (_this.tetherInstance = tether({
                element: $(".dropdown-options"),
                target: $(".dropdown--" + elementId),
                attachment: "top right",
                targetAttachment: "bottom right",
                targetOffset: "5px 0",
              }));
            });
          };
        })(this),
        hide: (function (_this) {
          return function () {
            return $timeout(function () {
              var ref, ref1, ref2;
              _this.ui.menuVisible = false;
              $(appDomSelector).off("click.dropdown");
              if ((ref = _this.tetherInstance) != null) {
                if ((ref1 = ref.element) != null) {
                  ref1.remove();
                }
              }
              return (ref2 = _this.tetherInstance) != null ? ref2.destroy() : void 0;
            });
          };
        })(this),
        menuVisible: false,
        filter: "",
        items: (function (_this) {
          return function () {
            return _this.items;
          };
        })(this),
        itemCount: (function (_this) {
          return function () {
            return _this.items.length;
          };
        })(this),
        emptyStateMessage: options.emptyStateMessage,
        filterItems: _.throttle(filterItems, 500),
      });
    }

    QwilrDropdown.prototype.updateItems = function (updatedItems) {
      var currentSelection, selected;
      selected = _.filter(this.items, function (item) {
        return item.$selected;
      });
      if (selected.length) {
        currentSelection = _.map(
          selected,
          (function (_this) {
            return function (item) {
              return item[_this.valueProperty];
            };
          })(this),
        );
        this.markSelection(updatedItems, currentSelection);
      }
      return (this.items = updatedItems);
    };

    /*
      Retrieve a list of selected items in the dropdown.
      @api public
     */

    QwilrDropdown.prototype.getSelectedItems = function () {
      return _.filter(this.items, function (item) {
        return item.$selected;
      });
    };

    return QwilrDropdown;
  })();
}.call(this));
