"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionsDropdown = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var Types_1 = require("@CommonFrontendBackend/Permissions/Types");
var Util_1 = require("@CommonFrontendBackend/Permissions/Util");
var kaleidoscope_2 = require("@qwilr/kaleidoscope");
var lodash_1 = require("lodash");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var permissionToText = (_a = {},
    _a[Types_1.Action.View] = "Can View",
    _a[Types_1.Action.Edit] = "Can Edit",
    _a[Types_1.Action.All] = "Full Access",
    _a);
var PermissionsDropdown = (function (_super) {
    __extends(PermissionsDropdown, _super);
    function PermissionsDropdown() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.permissionDropdownRef = react_1.default.createRef();
        _this.MaybeTooltip = function (_a) {
            var _b;
            var showTooltip = _a.showTooltip, children = _a.children, permission = _a.permission;
            if (showTooltip) {
                return (react_1.default.createElement(kaleidoscope_2.Tooltip, { wrapTarget: true, content: "".concat(getFriendlyName(permission), " is provided by the \"").concat((_b = _this.props.inheritedPermission) === null || _b === void 0 ? void 0 : _b.folderName, "\" folder"), size: kaleidoscope_2.TooltipSize.Large },
                    react_1.default.createElement(react_1.Fragment, null, children)));
            }
            return children;
        };
        _this.handleSelection = function (userId, permission) { return function () {
            return _this.props.handlePermissionChange(userId, permission);
        }; };
        return _this;
    }
    PermissionsDropdown.prototype.componentDidUpdate = function (prevProps) {
        if (this.props.scrollTop !== prevProps.scrollTop) {
            this.permissionDropdownRef.current.closeMenu();
        }
    };
    PermissionsDropdown.prototype.render = function () {
        var _this = this;
        var _a = this.props, entityId = _a.entityId, directPermission = _a.directPermission, inheritedPermission = _a.inheritedPermission, allowRemove = _a.allowRemove;
        var shouldShowInheritedPermission = (0, Util_1.isGreater)(inheritedPermission === null || inheritedPermission === void 0 ? void 0 : inheritedPermission.permission, directPermission);
        var effectiveAction = (0, Util_1.pickGreaterAction)(directPermission, inheritedPermission === null || inheritedPermission === void 0 ? void 0 : inheritedPermission.permission);
        return (react_1.default.createElement(kaleidoscope_1.OptionMenu, { className: "permissions-dropdown__menu nested-portal", ref: this.permissionDropdownRef, button: react_1.default.createElement(kaleidoscope_1.Button, { size: kaleidoscope_1.ButtonSize.XSmall, type: kaleidoscope_1.ButtonType.Tertiary },
                getFriendlyName(effectiveAction),
                react_1.default.createElement(icons_1.ChevronDown, { size: icons_1.IconSize.Small })) },
            react_1.default.createElement(this.MaybeTooltip, { showTooltip: shouldShowInheritedPermission, permission: effectiveAction },
                this.props.availablePermissions.map(function (action) { return (react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { key: action, onClick: _this.handleSelection(_this.props.entityId, action), selected: effectiveAction === action, disabled: shouldShowInheritedPermission }, getFriendlyName(action))); }),
                allowRemove && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(kaleidoscope_1.OptionMenuSeparator, null),
                    react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { destructive: true, onClick: this.handleSelection(entityId, Types_1.Action.None), disabled: shouldShowInheritedPermission }, "Remove"))))));
    };
    PermissionsDropdown.defaultProps = {
        availablePermissions: [Types_1.Action.Edit, Types_1.Action.View],
        allowRemove: true,
    };
    return PermissionsDropdown;
}(react_1.default.Component));
exports.PermissionsDropdown = PermissionsDropdown;
function getFriendlyName(permissions) {
    return (0, lodash_1.capitalize)(permissionToText[permissions]) || "";
}
