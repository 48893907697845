"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ONLY_SAME_DOMAIN_INVITES = exports.ONLY_ADMINS_CAN_INVITE = exports.isInvitedUserFromSameDomain = exports.createInviteNewUser = void 0;
var Types_1 = require("@CommonFrontendBackend/Permissions/Types");
var ONLY_ADMINS_CAN_INVITE = "Only admins can invite new users";
exports.ONLY_ADMINS_CAN_INVITE = ONLY_ADMINS_CAN_INVITE;
var ONLY_SAME_DOMAIN_INVITES = "You can only invite new users with the same email domain";
exports.ONLY_SAME_DOMAIN_INVITES = ONLY_SAME_DOMAIN_INVITES;
var createInviteNewUser = function (inviteNewUserModal, pageId, modal, userInvitePermission, userDataStore) {
    var inviteNewUser = function (email, permission, origin) { return __awaiter(void 0, void 0, Promise, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (userInvitePermission === Types_1.UserInvitePermission.Admin && !userDataStore.isAdmin) {
                        showInviteRestrictedModal(modal);
                        throw Error(ONLY_ADMINS_CAN_INVITE);
                    }
                    if (userInvitePermission === Types_1.UserInvitePermission.Domain && !userDataStore.isAdmin) {
                        if (!isInvitedUserFromSameDomain(userDataStore, email)) {
                            throw Error(ONLY_SAME_DOMAIN_INVITES);
                        }
                    }
                    return [4, inviteNewUserModal.invite(email, origin, {
                            role: Types_1.Role.Creator,
                            permission: {
                                projectId: pageId,
                                permission: permission,
                            },
                        })];
                case 1: return [2, _a.sent()];
            }
        });
    }); };
    return inviteNewUser;
};
exports.createInviteNewUser = createInviteNewUser;
var showInviteRestrictedModal = function (modal) {
    var inviteAccessAcknowledgeModal = {
        title: "You don't have invite permissions",
        explainer: "Your admin has retricted user invite permissions for this account. Contact your admin to invite a new user.",
        buttonLabel: "Okay, Got it",
    };
    modal.showAcknowledgeModal(inviteAccessAcknowledgeModal);
};
var isInvitedUserFromSameDomain = function (userDataStore, inviteEmail) {
    return getEmailDomain(userDataStore.email) === getEmailDomain(inviteEmail);
};
exports.isInvitedUserFromSameDomain = isInvitedUserFromSameDomain;
var getEmailDomain = function (email) {
    return email.replace(/.*@/, "");
};
