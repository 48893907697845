// Generated by CoffeeScript 1.12.7
(function () {
  var CheckValid;

  module.exports = CheckValid = (function () {
    function CheckValid(make, account, showAcknowledgeModal) {
      this.check = function (style) {
        var match;
        if (!style.name) {
          showAcknowledgeModal({
            title: "Missing Name",
            explainer: "Oops, looks like you didn't provide a name for this style.",
          });
          return false;
        }
        match = _.find(account.styles, {
          name: style.name,
        });
        if (match != null && match !== style) {
          showAcknowledgeModal({
            title: "Duplicate Names",
            explainer: "Looks like you're already using that style name, please try another.",
          });
          return false;
        }
        if (_.includes(style.rawStyles, "`")) {
          showAcknowledgeModal({
            title: "Backticks Disallowed",
            explainer: "Backticks are not allowed in the custom styles",
          });
          return false;
        }
        return true;
      };
    }

    return CheckValid;
  })();
}.call(this));
