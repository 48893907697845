// Generated by CoffeeScript 1.12.7
(function () {
  var ModulesList;

  module.exports = ModulesList = (function () {
    function ModulesList($resource, account, apiBaseUrl) {
      this.getList = function () {
        var i, len, modules, type, types;
        types = [
          {
            name: "Quote",
            route: "quotes",
          },
          {
            name: "Video",
            route: "videos",
          },
          {
            name: "Splash",
            route: "splash",
          },
        ];
        modules = {};
        for (i = 0, len = types.length; i < len; i++) {
          type = types[i];
          modules[type.name] = {
            service: $resource(apiBaseUrl + "/projects/:projectId/modules/" + type.route + "/:blockId", {
              blockId: "@blockId",
              projectId: "@projectId",
            }),
            styles:
              _.filter(account.styles, {
                blockType: type.name,
              }) || [],
          };
        }
        return modules;
      };
    }

    return ModulesList;
  })();
}.call(this));
