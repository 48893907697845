"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OverlayEditorLayout = void 0;
var OverlayEditorLayout = (function () {
    function OverlayEditorLayout(createUi, overlayStack, listComponent, title, description, createNewItem, iconSrc, isValid) {
        var _this = this;
        var Editor = require("./OverlayEditorLayout.html");
        this.ui = createUi(Editor, {
            iconSrc: iconSrc,
            title: title,
            description: description,
            listComponent: listComponent,
            createNewItem: createNewItem,
            close: function () {
                if (isValid()) {
                    overlayStack.closeCurrent();
                }
            },
        });
        this.show = function () {
            return overlayStack.add({
                contentUi: _this.ui,
                options: {
                    centerContent: false,
                    hideCloseButton: true,
                },
            });
        };
    }
    return OverlayEditorLayout;
}());
exports.OverlayEditorLayout = OverlayEditorLayout;
