"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SettingsTelemetry = (function () {
    function SettingsTelemetry(telemetry) {
        var _this = this;
        this.telemetry = telemetry;
        this.trackSectionViewed = function (sectionName) {
            _this.telemetry.trackOnly("Settings Section Viewed", { settings_section_name: sectionName });
        };
        this.trackIntegrationsSettingsSubsectionViewed = function (subsectionName) {
            _this.telemetry.trackOnly("Integrations Settings Subsection Viewed", {
                integrations_settings_subsection_name: subsectionName,
            });
        };
        this.trackConnectToZapierButtonClicked = function (subsectionName) {
            _this.telemetry.trackOnly("Connect To Zapier Button Clicked", {
                integrations_settings_subsection_name: subsectionName,
            });
        };
    }
    return SettingsTelemetry;
}());
exports.default = SettingsTelemetry;
