"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.moveDownwardsUntilValid = exports.moveUpwardsUntilValid = exports.isAtStartOfInline = void 0;
var slate_1 = require("slate");
var Utils_1 = require("../Utils");
var isAtStartOfInline = function (editor) {
    if (!editor.selection || slate_1.Range.isExpanded(editor.selection)) {
        return false;
    }
    var startPoint = slate_1.Range.start(editor.selection);
    var parent = slate_1.Editor.parent(editor, startPoint.path);
    return !!parent && (0, Utils_1.isInline)(parent[0]) && startPoint.offset === 0;
};
exports.isAtStartOfInline = isAtStartOfInline;
var moveUpwardsUntilValid = function (editor, isIllegalCursor) {
    if (!editor.selection) {
        return editor;
    }
    while (isIllegalCursor(editor)) {
        var startPoint = slate_1.Range.start(editor.selection);
        var previousTextNode = (0, Utils_1.getPrevious)(editor, startPoint.path, slate_1.Text.isText);
        if (!previousTextNode) {
            return editor;
        }
        slate_1.Transforms.select(editor, slate_1.Editor.end(editor, previousTextNode[1]));
    }
    return editor;
};
exports.moveUpwardsUntilValid = moveUpwardsUntilValid;
var moveDownwardsUntilValid = function (editor, isIllegalCursor) {
    if (!editor.selection) {
        return editor;
    }
    while (isIllegalCursor(editor)) {
        var startPoint = slate_1.Range.start(editor.selection);
        var nextTextNode = (0, Utils_1.getNext)(editor, startPoint.path, slate_1.Text.isText);
        if (!nextTextNode) {
            return editor;
        }
        slate_1.Transforms.select(editor, slate_1.Editor.start(editor, nextTextNode[1]));
    }
    return editor;
};
exports.moveDownwardsUntilValid = moveDownwardsUntilValid;
