"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColumnWidths = exports.calculateColumnWidths = void 0;
var ROW_WIDTH = 24;
var getInitialCalculatedColumnWidths = function (_a) {
    var startingColumnWidths = _a.startingColumnWidths, operation = _a.operation, columnIndex = _a.columnIndex;
    if (operation === "remove") {
        return startingColumnWidths.map(function (width, index) { return (index === columnIndex ? 0 : width); });
    }
    var targetNumberOfColumns = startingColumnWidths.length + 1;
    var newColWidth = Math.floor(ROW_WIDTH / targetNumberOfColumns);
    var initialCalculatedWidths = [];
    for (var i = 0; i < targetNumberOfColumns; i += 1) {
        if (columnIndex > i) {
            initialCalculatedWidths.push(startingColumnWidths[i]);
        }
        else if (columnIndex === i) {
            initialCalculatedWidths.push(newColWidth);
        }
        else {
            initialCalculatedWidths.push(startingColumnWidths[i - 1]);
        }
    }
    return initialCalculatedWidths;
};
var canResolveProportionately = function (_a) {
    var columnProportions = _a.columnProportions, unaccountedWidth = _a.unaccountedWidth;
    var containsNonWholeNumber = columnProportions.find(function (proportion) { return proportion % 1 !== 0; });
    if (containsNonWholeNumber)
        return false;
    var sumOfColumnProportions = columnProportions.reduce(function (acc, number) { return acc + number; }, 0);
    return sumOfColumnProportions <= unaccountedWidth;
};
var getInitialData = function (_a) {
    var startingColumnWidths = _a.startingColumnWidths, operation = _a.operation, columnIndex = _a.columnIndex, metadata = _a.metadata;
    var lastIndex = operation === "add" ? startingColumnWidths.length : startingColumnWidths.length - 1;
    var targetColumnIndex = typeof columnIndex !== "undefined" ? columnIndex : lastIndex;
    var columnWidthsIncludingTarget = (metadata === null || metadata === void 0 ? void 0 : metadata.columnWidthsIncludingTarget) ||
        getInitialCalculatedColumnWidths({ startingColumnWidths: startingColumnWidths, operation: operation, columnIndex: targetColumnIndex });
    var getUnaccountedWidth = function () {
        if (typeof (metadata === null || metadata === void 0 ? void 0 : metadata.unaccountedWidth) !== "undefined")
            return metadata.unaccountedWidth;
        return operation === "add"
            ? Math.floor(ROW_WIDTH / columnWidthsIncludingTarget.length)
            : startingColumnWidths[targetColumnIndex];
    };
    return {
        targetColumnIndex: targetColumnIndex,
        columnWidthsIncludingTarget: columnWidthsIncludingTarget,
        unaccountedWidth: getUnaccountedWidth(),
    };
};
var calculateColumnWidths = function (_a) {
    var _b;
    var startingColumnWidths = _a.startingColumnWidths, operation = _a.operation, _c = _a.strategy, strategy = _c === void 0 ? "proportional" : _c, columnIndex = _a.columnIndex, metadata = _a.metadata;
    if (startingColumnWidths.length === 1 && operation === "remove")
        return startingColumnWidths;
    if (startingColumnWidths.length === 12 && operation === "add")
        return startingColumnWidths;
    var _d = getInitialData({
        startingColumnWidths: startingColumnWidths,
        operation: operation,
        columnIndex: columnIndex,
        metadata: metadata,
    }), targetColumnIndex = _d.targetColumnIndex, columnWidthsIncludingTarget = _d.columnWidthsIncludingTarget, unaccountedWidth = _d.unaccountedWidth;
    if (strategy === "proportional") {
        var minColumnSize_1 = Math.min.apply(Math, __spreadArray([], __read(columnWidthsIncludingTarget.filter(function (_, index) { return index !== targetColumnIndex; })), false));
        var columnProportions_1 = columnWidthsIncludingTarget.map(function (width, index) {
            if (index === targetColumnIndex)
                return 0;
            return width / minColumnSize_1;
        });
        if (!canResolveProportionately({ columnProportions: columnProportions_1, unaccountedWidth: unaccountedWidth })) {
            return (0, exports.calculateColumnWidths)({
                startingColumnWidths: startingColumnWidths,
                operation: operation,
                strategy: "column-size-round-robin",
                columnIndex: targetColumnIndex,
                metadata: {
                    targetColumnIndex: targetColumnIndex,
                    columnWidthsIncludingTarget: columnWidthsIncludingTarget,
                    unaccountedWidth: unaccountedWidth,
                },
            });
        }
        var widthPerProportionalPass = columnProportions_1.reduce(function (acc, number) { return acc + number; }, 0);
        var numberOfWholeProportionalPasses_1 = Math.floor(unaccountedWidth / widthPerProportionalPass);
        var newColumnWidthsIncludingTarget_1 = columnWidthsIncludingTarget.map(function (width, index) {
            if (index === targetColumnIndex)
                return width;
            var proportion = columnProportions_1[index];
            if (!proportion)
                return width;
            var totalProportionalAdjustment = proportion * numberOfWholeProportionalPasses_1;
            return operation === "add" ? width - totalProportionalAdjustment : width + totalProportionalAdjustment;
        });
        var accountedWidth = widthPerProportionalPass * numberOfWholeProportionalPasses_1;
        if (accountedWidth === unaccountedWidth)
            return newColumnWidthsIncludingTarget_1;
        return (0, exports.calculateColumnWidths)({
            startingColumnWidths: startingColumnWidths,
            operation: operation,
            columnIndex: targetColumnIndex,
            strategy: "column-size-round-robin",
            metadata: {
                targetColumnIndex: targetColumnIndex,
                columnWidthsIncludingTarget: newColumnWidthsIncludingTarget_1,
                unaccountedWidth: unaccountedWidth - accountedWidth,
            },
        });
    }
    var allocatedWidth = 0;
    var newColumnWidthsIncludingTarget = columnWidthsIncludingTarget;
    var _loop_1 = function () {
        var e_1, _e;
        var ascSortedColumnsAndIndexes = columnWidthsIncludingTarget
            .reduce(function (acc, width, index) {
            if (index === targetColumnIndex)
                return acc;
            return __spreadArray(__spreadArray([], __read(acc), false), [{ width: width, index: index }], false);
        }, [])
            .sort(function (a, b) { return a.width - b.width; });
        var targetWidth = (_b = (operation === "add"
            ? ascSortedColumnsAndIndexes[ascSortedColumnsAndIndexes.length - 1]
            : ascSortedColumnsAndIndexes[0])) === null || _b === void 0 ? void 0 : _b.width;
        var passTargets = ascSortedColumnsAndIndexes.filter(function (col) { return col.width === targetWidth; });
        try {
            for (var passTargets_1 = (e_1 = void 0, __values(passTargets)), passTargets_1_1 = passTargets_1.next(); !passTargets_1_1.done; passTargets_1_1 = passTargets_1.next()) {
                var target = passTargets_1_1.value;
                if (allocatedWidth === unaccountedWidth)
                    break;
                newColumnWidthsIncludingTarget[target.index] = operation === "add" ? target.width - 1 : target.width + 1;
                allocatedWidth = allocatedWidth + 1;
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (passTargets_1_1 && !passTargets_1_1.done && (_e = passTargets_1.return)) _e.call(passTargets_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    while (allocatedWidth < unaccountedWidth) {
        _loop_1();
    }
    return newColumnWidthsIncludingTarget;
};
exports.calculateColumnWidths = calculateColumnWidths;
var getColumnWidths = function (options) {
    var columnWidths = (0, exports.calculateColumnWidths)(options);
    return columnWidths.reduce(function (acc, width) {
        if (width === 0)
            return acc;
        return __spreadArray(__spreadArray([], __read(acc), false), [{ width: width }], false);
    }, []);
};
exports.getColumnWidths = getColumnWidths;
