(function () {
  var QuoteAccountSettingsUpdater, cleanJson;

  QuoteAccountSettingsUpdater = require("Project/Modules/QuoteV2/QuoteAccountSettingsUpdater")
    .QuoteAccountSettingsUpdater;

  cleanJson = require("CleanJson/CleanJson")["default"];

  /*
    This module creates UI for the Rates panel.
   */

  module.exports = function (createUi, overlayStack, make, mapControllers, telemetry, viewState, account) {
    var RateEditController, apiUpdater, blankRate, createRateEditController, rates;
    apiUpdater = make(QuoteAccountSettingsUpdater, {
      settingsName: "rates",
      transformFunction: (function (_this) {
        return function (rate) {
          return cleanJson(rate);
        };
      })(this),
    });
    blankRate = {
      type: "rate",
      name: "Untitled",
      rate: 0,
      unit: "Hour",
    };
    rates = account.settings.modules.quote.rates;
    RateEditController = require("./Rate/Rate");
    createRateEditController = function (rate) {
      return make(RateEditController, {
        rate: rate,
        rateList: rates,
        apiUpdater: apiUpdater,
      });
    };
    this.ui = createUi(require("./RatesPanel.html"), {
      ratesList: rates,
      viewState: viewState,
      close: overlayStack.closeCurrent,
      newRate: (function (_this) {
        return function () {
          _this.ui.ratesList.push(angular.copy(blankRate));
          apiUpdater.debouncedApiUpdate();
          return telemetry.trackOnly("Quote Block Rate Added", {
            quote_block_rate_number: rates.length,
          });
        };
      })(this),
      $init: function (scope) {
        return (scope.ui.rates = mapControllers(scope, createRateEditController, rates));
      },
    });
    return (this.show = (function (_this) {
      return function () {
        return overlayStack.add(_this.ui);
      };
    })(this));
  };
}.call(this));
