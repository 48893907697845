"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var TableActions = __importStar(require("./TableActions"));
var Types_1 = require("@CommonFrontendBackend/ContentTokens/Types");
exports.default = (function (di) {
    var editorTelemetry = di.get("editorTelemetry");
    return {
        onTableAddRow: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("addTableRow")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "table", action: EditorTypes_1.TableActionType.AddRow })
            .doAction(function (event, editor) {
            TableActions.addTableRow(editor, event.data.path, event.data.row, event.data.direction);
            return true;
        }),
        onTableAddRepeatingRow: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("addRepeatingTableRow")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "table", action: EditorTypes_1.TableActionType.AddRepeatingRow })
            .doAction(function (event, editor) {
            TableActions.addRepeatingTableRow(editor, event.data.path, event.data.row, event.data.direction);
            editorTelemetry.tableRepeatingRowAdded();
            return true;
        }),
        onTableSetRepeatingRowToken: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(EditorTypes_1.TableActionType.SetRepeatingRowToken)
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: Types_1.WidgetBlockType.Table, action: EditorTypes_1.TableActionType.SetRepeatingRowToken })
            .doAction(function (event, editor) {
            TableActions.setRepeatingTableRowToken(editor, event.data.path, event.data.repeatingToken);
            editorTelemetry.tableRepeatingRowTokenSet(event.data.repeatingToken);
            return true;
        }),
        onTableDeleteRows: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("deleteTableRows")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "table", action: EditorTypes_1.TableActionType.DeleteRows })
            .doAction(function (event, editor) {
            TableActions.deleteTableRows(editor, event.data.path, event.data.rows);
            return true;
        }),
        onTableAddColumn: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("addTableColumn")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "table", action: EditorTypes_1.TableActionType.AddColumn })
            .doAction(function (event, editor) {
            TableActions.addTableColumn(editor, event.data.path, event.data.column, event.data.columnBorders);
            return true;
        }),
        onTableDeleteColumns: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("deleteTableColumns")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "table", action: EditorTypes_1.TableActionType.DeleteColumns })
            .doAction(function (event, editor) {
            TableActions.deleteTableColumns(editor, event.data.path, event.data.columns, event.data.columnBorders);
            return true;
        }),
        onTableResizeColumns: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("resizeTableColumns")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "table", action: EditorTypes_1.TableActionType.ResizeColumns })
            .doAction(function (event, editor) {
            TableActions.resizeColumns(editor, event.data.path, event.data.columnBorders);
            return true;
        }),
        onTabLastTableCell: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onTabLastTableCell")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Tab)
            .inTableCell()
            .doAction(function (event, editor) {
            if (TableActions.inLastTableCell(editor)) {
                event.preventDefault();
                TableActions.addTableRowAndSelectNextCell(editor);
                return true;
            }
            return false;
        }),
        onBackspaceAtStartOfTableCell: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onBackspaceAtStartOfTableCell")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Backspace)
            .isOfAncestry("tableCell")
            .doAction(function (event, editor) {
            if (TableActions.isAtStartOfCell(editor)) {
                event.preventDefault();
                return true;
            }
        }),
        onDeleteAtEndOfTableCell: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onDeleteAtEndOfTableCell")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Delete)
            .isOfAncestry("tableCell")
            .doAction(function (event, editor) {
            if (TableActions.isAtEndOfCell(editor)) {
                event.preventDefault();
                return true;
            }
        }),
    };
});
