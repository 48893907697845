// Generated by CoffeeScript 1.12.7

/*
  A CarouselButton is an abstract button that is designed to fit in the header section of
  the Carousel.

  @class CarouselButton
  @param name [String] - the name of the Button, this is not shown in the UI but is useful for
                         differentating between the type of Button (i.e. a form of identifier)
  @param text [String] - the text of what is shown in the UI label for the Button
  @param icon [String] - the SVG icon name
  @param onClick [Function] - called when the button is clicked
  @param shouldShow [Function] - (Optional) a function that returns whether this button should
         should or not. If this is not passed in, it defaults to true (i.e. always shows)
 */

(function () {
  var CarouselButton;

  module.exports = CarouselButton = (function () {
    function CarouselButton(name, text, icon, onClick, createUi, shouldShow) {
      this.ui = createUi(require("./CarouselButton.html"), {
        name: name,
        text: text,
        icon: icon,
        onClick: onClick,
        shouldShow:
          shouldShow ||
          function () {
            return true;
          },
      });
    }

    return CarouselButton;
  })();
}.call(this));
