(function () {
  var ChangePasswordController, FeatureFlag;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = ChangePasswordController = (function () {
    function ChangePasswordController(
      $http,
      createUi,
      apiBaseUrl,
      AsyncButton,
      $q,
      $window,
      make,
      telemetry,
      user,
      usingFeature,
    ) {
      var button, errorMessageMapping, generalErrorMessage, onError, updatePassword;
      errorMessageMapping = {
        "Password doesn't match": "Mhm, that doesn't seem to match your current password. Please try again.",
        "Can't have an empty password field": "Whoops, looks like you left a field blank. Please try again.",
        "New password and confirmation don't match":
          "Your new password and the confirmation don't seem to match? Please try again.",
        "Password was less than 10 characters":
          "That password was a bit too short to be secure. Please use a password of at least 10 characters.",
        "Password was more than 100 characters":
          "That password was a bit too long to be secure. Please use a password of at most 100 characters.",
        "This password has previously appeared in a data breach. Please choose a different password":
          "This password has previously appeared in a data breach. Please choose a different password",
        "New password same as current":
          "Your new password cannot be the same as your old password. To help protect your account, you need to choose a new password every time you reset it",
      };
      generalErrorMessage =
        "Mhm, we're having some trouble changing your password. Please check the details and try again.";
      updatePassword = (function (_this) {
        return function () {
          var confirm, current, newPwd, ref;
          (ref = _this.ui.passwords), (current = ref.current), (newPwd = ref.newPwd), (confirm = ref.confirm);
          if (!current || !newPwd || !confirm) {
            onError("Can't have an empty password field");
            return $q.reject(new Error("Error updating password"));
          }
          if (newPwd !== confirm) {
            onError("New password and confirmation don't match");
            return $q.reject(new Error("Error updating password"));
          }
          if (newPwd === current) {
            onError("New password same as current");
            return $q.reject(new Error("Error updating password"));
          }
          return $http
            .post(apiBaseUrl + "/users/change-password", {
              current: _this.ui.passwords.current,
              newPwd: _this.ui.passwords.newPwd,
              newPwdConfirm: _this.ui.passwords.confirm,
            })
            .then(function (response) {
              var base;
              _this.ui.passwords = {};
              _this.ui.errors = "";
              if ((base = user.useHistory).passwordChangeCount == null) {
                base.passwordChangeCount = 0;
              }
              user.useHistory.passwordChangeCount++;
              telemetry.trackOnly(
                "Password Changed",
                {
                  password_change_number: user.useHistory.passwordChangeCount,
                },
                {
                  blockIntercom: true,
                },
              );
              return ($window.location = $window.QwilrConfig.publicApiBaseUrl + "/logout");
            })
            ["catch"](function (response) {
              var data;
              data = response.data;
              onError(data.message || data.error.message);
              return $q.reject(new Error("Error updating password"));
            });
        };
      })(this);
      button = new AsyncButton(updatePassword, "Update Password");
      onError = (function (_this) {
        return function (error) {
          return (_this.ui.errors = errorMessageMapping[error] || generalErrorMessage);
        };
      })(this);
      this.ui = createUi(require("./ChangePassword.html"), {
        passwords: {},
        button: button.ui,
        isFieldDisabled: function () {
          return button.state === "waiting";
        },
        unlockButton: function () {
          var ref;
          if ((ref = button.state) === "disabled" || ref === "success") {
            return (button.state = "idle");
          }
        },
        showResetPasswordLink: usingFeature(FeatureFlag.OptionalLoginMethods),
      });
    }

    return ChangePasswordController;
  })();
}.call(this));
