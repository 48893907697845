"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PermissionsDataStore = void 0;
var PagePermissionsDataStore_1 = require("./PagePermissionsDataStore");
var PermissionsDataStore = (function () {
    function PermissionsDataStore(permissionsService, user, usersDataStore, accountStore) {
        this.permissionsService = permissionsService;
        this.user = user;
        this.usersDataStore = usersDataStore;
        this.accountStore = accountStore;
        this.pagePermissions = new Map();
    }
    PermissionsDataStore.prototype.getPagePermissions = function (pageId) {
        this.createNewPagePermissionStoreIfNotExists(pageId);
        var pagePermissionsDataStore = this.pagePermissions.get(pageId);
        return pagePermissionsDataStore;
    };
    PermissionsDataStore.prototype.createNewPagePermissionStoreIfNotExists = function (pageId) {
        if (!this.pagePermissions.has(pageId)) {
            var pagePermissions = new PagePermissionsDataStore_1.PagePermissionsDataStore(pageId, this.permissionsService, this.usersDataStore.getAllUsers(), this.accountStore);
            this.pagePermissions.set(pageId, pagePermissions);
        }
    };
    return PermissionsDataStore;
}());
exports.PermissionsDataStore = PermissionsDataStore;
