// Generated by CoffeeScript 1.12.7

/*
  CarouselControls are arrows that are on the left/right of the UI at the Block level

  This is used to control the prev() and next() iterations on the Carousel itself

  @param carousel [Object] - the Carousel object that the controls are attached to
 */

(function () {
  module.exports = function (carousel, createUi) {
    return (this.ui = createUi(require("./CarouselControls.html"), {
      carousel: carousel,
      goNext: (function (_this) {
        return function () {
          return carousel != null ? carousel.selectNext() : void 0;
        };
      })(this),
      goPrev: (function (_this) {
        return function () {
          return carousel != null ? carousel.selectPrev() : void 0;
        };
      })(this),
      shouldShow: (function (_this) {
        return function () {
          if (carousel != null) {
            return !carousel.viewState.hidden;
          } else {
            return false;
          }
        };
      })(this),
    }));
  };
}.call(this));
