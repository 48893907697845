"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var types_1 = require("@CommonFrontendBackend/User/types");
var CensusCollectorMetadataStore = (function () {
    function CensusCollectorMetadataStore(userDataStore, useHistoryDataStore) {
        this.userDataStore = userDataStore;
        this.useHistoryDataStore = useHistoryDataStore;
    }
    Object.defineProperty(CensusCollectorMetadataStore.prototype, "isTemplateSignup", {
        get: function () {
            return !!(this.userDataStore.projectCount === 1 &&
                this.useHistoryDataStore.userEntryPoint === types_1.UserSignupEntryPoints.Template);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CensusCollectorMetadataStore.prototype, "isPageBuilderAISignup", {
        get: function () {
            return !!(this.userDataStore.projectCount === 1 &&
                this.useHistoryDataStore.userEntryPoint === types_1.UserSignupEntryPoints.PageBuilderAI);
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], CensusCollectorMetadataStore.prototype, "isTemplateSignup", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], CensusCollectorMetadataStore.prototype, "isPageBuilderAISignup", null);
    return CensusCollectorMetadataStore;
}());
exports.default = CensusCollectorMetadataStore;
