// Generated by CoffeeScript 1.12.7
(function () {
  var FormTextField;

  module.exports = FormTextField = (function () {
    function FormTextField(createUi, model, $timeout) {
      var focusToLastOption;
      if (model.items == null) {
        model.items = [];
      }
      focusToLastOption = function () {
        return $timeout(function () {
          return $("#form-field-" + model.id)
            .find(".input")
            .last()
            .trigger("focus");
        }, 25);
      };
      this.ui = createUi(require("Common/FormBuilder/FormEditor/Field/Dropdown/Dropdown.html"), {
        model: model,
        error: "",
        emptyItemsError: false,
        addItem: function () {
          model.items.push("");
          return focusToLastOption();
        },
        deleteItem: function (index) {
          model.items.splice(index, 1);
          return this.validate();
        },
        validate: (function (_this) {
          return function () {
            if (model.name.length === 0) {
              return (_this.ui.error = "Please enter a name for this dropdown field.");
            } else {
              return (_this.ui.error = "");
            }
          };
        })(this),
      });
      focusToLastOption();
    }

    return FormTextField;
  })();
}.call(this));
