"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSideBySideBehaviours = void 0;
var BehaviourBuilder_1 = require("../BehaviourBuilder");
var EditorTypes_1 = require("../../EditorTypes");
var Types_1 = require("@CommonFrontendBackend/ContentTokens/Types");
var SideBySideActions_1 = require("./SideBySideActions");
var QwilrEditorTelemetry_1 = require("Project/QwilrEditor/QwilrEditorTelemetry");
var TelemetryParameterValue_1 = require("Telemetry/TelemetryParameterValue");
var BehaviourNames = Object.freeze({
    SetPercentage: "setPercentageForSideBySide",
    DismissPlaceholder: "dismissSideBySidePlaceholder",
    PickImage: "pickSideBySideImage",
    EmbedImage: "embedSideBySideImage",
    SwapOrder: "swapOrderForSideBySide",
    BackspaceAtColumnStart: "backspaceAtColumnStartSideBySide",
    DeleteAtColumnEnd: "deleteAtColumnEndSideBySide",
});
var getSideBySideBehaviours = function (di, router) {
    var telemetry = di.get("editorTelemetry");
    return {
        onChangeSideBySidePercentage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourNames.SetPercentage)
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: Types_1.WidgetBlockType.SideBySide, action: EditorTypes_1.SideBySideActionType.SetPercentage })
            .doAction(function (event, editor) {
            var _a = event.data, path = _a.path, percentage = _a.percentage;
            telemetry.inlineContentResized("sideBySide", {
                columnsSize: percentage,
            });
            (0, SideBySideActions_1.setSideBySidePercentage)(editor, percentage, path);
            return true;
        }),
        onSwapOrderForSideBySide: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourNames.SwapOrder)
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: Types_1.WidgetBlockType.SideBySide, action: EditorTypes_1.SideBySideActionType.SwapOrder })
            .doAction(function (event, editor) {
            (0, SideBySideActions_1.swapSideBySideOrder)(editor, event.data.path);
            telemetry.columnsSwappedInSideBySide();
            return true;
        }),
        onDismissSideBySidePlaceholder: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourNames.DismissPlaceholder)
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: Types_1.WidgetBlockType.SideBySide, action: EditorTypes_1.SideBySideActionType.DismissPlaceholder })
            .doAction(function (event, editor) {
            telemetry.inlineContentPlaceholderDeleted(QwilrEditorTelemetry_1.InlineContentPlaceholderType.Columns);
            (0, SideBySideActions_1.dismissSideBySidePlaceholder)(editor, event.data.path);
            return true;
        }),
        onUploadImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourNames.PickImage)
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: Types_1.WidgetBlockType.SideBySide, action: EditorTypes_1.SideBySideActionType.PickImage })
            .doAction(function (event, editor) {
            var path = event.data.path;
            (0, SideBySideActions_1.sideBySidePickImage)(editor, router, di, path);
            return true;
        }),
        onEmbedImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourNames.EmbedImage)
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: Types_1.WidgetBlockType.SideBySide, action: EditorTypes_1.SideBySideActionType.EmbedImage })
            .doAction(function (event, editor) {
            var _a = event.data, imageData = _a.imageData, path = _a.path;
            (0, SideBySideActions_1.sideBySideEmbedImage)(editor, path, imageData);
            telemetry.sideBySideContentAdded(TelemetryParameterValue_1.ContentAddedType.Image);
            return true;
        }),
        onBackspaceAtColumnStart: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourNames.BackspaceAtColumnStart)
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Backspace)
            .selectionIsCollapsed()
            .isOfAncestry("sideBySideColumn")
            .inNonEmptyBlock()
            .doAction(function (event, editor) {
            if ((0, SideBySideActions_1.backspaceAtColumnStart)(editor)) {
                event.preventDefault();
                return true;
            }
        }),
        onDeleteAtColumnEnd: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(BehaviourNames.DeleteAtColumnEnd)
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Delete)
            .selectionIsCollapsed()
            .isOfAncestry("sideBySideColumn")
            .doAction(function (event, editor) {
            if ((0, SideBySideActions_1.deleteAtColumnEnd)(editor)) {
                event.preventDefault();
                return true;
            }
        }),
    };
};
exports.getSideBySideBehaviours = getSideBySideBehaviours;
