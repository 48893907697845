// Generated by CoffeeScript 1.12.7
(function () {
  var ProjectActions, ShouldDisableAcceptAndQuoteAccess, StatusChecker, _, isActionAllowed;

  ShouldDisableAcceptAndQuoteAccess = require("./ShouldDisableAcceptAndQuoteAccess")["default"];

  isActionAllowed = require("@CommonFrontendBackend/Projects/Utils").isActionAllowed;

  ProjectActions = require("@CommonFrontendBackend/Projects/Types").ProjectActions;

  _ = require("lodash");

  module.exports = StatusChecker = (function () {
    function StatusChecker(
      make,
      invoke,
      showAcknowledgeModal,
      projectSecurer,
      projectTagger,
      projectLiveLimit,
      permissionsService,
    ) {
      var shouldDisableAcceptAndQuoteAccess;
      shouldDisableAcceptAndQuoteAccess = invoke(ShouldDisableAcceptAndQuoteAccess);
      this.isEditable = (function (_this) {
        return function (project) {
          if (project.status == null) {
            return true;
          }
          if (!isActionAllowed(project.status, ProjectActions.Edit)) {
            return false;
          }
          if (_this.isPartiallyAccepted(project)) {
            return false;
          }
          return true;
        };
      })(this);
      this.isDeletable = function (project) {
        if (project.status == null) {
          return true;
        }
        if (project.status === "accepted") {
          return false;
        }
        if (this.isPartiallyAccepted(project)) {
          return false;
        }
        return true;
      };
      this.isAcceptable = function (project) {
        var block, i, len, quoteBlocks;
        quoteBlocks = _.filter(project.blocks, {
          type: "Quote",
        });
        for (i = 0, len = quoteBlocks.length; i < len; i++) {
          block = quoteBlocks[i];
          if (block.settings.accept == null) {
            continue;
          }
          if (block.settings.accept.active) {
            return true;
          }
        }
        if (
          _.find(project.blocks, {
            type: "Accept",
          }) != null
        ) {
          return true;
        }
        return false;
      };
      this.canToggleLive = function (project) {
        var userAction;
        userAction = _.isNil(project.$userAction) ? project.userAction : project.$userAction;
        if (projectTagger.isTemplateProject(project) || project.hasTemplateChildren) {
          return false;
        } else if (project.status == null || this.isDraft(project)) {
          return true;
        } else if (this.isLive(project) && project.partialAcceptanceData == null) {
          return true;
        } else if (
          (!projectLiveLimit.hasHitLimit() || this.isLive(project)) &&
          (permissionsService.isAllowed(userAction, "edit") || permissionsService.isAllowed(userAction, "edit")) &&
          (this.isLive(project) || !shouldDisableAcceptAndQuoteAccess(project))
        ) {
          return true;
        } else {
          return false;
        }
      };
      this.isDraft = function (project) {
        return project.status === "";
      };
      this.isLive = function (project) {
        return project.status === "live";
      };
      this.isDisabled = function (project) {
        return project.status === "disabled";
      };
      this.isPublicViewable = function (project) {
        return isActionAllowed(project.status, ProjectActions.PublicPageView);
      };
      this.isEnabled = function (project) {
        return !projectSecurer.isDisabled(project);
      };
      this.isPartiallyAccepted = function (project) {
        return this.isLive(project) && project.partialAcceptanceData != null;
      };
      this.showDisabledWarning = function (project) {
        return showAcknowledgeModal({
          title: "Project is disabled",
          explainer: "Please enable this project to allow sharing.",
        });
      };
    }

    return StatusChecker;
  })();
}.call(this));
