// Generated by CoffeeScript 1.12.7
(function () {
  var createBluePrint,
    getProject,
    projectCollaborator,
    shouldDisableAcceptAndQuoteAccess,
    slice = [].slice;

  getProject = require("./Getter")["default"];

  createBluePrint = require("./BluePrintCreator")["default"];

  shouldDisableAcceptAndQuoteAccess = require("./ShouldDisableAcceptAndQuoteAccess")["default"];

  projectCollaborator = require("./Collaborator")["default"];

  module.exports = function (
    projectStatusChecker,
    projectLinkRetriever,
    projectDeleter,
    projectCreator,
    projectRestorer,
    projectStatusSetter,
    projectPreviewer,
    projectSharer,
    projectOpener,
    projectSecurer,
    projectAnalyser,
    projectTemplater,
    projectTagger,
    projectPermissionsHandler,
    projectCollaborator,
    usingFeature,
    invoke,
    PdfExport,
    make,
    $rootScope,
  ) {
    return {
      getUrl: projectLinkRetriever.getUrl,
      clone: projectCreator.clone,
      confirmDelete: projectDeleter.confirmDelete,
      delete: projectDeleter["delete"],
      confirmRestore: projectRestorer.confirmRestore,
      restore: projectRestorer.restore,
      create: projectCreator.create,
      createWelcomePage: projectCreator.createWelcomePage,
      setStatus: projectStatusSetter.setStatus,
      isEditable: projectStatusChecker.isEditable,
      isDeletable: projectStatusChecker.isDeletable,
      isAcceptable: projectStatusChecker.isAcceptable,
      share: projectSharer.share,
      shareWithTeam: projectSharer.shareWithTeam,
      preview: projectPreviewer.preview,
      open: projectOpener.open,
      secure: projectSecurer.secure,
      secureLink: projectSecurer.secureLink,
      getEnabledSecurityMeasures: projectSecurer.getEnabledSecurityMeasures,
      analyse: projectAnalyser.analyse,
      tag: projectTagger.tag,
      removeTag: projectTagger.removeTag,
      addAsTemplate: projectTemplater.addAsTemplate,
      removeAsTemplate: projectTemplater.removeAsTemplate,
      isTemplate: projectTemplater.isTemplate,
      getPreviewLink: projectPreviewer.getPreviewLink,
      openCollaboratorPreview: function (project, source) {
        return projectCollaborator.getCollaboratorPageUrlWithGeneration(project).then(function (url) {
          return window.open(url + "?source=" + source);
        });
      },
      changeOwner: function () {
        var args;
        args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
        return projectPermissionsHandler.changeOwner.apply(projectPermissionsHandler, args);
      },
      showSelectUserModal: function () {
        var args, showing;
        args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
        showing = projectPermissionsHandler.showSelectUserModal.apply(projectPermissionsHandler, args);
        setTimeout(function () {
          var e;
          try {
            /*
              @HACK(mike, 2019-09-09) This is done so that it shows the modal properly. If you try to
              trigger an angular event from outside angular, it doesn’t run until the next digest cycle.
              This triggers a digest cycle manually
             */
            return $rootScope.$apply();
          } catch (error) {
            e = error;

            /*
              @NOTE(mike, 2019-09-06) This will only throw an error if there is already an apply in
              progress so we don't need to run it in the first place as it's already being triggered
             */
          }
        });
        return showing;
      },
      shouldDisableAcceptAndQuoteAccess: invoke(shouldDisableAcceptAndQuoteAccess),
      getProject: invoke(getProject),
      createBluePrint: invoke(createBluePrint),
      exportToPDF: function (project) {
        var pdfOverlay;
        pdfOverlay = make(require("../../PdfPreviewOverlay/PdfPreviewOverlay").PdfPreviewOverlay, {
          previewLink: projectPreviewer.getPreviewLink(project),
          pdfUrl: projectLinkRetriever.getPdfUrlForProject(project),
          oneTimeTokenUrl: projectLinkRetriever.getOneTimeTokenUrl(project._id, project.links[0].secret),
        });
        setTimeout(function () {
          var e;
          try {
            /*
              @HACK(mike, 2019-09-09) This is done so that it shows the overlay properly. If you try to
              trigger an angular event from outside angular, it doesn’t run until the next digest cycle.
              This triggers a digest cycle manually
             */
            return $rootScope.$apply();
          } catch (error) {
            e = error;

            /*
              @NOTE(mike, 2019-09-06) This will only throw an error if there is already an apply in
              progress so we don't need to run it in the first place as it's already being triggered
             */
          }
        });
        return pdfOverlay.show();
      },
    };
  };
}.call(this));
