// Generated by CoffeeScript 1.12.7
(function () {
  var GoogleAnalyticsIntegration;

  module.exports = GoogleAnalyticsIntegration = (function () {
    function GoogleAnalyticsIntegration(createUi, genericIntegration) {
      genericIntegration.build.call(
        this,
        "googleAnalytics",
        "Google Analytics tracking ID",
        require("./GoogleAnalytics.html"),
      );
    }

    return GoogleAnalyticsIntegration;
  })();
}.call(this));
