(function () {
  var FeatureFlag, SelectBar, TeamController;

  SelectBar = require("./TeamSelect/SelectBar")["default"];

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  /*
    The 'Team page' controller and ui object definition
   */

  module.exports = TeamController = (function () {
    function TeamController(
      createUi,
      make,
      Button,
      seatsService,
      user,
      account,
      AccountTeamController,
      UserTeamsManager,
      initSubscription,
      rolesService,
      showDeleteModal,
      usingFeature,
      showDecideModal,
      telemetry,
    ) {
      var addNewEmptyTeam,
        additionalUsersCount,
        createTeamManager,
        hasUsersSelected,
        includedUsersCount,
        initializeTeamsUi,
        isTrial,
        refreshUi,
        setupTeams,
        setupUi;
      isTrial = account.plan.displayName === "Trial";
      this.userTeamsManager;
      initializeTeamsUi = function () {
        telemetry.trackOnly("Account Users Viewed");
        setupUi();
        return refreshUi();
      };
      setupTeams = (function (_this) {
        return function () {
          _this.ui.loading = true;
          if (usingFeature(FeatureFlag.Teams)) {
            return createTeamManager(true).then(function (userTeamsManager) {
              _this.userTeamsManager = userTeamsManager;
              _this.ui.teamActions = make(SelectBar, {
                createUi: createUi,
                hasUsersSelected: hasUsersSelected,
                showDecideModal: showDecideModal,
                showDeleteModal: showDeleteModal,
                userTeamsManager: userTeamsManager,
              });
              _this.ui.accountTeamControllers = _this.userTeamsManager.getTeamControllers();
              _this.ui.accountWideTeamController = _this.userTeamsManager.getAccountTeamController();
              return (_this.ui.loading = false);
            });
          } else {
            return createTeamManager(false).then(function (userTeamsManager) {
              _this.userTeamsManager = userTeamsManager;
              _this.ui.accountWideTeamController = _this.userTeamsManager.getAccountTeamController();
              return (_this.ui.loading = false);
            });
          }
        };
      })(this);

      /*
        We actually do a round trip to the server for teams refresh most of the time
       */
      refreshUi = (function (_this) {
        return function () {
          return setupTeams();
        };
      })(this);
      addNewEmptyTeam = (function (_this) {
        return function () {
          if (_this.userTeamsManager && rolesService.isAdmin(user)) {
            return _this.userTeamsManager.addNewController();
          }
        };
      })(this);
      createTeamManager = (function (_this) {
        return function (isUsingTeams) {
          return UserTeamsManager.createInstance(account, refreshUi, isUsingTeams);
        };
      })(this);
      hasUsersSelected = (function (_this) {
        return function () {
          if (_this.userTeamsManager) {
            return _this.userTeamsManager.hasUsersSelected();
          } else {
            return false;
          }
        };
      })(this);

      /*
        @NOTE (som, 2019-09-17): the concept of 'included' and 'additional' 'users' is a bit confusing
        because it is similar to (but not the same as) the concept of 'included' and 'additional'
        seats in our database.
      
        This is because with the move to per-seat pricing, we were originally going to move away from
        using this language in our customer-facing copy. However, we then moved back to using the
        'included'/'additional' language a few weeks later. Now on this team settings summary, we
        communicate the number of min seats on the plan as 'included' seats.
      
        To add to the confusion: for our customer-facing copy, we are trying to remove 'seats' from
        the vocabulary, and call them 'users' instead. So when we talk about 'users' here we are
        actually referring to seats!
       */
      includedUsersCount = (function (_this) {
        return function () {
          return seatsService.minSeats() || seatsService.seatsOnPlan();
        };
      })(this);
      additionalUsersCount = (function (_this) {
        return function () {
          return seatsService.seatsOnAccount() - includedUsersCount();
        };
      })(this);
      setupUi = (function (_this) {
        return function () {
          var createTeamButton, numSeatsRemaining;
          createTeamButton = new Button(addNewEmptyTeam, "Create team", {
            theme: "primary",
            size: "small",
            initialState: rolesService.isAdmin(user) ? "idle" : "disabled",
          });
          numSeatsRemaining = function () {
            if (_this.userTeamsManager) {
              return _this.userTeamsManager.numRemainingSeats();
            } else {
              return 0;
            }
          };
          return (_this.ui = createUi(require("./Team.html"), {
            allowUserEditing: rolesService.isAdmin(user),
            canEditTeam: rolesService.isAdmin(user),
            loading: true,
            teamActions: {},
            canAddUsers: function () {
              return seatsService.currentlyOccupied() < seatsService.maxSeats();
            },
            occupiedUsersCount: function () {
              return seatsService.currentlyOccupied();
            },
            includedUsersCount: includedUsersCount,
            additionalUsersCount: additionalUsersCount,
            hasAdditionalUsers: function () {
              return additionalUsersCount() > 0;
            },
            pricingPhrase: "$" + seatsService.seatPrice() + " per " + seatsService.timeUnit(),
            planName: account.plan.displayName,
            isTrial: isTrial,
            accountTeamControllers: [],
            usingTeams: usingFeature(FeatureFlag.Teams),
            createTeamButton: createTeamButton.ui,
            explorePlans: function () {
              return initSubscription.init();
            },
          }));
        };
      })(this);
      initializeTeamsUi();
    }

    return TeamController;
  })();
}.call(this));
