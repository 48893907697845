// Generated by CoffeeScript 1.12.7

/*
  An abstracted search button which "opens" and "closes" when it is selected.

  It has an input field, and based on a debounce will return the user's query

  @class SearchButton
  @param getSearchResults [Function] - a callback that is triggered when the user issues a new query
  @param onOpen [Function] - called when the search field is opened
  @param onClose [Function] - called when the search field is closed
  @param placeholder [String] - placeholder text when the search is empty
 */

(function () {
  var SearchButton;

  module.exports = SearchButton = (function () {
    function SearchButton(createUi, $timeout, getSearchResults, onOpen, onClose, placeholder) {
      var focusOnInput;
      this.onOpen = onOpen;
      this.onClose = onClose;
      this.elem = null;
      if (this.onOpen == null) {
        this.onOpen = function () {};
      }
      if (this.onClose == null) {
        this.onClose = function () {};
      }
      focusOnInput = (function (_this) {
        return function () {
          return $timeout(function () {
            return _this.elem.find("#search-input").trigger("focus");
          }, 200);
        };
      })(this);
      this.ui = createUi(require("./SearchButton.html"), {
        searchEnabled: false,
        searchQuery: "",
        placeholder: placeholder,
        clickSearch: (function (_this) {
          return function () {
            _this.ui.searchEnabled = true;
            _this.onOpen();
            focusOnInput();
          };
        })(this),
        closeSearch: (function (_this) {
          return function () {
            _this.ui.searchEnabled = false;
            return _this.onClose();
          };
        })(this),
        $onElementRendered: (function (_this) {
          return function (element, domName) {
            if (domName === "container") {
              return (_this.elem = element);
            }
          };
        })(this),
        query: (function (_this) {
          return function () {
            return getSearchResults(_this.ui.searchQuery);
          };
        })(this),
      });
    }

    return SearchButton;
  })();
}.call(this));
