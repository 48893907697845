"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CancelSubscription_1 = require("./Controls/CancelSubscription");
exports.default = {
    initSubscription: function (make, startSubscription, updateSubscription) {
        var InitSubscription = require("./Controls/InitSubscription");
        return make(InitSubscription, {
            startSubscription: startSubscription,
            updateSubscription: updateSubscription,
        });
    },
    startSubscription: function (make) {
        var StartSubscription = require("./Controls/StartSubscription");
        return make(StartSubscription);
    },
    updateSubscription: function (make) {
        var UpdateSubscription = require("./Controls/UpdateSubscription");
        return make(UpdateSubscription);
    },
    startBusinessTrial: function (make) {
        var StartBusinessTrial = require("./Controls/StartBusinessTrial");
        return make(StartBusinessTrial);
    },
    subscribeToPlan: function (make) {
        var SubscribeToPlan = require("./Controls/SubscribeToPlan");
        return make(SubscribeToPlan);
    },
    cancelSubscription: function (make) {
        return make(CancelSubscription_1.CancelSubscription);
    },
    isSubscribed: function (account) {
        return function () { var _a; return ((_a = account.stripe) === null || _a === void 0 ? void 0 : _a.subToken) != null; };
    },
    SubscriptionSequencer: function () {
        return require("./Controls/SubscriptionSequencer");
    },
    subscriptionApiService: function (make) {
        return make(require("./SubscriptionService").SubscriptionService);
    },
    subscriptionService: function (initSubscription, cancelSubscription, isSubscribed) {
        return {
            start: initSubscription.init,
            cancel: cancelSubscription.cancel,
            isSubscribed: isSubscribed,
        };
    },
};
