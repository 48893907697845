// Generated by CoffeeScript 1.12.7
(function () {
  var CustomLabelsList, QuoteAccountSettingsUpdater;

  QuoteAccountSettingsUpdater = require("../QuoteAccountSettingsUpdater").QuoteAccountSettingsUpdater;

  module.exports = CustomLabelsList = (function () {
    function CustomLabelsList(createUi, make, labelService, overlayStack, onComplete) {
      var CustomLabelsEditor, apiUpdater, overlayOptions, saveSet;
      apiUpdater = make(QuoteAccountSettingsUpdater, {
        settingsName: "labels",
        transformFunction: (function (_this) {
          return function (arg) {
            var id, isActive, labels, name;
            (id = arg.id), (name = arg.name), (isActive = arg.isActive), (labels = arg.labels);
            return {
              id: id,
              name: name,
              isActive: isActive,
              labels: labels,
            };
          };
        })(this),
      });
      CustomLabelsEditor = require("./CustomLabelsEditor").CustomLabelsEditor;
      saveSet = function (id, labelSet) {
        labelService.updateLabelSet(id, labelSet.name, labelSet.labels);
        apiUpdater.debouncedApiUpdate();
        return overlayStack.closeCurrent();
      };
      overlayOptions = {
        centerContent: false,
        hideCloseButton: true,
      };
      this.ui = createUi(require("./CustomLabelsList.html"), {
        allLabelSets: labelService.getActiveLabelSets,
        closeOverlay: function () {
          onComplete();
          return overlayStack.closeAll();
        },
        editSet: function (id) {
          var editor;
          editor = make(CustomLabelsEditor, {
            labelSet: _.cloneDeep(labelService.getLabelSet(id)),
            onDone: saveSet,
          });
          return overlayStack.add({
            contentUi: editor.ui,
            options: overlayOptions,
          });
        },
        createSet: function () {
          var editor, labelSet;
          labelSet = labelService.createLabelSet();
          editor = make(CustomLabelsEditor, {
            labelSet: labelSet,
            onDone: saveSet,
          });
          return overlayStack.add({
            contentUi: editor.ui,
            options: overlayOptions,
          });
        },
        deleteSet: function (id) {
          return labelService.deleteLabelSet(id);
        },
      });
    }

    return CustomLabelsList;
  })();
}.call(this));
