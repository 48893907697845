"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.schema = void 0;
var EditorTypes_1 = require("../EditorTypes");
var defaultMarks = [EditorTypes_1.MarkType.Italic, EditorTypes_1.MarkType.Bold, EditorTypes_1.MarkType.Strikethrough, EditorTypes_1.MarkType.Comment];
var defaultBlockChildren = [EditorTypes_1.InlineType.Variable, EditorTypes_1.InlineType.Link, EditorTypes_1.InlineType.LineBreak, EditorTypes_1.InlineType.TokenWidget];
exports.schema = {
    editor: {
        validChildren: [],
    },
    blocks: {},
    inlines: (_a = {},
        _a[EditorTypes_1.InlineType.LineBreak] = {
            isVoid: true,
        },
        _a[EditorTypes_1.InlineType.Link] = {
            marks: defaultMarks,
        },
        _a[EditorTypes_1.InlineType.Variable] = {
            isVoid: true,
            marks: false,
        },
        _a[EditorTypes_1.InlineType.TokenWidget] = {
            isVoid: true,
        },
        _a),
    name: "quotePlanCard",
};
