// Generated by CoffeeScript 1.12.7

/*
  Change an account's contact phone number.
 */

(function () {
  module.exports = function (account, user, internalSlack, $http, apiBaseUrl, getFullName) {
    return function (phoneNumber) {
      return $http
        .post(apiBaseUrl + "/accounts/" + account._id + "/change-phone-number", {
          phoneNumber: phoneNumber,
        })
        .then(function () {
          internalSlack.ping(
            "Team, " +
              getFullName(user) +
              ", of " +
              account.name +
              "\nJust updated their phone number to: " +
              phoneNumber,
            "#phone",
          );
          return phoneNumber;
        });
    };
  };
}.call(this));
