// Generated by CoffeeScript 1.12.7
(function () {
  var LoadingState;

  module.exports = LoadingState = (function () {
    function LoadingState(createUi, make, domFrame) {
      this.ui = createUi(require("./Loading.html"), {
        visible: false,
        message: "",
      });
      domFrame.add(this.ui);
      this.loading = (function (_this) {
        return function () {
          _this.ui.message = "Loading...";
          return (_this.ui.visible = true);
        };
      })(this);
      this.showCustomMessage = (function (_this) {
        return function (message) {
          _this.ui.message = message;
          return (_this.ui.visible = true);
        };
      })(this);
      this.hide = (function (_this) {
        return function () {
          _this.ui.visible = false;
          return (_this.ui.message = "");
        };
      })(this);
      this.show = this.loading;
      this.done = this.hide;
    }

    return LoadingState;
  })();
}.call(this));
