// Generated by CoffeeScript 1.12.7
(function () {
  var Pipedrive,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = Pipedrive = (function () {
    function Pipedrive($http, $timeout, createUi, apiBaseUrl, settings) {
      var ref, ref1;
      this.apiBaseUrl = apiBaseUrl;
      this.getDealsAndStages = bind(this.getDealsAndStages, this);
      this.getPipelines = bind(this.getPipelines, this);
      this.http = $http;
      this.ui = createUi(require("Project/Modules/Quote/Accept/Pipedrive/Pipedrive.html"), {
        pipelines: null,
        deals: null,
        stages: null,
        deal: ((ref = settings.pipedrive) != null ? ref.deal : void 0) != null || null,
        toStage: ((ref1 = settings.pipedrive) != null ? ref1.toStage : void 0) != null || null,
        settings: settings,
        showPipelinesLoading: (function (_this) {
          return function () {
            return _this.ui.pipelines == null;
          };
        })(this),
        showDealsLoading: (function (_this) {
          return function () {
            return _this.ui.deals == null && _this.ui.stages == null;
          };
        })(this),
        showDeals: (function (_this) {
          return function () {
            var ref2;
            return (ref2 = _this.ui.settings.pipedrive) != null ? ref2.pipeline : void 0;
          };
        })(this),
        getDealsAndStages: _.throttle(this.getDealsAndStages, 500),
        $init: (function (_this) {
          return function () {
            var ref2;
            _this.getPipelines().then(function (pipelines) {
              return $timeout(function () {
                return (_this.ui.pipelines = pipelines);
              });
            });
            if (((ref2 = _this.ui.settings.pipedrive) != null ? ref2.pipeline : void 0) != null) {
              return _this.getDealsAndStages();
            }
          };
        })(this),
      });
    }

    Pipedrive.prototype.getPipelines = function () {
      return this.http.get(this.apiBaseUrl + "/integration/pipedrive/pipelines").then(
        (function (_this) {
          return function (response) {
            return response.data.data;
          };
        })(this),
      );
    };

    Pipedrive.prototype.getDealsAndStages = function () {
      var pipelineId;
      this.ui.deals = null;
      this.ui.stages = null;
      pipelineId = this.ui.settings.pipedrive.pipeline.id;
      return this.http.get(this.apiBaseUrl + "/integration/pipedrive/deals-and-stages?pipeline=" + pipelineId).then(
        (function (_this) {
          return function (response) {
            _this.ui.deals = response.data.deals;
            return (_this.ui.stages = response.data.stages);
          };
        })(this),
      );
    };

    return Pipedrive;
  })();
}.call(this));
