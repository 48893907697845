"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DragType = void 0;
var mobx_1 = require("mobx");
var DragType;
(function (DragType) {
    DragType["Rectangle"] = "rectangle";
})(DragType || (exports.DragType = DragType = {}));
var DragStore = (function () {
    function DragStore() {
    }
    DragStore.prototype.startDrag = function (clientX, clientY, containerRect, type) {
        this._dragType = type;
        var x = clientX - containerRect.left;
        var y = clientY - containerRect.top;
        this.anchorX = x;
        this.anchorY = y;
        this._focusX = x;
        this._focusY = y;
        this.containerLeft = containerRect.left;
        this.containerTop = containerRect.top;
    };
    DragStore.prototype.moveFocus = function (clientX, clientY) {
        if (this.containerLeft == null || this.containerTop == null) {
            throw new Error("Moved focus without starting drag");
        }
        this._focusX = clientX - this.containerLeft;
        this._focusY = clientY - this.containerTop;
    };
    DragStore.prototype.stopDrag = function () {
        this._dragType = undefined;
        this.anchorX = undefined;
        this.anchorY = undefined;
        this._focusX = undefined;
        this._focusY = undefined;
    };
    Object.defineProperty(DragStore.prototype, "isDragging", {
        get: function () {
            return !!this._dragType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragStore.prototype, "dragType", {
        get: function () {
            return this._dragType;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragStore.prototype, "isPoint", {
        get: function () {
            return this.width === 0 && this.height === 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragStore.prototype, "focusX", {
        get: function () {
            if (this._focusX == undefined) {
                return 0;
            }
            return this._focusX;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragStore.prototype, "focusY", {
        get: function () {
            if (this._focusY == undefined) {
                return 0;
            }
            return this._focusY;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragStore.prototype, "top", {
        get: function () {
            if (this.anchorY == undefined || this._focusY == undefined) {
                return 0;
            }
            return Math.min(this.anchorY, this._focusY);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragStore.prototype, "left", {
        get: function () {
            if (this.anchorX == undefined || this._focusX == undefined) {
                return 0;
            }
            return Math.min(this.anchorX, this._focusX);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragStore.prototype, "absoluteTop", {
        get: function () {
            if (this.containerTop == undefined) {
                return 0;
            }
            return this.top + this.containerTop;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragStore.prototype, "absoluteLeft", {
        get: function () {
            if (this.containerLeft == undefined) {
                return 0;
            }
            return this.left + this.containerLeft;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragStore.prototype, "width", {
        get: function () {
            if (this.anchorX == undefined || this._focusX == undefined) {
                return 0;
            }
            return Math.abs(this.anchorX - this._focusX);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragStore.prototype, "height", {
        get: function () {
            if (this.anchorY == undefined || this._focusY == undefined) {
                return 0;
            }
            return Math.abs(this.anchorY - this._focusY);
        },
        enumerable: false,
        configurable: true
    });
    var _a;
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], DragStore.prototype, "anchorX", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], DragStore.prototype, "anchorY", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], DragStore.prototype, "_focusX", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], DragStore.prototype, "_focusY", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], DragStore.prototype, "containerLeft", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], DragStore.prototype, "containerTop", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", String)
    ], DragStore.prototype, "_dragType", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number, typeof (_a = typeof DOMRect !== "undefined" && DOMRect) === "function" ? _a : Object, String]),
        __metadata("design:returntype", void 0)
    ], DragStore.prototype, "startDrag", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number, Number]),
        __metadata("design:returntype", void 0)
    ], DragStore.prototype, "moveFocus", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DragStore.prototype, "stopDrag", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "isDragging", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "dragType", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "isPoint", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "focusX", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "focusY", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "top", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "left", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "absoluteTop", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "absoluteLeft", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "width", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragStore.prototype, "height", null);
    return DragStore;
}());
exports.default = DragStore;
