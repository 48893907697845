// Generated by CoffeeScript 1.12.7
(function () {
  let ChangeCompanyDetailsController, FeatureFlag;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = ChangeCompanyDetailsController = (function () {
    function ChangeCompanyDetailsController(
      $http,
      $timeout,
      account,
      createUi,
      apiBaseUrl,
      make,
      AsyncButton,
      telemetry,
      $q,
      usingFeature,
      upsellerService,
      $tooltip,
    ) {
      let onClick, onError, submitButton, upsellerMessage;
      if (account.website == null) {
        account.website = "";
      }
      upsellerMessage = null;
      if (!usingFeature(FeatureFlag.Domain)) {
        account.website = "";
        upsellerService.getPlanNameForFeature("domain").then(function (planName) {
          return (upsellerMessage = `The website redirect is only available on the ${planName} Plan. Please upgrade your plan in order to use this feature.`);
        });
      }
      onError = (function (_this) {
        return function (errorMessage) {
          _this.ui.error = errorMessage;
          return $timeout(function () {
            return (_this.ui.error = "");
          }, 5000);
        };
      })(this);
      onClick = (function (_this) {
        return function () {
          let newCompanyName, newCompanyWebsite;
          _this.ui.error = "";
          newCompanyName = _this.ui.companyName;
          newCompanyWebsite = _this.ui.companyWebsite;
          if (newCompanyName == null || !newCompanyName.length) {
            onError("Whoops, looks like you left this field blank. Please try again.");
            return $q.reject(new Error("Error setting company details"));
          }
          if (
            (newCompanyWebsite != null ? newCompanyWebsite.length : void 0) > 0 &&
            !newCompanyWebsite.startsWith("http")
          ) {
            newCompanyWebsite = `http://${newCompanyWebsite}`;
          }
          return $http
            .post(`${apiBaseUrl}/accounts/${account._id}`, {
              name: newCompanyName,
              website: newCompanyWebsite,
            })
            .then(function (response) {
              telemetry.update({
                company_name: newCompanyName,
              });
              account.name = newCompanyName;
              return (account.website = newCompanyWebsite);
            })
            ["catch"](function (response) {
              let ref, ref1;
              if (
                ((ref = response.data) != null ? ((ref1 = ref.error) != null ? ref1.message : void 0) : void 0) != null
              ) {
                onError(response.data.error.message);
              } else {
                onError("An error has occurred while trying to update your company details, please try again later.");
              }
              return $q.reject(new Error("Error setting company details"));
            });
        };
      })(this);
      submitButton = new AsyncButton(onClick, "Update Company Details");
      this.ui = createUi(require("./ChangeCompanyDetails.html"), {
        submitButton: submitButton.ui,
        error: "",
        companyName: account.name,
        companyWebsite: account.website,
        canEditCompanyWebsite: usingFeature(FeatureFlag.Domain),
        showTooltip: (function (_this) {
          return function () {
            let ref, selector;
            if (upsellerMessage == null) {
              return;
            }
            selector = ".change-company-details .tooltip-container";
            if ((ref = _this.ui.tooltip) != null) {
              ref.hide();
            }
            _this.ui.tooltip = $tooltip($(selector), {
              title: upsellerMessage,
              trigger: "manual",
              container: $(selector),
            });
            _this.ui.tooltip.$promise.then(function () {
              return _this.ui.tooltip.show();
            });
            return $timeout(function () {
              let ref1;
              return (ref1 = _this.ui.tooltip) != null ? ref1.hide() : void 0;
            }, 2000);
          };
        })(this),
        hideTooltip: (function (_this) {
          return function () {
            let ref;
            return (ref = _this.ui.tooltip) != null ? ref.hide() : void 0;
          };
        })(this),
        unlockButton: (function (_this) {
          return function () {
            let ref;
            if (
              (account.name !== _this.ui.companyName || account.website !== _this.ui.companyWebsite) &&
              ((ref = submitButton.state) === "disabled" || ref === "success")
            ) {
              return (submitButton.state = "idle");
            }
          };
        })(this),
      });
    }

    return ChangeCompanyDetailsController;
  })();
}.call(this));
