// Generated by CoffeeScript 1.12.7
(function () {
  var ProjectStatus, createGetStatusType;

  createGetStatusType = require("./GetStatusType")["default"];

  module.exports = ProjectStatus = (function () {
    function ProjectStatus(project, invoke) {
      var getStatusType, properties;
      getStatusType = invoke(createGetStatusType);
      properties = {
        draft: {
          cssClass: "draft",
          iconClass: "",
          iconText: "",
          text: "Draft",
          statusClass: "status-draft",
        },
        live: {
          cssClass: "live",
          iconClass: "",
          iconText: "●",
          text: "Live",
          statusClass: "status-live",
        },
        pending: {
          cssClass: "live",
          iconClass: "",
          iconText: "●",
          text: "Pending",
          statusClass: "status-live",
        },
        "partially accepted": {
          cssClass: "accepted",
          iconClass: "icon-check",
          iconText: "",
          text: "Accepted",
          statusClass: "status-accepted",
        },
        accepted: {
          cssClass: "accepted",
          iconClass: "icon-check",
          iconText: "",
          text: "Accepted",
          statusClass: "status-accepted",
        },
        disabled: {
          cssClass: "disabled",
          iconClass: "icon-cross",
          iconText: "",
          text: "Disabled",
          statusClass: "status-disabled",
        },
        "time limit": {
          cssClass: "disabled",
          iconClass: "icon-cross",
          iconText: "",
          text: "Time Limit",
          statusClass: "status-disabled",
        },
        "view limit": {
          cssClass: "disabled",
          iconClass: "icon-cross",
          iconText: "",
          text: "View Limit",
          statusClass: "status-disabled",
        },
        blueprint: {
          cssClass: "disabled",
          iconClass: "",
          iconText: "",
          text: "Blueprint",
          statusClass: "status-disabled",
        },
      };
      this.getProperties = (function (_this) {
        return function () {
          return properties[getStatusType(project)];
        };
      })(this);
    }

    return ProjectStatus;
  })();
}.call(this));
