"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var VariableActions = __importStar(require("./VariableActions"));
var DragStore_1 = require("../../Stores/DragStore/DragStore");
var Types_1 = require("Tokens/Types");
exports.default = (function (di) {
    var editorTelemetry = di.get("editorTelemetry");
    var dragStore = di.get("dragStore");
    var projectStore = di.get("projectStore");
    var blockStore = di.get("blockStore");
    var blockType = blockStore.blockType;
    return {
        onBackspaceAfterVariable: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onBackspaceAfterVariable")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Backspace)
            .hasInlineBefore(EditorTypes_1.InlineType.Variable)
            .doAction(function (event, editor) {
            event.preventDefault();
            return VariableActions.deleteVariableOnBackspace(editor);
        }),
        onDeleteBeforeVariable: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onDeleteBeforeVariable")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Delete)
            .hasInlineAfter(EditorTypes_1.InlineType.Variable)
            .doAction(function (event, editor) {
            event.preventDefault();
            return VariableActions.deleteVariableOnDelete(editor);
        }),
        onDragOverVariable: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("VariableDragOver")
            .isEvent(EditorTypes_1.EventType.DragOver)
            .doAction(function (event) {
            if (dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.Variable)) {
                var token = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.Variable).token;
                if ((0, Types_1.isNotPlatformProductToken)(token)) {
                    event.preventDefault();
                    return true;
                }
            }
        }),
        onDropVariable: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("VariableDrop")
            .isEvent(EditorTypes_1.EventType.Drop)
            .doAction(function (event, editor) {
            var variable = VariableActions.getVariableTokenFromDragEvent(event);
            if (dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.Variable) &&
                variable &&
                (0, Types_1.isNotPlatformProductToken)(variable)) {
                if (!VariableActions.dropCopyVariable(editor, event)) {
                    return false;
                }
                editorTelemetry.variableInserted(variable, {
                    tokenInsertedOrigin: "drag",
                    tokenInsertedBlockType: blockType,
                    templateId: projectStore._id,
                });
                return true;
            }
            else {
                return false;
            }
        }),
        onDeleteVariableWithToolbar: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("deleteVariable")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: EditorTypes_1.InlineType.Variable, action: EditorTypes_1.VariableActionType.DeleteVariable })
            .doAction(function (event, editor) {
            VariableActions.deleteVariableFromToolbar(editor, event.data.path);
            return true;
        }),
        onBoldVariableWithToolbar: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("boldVariable")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: EditorTypes_1.InlineType.Variable, action: EditorTypes_1.VariableActionType.Bold })
            .doAction(function (event, editor) {
            VariableActions.toggleBoldVariable(editor, event.data.path);
            return true;
        }),
        onItalicVariableWithToolbar: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("italicVariable")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: EditorTypes_1.InlineType.Variable, action: EditorTypes_1.VariableActionType.Italic })
            .doAction(function (event, editor) {
            VariableActions.toggleItalicVariable(editor, event.data.path);
            return true;
        }),
        onStrikethroughVariableWithToolbar: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("strikethroughVariable")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: EditorTypes_1.InlineType.Variable, action: EditorTypes_1.VariableActionType.Strikethrough })
            .doAction(function (event, editor) {
            VariableActions.toggleStrikethroughVariable(editor, event.data.path);
            return true;
        }),
        onAddTokenWidget: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onAddTokenWidget")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.CurlyBracketOpen)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(/.*{$/)
            .doAction(function (event, editor) {
            if (projectStore.isTemplate || blockStore.isSavedBlock) {
                if (VariableActions.addTokenWidget(editor)) {
                    event.preventDefault();
                    return true;
                }
            }
            return false;
        }),
        onCancelTokenWidget: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onCancelTokenWidget")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: EditorTypes_1.InlineType.Variable, action: EditorTypes_1.VariableActionType.Cancel })
            .doAction(function (event, editor) {
            VariableActions.removeTokenWidget(editor, event.data.path);
            return true;
        }),
        onInsertVariable: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("onInsertVariable")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: EditorTypes_1.InlineType.Variable, action: EditorTypes_1.VariableActionType.Insert })
            .doAction(function (event, editor) {
            var variable = event.data.variable;
            VariableActions.replaceTokenWidgetWithVariable(editor, event.data.path, variable);
            editorTelemetry.variableInserted(variable, {
                tokenInsertedOrigin: "search",
                tokenInsertedBlockType: blockType,
                templateId: projectStore._id,
            });
            return true;
        }),
    };
});
