"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getTableFromCell = exports.moveToPrevious = exports.moveToNext = exports.moveDown = exports.moveUp = void 0;
var slate_1 = require("slate");
var Utils_1 = require("../Utils");
var TableActions_1 = require("./TableActions");
var moveUp = function (editor) {
    if (!editor.selection) {
        return;
    }
    var startPoint = slate_1.Range.start(editor.selection);
    var currentCell = getCellAtPath(editor, startPoint.path);
    if (!currentCell) {
        return;
    }
    var tableEntry = (0, exports.getTableFromCell)(editor, currentCell[1]);
    var cellAbove = getCellAbove(editor, tableEntry, currentCell);
    if (cellAbove) {
        slate_1.Transforms.select(editor, slate_1.Editor.start(editor, cellAbove[1]));
    }
    else {
        var targetNode = (0, Utils_1.getPrevious)(editor, tableEntry[1], slate_1.Text.isText);
        if (targetNode) {
            slate_1.Transforms.select(editor, slate_1.Editor.start(editor, targetNode[1]));
        }
    }
};
exports.moveUp = moveUp;
var moveDown = function (editor) {
    if (!editor.selection) {
        return;
    }
    var startPoint = slate_1.Range.start(editor.selection);
    var currentCell = getCellAtPath(editor, startPoint.path);
    if (!currentCell) {
        return;
    }
    var tableEntry = (0, exports.getTableFromCell)(editor, currentCell[1]);
    var cellBelow = getCellBelow(editor, tableEntry, currentCell);
    if (cellBelow) {
        slate_1.Transforms.select(editor, slate_1.Editor.start(editor, cellBelow[1]));
    }
    else {
        var targetNode = (0, Utils_1.getNext)(editor, tableEntry[1], slate_1.Text.isText);
        if (targetNode) {
            slate_1.Transforms.select(editor, slate_1.Editor.start(editor, targetNode[1]));
        }
    }
};
exports.moveDown = moveDown;
var moveToNext = function (editor) {
    if (!editor.selection) {
        return;
    }
    var startPoint = slate_1.Range.start(editor.selection);
    var currentCell = getCellAtPath(editor, startPoint.path);
    if (!currentCell) {
        return;
    }
    var nextCellEntry = (0, Utils_1.getNext)(editor, currentCell[1], Utils_1.isBlockElement);
    if (!nextCellEntry) {
        return;
    }
    slate_1.Transforms.select(editor, slate_1.Editor.start(editor, nextCellEntry[1]));
};
exports.moveToNext = moveToNext;
var moveToPrevious = function (editor) {
    if (!editor.selection) {
        return;
    }
    var startPoint = slate_1.Range.start(editor.selection);
    var currentCell = getCellAtPath(editor, startPoint.path);
    if (!currentCell) {
        return;
    }
    var previousCellEntry = (0, Utils_1.getPrevious)(editor, currentCell[1], Utils_1.isBlockElement);
    if (!previousCellEntry) {
        return;
    }
    var _a = __read(previousCellEntry, 2), previousCellPath = _a[1];
    slate_1.Transforms.select(editor, slate_1.Editor.end(editor, previousCellPath));
};
exports.moveToPrevious = moveToPrevious;
var getCellAbove = function (editor, tableEntry, currentCellEntry) {
    var _a = __read(tableEntry, 1), table = _a[0];
    var columns = table.data.columns;
    var nodePath = currentCellEntry[1];
    var cellIndex = (0, TableActions_1.getCellIndexFromCellPath)(editor, nodePath);
    var aboveIndex = cellIndex - columns;
    if (aboveIndex >= 0) {
        return (0, TableActions_1.getCellAtIndex)(aboveIndex, tableEntry);
    }
    return null;
};
var getCellBelow = function (editor, tableEntry, currentCell) {
    var _a = __read(tableEntry, 1), table = _a[0];
    var rows = table.data.rows;
    var columns = table.data.columns;
    var nodePath = currentCell[1];
    var cellIndex = (0, TableActions_1.getCellIndexFromCellPath)(editor, nodePath);
    var belowIndex = cellIndex + columns;
    if (belowIndex < rows * columns) {
        return (0, TableActions_1.getCellAtIndex)(belowIndex, tableEntry);
    }
    return null;
};
var getCellAtPath = function (editor, path) {
    return (0, Utils_1.checkAncestors)(editor, path, function (curr) {
        return slate_1.Element.isElement(curr) && curr.type === "tableCell";
    });
};
var getTableFromCell = function (editor, cellPath) {
    return (0, Utils_1.checkAncestors)(editor, cellPath, function (curr) {
        return slate_1.Element.isElement(curr) && curr.type === "table";
    });
};
exports.getTableFromCell = getTableFromCell;
