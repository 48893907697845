/*
  This module is responsible for instantiating a block.
 */

(function () {
  module.exports = function (di, utils, blockBackwardsCompatibility, blockWiring, BlockController, project) {
    /*
      @param blockConfig [object] - expected to contain:
       `block` - the data model for the block.
       `autoPersist` - flag to indicate whether to automatically persist this block on change.
       `blockMenuActions` - the actions for this block's menu.
     */
    return function (blockConfig) {
      var base, block, blockDi, theBlock;
      block = blockConfig.block;
      if (block.type === "QuoteV2") {
        if ((base = block.settings).currency == null) {
          base.currency = project.currency;
        }
      }
      block = blockBackwardsCompatibility(block);
      block.$id = utils.uniqueId(8);
      block.$animationState = "off";
      blockDi = di.child();
      blockDi.load(blockWiring(block, di));
      theBlock = blockDi.make(BlockController, blockConfig);
      return { block: theBlock, di: blockDi };
    };
  };
}.call(this));
