// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = angular.module("qwilr.common", [
    require("./Viewport/Viewport").name,
    require("./ErrorCatcher/ErrorCatcher").name,
    require("./PreviousState/PreviousState").name,
    require("./Telemetry/TrackingDirective").name,
    require("./ImageOnLoad/ImageOnLoad").name,
    require("AspectResize/AspectResize").name,
    require("DomTrick/DomTrick").name,
    require("./RightClick/RightClick").name,
    require("./Utils/FileInputOnChange").name,
    require("./Utils/DragDropDirectives")["default"].name,
    require("./AtomicUI/ToggleButton/ToggleButton").name,
    require("./AtomicUI/NumberInput/NumberInput").name,
    require("./AtomicUI/UpgradeButtonAngular/UpgradeButton").name,
    require("./AtomicUI/InlineReachoutAngular/InlineReachout").name,
    require("./Vendor/AccountDock").name,
  ]);
}.call(this));
