// Generated by CoffeeScript 1.12.7
(function () {
  var AccountService, FeatureFlag;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = AccountService = (function () {
    function AccountService(invoke, account, usingFeature, $q, $http, apiBaseUrl) {
      this.setPhoneNumber = invoke(require("../../Profile/Service/PhoneNumberChanger"));
      this.setDomain = invoke(require("SettingsAngular/BrandSetup/Domains/CustomDomainSetter/CustomDomainUpdater"));
      this.setSubdomain = invoke(require("SettingsAngular/BrandSetup/Domains/SubdomainSetter/SubdomainUpdater"));
      this.setDefaultProjectAccess = function (accessType) {
        return $http.post(apiBaseUrl + "/accounts/" + account._id + "/settings/default-access", {
          defaultAccess: accessType,
        });
      };
      this.setUserInvitePermission = function (permissionType) {
        return $http.post(apiBaseUrl + "/accounts/" + account._id + "/settings/user-invite-permission", {
          userInvitePermission: permissionType,
        });
      };

      /*
        Determines if a given account has a custom domain set.
       */
      this.hasCustomDomain = function () {
        var ref;
        return ((ref = account.customDomain) != null ? ref.length : void 0) > 0;
      };

      /*
        Determines if a given account has a custom subdomain set.
       */
      this.hasSubdomain = function () {
        var ref;
        return (
          usingFeature(FeatureFlag.Subdomain) && ((ref = account.customSubdomain) != null ? ref.length : void 0) > 0
        );
      };
      this.getSubdomain = function () {
        return account.customSubdomain;
      };
      this.getCustomDomain = function () {
        return account.customDomain;
      };
      this.setOfficeIpAddress = (function (_this) {
        return function (ipAddresses) {
          return $http.post(apiBaseUrl + "/accounts/" + account._id + "/settings/office-ip-address", {
            officeIpAddresses: ipAddresses,
          });
        };
      })(this);
      this.deleteOfficeIpAddress = (function (_this) {
        return function (ipAddressId) {
          return $http["delete"](
            apiBaseUrl + "/accounts/" + account._id + "/settings/office-ip-address/" + ipAddressId,
          );
        };
      })(this);
      this.setStyleVersion = (function (_this) {
        return function (styleVersion) {
          return $http
            .post(apiBaseUrl + "/accounts/" + account._id + "/settings/style-version", {
              styleVersion: styleVersion,
            })
            .then(function () {
              return (account.styleVersion = styleVersion);
            });
        };
      })(this);
      this.updateDefaultLinkPreview = (function (_this) {
        return function (defaultLinkPreview) {
          return $http.post(apiBaseUrl + "/accounts/" + account._id + "/settings/link-preview", defaultLinkPreview);
        };
      })(this);
    }

    return AccountService;
  })();
}.call(this));
