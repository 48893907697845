"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.chartExampleData = void 0;
exports.chartExampleData = [
    {
        time: "2023-04-10T00:00:00.000Z",
        metrics: {
            pageStatusMetrics: {
                draft: {
                    totalCount: 30,
                    periodCount: 15,
                    totalValue: 0,
                    periodValue: 0,
                },
                live: {
                    totalCount: 12,
                    periodCount: 5,
                    totalValue: 0,
                    periodValue: 0,
                },
                accepted: {
                    totalCount: 55,
                    periodCount: 45,
                    totalValue: 0,
                    periodValue: 0,
                },
                declined: {
                    totalCount: 12,
                    periodCount: 10,
                    totalValue: 0,
                    periodValue: 0,
                },
            },
            pageVelocity: {
                closeAcceptRate: 0,
                averagePageValue: 0,
                velocity: 22000,
                pageCycleTimes: {
                    averageLife: 0,
                    averageCreate: 0,
                    averageClose: 8,
                },
            },
        },
    },
    {
        time: "2023-04-17T00:00:00.000Z",
        metrics: {
            pageStatusMetrics: {
                draft: {
                    totalCount: 22,
                    periodCount: 15,
                    totalValue: 0,
                    periodValue: 0,
                },
                live: {
                    totalCount: 18,
                    periodCount: 5,
                    totalValue: 0,
                    periodValue: 0,
                },
                accepted: {
                    totalCount: 35,
                    periodCount: 25,
                    totalValue: 0,
                    periodValue: 0,
                },
                declined: {
                    totalCount: 8,
                    periodCount: 6,
                    totalValue: 0,
                    periodValue: 0,
                },
            },
            pageVelocity: {
                closeAcceptRate: 0,
                averagePageValue: 0,
                velocity: 30000,
                pageCycleTimes: {
                    averageLife: 0,
                    averageCreate: 0,
                    averageClose: 18,
                },
            },
        },
    },
    {
        time: "2023-04-24T00:00:00.000Z",
        metrics: {
            pageStatusMetrics: {
                draft: {
                    totalCount: 18,
                    periodCount: 5,
                    totalValue: 0,
                    periodValue: 0,
                },
                live: {
                    totalCount: 24,
                    periodCount: 9,
                    totalValue: 0,
                    periodValue: 0,
                },
                accepted: {
                    totalCount: 15,
                    periodCount: 5,
                    totalValue: 0,
                    periodValue: 0,
                },
                declined: {
                    totalCount: 10,
                    periodCount: 8,
                    totalValue: 0,
                    periodValue: 0,
                },
            },
            pageVelocity: {
                closeAcceptRate: 0,
                averagePageValue: 0,
                velocity: 55000,
                pageCycleTimes: {
                    averageLife: 0,
                    averageCreate: 0,
                    averageClose: 3,
                },
            },
        },
    },
    {
        time: "2023-05-01T00:00:00.000Z",
        metrics: {
            pageStatusMetrics: {
                draft: {
                    totalCount: 28,
                    periodCount: 22,
                    totalValue: 0,
                    periodValue: 0,
                },
                live: {
                    totalCount: 15,
                    periodCount: 13,
                    totalValue: 0,
                    periodValue: 0,
                },
                accepted: {
                    totalCount: 33,
                    periodCount: 25,
                    totalValue: 0,
                    periodValue: 0,
                },
                declined: {
                    totalCount: 21,
                    periodCount: 10,
                    totalValue: 0,
                    periodValue: 0,
                },
            },
            pageVelocity: {
                closeAcceptRate: 0,
                averagePageValue: 0,
                velocity: 45000,
                pageCycleTimes: {
                    averageLife: 0,
                    averageCreate: 0,
                    averageClose: 11,
                },
            },
        },
    },
    {
        time: "2023-05-08T00:00:00.000Z",
        metrics: {
            pageStatusMetrics: {
                draft: {
                    totalCount: 45,
                    periodCount: 42,
                    totalValue: 0,
                    periodValue: 0,
                },
                live: {
                    totalCount: 65,
                    periodCount: 32,
                    totalValue: 0,
                    periodValue: 0,
                },
                accepted: {
                    totalCount: 21,
                    periodCount: 15,
                    totalValue: 0,
                    periodValue: 0,
                },
                declined: {
                    totalCount: 9,
                    periodCount: 7,
                    totalValue: 0,
                    periodValue: 0,
                },
            },
            pageVelocity: {
                closeAcceptRate: 0,
                averagePageValue: 0,
                velocity: 82953,
                pageCycleTimes: {
                    averageLife: 0,
                    averageCreate: 0,
                    averageClose: 5,
                },
            },
        },
    },
    {
        time: "2023-05-15T00:00:00.000Z",
        metrics: {
            pageStatusMetrics: {
                draft: {
                    totalCount: 35,
                    periodCount: 6,
                    totalValue: 0,
                    periodValue: 0,
                },
                live: {
                    totalCount: 36,
                    periodCount: 8,
                    totalValue: 0,
                    periodValue: 0,
                },
                accepted: {
                    totalCount: 26,
                    periodCount: 25,
                    totalValue: 0,
                    periodValue: 0,
                },
                declined: {
                    totalCount: 12,
                    periodCount: 11,
                    totalValue: 0,
                    periodValue: 0,
                },
            },
            pageVelocity: {
                closeAcceptRate: 0.7,
                averagePageValue: 34500,
                velocity: 86940,
                pageCycleTimes: {
                    averageLife: 0,
                    averageCreate: 0,
                    averageClose: 10,
                },
            },
        },
    },
];
