"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoLinkExpiryStore = void 0;
var lodash_1 = require("lodash");
var mobx_1 = require("mobx");
var AutoLinkExpiryStore = (function () {
    function AutoLinkExpiryStore(projectId, link, projectApiService) {
        var _this = this;
        this.link = link;
        this.debouncedSave = (0, lodash_1.debounce)(function () {
            var _a = _this.autoLinkExpiry, expiresOn = _a.expiresOn, migrated = _a.migrated, autoLinkExpiryParams = __rest(_a, ["expiresOn", "migrated"]);
            projectApiService.saveAutoLinkExpiry(projectId, link.secret, autoLinkExpiryParams);
        }, 500);
    }
    Object.defineProperty(AutoLinkExpiryStore.prototype, "enabled", {
        get: function () {
            return this.autoLinkExpiry.enabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoLinkExpiryStore.prototype, "startDate", {
        get: function () {
            if (this.autoLinkExpiry.startDate == undefined) {
                return undefined;
            }
            return new Date(this.autoLinkExpiry.startDate);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoLinkExpiryStore.prototype, "expireAfterDays", {
        get: function () {
            return this.autoLinkExpiry.expireAfterDays;
        },
        enumerable: false,
        configurable: true
    });
    AutoLinkExpiryStore.prototype.setEnabled = function (enabled) {
        this.autoLinkExpiry.enabled = enabled;
        this.debouncedSave();
    };
    AutoLinkExpiryStore.prototype.startExpiryTimer = function () {
        this.autoLinkExpiry.startDate = new Date().toString();
        this.debouncedSave();
    };
    AutoLinkExpiryStore.prototype.setExpireAfterDays = function (days) {
        this.autoLinkExpiry.expireAfterDays = days;
        this.debouncedSave();
    };
    AutoLinkExpiryStore.prototype.clearStartDate = function () {
        this.autoLinkExpiry.startDate = null;
    };
    Object.defineProperty(AutoLinkExpiryStore.prototype, "autoLinkExpiry", {
        get: function () {
            if (this.link.autoLinkExpiry == undefined) {
                this.link.autoLinkExpiry = { enabled: false };
            }
            return this.link.autoLinkExpiry;
        },
        enumerable: false,
        configurable: true
    });
    var _a;
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], AutoLinkExpiryStore.prototype, "startDate", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], AutoLinkExpiryStore.prototype, "setEnabled", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], AutoLinkExpiryStore.prototype, "startExpiryTimer", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], AutoLinkExpiryStore.prototype, "setExpireAfterDays", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], AutoLinkExpiryStore.prototype, "clearStartDate", null);
    return AutoLinkExpiryStore;
}());
exports.AutoLinkExpiryStore = AutoLinkExpiryStore;
