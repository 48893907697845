// Generated by CoffeeScript 1.12.7
(function () {
  var ApiService,
    SettingsTelemetry,
    controller,
    createAuthorizeIntegrationModal,
    createAuthorizeIntegrationUi,
    mountReactComponent;

  require("SettingsAngular/Settings.ng.html");

  require("SettingsAngular/Integrations/AuthorizeIntegration.ng.html");

  ApiService = require("@CommonFrontend/CreatePageFlow/ApiService").ApiService;

  createAuthorizeIntegrationModal = require("./Integrations/AuthorizeIntegration").createAuthorizeIntegrationModal;

  createAuthorizeIntegrationUi = require("./Integrations/AuthorizeIntegration").createAuthorizeIntegrationUi;

  mountReactComponent = require("MountReactComponent/MountReactComponent")["default"];

  SettingsTelemetry = require("../Settings/Telemetry")["default"];

  controller = function ($scope, $rootScope, $stateParams, $log) {
    var di;
    di = $rootScope.di.child();
    return di.invoke(function (make, invoke, $state, state) {
      var availableSettingItems, item, items, mainNav, menu, menuItems, menuName, ref, showSetting, telemetry;
      mainNav = invoke(require("../Settings/Navigation")["default"]);
      telemetry = make(SettingsTelemetry);
      showSetting = function (item) {
        telemetry.trackSectionViewed(item.name);
        if (item.onClick != null) {
          return item.onClick();
        } else {
          controller = make(item.controller);
          $scope.ui.activeSetting = item.name;
          $scope.ui.currentSetting = controller.ui;
          if ($state.params.section !== item.id) {
            return state.silentlyGo("settings", {
              section: item.id,
            });
          }
        }
      };
      availableSettingItems = invoke(require("./SettingItems"));
      $scope.ui = {
        mainNav: mainNav,
        activeSetting: false,
        menus: availableSettingItems,
        showSetting: showSetting,
        isActiveSetting: function (item) {
          return $scope.ui.activeSetting === item.name;
        },
      };
      if ((ref = $stateParams.section) != null ? ref.length : void 0) {
        menu = availableSettingItems;
        items = [];
        for (menuName in menu) {
          menuItems = menu[menuName];
          items = items.concat(menuItems);
        }
        item = _.find(items, {
          id: $stateParams.section,
        });
        if (item != null) {
          return showSetting(item);
        } else if ($stateParams.section !== "all") {
          return $log.error("Bad settings section given: " + $stateParams.section);
        }
      }
    });
  };

  module.exports = angular.module("qwilr.settings.main", ["ui.router"]).config(function ($stateProvider) {
    return $stateProvider
      .state("settings", {
        url: "/settings/:section",
        templateUrl: "SettingsAngular/Settings.ng.html",
        data: {
          pageTitle: "Settings",
        },
        controller: controller,
      })
      .state("settingsWithSubsection", {
        url: "/settings/:section/:subsection",
        templateUrl: "SettingsAngular/Settings.ng.html",
        data: {
          pageTitle: "Settings",
        },
        controller: controller,
      })
      .state("authorizeIntegration", {
        url: "/settings/integrations/:integration/authorize",
        templateUrl: "SettingsAngular/Integrations/AuthorizeIntegration.ng.html",
        controller: function ($scope, $rootScope, $stateParams) {
          var account, apiBaseUrl, apiService, createUi, di, integrationName, modal;
          integrationName = $stateParams.integration;
          di = $rootScope.di.child();
          createUi = di.get("createUi");
          apiBaseUrl = di.get("apiBaseUrl");
          modal = di.get("modal");
          account = di.get("account");
          apiService = new ApiService(apiBaseUrl, account._id);
          $scope.ui = {
            authorizeIntegration: mountReactComponent(createUi)(createAuthorizeIntegrationUi()),
          };
          return modal.showSimpleModal({
            containerClassName: "standalone-create-page-app-modal",
            isEscapable: false,
            body: createAuthorizeIntegrationModal(integrationName, apiService),
          });
        },
      });
  });
}.call(this));
