"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInvalidClickToAdd = void 0;
var slate_1 = require("slate");
var SchemaType_1 = require("../Schemas/SchemaType");
var Utils_1 = require("../Utils");
var InvalidReason_1 = require("./InvalidReason");
var EditorWidgetRegistry_1 = require("../WidgetRegistry/EditorWidgetRegistry");
var checkPathValidity = function (path, editor) {
    var schema = editor.schema;
    var node = slate_1.Node.get(editor, path);
    if (slate_1.Editor.isEditor(node)) {
        if ((0, EditorWidgetRegistry_1.getConfigByType)("image").validEditors.includes(schema.name)) {
            return;
        }
        else {
            return InvalidReason_1.InvalidReason.InvalidEditorChild;
        }
    }
    if (slate_1.Element.isElement(node) && (0, Utils_1.isElementNode)(node)) {
        var imageConfig = (0, EditorWidgetRegistry_1.getConfigByType)("image");
        var shouldCheckIfValidContainer = false;
        var ewrConfig = (0, EditorWidgetRegistry_1.getGenericConfigByType)(node.type, true);
        if (ewrConfig) {
            if (ewrConfig.nodeType === "layoutElement") {
                shouldCheckIfValidContainer = true;
            }
        }
        else {
            var blockConfig = schema.blocks[node.type];
            if ((0, SchemaType_1.isContainerBlockSchema)(blockConfig)) {
                shouldCheckIfValidContainer = true;
            }
        }
        if (shouldCheckIfValidContainer) {
            if (imageConfig.validContainers.includes(node.type)) {
                return;
            }
            else {
                return InvalidReason_1.InvalidReason.InvalidContainerChild;
            }
        }
    }
    return checkPathValidity(slate_1.Path.parent(path), editor);
};
var isInvalidClickToAdd = function (_a) {
    var selection = _a.selection, editor = _a.editor, projectStore = _a.projectStore, blockStore = _a.blockStore;
    if (projectStore.isBlockLocked(blockStore.blockId)) {
        return InvalidReason_1.InvalidReason.BlockLocked;
    }
    var checkBothEdges = slate_1.Range.isExpanded(selection);
    var anchor = selection.anchor, focus = selection.focus;
    if (!slate_1.Node.has(editor, anchor.path)) {
        return InvalidReason_1.InvalidReason.LocationNoLongerExists;
    }
    if (checkBothEdges && !slate_1.Node.has(editor, focus.path)) {
        return InvalidReason_1.InvalidReason.LocationNoLongerExists;
    }
    var anchorInvalidReason = checkPathValidity(anchor.path, editor);
    if (anchorInvalidReason) {
        return anchorInvalidReason;
    }
    if (checkBothEdges) {
        var focusInvalidReason = checkPathValidity(focus.path, editor);
        if (focusInvalidReason) {
            return focusInvalidReason;
        }
    }
    return;
};
exports.isInvalidClickToAdd = isInvalidClickToAdd;
