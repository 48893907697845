// Generated by CoffeeScript 1.12.7

/*
Drift - live chat solution
 */

(function () {
  var DriftIntegration;

  module.exports = DriftIntegration = (function () {
    function DriftIntegration(createUi, genericIntegration) {
      genericIntegration.build.call(this, "drift", "Drift App ID", require("./Drift.html"));
    }

    return DriftIntegration;
  })();
}.call(this));
