// Generated by CoffeeScript 1.12.7
(function () {
  var AcceptBlockModel, AcceptEmail, cloneDeep;

  cloneDeep = require("lodash").cloneDeep;

  AcceptBlockModel = require("@CommonFrontendBackend/BlockModels/Accept")["default"];

  module.exports = AcceptEmail = (function () {
    function AcceptEmail(createUi, block) {
      var base;
      if ((base = block.settings).email == null) {
        base.email = cloneDeep(AcceptBlockModel.settings.email);
      }
      this.ui = createUi(require("Project/Modules/Accept/Controls/AcceptEmail/AcceptEmail.html"), {
        settings: block.settings.email,
      });
    }

    return AcceptEmail;
  })();
}.call(this));
