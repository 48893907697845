(function () {
  var InviteErrorModal, InviteUserPopup, InviteUserService, validator;

  validator = require("validator");

  InviteUserService = require("SettingsAngular/InviteUserService/InviteUserService.ts")["default"];

  InviteErrorModal = require("SettingsAngular/InviteUserService/InviteErrorModal.ts")["default"];

  /*
   @param options [Object]
    - inputEmail [String] Constructed with an input email
    - invitesSentCallback [Function] Called when `sendInvites` is successful
   */

  module.exports = InviteUserPopup = (function () {
    function InviteUserPopup(
      createUi,
      $q,
      account,
      showDecideModal,
      apiBaseUrl,
      telemetry,
      $timeout,
      user,
      userList,
      userIconFactory,
      keyboard,
      speechBubble,
      simpleDropdownFactory,
      make,
      invoke,
      utils,
      usingFeature,
      rolesService,
      AsyncButton,
      hint,
      options,
      hasSeatsRemaining,
      getTeam,
      getDomId,
      onInvited,
    ) {
      var domId, inviteErrorModal, inviteUserService, inviteUsers, showHint;
      domId = getDomId() || "" + utils.uniqueId(8);
      inviteUserService = make(InviteUserService);
      inviteErrorModal = invoke(InviteErrorModal);
      showHint = (function (_this) {
        return function (hintText) {
          var targetSelector;
          targetSelector = ".invite-user-popup .roles-dropdown-wrapper";
          domId = getDomId();
          if (domId) {
            targetSelector = "#" + domId + ".invite-user-popup .roles-dropdown-wrapper";
          }
          return hint
            .large({
              targetSelector: targetSelector,
              hint: hintText,
            })
            .show();
        };
      })(this);
      this.ui = createUi(require("./InviteUserPopup.html"), {
        domId: domId,
        flags: {
          sending: false,
          sent: false,
        },
        showInviteButton: true,
        userList: userList,
        userIcon: userIconFactory.createEmpty({
          backgroundColor: "#EBF0F3",
        }),
        showRolesDropdown: true,
        isAdmin: rolesService.isAdmin(user),
        hasSeatsRemaining: hasSeatsRemaining,
        role: "creator",
        rolesDropdown: simpleDropdownFactory.create(
          [
            {
              name: "Admin",
              id: "admin",
            },
            {
              name: "Creator",
              id: "creator",
            },
          ],
          {
            id: "creator",
          },
          (function (_this) {
            return function (item) {
              return (_this.ui.role = item.id);
            };
          })(this),
        ),
        showHint: (function (_this) {
          return function ($event) {
            var hintText;
            if (_this.ui.role === "creator") {
              hintText = "Creators can start new pages but you'll need share existing ones with them";
            } else if (_this.ui.role === "admin") {
              hintText = "Admins can make accounts changes and edit all pages";
            }
            return showHint(hintText);
          };
        })(this),
        sendInvites: (function (_this) {
          return function (emails, newInviteCount, role, team) {
            _this.ui.flags.sending = true;
            return $q
              .resolve()
              .then(function () {
                return inviteUserService.inviteWithModal(emails, newInviteCount, role, team);
              })
              .then(function () {
                var email, i, len, match;
                _this.ui.flags.sending = false;
                _this.ui.flags.sent = true;
                for (i = 0, len = emails.length; i < len; i++) {
                  email = emails[i];
                  match = account.pendingInvitations.find(function (invite) {
                    return invite.email === email;
                  });
                  if (match && match.teams) {
                    telemetry.trackOnly(
                      "Teams Updated",
                      {
                        user_teams_membership_type: role,
                        user_teams_membership_origin: "search invite",
                        user_teams_membership_number: match.teams.length,
                      },
                      {
                        blockIntercom: true,
                      },
                    );
                  }
                }
                onInvited();
                return $timeout(function () {
                  return (_this.ui.flags.sent = false);
                }, 1000);
              })
              ["catch"](function (response) {
                var errMessage;
                _this.ui.flags.sending = false;
                _this.ui.flags.sent = false;
                errMessage = response.data.error.message;
                return inviteErrorModal(errMessage).then(function (modalDidShow) {
                  if (!modalDidShow) {
                    return _this.ui.showSpeechBubble(errMessage);
                  }
                });
                return $q.reject();
              });
          };
        })(this),
        showInviteUsers: (function (_this) {
          return function () {
            return _this.ui.hasSeatsRemaining() && !_this.ui.flags.sending && !_this.ui.flags.sent;
          };
        })(this),
        showSpeechBubble: (function (_this) {
          return function (message, target) {
            return speechBubble.alert({
              targetSelector: ".invite-user-popup .invite-button",
              message: message,
            });
          };
        })(this),
      });
      inviteUsers = (function (_this) {
        return function () {
          return $q.resolve().then(function () {
            var email, existingUsersEmails, existingUsersInvites, message, newEmails, oldEmails, pendingEmails;
            email = options.inputEmail;
            if (!validator.isEmail(email)) {
              _this.ui.showSpeechBubble("Invalid email address: " + email);
              return $q.reject();
            }
            existingUsersEmails = _.map(userList, function (user) {
              return user.email;
            });
            existingUsersInvites = _.intersection([email], existingUsersEmails);
            if (existingUsersInvites.length > 0) {
              message = existingUsersInvites[0] + " is already in the account";
              _this.ui.showSpeechBubble(message);
              return $q.reject();
            }
            pendingEmails = _.map(account.pendingInvitations, "email");
            newEmails = _.difference([email], pendingEmails);
            oldEmails = _.intersection([email], pendingEmails);
            if (oldEmails.length) {
              message = "";
              if (oldEmails.length === 1) {
                message = oldEmails[0] + " has already been invited. Would you like to send a new invite?";
              } else {
                message =
                  "Invites have already been sent to these email addresses: " +
                  oldEmails.join(", ") +
                  ". Would you like to send new invites?";
              }
              return showDecideModal({
                title: "Re-send Invite?",
                explainer: message,
              }).then(function (shouldResend) {
                if (shouldResend) {
                  return _this.ui.sendInvites([email], newEmails.length, _this.ui.role, getTeam());
                } else if (newEmails.length) {
                  return _this.ui.sendInvites(newEmails, newEmails.length, _this.ui.role, getTeam());
                }
              });
            } else {
              return _this.ui.sendInvites([email], 1, _this.ui.role, getTeam());
            }
          });
        };
      })(this);
      this.ui.inviteButton = new AsyncButton(inviteUsers, "Invite", {
        initialState: "idle",
        size: "mini",
      });
    }

    return InviteUserPopup;
  })();
}.call(this));
