"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Table = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var react_1 = __importStar(require("react"));
var styles = __importStar(require("./TimeSpentAndInteractions.css"));
var Table = function (_a) {
    var columns = _a.columns, data = _a.data, activeTableIndices = _a.activeTableIndices, defaultSortState = _a.defaultSortState, localStorageKey = _a.localStorageKey, onTableHover = _a.onTableHover;
    var _b = __read((0, react_1.useState)(function () {
        var storedValue = tryLocalStorage(columns, localStorageKey);
        return storedValue !== null && storedValue !== void 0 ? storedValue : defaultSortState;
    }), 2), sortState = _b[0], setSortState = _b[1];
    (0, react_1.useEffect)(function () {
        if (localStorageKey) {
            localStorage.setItem(localStorageKey, JSON.stringify(sortState));
        }
    }, [localStorageKey, sortState]);
    var sortedData = getSortedData(data, sortState, columns);
    function onSortClick(columnIndex) {
        var clickedColumn = columns[columnIndex];
        if (!clickedColumn.sortOptions)
            return;
        var newSortOptionIndex;
        if (sortState.columnIndex === columnIndex) {
            newSortOptionIndex = (sortState.sortOptionIndex + 1) % clickedColumn.sortOptions.length;
        }
        else {
            newSortOptionIndex = 0;
        }
        setSortState({ columnIndex: columnIndex, sortOptionIndex: newSortOptionIndex });
    }
    return (react_1.default.createElement("div", { className: styles.tableContainer },
        react_1.default.createElement("table", { className: styles.table },
            react_1.default.createElement("thead", null,
                react_1.default.createElement("tr", null, columns.map(function (_a, index) {
                    var heading = _a.heading, className = _a.className, sortOptions = _a.sortOptions;
                    return (react_1.default.createElement("th", { key: index, className: className },
                        react_1.default.createElement(kaleidoscope_1.Button, { className: styles.tableHeaderButton, onClick: sortOptions ? function () { return onSortClick(index); } : undefined, type: kaleidoscope_1.ButtonType.Tertiary, size: kaleidoscope_1.ButtonSize.Small, disabled: !sortOptions },
                            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "xxs" },
                                react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "s" }, heading),
                                sortOptions &&
                                    sortState.columnIndex === index &&
                                    sortOptions.length > sortState.sortOptionIndex && (react_1.default.createElement(kaleidoscope_1.Tooltip, { content: sortOptions[sortState.sortOptionIndex].description }, sortOptions[sortState.sortOptionIndex].icon))))));
                }))),
            react_1.default.createElement("tbody", null, sortedData.map(function (rowData) { return (react_1.default.createElement("tr", { key: rowData.index, className: styles.tableRow({
                    active: activeTableIndices.includes(rowData.index),
                }), onMouseEnter: function () { return onTableHover(rowData.index); }, onMouseLeave: function () { return onTableHover(null); } }, columns.map(function (column, index) {
                var renderedCell = column.renderCellFn(rowData);
                return (react_1.default.createElement("td", { key: index, className: styles.tableCell }, typeof renderedCell === "string" ? react_1.default.createElement(kaleidoscope_1.Text, { size: "s" }, renderedCell) : renderedCell));
            }))); })))));
};
exports.Table = Table;
function getSortedData(data, sortState, columns) {
    var compareFn = getCompareFunction(sortState, columns);
    var sortedData = compareFn ? data.slice().sort(compareFn) : data;
    return sortedData;
}
function getCompareFunction(sortState, columns) {
    var _a, _b;
    var sortOptions = (_a = columns[sortState.columnIndex]) === null || _a === void 0 ? void 0 : _a.sortOptions;
    if (sortOptions) {
        return (_b = sortOptions[sortState.sortOptionIndex]) === null || _b === void 0 ? void 0 : _b.compareFn;
    }
    return null;
}
function tryLocalStorage(columns, localStorageKey) {
    if (!localStorageKey)
        return null;
    var storedValue = localStorage.getItem(localStorageKey);
    if (!storedValue)
        return null;
    try {
        var parsedValue = JSON.parse(storedValue);
        var columnIndex = parsedValue.columnIndex, sortOptionIndex = parsedValue.sortOptionIndex;
        if (typeof columnIndex === "number" && typeof sortOptionIndex === "number") {
            if (columnIndex < 0 || columnIndex >= columns.length)
                return null;
            var sortOptions = columns[columnIndex].sortOptions;
            if (!sortOptions || sortOptionIndex < 0 || sortOptionIndex >= sortOptions.length)
                return null;
            return { columnIndex: columnIndex, sortOptionIndex: sortOptionIndex };
        }
    }
    catch (e) {
        return null;
    }
    return null;
}
