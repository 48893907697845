"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.triggerEventWithPath = exports.updateButton = void 0;
var WidgetManager_1 = require("Project/QwilrEditor/WidgetManager");
var updateButton = function (widgetManager, element, propertiesToUpdate) {
    var eventData = {
        action: "update",
        path: widgetManager.getPath(element),
        toUpdate: propertiesToUpdate,
    };
    widgetManager.triggerEvent(new WidgetManager_1.WidgetEvent("button", eventData));
};
exports.updateButton = updateButton;
var triggerEventWithPath = function (widgetManager, element, action) {
    var data = {
        action: action,
        path: widgetManager.getPath(element),
    };
    widgetManager.triggerEvent(new WidgetManager_1.WidgetEvent("button", data));
};
exports.triggerEventWithPath = triggerEventWithPath;
