"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var EditActionBarStore = (function () {
    function EditActionBarStore(savedBlock, savedBlockService, saveOrchestrator, overlayStack) {
        var _this = this;
        this.savedBlockService = savedBlockService;
        this.saveOrchestrator = saveOrchestrator;
        this.overlayStack = overlayStack;
        this.hasUnsavedChanges = false;
        this.lastValidBlockName = "";
        this.isSavingChanges = false;
        this.setLockEditingSetting = function (newState) { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.saveOrchestrator.requiresInitialSave) return [3, 2];
                        return [4, this.savedBlockService.setLockEditingSetting(this.blockId, newState)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        (0, mobx_1.runInAction)(function () {
                            _this._isEditingIsLockedToAdmins = newState;
                        });
                        return [2];
                }
            });
        }); };
        this.saveChanges = function () { return __awaiter(_this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.hasUnsavedChanges = false;
                        this.isSavingChanges = true;
                        return [4, this.saveOrchestrator.saveChanges(this.isEditingIsLockedToAdmins, this.blockName)];
                    case 1:
                        _a.sent();
                        setTimeout(function () {
                            (0, mobx_1.runInAction)(function () {
                                _this.isSavingChanges = false;
                            });
                        }, 3500);
                        return [2];
                }
            });
        }); };
        this.delete = function () { return __awaiter(_this, void 0, Promise, function () {
            var didDelete;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.saveOrchestrator.requiresInitialSave) return [3, 1];
                        this.overlayStack.closeCurrent();
                        return [3, 3];
                    case 1: return [4, this.savedBlockService.delete(this.blockId)];
                    case 2:
                        didDelete = _a.sent();
                        if (didDelete) {
                            this.overlayStack.closeCurrent();
                        }
                        _a.label = 3;
                    case 3: return [2];
                }
            });
        }); };
        this.onBlockNameChange = function (name) {
            _this.blockName = name;
            _this.hasUnsavedChanges = true;
        };
        this.onBlockNameInputBlur = function () {
            if (_this.blockName) {
                _this.lastValidBlockName = _this.blockName;
            }
            else {
                _this.blockName = _this.lastValidBlockName;
            }
        };
        this._blockName = savedBlock.savedBlockName;
        this._isEditingIsLockedToAdmins = savedBlock.$editingLockedToAdmins;
        this.blockId = savedBlock.id;
        this.lastValidBlockName = savedBlock.savedBlockName;
    }
    Object.defineProperty(EditActionBarStore.prototype, "blockName", {
        get: function () {
            return this._blockName;
        },
        set: function (name) {
            this._blockName = name;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EditActionBarStore.prototype, "isNewSavedBlock", {
        get: function () {
            return this.saveOrchestrator.requiresInitialSave;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(EditActionBarStore.prototype, "isEditingIsLockedToAdmins", {
        get: function () {
            return this._isEditingIsLockedToAdmins;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", String)
    ], EditActionBarStore.prototype, "_blockName", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], EditActionBarStore.prototype, "hasUnsavedChanges", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], EditActionBarStore.prototype, "_isEditingIsLockedToAdmins", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], EditActionBarStore.prototype, "lastValidBlockName", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], EditActionBarStore.prototype, "isSavingChanges", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [String])
    ], EditActionBarStore.prototype, "blockName", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], EditActionBarStore.prototype, "isNewSavedBlock", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], EditActionBarStore.prototype, "setLockEditingSetting", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], EditActionBarStore.prototype, "isEditingIsLockedToAdmins", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], EditActionBarStore.prototype, "saveChanges", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], EditActionBarStore.prototype, "delete", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], EditActionBarStore.prototype, "onBlockNameChange", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], EditActionBarStore.prototype, "onBlockNameInputBlur", void 0);
    return EditActionBarStore;
}());
exports.default = EditActionBarStore;
