"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PdfPreviewOverlay = void 0;
var AttachReactComponent_1 = require("./AttachReactComponent");
var PdfPreviewOverlay = (function () {
    function PdfPreviewOverlay(di, createUi, $http, overlayStack, BackButton, previewLink, pdfUrl, oneTimeTokenUrl, telemetry, user, usingFeature) {
        var _this = this;
        var publicLink = "".concat(previewLink, "?print=1");
        var Editor = require("./PdfPreviewOverlay.html");
        var closePreview = function () {
            overlayStack.closeCurrent();
        };
        var getBackButton = function () {
            return BackButton(closePreview);
        };
        var updateDefaultPreviewSettings = function (newPreviewSetting) {
            Object.keys(newPreviewSetting).forEach(function (key) {
                user.settings.pdfPreview[key] = newPreviewSetting[key];
            });
        };
        var getOneTimeToken = function () {
            return $http.get(oneTimeTokenUrl).then(function (res) {
                return res.data;
            });
        };
        var trackPdfPreviewAction = function () {
            telemetry.trackOnly("Project Share PDF Previewed", {});
        };
        this.ui = createUi(Editor, {
            previewContainer: di.convoke(AttachReactComponent_1.attachReactComponent, null, {
                publicLink: publicLink,
                pdfUrl: pdfUrl,
                defaultPreviewSettings: user.settings.pdfPreview,
                getOneTimeToken: getOneTimeToken,
                trackPdfPreviewAction: trackPdfPreviewAction,
                updateDefaultPreviewSettings: updateDefaultPreviewSettings,
                usingFeature: usingFeature,
                closePreview: closePreview,
            }),
            back: getBackButton(),
        });
        this.show = function () {
            return overlayStack.add({
                contentUi: _this.ui,
                options: {
                    centerContent: false,
                    hideCloseButton: true,
                },
            });
        };
    }
    return PdfPreviewOverlay;
}());
exports.PdfPreviewOverlay = PdfPreviewOverlay;
