// Generated by CoffeeScript 1.12.7
(function () {
  var Bootstrapper,
    GatekeeperPage,
    GatekeeperRoute,
    GatekeeperSignupParamKeys,
    UserSignupEntryPoints,
    extractQueryParam,
    ref,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  UserSignupEntryPoints = require("@CommonFrontendBackend/User/types").UserSignupEntryPoints;

  (ref = require("Gatekeeper/Gatekeeper")),
    (GatekeeperPage = ref.GatekeeperPage),
    (GatekeeperSignupParamKeys = ref.GatekeeperSignupParamKeys);

  GatekeeperRoute = require("Gatekeeper/GatekeeperMount").GatekeeperRoute;

  extractQueryParam = require("Gatekeeper/ExtractQueryParam")["default"];

  module.exports = Bootstrapper = (function () {
    function Bootstrapper(di, $log, scopeUi, $http, $location, $window, $state, $rootScope) {
      var getInitialData, hasLoadedInitialData, hasStartedLoadingInitialData, initiator, ref1, wiringConfig;
      hasLoadedInitialData = false;
      hasStartedLoadingInitialData = false;
      initiator = require("Common/Bootstrapper/InitialiseApp/InitialiseApp");
      wiringConfig = require("./Wiring");
      $rootScope.$on("$stateChangeStart", function (e, toState, toParams) {
        var ref1, ref2, ref3;
        if (toState) {
          if (toState.name === GatekeeperPage.Signup) {
            document.title = "Qwilr - Signup";
          } else if ((ref1 = toState.name) === GatekeeperPage.Login || ref1 === GatekeeperPage.StanfordLogin) {
            document.title = "Qwilr - Login";
          } else if ((ref2 = toState.name) === GatekeeperPage.ResetPassword || ref2 === GatekeeperPage.SetNewPassword) {
            document.title = "Qwilr - Reset Password";
          } else if (toState.name !== "project") {
            document.title = "Qwilr";
          }
        }
        if (((ref3 = toState.name), indexOf.call(Object.values(GatekeeperPage), ref3) >= 0)) {
          scopeUi.appViewState = {
            initialDataLoaded: true,
          };
          return;
        }
        getInitialData(toState, toParams);
        if (!hasLoadedInitialData) {
          return e.preventDefault();
        }
      });
      getInitialData = function (toState, toParams) {
        var toOptions;
        if (toState == null) {
          toState = "dashboard";
        }
        if (toParams == null) {
          toParams = {};
        }
        toOptions = {};
        if (hasLoadedInitialData || hasStartedLoadingInitialData) {
          return;
        }
        hasStartedLoadingInitialData = true;
        performance.mark("initialDataStart");
        return $http.get(QwilrConfig.apiBaseUrl + "/initial-data").then(function (result) {
          var entryPoint, injectables, linkSecret, redirectToUrl, ref1, withPage, withTemplate;
          performance.mark("initialDataFetched");
          hasLoadedInitialData = true;
          injectables = di.invoke(wiringConfig, null, result.data);
          di.load(injectables);
          di.invoke(initiator, null, {
            scopeUi: scopeUi,
          });
          if (sessionStorage.url) {
            redirectToUrl = sessionStorage.url;
            sessionStorage.isRedirecting = true;
            delete sessionStorage.url;
            setTimeout(function () {
              return ($window.location.href = redirectToUrl);
            }, 500);
            return setTimeout(function () {
              return delete sessionStorage.isRedirecting;
            }, 10000);
          } else {
            if (
              ((ref1 = $location.path().replace(/\/$/, "")) === GatekeeperRoute.Login ||
                ref1 === GatekeeperRoute.Signup ||
                ref1 === GatekeeperRoute.StanfordLogin) &&
              toState !== $state.current.name
            ) {
              /* 
                Below we handle instances where existing users, who are currently logged in, 
                have come from an external Qwilr property which creates/clones Qwilr pages. 
                For example, hitting "use template" on the marketing website template gallery -
                or spinning up a new document with the Page Builder AI project. The intuitive behaviour
                is for these pages to appear in their existing account (ie. versus spinning up pages in a new account). 
                So, when these users are redirected to the Client app after creating/cloning 
                a project we bounce them to the sample page creator.
               */
              entryPoint = extractQueryParam(window.location, GatekeeperSignupParamKeys.EntryPoint);
              withTemplate = entryPoint === UserSignupEntryPoints.Template;
              withPage = entryPoint === UserSignupEntryPoints.PageBuilderAI;
              if (withTemplate || withPage) {
                if (withTemplate) {
                  linkSecret = extractQueryParam(window.location, GatekeeperSignupParamKeys.WithTemplate);
                }
                if (withPage) {
                  linkSecret = extractQueryParam(window.location, GatekeeperSignupParamKeys.WithPage);
                }
                toState = "sample-page-creator";
                toParams = {
                  linkSecret: linkSecret,
                  pageSource: entryPoint,
                };
                toOptions = {
                  location: "replace",
                };
              }
              $state.go(toState, toParams, toOptions);
            }
          }
        });
      };
      if (
        (ref1 = $location.path().replace(/\/$/, "")) === GatekeeperRoute.ResetPassword ||
        ref1 === GatekeeperRoute.SetNewPassword
      ) {
        scopeUi.appViewState = {
          initialDataLoaded: true,
        };
        return;
      } else {
        getInitialData();
      }
    }

    return Bootstrapper;
  })();
}.call(this));
