"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateManagementDashboardSources = void 0;
var inversify_react_1 = require("inversify-react");
var EventNamesForTelemetry;
(function (EventNamesForTelemetry) {
    EventNamesForTelemetry["TemplateManagementOpened"] = "Template Management Opened";
    EventNamesForTelemetry["TemplatePreviewViewed"] = "Template Preview Viewed";
    EventNamesForTelemetry["TemplatePreviewClosed"] = "Template Preview Closed";
    EventNamesForTelemetry["TemplateCloned"] = "Template Cloned";
    EventNamesForTelemetry["TemplateDeleted"] = "Template Deleted";
    EventNamesForTelemetry["TemplateRestored"] = "Template Restored";
    EventNamesForTelemetry["TemplateArchived"] = "Template Archived";
    EventNamesForTelemetry["TemplateUnarchived"] = "Template Unarchived";
})(EventNamesForTelemetry || (EventNamesForTelemetry = {}));
var TemplateManagementDashboardSources;
(function (TemplateManagementDashboardSources) {
    TemplateManagementDashboardSources["PreviewModalOptionsMenu"] = "preview modal options menu";
    TemplateManagementDashboardSources["PreviewModalEditButton"] = "preview modal edit button";
    TemplateManagementDashboardSources["TemplatesListOptionsMenu"] = "templates list option menu";
    TemplateManagementDashboardSources["DragAndDrop"] = "drag and drop";
    TemplateManagementDashboardSources["TemplateListItem"] = "template list item";
    TemplateManagementDashboardSources["RestoreButton"] = "restore button";
    TemplateManagementDashboardSources["CloneNotificationButton"] = "clone notification button";
    TemplateManagementDashboardSources["CreateTemplateButton"] = "create template button";
})(TemplateManagementDashboardSources || (exports.TemplateManagementDashboardSources = TemplateManagementDashboardSources = {}));
var TEMPLATES_MANAGEMENT_DASHBOARD = "Templates Management Dashboard";
var options = {
    blockIntercom: true,
};
exports.default = (function () {
    var telemetry = (0, inversify_react_1.useInjection)("telemetry");
    return {
        templateManagementOpened: function () {
            telemetry.trackOnly(EventNamesForTelemetry.TemplateManagementOpened, {}, options);
        },
        previewViewed: function (templateId) {
            telemetry.trackOnly(EventNamesForTelemetry.TemplatePreviewViewed, {
                event_origin: TEMPLATES_MANAGEMENT_DASHBOARD,
                template_id: templateId,
            }, options);
        },
        previewClosed: function (templateId) {
            telemetry.trackOnly(EventNamesForTelemetry.TemplatePreviewClosed, {
                event_origin: TEMPLATES_MANAGEMENT_DASHBOARD,
                template_id: templateId,
            }, options);
        },
        templateDeleted: function (templateId, source) {
            telemetry.trackOnly(EventNamesForTelemetry.TemplateDeleted, {
                event_origin: TEMPLATES_MANAGEMENT_DASHBOARD,
                template_id: templateId,
                source: source,
            }, options);
        },
        templateCloned: function (templateId, newTemplateId, source) {
            telemetry.trackOnly(EventNamesForTelemetry.TemplateCloned, {
                event_origin: TEMPLATES_MANAGEMENT_DASHBOARD,
                template_id: templateId,
                new_template_id: newTemplateId,
                source: source,
            }, options);
        },
        templateRestored: function (templateId, source) {
            telemetry.trackOnly(EventNamesForTelemetry.TemplateRestored, {
                event_origin: TEMPLATES_MANAGEMENT_DASHBOARD,
                template_id: templateId,
                source: source,
            }, options);
        },
        templateArchived: function (templateId, source) {
            telemetry.trackOnly(EventNamesForTelemetry.TemplateArchived, {
                event_origin: TEMPLATES_MANAGEMENT_DASHBOARD,
                template_id: templateId,
                source: source,
            }, options);
        },
        templateUnarchived: function (templateId, source) {
            telemetry.trackOnly(EventNamesForTelemetry.TemplateUnarchived, {
                event_origin: TEMPLATES_MANAGEMENT_DASHBOARD,
                template_id: templateId,
                source: source,
            }, options);
        },
        assignmentModalOpened: function (templateId, assignedAutomationsCount) {
            telemetry.trackOnly("Automation Assignment Modal Opened", {
                event_origin: TEMPLATES_MANAGEMENT_DASHBOARD,
                template_id: templateId,
                assigned_automations_count: assignedAutomationsCount,
            }, options);
        },
        automationAssignmentToggled: function (assigned) {
            telemetry.trackOnly("Automation Assignment Toggled", {
                event_origin: TEMPLATES_MANAGEMENT_DASHBOARD,
                assigned: assigned,
            }, options);
        },
        automationExpandToggled: function (automationId, expanded) {
            telemetry.trackOnly("Automation Expand Toggled", {
                event_origin: TEMPLATES_MANAGEMENT_DASHBOARD,
                automation_id: automationId,
                expanded: expanded,
            }, options);
        },
        assignmentModalConfirmed: function (templateId, assignedAutomationsCount) {
            telemetry.trackOnly("Automation Assignment Modal Confirmed", {
                event_origin: TEMPLATES_MANAGEMENT_DASHBOARD,
                template_id: templateId,
                assigned_automations_count: assignedAutomationsCount,
            }, options);
        },
        assignmentModalCancelled: function (templateId) {
            telemetry.trackOnly("Automation Assignment Modal Cancelled", {
                event_origin: TEMPLATES_MANAGEMENT_DASHBOARD,
                template_id: templateId,
            }, options);
        },
    };
});
