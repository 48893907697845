// Generated by CoffeeScript 1.12.7
(function () {
  var Point, Range, Selection, assert, isBlock, isInsertable, ref;

  (ref = require("../Text/Helpers")), (assert = ref.assert), (isBlock = ref.isBlock), (isInsertable = ref.isInsertable);

  Point = qed.Point;

  Range = qed.Range;

  module.exports = Selection = (function () {
    function Selection(editor) {
      var getInsertablesInSelection, selectInsertables, tidySelection;
      editor.addListener({
        onSelection: function (selection) {
          var point, range, ref1;
          range = selection.getRange();
          if (!selection.withinEditor()) {
            return false;
          }
          if (range != null ? range.isCollapsed() : void 0) {
            point = range.anchor;
            selection = [];
            while (point.node !== editor.currentElem()) {
              if (((ref1 = point.node.tagName) != null ? ref1.toLowerCase() : void 0) === "insertable") {
                selection = [point.node.getAttribute("widget-id")];
                break;
              }
              point = qed.Point.before(point.containingElement());
            }
            if (editor.widgetTracker != null) {
              editor.widgetTracker.setSelected(selection);
            }
          } else {
            tidySelection(range);
            selectInsertables(editor.selection());
          }
          return false;
        },
        onMouseDown: function (e) {
          this.anchorPosition = {
            x: e.pageX,
            y: e.pageY,
          };
          return false;
        },
        onMouseUp: function (e) {
          this.anchorPosition = {};
          return false;
        },
        onSelectionDrag: function (e) {
          selectInsertables(editor.selection());
          return false;
        },
      });
      tidySelection = function (range) {
        var anchor, focus;
        focus = range.focus.leftNormalized();
        while (focus.type === "start" && !isBlock(focus.node)) {
          focus = Point.before(focus.node).leftNormalized();
        }
        if (
          range.isOrdered() &&
          focus.type === "start" &&
          isBlock(focus.node) &&
          focus.node.previousElementSibling != null
        ) {
          anchor = range.anchor;
          focus = Point.end(focus.node.previousElementSibling);
          range = new Range(range.anchor, focus.leftNormalized());
          editor.selection().setEndpoints(range.anchor, range.focus);
        }
      };
      selectInsertables = function (selection) {
        var iterator, range, ref1, selectedIds;
        range = selection.getRange();
        if (!range) {
          return false;
        }
        iterator = range.iterateRight();
        selectedIds = getInsertablesInSelection(iterator);
        return (ref1 = editor.widgetTracker) != null ? ref1.setSelected(selectedIds) : void 0;
      };
      getInsertablesInSelection = function (iterator) {
        var elem, selectedIds, txt;
        selectedIds = [];
        while (true) {
          elem = iterator.enterElement();
          if (elem) {
            if (isInsertable(elem)) {
              selectedIds.push(elem.getAttribute("widget-id"));
            }
            iterator.leaveElement();
            continue;
          }
          txt = iterator.skipText();
          if (txt) {
            continue;
          }
          elem = iterator.leaveElement();
          if (elem) {
            if (selectedIds.length === 0 && isInsertable(elem)) {
              selectedIds.push(elem.getAttribute("widget-id"));
            }
            continue;
          }
          assert(iterator.isAtEnd());
          break;
        }
        return selectedIds;
      };
    }

    return Selection;
  })();
}.call(this));
