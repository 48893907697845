// Generated by CoffeeScript 1.12.7

/*
  This component is a vendor panel allowing you to select a font from Google
  Fonts. It hits the Google Fonts api endpoint to get a font list and then
  passes that list to the FontList component.

  It also has an internal search box and filter dropdown used to send a filter
  function to the FontList.

  It calls options.onSelected when the font is finished uploading.

  The font list will only be requested when .activate() is called so that
  loading the panel doesn't automatically fire off requests.
 */

(function () {
  var GoogleFontPanel, WebFont, cachedFontList;

  cachedFontList = [];

  WebFont = require("webfontloader");

  module.exports = GoogleFontPanel = (function () {
    function GoogleFontPanel(createUi, make, $http, $q, dropdown, googleFontsApiKey, options) {
      var apiEndpoint,
        categories,
        dropdownOptions,
        filteredList,
        k,
        madeDropdown,
        madeFontList,
        onSelected,
        selectedCategories,
        updateSelected;
      filteredList = [];
      apiEndpoint = "https://www.googleapis.com/webfonts/v1/webfonts?key=" + googleFontsApiKey;
      onSelected = options.onSelected;
      categories = [
        {
          name: "Serif",
          internal: "serif",
        },
        {
          name: "Sans Serif",
          internal: "sans-serif",
        },
        {
          name: "Display",
          internal: "display",
        },
        {
          name: "Handwritten",
          internal: "handwriting",
        },
        {
          name: "Monospaced",
          internal: "monospace",
        },
      ];
      selectedCategories = {
        serif: true,
        "sans-serif": true,
        display: true,
      };
      updateSelected = (function (_this) {
        return function (selected) {
          var cat, i, len;
          selectedCategories = {};
          for (i = 0, len = selected.length; i < len; i++) {
            cat = selected[i];
            selectedCategories[cat.internal] = true;
          }
          return _this.ui.searchUpdated();
        };
      })(this);
      dropdownOptions = {
        labelProperty: "internal",
        valueProperty: "internal",
        displayClass: "filter-dropdown",
        initialSelection: (function () {
          var results;
          results = [];
          for (k in selectedCategories) {
            results.push(k);
          }
          return results;
        })(),
        onClick: updateSelected,
      };
      madeDropdown = make(dropdown, {
        items: categories,
        options: dropdownOptions,
      });
      madeDropdown.ui.currentItem = function () {
        return "Filter";
      };
      madeFontList = make(require("../../FontList/FontList"), {
        options: {
          onSelected: function (font) {
            return onSelected({
              friendlyName: font.family,
              name: font.family,
              typeface: font.category.toLowerCase(),
              vendor: "GoogleFonts",
            });
          },
          loadFont: function (fontFamily) {
            var deferred;
            deferred = $q.defer();
            WebFont.load({
              classes: false,
              google: {
                families: [fontFamily],
                text: fontFamily,
              },
              fontactive: function () {
                return deferred.resolve();
              },
              fontinactive: function () {
                return _.remove(cachedFontList, {
                  family: fontFamily,
                });
              },
            });
            return deferred.promise;
          },
        },
      });
      this.ui = createUi(require("./Google.html"), {
        error: "",
        searchTerm: "",
        dropdown: madeDropdown.ui,
        fontList: madeFontList.ui,
        searchUpdated: (function (_this) {
          return function () {
            var filter, searchTerm;
            searchTerm = _this.ui.searchTerm.toLowerCase();
            if (!searchTerm) {
              filter = function (font) {
                return selectedCategories[font.category];
              };
            } else {
              filter = function (font) {
                return selectedCategories[font.category] && font.family.toLowerCase().indexOf(searchTerm) >= 0;
              };
            }
            return madeFontList.updateFilter(filter);
          };
        })(this),
      });
      this.activate = (function (_this) {
        return function () {
          if (!cachedFontList.length) {
            return $http
              .get(apiEndpoint, {
                withCredentials: false,
                headers: {
                  "X-Requested-With": void 0,
                  "X-XSRF-TOKEN": void 0,
                  "x-pusher-socket-id": void 0,
                },
              })
              .then(function (result) {
                cachedFontList = _.filter(result.data.items, function (font) {
                  return _.includes(font.subsets, "latin") && _.includes(font.variants, "regular");
                });
                return madeFontList.updateFonts(cachedFontList);
              })
              ["catch"](function (err) {
                var ref, ref1;
                return (_this.ui.error =
                  (ref = err.data) != null ? ((ref1 = ref.error) != null ? ref1.message : void 0) : void 0);
              });
          } else {
            return madeFontList.updateFonts(cachedFontList);
          }
        };
      })(this);
    }

    return GoogleFontPanel;
  })();
}.call(this));
