"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var IsDomElement_1 = require("Utils/IsDomElement/IsDomElement");
var CopyActions_1 = require("./CopyActions");
var CopyActions_2 = require("./CopyActions");
exports.default = (function (di) {
    return {
        dragFromTable: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Drag from a table")
            .isEvent(EditorTypes_1.EventType.DragStart)
            .inTableCell()
            .selectionIsExpanded()
            .doAction(function (event, editor) {
            event.preventDefault();
            return true;
        }),
        dragText: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Drag text")
            .isEvent(EditorTypes_1.EventType.DragStart)
            .doAction(function (event, editor) {
            if (!event.target || !(0, IsDomElement_1.isDOMElement)(event.target)) {
                return;
            }
            var hasTargetTagName = event.target.tagName && event.target.tagName.toUpperCase;
            if (hasTargetTagName && event.target.tagName.toUpperCase() !== "IMG") {
                event.preventDefault();
                return true;
            }
            else {
                return;
            }
        }),
        copyCatchAll: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("copy catch all")
            .isEvent(EditorTypes_1.EventType.Copy)
            .selectionIsExpanded()
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, CopyActions_1.copyCatchAll)(editor, event);
            return true;
        }),
        cutCatchAll: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("cut catch all")
            .isEvent(EditorTypes_1.EventType.Cut)
            .selectionIsExpanded()
            .doAction(function (event, editor) {
            event.preventDefault();
            return (0, CopyActions_2.cutCatchAll)(editor, event);
        }),
    };
});
