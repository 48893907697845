"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTempId = exports.getTempId = void 0;
var crypto_1 = require("crypto");
var getTempId = function () {
    return "temp".concat((0, crypto_1.randomBytes)(16).toString("base64"));
};
exports.getTempId = getTempId;
var isTempId = function (id) {
    return !!id.match(/^temp/);
};
exports.isTempId = isTempId;
