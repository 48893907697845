"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlocksExploreDataStore = void 0;
var Types_1 = require("Project/ContentSidebar/Types");
var SidebarExploreDataStore_1 = require("../SidebarExploreDataStore");
var mobx_1 = require("mobx");
var generateThumbnailId_1 = require("@CommonFrontendBackend/SavedBlocks/generateThumbnailId");
var lodash_1 = require("lodash");
var jsonData = require("./data.json");
var qwilrBlocksAccountId = "669496baf42d9472ef5ae677";
var carouselOrder = [
    "introductions",
    "executive summaries",
    "product features",
    "timelines",
    "return on investment",
    "meet the team",
    "pricing & quotes",
    "agreements & acceptance",
    "terms & conditions",
];
var BlocksExploreDataStore = (function (_super) {
    __extends(BlocksExploreDataStore, _super);
    function BlocksExploreDataStore() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.searchFilter = "";
        _this.fetchNextIncrement = function () {
        };
        _this.getThumbnailUrl = function (thumbnailApiBaseUrl, savedBlockId) {
            var thumbnailId = (0, generateThumbnailId_1.generateThumbnailId)(qwilrBlocksAccountId, savedBlockId);
            return "".concat(thumbnailApiBaseUrl, "/v1/artefact/").concat(thumbnailId, "/thumbnail");
        };
        return _this;
    }
    Object.defineProperty(BlocksExploreDataStore.prototype, "loadedAssets", {
        get: function () {
            var _this = this;
            var blocks = jsonData.blocks.map(function (jsonBlock) { return ({
                name: jsonBlock.savedBlockName,
                tags: [],
                type: Types_1.AssetType.Block,
                content: jsonBlock,
            }); });
            if (!!this.searchFilter) {
                return blocks.filter(function (block) { return block.name.toLowerCase().includes(_this.searchFilter.toLowerCase().trim()); });
            }
            else {
                return blocks;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BlocksExploreDataStore.prototype, "groupedAssets", {
        get: function () {
            var categories = Object.entries((0, lodash_1.groupBy)(this.loadedAssets, "content.savedBlockName"));
            var sortedCategories = (0, lodash_1.sortBy)(categories, function (_a) {
                var _b = __read(_a, 1), category = _b[0];
                var sortOrder = (0, lodash_1.indexOf)(carouselOrder, category.toLowerCase());
                return sortOrder !== -1 ? sortOrder : 999;
            });
            return sortedCategories;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BlocksExploreDataStore.prototype, "getFeaturedAssets", {
        get: function () {
            return this.groupedAssets.map(function (_a) {
                var _b = __read(_a, 2), category = _b[0], assets = _b[1];
                return assets[0];
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(BlocksExploreDataStore.prototype, "hasUnloadedAssets", {
        get: function () {
            return false;
        },
        enumerable: false,
        configurable: true
    });
    BlocksExploreDataStore.prototype.search = function (search) {
        this.searchFilter = search;
    };
    BlocksExploreDataStore.prototype.clearSearch = function () {
        this.searchFilter = "";
    };
    var _a;
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], BlocksExploreDataStore.prototype, "searchFilter", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], BlocksExploreDataStore.prototype, "loadedAssets", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object),
        __metadata("design:paramtypes", [])
    ], BlocksExploreDataStore.prototype, "groupedAssets", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], BlocksExploreDataStore.prototype, "getFeaturedAssets", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], BlocksExploreDataStore.prototype, "fetchNextIncrement", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], BlocksExploreDataStore.prototype, "search", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], BlocksExploreDataStore.prototype, "clearSearch", null);
    return BlocksExploreDataStore;
}(SidebarExploreDataStore_1.BaseExploreDataStore));
exports.BlocksExploreDataStore = BlocksExploreDataStore;
