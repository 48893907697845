"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useViewersListFilters = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var StringManipulater_1 = require("@CommonFrontend/Utils/StringManipulater");
var LocationFlag_1 = require("Analytics/Page/Components/LocationFlag");
var styles = __importStar(require("./ViewersListFilters.css"));
function useViewersListFilters(viewers) {
    var _a = __read((0, react_1.useState)(function () { return new Set(); }), 2), selectedCountryCodes = _a[0], setSelectedCountryCodes = _a[1];
    var countryCodes = (0, react_1.useMemo)(function () {
        var e_1, _a;
        var set = new Set();
        try {
            for (var viewers_1 = __values(viewers), viewers_1_1 = viewers_1.next(); !viewers_1_1.done; viewers_1_1 = viewers_1.next()) {
                var v = viewers_1_1.value;
                if (v.countryCode) {
                    set.add(v.countryCode);
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (viewers_1_1 && !viewers_1_1.done && (_a = viewers_1.return)) _a.call(viewers_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return Array.from(set).sort();
    }, [viewers]);
    function onCountryCodeToggle(countryCode) {
        var newState = new Set(selectedCountryCodes);
        if (selectedCountryCodes.has(countryCode)) {
            newState.delete(countryCode);
        }
        else {
            newState.add(countryCode);
        }
        setSelectedCountryCodes(newState);
    }
    function onClearFilters() {
        setSelectedCountryCodes(new Set());
    }
    var filteredData = filterData(viewers, selectedCountryCodes);
    var noViewers = viewers.length === 0;
    return {
        filteredData: filteredData,
        filtersButton: (react_1.default.createElement(FiltersButton, { countryCodes: countryCodes, selectedCountryCodes: selectedCountryCodes, disabled: noViewers, onCountryCodeToggle: onCountryCodeToggle, onClearFilters: onClearFilters })),
    };
}
exports.useViewersListFilters = useViewersListFilters;
function filterData(viewers, selectedCountryCodes) {
    if (selectedCountryCodes.size === 0) {
        return viewers;
    }
    return viewers.filter(function (v) { return v.countryCode && selectedCountryCodes.has(v.countryCode); });
}
var FiltersButton = function (_a) {
    var countryCodes = _a.countryCodes, selectedCountryCodes = _a.selectedCountryCodes, disabled = _a.disabled, onCountryCodeToggle = _a.onCountryCodeToggle, onClearFilters = _a.onClearFilters;
    var regionNamesEnglish = new Intl.DisplayNames(["en"], { type: "region" });
    var numSelectedFilters = selectedCountryCodes.size;
    var buttonText = numSelectedFilters === 0 ? "All viewers" : (0, StringManipulater_1.pluralise)(numSelectedFilters, "filter");
    return (react_1.default.createElement(kaleidoscope_1.Popover, { maxHeight: 400, button: function (buttonProps) { return (react_1.default.createElement(kaleidoscope_1.Button, __assign({ className: styles.filterButton, type: kaleidoscope_1.ButtonType.Tertiary, size: kaleidoscope_1.ButtonSize.Small, disabled: disabled }, buttonProps),
            react_1.default.createElement(icons_1.Filter, null),
            buttonText)); }, autoStack: true },
        react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xs" },
            react_1.default.createElement(kaleidoscope_1.Button, { disabled: numSelectedFilters === 0, type: kaleidoscope_1.ButtonType.Secondary, size: kaleidoscope_1.ButtonSize.Small, width: "full", onClick: onClearFilters }, "Clear filters"),
            react_1.default.createElement(kaleidoscope_1.Stack, { gap: "s" }, countryCodes.map(function (code) {
                var _a;
                var countryName;
                try {
                    countryName = (_a = regionNamesEnglish.of(code)) !== null && _a !== void 0 ? _a : code;
                }
                catch (_b) {
                    countryName = code;
                }
                return (react_1.default.createElement(kaleidoscope_1.Stack, { key: code, direction: "horizontal", align: "center", gap: "xs" },
                    react_1.default.createElement(kaleidoscope_1.Checkbox, { id: code, label: countryName, checked: selectedCountryCodes.has(code), size: "s", onChange: function () { return onCountryCodeToggle(code); } }),
                    react_1.default.createElement(LocationFlag_1.LocationFlag, { countryCode: code })));
            })))));
};
