// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (createUi, item, make, itemViewState, currency) {
    var RateSelectorController, rateSelector;
    RateSelectorController = require("./RateSelector/RateSelector");
    rateSelector = make(RateSelectorController, {
      item: item,
    });
    return (this.ui = createUi(require("Project/Modules/Quote/Item/Rate/Rate.html"), {
      item: item,
      itemViewState: itemViewState,
      rateSelector: rateSelector.ui,
      isFixedCost: item.type === "fixedCost",
      isRateItem: item.type !== "fixedCost",
      currency: currency,
    }));
  };
}.call(this));
