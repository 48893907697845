"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StyleableAction = void 0;
var BehaviourBuilder_1 = require("../BehaviourBuilder");
var EditorTypes_1 = require("../../EditorTypes");
var StyleableActions = __importStar(require("./StyleableActions"));
var StyleableAction;
(function (StyleableAction) {
    StyleableAction["ClearElementStyles"] = "clearElementStyles";
    StyleableAction["UpdateElementStyles"] = "updateElementStyles";
    StyleableAction["PropagateElementStyle"] = "propagateElementStyle";
    StyleableAction["RemoveElementStyle"] = "removeElementStyle";
})(StyleableAction || (exports.StyleableAction = StyleableAction = {}));
exports.default = (function (di) {
    return [
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("clearElementStyles")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "styleable", action: StyleableAction.ClearElementStyles })
            .doAction(function (event, editor) {
            StyleableActions.clearElementStyles(editor, event.data.path);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("updateElementStyles")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "styleable", action: StyleableAction.UpdateElementStyles })
            .doAction(function (event, editor) {
            var _a = event.data, path = _a.path, style = _a.style;
            StyleableActions.updateWidgetStyles(editor, path, style);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("propagateElementStyle")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "styleable", action: StyleableAction.PropagateElementStyle })
            .doAction(function (event, editor) {
            StyleableActions.clearWidgetOverrides(editor, event.data.path);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("removeElementStyle")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "styleable", action: StyleableAction.RemoveElementStyle })
            .doAction(function (event, editor) {
            StyleableActions.removeElementStyle(editor, event.data.path, event.data.stylePath);
            return true;
        }),
    ];
});
