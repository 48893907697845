// Generated by CoffeeScript 1.12.7
(function () {
  var CustomLabels;

  module.exports = CustomLabels = (function () {
    function CustomLabels(make, overlayStack) {
      this.show = function (onComplete) {
        var labelsList;
        labelsList = make(require("./CustomLabelsList"), {
          onComplete: onComplete,
        });
        return overlayStack.add({
          contentUi: labelsList.ui,
          options: {
            centerContent: false,
            hideCloseButton: true,
          },
        });
      };
    }

    return CustomLabels;
  })();
}.call(this));
