(function () {
  var CustomForm, FeatureFlag;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = CustomForm = (function () {
    function CustomForm(createUi, account, formBuilder, block, usingFeature, upseller, upsellerService, telemetry) {
      var assignForms, forms, ref;
      forms = [];
      assignForms = function () {
        return (forms = _.map(account.forms, function (form) {
          return {
            id: form.id,
            name: form.name,
          };
        }));
      };
      assignForms();
      this.ui = createUi(require("Project/Modules/Accept/Controls/CustomForm/CustomForm.html"), {
        usingCustomForms: usingFeature(FeatureFlag.AcceptCustomForms),
        settings: block.settings,
        customFormId: ((ref = block.settings.customForm) != null ? ref.id : void 0) || "",
        customForms: function () {
          return forms;
        },
        editForms: function () {
          return formBuilder.show();
        },
        selectForm: (function (_this) {
          return function (formId) {
            var form;
            if (formId === "") {
              _this.ui.settings.customForm = null;
              telemetry.trackOnly("Accept custom form selected", {
                form_id: "none",
              });
              return;
            }
            form = _.find(account.forms, {
              id: formId,
            });
            if (form != null) {
              _this.ui.settings.customForm = form;
              return telemetry.trackOnly("Accept custom form selected", {
                form_id: form.id,
                form_name: form.name,
              });
            }
          };
        })(this),
        customFormsUpsell: (function (_this) {
          return function () {
            return upseller.upsell({
              feature: "acceptCustomForms",
              featureLabel: "Custom Forms",
              customUpsellMessage:
                "Upgrade to add a custom form to collect information from your clients during the accept flow.",
            });
          };
        })(this),
        $init: function (scope) {
          scope.account = account;
          return scope.$watch(
            "account.forms",
            function (formsArray) {
              if (formsArray != null) {
                return assignForms();
              }
            },
            true,
          );
        },
      });
    }

    return CustomForm;
  })();
}.call(this));
