// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = [
    {
      name: "Project Created",
      description: "Whenever a project is created, whether by cloning, creating a blank project or from a template.",
      eventName: "projectCreated",
    },
    {
      name: "Project Published",
      description: "Whenever a project is made live.",
      eventName: "projectPublished",
    },
    {
      name: "Project First Viewed",
      description:
        "When a project is viewed for the first time by someone who isn't the owner, or belongs to the same account as the owner (i.e. your teammates).",
      eventName: "projectFirstViewed",
    },
    {
      name: "Project Accepted",
      description: "When a project with a quote block is accepted.",
      eventName: "projectAccepted",
    },
  ];
}.call(this));
