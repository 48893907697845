"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var CommentCollaborationTypes_1 = require("@CommonFrontendBackend/Comments/Types/CommentCollaborationTypes");
var ChannelUtil_1 = require("@CommonFrontendBackend/Utils/PusherChannelUtil/ChannelUtil");
var CommentsCollaborationConsumer = (function () {
    function CommentsCollaborationConsumer(pusher, commentThreadStore, projectId, userList, currentUserId, blockId) {
        var _this = this;
        this.unsubscribe = function () {
            if (_this.channel) {
                _this.channel.unbind();
                _this.pusher.unsubscribe(_this.projectBlockChannel);
            }
        };
        this.onCommentAdded = function (commentCreated) {
            var threadId = commentCreated.threadId, createdBy = commentCreated.createdBy;
            if (_this.currentUserId === createdBy) {
                return;
            }
            try {
                var commentGroupStore = _this.getCommentGroupStore(threadId);
                var clientSideComment = _this.transformToClientSideComment(commentCreated);
                commentGroupStore.addCommentLocally(clientSideComment);
            }
            catch (e) {
                console.error(e);
            }
        };
        this.onCommentDeleted = function (commentDeleted) {
            var creatorId = commentDeleted.creatorId, threadId = commentDeleted.threadId, commentId = commentDeleted.commentId;
            if (_this.currentUserId === creatorId) {
                return;
            }
            try {
                var commentGroupStore = _this.getCommentGroupStore(threadId);
                commentGroupStore.deleteCommentLocally(commentId);
            }
            catch (e) {
                console.error(e);
            }
        };
        this.onCommentUpdated = function (commentUpdated) {
            var createdBy = commentUpdated.createdBy, threadId = commentUpdated.threadId, _id = commentUpdated._id, content = commentUpdated.content, mentions = commentUpdated.mentions;
            if (_this.currentUserId === createdBy) {
                return;
            }
            try {
                var commentGroupStore = _this.getCommentGroupStore(threadId);
                commentGroupStore.editCommentLocally(_id, content, mentions);
            }
            catch (e) {
                console.error(e);
            }
        };
        this.onThreadDeleted = function (threadUpdated) {
            var updaterId = threadUpdated.updaterId, threadId = threadUpdated.threadId;
            if (_this.currentUserId === updaterId) {
                return;
            }
            try {
                var commentGroupStore = _this.getCommentGroupStore(threadId);
                commentGroupStore.deleteThreadLocally();
            }
            catch (e) {
                console.error(e);
            }
        };
        this.onThreadStatusChange = function (isResolved, threadUpdated) {
            var updaterId = threadUpdated.updaterId, threadId = threadUpdated.threadId;
            if (_this.currentUserId === updaterId) {
                return;
            }
            try {
                var commentGroupStore = _this.getCommentGroupStore(threadId);
                commentGroupStore.updateCommentThreadStatus(isResolved);
            }
            catch (e) {
                console.error(e);
            }
        };
        this.onThreadCreated = function (threadCreated) { return __awaiter(_this, void 0, void 0, function () {
            var creatorId, threadId, e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        creatorId = threadCreated.creatorId, threadId = threadCreated.threadId;
                        if (this.currentUserId === creatorId) {
                            return [2];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.commentThreadStore.load(threadId)];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        e_1 = _a.sent();
                        console.error(e_1);
                        return [3, 4];
                    case 4: return [2];
                }
            });
        }); };
        this.transformToClientSideComment = function (comment) {
            var commentAuthor = _this.userList.find(function (user) { return user._id == comment.createdBy; });
            var formattedComment = {
                id: comment._id,
                message: comment.content,
                createdBy: comment.createdBy,
                lastEditedAt: new Date(comment.updatedAt),
                createdAt: new Date(comment.createdAt),
                mentions: comment.mentions,
                user: commentAuthor,
            };
            return formattedComment;
        };
        this.getCommentGroupStore = function (threadId) {
            var commentGroupStore = _this.commentThreadStore.commentThreadsById[threadId];
            if (!commentGroupStore) {
                throw new Error("comment group store not found with thread id ".concat(threadId));
            }
            return commentGroupStore;
        };
        if (!blockId) {
            throw new Error("Attempted to instantiate a CommentsCollaborationConsumer without a blockId");
        }
        this.pusher = pusher;
        this.commentThreadStore = commentThreadStore;
        this.projectBlockChannel = (0, ChannelUtil_1.buildChannelName)(projectId, blockId);
        this.userList = userList;
        this.currentUserId = currentUserId;
    }
    CommentsCollaborationConsumer.prototype.subscribe = function () {
        var _this = this;
        this.channel = this.pusher.subscribe(this.projectBlockChannel);
        this.channel.bind(CommentCollaborationTypes_1.CommentCollaborationEvent.Comment_Added, function (commentAdded) {
            _this.onCommentAdded(commentAdded);
        });
        this.channel.bind(CommentCollaborationTypes_1.CommentCollaborationEvent.Comment_Updated, function (commentUpdated) {
            _this.onCommentUpdated(commentUpdated);
        });
        this.channel.bind(CommentCollaborationTypes_1.CommentCollaborationEvent.Comment_Deleted, function (commentDeleted) {
            _this.onCommentDeleted(commentDeleted);
        });
        this.channel.bind(CommentCollaborationTypes_1.CommentCollaborationEvent.Thread_Created, function (threadCreated) {
            _this.onThreadCreated(threadCreated);
        });
        this.channel.bind(CommentCollaborationTypes_1.CommentCollaborationEvent.Thread_Deleted, function (threadUpdated) {
            _this.onThreadDeleted(threadUpdated);
        });
        this.channel.bind(CommentCollaborationTypes_1.CommentCollaborationEvent.Thread_Resolved, function (threadUpdated) {
            _this.onThreadStatusChange(true, threadUpdated);
        });
        this.channel.bind(CommentCollaborationTypes_1.CommentCollaborationEvent.Thread_UnResolved, function (threadUpdated) {
            _this.onThreadStatusChange(false, threadUpdated);
        });
    };
    return CommentsCollaborationConsumer;
}());
exports.default = CommentsCollaborationConsumer;
