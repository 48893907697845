"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageTab = void 0;
var react_1 = __importStar(require("react"));
var mobx_react_1 = require("mobx-react");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var styles = __importStar(require("./ImageTab.css"));
var SidebarExploreContext_1 = require("../SidebarExploreContext");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var dynamic_1 = require("@vanilla-extract/dynamic");
var UnsplashImageTab_1 = require("./UnsplashImage/UnsplashImageTab");
var UnsplashCollectionTab_1 = require("./UnsplashCollection/UnsplashCollectionTab");
var DisplayMode;
(function (DisplayMode) {
    DisplayMode["Collections"] = "collections";
    DisplayMode["Images"] = "images";
    DisplayMode["Error"] = "error";
})(DisplayMode || (DisplayMode = {}));
exports.ImageTab = (0, mobx_react_1.observer)(function (_a) {
    var _b;
    var onClickToAdd = _a.onClickToAdd, onDragAssetEnd = _a.onDragAssetEnd, onDragAssetStart = _a.onDragAssetStart, openImageLibrary = _a.openImageLibrary, fileInputRef = _a.fileInputRef, onUpload = _a.onUpload;
    var searchTerm = (0, SidebarExploreContext_1.useSidebarExploreContext)().searchTerm;
    var _c = __read((0, react_1.useState)(DisplayMode.Collections), 2), displayMode = _c[0], setDisplayMode = _c[1];
    var _d = __read((0, react_1.useState)(""), 2), collectionId = _d[0], setCollectionId = _d[1];
    var _e = __read((0, react_1.useState)(""), 2), collectionName = _e[0], setCollectionName = _e[1];
    var footerRef = react_1.default.createRef();
    var _f = __read((0, react_1.useState)("0px"), 2), footerHeight = _f[0], setFooterHeight = _f[1];
    var headerRef = react_1.default.createRef();
    var _g = __read((0, react_1.useState)("0px"), 2), headerHeight = _g[0], setHeaderHeight = _g[1];
    (0, react_1.useEffect)(function () {
        if (footerRef.current) {
            setFooterHeight("".concat(footerRef.current.clientHeight, "px"));
        }
    }, [footerRef]);
    (0, react_1.useEffect)(function () {
        if (headerRef.current) {
            setHeaderHeight("".concat(headerRef.current.clientHeight, "px"));
        }
    }, [headerRef]);
    (0, react_1.useEffect)(function () {
        if (!!searchTerm) {
            setDisplayMode(DisplayMode.Images);
        }
        else {
            setDisplayMode(DisplayMode.Collections);
        }
    }, [searchTerm]);
    var selectCollection = function (collectionId, collectionName) {
        setCollectionId(collectionId);
        setCollectionName(collectionName);
        setDisplayMode(DisplayMode.Images);
    };
    var returnToCollectionsView = function () {
        setDisplayMode(DisplayMode.Collections);
    };
    var handleOnUpload = function () {
        onUpload();
    };
    var handleOpenImageLibrary = function () {
        openImageLibrary();
    };
    return (react_1.default.createElement("div", { style: (0, dynamic_1.assignInlineVars)((_b = {}, _b[styles.footerHeight] = footerHeight, _b[styles.imagesHeaderHeight] = headerHeight, _b)) },
        displayMode === DisplayMode.Collections && (react_1.default.createElement(UnsplashCollectionTab_1.UnsplashCollectionsTab, { selectCollection: selectCollection, showError: function () {
                setDisplayMode(DisplayMode.Error);
            } })),
        displayMode === DisplayMode.Images && (react_1.default.createElement(UnsplashImageTab_1.UnsplashImageTab, { onClickToAdd: onClickToAdd, onDragAssetStart: onDragAssetStart, onDragAssetEnd: onDragAssetEnd, collectionId: collectionId, collectionName: collectionName, returnToCollectionsView: returnToCollectionsView, headerRef: headerRef, showError: function () {
                setDisplayMode(DisplayMode.Error);
            } })),
        displayMode === DisplayMode.Error && (react_1.default.createElement(kaleidoscope_1.Stack, { paddingLeft: "l", paddingRight: "l", paddingTop: "s" },
            react_1.default.createElement(kaleidoscope_1.Alert, { heading: "Unsplash is temporarily unavailable", alertType: kaleidoscope_1.AlertType.Error }, "Please try again in a few minutes"))),
        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.footer, ref: footerRef, gap: "xs", paddingLeft: "l", paddingRight: "m", paddingTop: "s", paddingBottom: "s", direction: "horizontal" },
            react_1.default.createElement(kaleidoscope_1.Button, { className: styles.uploadButton, as: "label", type: kaleidoscope_1.ButtonType.Primary, onKeyDown: function (event) {
                    if (event.key === "Enter" || event.key === " ") {
                        fileInputRef.current.click();
                    }
                }, tabIndex: 0, "aria-label": "Upload image" },
                react_1.default.createElement(icons_1.Upload, null),
                "Upload Image",
                react_1.default.createElement("input", { type: "file", className: styles.uploadInput, ref: fileInputRef, accept: "image/*", onChange: handleOnUpload, tabIndex: -1 })),
            react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.Popout, null), onClick: handleOpenImageLibrary, "aria-label": "Open image library", tooltip: {
                    content: "Full library",
                } }))));
});
