// Generated by CoffeeScript 1.12.7
(function () {
  var IntercomIntegration,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = IntercomIntegration = (function () {
    function IntercomIntegration(createUi, settings, $http, $timeout, account, apiBaseUrl) {
      this.account = account;
      this.apiBaseUrl = apiBaseUrl;
      this.update = bind(this.update, this);
      this.disable = bind(this.disable, this);
      this.enable = bind(this.enable, this);
      this.isEnabled = bind(this.isEnabled, this);
      this.http = $http;
      this.ui = createUi(require("./Intercom.html"), {
        success: null,
        error: null,
        settings: settings,
        update: (function (_this) {
          return function (data) {
            _this.ui.error = "";
            _this.ui.success = "";
            return _this
              .update(data)
              .then(function () {
                _this.ui.success = "Your Intercom site token has been updated.";
                return $timeout(function () {
                  return (_this.ui.success = null);
                }, 2500);
              })
              ["catch"](function (response) {
                var ref, ref1;
                _this.ui.error =
                  ((ref = response.data) != null ? ((ref1 = ref.error) != null ? ref1.message : void 0) : void 0) ||
                  "There was an error updating your Intercom app key.";
                return $timeout(function () {
                  return (_this.ui.error = null);
                }, 5000);
              });
          };
        })(this),
      });
    }

    IntercomIntegration.prototype.isEnabled = function () {
      var ref, ref1;
      return (ref = this.account.integrations) != null
        ? (ref1 = ref.intercom) != null
          ? ref1.enabled
          : void 0
        : void 0;
    };

    IntercomIntegration.prototype.enable = function () {
      return this.http.post(this.apiBaseUrl + "/integration/intercom/enable").then(
        (function (_this) {
          return function () {
            var base;
            if ((base = _this.account.integrations).intercom == null) {
              base.intercom = {};
            }
            return (_this.account.integrations.intercom.enabled = true);
          };
        })(this),
      );
    };

    IntercomIntegration.prototype.disable = function () {
      return this.http.post(this.apiBaseUrl + "/integration/intercom/disable").then(
        (function (_this) {
          return function () {
            var base;
            if ((base = _this.account.integrations).intercom == null) {
              base.intercom = {};
            }
            _this.account.integrations.intercom.enabled = false;
            return (_this.account.integrations.intercom.settings = {});
          };
        })(this),
      );
    };

    IntercomIntegration.prototype.update = function (settings) {
      return this.http.post(this.apiBaseUrl + "/integration/intercom/update", settings).then(
        (function (_this) {
          return function () {
            var base;
            if ((base = _this.account.integrations).intercom == null) {
              base.intercom = {};
            }
            return (_this.account.integrations.intercom.settings = settings);
          };
        })(this),
      );
    };

    return IntercomIntegration;
  })();
}.call(this));
