"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var DomWalker_1 = require("./DomWalker");
var utils_1 = require("@qwilr/kaleidoscope/utils");
var lodash_1 = require("lodash");
var last = function (arr) {
    return arr[arr.length - 1];
};
var IGNORED_ELEMENTS = ["STYLE", "SCRIPT"];
var INLINE_ELEMENTS = ["SPAN", "A", "B", "I", "S", "STRONG", "EM"];
var nodeIsElement = function (node) { return node.nodeType === window.Node.ELEMENT_NODE; };
var nodeIsText = function (node) { return node.nodeType === window.Node.TEXT_NODE; };
var elementIsInline = function (element) { return INLINE_ELEMENTS.includes(element.tagName); };
var getTextStyle = function (textNode) {
    var parentElement = textNode.parentElement;
    if (!parentElement) {
        return {};
    }
    var elementStyle = window.getComputedStyle(parentElement);
    var style = {};
    if ((0, lodash_1.parseInt)(elementStyle.fontWeight, 10) > 400) {
        style.bold = true;
    }
    if (elementStyle.fontStyle === "italic") {
        style.italic = true;
    }
    if (elementStyle.textDecorationLine === "line-through") {
        style.strikethrough = true;
    }
    return style;
};
var isLastTokenText = function (tokens) {
    var lastToken = last(tokens);
    var lastTokenWasText = (lastToken === null || lastToken === void 0 ? void 0 : lastToken.type) === "inline" && (lastToken === null || lastToken === void 0 ? void 0 : lastToken.subType) === "text";
    return lastTokenWasText;
};
var ensureText = function (tokens) {
    if (!isLastTokenText(tokens)) {
        tokens.push({ type: "inline", subType: "text", content: "" });
    }
};
exports.default = (function (contentElem) {
    var _a;
    var walker = new DomWalker_1.DomWalker(contentElem, {
        initialContext: {
            tokens: [],
        },
        onEnter: function (node, context) {
            var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
            var tokens = (_a = context.get("tokens")) !== null && _a !== void 0 ? _a : [];
            if (nodeIsElement(node)) {
                if (IGNORED_ELEMENTS.includes(node.tagName)) {
                    return { skip: true };
                }
                else if (node.tagName === "H1") {
                    tokens.push({ type: "block", subType: "heading1" });
                    return { onExit: function () { return ensureText(tokens); } };
                }
                else if (node.tagName === "H2") {
                    tokens.push({ type: "block", subType: "heading2" });
                    return { onExit: function () { return ensureText(tokens); } };
                }
                else if (["H3", "H4", "H5", "H6"].includes(node.tagName)) {
                    tokens.push({ type: "block", subType: "paragraph" });
                    return { onExit: function () { return ensureText(tokens); } };
                }
                else if (node.tagName === "P") {
                    if (context.get("textContainer")) {
                        if (isLastTokenText(tokens)) {
                            tokens.push({ type: "inline", subType: "lineBreak" });
                        }
                        return;
                    }
                    tokens.push({ type: "block", subType: "paragraph" });
                    return { onExit: function () { return ensureText(tokens); } };
                }
                else if (node.tagName === "BLOCKQUOTE") {
                    context.set("textContainer", true);
                    tokens.push({ type: "block", subType: "blockQuote" });
                    return { onExit: function () { return ensureText(tokens); } };
                }
                else if (node.tagName === "A") {
                    var href = node.getAttribute("href");
                    if (href != null) {
                        context.set("href", href);
                    }
                }
                else if (node.tagName === "BR") {
                    tokens.push({ type: "inline", subType: "lineBreak" });
                }
                else if (node.tagName === "UL" || node.tagName === "OL") {
                    context.set("list", {
                        type: node.tagName === "OL" ? "ordered" : "unordered",
                        level: ((_c = (_b = context.get("list")) === null || _b === void 0 ? void 0 : _b.level) !== null && _c !== void 0 ? _c : 0) + 1,
                    });
                }
                else if (node.tagName === "LI") {
                    var level = (_e = (_d = context.get("list")) === null || _d === void 0 ? void 0 : _d.level) !== null && _e !== void 0 ? _e : 1;
                    var ariaLevel = (_f = node.getAttribute("aria-level")) !== null && _f !== void 0 ? _f : node.getAttribute("data-aria-level");
                    if (ariaLevel) {
                        level = (0, lodash_1.parseInt)(ariaLevel, 10);
                    }
                    context.set("textContainer", true);
                    tokens.push({
                        type: "block",
                        subType: "listItem",
                        listType: ((_g = context.get("list")) === null || _g === void 0 ? void 0 : _g.type) === "ordered" ? "number" : "bullet",
                        level: level,
                    });
                }
                else if (node.tagName === "TABLE") {
                    var table_1 = { rows: [] };
                    context.set("table", table_1);
                    return {
                        onExit: function () {
                            var _a, _b;
                            var columnCount = Math.max.apply(Math, __spreadArray([], __read(table_1.rows.map(function (row) { return row.length; })), false));
                            for (var rowIndex = 0; rowIndex < table_1.rows.length; rowIndex++) {
                                var row = table_1.rows[rowIndex];
                                var paddingCells = Array.from(Array(columnCount - row.length)).map(function () { return []; });
                                table_1.rows[rowIndex] = row.concat(paddingCells);
                            }
                            if (!((_b = (_a = table_1.rows) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b[0])) {
                                return;
                            }
                            var rows = table_1.rows.length;
                            var columns = table_1.rows[0].length;
                            var columnBorders = Array.from(Array(columns + 1)).map(function (_, i) { return i * (100 / columns); });
                            tokens.push({
                                type: "block",
                                subType: "table",
                                cells: table_1.rows,
                                rows: rows,
                                columns: columns,
                                columnBorders: columnBorders,
                            });
                        },
                    };
                }
                else if (node.tagName === "TR") {
                    (_h = context.get("table")) === null || _h === void 0 ? void 0 : _h.rows.push([]);
                }
                else if (node.tagName === "TD" || node.tagName === "TH") {
                    var table = context.get("table");
                    if (!table) {
                        return;
                    }
                    last(table.rows).push([]);
                    context.set("tokens", last(last(table.rows)));
                }
            }
            else if (nodeIsText(node)) {
                var text = (_j = node.textContent) !== null && _j !== void 0 ? _j : "";
                text = text
                    .replace(/\u00A0/g, " ")
                    .replace(/\u202F/g, " ")
                    .replace(/\uFEFF/g, "");
                var parent = node.parentNode;
                var parentIsInline = parent && nodeIsElement(parent) && elementIsInline(parent);
                if (!parentIsInline) {
                    var previousSibling = node.previousElementSibling;
                    var previousSiblingIsInline = !!previousSibling && nodeIsElement(previousSibling) && elementIsInline(previousSibling);
                    if (!previousSiblingIsInline) {
                        text = text.trimStart();
                    }
                    var nextSibling = node.nextElementSibling;
                    var nextSiblingIsInline = !!nextSibling && nodeIsElement(nextSibling) && elementIsInline(nextSibling);
                    if (!nextSiblingIsInline) {
                        text = text.trimEnd();
                    }
                }
                var onlyTextContainingNewlines = text.match(/^[\s\n]*\n[\s\n]*$/);
                if (onlyTextContainingNewlines) {
                    text = " ";
                }
                else {
                    text = text
                        .replace(/^[\s\n]*\n[\s\n]*/g, "")
                        .replace(/[\s\n]*\n[\s\n]*$/g, "")
                        .replace(/[\s\n]*\n[\s\n]*/g, " ");
                }
                if (text.length === 0) {
                    return;
                }
                if (tokens.length === 0) {
                    tokens.push({ type: "block", subType: "paragraph" });
                }
                var style = getTextStyle(node);
                var lastToken = last(tokens);
                var lastTokenWasSimilarText = (lastToken === null || lastToken === void 0 ? void 0 : lastToken.type) === "inline" &&
                    (lastToken === null || lastToken === void 0 ? void 0 : lastToken.subType) === "text" &&
                    (lastToken === null || lastToken === void 0 ? void 0 : lastToken.href) === context.get("href") &&
                    (0, lodash_1.isEqual)((_k = lastToken === null || lastToken === void 0 ? void 0 : lastToken.style) !== null && _k !== void 0 ? _k : {}, style);
                if (lastTokenWasSimilarText) {
                    lastToken.content += text;
                    return;
                }
                tokens.push(__assign(__assign({ type: "inline", subType: "text", content: text }, (0, utils_1.includeIf)(!(0, lodash_1.isEmpty)(style), { style: style })), (0, utils_1.includeIf)(!!context.get("href"), { href: context.get("href") })));
            }
            else {
                return { skip: true };
            }
        },
    });
    var finalContext = walker.walk();
    return (_a = finalContext.get("tokens")) !== null && _a !== void 0 ? _a : [];
});
