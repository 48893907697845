// Generated by CoffeeScript 1.12.7

/*
  The top-level image/video button for the block menu
 */

(function () {
  var MediaButton, Splash, Video, ref;

  (ref = require("Common/Utils/ImageImports")), (Splash = ref.Splash), (Video = ref.Video);

  module.exports = MediaButton = (function () {
    function MediaButton(createUi, block, imageLocation, mediaBrowser, make, projectEvents) {
      var backgroundSetter, getSplash;
      backgroundSetter = make(require("../BackgroundSetter"), {
        onAddedContent: null,
      });

      /*
        The splash controller is not initialised before the SplashMenu,
        so we need to get it
       */
      getSplash = (function (_this) {
        return function () {
          return block.$ctrl.theBlock;
        };
      })(this);
      this.ui = createUi(require("./MediaButton.html"), {
        icon: (function (_this) {
          return function () {
            var icon;
            icon = null;
            switch (block.resourceType) {
              case "image":
                icon = Splash;
                break;
              case "video":
              case "embed":
                icon = Video;
            }
            return icon;
          };
        })(this),
      });
      this.className = "media-button";
      this.onClick = (function (_this) {
        return function () {
          switch (block.resourceType) {
            case "image":
              return block.$ctrl.theBlock.showMediaCarousel();
            case "video":
            case "embed":
              return mediaBrowser.show("stockVideos").then(function (result) {
                return backgroundSetter.setVideo(result.data.url);
              });
          }
        };
      })(this);
    }

    return MediaButton;
  })();
}.call(this));
