"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var ActionsList = require("./ActionsList");
var Excluder = require("./Excluder");
var allActions = [
    "settings",
    "share",
    "clone",
    "analytics",
    "bluePrint",
    "delete",
    "archive",
    "disable",
    "changeOwner",
    "tag",
    "star",
    "preview",
    "pdfExport",
    "restore",
    "markTemplate",
];
var AllowedActionsChecker = (function () {
    function AllowedActionsChecker(make, invoke, permissionsService) {
        var _this = this;
        this.permissionsService = permissionsService;
        this.getAction = function (action) { return _this.availableActions[action]; };
        this.getActions = function (actions, project) {
            var e_1, _a;
            var actionsArray = [];
            try {
                for (var actions_1 = __values(actions), actions_1_1 = actions_1.next(); !actions_1_1.done; actions_1_1 = actions_1.next()) {
                    var actionName = actions_1_1.value;
                    var action = _this.availableActions[actionName];
                    if (action && _this.permissionsService.isAllowed(project.userAction, action.permission)) {
                        actionsArray.push(action);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (actions_1_1 && !actions_1_1.done && (_a = actions_1.return)) _a.call(actions_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return actionsArray;
        };
        this.getAllowedActions = function (project, options) {
            if (options === void 0) { options = {}; }
            return _this.getActions(_this.getAllowedActionNames(project, options), project);
        };
        this.getAllowedActionNames = function (project, options) {
            var _a;
            var exclude = options.exclude || [];
            exclude = _this.excluder.addExclusions(project, exclude);
            var items = _this.excluder.getAllExcept(allActions, exclude);
            if ((_a = options.subset) === null || _a === void 0 ? void 0 : _a.length) {
                items = _this.excluder.getSubset(items, options.subset);
            }
            return items;
        };
        this.availableActions = invoke(ActionsList);
        this.excluder = make(Excluder);
    }
    return AllowedActionsChecker;
}());
exports.default = AllowedActionsChecker;
