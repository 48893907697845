"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpheresBackgroundManager = void 0;
var react_1 = require("react");
var SpheresBackgroundManager = (function () {
    function SpheresBackgroundManager() {
        var _this = this;
        this.spheresRef = (0, react_1.createRef)();
        this.sphereSaturation = 0;
        this.triggerPulse = function (intensity) {
            if (intensity === void 0) { intensity = 1.5; }
            if (!_this.spheresRef.current) {
                return;
            }
            clearTimeout(_this.spherePulseClearingTimer);
            _this.spherePulseClearingTimer = setTimeout(function () {
                clearTimeout(_this.spherePulseClearingTimer);
                _this.spheresRef.current.dataset.pulse = "false";
                _this.spheresRef.current.style.setProperty("--intensity", "0");
            }, 300);
            _this.sphereSaturation = _this.sphereSaturation + intensity;
            _this.spheresRef.current.style.setProperty("--intensity", "".concat(Math.min(8, intensity + _this.sphereSaturation)));
            _this.spheresRef.current.dataset.pulse = "true";
            _this.spheresRef.current.offsetHeight;
        };
        this.handleContainerMouseMove = function (event) {
            if (!_this.spheresRef.current) {
                return;
            }
            var x = (window.innerWidth / 2 + -event.clientX) * 0.2;
            var y = (window.innerHeight / 2 + -event.clientY) * 0.2;
            _this.spheresRef.current.style.setProperty("--offset-x", "".concat(x, "px"));
            _this.spheresRef.current.style.setProperty("--offset-y", "".concat(y, "px"));
        };
        this.decaySphereSaturation = function () {
            _this.sphereSaturation = Math.max(0, _this.sphereSaturation - 2);
        };
    }
    return SpheresBackgroundManager;
}());
exports.SpheresBackgroundManager = SpheresBackgroundManager;
