(function () {
  var LinkPassword;

  module.exports = LinkPassword = (function () {
    function LinkPassword(createUi, link, telemetry) {
      this.isEnabled = (function (_this) {
        return function () {
          return link.isPasswordProtected;
        };
      })(this);
      this.ui = createUi(require("./Password.html"), {
        newPassword: "",
        isPasswordProtected: link.isPasswordProtected,
        linkPasswordToggled: (function (_this) {
          return function () {
            link.isPasswordProtected = _this.ui.isPasswordProtected;
            if (_this.ui.isPasswordProtected) {
              telemetry.trackOnly("Project Security Password Applied");
              $(".link-password").trigger("focus");
            }
          };
        })(this),
        passwordChanged: (function (_this) {
          return function () {
            link.password = _this.ui.newPassword;
            return (link.hasPassword = _this.ui.newPassword !== "");
          };
        })(this),
        isPasswordEmpty: (function (_this) {
          return function () {
            return _this.ui.newPassword === "" || (_this.ui.newPassword == null && !link.hasPassword);
          };
        })(this),
        placeHolder: (function (_this) {
          return function () {
            if (!link.hasPassword) {
              return "";
            } else {
              return "•••••••";
            }
          };
        })(this),
      });
    }

    return LinkPassword;
  })();
}.call(this));
