// Generated by CoffeeScript 1.12.7
(function () {
  var InvoicerService;

  module.exports = InvoicerService = (function () {
    function InvoicerService($http, $q, apiBaseUrl, xero) {
      var checkEstimateArgs,
        generateFreshbooksEstimates,
        generateQuickbooksEstimate,
        generateXeroEstimate,
        getFreshbooksCustomers,
        getQuickbooksCustomers,
        getXeroAccounts,
        getXeroCustomers;
      getQuickbooksCustomers = (function (_this) {
        return function () {
          return $http.get(apiBaseUrl + "/integration/quickbooks/customers").then(function (response) {
            return response.data.message;
          });
        };
      })(this);
      getXeroCustomers = (function (_this) {
        return function () {
          return xero.getContacts().then(function (response) {
            var contacts;
            contacts = response;
            return _.map(contacts, function (c) {
              return {
                name: c.Name,
                id: c.ContactID,
                email: c.EmailAddress,
              };
            });
          });
        };
      })(this);
      getXeroAccounts = (function (_this) {
        return function () {
          return xero.getAccounts().then(function (response) {
            var accounts;
            accounts = response;
            return _.map(accounts, function (a) {
              return {
                name: a.Name,
                id: a.AccountID,
                code: a.Code,
              };
            });
          });
        };
      })(this);
      getFreshbooksCustomers = (function (_this) {
        return function () {
          return $http.get(apiBaseUrl + "/integration/freshbooks/customers").then(function (response) {
            var customers, data, i, item, len;
            data = response.data.message;
            customers = [];
            for (i = 0, len = data.length; i < len; i++) {
              item = data[i];
              customers.push({
                name: item.first_name + " " + item.last_name,
                id: item.client_id,
                email: item.email,
              });
            }
            return customers;
          });
        };
      })(this);
      checkEstimateArgs = function (blockId, integrationSettings, projectLink) {
        var err, ref;
        err = null;
        if (blockId == null) {
          err = new Error("An error detecting the quote block occurred, please contact help@qwilr.com");
        }
        if (
          (integrationSettings != null
            ? (ref = integrationSettings.selectedCustomer) != null
              ? ref.id
              : void 0
            : void 0) == null
        ) {
          err = "No contact specified, please provide a contact for the invoice";
        }
        if (!(projectLink != null ? projectLink.length : void 0)) {
          err = new Error("An error with the project occurred, please contact help@qwilr.com");
        }
        if (err != null) {
          return err;
        } else {
          return null;
        }
      };
      generateXeroEstimate = function (blockId, integrationSettings, projectLink) {
        var err;
        err = checkEstimateArgs(blockId, integrationSettings, projectLink);
        if (err != null) {
          return $q.reject(err);
        }
        return $http.post(apiBaseUrl + "/integration/xero/estimate", {
          blockId: blockId,
          linkSecret: projectLink,
        });
      };
      generateQuickbooksEstimate = function (blockId, integrationSettings, projectLink) {
        var err;
        err = checkEstimateArgs(blockId, integrationSettings, projectLink);
        if (err != null) {
          return $q.reject(err);
        }
        return $http.post(apiBaseUrl + "/integration/quickbooks/estimate", {
          blockId: blockId,
          linkSecret: projectLink,
        });
      };
      generateFreshbooksEstimates = function (blockId, integrationSettings, projectLink) {
        var err;
        err = checkEstimateArgs(blockId, integrationSettings, projectLink);
        if (err != null) {
          return $q.reject(err);
        }
        return $http.post(apiBaseUrl + "/integration/freshbooks/estimate", {
          blockId: blockId,
          linkSecret: projectLink,
        });
      };
      this.getCustomers = (function (_this) {
        return function (integration) {
          var customers;
          customers = [];
          switch (integration) {
            case "freshbooks":
              customers = getFreshbooksCustomers();
              break;
            case "quickbooks":
              customers = getQuickbooksCustomers();
              break;
            case "xero":
              customers = getXeroCustomers();
          }
          return $q.resolve(customers);
        };
      })(this);
      this.getAccounts = (function (_this) {
        return function (integration) {
          var accounts;
          accounts = [];
          if (integration === "xero") {
            accounts = getXeroAccounts();
          }
          return $q.resolve(accounts);
        };
      })(this);
      this.generateEstimate = (function (_this) {
        return function (integration, blockId, integrationSettings, projectLink) {
          var response;
          response = null;
          switch (integration) {
            case "xero":
              response = generateXeroEstimate(blockId, integrationSettings, projectLink);
              break;
            case "quickbooks":
              response = generateQuickbooksEstimate(blockId, integrationSettings, projectLink);
              break;
            case "freshbooks":
              response = generateFreshbooksEstimates(blockId, integrationSettings, projectLink);
              break;
            default:
              response = $q.reject("Integration not supported");
          }
          return response;
        };
      })(this);
    }

    return InvoicerService;
  })();
}.call(this));
