import "../CommonFrontend/ContentTokens/TokenRenderers/Resizeable.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/ContentTokens/TokenRenderers/Resizeable.css.ts.vanilla.css\",\"source\":\"Ll8xOXpydnJ0MCB7CiAgd2lkdGg6IDEwMCU7Cn0KLl8xOXpydnJ0MSB7CiAgbWFyZ2luLWxlZnQ6IDUwJTsKICB0cmFuc2Zvcm06IHRyYW5zbGF0ZVgoLTUwJSk7CiAgd2lkdGg6IDEwMCU7CiAgbWF4LXdpZHRoOiB2YXIoLS1wYWdlLWNvbnRlbnQtd2lkdGgpOwp9Ci5fMTl6cnZydDYgewogIG1hcmdpbi1sZWZ0OiA1MCU7CiAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC01MCUpOwogIHdpZHRoOiB2YXIoLS1wYWdlLWNvbnRlbnQtd2lkdGgpOwp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDExNDBweCkgewogIC5fMTl6cnZydDEgewogICAgd2lkdGg6IDExNSU7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDE0NDBweCkgewogIC5fMTl6cnZydDEgewogICAgd2lkdGg6IDEzMyU7CiAgfQp9CkBtZWRpYSBzY3JlZW4gYW5kIChtaW4td2lkdGg6IDc2OHB4KSB7CiAgLl8xOXpydnJ0NSB7CiAgICBtYXJnaW4tbGVmdDogNTAlOwogICAgdHJhbnNmb3JtOiB0cmFuc2xhdGVYKC01MCUpOwogICAgd2lkdGg6IHZhcigtLXBhZ2UtY29udGVudC13aWR0aCk7CiAgfQp9\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "../CommonFrontend/Public/Widgets/Layout/Layout.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../CommonFrontend/Public/Widgets/Layout/Layout.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA9WT3Y6bMBCF7/MUU1UrQRWnZLtJto5U7ZtEBk9guvinxs3PVrx7BUsCJJD0ohftFeCDzxyPv4mNPH60jrSH2WapaEFFBL8mAEq4lDTzxnJYLO1h3a7FxnujTsvlJL72WNQekgqbiyOHbY61QSoshyQXygaRPUxhJ1zAWLNnHk4hEXkSzKPoAT5D0FOfQmAwD8Ow8rFCStIp7xs81prIKdWMPKriQv8SjqRd1WmrkBc7lrWjIs32JH3GIaq+98ZJFjsUrxzqB6tWKiU72gx1wUH89KYqNotzk7yyxGiP2tdlqndBGh3TQiGHptTzuqf5o0UOpHPSyAp6w9Ho3+AT35IrPEsyyuXV5UXwgZQ1zgvtO70bEm9UyMVQgRMJYzWG9XLyolCSgCJxiBqElhB0mrxaPttDWNfpMzlM5QiXAGXHYNEYXCH596C8jeU9MPtxV03cUSavqbzF5RWZl9WGILqN0U2QhuwvCLrL0D2K3mu8nEfmPEg9lqITSV0UusFm1pnvmHhWT+pm8z6wmKNC7bk2PpiREilWhin6zaawIqlSFRmpKcx2JNGMiahilIPiKVTVhsMp7DyKkh/7dSO0iw/r8791y3Lc+vYmO4KjNOspZXPy+dc3t3P+se3BxY3/Cy0Z7IkiHbQDyGD5ZA/htGlV+CenbMH77w9ZTsrfEQEbo7EHAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css\",\"source\":\"Ll8xcnVhYmNyMCB7CiAgcGFkZGluZzogMCAhaW1wb3J0YW50OwogIG1hcmdpbjogMCAhaW1wb3J0YW50OwogIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDsKfQouXzFydWFiY3IyIHsKICBwb3NpdGlvbjogZml4ZWQ7CiAgd2lkdGg6IHZhcigtLV8xcnVhYmNyMSk7CiAgdG9wOiAwOwogIG91dGxpbmU6IDFweCBzb2xpZCB0cmFuc3BhcmVudDsKICBsZWZ0OiAyMDB2dzsKfQpfOjotd2Via2l0LWZ1bGwtcGFnZS1tZWRpYSwgXzpmdXR1cmUsIDpyb290IC5fMXJ1YWJjcjI6OmJlZm9yZSB7CiAgY29udGVudDogIiI7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHdpZHRoOiAxcHg7CiAgaGVpZ2h0OiAxcHg7CiAgbGVmdDogY2FsYygtMTAwdncgLSAxcHgpOwogIHRvcDogMDsKICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDsKfQouXzFydWFiY3IyID4gKjpmaXJzdC1jaGlsZCB7CiAgcGFkZGluZy10b3A6IDAgIWltcG9ydGFudDsKICBtYXJnaW4tdG9wOiAwICFpbXBvcnRhbnQ7Cn0KLl8xcnVhYmNyMiAuaW1hZ2Utd2lkZ2V0X19naG9zdCB7CiAgZGlzcGxheTogbm9uZTsKfQ==\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVXW/bIBR9z6+4ilQpXkNlO3HqUvVxf2Bv01RV2CYOLQEPcJt26n+fjHFicDqtm/xiLod7Lud+cPWQCPZWvFUJ/JoBNFIzw6TAoCgnhj3T2xnAjrJ6ZzAkcXxxO3ufXQ2HUowLupWK2sOlFIYKg2E+v/V8kUJL3hrri9OtwRA3B89x5gwvrDK7gQjAKCL0Vqo97n85MfT7All45EWywphsDVV/H8iRO3fcXmReICOetSX4aDcb726ZQS6SDvSjIoYgTTktu2CQYqK+78NtlZYKQyOZMFSdwHZJyi4N6E0Ker90TjodkJAVvZtTTvdUmLnvqqJb0vIRr4Mh89rQu7mhB/P17MFux7tSYbcRGtbEpaIhyl5svLc5yneyXZ+x5Ufb+dRMBAFgQlODoSS8XCyeiVqMGCK4BN+URxF8AZT4NVJ8vloLeUB6Ryr5giG2X9oHXkreRejTxsvAQKLIeiHlU61kKyrkzgUS2qWjtwjnp2oVscWiHx6Sgl8/PiZJG7lNSjQTNdKGiIqo6oTQ0XJC+Z8ORykIle5lUhVVSJGKtTqE3ISqbKIhL6olRalSCKqtYrrh5BWDkIJ6KSyn1ajqYpGuMkjXMaTraz/l1Xl8kt1AmqwhzdY+nn6A30CSrSDNUh9eB/DNcPdekG9WDzsElOQnPeto0iP5mR5Zd7YRG3Mj2jYFos9UGD1odEwPmpzyqt6h4vNlGVZv2AKTfDxN9bKu0J4dFkwAJ8XSKpjnkOSd4KsIkvhiOe4AX1P+Ly7TP7qUwds27nAjm/Dewt5b9c+DnTjdoJ8MmX0UsHx+vFjyPhfSGLl3i/4lGh5B95yE5B+1UH9Z2ZCSmVfncOAZRdsEMosQ8HMKQEkcFJcKQHsMmwCip5AkrNF2irEN8f4b00L4Xp8IAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/Widgets/Layout/LayoutWidget.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/Widgets/Layout/LayoutWidget.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA81Ya2+jOBT93l9xd6RK0I27QB5tHWm0H/dfVAYc4o6xGdsk6az631cYJ2DIq9PuaBSpCfj6+D7Oudfq/XPC1IJsI/j3BqCSmhkmBQZFOTFsQ5c3bzf3ziaxNgi5xxiDUUToiigqzPLEdgDCWSEQM7TUGJhghhHuwWKc0pVU1MJnUhgqDIYvX3xIkmrJa2MhmdDUYIia36ncIb0mudxiiOznsdotLRKXCsOGqKDzOWxWrNsO1lo5o7xWpHmL9PNznPKHl5c4rkO3SIlmokDaEJETlXcWOmwdZcJQheiGCqMxCCnoIEqyMlR9PEj73oXqgluUbM50Ev5OYceqJmmmEjhe55zpipPXSzu6nB3Z0FpNR7S0waGS7QImgJN0AqpIg3gB8XwKyTwJIY5uJ332hn3A2U8AJucA59cCxlOIowdIZvEFxMcr5PpkbdaUFWuDIY6i2yEJPl7+Dg1VSlZUmVcMsiIZM68TWDFuqOr7RKxPzgBDdJ80KK0dhkKRV50RToM4iq5S1RN8hbsjcXYWqZ/7hzNN67T4VE4VUiRntcaQtO1F1oYzQTHE1Q605Cz3Nfcw0tz/le7WkQmkJPtWKFmLHFl+vVOf6XmZZaNEti63VG5z1PkbeXTNR3sH+ukbt/1hGMyHRUjxWm5cL/kZ8Ok58MLp0U923ZCob7YeyLbPOCMrxzdOVwbDvKfXlVSlIy4nhgZo3jjT/LW02HM/iVpqbllu1hiSWft4KGmhWG5JwUlG0WEIZVRYmR7LS7/GulYrktFDkYtwOJpa6/YJUU43lulxR4vcbun0P5SIW/kEqfxATOR0hyHuF+Cb33+8tdQRZP/Mz9hKn9DllV2lq3EqjZGlV/B9h3bVyzgpqyCqdhN/ysfhBDLCsyDw2o0IAUEwuA/AHSRhCH8NFmaNbRyGo/qdvFrEv/xq0cv1u29ONs1OP56Y9kJ59DLtGvo1Sjs+Ck7JZs+OUYqGGz4lW6OGLs839Or99+6LiR1weN+QrkntccFWva7dl9tofgBktdJN7ukWKarZDy/Y7x9GaH04eJmczlKbGavRJh/wJzxVu3CYv/c0dlIbCX+wspLKEOGNFN0OtJ6I2zrvudq/nwzvmB5OfzwO0Lyjr5wS/zRoB5KKwVk50yTlNP+047yh1J1kfAmsOLV8bL5RzhTNDi2rLkWz8lJrw1av3XD02uA0HJO9f9hXuMMrprRB2ZrxNrqSqIIJ1Lb+QWgVyfNG1EcWh7CcHEM9jJETwMfXO+x6ROq3m7+b2AkTVIEL+xGCkgnkRB1Vu9Bu6/4v4Llq1xoPd/sdcRRl37dL+7p7dbt0djaSVjQN0b3XqhNA8/6tf+z+x2KU9wv+lEwEnTwRLGbVLpw4N8ML5/QK8QnHvP0HWqyg+4ERAAA=\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var layoutWrapper = '_2ir6aw0';
export var layout = _7a468({defaultClassName:'_2ir6aw2',variantClassNames:{mouseOverSelectableRegion:{true:'_2ir6aw3'},selected:{true:'_2ir6aw4'},editMode:{true:'_2ir6aw5'},fullWidth:{true:'_2ir6aw6'}},defaultVariants:{},compoundVariants:[]});
export var layoutColumn = '_2ir6aw8';
export var layoutColumnContent = _7a468({defaultClassName:'_2ir6aw9',variantClassNames:{editMode:{true:'_2ir6awa'}},defaultVariants:{},compoundVariants:[]});
export var columnFrame = _7a468({defaultClassName:'_2ir6awb',variantClassNames:{mouseOverWidget:{true:'_2ir6awc'},selected:{true:'_2ir6awd'},editMode:{true:'_2ir6awe'},isResizing:{true:'_2ir6awf'}},defaultVariants:{},compoundVariants:[[{editMode:true,isResizing:false},'_2ir6awg']]});
export var columnToolbarButton = _7a468({defaultClassName:'_2ir6awh',variantClassNames:{editMode:{true:'_2ir6awi'},isResizing:{true:'_2ir6awj'},isOpen:{true:'_2ir6awk'}},defaultVariants:{},compoundVariants:[[{editMode:true,isResizing:false},'_2ir6awl']]});
export var layoutWidth = 'var(--_2ir6awn)';
export var gap = _7a468({defaultClassName:'_2ir6awo',variantClassNames:{editMode:{true:'_2ir6awp'},resizing:{true:'_2ir6awq'}},defaultVariants:{},compoundVariants:[]});
export var layoutAddButtonTooltipTarget = '_2ir6awr';
export var layoutAddColumnButton = '_2ir6aws';
export var layoutColumnWidget = '_2ir6awt';
export var layoutOptionMenu = '_2ir6awu';