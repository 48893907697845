"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var slate_1 = require("slate");
var CursorValidatorUtils_1 = require("./CursorValidatorUtils");
var LinkCursorValidator = {
    isIllegalCursor: function (editor) {
        var parent = slate_1.Editor.parent(editor, slate_1.Range.start(editor.selection));
        return (slate_1.Element.isElement(parent[0]) &&
            parent[0].type == "link" &&
            slate_1.Range.isCollapsed(editor.selection) &&
            (0, CursorValidatorUtils_1.isAtStartOfInline)(editor));
    },
    makeCursorValid: function (editor) {
        return (0, CursorValidatorUtils_1.moveUpwardsUntilValid)(editor, LinkCursorValidator.isIllegalCursor);
    },
};
exports.default = LinkCursorValidator;
