// Generated by CoffeeScript 1.12.7

/*
  'View factory' for manually entering youtube links, based off the 'Youtube'
  section of the old VideoPicker

  Unlike the other view factories, this one is NOT using InfiniteScroller
 */

(function () {
  var MediaSource, YoutubeFactory;

  MediaSource = require("@CommonFrontendBackend/AssetLibrary/Types").MediaSource;

  module.exports = YoutubeFactory = (function () {
    function YoutubeFactory($q, $timeout, createUi, make, VideoBackground, selectCallback) {
      var ui;
      ui = createUi(require("./Youtube.html"), {
        externalUrl: "",
        videoBackground: null,
        updatePreview: (function (_this) {
          return function () {
            if (!ui.externalUrl) {
              return;
            }
            ui.videoBackground = null;
            return $timeout(function () {
              return (ui.videoBackground = make(VideoBackground, {
                url: ui.externalUrl,
              }));
            });
          };
        })(this),
        select: (function (_this) {
          return function () {
            return selectCallback(MediaSource.Youtube, {
              url: ui.externalUrl,
            });
          };
        })(this),
      });
      this.setup = (function (_this) {
        return function (data) {
          ui.updatePreview();
          return ui;
        };
      })(this);
      this.getCSSClass = (function (_this) {
        return function () {
          return "youtube";
        };
      })(this);
    }

    return YoutubeFactory;
  })();
}.call(this));
