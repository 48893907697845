// Generated by CoffeeScript 1.12.7

/*
 * UI for FreshBooks integration
 */

(function () {
  var FreshBooksIntegration,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = FreshBooksIntegration = (function () {
    function FreshBooksIntegration(make, createUi, $http, $q, $timeout, $log, account, apiBaseUrl) {
      this.account = account;
      this.apiBaseUrl = apiBaseUrl;
      this.update = bind(this.update, this);
      this.disable = bind(this.disable, this);
      this.enable = bind(this.enable, this);
      this.q = $q;
      this.http = $http;
      this.enableUi = make(require("./FreshBooksEnable"));
      this.ui = createUi(require("SettingsAngular/Integrations/FreshBooks/FreshBooks.html"), {
        settings: this.account.integrations.freshbooks.settings,
        toggleAutoPush: (function (_this) {
          return function (enabled) {
            if (enabled == null) {
              return;
            }
            _this.update({
              defaultAutoPush: enabled,
            });
            return $http.post(_this.apiBaseUrl + "/accounts/" + _this.account._id, {
              "integrations.freshbooks.settings.defaultAutoPush": enabled,
            });
          };
        })(this),
      });
      this.enablingUi = this.enableUi.ui;
    }

    FreshBooksIntegration.prototype.enable = function () {
      var callbackUrl;
      callbackUrl = this.apiBaseUrl + "/integration/freshbooks/callback";
      return this.enableUi.show().then(
        (function (_this) {
          return function (site) {
            var enabling, polling, win;
            enabling = _this.q.defer();
            win = window.open(
              _this.apiBaseUrl + "/integration/freshbooks/authorize?site=" + site,
              "_blank",
              "top=100,left=100,width=900,height=1000",
            );
            polling = window.setInterval(function () {
              var e, url;
              try {
                if (win.QWILR_SUCCESS) {
                  window.clearInterval(polling);
                  url = win.document.URL;
                  enabling.resolve(url.split("?")[1]);
                  return win.close();
                }
              } catch (error) {
                e = error;
              }
            }, 100);
            return enabling.promise
              .then(function (queryParams) {
                return _this.http.get(callbackUrl + "?" + queryParams);
              })
              .then(function () {
                return _this.http.post(_this.apiBaseUrl + "/integration/freshbooks/enable", {});
              })
              .then(function () {
                var base;
                if ((base = _this.account.integrations).freshbooks == null) {
                  base.freshbooks = {};
                }
                return (_this.account.integrations.freshbooks.enabled = true);
              });
          };
        })(this),
      );
    };

    FreshBooksIntegration.prototype.disable = function () {
      return this.http.post(this.apiBaseUrl + "/integration/freshbooks/disable").then(
        (function (_this) {
          return function () {
            return (_this.account.integrations.freshbooks = {
              enabled: false,
              settings: {},
            });
          };
        })(this),
      );
    };

    FreshBooksIntegration.prototype.update = function (settings) {
      return this.http.post(this.apiBaseUrl + "/integration/freshbooks/update", settings).then(
        (function (_this) {
          return function () {
            var base;
            if ((base = _this.account.integrations).freshbooks == null) {
              base.freshbooks = {};
            }
            return (_this.account.integrations.freshbooks.settings = settings);
          };
        })(this),
      );
    };

    return FreshBooksIntegration;
  })();
}.call(this));
