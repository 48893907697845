(function () {
  var ESignatureButton, FeatureFlag, mountEsignatureIcon, mountReactComponent;

  mountReactComponent = require("MountReactComponent/MountReactComponent")["default"];

  mountEsignatureIcon = require("../ReactSuperpowerIcons").mountEsignatureIcon;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = ESignatureButton = (function () {
    function ESignatureButton(createUi, make, Tooltip, block, usingFeature) {
      var eSignatureMenu, menu;
      eSignatureMenu = make(require("./ESignatureMenu"));
      menu = make(Tooltip, {
        targetSelector: "#" + block.$id + " .esignature-button",
        options: {
          container: "#" + block.$id + " .project-block",
          position: "bottom",
          contents: eSignatureMenu.ui,
          spacing: 7,
        },
      });
      this.ui = createUi(require("./ESignatureButton.html"));
      this.ui.reactIcon = mountEsignatureIcon(mountReactComponent(createUi));
      this.ui.usingReactIcon = !usingFeature(FeatureFlag.OptOutNewEditor);
      this.className = "esignature-button";
      this.onClick = menu.show;
      this.onClose = menu.hide;
    }

    return ESignatureButton;
  })();
}.call(this));
