// Generated by CoffeeScript 1.12.7

/*
  Deals with fetching and buffering images using the ImageService. This abstracts away a lot of the
  "heavy" lifting of loading multiple pages in an incremental fashion (i.e. load one first,
  display it, and then load the next one and so on)

  @class ImagePageBuffer
  @param mediaCarousel [Object] - the MediaCarousel instance
 */

(function () {
  var ImagePageBuffer,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = ImagePageBuffer = (function () {
    function ImagePageBuffer(mediaCarousel, $q) {
      this.cacheCollection = bind(this.cacheCollection, this);
      this.cacheQuery = bind(this.cacheQuery, this);
      this.getAndAddPage = bind(this.getAndAddPage, this);
      this.updatePage = bind(this.updatePage, this);
      this.addPage = bind(this.addPage, this);
      this.getPhotos = bind(this.getPhotos, this);
      this.getFlagsForPage = bind(this.getFlagsForPage, this);
      this.createNewRequest = bind(this.createNewRequest, this);
      this.mediaCarousel = mediaCarousel;
      this.carousel = mediaCarousel.carousel.ui;
      this.pageHandler = this.carousel.pageHandler;
      this.imageService = this.mediaCarousel.imageService;
      this.pageIndex = this.carousel.pageIndex;
      this.q = $q;
    }

    /*
      Generates a unique hash of length 7 Generally Math.random() is not entirely unique,
      and we would not want to use it where we are worried about collisons over many requests,
      this logic only exists for 1 user, which gives us a very low possibility of collisions
    
      @api private
     */

    ImagePageBuffer.prototype.generateRequestHash = function () {
      return Math.random().toString(36).substring(7);
    };

    /*
      Generates a new request hash and associates it with this cache
    
      @api private
     */

    ImagePageBuffer.prototype.createNewRequest = function () {
      var hash;
      hash = this.generateRequestHash();
      this.currentRequest = hash;
      return hash;
    };

    /*
      Returns the option flags associated with a page type
    
      @param pageType [String] - the type of page to get the options for
      @api public
     */

    ImagePageBuffer.prototype.getFlagsForPage = function (pageType) {
      var options;
      options = {
        active: false,
        isFirst: false,
        isNext: false,
        isPrev: false,
        isAfterNext: false,
      };
      if (pageType === "current") {
        options.active = true;
        options.isFirst = true;
      } else if (pageType === "next") {
        options.active = true;
        options.isNext = true;
      } else if (pageType === "afterNext") {
        options.active = true;
        options.isAfterNext = true;
      } else if (pageType === "prev") {
        options.active = true;
        options.isPrev = true;
      }
      return options;
    };

    /*
      Gets images from the service, will grab photos depending on the type of request
    
      Either the collectionId or the query should be provided, but not both
    
      @param collectionId [String] - (optional) the collectionId to query
      @param query [String] - (optional) the query string to search for photos
      @param page [Number] - the page number of the request
      @api private
     */

    ImagePageBuffer.prototype.getPhotos = function (collectionId, query, page) {
      if (collectionId != null) {
        return this.imageService.getCollectionPhotos(collectionId, {
          page: page,
          perPage: this.carousel.PAGE_SIZE,
        });
      } else {
        return this.imageService.searchPhotos({
          query: query,
          page: page,
          perPage: this.carousel.PAGE_SIZE,
        });
      }
    };

    /*
      Adds a new page to the carousel
    
      @param photos [Array] - the raw data returned from the service that is to be added to a new page
      @param options [Object] - the associated flags/options for the page to be added
      @param requestId [String] - the unique hash associated with the request
      @api private
     */

    ImagePageBuffer.prototype.addPage = function (photos, options, requestId) {
      if (requestId === this.currentRequest) {
        return this.pageHandler.addPage(photos, options);
      }
    };

    /*
      Updates an existing page with new data
    
      @param page [Object] - the PageTile instance, created by a factory, that is to be updated
      @param photos [Array] - the raw data returned from the service that is to be added to a new page
      @param requestId [String] - the unique hash associated with the request
      @api public
     */

    ImagePageBuffer.prototype.updatePage = function (page, photos, requestId) {
      if (requestId === this.currentRequest) {
        return this.pageHandler.updatePageTile(page, photos, requestId);
      }
    };

    /*
      Issues a request first, and then adds a new page to the Carousel
    
      Either the collectionId or the query should be provided, but not both
    
      @param collectionId [String] - (optional) the ID of the collection to query
      @param query [String] - (optional) the query string
      @param page [Number] - the page number to query
      @param options [Object] - the associated flags/options for the page to be added
      @api public
     */

    ImagePageBuffer.prototype.getAndAddPage = function (collectionId, query, page, options) {
      var currentRequest;
      currentRequest = this.createNewRequest();
      return this.getPhotos(collectionId, query, page).then(
        (function (_this) {
          return function (photos) {
            _this.addPage(photos, options, currentRequest);
            return _this.q.resolve();
          };
        })(this),
      );
    };

    /*
      Caches a query (i.e. a search), and loads the next 3 pages in an incremental fashion
    
      @param query [String] - the query string for the search request
     */

    ImagePageBuffer.prototype.cacheQuery = function (query) {
      var currentPage, currentRequest;
      if (!query || query === "") {
        return;
      }
      currentRequest = this.createNewRequest();
      currentPage = 0;
      return this.getPhotos(null, query, currentPage)
        .then(
          (function (_this) {
            return function (photos) {
              _this.carousel.clear();
              _this.carousel.viewState.showPosition = false;
              _this.carousel.setTitle(query);
              if (photos.length === 0) {
                _this.carousel.setEmptyContentMessage("Library doesn't have any " + query + " images :(");
              }
              _this.addPage(photos, _this.getFlagsForPage("current"), currentRequest);
              return _this.q.resolve();
            };
          })(this),
        )
        .then(
          (function (_this) {
            return function () {
              currentPage++;
              return _this.getPhotos(null, query, currentPage);
            };
          })(this),
        )
        .then(
          (function (_this) {
            return function (photos) {
              _this.addPage(photos, _this.getFlagsForPage("next"), currentRequest);
              currentPage++;
              return _this.getPhotos(null, query, currentPage);
            };
          })(this),
        )
        .then(
          (function (_this) {
            return function (photos) {
              _this.addPage(photos, _this.getFlagsForPage("afterNext"), currentRequest);
              return _this.q.resolve();
            };
          })(this),
        );
    };

    /*
      Caches a collection and loads the next 3 pages in an incremental fashion
    
      @param collectionId [String] - (optional) we can select a collection to load, if we do not
                                     specify an id, a random collection will be loaded and cached
     */

    ImagePageBuffer.prototype.cacheCollection = function (collectionId) {
      var currentRequest, dummyTiles, firstPage, i, ref, results, secondPage;
      currentRequest = this.createNewRequest();
      dummyTiles = _.map(
        function () {
          results = [];
          for (var i = 0, ref = this.carousel.PAGE_SIZE; 0 <= ref ? i < ref : i > ref; 0 <= ref ? i++ : i--) {
            results.push(i);
          }
          return results;
        }.apply(this),
        function () {
          return {
            placeholder: true,
          };
        },
      );
      firstPage = this.addPage(dummyTiles, this.getFlagsForPage("current"), currentRequest);
      secondPage = this.addPage(dummyTiles, this.getFlagsForPage("next"), currentRequest);
      this.carousel.setErrorState(false);
      return this.imageService
        .getCollectionOrRandomise(collectionId)
        .then(
          (function (_this) {
            return function (collection) {
              collectionId = collection.collectionId;
              _this.mediaCarousel.currentCollection = collection;
              _this.carousel.setTotalCount(collection.count);
              _this.carousel.setTitle(collection.title);
              return _this.getPhotos(collectionId, null, _this.pageIndex);
            };
          })(this),
        )
        .then(
          (function (_this) {
            return function (photos) {
              _this.updatePage(firstPage, photos, currentRequest);
              return _this.q.resolve();
            };
          })(this),
        )
        .then(
          (function (_this) {
            return function () {
              return _this.getPhotos(collectionId, null, _this.pageIndex + 1);
            };
          })(this),
        )
        .then(
          (function (_this) {
            return function (photos) {
              _this.updatePage(secondPage, photos, currentRequest);
              return _this.getPhotos(collectionId, null, _this.pageIndex + 2);
            };
          })(this),
        )
        .then(
          (function (_this) {
            return function (photos) {
              _this.addPage(photos, _this.getFlagsForPage("afterNext"), currentRequest);
              return _this.q.resolve();
            };
          })(this),
        )
        ["catch"](
          (function (_this) {
            return function (error) {
              _this.carousel.setErrorState(true);
              return [];
            };
          })(this),
        );
    };

    return ImagePageBuffer;
  })();
}.call(this));
