"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Menu = require("./SelectionTypeMenu");
var ButtonTemplate = require("./SelectionTypeButton.html");
var SelectionTypeButton = (function () {
    function SelectionTypeButton(createUi, make, Tooltip, block) {
        var _this = this;
        this.onClick = function () {
            _this.tooltip.show();
        };
        this.onClose = function () {
            _this.tooltip.hide();
        };
        var menu = make(Menu);
        this.tooltip = make(Tooltip, {
            targetSelector: "#".concat(block.$id, " .selection-type-button"),
            options: {
                container: "#".concat(block.$id, " .project-block"),
                position: "bottom",
                contents: menu.ui,
                spacing: 7,
                closeOnClickAway: true,
            },
        });
        this._ui = createUi(ButtonTemplate);
    }
    Object.defineProperty(SelectionTypeButton.prototype, "ui", {
        get: function () {
            return this._ui;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SelectionTypeButton.prototype, "className", {
        get: function () {
            return "selection-type-button";
        },
        enumerable: false,
        configurable: true
    });
    return SelectionTypeButton;
}());
exports.default = SelectionTypeButton;
