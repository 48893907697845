// Generated by CoffeeScript 1.12.7

/*
  A wrapper for:
  - Getting and setting data for the block's Pipedrive integration settings
  - Getting the account's Pipedrive info via our server
 */

(function () {
  var PipedriveService;

  module.exports = PipedriveService = (function () {
    function PipedriveService(make, $http, apiBaseUrl, block) {
      var blockSettings;
      blockSettings = block.settings;
      if (blockSettings.integrations == null) {
        blockSettings.integrations = {};
      }
      this.isEnabled = function () {
        return blockSettings.integrations["pipedrive-enabled"];
      };

      /*
        Turn on/off Pipedrive for the block
       */
      this.setEnabled = function (enabled) {
        blockSettings.integrations["pipedrive-enabled"] = enabled;
        return blockSettings.pipedrive != null ? blockSettings.pipedrive : (blockSettings.pipedrive = {});
      };

      /*
        Get information on the current block's Pipedrive settings
       */
      this.getCurrentPipeline = function () {
        var ref;
        return (ref = blockSettings.pipedrive) != null ? ref.pipeline : void 0;
      };
      this.getCurrentDeal = function () {
        var ref;
        return (ref = blockSettings.pipedrive) != null ? ref.deal : void 0;
      };
      this.getCurrentStage = function () {
        var ref;
        return (ref = blockSettings.pipedrive) != null ? ref.toStage : void 0;
      };

      /*
        Set the Pipedrive settings for this block
       */
      this.setPipeline = (function (_this) {
        return function (pipelineData) {
          return (blockSettings.pipedrive.pipeline = pipelineData);
        };
      })(this);
      this.setDeal = (function (_this) {
        return function (dealData) {
          return (blockSettings.pipedrive.deal = dealData);
        };
      })(this);
      this.setStage = (function (_this) {
        return function (stageData) {
          return (blockSettings.pipedrive.toStage = stageData);
        };
      })(this);

      /*
        Get a list of all the Pipelines on the linked Pipedrive account
       */
      this.getPipelines = (function (_this) {
        return function () {
          return $http.get(apiBaseUrl + "/integration/pipedrive/pipelines").then(function (response) {
            return response.data.data;
          });
        };
      })(this);

      /*
        Get a list of all deals and a list of all stages for a particular Pipeline
       */
      this.getDealsAndStages = (function (_this) {
        return function (pipelineId) {
          return $http
            .get(apiBaseUrl + "/integration/pipedrive/deals-and-stages?pipeline=" + pipelineId)
            .then(function (response) {
              return {
                deals: response.data.deals,
                stages: response.data.stages,
              };
            });
        };
      })(this);
    }

    return PipedriveService;
  })();
}.call(this));
