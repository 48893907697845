"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.INACTIVE_TIMEOUT_MS = void 0;
var mobx_1 = require("mobx");
exports.INACTIVE_TIMEOUT_MS = 20000;
var ActiveUsersStore = (function () {
    function ActiveUsersStore(userList, currentUserId) {
        var _this = this;
        this.activeUsersMap = new Map();
        this.REMOVE_INTERVAL_MS = 10000;
        this.getUserInAccountById = function (userId) {
            return _this.accountUserList.find(function (u) { return u._id === userId; });
        };
        this.accountUserList = userList;
        this.currentUserId = currentUserId;
        this.removeInactiveUserInterval = setInterval(function () {
            _this.removeInactiveUsers();
        }, this.REMOVE_INTERVAL_MS);
    }
    ActiveUsersStore.prototype.addUser = function (userId) {
        if (userId === this.currentUserId) {
            return;
        }
        this.activeUsersMap.set(userId, Date.now());
    };
    ActiveUsersStore.prototype.removeUser = function (userId) {
        this.activeUsersMap.delete(userId);
    };
    ActiveUsersStore.prototype.clearAll = function () {
        this.activeUsersMap.clear();
        clearInterval(this.removeInactiveUserInterval);
    };
    ActiveUsersStore.prototype.removeInactiveUsers = function () {
        var _this = this;
        var removeTime = Date.now() - exports.INACTIVE_TIMEOUT_MS;
        this.activeUsersMap.forEach(function (lastActiveTime, userId) {
            var isUserInactive = lastActiveTime < removeTime;
            if (isUserInactive) {
                _this.activeUsersMap.delete(userId);
            }
        });
    };
    Object.defineProperty(ActiveUsersStore.prototype, "allUsers", {
        get: function () {
            var _this = this;
            var users = [];
            this.activeUsersMap.forEach(function (lastActiveTime, userId) {
                var user = _this.getUserInAccountById(userId);
                if (user) {
                    users.push(user);
                }
            });
            if (users.length === 0) {
                return [];
            }
            return users.map(function (user) { return ({
                firstName: user.firstName,
                lastName: user.lastName,
                id: user._id,
            }); });
        },
        enumerable: false,
        configurable: true
    });
    var _a;
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
    ], ActiveUsersStore.prototype, "activeUsersMap", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ActiveUsersStore.prototype, "addUser", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ActiveUsersStore.prototype, "removeUser", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ActiveUsersStore.prototype, "clearAll", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ActiveUsersStore.prototype, "removeInactiveUsers", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], ActiveUsersStore.prototype, "allUsers", null);
    return ActiveUsersStore;
}());
exports.default = ActiveUsersStore;
