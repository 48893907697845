// Generated by CoffeeScript 1.12.7
(function () {
  var BlockQuote,
    Headings,
    Insertable,
    List,
    Paragraph,
    Placeholders,
    Q,
    RangedKey,
    Undo,
    allKeySuites,
    clearContent,
    getEditorResult,
    getSelectionMarkers,
    grabEditor,
    grabbedEditor,
    keyEvents,
    loadTestState,
    runAllSuites,
    runSuite,
    runTest,
    runUndoSuite,
    selectAll,
    setContent,
    undoTests;

  BlockQuote = require("./Text/Handlers/BlockQuote");

  Headings = require("./Text/Handlers/Headings");

  Insertable = require("./Text/Handlers/Insertable");

  List = require("./Text/Handlers/List");

  Paragraph = require("./Text/Handlers/Paragraph");

  Placeholders = require("./Text/Handlers/Placeholders");

  RangedKey = require("./Text/Handlers/RangedKey");

  Undo = require("./Undo/Undo").Undo;

  undoTests = require("./Undo/Undo.data.spec");

  Q = require("q");

  keyEvents = require("Test/GenerateKeyEvents");

  getSelectionMarkers = require("Test/GetSelectionMarkers");

  grabbedEditor = null;

  module.exports.prepareDebug = function () {
    return grabEditor(function (editor) {
      window.grabbedEditor = editor;
      grabbedEditor = editor;
      if (grabbedEditor != null) {
        return console.log("Debugging is ready");
      } else {
        return console.error("Could not find an editor");
      }
    });
  };

  grabEditor = function (callback) {
    return window.setTimeout(function () {
      var currNode, sel;
      sel = window.getSelection();
      currNode = sel.focusNode;
      while (
        (currNode != null ? currNode.tagName : void 0) == null ||
        currNode.tagName.toLowerCase() !== "content-area"
      ) {
        currNode = currNode.parentElement;
      }
      return callback(currNode.qwilrEditor);
    }, 2000);
  };

  module.exports.runTests = function (editor) {
    if (editor == null) {
      editor = grabbedEditor;
    }
    console.log("Starting Debugger...");
    if (editor == null) {
      console.error("Could not find an editor to use");
      return false;
    } else {
      console.log("Starting test suite");
      return runAllSuites(editor) && runUndoSuite(editor);
    }
  };

  runAllSuites = function (editor, suites) {
    var handler, suite;
    if (suites == null) {
      suites = allKeySuites;
    }
    if (suites.length === 0) {
      return true;
    }
    suite = suites.pop();
    handler = new suite.Handler(editor, editor.undo);
    console.log("Running " + suite.name + " test suite...");
    return runSuite(suite.tests, handler[suite.eventType], editor) && runAllSuites(editor, suites);
  };

  runUndoSuite = function (editor) {
    var error,
      failedTests,
      getEditorHtml,
      i,
      len,
      mockCheckpoint,
      mockStack,
      result,
      results,
      test,
      testNumber,
      testTimeout,
      undo;
    console.log("Running Undo test suite...");
    testNumber = 0;
    failedTests = [];
    mockStack = {
      shouldCheckpoint: function () {
        return true;
      },
      checkpoint: function (data) {
        return true;
      },
      pos: 0,
    };
    mockCheckpoint = function (func) {
      return {};
    };
    getEditorHtml = function () {
      return editor.currentElem().innerHTML;
    };
    testTimeout = function (func) {
      return func();
    };
    undo = new Undo(editor, {}, [], testTimeout, getEditorHtml, mockStack, mockCheckpoint, editor.focus);
    results = [];
    for (i = 0, len = undoTests.length; i < len; i++) {
      test = undoTests[i];
      testNumber++;
      loadTestState(editor, test.content);
      undo.unthrottledCheckpoint();
      result = getEditorResult(editor);
      if (result !== test.content) {
        error = 'Result was "' + result + '" but we expected "' + test.content + '"';
        console.error(testNumber + ". " + test.name + ": FAILED..." + error);
        results.push(false);
      } else {
        console.log(testNumber + ". " + test.name + ": PASSED");
        results.push(true);
      }
    }
    if (_.every(results)) {
      console.log("ALL UNDO TESTS PASSED!!!");
      return true;
    } else {
      console.log("NOT ALL UNDO TESTS PASSED");
      return false;
    }
  };

  allKeySuites = [
    {
      name: "BlockQuote",
      Handler: BlockQuote,
      eventType: "onKey",
      tests: require("Project/QwilrEditorV0/Text/Handlers/BlockQuote.unit.data"),
    },
    {
      name: "Headings",
      Handler: Headings,
      eventType: "onKey",
      tests: require("Project/QwilrEditorV0/Text/Handlers/Headings.unit.data"),
    },
    {
      name: "Insertable",
      Handler: Insertable,
      eventType: "onKey",
      tests: require("Project/QwilrEditorV0/Text/Handlers/Insertable.unit.data"),
    },
    {
      name: "List",
      Handler: List,
      eventType: "onKey",
      tests: require("Project/QwilrEditorV0/Text/Handlers/List.unit.data"),
    },
    {
      name: "Paragraph",
      Handler: Paragraph,
      eventType: "onKey",
      tests: require("Project/QwilrEditorV0/Text/Handlers/Paragraph.unit.data"),
    },
    {
      name: "RangedKey",
      Handler: RangedKey,
      eventType: "onRangedKey",
      tests: require("Project/QwilrEditorV0/Text/Handlers/RangedKey.unit.data"),
    },
  ];

  runSuite = function (testSuite, eventHandler, editor, onlyRunTest) {
    var failedTests, i, idx, len, passed, prefix, ref, suite, test, testName, testNumber;
    failedTests = [];
    testNumber = 0;
    for (prefix in testSuite) {
      suite = testSuite[prefix];
      ref = suite.tests;
      for (idx = i = 0, len = ref.length; i < len; idx = ++i) {
        test = ref[idx];
        testName = prefix + " " + test.name;
        testNumber++;
        if (onlyRunTest != null && onlyRunTest !== testNumber) {
          continue;
        }
        passed = runTest(
          testNumber,
          testName,
          editor,
          test.startContent,
          test.selectionResult,
          eventHandler,
          suite.key,
          test.tagName,
        );
        if (!passed) {
          failedTests.push({
            number: testNumber,
            name: testName,
          });
        }
      }
    }
    setContent(editor, "<p><br></p>");
    if (failedTests.length > 0) {
      console.log("NOT ALL TESTS PASSED");
      return false;
    } else {
      console.log("ALL TESTS PASSED!!!");
      return true;
    }
  };

  runTest = function (testNumber, testName, editor, startContent, expectedEndState, eventHandler, key, tagName) {
    var e, error, range, result;
    loadTestState(editor, startContent);
    range = editor.selection().getRange();
    e = keyEvents.trigger(key, range.anchor);
    if (tagName != null) {
      eventHandler(tagName, e);
    } else {
      eventHandler(e);
    }
    result = getEditorResult(editor);
    if (result !== expectedEndState) {
      error = 'Result was "' + result + '" but we expected "' + expectedEndState + '"';
      console.error(testNumber + ". " + testName + ": FAILED..." + error);
      return false;
    } else {
      console.log(testNumber + ". " + testName + ": PASSED");
      return true;
    }
  };

  loadTestState = function (editor, content) {
    var loadedRange;
    setContent(editor, content);
    loadedRange = getSelectionMarkers(editor.currentElem());
    if (loadedRange.end != null) {
      return editor.selection().setEndpoints(loadedRange.start, loadedRange.end);
    } else {
      return editor.selection().setCaret(loadedRange.start);
    }
  };

  module.exports.loadTestState = loadTestState;

  getEditorResult = function (editor) {
    var result;
    editor.selection().saveToMarkers();
    result = editor.currentElem().innerHTML;
    result = result.replace('<span data-marker="start"></span>', "[");
    result = result.replace('<span data-marker="end"></span>', "]");
    result = result.replace("[]", "|");
    result = result.replace("][", "|");
    return result;
  };

  module.exports.getEditorResult = getEditorResult;

  clearContent = function (editor) {
    return (editor.currentElem().innerHTML = "");
  };

  setContent = function (editor, content) {
    return (editor.currentElem().innerHTML = content);
  };

  selectAll = function (editor) {
    var end, start;
    start = qed.Point.start(editor.currentElem().firstChild);
    end = qed.Point.end(editor.currentElem().lastChild);
    return editor.selection().setEndpoints(start, end);
  };
}.call(this));
