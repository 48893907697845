// Generated by CoffeeScript 1.12.7

/*
  QuickBooks invoicing requires that taxes are mapped between Qwilr and QuickBooks and
  this module checks this.
 */

(function () {
  var QuickBooksTaxMapChecker;

  module.exports = QuickBooksTaxMapChecker = (function () {
    function QuickBooksTaxMapChecker($log, integrationService, block, account, notifyUser, telemetry) {
      var getTaxMaps, hasAllMappedTaxes, hasPushInvoiceOn, quote;
      quote = block;

      /*
        Check taxes are correctly mapped and track/notify if unmapped
       */
      this.checkTaxMaps = (function (_this) {
        return function () {
          var taxMaps;
          if (!integrationService.isEnabled("quickbooks")) {
            $log.error("QuickBooksTaxMapChecker should not have been called if QuickBooks isn't enabled");
            return;
          }
          if (hasPushInvoiceOn()) {
            taxMaps = getTaxMaps();
            if (!hasAllMappedTaxes(taxMaps)) {
              return notifyUser.warn(
                "Your quote has unmapped taxes. Please sync these taxes on your accounting integration.",
              );
            }
          }
        };
      })(this);
      hasPushInvoiceOn = function () {
        return quote.settings.accept["pushInvoice-quickbooks"] || false;
      };

      /*
        Given a tax map, ensure that all taxes on this quote are accounted for
        @param taxMaps [Object] an object mapping internal tax IDs to an accounting
        integration's tax ID
       */
      hasAllMappedTaxes = function (taxMaps) {
        return _.every(quote.taxes, function (tax) {
          return taxMaps[tax.id] != null;
        });
      };

      /*
        Get the tax maps mapping internal tax IDs to the IDs of an accounting integration
        Currently only setup for QuickBooks
       */
      getTaxMaps = function () {
        var ref, ref1;
        return (
          ((ref = account.integrations.quickbooks) != null
            ? (ref1 = ref.settings) != null
              ? ref1.taxMaps
              : void 0
            : void 0) || {}
        );
      };
    }

    return QuickBooksTaxMapChecker;
  })();
}.call(this));
