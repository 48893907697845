"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPlaceholder = void 0;
function getPlaceholder(store) {
    var isResolved = store.isResolved, hasComment = store.hasComment;
    if (isResolved) {
        return "Reply and reopen this thread";
    }
    else if (hasComment) {
        return selectAtRandom(["What do you think?", "Have your say", "Leave your reply"]);
    }
    else {
        return selectAtRandom(["What's on your mind?", "Here's a thought", "Leave your comment"]);
    }
}
exports.getPlaceholder = getPlaceholder;
function selectAtRandom(list) {
    var index = Math.floor(Math.random() * list.length);
    return list[index];
}
