// Generated by CoffeeScript 1.12.7

/*
   Function for determining the upgrade cost variables from a previewed invoice
   @param {object} invoice -  Stripe invoice object
   @return {object} - cost breakdown for upgrading a plan
    {
      payToday: How much the user must pay to settle the invoice (or credited to account)
      remainingTimeCost: If on the same billing frequency, the remaining cost of the period on the new plan
      unusedTimeCredit: The prorated credit from unused time on existing plan
      newPlanCost: If changing billing frequency, the cost of the new plan
    }
 */

(function () {
  module.exports = function (invoice) {
    var i, invoice_item, len, newPlanCost, payToday, ref, remainingTimeCost, unusedTimeCredit, upgradeCosts;
    if ((invoice != null ? invoice.lines : void 0) == null) {
      return {
        payToday: null,
        remainingTimeCost: null,
        unusedTimeCredit: null,
        newPlanCost: null,
      };
    }
    unusedTimeCredit = 0;
    remainingTimeCost = 0;
    newPlanCost = 0;

    /*
    iterates through the items listed on the invoice and accounts for them depending on whether they are credits or debits
    all invoices will contain the subscription as a non-prorated line item. 
    
    invoices that are for upgrades/downgrades that maintain payment frequency will only need use the 'prorated items'
    in this case, the non-protated subscription item is ignored
    
    invoices that are for upgrades/downgrades that change payment frequency will need to use the non-prorated subscription item to calculate final cost
    this is because 'remaining time' is not listed on such upgrades and pay total must be deduced from the ongoing non-prorated item
     */
    ref = invoice.lines.data;
    for (i = 0, len = ref.length; i < len; i++) {
      invoice_item = ref[i];
      if (invoice_item.proration === true) {
        if (invoice_item.amount <= 0) {
          unusedTimeCredit -= invoice_item.amount / 100;
        } else {
          remainingTimeCost += invoice_item.amount / 100;
        }
      } else {
        newPlanCost += invoice_item.amount / 100;
      }
    }
    if (remainingTimeCost) {
      payToday = -unusedTimeCredit + remainingTimeCost;
      remainingTimeCost = remainingTimeCost || null;
      newPlanCost = null;
    } else {
      payToday = invoice.total / 100;
      newPlanCost = invoice.total / 100 + unusedTimeCredit;
    }
    return (upgradeCosts = {
      payToday: Number(payToday != null ? payToday.toFixed(2) : void 0) || null,
      remainingTimeCost: Number(remainingTimeCost != null ? remainingTimeCost.toFixed(2) : void 0) || null,
      unusedTimeCredit: Number(unusedTimeCredit != null ? unusedTimeCredit.toFixed(2) : void 0) || null,
      newPlanCost: Number(newPlanCost != null ? newPlanCost.toFixed(2) : void 0) || null,
    });
  };
}.call(this));
