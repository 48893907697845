"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PostAcceptRedirect_1 = require("@CommonFrontendBackend/Accept/PostAcceptRedirect");
var lodash_1 = require("lodash");
var MAX_LABEL_LENGTH = 140;
var PostAcceptControls = (function () {
    function PostAcceptControls(createUi, block, telemetry) {
        var _this = this;
        this.block = block;
        this.telemetry = telemetry;
        this.hasValidUrl = function () {
            if (_this.ui.postAcceptanceUrl === "") {
                return true;
            }
            return (0, PostAcceptRedirect_1.isValidUrl)(_this.ui.postAcceptanceUrl);
        };
        this.tryCommitUrl = function () {
            _this.ui.urlIsValid = _this.hasValidUrl();
            if (_this.ui.urlIsValid) {
                var urlWithProtocol = (0, PostAcceptRedirect_1.maybeAddFallbackProtocol)(_this.ui.postAcceptanceUrl);
                if (_this.ui.postAcceptance.url !== urlWithProtocol && !(0, lodash_1.isEmpty)(urlWithProtocol)) {
                    _this.telemetry.trackOnly("Post Accept URL Added");
                }
                _this.ui.postAcceptance.url = urlWithProtocol;
                _this.ui.postAcceptanceUrl = urlWithProtocol;
            }
        };
        this.ui = createUi(require("./PostAccept.html"), {
            maxLabelLength: MAX_LABEL_LENGTH,
            postAcceptance: block.settings.postAcceptance,
            postAcceptanceUrl: block.settings.postAcceptance.url,
            onUrlBlur: function () {
                _this.tryCommitUrl();
            },
            onUrlChange: function () {
                _this.ui.urlIsValid = true;
            },
            onUrlKeypress: function (event) {
                if (event.key === "Enter") {
                    _this.tryCommitUrl();
                }
            },
            urlIsValid: true,
            shouldShowLabelLengthWarning: function () { return _this.ui.postAcceptance.continueLabel.length >= MAX_LABEL_LENGTH - 10; },
            getLabelLengthWarning: function () {
                var remainingChar = MAX_LABEL_LENGTH - _this.ui.postAcceptance.continueLabel.length;
                return "".concat(remainingChar, " characters left (max ").concat(MAX_LABEL_LENGTH, ")");
            },
            trackRedirectEnabledChange: function () {
                if (_this.ui.postAcceptance.enabled) {
                    telemetry.trackOnly("Post Accept Redirect Enabled");
                }
            },
            $init: function ($scope) {
                $scope.$watch(function () { return block.settings.postAcceptance.url; }, function (newUrl) {
                    if (_this.ui.postAcceptanceUrl !== newUrl) {
                        _this.ui.postAcceptanceUrl = newUrl;
                        _this.ui.urlIsValid = true;
                    }
                });
            },
        });
    }
    return PostAcceptControls;
}());
exports.default = PostAcceptControls;
