"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DashboardFoldersDataStore = void 0;
var mobx_1 = require("mobx");
var ResourceTypes_1 = require("@CommonFrontendBackend/Resources/ResourceTypes");
var Types_1 = require("@CommonFrontendBackend/Permissions/Types");
var EffectivePermission_1 = require("@CommonFrontendBackend/Folders/EffectivePermission");
var FoldersTelemetry_1 = require("Dashboard/FoldersTelemetry");
var DashboardFoldersDataStore = (function () {
    function DashboardFoldersDataStore(_userId, foldersApiService, resourceApiService, telemetry, collectionType) {
        var _this = this;
        this._userId = _userId;
        this.foldersApiService = foldersApiService;
        this.resourceApiService = resourceApiService;
        this.collectionType = collectionType;
        this._isRefreshing = false;
        this.didFetchError = false;
        this._allFolders = [];
        this.hasLoadedData = false;
        this.getEffectiveFolderPermissions = function (folderId) {
            var folder = _this.getFolder(folderId);
            return (0, EffectivePermission_1.calculateEffectiveFolderPermissions)([folder]);
        };
        this.updateFolderName = function (folderId, folderName) { return __awaiter(_this, void 0, Promise, function () {
            var folder;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.foldersApiService.updateFolderName(folderId, folderName)];
                    case 1:
                        _a.sent();
                        this.telemetry.folderRenamed(this.collectionType);
                        folder = this.getFolder(folderId);
                        folder.name = folderName;
                        return [2];
                }
            });
        }); };
        this.telemetry = new FoldersTelemetry_1.FoldersTelemetry(telemetry);
    }
    Object.defineProperty(DashboardFoldersDataStore.prototype, "userId", {
        get: function () {
            return this._userId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardFoldersDataStore.prototype, "isRefreshing", {
        get: function () {
            return this._isRefreshing;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardFoldersDataStore.prototype, "allFolders", {
        get: function () {
            return this._allFolders;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardFoldersDataStore.prototype, "hasLoaded", {
        get: function () {
            return this.hasLoadedData;
        },
        enumerable: false,
        configurable: true
    });
    DashboardFoldersDataStore.prototype.createFolder = function (name_1, origin_1, parentFolder_1) {
        return __awaiter(this, arguments, Promise, function (name, origin, parentFolder, autoRefresh, permissions) {
            var result;
            if (autoRefresh === void 0) { autoRefresh = true; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setIsRefreshing(true);
                        return [4, this.foldersApiService.createFolder(name, this.collectionType, parentFolder, permissions)];
                    case 1:
                        result = _a.sent();
                        this.telemetry.folderCreated(origin, result.ancestors.length, FoldersTelemetry_1.FolderPermissionType.Shared, this.collectionType);
                        if (!autoRefresh) return [3, 3];
                        return [4, this.forceFetch()];
                    case 2:
                        _a.sent();
                        return [3, 4];
                    case 3:
                        this.setIsRefreshing(false);
                        _a.label = 4;
                    case 4: return [2];
                }
            });
        });
    };
    DashboardFoldersDataStore.prototype.fetch = function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!this.hasLoadedData) return [3, 2];
                        return [4, this.forceFetch()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    DashboardFoldersDataStore.prototype.forceFetch = function () {
        return __awaiter(this, void 0, Promise, function () {
            var errorFetching, _a, e_1;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.setIsRefreshing(true);
                        errorFetching = false;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, , 4]);
                        _a = this;
                        return [4, this.foldersApiService.getFolders(this.collectionType)];
                    case 2:
                        _a._allFolders = _b.sent();
                        this.hasLoadedData = true;
                        return [3, 4];
                    case 3:
                        e_1 = _b.sent();
                        errorFetching = true;
                        return [3, 4];
                    case 4:
                        this.setIsRefreshing(false, errorFetching);
                        return [2];
                }
            });
        });
    };
    DashboardFoldersDataStore.prototype.getFolder = function (folderId) {
        return this._allFolders.find(function (f) { return f.id === folderId; });
    };
    DashboardFoldersDataStore.prototype.moveFolder = function (folderToMove, destinationFolder) {
        return __awaiter(this, void 0, Promise, function () {
            var folderIResource;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.setIsRefreshing(true);
                        folderIResource = (0, ResourceTypes_1.folderAsResource)(folderToMove);
                        return [4, this.resourceApiService.moveResources(destinationFolder, [folderIResource])];
                    case 1:
                        _a.sent();
                        this.telemetry.folderMoved(FoldersTelemetry_1.FolderMovedTrigger.Drag, this.collectionType);
                        return [4, this.forceFetch()];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    DashboardFoldersDataStore.prototype.deleteFolder = function (folderToDelete) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.resourceApiService.deleteResources([
                            {
                                id: folderToDelete,
                                type: ResourceTypes_1.DashboardResourceType.Folder,
                            },
                        ])];
                    case 1:
                        _a.sent();
                        this.telemetry.folderDeleted(FoldersTelemetry_1.FolderPermissionType.Shared, this.collectionType);
                        return [4, this.forceFetch()];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    DashboardFoldersDataStore.prototype.updateUserPermission = function (folderId, userId, permission) {
        return __awaiter(this, void 0, Promise, function () {
            var folder;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        permission = stripNoneAction(permission);
                        return [4, this.foldersApiService.updateUserPermission(folderId, userId, permission)];
                    case 1:
                        _a.sent();
                        folder = this.getFolder(folderId);
                        folder.permissions.users[userId] = permission;
                        return [2];
                }
            });
        });
    };
    DashboardFoldersDataStore.prototype.updateAccountPermission = function (folderId, permission) {
        return __awaiter(this, void 0, Promise, function () {
            var folder;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        permission = stripNoneAction(permission);
                        return [4, this.foldersApiService.updateAccountPermission(folderId, permission)];
                    case 1:
                        _a.sent();
                        folder = this.getFolder(folderId);
                        folder.permissions.account = permission;
                        return [2];
                }
            });
        });
    };
    DashboardFoldersDataStore.prototype.updateTeamPermission = function (folderId, teamId, permission) {
        return __awaiter(this, void 0, Promise, function () {
            var folder;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        permission = stripNoneAction(permission);
                        return [4, this.foldersApiService.updateTeamPermission(folderId, teamId, permission)];
                    case 1:
                        _a.sent();
                        folder = this.getFolder(folderId);
                        folder.permissions.teams[teamId] = permission;
                        return [2];
                }
            });
        });
    };
    DashboardFoldersDataStore.prototype.setIsRefreshing = function (isRefreshing, didError) {
        if (didError === void 0) { didError = null; }
        this._isRefreshing = isRefreshing;
        if (didError !== null) {
            this.didFetchError = didError;
        }
    };
    DashboardFoldersDataStore.prototype.moveBlocksToFolder = function (blockIds, folderId) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.resourceApiService.moveBlocksToFolder(blockIds, folderId)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], DashboardFoldersDataStore.prototype, "_isRefreshing", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], DashboardFoldersDataStore.prototype, "didFetchError", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Array)
    ], DashboardFoldersDataStore.prototype, "_allFolders", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], DashboardFoldersDataStore.prototype, "hasLoadedData", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DashboardFoldersDataStore.prototype, "isRefreshing", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], DashboardFoldersDataStore.prototype, "allFolders", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DashboardFoldersDataStore.prototype, "hasLoaded", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean, Object]),
        __metadata("design:returntype", void 0)
    ], DashboardFoldersDataStore.prototype, "setIsRefreshing", null);
    return DashboardFoldersDataStore;
}());
exports.DashboardFoldersDataStore = DashboardFoldersDataStore;
function stripNoneAction(permission) {
    if (permission === Types_1.Action.None) {
        return null;
    }
    else {
        return permission;
    }
}
