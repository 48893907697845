// Generated by CoffeeScript 1.12.7

/*
  Defines a mock clipboard in an attempt to force the browser to act rationally
  on paste commands
 */

(function () {
  var Cleaner, Clipboard, Helpers, Point, Range;

  Point = qed.Point;

  Range = qed.Range;

  Helpers = require("../Text/Helpers");

  Cleaner = require("../../../../../CommonFrontend/HtmlCleaner/HtmlCleaner");

  module.exports = Clipboard = (function () {
    function Clipboard(editor, notifyUser, internalSlack, $log) {
      var assert, cleanPlaceholder, cleaner, clipboard, editorElem, h, pastePoint, setupClipboard, util;
      h = require("../Text/Helpers");
      assert = h.assert;
      Point = qed.Point;
      util = qed.util;
      pastePoint = null;
      clipboard = document.createElement("DIV");
      clipboard.className = "qwilr-clipboard";
      clipboard.setAttribute("contenteditable", true);
      clipboard.setAttribute("tabindex", -1);
      editorElem = editor.currentElem();
      editorElem.parentElement.appendChild(clipboard);
      this.elem = clipboard;
      cleaner = new Cleaner(editor.insertWidget);
      this.cleaner = cleaner;
      setupClipboard = (function (_this) {
        return function (containerTag) {
          var children, p;
          children = _this.elem.children;
          while (_this.elem.children.length) {
            _this.elem.removeChild(_this.elem.firstChild);
          }
          p = document.createElement(containerTag);
          p.innerHTML = "<br>";
          clipboard.appendChild(p);
          return Point.start(p);
        };
      })(this);
      this.pasteIn = (function (_this) {
        return function () {
          var range, ref;
          if (!editor.selection().isCollapsed()) {
            range = editor.selection().getRange().order();
            pastePoint = h.removeSelection(editor, range.anchor, range.focus);
          } else {
            pastePoint = editor.selection().getRange().anchor;
          }
          _this.pasteContextTag = pastePoint.containingElement().tagName;
          if (!((ref = _this.pasteContextTag) === "P" || ref === "H1" || ref === "H2")) {
            _this.pasteContextTag = "P";
          }
          editor.selection().setCaret(setupClipboard(_this.pasteContextTag));
        };
      })(this);
      this.grabPastePoint = function () {
        var returnPoint;
        returnPoint = pastePoint;
        pastePoint = null;
        return returnPoint;
      };
      this.getInsertionPoint = function (caret) {
        if (caret.containingElement() === editor.currentElem() && caret.canJoin()) {
          caret = Helpers.listSafeJoinLeft(caret, editor);
        }
        cleanPlaceholder(caret);
        if (caret.containingElement() === editor.currentElem()) {
          if (caret.type === "before") {
            caret = qed.Point.start(caret.node);
          } else if (caret.type === "start") {
            caret = qed.Point.start(caret.node.firstChild);
          } else if (caret.type === "end") {
            caret = qed.Point.end(caret.node.lastChild);
          } else if (caret.type === "after") {
            caret = qed.Point.end(caret.node);
          }
        }
        return caret;
      };
      cleanPlaceholder = function (caret) {
        if (
          caret.containingElement() !== editor.currentElem() &&
          caret.containingElement().hasAttribute("placeholder")
        ) {
          return caret.containingElement().removeAttribute("placeholder");
        }
      };
      this.shouldInheritBlockContext = function (tokens) {
        var i, len, tok;
        for (i = 0, len = tokens.length; i < len; i++) {
          tok = tokens[i];
          if (tok.blockType) {
            return false;
          }
        }
        return true;
      };
      this.insertFormattedPasteData = (function (_this) {
        return function (insertingIncompatible, tokens, insertionPoint) {
          var blockElem,
            container,
            createBlockElem,
            createTextItem,
            dummy,
            getToTop,
            i,
            incompatible,
            insertBulletAtTopWhileMerging,
            isEmpty,
            isInCompatibleGroupContainer,
            joinAfter,
            len,
            processTextToken,
            shouldInheritBlock,
            tagMatches,
            tok,
            wrap;
          if (tokens.length === 0) {
            return insertionPoint;
          }
          shouldInheritBlock = !insertingIncompatible && _this.shouldInheritBlockContext(tokens);
          assert(!(shouldInheritBlock && insertingIncompatible));
          getToTop = function () {
            var results;
            results = [];
            while (insertionPoint.containingElement() !== editorElem) {
              if (!insertionPoint.nodeAfter()) {
                results.push(insertionPoint.splitRight(false));
              } else {
                results.push(insertionPoint.splitLeft(false));
              }
            }
            return results;
          };
          isEmpty = function (obj) {
            var k;
            for (k in obj) {
              return false;
            }
            return true;
          };
          createTextItem = function (tok) {
            var k, ref, textNode, v, wrapper;
            textNode = document.createTextNode(tok.text);
            if (isEmpty(tok.attrs)) {
              return textNode;
            }
            if (tok.attrs.link) {
              wrapper = document.createElement("a");
            } else {
              wrapper = document.createElement("span");
            }
            ref = tok.attrs;
            for (k in ref) {
              v = ref[k];
              Helpers.inlineAttributes[k].write(wrapper, v);
            }
            wrapper.appendChild(textNode);
            return wrapper;
          };
          wrap = function (parentTag, child) {
            var container;
            container = document.createElement(parentTag);
            container.appendChild(child);
            return container;
          };
          createBlockElem = function (tok) {
            var elem;
            return (elem = document.createElement(tok.blockType || "p"));
          };
          isInCompatibleGroupContainer = function (insertionPoint, tok) {
            var container;
            container = insertionPoint.containingElement();
            return container !== editorElem && container.tagName.toLowerCase() === tok.bulletStyle;
          };
          tagMatches = function (name, elem) {
            var ref;
            return (
              (elem != null ? ((ref = elem.tagName) != null ? ref.toLowerCase() : void 0) : void 0) ===
              name.toLowerCase()
            );
          };
          processTextToken = function (tok) {
            var node;
            node = createTextItem(tok);
            insertionPoint.insert(node);
            return (insertionPoint = Point.after(node));
          };
          if (insertingIncompatible) {
            joinAfter = false;
            if (tokens[0].type !== "block" && tokens[0].type !== "widget") {
              throw new Error("first must be block or widget when incompatible");
            }
            while (insertionPoint.containingElement() !== editorElem) {
              incompatible = insertionPoint.containingElement();
              if (incompatible.textContent === "") {
                insertionPoint = Point.after(incompatible).rightNormalized();
                incompatible.parentNode.removeChild(incompatible);
              } else {
                break;
              }
            }
            getToTop();
          }
          for (i = 0, len = tokens.length; i < len; i++) {
            tok = tokens[i];
            switch (tok.type) {
              case "text":
                assert(insertionPoint.containingElement() !== editorElem);
                processTextToken(tok);
                break;
              case "widget":
                getToTop();
                dummy = document.createElement("span");
                insertionPoint.insert(dummy);
                insertionPoint = Point.after(dummy).rightNormalized();
                editor.replaceElemWithWidget(dummy, tok.model);
                break;
              case "block":
                blockElem = createBlockElem(tok);
                container = insertionPoint.containingElement();
                insertBulletAtTopWhileMerging = function () {
                  var matchesAfter, matchesBefore;
                  matchesBefore = tagMatches(tok.bulletStyle, insertionPoint.nodeBefore());
                  matchesAfter = tagMatches(tok.bulletStyle, insertionPoint.nodeAfter());
                  if (matchesBefore && matchesAfter) {
                    insertionPoint.joinLeft();
                    return insertionPoint.insert(blockElem);
                  } else if (matchesAfter) {
                    insertionPoint = Point.start(insertionPoint.nodeAfter());
                    return insertionPoint.insert(blockElem);
                  } else if (matchesBefore) {
                    insertionPoint = Point.end(insertionPoint.nodeBefore());
                    return insertionPoint.insert(blockElem);
                  } else {
                    return insertionPoint.insert(wrap(tok.bulletStyle, blockElem));
                  }
                };
                if (container === editorElem) {
                  assert(
                    !shouldInheritBlock || insertionPoint.leftNormalized().node.tagName.toLowerCase() === "insertable",
                  );
                  if (tok.blockType === "li") {
                    insertBulletAtTopWhileMerging();
                  } else {
                    if (shouldInheritBlock) {
                      blockElem = insertionPoint.node;
                    } else {
                      insertionPoint.insert(blockElem);
                    }
                  }
                } else {
                  if (shouldInheritBlock) {
                    insertionPoint.splitRight(true);
                    blockElem = insertionPoint.nodeAfter();
                  } else {
                    insertionPoint.splitRight(blockElem);
                    insertionPoint = Point.after(blockElem).rightNormalized();
                    if (tok.blockType === "li") {
                      if (!isInCompatibleGroupContainer(insertionPoint, tok)) {
                        getToTop();
                        insertBulletAtTopWhileMerging();
                      }
                    } else {
                      if (insertionPoint.containingElement() !== editorElem) {
                        insertionPoint.splitRight(false);
                        insertionPoint.insert(blockElem);
                      }
                    }
                  }
                }
                insertionPoint = Point.start(blockElem);
                break;
              default:
                throw new Error("unknown token type: " + tok.type);
            }
          }
          return insertionPoint;
        };
      })(this);
      this.pasteContentFromClipboard = (function (_this) {
        return function () {
          var anchor, endPoint, point;
          point = _this.grabPastePoint();
          anchor = _this.getInsertionPoint(point);
          while (!util.isBlock(anchor.containingElement())) {
            anchor.splitLeft(true);
          }
          _this.elem.normalize();
          endPoint = cleaner.cleanHtml(_this.elem, anchor, _this.pasteContextTag, editor.currentElem());
          if (endPoint.containingElement() !== editor.currentElem()) {
            editor.selection().setCaret(endPoint);
          }
          return h.cleanStemNodes(editor);
        };
      })(this);
      this.elem.addEventListener("input", this.pasteContentFromClipboard);
    }

    return Clipboard;
  })();
}.call(this));
