"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.competitors = void 0;
exports.competitors = [
    "Better Proposals",
    "Google Docs",
    "Google Slides",
    "Microsoft Word",
    "Pandadoc",
    "Proposify",
    "Other",
];
