// Generated by CoffeeScript 1.12.7

/*
  Add DeleteOnlyMenu for blocks that can only be deleted.
  @TODO(vishesh, 2018-07-20) Refactor the message and other paramters to be supplied in constructor
 */

(function () {
  var DeleteOnlyMenu;

  module.exports = DeleteOnlyMenu = (function () {
    function DeleteOnlyMenu(
      createUi,
      block,
      initSubscription,
      deleteBlock,
      $timeout,
      make,
      showDeleteModal,
      isLockedByPermission,
    ) {
      var clickCount, message, ref, showUpgrade;
      if (isLockedByPermission) {
        message = "Editable by admins only";
        showUpgrade = false;
      } else if (block.type === "Accept") {
        message = "Editing an Accept block is only available on our paid plans.";
        showUpgrade = true;
      } else if ((ref = block.type) === "QuoteV2" || ref === "Quote") {
        message = "Editing a Quote block is only available on our paid plans.";
        showUpgrade = true;
      }
      this.ui = createUi(require("./DeleteOnlyMenu.html"), {
        message: message,
        showUpgrade: showUpgrade,
        upgrade: function () {
          return initSubscription.init();
        },
        delete: function () {
          return showDeleteModal({
            title: "Remove block",
            explainer: "Are you sure you want to delete this block?",
            deleteButton: "Delete block",
            cancelButton: "Go back",
          }).then(function (confirmed) {
            if (confirmed) {
              return deleteBlock(block);
            }
          });
        },
      });
      clickCount = 0;
      this.addBorder = (function (_this) {
        return function () {
          var thisClickCount;
          _this.ui.className = "with-border";
          clickCount++;
          thisClickCount = clickCount;
          return $timeout(function () {
            if (thisClickCount === clickCount) {
              return (_this.ui.className = "");
            }
          }, 500);
        };
      })(this);
    }

    return DeleteOnlyMenu;
  })();
}.call(this));
