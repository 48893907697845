// Generated by CoffeeScript 1.12.7
(function () {
  var MediaSource,
    StockVideoTile,
    StockVideosFactory,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  StockVideoTile = require("./StockVideoTile.js");

  MediaSource = require("@CommonFrontendBackend/AssetLibrary/Types").MediaSource;

  /*
    'View factory' for listing our existing stock videos.
    The ability to preview video tiles is based off the old VideoPicker
   */

  module.exports = StockVideosFactory = (function () {
    function StockVideosFactory($q, make, createUi, selectCallback, $rootScope, $timeout) {
      this.createUi = createUi;
      this.selectCallback = selectCallback;
      this.pauseAllVideos = bind(this.pauseAllVideos, this);
      this.playVideo = bind(this.playVideo, this);
      this.getCSSClass = bind(this.getCSSClass, this);
      this.createHeader = bind(this.createHeader, this);
      this.createTileData = bind(this.createTileData, this);
      this.select = bind(this.select, this);
      this.fetchFromService = bind(this.fetchFromService, this);
      this.setup = bind(this.setup, this);
      this.q = $q;
      this.make = make;
      this.videoService = make(require("./StockVideoService"));
      this.timeout = $timeout;
      this.rootScope = $rootScope;
      this.scroller = make(require("./InfiniteScroller.js"), {
        viewFactory: this,
      });
    }

    StockVideosFactory.prototype.setup = function (data) {
      this.itemsPerPage = 12;
      this.tileData = [];
      return this.scroller.setup();
    };

    StockVideosFactory.prototype.fetchFromService = function (page) {
      return this.videoService.getVideos(page, this.itemsPerPage);
    };

    StockVideosFactory.prototype.select = function (id, url) {
      return this.selectCallback(MediaSource.StockVideo, {
        id: id,
        url: url,
      });
    };

    StockVideosFactory.prototype.createTileData = function (item) {
      return this.make(StockVideoTile, {
        item: item,
        selectHook: this.select,
        playVideoHook: this.playVideo,
      });
    };

    StockVideosFactory.prototype.createHeader = function () {
      var ui;
      ui = this.createUi(require("./GenericTitleHeader.html"), {
        title: "Qwilr Videos",
      });
      return ui;
    };

    StockVideosFactory.prototype.getCSSClass = function () {
      return "videos-view";
    };

    /*
      Play the given video after pausing all other videos
     */

    StockVideosFactory.prototype.playVideo = function (videoTile) {
      if (!videoTile.isPlaying()) {
        this.pauseAllVideos();
        return videoTile.play();
      }
    };

    StockVideosFactory.prototype.pauseAllVideos = function () {
      var i, len, ref, results, videoTile;
      ref = this.tileData;
      results = [];
      for (i = 0, len = ref.length; i < len; i++) {
        videoTile = ref[i];
        results.push(videoTile.pause());
      }
      return results;
    };

    return StockVideosFactory;
  })();
}.call(this));
