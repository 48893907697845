"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getROIPresetDataFromId = void 0;
var ROIPresetID_1 = require("./ROIPresetID");
var getUrlbyPhotoId = function (id) {
    return "https://images.unsplash.com/photo-".concat(id, "?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwxMDE5OXwwfDF8Y29sbGVjdGlvbnwxfEtYRUFnWFJoeHRFfHx8fHx8fDE2Mjg1NjkwNTY&ixlib=rb-1.2.1&q=80&w=1080");
};
var dollarsSaved = {
    slider: {
        label: "Team members",
        increment: 1,
        min: 5,
        max: 100,
        initialValue: 15,
    },
    inputs: {
        A: {
            label: "Average salary",
            value: 80000,
            min: 0,
            max: 500000,
            prefix: "$",
            suffix: "",
        },
        B: {
            label: "Monthly admin hours per employee",
            value: 20,
            min: 0,
            max: 80,
            prefix: "",
            suffix: "hrs",
        },
        C: {
            label: "Software setup cost",
            value: 9000,
            min: 0,
            max: 100000,
            prefix: "$",
            suffix: "",
        },
        D: {
            label: "Subscription cost",
            value: 28500,
            min: 0,
            max: 100000,
            prefix: "$",
            suffix: "/yr",
        },
    },
    roiResult: {
        prefix: "$",
        suffix: "",
        formula: "{{slider}} * {{A}} / 2080 * {{B}} * 12 - ({{C}} + {{D}})",
        label: {
            plain: "Potential savings after one year",
        },
    },
    title: {
        plain: "Calculator",
    },
    description: {
        plain: "Fill out the assumptions below to match your individual situation",
    },
    variableCounter: 4,
    backgroundImageUrl: getUrlbyPhotoId("1435575653489-b0873ec954e2"),
    hasSelectedPreset: true,
};
var timeSaved = {
    slider: {
        label: "Monthly leads",
        increment: 1,
        min: 1,
        max: 200,
        initialValue: 25,
    },
    inputs: {
        A: {
            label: "Time spent per lead",
            value: 120,
            min: 0,
            max: 1000,
            prefix: "",
            suffix: "mins",
        },
    },
    roiResult: {
        prefix: "",
        suffix: "hrs",
        formula: "{{A}} * {{slider}} / 60 * 0.5",
        label: {
            plain: "Monthly time saved",
        },
    },
    title: {
        plain: "Calculator",
    },
    description: {
        plain: "Fill out on average the amount of time you currently spend",
    },
    variableCounter: 1,
    backgroundImageUrl: getUrlbyPhotoId("1614059632169-522876ce04c8"),
    hasSelectedPreset: true,
};
var sunkCost = {
    slider: {
        label: "Monthly Admin hours",
        increment: 1,
        min: 1,
        max: 100,
        initialValue: 15,
    },
    inputs: {
        A: {
            label: "Avg admin labor cost",
            value: 80,
            min: 0,
            max: 100000,
            prefix: "$",
            suffix: "/hour",
        },
        B: {
            label: "Existing software costs",
            value: 3200,
            min: 0,
            max: 10000,
            prefix: "$",
            suffix: "/mo",
        },
    },
    roiResult: {
        prefix: "$",
        suffix: "",
        formula: "({{slider}} * {{A}} + {{B}}) * 12",
        label: {
            plain: "How much you're currently spending per year",
        },
    },
    title: {
        plain: "Calculator",
    },
    description: {
        plain: "Fill out the assumptions below to match your individual situation",
    },
    variableCounter: 2,
    backgroundImageUrl: getUrlbyPhotoId("1517245386807-bb43f82c33c4"),
    hasSelectedPreset: true,
};
var percentageSaved = {
    slider: {
        label: "Team members",
        increment: 1,
        min: 5,
        max: 100,
        initialValue: 25,
    },
    inputs: {
        A: {
            label: "Average salary",
            value: 80000,
            min: 0,
            max: 500000,
            prefix: "$",
            suffix: "",
        },
        B: {
            label: "Monthly admin hours per employee",
            value: 20,
            min: 0,
            max: 80,
            prefix: "",
            suffix: "hrs",
        },
        C: {
            label: "Software setup cost",
            value: 9000,
            min: 0,
            max: 100000,
            prefix: "$",
            suffix: "",
        },
        D: {
            label: "Subscription cost",
            value: 28500,
            min: 0,
            max: 100000,
            prefix: "$",
            suffix: "/yr",
        },
    },
    roiResult: {
        prefix: "",
        suffix: "%",
        formula: "({{slider}} * {{A}} / 2080 * {{B}} * 12 - ({{C}} + {{D}})) / ({{slider}} * {{A}} / 2080 * {{B}} * 12) * 100",
        label: {
            plain: "Savings per year",
        },
    },
    title: {
        plain: "Calculator",
    },
    description: {
        plain: "Fill out the assumptions below to match your individual situation",
    },
    variableCounter: 4,
    backgroundImageUrl: getUrlbyPhotoId("1621519991769-ad15976f56fa"),
    hasSelectedPreset: true,
};
var resourcesSaved = {
    slider: {
        label: "Pages printed per day",
        increment: 10,
        min: 10,
        max: 10000,
        initialValue: 20,
    },
    inputs: {
        A: {
            label: "Wood used per printed page",
            value: 0.012,
            min: 0,
            max: 100000,
            prefix: "",
            suffix: "kg",
        },
    },
    roiResult: {
        prefix: "",
        suffix: "kg",
        formula: "{{slider}} * {{A}} * 365",
        label: {
            plain: "Wood saved per year",
        },
    },
    title: {
        plain: "Calculator",
    },
    description: {
        plain: "Environmental impact estimates were made using the Environmental Paper Network Paper Calculator Version 4.0. For more information visit www.papercalculator.org",
    },
    variableCounter: 1,
    backgroundImageUrl: getUrlbyPhotoId("1448375240586-882707db888b"),
    hasSelectedPreset: true,
};
var blank = {
    slider: {
        label: "Main variable",
        increment: 1,
        min: 1,
        max: 100,
        initialValue: 20,
    },
    inputs: {
        A: {
            label: "Example variable",
            value: 80,
            min: 0,
            max: 1000,
            prefix: "",
            suffix: "",
        },
    },
    roiResult: {
        prefix: "$",
        suffix: "",
        formula: "{{slider}} * {{A}}",
        label: {
            plain: "",
        },
    },
    title: {
        plain: "",
    },
    description: {
        plain: "",
    },
    variableCounter: 1,
    backgroundImageUrl: "",
    hasSelectedPreset: true,
};
var presets = (_a = {},
    _a[ROIPresetID_1.ROIPresetID.DollarsSaved] = dollarsSaved,
    _a[ROIPresetID_1.ROIPresetID.TimeSaved] = timeSaved,
    _a[ROIPresetID_1.ROIPresetID.SunkCost] = sunkCost,
    _a[ROIPresetID_1.ROIPresetID.PercentageSaved] = percentageSaved,
    _a[ROIPresetID_1.ROIPresetID.ResourcesSaved] = resourcesSaved,
    _a[ROIPresetID_1.ROIPresetID.Blank] = blank,
    _a);
var getROIPresetDataFromId = function (id) {
    return presets[id];
};
exports.getROIPresetDataFromId = getROIPresetDataFromId;
