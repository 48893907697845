// Generated by CoffeeScript 1.12.7
(function () {
  var WidgetWrapperController;

  module.exports = WidgetWrapperController = (function () {
    function WidgetWrapperController(
      createUi,
      make,
      widget,
      onDelete,
      triggerContentEvent,
      selection,
      editor,
      $timeout,
    ) {
      this.model = widget.ui.model;
      this.ui = createUi(require("./Wrapper.html"), {
        isSelected: selection.isSelected,
        destroy: (function (_this) {
          return function () {
            widget.remove();
            triggerContentEvent();
            return onDelete(_this.model.id);
          };
        })(this),
        enterResizeMode: function () {
          return widget.ui.enterResizeMode();
        },
        widget: widget.ui,
        canResize: (function (_this) {
          return function () {
            return _this.model.type === "image" && widget.canResize();
          };
        })(this),
        hasContent: function () {
          return !widget.isBlank();
        },
        showControls: (function (_this) {
          return function () {
            return widget.isBlank() || _this.ui.isHovered;
          };
        })(this),
        $onElementRendered: (function (_this) {
          return function (elem, propertyName) {
            if (propertyName === "widget-wrapper") {
              return (_this.elem = elem[0]);
            }
          };
        })(this),
      });
      this.select = function () {
        return $timeout(selection.select);
      };
      this.deselect = (function (_this) {
        return function () {
          _this.entered = null;
          return $timeout(selection.deselect);
        };
      })(this);
      this.isSelected = selection.isSelected;
    }

    return WidgetWrapperController;
  })();
}.call(this));
