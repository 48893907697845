"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getClickToAddBehaviours = void 0;
var EditorTypes_1 = require("../../EditorTypes");
var BehaviourBuilder_1 = require("../BehaviourBuilder");
var lodash_1 = require("lodash");
var slate_1 = require("slate");
var slate_react_1 = require("slate-react");
var ClickToAddImageChecks_1 = require("../../ClickToAdd/ClickToAddImageChecks");
var ClickToAddActions_1 = require("./ClickToAddActions");
var Types_1 = require("@CommonFrontendBackend/AssetLibrary/Types");
var Utils_1 = require("Project/QwilrEditor/Utils");
var TelemetryParameterValue_1 = require("Telemetry/TelemetryParameterValue");
var Types_2 = require("@CommonFrontendBackend/AssetLibrary/Types");
var InvalidReason_1 = require("Project/QwilrEditor/ClickToAdd/InvalidReason");
var TelemetryEnums_1 = require("Telemetry/TelemetryEnums");
var ImageNodeFactory_1 = require("Project/QwilrEditor/Widgets/ImageWidget/ImageNodeFactory");
var Video_1 = require("Project/QwilrEditor/WidgetRegistry/Widgets/Video");
var getClickToAddBehaviours = function (di) {
    var blockStore = di.get("blockStore");
    var projectStore = di.get("projectStore");
    var clickToAddStore = di.get("clickToAddStore");
    var mediaUsageReporter = di.get("mediaUsageReporter");
    var editorTelemetry = di.get("editorTelemetry");
    var contentSidebarTelemetry = di.get("contentSidebarTelemetry");
    return {
        onClickToAddImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("clickToAddImage")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "image", action: "clickToAdd" })
            .doAction(function (event, editor) {
            var _a = event.data, mediaData = _a.mediaData, mediaSource = _a.mediaSource, selection = _a.selection, telemetryData = _a.telemetryData;
            var view = telemetryData.view, tab = telemetryData.tab, source = telemetryData.source, originWasFiltered = telemetryData.originWasFiltered, assetFolderType = telemetryData.assetFolderType;
            var invalidReason = (0, ClickToAddImageChecks_1.isInvalidClickToAdd)({
                selection: selection,
                editor: editor,
                projectStore: projectStore,
                blockStore: blockStore,
            });
            if (invalidReason) {
                clickToAddStore.notifyForInvalidClickToAdd(invalidReason);
                editorTelemetry.inlineContentCouldNotBeAdded((0, InvalidReason_1.mapInvalidReasonToTelemetry)(invalidReason));
                return true;
            }
            var imageNode;
            var uploadId = mediaData.uploadId, altDescription = mediaData.altDescription;
            if (mediaSource === Types_1.MediaSource.FastUpload) {
                imageNode = (0, ImageNodeFactory_1.createImageNode)(mediaData.url, { alt: altDescription, uploadId: uploadId });
            }
            else {
                imageNode = (0, ImageNodeFactory_1.createImageNode)(mediaData.url, { alt: altDescription });
            }
            slate_react_1.ReactEditor.focus(editor);
            slate_1.Transforms.select(editor, selection);
            (0, ClickToAddActions_1.insertNodeFromClickToAdd)(editor, imageNode);
            mediaUsageReporter.reportUsage(mediaSource, mediaData);
            var isFromSidebar = (0, lodash_1.isNil)(source);
            editorTelemetry.inlineContentAdded({
                blockType: blockStore.blockType,
                contentType: TelemetryParameterValue_1.ContentAddedType.Image,
                origin: TelemetryParameterValue_1.ContentAddedOrigin.Click,
                isInsideSideBySide: (0, Utils_1.isInsideSideBySide)(editor, selection),
                isInsideLayout: (0, Utils_1.isInsideLayout)(editor, selection),
                source: isFromSidebar ? Types_2.MetricSource.Sidebar : source,
                originWasFiltered: originWasFiltered,
                assetFolderType: assetFolderType,
            });
            contentSidebarTelemetry.sidebarAssetAdded({
                assetType: TelemetryEnums_1.SidebarAssetAddedType.Image,
                insertMethod: TelemetryEnums_1.SidebarAssetAddedInsertMethod.Click,
                source: view,
                secondarySource: tab,
            });
            (0, lodash_1.defer)(function () {
                var insertedImagePath = slate_1.Range.start(editor.selection).path;
                (0, ClickToAddActions_1.scrollToInsertedNode)(editor, insertedImagePath, "image");
            });
            return true;
        }),
        onClickToAddVideo: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("clickToAddVideo")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "video", action: "clickToAdd" })
            .doAction(function (event, editor) {
            var _a = event.data, mediaData = _a.mediaData, mediaSource = _a.mediaSource, selection = _a.selection, telemetryData = _a.telemetryData;
            var view = telemetryData.view, tab = telemetryData.tab, assetFolderType = telemetryData.assetFolderType, originWasFiltered = telemetryData.originWasFiltered;
            var invalidReason = (0, ClickToAddImageChecks_1.isInvalidClickToAdd)({
                selection: selection,
                editor: editor,
                projectStore: projectStore,
                blockStore: blockStore,
            });
            if (invalidReason) {
                clickToAddStore.notifyForInvalidClickToAdd(invalidReason, "video");
                editorTelemetry.inlineContentCouldNotBeAdded((0, InvalidReason_1.mapInvalidReasonToTelemetry)(invalidReason));
                return true;
            }
            var videoNode = (0, Video_1.createVideoNode)({ displayUrl: mediaData.url, embedUrl: mediaData.embedUrl });
            slate_react_1.ReactEditor.focus(editor);
            slate_1.Transforms.select(editor, selection);
            (0, ClickToAddActions_1.insertNodeFromClickToAdd)(editor, videoNode);
            mediaUsageReporter.reportUsage(mediaSource, mediaData);
            editorTelemetry.inlineContentAdded({
                blockType: blockStore.blockType,
                contentType: TelemetryParameterValue_1.ContentAddedType.Video,
                origin: TelemetryParameterValue_1.ContentAddedOrigin.Click,
                isInsideSideBySide: (0, Utils_1.isInsideSideBySide)(editor, selection),
                isInsideLayout: (0, Utils_1.isInsideLayout)(editor, selection),
                source: Types_2.MetricSource.Sidebar,
                originWasFiltered: originWasFiltered,
                assetFolderType: assetFolderType,
            });
            contentSidebarTelemetry.sidebarAssetAdded({
                assetType: TelemetryEnums_1.SidebarAssetAddedType.VideoLink,
                insertMethod: TelemetryEnums_1.SidebarAssetAddedInsertMethod.Click,
                source: view,
                secondarySource: tab,
            });
            (0, lodash_1.defer)(function () {
                var insertedPath = slate_1.Range.start(editor.selection).path;
                (0, ClickToAddActions_1.scrollToInsertedNode)(editor, insertedPath, "video");
            });
            return true;
        }),
    };
};
exports.getClickToAddBehaviours = getClickToAddBehaviours;
