// Generated by CoffeeScript 1.12.7

/*
  AlignmentSection is a row in the AlignmentPicker. All it does is handle the click events and
  bubbles up events to the AlignmentPicker
 */

(function () {
  var AlignmentSection;

  module.exports = AlignmentSection = (function () {
    function AlignmentSection(createUi, title, initialValue, setValue) {
      this.ui = createUi(require("./AlignmentSection.html"), {
        title: title,
        selected: initialValue,
        click: (function (_this) {
          return function (value) {
            _this.ui.selected = value;
            return setValue(value);
          };
        })(this),
      });
      this.update = (function (_this) {
        return function (value) {
          return (_this.ui.selected = value);
        };
      })(this);
    }

    return AlignmentSection;
  })();
}.call(this));
