// Generated by CoffeeScript 1.12.7
(function () {
  var CustomFormOption, FeatureFlag;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = CustomFormOption = (function () {
    function CustomFormOption(createUi, account, formBuilder, usingFeature, settings, make) {
      var assignForms, forms;
      forms = [];
      assignForms = function () {
        return (forms = _.map(account.forms, function (form) {
          return {
            id: form.id,
            name: form.name,
          };
        }));
      };
      assignForms();
      this.ui = createUi(require("Project/Modules/Quote/Accept/CustomForm/CustomForm.html"), {
        usingCustomForms: usingFeature(FeatureFlag.AcceptCustomForms),
        settings: settings,
        forms: function () {
          return forms;
        },
        editForms: function () {
          return formBuilder.show();
        },
        selectForm: (function (_this) {
          return function (formId) {
            var form;
            form = _.find(account.forms, {
              id: formId,
            });
            if (form != null) {
              return (_this.ui.settings.customForm = form);
            }
          };
        })(this),
        $init: function (scope) {
          scope.account = account;
          return scope.$watch(
            "account.forms",
            function (formsArray) {
              if (formsArray != null) {
                return assignForms();
              }
            },
            true,
          );
        },
      });
    }

    return CustomFormOption;
  })();
}.call(this));
