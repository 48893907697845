"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityChart = void 0;
var react_1 = __importStar(require("react"));
var react_dom_1 = require("react-dom");
var recharts_1 = require("recharts");
var luxon_1 = require("luxon");
var dynamic_1 = require("@vanilla-extract/dynamic");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var tokens_1 = require("@qwilr/kaleidoscope/tokens");
var DefaultCartesianGrid_1 = require("@CommonFrontend/Charts/Common/DefaultCartesianGrid");
var DefaultYAxis_1 = require("@CommonFrontend/Charts/Common/DefaultYAxis");
var ActivityChartTooltip_1 = require("./ActivityChartTooltip");
var styles = __importStar(require("./ActivityChart.css"));
var LINE_COLOUR = tokens_1.palette.blue400;
var ActivityChart = function (_a) {
    var _b;
    var chartData = _a.chartData, timePeriodSelection = _a.timePeriodSelection, _c = _a.timeParams, periodStart = _c.periodStart, periodEnd = _c.periodEnd, timeUnit = _c.timeUnit, timeZone = _c.timeZone, noViewsYetComponent = _a.noViewsYetComponent, openSession = _a.openSession;
    (0, react_1.useEffect)(function () {
        resetTooltipStates();
    }, [chartData]);
    var maxYValue = chartData.reduce(function (max, _a) {
        var viewCount = _a.viewCount;
        return Math.max(max, viewCount);
    }, 0);
    var shiftedXDomain = getShiftedXDomain(periodStart, periodEnd, timeUnit);
    var ticks = getXTicks(periodStart, timePeriodSelection);
    var refForTooltip = (0, react_1.useRef)(null);
    var _d = __read((0, react_1.useState)(null), 2), rechartsActiveIndex = _d[0], setRechartsActiveIndex = _d[1];
    var _e = __read((0, react_1.useState)(null), 2), detailedTooltipIndex = _e[0], setDetailedTooltipIndex = _e[1];
    var _f = __read((0, react_1.useState)(null), 2), insideHoverTooltipIndex = _f[0], setInsideHoverTooltipIndex = _f[1];
    var tooltipIndex = (_b = detailedTooltipIndex !== null && detailedTooltipIndex !== void 0 ? detailedTooltipIndex : rechartsActiveIndex) !== null && _b !== void 0 ? _b : insideHoverTooltipIndex;
    function openDetailedTooltip(index) {
        setDetailedTooltipIndex(index);
        setInsideHoverTooltipIndex(null);
    }
    function closeDetailedTooltip() {
        resetTooltipStates();
    }
    function resetTooltipStates() {
        setDetailedTooltipIndex(null);
        setInsideHoverTooltipIndex(null);
        setRechartsActiveIndex(null);
    }
    return (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.wrapper, gap: "m" },
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "spaceBetween" },
            react_1.default.createElement(kaleidoscope_1.Heading, { strong: true, level: "4" }, "Views")),
        react_1.default.createElement("div", { className: styles.chartContainer, ref: refForTooltip }, noViewsYetComponent ? (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.noViewsYetContainer, justify: "center", align: "center", paddingBottom: "xl" }, noViewsYetComponent)) : (react_1.default.createElement(recharts_1.ResponsiveContainer, { width: "100%", height: "100%", minHeight: "300px" },
            react_1.default.createElement(recharts_1.LineChart, { data: chartData, style: { strokeWidth: 1 }, onMouseMove: function (e) {
                    var activeTooltipIndex = e === null || e === void 0 ? void 0 : e.activeTooltipIndex;
                    if (activeTooltipIndex != null) {
                        setRechartsActiveIndex(activeTooltipIndex);
                        if (activeTooltipIndex !== insideHoverTooltipIndex) {
                            setInsideHoverTooltipIndex(null);
                        }
                    }
                    else {
                        setRechartsActiveIndex(null);
                    }
                }, onClick: function () {
                    if (detailedTooltipIndex !== null)
                        closeDetailedTooltip();
                }, onMouseLeave: function () { return setRechartsActiveIndex(null); } },
                (0, DefaultCartesianGrid_1.GetDefaultCartesianGrid)(),
                (0, DefaultYAxis_1.GetDefaultYAxis)({
                    dataMax: Math.max(maxYValue, 2),
                    config: { removeDecimalTicks: true },
                    isMobile: false,
                }),
                react_1.default.createElement(recharts_1.XAxis, { type: "number", ticks: ticks, tick: {
                        fontFamily: tokens_1.tokens.font.ui,
                        fontWeight: tokens_1.tokens.fontWeight.medium,
                        fontSize: tokens_1.tokens.fontSize.label.m,
                        color: tokens_1.tokens.color.textSecondary,
                        letterSpacing: "1px",
                    }, tickLine: false, height: 48, dataKey: "epochTime", domain: shiftedXDomain, tickFormatter: function (v, index) { return formatChartXTick(index, ticks, { timeZone: timeZone, timeUnit: timeUnit }); }, axisLine: { stroke: tokens_1.tokens.color.controlBorder }, allowDataOverflow: true }),
                react_1.default.createElement(recharts_1.Tooltip, { content: react_1.default.createElement(react_1.default.Fragment, null), cursor: detailedTooltipIndex === null ? { stroke: tokens_1.tokens.color.controlBorder } : false }),
                react_1.default.createElement(recharts_1.Line, { stroke: LINE_COLOUR, dataKey: "viewCount", strokeWidth: 2, isAnimationActive: false, dot: function (props) {
                        return renderCustomisedDot(props, {
                            refForTooltip: refForTooltip,
                            tooltipIndex: tooltipIndex,
                            detailedTooltipIndex: detailedTooltipIndex,
                            timeUnit: timeUnit,
                            onOpenDetailedTooltip: function (index) { return openDetailedTooltip(index); },
                            onCloseDetailedTooltip: function () { return closeDetailedTooltip(); },
                            insideHoverTooltipIndex: insideHoverTooltipIndex,
                            setInsideHoverTooltipIndex: setInsideHoverTooltipIndex,
                            openSession: openSession,
                        });
                    }, activeDot: false })))))));
};
exports.ActivityChart = ActivityChart;
var renderCustomisedDot = function (props, _a) {
    var refForTooltip = _a.refForTooltip, tooltipIndex = _a.tooltipIndex, detailedTooltipIndex = _a.detailedTooltipIndex, timeUnit = _a.timeUnit, onOpenDetailedTooltip = _a.onOpenDetailedTooltip, onCloseDetailedTooltip = _a.onCloseDetailedTooltip, insideHoverTooltipIndex = _a.insideHoverTooltipIndex, setInsideHoverTooltipIndex = _a.setInsideHoverTooltipIndex, openSession = _a.openSession;
    var index = props.index, cx = props.cx, cy = props.cy, payload = props.payload;
    var shouldShowTooltip = index === tooltipIndex;
    var isDetailedTooltip = index === detailedTooltipIndex;
    var isMouseInside = index === insideHoverTooltipIndex;
    var hasViews = payload.viewCount > 0;
    function openDetailedTooltip() {
        onOpenDetailedTooltip(index);
    }
    function toggleDetailedTooltip() {
        if (isDetailedTooltip) {
            onCloseDetailedTooltip();
        }
        else {
            if (hasViews)
                openDetailedTooltip();
        }
    }
    return (react_1.default.createElement(react_1.Fragment, { key: index },
        react_1.default.createElement(recharts_1.Dot, __assign({}, props, { fill: LINE_COLOUR, stroke: LINE_COLOUR, strokeWidth: shouldShowTooltip ? 12 : 2, strokeOpacity: shouldShowTooltip ? 0.33 : 1, r: shouldShowTooltip ? 5 : 3, cursor: hasViews ? "pointer" : undefined, onClick: toggleDetailedTooltip })),
        refForTooltip.current &&
            shouldShowTooltip &&
            (0, react_dom_1.createPortal)(react_1.default.createElement(kaleidoscope_1.Popover, { className: styles.tooltipPopover({ translucent: !isDetailedTooltip && !isMouseInside }), padding: "none", autoStack: true, isOpen: true, position: kaleidoscope_1.PopoverPosition.Top, size: kaleidoscope_1.PopoverSize.Auto, offset: tokens_1.spacing.s, recalculatePositionOnResize: true, button: function (_a) {
                    var _b;
                    var ref = _a.ref;
                    return (react_1.default.createElement("div", { ref: ref, className: styles.tooltipPositioner, style: (0, dynamic_1.assignInlineVars)((_b = {},
                            _b[styles.tooltipLeft] = "".concat(cx, "px"),
                            _b[styles.tooltipTop] = "".concat(cy, "px"),
                            _b)) }));
                } }, isDetailedTooltip ? (react_1.default.createElement(ActivityChartTooltip_1.DetailedTooltip, { payload: payload, timeUnit: timeUnit, onSessionClick: openSession, onClose: onCloseDetailedTooltip })) : (react_1.default.createElement("div", { onMouseEnter: function () { return setInsideHoverTooltipIndex(index); }, onMouseLeave: function () { return setInsideHoverTooltipIndex(null); } },
                react_1.default.createElement(ActivityChartTooltip_1.SmallHoverTooltip, { payload: payload, timeUnit: timeUnit, openDetailedTooltip: openDetailedTooltip })))), refForTooltip.current)));
};
function getShiftedXDomain(periodStart, periodEnd, timeUnit) {
    var _a;
    var shiftAmount = (_a = {}, _a[timeUnit] = 0.5, _a);
    var shiftedStart = periodStart.minus(shiftAmount).toMillis();
    var shiftedEnd = periodEnd.minus(shiftAmount).toMillis();
    return [shiftedStart, shiftedEnd];
}
function formatChartXTick(tickIndex, ticks, _a) {
    var timeZone = _a.timeZone, timeUnit = _a.timeUnit;
    var epochTime = ticks[tickIndex];
    var showYear = tickIndex === 0 || luxon_1.DateTime.fromMillis(epochTime).year !== luxon_1.DateTime.fromMillis(ticks[tickIndex - 1]).year;
    var showDay = timeUnit === "day";
    return new Intl.DateTimeFormat(undefined, {
        day: showDay ? "numeric" : undefined,
        month: "short",
        year: showYear ? "numeric" : undefined,
        timeZone: timeZone,
    }).format(epochTime);
}
function getXTicks(periodStart, timePeriodSelection) {
    var _a;
    var ticks = [];
    var _b = ticksConfig[timePeriodSelection], unit = _b.unit, tickCount = _b.tickCount;
    for (var i = 0; i < tickCount; ++i) {
        ticks.push(periodStart.plus((_a = {}, _a[unit] = i, _a)).toMillis());
    }
    return ticks;
}
var ticksConfig = {
    "1w": { unit: "days", tickCount: 7 },
    "1m": { unit: "weeks", tickCount: 5 },
    "1y": { unit: "months", tickCount: 12 },
};
