// Generated by CoffeeScript 1.12.7
(function () {
  var FormFieldService,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = FormFieldService = (function () {
    function FormFieldService(fields, model, scrollToElement, overlayStack) {
      this.fields = fields;
      this.model = model;
      this.scrollToElement = scrollToElement;
      this.overlayStack = overlayStack;
      this.getModelId = bind(this.getModelId, this);
      this.findIndex = bind(this.findIndex, this);
      this.scrollToField = bind(this.scrollToField, this);
      this.swap = bind(this.swap, this);
      this.makeOptional = bind(this.makeOptional, this);
      this.makeRequired = bind(this.makeRequired, this);
      this.moveDown = bind(this.moveDown, this);
      this.moveUp = bind(this.moveUp, this);
      this["delete"] = bind(this["delete"], this);
    }

    FormFieldService.prototype["delete"] = function () {
      var id;
      id = this.getModelId();
      return _.remove(this.fields, {
        id: id,
      });
    };

    FormFieldService.prototype.moveUp = function () {
      var index;
      index = this.findIndex();
      if (index > 0) {
        return this.swap(index, index - 1);
      }
    };

    FormFieldService.prototype.moveDown = function () {
      var index;
      index = this.findIndex();
      if (index < this.fields.length - 1) {
        return this.swap(index, index + 1);
      }
    };

    FormFieldService.prototype.makeRequired = function () {
      var id;
      id = this.findIndex();
      return (this.fields[id].isRequired = true);
    };

    FormFieldService.prototype.makeOptional = function () {
      var id;
      id = this.findIndex();
      return (this.fields[id].isRequired = false);
    };

    FormFieldService.prototype.swap = function (fieldA, fieldB) {
      var temp;
      temp = this.fields[fieldA];
      this.fields[fieldA] = this.fields[fieldB];
      this.fields[fieldB] = temp;
      return this.scrollToField();
    };

    FormFieldService.prototype.scrollToField = function () {
      return this.scrollToElement($("#form-field-" + this.model.id), {
        time: 650,
        scrollingElement: this.overlayStack.getCurrentOverlayElem(),
      });
    };

    FormFieldService.prototype.findIndex = function () {
      var id;
      id = this.getModelId();
      return _.findIndex(this.fields, {
        id: id,
      });
    };

    FormFieldService.prototype.getModelId = function () {
      return this.model.id;
    };

    return FormFieldService;
  })();
}.call(this));
