// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (items, make, createUi, MenuDropdown, selector) {
    var dropdown, moreActionsDropdownItem;
    moreActionsDropdownItem = require("./DropdownItem.html");
    dropdown = make(MenuDropdown, {
      toggleButtonElem: selector,
      options: {},
      items: _.map(items, function (item) {
        return createUi(moreActionsDropdownItem, {
          item: item,
        });
      }),
    });
    return dropdown;
  };
}.call(this));
