// Generated by CoffeeScript 1.12.7
(function () {
  var FeatureFlag, UsersController, actionsDropdown;

  actionsDropdown = require("../MoreActionsDropdown/Dropdown");

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  /*
    @TODO: (som, 2018-03-26): refactor this. `confirmDeleteUser`, `doDeleteUser`,
    and `createActionDropdown` are basically member functions of a 'user ui' class
    All the actions dropdown code is particularly confusing right now
   */

  module.exports = UsersController = (function () {
    function UsersController(
      createUi,
      invoke,
      account,
      user,
      userList,
      $http,
      apiBaseUrl,
      getFullName,
      userIconFactory,
      make,
      refreshUi,
      dropdown,
      simpleDropdownFactory,
      rolesService,
      showDeleteModal,
      userSelected,
      usingFeature,
    ) {
      var addUserAsAdmin,
        confirmDeleteUser,
        createActionDropdown,
        createRolesDropdown,
        createUserUi,
        doDeleteUser,
        init,
        selectUserModal,
        userUis;
      selectUserModal = make(require("./SelectUserModal"));
      userUis = [];
      init = (function (_this) {
        return function () {
          var i, index, len, results, userInfo;
          results = [];
          for (index = i = 0, len = userList.length; i < len; index = ++i) {
            userInfo = userList[index];
            results.push(userUis.push(createUserUi(userInfo, index)));
          }
          return results;
        };
      })(this);
      this.getUserUis = function () {
        return userUis;
      };
      addUserAsAdmin = function (userInfo) {
        return rolesService.setRole(userInfo, "admin").then(function () {
          return refreshUi();
        });
      };
      confirmDeleteUser = (function (_this) {
        return function (userToDelete) {
          return showDeleteModal({
            title: "Remove User?",
            explainer:
              "They'll no longer be able to log in, and you can transfer their pages to another user on your account.",
            deleteButton: "Remove",
          }).then(function (confirmed) {
            var explainer;
            if (confirmed) {
              if (userToDelete.$projectCount === 0) {
                doDeleteUser(userToDelete, user._id);
                return;
              }
              explainer =
                userToDelete.name() +
                " owns " +
                userToDelete.$projectCount +
                " pages on your account. Who will be the new owner?";
              return selectUserModal.selectUser({
                title: "Transfer pages to another user",
                excludeUsers: [userToDelete],
                explainer: explainer,
                buttonLabel: "Move projects",
                selectedUserAction: function (selectedUser) {
                  return doDeleteUser(userToDelete, selectedUser._id);
                },
              });
            }
          });
        };
      })(this);
      doDeleteUser = (function (_this) {
        return function (userToDelete, moveProjectsTo) {
          return $http["delete"](apiBaseUrl + "/users/users-and-seats", {
            data: {
              moveProjectsTo: moveProjectsTo,
              usersToDelete: [userToDelete._id],
            },
            headers: {
              "Content-Type": "application/json",
            },
          })
            .then(function (response) {
              var deletedUserIndex, updatedAdditionalSeats;
              updatedAdditionalSeats = response.data.message.additionalSeats;
              if (updatedAdditionalSeats != null) {
                account.additionalSeats = updatedAdditionalSeats;
              }
              deletedUserIndex = _.findIndex(userList, {
                _id: userToDelete._id,
              });
              userList.splice(deletedUserIndex, 1);
              return refreshUi();
            })
            ["catch"](function (response) {
              throw new Error(response.data.error.message);
            });
        };
      })(this);

      /*
        Create the dropdown menu for a single user ui object
       */
      createActionDropdown = (function (_this) {
        return function (ui) {
          var items, moreActionsDropdown;
          items = [];
          if (user._id !== ui._id) {
            items.push({
              name: "Remove",
              onClick: function ($event) {
                return confirmDeleteUser(ui).then(function () {
                  ui.actionsDropdown.hide();
                  return $event.stopPropagation();
                });
              },
            });
          }
          return (moreActionsDropdown = invoke(actionsDropdown, null, {
            items: items,
            selector: ".user-actions",
          }));
        };
      })(this);

      /*
        Create a single ui object for a user in the team
       */
      createUserUi = (function (_this) {
        return function (userInfo) {
          var ui;
          ui = createUi(require("SettingsAngular/Team/Users/User.html"), {
            _id: userInfo._id,
            name: function () {
              if (userInfo.firstName != null) {
                return getFullName(userInfo);
              } else if (userInfo.email != null) {
                return userInfo.email;
              }
            },
            email: userInfo.email,
            $projectCount: userInfo.$projectCount,
            $selected: false,
            toggleSelected: function () {
              return userSelected(ui._id, ui.$selected);
            },
            isUsingTeams: function () {
              return usingFeature(FeatureFlag.Teams);
            },
            canEditTeams: function () {
              return rolesService.isAdmin(user);
            },
            userInfo: userInfo,
            icon: userIconFactory.create(userInfo, {
              textColor: "#242C39",
              backgroundColor: "#B6B9BD",
            }),
            rolesDropdown: createRolesDropdown(userInfo),
            allowRoleChange: rolesService.isAdmin(user) && user._id !== userInfo._id,
            isAdmin: rolesService.isAdmin(userInfo),
            showRolesDropdown: true,
            showActionsDropdown: function () {
              var ref, ref1, ref2, ref3;
              if (!rolesService.isAdmin(user)) {
                return;
              }
              if ((ref = ui.actionsDropdown) != null ? ((ref1 = ref.ui) != null ? ref1.visible : void 0) : void 0) {
                ui.actionsDropdown.hide();
                return (_this.selected = null);
              } else {
                if ((ref2 = _this.selected) != null) {
                  if ((ref3 = ref2.actionsDropdown) != null) {
                    ref3.hide();
                  }
                }
                ui.actionsDropdown = createActionDropdown(ui);
                if (ui.actionsDropdown.ui.items.length) {
                  ui.actionsDropdown.show();
                }
                return (_this.selected = ui);
              }
            },
          });
          return ui;
        };
      })(this);
      createRolesDropdown = function (_user) {
        return simpleDropdownFactory.create(
          [
            {
              name: "Admin",
              id: "admin",
            },
            {
              name: "Creator",
              id: "creator",
            },
          ],
          {
            id: rolesService.getRole(_user),
          },
          function (item) {
            return rolesService.setRole(_user, item.id);
          },
        );
      };
      init();
    }

    return UsersController;
  })();
}.call(this));
