"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var PasteActions = __importStar(require("./PasteActions"));
var Utils_1 = require("Project/QwilrEditor/Utils");
var PasteActions_1 = require("./PasteActions");
exports.default = (function (di) {
    var editorTelemetry = di.get("editorTelemetry");
    var blockType = di.get("blockStore").blockType;
    var projectStore = di.get("projectStore");
    return {
        pasteLeadingImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("paste leading image")
            .isEvent(EditorTypes_1.EventType.Insert)
            .withData({ leadingType: "image" })
            .doAction(function (event, editor) {
            PasteActions.insertLeadingWidget(editor, event.fragment);
            editorTelemetry.imagePastedInto(blockType, (0, Utils_1.isInsideSideBySide)(editor));
            return true;
        }),
        pasteURL: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("paste URL link")
            .isEvent(EditorTypes_1.EventType.Insert)
            .withData({ leadingType: "paragraph" })
            .selectionIsCollapsed()
            .inBlockType(["paragraph", "heading1", "heading2", "listItem", "blockQuote"])
            .doAction(function (event, editor) {
            return (0, PasteActions_1.maybeInsertLink)(editor, event.fragment, editorTelemetry);
        }),
        pasteURLOverText: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("paste URL link over text")
            .isEvent(EditorTypes_1.EventType.Insert)
            .withData({ leadingType: "paragraph" })
            .selectionIsExpanded()
            .inBlockType(["paragraph", "heading1", "heading2", "listItem", "blockQuote"])
            .doAction(function (event, editor) {
            return PasteActions.maybeInsertLinkOverText(editor, event.fragment, editorTelemetry);
        }),
        pasteLeadingTable: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("paste leading table")
            .isEvent(EditorTypes_1.EventType.Insert)
            .withData({ leadingType: "table" })
            .doAction(function (event, editor) {
            PasteActions.insertLeadingWidget(editor, event.fragment);
            return true;
        }),
        pasteLeadingSideBySide: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("paste leading side by side")
            .isEvent(EditorTypes_1.EventType.Insert)
            .withData({ leadingType: "sideBySide" })
            .doAction(function (event, editor) {
            PasteActions.insertLeadingWidget(editor, event.fragment);
            return true;
        }),
        pasteLeadingAccordion: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("paste leading accordion")
            .isEvent(EditorTypes_1.EventType.Insert)
            .withData({ leadingType: "accordion" })
            .doAction(function (event, editor) {
            PasteActions.insertLeadingWidget(editor, event.fragment);
            return true;
        }),
        pasteLeadingListItemIntoPlaceholder: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Paste leading list into placeholder")
            .isEvent(EditorTypes_1.EventType.Insert)
            .withData({ leadingType: "listItem" })
            .inEmptyBlock()
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            PasteActions.insertAndOverride(editor, event.fragment);
            return true;
        }),
        pasteIntoH1Placeholder: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Paste formatted into h1 placeholder")
            .isEvent(EditorTypes_1.EventType.Insert)
            .inBlockType("heading1")
            .inEmptyBlock()
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            PasteActions.insertAndAssume(editor, event.fragment);
            return true;
        }),
        pasteIntoH2Placeholder: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Paste formatted into h2 placeholder")
            .isEvent(EditorTypes_1.EventType.Insert)
            .inBlockType("heading2")
            .inEmptyBlock()
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            PasteActions.insertIntoH2Placeholder(editor, event.fragment);
            return true;
        }),
        pasteIntoUnformattedPlaceholder: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Paste into unformatted placeholder")
            .isEvent(EditorTypes_1.EventType.Insert)
            .inBlockType("paragraph")
            .inEmptyBlock()
            .selectionIsCollapsed()
            .doAction(function (event, editor) {
            PasteActions.insertAndOverride(editor, event.fragment);
            return true;
        }),
        pasteLeadingListItem: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Paste leading list")
            .isEvent(EditorTypes_1.EventType.Insert)
            .withData({ leadingType: "listItem" })
            .doAction(function (event, editor) {
            PasteActions.splitAndInsert(editor, event.fragment);
            return true;
        }),
        pasteVariableTelemetry: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Paste variable telemetry")
            .isEvent(EditorTypes_1.EventType.Insert)
            .withData({ isVariable: true })
            .doAction(function (event, editor) {
            editorTelemetry.variableInserted(event.data.variable, {
                tokenInsertedOrigin: "copy paste",
                tokenInsertedBlockType: blockType,
                tokenInsertedInlineContentType: (0, Utils_1.isInsideImage)(editor) ? "image" : undefined,
                templateId: projectStore._id,
            });
            return false;
        }),
        pasteCatchAll: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Paste catch all")
            .isEvent(EditorTypes_1.EventType.Insert)
            .doAction(function (event, editor) {
            PasteActions.insertAndAssume(editor, event.fragment);
            return true;
        }),
    };
});
