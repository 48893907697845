// Generated by CoffeeScript 1.12.7
(function () {
  var currentTooltip;

  require("Common/AtomicUI/ToggleButton/ToggleButton.ng.html");

  currentTooltip = null;

  module.exports = angular.module("qwilr.qtogglebutton", []).directive("qToggleButton", function () {
    return {
      restrict: "EA",
      scope: {
        model: "=",
        label: "@",
        onChange: "=",
        disabled: "=?",
        getDisabledMessage: "=?",
        onDisabledClick: "=?",
      },
      templateUrl: "Common/AtomicUI/ToggleButton/ToggleButton.ng.html",
      controller: function ($scope, $tooltip, $element, $rootScope, $timeout) {
        var Tooltip, make;
        if ($scope.disabled == null) {
          $scope.disabled = false;
        }
        $scope.changeState = _.throttle(
          function () {
            if ($scope.disabled) {
              if (typeof $scope.onDisabledClick === "function") {
                $scope.onDisabledClick();
              }
              return;
            }
            $scope.model = !$scope.model;
            if ($scope.onChange != null) {
              return $timeout($scope.onChange);
            }
          },
          200,
          {
            leading: true,
            trailing: false,
          },
        );
        make = $rootScope.di.make;
        Tooltip = $rootScope.di.get("Tooltip");
        if ($scope.getDisabledMessage != null && $scope.disabled) {
          return $scope.getDisabledMessage().then(function (message) {
            var tooltip, tooltipOptions;
            if (message != null) {
              tooltipOptions = {
                targetSelector: $element[0].lastElementChild.lastElementChild.lastElementChild,
                options: {
                  container: $element[0].lastElementChild,
                  showOnHover: true,
                  hideAfter: 1000,
                  customTheme: "helper-theme",
                  position: "bottom",
                },
              };
              if (message.$templateUrl != null) {
                tooltipOptions.options.contents = message;
              } else {
                tooltipOptions.options.stringContent = message;
              }
              return (tooltip = make(Tooltip, tooltipOptions));
            }
          });
        }
      },
    };
  });
}.call(this));
