// Generated by CoffeeScript 1.12.7
(function () {
  var AvatarController, DeleteController, FeatureFlag, InvitatationRequestService, PendingInviteRequests;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  InvitatationRequestService = require("SettingsAngular/InvitationRequestService/InvitationRequestService.ts")[
    "default"
  ];

  AvatarController = require("./AvatarMount")["default"];

  DeleteController = require("./DeleteButtonMount")["default"];

  /*
    @TODO: (som, 2018-03-26): refactor this. `resendInvite`, `deletePendingInvite`, and
    `createActionDropdown` are basically member functions of a 'pending invite' class
    All the actions dropdown code is particularly confusing right now
   */

  module.exports = PendingInviteRequests = (function () {
    function PendingInviteRequests(
      createUi,
      pendingInviteRequestees,
      user,
      rolesService,
      simpleDropdownFactory,
      make,
      AsyncButton,
      telemetry,
    ) {
      var avatarControls, createPendingUi, deleteControls, init, invitatationRequestService, pendingUis;
      pendingUis = [];
      invitatationRequestService = make(InvitatationRequestService);
      avatarControls = make(AvatarController);
      deleteControls = make(DeleteController);
      init = function () {
        var i, invitee, len, results;
        results = [];
        for (i = 0, len = pendingInviteRequestees.length; i < len; i++) {
          invitee = pendingInviteRequestees[i];
          results.push(pendingUis.push(createPendingUi(invitee)));
        }
        return results;
      };
      this.getPendingUis = function () {
        return pendingUis;
      };

      /*
        Create a single ui element for a pending invite
       */
      createPendingUi = (function (_this) {
        return function (invitation) {
          var approveButton, ui;
          approveButton = new AsyncButton(
            function () {
              return invitatationRequestService.inviteRequestWithModal(invitation);
            },
            "Approve",
            {
              theme: "primary",
              size: "small",
              initialState: rolesService.isAdmin(user) ? "idle" : "disabled",
            },
          );
          avatarControls.setAvatarWithName(invitation.name);
          deleteControls.deleteButtonWithCallback(function () {
            return invitatationRequestService.deleteRequestWithModal(invitation);
          });
          return (ui = createUi(require("./PendingInviteRequests.html"), {
            email: invitation.email,
            inviteId: invitation._id,
            showRolesDropdown: true,
            avatar: avatarControls.ui,
            deleteButton: deleteControls.ui,
            rolesDropdown: simpleDropdownFactory.create([
              {
                name: "Creator",
                id: "creator",
              },
              {
                name: "Admin",
                id: "admin",
              },
            ]),
            approveButton: approveButton.ui,
          }));
        };
      })(this);
      init();
    }

    return PendingInviteRequests;
  })();
}.call(this));
