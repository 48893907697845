"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AcceptPreviewEventEmitter = exports.AcceptPreviewEvent = void 0;
var AcceptPreviewEvent;
(function (AcceptPreviewEvent) {
    AcceptPreviewEvent["OpenPreview"] = "open preview";
    AcceptPreviewEvent["ClosePreview"] = "close preview";
})(AcceptPreviewEvent || (exports.AcceptPreviewEvent = AcceptPreviewEvent = {}));
var AcceptPreviewEventEmitter = (function () {
    function AcceptPreviewEventEmitter() {
        this.listeners = {};
    }
    AcceptPreviewEventEmitter.prototype.addListener = function (eventName, listener) {
        if (this.listeners[eventName] == null) {
            this.listeners[eventName] = [];
        }
        this.listeners[eventName].push(listener);
    };
    AcceptPreviewEventEmitter.prototype.trigger = function (eventName) {
        if (this.listeners[eventName] == null) {
            return;
        }
        this.listeners[eventName].forEach(function (listener) {
            listener(eventName);
        });
    };
    AcceptPreviewEventEmitter.prototype.removeListeners = function (eventName) {
        if (!this.listeners[eventName]) {
            return 0;
        }
        var numRemoved = this.listeners[eventName].length;
        this.listeners[eventName] = [];
        return numRemoved;
    };
    return AcceptPreviewEventEmitter;
}());
exports.AcceptPreviewEventEmitter = AcceptPreviewEventEmitter;
