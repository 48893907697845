"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PayNowDisabler = (function () {
    function PayNowDisabler(projectCurrencies, project) {
        this.projectCurrencies = projectCurrencies;
        this.project = project;
    }
    PayNowDisabler.prototype.recalculatePayNowState = function () {
        var acceptBlock = this.getAcceptBlock();
        if (!!acceptBlock && !this.isPayNowPossible()) {
            var payNow = acceptBlock.settings.payNow;
            if (payNow.enabled) {
                payNow.enabled = false;
                return true;
            }
        }
        return false;
    };
    PayNowDisabler.prototype.isPayNowPossible = function () {
        var acceptBlock = this.getAcceptBlock();
        if (!acceptBlock) {
            return false;
        }
        var payNow = acceptBlock.settings.payNow;
        return !!payNow && !this.projectCurrencies.hasMultipleCurrencies();
    };
    PayNowDisabler.prototype.getPayNowImpossibleReason = function () {
        return "Pay now is currently not supported if a page has multiple quote blocks with different currencies. \
      Change all quote blocks to use the same currency to enable Pay now again for this Qwilr Page";
    };
    PayNowDisabler.prototype.getAcceptBlock = function () {
        return this.project.blocks.find(function (block) {
            return block.type === "Accept";
        });
    };
    return PayNowDisabler;
}());
exports.default = PayNowDisabler;
