"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var lodash_1 = require("lodash");
var mobx_1 = require("mobx");
var SavedBlockViewStore_1 = require("SavedBlock/SavedBlockViewStore");
var DEFAULT_INITIAL_PAGE_SIZE = 10;
var PAGE_SIZE = 5;
var SAVED_BLOCK_CARD_HEIGHT = 238;
var SavedBlockSideBarViewStore = (function (_super) {
    __extends(SavedBlockSideBarViewStore, _super);
    function SavedBlockSideBarViewStore(savedBlockDataStore, savedBlockService) {
        var _this = _super.call(this, savedBlockDataStore, savedBlockService, { enabled: false }, [BlockTypes_1.BlockType.Agreement]) || this;
        _this.currentPage = 0;
        _this.resetPagination = function () {
            _this.currentPage = 0;
        };
        _this.incrementPage = function () {
            setTimeout(function () {
                (0, mobx_1.runInAction)(function () {
                    _this.currentPage += 1;
                });
            }, 500);
        };
        _this.updateInitialPageSize = function () {
            var optimalHeight = Math.ceil(window.innerHeight / SAVED_BLOCK_CARD_HEIGHT);
            _this.initialPageSize = (0, lodash_1.max)([optimalHeight, DEFAULT_INITIAL_PAGE_SIZE]);
        };
        _this.throttledUpdateInitialPageSize = (0, lodash_1.throttle)(_this.updateInitialPageSize, 500);
        _this.updateInitialPageSize();
        return _this;
    }
    SavedBlockSideBarViewStore.prototype.bind = function () {
        var _this = this;
        this.disposeResetPaginationReaction = (0, mobx_1.reaction)(function () { return _this.savedBlocks; }, function () {
            _this.resetPagination();
        });
        window.addEventListener("resize", this.throttledUpdateInitialPageSize);
    };
    SavedBlockSideBarViewStore.prototype.unbind = function () {
        this.disposeResetPaginationReaction();
        window.removeEventListener("resize", this.throttledUpdateInitialPageSize);
    };
    Object.defineProperty(SavedBlockSideBarViewStore.prototype, "savedBlocksPaginated", {
        get: function () {
            return (0, lodash_1.take)(this.savedBlocks, this.totalPagedImageCount);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SavedBlockSideBarViewStore.prototype, "hasMoreToPaginate", {
        get: function () {
            return this.totalPagedImageCount < this.savedBlocks.length;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SavedBlockSideBarViewStore.prototype, "totalPagedImageCount", {
        get: function () {
            return this.currentPage * PAGE_SIZE + this.initialPageSize;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SavedBlockSideBarViewStore.prototype, "savedBlockCount", {
        get: function () {
            return this.savedBlockDataStore.savedBlocks.length;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], SavedBlockSideBarViewStore.prototype, "currentPage", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], SavedBlockSideBarViewStore.prototype, "initialPageSize", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], SavedBlockSideBarViewStore.prototype, "savedBlocksPaginated", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], SavedBlockSideBarViewStore.prototype, "hasMoreToPaginate", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], SavedBlockSideBarViewStore.prototype, "resetPagination", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], SavedBlockSideBarViewStore.prototype, "incrementPage", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], SavedBlockSideBarViewStore.prototype, "totalPagedImageCount", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], SavedBlockSideBarViewStore.prototype, "updateInitialPageSize", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], SavedBlockSideBarViewStore.prototype, "savedBlockCount", null);
    return SavedBlockSideBarViewStore;
}(SavedBlockViewStore_1.SavedBlockViewStore));
exports.default = SavedBlockSideBarViewStore;
