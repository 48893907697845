(function () {
  var TagManager,
    TelemetryEventName,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  TelemetryEventName = require("@CommonFrontendBackend/Telemetry").TelemetryEventName;

  module.exports = TagManager = (function () {
    function TagManager(createUi, $q, modalAngular, projectTagger, telemetry) {
      this.modalAngular = modalAngular;
      this.projectTagger = projectTagger;
      this.telemetry = telemetry;
      this.updateSelectedTags = bind(this.updateSelectedTags, this);
      this.show = bind(this.show, this);
      this.addTagInMemory = bind(this.addTagInMemory, this);
      this.removeTagInMemory = bind(this.removeTagInMemory, this);
      this.q = $q;
      this.ui = createUi(require("./TagManager.html"), {
        selectedTagsArray: [],
        selectedTags: (function (_this) {
          return function () {
            return _this.ui.selectedTagsArray;
          };
        })(this),
        filter: "",
        modified: false,
        allTags: this.projectTagger.allTags,
        close: this.modalAngular.close,
        tags: (function (_this) {
          return function () {
            var nonSelectedTags;
            nonSelectedTags = _.filter(_this.projectTagger.allTags, function (tag) {
              return (
                _.find(_this.ui.selectedTags(), function (selected) {
                  return selected === tag;
                }) == null
              );
            });
            if (_this.ui.filter.length > 0) {
              return _.filter(nonSelectedTags, function (tag) {
                return tag.toLowerCase().indexOf(_this.ui.filter.toLowerCase()) >= 0;
              });
            } else {
              return nonSelectedTags;
            }
          };
        })(this),
        removeTag: (function (_this) {
          return function (tag) {
            _this.removeTagInMemory(tag);
            return _this.projectTagger
              .removeTag(_this.ui.projects, tag)
              .then(function () {
                return (_this.ui.modified = true);
              })
              ["catch"](function () {
                return _this.addTagInMemory(tag);
              });
          };
        })(this),
        addTag: (function (_this) {
          return function (tag) {
            var newTag;
            if (tag.isCreateTrigger != null) {
              newTag = _this.ui.filter;
            } else {
              newTag = tag;
            }
            _this.addTagInMemory(tag);
            _this.ui.filter = "";
            return _this.projectTagger
              .tag(_this.ui.projects, newTag)
              .then(function () {
                return (_this.ui.modified = true);
              })
              ["catch"](function () {
                return _this.removeTagInMemory(tag);
              });
          };
        })(this),
        addTagOnEnter: (function (_this) {
          return function (event, tag) {
            var ENTER_KEY, key;
            key = event.keyCode || event.which;
            ENTER_KEY = 13;
            if (key === ENTER_KEY && tag.length > 0) {
              return _this.ui.addTag(tag);
            }
          };
        })(this),
        showCreateTag: (function (_this) {
          return function () {
            return (
              _this.ui.filter.length &&
              _.find(_this.projectTagger.allTags, function (tag) {
                return _this.ui.filter === tag;
              }) == null
            );
          };
        })(this),
      });
    }

    TagManager.prototype.removeTagInMemory = function (tag) {
      var i, len, project, ref;
      ref = this.ui.projects;
      for (i = 0, len = ref.length; i < len; i++) {
        project = ref[i];
        _.pull(project.tags, tag);
      }
      return this.updateSelectedTags();
    };

    TagManager.prototype.addTagInMemory = function (tag) {
      var i, len, project, ref;
      ref = this.ui.projects;
      for (i = 0, len = ref.length; i < len; i++) {
        project = ref[i];
        if (!_.includes(project.tags, tag)) {
          project.tags.push(tag);
        }
      }
      return this.updateSelectedTags();
    };

    TagManager.prototype.show = function (projects, projectList) {
      var onHide, showing;
      if (_.isArray(projects)) {
        this.ui.projects = projects;
      } else {
        this.ui.projects = [projects];
      }
      this.ui.projectList = projectList;
      this.updateSelectedTags();
      this.ui.modified = false;
      showing = this.q.defer();
      onHide = (function (_this) {
        return function () {
          if (_this.ui.modified && _this.ui.projectList != null) {
            _this.ui.projectList.refresh();
          }
          return showing.resolve();
        };
      })(this);
      this.modalAngular.show(this.ui, {
        onHide: onHide,
        customClass: "tag-modal",
      });
      this.telemetry.trackOnly(
        TelemetryEventName.ProjectTagsViewed,
        {},
        {
          blockIntercom: true,
        },
      );
      return showing.promise;
    };

    TagManager.prototype.updateSelectedTags = function () {
      var tags, tagsPerProject;
      tagsPerProject = _.map(this.ui.projects, function (project) {
        return project.tags;
      });
      tags = _.intersection.apply(_, tagsPerProject);
      this.ui.selectedTagsArray = _.filter(
        tags,
        (function (_this) {
          return function (tag) {
            return !_this.projectTagger.reservedTag(tag);
          };
        })(this),
      );
      return {
        selectedTags: (function (_this) {
          return function () {
            return _this.ui.selectedTagsArray;
          };
        })(this),
      };
    };

    return TagManager;
  })();
}.call(this));
