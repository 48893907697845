"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deleteSavedFolderId = exports.getSavedFolderId = exports.setSavedFolderId = exports.getBlockFolderTitle = exports.isASystemFolder = exports.getTitle = void 0;
var DashboardTypes_1 = require("@CommonFrontendBackend/Dashboard/DashboardTypes");
var STORAGE_KEY = "lastSelectedFolder";
function getTitle(collection) {
    if (collection === DashboardTypes_1.ProjectCollection.All) {
        return "Pages";
    }
    else if (collection === DashboardTypes_1.ProjectCollection.Archived) {
        return "Archived";
    }
    else {
        return "Deleted";
    }
}
exports.getTitle = getTitle;
function getBlockFolderTitle(collection) {
    if (collection === DashboardTypes_1.SavedBlockCollection.All) {
        return "Saved Blocks";
    }
    else {
        return "Deleted";
    }
}
exports.getBlockFolderTitle = getBlockFolderTitle;
function isASystemFolder(folderId) {
    return (Object.values(DashboardTypes_1.ProjectCollection).includes(folderId) ||
        Object.values(DashboardTypes_1.SavedBlockCollection).includes(folderId));
}
exports.isASystemFolder = isASystemFolder;
function setSavedFolderId(folderId) {
    sessionStorage.setItem(STORAGE_KEY, folderId);
}
exports.setSavedFolderId = setSavedFolderId;
function getSavedFolderId() {
    return sessionStorage.getItem(STORAGE_KEY);
}
exports.getSavedFolderId = getSavedFolderId;
function deleteSavedFolderId() {
    sessionStorage.removeItem(STORAGE_KEY);
}
exports.deleteSavedFolderId = deleteSavedFolderId;
