"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var KeyboardShortcuts = (function () {
    function KeyboardShortcuts(pdfPreviewOverlay, notifyUser, contentSidebarViewStore) {
        var _this = this;
        this.pdfPreviewOverlay = pdfPreviewOverlay;
        this.notifyUser = notifyUser;
        this.contentSidebarViewStore = contentSidebarViewStore;
        this.handleProjectKeydownEvent = function (e) {
            if (e.key === "p" && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                _this.pdfPreviewOverlay.show();
            }
            else if (e.key === "s" && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                _this.notifyUser.showNotification("Qwilr auto-saves your pages", kaleidoscope_1.NotificationType.Success);
            }
            else if (e.key === "\\" && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                _this.contentSidebarViewStore.toggle();
            }
        };
    }
    KeyboardShortcuts.prototype.bind = function () {
        document.addEventListener("keydown", this.handleProjectKeydownEvent);
    };
    KeyboardShortcuts.prototype.unbind = function () {
        document.removeEventListener("keydown", this.handleProjectKeydownEvent);
    };
    return KeyboardShortcuts;
}());
exports.default = KeyboardShortcuts;
