import "Application/Onboarding/CensusCollector/MakeQwilrYourOwn/LogoUploader.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Onboarding/CensusCollector/MakeQwilrYourOwn/LogoUploader.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWQY/TOhC+768YIa3ULHibdGlZgvQE3LjB4wInNEmcxq1j59lOm4L4709xnMbtpt0tIHFq47G/+WbmG4/frukuV1hSDerlbHcXwY8rgPDa/gDIClNmdjGEb64Afl4BROFDY9QZf17dWoyZtW9ZZorY7m/NFWYZE8sYNqgmhOj2rFiSRn/7FiX81WoVReugddOj3FmUkgnikGaLedUM9pfWnkouVQzbghnauilRLZkgiTRGlsfOPF88GJDmI0i9bWFtjjzhNDcxpMjTySFyOSCXATyHWdUEj9I5jH3w+crPn3U2u1tUDTyHyRPcwg3MAuu8oGxZmBiixX2bOK8G4SUxyA1VOZfbGAqWZVQMRO99opbjgdu+8lIzw6SIQVGOhm289L62CIcqG/ZjoiWvu8J+J0xktHFiU52LMwG0u4ysHt2TyIboArM2PrfVfhHK6QZbHrPhCHVHVEZVDEIKL5T7uGgzBaOBRcM+/L2QF2GX5b8YG56MLfnF2Gww804uv00awCgUOpeqjLu/HA39OiHz8DrYmx0nx9V5ymplPfg3RR04I0XdZjlHbWRtPnO5ZWLYpoIXHvU/gXcq/cnJ9KfW4l1SYdUAOacSz0lmDzuVXXyaPqh8b8lPWlZjc+L49kDBSnTXRzegwtuFvjiF66f4ypiuOO5iyDm1TbaqtWH5jqRSGCravFBhqLK8OFsKwgwttb/cS1FLzjKIqsYxtdOFVIqVqHbv7aaBqMeTd7OoVrodRpVkHXBvLkcabB/U9el220d22NfCwj2ahrF++jKxGpkQ+yC4cWFa1DAI7Kx62rA6OngDJAqCB326p3DcWXxA3V7eWQL+gZsxaXjvjm7p4EQspJnEHLUhacF4FnRvlW7Y+y30lAnbI8sxHp2eiMKM1bq/ELvFf+0a0bXKMaUDdBH8gb75dSUDJJiul0rWIouhVnzybPpOa2r09EOJS6qnUiQSVfsSmZa4pp+2jKuvslZ6aqtcoaLCkAHlVm+WzwJQtKJohnxVo+I9yp9MVjQ1JGdtRaQwyMSZ1+iJB+J/1tMlt4OTwuNDeu/CXfFnu18dKuT4udV993VrK9bVzsvqiKTc2PWbDTmHaB6WGihqSmRtDstK3FvZF4ShjfnIMaWF5L4kEi9Ibfmfx2GpHKRYtYf/B7Uw3CejDAAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var logoIndex = 'var(--r42y30)';
export var root = 'r42y32';
export var logoTooltip = 'r42y33';
export var tooltipHeading = 'r42y34';
export var tooltipText = 'r42y35';
export var label = 'r42y36';
export var container = 'r42y37';
export var content = 'r42y38';
export var deleteButton = 'r42y39';
export var smallUploadButton = 'r42y3a';
export var sliderButton = _7a468({defaultClassName:'r42y3b',variantClassNames:{position:{left:'r42y3c',right:'r42y3d'},status:{exiting:'r42y3e',exited:'r42y3f',entering:'r42y3g',entered:'r42y3h',unmounted:'r42y3i'}},defaultVariants:{},compoundVariants:[]});
export var emptyCardWrapper = 'r42y3j';
export var emptyCard = 'r42y3k';
export var uploadButton = 'r42y3l';
export var uploadInput = 'r42y3m';
export var logoSlider = 'r42y3n';
export var logoCard = 'r42y3o';
export var logo = 'r42y3p';
export var sliderCount = 'r42y3q';
export var sliderCountElement = _7a468({defaultClassName:'r42y3r',variantClassNames:{position:{current:'r42y3s','default':'r42y3t'}},defaultVariants:{},compoundVariants:[]});