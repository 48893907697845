// Generated by CoffeeScript 1.12.7
(function () {
  var VariableMenu;

  module.exports = VariableMenu = (function () {
    function VariableMenu(createUi, variable, deleteVariable) {
      this.ui = createUi(require("./VariableMenu.html"), {
        delete: deleteVariable,
        enabledUnder: variable.ifUnder.isActive,
        enabledOver: variable.ifOver.isActive,
        toggledUnder: (function (_this) {
          return function () {
            variable.ifUnder.isActive = _this.ui.enabledUnder;
            return variable.save();
          };
        })(this),
        toggledOver: (function (_this) {
          return function () {
            variable.ifOver.isActive = _this.ui.enabledOver;
            return variable.save();
          };
        })(this),
      });
    }

    return VariableMenu;
  })();
}.call(this));
