// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (disabledMessage, createUi, initSubscription) {
    return createUi(require("./DisabledBlockTooltip.html"), {
      disabledMessage: disabledMessage,
      upgrade: function () {
        return initSubscription.init();
      },
    });
  };
}.call(this));
