// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (make, Tooltip, invoke, $timeout) {
    /*
      Show the disabled block tooltip on the BlockBrowser
      @param blockType [Object] the object from the BlockDisplaySettings
      @param block [Object] the block we want to show the message on
     */
    var buildTooltip, getTooltip, showDisabledBlockTooltip, tooltips;
    showDisabledBlockTooltip = function (blockType, block) {
      var blockModel, cssClass, disabledMessage, enabled, tooltip;
      (blockModel = blockType.blockModel),
        (enabled = blockType.enabled),
        (disabledMessage = blockType.disabledMessage),
        (cssClass = blockType.cssClass);
      tooltip = getTooltip(block.$id, cssClass);
      if (!tooltip) {
        return (tooltip = buildTooltip(block.$id, cssClass, disabledMessage));
      }
    };
    tooltips = [];
    getTooltip = function (blockId, type) {
      return _.find(tooltips, {
        blockId: blockId,
        type: type,
      });
    };
    buildTooltip = function (blockId, type, disabledMessage) {
      var element, tooltip;
      element = make(Tooltip, {
        targetSelector: "#" + blockId + " .project-block .block-type-" + type,
        options: {
          container: "#" + blockId + " .project-block",
          position: "bottom",
          contents: invoke(require("./DisabledBlockTooltip"), null, {
            disabledMessage: disabledMessage,
          }),
          customClass: "helper-theme",
          spacing: -5,
          closeOnClickAway: true,
          showOnHover: true,
          hideAfter: 1,
        },
      });
      tooltip = {
        blockId: blockId,
        type: type,
        element: element,
      };
      tooltips.push(tooltip);
      tooltip.element.show();
      return tooltip;
    };
    return showDisabledBlockTooltip;
  };
}.call(this));
