// Generated by CoffeeScript 1.12.7
(function () {
  var AdRollIntegration;

  module.exports = AdRollIntegration = (function () {
    function AdRollIntegration(createUi, genericIntegration) {
      genericIntegration.build.call(this, "adRoll", "AdRoll settings", require("./AdRoll.html"));
    }

    return AdRollIntegration;
  })();
}.call(this));
