"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DomWalker = void 0;
var Context = (function () {
    function Context(initialContext) {
        this.context = [initialContext !== null && initialContext !== void 0 ? initialContext : {}];
    }
    Context.prototype.push = function () {
        this.context.push({});
    };
    Context.prototype.pop = function () {
        this.context.pop();
    };
    Context.prototype.set = function (key, value) {
        this.context[this.context.length - 1][key] = value;
    };
    Context.prototype.get = function (key) {
        for (var i = this.context.length - 1; i >= 0; i--) {
            var context = this.context[i];
            if (context[key] !== undefined) {
                return context[key];
            }
        }
        return undefined;
    };
    return Context;
}());
var DomWalker = (function () {
    function DomWalker(currentNode, options) {
        if (options === void 0) { options = {}; }
        this.currentNode = currentNode;
        this.options = options;
        this.context = new Context(options.initialContext);
    }
    DomWalker.prototype.walk = function () {
        this.innerWalk(this.currentNode);
        return this.context;
    };
    DomWalker.prototype.innerWalk = function (node) {
        var _this = this;
        var children = node.childNodes;
        children.forEach(function (child) {
            var _a, _b, _c;
            _this.context.push();
            var result = (_b = (_a = _this.options).onEnter) === null || _b === void 0 ? void 0 : _b.call(_a, child, _this.context);
            if (!(result === null || result === void 0 ? void 0 : result.skip)) {
                _this.innerWalk(child);
            }
            (_c = result === null || result === void 0 ? void 0 : result.onExit) === null || _c === void 0 ? void 0 : _c.call(result);
            _this.context.pop();
        });
    };
    return DomWalker;
}());
exports.DomWalker = DomWalker;
