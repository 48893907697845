// Generated by CoffeeScript 1.12.7
(function () {
  var WidgetSelectorController;

  module.exports = WidgetSelectorController = (function () {
    function WidgetSelectorController(createUi, onSelect, onDelete, insertables, model) {
      if (model == null || _.keys(model).length === 0) {
        model = {
          type: "selector",
        };
      }
      this.isBlank = (function (_this) {
        return function () {
          return true;
        };
      })(this);
      this.ui = createUi(require("./WidgetSelector.html"), {
        model: model,
        select: onSelect,
        destroy: onDelete,
        insertables: insertables,
      });
      this.remove = (function (_this) {
        return function () {};
      })(this);
    }

    return WidgetSelectorController;
  })();
}.call(this));
