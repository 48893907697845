"use strict";
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.schema = void 0;
var EditorTypes_1 = require("../EditorTypes");
exports.schema = {
    editor: {
        validChildren: [],
        validFirstChild: [],
    },
    blocks: (_a = {},
        _a[EditorTypes_1.BlockType.Caption] = {},
        _a),
    inlines: (_b = {},
        _b[EditorTypes_1.InlineType.LineBreak] = {
            isVoid: true,
        },
        _b[EditorTypes_1.InlineType.Link] = {},
        _b[EditorTypes_1.InlineType.TokenWidget] = {
            isVoid: true,
        },
        _b[EditorTypes_1.InlineType.Variable] = {
            isVoid: true,
            marks: false,
        },
        _b),
    name: "standard",
};
