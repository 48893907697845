(function () {
  var Bell,
    FeatureFlag,
    Splash,
    Table,
    WidgetFactory,
    WidgetSelection,
    WidgetWrapper,
    ref,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  WidgetWrapper = require("./Wrapper/Wrapper");

  WidgetSelection = require("./WidgetSelection");

  (ref = require("Common/Utils/ImageImports")), (Splash = ref.Splash), (Bell = ref.Bell), (Table = ref.Table);

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = WidgetFactory = (function () {
    function WidgetFactory(make, usingFeature, $log, imageLocation, widgetTracker, utils, scope, $compile) {
      var data, ref1, type;
      this.usingFeature = usingFeature;
      this.widgetTracker = widgetTracker;
      this.utils = utils;
      this.scope = scope;
      this.createSelector = bind(this.createSelector, this);
      this.generateId = bind(this.generateId, this);
      this.createMultiple = bind(this.createMultiple, this);
      this.create = bind(this.create, this);
      this.make = make;
      this.log = $log;
      this.compile = $compile;
      this.widgets = {
        image: {
          ctrl: require("./Types/Image/Image"),
          name: "Image",
          icon: Splash,
          model: {
            height: 0,
            type: "image",
            url: "",
            maxWidth: 100,
            displayWidth: 100,
          },
        },
        audio: {
          ctrl: require("./Types/Audio/Audio"),
          name: "Audio",
          icon: Bell,
          model: {
            height: 0,
            type: "audio",
            url: "",
          },
        },
        table: {
          ctrl: require("./Types/Table/Table"),
          name: "Table",
          icon: Table,
          model: {
            height: 0,
            type: "table",
          },
        },
        selector: {
          ctrl: require("./Types/WidgetSelector/WidgetSelector"),
          name: "Selector",
          model: {
            height: 0,
            type: "selector",
          },
        },
      };
      this.activeTypes = ["image", "table"];
      this.activeWidgets = [];
      ref1 = this.widgets;
      for (type in ref1) {
        data = ref1[type];
        if (this.activeTypes.indexOf(type) >= 0) {
          this.activeWidgets.push(this.widgets[type]);
        }
      }
    }

    WidgetFactory.prototype.create = function (model) {
      var compileSeed, selection, type, widget, widgetScope, wrapped;
      if (model == null || _.keys(model).length === 0) {
        if (this.activeTypes.length > 1) {
          type = "selector";
        } else {
          type = this.activeTypes[0];
        }
      } else if (_.isString(model)) {
        type = model;
        model = null;
      } else {
        type = model.type;
      }
      if (model == null) {
        model = _.cloneDeep(this.widgets[type].model);
      }
      selection = this.make(WidgetSelection);
      widget =
        type !== "selector"
          ? this.make(this.widgets[type].ctrl, {
              model: model,
              selection: selection,
            })
          : this.createSelector(model, selection);
      wrapped = this.make(WidgetWrapper, {
        widget: widget,
        selection: selection,
      });
      if (wrapped.model.id == null) {
        wrapped.model.id = this.generateId(type);
      }
      widgetScope = this.scope.$new(true);
      widgetScope.childUi = wrapped.ui;
      compileSeed =
        "<insertable class='widget widget-" +
        type +
        "' widget-id='" +
        wrapped.model.id +
        "' ui=childUi contenteditable=false></insertable>";
      return {
        widget: wrapped,
        scope: widgetScope,
        rendered: this.compile(compileSeed)(widgetScope)[0],
      };
    };

    WidgetFactory.prototype.createMultiple = function (models) {
      return _.map(models, this.create);
    };

    WidgetFactory.prototype.generateId = function (widgetType) {
      if (widgetType === "selector") {
        return "$" + this.utils.uniqueId();
      } else {
        return this.utils.uniqueId();
      }
    };

    WidgetFactory.prototype.createSelector = function (model, selection) {
      var selector;
      if (model == null) {
        model = {};
      }
      return (selector = this.make(this.widgets.selector.ctrl, {
        model: model,
        insertables: this.activeWidgets,
        onSelect: (function (_this) {
          return function (newType) {
            var newWidget;
            newWidget = _this.create(newType);
            return _this.widgetTracker.replace(selector.ui.model.id, newWidget);
          };
        })(this),
        selection: selection,
      }));
    };

    return WidgetFactory;
  })();
}.call(this));
