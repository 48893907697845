// Generated by CoffeeScript 1.12.7

/*
  This module creates a resize box that fills the container in which it is placed.

  When a particular callback is fired, it enters a resizing state, displaying UI handles that
  respond to click and drag, and firing another callback on completion.
 */

(function () {
  var Resizer;

  module.exports = Resizer = (function () {
    function Resizer($document, $timeout, make, createUi, onEndResize, getElement, noop) {
      var CORNERS;
      this.MINIMUM_RESIZE_WIDTH_IN_PIXELS = 70;
      CORNERS = {
        TOP_LEFT: "TOP_LEFT",
        TOP_RIGHT: "TOP_RIGHT",
        BOTTOM_LEFT: "BOTTOM_LEFT",
        BOTTOM_RIGHT: "BOTTOM_RIGHT",
      };
      this.ui = createUi(require("Common/Resizer/Resizer.html"), {
        $init: (function (_this) {
          return function (scope) {
            return (_this.ngScope = scope);
          };
        })(this),
      });
      this.isDraggingHandle = false;
      this.isActive = false;
      this.ui.getIsActive = (function (_this) {
        return function () {
          return _this.isActive;
        };
      })(this);
      this.enterResizeMode = (function (_this) {
        return function () {
          var container, leftPosition;
          _this.isActive = true;
          container = getElement()[0];
          _this.ui.startHeight = container.clientHeight;
          _this.ui.startWidth = container.clientWidth;
          _this.ui.aspectRatio = _this.ui.startWidth / _this.ui.startHeight;
          leftPosition = $(container).position().left;
          _this.ui.width = _this.ui.startWidth;
          _this.ui.height = _this.ui.startHeight;
          _this.ui.box = {
            top: 0,
            bottom: 0,
            left: leftPosition,
            right: 0,
          };
          $document.on("mouseup", _this.ui.stopResize);
          $document.on("touchend", _this.ui.stopResize);
          $document.on("mousemove", _this.ui.onResize);
          $document.on("touchmove", _this.ui.onResize);
          return (_this.ui.CORNERS = CORNERS);
        };
      })(this);
      this.ui.startDrag = (function (_this) {
        return function (corner) {
          _this.ui.resizingCorner = corner;
          return (_this.isDraggingHandle = true);
        };
      })(this);
      this.ui.stopResize = (function (_this) {
        return function () {
          if (!_this.isDraggingHandle) {
            return;
          }
          _this.isActive = false;
          $document.off("mouseup", _this.ui.stopResize);
          $document.off("touchend", _this.ui.stopResize);
          $document.off("mousemove", _this.ui.onResize);
          $document.off("touchmove", _this.ui.onResize);
          onEndResize(_this.ui.width / _this.ui.startWidth);
          _this.isDraggingHandle = false;
          return $timeout(noop);
        };
      })(this);
      this.ui.onResize = (function (_this) {
        return function (mouseOrTouchEvent) {
          var bottom, change, elem, jqOffset, left, leftPosition, right, top;
          if (!_this.isDraggingHandle) {
            return;
          }
          elem = getElement();
          jqOffset = elem.offset();
          leftPosition = elem.position().left;
          if (mouseOrTouchEvent.type === "touchmove") {
            mouseOrTouchEvent = mouseOrTouchEvent.originalEvent.touches[0];
          }
          top = mouseOrTouchEvent.pageY - jqOffset.top;
          left = mouseOrTouchEvent.pageX - jqOffset.left;
          right = _this.ui.startWidth - left;
          bottom = _this.ui.startHeight - top;
          if (_this.ui.resizingCorner === CORNERS.TOP_LEFT || _this.ui.resizingCorner === CORNERS.BOTTOM_LEFT) {
            change = 2 * left;
          }
          if (_this.ui.resizingCorner === CORNERS.TOP_RIGHT || _this.ui.resizingCorner === CORNERS.BOTTOM_RIGHT) {
            change = 2 * right;
          }
          change = Math.min(change, _this.ui.startWidth - _this.MINIMUM_RESIZE_WIDTH_IN_PIXELS);
          _this.ui.box.left = change / 2 + leftPosition;
          _this.ui.box.bottom = change / _this.ui.aspectRatio;
          _this.ui.height = _this.ui.startHeight - _this.ui.box.top - _this.ui.box.bottom;
          _this.ui.width = _this.ui.startWidth - change;
          $timeout(noop);
          return false;
        };
      })(this);
    }

    return Resizer;
  })();
}.call(this));
