// Generated by CoffeeScript 1.12.7

/*
  Handles the editor-level ranged key event
 */

(function () {
  var RangedKeyHandler,
    assert,
    bowser,
    ensureOpen,
    getCommonParentOverRange,
    isLeafBlock,
    isList,
    isSameNode,
    listSafeJoinLeft,
    ref,
    removeSelection,
    setCaretInside;

  (ref = require("../Helpers")),
    (removeSelection = ref.removeSelection),
    (setCaretInside = ref.setCaretInside),
    (assert = ref.assert),
    (ensureOpen = ref.ensureOpen),
    (listSafeJoinLeft = ref.listSafeJoinLeft),
    (isList = ref.isList),
    (isLeafBlock = ref.isLeafBlock),
    (isSameNode = ref.isSameNode),
    (getCommonParentOverRange = ref.getCommonParentOverRange);

  bowser = require("bowser");

  module.exports = RangedKeyHandler = (function () {
    function RangedKeyHandler(editor, undo) {
      var Point,
        elementTagToAdd,
        finaliseRangedKey,
        getSlidePointsAtEnds,
        handleDeleteKeypress,
        handleEnterKeypress,
        isContainedByEditor,
        keycodes,
        positionCaret,
        shouldJoin,
        slideLeft,
        slideRight;
      (keycodes = qed.keycodes), (Point = qed.Point);
      this.onRangedKey = function (e) {
        /*
         Checks and set up
         */
        var addElemTag,
          anchor,
          caret,
          commonParent,
          focus,
          newCaret,
          newElem,
          range,
          shouldJoinOnDeleteOrInput,
          text,
          topBoundaries;
        switch (e.keyCategory) {
          case keycodes.types.NAVIGATION:
          case keycodes.types.NOEFFECT:
            return true;
        }
        if (e.metaKey || e.ctrlKey) {
          return false;
        }
        range = editor.selection().getRange().order();
        anchor = range.anchor;
        focus = range.focus;
        if (e.keyCategory !== keycodes.types.ENTER) {
          commonParent = getCommonParentOverRange(editor, range);
          topBoundaries = getSlidePointsAtEnds(commonParent, range);
          shouldJoinOnDeleteOrInput = shouldJoin(topBoundaries.anchor, topBoundaries.focus);
          addElemTag = elementTagToAdd(topBoundaries.anchor, topBoundaries.focus);
        }
        switch (e.keyCategory) {
          case keycodes.types.DELETE:
          case keycodes.types.ENTER:
            e.preventDefault();
        }
        newCaret = removeSelection(editor, range.anchor, range.focus);
        assert(newCaret.type !== "after", "Got unexpected point type 'after'");
        if (addElemTag != null && newCaret.type !== "start") {
          newElem = document.createElement(addElemTag);
          ensureOpen(newElem);
          newCaret.insert(newElem);
          newCaret = Point.start(newElem);
        } else {
          newCaret = positionCaret(newCaret, shouldJoinOnDeleteOrInput);
          ensureOpen(newCaret.node);
        }
        if (e.keyCategory === keycodes.types.ENTER) {
          newCaret = handleEnterKeypress(e, newCaret);
        } else if (e.keyCategory === keycodes.types.DELETE) {
          newCaret = handleDeleteKeypress(e, newCaret);
        }
        finaliseRangedKey(editor, newCaret);
        if (e.keyCategory === keycodes.types.INPUT) {
          caret = editor.selection().getRange().anchor;
          if (caret.type === "text" && caret.offset > 0) {
            text = caret.node.textContent;
            if (text[caret.offset] === " " && text[caret.offset - 1] === " ") {
              text = text.split("");
              text.splice(caret.offset, 1, "\xA0");
              caret.node.textContent = text.join("");
              finaliseRangedKey(editor, Point.text(caret.node, caret.offset));
            }
          }
        }
        return true;
      };
      finaliseRangedKey = function (editor, caret) {
        if (bowser.name === "Safari" || bowser.name === "Firefox") {
          return setCaretInside(editor, caret);
        } else {
          setCaretInside(editor, caret);
          return caret.node.parentElement.normalize();
        }
      };
      handleEnterKeypress = function (e, point) {
        while (!(point.containingElement() === editor.currentElem() || isList(point.containingElement()))) {
          point.splitRight(true);
          ensureOpen(point.node);
          ensureOpen(point.node.previousElementSibling);
        }
        return point;
      };
      handleDeleteKeypress = function (e, point) {
        var nextList, prevList;
        if (isLeafBlock(point.node)) {
          ensureOpen(point.node);
        }
        if (
          isList(point.node) &&
          isList(point.node.previousElementSibling) &&
          point.node.tagName === point.node.previousElementSibling.tagName
        ) {
          nextList = point.node;
          prevList = point.node.previousElementSibling;
          point = Point.start(nextList).rightNormalized();
          if (nextList.tagName === prevList.tagName) {
            Point.before(nextList).joinLeft();
          }
        }
        return point;
      };
      positionCaret = function (newCaret, join) {
        if (join == null) {
          join = true;
        }
        if (newCaret.containingElement() === editor.currentElem() || isList(newCaret.containingElement())) {
          if (newCaret.type === "before" && join) {
            newCaret = listSafeJoinLeft(newCaret, editor);
          } else if (newCaret.type === "end") {
            newCaret = Point.end(newCaret.node.lastElementChild);
          }
        }
        if (newCaret.containingElement() === editor.currentElem()) {
          if (newCaret.type === "before") {
            newCaret.moveToStart(newCaret.node);
          } else if (newCaret.type === "after") {
            newCaret.moveToEnd(newCaret.node);
          }
        }
        return newCaret;
      };
      shouldJoin = function (anchor, focus) {
        return !(isContainedByEditor(anchor) || isContainedByEditor(focus));
      };
      elementTagToAdd = function (anchor, focus) {
        if (isContainedByEditor(anchor) && isContainedByEditor(focus)) {
          return anchor.rightNormalized().node.tagName;
        } else {
          return null;
        }
      };
      isContainedByEditor = function (point) {
        return isSameNode(point.containingElement(), editor.currentElem());
      };
      getSlidePointsAtEnds = function (commonParent, range) {
        var anchor, focus;
        anchor = slideLeft(range.anchor, commonParent);
        focus = slideRight(range.focus, commonParent);
        return {
          anchor: anchor,
          focus: focus,
        };
      };
      slideLeft = function (point, parent) {
        var temp;
        temp = point.leftNormalized();
        while (temp.type === "start" && temp.containingElement() !== parent) {
          temp = Point.before(temp.node).leftNormalized();
        }
        return temp;
      };
      slideRight = function (point, parent) {
        var temp;
        temp = point.rightNormalized();
        while (temp.type === "end" && temp.containingElement() !== parent) {
          temp = Point.after(temp.node).rightNormalized();
        }
        return temp;
      };
    }

    return RangedKeyHandler;
  })();
}.call(this));
