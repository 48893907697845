// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = angular.module("qwilr.accountDock", []).directive("accountdock", function ($rootScope) {
    var inject;
    inject = function (element) {
      var srcUrl, tag;
      srcUrl = "https://sandbox.accountdock.com/app.min.js";
      if ($rootScope.di.get("environment") === "production") {
        srcUrl = "https://static.accountdock.com/app.min.js";
      }
      tag = angular.element(document.createElement("script"));
      tag.attr("charset", "utf-8");
      tag.attr("src", srcUrl);
      return element.append(tag);
    };
    return {
      link: function (scope, element) {
        return inject(element);
      },
    };
  });
}.call(this));
