"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var CurrenciesUiDelegate = (function () {
    function CurrenciesUiDelegate(currencySetter, payNowDisabler, showAcknowledgeModal) {
        this.currencySetter = currencySetter;
        this.payNowDisabler = payNowDisabler;
        this.showAcknowledgeModal = showAcknowledgeModal;
    }
    CurrenciesUiDelegate.prototype.currenciesChanged = function () {
        this.acknowledgePayNowChange(this.currencySetter.currenciesChanged());
    };
    CurrenciesUiDelegate.prototype.setCurrency = function (block, currencyCode) {
        this.acknowledgePayNowChange(this.currencySetter.setCurrency(block, currencyCode));
    };
    CurrenciesUiDelegate.prototype.acknowledgePayNowChange = function (payNowChanged) {
        if (payNowChanged) {
            this.showAcknowledgeModal({
                title: "Pay Now disabled",
                explainer: this.payNowDisabler.getPayNowImpossibleReason(),
                button: "Got it",
            });
        }
    };
    return CurrenciesUiDelegate;
}());
exports.default = CurrenciesUiDelegate;
