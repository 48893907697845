"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapInvalidReasonToTelemetry = exports.InvalidReason = void 0;
var TelemetryParameterValue_1 = require("Telemetry/TelemetryParameterValue");
var InvalidReason;
(function (InvalidReason) {
    InvalidReason["InvalidEditorChild"] = "invalid:editorchild";
    InvalidReason["InvalidContainerChild"] = "invalid:containerchild";
    InvalidReason["BlockLocked"] = "invalid:blocklocked";
    InvalidReason["LocationNoLongerExists"] = "invalid:locationnolongerexists";
    InvalidReason["EditorNoLongerExists"] = "invalid:editornolongerexists";
})(InvalidReason || (exports.InvalidReason = InvalidReason = {}));
var mapInvalidReasonToTelemetry = function (invalidReason) {
    var _a;
    return ((_a = {},
        _a[InvalidReason.InvalidEditorChild] = TelemetryParameterValue_1.InlineContentAddedErrorName.InvalidEditor,
        _a[InvalidReason.InvalidContainerChild] = TelemetryParameterValue_1.InlineContentAddedErrorName.InvalidContainer,
        _a[InvalidReason.BlockLocked] = TelemetryParameterValue_1.InlineContentAddedErrorName.LockedBlock,
        _a[InvalidReason.LocationNoLongerExists] = TelemetryParameterValue_1.InlineContentAddedErrorName.MissingLocation,
        _a[InvalidReason.EditorNoLongerExists] = TelemetryParameterValue_1.InlineContentAddedErrorName.MissingEditor,
        _a)[invalidReason]);
};
exports.mapInvalidReasonToTelemetry = mapInvalidReasonToTelemetry;
