// Generated by CoffeeScript 1.12.7
(function () {
  var WidgetTracker,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    },
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  module.exports = WidgetTracker = (function () {
    function WidgetTracker(model, utils, scope, $timeout, $compile, editor, block, triggerContentEvent) {
      var me;
      this.model = model;
      this.editor = editor;
      this.block = block;
      this.triggerContentEvent = triggerContentEvent;
      this.grabPlaceholder = bind(this.grabPlaceholder, this);
      this.isTracked = bind(this.isTracked, this);
      this.positionWidgets = bind(this.positionWidgets, this);
      this.updatePlaceholderSizes = bind(this.updatePlaceholderSizes, this);
      this._repositionAll = bind(this._repositionAll, this);
      this.hasSelected = bind(this.hasSelected, this);
      this.setSelected = bind(this.setSelected, this);
      this.removeTracking = bind(this.removeTracking, this);
      this.remove = bind(this.remove, this);
      this.repositionAll = bind(this.repositionAll, this);
      this.getDomWidgetIds = bind(this.getDomWidgetIds, this);
      this.cleanRemovedWidgets = bind(this.cleanRemovedWidgets, this);
      this.getUntrackedWidgets = bind(this.getUntrackedWidgets, this);
      this.startTracking = bind(this.startTracking, this);
      this.insertNew = bind(this.insertNew, this);
      this.replace = bind(this.replace, this);
      this.get = bind(this.get, this);
      this.initialise = bind(this.initialise, this);
      me = this;
      this.activeWidgets = {};
      this.container = null;
      this.timeout = $timeout;
    }

    WidgetTracker.prototype.initialise = function () {
      var containerElem;
      containerElem = document.createElement("div");
      containerElem.className = "widget-container";
      this.editor.currentElem().parentNode.insertBefore(containerElem, this.editor.currentElem());
      this.container = containerElem;
      this.editor.addListener({
        onContent: (function (_this) {
          return function () {
            if (!_this.editor.attached()) {
              return false;
            }
            _this._repositionAll();
            return false;
          };
        })(this),
      });
      return $(window).on(
        "resize",
        _.throttle(this.repositionAll, 250, {
          trailing: true,
        }),
      );
    };

    WidgetTracker.prototype.get = function (id) {
      return this.activeWidgets[id] || null;
    };

    WidgetTracker.prototype.replace = function (oldWidgetId, newWidget) {
      var oldPlaceholder, oldTrackedWidget;
      oldTrackedWidget = this.activeWidgets[oldWidgetId];
      oldPlaceholder = this.grabPlaceholder(oldWidgetId)[0];
      this.insertNew(newWidget, oldPlaceholder);
      return this.removeTracking(oldWidgetId);
    };

    WidgetTracker.prototype.insertNew = function (newWidget, elemToReplace) {
      var placeholder;
      placeholder = $(
        "<insertable widget-id='" + newWidget.widget.model.id + "' contenteditable=true></insertable>",
      )[0];
      elemToReplace.parentElement.insertBefore(placeholder, elemToReplace);
      elemToReplace.parentElement.removeChild(elemToReplace);
      this.model[newWidget.widget.model.id] = newWidget.widget.model;
      this.startTracking(newWidget);
      return placeholder;
    };

    WidgetTracker.prototype.startTracking = function (activeWidgets) {
      var active, i, len, placeholder;
      if (!_.isArray(activeWidgets)) {
        activeWidgets = [activeWidgets];
      }
      for (i = 0, len = activeWidgets.length; i < len; i++) {
        active = activeWidgets[i];
        placeholder = $("#" + this.block.$id + " content-area insertable[widget-id='" + active.widget.model.id + "']");
        if (!(placeholder != null && placeholder.length > 0 && this.activeWidgets[active.widget.model.id] == null)) {
          continue;
        }
        this.container.appendChild(active.rendered);
        active.placeholder = placeholder[0];
        this.activeWidgets[active.widget.model.id] = active;
      }
      return this.repositionAll();
    };

    WidgetTracker.prototype.getUntrackedWidgets = function () {
      var i, id, inDomWidgetIds, len, untracked;
      inDomWidgetIds = this.getDomWidgetIds();
      untracked = [];
      for (i = 0, len = inDomWidgetIds.length; i < len; i++) {
        id = inDomWidgetIds[i];
        if (this.activeWidgets[id] == null && this.model[id] != null) {
          untracked.push(this.model[id]);
        }
      }
      return untracked;
    };

    WidgetTracker.prototype.cleanRemovedWidgets = function () {
      var i, id, inDomWidgetIds, len, results;
      inDomWidgetIds = this.getDomWidgetIds();
      results = [];
      for (i = 0, len = inDomWidgetIds.length; i < len; i++) {
        id = inDomWidgetIds[i];
        if (this.model[id] == null) {
          results.push(this.remove(id, false));
        } else {
          results.push(void 0);
        }
      }
      return results;
    };

    WidgetTracker.prototype.getDomWidgetIds = function () {
      var inDomWidgets;
      inDomWidgets = $("#" + this.block.$id + " content-area insertable");
      return _.map(inDomWidgets, function (widgetElem) {
        return widgetElem.getAttribute("widget-id");
      });
    };

    WidgetTracker.prototype.repositionAll = function () {
      return this._repositionAll(this.triggerContentEvent);
    };

    WidgetTracker.prototype.remove = function (id, keepOpen) {
      var p, placeholder, widget;
      if (keepOpen == null) {
        keepOpen = true;
      }
      widget = this.activeWidgets[id];
      placeholder = this.grabPlaceholder(id);
      if (placeholder[0] != null) {
        if (keepOpen) {
          p = document.createElement("p");
          placeholder[0].parentElement.insertBefore(p, placeholder[0]);
          qed.util.ensureOpen(p);
          this.editor.selection().setCaret(qed.Point.start(p));
        }
        placeholder.remove();
      }
      delete this.model[id];
      return this.removeTracking(id);
    };

    WidgetTracker.prototype.removeTracking = function (id) {
      var active;
      active = this.activeWidgets[id];
      if (active == null) {
        return;
      }
      active.scope.$destroy();
      $(active.rendered).remove();
      return delete this.activeWidgets[id];
    };

    WidgetTracker.prototype.setSelected = function (widgetIds) {
      var id, ref, results, widget;
      ref = this.activeWidgets;
      results = [];
      for (id in ref) {
        widget = ref[id];
        if (indexOf.call(widgetIds, id) >= 0) {
          results.push(this.activeWidgets[id].widget.select());
        } else {
          results.push(this.activeWidgets[id].widget.deselect());
        }
      }
      return results;
    };

    WidgetTracker.prototype.hasSelected = function () {
      return (
        _.find(this.activeWidgets, function (active) {
          return active.widget.isSelected;
        }).length > 0
      );
    };

    WidgetTracker.getWidgetIdAtPoint = function (point) {
      var node;
      node = point.node;
      while (node != null && node.tagName.toLowerCase() !== "insertable") {
        node = node.parentElement;
      }
      if (node != null) {
        return node.getAttribute("widget-id");
      } else {
        return null;
      }
    };

    WidgetTracker.prototype._repositionAll = function (postRepositionCallback) {
      return this.timeout(
        (function (_this) {
          return function () {
            _this.updatePlaceholderSizes();
            return _this.timeout(function () {
              _this.positionWidgets();
              return _this.timeout(function () {
                if (postRepositionCallback != null) {
                  return postRepositionCallback();
                }
              });
            });
          };
        })(this),
      );
    };

    WidgetTracker.prototype.updatePlaceholderSizes = function () {
      var active, height, id, placeholder, ref, results;
      ref = this.activeWidgets;
      results = [];
      for (id in ref) {
        active = ref[id];
        placeholder = this.grabPlaceholder(active.widget.model.id)[0];
        if (placeholder == null) {
          this.remove(id);
          continue;
        }
        height = active.rendered.getBoundingClientRect().height;
        active.widget.model.height = height;
        placeholder.style.height = height + "px";
        results.push((placeholder.contenteditable = true));
      }
      return results;
    };

    WidgetTracker.prototype.positionWidgets = function () {
      var active, containerTop, id, placeholder, placeholderTop, ref, results;
      ref = this.activeWidgets;
      results = [];
      for (id in ref) {
        active = ref[id];
        placeholder = this.grabPlaceholder(active.widget.model.id)[0];
        if (placeholder == null) {
          continue;
        }
        placeholderTop = placeholder.getBoundingClientRect().top;
        containerTop = this.container.getBoundingClientRect().top;
        results.push((active.rendered.style.top = placeholderTop - containerTop - 2 + "px"));
      }
      return results;
    };

    WidgetTracker.prototype.isTracked = function (id) {
      return this.activeWidgets[id] != null;
    };

    WidgetTracker.prototype.grabPlaceholder = function (widgetId) {
      return $("#" + this.block.$id + " content-area insertable[widget-id='" + widgetId + "']");
    };

    return WidgetTracker;
  })();
}.call(this));
