"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StatusHistoryFilter = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var Types_1 = require("Reports/Types");
var STATUS_HISTORY_FILTER_ITEMS = [
    {
        label: "Page count",
        value: Types_1.SelectedStatusHistoryFilter.Count,
    },
    {
        label: "Page value",
        value: Types_1.SelectedStatusHistoryFilter.Value,
    },
];
var actionTypeTelemetryMap = Object.freeze({
    Count: "status_history_select_count",
    Value: "status_history_select_value",
});
function StatusHistoryFilter(_a) {
    var onSelect = _a.onSelect, firePipelineTelemetry = _a.firePipelineTelemetry;
    var _b = __read((0, react_1.useState)(STATUS_HISTORY_FILTER_ITEMS[0].value), 2), selected = _b[0], setSelected = _b[1];
    var onChangeHandler = function (selected) {
        setSelected(selected);
        onSelect([selected]);
        firePipelineTelemetry(actionTypeTelemetryMap[selected]);
    };
    (0, react_1.useEffect)(function () {
        onSelect([STATUS_HISTORY_FILTER_ITEMS[0].value]);
    }, []);
    return (react_1.default.createElement("div", { className: "history-chart-view__status-history-filter" },
        react_1.default.createElement(kaleidoscope_1.SegmentedControl, { className: "history-chart-view__chart-filter__segment-control", label: "Status History Filter", onClickHandler: onChangeHandler, options: STATUS_HISTORY_FILTER_ITEMS, selectedValue: selected, labelHidden: true })));
}
exports.StatusHistoryFilter = StatusHistoryFilter;
