"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticsSettingSectionId = void 0;
var AnalyticsSettingSectionId;
(function (AnalyticsSettingSectionId) {
    AnalyticsSettingSectionId["Timezone"] = "timezone-setting";
    AnalyticsSettingSectionId["Currency"] = "currency-setting";
    AnalyticsSettingSectionId["Collection"] = "collection-setting";
    AnalyticsSettingSectionId["Cleanup"] = "cleanup-setting";
})(AnalyticsSettingSectionId || (exports.AnalyticsSettingSectionId = AnalyticsSettingSectionId = {}));
