"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToolbarFocusTracker = void 0;
var react_1 = require("react");
function useToolbarFocusTracker() {
    var _a = __read((0, react_1.useState)(function () { return new Set(); }), 2), focusedMenus = _a[0], setFocusedMenus = _a[1];
    function handleOnFocus(focusMenu) {
        setFocusedMenus(function (prevState) {
            var newState = new Set(prevState);
            newState.add(focusMenu);
            return newState;
        });
    }
    function handleOnBlur(event, focusMenu) {
        var container = event.currentTarget;
        setTimeout(function () {
            if (container.contains(document.activeElement))
                return;
            setFocusedMenus(function (prevState) {
                var newState = new Set(prevState);
                newState.delete(focusMenu);
                return newState;
            });
        }, 0);
    }
    return {
        onFocusChange: function (event, focusMenu, isFocused) {
            if (isFocused) {
                handleOnFocus(focusMenu);
            }
            else {
                handleOnBlur(event, focusMenu);
            }
        },
        isToolbarFocused: focusedMenus.size > 0,
    };
}
exports.useToolbarFocusTracker = useToolbarFocusTracker;
