// Generated by CoffeeScript 1.12.7
(function () {
  var GenericIntegration;

  module.exports = GenericIntegration = (function () {
    function GenericIntegration(createUi, $http, $timeout, account, apiBaseUrl, make, ActionButton, $q) {
      /*
        Build a generic integration page.
      
        This function is expected to be called using the integration's context as it adds public functions to the integration.
        E.g. genericIntegration.build.call @, "slaask", "Slaask widget key", require("./Slaask.html")
      
        @param internalName [String] the name of the integration as used in the account object. E.g. "slaask"
        @param keyMessage [String] the messaging of the item we are updating, including the integration name itself. E.g. "Slaask widget key"
        @param uiElement [Object] the required html ui. E.g. require("./Slaask.html")
        @param sanitiseSettings [Function] the function that takes in the ui element and mutates it
        to update ui.settings to be the appropriate values
       */
      this.build = function (internalName, keyMessage, uiElement, sanitiseSettings) {
        var apiName, onError, submitButton;
        apiName = internalName.toLowerCase();
        submitButton = make(ActionButton, {
          config: {
            standard: "Update " + keyMessage,
            waiting: "Updating " + keyMessage + "...",
            success: "Updated " + keyMessage,
            error: "Error setting " + keyMessage,
          },
          onClick: (function (_this) {
            return function () {
              _this.ui.parseSnippet();
              _this.ui.error = "";
              return sanitiseSettings(_this.ui)
                .then(function () {
                  return _this.ui.settings;
                })
                ["catch"](onError)
                .then(function (settings) {
                  return _this.update(settings);
                });
            };
          })(this),
        });
        this.ui = createUi(uiElement, {
          submitButton: submitButton.ui,
          error: "",
          keyMessage: keyMessage,
          settings: account.integrations[internalName].settings,
          parseSnippet: (function (_this) {
            return function () {
              var e, find, matches, snippet;
              if (apiName === "drift") {
                snippet = _this.ui.settings.appId;
                find = /drift.load\('([a-zA-Z0-9]+)'\);/g;
                matches = find.exec(snippet);
                try {
                  return (_this.ui.settings.appId = matches[1]);
                } catch (error) {
                  e = error;
                }
              }
            };
          })(this),
        });
        this.isEnabled = (function (_this) {
          return function () {
            var ref, ref1;
            return (ref = account.integrations) != null
              ? (ref1 = ref[internalName]) != null
                ? ref1.enabled
                : void 0
              : void 0;
          };
        })(this);
        this.enable = (function (_this) {
          return function () {
            return $http.post(apiBaseUrl + "/integration/" + apiName + "/enable").then(function () {
              var base, base1;
              if ((base = account.integrations)[internalName] == null) {
                base[internalName] = {};
              }
              account.integrations[internalName].enabled = true;
              return (base1 = account.integrations[internalName]).settings != null
                ? base1.settings
                : (base1.settings = {});
            });
          };
        })(this);
        this.disable = (function (_this) {
          return function () {
            return $http.post(apiBaseUrl + "/integration/" + apiName + "/disable").then(function () {
              var base;
              if ((base = account.integrations)[internalName] == null) {
                base[internalName] = {};
              }
              account.integrations[internalName].enabled = false;
              return (account.integrations[internalName].settings = {});
            });
          };
        })(this);
        this.update = (function (_this) {
          return function (settings) {
            return $http
              .post(apiBaseUrl + "/integration/" + apiName + "/update", settings)
              .then(function (response) {
                return (account.integrations[internalName].settings = settings);
              })
              ["catch"](function (response) {
                var ref, ref1;
                if (
                  ((ref = response.data) != null ? ((ref1 = ref.error) != null ? ref1.message : void 0) : void 0) !=
                  null
                ) {
                  return onError(response.data.error.message);
                } else {
                  return onError(
                    "An error has occurred while trying to update your " + keyMessage + ", please try again later.",
                  );
                }
              });
          };
        })(this);
        onError = (function (_this) {
          return function (errorMessage) {
            _this.ui.error = errorMessage;
            $timeout(function () {
              return (_this.ui.error = "");
            }, 5000);
            return $q.reject(new Error("Error setting " + keyMessage));
          };
        })(this);
        return sanitiseSettings != null
          ? sanitiseSettings
          : (sanitiseSettings = function (ui) {
              var key, ref, value;
              ref = ui.settings;
              for (key in ref) {
                value = ref[key];
                if (value == null || !value.length) {
                  return $q.reject("Whoops, looks like you left this field blank. Please try again.");
                }
              }
              return $q.resolve();
            });
      };
    }

    return GenericIntegration;
  })();
}.call(this));
