/*
  Update an account's subdomain.
 */

(function () {
  module.exports = function ($http, account, apiBaseUrl, $q, telemetry) {
    var setSubdomain, validateSubdomain;
    validateSubdomain = function (subdomain) {
      var regex;
      regex = /^[a-z0-9][a-z0-9\-]*$/;
      return regex.test(subdomain);
    };
    setSubdomain = (function (_this) {
      return function (subdomain, origin) {
        if (subdomain !== "" && !validateSubdomain(subdomain)) {
          return $q.reject("Mhm, that subdomain is invalid. Please don't use spaces or capital letters.");
        }
        return $http
          .post(apiBaseUrl + "/accounts/" + account._id + `/settings/subdomain?origin=${origin}`, {
            subdomain: subdomain,
          })
          .then(function () {
            var ref;
            if (((ref = account.customSubdomain) != null ? ref.length : void 0) > 0) {
              telemetry.trackOnly(
                "Account Subdomain Changed",
                {
                  custom_subdomain: subdomain,
                  custom_subdomain_old: account.customSubdomain,
                },
                {
                  blockIntercom: true,
                },
              );
            }
            return (account.customSubdomain = subdomain);
          })
          ["catch"](function (response) {
            var errorMessage;
            errorMessage = response.data.message || "An error occurred trying to update your subdomain.";
            return $q.reject(errorMessage);
          });
      };
    })(this);
    return setSubdomain;
  };
}.call(this));
