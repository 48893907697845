// Generated by CoffeeScript 1.12.7
(function () {
  var FeatureFlag, PendingInvite, actionsDropdown;

  actionsDropdown = require("../MoreActionsDropdown/Dropdown");

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  /*
    @TODO: (som, 2018-03-26): refactor this. `resendInvite`, `deletePendingInvite`, and
    `createActionDropdown` are basically member functions of a 'pending invite' class
    All the actions dropdown code is particularly confusing right now
   */

  module.exports = PendingInvite = (function () {
    function PendingInvite(
      createUi,
      pendingInvitees,
      account,
      $http,
      apiBaseUrl,
      invoke,
      user,
      userIconFactory,
      refreshUi,
      rolesService,
      simpleDropdownFactory,
      modal,
      seatsService,
      rootDataStore,
    ) {
      var canDeleteInvites,
        createActionDropdown,
        createPendingUi,
        deletePendingInvite,
        doDelete,
        init,
        inviteIcon,
        pendingUis,
        resendInvite;
      pendingUis = [];
      inviteIcon = null;
      init = function () {
        var i, invitee, len, results;
        inviteIcon = userIconFactory.createEmpty({
          backgroundColor: "#4A4A4A",
        });
        results = [];
        for (i = 0, len = pendingInvitees.length; i < len; i++) {
          invitee = pendingInvitees[i];
          results.push(pendingUis.push(createPendingUi(invitee)));
        }
        return results;
      };
      canDeleteInvites = (function (_this) {
        return function () {
          var permission;
          permission = account.settings.permissions;
          if (
            (permission != null ? permission.userInvitePermission : void 0) === "creator" ||
            (permission != null ? permission.userInvitePermission : void 0) === "domain"
          ) {
            return true;
          } else {
            return rolesService.isAdmin(user);
          }
        };
      })(this);
      this.getPendingUis = function () {
        return pendingUis;
      };
      resendInvite = function (invite) {
        return $http.post(apiBaseUrl + "/accounts/" + account._id + "/invites/" + invite.inviteId + "/resend");
      };
      doDelete = function (inviteObject) {
        return $http["delete"](apiBaseUrl + "/accounts/" + account._id + "/invites/invite-with-seats", {
          data: {
            invites: [inviteObject.email],
          },
          headers: {
            "Content-Type": "application/json",
          },
        })
          .then(function (response) {
            var updatedAdditionalSeats;
            account.pendingInvitations = response.data.message.pendingInvitations;
            updatedAdditionalSeats = response.data.message.additionalSeats;
            if (updatedAdditionalSeats != null) {
              account.additionalSeats = updatedAdditionalSeats;
            }
            rootDataStore.getAccountDataStore().getPendingUsersDataStore().refreshPendingUsers();
            return refreshUi();
          })
          ["catch"](function (response) {
            throw new Error(response.data.error.message);
          });
      };
      deletePendingInvite = function (inviteObject) {
        var isTrial;
        isTrial = account.plan.displayName === "Trial";
        if (seatsService.canRemoveSeats(1) && !isTrial) {
          return modal.showDeleteModal({
            title: "Delete invite?",
            confirmLabel: "Delete invite",
            explainer:
              "You'll receive pro-rate credit for deleting this invite, which you can use towards your subscription.",
            confirming: function () {
              return doDelete(inviteObject);
            },
          });
        } else {
          return doDelete(inviteObject);
        }
      };
      createActionDropdown = (function (_this) {
        return function (invite) {
          var items, moreActionsDropdown;
          items = [
            {
              name: "Re-send Invite",
              onClick: function ($event) {
                return resendInvite(invite).then(function () {
                  invite.actionsDropdown.hide();
                  return $event.stopPropagation();
                });
              },
            },
          ];
          if (canDeleteInvites()) {
            items.push({
              name: "Delete Invite",
              onClick: function ($event) {
                return deletePendingInvite(invite);
              },
            });
          }
          return (moreActionsDropdown = invoke(actionsDropdown, null, {
            items: items,
            selector: ".invite-actions",
          }));
        };
      })(this);

      /*
        Create a single ui element for a pending invite
       */
      createPendingUi = (function (_this) {
        return function (invitation) {
          var ui;
          return (ui = createUi(require("./PendingInvite.html"), {
            inviteIcon: inviteIcon,
            email: invitation.email,
            role: invitation.role,
            inviteId: invitation._id,
            canDeleteInvites: canDeleteInvites,
            isUsingTeams: function () {
              return usingFeature(FeatureFlag.Teams);
            },
            showRolesDropdown: true,
            rolesDropdown: simpleDropdownFactory.create([
              {
                name: invitation.role,
                id: invitation.role,
              },
            ]),
            showActionsDropdown: function () {
              var ref, ref1, ref2, ref3;
              if (!ui.canDeleteInvites()) {
                return;
              }
              if ((ref = ui.actionsDropdown) != null ? ((ref1 = ref.ui) != null ? ref1.visible : void 0) : void 0) {
                ui.actionsDropdown.hide();
                return (_this.selected = null);
              } else {
                if ((ref2 = _this.selected) != null) {
                  if ((ref3 = ref2.actionsDropdown) != null) {
                    ref3.hide();
                  }
                }
                ui.actionsDropdown = createActionDropdown(ui);
                ui.actionsDropdown.show();
                return (_this.selected = ui);
              }
            },
          }));
        };
      })(this);
      init();
    }

    return PendingInvite;
  })();
}.call(this));
