// Generated by CoffeeScript 1.12.7
(function () {
  var AcceptText;

  module.exports = AcceptText = (function () {
    function AcceptText(createUi, block, showLabel) {
      this.ui = createUi(require("Project/Modules/Accept/Controls/AcceptText/AcceptText.html"), {
        settings: block.settings.button,
        showLabel: showLabel,
      });
    }

    return AcceptText;
  })();
}.call(this));
