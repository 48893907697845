// Generated by CoffeeScript 1.12.7
(function () {
  var FullStoryIntegration;

  module.exports = FullStoryIntegration = (function () {
    function FullStoryIntegration(createUi, genericIntegration) {
      genericIntegration.build.call(this, "fullStory", "FullStory Settings", require("./FullStory.html"));
    }

    return FullStoryIntegration;
  })();
}.call(this));
