// Generated by CoffeeScript 1.12.7

/*
  Initialise the Qwilr text editor
 */

(function () {
  module.exports = function (make, scope, qed, $timeout, editor, undo, buttonNames, allowTab) {
    var backspaceHandler, keyEventHandlers, keys, util;
    util = qed.util;
    keys = qed.keycodes;
    keyEventHandlers = make(require("./KeyEventHandlers.js"));
    backspaceHandler = require("./Handlers/Backspace");
    editor.addListener({
      onContent: function (e) {
        $timeout(function () {
          return scope.$apply();
        });
        return false;
      },
      onKey: function (e) {
        if (backspaceHandler(editor, "P", e)) {
          return true;
        }
        if (!allowTab && e.keyCode === keys.codes.TAB) {
          e.preventDefault();
          return true;
        } else {
          return false;
        }
      },
    });
    editor.toolbar = make.invoke(require("./QwilrToolbar.js"), {
      buttonNames: buttonNames,
    });
    return editor;
  };
}.call(this));
