"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertRateByBillingSchedule = exports.copyBillingSchedule = void 0;
var Types_1 = require("@CommonFrontendBackend/QuoteV2/Types");
var lodash_1 = require("lodash");
var mobx_1 = require("mobx");
var QuoteItemStore_1 = require("QuoteV2/Stores/QuoteItemStore");
var copyBillingSchedule = function (type, source) {
    if ((0, lodash_1.isNil)(source)) {
        return;
    }
    if ([Types_1.QuoteItemType.FixedCost, Types_1.QuoteItemType.Variable].includes(type)) {
        if (source instanceof QuoteItemStore_1.QuoteFixedCostItemStore || source instanceof QuoteItemStore_1.QuoteVariableItemStore) {
            return (0, mobx_1.toJS)(source.billingSchedule);
        }
    }
    return;
};
exports.copyBillingSchedule = copyBillingSchedule;
var convertRateByFrequency = function (rate, from, to) {
    var _a;
    if (from === to || from === "one off" || to === "one off") {
        return rate;
    }
    var baseUnit = (_a = {},
        _a[Types_1.RecurringFrequency.Yearly] = 1,
        _a[Types_1.RecurringFrequency.Quarterly] = 4,
        _a[Types_1.RecurringFrequency.Monthly] = 12,
        _a[Types_1.RecurringFrequency.Weekly] = 52,
        _a);
    return (rate / baseUnit[to]) * baseUnit[from];
};
exports.convertRateByBillingSchedule = convertRateByFrequency;
