(function () {
  var FoldableSetting;

  module.exports = FoldableSetting = (function () {
    function FoldableSetting(createUi, block, telemetry) {
      this.ui = createUi(require("./Foldable.html"), {
        settings: block.settings,
        triggerFoldable: (function (_this) {
          return function () {
            if (block.settings.foldable.isFoldable) {
              return telemetry.trackOnly("Block Settings Fold Enabled");
            }
          };
        })(this),
      });
    }

    return FoldableSetting;
  })();
}.call(this));
