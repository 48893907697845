// Generated by CoffeeScript 1.12.7

/*
  Given an embed url, generate the iframe for various types
  E.g. video, embed, splash blocks

  @TODO(mike, 2017-04-21) Consolidate all iframe generators here
 */

(function () {
  var IframeGenerator;

  module.exports = IframeGenerator = (function () {
    function IframeGenerator() {}

    IframeGenerator.prototype.getVideoBlockIframeHTML = function (embedUrl) {
      return (
        '<iframe\n  src="' +
        embedUrl +
        '"\n  width="660"\n  height="450"\n  frameborder="0"\n  webkitallowfullscreen mozallowfullscreen allowfullscreen>\n</iframe>'
      );
    };

    return IframeGenerator;
  })();
}.call(this));
