// Generated by CoffeeScript 1.12.7
(function () {
  var CarouselHandler,
    _,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  _ = require("lodash");

  /*
    The CarouselHandler is responsible for opening and closing Carousels for a Block.
  
    Several Carousels can be added using the handler, and when a Carousel is opened in the UI,
    any other open Carousels are closed and the new one is opened in its place.
  
    Every Block has its own instance of a handler.
   */

  module.exports = CarouselHandler = (function () {
    function CarouselHandler(block, projectEvents, $timeout, $log) {
      this.closeCarousel = bind(this.closeCarousel, this);
      this.openCarousel = bind(this.openCarousel, this);
      this.addCarousel = bind(this.addCarousel, this);
      this.getCarousel = bind(this.getCarousel, this);
      this.getCurrentCarousel = bind(this.getCurrentCarousel, this);
      this.getCarousels = bind(this.getCarousels, this);
      this.carousels = {};
      this.currentCarousel = null;
      this.timeout = $timeout;
      projectEvents.on(
        "bigComponentOpened",
        (function (_this) {
          return function (data) {
            if (data == null) {
              $log.error(new Error("No data object given in bigComponentOpened event"));
              return;
            }
            if (
              (data.blockId === block._id && data.openedByCollaborator) ||
              (data.blockId !== block._id && !data.openedByCollaborator)
            ) {
              return _this.closeCarousel();
            } else if (data.blockId === block._id && !data.openedByCollaborator) {
              return _this.openCarousel(data.type);
            }
          };
        })(this),
      );
    }

    /*
      Returns a list of carousels
    
      @api public
     */

    CarouselHandler.prototype.getCarousels = function () {
      return this.carousels;
    };

    /*
      Returns the current carousel
    
      @api public
     */

    CarouselHandler.prototype.getCurrentCarousel = function () {
      return this.currentCarousel;
    };

    /*
      Gets the carousel object.
    
      @param [String] name - the name of the carousel to get
      @api public
     */

    CarouselHandler.prototype.getCarousel = function (name) {
      return this.carousels[name];
    };

    /*
      Adds a carousel to the block
    
      @param [Object] carousel - an instance of Carousel
      @api public
     */

    CarouselHandler.prototype.addCarousel = function (carousel) {
      return (this.carousels[carousel.name] = carousel);
    };

    /*
      Opens a carousel. Closes any other carousels that are open at the time.
    
      @param [String] name - the name of the carousel to open
      @api public
     */

    CarouselHandler.prototype.openCarousel = function (name) {
      var carousel;
      carousel = this.getCarousel(name);
      if (carousel != null) {
        _.mapValues(this.carousels, function (o) {
          if (o.name !== name) {
            return o.hide();
          }
        });
        if (this.currentCarousel != null) {
          return this.timeout(
            (function (_this) {
              return function () {
                _this.currentCarousel = carousel;
                return carousel.open();
              };
            })(this),
            350,
          );
        } else {
          this.currentCarousel = carousel;
          return carousel.open();
        }
      }
    };

    /*
      Closes the carousel. If there is no name passed in, it will close all.
    
      @param [String] name - the name of the carousel to close
      @api public
     */

    CarouselHandler.prototype.closeCarousel = function (name) {
      var carousel;
      carousel = this.getCarousel(name);
      if (carousel != null) {
        return carousel.hide();
      } else {
        return _.mapValues(this.carousels, function (o) {
          return o.hide();
        });
      }
    };

    return CarouselHandler;
  })();
}.call(this));
