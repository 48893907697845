// Generated by CoffeeScript 1.12.7
(function () {
  var Save, template;

  template = require("./Save.html");

  module.exports = Save = (function () {
    function Save(createUi, waitFor, saveImpl) {
      this.state = "idle";
      this.waitForConfig = {
        type: "timeout",
        waitTime: 800,
      };
      this.ui = createUi(template, {
        save: (function (_this) {
          return function () {
            if (_this.ui.isClickable()) {
              _this.state = "adding";
              saveImpl();
              return waitFor(_this.waitForConfig)
                .then(function () {
                  _this.state = "justDone";
                  return waitFor(_this.waitForConfig);
                })
                .then(function () {
                  return (_this.state = "idle");
                });
            }
          };
        })(this),
        getState: (function (_this) {
          return function () {
            return _this.state;
          };
        })(this),
        isClickable: (function (_this) {
          return function () {
            return _this.state === "idle";
          };
        })(this),
      });
    }

    return Save;
  })();
}.call(this));
