"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DUMMY_FOLDER_ID = exports.parseRawFolder = exports.DashboardFoldersViewStore = void 0;
var mobx_1 = require("mobx");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var Types_1 = require("@CommonFrontendBackend/Folders/Types");
var Types_2 = require("@CommonFrontendBackend/Permissions/Types");
var FoldersTelemetry_1 = require("Dashboard/FoldersTelemetry");
var NewFolderType;
(function (NewFolderType) {
    NewFolderType["Account"] = "Account";
    NewFolderType["Private"] = "Private";
})(NewFolderType || (NewFolderType = {}));
var DUMMY_FOLDER_ID = "DummyNewFolder";
exports.DUMMY_FOLDER_ID = DUMMY_FOLDER_ID;
var LOADING_FOLDER_ID = "LoadingNewFolder";
var MAX_NAME_LENGTH = 64;
var DashboardFoldersViewStore = (function () {
    function DashboardFoldersViewStore(folderSelectionViewStore, dataStore, modalService, collectionType) {
        if (collectionType === void 0) { collectionType = Types_1.CollectionType.Page; }
        var _this = this;
        this.folderSelectionViewStore = folderSelectionViewStore;
        this.dataStore = dataStore;
        this.modalService = modalService;
        this.collectionType = collectionType;
        this.dummyNewFolder = null;
        this.loadingNewFolder = null;
        this.isCustomFolderBeingDragged = false;
        this.onCreateChildFolder = function (parentFolderId) {
            return _this.dataStore.createFolder("New folder", FoldersTelemetry_1.FolderCreatedOrigin.RightPanelButton, parentFolderId, true, {
                account: Types_2.Action.All,
            });
        };
        this.moveResourceToFolder = function (blockIds, folderId) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(this.collectionType === Types_1.CollectionType.Block)) return [3, 2];
                        return [4, this.dataStore.moveBlocksToFolder(blockIds, folderId)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        }); };
        this._myFoldersViewStore = new kaleidoscope_1.FolderTreeViewStore(function () { return _this.filterMyFolders; }, folderSelectionViewStore);
        this._accountFoldersViewStore = new kaleidoscope_1.FolderTreeViewStore(function () { return _this.filterAccountFolders; }, folderSelectionViewStore);
        switch (collectionType) {
            case Types_1.CollectionType.Block:
                this.resourceLabel = "blocks";
                break;
            case Types_1.CollectionType.Page:
                this.resourceLabel = "pages";
                break;
            case Types_1.CollectionType.UploadedImage:
                this.resourceLabel = "images";
                break;
        }
    }
    Object.defineProperty(DashboardFoldersViewStore.prototype, "folderSelectionStore", {
        get: function () {
            return this.folderSelectionViewStore;
        },
        enumerable: false,
        configurable: true
    });
    DashboardFoldersViewStore.prototype.getFolderName = function (folderId) {
        var rawFolder = this.dataStore.allFolders.find(function (_a) {
            var id = _a.id;
            return id === folderId;
        });
        return rawFolder === null || rawFolder === void 0 ? void 0 : rawFolder.name;
    };
    Object.defineProperty(DashboardFoldersViewStore.prototype, "allowCreatingFolder", {
        get: function () {
            return !this.dataStore.isRefreshing && !this.dummyNewFolder;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardFoldersViewStore.prototype, "allowDraggingFolders", {
        get: function () {
            return !this.dataStore.isRefreshing && !this.dummyNewFolder && !this.loadingNewFolder;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardFoldersViewStore.prototype, "myFoldersViewStore", {
        get: function () {
            return this._myFoldersViewStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardFoldersViewStore.prototype, "accountFolderViewStore", {
        get: function () {
            return this._accountFoldersViewStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardFoldersViewStore.prototype, "foldersDataStore", {
        get: function () {
            return this.dataStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardFoldersViewStore.prototype, "maxNameLength", {
        get: function () {
            return MAX_NAME_LENGTH;
        },
        enumerable: false,
        configurable: true
    });
    DashboardFoldersViewStore.prototype.startCreatingFolder = function (isAccountLevel) {
        if (isAccountLevel === void 0) { isAccountLevel = false; }
        this.dummyNewFolder = {
            id: DUMMY_FOLDER_ID,
            name: "",
            parent: undefined,
            renaming: true,
            animationState: kaleidoscope_1.FolderAnimationStates.Visible,
            newFolderType: isAccountLevel ? NewFolderType.Account : NewFolderType.Private,
            disableSelect: true,
        };
    };
    DashboardFoldersViewStore.prototype.onNameChange = function (folderId, newName) {
        if (folderId === DUMMY_FOLDER_ID && this.dummyNewFolder) {
            this.dummyNewFolder.name = newName;
        }
    };
    DashboardFoldersViewStore.prototype.onRenameConfirm = function (folderId, finalName) {
        return __awaiter(this, void 0, Promise, function () {
            var permissions;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(folderId === DUMMY_FOLDER_ID)) return [3, 3];
                        this.dummyNewFolder.animationState = kaleidoscope_1.FolderAnimationStates.Hidden;
                        this.loadingNewFolder = {
                            id: LOADING_FOLDER_ID,
                            name: this.dummyNewFolder.name,
                            parent: undefined,
                            animationState: kaleidoscope_1.FolderAnimationStates.Visible,
                            isLoading: true,
                            newFolderType: this.dummyNewFolder.newFolderType,
                            disableSelect: true,
                        };
                        permissions = void 0;
                        if (!!this.dummyNewFolder && this.dummyNewFolder.newFolderType === NewFolderType.Account) {
                            permissions = {
                                account: Types_2.Action.All,
                            };
                        }
                        return [4, Promise.all([
                                this.dataStore.createFolder(finalName, FoldersTelemetry_1.FolderCreatedOrigin.Sidebar, undefined, false, permissions),
                                delay(1400),
                            ])];
                    case 1:
                        _a.sent();
                        return [4, this.dataStore.forceFetch()];
                    case 2:
                        _a.sent();
                        this.dummyNewFolder = null;
                        this.loadingNewFolder = null;
                        return [3, 5];
                    case 3: return [4, this.dataStore.updateFolderName(folderId, finalName)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2];
                }
            });
        });
    };
    DashboardFoldersViewStore.prototype.onRenameCancel = function (folderId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(folderId === DUMMY_FOLDER_ID)) return [3, 2];
                        this.dummyNewFolder.animationState = kaleidoscope_1.FolderAnimationStates.Hidden;
                        return [4, delay(400)];
                    case 1:
                        _a.sent();
                        this.dummyNewFolder = null;
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    DashboardFoldersViewStore.prototype.moveFolder = function (folderToMove, destinationFolder) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.dataStore.moveFolder(folderToMove, destinationFolder)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    Object.defineProperty(DashboardFoldersViewStore.prototype, "isFolderBeingDragged", {
        get: function () {
            return this.isCustomFolderBeingDragged;
        },
        enumerable: false,
        configurable: true
    });
    DashboardFoldersViewStore.prototype.setIsCustomFolderBeingDragged = function (isBeingDragged) {
        this.isCustomFolderBeingDragged = isBeingDragged;
    };
    DashboardFoldersViewStore.prototype.onDeleteFolder = function (folderToDelete) {
        return __awaiter(this, void 0, Promise, function () {
            var msg, confirmedDeletion;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        msg = "Do you definitely want to delete the folder \"".concat(this.getFolderName(folderToDelete), "\"?\n                This will delete all ").concat(this.resourceLabel, " and folders within. This action cannot be undone.");
                        return [4, this.modalService.showDeleteModal({
                                confirmLabel: "Delete folder",
                                title: "Delete Folder?",
                                explainer: msg,
                                isEscapable: true,
                            })];
                    case 1:
                        confirmedDeletion = _a.sent();
                        if (!confirmedDeletion) return [3, 3];
                        return [4, this.dataStore.deleteFolder(folderToDelete)];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2, confirmedDeletion && folderToDelete === this.folderSelectionViewStore.selectedFolder];
                }
            });
        });
    };
    DashboardFoldersViewStore.prototype.addDummyNewFolder = function (folders) {
        if (!!this.loadingNewFolder) {
            folders.push(this.loadingNewFolder);
            folders.sort(sortFolderAlphabetically);
        }
        if (!!this.dummyNewFolder) {
            folders.unshift(this.dummyNewFolder);
        }
    };
    Object.defineProperty(DashboardFoldersViewStore.prototype, "filterAccountFolders", {
        get: function () {
            var _a;
            var filtered = this.dataStore.allFolders
                .filter(function (folder) {
                var _a;
                return !!((_a = folder.permissions) === null || _a === void 0 ? void 0 : _a.account);
            })
                .map(parseRawFolder)
                .sort(sortFolderAlphabetically);
            if (((_a = this.dummyNewFolder) === null || _a === void 0 ? void 0 : _a.newFolderType) === NewFolderType.Account) {
                this.addDummyNewFolder(filtered);
            }
            return filtered;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardFoldersViewStore.prototype, "filterMyFolders", {
        get: function () {
            var e_1, _a;
            var _b, _c;
            var filtered = [];
            try {
                for (var _d = __values(this.dataStore.allFolders), _e = _d.next(); !_e.done; _e = _d.next()) {
                    var rawFolder = _e.value;
                    if (rawFolder.userId === this.dataStore.userId && !!!((_b = rawFolder.permissions) === null || _b === void 0 ? void 0 : _b.account)) {
                        var parsed = parseRawFolder(rawFolder);
                        filtered.push(parsed);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_e && !_e.done && (_a = _d.return)) _a.call(_d);
                }
                finally { if (e_1) throw e_1.error; }
            }
            if (((_c = this.dummyNewFolder) === null || _c === void 0 ? void 0 : _c.newFolderType) === NewFolderType.Private) {
                this.addDummyNewFolder(filtered);
            }
            return filtered;
        },
        enumerable: false,
        configurable: true
    });
    var _a, _b, _c, _d, _e, _f;
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], DashboardFoldersViewStore.prototype, "dummyNewFolder", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], DashboardFoldersViewStore.prototype, "loadingNewFolder", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], DashboardFoldersViewStore.prototype, "isCustomFolderBeingDragged", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DashboardFoldersViewStore.prototype, "allowCreatingFolder", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DashboardFoldersViewStore.prototype, "allowDraggingFolders", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DashboardFoldersViewStore.prototype, "startCreatingFolder", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DashboardFoldersViewStore.prototype, "onCreateChildFolder", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_a = typeof kaleidoscope_1.FolderTreeId !== "undefined" && kaleidoscope_1.FolderTreeId) === "function" ? _a : Object, String]),
        __metadata("design:returntype", void 0)
    ], DashboardFoldersViewStore.prototype, "onNameChange", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_b = typeof kaleidoscope_1.FolderTreeId !== "undefined" && kaleidoscope_1.FolderTreeId) === "function" ? _b : Object, String]),
        __metadata("design:returntype", typeof (_c = typeof Promise !== "undefined" && Promise) === "function" ? _c : Object)
    ], DashboardFoldersViewStore.prototype, "onRenameConfirm", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_d = typeof kaleidoscope_1.FolderTreeId !== "undefined" && kaleidoscope_1.FolderTreeId) === "function" ? _d : Object]),
        __metadata("design:returntype", typeof (_e = typeof Promise !== "undefined" && Promise) === "function" ? _e : Object)
    ], DashboardFoldersViewStore.prototype, "onRenameCancel", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DashboardFoldersViewStore.prototype, "isFolderBeingDragged", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], DashboardFoldersViewStore.prototype, "setIsCustomFolderBeingDragged", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", typeof (_f = typeof Promise !== "undefined" && Promise) === "function" ? _f : Object)
    ], DashboardFoldersViewStore.prototype, "onDeleteFolder", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], DashboardFoldersViewStore.prototype, "filterAccountFolders", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], DashboardFoldersViewStore.prototype, "filterMyFolders", null);
    return DashboardFoldersViewStore;
}());
exports.DashboardFoldersViewStore = DashboardFoldersViewStore;
function parseRawFolder(rawFolder) {
    var id = rawFolder.id, ancestors = rawFolder.ancestors, name = rawFolder.name;
    var parent = ancestors.length > 0 ? ancestors[ancestors.length - 1] : undefined;
    return {
        id: id,
        parent: parent,
        name: name,
    };
}
exports.parseRawFolder = parseRawFolder;
function delay(milliseconds) {
    return new Promise(function (resolve) {
        setTimeout(resolve, milliseconds);
    });
}
var sortFolderAlphabetically = function (a, b) {
    return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
};
