// Generated by CoffeeScript 1.12.7

/*
  Handles events on H1 and H2 tags
 */

(function () {
  var EnterHandler, HeadingHandler, backspaceHandler, h;

  h = require("../Helpers.js");

  backspaceHandler = require("./Backspace");

  EnterHandler = require("./GenericEnterKeyHandler");

  module.exports = HeadingHandler = (function () {
    function HeadingHandler(editor, undo) {
      this.onKey = function (tag, e) {
        var enterHandler;
        if (e.keyCode === 13) {
          enterHandler = EnterHandler(tag)(editor, e);
          return true;
        }
        if (backspaceHandler(editor, tag, e)) {
          return true;
        }
        return false;
      };
    }

    return HeadingHandler;
  })();
}.call(this));
