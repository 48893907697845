"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getChartDateLabel = exports.chartLabelYearly = exports.chartLabelQuarterly = exports.chartLabelMonthly = exports.chartLabelWeekly = void 0;
var Types_1 = require("Reports/Types");
var chartLabelWeekly = function (isoString) {
    var date = new Date(isoString);
    var month = date.toLocaleString("default", { month: "short" }).toUpperCase();
    var day = date.getDate();
    var year = date.getFullYear().toString().slice(-2);
    return "".concat(month, " ").concat(day, ", ").concat(year);
};
exports.chartLabelWeekly = chartLabelWeekly;
var chartLabelMonthly = function (isoString) {
    var date = new Date(isoString);
    var month = date.toLocaleString("default", { month: "short" }).toUpperCase();
    var year = date.getFullYear();
    return "".concat(month, ", ").concat(year);
};
exports.chartLabelMonthly = chartLabelMonthly;
var chartLabelQuarterly = function (isoString) {
    var date = new Date(isoString);
    var quarter = Math.floor(date.getMonth() / 3 + 1);
    var year = date.getFullYear();
    return "Q".concat(quarter, " ").concat(year);
};
exports.chartLabelQuarterly = chartLabelQuarterly;
var chartLabelYearly = function (isoString) { return new Date(isoString).getFullYear().toString(); };
exports.chartLabelYearly = chartLabelYearly;
var DATE_CONVERTER_MAPPING = (_a = {},
    _a[Types_1.PeriodGranularity.WEEK] = exports.chartLabelWeekly,
    _a[Types_1.PeriodGranularity.MONTH] = exports.chartLabelMonthly,
    _a[Types_1.PeriodGranularity.QUARTER] = exports.chartLabelQuarterly,
    _a[Types_1.PeriodGranularity.YEAR] = exports.chartLabelYearly,
    _a);
function getChartDateLabel(isoString, granularity) {
    return DATE_CONVERTER_MAPPING[granularity](isoString);
}
exports.getChartDateLabel = getChartDateLabel;
