// Generated by CoffeeScript 1.12.7

/*
 * Defines an update subscription button and shows overlays to guide user through process
 */

(function () {
  var UpdateSubscription,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = UpdateSubscription = (function () {
    var confirmationScreen, selectPlanScreen;

    function UpdateSubscription(make, $timeout, account, apiBaseUrl, SubscriptionSequencer) {
      this.make = make;
      this.$timeout = $timeout;
      this.account = account;
      this.apiBaseUrl = apiBaseUrl;
      this.SubscriptionSequencer = SubscriptionSequencer;
      this.update = bind(this.update, this);
    }

    selectPlanScreen = require("SettingsAngular/Subscription/PlanExplorer/PlanExplorer")["default"];

    confirmationScreen = require("SettingsAngular/Subscription/Confirmation/Confirmation");

    UpdateSubscription.prototype.update = function () {
      var originalPlan, sequence;
      originalPlan = _.cloneDeep(this.account.plan);
      return (sequence = this.make(this.SubscriptionSequencer, {
        steps: [selectPlanScreen, confirmationScreen],
        allowCoupon: false,
        businessTrial: false,
        canEditFrequency: true,
        isNewSubscription: false,
        options: {
          onHitEnd: (function (_this) {
            return function (subscriptionDi) {
              return _this.$timeout(function () {
                return window.location.reload();
              }, 2500);
            };
          })(this),
        },
      }));
    };

    return UpdateSubscription;
  })();
}.call(this));
