// Generated by CoffeeScript 1.12.7
(function () {
  var AudioPlayerController;

  module.exports = AudioPlayerController = (function () {
    function AudioPlayerController(createUi, options, model, $sce) {
      this.ui = createUi(require("./AudioPlayer.html"), {
        url: (function (_this) {
          return function () {
            return model.url;
          };
        })(this),
        showWaveform: options.showWaveform || false,
        backgroundImage: options.backgroundImage || false,
        thumbnail: options.thumbnail || false,
        trustedUrl: (function (_this) {
          return function () {
            return $sce.trustAsResourceUrl(_this.ui.url());
          };
        })(this),
      });
    }

    return AudioPlayerController;
  })();
}.call(this));
