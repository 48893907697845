"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.VideoTab = void 0;
var react_1 = __importStar(require("react"));
var mobx_react_1 = require("mobx-react");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var styles = __importStar(require("./VideoTab.css"));
var BetterInfiniteScroll_1 = require("Project/ContentSidebar/Images/BetterInfiniteScroll/BetterInfiniteScroll");
var SidebarExploreContext_1 = require("../SidebarExploreContext");
var VideoThumbnail_1 = require("./VideoThumbnail");
var Types_1 = require("Project/ContentSidebar/Types");
var ExploreEmptyState_1 = require("../SharedComponents/ExploreEmptyState");
var SidebarExplore_1 = require("Project/ContentSidebar/Explore/SidebarExplore");
exports.VideoTab = (0, mobx_react_1.observer)(function (_a) {
    var onClickToAdd = _a.onClickToAdd, onDragAssetEnd = _a.onDragAssetEnd, onDragAssetStart = _a.onDragAssetStart;
    var elementId = "video-tab-panel-scroll-container";
    var _b = (0, SidebarExploreContext_1.useSidebarExploreContext)(), store = _b.videoExploreDataStore, searchTerm = _b.searchTerm;
    (0, react_1.useEffect)(function () {
        if (searchTerm) {
            store.search(searchTerm);
        }
        else {
            store.clearSearch();
        }
    }, [searchTerm, store]);
    var showEmptyState = !!searchTerm && store.loadedAssets.length === 0;
    return (react_1.default.createElement("div", { id: elementId, className: styles.scrollContainer }, showEmptyState ? (react_1.default.createElement(ExploreEmptyState_1.ExploreEmptyState, { assetType: Types_1.AssetType.Video, searchTerm: searchTerm })) : (react_1.default.createElement(BetterInfiniteScroll_1.BetterInfiniteScroll, { next: store.fetchNextIncrement, hasMore: store.hasUnloadedAssets, loader: react_1.default.createElement(kaleidoscope_1.Stack, { padding: "s" },
            react_1.default.createElement(kaleidoscope_1.Spinner, null)), dataLength: store.loadedAssets.length, scrollableTarget: elementId, style: { overflow: "initial" } }, react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.grid, "data-testid": "sidebar-video-grid" }, store.loadedAssets.map(function (asset, index) { return (react_1.default.createElement(VideoThumbnail_1.VideoThumbnail, { key: index, asset: asset, onClick: function () {
            onClickToAdd(asset, { tab: SidebarExplore_1.SidebarExploreTab.Videos, withSearchTerm: !!searchTerm });
        }, onDragStart: function () {
            onDragAssetStart(asset, { tab: SidebarExplore_1.SidebarExploreTab.Videos, withSearchTerm: !!searchTerm });
        }, onDragEnd: function () {
            onDragAssetEnd(asset);
        } })); }))))));
});
