// Generated by CoffeeScript 1.12.7
(function () {
  var InitSubcriptionFlow;

  module.exports = InitSubcriptionFlow = (function () {
    function InitSubcriptionFlow(isSubscribed, startSubscription, updateSubscription) {
      this.init = (function (_this) {
        return function () {
          if (isSubscribed()) {
            return updateSubscription.update();
          } else {
            return startSubscription.start();
          }
        };
      })(this);
    }

    return InitSubcriptionFlow;
  })();
}.call(this));
