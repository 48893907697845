// Generated by CoffeeScript 1.12.7
(function () {
  var ColumnWidthPicker;

  module.exports = ColumnWidthPicker = (function () {
    function ColumnWidthPicker(style, onChange, createUi) {
      if (style.columnWidth == null) {
        style.columnWidth = "regular";
      }
      this.ui = createUi(require("./ColumnWidthPicker.html"), {
        options: [
          {
            label: "Regular",
            value: "regular",
            icon: "Assets/icons/large/colwidth-regular-32.svg",
          },
          {
            label: "Medium",
            value: "medium",
            icon: "Assets/icons/large/colwidth-medium-32.svg",
          },
          {
            label: "Large",
            value: "large",
            icon: "Assets/icons/large/colwidth-large-32.svg",
          },
        ],
        selectedColumnWidth: style.columnWidth,
        select: (function (_this) {
          return function (columnWidth) {
            if (columnWidth === style.columnWidth) {
              return;
            }
            style.columnWidth = columnWidth;
            _this.ui.selectedColumnWidth = columnWidth;
            return onChange();
          };
        })(this),
      });
    }

    return ColumnWidthPicker;
  })();
}.call(this));
