// Generated by CoffeeScript 1.12.7
(function () {
  var BackgroundMenu,
    slice = [].slice;

  module.exports = BackgroundMenu = (function () {
    function BackgroundMenu(make, createUi, block, viewState, ImagePickerButton, $timeout, telemetry) {
      var VideoButton, backgroundSetter, imagePickerButton, imageUrl, setEmbed, setImageAction, videoPickerButton;
      backgroundSetter = make(require("../BackgroundSetter"), {
        onAddedContent: null,
      });
      setImageAction = function (imageUrl) {
        if (imageUrl != null) {
          return backgroundSetter.setImage(imageUrl);
        } else {
          $(".project-content-wrapper").off("click.blockMenu");
          viewState.currentTab.ui = null;
          viewState.currentTab.name = "";
          return (viewState.menuExpanded = false);
        }
      };
      setEmbed = function (embedUrl) {
        return backgroundSetter.setEmbed(embedUrl);
      };
      if (block.resourceUrl != null && block.resourceType === "image") {
        imageUrl = block.resourceUrl;
      } else if (block.imageUrl != null) {
        imageUrl = block.imageUrl;
      } else {
        imageUrl = "";
      }
      imagePickerButton = make(ImagePickerButton, {
        imageFile: imageUrl,
        skin: "light",
        onUploadedCallback: setImageAction,
        useImageCarousel: true,
      });
      VideoButton = require("Common/MediaPicker/VideoPicker/Button/Button");
      videoPickerButton = make(VideoButton, {
        skin: "light",
        videoUrl: block.videoUrl || "",
        onSelected: function () {
          var args;
          args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
          return backgroundSetter.setVideo.apply(backgroundSetter, args);
        },
      });
      this.ui = createUi(require("Project/Modules/Splash/Controls/Background/Background.html"), {
        block: block,
        viewState: viewState,
        imagePickerButton: imagePickerButton.ui,
        videoPickerButton: videoPickerButton.ui,
        hasResource: function () {
          var ref, ref1;
          return (
            ((ref = block.imageUrl) != null ? ref.length : void 0) > 0 ||
            ((ref1 = block.resourceUrl) != null ? ref1.length : void 0) > 0
          );
        },
      });
    }

    return BackgroundMenu;
  })();
}.call(this));
