"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = angular
    .module("qwilr.dragDrop", [])
    .directive("qDragover", function () { return ({
    link: function (scope, element, attrs) {
        var onDragOverHandler = scope.$eval(attrs.qDragover);
        element.on("dragover", onDragOverHandler);
    },
    restrict: "A",
}); })
    .directive("qDrop", function () { return ({
    link: function (scope, element, attrs) {
        var onDropHandler = scope.$eval(attrs.qDrop);
        element.on("drop", onDropHandler);
    },
    restrict: "A",
}); });
