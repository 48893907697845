"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var slate_1 = require("slate");
var CursorValidatorUtils_1 = require("./CursorValidatorUtils");
var BehaviourBuilder_1 = require("../Behaviours/BehaviourBuilder");
var Utils_1 = require("../Utils");
var Utils_2 = require("Project/QwilrEditor/Utils");
var lodash_1 = require("lodash");
var AccordionCursorValidator = {
    isIllegalCursor: function (editor) {
        if (editor.selection && slate_1.Range.isCollapsed(editor.selection) && (0, Utils_1.isInsideAccordionBody)(editor)) {
            var accordion = (0, Utils_1.checkAncestors)(editor, editor.selection.anchor.path, function (curr) { return slate_1.Element.isElement(curr) && curr.type === "accordion"; });
            var expanded = (0, Utils_2.as)("accordion", accordion[0]).data.expanded;
            if (!expanded) {
                return true;
            }
        }
        return false;
    },
    makeCursorValid: function (editor, lastKeyPress) {
        switch (lastKeyPress) {
            case BehaviourBuilder_1.KeyType.ArrowUp:
            case BehaviourBuilder_1.KeyType.ArrowLeft:
                return (0, CursorValidatorUtils_1.moveUpwardsUntilValid)(editor, AccordionCursorValidator.isIllegalCursor);
            case BehaviourBuilder_1.KeyType.ArrowDown:
            case BehaviourBuilder_1.KeyType.ArrowRight:
                return (0, CursorValidatorUtils_1.moveDownwardsUntilValid)(editor, AccordionCursorValidator.isIllegalCursor);
            default:
                setImmediate(function () {
                    var accordion = (0, Utils_1.checkAncestors)(editor, editor.selection.anchor.path, function (curr) { return slate_1.Element.isElement(curr) && curr.type === "accordion"; });
                    var newData = (0, lodash_1.cloneDeep)((0, Utils_2.as)("accordion", accordion[0]).data);
                    newData.expanded = true;
                    slate_1.Transforms.setNodes(editor, { data: newData }, { at: accordion[1] });
                });
                return editor;
        }
    },
};
exports.default = AccordionCursorValidator;
