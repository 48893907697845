"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FoldersTelemetry = exports.ProjectMovedTrigger = exports.FolderMovedTrigger = exports.FolderPermissionType = exports.FolderCreatedOrigin = void 0;
var Types_1 = require("@CommonFrontendBackend/Folders/Types");
var FolderCreatedOrigin;
(function (FolderCreatedOrigin) {
    FolderCreatedOrigin["Sidebar"] = "sidebar";
    FolderCreatedOrigin["RightPanelButton"] = "right panel button";
})(FolderCreatedOrigin || (exports.FolderCreatedOrigin = FolderCreatedOrigin = {}));
var FolderPermissionType;
(function (FolderPermissionType) {
    FolderPermissionType["Shared"] = "shared";
    FolderPermissionType["Private"] = "private";
})(FolderPermissionType || (exports.FolderPermissionType = FolderPermissionType = {}));
var FolderMovedTrigger;
(function (FolderMovedTrigger) {
    FolderMovedTrigger["Drag"] = "drag";
})(FolderMovedTrigger || (exports.FolderMovedTrigger = FolderMovedTrigger = {}));
var ProjectMovedTrigger;
(function (ProjectMovedTrigger) {
    ProjectMovedTrigger["Drag"] = "drag";
})(ProjectMovedTrigger || (exports.ProjectMovedTrigger = ProjectMovedTrigger = {}));
var FoldersTelemetry = (function () {
    function FoldersTelemetry(telemetry) {
        this.telemetry = telemetry;
    }
    FoldersTelemetry.prototype.folderCreated = function (createdOrigin, createdNestLevel, permissionType, collectionType) {
        this.telemetry.trackOnly("Folder Created", {
            folder_created_origin: createdOrigin,
            folder_created_nest_level: createdNestLevel,
            folder_permissions_type: permissionType,
            folder_type: getTelemetryNameForCollectionType(collectionType),
        });
    };
    FoldersTelemetry.prototype.folderRenamed = function (collectionType) {
        this.telemetry.trackOnly("Folder Renamed", { folder_type: getTelemetryNameForCollectionType(collectionType) });
    };
    FoldersTelemetry.prototype.folderDeleted = function (permissionType, collectionType) {
        this.telemetry.trackOnly("Folder Deleted", {
            folder_permission_type: permissionType,
            folder_type: getTelemetryNameForCollectionType(collectionType),
        });
    };
    FoldersTelemetry.prototype.folderMoved = function (movedTrigger, collectionType) {
        this.telemetry.trackOnly("Folder Moved", {
            folder_moved_trigger: movedTrigger,
            folder_type: getTelemetryNameForCollectionType(collectionType),
        });
    };
    FoldersTelemetry.prototype.projectMoved = function (movedTrigger) {
        this.telemetry.trackOnly("Project Moved", {
            project_moved_trigger: movedTrigger,
        });
    };
    return FoldersTelemetry;
}());
exports.FoldersTelemetry = FoldersTelemetry;
function getTelemetryNameForCollectionType(collectionType) {
    switch (collectionType) {
        case Types_1.CollectionType.Block:
            return "block";
        case Types_1.CollectionType.Page:
            return "page";
        case Types_1.CollectionType.UploadedImage:
            return "image";
    }
}
