// Generated by CoffeeScript 1.12.7
(function () {
  require("./EntryPoint.ng.html");

  module.exports = angular.module("qwilr.entry-point.main", ["ui.router"]).config(function ($stateProvider) {
    return $stateProvider.state("entry-point", {
      url: "/entry-point/quickbooks",
      templateUrl: "EntryPoint/EntryPoint.ng.html",
      data: {
        pageTitle: "QuickBooks",
      },
      controller: function ($rootScope, $state) {
        var di;
        di = $rootScope.di.child();
        return di.invoke(function (integrationService) {
          var quickbooksSettings;
          if (!integrationService.isEnabled("quickbooks")) {
            quickbooksSettings = integrationService.openIntegrationSettings("quickbooks");
            quickbooksSettings.enable();
          }
          return $state.go("dashboard", {
            nav: "pages",
          });
        });
      },
    });
  });
}.call(this));
