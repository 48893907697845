"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAtEndOfCell = exports.isAtStartOfCell = exports.inLastTableCell = exports.getCellIndexFromCellPath = exports.getClosestTable = exports.getCellAtIndex = exports.resizeColumns = exports.deleteTableColumns = exports.addTableColumn = exports.deleteTableRows = exports.setRepeatingTableRowToken = exports.addRepeatingTableRow = exports.addTableRow = exports.addTableRowAndSelectNextCell = void 0;
var Table_1 = require("ContentTokens/Models/Table");
var Utils_1 = require("../Utils");
var lodash_1 = require("lodash");
var slate_1 = require("slate");
var slate_react_1 = require("slate-react");
var Utils_2 = require("../Utils");
var Table_2 = require("../WidgetRegistry/Widgets/Table");
var addTableRowAndSelectNextCell = function (editor) {
    var _a;
    if (!editor.selection) {
        return;
    }
    var startPoint = slate_1.Range.start(editor.selection);
    var tableEntry = (0, exports.getClosestTable)(editor, startPoint.path);
    if (!tableEntry) {
        return;
    }
    var _b = __read(tableEntry, 2), table = _b[0], tablePath = _b[1];
    (0, exports.addTableRow)(editor, tablePath, table.data.rows - 1, "below");
    _a = __read((0, exports.getClosestTable)(editor, startPoint.path), 2), table = _a[0], tablePath = _a[1];
    var lastRow = (0, lodash_1.last)(table.children);
    var lastRowIndex = table.children.length - 1;
    var _c = __read(slate_1.Node.texts(lastRow), 1), targetText = _c[0];
    slate_1.Transforms.select(editor, {
        path: __spreadArray(__spreadArray(__spreadArray([], __read(tablePath), false), [lastRowIndex], false), __read(targetText[1]), false),
        offset: 0,
    });
};
exports.addTableRowAndSelectNextCell = addTableRowAndSelectNextCell;
var addTableRow = function (editor, path, rowId, direction) {
    var tableEntry = getTable(editor, path);
    if (!tableEntry) {
        throw new Error("Could not find a table at path provided");
    }
    var columns = tableEntry[0].data.columns;
    var data = (0, lodash_1.cloneDeep)(tableEntry[0].data);
    data.rows += 1;
    data.selection = null;
    slate_1.Transforms.setNodes(editor, { data: data }, { at: tableEntry[1] });
    var newRow = (0, Table_2.createTableRowNode)();
    for (var i = 0; i < columns; i++) {
        newRow.children.push((0, Table_2.createTableCellNode)());
    }
    var insertionLocation = rowId + (direction === "above" ? 0 : 1);
    slate_1.Transforms.insertNodes(editor, newRow, { at: __spreadArray(__spreadArray([], __read(path), false), [insertionLocation], false) });
};
exports.addTableRow = addTableRow;
var addRepeatingTableRow = function (editor, path, rowId, direction) {
    var tableEntry = getTable(editor, path);
    if (!tableEntry) {
        throw new Error("Could not find a table at path provided");
    }
    var columns = tableEntry[0].data.columns;
    var data = (0, lodash_1.cloneDeep)(tableEntry[0].data);
    data.rows += 1;
    data.selection = null;
    slate_1.Transforms.setNodes(editor, { data: data }, { at: tableEntry[1] });
    var newRow = (0, Table_2.createTableRepeatingRowNode)();
    for (var i = 0; i < columns; i++) {
        newRow.children.push((0, Table_2.createTableCellNode)());
    }
    var insertionLocation = rowId + (direction === "above" ? 0 : 1);
    slate_1.Transforms.insertNodes(editor, newRow, { at: __spreadArray(__spreadArray([], __read(path), false), [insertionLocation], false) });
};
exports.addRepeatingTableRow = addRepeatingTableRow;
var setRepeatingTableRowToken = function (editor, path, repeatingToken) {
    var nodePropsToUpdate = {
        data: { repeatingToken: repeatingToken },
    };
    slate_1.Transforms.setNodes(editor, nodePropsToUpdate, { at: path });
};
exports.setRepeatingTableRowToken = setRepeatingTableRowToken;
var deleteTableRows = function (editor, path, rowIds) {
    var e_1, _a;
    var tableEntry = getTable(editor, path);
    if (!tableEntry) {
        throw new Error("Could not find a table at path provided");
    }
    var data = (0, lodash_1.cloneDeep)(tableEntry[0].data);
    data.rows -= rowIds.length;
    slate_1.Transforms.setNodes(editor, { data: data }, { at: tableEntry[1] });
    var reverseSortedRowIds = rowIds.sort(function (a, b) { return b - a; });
    try {
        for (var reverseSortedRowIds_1 = __values(reverseSortedRowIds), reverseSortedRowIds_1_1 = reverseSortedRowIds_1.next(); !reverseSortedRowIds_1_1.done; reverseSortedRowIds_1_1 = reverseSortedRowIds_1.next()) {
            var rowId = reverseSortedRowIds_1_1.value;
            try {
                var pathToRow = __spreadArray(__spreadArray([], __read(tableEntry[1]), false), [rowId], false);
                slate_1.Transforms.removeNodes(editor, { at: pathToRow });
            }
            catch (error) {
                console.error(error);
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (reverseSortedRowIds_1_1 && !reverseSortedRowIds_1_1.done && (_a = reverseSortedRowIds_1.return)) _a.call(reverseSortedRowIds_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    slate_react_1.ReactEditor.blur(editor);
};
exports.deleteTableRows = deleteTableRows;
var addTableColumn = function (editor, tablePath, columnIndex, columnBorders) {
    var tableEntry = slate_1.Editor.node(editor, tablePath);
    var tableNode = (0, Utils_1.asTableNode)(tableEntry[0]);
    var tableRowNodes = (0, Table_1.getTableRowNodes)(tableNode);
    slate_1.Editor.withoutNormalizing(editor, function () {
        for (var rowIndex = 0; rowIndex < tableRowNodes.length; rowIndex++) {
            slate_1.Transforms.insertNodes(editor, (0, Table_2.createTableCellNode)(), {
                at: (0, Table_1.getTableCellPath)(tablePath, rowIndex, columnIndex),
            });
        }
        var data = (0, lodash_1.cloneDeep)(tableNode.data);
        data.columns += 1;
        data.columnBorders = (0, lodash_1.cloneDeep)(columnBorders);
        slate_1.Transforms.setNodes(editor, { data: data }, { at: tablePath });
    });
};
exports.addTableColumn = addTableColumn;
var deleteTableColumns = function (editor, tablePath, columnIndices, columnBorders) {
    var tableEntry = slate_1.Editor.node(editor, tablePath);
    var tableNode = (0, Utils_1.asTableNode)(tableEntry[0]);
    var tableRowNodes = (0, Table_1.getTableRowNodes)(tableNode);
    var data = (0, lodash_1.cloneDeep)(tableNode.data);
    data.columns -= columnIndices.length;
    data.columnBorders = (0, lodash_1.cloneDeep)(columnBorders);
    slate_1.Transforms.setNodes(editor, { data: data }, { at: tablePath });
    var reverseSortedColumnsIndices = columnIndices.sort(function (a, b) { return b - a; });
    var _loop_1 = function (rowIndex) {
        reverseSortedColumnsIndices.forEach(function (columnIndex) {
            try {
                slate_1.Transforms.removeNodes(editor, { at: (0, Table_1.getTableCellPath)(tablePath, rowIndex, columnIndex) });
            }
            catch (error) {
                console.error(error);
            }
        });
    };
    for (var rowIndex = 0; rowIndex < tableRowNodes.length; rowIndex++) {
        _loop_1(rowIndex);
    }
    slate_react_1.ReactEditor.blur(editor);
};
exports.deleteTableColumns = deleteTableColumns;
var resizeColumns = function (editor, path, columnBorders) {
    var tableEntry = getTable(editor, path);
    if (!tableEntry) {
        throw new Error("Could not find a table at path provided");
    }
    var data = (0, lodash_1.cloneDeep)(tableEntry[0].data);
    data.columnBorders = (0, lodash_1.cloneDeep)(columnBorders);
    slate_1.Transforms.setNodes(editor, { data: data }, { at: tableEntry[1] });
};
exports.resizeColumns = resizeColumns;
var getCellAtIndex = function (index, tableEntry) {
    var _a = __read(tableEntry, 2), table = _a[0], tablePath = _a[1];
    var columns = table.data.columns;
    var rowId = Math.floor(index / columns);
    var columnId = index % columns;
    return [table.children[rowId].children[columnId], __spreadArray(__spreadArray([], __read(tablePath), false), [rowId, columnId], false)];
};
exports.getCellAtIndex = getCellAtIndex;
var getClosestTable = function (editor, path) {
    return (0, Utils_2.checkAncestors)(editor, path, function (curr) { return slate_1.Element.isElement(curr) && curr.type === "table"; });
};
exports.getClosestTable = getClosestTable;
var getCellIndexFromCellPath = function (editor, path) {
    var _a = __read((0, lodash_1.take)(path, 3), 3), tableIndex = _a[0], rowIndex = _a[1], colIndex = _a[2];
    var table = (0, Utils_1.asTableNode)(editor.children[tableIndex]);
    var columnCount = table.data.columns;
    var cellIndex = rowIndex * columnCount + colIndex;
    return cellIndex;
};
exports.getCellIndexFromCellPath = getCellIndexFromCellPath;
var inLastTableCell = function (editor) {
    if (!editor.selection) {
        return false;
    }
    var startPoint = slate_1.Range.start(editor.selection);
    var tableCellEntry = (0, Utils_2.checkAncestors)(editor, startPoint.path, function (curr) {
        return slate_1.Element.isElement(curr) && curr.type === "tableCell";
    });
    if (!tableCellEntry) {
        return false;
    }
    var tableEntry = (0, Utils_2.checkAncestors)(editor, tableCellEntry[1], function (curr) { return slate_1.Element.isElement(curr) && curr.type === "table"; });
    var _a = __read(tableEntry, 2), table = _a[0], tablePath = _a[1];
    var cellPath = tableCellEntry[1];
    var cellIndex = (0, exports.getCellIndexFromCellPath)(editor, cellPath);
    var lastCellPath = __spreadArray(__spreadArray([], __read(tablePath), false), [table.data.rows - 1, table.data.columns - 1], false);
    var lastCellIndex = (0, exports.getCellIndexFromCellPath)(editor, lastCellPath);
    return cellIndex === lastCellIndex;
};
exports.inLastTableCell = inLastTableCell;
var isAtStartOfCell = function (editor) {
    return isAtPointOfCell(editor, slate_1.Editor.start);
};
exports.isAtStartOfCell = isAtStartOfCell;
var isAtEndOfCell = function (editor) {
    return isAtPointOfCell(editor, slate_1.Editor.end);
};
exports.isAtEndOfCell = isAtEndOfCell;
var isAtPointOfCell = function (editor, getPoint) {
    var selection = editor.selection;
    if (selection && slate_1.Range.isCollapsed(selection)) {
        var cellPath = getCellPath(editor);
        if (cellPath) {
            var end = getPoint(editor, cellPath);
            if (slate_1.Point.equals(selection.anchor, end)) {
                return true;
            }
        }
    }
    return false;
};
var getCellPath = function (editor) {
    var _a = __read(slate_1.Editor.nodes(editor, {
        match: function (n) { return slate_1.Element.isElement(n) && n.type === "tableCell"; },
    }), 1), cell = _a[0];
    if (cell) {
        return cell[1];
    }
};
var getTable = function (editor, path) {
    var maybeTableEntry = slate_1.Editor.node(editor, path);
    if (!maybeTableEntry) {
        return null;
    }
    else if (slate_1.Element.isElement(maybeTableEntry[0]) && maybeTableEntry[0].type === "table") {
        return maybeTableEntry;
    }
    else {
        throw new Error("Attempting to get table with path that doesn't lead to a table");
    }
};
