// Generated by CoffeeScript 1.12.7

/*
  Provides a list of "recommended" font pairings for Google Fonts.
 */

(function () {
  module.exports = function (imageLocation) {
    var cssBackground, googleFont, pairs;
    googleFont = function (fontFamily, typeface) {
      return {
        friendlyName: fontFamily,
        name: fontFamily,
        typeface: typeface,
        vendor: "GoogleFonts",
      };
    };
    cssBackground = function (filename) {
      return "url(" + imageLocation.image("font-pairings/" + filename + ".jpg") + ")";
    };
    pairs = [
      {
        heading: "Recalled To Life",
        subHeading: "It was the best of times, it was the worst of times.",
        body:
          "it was the age of wisdom, it was the age of foolishness, it was the epoch of belief, it was the epoch of incredulity, it was the season of Light, it was the season of Darkness, it was the spring of hope, it was the winter of despair, we had everything before us, we had nothing before us, we were all going direct to Heaven, we were all going direct the other way— in short, the period was so far like the present period, that some of its noisiest authorities insisted on its being received, for good or for evil, in the superlative degree of comparison only.",
        fonts: {
          h1: googleFont("Josefin Slab", "sans-serif"),
          h2: googleFont("Josefin Slab", "sans-serif"),
          p: googleFont("Lora", "sans-serif"),
        },
        backgroundColor: "#11469b",
        textColor: "#fff",
        backgroundImage: cssBackground("lion"),
      },
      {
        heading: "Book One: 1805",
        subHeading: "Well, Prince, so Genoa and Lucca are now just family estates of the Buonapartes.",
        body:
          "But I warn you, if you don't tell me that this means war, if you still try to defend the infamies and horrors perpetrated by that Antichrist- I really believe he is Antichrist- I will have nothing more to do with you and you are no longer my friend, no longer my 'faithful slave,' as you call yourself! But how do you do? I see I have frightened you- sit down and tell me all the news.",
        fonts: {
          h1: googleFont("Julius Sans One", "sans-serif"),
          h2: googleFont("Crimson Text", "sans-serif"),
          p: googleFont("Crimson Text", "sans-serif"),
        },
        backgroundColor: "#9b59b6",
        textColor: "#fff",
        backgroundImage: cssBackground("eagle"),
      },
      {
        heading: "Part One",
        subHeading: "Lightness And Weight",
        body:
          "The idea of eternal return is a mysterious one, and Nietzsche has often perplexed other philosophers with it: to think that everything recurs as we once experienced it, and the the recurrence itself recurs ad infinitum! What does this myth signify?",
        fonts: {
          h1: googleFont("Quattrocento", "sans-serif"),
          h2: googleFont("Fanwood Text", "sans-serif"),
          p: googleFont("Fanwood Text", "sans-serif"),
        },
        backgroundColor: "#467885",
        textColor: "#fcfefd",
        backgroundImage: cssBackground("rocky-mountain"),
      },
      {
        heading: "Inferno",
        subHeading: "Midway upon the journey of our life I found myself within a forest dark",
        body:
          "For the straightforward pathway had been lost. Ah me! how hard a thing it is to say What was this forest savage, rough, and stern, Which in the very thought renews the fear. So bitter is it, death is little more; But of the good to treat, which there I found, Speak will I of the other things I saw there. I cannot well repeat how there I entered, So full was I of slumber at the moment In which I had abandoned the true way.",
        fonts: {
          h1: googleFont("Nixie One", "sans-serif"),
          h2: googleFont("Libre Baskerville", "sans-serif"),
          p: googleFont("Libre Baskerville", "sans-serif"),
        },
        backgroundColor: "#ff7575",
        textColor: "#fff",
        backgroundImage: cssBackground("watch"),
      },
      {
        heading: "Recalled To Life",
        subHeading: "It was the best of times, it was the worst of times.",
        body:
          "it was the age of wisdom, it was the age of foolishness, it was the epoch of belief, it was the epoch of incredulity, it was the season of Light, it was the season of Darkness, it was the spring of hope, it was the winter of despair, we had everything before us, we had nothing before us, we were all going direct to Heaven, we were all going direct the other way— in short, the period was so far like the present period, that some of its noisiest authorities insisted on its being received, for good or for evil, in the superlative degree of comparison only.",
        fonts: {
          h1: googleFont("Archivo Black", "sans-serif"),
          h2: googleFont("Tenor Sans", "sans-serif"),
          p: googleFont("Tenor Sans", "sans-serif"),
        },
        backgroundColor: "#272727",
        textColor: "#fff",
        backgroundImage: cssBackground("eagle"),
      },
      {
        heading: "One morning...",
        subHeading: "when Gregor Samsa woke from troubled dreams, he found himself transformed in his bed",
        body:
          "Into a horrible vermin. He lay on his armour-like back, and if he lifted his head a little he could see his brown belly, slightly domed and divided by arches into stiff sections. The bedding was hardly able to cover it and seemed ready to slide off any moment. His many legs, pitifully thin compared with the size of the rest of him, waved about helplessly as he looked.",
        fonts: {
          h1: googleFont("Playfair Display", "sans-serif"),
          h2: googleFont("Slabo", "sans-serif"),
          p: googleFont("Slabo", "sans-serif"),
        },
        backgroundColor: "#1174cf",
        textColor: "#fff",
        backgroundImage: cssBackground("road"),
      },
      {
        heading: "Sing, O Goddess",
        subHeading: "The anger of Achilles son of Peleus",
        body:
          "That brought countless ills upon the Achaeans. Many a brave soul did it send hurrying down to Hades, and many a hero did it yield a prey to dogs and vultures, for so were the counsels of Jove fulfilled from the day on which the son of Atreus, king of men, and great Achilles, first fell out with one another. ",
        fonts: {
          h1: googleFont("Sintony", "sans-serif"),
          h2: googleFont("Dancing Script", "sans-serif"),
          p: googleFont("Sintony", "sans-serif"),
        },
        backgroundColor: "#eb1d5d",
        textColor: "#fff",
        backgroundImage: cssBackground("sunny-hilltop"),
      },
      {
        heading: "Inferno",
        subHeading: "Midway upon the journey of our life I found myself within a forest dark",
        body:
          "For the straightforward pathway had been lost. Ah me! how hard a thing it is to say What was this forest savage, rough, and stern, Which in the very thought renews the fear. So bitter is it, death is little more; But of the good to treat, which there I found, Speak will I of the other things I saw there. I cannot well repeat how there I entered, So full was I of slumber at the moment In which I had abandoned the true way.",
        fonts: {
          h1: googleFont("Rufina", "sans-serif"),
          h2: googleFont("Slabo", "sans-serif"),
          p: googleFont("Oxygen", "sans-serif"),
        },
        backgroundColor: "#e67e22",
        textColor: "#fff",
        backgroundImage: cssBackground("happy-girl"),
      },
    ];
    return pairs;
  };
}.call(this));
