"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.widgetAdderBlacklist = exports.WidgetAdderOptions = exports.getWidgetAdderOptions = exports.shouldOpenMenu = exports.getTopPosition = void 0;
var slate_1 = require("slate");
var EditorTypes_1 = require("../../EditorTypes");
var Utils_1 = require("Project/QwilrEditor/Utils");
var slate_react_1 = require("slate-react");
var lodash_1 = require("lodash");
var EditorWidgetRegistry_1 = require("Project/QwilrEditor/WidgetRegistry/EditorWidgetRegistry");
var LEFT_MARGIN = 7;
var getTopPosition = function (editor, editorElem, adderElem) {
    var selection = editor.selection;
    var startBlockEntry = (0, Utils_1.getStartBlock)(editor);
    var container = editorElem.getBoundingClientRect();
    var adderRect = adderElem.getBoundingClientRect();
    var goodSelection = selection && slate_react_1.ReactEditor.isFocused(editor) && slate_1.Range.isCollapsed(selection);
    var shouldDisplayWidgetAdderOnCurrentSelection = goodSelection &&
        !!startBlockEntry &&
        isParentBlockValid(editor, startBlockEntry) &&
        (slate_1.Editor.isEmpty(editor, startBlockEntry[0]) || hasWidgetAddingText(slate_1.Node.string(startBlockEntry[0])));
    if (shouldDisplayWidgetAdderOnCurrentSelection) {
        var blockEntry = slate_1.Editor.node(editor, slate_1.Path.parent(selection.anchor.path));
        var blockEntryNode = slate_react_1.ReactEditor.toDOMNode(editor, blockEntry[0]);
        var nativeNodeRect = blockEntryNode.getBoundingClientRect();
        var blockEntryTopPadding = window.getComputedStyle(blockEntryNode).paddingTop;
        return {
            position: EditorTypes_1.WidgetAdderPosition.Selection,
            top: "calc(".concat(nativeNodeRect.top - container.top, "px + ").concat(blockEntryTopPadding, ")"),
            left: nativeNodeRect.left - container.left - adderRect.height - LEFT_MARGIN,
            text: slate_1.Node.string(startBlockEntry[0]),
            path: slate_1.Path.parent(selection.anchor.path),
        };
    }
    return {
        position: EditorTypes_1.WidgetAdderPosition.AfterLastLine,
        top: "".concat(container.height - adderRect.height, "px"),
        left: -adderRect.height - LEFT_MARGIN,
        text: "",
    };
};
exports.getTopPosition = getTopPosition;
var shouldOpenMenu = function (editor) {
    var selection = editor.selection;
    var startBlockEntry = (0, Utils_1.getStartBlock)(editor);
    if (startBlockEntry) {
        return (!!selection &&
            slate_1.Range.isCollapsed(selection) &&
            slate_react_1.ReactEditor.isFocused(editor) &&
            isParentBlockValid(editor, startBlockEntry) &&
            hasWidgetAddingText(slate_1.Node.string(startBlockEntry[0])));
    }
    else {
        return false;
    }
};
exports.shouldOpenMenu = shouldOpenMenu;
var getWidgetAdderOptions = function (context) {
    var filterText = context.filterText, inLayoutType = context.inLayoutType, usingFeature = context.usingFeature;
    if (!filterText.startsWith("+")) {
        return [];
    }
    var filterTextWithoutPlus = filterText.replace(/^\+/, "").toLowerCase();
    return (0, lodash_1.sortBy)(exports.WidgetAdderOptions, function (option) {
        var index = widgetAdderOrdering.findIndex(function (sortId) { return option.type === sortId; });
        if (index === -1) {
            return 1000;
        }
        return index;
    }).filter(function (_a) {
        var type = _a.type, tags = _a.tags;
        if (!type.startsWith(filterTextWithoutPlus) && !tags.find(function (tag) { return tag.startsWith(filterTextWithoutPlus); })) {
            return false;
        }
        var widgetConfig = (0, EditorWidgetRegistry_1.getGenericConfigByType)(type);
        var registrySourcedOption = widgetConfig === null || widgetConfig === void 0 ? void 0 : widgetConfig.widgetAdderConfig;
        if (registrySourcedOption) {
            if (inLayoutType && !widgetConfig.validContainers.includes(inLayoutType)) {
                return false;
            }
            if (widgetConfig.requiresTokenAvailability && !context.tokensAvailable) {
                return false;
            }
            var hideWithAnyFeatures = registrySourcedOption.hideWithAnyFeatures, requiresFeatures = registrySourcedOption.requiresFeatures;
            if (hideWithAnyFeatures && hideWithAnyFeatures.some(function (f) { return usingFeature(f); })) {
                return false;
            }
            if (!requiresFeatures) {
                return true;
            }
            return requiresFeatures.every(function (f) { return usingFeature(f); });
        }
        return true;
    });
};
exports.getWidgetAdderOptions = getWidgetAdderOptions;
var widgetAdderOrdering = [
    "image",
    "layout",
    "sideBySide",
    "table",
    "accordion",
    "video",
    "iframe",
    "button",
    "googleMaps",
    "roiCalculator",
    "contentLoop",
    "horizontalRule",
];
exports.WidgetAdderOptions = EditorWidgetRegistry_1.configs
    .filter(function (c) { return !(0, lodash_1.isNil)(c.widgetAdderConfig); })
    .map(function (config) {
    var widgetAdderConfig = config.widgetAdderConfig;
    if ((0, lodash_1.isNil)(widgetAdderConfig)) {
        throw new Error("No-op");
    }
    return {
        type: config.type,
        tags: widgetAdderConfig.tags,
        icon: widgetAdderConfig.icon,
        displayName: widgetAdderConfig.displayName,
        tooltip: widgetAdderConfig.tooltip,
    };
});
exports.widgetAdderBlacklist = ["quoteSummary"];
var allTags = [];
exports.WidgetAdderOptions.forEach(function (option) {
    allTags.push(option.type.toLowerCase());
    if (option.displayName) {
        allTags.push(option.displayName.toLowerCase());
    }
    allTags = allTags.concat(option.tags);
});
var hasWidgetAddingText = function (text) {
    if (text.length === 0 || !text.startsWith("+")) {
        return false;
    }
    var lowerCaseText = text.toLowerCase();
    var matchingTags = allTags.filter(function (tag) {
        return "+".concat(tag).startsWith(lowerCaseText);
    });
    return matchingTags.length > 0;
};
var isParentBlockValid = function (editor, block) {
    var _a, _b;
    var parent = slate_1.Node.parent(editor, block[1]);
    return (isContentBlock(block[0].type) &&
        (slate_1.Editor.isEditor(parent) ||
            parent.type === "sideBySideColumn" ||
            !!((_b = (_a = (0, EditorWidgetRegistry_1.getGenericConfigByType)(parent.type, true)) === null || _a === void 0 ? void 0 : _a.containerNodeConfiguration) === null || _b === void 0 ? void 0 : _b.showWidgetAdder)));
};
var isContentBlock = function (type) {
    return type === "paragraph";
};
