"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageLibraryDataStore = exports.IMAGES_PER_PAGE = void 0;
var Types_1 = require("Common/AssetLibrary/Types");
var Types_2 = require("Common/AssetLibrary/ImageLibrary/Types");
var Types_3 = require("Common/AssetLibrary/ImageLibrary/SystemFolderStores/Types");
var mobx_1 = require("mobx");
var ImageLibraryUtils_1 = require("Common/AssetLibrary/ImageLibrary/ImageLibraryUtils");
var Types_4 = require("@CommonFrontendBackend/Folders/Types");
exports.IMAGES_PER_PAGE = 12;
var ImageLibraryDataStore = (function () {
    function ImageLibraryDataStore(systemFolderDataStores) {
        var e_1, _a;
        var _this = this;
        this.type = Types_1.AssetType.Image;
        this.systemFolderDataStores = new Map();
        this.moveAssets = function (assetIds, folderId) { return __awaiter(_this, void 0, Promise, function () {
            var isDifferentFolder, isNotRestrictedFolder;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(0, ImageLibraryUtils_1.isSystemFolderDataStoreWithMove)(this.selectedSystemFolderDataStore)) {
                            return [2];
                        }
                        isDifferentFolder = this.isCustomFolderSelected
                            ? folderId !== this.selectedCustomFolderId
                            : folderId !== Types_2.ImageLibrarySystemFolder.Uploads;
                        isNotRestrictedFolder = folderId !== Types_2.ImageLibrarySystemFolder.Unsplash && folderId !== Types_2.ImageLibrarySystemFolder.RecentlyUsed;
                        if (!(isDifferentFolder && isNotRestrictedFolder)) return [3, 2];
                        return [4, this.selectedSystemFolderDataStore.moveImages(assetIds, folderId)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        }); };
        try {
            for (var systemFolderDataStores_1 = __values(systemFolderDataStores), systemFolderDataStores_1_1 = systemFolderDataStores_1.next(); !systemFolderDataStores_1_1.done; systemFolderDataStores_1_1 = systemFolderDataStores_1.next()) {
                var store = systemFolderDataStores_1_1.value;
                this.systemFolderDataStores.set(store.systemFolderId, store);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (systemFolderDataStores_1_1 && !systemFolderDataStores_1_1.done && (_a = systemFolderDataStores_1.return)) _a.call(systemFolderDataStores_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    Object.defineProperty(ImageLibraryDataStore.prototype, "assets", {
        get: function () {
            return this.selectedSystemFolderDataStore.images;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageLibraryDataStore.prototype, "collections", {
        get: function () {
            if ((0, ImageLibraryUtils_1.isSystemFolderDataStoreWithCollection)(this.selectedSystemFolderDataStore)) {
                return this.selectedSystemFolderDataStore.collections || [];
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageLibraryDataStore.prototype, "isOutOfNewData", {
        get: function () {
            return this.selectedSystemFolderDataStore.isOutOfNewData;
        },
        enumerable: false,
        configurable: true
    });
    ImageLibraryDataStore.prototype.emptyAssetList = function () {
        this.selectedSystemFolderDataStore.emptyImageList();
    };
    ImageLibraryDataStore.prototype.emptyCollectionList = function () {
        if ((0, ImageLibraryUtils_1.isSystemFolderDataStoreWithCollection)(this.selectedSystemFolderDataStore)) {
            this.selectedSystemFolderDataStore.emptyCollectionList();
        }
    };
    ImageLibraryDataStore.prototype.renameAsset = function (assetId, newName) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(0, ImageLibraryUtils_1.isSystemFolderDataStoreWithRename)(this.selectedSystemFolderDataStore)) return [3, 2];
                        return [4, this.selectedSystemFolderDataStore.renameImage(assetId, newName)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ImageLibraryDataStore.prototype.deleteAsset = function (assetId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(0, ImageLibraryUtils_1.isSystemFolderDataStoreWithRename)(this.selectedSystemFolderDataStore)) return [3, 2];
                        return [4, this.selectedSystemFolderDataStore.deleteImage(assetId)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ImageLibraryDataStore.prototype.bulkDeleteAssets = function (assetIds) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(0, ImageLibraryUtils_1.isSystemFolderDataStoreWithRename)(this.selectedSystemFolderDataStore)) return [3, 2];
                        return [4, this.selectedSystemFolderDataStore.bulkDeleteImages(assetIds)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ImageLibraryDataStore.prototype.searchAssets = function (searchTerm) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(0, ImageLibraryUtils_1.isSystemFolderDataStoreWithSearch)(this.selectedSystemFolderDataStore)) return [3, 2];
                        return [4, this.selectedSystemFolderDataStore.searchImages(searchTerm, this.pageToFetchNext)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ImageLibraryDataStore.prototype.fetchAssets = function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.isCustomFolderSelected) return [3, 2];
                        return [4, this.selectedSystemFolderDataStore.fetchImages(this.pageToFetchNext, this.selectedCustomFolderId)];
                    case 1:
                        _a.sent();
                        return [3, 4];
                    case 2: return [4, this.selectedSystemFolderDataStore.fetchImages(this.pageToFetchNext)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2];
                }
            });
        });
    };
    ImageLibraryDataStore.prototype.fetchCollectionImages = function (collectionId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!((0, ImageLibraryUtils_1.isSystemFolderDataStoreWithCollection)(this.selectedSystemFolderDataStore) && collectionId)) return [3, 2];
                        return [4, this.selectedSystemFolderDataStore.fetchCollectionImages(collectionId, this.pageToFetchNext)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ImageLibraryDataStore.prototype.fetchQwilrStockImages = function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(0, ImageLibraryUtils_1.isSystemFolderDataStoreWithCollection)(this.selectedSystemFolderDataStore)) return [3, 2];
                        return [4, this.selectedSystemFolderDataStore.fetchQwilrStockImages(this.pageToFetchNext)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ImageLibraryDataStore.prototype.fetchCollections = function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(0, ImageLibraryUtils_1.isSystemFolderDataStoreWithCollection)(this.selectedSystemFolderDataStore)) return [3, 2];
                        return [4, this.selectedSystemFolderDataStore.fetchCollections()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    ImageLibraryDataStore.prototype.getImageById = function (imageId) {
        return this.selectedSystemFolderDataStore.getImageById(imageId);
    };
    ImageLibraryDataStore.prototype.selectFolder = function (folderId) {
        if (!this.systemFolderDataStores.has(folderId)) {
            this.selectedSystemFolderId = Types_2.ImageLibrarySystemFolder.Uploads;
            this.selectedCustomFolderId = folderId;
        }
        else {
            this.selectedSystemFolderId = folderId;
            this.selectedCustomFolderId = undefined;
        }
    };
    Object.defineProperty(ImageLibraryDataStore.prototype, "selectedSystemFolderDataStore", {
        get: function () {
            return this.systemFolderDataStores.get(this.selectedSystemFolderId);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageLibraryDataStore.prototype, "isCustomFolderSelected", {
        get: function () {
            return this.selectedSystemFolderId === Types_2.ImageLibrarySystemFolder.Uploads && !!this.selectedCustomFolderId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageLibraryDataStore.prototype, "pageToFetchNext", {
        get: function () {
            return Math.floor(this.assets.length / exports.IMAGES_PER_PAGE);
        },
        enumerable: false,
        configurable: true
    });
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", typeof (_a = typeof Types_4.ImageLibraryFolderId !== "undefined" && Types_4.ImageLibraryFolderId) === "function" ? _a : Object)
    ], ImageLibraryDataStore.prototype, "selectedSystemFolderId", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], ImageLibraryDataStore.prototype, "selectedCustomFolderId", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ImageLibraryDataStore.prototype, "emptyAssetList", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ImageLibraryDataStore.prototype, "emptyCollectionList", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String, String]),
        __metadata("design:returntype", typeof (_c = typeof Promise !== "undefined" && Promise) === "function" ? _c : Object)
    ], ImageLibraryDataStore.prototype, "renameAsset", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", typeof (_d = typeof Promise !== "undefined" && Promise) === "function" ? _d : Object)
    ], ImageLibraryDataStore.prototype, "deleteAsset", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", typeof (_e = typeof Promise !== "undefined" && Promise) === "function" ? _e : Object)
    ], ImageLibraryDataStore.prototype, "bulkDeleteAssets", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", typeof (_f = typeof Promise !== "undefined" && Promise) === "function" ? _f : Object)
    ], ImageLibraryDataStore.prototype, "searchAssets", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", typeof (_g = typeof Promise !== "undefined" && Promise) === "function" ? _g : Object)
    ], ImageLibraryDataStore.prototype, "fetchAssets", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", typeof (_h = typeof Promise !== "undefined" && Promise) === "function" ? _h : Object)
    ], ImageLibraryDataStore.prototype, "fetchCollectionImages", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", typeof (_j = typeof Promise !== "undefined" && Promise) === "function" ? _j : Object)
    ], ImageLibraryDataStore.prototype, "fetchQwilrStockImages", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", typeof (_k = typeof Promise !== "undefined" && Promise) === "function" ? _k : Object)
    ], ImageLibraryDataStore.prototype, "fetchCollections", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_l = typeof Types_4.ImageLibraryFolderId !== "undefined" && Types_4.ImageLibraryFolderId) === "function" ? _l : Object]),
        __metadata("design:returntype", void 0)
    ], ImageLibraryDataStore.prototype, "selectFolder", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_m = typeof Types_3.IILSystemFolderDataStore !== "undefined" && Types_3.IILSystemFolderDataStore) === "function" ? _m : Object),
        __metadata("design:paramtypes", [])
    ], ImageLibraryDataStore.prototype, "selectedSystemFolderDataStore", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ImageLibraryDataStore.prototype, "isCustomFolderSelected", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], ImageLibraryDataStore.prototype, "pageToFetchNext", null);
    return ImageLibraryDataStore;
}());
exports.ImageLibraryDataStore = ImageLibraryDataStore;
