// Generated by CoffeeScript 1.12.7
(function () {
  var MixpanelIntegration;

  module.exports = MixpanelIntegration = (function () {
    function MixpanelIntegration(createUi, genericIntegration) {
      genericIntegration.build.call(this, "mixPanel", "Mixpanel site token", require("./MixPanel.html"));
    }

    return MixpanelIntegration;
  })();
}.call(this));
