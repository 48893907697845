// Generated by CoffeeScript 1.12.7

/*
 * Handles the UI for Xero invoicing settings in Accept
 */

(function () {
  var XeroInvoicer,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = XeroInvoicer = (function () {
    function XeroInvoicer(
      $http,
      $q,
      $log,
      $timeout,
      createUi,
      xero,
      settings,
      project,
      block,
      account,
      apiBaseUrl,
      utils,
      make,
      ActionButton,
    ) {
      this.invoiceHandler = bind(this.invoiceHandler, this);
      this.createInvoice = bind(this.createInvoice, this);
      var invoiceCreateButton, ref, ref1, ref2, ref3;
      this.q = $q;
      this.http = $http;
      this.xero = xero;
      this.timeout = $timeout;
      this.log = $log;
      this.apiBaseUrl = apiBaseUrl;
      this.account = account;
      invoiceCreateButton = make(ActionButton, {
        config: {
          standardIcon: "icon-document2",
          standard: "Create An Invoice Now",
          waiting: "Creating Invoice...",
          success: "Invoice Created",
          error: "Error Creating Invoice",
        },
        onClick: (function (_this) {
          return function () {
            var invoice, ref, ref1;
            invoice = _this.createInvoice(
              block._id,
              (ref = settings.xero) != null ? ref.contactId : void 0,
              project.links[0].secret,
              (ref1 = settings.xero) != null ? ref1.accountCode : void 0,
            );
            return _this.invoiceHandler(invoice);
          };
        })(this),
      });
      if (settings.xero == null) {
        settings.xero = {};
      }
      if (
        ((ref = account.integrations.xero.settings) != null ? ref.defaultAccount : void 0) != null &&
        ((ref1 = settings.xero) != null ? ref1.accountCode : void 0) == null
      ) {
        settings.xero.accountCode = account.integrations.xero.settings.defaultAccount.code;
      }
      if (
        ((ref2 = account.integrations.xero.settings) != null ? ref2.defaultAccount : void 0) != null &&
        ((ref3 = settings.xero) != null ? ref3.accountName : void 0) == null
      ) {
        settings.xero.accountName = account.integrations.xero.settings.defaultAccount.name;
      }
      this.ui = createUi(require("./XeroInvoicer.html"), {
        customers: [],
        accounts: [],
        selectedCustomer: null,
        selectedAccount: null,
        settings: settings,
        invoiceCreateButton: invoiceCreateButton.ui,
        changedSelection: (function (_this) {
          return function () {
            if (_this.ui.selectedAccount != null) {
              settings.xero.accountCode = _this.ui.selectedAccount.Code;
              settings.xero.accountName = _this.ui.selectedAccount.Name;
            }
            if (_this.ui.selectedCustomer != null) {
              settings.xero.contactId = _this.ui.selectedCustomer.ContactID;
              return (settings.xero.contactName = _this.ui.selectedCustomer.Name);
            }
          };
        })(this),
        error: "",
        $init: (function (_this) {
          return function () {
            return _this.xero.getAccountsAndContacts().then(function (response) {
              var accounts, contacts;
              (contacts = response.contacts), (accounts = response.accounts);
              _this.ui.customers = contacts;
              _this.ui.accounts = accounts;
              if (settings.xero.accountCode != null) {
                _this.ui.selectedAccount = _.find(accounts, {
                  Code: settings.xero.accountCode,
                });
              }
              if (settings.xero.contactId != null) {
                return (_this.ui.selectedCustomer = _.find(contacts, {
                  ContactID: settings.xero.contactId,
                }));
              }
            });
          };
        })(this),
      });
    }

    XeroInvoicer.prototype.createInvoice = function (blockId, customer, linkSecret, accountCode) {
      var err;
      err = null;
      if (blockId == null) {
        err = new Error("An error detecting the quote block occurred, please contact help@qwilr.com");
      }
      if (customer == null) {
        err = "No contact specified, please provide a contact for the invoice";
      }
      if (!(linkSecret != null ? linkSecret.length : void 0)) {
        err = new Error("An error with the project occurred, please contact help@qwilr.com");
      }
      if (accountCode == null) {
        err = "No account specified, please provide an account for the invoice line items";
      }
      if (err != null) {
        return this.q.reject(err);
      }
      return this.http.put(this.apiBaseUrl + "/" + this.account._id + "/integration/xero?type=invoices", {
        blockId: blockId,
        linkSecret: linkSecret,
        xero: {
          contactId: customer,
          accountCode: accountCode,
        },
      });
    };

    XeroInvoicer.prototype.invoiceHandler = function (promise) {
      return promise["catch"](
        (function (_this) {
          return function (err) {
            if (err == null) {
              _this.ui.error = "An unknown error occurred, please contact help@qwilr.com";
              _this.log.error(
                "An unknown error occurred in manually pushing invoice to " + integration + " for block " + block._id,
              );
            } else if (err.message != null) {
              _this.ui.error = err.message;
              _this.log.error(err);
            } else {
              _this.ui.error = err;
            }
            _this.timeout(function () {
              return (_this.ui.error = "");
            }, 5000);
            return _this.q.reject(err);
          };
        })(this),
      );
    };

    return XeroInvoicer;
  })();
}.call(this));
