// Generated by CoffeeScript 1.12.7

/*
  This module is a "safety net" for when our content > persistence logic breaks.
  Its purpose is to provide early warnings to a user,
  and to provide debugging clues and data to Qwilr developers
 */

(function () {
  module.exports = function (
    $timeout,
    savingIndicator,
    $log,
    user,
    account,
    project,
    internalSlack,
    make,
    showAcknowledgeModal,
  ) {
    var ACCEPTABLE_LAG_TIME_RADIUS, alertUser, debouncedChecker, getBlockId, safetyNetChecker;
    ACCEPTABLE_LAG_TIME_RADIUS = 3e3;
    alertUser = function () {
      return showAcknowledgeModal({
        title: "Having Trouble Saving...",
        explainer:
          "Our editor is having some trouble saving the content in this block. We'd recommend refreshing your browser. Meantime, our engineering team has been notified, but if the issue continues please do reach out to us via in-app message.",
      });
    };
    getBlockId = function (event) {
      return $(event.target).parents(".top-level-block-wrapper").attr("data-id");
    };
    safetyNetChecker = function (event) {
      return $timeout(function () {
        var difference, errorMessage;
        difference = Math.abs(savingIndicator.lastSaveCall.toString() - Date.now().toString());
        if (difference > ACCEPTABLE_LAG_TIME_RADIUS) {
          alertUser();
          errorMessage =
            "User is inputting from keyboard but nothing is saving\nAccount: " +
            account._id +
            "\nUser: " +
            user._id +
            "\nProject Id: " +
            project._id +
            "\nProject Name: " +
            project.name +
            "\nBlock: " +
            getBlockId(event);
          internalSlack.ping(errorMessage);
          return $log.error(errorMessage);
        }
      }, 1000);
    };
    debouncedChecker = _.debounce(safetyNetChecker, 500);
    return $(".project-content-wrapper").on("keypress", debouncedChecker);
  };
}.call(this));
