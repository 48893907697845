// Generated by CoffeeScript 1.12.7
(function () {
  var ProjectLimit;

  module.exports = function (make, account, projectCounter, projectLiveCounter) {
    return {
      createProjectLiveLimit: function () {
        return make(ProjectLimit, {
          projectCounter: projectLiveCounter,
          getMaxProjects: function (account) {
            if (account.plan.maxLiveProjects != null && account.plan.maxLiveProjects > 0) {
              return account.plan.maxLiveProjects;
            } else {
              return 2e308;
            }
          },
        });
      },
      createProjectLimit: function () {
        return make(ProjectLimit, {
          getMaxProjects: function (account) {
            if (account.plan.maxProjects != null && account.plan.maxProjects > 0) {
              return account.plan.maxProjects;
            } else {
              return 2e308;
            }
          },
        });
      },
    };
  };

  ProjectLimit = (function () {
    function ProjectLimit(account, projectCounter, getMaxProjects) {
      this.hasProjectLimit = (function (_this) {
        return function () {
          return _this.getMaxProjects() > 0 && _this.getMaxProjects() !== 2e308;
        };
      })(this);
      this.getMaxProjects = function () {
        return getMaxProjects(account);
      };
      this.getProjectCount = function () {
        return projectCounter.projectCount;
      };
      this.getRemainingProjects = (function (_this) {
        return function () {
          if (_this.hasProjectLimit()) {
            return Math.max(_this.getMaxProjects() - _this.getProjectCount(), 0);
          } else {
            return 2e308;
          }
        };
      })(this);
      this.getOverLimitAmount = (function (_this) {
        return function () {
          return Math.max(_this.getProjectCount() - _this.getMaxProjects(), 0);
        };
      })(this);
      this.hasHitLimit = (function (_this) {
        return function () {
          return _this.getMaxProjects() !== 0 && _this.getProjectCount() >= _this.getMaxProjects();
        };
      })(this);
    }

    return ProjectLimit;
  })();
}.call(this));
