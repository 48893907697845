// Generated by CoffeeScript 1.12.7

/*
 * Defines functionality for setting up Xero
 */

(function () {
  var XeroSettings,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = XeroSettings = (function () {
    function XeroSettings(createUi, make, xero) {
      var defaultAccount, enableUi, syncSettings, taxMapSettings, xeroAccounts, xeroTaxes;
      this.xero = xero;
      this.disable = bind(this.disable, this);
      this.enable = bind(this.enable, this);
      xeroAccounts = [];
      xeroTaxes = null;
      enableUi = make(require("../OauthEnablingUI"), {
        name: "Xero",
      });
      this.enablingUi = enableUi.ui;
      defaultAccount = make(require("./XeroDefaultAccount"));
      syncSettings = make(require("./XeroSyncSettings"));
      taxMapSettings = make(require("./XeroTaxMapping"));
      this.ui = createUi(require("SettingsAngular/Integrations/Xero/XeroSettings.html"), {
        defaultAccountSettings: defaultAccount.ui,
        syncSettings: syncSettings.ui,
        taxMapSettings: taxMapSettings.ui,
      });
    }

    XeroSettings.prototype.enable = function () {
      return this.xero.enable();
    };

    XeroSettings.prototype.disable = function () {
      return this.xero.disable();
    };

    return XeroSettings;
  })();
}.call(this));
