// Generated by CoffeeScript 1.12.7

/*
  Used to control the messages that are located above the Carousel item
  Messages can be placed on the left/right and center

  @class CarouselMessage
 */

(function () {
  var CarouselMessage;

  module.exports = CarouselMessage = (function () {
    function CarouselMessage(createUi, $q) {
      this.q = $q;
      this.ui = createUi(require("./CarouselMessage.html"), {
        msgLeft: null,
        msgRight: null,
        msgCenter: null,
        show: false,
      });

      /*
        Checks to see whether the message is entirely empty.
      
        @api private
       */
      this.isEmpty = (function (_this) {
        return function () {
          return !_this.ui.msgLeft && !_this.ui.msgRight && !_this.ui.msgCenter;
        };
      })(this);

      /*
        Sets a message in the carousel message block.
      
        @param dom [string] - a string representing some HTML that is to be injected in the message
        @param position [string] - enum, "left", "right" or "center" for where the string should be
                                positioned
        @api public
       */
      this.setMessage = (function (_this) {
        return function (dom, position) {
          if (position == null) {
            position = "center";
          }
          if (position !== "left" && position !== "right" && position !== "center") {
            return _this.q.reject();
          }
          if (position === "left") {
            _this.ui.msgLeft = dom;
          } else if (position === "right") {
            _this.ui.msgRight = dom;
          } else {
            _this.ui.msgCenter = dom;
          }
          return (_this.ui.show = true);
        };
      })(this);

      /*
        Clears the message block.
      
        @param position [string] - optionally we can clear a select area. If this is not passed in,
                                we clear everything
        @api public
       */
      this.clear = (function (_this) {
        return function (position) {
          if (position == null) {
            _this.ui.msgLeft = null;
            _this.ui.msgRight = null;
            _this.ui.msgCenter = null;
            return (_this.ui.show = false);
          } else if (position === "left") {
            _this.ui.msgLeft = null;
            if (_this.isEmpty()) {
              return (_this.ui.show = false);
            }
          } else if (position === "right") {
            _this.ui.msgRight = null;
            if (_this.isEmpty()) {
              return (_this.ui.show = false);
            }
          } else {
            _this.ui.msgCenter = null;
            if (_this.isEmpty()) {
              return (_this.ui.show = false);
            }
          }
        };
      })(this);
    }

    return CarouselMessage;
  })();
}.call(this));
