"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isNonEvenColumnWidths = exports.getEvenWidths = exports.evenlyDistributeColumns = void 0;
var columnWidths = {
    2: [12, 12],
    3: [8, 8, 8],
    4: [6, 6, 6, 6],
    5: [5, 5, 5, 5, 4],
    6: [4, 4, 4, 4, 4, 4],
    7: [4, 4, 4, 3, 3, 3, 3],
    8: [3, 3, 3, 3, 3, 3, 3, 3],
    9: [3, 3, 3, 3, 3, 3, 2, 2, 2],
    10: [3, 3, 3, 3, 2, 2, 2, 2, 2, 2],
    11: [3, 3, 2, 2, 2, 2, 2, 2, 2, 2, 2],
    12: [2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2],
};
var evenlyDistributeColumns = function (numberOfColumns) {
    return columnWidths[numberOfColumns].map(function (width) { return ({ width: width }); });
};
exports.evenlyDistributeColumns = evenlyDistributeColumns;
var getEvenWidths = function (numberOfColumns) { return columnWidths[numberOfColumns]; };
exports.getEvenWidths = getEvenWidths;
var isNonEvenColumnWidths = function (numberOfColumns) { return [5, 7, 9, 10, 11].includes(numberOfColumns); };
exports.isNonEvenColumnWidths = isNonEvenColumnWidths;
