"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAutomationAssignmentExplainer = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var PromptTypes_1 = require("Prompt/PromptTypes");
var styles = __importStar(require("./EnableAutomationModal.css"));
var inversify_react_1 = require("inversify-react");
var useAutomationAssignmentExplainer = function () {
    var modalService = (0, inversify_react_1.useInjection)("modal");
    var inlinePromptsHistoryStore = (0, inversify_react_1.useInjection)("rootDataStore").userDataStore.useHistoryDataStore.inlinePromptsHistoryStore;
    var onConfirmClick = function () {
        window.location.href = "#/templates";
    };
    return {
        potentiallyShowModal: function () {
            if (!inlinePromptsHistoryStore.hasDismissed(PromptTypes_1.PromptId.EnableAutomationExplanation)) {
                modalService.showSimpleModal({
                    isEscapable: true,
                    body: (react_1.default.createElement(EnableAutomationModalBody, { modal: modalService, inlinePromptsHistoryStore: inlinePromptsHistoryStore, onConfirmClick: onConfirmClick })),
                });
            }
        },
    };
};
exports.useAutomationAssignmentExplainer = useAutomationAssignmentExplainer;
var EnableAutomationModalBody = function (_a) {
    var modal = _a.modal, inlinePromptsHistoryStore = _a.inlinePromptsHistoryStore, onConfirmClick = _a.onConfirmClick;
    var _b = __read((0, react_1.useState)(false), 2), shouldHide = _b[0], setShouldHide = _b[1];
    var closeModal = function () {
        if (shouldHide) {
            inlinePromptsHistoryStore.dismissedPrompt(PromptTypes_1.PromptId.EnableAutomationExplanation);
        }
        modal.closeAnyModals();
    };
    var handleConfirmClick = function () {
        onConfirmClick();
        closeModal();
    };
    var handleCancelClick = function () {
        closeModal();
    };
    return (react_1.default.createElement("div", { className: styles.modalWrapper },
        react_1.default.createElement(kaleidoscope_1.Heading, { strong: true, level: "3" }, "Your automation is ready to be assigned to templates"),
        react_1.default.createElement(kaleidoscope_1.Text, { size: "s" },
            "Automations don’t run until they are assigned to at least one template. You can also assign templates from the Templates Library, go to Library > ",
            react_1.default.createElement(kaleidoscope_1.Link, { href: "/#/templates", target: "_blank" }, "Templates")),
        react_1.default.createElement("img", { src: "Assets/Images/integrations/enable-automation-confirmation.png", alt: "Assign to templates" }),
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "l" },
            react_1.default.createElement(kaleidoscope_1.Button, { type: kaleidoscope_1.ButtonType.Secondary, size: kaleidoscope_1.ButtonSize.Large, onClick: handleCancelClick, buttonState: kaleidoscope_1.ButtonState.Idle }, "Do it later"),
            react_1.default.createElement(kaleidoscope_1.Button, { size: kaleidoscope_1.ButtonSize.Large, onClick: handleConfirmClick, buttonState: kaleidoscope_1.ButtonState.Idle }, "Go to templates")),
        react_1.default.createElement(kaleidoscope_1.Checkbox, { id: "dontShowAgainCheckbox", label: "Don't show this message again", checked: shouldHide, onChange: setShouldHide })));
};
