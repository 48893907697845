// Generated by CoffeeScript 1.12.7
(function () {
  var ButtonTemplate, PipedriveButton, SettingsMenu;

  SettingsMenu = require("./PipedriveSettingsMenu");

  ButtonTemplate = require("./PipedriveButton.html");

  /*
    Superpower button that activates the Pipedrive menu
   */

  module.exports = PipedriveButton = (function () {
    function PipedriveButton(createUi, make, Tooltip, block) {
      var settingsMenu, tooltip;
      settingsMenu = make(SettingsMenu);
      tooltip = make(Tooltip, {
        targetSelector: "#" + block.$id + " .pipedrive-settings-button",
        options: {
          container: "#" + block.$id + " .project-block",
          position: "bottom",
          contents: settingsMenu.ui,
          spacing: 7,
          closeOnClickAway: true,
        },
      });
      this.ui = createUi(ButtonTemplate);
      this.className = "pipedrive-settings-button";
      this.onClick = function () {
        return tooltip.show();
      };
      this.onClose = function () {
        return tooltip.hide();
      };
    }

    return PipedriveButton;
  })();
}.call(this));
