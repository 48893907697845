import "Application/Library/EngagementLibrary/AlgorithmEditor/AlgorithmEditor.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/EngagementLibrary/AlgorithmEditor/AlgorithmEditor.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA51Uy3LbMAy8+yswObUHZaS0TVL51Mcxp/oDMjQJSbApUkNCtpxO/70jypLoR9LHRRoCu4sFIOq2YbXdblP4uQCohSvJJBoLzuE+bbrl4tfidkBkAaHIN1occigdqeUCwjthrBstGBNpdVsbP5BBtGwvMc7ufR5y86NHCU2lSYix9jlINIzukiwcCp/DAgDgRpjDF61XqFGydTAcv6OXjhq27mZA7dAxSaGfyCBIaxQxWeOfyPMRIZT6NsbhdghGnd+FzoOPvnwOp3V7k5vWMxWHxKMucvDskGUVSXy4LjF7XZ5PfyfcuyTxjZBkyqTr/PNzttYPm02Wbd5Hyh/PleN2L63Ncx3GfWYYYG2dQnc0kTUdKOErVFFKWm3daDAcEmkNO6u/BsBsNIuNfjo3erqKCHl/MaxoP6+3NPEfAv/olm0z9OGtpj+0sT7zn8b+H4NqobHLIYUMHk+vx+eQrpDKimfVMJYVvWBST7J3vSyAJoPJP+CPtvekuAodRUHPB43537T4xqYmmhOKWj/yhuCPEBvpM7EciEJuS2dbo67V9K0rhMQV9hsX7jCxKZ6vCANshFL9B3/1EkR3YBvqjmgXT/F1OGPHCTthfGFdnYMUDbHQ9BIuyrja9PhHmpyt/8PZIJZBBml/tDt0hbb7HCpSCs3kZk6g1tR48n1qXxFjUMccjN070UR+5PUv8TfGCBBqygUAAA==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var introSentence = 'ptdkkk0';
export var decisionNodeGrid = 'ptdkkk1';
export var anyAllSelector = 'ptdkkk2';
export var anyAllDescriptor = 'ptdkkk3';
export var dottedVerticalLine = 'ptdkkk4';
export var conditionsList = 'ptdkkk5';
export var addCondition = 'ptdkkk6';
export var horizontalDivider = 'ptdkkk7';
export var timeValueInput = 'ptdkkk8';
export var timeUnits = 'ptdkkka ptdkkk9';
export var conditionDescriptor = 'ptdkkkb ptdkkk9';
export var thresholdInput = 'ptdkkkc';