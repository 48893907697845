"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MultiAccepterForm = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var MappingTable_1 = require("./MappingTable");
var index_1 = require("./index");
var styles = __importStar(require("./MultiAccepterForm.css"));
var MAX_ACCEPTERS = 5;
var MultiAccepterForm = function (_a) {
    var accepters = _a.accepters, onChange = _a.onChange, integrationData = _a.integrationData;
    var _b = __read((0, react_1.useState)(0), 2), selectedIndex = _b[0], setSelectedIndex = _b[1];
    var getMappedTokens = function () {
        var e_1, _a, e_2, _b;
        var mappedTokenNames = new Set();
        try {
            for (var accepters_1 = __values(accepters), accepters_1_1 = accepters_1.next(); !accepters_1_1.done; accepters_1_1 = accepters_1.next()) {
                var acc = accepters_1_1.value;
                try {
                    for (var _c = (e_2 = void 0, __values(acc.fields)), _d = _c.next(); !_d.done; _d = _c.next()) {
                        var field = _d.value;
                        if (!!field.to) {
                            mappedTokenNames.add(field.to.apiName);
                        }
                    }
                }
                catch (e_2_1) { e_2 = { error: e_2_1 }; }
                finally {
                    try {
                        if (_d && !_d.done && (_b = _c.return)) _b.call(_c);
                    }
                    finally { if (e_2) throw e_2.error; }
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (accepters_1_1 && !accepters_1_1.done && (_a = accepters_1.return)) _a.call(accepters_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return __spreadArray([], __read(mappedTokenNames), false);
    };
    var addAccepter = function () {
        var currentAccepter = accepters[selectedIndex];
        if (currentAccepter) {
            onChange(__spreadArray(__spreadArray([], __read(accepters), false), [(0, index_1.createDuplicateAccepter)(currentAccepter)], false));
            setSelectedIndex(accepters.length);
        }
    };
    var deleteSelectedAccepter = function () {
        if (selectedIndex > 0) {
            setSelectedIndex(selectedIndex - 1);
        }
        onChange(__spreadArray(__spreadArray([], __read(accepters.slice(0, selectedIndex)), false), __read(accepters.slice(selectedIndex + 1)), false));
    };
    var setAccepterFields = function (accepter, fields) {
        onChange(accepters.map(function (acc) { return (acc.guid === accepter.guid ? __assign(__assign({}, accepter), { fields: fields }) : acc); }));
    };
    (0, react_1.useEffect)(function () {
        if (selectedIndex >= accepters.length) {
            setSelectedIndex(0);
        }
    }, [selectedIndex, accepters.length]);
    var itemsRef = (0, react_1.useRef)([]);
    var mappedTokenNames = getMappedTokens();
    var onTabButtonClick = function (index) {
        if (itemsRef.current && itemsRef.current[index] !== null) {
            itemsRef.current[index].click();
        }
    };
    return (react_1.default.createElement("div", { className: styles.wrapper },
        react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.multiAccepterForm },
            react_1.default.createElement(kaleidoscope_1.Tabs, { selectedIndex: selectedIndex, onChange: setSelectedIndex, className: styles.tabsWrapper },
                react_1.default.createElement("div", { className: styles.accepterTabs },
                    react_1.default.createElement("div", { className: styles.tabWrapper },
                        react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.ChevronLeft, null), size: kaleidoscope_1.ButtonSize.Small, disabled: selectedIndex <= 0, onClick: function () { return onTabButtonClick(selectedIndex - 1); }, "aria-label": "Previous Accepter" }),
                        react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "s", className: styles.selectedAccepter },
                            "Accepter ",
                            String.fromCharCode(selectedIndex + "A".charCodeAt(0))),
                        react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.ChevronRight, null), size: kaleidoscope_1.ButtonSize.Small, disabled: selectedIndex >= accepters.length - 1, onClick: function () { return onTabButtonClick(selectedIndex + 1); }, "aria-label": "Next Accepter" })),
                    react_1.default.createElement(kaleidoscope_1.OptionMenu, { size: "medium", button: react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.OptionsVertical, null), size: kaleidoscope_1.ButtonSize.Small }), autoStack: true },
                        react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { icon: react_1.default.createElement(icons_1.AddUser, null), disabled: accepters.length >= MAX_ACCEPTERS, onClick: function () { return addAccepter(); } }, "Add accepter"),
                        react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { icon: react_1.default.createElement(icons_1.RemoveUser, null), disabled: accepters.length <= 1, onClick: function () { return deleteSelectedAccepter(); } }, "Remove accepter"))),
                react_1.default.createElement(kaleidoscope_1.TabList, { "aria-label": "Style options", className: styles.hiddenTabs }, accepters.map(function (accepter, index) { return (react_1.default.createElement(kaleidoscope_1.Tab, { key: index },
                    react_1.default.createElement("div", { ref: function (el) {
                            if (itemsRef.current)
                                itemsRef.current[index] = el;
                        } },
                        "Tab for index ",
                        index))); })),
                react_1.default.createElement(kaleidoscope_1.TabPanels, null, accepters.map(function (accepter) { return (react_1.default.createElement(kaleidoscope_1.TabPanel, { key: accepter.guid },
                    react_1.default.createElement(MappingTable_1.MappingTable, { fields: accepter.fields, mappedTokenNames: mappedTokenNames, onChange: function (fields) { return setAccepterFields(accepter, fields); }, integrationData: integrationData }))); }))))));
};
exports.MultiAccepterForm = MultiAccepterForm;
