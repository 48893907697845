// Generated by CoffeeScript 1.12.7
(function () {
  var FacebookIntegration;

  module.exports = FacebookIntegration = (function () {
    function FacebookIntegration(createUi, genericIntegration) {
      genericIntegration.build.call(this, "facebook", "Facebook Pixel ID", require("./Facebook.html"));
    }

    return FacebookIntegration;
  })();
}.call(this));
