// Generated by CoffeeScript 1.12.7
(function () {
  var SlackIntegration,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = SlackIntegration = (function () {
    function SlackIntegration(createUi, settings, $http, $timeout, account, apiBaseUrl, $q, notificationTypes) {
      var activatedTypes, i, len, type;
      this.account = account;
      this.apiBaseUrl = apiBaseUrl;
      this.update = bind(this.update, this);
      this.disable = bind(this.disable, this);
      this.enable = bind(this.enable, this);
      this.isEnabled = bind(this.isEnabled, this);
      this.http = $http;
      this.q = $q;
      if (settings.notificationTypes == null) {
        settings.notificationTypes = {};
      }
      activatedTypes = {};
      for (i = 0, len = notificationTypes.length; i < len; i++) {
        type = notificationTypes[i];
        activatedTypes[type.eventName] = settings.notificationTypes[type.eventName] || false;
      }
      this.ui = createUi(require("./Slack.html"), {
        success: null,
        error: null,
        settings: settings,
        types: notificationTypes,
        activatedTypes: activatedTypes,
        setTypeState: (function (_this) {
          return function (type, typeState) {
            settings.notificationTypes[type] = typeState;
            return _this.update(settings);
          };
        })(this),
      });
    }

    SlackIntegration.prototype.isEnabled = function () {
      var ref, ref1;
      return (ref = this.account.integrations) != null ? ((ref1 = ref.slack) != null ? ref1.enabled : void 0) : void 0;
    };

    SlackIntegration.prototype.enable = function () {
      var callbackUrl, enabling, left, popupWindow, top;
      enabling = this.q.defer();
      callbackUrl = this.apiBaseUrl + "/integration/slack/callback";
      left = screen.width / 2 - 450;
      top = screen.height / 2 - 325;
      popupWindow = (function (_this) {
        return function () {
          var polling, win;
          win = window.open(
            _this.apiBaseUrl + "/integration/slack/authorize",
            "_blank",
            "top=" + top + ",left=" + left + ",width=900,height=650",
          );
          return (polling = window.setInterval(function () {
            var e, url;
            try {
              if (win.QWILR_SUCCESS) {
                window.clearInterval(polling);
                url = win.document.URL;
                enabling.resolve(url.split("?")[1]);
                return win.close();
              }
            } catch (error) {
              e = error;
            }
          }, 100));
        };
      })(this);
      this.popup = popupWindow();
      return enabling.promise
        .then(
          (function (_this) {
            return function (queryParams) {
              return _this.http.get(callbackUrl + "?" + queryParams);
            };
          })(this),
        )
        .then(
          (function (_this) {
            return function (results) {
              var base;
              if ((base = _this.account.integrations).slack == null) {
                base.slack = {};
              }
              _this.account.integrations.slack.enabled = true;
              return (_this.account.integrations.slack.settings = _this.ui.settings = results.data.message);
            };
          })(this),
        );
    };

    SlackIntegration.prototype.disable = function () {
      return this.http.post(this.apiBaseUrl + "/integration/slack/disable").then(
        (function (_this) {
          return function () {
            var base;
            if ((base = _this.account.integrations).slack == null) {
              base.slack = {};
            }
            _this.account.integrations.slack.enabled = false;
            return (_this.account.integrations.slack.settings = {});
          };
        })(this),
      );
    };

    SlackIntegration.prototype.update = function (settings) {
      return this.http.post(this.apiBaseUrl + "/integration/slack/update", settings).then(
        (function (_this) {
          return function () {
            var base;
            if ((base = _this.account.integrations).slack == null) {
              base.slack = {};
            }
            return (_this.account.integrations.slack.settings = settings);
          };
        })(this),
      );
    };

    return SlackIntegration;
  })();
}.call(this));
