(function () {
  module.exports = angular.module("qwilr.trackingDirective", []).directive("qtrack", function ($rootScope) {
    return {
      restrict: "A",
      link: function (scope, element, attrs) {
        var alreadyTracked, telemetry, trackOnce;
        trackOnce = attrs.trackOnceOnly != null;
        if (trackOnce) {
          alreadyTracked = false;
        }
        telemetry = null;
        return element.click(function () {
          var e, eventModifiers, eventProperties;
          if (telemetry == null) {
            telemetry = $rootScope.di.get("telemetry");
          }
          try {
            if (attrs.trackProperties != null) {
              eventProperties = JSON.parse(attrs.trackProperties);
            }
            if (attrs.trackModifiers != null) {
              eventModifiers = JSON.parse(attrs.trackModifiers);
            }
          } catch (error) {
            e = error;
          }
          if (eventProperties == null) {
            eventProperties = {};
          }
          if (eventModifiers == null) {
            eventModifiers = {};
          }
          if (trackOnce) {
            if (alreadyTracked) {
              return;
            }
            alreadyTracked = true;
          }
          telemetry.trackOnly(attrs.trackEvent, eventProperties, eventModifiers);
          if (attrs["trackVirtualpage"] != null) {
            telemetry.pageHit(attrs["trackVirtualpage"]);
          }
          return true;
        });
      },
    };
  });
}.call(this));
