"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tour = exports.TourStepConfigurationType = void 0;
var TourStepConfigurationType;
(function (TourStepConfigurationType) {
    TourStepConfigurationType["Regular"] = "regular";
    TourStepConfigurationType["Completion"] = "completion";
    TourStepConfigurationType["Welcome"] = "welcome";
})(TourStepConfigurationType || (exports.TourStepConfigurationType = TourStepConfigurationType = {}));
var Tour = (function () {
    function Tour() {
    }
    Object.defineProperty(Tour.prototype, "enabled", {
        get: function () {
            return true;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tour.prototype, "priority", {
        get: function () {
            return {
                order: 0,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(Tour.prototype, "defaultStartingStep", {
        get: function () {
            return undefined;
        },
        enumerable: false,
        configurable: true
    });
    Tour.prototype.onTourStart = function () { };
    Tour.prototype.stepById = function (stepId) {
        return this.steps.find(function (s) { return s.step === stepId; });
    };
    return Tour;
}());
exports.Tour = Tour;
