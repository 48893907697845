// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (make, dropdown, simpleDropdownFactory) {
    return {
      makeAccountPermissionDropdown: function (accountTeam) {
        var initialSelection, items;
        items = [
          {
            name: "Can View",
            id: "view",
          },
          {
            name: "Can Edit",
            id: "edit",
          },
        ];
        initialSelection = "view";
        if (accountTeam.permission && accountTeam.permission.action !== "none") {
          initialSelection = accountTeam.permission.action;
        }
        return simpleDropdownFactory.create(
          items,
          {
            id: initialSelection,
          },
          function (item) {
            if (accountTeam.permission == null) {
              accountTeam.permission = {};
            }
            accountTeam.permission.action = item.id;
            return accountTeam.permission.action;
          },
        );
      },
      makeUserPermissionsDropdown: function (user, project) {
        var initialSelection, items;
        items = [
          {
            name: "Can View",
            id: "view",
          },
          {
            name: "Can Edit",
            id: "edit",
          },
        ];
        if (project.user === user._id) {
          items.unshift({
            name: "Is Owner",
            id: "all",
          });
        }
        initialSelection = "view";
        if (user.permission && user.permission.action !== "none") {
          initialSelection = user.permission.action;
        }
        if (user.isAdmin) {
          initialSelection = "edit";
        }
        return simpleDropdownFactory.create(
          items,
          {
            id: initialSelection,
          },
          function (item) {
            user.permission.action = item.id;
            return user.permission.action;
          },
        );
      },
      makeTeamPermissionsDropdown: function (team) {
        var initialSelection, items;
        items = [
          {
            name: "Can View",
            id: "view",
          },
          {
            name: "Can Edit",
            id: "edit",
          },
        ];
        initialSelection = "view";
        if (team.permission && team.permission.action !== "none") {
          initialSelection = team.permission.action;
        }
        return simpleDropdownFactory.create(
          items,
          {
            id: initialSelection,
          },
          function (item) {
            team.permission.action = item.id;
            return team.permission.action;
          },
        );
      },
      extendPermissionsWithOriginalAccess: function (permissions) {
        return permissions.map(function (permission) {
          return _.extend(permission, {
            originalAction: permission.action,
          });
        });
      },
      filterList: function (list, searchTerm) {
        return _.filter(list, function (member) {
          return !searchTerm || member.name.match(new RegExp("\\b" + searchTerm, "i"));
        });
      },
    };
  };
}.call(this));
