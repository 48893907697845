"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VIDEOS = void 0;
exports.VIDEOS = [
    "3641.mp4",
    "4099.mp4",
    "4703.mp4",
    "1409899.mp4",
    "1416529.mp4",
    "1536350.mp4",
    "1739010.mp4",
    "1826676.mp4",
    "islands.mp4",
    "mountains.mp4",
    "Agua-natural.mp4",
    "Airtaxi.mp4",
    "Aloha-Mundo.mp4",
    "Android-Scroll.mp4",
    "Backyard.mp4",
    "Beach-Ball.mp4",
    "Beach-please.mp4",
    "Big-City-Life.mp4",
    "Billowing.mp4",
    "Bull-Creek.mp4",
    "Coverr-lamp.mp4",
    "Dancing-Bulbs.mp4",
    "Duck-Duck-Go.mp4",
    "Fish-Tank.mp4",
    "Flow.mp4",
    "For_Wes.mp4",
    "Free-Cows.mp4",
    "Going-Places.mp4",
    "Green.mp4",
    "Head-or-Tails.mp4",
    "Holy-Cow.mp4",
    "In-The-Clouds.mp4",
    "Into-The-Woods.mp4",
    "it-was-all-yellow.mp4",
    "Mock-up.mp4",
    "Moving-Forward.mp4",
    "Night-Traffic.mp4",
    "NYC-Traffic.mp4",
    "One-Swan.mp4",
    "PC-Typing.mp4",
    "Puddle.mp4",
    "Rush-Hour.mp4",
    "Sail-Away.mp4",
    "Strum-Away.mp4",
    "The-Boulevard.mp4",
    "Typer.mp4",
    "Typing-numbers.mp4",
    "Wall-Sketching.mp4",
    "White-Board.mp4",
    "Winter-Grass.mp4",
    "leaves-street-autumn.mp4",
    "night-street-timelapse.mp4",
    "river-stones.mp4",
    "river.mp4",
    "still-lake.mp4",
    "nightscape.mp4",
    "adventure-trip-481.mp4",
    "ants-trees-forest-502.mp4",
    "autumn-creek-425.mp4",
    "blurred-tree-433.mp4",
    "branches-of-a-tree-469.mp4",
    "cars-driving-on-street-with-leafs-462.mp4",
    "coffee-at-the-morning-466.mp4",
    "coffee-machine-in-cafe-405.mp4",
    "colorful-reflections-in-water-406.mp4",
    "couple-drinks-hot-coffee-in-the-morning-325.mp4",
    "deer-eating-leaves-446.mp4",
    "dog-walking-through-a-forest-558.mp4",
    "drinking-beer-450.mp4",
    "driving-boat-on-sea-391.mp4",
    "ferry-on-a-small-lake-444.mp4",
    "ferry-passing-by-on-river-480.mp4",
    "filling-a-cup-with-water-in-slow-motion-545.mp4",
    "filling-glass-with-beer-452.mp4",
    "forest-443.mp4",
    "forest-sky-cloudy-sun-422.mp4",
    "hiking-and-driving-550.mp4",
    "jump-into-water-521.mp4",
    "kayaks-414.mp4",
    "lake-and-mountain-440.mp4",
    "leafs-tree-wind-autumn-385.mp4",
    "leaves-on-asphalt-windy-461.mp4",
    "lights-along-a-river-468.mp4",
    "look-to-the-sky-in-a-forest-445.mp4",
    "looking-up-at-forest-447.mp4",
    "maple-leaf-autumn-426.mp4",
    "mountains-desert-camels-asia-496.mp4",
    "mystic-landscapes-505.mp4",
    "orange-sky-448.mp4",
    "panorama-of-island-with-blue-water-and-beach-392.mp4",
    "pine-trees-forest-landscape-424.mp4",
    "rainy-weather-raindrops-386.mp4",
    "river-503.mp4",
    "river-504.mp4",
    "sailboats-at-port-417.mp4",
    "sailing-boat-on-sea-413.mp4",
    "starry-sky-timelapse-472.mp4",
    "still-lake-441.mp4",
    "stones-waves-water-489.mp4",
    "stones-waves-water-490.mp4",
    "swinging-light-bulb-463.mp4",
    "timelapse-of-mountains-501.mp4",
    "timelapse-of-mountains-and-the-sky-495.mp4",
    "timelapse-of-stockholm-at-night-539.mp4",
    "timelapse-video-at-a-lake-435.mp4",
    "tourists-looking-at-niagara-falls-333.mp4",
    "trip-to-the-mountains-526.mp4",
    "trip-with-dogs-523.mp4",
    "water-flowing-329.mp4",
    "water-flowing-fountain-334.mp4",
    "water-flowing-in-drain-434.mp4",
    "water-flowing-out-of-gutter-393.mp4",
    "water-reflections-haze-407.mp4",
    "water-sea-ocean-388.mp4",
    "water-wet-rocks-428.mp4",
    "waterfall-stream-nature-423.mp4",
    "wet-leaves-on-street-rainy-394.mp4",
    "woman-drinking-coffee-and-talking-with-her-boyfriend-331.mp4",
];
