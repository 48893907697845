"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var template = require("./LineItemButtons.html");
var LineItemButtons = (function () {
    function LineItemButtons(createUi, savedLineItemsLibrary, project, insertSavedLineItems, createLineItemByType, projectStore) {
        var _this = this;
        this.insertSaveLineItems = function () {
            _this.savedLineItemsLibrary.open(_this.project.currency).then(function (itemsToInsert) {
                _this.insertSavedLineItems(itemsToInsert);
            });
        };
        this.createUiComponent = function () {
            return _this.createUi(template, {
                insertSaveLineItems: _this.insertSaveLineItems,
                createLineItemByType: _this.createLineItemByType,
                hasEditAccess: _this.projectStore.isEditable,
            });
        };
        this.savedLineItemsLibrary = savedLineItemsLibrary;
        this.project = project;
        this.insertSavedLineItems = insertSavedLineItems;
        this.createLineItemByType = createLineItemByType;
        this.createUi = createUi;
        this.projectStore = projectStore;
        this.ui = this.createUiComponent();
    }
    return LineItemButtons;
}());
exports.default = LineItemButtons;
