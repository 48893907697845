"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getNodeMetadataFromChildren = exports.getPrunedEditorState = void 0;
var slate_1 = require("slate");
var getNodeMetadataFromChildren = function (nodes) {
    var recursedNodes = nodes.map(function (node) {
        if (slate_1.Editor.isEditor(node)) {
            return {
                type: "editor",
                children: getNodeMetadataFromChildren(node.children),
            };
        }
        else if (slate_1.Text.isText(node)) {
            return {
                type: "text",
            };
        }
        else if (slate_1.Element.isElement(node)) {
            var recursedNode = {
                type: node.type,
                children: getNodeMetadataFromChildren(node.children),
            };
            return recursedNode;
        }
        else {
            return {};
        }
    });
    return recursedNodes;
};
exports.getNodeMetadataFromChildren = getNodeMetadataFromChildren;
var getPrunedEditorState = function (editor) {
    var children = getNodeMetadataFromChildren(editor.children.slice(0, 200));
    return {
        children: children,
        selection: editor.selection,
        numberOfTopLevelChildren: editor.children.length,
    };
};
exports.getPrunedEditorState = getPrunedEditorState;
