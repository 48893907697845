// Generated by CoffeeScript 1.12.7
(function () {
  var SetDefaultAccessController;

  module.exports = SetDefaultAccessController = (function () {
    function SetDefaultAccessController(createUi, $q, AsyncButton, account, accountService, RadioButtons) {
      var getAccountAccess, onClick, selectedAccessType, updateButton;
      selectedAccessType = null;
      getAccountAccess = function () {
        var ref, ref1;
        return (
          ((ref = account.settings) != null
            ? (ref1 = ref.permissions) != null
              ? ref1.defaultProjectAccess
              : void 0
            : void 0) || "none"
        );
      };
      onClick = (function (_this) {
        return function () {
          _this.ui.error = "";
          return accountService
            .setDefaultProjectAccess(selectedAccessType)
            .then(function (result) {
              _this.ui.error = "";
              return (account.settings.permissions.defaultProjectAccess = selectedAccessType);
            })
            ["catch"](function (response) {
              var error, ref, ref1;
              error =
                (response != null
                  ? (ref = response.data) != null
                    ? (ref1 = ref.error) != null
                      ? ref1.message
                      : void 0
                    : void 0
                  : void 0) ||
                "An error has occurred while trying to update your company details, please try again later.";
              _this.ui.error = error;
              return $q.reject(error);
            });
        };
      })(this);
      updateButton = new AsyncButton(onClick, "Update defaults");
      this.ui = createUi(require("./SetDefaultAccess.html"), {
        updateButton: updateButton.ui,
        radioButtons: new RadioButtons(
          [
            {
              id: "none",
              label: "Pages are private by default",
            },
            {
              id: "view",
              label: "Pages can be viewed by everyone",
            },
            {
              id: "edit",
              label: "Pages can be be edited by everyone",
            },
          ],
          function (arg) {
            var id;
            id = arg.id;
            if (id === getAccountAccess()) {
              selectedAccessType = null;
              if (updateButton.state === "idle") {
                return (updateButton.state = "disabled");
              }
            } else {
              selectedAccessType = id;
              if (updateButton.state === "disabled") {
                return (updateButton.state = "idle");
              }
            }
          },
          getAccountAccess(),
        ),
      });
    }

    return SetDefaultAccessController;
  })();
}.call(this));
