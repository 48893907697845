// Generated by CoffeeScript 1.12.7
(function () {
  var ActionsCtrl,
    Item,
    ItemTemplate,
    RateController,
    SliderController,
    TextItemTemplate,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  SliderController = require("./Slider/Slider");

  RateController = require("./Rate/Rate");

  ActionsCtrl = require("./Actions/Actions");

  TextItemTemplate = require("./TextItem.html");

  ItemTemplate = require("./Item.html");

  module.exports = Item = (function () {
    function Item(
      di,
      createUi,
      item,
      section,
      viewState,
      account,
      block,
      itemViewState,
      itemService,
      currency,
      undoNotifier,
      defaultRate,
      projectScrollTo,
      $timeout,
    ) {
      var make, template;
      this.projectScrollTo = projectScrollTo;
      this.scrollToMe = bind(this.scrollToMe, this);
      this.getElement = bind(this.getElement, this);
      this.item = item;
      di = di.child();
      make = di.make;
      if (item.type === "text") {
        template = TextItemTemplate;
      } else {
        template = ItemTemplate;
      }
      this.ui = createUi(template, {
        item: item,
        viewState: itemViewState,
        actions: null,
        deleteItem: function () {
          return itemService.deleteItem(section, item);
        },
        ensureBounds: itemService.ensureBounds,
        $onElementRendered: (function (_this) {
          return function (element, property) {
            if (property === "itemElement" && item.$scrollTo) {
              return $timeout(_this.scrollToMe, 50);
            }
          };
        })(this),
        showActions: null,
        toggleActions: (function (_this) {
          return function () {
            var actions;
            if (_this.ui.showActions != null) {
              return (_this.ui.showActions = !_this.ui.showActions);
            } else {
              actions = make(ActionsCtrl, {
                item: item,
                closeActionsPanel: function () {
                  return (_this.ui.showActions = false);
                },
              });
              _this.ui.actions = actions.ui;
              return (_this.ui.showActions = true);
            }
          };
        })(this),
      });
      if (item.type !== "text") {
        this.ui.rate = make(RateController, {
          item: item,
        }).ui;
        this.ui.slider = make(SliderController, {
          item: item,
        }).ui;
        this.ui.currency = currency;
      }
    }

    Item.prototype.getElement = function () {
      return $("#quote-item-" + this.item.$id);
    };

    Item.prototype.scrollToMe = function () {
      var element;
      element = this.getElement();
      this.projectScrollTo(element, {
        offset: 150,
      });
      return element.find("textarea.item-name").trigger("focus");
    };

    return Item;
  })();
}.call(this));
