"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var Types_1 = require("@CommonFrontendBackend/Permissions/Types");
var PendingInviteRequestModal_1 = require("./PendingInviteRequestModal");
var DeleteInviteRequestModal_1 = require("./DeleteInviteRequestModal");
var ContactSupportForUsersModal_1 = require("../../Common/ContactSupportForUsersModal/ContactSupportForUsersModal");
var luxon_1 = require("luxon");
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var InvitationRequestService = (function () {
    function InvitationRequestService(modal, $http, apiBaseUrl, account, rootDataStore, refreshUi, seatsService, serverTelemetry) {
        this.modal = modal;
        this.$http = $http;
        this.apiBaseUrl = apiBaseUrl;
        this.account = account;
        this.rootDataStore = rootDataStore;
        this.refreshUi = refreshUi;
        this.seatsService = seatsService;
        this.serverTelemetry = serverTelemetry;
    }
    InvitationRequestService.prototype.acceptRequest = function (invitation) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, refreshUi, $http, apiBaseUrl, account, rootDataStore, serverTelemetry, response, _b, additionalSeats, pendingInvitations, error_1;
            var _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _a = this, refreshUi = _a.refreshUi, $http = _a.$http, apiBaseUrl = _a.apiBaseUrl, account = _a.account, rootDataStore = _a.rootDataStore, serverTelemetry = _a.serverTelemetry;
                        _d.label = 1;
                    case 1:
                        _d.trys.push([1, 5, , 9]);
                        return [4, $http.post("".concat(apiBaseUrl, "/accounts/").concat(account._id, "/invites/invite-with-seats"), {
                                invites: [
                                    {
                                        email: invitation.email,
                                        role: Types_1.Role.Creator,
                                        permission: invitation.permission,
                                    },
                                ],
                            })];
                    case 2:
                        response = _d.sent();
                        return [4, this.deleteRequest(invitation, false)];
                    case 3:
                        _d.sent();
                        _b = response.data.message, additionalSeats = _b.additionalSeats, pendingInvitations = _b.pendingInvitations;
                        account.pendingInvitations = pendingInvitations;
                        account.additionalSeats = additionalSeats;
                        return [4, rootDataStore.getAccountDataStore().getPendingUsersDataStore().refreshPendingUsers()];
                    case 4:
                        _d.sent();
                        serverTelemetry.sendUserInvitedTelemetry({
                            eventProperties: {
                                planPendingInvites: account.pendingInvitations.length,
                                userInviteeType: Types_1.Role.Creator,
                                inviteRequestAge: this.getInvitationAge(new Date(invitation.createdAt)),
                            },
                            eventOrigin: event_tracker_types_1.EventOrigin.QwilrInviteRequest,
                        });
                        refreshUi();
                        return [3, 9];
                    case 5:
                        error_1 = _d.sent();
                        if (!(((_c = error_1.data) === null || _c === void 0 ? void 0 : _c.error.message) == "The email '".concat(invitation.email, "' matches an existing Qwilr user"))) return [3, 7];
                        return [4, this.deleteRequest(invitation, false)];
                    case 6:
                        _d.sent();
                        refreshUi();
                        return [3, 8];
                    case 7: throw error_1;
                    case 8: return [3, 9];
                    case 9: return [2];
                }
            });
        });
    };
    InvitationRequestService.prototype.inviteRequestWithModal = function (invitation) {
        return __awaiter(this, void 0, void 0, function () {
            var seatsAvailable, approveModal, seatPrice, isTrial, timeUnit;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        seatsAvailable = this.seatsService.remainingSeats();
                        if (!(!this.seatsService.canModifySeats() && seatsAvailable <= 0)) return [3, 1];
                        new ContactSupportForUsersModal_1.ContactSupportForUsersModal(this.modal).showModal(1);
                        return [3, 3];
                    case 1:
                        approveModal = new PendingInviteRequestModal_1.PendingInviteRequestModal(this.modal);
                        seatPrice = this.seatsService.seatPrice();
                        isTrial = this.account.plan.displayName === "Trial";
                        timeUnit = this.seatsService.timeUnit();
                        return [4, approveModal.showModal(invitation, seatsAvailable, seatPrice, timeUnit, isTrial, this.acceptRequest.bind(this))];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2];
                }
            });
        });
    };
    InvitationRequestService.prototype.deleteRequestWithModal = function (invitation) {
        return __awaiter(this, void 0, void 0, function () {
            var deleteModal;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        deleteModal = new DeleteInviteRequestModal_1.DeleteInviteRequestModal(this.modal);
                        return [4, deleteModal.showModal(invitation, this.deleteRequest.bind(this))];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    InvitationRequestService.prototype.deleteRequest = function (invitation_1) {
        return __awaiter(this, arguments, void 0, function (invitation, deleteFlow) {
            var _a, refreshUi, rootDataStore, account, serverTelemetry, error_2;
            if (deleteFlow === void 0) { deleteFlow = true; }
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _b.trys.push([0, 2, , 3]);
                        _a = this, refreshUi = _a.refreshUi, rootDataStore = _a.rootDataStore, account = _a.account, serverTelemetry = _a.serverTelemetry;
                        account.pendingRequests = account.pendingRequests.filter(function (pendingReq) { return pendingReq._id !== invitation._id; });
                        return [4, rootDataStore.getAccountDataStore().update({ pendingRequests: account.pendingRequests })];
                    case 1:
                        _b.sent();
                        if (deleteFlow) {
                            serverTelemetry.sendInviteRequestDeletedTelemetry({
                                eventProperties: {
                                    planPendingInvites: account.pendingRequests.length,
                                    userInviteeType: Types_1.Role.Creator,
                                    inviteRequestAge: this.getInvitationAge(new Date(invitation.createdAt)),
                                },
                            });
                        }
                        refreshUi();
                        return [3, 3];
                    case 2:
                        error_2 = _b.sent();
                        throw error_2;
                    case 3: return [2];
                }
            });
        });
    };
    InvitationRequestService.prototype.getInvitationAge = function (invitationDate) {
        var invitationDateTime = luxon_1.DateTime.fromJSDate(invitationDate);
        var age = Math.round(luxon_1.DateTime.now().diff(invitationDateTime, "minutes").minutes);
        return age;
    };
    return InvitationRequestService;
}());
exports.default = InvitationRequestService;
