"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dropHandler = void 0;
var PreventHandledEvent_1 = require("./PreventHandledEvent");
var dropHandler = function (onDrop, editor) {
    return (0, PreventHandledEvent_1.preventHandledEvent)(function (event) {
        return onDrop(event, editor);
    });
};
exports.dropHandler = dropHandler;
