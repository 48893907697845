// Generated by CoffeeScript 1.12.7

/*
  Handles key events for BlockQuote elements
 */

(function () {
  var BlockQuoteHandler, backspaceHandler, enterHandler, h;

  h = require("../Helpers");

  backspaceHandler = require("./Backspace");

  enterHandler = require("./GenericEnterKeyHandler")("BLOCKQUOTE");

  module.exports = BlockQuoteHandler = (function () {
    function BlockQuoteHandler(editor, undo) {
      this.onKey = function (e) {
        if (e.keyCode === 13) {
          enterHandler(editor, e);
          return true;
        }
        if (backspaceHandler(editor, "P", e)) {
          return true;
        }
        return false;
      };
    }

    return BlockQuoteHandler;
  })();
}.call(this));
