"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasOwnProperty = exports.getParamsFromAngularUrl = void 0;
function getParamsFromAngularUrl(url) {
    var paramsString = url.split("?")[1];
    if (paramsString) {
        return new URLSearchParams(paramsString);
    }
    return new URLSearchParams();
}
exports.getParamsFromAngularUrl = getParamsFromAngularUrl;
function hasOwnProperty(obj, prop) {
    return Object.hasOwnProperty.call(obj, prop);
}
exports.hasOwnProperty = hasOwnProperty;
