// Generated by CoffeeScript 1.12.7
(function () {
  var OlarkIntegration;

  module.exports = OlarkIntegration = (function () {
    function OlarkIntegration(createUi, genericIntegration) {
      genericIntegration.build.call(this, "olark", "Olark Site ID", require("./Olark.html"));
    }

    return OlarkIntegration;
  })();
}.call(this));
