// Generated by CoffeeScript 1.12.7
(function () {
  var TableController;

  module.exports = TableController = (function () {
    function TableController(
      make,
      createUi,
      model,
      GridPicker,
      ContextMenu,
      $timeout,
      $rootScope,
      Editor,
      EditorModel,
      undo,
      triggerContentEvent,
    ) {
      var ENTER_KEY,
        TAB_KEY,
        TableEditorModel,
        contextButtons,
        createCellEditor,
        createEditors,
        draggingHandle,
        draggingIdx,
        gridPicker,
        handlePositions,
        range,
        startingCellValue,
        tableMenu,
        updateEditors;
      ENTER_KEY = 13;
      TAB_KEY = 9;
      draggingIdx = null;
      draggingHandle = null;
      handlePositions = model.colOffsets ? model.colOffsets.slice(0) : [];
      startingCellValue = function () {
        return {
          content: "<p><br></p>",
        };
      };
      range = function (num) {
        var i, results;
        return function () {
          results = [];
          for (var i = 0; 0 <= num ? i < num : i > num; 0 <= num ? i++ : i--) {
            results.push(i);
          }
          return results;
        }.apply(this);
      };
      gridPicker = make(GridPicker, {
        onSelectedCallback: (function (_this) {
          return function (selection) {
            var col, evenColSize, i, ref, results;
            model.table = _.map(
              function () {
                results = [];
                for (var i = 0, ref = selection.rows - 1; 0 <= ref ? i <= ref : i >= ref; 0 <= ref ? i++ : i--) {
                  results.push(i);
                }
                return results;
              }.apply(this),
              function () {
                var j, ref1, results1;
                return _.map(
                  function () {
                    results1 = [];
                    for (
                      var j = 0, ref1 = selection.cols - 1;
                      0 <= ref1 ? j <= ref1 : j >= ref1;
                      0 <= ref1 ? j++ : j--
                    ) {
                      results1.push(j);
                    }
                    return results1;
                  }.apply(this),
                  startingCellValue,
                );
              },
            );
            evenColSize = 100.0 / selection.cols;
            model.rows = selection.rows;
            model.cols = selection.cols;
            model.colOffsets = (function () {
              var j, len, ref1, results1;
              ref1 = range(model.cols - 1);
              results1 = [];
              for (j = 0, len = ref1.length; j < len; j++) {
                col = ref1[j];
                results1.push(evenColSize * (col + 1));
              }
              return results1;
            })();
            _this.ui.handlePositions = model.colOffsets.slice(0);
            _this.ui.editors = createEditors();
            return triggerContentEvent();
          };
        })(this),
      });
      contextButtons = [
        {
          text: "Row After",
          cssClass: "icon icon-plus",
          onClick: (function (_this) {
            return function (params) {
              return _this.ui.addRow(params.row + 1);
            };
          })(this),
        },
        {
          text: "Row Before",
          cssClass: "icon icon-plus",
          onClick: (function (_this) {
            return function (params) {
              return _this.ui.addRow(params.row);
            };
          })(this),
        },
        {
          text: "Column After",
          cssClass: "icon icon-plus",
          onClick: (function (_this) {
            return function (params) {
              return _this.ui.addColumn(params.col + 1);
            };
          })(this),
        },
        {
          text: "Column Before",
          cssClass: "icon icon-plus",
          onClick: (function (_this) {
            return function (params) {
              return _this.ui.addColumn(params.col);
            };
          })(this),
        },
        {
          text: "Delete Row",
          cssClass: "icon icon-remove",
          onClick: (function (_this) {
            return function (params) {
              return _this.ui.deleteRow(params.row);
            };
          })(this),
        },
        {
          text: "Delete Column",
          cssClass: "icon icon-remove",
          onClick: (function (_this) {
            return function (params) {
              return _this.ui.deleteColumn(params.col);
            };
          })(this),
        },
      ];
      tableMenu = make(ContextMenu, {
        contextButtons: contextButtons,
      });
      TableEditorModel = require("./TableEditorModel")(EditorModel);
      createCellEditor = (function (_this) {
        return function (row, col) {
          var cellEditor;
          if (model.table[row][col] == null) {
            model.table[row][col] = startingCellValue();
          }
          cellEditor = make(Editor, {
            model: new TableEditorModel(model.table, row, col),
            options: {
              enableWidgets: false,
              autoFocus: false,
              undo: undo,
              allowTab: true,
            },
          });
          cellEditor.editor.addListener({
            onContent: function (e) {
              return triggerContentEvent();
            },
          });
          return cellEditor;
        };
      })(this);
      createEditors = (function (_this) {
        return function () {
          var col, editors, i, j, len, len1, ref, ref1, row;
          if (!(model.rows != null && model.cols != null)) {
            return;
          }
          editors = [];
          ref = range(model.rows);
          for (i = 0, len = ref.length; i < len; i++) {
            row = ref[i];
            editors[row] = [];
            ref1 = range(model.cols);
            for (j = 0, len1 = ref1.length; j < len1; j++) {
              col = ref1[j];
              editors[row].push(createCellEditor(row, col));
            }
          }
          return editors;
        };
      })(this);
      updateEditors = (function (_this) {
        return function () {
          var col, editors, i, len, ref, results, row;
          if (!(model.rows != null && model.cols != null)) {
            return;
          }
          editors = _this.ui.editors;
          ref = range(model.rows);
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            row = ref[i];
            if (editors[row] == null) {
              editors[row] = [];
            }
            results.push(
              (function () {
                var j, len1, ref1, results1;
                ref1 = range(model.cols);
                results1 = [];
                for (j = 0, len1 = ref1.length; j < len1; j++) {
                  col = ref1[j];
                  if (editors[row][col] == null) {
                    results1.push((editors[row][col] = createCellEditor(row, col)));
                  } else {
                    results1.push(void 0);
                  }
                }
                return results1;
              })(),
            );
          }
          return results;
        };
      })(this);
      this.ui = createUi(require("./Table.html"), {
        model: model,
        gridPicker: gridPicker.ui,
        tableMenu: tableMenu.ui,
        handlePositions: handlePositions,
        editors: createEditors(),
        getEditor: (function (_this) {
          return function (row, col) {
            var ref;
            return (ref = _this.ui.editors[row][col]) != null ? ref.ui : void 0;
          };
        })(this),
        getRows: (function (_this) {
          return function () {
            if (_this.ui.model.rows != null) {
              return range(_this.ui.model.rows);
            } else {
              return [];
            }
          };
        })(this),
        getCols: (function (_this) {
          return function () {
            if (_this.ui.model.cols != null) {
              return range(_this.ui.model.cols);
            } else {
              return [];
            }
          };
        })(this),
        isSelectingGrid: (function (_this) {
          return function () {
            return model.rows == null && model.cols == null;
          };
        })(this),
        addRow: (function (_this) {
          return function (atRow) {
            var col, newColumns;
            newColumns = function () {
              var i, ref, results;
              results = [];
              for (col = i = 0, ref = this.ui.model.cols; 0 <= ref ? i < ref : i > ref; col = 0 <= ref ? ++i : --i) {
                results.push(startingCellValue());
              }
              return results;
            }.call(_this);
            _this.ui.model.table.splice(atRow, 0, newColumns);
            _this.ui.model.rows += 1;
            updateEditors();
            return triggerContentEvent();
          };
        })(this),
        deleteRow: (function (_this) {
          return function (rowNum) {
            var editor, i, len, removedEditors;
            _this.ui.model.table.splice(rowNum, 1);
            removedEditors = _this.ui.editors.splice(rowNum, 1)[0];
            for (i = 0, len = removedEditors.length; i < len; i++) {
              editor = removedEditors[i];
              editor.remove();
            }
            _this.ui.model.rows -= 1;
            return triggerContentEvent();
          };
        })(this),
        addColumn: (function (_this) {
          return function (insertAtCol) {
            var columnWidths, equalPercentage, i, len, newColOffsets, offset, position, ratio, width;
            newColOffsets = [];
            equalPercentage = 100 / (model.cols + 1);
            ratio = _this.ui.model.cols / (_this.ui.model.cols + 1);
            columnWidths = function () {
              var i, len, ref, results;
              ref = this.ui.getColumnWidths();
              results = [];
              for (i = 0, len = ref.length; i < len; i++) {
                width = ref[i];
                results.push(width * ratio);
              }
              return results;
            }.call(_this);
            columnWidths.splice(insertAtCol, 0, equalPercentage);
            newColOffsets = [];
            offset = 0;
            for (position = i = 0, len = columnWidths.length; i < len; position = ++i) {
              width = columnWidths[position];
              if (position + 1 !== columnWidths.length) {
                offset += width;
                newColOffsets.push(offset);
              }
            }
            model.colOffsets = newColOffsets;
            _this.ui.handlePositions = model.colOffsets.slice(0);
            return $timeout(function () {
              var j, ref, row;
              _this.ui.model.cols += 1;
              for (row = j = 0, ref = _this.ui.model.rows; 0 <= ref ? j < ref : j > ref; row = 0 <= ref ? ++j : --j) {
                _this.ui.model.table[row].splice(insertAtCol, 0, startingCellValue());
              }
              updateEditors();
              return triggerContentEvent();
            });
          };
        })(this),
        tableKeydown: (function (_this) {
          return function (event) {
            var col, enterToIndex, flatTextareas, row, tabToIndex, table, textarea;
            textarea = event.target;
            table = event.currentTarget;
            if (event.which === TAB_KEY || event.keyCode === TAB_KEY) {
              row = parseInt(textarea.getAttribute("data-row"), 10);
              col = parseInt(textarea.getAttribute("data-col"), 10);
              tabToIndex = row * _this.ui.model.cols + col;
              if (event.shiftKey) {
                tabToIndex -= 1;
              } else {
                tabToIndex += 1;
              }
              flatTextareas = table.getElementsByTagName("textarea");
              if (tabToIndex < flatTextareas.length && tabToIndex >= 0) {
                flatTextareas[tabToIndex].focus();
                flatTextareas[tabToIndex].select();
              }
            }
            if (event.which === ENTER_KEY || event.keyCode === ENTER_KEY) {
              row = parseInt(textarea.getAttribute("data-row"), 10);
              col = parseInt(textarea.getAttribute("data-col"), 10);
              enterToIndex = (row + 1) * _this.ui.model.cols + col;
              flatTextareas = table.getElementsByTagName("textarea");
              if (enterToIndex < flatTextareas.length) {
                flatTextareas[enterToIndex].focus();
                return flatTextareas[enterToIndex].select();
              }
            }
          };
        })(this),
        textareaKeydown: (function (_this) {
          return function (event) {
            if (event.which === ENTER_KEY || event.keyCode === ENTER_KEY) {
              if (event.shiftKey) {
                return event.stopPropagation();
              } else {
                return event.preventDefault();
              }
            }
          };
        })(this),
        deleteColumn: (function (_this) {
          return function (deleteAtCol) {
            var columnWidths, i, len, newColOffsets, offset, position, ratio, sum, width;
            if (_this.ui.model.cols === 1) {
              return;
            }
            columnWidths = _this.ui.getColumnWidths();
            columnWidths.splice(deleteAtCol, 1);
            sum = _.sum(columnWidths);
            ratio = 100 / sum;
            $timeout(function () {
              var i, ref, removedEditor, row;
              for (row = i = 0, ref = _this.ui.model.rows; 0 <= ref ? i < ref : i > ref; row = 0 <= ref ? ++i : --i) {
                _this.ui.model.table[row].splice(deleteAtCol, 1);
                removedEditor = _this.ui.editors[row].splice(deleteAtCol, 1)[0];
                removedEditor.remove();
              }
              return (_this.ui.model.cols -= 1);
            });
            newColOffsets = [];
            offset = 0;
            for (position = i = 0, len = columnWidths.length; i < len; position = ++i) {
              width = columnWidths[position];
              if (position + 1 !== columnWidths.length) {
                offset += width * ratio;
                newColOffsets.push(offset);
              }
            }
            _this.ui.model.colOffsets = newColOffsets;
            _this.ui.handlePositions = model.colOffsets.slice(0);
            return triggerContentEvent();
          };
        })(this),
        onDragStop: (function (_this) {
          return function () {
            draggingIdx = null;
            return (draggingHandle = null);
          };
        })(this),
        onDragStart: (function (_this) {
          return function (data, evt) {
            draggingIdx = data;
            draggingHandle = evt.element;
          };
        })(this),
        onDragMove: (function (_this) {
          return function (evt) {
            var THRESHOLD, leftBoundaryPct, leftOffsetToTablePx, newPct, rightBoundaryPct, tableNode, tableWidthPx;
            if (draggingHandle == null) {
              return;
            }
            if (draggingIdx === 0) {
              leftBoundaryPct = 0;
            } else {
              leftBoundaryPct = _this.ui.model.colOffsets[draggingIdx - 1];
            }
            if (draggingIdx === _this.ui.model.colOffsets.length - 1) {
              rightBoundaryPct = 100;
            } else {
              rightBoundaryPct = _this.ui.model.colOffsets[draggingIdx + 1];
            }
            tableNode = evt.currentTarget;
            tableWidthPx = tableNode.clientWidth;
            leftOffsetToTablePx =
              draggingHandle[0].getBoundingClientRect().left - tableNode.getBoundingClientRect().left;
            newPct = (leftOffsetToTablePx / tableWidthPx) * 100;
            THRESHOLD = 5;
            if (newPct > leftBoundaryPct + THRESHOLD && newPct < rightBoundaryPct - THRESHOLD) {
              _this.ui.model.colOffsets[draggingIdx] = newPct;
              return triggerContentEvent();
            }
          };
        })(this),
        onDragSuccess: (function (_this) {
          return function () {
            _this.ui.handlePositions = _this.ui.model.colOffsets.slice();
            draggingIdx = null;
            draggingHandle = null;
            return $rootScope.$broadcast("elastic:adjust");
          };
        })(this),
        getColumnWidths: (function (_this) {
          return function () {
            var col, evenCols, i, j, last, len, len1, pctOffset, ref, ref1, result;
            if (_this.ui.model.colOffsets == null) {
              evenCols = [];
              ref = range(model.cols);
              for (i = 0, len = ref.length; i < len; i++) {
                col = ref[i];
                evenCols.push(100 / model.cols);
              }
              return evenCols;
            }
            last = 0;
            result = [];
            ref1 = _this.ui.model.colOffsets;
            for (j = 0, len1 = ref1.length; j < len1; j++) {
              pctOffset = ref1[j];
              result.push(pctOffset - last);
              last = pctOffset;
            }
            result.push(100 - last);
            return result;
          };
        })(this),
        maybeContextMenu: (function (_this) {
          return function (event, row, col) {
            return tableMenu.showAt(event, {
              row: row,
              col: col,
            });
          };
        })(this),
        focusChildTextarea: (function (_this) {
          return function ($event) {
            angular.element($event.currentTarget.getElementsByTagName("textarea")).trigger("focus");
          };
        })(this),
      });
      this.isBlank = (function (_this) {
        return function () {
          return !_this.ui.isSelectingGrid();
        };
      })(this);
      this.remove = (function (_this) {
        return function () {
          var editor, editorRow, i, len, ref, results;
          if (_this.ui.editors == null) {
            return;
          }
          ref = _this.ui.editors;
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            editorRow = ref[i];
            results.push(
              (function () {
                var j, len1, results1;
                results1 = [];
                for (j = 0, len1 = editorRow.length; j < len1; j++) {
                  editor = editorRow[j];
                  results1.push(editor.remove());
                }
                return results1;
              })(),
            );
          }
          return results;
        };
      })(this);
    }

    return TableController;
  })();
}.call(this));
