// Generated by CoffeeScript 1.12.7
(function () {
  var OverlayEditorLayout, QuoteAccountSettingsUpdater, UnitsDropdown, VariableEditor, VariableMenu;

  VariableMenu = require("./VariableMenu");

  UnitsDropdown = require("./UnitsDropdown");

  QuoteAccountSettingsUpdater = require("../QuoteAccountSettingsUpdater").QuoteAccountSettingsUpdater;

  OverlayEditorLayout = require("Common/OverlayEditorLayout/OverlayEditorLayout").OverlayEditorLayout;

  module.exports = VariableEditor = (function () {
    function VariableEditor($timeout, di, make, createUi, overlayStack, variableService, Tooltip, currenciesList) {
      var createNewVariable,
        deleteVariable,
        makeVariableMenu,
        overlayEditorLayout,
        refreshComponents,
        variableListComponent,
        variableMenus,
        variablesApiUpdater;
      variablesApiUpdater = make(QuoteAccountSettingsUpdater, {
        settingsName: "variables",
        transformFunction: (function (_this) {
          return function (arg) {
            var defaultAmount, id, ifOver, ifUnder, isDeleted, name, unitName;
            (id = arg.id),
              (name = arg.name),
              (isDeleted = arg.isDeleted),
              (unitName = arg.unitName),
              (defaultAmount = arg.defaultAmount),
              (ifUnder = arg.ifUnder),
              (ifOver = arg.ifOver);
            return {
              id: id,
              name: name,
              isDeleted: isDeleted,
              unitName: unitName,
              defaultAmount: defaultAmount,
              ifUnder: ifUnder,
              ifOver: ifOver,
            };
          };
        })(this),
      });
      variableMenus = {};
      refreshComponents = function () {
        var fn, i, len, variable, variables;
        variableMenus = {};
        variables = variableService.getAllVariables();
        fn = function (variable) {
          variable.unitsMenu = make(UnitsDropdown, {
            unitSelected: variable.unitName,
            select: function (unit) {
              variable.unitName = unit;
              variable.save();
              return variablesApiUpdater.debouncedApiUpdate();
            },
          });
          variable.underUnitsMenu = make(UnitsDropdown, {
            unitSelected: variable.ifUnder.unitName || variable.unitName,
            select: function (unit) {
              variable.ifUnder.unitName = unit;
              variable.save();
              return variablesApiUpdater.debouncedApiUpdate();
            },
          });
          return (variable.overUnitsMenu = make(UnitsDropdown, {
            unitSelected: variable.ifOver.unitName || variable.unitName,
            select: function (unit) {
              variable.ifOver.unitName = unit;
              variable.save();
              return variablesApiUpdater.debouncedApiUpdate();
            },
          }));
        };
        for (i = 0, len = variables.length; i < len; i++) {
          variable = variables[i];
          fn(variable);
        }
        return (variableListComponent.variables = variables);
      };
      variableListComponent = createUi(require("./VariableEditor.html"), {
        variables: null,
        hasCondition: function (variable) {
          return this.hasUnderCondition(variable) || this.hasOverCondition(variable);
        },
        hasUnderCondition: function (variable) {
          return variable.ifUnder.isActive;
        },
        hasOverCondition: function (variable) {
          return variable.ifOver.isActive;
        },
        hasClashingConditions: function (variable) {
          return (
            this.hasUnderCondition(variable) &&
            this.hasOverCondition(variable) &&
            variable.ifUnder.unitThreshold > variable.ifOver.unitThreshold
          );
        },
        currencySymbol: null,
        isMenuShowing: function (variable) {
          var ref;
          return (ref = variableMenus[variable.getId()]) != null ? ref.isCurrentlyShowing() : void 0;
        },
        showMenu: function (variable) {
          var id, menu, variableId;
          id = variable.getId();
          if (variableMenus[id] == null) {
            variableMenus[id] = makeVariableMenu(variable);
          }
          if (variableMenus[id].isCurrentlyShowing()) {
            variableMenus[id].hide();
            return;
          }
          for (variableId in variableMenus) {
            menu = variableMenus[variableId];
            if (menu.isCurrentlyShowing()) {
              menu.hide();
            }
          }
          return variableMenus[id].show();
        },
        variableUpdated: function (variable) {
          variable.save();
          return variablesApiUpdater.debouncedApiUpdate();
        },
      });
      createNewVariable = function () {
        variableService.addVariable();
        variablesApiUpdater.debouncedApiUpdate();
        return refreshComponents();
      };
      overlayEditorLayout = make(OverlayEditorLayout, {
        iconSrc: "Assets/icons/color/variable-75.svg",
        listComponent: variableListComponent,
        title: "Variables",
        description: "Set up variables for your account (any changes affect all unaccepted quote blocks).",
        createNewItem: createNewVariable,
        isValid: function () {
          return true;
        },
      });
      deleteVariable = function (id) {
        variableService.deleteVariable(id);
        variablesApiUpdater.debouncedApiUpdate();
        variableMenus[id].hide();
        delete variableMenus[id];
        return refreshComponents();
      };
      makeVariableMenu = function (variable) {
        var id, variableMenu;
        id = variable.getId();
        variableMenu = make(VariableMenu, {
          variable: variable,
          deleteVariable: function () {
            return deleteVariable(id);
          },
        });
        return make(Tooltip, {
          targetSelector: "#" + id + " .floating-menu-button",
          options: {
            container: "#" + id,
            position: "bottom",
            spacing: 10,
            contents: variableMenu.ui,
            closeOnClickAway: true,
          },
        });
      };
      this.show = (function (_this) {
        return function (currencySymbol) {
          refreshComponents();
          variableListComponent.currencySymbol = currencySymbol;
          return overlayEditorLayout.show();
        };
      })(this);
    }

    return VariableEditor;
  })();
}.call(this));
