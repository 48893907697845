// Generated by CoffeeScript 1.12.7

/*
  Developer convenience: accounts for optional fixed UI when scrolling elements
  into user's view.
 */

(function () {
  module.exports = function (scrollToElement, $state, projectNavigation) {
    return function (elem, options) {
      var HEADER_MENU_WIDGET_HEIGHT;
      if (options == null) {
        options = {};
      }
      if (options.offset == null) {
        options.offset = 0;
      }

      /*
        @HACK (dylan, 2017-4-5): We have to hardcode this value since we haven't found
        a convenient way (yet!) of making things DRY between LESS and JS code i.e. via Webpack for example.
        And we can't depend on the DOM being initiated when we run this code. It's not a huge
        issue at this point, but might be a nice improvement in the future to get shared values
        between LESS & our JS logic.
       */
      HEADER_MENU_WIDGET_HEIGHT = 60;

      /*
        @NOTE: This doesn't yet deal with the case where a user has minimised
        their header navigation bar.
       */
      if (projectNavigation.isWidgetEnabled("header")) {
        options.offset += HEADER_MENU_WIDGET_HEIGHT;
      }
      return scrollToElement(elem, options);
    };
  };
}.call(this));
