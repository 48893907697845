"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageLibraryViewStore = void 0;
var Types_1 = require("Common/AssetLibrary/Types");
var Types_2 = require("Common/AssetLibrary/ImageLibrary/Types");
var mobx_1 = require("mobx");
var ImageLibraryUtils_1 = require("Common/AssetLibrary/ImageLibrary/ImageLibraryUtils");
var Types_3 = require("Common/AssetLibrary/ImageLibrary/SystemFolderStores/Types");
var Types_4 = require("@CommonFrontendBackend/Folders/Types");
var ImageLibraryViewStore = (function () {
    function ImageLibraryViewStore(useAssetCallback, systemFolderViewStores) {
        var e_1, _a;
        this.useAssetCallback = useAssetCallback;
        this.type = Types_1.AssetType.Image;
        this.systemFolderViewStores = new Map();
        try {
            for (var systemFolderViewStores_1 = __values(systemFolderViewStores), systemFolderViewStores_1_1 = systemFolderViewStores_1.next(); !systemFolderViewStores_1_1.done; systemFolderViewStores_1_1 = systemFolderViewStores_1.next()) {
                var store = systemFolderViewStores_1_1.value;
                this.systemFolderViewStores.set(store.systemFolderId, store);
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (systemFolderViewStores_1_1 && !systemFolderViewStores_1_1.done && (_a = systemFolderViewStores_1.return)) _a.call(systemFolderViewStores_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    ImageLibraryViewStore.prototype.getSearchTerm = function () {
        if ((0, ImageLibraryUtils_1.isSystemFolderViewStoreWithSearch)(this.selectedSystemFolderViewStore)) {
            return this.selectedSystemFolderViewStore.searchTerm;
        }
        return "";
    };
    ImageLibraryViewStore.prototype.setSearchTerm = function (newSearchTerm) {
        if ((0, ImageLibraryUtils_1.isSystemFolderViewStoreWithSearch)(this.selectedSystemFolderViewStore)) {
            this.selectedSystemFolderViewStore.searchTerm = newSearchTerm;
        }
    };
    Object.defineProperty(ImageLibraryViewStore.prototype, "currentPanelView", {
        get: function () {
            return this.selectedSystemFolderViewStore.currentPanelView;
        },
        set: function (newView) {
            this.selectedSystemFolderViewStore.currentPanelView = newView;
        },
        enumerable: false,
        configurable: true
    });
    ImageLibraryViewStore.prototype.selectCollection = function (collection) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(0, ImageLibraryUtils_1.isSystemFolderViewStoreWithCollection)(this.selectedSystemFolderViewStore)) return [3, 2];
                        return [4, this.selectedSystemFolderViewStore.selectCollection(collection)];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    Object.defineProperty(ImageLibraryViewStore.prototype, "selectedCollection", {
        get: function () {
            if ((0, ImageLibraryUtils_1.isSystemFolderViewStoreWithCollection)(this.selectedSystemFolderViewStore)) {
                return this.selectedSystemFolderViewStore.selectedCollection;
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ImageLibraryViewStore.prototype, "isLoading", {
        get: function () {
            return this.selectedSystemFolderViewStore.isLoadingOn;
        },
        enumerable: false,
        configurable: true
    });
    ImageLibraryViewStore.prototype.setLoadingOn = function () {
        this.selectedSystemFolderViewStore.isLoadingOn = true;
    };
    ImageLibraryViewStore.prototype.setLoadingOff = function () {
        this.selectedSystemFolderViewStore.isLoadingOn = false;
    };
    ImageLibraryViewStore.prototype.useAsset = function (image) {
        this.selectedSystemFolderViewStore.useImage(image, this.useAssetCallback);
    };
    ImageLibraryViewStore.prototype.selectFolder = function (folderId) {
        if (!this.systemFolderViewStores.has(folderId)) {
            this.selectedSystemFolderId = Types_2.ImageLibrarySystemFolder.Uploads;
        }
        else {
            this.selectedSystemFolderId = folderId;
        }
        this.selectedSystemFolderViewStore.resetAll();
    };
    Object.defineProperty(ImageLibraryViewStore.prototype, "selectedSystemFolderViewStore", {
        get: function () {
            return this.systemFolderViewStores.get(this.selectedSystemFolderId);
        },
        enumerable: false,
        configurable: true
    });
    var _a, _b, _c, _d, _e, _f;
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", typeof (_a = typeof Types_4.ImageLibraryFolderId !== "undefined" && Types_4.ImageLibraryFolderId) === "function" ? _a : Object)
    ], ImageLibraryViewStore.prototype, "selectedSystemFolderId", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ImageLibraryViewStore.prototype, "setSearchTerm", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_b = typeof Types_2.IUnsplashCollection !== "undefined" && Types_2.IUnsplashCollection) === "function" ? _b : Object]),
        __metadata("design:returntype", typeof (_c = typeof Promise !== "undefined" && Promise) === "function" ? _c : Object)
    ], ImageLibraryViewStore.prototype, "selectCollection", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ImageLibraryViewStore.prototype, "setLoadingOn", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ImageLibraryViewStore.prototype, "setLoadingOff", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_d = typeof Types_2.IBaseImage !== "undefined" && Types_2.IBaseImage) === "function" ? _d : Object]),
        __metadata("design:returntype", void 0)
    ], ImageLibraryViewStore.prototype, "useAsset", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_e = typeof Types_4.ImageLibraryFolderId !== "undefined" && Types_4.ImageLibraryFolderId) === "function" ? _e : Object]),
        __metadata("design:returntype", void 0)
    ], ImageLibraryViewStore.prototype, "selectFolder", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_f = typeof Types_3.IILSystemFolderViewStore !== "undefined" && Types_3.IILSystemFolderViewStore) === "function" ? _f : Object),
        __metadata("design:paramtypes", [])
    ], ImageLibraryViewStore.prototype, "selectedSystemFolderViewStore", null);
    return ImageLibraryViewStore;
}());
exports.ImageLibraryViewStore = ImageLibraryViewStore;
