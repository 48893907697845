"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetType = exports.Tag = void 0;
var Tag;
(function (Tag) {
    Tag["Business"] = "Business";
    Tag["Marketing"] = "Marketing";
    Tag["Gaming"] = "Gaming";
    Tag["Music"] = "Music";
    Tag["Weather"] = "Weather";
})(Tag || (exports.Tag = Tag = {}));
var AssetType;
(function (AssetType) {
    AssetType["UnsplashImage"] = "unsplashImage";
    AssetType["Video"] = "video";
    AssetType["Block"] = "block";
    AssetType["UnsplashCollection"] = "unsplashCollection";
})(AssetType || (exports.AssetType = AssetType = {}));
