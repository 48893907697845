/*
  This module defines the Block Menu for Quote Block.
 */

(function () {
  module.exports = function (
    currencySelector,
    ratesPanel,
    toggleDisplayMode,
    make,
    telemetry,
    usingFeature,
    StyleButton,
    BlockControls,
  ) {
    var blockControls, styleButton, superpowers;
    styleButton = make(StyleButton);
    blockControls = make(BlockControls);
    superpowers = [
      {
        name: "Style library",
        ctrl: styleButton,
        selectable: false,
        select: styleButton.onClick,
      },
      {
        name: "Block Controls",
        ctrl: blockControls,
        doNotWrapControl: true,
        visibleOnViewOnlyBlocks: true,
      },
    ];
    return {
      settings: {
        useItems: ["foldable", "navigationMenu"],
        items: [
          {
            name: "Currency",
            description: "Change the currency of this quote.",
            onClick: (function (_this) {
              return function () {
                return currencySelector.show();
              };
            })(this),
          },
          {
            name: "Rates",
            description: "Create and manage rates for this account.",
            onClick: (function (_this) {
              return function () {
                return ratesPanel.show();
              };
            })(this),
          },
          {
            name: "Taxes",
            description: "Create and manage taxes for this account.",
            ctrl: make(require("./Controls/Taxes/Taxes")),
          },
          {
            name: "Configure",
            description: "Show / hide elements of your quote.",
            ctrl: make(require("./Controls/Configuration/Configuration")),
            onOpen: function () {
              telemetry.trackOnly("Quote Block Configuration Viewed");
              return toggleDisplayMode();
            },
          },
          {
            name: "Custom Labels",
            description: "Customize labels for non-English speakers.",
            ctrl: make(require("./Controls/CustomLabels/CustomLabels")),
            onOpen: function () {
              return telemetry.trackOnly("Quote Block Custom Labels Viewed");
            },
          },
        ],
        superpowers: superpowers,
      },
    };
  };
}.call(this));
