// Generated by CoffeeScript 1.12.7
(function () {
  var FeatureFlag, StyleEditorControls;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = StyleEditorControls = (function () {
    function StyleEditorControls(createUi, make, style, onChange, onSave, onCancel, usingFeature, $timeout) {
      var advancedEditor,
        alignmentEditor,
        backgroundCardEditor,
        backgroundEditor,
        colorPickerFactory,
        columnWidthEditor,
        elements,
        fontStyleSliderFactory,
        getElementDescription,
        makeColorEditors,
        makeFontStyleEditors,
        ref,
        ref1,
        toggle,
        viewState;
      getElementDescription = function (element) {
        if (element === "h1") {
          return "Heading";
        } else if (element === "h2") {
          return "Sub Heading";
        } else if (element === "p") {
          return "Paragraph";
        } else if (element === "") {
          return "Container";
        }
      };
      elements = ["h1", "h2", "p"];
      backgroundEditor = make(require("./BackgroundPicker/BackgroundPicker"), {
        style: style,
        isCard: false,
        getElementDescription: getElementDescription,
        onChange: (function (_this) {
          return function () {
            if (
              typeof makeColorEditors !== "undefined" &&
              makeColorEditors !== null &&
              (style.backgroundCard == null || style.backgroundCard === "none")
            ) {
              _this.ui.colorEditors = makeColorEditors();
            }
            return onChange();
          };
        })(this),
      });
      if (style.backgroundCard != null) {
        backgroundCardEditor = make(require("./BackgroundPicker/BackgroundPicker"), {
          style: style,
          isCard: true,
          onChange: (function (_this) {
            return function () {
              _this.ui.colorEditors = makeColorEditors();
              return onChange();
            };
          })(this),
        });
      }
      alignmentEditor = make(require("./AlignmentPicker/AlignmentPicker"), {
        style: style,
        getElementDescription: getElementDescription,
        onChange: onChange,
        elements: elements,
      });
      colorPickerFactory = make(require("./ColorPickerFactory/ColorPickerFactory"), {
        getElementDescription: getElementDescription,
        elements: elements,
      });
      fontStyleSliderFactory = make(require("./FontStyleSliderFactory/FontStyleSliderFactory"));
      columnWidthEditor = {
        title: "Column Width",
        ui: make(require("./ColumnWidthPicker/ColumnWidthPicker"), {
          style: style,
          onChange: onChange,
        }).ui,
      };
      makeColorEditors = function () {
        return colorPickerFactory.createAll(style, onChange);
      };
      viewState = {
        enableGroupedEditControls: _.isEmpty(style.fontStyles.h1),
      };
      makeFontStyleEditors = (function (_this) {
        return function (isRebuild) {
          var editors;
          editors = fontStyleSliderFactory.createAll(style, onChange, viewState.enableGroupedEditControls, isRebuild);
          editors.push(columnWidthEditor);
          return editors;
        };
      })(this);
      toggle = (function (_this) {
        return function (name) {
          if (_this.ui.toggled === name) {
            return (_this.ui.toggled = "");
          } else {
            return (_this.ui.toggled = name);
          }
        };
      })(this);
      this.ui = createUi(require("./Controls.html"), {
        toggled: "fontStyles",
        toggle: toggle,
        viewState: viewState,
        backgroundEditor: backgroundEditor.ui,
        backgroundCardEditor: backgroundCardEditor != null ? backgroundCardEditor.ui : void 0,
        colorEditors: makeColorEditors(),
        fontStyleEditors: makeFontStyleEditors(),
        rebuildFontStyleEditors: (function (_this) {
          return function () {
            var fontStyleEditors;
            fontStyleEditors = makeFontStyleEditors(true);
            _this.ui.fontStyleEditors = null;
            return $timeout(function () {
              return (_this.ui.fontStyleEditors = fontStyleEditors);
            }, 350);
          };
        })(this),
        alignmentEditor: alignmentEditor.ui,
        close: onCancel,
        style: style,
        save: function () {
          if (style.name === "" || !style.name) {
            style.name = "Untitled Style";
          }
          return onSave();
        },
      });
      if (((ref = style.styleModel) != null ? ref.length : void 0) === 1 && style.styleModel[0].rawStyles === "") {
        style.styleModel = [];
      }
      if (
        usingFeature(FeatureFlag.AdvancedStyleEditor) ||
        ((ref1 = style.styleModel) != null ? ref1.length : void 0) > 0
      ) {
        this.ui.isAdvanced = true;
        advancedEditor = make(require("./AdvancedEditor/AdvancedEditor"), {
          style: style,
          elements: elements,
          getElementDescription: getElementDescription,
          onChange: onChange,
        });
        this.ui.advancedEditor = advancedEditor.ui;
      }
    }

    return StyleEditorControls;
  })();
}.call(this));
