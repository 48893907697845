"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DragManager = void 0;
var mobx_1 = require("mobx");
var SideBySide_1 = require("ContentTokens/DataModels/SideBySide");
var FindNearestValue_1 = require("Utils/FindNearestValue");
var DragStatus;
(function (DragStatus) {
    DragStatus["None"] = "none";
    DragStatus["InProgress"] = "InProgress";
})(DragStatus || (DragStatus = {}));
var DragManager = (function () {
    function DragManager() {
        var _this = this;
        this.dragStatus = DragStatus.None;
        this.containerLeft = 0;
        this.containerWidth = 0;
        this.setDragContainer = function (ref, onDragComplete) {
            _this.containerRef = ref;
            _this.onDragComplete = onDragComplete;
        };
        this.startDrag = function (initialMouseXCoordinate) {
            _this.dragStatus = DragStatus.InProgress;
            var dragContainer = _this.containerRef.current.getBoundingClientRect();
            _this.containerLeft = dragContainer.left;
            _this.containerWidth = dragContainer.width;
            _this.mouseXCoordinate = initialMouseXCoordinate;
            _this.setupDragListeners();
        };
        this.dragMoved = function (newMouseXCoordinate) {
            _this.mouseXCoordinate = newMouseXCoordinate;
        };
        this.stopDrag = function () {
            _this.cleanupDragListeners();
            _this.onDragComplete(_this.finishedDragPercentage);
            _this.dragStatus = DragStatus.None;
            _this._currentGuidelinePercentage = undefined;
            _this.containerLeft = 0;
            _this.containerWidth = 0;
        };
        this.onGuidelineHover = function (newPercentage) {
            _this._currentGuidelinePercentage = newPercentage;
        };
        this.onGuidelineLeave = function () {
            _this._currentGuidelinePercentage = undefined;
        };
        this.setupDragListeners = function () {
            document.addEventListener("mousemove", _this.onMouseMove);
            document.addEventListener("mouseup", _this.stopDrag);
        };
        this.cleanupDragListeners = function () {
            document.removeEventListener("mousemove", _this.onMouseMove);
            document.removeEventListener("mouseup", _this.stopDrag);
        };
        this.onMouseMove = function (event) {
            _this.dragMoved(event.clientX);
        };
    }
    Object.defineProperty(DragManager.prototype, "activeDragPercentage", {
        get: function () {
            return this._currentGuidelinePercentage;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragManager.prototype, "isDragging", {
        get: function () {
            return this.dragStatus === DragStatus.InProgress;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragManager.prototype, "snappedDragPercentage", {
        get: function () {
            if (this.activeDragPercentage) {
                return this.activeDragPercentage;
            }
            if (this.rawDragPercentage > 75) {
                return 75;
            }
            else if (this.rawDragPercentage < 25) {
                return 25;
            }
            return;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragManager.prototype, "drawnDragPercentage", {
        get: function () {
            return this.snappedDragPercentage || this.rawDragPercentage;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragManager.prototype, "finishedDragPercentage", {
        get: function () {
            if (this.snappedDragPercentage) {
                return this.snappedDragPercentage;
            }
            return (0, FindNearestValue_1.findNearestValue)(this.rawDragPercentage, SideBySide_1.AllowedPercentageValues);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragManager.prototype, "rawDragPercentage", {
        get: function () {
            return (this.dragXValue / this.containerWidth) * 100;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DragManager.prototype, "dragXValue", {
        get: function () {
            if (!this.isDragging) {
                return null;
            }
            return this.mouseXCoordinate - this.containerLeft;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", String)
    ], DragManager.prototype, "dragStatus", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Number)
    ], DragManager.prototype, "_currentGuidelinePercentage", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], DragManager.prototype, "containerLeft", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], DragManager.prototype, "containerWidth", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], DragManager.prototype, "mouseXCoordinate", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DragManager.prototype, "startDrag", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DragManager.prototype, "dragMoved", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DragManager.prototype, "stopDrag", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DragManager.prototype, "onGuidelineHover", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DragManager.prototype, "onGuidelineLeave", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], DragManager.prototype, "activeDragPercentage", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DragManager.prototype, "isDragging", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragManager.prototype, "snappedDragPercentage", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragManager.prototype, "drawnDragPercentage", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragManager.prototype, "finishedDragPercentage", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragManager.prototype, "rawDragPercentage", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], DragManager.prototype, "dragXValue", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], DragManager.prototype, "onMouseMove", void 0);
    return DragManager;
}());
exports.DragManager = DragManager;
