import "Application/Library/AutomationLibrary/AutomationActivityLog.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/AutomationLibrary/AutomationActivityLog.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6XS326CMBQG8HueojdL5kUN+GcqXu4FlvgAprQFqqWHlVZdlr37IptIUQHDJcn363dazngbRJ8pFNJH3x5CR8FMGqLJ9GXtIQQHrmMJxxClgjGu1t6PN76AoA5m3fmJUzDvBtNnwawOgh5XmDvA7wZvJYglP4XIRz4i1sAZUZCgQ3Qg+hXj8gMLCmq7DSK52O2CfOQcsyiPyYhOhMIG8ossckKFSnBWwSBz5bKUOWFMqKSDIRQRuk80WMXwnQkLq2NC+YZTUIzor0oLt3P1VOf/rSIwBrJmurimZf8J37UwghJZ4cajkHLAO97wk7nBKxdHdSxUyrUwToC2nb6xlPLieq2FezgbMhlvxcQaUduwpWvj0kagGddYEyZsEaL5ecNroeQv1GtJGvfcj1rWvhk27mxp/9q7f/5h70364BaLJ4obzytbexth69buujZsP+BHywE2a7MVmrlIDVlpaMMfklCegmRcVz46+19WLFgGpAYAAA==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var col1 = '_1bqhosl0';
export var col2 = '_1bqhosl1';
export var col3 = '_1bqhosl2';
export var col4 = '_1bqhosl3';
export var col5 = '_1bqhosl4';
export var col6 = '_1bqhosl5';
export var icon = '_1bqhosl6';
export var expandableContent = '_1bqhosl7';
export var executionOverview = '_1bqhosl8';
export var executionErrorOverview = '_1bqhosl9';
export var executionErrorText = '_1bqhosla';
export var statusText = _7a468({defaultClassName:'_1bqhoslb',variantClassNames:{outcome:{success:'_1bqhoslc',failure:'_1bqhosld',review:'_1bqhosle'}},defaultVariants:{},compoundVariants:[]});
export var statusIcon = _7a468({defaultClassName:'_1bqhoslf',variantClassNames:{outcome:{success:'_1bqhoslg',failure:'_1bqhoslh',review:'_1bqhosli'}},defaultVariants:{},compoundVariants:[]});
export var actionText = _7a468({defaultClassName:'_1bqhoslj',variantClassNames:{outcome:{'partial-success':'_1bqhoslk','not-executed':'_1bqhosll',success:'_1bqhoslm',failure:'_1bqhosln'}},defaultVariants:{},compoundVariants:[]});
export var notCreatedFromTemplateText = '_1bqhoslo';