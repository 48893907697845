"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentWidgetActionType = exports.CommentWidgetName = void 0;
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var CommentActions = __importStar(require("./CommentActions"));
exports.CommentWidgetName = "comment";
var CommentWidgetActionType;
(function (CommentWidgetActionType) {
    CommentWidgetActionType["Update"] = "update";
    CommentWidgetActionType["RecoverMarker"] = "recoverMarker";
})(CommentWidgetActionType || (exports.CommentWidgetActionType = CommentWidgetActionType = {}));
exports.default = (function (di) {
    return {
        onAppendToComment: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("appendToComment")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Any)
            .selectionIsCollapsed()
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .inBlockType(EditorTypes_1.BlockType.Any)
            .atEndOfMark(EditorTypes_1.MarkType.Comment)
            .doAction(function (event, editor) {
            CommentActions.preventCommentContinuation(editor);
            return;
        }),
        onCommentUpdate: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("commentUpdate")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: exports.CommentWidgetName, action: CommentWidgetActionType.Update })
            .doAction(function (event, editor) {
            CommentActions.updateCommentId(editor, event.data.fromId, event.data.toId);
            return true;
        }),
        onCommentMarkerDelete: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("commentMarkerDeletion")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: exports.CommentWidgetName, action: CommentWidgetActionType.RecoverMarker })
            .doAction(function (event, editor) {
            CommentActions.forceRefreshCommentId(editor, event.data.id);
            return true;
        }),
        moveRightFromComment: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("moveRightFromComment")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.ArrowRight)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .inBlockType(EditorTypes_1.BlockType.Any)
            .atEndOfMark(EditorTypes_1.MarkType.Comment)
            .doAction(function (event, editor) {
            CommentActions.moveRight(event, editor);
            return true;
        }),
    };
});
