"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTokenNotFoundError = exports.getRenameTokenErrorMessage = exports.getCreateTokenErrorMessage = exports.getFetchTokensErrorMessage = void 0;
var AxiosErrorParser_1 = require("Errors/AxiosErrorParser");
var ErrorCodes_1 = require("@CommonFrontendBackend/ErrorCodes/ErrorCodes");
var Types_1 = require("@CommonFrontendBackend/Tokens/Types");
var CreateTokenAlreadyExists = "A token with the same name already exists";
var CreateTokenNotAllowed = "Sorry, you are not allowed to create new tokens";
var CreateTokenQuotaExceeded = "Sorry, you've reached the maximum number of ".concat(Types_1.MAX_CUSTOM_TOKENS_PER_ACCOUNT, " tokens allowed for your account. Please remove some to free up space");
var RenameTokenDoesNotExist = "Sorry, someone else on your team has deleted this token. Please refresh the page and try again";
var ServerError = "An error occurred. Please try again in a moment. If the problem persists, contact support.";
var NetworkError = "It looks like there might be a problem with your network connection";
var UnknownError = "Something went wrong. Please try again in a moment";
var getFetchTokensErrorMessage = function (error) {
    var _a;
    return (0, AxiosErrorParser_1.isAxiosError)(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 500 ? ServerError : UnknownError;
};
exports.getFetchTokensErrorMessage = getFetchTokensErrorMessage;
var getCreateTokenErrorMessage = function (error) {
    var _a, _b, _c;
    if ((0, AxiosErrorParser_1.isAxiosError)(error)) {
        if (!(0, AxiosErrorParser_1.isResponseError)(error)) {
            return NetworkError;
        }
        var responseError = (0, AxiosErrorParser_1.getResponseError)(error);
        if (((_a = responseError.additionalInfo) === null || _a === void 0 ? void 0 : _a.errorCode) === ErrorCodes_1.ServerErrorCode.TokenAlreadyExists) {
            return CreateTokenAlreadyExists;
        }
        else if (((_b = responseError.additionalInfo) === null || _b === void 0 ? void 0 : _b.errorCode) === ErrorCodes_1.ServerErrorCode.TokenQuotaExceeded) {
            return CreateTokenQuotaExceeded;
        }
        else if (((_c = error.response) === null || _c === void 0 ? void 0 : _c.status) === 403) {
            return CreateTokenNotAllowed;
        }
    }
    return UnknownError;
};
exports.getCreateTokenErrorMessage = getCreateTokenErrorMessage;
var getRenameTokenErrorMessage = function (error) {
    if ((0, AxiosErrorParser_1.isAxiosError)(error)) {
        if (!(0, AxiosErrorParser_1.isResponseError)(error)) {
            return NetworkError;
        }
        if (isTokenNotFoundError(error)) {
            return RenameTokenDoesNotExist;
        }
    }
    return UnknownError;
};
exports.getRenameTokenErrorMessage = getRenameTokenErrorMessage;
var isTokenNotFoundError = function (error) {
    var _a;
    return (0, AxiosErrorParser_1.isAxiosError)(error) && ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) === 404;
};
exports.isTokenNotFoundError = isTokenNotFoundError;
