"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSummaryTimelineFilter = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var styles = __importStar(require("./SummaryTimelineFilters.css"));
var StringManipulater_1 = require("@CommonFrontend/Utils/StringManipulater");
function useSummaryTimelineFilter(summaryTimelineData) {
    var _a = __read((0, react_1.useState)(function () { return new Set(); }), 2), checkboxFilterState = _a[0], setCheckboxFilterState = _a[1];
    function onCheckboxToggle(filter) {
        var newState = new Set(checkboxFilterState);
        if (checkboxFilterState.has(filter)) {
            newState.delete(filter);
        }
        else {
            newState.add(filter);
        }
        setCheckboxFilterState(newState);
    }
    var filteredData = checkboxFilterState.size === 0 || summaryTimelineData === undefined
        ? summaryTimelineData
        : filterData(summaryTimelineData, checkboxFilterState);
    return {
        filtersButton: react_1.default.createElement(FiltersButton, { checkboxFilterState: checkboxFilterState, onCheckboxToggle: onCheckboxToggle }),
        filteredData: filteredData,
    };
}
exports.useSummaryTimelineFilter = useSummaryTimelineFilter;
function filterData(summaryTimelineData, checkboxFilterState) {
    var e_1, _a;
    var validEventTypes = [];
    try {
        for (var _b = __values(checkboxFilterState.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
            var filter = _c.value;
            validEventTypes.push.apply(validEventTypes, __spreadArray([], __read(checkboxFiltersConfig[filter]), false));
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return summaryTimelineData.filter(function (item) { return validEventTypes.includes(item.eventData.eventType); });
}
var FiltersButton = function (_a) {
    var checkboxFilterState = _a.checkboxFilterState, onCheckboxToggle = _a.onCheckboxToggle;
    var numSelectedFilters = checkboxFilterState.size;
    var buttonText = numSelectedFilters === 0 ? "All events" : (0, StringManipulater_1.pluralise)(numSelectedFilters, "filter");
    return (react_1.default.createElement(kaleidoscope_1.Popover, { button: function (buttonProps) { return (react_1.default.createElement(kaleidoscope_1.Button, __assign({ className: styles.filterButton, type: kaleidoscope_1.ButtonType.Tertiary, size: kaleidoscope_1.ButtonSize.Small }, buttonProps),
            react_1.default.createElement(icons_1.Filter, null),
            buttonText)); }, autoStack: true },
        react_1.default.createElement("div", { className: styles.checkboxGrid }, checkboxesOrder.map(function (v) { return (react_1.default.createElement(kaleidoscope_1.Checkbox, { key: v, id: v, label: v, checked: checkboxFilterState.has(v), size: "s", onChange: function () { return onCheckboxToggle(v); } })); }))));
};
var checkboxesOrder = ["Page status", "Engagement", "Views", "Viewer interactions"];
var checkboxFiltersConfig = {
    "Page status": [
        "pageStatus",
        "Page Accepted",
        "Page Partially Accepted",
    ],
    Engagement: ["engagementLevel"],
    "Viewer interactions": [
        "Quote Changed",
        "ROI Calculator Updated",
        "Agreement Viewed",
        "Accept Process Stalled",
        "Page Partially Accepted",
        "Page Accepted",
    ],
    Views: ["pageView"],
};
