// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = {
    Text: [
      {
        name: "Container",
        element: "",
        rawStyles: "",
      },
      {
        name: "Heading",
        element: "h1",
        rawStyles: "",
      },
      {
        name: "Sub-Title",
        element: "h2",
        rawStyles: "",
      },
      {
        name: "Body Text",
        element: "p",
        rawStyles: "",
      },
    ],
    Quote: [
      {
        name: "Container",
        element: "",
        rawStyles: "",
      },
      {
        name: "Section Title",
        element: ".quote-section .section-name",
        rawStyles: "",
      },
      {
        name: "Line Item",
        element: ".item",
        rawStyles: "",
      },
      {
        name: "Totals",
        element: ".totals",
        rawStyles: "",
      },
      {
        name: "Heading",
        element: "h1",
        rawStyles: "",
      },
      {
        name: "Sub-Title",
        element: "h2",
        rawStyles: "",
      },
      {
        name: "Body Text",
        element: "p",
        rawStyles: "",
      },
    ],
  };
}.call(this));
