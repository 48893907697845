// Generated by CoffeeScript 1.12.7
(function () {
  var FieldService, FormField;

  FieldService = require("./FieldService");

  module.exports = FormField = (function () {
    function FormField(createUi, make, model, fields, typeIcon, fieldUi) {
      var fieldService;
      fieldService = make(FieldService, {
        model: model,
        fields: fields,
      });
      this.ui = createUi(require("Common/FormBuilder/FormEditor/Field/Field.html"), {
        fieldUi: fieldUi,
        model: model,
        typeIcon: typeIcon,
        isRequired: model.isRequired,
        changeIsRequired: (function (_this) {
          return function () {
            if (_this.ui.isRequired) {
              return fieldService.makeRequired();
            } else {
              return fieldService.makeOptional();
            }
          };
        })(this),
        actions: [
          {
            name: "Delete",
            icon: "cross",
            onClick: fieldService["delete"],
          },
          {
            name: "Move Up",
            icon: "arrow-up2",
            onClick: fieldService.moveUp,
          },
          {
            name: "Move Down",
            icon: "arrow-down",
            onClick: fieldService.moveDown,
          },
        ],
      });
    }

    return FormField;
  })();
}.call(this));
