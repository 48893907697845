// Generated by CoffeeScript 1.12.7

/*
 Page for updating a credit card. Not accessed via the UI, but navigated to via a URL.
 Mostly used by customer service
 */

(function () {
  require("SettingsAngular/Subscription/CreditCard/AddCreditCard.ng.html");

  module.exports = angular.module("qwilr.addCard", ["ui.router"]).config(function ($stateProvider) {
    return $stateProvider.state("add-card", {
      url: "/add-card",
      templateUrl: "SettingsAngular/Subscription/CreditCard/AddCreditCard.ng.html",
      controller: function ($scope, $rootScope, $http) {
        var CreditCardScreen,
          account,
          apiBaseUrl,
          creditCardDi,
          creditCardScreen,
          di,
          initialViewState,
          ref,
          rolesService,
          user;
        di = $rootScope.di;
        apiBaseUrl = di.get("apiBaseUrl");
        user = di.get("user");
        account = di.get("account");
        rolesService = di.get("rolesService");
        creditCardDi = di.child();
        di.value("allowCoupon", false);
        CreditCardScreen = require("../CreditCard/UpdateCreditCard");
        creditCardScreen = creditCardDi.instantiate(CreditCardScreen, {
          isUpdating: false,
          onComplete: function (result) {
            return ($scope.ui.viewState = "success");
          },
        });
        initialViewState =
          ((ref = account.stripe) != null ? ref.customerToken : void 0) != null
            ? "success"
            : !rolesService.isAdmin(user)
            ? "not admin"
            : "show form";
        return ($scope.ui = {
          viewState: initialViewState,
          creditCardScreen: creditCardScreen.ui,
          showForm: function () {
            return $scope.ui.viewState === "show form";
          },
          showNotAdmin: function () {
            return $scope.ui.viewState === "not admin";
          },
          showSuccess: function () {
            return $scope.ui.viewState === "success";
          },
          showSaving: function () {
            return $scope.ui.viewState === "saving";
          },
        });
      },
    });
  });
}.call(this));
