// Generated by CoffeeScript 1.12.7
(function () {
  var FormTextField;

  module.exports = FormTextField = (function () {
    function FormTextField(createUi, model) {
      this.ui = createUi(require("Common/FormBuilder/FormEditor/Field/Text/Text.html"), {
        model: model,
        validate: (function (_this) {
          return function () {
            if (model.name.length === 0) {
              return (_this.ui.error = "Please enter a name for this text field.");
            } else {
              return (_this.ui.error = "");
            }
          };
        })(this),
      });
    }

    return FormTextField;
  })();
}.call(this));
