// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (make, createUi, Slider, item, itemService, currency) {
    var readout;
    readout = createUi(require("./SliderReadout.html"), {
      item: item,
      currency: currency,
    });
    return (this.ui = make(Slider, {
      defaultValue: item.units || 0,
      readout: readout,
      getMin: function () {
        var ref, ref1;
        return ((ref = item.interactive) != null ? ((ref1 = ref.settings) != null ? ref1.min : void 0) : void 0) || 0;
      },
      getMax: function () {
        var ref, ref1;
        return ((ref = item.interactive) != null ? ((ref1 = ref.settings) != null ? ref1.max : void 0) : void 0) || 100;
      },
      onChange: function (newValue) {
        item.units = newValue;
        item.cost = itemService.getCost(item);
        return itemService.ensureBounds(item);
      },
    }).ui);
  };
}.call(this));
