// Generated by CoffeeScript 1.12.7
(function () {
  var QuoteAccountSettingsUpdater, UnitsDropdown;

  QuoteAccountSettingsUpdater = require("../QuoteAccountSettingsUpdater").QuoteAccountSettingsUpdater;

  module.exports = UnitsDropdown = (function () {
    function UnitsDropdown($timeout, make, createUi, variableService, unitSelected, select, OnClickOutside) {
      var apiUpdater, defaultUnits;
      apiUpdater = make(QuoteAccountSettingsUpdater, {
        settingsName: "unitNames",
        transformFunction: (function (_this) {
          return function (unitName) {
            return unitName;
          };
        })(this),
      });
      defaultUnits = ["Unit", "Hour", "Day"];
      this.ui = createUi(require("./UnitsDropdown.html"), {
        defaultUnits: defaultUnits,
        units: variableService.getUnitNames,
        unitSelected: unitSelected,
        isSelected: (function (_this) {
          return function (unit) {
            return unit === _this.ui.unitSelected;
          };
        })(this),
        select: (function (_this) {
          return function (unit) {
            select(unit);
            _this.ui.unitSelected = unit;
            return _this.close();
          };
        })(this),
        menuVisible: false,
        toggleMenu: (function (_this) {
          return function () {
            if (_this.ui.menuVisible) {
              return _this.close();
            } else {
              return _this.open();
            }
          };
        })(this),
        newItem: "",
        editingNewItem: false,
        createNew: (function (_this) {
          return function () {
            _this.ui.editingNewItem = true;
            return _this.ui.focusOnInputBox();
          };
        })(this),
        deleteUnit: (function (_this) {
          return function (unit) {
            variableService.deleteUnit(unit);
            return apiUpdater.debouncedApiUpdate();
          };
        })(this),
        isValidUnitName: (function (_this) {
          return function (name) {
            var i, j, len, len1, ref, unit;
            if (name == null || name.length === 0) {
              return false;
            }
            for (i = 0, len = defaultUnits.length; i < len; i++) {
              unit = defaultUnits[i];
              if (name.toLowerCase() === unit.toLowerCase()) {
                return false;
              }
            }
            ref = variableService.getUnitNames();
            for (j = 0, len1 = ref.length; j < len1; j++) {
              unit = ref[j];
              if (name.toLowerCase() === unit.toLowerCase()) {
                return false;
              }
            }
            return true;
          };
        })(this),
        finishCreatingUnit: (function (_this) {
          return function (event) {
            if (event.keyCode === 13 || event.which === 13) {
              if (!_this.ui.isValidUnitName(_this.ui.newItem)) {
                return;
              }
              variableService.addUnit(_this.ui.newItem);
              apiUpdater.debouncedApiUpdate();
              _this.ui.newItem = "";
              return (_this.ui.editingNewItem = false);
            }
          };
        })(this),
        $onElementRendered: (function (_this) {
          return function (elem) {
            return (_this.ui.focusOnInputBox = function () {
              return $timeout(function () {
                var inputBox;
                inputBox = elem.find("input.edit-new-item");
                return inputBox.trigger("focus");
              });
            });
          };
        })(this),
      });
      this.open = (function (_this) {
        return function () {
          _this.ui.menuVisible = true;
          return $timeout(function () {
            return (_this.onClickOutside = make(OnClickOutside, {
              ignoreElems: [".variable-dropdown"],
              onClickOutside: function () {
                return _this.close();
              },
            }));
          });
        };
      })(this);
      this.close = (function (_this) {
        return function () {
          _this.ui.menuVisible = false;
          if (_this.onClickOutside != null) {
            _this.onClickOutside.stopListening();
            return (_this.onClickOutside = null);
          }
        };
      })(this);
    }

    return UnitsDropdown;
  })();
}.call(this));
