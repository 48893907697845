// Generated by CoffeeScript 1.12.7

/*
 * Provides a general accept setting's UI for invoice integrations to use
 */

(function () {
  var GenericInvoicer,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = GenericInvoicer = (function () {
    function GenericInvoicer(
      $timeout,
      $log,
      createUi,
      settings,
      block,
      project,
      integration,
      getCustomers,
      createInvoice,
      make,
      ActionButton,
    ) {
      this.invoiceHandler = bind(this.invoiceHandler, this);
      var createInvoiceButton;
      this.timeout = $timeout;
      this.log = $log;
      createInvoiceButton = make(ActionButton, {
        config: {
          standardIcon: "icon-document2",
          standard: "Create An Invoice",
          waiting: "Creating Invoice...",
          success: "Invoice Created",
          error: "Error Creating Invoice",
        },
        onClick: (function (_this) {
          return function () {
            var creatingInvoice, ref;
            creatingInvoice = createInvoice(
              block._id,
              (ref = settings[integration]) != null ? ref.customer.id : void 0,
              project.links[0].secret,
            );
            _this.invoiceHandler(creatingInvoice);
            return creatingInvoice;
          };
        })(this),
      });
      this.ui = createUi(require("./GenericInvoicer.html"), {
        createInvoiceButton: createInvoiceButton.ui,
        settings: settings,
        integration: integration,
        error: "",
        success: "",
        customers: [],
        $init: (function (_this) {
          return function () {
            return getCustomers()
              .then(function (customers) {
                return (_this.ui.customers = customers);
              })
              ["catch"](function (response) {
                var ref;
                _this.ui.customers = [];
                if (((ref = response.data.error) != null ? ref.message : void 0) != null) {
                  _this.ui.error = response.data.error.message;
                  return _this.log.error(response.data.error);
                }
              });
          };
        })(this),
      });
    }

    GenericInvoicer.prototype.invoiceHandler = function (promise) {
      return promise["catch"](
        (function (_this) {
          return function (err) {
            if (err == null) {
              _this.ui.error = "An unknown error occurred, please contact help@qwilr.com";
              _this.log.error(
                "An unknown error occurred in manually pushing invoice to " + integration + " for block " + block._id,
              );
            } else if (err.message != null) {
              _this.ui.error = err.message;
              _this.log.error(err);
            } else {
              _this.ui.error = err;
            }
            return _this.timeout(function () {
              return (_this.ui.error = "");
            }, 5000);
          };
        })(this),
      );
    };

    return GenericInvoicer;
  })();
}.call(this));
