"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var slate_1 = require("slate");
var Utils_1 = require("../Utils");
exports.default = (function (di) {
    return {
        tripleClick: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Triple click selection")
            .isEvent(EditorTypes_1.EventType.Click)
            .selectionIsExpanded()
            .doAction(function (event, editor) {
            if (event && event.detail === 3 && editor.selection) {
                var end = slate_1.Range.end(editor.selection);
                var blockElement = (0, Utils_1.getClosestBlock)(editor, end.path);
                var _a = __read(blockElement, 1), element = _a[0];
                var offsetFromBlockElement = (0, Utils_1.getOffsetFrom)(editor, blockElement[1], end.path);
                var isAtStartOfNextBlockElement = offsetFromBlockElement + end.offset === 0;
                var isAtStartOfImageElement = element.type === "image" && offsetFromBlockElement + end.offset === 1;
                var shouldMoveSelection = isAtStartOfNextBlockElement || isAtStartOfImageElement;
                if (shouldMoveSelection) {
                    slate_1.Transforms.move(editor, { distance: 1, reverse: true, edge: "end" });
                }
            }
            return false;
        }),
    };
});
