// Generated by CoffeeScript 1.12.7

/*
 * Override certain key events for INSERTABLE elements
 */

(function () {
  var InsertableHandler, Point, assert, backspaceHandler, ensureOpen, enterHandler, keycodes, ref, upToBlockElem;

  (keycodes = qed.keycodes), (Point = qed.Point);

  (ref = require("../Helpers")),
    (assert = ref.assert),
    (upToBlockElem = ref.upToBlockElem),
    (ensureOpen = ref.ensureOpen);

  enterHandler = require("./GenericEnterKeyHandler")("INSERTABLE");

  backspaceHandler = require("./Backspace");

  module.exports = InsertableHandler = (function () {
    function InsertableHandler(editor) {
      var addReplacementElement, deleteInsertable;
      this.onKey = function (e) {
        var keyType, point;
        keyType = keycodes.computeKeyType(e);
        switch (keyType) {
          case keycodes.types.NOEFFECT:
          case keycodes.types.NAVIGATION:
            return false;
          case keycodes.types.INPUT:
            e.preventDefault();
            return true;
          case keycodes.types.ENTER:
            enterHandler(editor, e);
            return true;
          case keycodes.types.DELETE:
            e.preventDefault();
            point = deleteInsertable(e);
            editor.selection().setCaret(point);
            return true;
          default:
            return false;
        }
      };
      this.onPaste = function (e) {
        var ref1, widgetId;
        if ((ref1 = editor.selection().getRange()) != null ? ref1.isCollapsed() : void 0) {
          widgetId = editor.widgetTracker.getWidgetIdAtPoint(editor.selection().getRange().anchor);
          assert(widgetId != null, "Couldn't find the widget-id at the cursor point");
          editor.widgetTracker.remove(widgetId);
        }
        return false;
      };
      deleteInsertable = function (e) {
        var editorElem, insertable, range, returnPoint;
        range = editor.selection().getRange();
        assert(range.isCollapsed(), "Should not be given a non-collapsed range");
        assert(
          e.which === keycodes.codes.BACKSPACE || e.which === keycodes.codes.DELETE,
          "Deleting insertable without backspace or delete keypress",
        );
        insertable = range.anchor.containingElement();
        editorElem = editor.currentElem();
        returnPoint = null;
        if (e.which === keycodes.codes.BACKSPACE) {
          if (insertable.previousElementSibling != null) {
            returnPoint = Point.end(insertable.previousElementSibling);
          } else if (insertable.nextElementSibling != null) {
            returnPoint = Point.start(insertable.nextElementSibling);
          } else {
            returnPoint = addReplacementElement(insertable);
          }
        } else {
          if (insertable.nextElementSibling != null) {
            returnPoint = Point.start(insertable.nextElementSibling);
          } else if (insertable.previousElementSibling != null) {
            returnPoint = Point.end(insertable.previousElementSibling);
          } else {
            returnPoint = addReplacementElement(insertable);
          }
        }
        insertable.parentElement.removeChild(insertable);
        return returnPoint;
      };
      addReplacementElement = function (insertable) {
        var elem;
        elem = document.createElement("p");
        ensureOpen(elem);
        insertable.parentElement.insertBefore(elem, insertable);
        return Point.start(elem);
      };
    }

    return InsertableHandler;
  })();
}.call(this));
