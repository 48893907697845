// Generated by CoffeeScript 1.12.7

/*
 * Defines a simple UI with messaging about the Oauth process. Basically just shows a loader with
 * informative message. It is up to the calling module to determine when to show the screen.
 */

(function () {
  var OauthEnablingUI;

  module.exports = OauthEnablingUI = (function () {
    function OauthEnablingUI(createUi, name) {
      this.ui = createUi(require("SettingsAngular/Integrations/OauthEnablingUI.html"), {
        name: name,
      });
    }

    return OauthEnablingUI;
  })();
}.call(this));
