"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var Types_1 = require("Common/AssetLibrary/ImageLibrary/Types");
var AbstractSystemFolderViewStore_1 = require("Common/AssetLibrary/ImageLibrary/SystemFolderStores/AbstractSystemFolderViewStore");
var Types_2 = require("@CommonFrontendBackend/AssetLibrary/Types");
var UploadsViewStore = (function (_super) {
    __extends(UploadsViewStore, _super);
    function UploadsViewStore(uploadsDataStore) {
        var _this = _super.call(this) || this;
        _this.uploadsDataStore = uploadsDataStore;
        _this.systemFolderId = Types_1.ImageLibrarySystemFolder.Uploads;
        _this.currentSearchTerm = "";
        _this.currentView = Types_1.ImageLibraryViewTypes.Images;
        return _this;
    }
    Object.defineProperty(UploadsViewStore.prototype, "searchTerm", {
        get: function () {
            return this.currentSearchTerm;
        },
        set: function (newSearchTerm) {
            this.currentSearchTerm = newSearchTerm;
        },
        enumerable: false,
        configurable: true
    });
    UploadsViewStore.prototype.resetAll = function () {
        _super.prototype.resetAll.call(this);
        this.currentSearchTerm = "";
        this.currentView = Types_1.ImageLibraryViewTypes.Images;
        this.uploadsDataStore.emptyImageList();
    };
    UploadsViewStore.prototype.useImage = function (image, useImageCallback) {
        var metricSource = this.searchTerm ? Types_2.MetricSource.SearchExisting : Types_2.MetricSource.UploadExisting;
        useImageCallback(Types_2.MediaSource.UploadedImage, {
            id: image.id,
            url: image.url,
            thumbnailUrl: image.thumbnailUrl,
        }, metricSource);
    };
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], UploadsViewStore.prototype, "currentSearchTerm", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [String])
    ], UploadsViewStore.prototype, "searchTerm", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], UploadsViewStore.prototype, "resetAll", null);
    return UploadsViewStore;
}(AbstractSystemFolderViewStore_1.AbstractSystemFolderViewStore));
exports.default = UploadsViewStore;
