"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidebarView = exports.sidebarViewLocalStorageKey = exports.sidebarLocalStorageKey = void 0;
var SidebarViewStore_1 = require("Project/Stores/SidebarViewStore/SidebarViewStore");
var mobx_1 = require("mobx");
var TelemetryEnums_1 = require("Telemetry/TelemetryEnums");
var ContentSidebarLibraryViewStore_1 = require("./Library/ContentSidebarLibraryViewStore");
exports.sidebarLocalStorageKey = "qwilr:sidebar-state";
exports.sidebarViewLocalStorageKey = "qwilr:sidebar-view";
var SidebarView;
(function (SidebarView) {
    SidebarView["Explore"] = "explore";
    SidebarView["Library"] = "library";
})(SidebarView || (exports.SidebarView = SidebarView = {}));
var ContentSidebarViewStore = (function () {
    function ContentSidebarViewStore(sidebarViewStore, telemetry, editorState, blockList, dropzonesStore, contentSidebarLibraryViewStore, sidebarExploreViewStore, clickToAddStore) {
        var _this = this;
        this.sidebarViewStore = sidebarViewStore;
        this.telemetry = telemetry;
        this.editorState = editorState;
        this.blockList = blockList;
        this.dropzonesStore = dropzonesStore;
        this.contentSidebarLibraryViewStore = contentSidebarLibraryViewStore;
        this.sidebarExploreViewStore = sidebarExploreViewStore;
        this.clickToAddStore = clickToAddStore;
        this._currentView = SidebarView.Explore;
        this.setView = function (view) {
            _this._currentView = view;
            window.localStorage.setItem(exports.sidebarViewLocalStorageKey, view);
        };
    }
    Object.defineProperty(ContentSidebarViewStore.prototype, "view", {
        get: function () {
            return this._currentView;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ContentSidebarViewStore.prototype, "isOpen", {
        get: function () {
            return this.sidebarViewStore.sidebarIsOpen(SidebarViewStore_1.SidebarType.ContentSidebar);
        },
        enumerable: false,
        configurable: true
    });
    ContentSidebarViewStore.prototype.toggle = function () {
        if (this.isOpen) {
            this.close();
        }
        else {
            this.open(TelemetryEnums_1.SidebarOpenOrigin.LibraryButton);
        }
    };
    ContentSidebarViewStore.prototype.open = function (origin, openAt) {
        if (openAt) {
            this.setView(openAt.view);
            if (openAt.view === SidebarView.Library) {
                this.contentSidebarLibraryViewStore.setCurrentTab(openAt.tab);
            }
            else if (openAt.view === SidebarView.Explore && openAt.tab) {
                this.sidebarExploreViewStore.setTab(openAt.tab);
            }
        }
        if (this.isOpen) {
            return;
        }
        this.sidebarViewStore.openSidebar(SidebarViewStore_1.SidebarType.ContentSidebar);
        var editSessionTime = this.editorState.getEditSessionTime();
        this.editorState.startSidebarSession();
        this.telemetry.trackOnly("Sidebar Opened", {
            sidebar_opened_project_edit_session_duration: editSessionTime,
            sidebar_opened_blocks_number: this.blockList.getLength(),
            sidebar_opened_origin: origin,
            sidebar_tab_type: (0, ContentSidebarLibraryViewStore_1.contentSidebarTabToSidebarTabTypeTelem)(this.contentSidebarLibraryViewStore.currentTab),
        });
        window.localStorage.setItem(exports.sidebarLocalStorageKey, "open");
    };
    ContentSidebarViewStore.prototype.close = function () {
        this.contentSidebarLibraryViewStore.hideFolders();
        this.sidebarViewStore.closeSidebar();
        this.dropzonesStore.clearPersistent();
        this.dropzonesStore.clearActiveDropzone();
        this.telemetry.trackOnly("Sidebar Closed", {
            sidebar_closed_app_session_duration: this.editorState.getSidebarSessionTime(),
            sidebar_closed_blocks_added_number: this.editorState.getNumBlocksAddedInCurrentSidebarSession(),
        });
        if (this.clickToAddStore.targetLocation === "block") {
            this.clickToAddStore.clearBlockEntry();
        }
        window.localStorage.setItem(exports.sidebarLocalStorageKey, "closed");
    };
    Object.defineProperty(ContentSidebarViewStore.prototype, "savedView", {
        get: function () {
            var savedView = window.localStorage.getItem(exports.sidebarViewLocalStorageKey);
            if (savedView) {
                return savedView;
            }
            return;
        },
        enumerable: false,
        configurable: true
    });
    var _a;
    __decorate([
        mobx_1.observable,
        __metadata("design:type", String)
    ], ContentSidebarViewStore.prototype, "_currentView", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], ContentSidebarViewStore.prototype, "view", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Object)
    ], ContentSidebarViewStore.prototype, "setView", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ContentSidebarViewStore.prototype, "isOpen", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ContentSidebarViewStore.prototype, "toggle", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_a = typeof TelemetryEnums_1.SidebarOpenOrigin !== "undefined" && TelemetryEnums_1.SidebarOpenOrigin) === "function" ? _a : Object, Object]),
        __metadata("design:returntype", void 0)
    ], ContentSidebarViewStore.prototype, "open", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ContentSidebarViewStore.prototype, "close", null);
    return ContentSidebarViewStore;
}());
exports.default = ContentSidebarViewStore;
