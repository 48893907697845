(function () {
  module.exports = function (createUi, make, accountQuoteSettings, block, telemetry) {
    var settings, taxEditor, taxes;
    taxes = accountQuoteSettings.taxes;
    taxEditor = make(require("Common/TaxEditor/TaxEditor").TaxEditor);
    settings = block.settings;
    if (!settings.enabledTaxes) {
      settings.enabledTaxes = {};
    }
    return (this.ui = createUi(require("./Taxes.html"), {
      blockSettings: settings,
      getTaxes: (function (_this) {
        return function () {
          return taxes;
        };
      })(this),
      openTaxEditor: (function (_this) {
        return function () {
          return taxEditor.show();
        };
      })(this),
      trackChange: (function (_this) {
        return function (taxId) {
          var state, tax;
          tax = accountQuoteSettings.taxes.find(function (tax) {
            return tax.id === taxId;
          });
          state = settings.enabledTaxes[taxId] === true ? "on" : "off";
          return telemetry.trackOnly(
            "Quote Block Tax Toggled",
            {
              quote_block_tax_name: tax.name,
              quote_block_tax_toggle_state: state,
            },
            {
              blockIntercom: true,
            },
          );
        };
      })(this),
    }));
  };
}.call(this));
