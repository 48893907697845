"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PromptTypes_1 = require("@CommonFrontend/Prompt/PromptTypes");
var getTelemetryID = function (promptId) {
    var _a;
    var promptToTelemetryId = (_a = {},
        _a[PromptTypes_1.PromptId.PipelineReportOnboarding] = "pipeline report",
        _a[PromptTypes_1.PromptId.IntegrationsHubspotOnboarding] = "hubspot integration",
        _a[PromptTypes_1.PromptId.TemplatesWelcome] = "templates dashboard",
        _a[PromptTypes_1.PromptId.EngagementLibraryOnboarding] = "engagement library",
        _a[PromptTypes_1.PromptId.AutomationsOverview] = "automations library",
        _a);
    var telemetryId = promptToTelemetryId[promptId];
    if (!telemetryId) {
        throw new Error("Couldn't get onboarding telemetry ID for PromptId: ".concat(promptId));
    }
    return telemetryId;
};
var InlineOnboardingTelemetry = (function () {
    function InlineOnboardingTelemetry(telemetry) {
        this.telemetry = telemetry;
    }
    InlineOnboardingTelemetry.prototype.trackViewed = function (promptId) {
        this.telemetry.trackOnly("Onboarding Card Viewed", {
            onboarding_card_name: getTelemetryID(promptId),
        });
    };
    InlineOnboardingTelemetry.prototype.trackDismissed = function (promptId, source) {
        this.telemetry.trackOnly("Onboarding Card Dismissed", {
            onboarding_card_name: getTelemetryID(promptId),
            onboarding_card_dismissed_source: source,
        });
    };
    InlineOnboardingTelemetry.prototype.trackVideoOpened = function (promptId, source) {
        this.telemetry.trackOnly("Onboarding Card Video Opened", {
            onboarding_card_name: getTelemetryID(promptId),
            onboarding_card_video_opened_source: source,
        });
    };
    InlineOnboardingTelemetry.prototype.trackTourStarted = function (promptId) {
        this.telemetry.trackOnly("Onboarding Card Tour Started", {
            onboarding_card_name: getTelemetryID(promptId),
        });
    };
    InlineOnboardingTelemetry.prototype.trackVideoPlayed = function (promptId, statistics) {
        this.telemetry.trackOnly("Onboarding Card Video Played", {
            onboarding_card_name: getTelemetryID(promptId),
            onboarding_card_video_played_finished: statistics.videoFinished,
            onboarding_card_video_played_duration: statistics.secondsWatched,
            onboarding_card_video_played_percentage: statistics.percentageWatched,
            onboarding_card_video_played_length: statistics.videoLength,
        });
    };
    return InlineOnboardingTelemetry;
}());
exports.default = InlineOnboardingTelemetry;
