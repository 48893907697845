// Generated by CoffeeScript 1.12.7

/*
  @TODO Similiar to the setup in the saved-block editor, we need to prompt users
  to save or discard their data, if they go to close the brand setup overlay with
  data (colors, subdomain, fonts etc.) that aren't saved to their account yet.
 */

/*
  UI and functionality for a user to setup the various aspects of their brand.
  This includes colors, fonts, subdomain and custom domain.

  As far as possible the intent of the interface is to demonstrate the effects
  of their aesthetic choices in a close-to-real-world mockup. This makes it
  faster and easier for our users to assess the merit of their stylistic choices.
 */

(function () {
  var FeatureFlag, isUsingNewBrandSetUp;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  isUsingNewBrandSetUp = require("@CommonFrontendBackend/FeatureFlag").isUsingNewBrandSetUp;

  module.exports = function (
    options,
    createUi,
    account,
    overlayStack,
    invoke,
    make,
    SubdomainSetter,
    CustomDomainSetter,
    TypographySelector,
    accountService,
    usingFeature,
    upseller,
    user,
    $timeout,
    primaryDomain,
  ) {
    var brandColorsSelector,
      brandExampleCopy,
      brandExampleCopyOptions,
      domain,
      ref,
      sections,
      subdomain,
      typographySelector,
      ui,
      usingNewBrandSetup;
    usingNewBrandSetup = isUsingNewBrandSetUp(usingFeature);
    domain = make(CustomDomainSetter, {
      options: {},
    });
    subdomain = make(SubdomainSetter, {
      options: {},
    });
    typographySelector = make(TypographySelector, {
      options: {},
    });
    brandColorsSelector = make(require("./Colors/Colors"), {
      options: {},
    });
    sections = [];
    if (!usingNewBrandSetup) {
      sections = [
        {
          name: "Colors",
          description: "Choose colors for your documents.",
          icon: "Colors",
          widget: brandColorsSelector.ui,
          $open: options.gotoState === "colors",
          internalId: "colors",
        },
        {
          name: "Fonts",
          description: "Setup your typography.",
          icon: "Fonts",
          widget: typographySelector.ui,
          $open: options.gotoState === "fonts",
          internalId: "fonts",
        },
      ];
    }
    sections.push({
      name: "Subdomain",
      description: "A subdomain URL to serve your pages from.",
      icon: "SocialNetworkBW",
      widget: subdomain.ui,
      $open: options.gotoState === "subdomain",
      internalId: "subdomain",
      $upsell: !usingFeature(FeatureFlag.Subdomain),
      $upsellData: {
        feature: "subdomain",
        featureLabel: "Custom Subdomain",
      },
    });
    sections.push({
      name: "Custom Domain",
      description: "A custom domain URL to serve your pages from.",
      icon: "SocialNetwork",
      widget: domain.ui,
      $open: options.gotoState === "custom-domain",
      internalId: "custom-domain",
      $upsell: !usingFeature(FeatureFlag.Domain),
      $upsellData: {
        feature: "domain",
        featureLabel: "Custom Domain",
        customUpsellMessage:
          "Upgrade to set up a branded custom domain that doesn't reference to Qwilr (eg. documents.yourbusiness.com).",
      },
    });
    brandExampleCopyOptions = [
      {
        title: "Your Success",
        subheading: "Is what matters. Don't limit yourself. Dream big. You can create what you can imagine.",
        body: [
          "Success is rarely about how much money you make. It's about the difference you can make.",
          "At Qwilr, we dream big. Our dream is to help you make your business successful. We created Qwilr with a single goal in mind: to help you achieve and realize your biggest dreams.",
          "The critical ingredient is getting off your butt and doing something. It's as simple as that. A lot of people have ideas, but there are few who decide to do something about them now. Not tomorrow. Not next week. But today. The true entrepreneur is a doer, not a dreamer. – Nolan Bushnell",
        ],
      },
      {
        title: "Read Me",
        subheading: "While you're getting comfortable with Qwilr, try answering the following questions:",
        body: [
          "<b>Q:</b> Will my clients 'get it'?<br/><b>A:</b> Qwilrs are responsive web pages and look great on any device your client or team views them on. If they can browse Facebook, they will be able to view and interact with your Qwilr page.",
          "<b>Q:</b> Can I connect my other apps to work with Qwilr?<br/><b>A:</b> Yes. We've got you covered with a dozen native integrations. We also have a Zapier integration, so you can connect all your apps.",
          "<b>Q:</b> Can I be notified when a Qwilr page is opened?<br/><b>A:</b> Indeed. You get a ton of useful stats when someone opens a Qwilr page, how much time they spent on each section, and even their location.",
        ],
      },
      {
        title: "Qwilr 101",
        subheading: "10 quick tips on how to use Qwilr to save hours and close more deals.",
        body: [
          "1. Don't start from scratch. We have dozens of proposal templates in our Template Library. You're welcome.",
          "2. Enable Slack notifications to be notified when your Qwilr pages are viewed. Strike while the iron's hot!",
          "3. Integrate Stripe with Qwilr and accept payments right in the Qwilr page.",
          "4. Password-protect important Qwilr pages to limit undesirable access. You can also enable time- and view-limit security features if you're paranoid. Who isn't these days?",
          "5. Save the blocks you're going to use often. You can reuse blocks in any of your Qwilr pages. Feeling like a pro already?",
          "6. Pay attention to Qwilr page analytics. It will help you understand what sections are important to your clients and how you can use them to your advantage.",
          "7. Embed forms, maps, videos and any other web content straight into your Qwilr pages. It's 2017, right about time.",
          "8. You can get PDF versions of Qwilr pages in the 'Share' screen.",
          "9. Take a break, get outside, be mindful. This tip is worth two. Also, we ran out of space.",
        ],
      },
    ];
    brandExampleCopy = brandExampleCopyOptions[_.random(0, 2)];
    ui = createUi(require("./BrandSetup.html"), {
      colors: brandColorsSelector.colors,
      fonts: typographySelector.currentlySelectedFonts,
      account: account,
      sections: sections,
      brandExampleCopy: brandExampleCopy,
      hasOpenSection: ((ref = options.gotoState) != null ? ref.length : void 0) > 0,
      usingNewBrandSetup: usingNewBrandSetup,
      isFaded: function (section) {
        return ui.hasOpenSection && !section.$open;
      },
      toggleSection: function (section) {
        var i, len, theSection;
        if (section.$upsell) {
          return upseller.upsell(section.$upsellData);
        } else {
          if (section.$open) {
            section.$open = false;
            return (ui.hasOpenSection = false);
          } else {
            for (i = 0, len = sections.length; i < len; i++) {
              theSection = sections[i];
              theSection.$open = false;
            }
            ui.hasOpenSection = true;
            section.$open = true;
            return typeof options.onSectionOpen === "function" ? options.onSectionOpen(section.internalId) : void 0;
          }
        }
      },
      getDemoUrlHost: function () {
        if (
          accountService.hasCustomDomain() &&
          _.find(sections, {
            name: "Custom Domain",
          }).$open
        ) {
          return accountService.getCustomDomain();
        } else if (
          accountService.hasSubdomain() &&
          _.find(sections, {
            name: "Subdomain",
          }).$open
        ) {
          return accountService.getSubdomain() + "." + primaryDomain;
        } else if (accountService.hasCustomDomain()) {
          return accountService.getCustomDomain();
        } else if (accountService.hasSubdomain()) {
          return accountService.getSubdomain() + "." + primaryDomain;
        } else {
          return "pages." + primaryDomain;
        }
      },
      $onElementRendered: function (elem) {
        var focusToInput;
        focusToInput = function (selectorString) {
          return $timeout(function () {
            return $(elem).find(selectorString).focus();
          }, 250);
        };
        switch (options.gotoState) {
          case "subdomain":
            return focusToInput(".subdomain-input .domain-input");
          case "custom-domain":
            return focusToInput(".custom-domain-setter .domain-input");
        }
      },
    });
    return overlayStack.add({
      contentUi: ui,
      options: {
        className: "mini-close-button",
        onClose: options.onClose,
      },
    });
  };
}.call(this));
