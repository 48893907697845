"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccordionActionType = exports.MarkType = exports.ListType = exports.InlineType = exports.BlockType = exports.isHTMLElement = exports.WidgetAdderPosition = exports.SideBySideActionType = exports.ContentLoopActionType = exports.VariableActionType = exports.TableActionType = exports.CustomEvent = exports.EventType = exports.IMark = exports.SlateEditor = void 0;
var Models_1 = require("ContentTokens/Models");
Object.defineProperty(exports, "BlockType", { enumerable: true, get: function () { return Models_1.BlockType; } });
Object.defineProperty(exports, "InlineType", { enumerable: true, get: function () { return Models_1.InlineType; } });
var List_1 = require("ContentTokens/Models/List");
Object.defineProperty(exports, "ListType", { enumerable: true, get: function () { return List_1.ListItemType; } });
var Text_1 = require("ContentTokens/Models/Text");
Object.defineProperty(exports, "MarkType", { enumerable: true, get: function () { return Text_1.MarkType; } });
var DIProvider_1 = require("DIProvider/DIProvider");
exports.SlateEditor = (0, DIProvider_1.makeServiceIdentifier)(Symbol("SlateEditor"));
var Text_2 = require("ContentTokens/Models/Text");
Object.defineProperty(exports, "IMark", { enumerable: true, get: function () { return Text_2.Mark; } });
var EventType;
(function (EventType) {
    EventType["Any"] = "any";
    EventType["Key"] = "keydown";
    EventType["Toolbar"] = "toolbar";
    EventType["Paste"] = "paste";
    EventType["Copy"] = "copy";
    EventType["Widget"] = "widget";
    EventType["SyntheticSelection"] = "syntheticSelection";
    EventType["Selection"] = "selection";
    EventType["DragStart"] = "dragstart";
    EventType["Drop"] = "drop";
    EventType["DragOver"] = "dragover";
    EventType["DragEnter"] = "dragenter";
    EventType["DragEnd"] = "dragend";
    EventType["Cut"] = "cut";
    EventType["Insert"] = "insert";
    EventType["Click"] = "click";
})(EventType || (exports.EventType = EventType = {}));
var CustomEvent = (function () {
    function CustomEvent(type) {
        this.type = type;
    }
    return CustomEvent;
}());
exports.CustomEvent = CustomEvent;
var TableActionType;
(function (TableActionType) {
    TableActionType["DeleteRows"] = "deleteRows";
    TableActionType["AddRow"] = "addRow";
    TableActionType["AddRepeatingRow"] = "addRepeatingRow";
    TableActionType["SetRepeatingRowToken"] = "setRepeatingRowToken";
    TableActionType["DeleteColumns"] = "deleteColumns";
    TableActionType["AddColumn"] = "addColumn";
    TableActionType["ResizeColumns"] = "resizeColumns";
})(TableActionType || (exports.TableActionType = TableActionType = {}));
var VariableActionType;
(function (VariableActionType) {
    VariableActionType["DeleteVariable"] = "deleteVariable";
    VariableActionType["Bold"] = "boldVariable";
    VariableActionType["Italic"] = "italicVariable";
    VariableActionType["Strikethrough"] = "strikethroughVariable";
    VariableActionType["Insert"] = "insertVariable";
    VariableActionType["Cancel"] = "cancelVariable";
})(VariableActionType || (exports.VariableActionType = VariableActionType = {}));
var ContentLoopActionType;
(function (ContentLoopActionType) {
    ContentLoopActionType["SetContentLoopVariable"] = "setContentLoopVariable";
    ContentLoopActionType["RemoveContentLoopVariable"] = "removeContentLoopVariable";
    ContentLoopActionType["DropContentLoopVariable"] = "dropContentLoopVariable";
})(ContentLoopActionType || (exports.ContentLoopActionType = ContentLoopActionType = {}));
var SideBySideActionType;
(function (SideBySideActionType) {
    SideBySideActionType["AddSideBySide"] = "addSideBySide";
    SideBySideActionType["DeleteSideBySide"] = "deleteSideBySide";
    SideBySideActionType["SetPercentage"] = "setPercentageForSideBySide";
    SideBySideActionType["SwapOrder"] = "swapOrderForSideBySide";
    SideBySideActionType["SetWidth"] = "setWidthForSideBySide";
    SideBySideActionType["SelectSideBySide"] = "selectSideBySide";
    SideBySideActionType["DeselectSideBySide"] = "deselectSideBySide";
    SideBySideActionType["DismissPlaceholder"] = "dismissPlaceholder";
    SideBySideActionType["PickImage"] = "pickImage";
    SideBySideActionType["EmbedImage"] = "embedImage";
})(SideBySideActionType || (exports.SideBySideActionType = SideBySideActionType = {}));
var WidgetAdderPosition;
(function (WidgetAdderPosition) {
    WidgetAdderPosition["Selection"] = "selection";
    WidgetAdderPosition["AfterLastLine"] = "after";
})(WidgetAdderPosition || (exports.WidgetAdderPosition = WidgetAdderPosition = {}));
var isHTMLElement = function (editorElem) {
    return editorElem instanceof window.Element;
};
exports.isHTMLElement = isHTMLElement;
var AccordionActionType;
(function (AccordionActionType) {
    AccordionActionType["SelectAccordion"] = "selectAccordion";
    AccordionActionType["SetExpanded"] = "setExpanded";
    AccordionActionType["SwapImage"] = "swapImage";
    AccordionActionType["EnsureVisualAccordionImages"] = "ensureVisualAccordionImages";
})(AccordionActionType || (exports.AccordionActionType = AccordionActionType = {}));
