"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var MultiSelectStore = (function () {
    function MultiSelectStore() {
        this.selectedIndices = new Set();
        this.mostRecentIndex = null;
    }
    Object.defineProperty(MultiSelectStore.prototype, "sortedIndices", {
        get: function () {
            return Array.from(this.selectedIndices).sort(function (a, b) { return a - b; });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(MultiSelectStore.prototype, "selectedCount", {
        get: function () {
            return this.selectedIndices.size;
        },
        enumerable: false,
        configurable: true
    });
    MultiSelectStore.prototype.isSelected = function (index) {
        return this.selectedIndices.has(index);
    };
    MultiSelectStore.prototype.clearAllSelections = function () {
        this.selectedIndices.clear();
        this.mostRecentIndex = null;
    };
    MultiSelectStore.prototype.addSelection = function (index) {
        this.selectedIndices.add(index);
        this.mostRecentIndex = index;
    };
    MultiSelectStore.prototype.setSelection = function (indices) {
        this.selectedIndices = new Set(indices);
        this.mostRecentIndex = null;
    };
    MultiSelectStore.prototype.removeSelection = function (index) {
        this.selectedIndices.delete(index);
        this.mostRecentIndex = index;
    };
    MultiSelectStore.prototype.toggleSelection = function (index) {
        if (this.selectedIndices.has(index)) {
            this.removeSelection(index);
        }
        else {
            this.addSelection(index);
        }
    };
    MultiSelectStore.prototype.rangeSelect = function (clickIndex) {
        var _a;
        if (clickIndex < 0) {
            throw new Error("Range-select index below 0: ".concat(clickIndex));
        }
        var anchor = (_a = this.mostRecentIndex) !== null && _a !== void 0 ? _a : clickIndex;
        var rangeBottom = Math.min(anchor, clickIndex);
        var rangeTop = Math.max(anchor, clickIndex);
        if (this.isSelected(clickIndex)) {
            for (var i = rangeBottom; i <= rangeTop; ++i) {
                this.selectedIndices.delete(i);
            }
        }
        else {
            for (var i = rangeBottom; i <= rangeTop; ++i) {
                this.selectedIndices.add(i);
            }
        }
        this.mostRecentIndex = clickIndex;
    };
    var _a;
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", typeof (_a = typeof Set !== "undefined" && Set) === "function" ? _a : Object)
    ], MultiSelectStore.prototype, "selectedIndices", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], MultiSelectStore.prototype, "sortedIndices", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], MultiSelectStore.prototype, "clearAllSelections", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], MultiSelectStore.prototype, "addSelection", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], MultiSelectStore.prototype, "setSelection", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], MultiSelectStore.prototype, "removeSelection", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], MultiSelectStore.prototype, "toggleSelection", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Number]),
        __metadata("design:returntype", void 0)
    ], MultiSelectStore.prototype, "rangeSelect", null);
    return MultiSelectStore;
}());
exports.default = MultiSelectStore;
