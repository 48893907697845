"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var Types_1 = require("Common/AssetLibrary/ImageLibrary/Types");
var AbstractSystemFolderViewStore_1 = require("../AbstractSystemFolderViewStore");
var Types_2 = require("@CommonFrontendBackend/AssetLibrary/Types");
var UnsplashViewStore = (function (_super) {
    __extends(UnsplashViewStore, _super);
    function UnsplashViewStore(unsplashDataStore) {
        var _this = _super.call(this) || this;
        _this.unsplashDataStore = unsplashDataStore;
        _this.systemFolderId = Types_1.ImageLibrarySystemFolder.Unsplash;
        _this.currentSearchTerm = "";
        _this.currentView = Types_1.ImageLibraryViewTypes.Collections;
        return _this;
    }
    Object.defineProperty(UnsplashViewStore.prototype, "searchTerm", {
        get: function () {
            return this.currentSearchTerm;
        },
        set: function (newSearchTerm) {
            if (!newSearchTerm) {
                this.currentSearchTerm = "";
                this.currentView = Types_1.ImageLibraryViewTypes.Collections;
                return;
            }
            if (this.currentSearchTerm === "") {
                this.currentlySelectedCollection = undefined;
                this.currentView = Types_1.ImageLibraryViewTypes.Images;
            }
            this.currentSearchTerm = newSearchTerm;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(UnsplashViewStore.prototype, "selectedCollection", {
        get: function () {
            return this.currentlySelectedCollection;
        },
        enumerable: false,
        configurable: true
    });
    UnsplashViewStore.prototype.selectCollection = function (collection) {
        return __awaiter(this, void 0, Promise, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        this.imagePageIndex = 0;
                        this.unsplashDataStore.emptyImageList();
                        this.currentlySelectedCollection = collection;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, 4, 5]);
                        return [4, this.unsplashDataStore.fetchCollectionImages(collection.collectionId)];
                    case 2:
                        _a.sent();
                        this.currentView = Types_1.ImageLibraryViewTypes.Images;
                        return [3, 5];
                    case 3:
                        e_1 = _a.sent();
                        this.currentView = Types_1.ImageLibraryViewTypes.ErrorMessages;
                        return [3, 5];
                    case 4:
                        this.isLoading = false;
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    UnsplashViewStore.prototype.showQwilrStockImages = function () {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isLoading = true;
                        return [4, this.resetAll()];
                    case 1:
                        _a.sent();
                        return [4, this.unsplashDataStore.fetchQwilrStockImages()];
                    case 2:
                        _a.sent();
                        this.currentView = Types_1.ImageLibraryViewTypes.StockImages;
                        this.isLoading = false;
                        return [2];
                }
            });
        });
    };
    UnsplashViewStore.prototype.resetAll = function () {
        _super.prototype.resetAll.call(this);
        this.currentSearchTerm = "";
        this.currentView = Types_1.ImageLibraryViewTypes.Collections;
        this.currentlySelectedCollection = undefined;
        this.unsplashDataStore.emptyImageList();
        this.unsplashDataStore.emptyCollectionList();
    };
    UnsplashViewStore.prototype.useImage = function (image, useImageCallback) {
        var metricSource;
        var mediaSource;
        if (this.currentView === Types_1.ImageLibraryViewTypes.StockImages) {
            mediaSource = Types_2.MediaSource.StockImages;
            metricSource = Types_2.MetricSource.StockImage;
        }
        else {
            mediaSource = Types_2.MediaSource.Unsplash;
            metricSource = this.currentSearchTerm ? Types_2.MetricSource.SearchGallery : Types_2.MetricSource.Collection;
        }
        useImageCallback(mediaSource, {
            id: image.id,
            url: image.url,
            thumbnailUrl: image.thumbnailUrl,
            altDescription: image.altDescription,
        }, metricSource);
    };
    var _a, _b, _c, _d, _e;
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], UnsplashViewStore.prototype, "currentSearchTerm", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", typeof (_a = typeof Types_1.IUnsplashCollection !== "undefined" && Types_1.IUnsplashCollection) === "function" ? _a : Object)
    ], UnsplashViewStore.prototype, "currentlySelectedCollection", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [String])
    ], UnsplashViewStore.prototype, "searchTerm", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_b = typeof Types_1.IUnsplashCollection !== "undefined" && Types_1.IUnsplashCollection) === "function" ? _b : Object),
        __metadata("design:paramtypes", [])
    ], UnsplashViewStore.prototype, "selectedCollection", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_c = typeof Types_1.IUnsplashCollection !== "undefined" && Types_1.IUnsplashCollection) === "function" ? _c : Object]),
        __metadata("design:returntype", typeof (_d = typeof Promise !== "undefined" && Promise) === "function" ? _d : Object)
    ], UnsplashViewStore.prototype, "selectCollection", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", typeof (_e = typeof Promise !== "undefined" && Promise) === "function" ? _e : Object)
    ], UnsplashViewStore.prototype, "showQwilrStockImages", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], UnsplashViewStore.prototype, "resetAll", null);
    return UnsplashViewStore;
}(AbstractSystemFolderViewStore_1.AbstractSystemFolderViewStore));
exports.default = UnsplashViewStore;
