// Generated by CoffeeScript 1.12.7

/*
  Component to select a user from userList

  @param defaultSelection - user to be selected by default
  @param excludeUsers [] - array of users which should be excluded from search list

  Selected user is available as "selectedUser" property on the instance of this class
 */

(function () {
  var UserSelector;

  module.exports = UserSelector = (function () {
    function UserSelector(
      defaultSelection,
      excludeUsers,
      createUi,
      userList,
      getFullName,
      userIconFactory,
      rolesService,
    ) {
      var users;
      this.selectedUser = defaultSelection;
      users = userList
        .filter(function (user) {
          return !_.includes(
            excludeUsers.map(function (_user) {
              return _user._id;
            }),
            user._id,
          );
        })
        .map(function (user) {
          return {
            _id: user._id,
            icon: userIconFactory.create(user, {
              backgroundColor: "#81A2B2",
            }),
            name: getFullName(user),
            email: user.email,
            role: rolesService.getRole(user),
          };
        });
      this.ui = createUi(require("./UserSelector.html"), {
        users: users,
        selectedUser: _.find(users, function (user) {
          return user._id === defaultSelection._id;
        }),
        filteredUsers: users,
        filterUsers: (function (_this) {
          return function () {
            return (_this.ui.filteredUsers = _this.ui.users.filter(function (user) {
              return !_this.ui.filter || user.name.match(new RegExp("\\b" + _this.ui.filter, "i"));
            }));
          };
        })(this),
        selectUser: (function (_this) {
          return function (user) {
            _this.ui.selectedUser = user;
            _this.selectedUser = userList.find(function (_user) {
              return _user._id === user._id;
            });
            return (_this.ui.showDropdown = false);
          };
        })(this),
        toggleDropdown: (function (_this) {
          return function () {
            return (_this.ui.showDropdown = !_this.ui.showDropdown);
          };
        })(this),
      });
    }

    return UserSelector;
  })();
}.call(this));
