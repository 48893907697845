"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasDeselectElementClicked = void 0;
var lodash_1 = require("lodash");
var deselectElementClasses = ["new-editor", "block-wrapper", "qwilr-quote-v2", "section-style", "section-divider"];
var isNewEditorChildElementClicked = function (elementClassList) { return (0, lodash_1.includes)(elementClassList, "new-editor"); };
var hasDeselectElementClicked = function (eventTarget) {
    if (!eventTarget.parentElement ||
        !eventTarget.parentElement.parentElement ||
        !eventTarget.parentElement.parentElement.parentElement) {
        throw new Error("Given eventTarget with too few parentElements");
    }
    var isEditorChildElementClicked = isNewEditorChildElementClicked(eventTarget.parentElement.classList) ||
        isNewEditorChildElementClicked(eventTarget.parentElement.parentElement.classList) ||
        isNewEditorChildElementClicked(eventTarget.parentElement.parentElement.parentElement.classList);
    var isDeselectElementClicked = !!(0, lodash_1.find)(eventTarget.classList, function (className) {
        return deselectElementClasses.includes(className);
    });
    return isEditorChildElementClicked || isDeselectElementClicked;
};
exports.hasDeselectElementClicked = hasDeselectElementClicked;
