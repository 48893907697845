// Generated by CoffeeScript 1.12.7
(function () {
  var AdvancedEditor;

  module.exports = AdvancedEditor = (function () {
    function AdvancedEditor(createUi, style, elements, getElementDescription, onChange, make) {
      var CodeEditorController, editableElements, elementObj, fn, i, len, onChangeFn, ref;
      CodeEditorController = require("Common/CodeEditor/CodeEditor.js");
      if (style.styleModel == null || style.styleModel.length === 0) {
        editableElements = _.concat([""], elements);
        style.styleModel = _.map(editableElements, function (element) {
          return {
            name: getElementDescription(element),
            element: element,
            rawStyles: "",
          };
        });
      }
      onChangeFn = function (elementObj) {
        return function (rawStyles) {
          elementObj.rawStyles = rawStyles;
          return onChange();
        };
      };
      ref = style.styleModel;
      fn = function (elementObj) {
        return (elementObj.$editor = make(CodeEditorController, {
          language: "css",
          model: elementObj.rawStyles,
          onChange: onChangeFn(elementObj),
        }));
      };
      for (i = 0, len = ref.length; i < len; i++) {
        elementObj = ref[i];
        fn(elementObj);
      }
      this.ui = createUi(require("./AdvancedEditor.html"), {
        style: style,
      });
    }

    return AdvancedEditor;
  })();
}.call(this));
