// Generated by CoffeeScript 1.12.7

/*
  Defines a "wait for X" type condition so that steps
  in a sequence can have some flexibility around the conditions
  under which they initiate.

  We need this since DOM will transition into focus,
  or a step will only be relevant once X event has happened -
  this means that a step cannot initiate instantly, or it won't
  have the necessary DOM setup.
 */

(function () {
  module.exports = function ($q, $timeout, $interval) {
    /*
    
      @param config [Object] configuration for the WaitFor. It must contain:
      - `type` [String] which type of WaitFor. Can be one of:
        - `timeout` for known time scenarios.
        - `check` to supply a condition checking function.
        - `listenFor` to leverage DOM events as our checking condition.
        - `elemExists` shorthand function for checking that a particular DOM element exists.
    
      And depending on the type, it can optionally contain:
    
        - Type: timeout
          `waitTime` (optional) [Integer] amount of time to wait in milliseconds. Defaults to 500.
    
        - Type: check
          `checkFunction` [Function] returns boolean flag indicating whether the WaitFor should complete.
          `interval` [Integer] (optional) how long we should wait between running iterations of checking function.
          `maxTime` [Integer] (optional) a maximum amount of time we should run the checking function for.
    
        - Type: listenFor
          `target` [String] selector string for the DOM object we're expecting the event to have as its target.
          `listenerElem` (optional) [String] selector string for the DOM object to listen for events. Defaults to "body".
          `event` (optional) [String] event to listen for. Defaults to "click".
    
        - Type: elemExists
          `element`: [String] selector string for the DOM we're looking for.
     */
    var waitFor;
    waitFor = function (config) {
      var check, elemExists, listenFor, timeout, waitForTypes, waiting;
      waiting = $q.defer();
      timeout = function () {
        var time;
        time = config.waitTime || 500;
        return $timeout(function () {
          return waiting.resolve();
        }, time);
      };
      listenFor = function () {
        if (config.listenerElem == null) {
          config.listenerElem = "body";
        }
        if (config.event == null) {
          config.event = "click";
        }
        return $(config.listenerElem).on(config.event, function (event) {
          if ($(event.target).is(config.target)) {
            return $timeout(waiting.resolve);
          }
        });
      };
      check = function () {
        var interval, maxTries, stop;
        interval = config.interval || 100;
        if (config.maxTime) {
          maxTries = parseInt(maxTime / interval);
        } else {
          maxTries = 0;
        }
        return (stop = $interval(
          function () {
            if (config.checkFunction()) {
              waiting.resolve();
              return $interval.cancel(stop);
            }
          },
          interval,
          maxTries,
        ));
      };
      elemExists = function () {
        config.interval = 250;
        config.checkFunction = function () {
          return $(config.element).length > 0;
        };
        return check();
      };
      waitForTypes = {
        timeout: timeout,
        listenFor: listenFor,
        check: check,
        elemExists: elemExists,
      };
      waitFor = waitForTypes[config.type];
      if (waitFor == null) {
        throw new Error("Got an unknown type of WaitFor: " + config.type);
      }
      waitFor();
      return waiting.promise;
    };
    return waitFor;
  };
}.call(this));
