// Generated by CoffeeScript 1.12.7

/*
  Quote Module Dependency Injection Wiring.

  This module provides a map of dependencies that are used throughout
  the quote module.
 */

(function () {
  var injectables, maker;

  maker = function (constructor, extraArgs) {
    if (extraArgs == null) {
      extraArgs = {};
    }
    return function (make) {
      return make(constructor, extraArgs);
    };
  };

  injectables = {
    itemService: maker(require("./Item/ItemService")),
    sectionService: maker(require("./Section/SectionService")),
    quoteService: maker(require("./QuoteService")),
    currency: function (block) {
      return function () {
        return block.settings.currency;
      };
    },
    currencySelector: maker(require("./Controls/Currency/Currency")),
    ratesPanel: maker(require("./RatesPanel/RatesPanel")),
    taxes: function (account) {
      return account.settings.modules.quote.taxes;
    },
    toggleDisplayMode: function (viewState, projectScrollTo, block, $timeout) {
      return function () {
        viewState.displayMode = true;
        return $timeout(function () {
          var quoteBlock;
          quoteBlock = $("#" + block.$id).find(".display-mode");
          return projectScrollTo(quoteBlock, {
            offset: 200,
          });
        });
      };
    },
    QuoteModel: require("@CommonFrontendBackend/BlockModels/Quote"),
    quickBooksTaxMapChecker: maker(require("./Controls/Taxes/QuickBooksTaxMapChecker")),
    defaultRate: function (account) {
      return account.settings.modules.quote.rates[0];
    },
    telemetryDebounceInterval: 1000,
  };

  module.exports = injectables;
}.call(this));
