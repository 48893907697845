"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEndpoint = exports.isDocx = exports.isFont = exports.isTiff = exports.isGif = exports.isImage = exports.islargeFile = exports.isSmallFile = exports.UploadEndpoint = exports.FileType = exports.BYTES_IN_1KB = void 0;
exports.BYTES_IN_1KB = 1024;
var FileType;
(function (FileType) {
    FileType["Image"] = "image/";
    FileType["Docx"] = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    FileType["Gif"] = "image/gif";
    FileType["Tiff"] = "image/tiff";
})(FileType || (exports.FileType = FileType = {}));
var UploadEndpoint;
(function (UploadEndpoint) {
    UploadEndpoint["Image"] = "upload/images";
    UploadEndpoint["Font"] = "upload/fonts";
    UploadEndpoint["Docx"] = "upload/docx";
})(UploadEndpoint || (exports.UploadEndpoint = UploadEndpoint = {}));
var isSmallFile = function (file, thresholdInKb) {
    var kb = file.size / exports.BYTES_IN_1KB;
    return kb < thresholdInKb;
};
exports.isSmallFile = isSmallFile;
var islargeFile = function (file, thresholdInKb) {
    var kb = file.size / exports.BYTES_IN_1KB;
    return kb > thresholdInKb;
};
exports.islargeFile = islargeFile;
var isImage = function (file) {
    return file.type.startsWith(FileType.Image);
};
exports.isImage = isImage;
var isGif = function (file) {
    return file.type === FileType.Gif;
};
exports.isGif = isGif;
var isTiff = function (file) {
    return file.type === FileType.Tiff;
};
exports.isTiff = isTiff;
var isFont = function (file) {
    return false;
};
exports.isFont = isFont;
var isDocx = function (file) {
    return file.type.includes("".concat(FileType.Docx));
};
exports.isDocx = isDocx;
var getEndpoint = function (file) {
    if ((0, exports.isImage)(file)) {
        return UploadEndpoint.Image;
    }
    else if ((0, exports.isFont)(file)) {
        return UploadEndpoint.Font;
    }
    else if ((0, exports.isDocx)(file)) {
        return UploadEndpoint.Docx;
    }
    else {
        throw new Error("File not supported");
    }
};
exports.getEndpoint = getEndpoint;
exports.default = {
    BYTES_IN_1KB: exports.BYTES_IN_1KB,
    FileType: FileType,
    UploadEndpoint: UploadEndpoint,
    isImage: exports.isImage,
    isGif: exports.isGif,
    isTiff: exports.isTiff,
    isFont: exports.isFont,
    isDocx: exports.isDocx,
    isSmallFile: exports.isSmallFile,
    islargeFile: exports.islargeFile,
    getEndpoint: exports.getEndpoint,
};
