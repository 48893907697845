// Generated by CoffeeScript 1.12.7

/*
 * Uses the generic manual invoicer module
 */

(function () {
  var FreshBooksInvoicer;

  module.exports = FreshBooksInvoicer = (function () {
    function FreshBooksInvoicer(manualInvoicer) {
      this.show = manualInvoicer.show("freshbooks");
    }

    return FreshBooksInvoicer;
  })();
}.call(this));
