// Generated by CoffeeScript 1.12.7
(function () {
  var MenuDropdown;

  module.exports = MenuDropdown = (function () {
    /*
      @param items [Array] An array of ui items for the menu dropdown.
      @param toggleButtonElem [String] A selector string for the element which acts as the toggle button for the menu.
      @param options [Object]
        - hideOnClick [Boolean] When you click an item, it will hide the dropdown
        - keepOnDom [Boolean] The dropdown will use ng-show instead of ng-if so that it loads right away
         instead of lazily
        - onHide [Function] A function to be run whenever the dropdown disappears
        - onShow [Function] A function to be run whenever the dropdown appears
     */
    function MenuDropdown(make, createUi, items, toggleButtonElem, $timeout, OnClickOutside, options) {
      this.ui = createUi(require("./MenuDropdown.html"), {
        visible: false,
        items: items,
        onClick: (function (_this) {
          return function () {
            if (options.hideOnClick) {
              _this.hide();
              return typeof options.onHide === "function" ? options.onHide() : void 0;
            }
          };
        })(this),
      });
      if (options.keepOnDom) {
        this.ui.inDom = true;
      } else {
        this.ui.inDom = false;
      }
      this.toggle = (function (_this) {
        return function () {
          if (_this.ui.visible) {
            return _this.hide();
          } else {
            return _this.show();
          }
        };
      })(this);
      this.show = (function (_this) {
        return function () {
          var onClickOutside;
          _this.ui.visible = true;
          if (typeof options.onShow === "function") {
            options.onShow();
          }
          if (!options.keepOnDom) {
            _this.ui.inDom = true;
          }
          return (onClickOutside = make(OnClickOutside, {
            ignoreElems: [toggleButtonElem, ".menu-dropdown-position"],
            onClickOutside: function () {
              _this.hide();
              if (typeof options.onHide === "function") {
                options.onHide();
              }
              return onClickOutside.stopListening();
            },
          }));
        };
      })(this);
      this.hide = (function (_this) {
        return function () {
          _this.ui.visible = false;
          if (!options.keepOnDom) {
            return (_this.ui.inDom = false);
          }
        };
      })(this);
      this.updateItems = (function (_this) {
        return function (items) {
          return (_this.ui.items = items);
        };
      })(this);
    }

    return MenuDropdown;
  })();
}.call(this));
