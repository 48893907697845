/*
  This module creates UI for the currency selector.
 */

(function () {
  module.exports = function (createUi, currencies, block, overlayStack, telemetry) {
    this.ui = createUi(require("./Currency.html"), {
      currencies: currencies,
      quoteCurrency: block.settings.currency,
      setCurrency: (function (_this) {
        return function (currency) {
          block.settings.currency = currency;
          _this.ui.quoteCurrency = currency;
          overlayStack.closeCurrent();
          return telemetry.trackOnly("Quote Block Currency Changed", {
            quote_block_currency: currency.name,
          });
        };
      })(this),
      isActive: (function (_this) {
        return function (currency) {
          return _this.ui.quoteCurrency.code === currency.code;
        };
      })(this),
    });
    return (this.show = (function (_this) {
      return function () {
        return overlayStack.add(_this.ui);
      };
    })(this));
  };
}.call(this));
