"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var VideoPlayer_1 = require("@CommonFrontendBackend/Video/VideoPlayer");
var Text = (function () {
    function Text(block, make, createUi, Editor, viewState, videoEmbedUrlGenerator, VideoBackground) {
        var _this = this;
        var mainContent = make(Editor, {
            model: block.contentAreas.mainContent,
            options: {},
            configuration: "standard",
        });
        this.ui = createUi(require("./Text.html"), {
            viewState: viewState,
            mainContent: mainContent.ui,
            hasVideo: false,
            videoUrl: "",
            videoProvider: "",
            youtubePlayer: null,
            $init: function ($scope) { return __awaiter(_this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    $scope.$watch(function () {
                        return block.style;
                    }, function (style) { return __awaiter(_this, void 0, void 0, function () {
                        var hasVideo, videoUrl, provider, playableUrl;
                        var _a;
                        return __generator(this, function (_b) {
                            switch (_b.label) {
                                case 0:
                                    hasVideo = (style === null || style === void 0 ? void 0 : style.background.type) === "video" && !!(style === null || style === void 0 ? void 0 : style.background.url);
                                    if (!hasVideo) return [3, 3];
                                    videoUrl = (_a = style.background.url) !== null && _a !== void 0 ? _a : "";
                                    provider = (0, VideoPlayer_1.getVideoPlayerTypeFromUrl)(videoUrl);
                                    if (!(provider === "vimeo")) return [3, 2];
                                    return [4, videoEmbedUrlGenerator.getEmbedUrl(videoUrl)];
                                case 1:
                                    playableUrl = _b.sent();
                                    videoUrl = "".concat(playableUrl, "?background=1");
                                    _b.label = 2;
                                case 2:
                                    if (provider === "youtube") {
                                        this.ui.youtubePlayer = make(VideoBackground, { url: videoUrl });
                                    }
                                    this.ui.hasVideo = true;
                                    this.ui.videoUrl = videoUrl;
                                    this.ui.videoProvider = provider;
                                    return [3, 4];
                                case 3:
                                    this.ui.hasVideo = false;
                                    this.ui.videoUrl = "";
                                    this.ui.videoProvider = "";
                                    this.ui.youtubePlayer = null;
                                    _b.label = 4;
                                case 4: return [2];
                            }
                        });
                    }); }, true);
                    return [2];
                });
            }); },
        });
    }
    return Text;
}());
exports.default = Text;
