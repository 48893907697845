"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var EditorTypes_1 = require("../EditorTypes");
var LayoutActions_1 = require("./LayoutActions");
exports.default = (function (di) {
    var editorTelemetry = di.get("editorTelemetry");
    return [
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("resizeLayoutColumn")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "layout", action: "resizeColumn" })
            .doAction(function (event, editor) {
            (0, LayoutActions_1.resizeLayoutColumn)(editor, event.data.path, {
                columnIndex: event.data.columnIndex,
                distance: event.data.distance,
            });
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("addColumn")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "layout", action: "addColumn" })
            .doAction(function (event, editor) {
            (0, LayoutActions_1.addLayoutColumn)(editor, event.data.path);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("addColumnAtIndex")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "layout", action: "addColumnAtIndex" })
            .doAction(function (event, editor) {
            (0, LayoutActions_1.addLayoutColumnAtIndex)(editor, event.data.path, event.data.addColumnAtIndex);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("moveColumn")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "layout", action: "moveColumn" })
            .doAction(function (event, editor) {
            (0, LayoutActions_1.moveLayoutColumn)(editor, event.data.path, event.data.currentColumnIndex, event.data.newColumnIndex);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("removeColumnAtIndex")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "layout", action: "removeColumnAtIndex" })
            .doAction(function (event, editor) {
            (0, LayoutActions_1.removeLayoutColumn)(editor, event.data.path, event.data.columnIndex);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("cloneColumn")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "layout", action: "cloneColumn" })
            .doAction(function (event, editor) {
            (0, LayoutActions_1.cloneLayoutColumn)(editor, event.data.path, event.data.columnIndex);
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("updateLayoutGap")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "layout", action: "updateGap" })
            .doAction(function (event, editor) {
            (0, LayoutActions_1.updateLayoutGap)(editor, event.data.path, {
                gap: event.data.gap,
            });
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("updateLayoutPadding")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "layout", action: "updatePadding" })
            .doAction(function (event, editor) {
            (0, LayoutActions_1.updateLayoutPadding)(editor, event.data.path, {
                padding: event.data.padding,
            });
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("initialiseWithColumns")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "layout", action: "initialiseWithColumns" })
            .doAction(function (event, editor) {
            (0, LayoutActions_1.initialiseWithColumns)(editor, event.data.path, event.data.numberOfColumns);
            editorTelemetry.layoutTypeAdded({
                layoutType: "".concat(event.data.numberOfColumns, "_column"),
            });
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("updateLayoutAlignment")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "layout", action: "updateAlignment" })
            .doAction(function (event, editor) {
            (0, LayoutActions_1.updateLayoutAlignment)(editor, event.data.path, {
                alignment: event.data.alignment,
            });
            return true;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("tidyColumns")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "layout", action: "tidyColumns" })
            .doAction(function (event, editor) {
            (0, LayoutActions_1.tidyColumns)(editor, event.data.path);
            return true;
        }),
    ];
});
