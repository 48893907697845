// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = angular.module("qwilr.video", []).directive("video", function () {
    return {
      restrict: "E",
      link: function (scope, element, attrs) {
        return scope.$on("$destroy", function () {
          return element.prop("src", "");
        });
      },
    };
  });
}.call(this));
