"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.NONE_PAGE_FILTER = exports.EveryoneInAccountId = exports.DEFAULT_HISTORY_SIZE_MAPPING = exports.includedPagesTypeTelemetryMapping = exports.ReportsTelemetryOrigin = exports.ReportTelemetryEventName = exports.SelectedVelocityHistoryFilter = exports.SelectedStatusHistoryFilter = exports.AnalyticsPageMetric = exports.PeriodGranularity = exports.TimePeriod = exports.OwnerFilterType = void 0;
var Types_1 = require("@CommonFrontendBackend/Account/Types");
var OwnerFilterType;
(function (OwnerFilterType) {
    OwnerFilterType["USER"] = "user";
    OwnerFilterType["TEAM"] = "team";
    OwnerFilterType["ACCOUNT"] = "account";
})(OwnerFilterType || (exports.OwnerFilterType = OwnerFilterType = {}));
var TimePeriod;
(function (TimePeriod) {
    TimePeriod["WEEK"] = "week";
    TimePeriod["MONTH"] = "month";
    TimePeriod["QUARTER"] = "quarter";
    TimePeriod["YEAR"] = "year";
    TimePeriod["CUSTOM"] = "custom";
})(TimePeriod || (exports.TimePeriod = TimePeriod = {}));
var PeriodGranularity;
(function (PeriodGranularity) {
    PeriodGranularity["WEEK"] = "P1W";
    PeriodGranularity["MONTH"] = "P1M";
    PeriodGranularity["QUARTER"] = "P3M";
    PeriodGranularity["YEAR"] = "P1Y";
})(PeriodGranularity || (exports.PeriodGranularity = PeriodGranularity = {}));
var AnalyticsPageMetric;
(function (AnalyticsPageMetric) {
    AnalyticsPageMetric["PageCount"] = "Count";
    AnalyticsPageMetric["PageValue"] = "Value";
})(AnalyticsPageMetric || (exports.AnalyticsPageMetric = AnalyticsPageMetric = {}));
var SelectedStatusHistoryFilter;
(function (SelectedStatusHistoryFilter) {
    SelectedStatusHistoryFilter["Count"] = "Count";
    SelectedStatusHistoryFilter["Value"] = "Value";
})(SelectedStatusHistoryFilter || (exports.SelectedStatusHistoryFilter = SelectedStatusHistoryFilter = {}));
var SelectedVelocityHistoryFilter;
(function (SelectedVelocityHistoryFilter) {
    SelectedVelocityHistoryFilter["AverageClose"] = "pageVelocity.pageCycleTimes.averageClose";
    SelectedVelocityHistoryFilter["AveragePageValue"] = "pageVelocity.averagePageValue";
    SelectedVelocityHistoryFilter["CloseAcceptRate"] = "pageVelocity.closeAcceptRate";
})(SelectedVelocityHistoryFilter || (exports.SelectedVelocityHistoryFilter = SelectedVelocityHistoryFilter = {}));
var ReportTelemetryEventName;
(function (ReportTelemetryEventName) {
    ReportTelemetryEventName["ReportViewed"] = "Report Viewed";
    ReportTelemetryEventName["PipelineReportAction"] = "Pipeline Report Action";
    ReportTelemetryEventName["ReportFilterChanged"] = "Report Filter Changed";
    ReportTelemetryEventName["ReportCollectionChanged"] = "Report Collection Changed";
    ReportTelemetryEventName["ReportCleanupChanged"] = "Report Cleanup Changed";
    ReportTelemetryEventName["AnalyticsAndReportingSettingsChanged"] = "Analytics and Reporting Settings Changed";
    ReportTelemetryEventName["CollectionChanged"] = "Collection Changed";
    ReportTelemetryEventName["CleanupChanged"] = "Cleanup Changed";
})(ReportTelemetryEventName || (exports.ReportTelemetryEventName = ReportTelemetryEventName = {}));
var ReportsTelemetryOrigin;
(function (ReportsTelemetryOrigin) {
    ReportsTelemetryOrigin["Pipeline"] = "pipeline";
})(ReportsTelemetryOrigin || (exports.ReportsTelemetryOrigin = ReportsTelemetryOrigin = {}));
exports.includedPagesTypeTelemetryMapping = {
    acceptBlock: "accept",
    quoteBlock: "quote",
    acceptOrQuoteBlock: "quote_or_accept",
    value: "has_value",
    anyContent: "any_content",
};
exports.DEFAULT_HISTORY_SIZE_MAPPING = (_a = {},
    _a[PeriodGranularity.WEEK] = -5,
    _a[PeriodGranularity.MONTH] = -5,
    _a[PeriodGranularity.QUARTER] = -4,
    _a[PeriodGranularity.YEAR] = -5,
    _a);
exports.EveryoneInAccountId = "everyoneInAccount";
exports.NONE_PAGE_FILTER = { templateIds: [], tags: [], pageWith: Types_1.PageWithOption.AnyContent };
