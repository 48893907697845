// Generated by CoffeeScript 1.12.7
(function () {
  var InsertItemMenu, menuTemplate;

  menuTemplate = require("./InsertItemMenu.html");

  /*
  The menu inside the Tooltip for inserting items
   */

  module.exports = InsertItemMenu = (function () {
    function InsertItemMenu(createUi, insertItem, insertSavedLineItems, savedLineItemsLibrary, block) {
      var createItemType, itemTypes;
      itemTypes = [];
      createItemType = function (itemDisplayName, onItemClick, icon) {
        return itemTypes.push({
          itemDisplayName: itemDisplayName,
          onClick: function () {
            return onItemClick();
          },
          icon: icon,
        });
      };
      createItemType(
        "Variable based",
        function () {
          return insertItem("variable");
        },
        "Assets/icons/small/variable-16.svg",
      );
      createItemType(
        "Fixed cost item",
        function () {
          return insertItem("fixedCost");
        },
        "Assets/icons/small/fixed-16.svg",
      );
      createItemType(
        "Text item",
        function () {
          return insertItem("text");
        },
        "Assets/icons/small/text-16.svg",
      );
      createItemType(
        "Saved item",
        function () {
          return savedLineItemsLibrary.open(block.settings.currency).then(function (itemsToInsert) {
            return insertSavedLineItems(itemsToInsert);
          });
        },
        "Assets/icons/small/saved-16.svg",
      );
      this.ui = createUi(menuTemplate, {
        getItemTypes: function () {
          return itemTypes;
        },
      });
    }

    return InsertItemMenu;
  })();
}.call(this));
