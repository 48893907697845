"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActiveTokenToolbarStore = void 0;
var mobx_1 = require("mobx");
var ActiveTokenToolbarStore = (function () {
    function ActiveTokenToolbarStore() {
        this.activeToolbarId = null;
    }
    ActiveTokenToolbarStore.prototype.setActiveVariableToolbar = function (id) {
        this.activeToolbarId = id;
    };
    ActiveTokenToolbarStore.prototype.clearActiveVariableToolbar = function () {
        this.activeToolbarId = null;
    };
    Object.defineProperty(ActiveTokenToolbarStore.prototype, "currentActiveVariableToolbarId", {
        get: function () {
            return this.activeToolbarId;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], ActiveTokenToolbarStore.prototype, "activeToolbarId", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ActiveTokenToolbarStore.prototype, "setActiveVariableToolbar", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ActiveTokenToolbarStore.prototype, "clearActiveVariableToolbar", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], ActiveTokenToolbarStore.prototype, "currentActiveVariableToolbarId", null);
    return ActiveTokenToolbarStore;
}());
exports.ActiveTokenToolbarStore = ActiveTokenToolbarStore;
