// Generated by CoffeeScript 1.12.7

/*
  Defines the Undo for a editor instance
 */

(function () {
  var Undo, UndoStack, bowser;

  UndoStack = require("./UndoStack");

  bowser = require("bowser");

  module.exports.create = function (di, make, $timeout, editor, block, element, getEditorHtml) {
    var checkpoint, getBlockDataLoader, shouldCheckpoint, undoStack;
    shouldCheckpoint = function (currState) {
      var cleanedContent, cleanedStateContent, clearCursorPosition, content, widgets;
      clearCursorPosition = function (content) {
        return content
          .replace('<span data-marker="start"></span>', "")
          .replace('<span data-marker="end"></span>', "")
          .replace(/[ ]style\=\"height: [0-9]+px;\"/, "");
      };
      if (currState == null) {
        return true;
      }
      content = getEditorHtml();
      widgets = block.getWidgets();
      cleanedContent = clearCursorPosition(content);
      cleanedStateContent = clearCursorPosition(currState.content);
      return cleanedStateContent !== cleanedContent || !block.isEquivalentToWidgetState(currState.widgets);
    };
    getBlockDataLoader = function (block, loadFunc) {
      return function (state) {
        var data;
        data = _.cloneDeep(state);
        return $timeout(function () {
          block.updateHtml(data.content);
          block.updateWidgets(data.widgets);
          return loadFunc(data);
        });
      };
    };
    checkpoint = function (loadFunc) {
      var blockContent, blockData;
      blockContent = getEditorHtml();
      blockData = _.cloneDeep({
        content: blockContent,
        widgets: block.getWidgets(),
      });
      blockData.loader = getBlockDataLoader(block, loadFunc);
      return blockData;
    };
    if (di.has("undoStack")) {
      undoStack = di.get("undoStack");
    } else {
      undoStack = new UndoStack(shouldCheckpoint);
      di.value("undoStack", undoStack);
      di.value("undoCheckpoint", checkpoint);
    }
    return make(Undo, {
      editor: editor,
      block: block,
      element: element,
      getEditorHtml: getEditorHtml,
    });
  };

  Undo = (function () {
    function Undo(editor, block, element, $timeout, getEditorHtml, undoStack, undoCheckpoint, editorFocus) {
      var deserialize, getLoadFunc, keys, loadState, me, serialize;
      me = this;
      keys = qed.keycodes;
      this.stack = undoStack;
      this.editor = editor;
      this.block = block;
      this.elem = element[0];
      this.CHECKPOINT_DEBOUNCE_TIME = 2000;
      this.CHECKPOINT_MINIMUM_TIME = 2000;
      this.undo = function () {
        if (!me.stack.canUndo()) {
          return;
        }
        me.unthrottledCheckpoint(true);
        return $timeout(function () {
          var state;
          state = me.stack.undo();
          if (state != null) {
            return loadState(state);
          }
        });
      };
      this.redo = function () {
        if (!me.stack.canRedo()) {
          return;
        }
        return $timeout(function () {
          var state;
          state = me.stack.redo();
          if (state != null) {
            return loadState(state);
          }
        });
      };
      getLoadFunc = function (editor, contentWithSelection, block) {
        return function (state) {
          return $timeout(function () {
            if (contentWithSelection != null) {
              return $timeout(function () {
                var end, start;
                start = $("[data-marker=start]")[0];
                end = $("[data-marker=end]")[0];
                if (!(start != null && end != null)) {
                  return editorFocus();
                } else {
                  editor.selection().setMarkers({
                    start: start,
                    end: end,
                  });
                  editor.selection().loadFromMarkers();
                  return editor.currentElem().normalize();
                }
              });
            } else {
              return editorFocus();
            }
          });
        };
      };
      this.unthrottledCheckpoint = function (createFuture) {
        if (createFuture == null) {
          createFuture = false;
        }
        return $timeout(function () {
          var contentWithSelection, created, loadFunc, range, saveWithSelection;
          if (!me.stack.shouldCheckpoint()) {
            return;
          }
          if (bowser.name !== "Safari") {
            saveWithSelection = true;
          }
          range = editor.selection().getRange();
          if (range != null && saveWithSelection) {
            editor.selection().saveToMarkers();
            contentWithSelection = getEditorHtml();
          }
          block = me.block;
          loadFunc = getLoadFunc(editor, contentWithSelection, block);
          created = me.stack.checkpoint(undoCheckpoint(loadFunc));
          if (createFuture && created) {
            me.stack.pos--;
          }
          if (range != null && saveWithSelection) {
            editor.selection().loadFromMarkers();
            return editor.currentElem().normalize();
          }
        });
      };
      this.checkpoint = _.throttle(me.unthrottledCheckpoint, me.CHECKPOINT_DEBOUNCE_TIME, {
        leading: true,
        trailing: false,
      });
      this.remove = function () {};
      this.genericCheckpointKeys = [keys.codes.ENTER, keys.codes.SPACE, 190];
      loadState = function (state) {
        state.loader(state);
      };
      serialize = (function (_this) {
        return function (block) {
          var state;
          state = _.cloneDeep(block);
          return state;
        };
      })(this);
      deserialize = (function (_this) {
        return function (state) {
          block = _.cloneDeep(state);
          return block;
        };
      })(this);
    }

    return Undo;
  })();

  module.exports.Undo = Undo;
}.call(this));
