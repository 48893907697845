// Generated by CoffeeScript 1.12.7
(function () {
  var Size;

  module.exports = Size = (function () {
    function Size(createUi, block) {
      this.ui = createUi(require("Project/Modules/Splash/Controls/Size/Size.html"), {
        setSize: function (size) {
          return (block.settings.size = size.cssClassName);
        },
        isSelected: function (size) {
          return block.settings.size === size.cssClassName;
        },
        sizes: [
          {
            name: "One Third",
            height: 33,
            cssClassName: "third",
          },
          {
            name: "Half Page",
            height: 60,
            cssClassName: "half",
          },
          {
            name: "Full Page",
            height: 100,
            cssClassName: "fullpage",
          },
        ],
      });
    }

    return Size;
  })();
}.call(this));
