// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = {
    QwilrColors: ["#00a99d", "#46baf9", "#c93d2d", "#ff883f", "#b4c636", "#ffcd3f"],
    ColorPalette: [
      {
        name: "Aqua",
        color: "#7FDBFF",
        darkText: true,
      },
      {
        name: "Blue",
        color: "#0074D9",
      },
      {
        name: "Navy",
        color: "#001F3F",
      },
      {
        name: "Teal",
        color: "#39CCCC",
        darkText: true,
      },
      {
        name: "Green",
        color: "#2ECC40",
      },
      {
        name: "Olive",
        color: "#3D9970",
      },
      {
        name: "Lime",
        color: "#01FF70",
        darkText: true,
      },
      {
        name: "Yellow",
        color: "#FFDC00",
        darkText: true,
      },
      {
        name: "Orange",
        color: "#FF851B",
      },
      {
        name: "Red",
        color: "#FF4136",
      },
      {
        name: "Fuchsia",
        color: "#F012BE",
      },
      {
        name: "Purple",
        color: "#B10DC9",
      },
      {
        name: "Maroon",
        color: "#85144B",
      },
      {
        name: "Silver",
        color: "#ddd",
        darkText: true,
      },
      {
        name: "Gray",
        color: "#aaa",
        darkText: true,
      },
      {
        name: "Black",
        color: "#111",
      },
    ],
  };
}.call(this));
