"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.extractAttributerCookie = exports.buildSSOUrl = void 0;
var buildSSOUrl = function (_a) {
    var baseUrl = _a.baseUrl, authRoute = _a.authRoute, attributionStr = _a.attributionStr, inviteHash = _a.inviteHash, inviteAccountId = _a.inviteAccountId;
    var resultUrl = new URL("".concat(baseUrl).concat(authRoute));
    if (attributionStr) {
        resultUrl.searchParams.append("ma", attributionStr);
    }
    if (inviteHash && inviteAccountId) {
        resultUrl.searchParams.append("invite_hash", inviteHash);
        resultUrl.searchParams.append("invite_account", inviteAccountId);
    }
    return resultUrl.toString();
};
exports.buildSSOUrl = buildSSOUrl;
var extractAttributerCookie = function (doc) {
    var _a;
    var attributerCookieName = "flaretrk";
    var results = (_a = doc === null || doc === void 0 ? void 0 : doc.cookie) === null || _a === void 0 ? void 0 : _a.match("".concat(attributerCookieName, "=(.*?)(;|$)"));
    if (results)
        return results[1];
};
exports.extractAttributerCookie = extractAttributerCookie;
