"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var LinkActions = __importStar(require("./LinkActions"));
var QwilrEditorTelemetry_1 = require("../QwilrEditorTelemetry");
exports.default = (function (di) {
    var editorTelemetry = di.get("editorTelemetry");
    return {
        onLinkUpdate: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("linkWidgetUpdate")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "link", action: "update" })
            .doAction(function (event, editor) {
            editorTelemetry.hyperlinkURLAdded(event.data.href, QwilrEditorTelemetry_1.HyperlinkAddedSource.Toolbar);
            LinkActions.updateLinkWidget(editor, event.data.path, event.data.href);
            return true;
        }),
        onLinkRemove: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("linkWidgetRemove")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "link", action: "remove" })
            .doAction(function (event, editor) {
            LinkActions.removeLinkWidget(editor, event.data.path);
            return true;
        }),
    };
});
