// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = [
    {
      name: "Turquoise",
      hex: "#1abc9c",
      rgb: "rgb(26, 188, 156)",
    },
    {
      name: "Greensea",
      hex: "#16a085",
      rgb: "rgb(22, 160, 133)",
    },
    {
      name: "Emerland",
      hex: "#2ecc71",
      rgb: "rgb(46, 204, 113)",
    },
    {
      name: "Nephritis",
      hex: "#27ae60",
      rgb: "rgb(39, 174, 96)",
    },
    {
      name: "Peterriver",
      hex: "#3498db",
      rgb: "rgb(52, 152, 219)",
    },
    {
      name: "Belizehole",
      hex: "#2980b9",
      rgb: "rgb(41, 128, 185)",
    },
    {
      name: "Amethyst",
      hex: "#9b59b6",
      rgb: "rgb(155, 89, 182)",
    },
    {
      name: "Wisteria",
      hex: "#8e44ad",
      rgb: "rgb(142, 68, 173)",
    },
    {
      name: "Wetasphalt",
      hex: "#34495e",
      rgb: "rgb(52, 73, 94)",
    },
    {
      name: "Midnightblue",
      hex: "#2c3e50",
      rgb: "rgb(44, 62, 80)",
    },
    {
      name: "Sunflower",
      hex: "#f1c40f",
      rgb: "rgb(241, 196, 15)",
    },
    {
      name: "Orange",
      hex: "#f39c12",
      rgb: "rgb(243, 156, 18)",
    },
    {
      name: "Carrot",
      hex: "#e67e22",
      rgb: "rgb(230, 126, 34)",
    },
    {
      name: "Pumpkin",
      hex: "#d35400",
      rgb: "rgb(211, 84, 0)",
    },
    {
      name: "Alizarin",
      hex: "#e74c3c",
      rgb: "rgb(231, 76, 60)",
    },
    {
      name: "Pomegranate",
      hex: "#c0392b",
      rgb: "rgb(192, 57, 43)",
    },
  ];
}.call(this));
