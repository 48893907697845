// Generated by CoffeeScript 1.12.7

/*
  Controls for the default account section of Xero Settings
 */

(function () {
  var XeroDefaultAccount;

  module.exports = XeroDefaultAccount = (function () {
    function XeroDefaultAccount($http, $log, createUi, make, account, apiBaseUrl, xero, ActionButton) {
      var refreshButton;
      this.account = account;
      this.apiBaseUrl = apiBaseUrl;
      this.xero = xero;
      this.http = $http;
      this.log = $log;
      refreshButton = make(ActionButton, {
        config: {
          standard: "Refresh Accounts List",
          waiting: "Refreshing Accounts...",
          success: "Refreshed",
          error: "Could not refresh list",
        },
        onClick: (function (_this) {
          return function () {
            return _this.xero.getAccounts();
          };
        })(this),
      });
      this.ui = createUi(require("SettingsAngular/Integrations/Xero/XeroDefaultAccount.html"), {
        refreshButton: refreshButton.ui,
        selectedAccount: null,
        defaultAccount: (function (_this) {
          return function () {
            var ref, ref1, ref2;
            if (
              ((ref = _this.account.integrations) != null
                ? (ref1 = ref.xero) != null
                  ? (ref2 = ref1.settings) != null
                    ? ref2.defaultAccount
                    : void 0
                  : void 0
                : void 0) != null
            ) {
              return _this.account.integrations.xero.settings.defaultAccount.name;
            } else {
              return "None";
            }
          };
        })(this),
        setDefaultAccount: (function (_this) {
          return function (selected) {
            var postData;
            postData = {
              "integrations.xero.settings.defaultAccount.code": selected.Code,
              "integrations.xero.settings.defaultAccount.name": selected.Name,
            };
            return _this.http
              .post(_this.apiBaseUrl + "/accounts/" + _this.account._id, postData)
              .then(function (response) {
                var base;
                if ((base = _this.account.integrations.xero).settings == null) {
                  base.settings = {};
                }
                return (_this.account.integrations.xero.settings.defaultAccount = {
                  name: selected.Name,
                  code: selected.Code,
                });
              })
              ["catch"](function (err) {
                return _this.log.error(err);
              });
          };
        })(this),
        getAccounts: this.xero.getAccounts,
        $init: (function (_this) {
          return function (scope) {
            return _this.ui.getAccounts().then(function (accounts) {
              return (_this.ui.accounts = accounts);
            });
          };
        })(this),
      });
      if (this.ui.defaultAccount() !== "None") {
        this.ui.selectedAccount = this.account.integrations.xero.settings.defaultAccount;
      }
    }

    return XeroDefaultAccount;
  })();
}.call(this));
