import "Application/Library/AutomationLibrary/AssignmentModal.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/AutomationLibrary/AssignmentModal.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61VzW7bMAy+5ykEDAOagwo7cdpOue0VOmDHQpZoW6kiGZKc2B327oNkx5Vat+uKnQxT/PlIfiSvH/KDrTenJkO/Vgi1lHOhaoJusrbfrxA6C+4agvIs+7pf/V5dX9TzoF5S9lgb3SmOmZbaEHSi5gqPP9h2pqIMfoBxgprh4SEv5e3hkB/W3nOpDQeDDeWiswTtxnil7rFtKNdngjplwXmhbikTbiAou97tY5S7tkd37wPdfBzoPTCteIxUrJPweeJ5GzxzYVtJB4IqCQHIobNOVANmWjlQjiDbUga4BHcGUF6DSlErLBwcLUEMlAOTOC6C40orh614ggtWL7gXT4Ad9A73dka5OQWY50Y4wCEaQUqfDW29OGjrE5hK+qKClKK1wvqnI+3xVLdNNjV8zqeUmj2G9GfbRnA+pnCkphYKS6jcBZ4PLFQdA8sf10liu+WK+S/mwgBzQiuCmJbdMYSpaeuhjcj+Vrab4H2hvQ1QT5cZFqSobj9S7qjaXWp+F8yDUkBIkK/KfhmKV5s9FaFt/9znYHAGUTcuNvkZJNhA3UlqZqNtFk+b0y1Bedsjq6Xg0cMCVk9go+X3oPDc03z9TACCsrafGzSPZV60PUoXCKOSXYXpRBgV3mq9xLaort9Sssx0fLtekpYg4049pZ2inx3YhIZvLZpypJHncm38tKTbgr3/zMf1q60YZ4CWVsvOQZhg37TtFN6MfR9/Iwfwqdmatm4zsWmzTZMssmL8v+wAPBBEO6cTWf+8GSJA1bh4R3pZN0ggiFPbQEy7SzFTGEI1YISLgESSTxL2xbn58M6ql+s6c/3ucre8+/8zWy+gThHiflVCOh+tNnSwjEq4ytNLFQ5llEXz1m4UTKs5fpumLkbKTrFeX+N8v3hZv1RVlfg5LJcwJFS8t9v/AKj+im2dCAAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var assignmentModalBody = '_1jsg2vh0';
export var automationItem = '_1jsg2vh1';
export var enabled = '_1jsg2vh2';
export var cardHeader = '_1jsg2vh3';
export var checkboxLabel = '_1jsg2vh4';
export var content = '_1jsg2vh5';
export var title = '_1jsg2vh6';
export var subtitle = '_1jsg2vh7';
export var description = '_1jsg2vh8';
export var descriptionInfo = '_1jsg2vh9';
export var footer = '_1jsg2vha';
export var confirmButton = '_1jsg2vhb';
export var cancelButton = '_1jsg2vhc';
export var closeButton = '_1jsg2vhd';
export var automationsList = '_1jsg2vhe';
export var emptyAutomationsList = '_1jsg2vhf';
export var integrationIcons = '_1jsg2vhg';
export var chevronRight = '_1jsg2vhh';
export var selectedAutomation = '_1jsg2vhi';
export var rightOptions = '_1jsg2vhj';