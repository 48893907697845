"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var getDefaultItem = function (defaultItem, items) {
    return _getDefaultItem(defaultItem, items) || items[0];
};
var _getDefaultItem = function (defaultItem, items) {
    if (!defaultItem) {
        return items[0];
    }
    else if (typeof defaultItem === "string") {
        return items.find(function (item) {
            return item.id === defaultItem;
        });
    }
    else {
        return items.find(function (item) {
            return item.id === defaultItem.id;
        });
    }
};
exports.default = (function (createUi) {
    return (function () {
        function RadioButtons(items, click, defaultItem) {
            this.click = click;
            var me = this;
            this._selected = getDefaultItem(defaultItem, items);
            this.ui = createUi(require("./RadioButtons.html"), {
                items: items,
                getSelected: function () {
                    return me.selected;
                },
                select: function (item) {
                    me.selected = item;
                },
            });
        }
        Object.defineProperty(RadioButtons.prototype, "selected", {
            get: function () {
                return this._selected;
            },
            set: function (item) {
                if (!item.disabled) {
                    this._selected = item;
                    this.click(item);
                }
            },
            enumerable: false,
            configurable: true
        });
        return RadioButtons;
    }());
});
