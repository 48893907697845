// Generated by CoffeeScript 1.12.7
(function () {
  var FeatureFlag, LinkPublicClonable;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = LinkPublicClonable = (function () {
    function LinkPublicClonable(createUi, link, usingFeature, getDisabledMessage) {
      this.ui = createUi(require("./PublicCloneable.html"), {
        featureAvailable: usingFeature(FeatureFlag.PublicClonableProjects),
        getDisabledMessage: getDisabledMessage,
        isPublicEditable: link.isPublicEditable,
        publicEditableToggled: (function (_this) {
          return function () {
            return (link.isPublicEditable = _this.ui.isPublicEditable);
          };
        })(this),
      });
    }

    return LinkPublicClonable;
  })();
}.call(this));
