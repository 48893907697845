/*
  UI and logic for the Accept settings of a Quote block.
  Here users can (de)select and configure options for their
  desired Accept flow.
 */

(function () {
  var Accept;

  Accept = require("Common/Utils/ImageImports").Accept;

  module.exports = Accept = (function () {
    function Accept(
      createUi,
      imageLocation,
      upseller,
      settings,
      di,
      projectScrollTo,
      markAcceptEnabled,
      block,
      usingFeature,
      telemetry,
      integrationService,
    ) {
      var options;
      if (settings.active == null) {
        settings.active = true;
      }
      options = di.invoke(require("./AcceptOptions"), null);
      this.ui = createUi(require("./Accept.html"), {
        settings: settings,
        options: options,
        acceptIcon: Accept,
        usingAccountingIntegration: integrationService.usingAccountingIntegration(),
        hasUi: function (ui) {
          return ui != null;
        },
        toggleAcceptOption: (function (_this) {
          return function (option) {
            var ref, ref1, upsellOptions;
            if (((ref = option.upsellFlag) != null ? ref.length : void 0) && !usingFeature(option.upsellFlag)) {
              _this.ui.settings[option.internalName] = false;
              upsellOptions = {
                feature: option.upsellFlag,
                featureLabel: option.name,
                blur: $("#content-wrap"),
              };
              if (option.customUpsellOptions != null) {
                _.assign(upsellOptions, option.customUpsellOptions);
              }
              return upseller.upsell(upsellOptions);
            } else {
              if (_this.ui.settings[option.internalName] === true) {
                if ((ref1 = option.eventName) != null ? ref1.length : void 0) {
                  telemetry.trackOnly(option.eventName + " Enabled");
                }
                if (option.onEnabled != null) {
                  return option.onEnabled();
                }
              }
            }
          };
        })(this),
        toggleAccept: function () {
          var offset;
          settings.active = !settings.active;
          if (settings.active) {
            telemetry.trackOnly("Accept Button Enabled");
          } else if (!settings.active) {
            telemetry.trackOnly(
              "Accept Button Disabled",
              {},
              {
                blockIntercom: true,
              },
            );
          }
          markAcceptEnabled();
          if (settings.active === false) {
            offset = 300;
          } else {
            offset = 50;
          }
          return projectScrollTo($("#" + block.$id + " .new-accept-settings"), {
            offset: offset,
          });
        },
        buttonLabel: (function (_this) {
          return function () {
            var button;
            if (_this.ui.settings.active) {
              button = {
                label: "Disable Accept",
                icon: "cross",
              };
            } else {
              button = {
                label: "Enable Accept",
                icon: "check",
              };
            }
            return button;
          };
        })(this),
      });
    }

    return Accept;
  })();
}.call(this));
