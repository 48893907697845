// Generated by CoffeeScript 1.12.7

/*
  Displays IDs of Qwilr objects for use with the API.

  For some objects like Saved Blocks, which have their own search and listing UI,
  it makes sense to have IDs displayed in those contexts, for other objects
  like quote rates, where it doesn't really make sense, we can list their IDs here.
 */

(function () {
  var ObjectIdsController;

  module.exports = ObjectIdsController = (function () {
    function ObjectIdsController(createUi, account, variableService, labelService) {
      var groups, hasObjectIds, labelSetIds, taxIds, variableIds;
      taxIds = _.map(account.settings.modules.quote.taxes, function (tax) {
        return {
          label: tax.name,
          value: tax.id,
        };
      });
      variableIds = _.map(variableService.getAllVariables(), function (v) {
        return {
          label: v.name,
          value: v.getId(),
        };
      });
      labelSetIds = _.map(labelService.getActiveLabelSets(), function (labelSet) {
        return {
          label: labelSet.name,
          value: labelSet.id,
        };
      });
      groups = [
        {
          title: "Taxes",
          items: taxIds,
        },
        {
          title: "Variables",
          items: variableIds,
        },
        {
          title: "Custom Label Sets",
          items: labelSetIds,
        },
      ];
      hasObjectIds = account.settings.modules.quote.taxes.length > 0;
      this.ui = createUi(require("./ObjectIds.html"), {
        groups: groups,
        hasObjectIds: hasObjectIds,
      });
    }

    return ObjectIdsController;
  })();
}.call(this));
