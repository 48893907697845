"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isExistingUser = exports.UserType = void 0;
var UserType;
(function (UserType) {
    UserType["Existing"] = "existing";
    UserType["Pending"] = "pending";
})(UserType || (exports.UserType = UserType = {}));
var isExistingUser = function (user) {
    return user.firstName != undefined;
};
exports.isExistingUser = isExistingUser;
