"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewersList = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var Utils_1 = require("../../Utils");
var ViewerAvatar_1 = require("../../Components/ViewerAvatar");
var LocationFlag_1 = require("../../Components/LocationFlag");
var UseLoadMore_1 = require("../../Components/UseLoadMore");
var LinkedInSearch_1 = require("../../Components/LinkedInSearch");
var styles = __importStar(require("./ViewersList.css"));
var inversify_react_1 = require("inversify-react");
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var commonIconProps = {
    size: icons_1.IconSize.Small,
    color: "default",
};
var ViewersList = function (_a) {
    var viewers = _a.viewers, onViewerCardClick = _a.onViewerCardClick, noViewsYetComponent = _a.noViewsYetComponent, timeZone = _a.timeZone;
    var _b = (0, UseLoadMore_1.useLoadMore)(viewers, {
        initialItemCount: 8,
        increment: 20,
    }), itemsToShow = _b.itemsToShow, loadMoreButtons = _b.loadMoreButtons;
    var serverTelemetry = (0, inversify_react_1.useInjection)("serverTelemetry");
    (0, react_1.useEffect)(function () {
        serverTelemetry.sendPageAnalyticsTelemetry({
            eventName: event_tracker_types_1.EventName.ViewerTimelineTabViewed,
            eventOrigin: event_tracker_types_1.EventOrigin.PageAnalytics,
            tabName: "viewers",
        });
    }, []);
    return (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.viewersTab, align: "center", gap: "m" },
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "center", gap: "m", wrap: true }, noViewsYetComponent ? (react_1.default.createElement(kaleidoscope_1.Stack, { justify: "center", align: "center", padding: "l" }, noViewsYetComponent)) : (viewers.slice(0, itemsToShow).map(function (v) {
            var mostRecentSession = v.sessions[v.sessions.length - 1];
            var latestPageViewId = mostRecentSession === null || mostRecentSession === void 0 ? void 0 : mostRecentSession.pageViewId;
            return (react_1.default.createElement(ViewerCard, { viewer: v, key: v.id, onClick: function () { return onViewerCardClick(v, latestPageViewId); }, timeZone: timeZone }));
        }))),
        loadMoreButtons));
};
exports.ViewersList = ViewersList;
var ViewerCard = function (_a) {
    var viewer = _a.viewer, timeZone = _a.timeZone, onClick = _a.onClick;
    var displayName = viewer.displayName, email = viewer.email, countryCode = viewer.countryCode, location = viewer.location, mostRecentView = viewer.mostRecentView, totalViewDuration = viewer.totalViewDuration, sessions = viewer.sessions, isVerified = viewer.isVerified;
    return (react_1.default.createElement("div", { className: styles.cardWrapper },
        react_1.default.createElement(kaleidoscope_1.ButtonCard, { className: styles.fullCardSize, onClick: onClick }),
        react_1.default.createElement("div", { className: "".concat(styles.fullCardSize, " ").concat(styles.cardContent) },
            react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.viewerCardTopRow, direction: "horizontal", align: "center", gap: "xs", padding: "m" },
                react_1.default.createElement(ViewerAvatar_1.ViewerAvatar, { viewer: viewer, size: kaleidoscope_1.AvatarSize.Small }),
                react_1.default.createElement(kaleidoscope_1.Text, { className: styles.viewerDisplayName, strong: true, size: "s" }, displayName),
                isVerified && (react_1.default.createElement(kaleidoscope_1.Label, { className: styles.verifiedLabel },
                    react_1.default.createElement(icons_1.Tick, { size: icons_1.IconSize.Small, className: styles.verifiedIcon }))),
                react_1.default.createElement(LinkedInSearch_1.LinkedInSearch, { viewer: viewer })),
            react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.viewerCardBottomRow, justify: "center", padding: "m", gap: "xs" },
                react_1.default.createElement(InfoLine, { icon: react_1.default.createElement(icons_1.Mail, __assign({}, commonIconProps)), info: email !== null && email !== void 0 ? email : "—" }),
                react_1.default.createElement(InfoLine, { icon: react_1.default.createElement(LocationFlag_1.LocationFlag, { countryCode: countryCode }), info: location !== null && location !== void 0 ? location : "—" }),
                react_1.default.createElement(InfoLine, { icon: react_1.default.createElement(icons_1.Calendar, __assign({}, commonIconProps)), info: (0, Utils_1.formatDatetime)(mostRecentView, timeZone) }),
                react_1.default.createElement(InfoLine, { icon: react_1.default.createElement(icons_1.Clock, __assign({}, commonIconProps)), info: (0, Utils_1.formatCompactViewDuration)(totalViewDuration) }),
                react_1.default.createElement(InfoLine, { icon: react_1.default.createElement(icons_1.Preview, __assign({}, commonIconProps)), info: "".concat(sessions.length, " sessions") })))));
};
var InfoLine = function (_a) {
    var icon = _a.icon, info = _a.info;
    return (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "xxs" },
        icon,
        react_1.default.createElement(kaleidoscope_1.Text, { className: styles.infoText, size: "xs", align: "left" }, info)));
};
