// Generated by CoffeeScript 1.12.7
(function () {
  var StyleWorkerController,
    isUsingTCDC,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  isUsingTCDC = require("@CommonFrontendBackend/FeatureFlag").isUsingTCDC;

  module.exports = StyleWorkerController = (function () {
    function StyleWorkerController($http, apiBaseUrl, account, $log, usingFeature) {
      this.$http = $http;
      this.apiBaseUrl = apiBaseUrl;
      this.account = account;
      this.$log = $log;
      this.usingFeature = usingFeature;
      this.refreshCustomStylesList = bind(this.refreshCustomStylesList, this);
      this.loadProjectStylesheet = bind(this.loadProjectStylesheet, this);
      this.renderAccountStyleSheet = bind(this.renderAccountStyleSheet, this);
      this["delete"] = bind(this["delete"], this);
      this.addToHead = bind(this.addToHead, this);
      this.modify = bind(this.modify, this);
      this.addStyles = bind(this.addStyles, this);
    }

    StyleWorkerController.prototype.addStyles = function () {
      return this.$http.get(this.apiBaseUrl + "/styles/user-stylesheet").success(
        (function (_this) {
          return function (css) {
            return _this.addToHead(css);
          };
        })(this),
      );
    };

    StyleWorkerController.prototype.modify = function (colors) {
      return this.$http
        .post(this.apiBaseUrl + "/styles/modify", {
          colors: colors,
        })
        .success(
          (function (_this) {
            return function (css) {
              if (!isUsingTCDC(_this.usingFeature)) {
                return _this.addToHead(css);
              }
            };
          })(this),
        );
    };

    StyleWorkerController.prototype.addToHead = function (css, styleId) {
      if (styleId == null) {
        styleId = "qwilr-style-banks";
      }
      this["delete"](styleId);
      return $("<style id='" + styleId + "'> " + css + " </style>").appendTo("head");
    };

    StyleWorkerController.prototype["delete"] = function (styleId) {
      if (styleId == null) {
        styleId = "qwilr-style-banks";
      }
      return $("head #" + styleId).remove();
    };

    StyleWorkerController.prototype.renderAccountStyleSheet = function () {
      return this.$http.post(this.apiBaseUrl + "/accounts/" + this.account._id + "/styles/render");
    };

    StyleWorkerController.prototype.loadProjectStylesheet = function (projectId) {
      return this.$http.get(this.apiBaseUrl + "/projects/" + projectId + "/stylesheet").then(
        (function (_this) {
          return function (response) {
            return _this.addToHead(response.data, "qwilr-project");
          };
        })(this),
      );
    };

    StyleWorkerController.prototype.refreshCustomStylesList = function () {
      return this.$http
        .get(this.apiBaseUrl + "/accounts/" + this.account._id + "/styles")
        .then(
          (function (_this) {
            return function (response) {
              var styles;
              styles = response.data.styles;
              styles.$skipPersistenceCycle = true;
              return (_this.account.styles = styles);
            };
          })(this),
        )
        ["catch"](
          (function (_this) {
            return function (err) {
              return _this.$log.error(err);
            };
          })(this),
        );
    };

    return StyleWorkerController;
  })();
}.call(this));
