"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.removeAllChildrenFromElement = exports.hasBlockTypeAsAncestor = void 0;
var slate_1 = require("slate");
var hasBlockTypeAsAncestor = function (_a) {
    var e_1, _b;
    var selectionPath = _a.selectionPath, blockType = _a.blockType, editor = _a.editor;
    var ancestorPaths = slate_1.Path.ancestors(selectionPath);
    try {
        for (var ancestorPaths_1 = __values(ancestorPaths), ancestorPaths_1_1 = ancestorPaths_1.next(); !ancestorPaths_1_1.done; ancestorPaths_1_1 = ancestorPaths_1.next()) {
            var path = ancestorPaths_1_1.value;
            var ancestorNode = slate_1.Editor.node(editor, path)[0];
            if (slate_1.Element.isElement(ancestorNode) && ancestorNode.type === blockType) {
                return true;
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (ancestorPaths_1_1 && !ancestorPaths_1_1.done && (_b = ancestorPaths_1.return)) _b.call(ancestorPaths_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return false;
};
exports.hasBlockTypeAsAncestor = hasBlockTypeAsAncestor;
var removeAllChildrenFromElement = function (nodeEntry, editor) {
    slate_1.Editor.withoutNormalizing(editor, function () {
        var _a = __read(nodeEntry, 2), node = _a[0], path = _a[1];
        for (var childIndex = node.children.length - 1; childIndex >= 0; childIndex--) {
            var pathToChild = __spreadArray(__spreadArray([], __read(path), false), [childIndex], false);
            slate_1.Transforms.removeNodes(editor, { at: pathToChild, voids: true });
        }
    });
};
exports.removeAllChildrenFromElement = removeAllChildrenFromElement;
