// Generated by CoffeeScript 1.12.7

/*
  This module generates CSS for a set of fonts to style a qwilr.
 */

(function () {
  module.exports = function (fonts) {
    var css, font, genericTags, i, len, tag, tags;
    css = "";
    for (tag in fonts) {
      font = fonts[tag];
      tags = [
        ".project-block content-area " + tag,
        ".project-block .new-editor " + tag,
        ".style-editor .project-block " + tag,
        ".census-collector__project-preview .project-block " + tag,
      ].join(", ");
      css += tags + " {  font-family: '" + font.name + "', sans-serif;  }";
    }
    genericTags = ["h3", "h4", "h5", "h6", "li", "table", ".quote-section", ".quote-total", "blockquote"];
    if ((fonts != null ? fonts.p : void 0) != null) {
      for (i = 0, len = genericTags.length; i < len; i++) {
        tag = genericTags[i];
        tags = [
          ".project-block content-area " + tag + ":not(.icon)",
          ".project-block .new-editor " + tag + ":not(.icon)",
          ".style-editor .project-block " + tag + ":not(.icon)",
          ".user-font " + tag,
        ].join(", ");
        css += tags + " { font-family: '" + fonts.p.name + "', sans-serif !important; }";
      }
      css += ".user-font { font-family: '" + fonts.p.name + "', sans-serif !important; }";
      css +=
        ".project-block .widget-adder__placeholder p { font-family: '" + fonts.p.name + "', sans-serif !important;  }";
      css +=
        ".qwilr-font, .user-font .qwilr-font, .user-font .calibre { font-family: 'calibre-legacy', sans-serif !important; }";
    }
    return css;
  };
}.call(this));
