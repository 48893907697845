// Generated by CoffeeScript 1.12.7

/*
  UI for selecting a Qwilr recommended font pairing.
 */

(function () {
  var RecommendedFontPairings, WebFont;

  WebFont = require("webfontloader");

  module.exports = RecommendedFontPairings = (function () {
    function RecommendedFontPairings(createUi, overlayStack, invoke, $q, $timeout, noop) {
      var fonts, i, len, pair, pairs, selecting;
      selecting = $q.defer();
      pairs = invoke(require("./Pairs"));
      fonts = [];
      for (i = 0, len = pairs.length; i < len; i++) {
        pair = pairs[i];
        fonts.push(pair.fonts.h1.name);
        fonts.push(pair.fonts.h2.name);
        fonts.push(pair.fonts.p.name);
      }
      fonts = _.uniq(fonts);
      this.ui = createUi(require("./RecommendedPairings.html"), {
        pairs: pairs,
        select: function (pair) {
          selecting.resolve(pair.fonts);
          return overlayStack.closeCurrent();
        },
        togglePairSelection: (function (_this) {
          return function (selectedPair) {
            var j, len1, ref;
            if (selectedPair.$confirmSelection === true) {
              return (selectedPair.$confirmSelection = false);
            } else {
              ref = _this.ui.pairs;
              for (j = 0, len1 = ref.length; j < len1; j++) {
                pair = ref[j];
                pair.$confirmSelection = false;
              }
              return (selectedPair.$confirmSelection = true);
            }
          };
        })(this),
      });
      this.show = function () {
        WebFont.load({
          classes: false,
          google: {
            families: fonts,
          },
        });
        overlayStack.add({
          contentUi: this.ui,
          options: {
            className: "mini-close-button",
          },
        });
        $timeout(noop);
        return selecting.promise;
      };
    }

    return RecommendedFontPairings;
  })();
}.call(this));
