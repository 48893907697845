// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (user, getFullName) {
    return function () {
      if ((typeof FS !== "undefined" && FS !== null ? FS.identify : void 0) != null) {
        return FS.identify(user._id, {
          displayName: getFullName(user),
          email: user.email,
          signupDate: user.createdAt,
        });
      }
    };
  };
}.call(this));
