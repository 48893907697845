"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var AcceptTelemetry = (function () {
    function AcceptTelemetry() {
        this.track = function (eventName, eventParams) { };
        this.debouncedTrack = function (eventName, eventParams, debounceDelay, debounceOptions) {
            if (debounceDelay === void 0) { debounceDelay = 200; }
        };
        this.throttledTrack = function (eventName, eventParams, throttleDelay, throttleOptions) {
            if (throttleDelay === void 0) { throttleDelay = 1000; }
            if (throttleOptions === void 0) { throttleOptions = { trailing: false }; }
        };
    }
    return AcceptTelemetry;
}());
exports.default = AcceptTelemetry;
