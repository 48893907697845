// Generated by CoffeeScript 1.12.7

/*
 * UI for QuickBooks integration
 */

(function () {
  var QuickBooksIntegration,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = QuickBooksIntegration = (function () {
    function QuickBooksIntegration(createUi, make, $http, $q, $log, $timeout, account, isEnabled, apiBaseUrl) {
      this.update = bind(this.update, this);
      this.disable = bind(this.disable, this);
      this.enable = bind(this.enable, this);
      this.getTaxes = bind(this.getTaxes, this);
      var base, base1, base2, enableUi;
      this.q = $q;
      this.http = $http;
      this.isEnabled = isEnabled;
      this.apiBaseUrl = apiBaseUrl;
      this.account = account;
      enableUi = make(require("../OauthEnablingUI"), {
        name: "QuickBooks",
      });
      this.enablingUi = enableUi.ui;
      if (account.integrations == null) {
        account.integrations = {};
      }
      if ((base = account.integrations).quickbooks == null) {
        base.quickbooks = {};
      }
      if ((base1 = account.integrations.quickbooks).settings == null) {
        base1.settings = {};
      }
      if ((base2 = account.integrations.quickbooks.settings).taxMaps == null) {
        base2.taxMaps = {};
      }
      if (isEnabled()) {
        this.getTaxes();
      }
      this.ui = createUi(require("SettingsAngular/Integrations/QuickBooks/QuickBooks.html"), {
        error: "",
        viewState: {
          taxesLoading: true,
        },
        settings: account.integrations.quickbooks.settings,
        taxMaps: account.integrations.quickbooks.settings.taxMaps,
        isEnabled: this.isEnabled,
        quickbooksTaxes: null,
        toggleAutoPush: (function (_this) {
          return function (enabled) {
            if (enabled == null) {
              return;
            }
            return _this.update({
              defaultAutoPush: enabled,
            });
          };
        })(this),
        taxIsMapped: function (tax) {
          return tax.$quickbooksId.length && tax.$quickbooksId === tax.$selected.id;
        },
        mismatchedTax: (function (_this) {
          return function () {
            var i, len, ref, tax;
            ref = _this.ui.taxes();
            for (i = 0, len = ref.length; i < len; i++) {
              tax = ref[i];
              if (!(tax.$selected.rateValue == null || tax.percent === tax.$selected.rateValue)) {
                return tax;
              }
            }
            return null;
          };
        })(this),
        taxes: (function (_this) {
          return function () {
            var ref, ref1, ref2, taxes;
            taxes =
              (ref = account.settings) != null
                ? (ref1 = ref.modules) != null
                  ? (ref2 = ref1.quote) != null
                    ? ref2.taxes
                    : void 0
                  : void 0
                : void 0;
            if (!taxes) {
              taxes = [];
            }
            return _.map(taxes, function (tax) {
              tax.$quickbooksId = _this.ui.taxMaps[tax.id];
              if (tax.$quickbooksId != null && tax.$selected == null) {
                tax.$selected = _.find(_this.ui.quickbooksTaxes, {
                  id: tax.$quickbooksId,
                });
              }
              if (tax.$quickbooksId == null) {
                tax.$quickbooksId = "";
              }
              if (tax.$selected == null) {
                tax.$selected = {
                  id: "",
                };
              }
              return tax;
            });
          };
        })(this),
        setTaxMap: (function (_this) {
          return function (tax) {
            var obj;
            return _this
              .update(((obj = {}), (obj["taxMaps." + tax.id] = tax.$selected.id), obj))
              .then(function () {
                var base3;
                if ((base3 = _this.ui.settings).taxMaps == null) {
                  base3.taxMaps = {};
                }
                return (_this.ui.settings.taxMaps[tax.id] = tax.$selected.id);
              })
              ["catch"](function (err) {
                return $log.error(err);
              });
          };
        })(this),
      });
    }

    QuickBooksIntegration.prototype.getTaxes = function () {
      return this.http
        .get(this.apiBaseUrl + "/integration/quickbooks/taxes")
        .then(
          (function (_this) {
            return function (response) {
              var i, len, tax, taxes;
              taxes = response.data.message;
              for (i = 0, len = taxes.length; i < len; i++) {
                tax = taxes[i];
                tax.$display = tax.name + " - " + tax.rate + "%";
              }
              _this.ui.quickbooksTaxes = taxes;
              return (_this.ui.viewState.taxesLoading = false);
            };
          })(this),
        )
        ["catch"](
          (function (_this) {
            return function () {
              return (_this.ui.error = "There was an error fetching taxes from QuickBooks.");
            };
          })(this),
        );
    };

    QuickBooksIntegration.prototype.enable = function () {
      var callbackUrl, enabling, pollFunc, polling, win;
      enabling = this.q.defer();
      callbackUrl = this.apiBaseUrl + "/integration/quickbooks/callback";
      win = window.open(
        this.apiBaseUrl + "/integration/quickbooks/authorize",
        "_blank",
        "top=100,left=100,width=900,height=1000",
      );
      polling = null;
      pollFunc = function () {
        var e, url;
        try {
          if (win.QWILR_SUCCESS) {
            window.clearInterval(polling);
            url = win.document.URL;
            enabling.resolve(url.split("?")[1]);
            return win.close();
          }
        } catch (error) {
          e = error;
        }
      };
      polling = window.setInterval(pollFunc, 250);
      return enabling.promise
        .then(
          (function (_this) {
            return function (queryParams) {
              return _this.http.get(callbackUrl + "?" + queryParams);
            };
          })(this),
        )
        .then(
          (function (_this) {
            return function () {
              return _this.http.post(_this.apiBaseUrl + "/integration/quickbooks/enable", {});
            };
          })(this),
        )
        .then(
          (function (_this) {
            return function () {
              var base;
              if ((base = _this.account.integrations).quickbooks == null) {
                base.quickbooks = {};
              }
              return (_this.account.integrations.quickbooks.enabled = true);
            };
          })(this),
        )
        .then(
          (function (_this) {
            return function () {
              return _this.getTaxes();
            };
          })(this),
        );
    };

    QuickBooksIntegration.prototype.disable = function () {
      return this.http.post(this.apiBaseUrl + "/integration/quickbooks/disable").then(
        (function (_this) {
          return function () {
            var base;
            if ((base = _this.account.integrations).quickbooks == null) {
              base.quickbooks = {};
            }
            return (_this.account.integrations.quickbooks.enabled = false);
          };
        })(this),
      );
    };

    QuickBooksIntegration.prototype.update = function (settings) {
      return this.http.post(this.apiBaseUrl + "/integration/quickbooks/update", settings).then(
        (function (_this) {
          return function () {
            var base;
            if ((base = _this.account.integrations).quickbooks == null) {
              base.quickbooks = {};
            }
            return (_this.account.integrations.quickbooks.settings = settings);
          };
        })(this),
      );
    };

    return QuickBooksIntegration;
  })();
}.call(this));
