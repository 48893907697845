"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImageLibraryViewTypes = exports.ImageLibrarySystemFolder = void 0;
var Types_1 = require("@CommonFrontendBackend/Folders/Types");
Object.defineProperty(exports, "ImageLibrarySystemFolder", { enumerable: true, get: function () { return Types_1.ImageLibrarySystemFolder; } });
var ImageLibraryViewTypes;
(function (ImageLibraryViewTypes) {
    ImageLibraryViewTypes["Images"] = "images";
    ImageLibraryViewTypes["Collections"] = "collections";
    ImageLibraryViewTypes["ErrorMessages"] = "error messages";
    ImageLibraryViewTypes["StockImages"] = "stock images";
})(ImageLibraryViewTypes || (exports.ImageLibraryViewTypes = ImageLibraryViewTypes = {}));
