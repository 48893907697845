"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubdomainInput = void 0;
var react_1 = __importStar(require("react"));
var lodash_1 = require("lodash");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var styles = __importStar(require("./SubdomainInput.css"));
var icons_1 = require("@qwilr/kaleidoscope/icons");
var SubdomainTooltipContent = function () {
    return (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", justify: "center" },
        react_1.default.createElement(kaleidoscope_1.Text, { size: "s", strong: true, className: styles.tooltipHeading }, "Subdomains"),
        react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", className: styles.tooltipText }, "Get on-brand URLs for your proposals in Qwilr. You can change this later.")));
};
exports.SubdomainInput = (0, react_1.forwardRef)(function (_a, ref) {
    var dataStore = _a.dataStore, apiService = _a.apiService;
    var inputId = (0, react_1.useRef)("qwilr-subdomain-input");
    var _b = __read((0, react_1.useState)(dataStore.qwilrSubdomain), 2), subdomain = _b[0], setSubdomain = _b[1];
    var _c = __read((0, react_1.useState)(undefined), 2), subdomainCheckResult = _c[0], setSubdomainCheckResult = _c[1];
    var checkSubdomain = function (subdomainToCheck) {
        if (!subdomainToCheck) {
            setSubdomainCheckResult(undefined);
            dataStore.setSubdomainError("");
            return;
        }
        if (subdomainToCheck.indexOf(" ") >= 0) {
            setSubdomainCheckResult({ usable: false, reason: "invalid" });
            dataStore.setSubdomainError("invalid");
            return;
        }
        apiService
            .checkSubdomainAvailability(subdomainToCheck)
            .then(function (result) {
            if (!!result) {
                setSubdomainCheckResult(result);
                if (result.usable === false) {
                    dataStore.setSubdomainError(result.reason);
                }
                else {
                    dataStore.setSubdomainError("");
                }
            }
        })
            .catch(function (e) {
            setSubdomainCheckResult({ error: true });
            dataStore.setSubdomainError("failed");
        });
    };
    var debounceCheckSubdomain = (0, react_1.useRef)((0, lodash_1.debounce)(checkSubdomain, 300));
    (0, react_1.useEffect)(function () {
        (function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                debounceCheckSubdomain.current(subdomain);
                return [2];
            });
        }); })();
    }, [subdomain]);
    var onChangeHandle = function (newSubdomain) {
        setSubdomainCheckResult(undefined);
        dataStore.setSubdomainError("");
        setSubdomain(newSubdomain);
        dataStore.setQwilrSubdomain(newSubdomain);
    };
    var updateSubdomain = function (sd) {
        setSubdomain(sd);
    };
    (0, react_1.useImperativeHandle)(ref, function () { return ({
        updateSubdomain: updateSubdomain,
    }); });
    var isSubdomainCheckFailed = function () { return !!subdomainCheckResult && "error" in subdomainCheckResult; };
    var isSubdomainUsable = function () {
        return !!subdomainCheckResult && "usable" in subdomainCheckResult && subdomainCheckResult.usable === true;
    };
    var isSubdomainTaken = function () {
        return !!subdomainCheckResult &&
            "usable" in subdomainCheckResult &&
            "reason" in subdomainCheckResult &&
            subdomainCheckResult.usable === false &&
            subdomainCheckResult.reason === "not available";
    };
    var isSubdomainInvalid = function () {
        return !!subdomainCheckResult &&
            "usable" in subdomainCheckResult &&
            "reason" in subdomainCheckResult &&
            subdomainCheckResult.usable === false &&
            subdomainCheckResult.reason === "invalid";
    };
    return (react_1.default.createElement("div", { className: styles.root },
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "xxs", className: styles.label },
            react_1.default.createElement(kaleidoscope_1.Label, { long: true, size: "m" }, "Choose a subdomain"),
            react_1.default.createElement(kaleidoscope_1.Tooltip, { wrapTarget: true, content: react_1.default.createElement(SubdomainTooltipContent, null), size: kaleidoscope_1.TooltipSize.Large },
                react_1.default.createElement(icons_1.Help, { size: icons_1.IconSize.Small, color: "default" }))),
        react_1.default.createElement(kaleidoscope_1.TextInput, { id: inputId.current, size: kaleidoscope_1.TextInputSize.Large, label: "Choose a subdomain", labelHidden: true, placeholder: "example", value: subdomain, onChange: onChangeHandle, error: isSubdomainCheckFailed()
                ? "Unable to check subdomain availability"
                : isSubdomainTaken()
                    ? "This subdomain is already taken :("
                    : isSubdomainInvalid()
                        ? "This subdomain isn't valid :("
                        : undefined, endElement: react_1.default.createElement(kaleidoscope_1.Text, { secondary: true, size: "xl", className: styles.qwilrDomainText }, ".qwilr.com") }),
        react_1.default.createElement(kaleidoscope_1.Expandable, { expanded: isSubdomainUsable(), timeout: kaleidoscope_1.AnimationDuration.Short }, function (_a) {
            var expandableElementRef = _a.expandableElementRef;
            return (react_1.default.createElement("div", { id: "subdomain-check-result", className: styles.checkResult, ref: expandableElementRef, "aria-live": "polite" },
                react_1.default.createElement("div", { className: styles.checkResultIcon },
                    react_1.default.createElement(icons_1.Tick, { size: icons_1.IconSize.Small })),
                react_1.default.createElement("div", { className: styles.checkResultText }, "Available! This subdomain can be yours")));
        })));
});
