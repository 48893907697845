// Generated by CoffeeScript 1.12.7

/*
  A list of available options that can be used to configure a Carousel, along with their default
  / initialised values

  For the descriptions of these, refer to the documentation in `Carousel.js`
 */

(function () {
  module.exports = {
    name: "",
    totalCount: 0,
    nextPageBeforeHook: function () {},
    nextPageAfterHook: function () {},
    prevPageBeforeHook: function () {},
    prevPageAfterHook: function () {},
    onSelect: function () {},
    onClearHook: function () {},
    onInitHook: function () {},
    elementRenderedHook: function () {},
    tileFactory: null,
    data: [],
    leftButtons: [],
    rightButtons: [],
    message: null,
    emptyContentMessage: "Oops! There was no content found for your search",
  };
}.call(this));
