(function () {
  var FeatureFlag, LinkTimeLimit;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = LinkTimeLimit = (function () {
    function LinkTimeLimit(createUi, link, telemetry, usingFeature, getDisabledMessage) {
      this.isEnabled = (function (_this) {
        return function () {
          return !link.timeLimit.forever;
        };
      })(this);
      this.ui = createUi(require("./TimeLimit.html"), {
        featureAvailable: usingFeature(FeatureFlag.AdvancedSecurity) || this.isEnabled(),
        getDisabledMessage: getDisabledMessage,
        isLimited: !link.timeLimit.forever,
        fromDate: link.timeLimit.from,
        untilDate: link.timeLimit.until,
        timeLimitEnableChanged: (function (_this) {
          return function () {
            link.timeLimit.forever = !_this.ui.isLimited;
            if (!link.timeLimit.forever) {
              return telemetry.trackOnly("Project Security Time Limit Applied");
            }
          };
        })(this),
        timeLimitChanged: (function (_this) {
          return function () {
            link.timeLimit.from = _this.ui.fromDate;
            return (link.timeLimit.until = _this.ui.untilDate);
          };
        })(this),
      });
    }

    return LinkTimeLimit;
  })();
}.call(this));
