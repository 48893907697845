// Generated by CoffeeScript 1.12.7
(function () {
  var ButtonTemplate, IntegrationImages, InvoiceSettingsButton, SettingsMenu;

  SettingsMenu = require("./InvoiceSettingsMenu");

  ButtonTemplate = require("./InvoiceSettingsButton.html");

  IntegrationImages = {
    freshbooks: "/Assets/icons/super-freshbooks.svg",
    quickbooks: "/Assets/icons/super-quickbooks.svg",
    xero: "/Assets/icons/super-xero.svg",
  };

  module.exports = InvoiceSettingsButton = (function () {
    function InvoiceSettingsButton(createUi, make, Tooltip, block, integration) {
      var settingsMenu, tooltip;
      settingsMenu = make(SettingsMenu, {
        integration: integration,
      });
      tooltip = make(Tooltip, {
        targetSelector: "#" + block.$id + " #" + integration + ".invoice-settings-button",
        options: {
          container: "#" + block.$id + " .project-block",
          position: "bottom",
          contents: settingsMenu.ui,
          spacing: 7,
          closeOnClickAway: true,
        },
      });
      this.ui = createUi(ButtonTemplate, {
        integration: integration,
        invoicerImage: function () {
          var imageLocation;
          imageLocation = IntegrationImages[integration];
          return {
            "background-image": "url('" + imageLocation + "')",
          };
        },
      });
      this.name = integration;
      this.className = "invoice-settings-button";
      this.onClick = function () {
        return tooltip.show();
      };
      this.onClose = function () {
        return tooltip.hide();
      };
    }

    return InvoiceSettingsButton;
  })();
}.call(this));
