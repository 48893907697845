// Generated by CoffeeScript 1.12.7
(function () {
  var getFullName;

  getFullName = require("@CommonFrontendBackend/Utils/GetFullName")["default"];

  module.exports = function (blockType, user, className) {
    var style;
    style = {
      $newTemporaryStyle: true,
      createdBy: user._id,
      createdAt: new Date().toString(),
      author: getFullName(user),
      version: 1,
      background: "white",
      fontStyles: {
        scale: 18,
        ratio: 1.618,
        spacing: 0,
      },
      columnWidth: "regular",
      colors: {
        h1: {
          name: "brand",
          modifier: null,
        },
        h2: {
          name: "complementary",
          modifier: null,
        },
        p: {
          name: "slate",
          modifier: null,
        },
      },
      className: className,
      name: "Untitled Style",
    };
    if (blockType === "Splash" || blockType === "Video" || blockType === "Quote") {
      style.alignment = {
        h1: "center",
        h2: "center",
        p: "center",
      };
    } else {
      style.alignment = {
        h1: "left",
        h2: "left",
        p: "left",
      };
    }
    if (blockType === "Splash") {
      style.background = "none";
      style.backgroundCard = "none";
      style.tint = "none";
      style.colors.h1.name = "white";
      style.colors.h2.name = "white";
      style.colors.p.name = "white";
    }
    if (blockType === "Text" || blockType === "Video" || blockType === "Source") {
      style.blockTypes = ["Text", "Video", "Source"];
    } else {
      style.blockTypes = [blockType];
    }
    return style;
  };
}.call(this));
