// Generated by CoffeeScript 1.12.7

/*
  This module tracks the DOM objects controlled by Tether.
  This approach allows us to control these DOM elements centrally.
  This is useful when we want a way to hide or destroy these orphaned DOM
  elements. i.e When our Angular app changes state, or we open / close an overlay.
 */

(function () {
  var TetherTracker;

  module.exports = TetherTracker = (function () {
    function TetherTracker($rootScope, appEvents) {
      var getTether, reset, tethers;
      tethers = [];

      /*
        Reset UI by destroying all registered tether objects
        and removing them from the DOM.
        @api private
       */
      reset = function () {
        var i, len, tether;
        for (i = 0, len = tethers.length; i < len; i++) {
          tether = tethers[i];
          tether.destroy();
          tether.element.remove();
        }
        return (tethers = []);
      };
      $rootScope.$on("$stateChangeSuccess", reset);

      /*
        Register a Tether object to be tracked.
        @api public
       */
      this.register = function (tetherObject) {
        return tethers.push(tetherObject);
      };
      getTether = function (id) {
        return _.find(tethers, {
          $id: id,
        });
      };
      this.reposition = function (id) {
        return getTether(id).position();
      };
      this.repositionAll = function () {
        var i, len, results, tether;
        results = [];
        for (i = 0, len = tethers.length; i < len; i++) {
          tether = tethers[i];
          results.push(tether.position());
        }
        return results;
      };
      $(window).on("resize", _.throttle(this.repositionAll, 250));
    }

    return TetherTracker;
  })();
}.call(this));
