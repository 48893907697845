"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var TrackableVideo = function (_a) {
    var onClose = _a.onClose, videoProps = __rest(_a, ["onClose"]);
    var videoLength = (0, react_1.useRef)(undefined);
    var playStartTimestamp = (0, react_1.useRef)(undefined);
    var secondsWatchedChunks = (0, react_1.useRef)([]);
    var videoFinished = (0, react_1.useRef)(false);
    var secondsViewed = (0, react_1.useRef)(undefined);
    var startTiming = function () {
        playStartTimestamp.current = new Date().getTime();
        secondsWatchedChunks.current.push(0);
    };
    var stopTiming = function () {
        if (playStartTimestamp.current === undefined) {
            return;
        }
        secondsWatchedChunks.current[secondsWatchedChunks.current.length - 1] =
            new Date().getTime() - playStartTimestamp.current;
        playStartTimestamp.current = undefined;
    };
    var unmounting = (0, react_1.useRef)(false);
    (0, react_1.useEffect)(function () { return function () {
        unmounting.current = true;
    }; }, []);
    (0, react_1.useEffect)(function () {
        return function () {
            if (unmounting.current && !!videoLength.current) {
                stopTiming();
                onClose({
                    videoLength: videoLength.current,
                    videoFinished: videoFinished.current,
                    secondsWatched: Math.round(secondsWatchedChunks.current.reduce(function (acc, chunk) { return acc + chunk; }, 0) / 1000),
                    percentageWatched: Math.round((secondsViewed.current.reduce(function (acc, second) { return acc + second; }, 0) / secondsViewed.current.length) * 100),
                });
            }
        };
    }, [onClose]);
    return (react_1.default.createElement("video", __assign({}, videoProps, { onLoadedMetadata: function (e) {
            var _a;
            videoLength.current = Math.round(e.currentTarget.duration);
            secondsViewed.current = new Array(Math.ceil(e.currentTarget.duration)).fill(0);
            (_a = videoProps.onLoadedMetadata) === null || _a === void 0 ? void 0 : _a.call(videoProps, e);
        }, onTimeUpdate: function (e) {
            var _a;
            secondsViewed.current[Math.floor(e.currentTarget.currentTime)] = 1;
            (_a = videoProps.onTimeUpdate) === null || _a === void 0 ? void 0 : _a.call(videoProps, e);
        }, onPlay: function (e) {
            var _a;
            startTiming();
            (_a = videoProps.onPlay) === null || _a === void 0 ? void 0 : _a.call(videoProps, e);
        }, onPause: function (e) {
            var _a;
            stopTiming();
            (_a = videoProps.onPause) === null || _a === void 0 ? void 0 : _a.call(videoProps, e);
        }, onEnded: function (e) {
            var _a;
            videoFinished.current = true;
            (_a = videoProps.onEnded) === null || _a === void 0 ? void 0 : _a.call(videoProps, e);
        } })));
};
exports.default = TrackableVideo;
