"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockCreator = void 0;
var GetBlockModels_1 = require("@CommonFrontendBackend/BlockModels/GetBlockModels");
var lodash_1 = require("lodash");
var BlockCreator = (function () {
    function BlockCreator(blockList, insertBlock, apiBaseUrl) {
        var _this = this;
        this.blockList = blockList;
        this.insertBlock = insertBlock;
        this.apiBaseUrl = apiBaseUrl;
        this.createAndInsert = function (blockType, index, preset) { return __awaiter(_this, void 0, void 0, function () {
            var blockModel, blockInsertionFunction;
            var _this = this;
            return __generator(this, function (_a) {
                blockModel = (0, GetBlockModels_1.getBlockModel)(blockType, preset);
                if (preset) {
                    blockModel.$presetUsed = preset;
                }
                blockInsertionFunction = function (b) { return _this.blockList.insertBlockAtIndex(b, index); };
                return [2, this.insertBlock(blockInsertionFunction)(blockModel)];
            });
        }); };
        this.cloneAndInsert = function (block_1, index_1) {
            var args_1 = [];
            for (var _i = 2; _i < arguments.length; _i++) {
                args_1[_i - 2] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([block_1, index_1], __read(args_1), false), void 0, function (block, index, _a) {
                var blockModel, blockInsertionFunction;
                var _this = this;
                var _b = _a === void 0 ? {} : _a, _c = _b.insertDelayMs, insertDelayMs = _c === void 0 ? 0 : _c;
                return __generator(this, function (_d) {
                    blockModel = (0, lodash_1.cloneDeep)(block);
                    blockModel.persistVersion = 0;
                    blockModel.clonedFrom = block._id;
                    blockInsertionFunction = function (b) {
                        return _this.blockList.insertBlockAtIndex(b, index, { skipScrolling: true, insertDelayMs: insertDelayMs });
                    };
                    return [2, this.insertBlock(blockInsertionFunction)(blockModel)];
                });
            });
        };
        this.cloneBlock = function (incomingBlock) { return __awaiter(_this, void 0, Promise, function () {
            var blockModel, response, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        blockModel = (0, lodash_1.cloneDeep)(incomingBlock);
                        blockModel.persistVersion = 0;
                        blockModel.clonedFrom = incomingBlock._id;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.blockList.create(blockModel)];
                    case 2:
                        response = _a.sent();
                        return [2, response.data._id];
                    case 3:
                        error_1 = _a.sent();
                        throw new Error(error_1);
                    case 4: return [2];
                }
            });
        }); };
    }
    return BlockCreator;
}());
exports.BlockCreator = BlockCreator;
