"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var TokenConverter_1 = require("ContentTokens/TokenConverter");
var FeatureFlag_1 = require("@CommonFrontendBackend/FeatureFlag");
exports.default = (function (usingFeature) {
    return function (rawDescription) {
        if (!usingFeature(FeatureFlag_1.FeatureFlag.OptOutNewEditor)) {
            return {
                tokens: (0, TokenConverter_1.textToTokenConverter)(rawDescription),
            };
        }
        else {
            return {
                content: "<p>".concat((0, lodash_1.escape)(rawDescription), "</p>"),
            };
        }
    };
});
