"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mountReactComponent = require("MountReactComponent/MountReactComponent").default;
var mountPaymentsIcon = require("../ReactSuperpowerIcons").mountPaymentsIcon;
var FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;
var PaymentSetupButton = (function () {
    function PaymentSetupButton(createUi, make, Tooltip, block, usingFeature) {
        var paymentSetupMenu = make(require("./PaymentSetupMenu")["default"]);
        var menu = make(Tooltip, {
            targetSelector: "#".concat(block.$id, " .payment-setup-button"),
            options: {
                container: "#".concat(block.$id, " .project-block"),
                position: "bottom",
                contents: paymentSetupMenu.ui,
                spacing: 7,
            },
        });
        this.ui = createUi(require("./PaymentSetupButton.html"));
        this.ui.reactIcon = mountPaymentsIcon(mountReactComponent(createUi));
        this.ui.usingReactIcon = !usingFeature(FeatureFlag.OptOutNewEditor);
        this.className = "payment-setup-button";
        this.onClick = menu.show;
        this.onClose = menu.hide;
    }
    return PaymentSetupButton;
}());
exports.default = PaymentSetupButton;
