"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSummaryTimelineComponents = void 0;
var react_1 = __importStar(require("react"));
var inversify_react_1 = require("inversify-react");
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var Types_1 = require("@CommonFrontendBackend/Projects/Types");
var PageStatusIcon_1 = require("@CommonFrontend/Project/PageStatusIcon");
var Engagement_1 = require("Common/Engagement");
var UseSummaryTimeline_1 = require("./UseSummaryTimeline");
var PageStatusEventCard_1 = require("./PageStatusEventCard");
var EngagementChangedEventCard_1 = require("./EngagementChangedEventCard");
var PageViewedEventCard_1 = require("./PageViewedEventCard");
var PageAcceptedTimelineCards_1 = require("Analytics/Page/Components/Timeline/PageAcceptedTimelineCards");
var RoiCalculatorCard_1 = require("Analytics/Page/Components/Timeline/RoiCalculatorCard");
var QuoteChangedCard_1 = require("Analytics/Page/Components/Timeline/QuoteChangedCard");
var AcceptStalledCard_1 = require("Analytics/Page/Components/Timeline/AcceptStalledCard");
var AgreementViewedCard_1 = require("Analytics/Page/Components/Timeline/AgreementViewedCard");
var SummaryTimelineFilters_1 = require("./SummaryTimelineFilters");
var SortButton_1 = require("Analytics/Page/Components/Timeline/SortButton");
var Timeline_1 = require("Analytics/Page/Components/Timeline/Timeline");
function useSummaryTimelineComponents(_a) {
    var pageId = _a.pageId, timeParams = _a.timeParams, openSession = _a.openSession, openAuditTrailOverlay = _a.openAuditTrailOverlay;
    var serverTelemetry = (0, inversify_react_1.useInjection)("serverTelemetry");
    var summaryTimelineData = (0, UseSummaryTimeline_1.useSummaryTimeline)(pageId, timeParams).summaryTimelineData;
    (0, react_1.useEffect)(function () {
        serverTelemetry.sendPageAnalyticsTelemetry({
            eventName: event_tracker_types_1.EventName.ViewerTimelineTabViewed,
            eventOrigin: event_tracker_types_1.EventOrigin.PageAnalytics,
            tabName: "timeline",
        });
    }, []);
    function onOpenSessionClick(viewer, pageViewId) {
        openSession(viewer, pageViewId);
        serverTelemetry.sendPageAnalyticsTelemetry({
            eventName: event_tracker_types_1.EventName.PageAnalyticsSessionViewViewed,
            eventOrigin: event_tracker_types_1.EventOrigin.QwilrCard,
        });
    }
    var _b = (0, SummaryTimelineFilters_1.useSummaryTimelineFilter)(summaryTimelineData), filtersButton = _b.filtersButton, filteredData = _b.filteredData;
    var _c = (0, SortButton_1.useTimelineSortButton)(filteredData), sortButton = _c.sortButton, filteredAndSortedData = _c.sortedData;
    return {
        timelineComponent: (react_1.default.createElement(Timeline_1.Timeline, { timelineData: filteredAndSortedData, timeZone: timeParams.timeZone, getEventIcon: getEventIcon, renderEventCard: function (data) {
                return renderEventCard(data, timeParams.timeZone, onOpenSessionClick, openAuditTrailOverlay);
            } })),
        timelineFilterButtons: (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: { tablet: "xs" } },
            sortButton,
            filtersButton)),
    };
}
exports.useSummaryTimelineComponents = useSummaryTimelineComponents;
function renderEventCard(summaryTimelineData, timeZone, onOpenSessionClick, openAuditTrailOverlay) {
    var viewSessionButton = getViewSessionButton(summaryTimelineData, onOpenSessionClick);
    var eventData = summaryTimelineData.eventData;
    switch (eventData.eventType) {
        case "pageStatus":
            return react_1.default.createElement(PageStatusEventCard_1.PageStatusEventCard, { eventData: eventData });
        case "engagementLevel":
            return react_1.default.createElement(EngagementChangedEventCard_1.EngagementChangedEventCard, { eventData: eventData });
        case "pageView":
            return react_1.default.createElement(PageViewedEventCard_1.PageViewedEventCard, { eventData: eventData, onOpenSessionClick: onOpenSessionClick });
        case "Page Accepted":
            return (react_1.default.createElement(PageAcceptedTimelineCards_1.PageAcceptedTimelineCard, { interactionEventData: eventData, timeZone: timeZone, openAuditTrailOverlay: openAuditTrailOverlay }));
        case "Page Partially Accepted":
            return (react_1.default.createElement(PageAcceptedTimelineCards_1.PagePartiallyAcceptedTimelineCard, { interactionEventData: eventData, timeZone: timeZone, headingButton: viewSessionButton }));
        case "ROI Calculator Updated":
            return react_1.default.createElement(RoiCalculatorCard_1.RoiCalculatorCard, { interactionEventData: eventData, headingButton: viewSessionButton });
        case "Quote Changed":
            return react_1.default.createElement(QuoteChangedCard_1.QuoteChangedCard, { interactionEventData: eventData, headingButton: viewSessionButton });
        case "Accept Process Stalled":
            return react_1.default.createElement(AcceptStalledCard_1.AcceptStalledCard, { eventData: eventData, headingButton: viewSessionButton });
        case "Agreement Viewed":
            return react_1.default.createElement(AgreementViewedCard_1.AgreementViewedCard, { eventData: eventData, headingButton: viewSessionButton });
    }
}
function getViewSessionButton(_a, onOpenSessionClick) {
    var eventData = _a.eventData;
    switch (eventData.eventType) {
        case "Page Accepted":
        case "Page Partially Accepted":
        case "ROI Calculator Updated":
        case "Quote Changed":
        case "Accept Process Stalled":
        case "Agreement Viewed":
            if (eventData.sessionAndViewer != undefined) {
                var _b = eventData.sessionAndViewer, viewer_1 = _b.viewer, session_1 = _b.session;
                return (react_1.default.createElement(kaleidoscope_1.Button, { onClick: function () { return onOpenSessionClick(viewer_1, session_1.pageViewId); }, type: kaleidoscope_1.ButtonType.Secondary, size: kaleidoscope_1.ButtonSize.Small }, "View session"));
            }
    }
    return undefined;
}
function getEventIcon(_a) {
    var eventData = _a.eventData;
    switch (eventData.eventType) {
        case "pageStatus":
            if (eventData.changeSource === Types_1.PageStateChangeSource.PageCreation) {
                return react_1.default.createElement(icons_1.Page, null);
            }
            else {
                return react_1.default.createElement(PageStatusIcon_1.PageStatusIcon, { status: eventData.status });
            }
        case "engagementLevel":
            return react_1.default.createElement(Engagement_1.EngagementIcon, { engagementLevel: eventData.engagementLevel });
        case "pageView":
            return react_1.default.createElement(icons_1.Preview, { color: "default" });
        case "Page Partially Accepted":
        case "Page Accepted":
            if (eventData.eSigned) {
                return react_1.default.createElement(icons_1.BlockAcceptEsign, null);
            }
            else {
                return react_1.default.createElement(icons_1.BlockAccept, null);
            }
        case "ROI Calculator Updated":
            return react_1.default.createElement(icons_1.WidgetRoi, null);
        case "Quote Changed":
            return react_1.default.createElement(icons_1.BlockQuote, null);
        case "Accept Process Stalled":
            return react_1.default.createElement(icons_1.Pause, null);
        case "Agreement Viewed":
            return react_1.default.createElement(icons_1.Preview, null);
    }
}
