// Generated by CoffeeScript 1.12.7

/*
  Widget for setting up typography across an account.

  @param options [Object] includes:
  - `includeIcon` [Boolean] whether to include an SVG icon in the header.
  - `title` [String] the title text for the widget.
  - `description` [String] the description for the widget.
 */

(function () {
  var FontSelector, TypographySelector;

  FontSelector = require("./FontSelector/FontSelector");

  module.exports = TypographySelector = (function () {
    function TypographySelector(
      options,
      createUi,
      account,
      make,
      fontService,
      ActionButton,
      loadFontsIntoBrowser,
      noop,
      $q,
    ) {
      var RecommendedFontPairs, defaultFont, fontSelector, saveFontsButton;
      fontSelector = make(FontSelector);
      if (options.includeIcon == null) {
        options.includeIcon = true;
      }
      if (options.title == null) {
        options.title = "Fonts";
      }
      if (options.description == null) {
        options.description = "Setup the typography for your Qwilr pages.";
      }
      if (options.onChange == null) {
        options.onChange = noop;
      }
      RecommendedFontPairs = require("./RecommendedPairings/RecommendedPairings");

      /*
        We want to allow our user to experiment with fonts without actually changing the account data
        (i.e. so that public pages don't change randomly as they test different fonts),
        so we create a cloned object of the account's brand font data, and only copy it across
        the account model when we "save" the fonts.
      
        We expose this publicly so that callers can have access to the interim font selection.
       */
      defaultFont = {
        friendlyName: "Open Sans",
        name: "Open Sans",
        typeface: "sans-serif",
        vendor: "GoogleFonts",
      };
      this.currentlySelectedFonts = _.cloneDeep(account.settings.brand.fonts) || {
        h1: defaultFont,
        h2: defaultFont,
        p: defaultFont,
      };
      saveFontsButton = make(ActionButton, {
        onClick: (function (_this) {
          return function () {
            return $q.resolve(fontService.setFonts(_this.currentlySelectedFonts));
          };
        })(this),
        config: {
          standard: "Save Fonts",
          waiting: "Saving Fonts...",
          success: "Saved Fonts",
          error: "Error Saving Fonts",
        },
      });
      this.ui = createUi(require("./TypographySelector.html"), {
        saveFontsButton: saveFontsButton.ui,
        colors: account.settings.brand.colors,
        showRecommendedPairs: (function (_this) {
          return function () {
            var recommendFontPairs;
            recommendFontPairs = make(RecommendedFontPairs);
            return recommendFontPairs.show().then(function (selectedFonts) {
              var element, fontObj, results;
              if (selectedFonts) {
                results = [];
                for (element in selectedFonts) {
                  fontObj = selectedFonts[element];
                  results.push((_this.currentlySelectedFonts[element] = fontObj));
                }
                return results;
              }
            });
          };
        })(this),
        setFontFor: (function (_this) {
          return function (element) {
            return fontSelector.show().then(function (font) {
              if (font == null) {
                return;
              }
              _this.currentlySelectedFonts[element] = font;
              return loadFontsIntoBrowser(_this.currentlySelectedFonts);
            });
          };
        })(this),
        typographicElements: [
          {
            name: "Heading",
            tagClass: "heading-one",
            tagName: "h1",
            color: account.settings.brand.colors.brand,
            font: (function (_this) {
              return function () {
                return _this.currentlySelectedFonts.h1;
              };
            })(this),
          },
          {
            name: "Subheading",
            tagClass: "subheading",
            tagName: "h2",
            color: account.settings.brand.colors.complementary,
            font: (function (_this) {
              return function () {
                return _this.currentlySelectedFonts.h2;
              };
            })(this),
          },
          {
            name: "Body Text",
            tagClass: "body-text",
            tagName: "p",
            font: (function (_this) {
              return function () {
                return _this.currentlySelectedFonts.p;
              };
            })(this),
          },
        ],
      });
    }

    return TypographySelector;
  })();
}.call(this));
