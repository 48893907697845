// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = angular.module("qwilr.ImageOnLoad", []).directive("onImageLoad", function ($parse) {
    return {
      restrict: "A",
      link: function (scope, elem, attrs) {
        var imageLoadFunction;
        imageLoadFunction = $parse(attrs.onImageLoad);
        return elem.on("load", function (event) {
          return scope.$apply(function () {
            return imageLoadFunction(scope, {
              $event: event,
            });
          });
        });
      },
    };
  });
}.call(this));
