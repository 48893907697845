"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateGallerySources = void 0;
var EventNamesForTelemetry;
(function (EventNamesForTelemetry) {
    EventNamesForTelemetry["TemplatesGalleryOpened"] = "Templates Gallery Opened";
    EventNamesForTelemetry["TemplatesGalleryClosed"] = "Templates Gallery Closed";
    EventNamesForTelemetry["TabViewed"] = "Templates Gallery Tab Viewed";
    EventNamesForTelemetry["TemplatePreviewViewed"] = "Template Preview Viewed";
    EventNamesForTelemetry["TemplatePreviewClosed"] = "Template Preview Closed";
    EventNamesForTelemetry["CreatePageButtonClicked"] = "Create Page Button Clicked";
    EventNamesForTelemetry["TemplateCategoryClicked"] = "Template Category Clicked";
})(EventNamesForTelemetry || (EventNamesForTelemetry = {}));
var TemplateGallerySources;
(function (TemplateGallerySources) {
    TemplateGallerySources["FeaturedTemplatesPreviewModal"] = "featured template preview modal";
    TemplateGallerySources["TemplatePreview"] = "my template preview";
    TemplateGallerySources["MyTemplatesOptionMenu"] = "my template option menu";
    TemplateGallerySources["TemplateListItem"] = "template list item";
    TemplateGallerySources["FeaturedTemplateThumbnail"] = "featured template thumbnail";
    TemplateGallerySources["MyTemplates"] = "my templates";
    TemplateGallerySources["FeaturedTemplates"] = "featured templates";
})(TemplateGallerySources || (exports.TemplateGallerySources = TemplateGallerySources = {}));
var TEMPLATE_GALLERY = "Template Gallery";
var TemplatesGalleryTelemetry = (function () {
    function TemplatesGalleryTelemetry(telemetry) {
        var _this = this;
        this.telemetry = telemetry;
        this.templateGalleryOpened = function (onboarding) {
            if (onboarding === void 0) { onboarding = false; }
            _this.telemetry.trackOnly(EventNamesForTelemetry.TemplatesGalleryOpened, __assign({}, (onboarding && { event_origin: "qwilr_onboarding" })));
        };
        this.templateGalleryClosed = function (onboarding) {
            if (onboarding === void 0) { onboarding = false; }
            _this.telemetry.trackOnly(EventNamesForTelemetry.TemplatesGalleryClosed, __assign({}, (onboarding && { event_origin: "qwilr_onboarding" })));
        };
        this.previewViewed = function (template_id, source, selected_category) {
            _this.telemetry.trackOnly(EventNamesForTelemetry.TemplatePreviewViewed, {
                event_origin: TEMPLATE_GALLERY,
                template_id: template_id,
                source: source,
                selected_category: selected_category,
            });
        };
        this.previewClosed = function (template_id) {
            _this.telemetry.trackOnly(EventNamesForTelemetry.TemplatePreviewClosed, {
                event_origin: TEMPLATE_GALLERY,
                template_id: template_id,
            });
        };
        this.tabViewed = function (tab_name, previous_value) {
            _this.telemetry.trackOnly(EventNamesForTelemetry.TabViewed, {
                event_origin: TEMPLATE_GALLERY,
                tab_name: tab_name,
                previous_value: previous_value,
            });
        };
        this.categoryClicked = function (category_name) {
            _this.telemetry.trackOnly(EventNamesForTelemetry.TemplateCategoryClicked, {
                event_origin: TEMPLATE_GALLERY,
                category_name: category_name,
            });
        };
        this.telemetry = telemetry;
    }
    return TemplatesGalleryTelemetry;
}());
exports.default = TemplatesGalleryTelemetry;
