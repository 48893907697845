import "Application/Library/AutomationLibrary/CreateOrEditAutomationModal.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/AutomationLibrary/CreateOrEditAutomationModal.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61UXU/CMBR951fcxJjIQ8mm4mQ88kNIabt6sbRNP2Bq/O+mA8aGEyXxacl6z7nnnnPbyTKv4m67fc9gEnaGWKqFIl6EgFp6sjGcquXSoXwJ8DECWFH2Kp2JmhNmlHEl3FS0YhWfjwB2yMNLCXmW3c5Hn6PJkTtvoIf6LXV3ZA8mQdRh4TAgS13ylSrW63w27oHvGzBHbxV9K6FSok690pdwdIIFNLpM7HGj04mk9tjFW8pQS3Iiz3Wf/eH/2VsfimlmG7YNdRJ1CRnQGEyv/+M/9K/9ScBrf7zpT84jM7pF2T7oaZ+0cVy4EnJbgzcKOczbv2SAcX9yUpKNO/WOcoz+m/Cu8nVTbynnqOVvIw5YRhVKTTCIjS+BCR2Eu96vYjiOS9xFyrhD8fzDPel65aOrKBMLGlO8rRo1PFnrSWFryO/3O3XZ2G92XeFOZ51Ng11HH7B6I8zoIHQoIZUKshJhJ4TuDT+73r8/Z0MvviJnVj73sas/hJKuxDlNHFriaXreBpUccm3hMqn4AtrohwFjBQAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var modalWrapper = '_1fuwvvz0';
export var errorText = '_1fuwvvz1';
export var leftPanelWrapper = '_1fuwvvz2';
export var rightPanelWrapper = '_1fuwvvz3';
export var automationDetails = '_1fuwvvz4';
export var chevron = '_1fuwvvz5';
export var automationIcons = '_1fuwvvz6';
export var iconGroup = '_1fuwvvz7';
export var integrationAlert = '_1fuwvvz8';
export var integrationAlertLeft = '_1fuwvvz9';
export var integrationAlertLabel = '_1fuwvvza';
export var alertIcon = '_1fuwvvzb';