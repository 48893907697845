"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SavedBlockDragScroll = void 0;
var lodash_1 = require("lodash");
var mobx_1 = require("mobx");
var DragStore_1 = require("Project/Stores/DragStore/DragStore");
var CalculateVerticalStrength_1 = require("./CalculateVerticalStrength");
var STRENGTH_MULTIPLIER = 60;
var calculateBuffer = function () { return window.innerHeight * 0.125; };
var SavedBlockDragScroll = (function () {
    function SavedBlockDragScroll(dragStore) {
        var _this = this;
        this.dragStore = dragStore;
        this.lastCalculatedStrength = 0;
        this.updateScrolling = (0, lodash_1.throttle)(function (event) {
            var _a = window.document.body.getBoundingClientRect(), x = _a.left, y = _a.top, w = _a.width, h = _a.height;
            var box = { x: x, y: y, w: w, h: h };
            var coords = { x: event.clientX, y: event.clientY };
            _this.lastCalculatedStrength = (0, CalculateVerticalStrength_1.calculateVerticalStrength)(box, coords, calculateBuffer());
            if (!_this.currentAnimationFrame && _this.lastCalculatedStrength) {
                _this.runScrollingTick();
            }
        }, 100, { trailing: false });
        this.runScrollingTick = function () {
            var tick = function () {
                var lastCalculatedStrength = _this.lastCalculatedStrength;
                var _a = _this.scrollableDOMNode, scrollHeight = _a.scrollHeight, clientHeight = _a.clientHeight, scrollTop = _a.scrollTop;
                var preferred = scrollTop + lastCalculatedStrength * STRENGTH_MULTIPLIER;
                var lower = 0;
                var upper = scrollHeight - clientHeight;
                _this.scrollableDOMNode.scrollTop = (0, lodash_1.clamp)(preferred, lower, upper);
                _this.currentAnimationFrame = requestAnimationFrame(tick);
            };
            tick();
        };
    }
    SavedBlockDragScroll.prototype.bind = function () {
        var _this = this;
        this.disposeOfReaction = (0, mobx_1.autorun)(function () {
            var isDraggingBlock = _this.dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SavedBlock) ||
                _this.dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarBlockModel);
            if (isDraggingBlock) {
                _this.startScrolling();
            }
            else {
                _this.stopScrolling();
            }
        });
        this.scrollableDOMNode = document.getElementsByClassName("content-container__scroll-container").item(0);
    };
    SavedBlockDragScroll.prototype.unbind = function () {
        this.disposeOfReaction();
        this.stopScrolling();
    };
    SavedBlockDragScroll.prototype.startScrolling = function () {
        window.addEventListener("dragover", this.updateScrolling);
    };
    SavedBlockDragScroll.prototype.stopScrolling = function () {
        window.removeEventListener("dragover", this.updateScrolling);
        if (this.currentAnimationFrame) {
            cancelAnimationFrame(this.currentAnimationFrame);
            this.currentAnimationFrame = null;
            this.lastCalculatedStrength = 0;
        }
    };
    return SavedBlockDragScroll;
}());
exports.SavedBlockDragScroll = SavedBlockDragScroll;
