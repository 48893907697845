"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var menuTemplate = require("./ItemSettingsMenu.html");
var ToggleWithLabel = require("./ToggleWithLabel");
var Delete = require("./Delete");
var Save = require("./Save");
var OptionalQuantity = require("./OptionalQuantity");
var MoveUpDown = require("./MoveUpDown");
var Discount_1 = require("./Discount");
var ItemSettingsMenu = (function () {
    function ItemSettingsMenu(createUi, make, itemAdapter, deleter, saveImpl, itemView, sectionView) {
        var settings = [];
        var discountSetting = null;
        var isTextItem = itemAdapter.type() === "text" || itemAdapter.type() === "product";
        var addToggleWithLabel = function (description, getter, setter) {
            return settings.push(make(ToggleWithLabel, {
                description: description,
                getter: getter,
                setter: setter,
            }));
        };
        this.ui = createUi(menuTemplate, {
            getSettings: function () {
                return settings;
            },
        });
        settings.push(make(MoveUpDown, {
            description: "Move line item",
            viewToMove: itemView,
            moveUp: sectionView.moveItemUp,
            moveDown: sectionView.moveItemDown,
        }));
        if (!isTextItem) {
            addToggleWithLabel("Tax exempt", itemAdapter.isTaxExempt, itemAdapter.setTaxExempt);
            addToggleWithLabel("Optional line item", itemAdapter.isOptional, itemAdapter.setOptional);
            settings.push(make(OptionalQuantity, {
                description: "Optional quantity",
                itemAdapter: itemAdapter,
            }));
            discountSetting = make(Discount_1.Discount, {
                description: "Apply discount",
                discountAdapter: itemAdapter.getDiscountAdapter(),
            });
            settings.push(discountSetting);
        }
        settings.push(make(Save, { saveImpl: saveImpl }));
        settings.push(make(Delete, {
            deleter: deleter,
            isOnlyItem: function () {
                return itemAdapter.isOnlyItem();
            },
        }));
    }
    return ItemSettingsMenu;
}());
exports.default = ItemSettingsMenu;
