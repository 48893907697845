"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlocksTab = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var SidebarCarousel_1 = require("Project/ContentSidebar/Explore/SharedComponents/SidebarCarousel");
var react_1 = __importStar(require("react"));
var styles = __importStar(require("./BlocksTab.css"));
var Types_1 = require("Project/ContentSidebar/Types");
var SidebarExploreContext_1 = require("../SidebarExploreContext");
var ExploreEmptyState_1 = require("../SharedComponents/ExploreEmptyState");
var SidebarExplore_1 = require("Project/ContentSidebar/Explore/SidebarExplore");
var Types_2 = require("../Types");
var GenericThumbnail_1 = require("Project/ContentSidebar/Explore/SharedComponents/GenericThumbnail");
var BlocksTab = function (_a) {
    var onClickToAdd = _a.onClickToAdd, onDragAssetEnd = _a.onDragAssetEnd, onDragAssetStart = _a.onDragAssetStart;
    var _b = (0, SidebarExploreContext_1.useSidebarExploreContext)(), store = _b.blockExploreDataStore, searchTerm = _b.searchTerm;
    var _c = __read((0, react_1.useState)([]), 2), blocks = _c[0], setBlocks = _c[1];
    (0, react_1.useEffect)(function () {
        if (searchTerm) {
            store.search(searchTerm);
        }
        else {
            store.clearSearch();
        }
        setBlocks(store.groupedAssets);
    }, [searchTerm, store]);
    var showEmptyState = blocks.length === 0;
    return (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.container, gap: "l", "data-testid": "sidebar-blocks-list" }, searchTerm ? (showEmptyState ? (react_1.default.createElement(ExploreEmptyState_1.ExploreEmptyState, { assetType: Types_1.AssetType.Block, searchTerm: searchTerm })) : (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.grid, "data-testid": "sidebar-block-grid" }, store.loadedAssets.map(function (asset, index) { return (react_1.default.createElement(GenericThumbnail_1.GenericThumbnail, { key: index, asset: asset, onClick: function () {
            onClickToAdd(asset, { tab: SidebarExplore_1.SidebarExploreTab.Blocks, withSearchTerm: !!searchTerm });
        }, onDragStart: function () {
            onDragAssetStart(asset, { tab: SidebarExplore_1.SidebarExploreTab.Blocks, withSearchTerm: !!searchTerm });
        }, onDragEnd: function () {
            onDragAssetEnd(asset);
        } })); })))) : (blocks.map(function (_a) {
        var _b = __read(_a, 2), category = _b[0], categoryAssets = _b[1];
        return (react_1.default.createElement(kaleidoscope_1.Stack, { key: category },
            react_1.default.createElement(kaleidoscope_1.Stack, { paddingLeft: "l", paddingRight: "l" },
                react_1.default.createElement(kaleidoscope_1.Text, { size: "s", strong: true, lineHeight: "relaxed" }, category)),
            react_1.default.createElement(SidebarCarousel_1.SidebarCarousel, { items: categoryAssets, onClick: function (asset) {
                    if ((0, Types_2.isAssetBlock)(asset)) {
                        onClickToAdd(asset, { tab: SidebarExplore_1.SidebarExploreTab.Blocks, withSearchTerm: !!searchTerm });
                    }
                }, onDragStart: function (asset) {
                    if ((0, Types_2.isAssetBlock)(asset)) {
                        onDragAssetStart(asset, { tab: SidebarExplore_1.SidebarExploreTab.Blocks, withSearchTerm: !!searchTerm });
                    }
                }, onDragEnd: function (asset) {
                    if ((0, Types_2.isAssetBlock)(asset)) {
                        onDragAssetEnd(asset);
                    }
                } })));
    }))));
};
exports.BlocksTab = BlocksTab;
