// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = angular.module("qwilr.previousState", []).run(function ($state, $stateParams, $rootScope) {
    return $rootScope.$on("$stateChangeSuccess", function (event, toState, toParams, fromState, fromParams) {
      $rootScope.previousState = {
        name: fromState.name,
        params: fromParams,
      };
      return ($rootScope.gotoPreviousState = function () {
        if ($rootScope.previousState.name !== "") {
          return $state.go($rootScope.previousState.name, $rootScope.previousState.params);
        } else {
          return $state.go("dashboard");
        }
      });
    });
  });
}.call(this));
