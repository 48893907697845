// Generated by CoffeeScript 1.12.7

/*
  This Angular directive encapsulates the in-app "reachout" action for users.
  We've abstracted it so that if we decide to switch in-app messaging providers,
  or move to a different client communication model, we can centralise that behavior
  here.

  Currently, this will open the Helpscout Beacon in messaging mode. If Beacon is not available for
  some reason (e.g it hasn't loaded yet, or we've manually turned it off), it will fall back to a
  regular mailto: link
 */

(function () {
  module.exports = angular.module("qwilr.reachout", []).directive("reachout", function () {
    return {
      restrict: "AE",
      template:
        "<a href='mailto:help@qwilr.com' target='_blank' rel='noopener noreferrer' ng-click='openChat($event)' class='inline-reachout-angular'>{{ ::text }}</a>",
      scope: {
        text: "@",
        message: "@",
      },
      link: function (scope, element, attrs) {
        if (scope.text == null) {
          scope.text = "reach out";
        }
        if (scope.message == null) {
          scope.message = "";
        }
        return (scope.openChat = function (e) {
          if (window.Beacon) {
            Beacon("open");
            Beacon("navigate", "/ask/message/");
            return e.preventDefault();
          }
        });
      },
    };
  });
}.call(this));
