"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DefaultColors_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/DefaultColors");
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
exports.default = (function (colors) {
    return {
        isFactoryStyle: false,
        blockType: commonTypes_1.BlockType.Accept,
        css: "",
        h1: {
            color: {
                themeIndex: 0,
                abs: colors[0].value,
            },
            size: {
                abs: 65,
                themeRef: "medium",
            },
            alignment: commonTypes_1.AlignmentTypes.Center,
        },
        h2: {
            color: {
                themeIndex: 1,
                abs: colors[1].value,
            },
            size: {
                abs: 40,
                themeRef: "medium",
            },
            alignment: commonTypes_1.AlignmentTypes.Center,
        },
        p: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
                abs: DefaultColors_1.DEFAULT_MIDNIGHT.value,
            },
            size: {
                abs: 20,
                themeRef: "medium",
            },
            alignment: commonTypes_1.AlignmentTypes.Center,
        },
        blockQuote: {
            color: {
                themeIndex: DefaultColors_1.DEFAULT_MIDNIGHT.themeIndex,
                abs: DefaultColors_1.DEFAULT_MIDNIGHT.value,
            },
            size: {
                abs: 27,
                themeRef: "medium",
            },
            barColor: {
                themeIndex: 0,
                abs: colors[0].value,
            },
            displayQuoteIcon: false,
        },
        acceptButton: {
            color: {
                themeIndex: 0,
                abs: colors[0].value,
            },
            alignment: commonTypes_1.AlignmentTypes.Center,
        },
        background: {
            themeIndex: DefaultColors_1.DEFAULT_WHITE.themeIndex,
            abs: DefaultColors_1.DEFAULT_WHITE.value,
        },
        textSize: {
            ref: commonTypes_1.OptionLabelTypes.Small,
            abs: 14,
        },
        blockWidth: commonTypes_1.OptionLabelTypes.Medium,
        blockSpacing: commonTypes_1.OptionLabelTypes.Medium,
        hash: "",
    };
});
