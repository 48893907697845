// Generated by CoffeeScript 1.12.7
(function () {
  var FeatureFlag, Identity, Mail, validator;

  validator = require("validator");

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  Mail = require("Common/Utils/ImageImports").Mail;

  module.exports = Identity = (function () {
    Identity.prototype.template = require("./Identity.html");

    function Identity(createUi, di, $timeout, link, imageLocation, usingFeature, getDisabledMessage, Tooltip, $q) {
      var areAnyIdentityOptionsEnabled,
        base,
        errorMessages,
        hideErrorMessage,
        parseAllowedDomains,
        showErrorMessage,
        showIncorrectDomainValueError,
        showUnsetIdentityError,
        validateDomains;
      if (link.identityMapping == null) {
        link.identityMapping = {
          enabled: false,
        };
      }
      if ((base = link.identityMapping).limitDomains == null) {
        base.limitDomains = {
          enabled: false,
          allowedDomains: "",
        };
      }
      this.isEnabled = (function (_this) {
        return function () {
          var featureEnabled, hasMethodEnabled, mapping;
          mapping = link.identityMapping;
          featureEnabled = mapping.enabled;
          hasMethodEnabled = mapping.emailVerificationEnabled;
          return featureEnabled && hasMethodEnabled;
        };
      })(this);
      errorMessages = {};
      showUnsetIdentityError = function () {
        showErrorMessage(
          "Please select one of the above identification methods",
          ".identity-settings .domain-text-wrap .toggle-button__click-field",
          ".domain-text-wrap",
        );
        return $timeout(hideErrorMessage, 2000);
      };
      showIncorrectDomainValueError = function () {
        return showErrorMessage(
          "Incorrect domain value",
          ".identity-settings .domain-text-wrap .limit-domain-input",
          ".domain-text-wrap",
        );
      };
      showErrorMessage = function (message, selector, container) {
        var errorMessage, position;
        position = "bottom";
        errorMessage = errorMessages[message];
        if (errorMessage == null) {
          errorMessage = di.make(Tooltip, {
            targetSelector: selector,
            options: {
              container: container,
              position: position,
              stringContent: message,
              spacing: 5,
              customTheme: "error-theme",
            },
          });
          errorMessages[message] = errorMessage;
        }
        return errorMessage.show();
      };
      hideErrorMessage = function () {
        var errorMessage, i, results;
        results = [];
        for (i in errorMessages) {
          errorMessage = errorMessages[i];
          results.push(errorMessage != null ? errorMessage.hide() : void 0);
        }
        return results;
      };
      parseAllowedDomains = function (allowedDomains) {
        var domains;
        domains = allowedDomains.split(",");
        return _.map(domains, function (d) {
          return d.trim();
        });
      };
      validateDomains = function (allowedDomains) {
        var d, domains, j, len, result;
        domains = parseAllowedDomains(allowedDomains);
        result = true;
        for (j = 0, len = domains.length; j < len; j++) {
          d = domains[j];
          if (!validator.isFQDN(d) && d.length !== 0) {
            result = false;
          }
        }
        return result;
      };
      areAnyIdentityOptionsEnabled = function () {
        return link.identityMapping.emailVerificationEnabled;
      };
      this.waiting = void 0;
      this.ui = createUi(this.template, {
        featureAvailable: usingFeature(FeatureFlag.IdentityMapping) || this.isEnabled(),
        getDisabledMessage: getDisabledMessage,
        emailIcon: Mail,
        isDomainLimited: link.identityMapping.limitDomains.enabled,
        allowedDomains: link.identityMapping.limitDomains.allowedDomains,
        domainsValid: true,
        errorMessage: null,
        isDomainLimitedToggleDisabled: !areAnyIdentityOptionsEnabled(),
        onDomainLimitedDisabledClick: showUnsetIdentityError,
        requireVerification: link.identityMapping.enabled,
        requireVerificationToggled: (function (_this) {
          return function () {
            link.identityMapping.enabled = _this.ui.requireVerification;
            if (!_this.ui.requireVerification) {
              link.identityMapping.emailVerificationEnabled = false;
              return (link.identityMapping.limitDomains = {
                enabled: false,
                allowedDomains: "",
              });
            }
          };
        })(this),
        domainLimitToggle: (function (_this) {
          return function () {
            link.identityMapping.limitDomains.enabled = _this.ui.isDomainLimited;
            if (!_this.ui.isDomainLimited) {
              return (link.identityMapping.limitDomains.allowedDomains = "");
            }
          };
        })(this),
        emailVerificationEnabled: link.identityMapping.emailVerificationEnabled,
        enabledMethodsChanged: (function (_this) {
          return function () {
            link.identityMapping.emailVerificationEnabled = _this.ui.emailVerificationEnabled;
            return (_this.ui.isDomainLimitedToggleDisabled = !areAnyIdentityOptionsEnabled());
          };
        })(this),
        domainInvalid: (function (_this) {
          return function () {
            return !_this.ui.domainsValid;
          };
        })(this),
        updateDomainLimiting: (function (_this) {
          return function () {
            if (_this.waiting != null) {
              $timeout.cancel(_this.waiting);
            }
            if (!validateDomains(_this.ui.allowedDomains)) {
              _this.ui.domainsValid = false;
              return (_this.waiting = $timeout(showIncorrectDomainValueError, 1000));
            } else {
              _this.ui.domainsValid = true;
              link.identityMapping.limitDomains.allowedDomains = _this.ui.allowedDomains;
              return hideErrorMessage();
            }
          };
        })(this),
      });
    }

    return Identity;
  })();
}.call(this));
