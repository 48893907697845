// Generated by CoffeeScript 1.12.7

/*
  This module creates UI for users to interact with the BluePrint API.
 */

(function () {
  var BluePrint, IntegrationApiService, attachBuildFlow;

  attachBuildFlow = require("./BuildFlow/BuildFlow").attachBuildFlow;

  IntegrationApiService = require("../../IntegrationApiService")["default"];

  module.exports = BluePrint = (function () {
    function BluePrint(
      createUi,
      user,
      overlayStack,
      di,
      make,
      project,
      viewChildren,
      bluePrintService,
      projectCounter,
      projectLinkRetriever,
      telemetry,
    ) {
      var associateContextWithLinks, showChildProjects;
      showChildProjects = (function (_this) {
        return function () {
          overlayStack.closeCurrent();
          return viewChildren(project);
        };
      })(this);
      this.ui = createUi(require("./BluePrint.html"), {
        showChildren: showChildProjects,
        buildFlow: di.convoke(attachBuildFlow, null, {
          showChildProjects: showChildProjects,
          integrationApiService: make(IntegrationApiService),
          getOutputFile: (function (_this) {
            return function (createdProjects) {
              return bluePrintService.getOutputFile(createdProjects, {
                filename: project.name + "-projects",
              });
            };
          })(this),
          runBuild: (function (_this) {
            return function (variables) {
              return bluePrintService.build(project, variables).then(function (builtProjects) {
                var contextualisedProjects;
                projectCounter.add(builtProjects.length);
                contextualisedProjects = associateContextWithLinks(builtProjects, variables);
                return contextualisedProjects;
              });
            };
          })(this),
          telemetry: telemetry,
        }),
      });
      this.show = (function (_this) {
        return function () {
          return overlayStack.add(_this.ui);
        };
      })(this);
      associateContextWithLinks = (function (_this) {
        return function (createdProjects, importData) {
          var associatedData, context, fieldName, i, index, len, ref;
          if (((ref = importData[0]) != null ? ref.link : void 0) != null) {
            fieldName = "qwilrPageLink";
          } else {
            fieldName = "link";
          }
          associatedData = [];
          for (index = i = 0, len = importData.length; i < len; index = ++i) {
            context = importData[index];
            context[fieldName] = projectLinkRetriever.getUrlForSecret(createdProjects[index]);
            associatedData.push(context);
          }
          return associatedData;
        };
      })(this);
    }

    return BluePrint;
  })();
}.call(this));
