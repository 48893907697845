"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateSelectedListRange = void 0;
var lodash_1 = require("lodash");
var ListLayout_1 = require("./ListLayout");
var calculateSelectedListRange = function (rectangleTop, rectangleRight, rectangleBottom, rectangleLeft, projectListLeft, projectListTop, projectListRight) {
    if (rectangleLeft < projectListRight && rectangleRight > projectListLeft) {
        var firstIndex = Math.floor((rectangleTop - projectListTop + ListLayout_1.PROJECT_LIST_ITEM_MARGIN_BOTTOM) /
            (ListLayout_1.PROJECT_LIST_ITEM_HEIGHT + ListLayout_1.PROJECT_LIST_ITEM_MARGIN_BOTTOM));
        var lastIndex = Math.floor((rectangleBottom - projectListTop) / (ListLayout_1.PROJECT_LIST_ITEM_HEIGHT + ListLayout_1.PROJECT_LIST_ITEM_MARGIN_BOTTOM));
        if (firstIndex >= 0) {
            return (0, lodash_1.range)(firstIndex, lastIndex + 1);
        }
        else if (lastIndex >= 0) {
            return (0, lodash_1.range)(0, lastIndex + 1);
        }
    }
    return [];
};
exports.calculateSelectedListRange = calculateSelectedListRange;
