"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersDataStore = void 0;
var mobx_1 = require("mobx");
var UsersDataStore = (function () {
    function UsersDataStore(accountUsers) {
        var e_1, _a;
        this.allAccountUsers = new Map();
        try {
            for (var accountUsers_1 = __values(accountUsers), accountUsers_1_1 = accountUsers_1.next(); !accountUsers_1_1.done; accountUsers_1_1 = accountUsers_1.next()) {
                var u = accountUsers_1_1.value;
                this.allAccountUsers.set(u._id, {
                    id: u._id,
                    firstName: u.firstName || "",
                    lastName: u.lastName || "",
                    email: u.email,
                    role: u.roles[0].name,
                    apiKey: u.apiKey,
                    census: u.census || {},
                });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (accountUsers_1_1 && !accountUsers_1_1.done && (_a = accountUsers_1.return)) _a.call(accountUsers_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    UsersDataStore.prototype.getAllUsers = function () {
        return this.allAccountUsers;
    };
    var _a;
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", typeof (_a = typeof Map !== "undefined" && Map) === "function" ? _a : Object)
    ], UsersDataStore.prototype, "allAccountUsers", void 0);
    return UsersDataStore;
}());
exports.UsersDataStore = UsersDataStore;
