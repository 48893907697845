"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var commonTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/BlockConfig/commonTypes");
var H1 = commonTypes_1.TextElementType.H1, H2 = commonTypes_1.TextElementType.H2, P = commonTypes_1.TextElementType.P, Blockquote = commonTypes_1.TextElementType.Blockquote, UnorderedListItem = commonTypes_1.TextElementType.UnorderedListItem, OrderedListItem = commonTypes_1.TextElementType.OrderedListItem;
var getElementMap = function (elementTypes, hasOrderedListItem) {
    var _a;
    var elementMap = (_a = {},
        _a[H1] = false,
        _a[H2] = false,
        _a[P] = false,
        _a[Blockquote] = false,
        _a[UnorderedListItem] = false,
        _a[OrderedListItem] = false,
        _a);
    elementTypes.forEach(function (type) {
        elementMap[type] = true;
    });
    if (hasOrderedListItem) {
        elementMap[OrderedListItem] = true;
    }
    return elementMap;
};
var shouldShowTitle = function (elementTypes, elementMap) {
    if (elementTypes.length === 1) {
        return true;
    }
    else if (elementTypes.length === 2) {
        return elementMap[UnorderedListItem] && elementMap[OrderedListItem];
    }
    else {
        return false;
    }
};
var shouldShowTextSize = function (elementTypes, elementMap) {
    if (elementTypes.length === 1) {
        return true;
    }
    else if (elementTypes.length <= 3) {
        return ((elementMap[P] || elementMap[UnorderedListItem] || elementMap[OrderedListItem]) &&
            !(elementMap[H1] || elementMap[H2] || elementMap[Blockquote]));
    }
    else {
        return false;
    }
};
var shouldShowAlignment = function (elementTypes, elementMap) {
    return !(elementMap[UnorderedListItem] || elementMap[OrderedListItem] || elementMap[Blockquote]);
};
var shouldShowPullQuoteControls = function (elementTypes, elementMap) {
    return elementTypes.length === 1 && elementMap[Blockquote];
};
var shouldShowMarkerColor = function (elementTypes, elementMap) {
    return ((elementMap[UnorderedListItem] || elementMap[OrderedListItem]) &&
        !(elementMap[H1] || elementMap[H2] || elementMap[P] || elementMap[Blockquote]));
};
var shouldShowMarkerType = function (elementTypes, elementMap) {
    return (elementMap[UnorderedListItem] &&
        !(elementMap[H1] || elementMap[H2] || elementMap[P] || elementMap[Blockquote] || elementMap[OrderedListItem]));
};
exports.default = (function (elementTypes, hasOrderedListItem) {
    var elementMap = getElementMap(elementTypes, hasOrderedListItem);
    return {
        showTitle: shouldShowTitle(elementTypes, elementMap),
        showTextColor: true,
        showTextSize: shouldShowTextSize(elementTypes, elementMap),
        showAlignment: shouldShowAlignment(elementTypes, elementMap),
        showBarColor: shouldShowPullQuoteControls(elementTypes, elementMap),
        showDisplayQuoteIcon: shouldShowPullQuoteControls(elementTypes, elementMap),
        showMarkerColor: shouldShowMarkerColor(elementTypes, elementMap),
        showMarkerType: shouldShowMarkerType(elementTypes, elementMap),
    };
});
