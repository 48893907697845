// Generated by CoffeeScript 1.12.7
(function () {
  var FeatureFlag,
    OAuthWindow,
    StripeIntegration,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  OAuthWindow = require("@CommonFrontend/OAuth/OAuthWindow")["default"];

  module.exports = StripeIntegration = (function () {
    StripeIntegration.prototype.AUTH_BASE_URL = "https://connect.stripe.com/oauth/authorize";

    function StripeIntegration(
      createUi,
      make,
      $http,
      utils,
      publicApiBaseUrl,
      stripeClientId,
      apiBaseUrl,
      account,
      usingFeature,
      showAcknowledgeModal,
      overlayStack,
    ) {
      var enableUi, ref;
      this.utils = utils;
      this.publicApiBaseUrl = publicApiBaseUrl;
      this.stripeClientId = stripeClientId;
      this.apiBaseUrl = apiBaseUrl;
      this.account = account;
      this.disable = bind(this.disable, this);
      this.enable = bind(this.enable, this);
      this.http = $http;
      this.ui = createUi(require("SettingsAngular/Integrations/Stripe/Stripe.html"), {});
      enableUi = make(require("../OauthEnablingUI"), {
        name: "Stripe",
      });
      this.enablingUi = enableUi.ui;
      if (
        usingFeature(FeatureFlag.LegacyCustomDomain) &&
        !((ref = this.account.settings) != null ? ref.customDomainHasUpdatedDns : void 0) &&
        this.account.customDomain
      ) {
        showAcknowledgeModal({
          title: "Secure your Qwilr Pages with HTTPS",
          explainer: createUi(require("./SslExplainer.html")),
          button: "Got it",
        }).then(function () {
          return overlayStack.closeCurrent();
        });
      }
    }

    StripeIntegration.prototype.enable = function () {
      var authUrl, params;
      params = {
        response_type: "code",
        client_id: this.stripeClientId,
        redirect_uri: this.publicApiBaseUrl + "/stripe-connect/callback",
        state: this.utils.uniqueId(20),
        scope: "read_write",
      };
      authUrl = this.AUTH_BASE_URL + "?" + $.param(params);
      return OAuthWindow.show(authUrl, {
        width: 900,
        height: 600,
      }).then(
        (function (_this) {
          return function () {
            var base;
            if ((base = _this.account.integrations).stripe == null) {
              base.stripe = {};
            }
            return (_this.account.integrations.stripe.enabled = true);
          };
        })(this),
      );
    };

    StripeIntegration.prototype.disable = function () {
      return this.http.post(this.apiBaseUrl + "/integration/stripe/disable").then(
        (function (_this) {
          return function () {
            return (_this.account.integrations.stripe = {
              enabled: false,
            });
          };
        })(this),
      );
    };

    return StripeIntegration;
  })();
}.call(this));
