"use strict";
var _a, _b, _c;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPopularTokens = void 0;
var Types_1 = require("@CommonFrontendBackend/Tokens/Types");
var opportunityTokens = [
    {
        apiName: "Name",
        apiReference: "Name",
        name: "Name",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Opportunity,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Account.Name",
        apiReference: "Account.Name",
        name: "Account.Name",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Opportunity,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "primaryContact.FirstName",
        apiReference: "primaryContact.FirstName",
        name: "Primary Contact.FirstName",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Opportunity,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "primaryContact.LastName",
        apiReference: "primaryContact.LastName",
        name: "Primary Contact.LastName",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Opportunity,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "primaryContact.Name",
        apiReference: "primaryContact.Name",
        name: "Primary Contact.Name",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Opportunity,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Owner.Email",
        apiReference: "Owner.Email",
        name: "Owner.Email",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Opportunity,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "CloseDate",
        apiReference: "CloseDate",
        name: "CloseDate",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Opportunity,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: Types_1.ProductToken,
        apiReference: Types_1.ProductToken,
        name: "Products",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Opportunity,
        dataType: Types_1.TokenDataType.ProductList,
        childObjectType: Types_1.SalesforceObjectType.OpportunityLineItem,
        childPropertyLabel: "p",
    },
];
var accountTokens = [
    {
        apiName: "Name",
        apiReference: "Name",
        name: "Name",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Account,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Owner.FirstName",
        apiReference: "Owner.FirstName",
        name: "Owner.FirstName",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Account,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Owner.LastName",
        apiReference: "Owner.LastName",
        name: "Owner.LastName",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Account,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Owner.Name",
        apiReference: "Owner.Name",
        name: "Owner.Name",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Account,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Owner.Email",
        apiReference: "Owner.Email",
        name: "Owner.Email",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Account,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "BillingCity",
        apiReference: "BillingCity",
        name: "BillingCity",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Account,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "BillingCountry",
        apiReference: "BillingCountry",
        name: "BillingCountry",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Account,
        dataType: Types_1.TokenDataType.String,
    },
];
var contactTokens = [
    {
        apiName: "Name",
        apiReference: "Name",
        name: "Name",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Contact,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "FirstName",
        apiReference: "FirstName",
        name: "FirstName",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Contact,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "LastName",
        apiReference: "LastName",
        name: "LastName",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Contact,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Salutation",
        apiReference: "Salutation",
        name: "Salutation",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Contact,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Email",
        apiReference: "Email",
        name: "Email",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Contact,
        dataType: Types_1.TokenDataType.String,
    },
];
var leadTokens = [
    {
        apiName: "FirstName",
        apiReference: "FirstName",
        name: "FirstName",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Lead,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "LastName",
        apiReference: "LastName",
        name: "LastName",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Lead,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Name",
        apiReference: "Name",
        name: "Name",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Lead,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Company",
        apiReference: "Company",
        name: "Company",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Lead,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Owner.FirstName",
        apiReference: "Owner.FirstName",
        name: "Owner.FirstName",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Lead,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Owner.LastName",
        apiReference: "Owner.LastName",
        name: "Owner.LastName",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Lead,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Owner.Name",
        apiReference: "Owner.Name",
        name: "Owner.Name",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Lead,
        dataType: Types_1.TokenDataType.String,
    },
    {
        apiName: "Owner.Email",
        apiReference: "Owner.Email",
        name: "Owner.Email",
        providerType: Types_1.ProviderType.Salesforce,
        objectType: Types_1.SalesforceObjectType.Lead,
        dataType: Types_1.TokenDataType.String,
    },
];
var salesforceObjects = (_a = {},
    _a[Types_1.SalesforceObjectType.Opportunity] = opportunityTokens,
    _a[Types_1.SalesforceObjectType.Contact] = contactTokens,
    _a[Types_1.SalesforceObjectType.Lead] = leadTokens,
    _a[Types_1.SalesforceObjectType.Account] = accountTokens,
    _a);
var hubspotObjects = (_b = {},
    _b[Types_1.HubspotObjectType.Contact] = [
        {
            apiName: "company.name",
            apiReference: "company.name",
            name: "Company Name",
            providerType: Types_1.ProviderType.Hubspot,
            objectType: Types_1.HubspotObjectType.Contact,
            dataType: Types_1.TokenDataType.String,
        },
    ],
    _b[Types_1.HubspotObjectType.Deal] = [
        {
            apiName: "company.name",
            apiReference: "company.name",
            name: "Company Name",
            providerType: Types_1.ProviderType.Hubspot,
            objectType: Types_1.HubspotObjectType.Deal,
            dataType: Types_1.TokenDataType.String,
        },
    ],
    _b[Types_1.HubspotObjectType.Company] = [
        {
            apiName: "name",
            apiReference: "name",
            name: "Name",
            providerType: Types_1.ProviderType.Hubspot,
            objectType: Types_1.HubspotObjectType.Company,
            dataType: Types_1.TokenDataType.String,
        },
    ],
    _b);
var PopularV3Tokens = (_c = {},
    _c[Types_1.ProviderType.Salesforce] = salesforceObjects,
    _c[Types_1.ProviderType.Sfdc] = salesforceObjects,
    _c[Types_1.ProviderType.Hubspot] = hubspotObjects,
    _c);
var getPopularTokens = function (providerType, crmObjectType) {
    var popularTokens = (PopularV3Tokens[providerType] && PopularV3Tokens[providerType][crmObjectType]) || [];
    return popularTokens;
};
exports.getPopularTokens = getPopularTokens;
