// Generated by CoffeeScript 1.12.7

/*
 * Defines some basic helpers to be used across integrations which support
 * sending invoices.
 */

(function () {
  var IntegrationsInvoiceSettings;

  module.exports = IntegrationsInvoiceSettings = (function () {
    function IntegrationsInvoiceSettings(account, $http, apiBaseUrl) {
      this.autoPushInvoice = function (integration) {
        var ref, ref1, ref2, ref3;
        return (
          ((ref = account.integrations) != null
            ? (ref1 = ref.invoice) != null
              ? ref1.pushOnAccept
              : void 0
            : void 0) &&
          ((ref2 = account.integrations) != null ? ((ref3 = ref2.invoice) != null ? ref3.sendTo : void 0) : void 0) ===
            integration
        );
      };
      this.toggleAutoPush = function (value, integration) {
        var postData;
        if (value == null) {
          return;
        }
        postData = {
          "integrations.invoice.pushOnAccept": value,
        };
        if (value) {
          postData["integrations.invoice.sendTo"] = integration;
        }
        return $http.post(apiBaseUrl + "/accounts/" + account._id, postData).success(
          (function (_this) {
            return function (response) {
              return (account.integrations.invoice = {
                pushOnAccept: value,
              });
            };
          })(this),
        );
      };
    }

    return IntegrationsInvoiceSettings;
  })();
}.call(this));
