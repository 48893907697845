// Generated by CoffeeScript 1.12.7
(function () {
  var CrazyEggIntegration;

  module.exports = CrazyEggIntegration = (function () {
    function CrazyEggIntegration(createUi, genericIntegration, $q) {
      var getAccountSnippet;
      genericIntegration.build.call(this, "crazyEgg", "Account Number", require("./CrazyEgg.html"), function (ui) {
        return getAccountSnippet(ui.accountNumber).then(function (accountSnippet) {
          return (ui.settings.accountSnippet = accountSnippet);
        });
      });
      getAccountSnippet = function (accountNumber) {
        if (accountNumber == null || !accountNumber.length) {
          return $q.reject("Whoops, looks like you left this field blank. Please try again.");
        } else if (accountNumber.length === 8) {
          return $q.resolve(accountNumber.slice(0, 4) + "/" + accountNumber.slice(4, 8));
        } else {
          return $q.reject("Whoops, that doesn't look like an account number. Please try again.");
        }
      };
    }

    return CrazyEggIntegration;
  })();
}.call(this));
