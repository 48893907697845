// Generated by CoffeeScript 1.12.7
(function () {
  var ApiKeyController, FeatureFlag;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = ApiKeyController = (function () {
    function ApiKeyController(createUi, $http, user, apiBaseUrl, usingFeature) {
      this.ui = createUi(require("./ApiKey.html"), {
        flags: {
          generating: false,
        },
        apiKey: user.apiKey,
        internalApiIsAvailable: usingFeature(FeatureFlag.PublicApi),
        generateApiKey: (function (_this) {
          return function () {
            _this.ui.flags.generating = true;
            return $http
              .post(apiBaseUrl + "/users/" + user._id + "/generate-api-key")
              .then(function (results) {
                _this.ui.flags.generating = false;
                return (_this.ui.apiKey = results.data);
              })
              ["catch"](function (error) {
                _this.ui.flags.generating = false;
                return (_this.ui.error = error.message);
              });
          };
        })(this),
      });
    }

    return ApiKeyController;
  })();
}.call(this));
