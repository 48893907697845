// Generated by CoffeeScript 1.12.7
(function () {
  var ApiSettings, FeatureFlag;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = ApiSettings = (function () {
    function ApiSettings(
      createUi,
      make,
      invoke,
      user,
      usingFeature,
      $http,
      apiBaseUrl,
      account,
      subscriptionService,
      upseller,
    ) {
      var apiKey, objectIds, variables;
      if (usingFeature(FeatureFlag.QwilrApi) || usingFeature(FeatureFlag.ZapierIntegration)) {
        this.ui = createUi(require("./ApiSettings.html"), {
          startUpgrade: subscriptionService.start,
          apiIsAvailable: usingFeature(FeatureFlag.QwilrApi),
          internalApiIsAvailable: usingFeature(FeatureFlag.PublicApi),
        });
        apiKey = make(require("./ApiKey/ApiKey"));
        this.ui.apiKey = apiKey.ui;
        if (this.ui.internalApiIsAvailable) {
          this.ui.accessTokens = invoke(require("./AccessTokensPanel/AccessTokensPanel").attachAccessTokensPanel);
        }
        if (usingFeature(FeatureFlag.QwilrApi)) {
          variables = make(require("./Variables/Variables"));
          objectIds = make(require("./ObjectIds/ObjectIds"));
          this.ui.variables = variables.ui;
          this.ui.objectIds = objectIds.ui;
        } else {
          this.ui.loading = true;
          $http.get(apiBaseUrl + "/plans/feature/qwilr-api").then(
            (function (_this) {
              return function (response) {
                /*
                @NOTE(blambo, 2017-04-28): If the "qwilr-api" feature isn't found on any plans we still
                haven't released the feature and it is in a beta state
                @TODO(blambo, 2017-04-28): Remove this branching when the "qwilr-api" feature has been
                released
               */
                var plansWithFeature;
                plansWithFeature = response.data.message;
                if (plansWithFeature.length > 0) {
                  _this.ui.apiReleased = true;
                  _this.ui.planHasAccessToApi = plansWithFeature[0].displayName;
                  _this.ui.currentPlanName = account.plan.displayName;
                } else {
                  _this.ui.apiReleased = false;
                }
                return (_this.ui.loading = false);
              };
            })(this),
          );
        }
      } else {
        upseller.upsell({
          feature: "zapier integration",
          customUpsellMessage:
            "Upgrade to access your API key and integrate Qwilr with your custom workflows using Zapier",
        });
      }
    }

    return ApiSettings;
  })();
}.call(this));
