// Generated by CoffeeScript 1.12.7
(function () {
  var AccountController, FeatureFlag, mountProfileError, mountReactComponent, mountReadOnlyMessage, ref;

  (ref = require("./ProfileMessages")),
    (mountReadOnlyMessage = ref.mountReadOnlyMessage),
    (mountProfileError = ref.mountProfileError);

  mountReactComponent = require("MountReactComponent/MountReactComponent")["default"];

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = AccountController = (function () {
    function AccountController(createUi, usingFeature, make, user, rolesService, $http, apiBaseUrl) {
      var changeEmail, changeName, changePassword, changePhoneNumber;
      changePassword = make(require("./ChangePassword/ChangePassword"));
      changeName = make(require("./ChangeName/ChangeName"));
      changeEmail = make(require("./ChangeEmail/ChangeEmail"), {
        readOnly: false,
      });
      this.ui = createUi(require("./Profile.html"), {
        loaded: false,
        changeEmail: changeEmail.ui,
        changePassword: changePassword.ui,
        changeName: changeName.ui,
        readOnlyMessage: mountReadOnlyMessage(mountReactComponent(createUi)),
        errorAlert: mountProfileError(mountReactComponent(createUi)),
        isEmailInReadOnlyMode: false,
        showError: false,
        $init: (function (_this) {
          return function (scope) {
            if (usingFeature(FeatureFlag.UserManagementService)) {
              return $http
                .get(apiBaseUrl + "/users/active-apps")
                .then(function (res) {
                  var activeApps, hasSpringboardApp;
                  _this.ui.loaded = true;
                  activeApps = res.data;
                  hasSpringboardApp = _.find(activeApps, function (app) {
                    return app.name === "Springboard";
                  });
                  if (hasSpringboardApp) {
                    _this.ui.isEmailInReadOnlyMode = true;
                    return (_this.ui.changeEmail.readOnly = true);
                  }
                })
                ["catch"](function (err) {
                  _this.ui.loaded = true;
                  _this.ui.showError = true;
                  return (_this.ui.changeEmail.readOnly = true);
                });
            } else {
              return (_this.ui.loaded = true);
            }
          };
        })(this),
      });
      if (rolesService.isAdmin(user)) {
        changePhoneNumber = make(require("./ChangePhoneNumber/ChangePhoneNumber"));
        this.ui.changePhoneNumber = changePhoneNumber.ui;
      }
    }

    return AccountController;
  })();
}.call(this));
