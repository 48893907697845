import "Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/DragPreviewImageHost.css.ts.vanilla.css\",\"source\":\"Ll8xcnVhYmNyMCB7CiAgcGFkZGluZzogMCAhaW1wb3J0YW50OwogIG1hcmdpbjogMCAhaW1wb3J0YW50OwogIGJveC1zaGFkb3c6IG5vbmUgIWltcG9ydGFudDsKfQouXzFydWFiY3IyIHsKICBwb3NpdGlvbjogZml4ZWQ7CiAgd2lkdGg6IHZhcigtLV8xcnVhYmNyMSk7CiAgdG9wOiAwOwogIG91dGxpbmU6IDFweCBzb2xpZCB0cmFuc3BhcmVudDsKICBsZWZ0OiAyMDB2dzsKfQpfOjotd2Via2l0LWZ1bGwtcGFnZS1tZWRpYSwgXzpmdXR1cmUsIDpyb290IC5fMXJ1YWJjcjI6OmJlZm9yZSB7CiAgY29udGVudDogIiI7CiAgcG9zaXRpb246IGFic29sdXRlOwogIHdpZHRoOiAxcHg7CiAgaGVpZ2h0OiAxcHg7CiAgbGVmdDogY2FsYygtMTAwdncgLSAxcHgpOwogIHRvcDogMDsKICBiYWNrZ3JvdW5kLWNvbG9yOiB0cmFuc3BhcmVudDsKfQouXzFydWFiY3IyID4gKjpmaXJzdC1jaGlsZCB7CiAgcGFkZGluZy10b3A6IDAgIWltcG9ydGFudDsKICBtYXJnaW4tdG9wOiAwICFpbXBvcnRhbnQ7Cn0KLl8xcnVhYmNyMiAuaW1hZ2Utd2lkZ2V0X19naG9zdCB7CiAgZGlzcGxheTogbm9uZTsKfQ==\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/SelectionRing.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVXW/bIBR9z6+4ilQpXkNlO3HqUvVxf2Bv01RV2CYOLQEPcJt26n+fjHFicDqtm/xiLod7Lud+cPWQCPZWvFUJ/JoBNFIzw6TAoCgnhj3T2xnAjrJ6ZzAkcXxxO3ufXQ2HUowLupWK2sOlFIYKg2E+v/V8kUJL3hrri9OtwRA3B89x5gwvrDK7gQjAKCL0Vqo97n85MfT7All45EWywphsDVV/H8iRO3fcXmReICOetSX4aDcb726ZQS6SDvSjIoYgTTktu2CQYqK+78NtlZYKQyOZMFSdwHZJyi4N6E0Ker90TjodkJAVvZtTTvdUmLnvqqJb0vIRr4Mh89rQu7mhB/P17MFux7tSYbcRGtbEpaIhyl5svLc5yneyXZ+x5Ufb+dRMBAFgQlODoSS8XCyeiVqMGCK4BN+URxF8AZT4NVJ8vloLeUB6Ryr5giG2X9oHXkreRejTxsvAQKLIeiHlU61kKyrkzgUS2qWjtwjnp2oVscWiHx6Sgl8/PiZJG7lNSjQTNdKGiIqo6oTQ0XJC+Z8ORykIle5lUhVVSJGKtTqE3ISqbKIhL6olRalSCKqtYrrh5BWDkIJ6KSyn1ajqYpGuMkjXMaTraz/l1Xl8kt1AmqwhzdY+nn6A30CSrSDNUh9eB/DNcPdekG9WDzsElOQnPeto0iP5mR5Zd7YRG3Mj2jYFos9UGD1odEwPmpzyqt6h4vNlGVZv2AKTfDxN9bKu0J4dFkwAJ8XSKpjnkOSd4KsIkvhiOe4AX1P+Ly7TP7qUwds27nAjm/Dewt5b9c+DnTjdoJ8MmX0UsHx+vFjyPhfSGLl3i/4lGh5B95yE5B+1UH9Z2ZCSmVfncOAZRdsEMosQ8HMKQEkcFJcKQHsMmwCip5AkrNF2irEN8f4b00L4Xp8IAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import "Application/Project/QwilrEditor/RegistryElement/Resizer.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/QwilrEditor/RegistryElement/Resizer.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8VWTXObMBC9+1doMpMZM7VcRILtKqfeeu6tJ4+QZKNEkRhJGMed/vcOAgLyV9qQmdyQ9oO3b98uzNeILQv1tErA7wkAhbbCCa0wIJnVsnT8YQJAJZjLMdgRM4WwC4ij2uR0gUFcP2XaOf3cHQh92hpdKoaB2WZTtAAovQNJmoCvIJ4nqQ8+QKEY32OAHvy7hXLcQL7jylkMlFa8SWwYN9AQJkrboWguf/o7aEuzIZSv1yiTy8dHhHKfXheECvfSQnKGqK641tLmYqUh9T2UfYYqao2cWKG20DqiGDGs97DRbFDmyFQPkz+T+RqZkmTUJGAedoUJW0jy0lHiXRv7vbf3hc5XgXnhzW80I42CmKWPkXzjPHEDy8pbjNjmJ6ZvYdAFL3Ls5Xuy0eYZN4+SOH7HpqHQUDQD8QzEIczsA3Mxn+sfBGL7npX/KRBItdRmZMJTVV8dWLQq9vUx5003uvPRRKE4vq2vaWmsNrgbxGsz+Tq5SUgkzvhGG+75pFo5rhwGNzeXkfr9cdfAaprZHroSkqMSkqslHPPdaqEghit3acjYG0O2CYfs7LISSjhBZBiHh3ScQqty4QYbDgNU7IHVUnTbxPvBmkej5XdKuXI/9I6bXhKLqInfQ5sTpqtuPTYnyCXfeaGhPoSF2t++s7jt2OLWSIlDdmDx7HK1Peo0Gl1p7pH2u4BiQImk02WxB1+OQC2jMFacxsJlrcSBz+NgI/nER7uHRievWUURgLXi+29p2gj53Db7NYVpfBsNR5VX0HArDqFgn4aL+nOhyGa11uneBWQEnR0wVVl+DtrzSGhj+H3Fxu1Z2lQzVe0v1acxdwGdHo/uQ8gLGvsXauXdisoKAAA=\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var snapAreaWidth = 'var(--_1d7pnk80)';
export var snapAreaXOffset = 'var(--_1d7pnk81)';
export var resizeableSnapArea = _7a468({defaultClassName:'_1d7pnk82',variantClassNames:{visible:{false:'_1d7pnk83',true:'_1d7pnk84'},highlighted:{false:'_1d7pnk85',true:'_1d7pnk86'}},defaultVariants:{},compoundVariants:[]});
export var resizeableSnapAreaContentStart = '_1d7pnk87';
export var resizeableSnapAreaContentEnd = '_1d7pnk88';
export var resizableSnapAreaContent = '_1d7pnk89';
export var snapAreaWide = '_1d7pnk8a';
export var snapAreaFull = '_1d7pnk8b';
export var resizeHandle = _7a468({defaultClassName:'_1d7pnk8d',variantClassNames:{state:{idle:'_1d7pnk8e',hover:'_1d7pnk8f',active:'_1d7pnk8g'},inset:{true:'_1d7pnk8h',false:'_1d7pnk8i'},position:{left:'_1d7pnk8j',right:'_1d7pnk8k',topLeft:'_1d7pnk8l',topRight:'_1d7pnk8m',bottomLeft:'_1d7pnk8n',bottomRight:'_1d7pnk8o'}},defaultVariants:{},compoundVariants:[]});