// Generated by CoffeeScript 1.12.7
(function () {
  var TrialMessage;

  TrialMessage = require("Common/TrialAngular/TrialMessage");

  module.exports = function (make, trial, projectLimit, projectLiveLimit) {
    return function () {
      var projectLimited, projectLiveLimited, timeLimited;
      projectLimited = {
        showMessage: projectLimit.hasProjectLimit,
        amountRemaining: projectLimit.getRemainingProjects,
        upgradeButtonOrigin: "project limit header",
        type: "project",
      };
      projectLiveLimited = {
        showMessage: projectLiveLimit.hasProjectLimit,
        amountRemaining: projectLiveLimit.getRemainingProjects,
        upgradeButtonOrigin: "project limit header",
        type: "live project",
      };
      timeLimited = {
        showMessage: trial.hasTimeLimit,
        amountRemaining: trial.daysRemaining,
        upgradeButtonOrigin: "time limit header",
        type: "day",
      };
      if (trial.hasTimeLimit()) {
        return make(TrialMessage, timeLimited);
      } else if (projectLimit.hasProjectLimit()) {
        return make(TrialMessage, projectLimited);
      } else if (projectLiveLimit.hasProjectLimit()) {
        return make(TrialMessage, projectLiveLimited);
      }
    };
  };
}.call(this));
