// Generated by CoffeeScript 1.12.7

/*
  The UI for displaying quote totals in the quote edit mode.
 */

(function () {
  module.exports = function (createUi, quoteService, block, currency, taxes, currencySelector, imageLocation) {
    var ref;
    if (block.taxes == null) {
      block.taxes = [];
    }
    return (this.ui = createUi(require("Project/Modules/Quote/Total/Total.html"), {
      currency: currency,
      flagImage: function () {
        return imageLocation.local("flags/" + currency().flagName + ".svg");
      },
      block: block,
      customLabels: (ref = block.settings) != null ? ref.customLabels : void 0,
      showDiscounts: (function (_this) {
        return function () {
          var hasItemDiscounts, hasQuoteDiscount, ref1;
          hasItemDiscounts = block.$totalDiscounts != null && block.$totalDiscounts !== 0;
          hasQuoteDiscount = ((ref1 = block.discount) != null ? ref1.amount : void 0) > 0;
          return hasQuoteDiscount || hasItemDiscounts;
        };
      })(this),
      openCurrencySelector: currencySelector.show,
      withTaxTotal: function () {
        return quoteService.getTotal();
      },
    }));
  };
}.call(this));
