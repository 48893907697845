"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dropContentLoopVariable = exports.removeContentLoopVariable = exports.setContentLoopVariable = exports.deleteAtContentLoopEnd = exports.backspaceAtContentLoopStart = void 0;
var slate_1 = require("slate");
var Utils_1 = require("Project/QwilrEditor/Utils");
var slate_2 = require("slate");
var setContentLoopVariable = function (editor, path, variable) {
    var nodePropsToUpdate = {
        data: { variable: variable },
    };
    slate_1.Transforms.setNodes(editor, nodePropsToUpdate, { at: path });
};
exports.setContentLoopVariable = setContentLoopVariable;
var removeContentLoopVariable = function (editor, path) {
    var nodePropsToUpdate = {
        data: { variable: undefined },
    };
    slate_1.Transforms.setNodes(editor, nodePropsToUpdate, { at: path });
};
exports.removeContentLoopVariable = removeContentLoopVariable;
var dropContentLoopVariable = function (editor, path, variable) {
    var nodePropsToUpdate = {
        data: { variable: variable },
    };
    slate_1.Transforms.setNodes(editor, nodePropsToUpdate, { at: path });
};
exports.dropContentLoopVariable = dropContentLoopVariable;
var backspaceAtContentLoopStart = function (editor) {
    if (!slate_1.Range.isRange(editor.selection)) {
        return false;
    }
    var contentLoopBody = (0, Utils_1.checkAncestors)(editor, editor.selection.anchor.path, function (curr) { return slate_2.Element.isElement(curr) && curr.type === "contentLoopBody"; });
    if (!contentLoopBody) {
        throw new Error("Not in content loop body but trying to run backspaceAtContentLoopStart");
    }
    if ((0, Utils_1.isAtStartOfNode)(editor.selection.anchor, contentLoopBody)) {
        return true;
    }
};
exports.backspaceAtContentLoopStart = backspaceAtContentLoopStart;
var deleteAtContentLoopEnd = function (editor) {
    if (!slate_1.Range.isRange(editor.selection)) {
        return false;
    }
    var contentLoopBody = (0, Utils_1.checkAncestors)(editor, editor.selection.anchor.path, function (curr) { return slate_2.Element.isElement(curr) && curr.type === "contentLoopBody"; });
    if (!contentLoopBody) {
        throw new Error("Not in content loop body but trying to run backspaceAtContentLoopStart");
    }
    if ((0, Utils_1.isAtEndOfNode)(editor.selection.anchor, contentLoopBody)) {
        return true;
    }
};
exports.deleteAtContentLoopEnd = deleteAtContentLoopEnd;
