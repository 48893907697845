"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.bindCalculateAndUpdateScrollbarHeight = void 0;
function calculateAndUpdateHorizontalScrollbarHeight() {
    document.documentElement.style.setProperty("--horizontal-scrollbar-height", "".concat(window.innerHeight - document.documentElement.clientHeight, "px"));
}
function bindCalculateAndUpdateScrollbarHeight() {
    if ("ResizeObserver" in window) {
        var resizeObserver = new ResizeObserver(function (entries) {
            if (entries.length > 0) {
                calculateAndUpdateHorizontalScrollbarHeight();
            }
        });
        resizeObserver.observe(document.body);
    }
    else {
        calculateAndUpdateHorizontalScrollbarHeight();
    }
}
exports.bindCalculateAndUpdateScrollbarHeight = bindCalculateAndUpdateScrollbarHeight;
