import "Application/Project/TailoredPageTour/TailoredPageTourPrompt.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/TailoredPageTour/TailoredPageTourPrompt.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA71WwU7jMBC98xUjIUQrkdZpKC3pZfe4X4Gc2E5NHTvYTims+PeVkzRx0qa7sBInyMw8z5s343F/7Ogb0zinBjLFXordC/y+AmBa5dU/AKrAKbdvMYSb6tukWNAYkPv6uAKwahiIeoGLOvDjalYnQFV4oQy3XMkYGD9Q4mK4NNQ26PeAS0IPMYRoGW06cDgGHgAArMbyGNUwgz3WkyAgpcbOHuRPT2EiVs/PYbifNk6KDZdZYCyWBGvSRZjpXX0mUzr/xEmJKmVKO7+deuUsqnL6wrVJ4lrBCZqtfUzUx4RnMaGPuP9ClmWFSXC6y7QqJQlSJZSOm9qqj8CUmmGvtmy66WN4jjMaQ6nF5Hb+0xhqzfyXs5m5xlwm6jVIspnZZ7dDpOHvNIZU7al2ngITwmV2TG9cKTILRCernHpxgVXFMPZw8KKLOrodI00FtnxPnfWVE7uNIVqj4uAJ8lgJ0jgrCbeUZ9vjwHZn4cQoUdrqLI9HfcpDlVhQZvv21XRsZpH5z7H1asB1U5UmVAenJNZTMEpwUvMosKbSbjqArsv9BOKk/Db+mjGGWehxS76ZW6KsVfkoPcwitvbopT698bq+kOs85IIC14wywh49cuSbyV1S20kXspXHjp6sn9bFTnZZ68oGm/7kWq3d9QRouKx7l3VbP2LCPQgIEODSKhfMlLTNbrnvI7iH8Gk8V/Yzy6/QPMf6rb1pyL9ouwpFuCkEfouBS8FlxXxPteUpFgEWPJMxWHqwTQc6fWMIi0Mtpy86Jrw07oFDN14qMbKoB51rN2jUKzv/hzV/rtIRVu2CbJpzXJF9reV419VgVmYLz1kMRqLb2m2I9rd0iFCfRmu4MFYpFulkiW4ggCUqDt6+Puc5feycIFgEmftLpZ2kXKeCQiqUocYNH6GALaRUWqrvQGfJJAwjCNEKFvfhFNDNGWMUnVphDmi2vOx7eBjxLf7mdP281GcseY5rCY+/GxcGmNKvWBNTa8xdiQHdU2lNDFK5K/DxB25orKhxCgAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var backdrop = 'gofqpk0';
export var overlay = _7a468({defaultClassName:'gofqpk1',variantClassNames:{status:{entering:'gofqpk2',entered:'gofqpk3',exiting:'gofqpk4'}},defaultVariants:{},compoundVariants:[]});
export var card = 'gofqpk5';
export var caretTop = 'var(--gofqpk6)';
export var caretLeft = 'var(--gofqpk7)';
export var caretSize = 'var(--gofqpk8)';
export var caret = _7a468({defaultClassName:'gofqpk9',variantClassNames:{position:{top:'gofqpka',bottom:'gofqpkb',left:'gofqpkc',right:'gofqpkd'},status:{entering:'gofqpke',entered:'gofqpkf'}},defaultVariants:{},compoundVariants:[]});
export var closeButton = 'gofqpkg';
export var emoji = 'gofqpkh';
export var content = 'gofqpki';
export var text = 'gofqpkj';
export var addIcon = 'gofqpkk';
export var control = 'gofqpkl';
export var dot = _7a468({defaultClassName:'gofqpkm',variantClassNames:{active:{true:'gofqpkn',false:'gofqpko'}},defaultVariants:{},compoundVariants:[]});
export var buttonWrapper = 'gofqpkp';
export var ripple = 'gofqpkr';