"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createConflictResolver = void 0;
var createConflictResolver = function (updateBlock) {
    var resolver = function (errorResponse) {
        var currentState = errorResponse.additionalInfo.current;
        var version = errorResponse.additionalInfo.version;
        updateBlock(version, currentState);
        return {
            newVersion: version,
            conflictResolution: [],
        };
    };
    return resolver;
};
exports.createConflictResolver = createConflictResolver;
