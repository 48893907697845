// Generated by CoffeeScript 1.12.7
(function () {
  var GlobalDisplays, ToggleWithLabel;

  ToggleWithLabel = require("../../SettingsMenus/ToggleWithLabel");

  module.exports = GlobalDisplays = (function () {
    function GlobalDisplays(createUi, make, quoteAdapter) {
      var quoteTotalToggle, sectionIdToggle;
      quoteTotalToggle = make(ToggleWithLabel, {
        description: "Quote total",
        getter: quoteAdapter.showQuoteTotals,
        setter: quoteAdapter.setShowQuoteTotals,
      });
      sectionIdToggle = make(ToggleWithLabel, {
        description: "Section IDs",
        getter: quoteAdapter.showSectionIds,
        setter: quoteAdapter.setShowSectionIds,
      });
      this.ui = createUi(require("./GlobalDisplays.html"), {
        quoteTotalToggle: quoteTotalToggle.ui,
        sectionIdToggle: sectionIdToggle.ui,
      });
    }

    return GlobalDisplays;
  })();
}.call(this));
