/*
  Provides UI for the Block Browser.
 */

(function () {
  var BLOCK_BROWSER_TAB_COOKIE, BlockBrowser, NEW_BLOCKS, SAVED_BLOCK_LIBRARY, SavedBlockOrigin;

  SavedBlockOrigin = require("../../../../CommonFrontend/Telemetry/TelemetryEnums.ts").SavedBlockOrigin;

  NEW_BLOCKS = "NEW_BLOCKS";

  SAVED_BLOCK_LIBRARY = "SAVED_BLOCK_LIBRARY";

  BLOCK_BROWSER_TAB_COOKIE = "block-browser-tab";

  module.exports = BlockBrowser = (function () {
    function BlockBrowser(
      createUi,
      di,
      block,
      blockList,
      replaceBlock,
      telemetry,
      hasBlockType,
      blockDisplaySettings,
      invoke,
      getBlockTypeIcon,
      resetBrowserStateOnPrecedingBlock,
      $cookies,
      projectStore,
    ) {
      var activeBlockTypes,
        allBlockTypes,
        order,
        showDisabledBlockTooltip,
        showNewBlocks,
        showSavedBlocks,
        startingViewTab,
        template,
        viewTab;
      viewTab = NEW_BLOCKS;
      showNewBlocks = (function (_this) {
        return function () {
          if (viewTab !== NEW_BLOCKS) {
            viewTab = NEW_BLOCKS;
            return $cookies.put(BLOCK_BROWSER_TAB_COOKIE, NEW_BLOCKS);
          }
        };
      })(this);
      showSavedBlocks = (function (_this) {
        return function () {
          var savedBlockNavigator;
          if (viewTab !== SAVED_BLOCK_LIBRARY) {
            if (!_this.ui.savedBlockNavigator) {
              savedBlockNavigator = di.make(require("./SavedBlockNavigator/SavedBlockNavigator"));
              _this.ui.savedBlockNavigator = savedBlockNavigator.ui;
            }
            viewTab = SAVED_BLOCK_LIBRARY;
            $cookies.put(BLOCK_BROWSER_TAB_COOKIE, SAVED_BLOCK_LIBRARY);
            return telemetry.trackOnly("Saved Block Library Viewed", {
              saved_block_origin: SavedBlockOrigin.Editor,
            });
          }
        };
      })(this);
      allBlockTypes = blockDisplaySettings.getAllSettings();
      showDisabledBlockTooltip = invoke(require("./DisabledBlockTooltip/ShowDisabledBlockTooltip"));
      if (!hasBlockType("Quote")) {
        order = ["text", "splash", "video", "source", "quotev2", "accept"];
      } else {
        order = ["text", "quote", "video", "splash", "source"];
      }
      activeBlockTypes = {};
      order.forEach(function (item) {
        var blockType;
        blockType = allBlockTypes[item];
        activeBlockTypes[item] = blockType;
        if (!blockType.enabled) {
          return showDisabledBlockTooltip(blockType, block);
        }
      });
      startingViewTab = $cookies.get(BLOCK_BROWSER_TAB_COOKIE);
      template = require("./Browser.html");
      this.ui = createUi(template, {
        hasEditAccess: projectStore.isEditable,
        blockTypes: activeBlockTypes,
        savedBlockNavigator: null,
        getIcon: getBlockTypeIcon,
        showNewBlockTab: function () {
          return viewTab === NEW_BLOCKS;
        },
        showSavedBlocksTab: function () {
          return viewTab === SAVED_BLOCK_LIBRARY;
        },
        insertBlock: function (blockType) {
          if (blockType.enabled) {
            replaceBlock(blockType.blockModel);
            return resetBrowserStateOnPrecedingBlock(blockList.getIndex(block));
          }
        },
        showCloseButton: (function (_this) {
          return function () {
            return blockList.getLength() > 1;
          };
        })(this),
        removeBrowser: (function (_this) {
          return function () {
            var index;
            index = blockList.getIndex(block);
            blockList.removeBlock(block);
            return resetBrowserStateOnPrecedingBlock(index);
          };
        })(this),
        showNewBlocks: showNewBlocks,
        showSavedBlocks: showSavedBlocks,
      });
      if (!startingViewTab || startingViewTab === NEW_BLOCKS) {
        showNewBlocks();
      } else {
        showSavedBlocks();
      }
    }

    return BlockBrowser;
  })();
}.call(this));
