// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = {
    Enter: {
      key: "enter",
      tests: [
        {
          name: "should insert a new line item at end of line",
          startContent: "<ul><li>abcd|</li></ul>",
          domResult: "<ul><li>abcd</li><li><br></li></ul>",
          selectionResult: "<ul><li>abcd</li><li>|<br></li></ul>",
        },
        {
          name: "should move line item below a new line item if at start of line",
          startContent: "<ul><li>|abcd</li></ul>",
          domResult: "<ul><li><br></li><li>abcd</li></ul>",
          selectionResult: "<ul><li><br></li><li>|abcd</li></ul>",
        },
        {
          name: "should split line item into two line items if in middle of line",
          startContent: "<ul><li>ab|cd</li></ul>",
          domResult: "<ul><li>ab</li><li>cd</li></ul>",
          selectionResult: "<ul><li>ab</li><li>|cd</li></ul>",
        },
        {
          name: "should switch to paragraph if line item is empty",
          startContent: "<ul><li>|<br></li></ul>",
          domResult: "<p><br></p>",
          selectionResult: "<p>|<br></p>",
        },
        {
          name: "should switch to paragraph in middle of line items if empty",
          startContent: "<ul><li>foo</li><li>|<br></li><li>bar</li></ul>",
          domResult: "<ul><li>foo</li></ul><p><br></p><ul><li>bar</li></ul>",
          selectionResult: "<ul><li>foo</li></ul><p>|<br></p><ul><li>bar</li></ul>",
        },
      ],
    },
    Backspace: {
      key: "backspace",
      tests: [
        {
          name: "should convert to paragraph if at start of first line item in list",
          startContent: "<ul><li>|abcd</li></ul>",
          domResult: "<p>abcd</p>",
          selectionResult: "<p>|abcd</p>",
        },
        {
          name: "should convert to paragraph if at start of line item in middle of list",
          startContent: "<ul><li>foo</li><li>|abcd</li></ul>",
          domResult: "<ul><li>foo</li></ul><p>abcd</p>",
          selectionResult: "<ul><li>foo</li></ul><p>|abcd</p>",
        },
        {
          name: "should convert to paragraph if at start of first line item in list next to another list",
          startContent: "<ul><li>abcd</li></ul><ul><li>|efgh</li></ul>",
          domResult: "<ul><li>abcd</li></ul><p>efgh</p>",
          selectionResult: "<ul><li>abcd</li></ul><p>|efgh</p>",
        },
        {
          name: "should not handle event if content before",
          startContent: "<ul><li>abcd</li><li>ef|gh</li></ul>",
          domResult: "<ul><li>abcd</li><li>efgh</li></ul>",
          selectionResult: "<ul><li>abcd</li><li>ef|gh</li></ul>",
        },
        {
          name: "should not handle event if visible whitespace before",
          startContent: "<ul><li>abcd</li><li>&nbsp;|gh</li></ul>",
          domResult: "<ul><li>abcd</li><li>&nbsp;gh</li></ul>",
          selectionResult: "<ul><li>abcd</li><li>&nbsp;|gh</li></ul>",
        },
        {
          name: "should convert to paragraph when two lists are next to each other",
          startContent: "<ul><li>ab</li></ul><ul><li>|cd</li></ul>",
          domResult: "<ul><li>ab</li></ul><p>cd</p>",
          selectionResult: "<ul><li>ab</li></ul><p>|cd</p>",
        },
        {
          name: "should convert to paragraph when there is whitespace between block level elements",
          startContent: '<h2 placeholder="true"><br></h2>\n<ol><li><br>|</li></ol>',
          domResult: '<h2 placeholder="true"><br></h2>\n<p><br></p>',
          selectionResult: '<h2 placeholder="true"><br></h2>\n<p>|<br></p>',
        },
      ],
    },
    Delete: {
      key: "delete",
      tests: [
        {
          name: "should join next line item if at end",
          startContent: "<ul><li>abcd|</li><li>efgh</li></ul>",
          domResult: "<ul><li>abcdefgh</li></ul>",
          selectionResult: "<ul><li>abcd|efgh</li></ul>",
        },
        {
          name: "should join next element if at end of last line item",
          startContent: "<ul><li>abcd|</li></ul><p>efgh</p>",
          domResult: "<ul><li>abcdefgh</li></ul>",
          selectionResult: "<ul><li>abcd|efgh</li></ul>",
        },
        {
          name: "should join next item if at end of line item content but in style element",
          startContent: "<ul><li>ab<b>cd|</b></li><li>efgh</li></ul>",
          domResult: "<ul><li>ab<b>cd</b>efgh</li></ul>",
          selectionResult: "<ul><li>ab<b>cd|</b>efgh</li></ul>",
        },
        {
          name: "should do nothing if no items after line item",
          startContent: "<ul><li>abcd|</li></ul>",
          domResult: "<ul><li>abcd</li></ul>",
          selectionResult: "<ul><li>abcd|</li></ul>",
        },
        {
          name: "should not handle event if content after",
          startContent: "<ul><li>ab|cd</li><li>cd</li></ul>",
          domResult: "<ul><li>abcd</li><li>cd</li></ul>",
          selectionResult: "<ul><li>ab|cd</li><li>cd</li></ul>",
        },
        {
          name: "should not handle event if visible whitespace after",
          startContent: "<ul><li>ab|&nbsp;</li><li>cd</li></ul>",
          domResult: "<ul><li>ab&nbsp;</li><li>cd</li></ul>",
          selectionResult: "<ul><li>ab|&nbsp;</li><li>cd</li></ul>",
        },
        {
          name: "should merge list elements when two lists are next to each other",
          startContent: "<ul><li>ab|</li></ul><ul><li>cd</li></ul>",
          domResult: "<ul><li>abcd</li></ul>",
          selectionResult: "<ul><li>ab|cd</li></ul>",
        },
        {
          name: "should merge lists when deleting an element causes two lists to be next to each other",
          startContent: "<ul><li>ab|</li></ul><p>ef</p><ul><li>cd</li></ul>",
          domResult: "<ul><li>abef</li><li>cd</li></ul>",
          selectionResult: "<ul><li>ab|ef</li><li>cd</li></ul>",
        },
        {
          name:
            "should not merge lists of different types when deleting an element causes two lists to be next to each other",
          startContent: "<ul><li>ab|</li></ul><p>ef</p><ol><li>cd</li></ol>",
          domResult: "<ul><li>abef</li></ul><ol><li>cd</li></ol>",
          selectionResult: "<ul><li>ab|ef</li></ul><ol><li>cd</li></ol>",
        },
      ],
    },
  };
}.call(this));
