// Generated by CoffeeScript 1.12.7

/*
  @TODO As good will for our users we ought add messaging when changing
  from an existing subdomain that the links from the previous subdomain
  will no longer work.
 */

/*
  A widget which allows a user set the subdomain for their account.
  Dynamically checks for validity of the subdomain, and whether the subdomain is taken or blacklisted.

  @param options [Object] includes:
  - `onSaved` [Function] invoked upon successful update of the subdomain
  - `includeIcon` [Boolean] whether to include an SVG icon in the header.
  - `title` [String] the title text for the widget.
  - `description` [String] the description for the widget.
 */

(function () {
  var SubdomainSetter;

  module.exports = SubdomainSetter = (function () {
    function SubdomainSetter(
      options,
      createUi,
      accountService,
      make,
      invoke,
      ActionButton,
      overlayStack,
      noop,
      utils,
      $q,
      environment,
      primaryDomain,
      $log,
    ) {
      var domain, isValidSubdomain, saveButton, statusChecker, EventOrigin;

      EventOrigin = require("@qwilr/event-tracker-types").EventOrigin;

      if (options.includeIcon == null) {
        options.includeIcon = true;
      }
      if (options.title == null) {
        options.title = "Subdomain";
      }
      if (options.description == null) {
        options.description = "This is a subdomain URL to serve your Qwilr pages from.";
      }
      if (options.onSaved == null) {
        options.onSaved = noop;
      }
      domain = {
        name: accountService.getSubdomain() || "",
        type: "subdomain",
      };
      isValidSubdomain = (function (_this) {
        return function (subdomainName) {
          var regex;
          regex = /^[a-z0-9][a-z0-9\-]*$/;
          return regex.test(domain.name);
        };
      })(this);
      statusChecker = make(require("./../StatusChecker/StatusChecker"), {
        domain: domain,
        validityChecker: isValidSubdomain,
      });
      saveButton = make(ActionButton, {
        config: {
          standard: "Save Subdomain",
          waiting: "Saving Subdomain...",
          success: "Saved Subdomain",
          error: "Error setting subdomain",
        },
        onClick: (function (_this) {
          return function () {
            if (statusChecker.getStatus() === "checking") {
              return $q.resolve();
            } else if (domain.name !== "" && statusChecker.getStatus() !== "available") {
              _this.ui.error = "Please double check your domain and try again.";
              return $q.reject();
            } else {
              return accountService
                .setSubdomain(domain.name, EventOrigin.QwilrSubdomainSettings)
                .then(function () {
                  return options.onSaved(domain.name);
                })
                ["catch"](function (error) {
                  _this.ui.error = error;
                  return $log.error("Error updating custom domain", error);
                });
            }
          };
        })(this),
      });
      this.ui = createUi(require("./SubdomainSetter.html"), {
        domain: domain,
        checkStatus: "",
        error: "",
        saveButton: saveButton.ui,
        options: options,
        statusChecker: statusChecker.ui,
        updateStatus: statusChecker.updateStatus,
        showInvalid: (function (_this) {
          return function () {
            if (!domain.name.length) {
              return false;
            } else {
              return !_this.ui.isValidSubdomain();
            }
          };
        })(this),
        isValidSubdomain: (function (_this) {
          return function () {
            return isValidSubdomain(domain.name);
          };
        })(this),
        primaryDomain: primaryDomain,
        devFill: (function (_this) {
          return function () {
            if (environment === "production") {
              return;
            }
            _this.ui.domain.name = utils.randomString(10).toLowerCase();
            return _this.ui.updateStatus();
          };
        })(this),
      });
      this.showInOverlay = (function (_this) {
        return function () {
          return overlayStack.add(_this.ui);
        };
      })(this);
    }

    return SubdomainSetter;
  })();
}.call(this));
