// Generated by CoffeeScript 1.12.7
(function () {
  var BackgroundImageLoader;

  module.exports = BackgroundImageLoader = (function () {
    function BackgroundImageLoader(createUi, block, $timeout) {
      var loadingMsg, minimumTimeout;
      minimumTimeout = 500;
      loadingMsg = "Updating background image";
      this.ui = createUi(require("./BackgroundImageLoader.html"), {
        element: null,
        completeLoad: (function (_this) {
          return function (url, mediaCarousel) {
            if (mediaCarousel != null) {
              mediaCarousel.clearMessage("center");
            }
            _this.ui.element.removeClass("image-loader");
            return _this.ui.element.css("background-image", "url(" + url);
          };
        })(this),
        loadImage: (function (_this) {
          return function (url, mediaCarousel) {
            var start;
            _this.ui.loading = true;
            start = new Date();
            $(_this.ui.element).addClass("image-loader");
            if (mediaCarousel != null) {
              mediaCarousel.setMessage(loadingMsg, "center");
            }
            return $("<img/>")
              .attr("src", url)
              .on("load", function () {
                var end, timeTaken, timeToAdd;
                $(_this).remove();
                if (url !== block.resourceUrl) {
                  return;
                }
                end = new Date();
                timeToAdd = 0;
                timeTaken = end - start;
                if (timeTaken < minimumTimeout) {
                  timeToAdd = minimumTimeout - timeTaken;
                }
                return $timeout(function () {
                  return _this.ui.completeLoad(url, mediaCarousel);
                }, timeToAdd);
              });
          };
        })(this),
        $onElementRendered: (function (_this) {
          return function (elem) {
            return (_this.ui.element = elem);
          };
        })(this),
      });
    }

    return BackgroundImageLoader;
  })();
}.call(this));
