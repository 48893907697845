// Generated by CoffeeScript 1.12.7
(function () {
  var FeatureFlag,
    StyleTileFactory,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = StyleTileFactory = (function () {
    function StyleTileFactory(
      createUi,
      make,
      di,
      placeholderBlockFactory,
      projectEvents,
      block,
      blockMenu,
      usingFeature,
      user,
      showDeleteModal,
    ) {
      var StyleEditor, StyleTile, convertOldStyle, createPlaceholderBlock, createTile, stylesTelemetry;
      StyleEditor = require("Project/BlockNew/Menu/StyleSelector/StyleEditor/StyleEditor.js");
      convertOldStyle = require("Project/BlockNew/Menu/StyleSelector/ConvertOldStyle");
      StyleTile = require("./StyleTile.html");
      stylesTelemetry = di.invoke(require("./../StylesTelemetry"));

      /*
        Create a single style carousel tile that shows the preview of the style
       */
      createTile = (function (_this) {
        return function (styleObject) {
          var isFactoryStyle, ui;
          isFactoryStyle = styleObject.isFactoryStyle || false;
          ui = createUi(StyleTile, {
            isFactoryStyle: isFactoryStyle,
            placeholderBlock: createPlaceholderBlock(styleObject),
            styleName: styleObject.name,
            editCustomStyle: function () {
              if (usingFeature(FeatureFlag.AdvancedStyleEditor) && styleObject.version !== 1) {
                _.assign(styleObject, convertOldStyle(styleObject, user));
              }
              if (styleObject.version !== 1) {
                return blockMenu.openStyleCodeEditor(styleObject);
              } else {
                return make(StyleEditor, {
                  style: styleObject,
                  onSaveHook: function (styleObject) {
                    ui.styleName = styleObject.name;
                    ui.placeholderBlock = createPlaceholderBlock(styleObject);
                    return projectEvents.emit("stylesModified");
                  },
                });
              }
            },
            deleteCustomStyle: function ($event) {
              $event.stopPropagation();
              if (isFactoryStyle) {
                return;
              }
              return showDeleteModal({
                title: "Delete Style?",
                explainer:
                  "Any existing blocks that use this style will continue to use it, but you won't be able to see it as an option anymore if you delete it. Definitely want to delete this style?",
              }).then(function (confirmed) {
                if (!confirmed) {
                  return;
                }
                styleObject.deleted = true;
                stylesTelemetry.trackStyleDelete();
                return projectEvents.emit("stylesModified");
              });
            },
          });
          return {
            ui: ui,
            data: styleObject,
          };
        };
      })(this);

      /*
        Create all the carousel tiles for a list of style objects
       */
      this.createTiles = (function (_this) {
        return function (styleObjects) {
          return styleObjects.map(createTile);
        };
      })(this);
      createPlaceholderBlock = function (styleObject) {
        var blockType;
        if (styleObject.version === 1) {
          if (indexOf.call(styleObject.blockTypes, "Splash") >= 0) {
            blockType = "Splash";
          } else {
            blockType = "Text";
          }
        } else {
          blockType = styleObject.blockType;
        }
        return placeholderBlockFactory.create([styleObject.className, "project-block", "style-preview"], blockType);
      };
    }

    return StyleTileFactory;
  })();
}.call(this));
