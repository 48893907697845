"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sortByToTelemetryProperty = void 0;
var DashboardTypes_1 = require("../DashboardTypes");
var sortByToTelemetryProperty = function (sortBy, sortDirection) {
    var _a;
    return Object.freeze((_a = {},
        _a[DashboardTypes_1.SortBy.CreatedAt] = "date created",
        _a[DashboardTypes_1.SortBy.Name] = "name ".concat(sortDirection === DashboardTypes_1.SortDirection.Ascending ? "a to z" : "z to a"),
        _a[DashboardTypes_1.SortBy.LastEditedAt] = "last edited",
        _a[DashboardTypes_1.SortBy.Views] = "views",
        _a[DashboardTypes_1.SortBy.Relevance] = "relevance",
        _a))[sortBy];
};
exports.sortByToTelemetryProperty = sortByToTelemetryProperty;
