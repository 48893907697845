// Generated by CoffeeScript 1.12.7

/*
  This module creates UI for editing project security settings.
 */

(function () {
  var FeatureFlag, transformLink;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  transformLink = require("./LinkTransformer").transformLink;

  module.exports = function (
    make,
    $q,
    createUi,
    project,
    link,
    persist,
    usingFeature,
    apiBaseUrl,
    upseller,
    upsellerService,
    initSubscription,
  ) {
    var feature, fn, i, items, len, securityFeatures;
    securityFeatures = [
      {
        ctrl: require("./Password/Password"),
      },
      {
        ctrl: require("./TimeLimit/TimeLimit"),
        featureName: "advanced security",
      },
      {
        ctrl: require("./ViewsLimit/ViewsLimit"),
        featureName: "advanced security",
      },
    ];
    if (usingFeature(FeatureFlag.IdentityMapping)) {
      securityFeatures.push({
        ctrl: require("./Identity/Identity"),
        featureName: "identityMapping",
      });
    }
    if (usingFeature(FeatureFlag.IpFiltering)) {
      securityFeatures.push({
        ctrl: require("./IpFilter/IpFilter"),
        featureName: "ipFilter",
      });
    }
    if (usingFeature(FeatureFlag.PublicClonableProjects)) {
      securityFeatures.push({
        ctrl: require("./PublicCloneable/PublicCloneable"),
        featureName: "publicClonableProjects",
      });
    }
    items = [];
    fn = function (feature) {
      var featureControl;
      featureControl = make(feature.ctrl, {
        getDisabledMessage: function () {
          if (feature.featureName != null && !usingFeature(feature.featureName) && !featureControl.isEnabled()) {
            return upsellerService.getPlanNameForFeature(feature.featureName).then(function (planName) {
              return (
                "This setting is only available on the " +
                planName +
                " Plan. Please upgrade your plan in order to use this feature."
              );
            });
          } else {
            return $q.resolve();
          }
        },
      });
      return items.push(featureControl);
    };
    for (i = 0, len = securityFeatures.length; i < len; i++) {
      feature = securityFeatures[i];
      fn(feature);
    }
    this.hasSecurityEnabled = function () {
      var j, len1;
      for (j = 0, len1 = items.length; j < len1; j++) {
        feature = items[j];
        if (feature.isEnabled != null && feature.isEnabled()) {
          return true;
        }
      }
      return false;
    };
    return (this.ui = createUi(require("./Security.html"), {
      projectName: project.name,
      securitySettings: items,
      currentItem: items[0],
      items: items,
      link: link,
      isActive: (function (_this) {
        return function (item) {
          if (_this.ui.currentItem == null) {
            return;
          }
          return item.name === _this.ui.currentItem.name;
        };
      })(this),
      select: (function (_this) {
        return function (item) {
          if (item.upsell()) {
            return upseller.upsell({
              feature: "advanced security",
              featureLabel: "Advanced Security",
            });
          } else {
            return (_this.ui.currentItem = item);
          }
        };
      })(this),
      $init: function (scope) {
        scope.link = link;
        return make(persist, {
          options: {
            watchObject: "link",
            scope: scope,
            apiRoute: apiBaseUrl + "/projects/" + project._id + "/links/" + link.secret,
            dataTransformer: transformLink,
          },
        });
      },
    }));
  };
}.call(this));
