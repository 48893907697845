import "Application/Common/MultiSelectCombobox/MultiSelectCombobox.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Common/MultiSelectCombobox/MultiSelectCombobox.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7WUz3LbIBDG736KPdYHMpbjJjGZ6aV9gxx6zCBYyTgINID+OJ2+e0fEYlCoE19ysQf8ffutf9rVzfFhP2h+C39WAEK6VrEThUrh+LiC8E0Gy1oK0+d0VU+HntlvhLiWcalrMo7u+bko1f3xWBTH9aRqmK2lJt7k4kT7sn5c/V3dvLWwCy18YMxTuFHGzrpwIB5H/9NKLzlTUb5Pc76HHELeTpul3XW2YhyfkBstmD3FEjIEzq4iD43K3UK5XSolNzoq26DMoDMla02kx8ZR4Kg92kvg37EEKBl/qa3ptCALOOc/GzQtE0Lq+gq+ZyVRWPkrso0VaIllQnaOwq4dE+p3V1DPnlvzGfT/PukP4WeOPp2N+6+ZjV/SsVKhiA7xWZuZY0jbfAhtTvNCYQMbYJ032T6ca6e+ffCZHm2lzHBebKZPwwEtXihQpAVYKHAhJi5vabw3DQWyXYwAgx/g+jqUGKTwBwrbzUJRht8mYsRbpl1lbENBGx2aq4z2xMlXnKOniyf5ioFxOpDbfh0NA8r64FPL73BDGhSya6Lntggehd6jnaecQqcd+qRFnhO4sNnRItIX28zm/e6l6yTXi8CMWnEXqP0Dm9gGCbsFAAA=\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var selectedValues = 'j89wnc3';
export var errorText = 'j89wnc4';
export var selectionCard = _7a468({defaultClassName:'j89wnc5',variantClassNames:{error:{true:'j89wnc6'},disabled:{true:'j89wnc7'}},defaultVariants:{},compoundVariants:[]});
export var selectionCardIcon = 'j89wnc8';
export var selectionCardLabelText = 'j89wnc9';
export var selectionCardLabelIcon = 'j89wnca';
export var selectionCardTooltip = 'j89wncb';
export var comboboxEntryIcon = 'j89wncc';
export var comboboxEntryLabel = 'j89wncd';