"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.reloadOnWake = void 0;
var AccountTelemetryHelper_1 = require("@CommonFrontendBackend/Telemetry/AccountTelemetryHelper");
function reloadOnWake(telemetry, account, user) {
    var lastTime = Date.now();
    var intervalId = setInterval(function () {
        var _a;
        var currentTime = Date.now();
        var timeElapsed = currentTime - lastTime;
        if (timeElapsed > INTERVAL_PERIOD + RELOAD_THRESHOLD) {
            clearInterval(intervalId);
            telemetry.trackOnly("App Session Reloaded", { sleep_duration: timeElapsed }, { blockIntercom: true });
            telemetry.update(__assign(__assign({}, (0, AccountTelemetryHelper_1.buildAccountEventProperties)(account, account.plan)), { user_role: ((_a = user.roles[0]) === null || _a === void 0 ? void 0 : _a.name) == "admin" ? "admin" : "creator" }));
            setTimeout(function () {
                window.location.reload();
            }, 100);
        }
        lastTime = currentTime;
    }, INTERVAL_PERIOD);
}
exports.reloadOnWake = reloadOnWake;
var INTERVAL_PERIOD = 1000;
var RELOAD_THRESHOLD = 1200000;
