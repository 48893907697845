// Generated by CoffeeScript 1.12.7

/*
  Simple utility function to scroll to an element with animation.
 */

(function () {
  module.exports = function ($timeout, navigationHeaderHeight, overlayStack) {
    var overlayStackElem;
    overlayStackElem = overlayStack.getOverlayStackElem();

    /*
      @param elem [Object] A DOM reference to the object to scroll to.
      @param options [Object] A map of options, which can include:
        - `time` [Integer] The time in MS for the scroll animation to run - i.e. 1000 (for 1 second)
        - `offset` [Integer] A VERTICAL offset from the element to scroll to - i.e. 100 (for 100px up from the element)
        - `scrollingElement` [String] Which element to scroll - i.e. the scrolling container.
     */
    return function (elem, options) {
      var offset, scrollTime, scrollingElement, scrollingElementOffsetTop;
      if (options == null) {
        options = {};
      }
      if (elem == null) {
        throw new Error("Trying to scroll to undefined element.");
      }
      scrollTime = options.time || 450;
      offset = options.offset || 0;
      scrollingElement = options.scrollingElement || "html, body";
      scrollingElementOffsetTop = $(scrollingElement).scrollTop();

      /*
        Since the Qwilr app has a fixed navigation bar header,
        we need to account for this in our offset value. However, since in overlays
        we don't have the nav bar showing, so we need to check whether the element
        we're scrolling to is inside an overlay.
       */
      if ($(elem).parents(overlayStackElem).length > 0) {
        offset += navigationHeaderHeight;
      }

      /*
        We reduce the scroll offset of the scrolling element from the final offset, 
        as the offset function always computes the offset from the top of document
       */
      offset -= scrollingElementOffsetTop;
      return $timeout(function () {
        return $(scrollingElement).animate(
          {
            scrollTop: $(elem).offset().top - offset,
          },
          scrollTime,
        );
      });
    };
  };
}.call(this));
