// Generated by CoffeeScript 1.12.7

/*
  This module loads fonts.
  It leverages the Typekit webfont loader.
  Currently it supports GoogleFonts and hosted webfonts.

  @param fontConfig [Object] optionally we can provide a custom font configuration object.
  This means we can load other fonts than those stored in our account model.
 */

(function () {
  var WebFont, getCssForFonts;

  WebFont = require("webfontloader");

  getCssForFonts = require("./FontCssGenerator");

  module.exports = function (account, userFilesUrl, styleWorker, loadFontsIntoBrowser) {
    return function (fontConfig) {
      var css, fonts;
      fonts = fontConfig || account.settings.brand.fonts;
      loadFontsIntoBrowser(fonts);
      css = getCssForFonts(fonts);
      return styleWorker.addToHead(css, "qwilr-fonts");
    };
  };
}.call(this));
