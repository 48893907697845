"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (createUi, account, projectStore, overlayStack, projectNavigation, accountService, primaryDomain) {
    var ui = createUi(require("./Configurator.html"), {
        colors: account.settings.brand.colors,
        settings: projectStore.settings.navigation,
        projectName: projectStore.name,
        isNavEnabled: function () {
            return projectNavigation.isNavEnabled();
        },
        enableNavigation: function () {
            projectNavigation.enable();
        },
        disableNavigation: function () {
            projectNavigation.disable();
        },
        isSelected: function (type) {
            return projectNavigation.isWidgetEnabled(type);
        },
        updateShowContact: function (showContact) {
            return projectNavigation.updateShowContact(showContact);
        },
        selectType: projectNavigation.enableWidget,
        getDemoUrl: function () {
            if (accountService.hasCustomDomain()) {
                return accountService.getCustomDomain();
            }
            else if (accountService.hasSubdomain()) {
                return "".concat(accountService.getSubdomain(), ".").concat(primaryDomain);
            }
            else {
                return "example.".concat(primaryDomain);
            }
        },
    });
    overlayStack.add({
        contentUi: ui,
        options: {
            className: "mini-close-button",
        },
    });
});
