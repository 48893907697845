"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var EmbedPresetsWidgetContext_1 = require("./EmbedPresetsWidgetContext");
var EmbedPresetOptions_1 = require("./EmbedPresetOptions");
var lodash_1 = require("lodash");
var styles = __importStar(require("./ChooseSource.css"));
var react_resize_detector_1 = require("react-resize-detector");
var PresetCard = function (_a) {
    var preset = _a.preset, onClick = _a.onClick;
    return (react_1.default.createElement(kaleidoscope_1.ButtonCard, { onClick: onClick, className: styles.presetCard },
        react_1.default.createElement(kaleidoscope_1.SVG, { src: preset.iconPath }),
        react_1.default.createElement(kaleidoscope_1.Text, { strong: true, align: "center", lineHeight: "compact" }, preset.name)));
};
var ChooseSource = function (_a) {
    var closeButton = _a.closeButton;
    var _b = (0, EmbedPresetsWidgetContext_1.useEmbedPresetsWidgetContext)(), setPreset = _b.setPreset, setStep = _b.setStep;
    var _c = __read((0, react_1.useState)(EmbedPresetOptions_1.PresetCategory.Forms), 2), category = _c[0], setCategory = _c[1];
    var presetsGroupedByCategory = (0, lodash_1.groupBy)(EmbedPresetOptions_1.embedPresets, "category");
    var _d = (0, react_resize_detector_1.useResizeDetector)(), width = _d.width, ref = _d.ref;
    return (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.container, ref: ref }, width < 312 ? (react_1.default.createElement(kaleidoscope_1.Stack, { align: "center", justify: "center", padding: "xxs", className: styles.resizeNotification },
        react_1.default.createElement("div", { className: styles.resizeNotificationCloseButton }, closeButton && closeButton),
        react_1.default.createElement(kaleidoscope_1.Text, { size: "s", align: "center" }, "Please resize the widget larger to change source"))) : (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { className: styles.buttonContainer }, closeButton && closeButton),
        react_1.default.createElement(kaleidoscope_1.Stack, { align: "center", direction: "vertical", gap: "l", padding: "xxl" },
            react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "xl" }, "What would you like to embed?"),
            react_1.default.createElement(kaleidoscope_1.ChoiceChips, { label: "categories", value: category, onChange: function (value) { return setCategory(value); } }, Object.keys(presetsGroupedByCategory).map(function (presetCategory) { return (react_1.default.createElement(kaleidoscope_1.ChoiceChipsOption, { value: presetCategory, key: presetCategory, label: presetCategory })); })),
            react_1.default.createElement(kaleidoscope_1.Stack, { wrap: true, direction: "horizontal", gap: "xs", justify: "center" }, presetsGroupedByCategory[category].map(function (preset) { return (react_1.default.createElement(PresetCard, { key: preset.name, preset: preset, onClick: function () {
                    setPreset(preset);
                    setStep(EmbedPresetsWidgetContext_1.EmbedStep.EnterEmbedCode);
                } })); })))))));
};
exports.default = ChooseSource;
