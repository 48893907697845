// Generated by CoffeeScript 1.12.7

/*
  Check a subdomain is usable - i.e. not taken, blacklisted or invalid.
  @param setStatus [Function] Sets status in the caller.
  @param setError [Function] Error handler in the caller.
 */

(function () {
  module.exports = function (setStatus, setError, $http, apiBaseUrl, account, $log) {
    var INTERVAL, checkDomain, debouncedCheck, debouncedCheckWrapper;
    INTERVAL = 200;
    checkDomain = (function (_this) {
      return function (domain) {
        var checkParams, obj;
        _this.lastDomainName = domain.name;
        if (domain == null || domain.name === "") {
          setStatus("");
          return;
        }
        setError("");
        checkParams = ((obj = {}), (obj["" + domain.type] = domain.name), obj);
        return $http
          .post(apiBaseUrl + "/accounts/" + account._id + "/settings/" + domain.type + "/usable", checkParams)
          .then(function (response) {
            if (_this.lastDomainName !== response.config.data[domain.type]) {
              return;
            }
            setStatus({});
            debouncedCheck.cancel();
            if (response.data.usable) {
              return setStatus("available");
            } else if (response.data.reason === "not available") {
              return setStatus("taken");
            } else if (response.data.reason === "invalid") {
              return setStatus("invalid");
            }
          })
          ["catch"](function (err) {
            setStatus("busy");
            return $log.error("Error checking whether a " + domain.type + " is usable", err);
          });
      };
    })(this);

    /*
      We debounce the requests so we don't unnecessarily smash the server.
      @param domain [Object] expects:
        - `type` [String] can be either `domain` or `subdomain`
        - `name` [String] the user supplied domain name to test
     */
    debouncedCheck = _.debounce(checkDomain, INTERVAL, {
      trailing: true,

      /*
        We create a wrapper function below to stop using the debounce
        when running unit tests.
       */
    });
    debouncedCheckWrapper = (function (_this) {
      return function (domain, skipDebounce) {
        if (skipDebounce) {
          return checkDomain(domain);
        } else {
          return debouncedCheck(domain);
        }
      };
    })(this);
    return debouncedCheckWrapper;
  };
}.call(this));
