// Generated by CoffeeScript 1.12.7
(function () {
  var AlignmentPicker;

  module.exports = AlignmentPicker = (function () {
    function AlignmentPicker(make, style, onChange, createUi, getElementDescription, elements) {
      var AlignmentSection, alignment, getMainAlignment, main, sections;
      AlignmentSection = require("./AlignmentSection/AlignmentSection");
      alignment = style.alignment || {
        h1: "left",
        h2: "left",
        p: "left",
      };
      getMainAlignment = function (alignment) {
        var allEquals, element, i, len, value;
        value = null;
        allEquals = true;
        for (i = 0, len = elements.length; i < len; i++) {
          element = elements[i];
          if (value == null) {
            value = alignment[element];
          } else {
            if (alignment[element] !== value) {
              allEquals = false;
              break;
            }
          }
        }
        if (allEquals) {
          return value;
        } else {
          return null;
        }
      };
      main = make(AlignmentSection, {
        title: "All Text",
        initialValue: getMainAlignment(alignment),
        setValue: function (value) {
          var element, i, j, len, len1, section;
          for (i = 0, len = elements.length; i < len; i++) {
            element = elements[i];
            alignment[element] = value;
          }
          for (j = 0, len1 = sections.length; j < len1; j++) {
            section = sections[j];
            section.update(value);
          }
          return onChange();
        },
      });
      sections = _.map(elements, function (element) {
        return make(AlignmentSection, {
          title: getElementDescription(element),
          initialValue: alignment[element],
          setValue: function (value) {
            alignment[element] = value;
            main.update(getMainAlignment(alignment));
            return onChange();
          },
        });
      });
      this.ui = createUi(require("./AlignmentPicker.html"), {
        main: main.ui,
        sections: sections,
      });
    }

    return AlignmentPicker;
  })();
}.call(this));
