// Generated by CoffeeScript 1.12.7
(function () {
  var copy;

  copy = require("Common/Utils/CopyToClipboard")["default"];

  module.exports = function (
    make,
    createUi,
    MenuDropdown,
    selector,
    block,
    user,
    rolesService,
    savedBlockService,
    $http,
    apiBaseUrl,
    onDropdownHide,
    deleteSavedBlock,
  ) {
    var dropdown, dropdownUi;
    dropdownUi = createUi(require("./Dropdown.html"), {
      block: block,
      showBlockId: false,
      isAdmin: rolesService.isAdmin(user),
      lockEditingToAdmins: function () {
        return savedBlockService.setLockEditingSetting(block.id, block.$editingLockedToAdmins);
      },
      copyBlockId: function () {
        return copy(block.id);
      },
      delete: function () {
        return deleteSavedBlock(block);
      },
    });
    dropdown = make(MenuDropdown, {
      toggleButtonElem: selector,
      options: {
        onHide: function () {
          return onDropdownHide(block);
        },
      },
      items: [dropdownUi],
    });
    return dropdown;
  };
}.call(this));
