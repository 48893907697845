// Generated by CoffeeScript 1.12.7
(function () {
  var MoveUpDown, template;

  template = require("./MoveUpDown.html");

  /*
    A simple ui component with a description and an up and down arrow
    @param [String] description
    @param [Object] viewToMove A reference to the object that will be moved
    @param [Function] moveUp
    @param [Function] moveDown
   */

  module.exports = MoveUpDown = (function () {
    function MoveUpDown(createUi, description, viewToMove, moveUp, moveDown) {
      this.ui = createUi(template, {
        description: description,
        moveItemUp: function () {
          return moveUp(viewToMove);
        },
        moveItemDown: function () {
          return moveDown(viewToMove);
        },
      });
    }

    return MoveUpDown;
  })();
}.call(this));
