// Generated by CoffeeScript 1.12.7
(function () {
  var InviteErrorModal, InviteUser, InviteUserService, parseEmails, validator;

  validator = require("validator");

  InviteUserService = require("SettingsAngular/InviteUserService/InviteUserService.ts")["default"];

  InviteErrorModal = require("SettingsAngular/InviteUserService/InviteErrorModal.ts")["default"];

  /*
   @param options [Object]
    - placeholder [String] Text to use for the input field placeholder
    - showInviteButton [Boolean] Whether or not to use the 'Invite' button
    - invitesSentCallback [Function] Called when `sendInvites` is successful
  
  @TODO (som, 2018-09-11):
  - Very edge case: it's possible to send an invite to an email that is already on the account, if it
  is added with a comma alongside an email that is already pending
   */

  module.exports = InviteUser = (function () {
    function InviteUser(
      createUi,
      $q,
      account,
      showDecideModal,
      apiBaseUrl,
      telemetry,
      $timeout,
      user,
      userList,
      userIconFactory,
      keyboard,
      speechBubble,
      Tooltip,
      hint,
      simpleDropdownFactory,
      make,
      invoke,
      utils,
      rolesService,
      AsyncButton,
      options,
      getTeam,
    ) {
      var domId, inviteErrorModal, inviteUserService, inviteUsers, requireEmailFromSameDomain;
      inviteUserService = make(InviteUserService);
      inviteErrorModal = invoke(InviteErrorModal);
      if (options.placeholder == null) {
        options.placeholder = "samson@acme.com, oz@acme.com";
      }
      if (options.showInviteButton == null) {
        options.showInviteButton = true;
      }
      domId = "invite-" + utils.uniqueId(8);
      hint.large({
        targetSelector: "#" + domId + ".invite-user .invite-input",
        hint: "Invite multiple people by separating each email address with a comma",
      });
      this.ui = createUi(require("./InviteUser.html"), {
        domId: domId,
        flags: {
          sending: false,
          sent: false,
        },
        placeholder: options.placeholder,
        showInviteButton: options.showInviteButton,
        emails: "",
        userList: userList,
        userIcon: userIconFactory.createEmpty({
          backgroundColor: "#EBF0F3",
        }),
        showRolesDropdown: true,
        isAdmin: rolesService.isAdmin(user),
        role: "creator",
        rolesDropdown: simpleDropdownFactory.create(
          [
            {
              name: "Admin",
              id: "admin",
            },
            {
              name: "Creator",
              id: "creator",
            },
          ],
          {
            id: "creator",
          },
          (function (_this) {
            return function (item) {
              return (_this.ui.role = item.id);
            };
          })(this),
        ),
        isEmpty: (function (_this) {
          return function () {
            return _this.ui.emails.length === 0;
          };
        })(this),
        checkEnterKeypress: (function (_this) {
          return function (event) {
            if (keyboard.isKey(event, "ENTER")) {
              return inviteUsers();
            }
          };
        })(this),
        sendInvites: (function (_this) {
          return function (emails, newInviteCount, role, team) {
            _this.ui.flags.sending = true;
            return $q
              .resolve()
              .then(function () {
                return inviteUserService.inviteWithModal(emails, newInviteCount, role, team);
              })
              .then(function () {
                _this.ui.flags.sending = false;
                _this.ui.flags.sent = true;
                _this.ui.emails = "";
                if (typeof options.invitesSentCallback === "function") {
                  options.invitesSentCallback();
                }
                return $timeout(function () {
                  return (_this.ui.flags.sent = false);
                }, 3000);
              })
              ["catch"](function (response) {
                var errMessage, userFriendlyErrorMessage;
                userFriendlyErrorMessage =
                  "We encountered an error. Please refresh the page, try again later, or contact Qwilr support";
                _this.ui.flags.sending = false;
                _this.ui.flags.sent = false;
                if (response.data != null) {
                  errMessage = response.data.error.message;
                  inviteErrorModal(errMessage).then(function (modalDidShow) {
                    var message;
                    if (!modalDidShow) {
                      if (response.status === 400) {
                        message = errMessage;
                      } else {
                        message = userFriendlyErrorMessage;
                      }
                      return _this.ui.showSpeechBubble(message);
                    }
                  });
                } else {
                  _this.ui.showSpeechBubble(userFriendlyErrorMessage);
                }
                return $q.reject();
              });
          };
        })(this),
        showSpeechBubble: (function (_this) {
          return function (message, target) {
            if (options.showInviteButton) {
              if (target == null) {
                target = ".invite-button";
              }
            } else {
              if (target == null) {
                target = ".invite-action-helper";
              }
            }
            return speechBubble.explain({
              targetSelector: "#" + domId + ".invite-user " + target,
              message: message,
            });
          };
        })(this),
        checkEmpty: (function (_this) {
          return function () {
            if (!_this.ui.isEmpty() && _this.ui.inviteButton.state === "disabled") {
              return (_this.ui.inviteButton.state = "idle");
            }
          };
        })(this),
      });
      inviteUsers = (function (_this) {
        return function () {
          return $q.resolve().then(function () {
            var differentDomainEmails,
              domain,
              emails,
              existingUsersEmails,
              existingUsersInvites,
              invalidEmails,
              message,
              newEmails,
              oldEmails,
              pendingEmails;
            if (options.inputEmail != null) {
              emails = [options.inputEmail];
            }
            emails = parseEmails(_this.ui.emails);
            if (emails.length === 0) {
              _this.ui.showSpeechBubble("Please enter an email");
              return $q.reject();
            }
            invalidEmails = _.filter(emails, function (email) {
              return !validator.isEmail(email);
            });
            if (invalidEmails.length !== 0) {
              _this.ui.showSpeechBubble("Invalid email address: " + invalidEmails.join(", "));
              return $q.reject();
            }
            if (requireEmailFromSameDomain()) {
              domain = user.email.replace(/.*@/, "");
              differentDomainEmails = _.filter(emails, function (email) {
                return !utils.isEmailOnDomain(email, domain);
              });
              if (differentDomainEmails.length !== 0) {
                _this.ui.showSpeechBubble("Invite users who share the same domain as you");
                return $q.reject();
              }
            }
            existingUsersEmails = _.map(userList, function (user) {
              return user.email;
            });
            existingUsersInvites = _.intersection(emails, existingUsersEmails);
            if (existingUsersInvites.length > 0) {
              message = existingUsersInvites[0] + " is already in the account";
              _this.ui.showSpeechBubble(message);
              return $q.reject();
            }
            pendingEmails = _.map(account.pendingInvitations, "email");
            newEmails = _.difference(emails, pendingEmails);
            oldEmails = _.intersection(emails, pendingEmails);
            if (oldEmails.length) {
              message = "";
              if (oldEmails.length === 1) {
                message = oldEmails[0] + " has already been invited. Would you like to send a new invite?";
              } else {
                message =
                  "Invites have already been sent to these email addresses: " +
                  oldEmails.join(", ") +
                  ". Would you like to send new invites?";
              }
              return showDecideModal({
                title: "Re-send Invite?",
                explainer: message,
              }).then(function (shouldResend) {
                if (shouldResend) {
                  return _this.ui.sendInvites(emails, newEmails.length, _this.ui.role, getTeam());
                } else if (newEmails.length) {
                  return _this.ui.sendInvites(newEmails, newEmails.length, _this.ui.role, getTeam());
                }
              });
            } else {
              return _this.ui.sendInvites(emails, emails.length, _this.ui.role, getTeam());
            }
          });
        };
      })(this);
      requireEmailFromSameDomain = (function (_this) {
        return function () {
          var ref;
          return (
            ((ref = account.settings.permissions) != null ? ref.userInvitePermission : void 0) === "domain" &&
            !_this.ui.isAdmin
          );
        };
      })(this);
      this.ui.inviteButton = new AsyncButton(inviteUsers, "Invite", {
        size: "mini",
      });
    }

    return InviteUser;
  })();

  /*
    Parse a comma-separated list of emails into an array, discarding any entries that
    are only whitespace
   */

  parseEmails = function (rawEmails) {
    var emails, i, len, rawEmail, ref, trimmed;
    emails = [];
    ref = rawEmails.split(",");
    for (i = 0, len = ref.length; i < len; i++) {
      rawEmail = ref[i];
      trimmed = rawEmail.trim();
      if (trimmed !== "") {
        emails.push(trimmed);
      }
    }
    return emails;
  };
}.call(this));
