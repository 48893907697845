// Generated by CoffeeScript 1.12.7

/*
  A modal for selecting a single user from a dropdown
 */

(function () {
  var SelectUserModal;

  module.exports = SelectUserModal = (function () {
    function SelectUserModal(createUi, make, user, $q, UserSelector, showDecideModal) {
      /*
        @param options [Object]
          - excludeUsers [Array[Object]] List of user objects to exclude from the user selector
          - explainer [String] Text to show in the body of the modal
          - buttonLabel [String]
          - selectedUserAction [Function] On confirmation, call this function on the selected user
      
        @return [Promise] Resolves with the selected user, or `null` if the modal is cancelled
       */
      this.selectUser = (function (_this) {
        return function (arg) {
          var buttonLabel,
            excludeUsers,
            explainer,
            selectUserModalInner,
            selectedUser,
            selectedUserAction,
            title,
            userSelector;
          (title = arg.title),
            (explainer = arg.explainer),
            (excludeUsers = arg.excludeUsers),
            (buttonLabel = arg.buttonLabel),
            (selectedUserAction = arg.selectedUserAction);
          selectedUser = null;
          userSelector = make(UserSelector, {
            excludeUsers: excludeUsers || [],
            defaultSelection: user,
          });
          selectUserModalInner = createUi(require("./SelectUserModal.html"), {
            userSelectorUi: userSelector.ui,
            explainer: explainer || "",
          });
          return showDecideModal({
            title: title || "Select new owner",
            explainer: selectUserModalInner,
            yesButton: buttonLabel || "Select",
            noButton: "Cancel",
            confirming: function () {
              selectedUser = userSelector.selectedUser;
              return selectedUserAction(selectedUser);
            },
          }).then(function (confirmed) {
            if (confirmed) {
              return selectedUser;
            } else {
              return null;
            }
          });
        };
      })(this);
    }

    return SelectUserModal;
  })();
}.call(this));
