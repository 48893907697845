// Generated by CoffeeScript 1.12.7

/*
  ColorPickerFactory is in charge of building the color pickers for the style editor. It is in charge of determining which options to show.
 */

(function () {
  var ColorPickerFactory,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  module.exports = ColorPickerFactory = (function () {
    function ColorPickerFactory(account, createUi, utils, styleService, $timeout, getElementDescription, elements) {
      var colorArray, colorsMap, create, getBackground, getColorGroups, getColorGroupsFromNames, setInitialColor;
      colorArray = [];
      colorsMap = {};

      /*
        Create all color pickers.
        @param style [Style] a style object
        @param onChange [Function] a function to be triggered every time any of the color pickers have a change
       */
      this.createAll = function (style, onChange) {
        var background;
        background = getBackground(style);
        colorsMap = styleService.getColorVariationsByName(account.settings.brand.colors);
        return _.map(elements, function (element) {
          return {
            title: getElementDescription(element),
            ui: create(getColorGroups(background, element), style.colors[element], background, onChange),
          };
        });
      };
      create = function (colorGroups, color, background, onChange) {
        var ui;
        setInitialColor(color, background, onChange);
        ui = createUi(require("./ColorPicker.html"), {
          colorGroups: colorGroups,
          isDark: utils.shouldUseDarkText,
          color: color,
          select: function (colorTile) {
            ui.color.name = colorTile.name;
            ui.color.modifier = colorTile.modifier;
            return onChange();
          },
        });
        return ui;
      };
      getColorGroups = function (background, element) {
        if (background === "slate") {
          if (element === "p") {
            colorArray = ["white"];
          } else {
            colorArray = ["white", "brand", "complementary"];
          }
        } else if (background === "white" || background === "silver") {
          colorArray = ["brand", "complementary", "slate"];
        } else if (background === "complementary") {
          colorArray = ["white", "complementary", "slate"];
        } else {
          colorArray = ["white", "brand", "slate"];
        }
        return getColorGroupsFromNames(colorArray);
      };
      getColorGroupsFromNames = function (names) {
        return _.map(names, function (name) {
          return colorsMap[name];
        });
      };
      setInitialColor = function (color, background, onChange) {
        var ref;
        if (((ref = color.name), indexOf.call(colorArray, ref) >= 0)) {
          if (color.name === "white" && color.modifier === "darken") {
            color.modifier = "translucent";
            return onChange();
          } else if (color.name === background && color.modifier !== "darken") {
            color.modifier = "darken";
            return onChange();
          }
        } else if (colorsMap[background] != null && utils.shouldUseDarkText(colorsMap[background][0].hex)) {
          color.name = colorsMap.slate[0].name;
          color.modifier = "darken";
          return onChange();
        } else {
          color.name = colorsMap.white[0].name;
          color.modifier = null;
          return onChange();
        }
      };
      getBackground = function (style) {
        if (style.backgroundCard != null && style.backgroundCard !== "none") {
          return style.backgroundCard;
        } else {
          return style.background;
        }
      };
    }

    return ColorPickerFactory;
  })();
}.call(this));
