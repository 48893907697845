"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.moveRight = exports.forceRefreshCommentId = exports.updateCommentId = exports.preventCommentContinuation = exports.addComment = void 0;
var Text_1 = require("ContentTokens/Models/Text");
var slate_1 = require("slate");
var EditorTypes_1 = require("../EditorTypes");
var EditorMarks_1 = require("../Marks/EditorMarks");
var CommentUtils_1 = require("../Comments/CommentUtils");
var addComment = function (id) {
    return function (editor) {
        (0, EditorMarks_1.addMark)({ type: EditorTypes_1.MarkType.Comment, data: { id: id } }, editor);
    };
};
exports.addComment = addComment;
var preventCommentContinuation = function (editor) {
    var marks = (0, EditorMarks_1.getCurrentMarks)(editor);
    marks.forEach(function (mark) { return removeCommentMarkIfAtEndOfComment(mark, editor); });
};
exports.preventCommentContinuation = preventCommentContinuation;
var removeCommentMarkIfAtEndOfComment = function (mark, editor) {
    if (mark.type === EditorTypes_1.MarkType.Comment && (0, CommentUtils_1.atEndOfComment)(mark, slate_1.Range.start(editor.selection), editor)) {
        (0, EditorMarks_1.removeMark)(mark, editor);
    }
};
var updateCommentId = function (editor, fromId, toId) {
    setCommentId(editor, fromId, toId);
};
exports.updateCommentId = updateCommentId;
var forceRefreshCommentId = function (editor, currentId) {
    setCommentId(editor, currentId, currentId);
};
exports.forceRefreshCommentId = forceRefreshCommentId;
var moveRight = function (event, editor) {
    event.preventDefault();
    if (!event.shiftKey) {
        slate_1.Transforms.move(editor, { distance: 1 });
    }
    else {
        slate_1.Transforms.move(editor, { distance: 1, edge: "focus" });
    }
};
exports.moveRight = moveRight;
var setCommentId = function (editor, fromId, newId) {
    var e_1, _a;
    var isCommentIdEqualFromId = function (mark) { return mark.type === EditorTypes_1.MarkType.Comment && mark.data.id === fromId; };
    var matchTextWithCommentId = function (node) { return Text_1.Text.isText(node) && node.marks.some(isCommentIdEqualFromId); };
    var textNodes = slate_1.Editor.nodes(editor, { match: matchTextWithCommentId, at: [] });
    try {
        for (var textNodes_1 = __values(textNodes), textNodes_1_1 = textNodes_1.next(); !textNodes_1_1.done; textNodes_1_1 = textNodes_1.next()) {
            var _b = __read(textNodes_1_1.value, 2), text = _b[0], path = _b[1];
            var newMarks = text.marks.map(function (mark) {
                if (mark.type === EditorTypes_1.MarkType.Comment && isCommentIdEqualFromId(mark)) {
                    var clonedComment = (0, CommentUtils_1.cloneComment)(mark);
                    clonedComment.data.id = newId;
                    return clonedComment;
                }
                return mark;
            });
            slate_1.Transforms.setNodes(editor, { marks: newMarks }, { at: path });
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (textNodes_1_1 && !textNodes_1_1.done && (_a = textNodes_1.return)) _a.call(textNodes_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
};
