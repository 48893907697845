"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var lodash_1 = require("lodash");
var blockConfigUtil_1 = require("@CommonFrontendBackend/StyleServiceV2/Config/blockConfigUtil");
var BlockStyleStore = (function () {
    function BlockStyleStore() {
        var _this = this;
        this.styleModel = {};
        this.updateStyle = function (newStyle) {
            Object.assign(_this.styleModel, newStyle);
        };
        this.setStyleAttribute = function (attr, newValue) {
            _this.styleModel[attr] = newValue;
        };
        this.updateFromAngular = function (angularStyleObject) {
            var currentData = _this.styleModel;
            if (!(0, lodash_1.isEqual)(angularStyleObject, currentData)) {
                _this.updateStyle(angularStyleObject);
                return true;
            }
            return false;
        };
        this.getElementStyle = function (elementType) {
            return _this.styleModel[elementType];
        };
        this.setElementStyle = function (elementType, newValue) {
            _this.styleModel[elementType] = newValue;
            _this.updateStyleHash();
        };
        this.setStylePropsByKey = function (key, newValue) {
            _this.styleModel[key] = newValue;
            _this.updateStyleHash();
        };
        this.setClonedFrom = function (savedStyleId) {
            _this.styleModel.clonedFrom = savedStyleId;
        };
        this.updateStyleHash = function () {
            _this.styleModel.hash = (0, blockConfigUtil_1.getHash)(_this.styleModel);
        };
    }
    Object.defineProperty(BlockStyleStore.prototype, "model", {
        get: function () {
            return this.styleModel;
        },
        enumerable: false,
        configurable: true
    });
    var _a;
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", Object)
    ], BlockStyleStore.prototype, "styleModel", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], BlockStyleStore.prototype, "model", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], BlockStyleStore.prototype, "updateStyle", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], BlockStyleStore.prototype, "setStyleAttribute", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], BlockStyleStore.prototype, "updateFromAngular", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], BlockStyleStore.prototype, "setElementStyle", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], BlockStyleStore.prototype, "setStylePropsByKey", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], BlockStyleStore.prototype, "setClonedFrom", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], BlockStyleStore.prototype, "updateStyleHash", void 0);
    return BlockStyleStore;
}());
exports.default = BlockStyleStore;
