// Generated by CoffeeScript 1.12.7
(function () {
  var Carousel, PageHandler, _, defaultOptions;

  defaultOptions = require("./CarouselOptions");

  PageHandler = require("./CarouselUtil/PageHandler");

  _ = require("lodash");

  /*
    @class Carousel
  
    Carousel is an abstract, page-driven container that can sit at the bottom any Block.
    The parent is responsible for it's lifecycle.
  
    The implementing Class is required to:
    - Pass in hooks/functions for key events, such as on a tile click.
    - Pass in a TileFactory class which tells the PageHandler how to generate tiles that are
      shown in the UI.
    - Pass in left/right buttons that appear in the header section.
    - Pass in data that can be used for initialisation, in the case there is a cache or already data
      in a service/memory that can be used.
  
    @param options [Object] - contains a series of modifiers/hooks, which contains:
      @param totalCount [Number] - the number of carousel items, this can be 0 if there is no
             prefetched data.
      @param nextPageBeforeHook [Function] - (Optional) a function that is run before the next page
             animation
      @param nextPageAfterHook [Function] - (Optional) a function that is run after the next page
             animation
      @param prevPageBeforeHook [Function] - (Optional) a function that is run before the prev page
             animation
      @param prevPageAfterHook [Function] - (Optional) a function that is run after the prev page
             animation
      @param onSelect [Function] - a function that is run when an item is selected
      @param onClearHook [Function] - (Optional) a function that is run when the carousel items are
             cleared
      @param onInitHook [Function] - (Optional) a function that is run when the carousel is
             initialised
      @param elementRenderedHook [Function] - (Optional) a function that is run when the carousel is
             rendered
      @param tileFactory [Object] - (Optional) a factory that can render the appropriate Tile type
      @param data [Object] - (Optional) any prefetched carousel data to display, this must be
             transformed by the appropriate factory
      @param leftButtons [Array] - (Optional) ui button components that sit on the left header section
      @param rightButtons [Array] - (Optional) ui button components that sit on the right header
             section
      @param message [Object] - (Optional) a CarouselMessage instance that can control dynamic
             messages above the header section
      @param customClass [String] - (Optional) Add a class to the carousel element
      @param errorUi [Object] - (Optional) A UI object to display if there is an error
   */

  module.exports = Carousel = (function () {
    function Carousel(createUi, options, make, $timeout) {
      /*
        Getters
       */
      this.getPageSize = (function (_this) {
        return function () {
          return _this.ui.PAGE_SIZE;
        };
      })(this);
      this.getPageIndex = (function (_this) {
        return function () {
          return _this.ui.pageIndex;
        };
      })(this);
      this.getPageHandler = (function (_this) {
        return function () {
          return _this.ui.pageHandler;
        };
      })(this);
      this.ui = createUi(require("./Carousel.html"), {
        viewState: {
          loadingCollection: true,
          selecting: false,
          firstTimeOpened: true,
          hidden: true,
          hasPrev: false,
          hasNext: true,
          showPosition: true,
          disableNextClick: false,
        },
        PAGE_SIZE: 6,
        pageIndex: 0,
        pages: [],
        controls: null,
        totalCount: 0,
        smallCarousel: false,
        title: "",
        hasError: false,
        customClass: options.customClass,
        errorUi: options.errorUi,

        /*
          We attach options/functions from the implementing Class. These are various hooks/
          or callbacks that are run on specific events. See the "CarouselOptions" list for
          the detailed list of options.
        
          @api private
         */
        setup: (function (_this) {
          return function () {
            var optName, optValue, opts, results;
            opts = _.defaults(options, defaultOptions);
            results = [];
            for (optName in opts) {
              optValue = opts[optName];
              results.push((_this.ui[optName] = optValue));
            }
            return results;
          };
        })(this),
        shouldShow: (function (_this) {
          return function () {
            return !_this.ui.viewState.hidden;
          };
        })(this),
        getMessageState: (function (_this) {
          return function () {
            if (_this.ui.hasError) {
              return "error";
            } else if (_this.ui.pages.length === 0) {
              return "emptyContent";
            }
            return null;
          };
        })(this),

        /*
          Hides the carousel
        
          @api public
         */
        hide: (function (_this) {
          return function () {
            return (_this.ui.viewState.hidden = true);
          };
        })(this),

        /*
          Opens the carousel
        
          @param state [Object] - state options, this can open the carousel at a certain point
          @api public
         */
        open: (function (_this) {
          return function (state) {
            return (_this.ui.viewState.hidden = false);
          };
        })(this),

        /*
          Clears the carousel of its contents. This will also clear any data that
          was iniitally passed in.
        
          @api public
         */
        clear: (function (_this) {
          return function () {
            _this.ui.pageIndex = 0;
            _this.ui.data = null;
            _this.ui.pageHandler.clear();
            return _this.ui.onClearHook();
          };
        })(this),

        /*
          Sets new data on the Carousel. This will clear the state as well.
        
          @param data [Array] - a list of items that represents the new data of the Carousel, i.e.
                 this will replace the existing data, using the TileFactory for transformation
          @api public
         */
        setData: (function (_this) {
          return function (data) {
            _this.ui.data = data;
            return _this.ui.pageHandler.setupPrefetchedData();
          };
        })(this),

        /*
          Sets the state of the carousel, i.e. will select a Tile element that is specified in the
          query and optionally refresh the data in the Carousel
        
          @param query [Function] - a function that selects the correct tile, given a list of data
          @api public
          @return [bool] whether we found an item with the query
         */
        setState: (function (_this) {
          return function (query) {
            var state;
            state = _this.ui.pageHandler.setState(query);
            if (state != null) {
              _this.ui.selectItem(
                state.item,
                state.index,
                state.isFirst,
                state.prev,
                state.next,
                $.Event("SelectedItem"),
              );
              _this.ui.calcViewState();
              _this.ui.recalculatePageNumber();
              return true;
            } else {
              return false;
            }
          };
        })(this),

        /*
          Recalculates UI elements that are dependent on the position of what page the
          user is on.
        
          This should be called whenever the page has changed, or the data has been manipulated.
        
          @api private
         */
        calcViewState: (function (_this) {
          return function () {
            var newPageIndex, nextItemIndex, ref;
            _this.ui.viewState.hasPrev = !(_this.ui.pageIndex === 0 && _this.ui.selectedIndex === 0);
            nextItemIndex = (_this.ui.selectedIndex + 1) % _this.ui.PAGE_SIZE;
            newPageIndex = _this.ui.pageIndex;
            if (nextItemIndex === 0) {
              newPageIndex++;
            }
            return (_this.ui.viewState.hasNext =
              ((ref = _this.ui.pages[newPageIndex]) != null ? ref.tiles[nextItemIndex] : void 0) != null);
          };
        })(this),

        /*
          Returns true/false whether the page is the last or not
         */
        isOnLastPage: (function (_this) {
          return function () {
            var nextPageIndex;
            if (_this.ui.pages.length === 0) {
              return true;
            }
            nextPageIndex = _this.ui.pageIndex + 1;
            if (_this.ui.pages[nextPageIndex] == null) {
              return true;
            }
            return _this.ui.pages[nextPageIndex].tiles[0] == null;
          };
        })(this),

        /*
          Navigates to the next page
        
          @params shouldDebounce [Boolean] - if the page navigation request should be debounced
          @api public
         */
        goNextPage: (function (_this) {
          return function (shouldDebounce) {
            if (_this.ui.isOnLastPage()) {
              return;
            }
            if (shouldDebounce && _this.ui.viewState.disableNextClick) {
              return;
            }
            if (shouldDebounce) {
              _this.ui.viewState.disableNextClick = true;
            }
            _this.ui.nextPageBeforeHook();
            _this.ui.pageIndex++;
            _this.ui.recalculatePageNumber();
            _this.ui.pageHandler.setFlags(_this.ui.pageIndex);
            _this.ui.nextPageAfterHook();
            if (shouldDebounce) {
              return $timeout(function () {
                return (_this.ui.viewState.disableNextClick = false);
              }, 1250);
            }
          };
        })(this),
        isOnFirstPage: (function (_this) {
          return function () {
            return _this.ui.pageIndex === 0;
          };
        })(this),

        /*
          Navigates to the previous page
        
          @api public
         */
        goPrevPage: (function (_this) {
          return function () {
            if (_this.ui.isOnFirstPage()) {
              return;
            }
            _this.ui.prevPageBeforeHook();
            _this.ui.pageIndex--;
            _this.ui.recalculatePageNumber();
            _this.ui.pageHandler.setFlags(_this.ui.pageIndex);
            return _this.ui.prevPageAfterHook();
          };
        })(this),

        /*
          Navigates to a specific page
        
          @params page [Number] - the page number to navigate to
          @api public
         */
        goToPage: (function (_this) {
          return function (page) {
            _this.ui.pageIndex = page;
            _this.ui.recalculatePageNumber();
            return _this.ui.pageHandler.setFlags(_this.ui.pageIndex);
          };
        })(this),

        /*
          Internal function that is called when a Tile is clicked
        
          @param item [Object] - the Tile object
          @param index [number] - the position of the tile, relative to the page
          @param isFirst [boolean] - whether the tile is on the first page
          @param prev [boolean] - whether the tile is on the previous page
          @param next [boolean] - whether the tile is on the next page
          @param $event [Object] - the JS event object
          @api private
         */
        selectItem: (function (_this) {
          return function (item, index, isFirst, prev, next, $event) {
            if (item == null || item.placeholder) {
              return;
            }
            if (_this.ui.selectedItem != null) {
              _this.ui.selectedItem.ui.selected = false;
            }
            item.ui.selected = true;
            _this.ui.selectedItem = item;
            _this.ui.selectedIndex = index;
            _this.ui.onSelect(item, index, isFirst, prev, next, $event);
            if (next) {
              _this.ui.goNextPage();
            } else if (prev) {
              _this.ui.goPrevPage();
            }
            return _this.ui.calcViewState();
          };
        })(this),

        /*
          Calculates the index, relative to the whole array (i.e. all pages)
        
          @param index [number] - the index relative to the page
          @param pageIndex [number] - the index of the page the tile is on
          @api private
         */
        globalIndex: (function (_this) {
          return function (index, pageIndex) {
            return pageIndex * _this.ui.PAGE_SIZE + index + 1;
          };
        })(this),

        /*
          Selects the next item in the page, relative to the current selection
          If nothing is selected, it will default to the first item on the first page
        
          @param event [Object] - the JS event object
          @api public
         */
        selectNext: (function (_this) {
          return function (event) {
            var currentPage, newPageIndex, nextItemIndex, tile;
            if (_this.ui.pages.length > 0 && _this.ui.pages[_this.ui.pageIndex] != null) {
              newPageIndex = _this.ui.pageIndex;
              currentPage = _this.ui.pages[_this.ui.pageIndex];
              if (_this.ui.selectedItem == null) {
                tile = currentPage.tiles[0];
                return _this.ui.selectItem(tile, 0, currentPage.isFirst, currentPage.prev, currentPage.next, event);
              } else {
                nextItemIndex = (_this.ui.selectedIndex + 1) % _this.ui.PAGE_SIZE;
                if (nextItemIndex === 0) {
                  if (_this.ui.pages[_this.ui.pageIndex + 1] == null) {
                    return;
                  }
                  currentPage = _this.ui.pages[_this.ui.pageIndex + 1];
                  newPageIndex++;
                }
                if (_this.ui.globalIndex(nextItemIndex, newPageIndex) <= _this.ui.totalCount) {
                  tile = currentPage.tiles[nextItemIndex];
                  if (tile != null) {
                    return _this.ui.selectItem(
                      tile,
                      nextItemIndex,
                      currentPage.isFirst,
                      currentPage.prev,
                      currentPage.next,
                      event,
                    );
                  }
                }
              }
            }
          };
        })(this),

        /*
          Selects the prev item in the page, relative to the current selection
          If nothing is selected, it will default to the first item on the first page
        
          @param event [Object] - the JS event object
          @api public
         */
        selectPrev: (function (_this) {
          return function (event) {
            var currentPage, prevItemIndex, tile;
            if (_this.ui.pages.length > 0 && _this.ui.pages[_this.ui.pageIndex] != null) {
              currentPage = _this.ui.pages[_this.ui.pageIndex];
              if (_this.ui.selectedItem == null) {
                tile = currentPage.tiles[0];
                return _this.ui.selectItem(tile, 0, currentPage.isFirst, currentPage.prev, currentPage.next, event);
              } else {
                prevItemIndex = (_this.ui.selectedIndex - 1) % _this.ui.PAGE_SIZE;
                if (prevItemIndex === -1) {
                  if (_this.ui.pages[_this.ui.pageIndex - 1] == null) {
                    return;
                  }
                  currentPage = _this.ui.pages[_this.ui.pageIndex - 1];
                  prevItemIndex = _this.ui.PAGE_SIZE - 1;
                }
                if (prevItemIndex >= 0) {
                  tile = currentPage.tiles[prevItemIndex];
                  if (tile != null) {
                    return _this.ui.selectItem(
                      tile,
                      prevItemIndex,
                      currentPage.isFirst,
                      currentPage.prev,
                      currentPage.next,
                      event,
                    );
                  }
                }
              }
            }
          };
        })(this),
        $init: (function (_this) {
          return function ($scope) {
            $scope.$watch("ui", function (ui) {
              if (ui == null) {
                return;
              }
              if (_this.ui.controls == null) {
                return (_this.ui.controls = make(require("./CarouselControls"), {
                  carousel: _this.ui,
                }));
              }
            });
            return _this.ui.onInitHook();
          };
        })(this),

        /*
          Recalculates the carousel page numbers that are displayed to the user
        
          Checks to see the "actual" size, vs the previous totalCount and selects the minimum.
          This is for when we do not have the entire list of items, and we are caching them
          on a per page basis, and the totalCount might be incorrect due to incorrect/corrupted data.
        
          @api private
         */
        recalculatePageNumber: (function (_this) {
          return function () {
            _this.ui.pagesCount = Math.ceil(_this.ui.totalCount / _this.ui.PAGE_SIZE);
            return (_this.ui.carouselPosition = Math.min(_this.ui.pageIndex + 1, _this.ui.pagesCount));
          };
        })(this),

        /*
          Sets the total count for the carousel. It is useful to populate this when we know the
          collection/total page size before we load the whole list.
        
          @param count [number] - the total count of objects in the carousel
          @api public
         */
        setTotalCount: (function (_this) {
          return function (count) {
            _this.ui.totalCount = count;
            return _this.ui.recalculatePageNumber();
          };
        })(this),

        /*
          Sets the carousel title
        
          @param title [string] - the title text
          @api public
         */
        setTitle: (function (_this) {
          return function (title) {
            return (_this.ui.title = title);
          };
        })(this),

        /*
          Sets the empty carousel message
        
          @param title [string] - the empty message to display when there are no items
          @api public
         */
        setEmptyContentMessage: (function (_this) {
          return function (emptyContentMessage) {
            return (_this.ui.emptyContentMessage = emptyContentMessage);
          };
        })(this),
        setErrorState: (function (_this) {
          return function (hasError) {
            return (_this.ui.hasError = hasError);
          };
        })(this),

        /*
          Calculates the offset (i.e. from the top of the page) relative to the given element
          input. Adds additional "buffer" to account for the height of the carousel.
        
          @param ele [Object] - the Element object to calculate the offset of
          @api private
         */
        calcOffset: function (elem) {
          return elem.offset().top + 270;
        },
        $onElementRendered: (function (_this) {
          return function (elem) {
            var blockContainer, elemWrapper, offset, prevPageSize, ref, smallWidthBreakpoint;
            elemWrapper = $(elem);
            offset = _this.ui.calcOffset(elemWrapper);
            blockContainer = elemWrapper.closest(".block-container");
            $(window).on("scroll", function () {
              var bottomOfViewport, topOfBlock;
              bottomOfViewport = $(window).scrollTop() + $(window).height();
              topOfBlock = offset - blockContainer.height() + 270 + 80;
              if (bottomOfViewport <= offset) {
                if (bottomOfViewport <= topOfBlock) {
                  elemWrapper.removeClass("bottom-fixed floating");
                  return elemWrapper.addClass("top-fixed");
                } else {
                  elemWrapper.removeClass("top-fixed bottom-fixed");
                  return elemWrapper.addClass("floating");
                }
              } else {
                elemWrapper.removeClass("floating top-fixed");
                return elemWrapper.addClass("bottom-fixed");
              }
            });
            $(window).trigger("scroll");
            $(window).on("resize", function () {
              return (offset = _this.ui.calcOffset(elemWrapper));
            });
            smallWidthBreakpoint = options.smallWidthSetting || 1400;
            if ($(window).width() <= smallWidthBreakpoint) {
              _this.ui.smallCarousel = true;
              prevPageSize = _this.ui.PAGE_SIZE;
              _this.ui.PAGE_SIZE = 4;
              if (!(_this.ui.PAGE_SIZE === prevPageSize)) {
                if ((ref = _this.ui.pageHandler) != null) {
                  ref.setupPrefetchedData();
                }
              }
            }
            return _this.ui.elementRenderedHook(elem);
          };
        })(this),
      });
      this.ui.setup();
      this.ui.pageHandler = new PageHandler(this.ui);
      if (options.buttonsLeft != null) {
        this.ui.buttonsLeft = options.buttonsLeft;
      }
      if (options.buttonsRight != null) {
        this.ui.buttonsRight = options.buttonsRight;
      }
    }

    return Carousel;
  })();
}.call(this));
