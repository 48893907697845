import "Application/Library/AutomationLibrary/AutomationCard.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Library/AutomationLibrary/AutomationCard.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA72TwW7bMAyG73kKXQasBwVx2rWbciv6HoUsMQ5TRhQk2kkx7N0H2ZujLhm27rCTIf4i+f0UveRb2ft+pb4ulIqcUZCDUQnICg6wWShlcwQnOllBNqpZru9LNFrvMXRGDTZ91DpH6zB0mp6fm5Ye9vumCTebxbfFcmrQjA1cnzInozxsbU9S6etR3wF2OzGqWa0+VOLtKHrMkeyrUVuCU0EoX+0xgZugHVN/CEXpbPwV7JTPZC812d314vs+C25fteMgEMSoUgh0C3IEGJtYwi5oFDjkKU1nsak29eltaX2E9gVFtzx2mOM/zj9lwgDakT1Eo9ZFuAhUhTQnHPEGSILOUtEFTqJ5gLQlPhoFRBgz5iKdozv0vhiZae+nJ2IqLzQNbzzoUu4Js20J/DxEX8/w4V1GLxn+i/mZ9vO/G/0ypracPCQtHI1q4kllJvSbs3Cl9KScV3B1U/1EU6W3+1qtK013GYNA0jBAkGyU7YUv1tBBuVMB2xr4CldZ78T0OF648N7c1ebb380NHYc5J9Yp7q/mJRz/yHZmakqD76QPYni4BAAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var cardWrapper = _7a468({defaultClassName:'o3tjdu0',variantClassNames:{disabled:{true:'o3tjdu1'}},defaultVariants:{},compoundVariants:[]});
export var cardContent = 'o3tjdu2';
export var cardHeadingBlock = 'o3tjdu3';
export var cardHeadingRow = 'o3tjdu4';
export var automationName = _7a468({defaultClassName:'o3tjdu5',variantClassNames:{disabled:{true:'o3tjdu6'}},defaultVariants:{},compoundVariants:[]});
export var description = _7a468({defaultClassName:'o3tjdu7',variantClassNames:{disabled:{true:'o3tjdu8'}},defaultVariants:{},compoundVariants:[]});
export var bottomRow = _7a468({defaultClassName:'o3tjdu9',variantClassNames:{disabled:{true:'o3tjdua'}},defaultVariants:{},compoundVariants:[]});
export var chevron = 'o3tjdub';
export var destructiveOption = 'o3tjduc';