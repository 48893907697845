// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = {
    Enter: {
      key: "enter",
      tests: [
        {
          name: "should insert a paragraph after insertable on Enter keypress",
          startContent: "<insertable>|</insertable>",
          selectionResult: "<insertable></insertable><p>|<br></p>",
        },
        {
          name: "should insert a paragraph even if content after",
          startContent: "<insertable>|</insertable><p>yo</p>",
          selectionResult: "<insertable></insertable><p>|<br></p><p>yo</p>",
        },
        {
          name: "should insert a paragraph even if insertable after",
          startContent: "<insertable>|</insertable><insertable></insertable>",
          selectionResult: "<insertable></insertable><p>|<br></p><insertable></insertable>",
        },
      ],
    },
    Backspace: {
      key: "backspace",
      tests: [
        {
          name: "should remove insertable and move cursor to previous element",
          startContent: "<p>yo</p><insertable>|</insertable>",
          selectionResult: "<p>yo|</p>",
        },
        {
          name: "should not affect any elements after it",
          startContent: "<p>yo</p><insertable>|</insertable><p>yo</p>",
          selectionResult: "<p>yo|</p><p>yo</p>",
        },
        {
          name: "should leave an element in editor if only element",
          startContent: "<insertable>|</insertable>",
          selectionResult: "<p>|<br></p>",
        },
        {
          name: "should remove insertable if first element",
          startContent: "<insertable>|</insertable><p>gurt</p>",
          selectionResult: "<p>|gurt</p>",
        },
        {
          name: "should remove insertable and move selection into previous insertable",
          startContent: '<insertable widget-id="1"></insertable><insertable widget-id="2">|</insertable>',
          selectionResult: '<insertable widget-id="1">|</insertable>',
        },
      ],
    },
    Delete: {
      key: "delete",
      tests: [
        {
          name: "should remove insertable and not move next elements content into the insertable",
          startContent: "<insertable>|</insertable><p>content</p>",
          selectionResult: "<p>|content</p>",
        },
        {
          name: "should leave an element in editor if only element",
          startContent: "<insertable>|</insertable>",
          selectionResult: "<p>|<br></p>",
        },
        {
          name: "should remove if last element",
          startContent: "<p>foo</p><insertable>|</insertable>",
          selectionResult: "<p>foo|</p>",
        },
        {
          name: "should remove insertable and move selection to next insertable",
          startContent: '<insertable widget-id="1">|</insertable><insertable widget-id="2"></insertable>',
          selectionResult: '<insertable widget-id="2">|</insertable>',
        },
      ],
    },
    Input: {
      key: "space",
      tests: [
        {
          name: "should do nothing on input",
          startContent: "<insertable>|</insertable>",
          domResult: "<insertable></insertable>",
        },
      ],
    },
    Navigation: {
      key: "up",
      tests: [
        {
          name: "should do nothing on navigation key",
          startContent: "<p><br></p><insertable>|</insertable><p><br></p>",
          domResult: "<p><br></p><insertable></insertable><p><br></p>",
        },
      ],
    },
  };
}.call(this));
