"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPeriodGranularity = void 0;
var luxon_1 = require("luxon");
var Types_1 = require("Reports/Types");
var GRANULARITY_MAPPING = (_a = {},
    _a[Types_1.TimePeriod.WEEK] = Types_1.PeriodGranularity.WEEK,
    _a[Types_1.TimePeriod.MONTH] = Types_1.PeriodGranularity.MONTH,
    _a[Types_1.TimePeriod.QUARTER] = Types_1.PeriodGranularity.QUARTER,
    _a[Types_1.TimePeriod.YEAR] = Types_1.PeriodGranularity.YEAR,
    _a);
function getPeriodGranularity(selectedTimePeriod) {
    var timePeriod = selectedTimePeriod.timePeriod, customPeriod = selectedTimePeriod.customPeriod;
    if (timePeriod === Types_1.TimePeriod.CUSTOM) {
        var periodStart = luxon_1.DateTime.fromJSDate(customPeriod.start);
        var periodEnd = luxon_1.DateTime.fromJSDate(customPeriod.end);
        var diffObject = periodEnd.diff(periodStart, "days").toObject();
        return "P".concat(diffObject.days, "D");
    }
    return GRANULARITY_MAPPING[timePeriod];
}
exports.getPeriodGranularity = getPeriodGranularity;
