// Generated by CoffeeScript 1.12.7

/*
  This block define the UI and logic for the Quote "Heads Up Display" -
  a floating widget showing the quote total, and providing quick access
  to the quote's settings options.
 */

(function () {
  module.exports = function (
    createUi,
    currency,
    viewState,
    block,
    toggleDisplayMode,
    blockMenu,
    $timeout,
    navigationHeaderHeight,
    projectNavigation,
  ) {
    return (this.ui = createUi(require("./HeadsUpDisplay.html"), {
      includingTax: block.taxes.length > 0,
      block: block,
      currency: currency,
      toggleDisplayMode: toggleDisplayMode,
      visible: function () {
        return viewState.activeBlock && !viewState.displayMode;
      },
      openSettings: function (e) {
        e.stopPropagation();
        return blockMenu.gotoSettings();
      },

      /*
        @NOTE (dylan, 2017-05-08) While there is now support in most browsers
        for `position:sticky` (http://caniuse.com/#feat=css-sticky) - I haven't
        been able to get it to work correctly in this context. We'd also need a
        CSS solution + fallback for IE; so leaving it as is seems pragmatic.
       */
      $onElementRendered: function (childElem) {
        var elem, verticalOffset;
        elem = childElem.parents(".heads-up-display");

        /*
          @TODO (dylan, 2017-05-08) this implementation does not account for dynamic
          changes - i.e. if a user changes navigation config to hide the nav header,
          this won't be triggered to use a different `verticalOffset` value. It would
          be nice if it did :)
         */
        verticalOffset = navigationHeaderHeight;
        if (projectNavigation.isWidgetEnabled("header") && $(".project-content-wrapper.with-header-fixed").length) {
          verticalOffset = navigationHeaderHeight * 2;
        }
        elem.fixer({
          gap: verticalOffset,
        });
        return $("body, html").trigger("scroll");
      },
    }));
  };
}.call(this));
