"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.indentList = exports.undentList = exports.MAX_LIST_DEPTH = void 0;
var slate_1 = require("slate");
var Utils_1 = require("../Utils");
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var TransformActions = __importStar(require("./TransformActions"));
var lodash_1 = require("lodash");
var Paragraph_1 = require("../WidgetRegistry/Widgets/Paragraph");
exports.MAX_LIST_DEPTH = 6;
exports.default = (function (di) {
    return {
        onEnterEmpty: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("listOnEmptyEnter")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Enter)
            .selectionIsCollapsed()
            .inBlockType("listItem")
            .inEmptyBlock()
            .doAction(function (event, editor) {
            event.preventDefault();
            return (0, exports.undentList)(editor);
        }),
        onTab: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("listOnTab")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Tab)
            .inBlockType("listItem")
            .doAction(function (event, editor) {
            event.preventDefault();
            return (0, exports.indentList)(editor);
        }),
        onShiftTab: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("listOnShiftTab")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Tab)
            .withModifier(BehaviourBuilder_1.KeyModifier.Shift)
            .inBlockType("listItem")
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, exports.undentList)(editor);
            return true;
        }),
        onBackspace: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("listBackspace")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Backspace)
            .inBlockType("listItem")
            .selectionIsCollapsed()
            .atStartOfBlock()
            .doAction(function (event, editor) {
            event.preventDefault();
            slate_1.Transforms.setNodes(editor, (0, Paragraph_1.createParagraphNode)());
            return true;
        }),
        onListShortcutDash: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("listOnShortcutDash")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Space)
            .inBlockType("paragraph")
            .isNotOfAncestry("accordionHeading")
            .hasTextSequence(/^-$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            slate_1.Transforms.insertText(editor, " ");
            slate_1.Transforms.delete(editor, { distance: 2, reverse: true });
            var action = TransformActions.toggleList("bullet");
            action(editor);
            return true;
        }),
        onListShortcutStar: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("listOnShortcutStar")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Space)
            .inBlockType("paragraph")
            .isNotOfAncestry("accordionHeading")
            .hasTextSequence(/^\*$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            slate_1.Transforms.insertText(editor, " ");
            slate_1.Transforms.delete(editor, { distance: 2, reverse: true });
            var action = TransformActions.toggleList("bullet");
            action(editor);
            return true;
        }),
        onListShortcutNumber: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("listOnShortcutNumber")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Space)
            .inBlockType("paragraph")
            .isNotOfAncestry("accordionHeading")
            .hasTextSequence(/^\d+\.$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            if (!editor.selection) {
                return;
            }
            var _a = __read(slate_1.Editor.nodes(editor, { match: slate_1.Text.isText }), 1), textNode = _a[0];
            var text = textNode[0].text;
            var anchorOffset = editor.selection.anchor.offset;
            var listStartCounter = parseInt(text.substring(0, anchorOffset - 1), 10);
            slate_1.Transforms.insertText(editor, " ");
            slate_1.Transforms.delete(editor, { distance: anchorOffset + 1, reverse: true });
            var action = TransformActions.toggleList("number", listStartCounter);
            action(editor);
            return true;
        }),
    };
});
var ListItemActionType;
(function (ListItemActionType) {
    ListItemActionType[ListItemActionType["Indent"] = 1] = "Indent";
    ListItemActionType[ListItemActionType["Unindent"] = -1] = "Unindent";
})(ListItemActionType || (ListItemActionType = {}));
var indentListItems = function (editor, action) {
    var e_1, _a;
    var blocks = (0, Utils_1.getSelectedBlocks)(editor);
    try {
        for (var blocks_1 = __values(blocks), blocks_1_1 = blocks_1.next(); !blocks_1_1.done; blocks_1_1 = blocks_1.next()) {
            var blockEntry = blocks_1_1.value;
            if (!(0, Utils_1.isListNodeEntry)(blockEntry)) {
                throw new Error("Indent called on non-list item");
            }
            var newLevel = Math.min(blockEntry[0].data.level + action, exports.MAX_LIST_DEPTH);
            var data = (0, lodash_1.cloneDeep)(blockEntry[0].data);
            data.level = newLevel;
            if (newLevel > 0) {
                slate_1.Transforms.setNodes(editor, { data: data }, { at: blockEntry[1] });
            }
            else {
                slate_1.Transforms.setNodes(editor, (0, Paragraph_1.createParagraphNode)());
            }
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (blocks_1_1 && !blocks_1_1.done && (_a = blocks_1.return)) _a.call(blocks_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return true;
};
var undentList = function (editor) {
    return indentListItems(editor, ListItemActionType.Unindent);
};
exports.undentList = undentList;
var indentList = function (editor) {
    return indentListItems(editor, ListItemActionType.Indent);
};
exports.indentList = indentList;
