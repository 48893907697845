// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (project, projectStore, projectService) {
    return function () {
      var isEnabled;
      isEnabled = projectService.isAcceptable(project);
      if (projectStore.hasAcceptEnabled !== isEnabled) {
        return projectStore.setHasAcceptEnabled(isEnabled);
      }
    };
  };
}.call(this));
