"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROIPresetID = void 0;
var ROIPresetID;
(function (ROIPresetID) {
    ROIPresetID["DollarsSaved"] = "dollars";
    ROIPresetID["TimeSaved"] = "time";
    ROIPresetID["SunkCost"] = "sunk";
    ROIPresetID["PercentageSaved"] = "percentage";
    ROIPresetID["ResourcesSaved"] = "resources";
    ROIPresetID["Blank"] = "blank";
})(ROIPresetID || (exports.ROIPresetID = ROIPresetID = {}));
