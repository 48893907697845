import "Application/Project/Modules/Accept/Controls/Agreement/AgreementButton.css.ts.vanilla.css!=!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/Modules/Accept/Controls/Agreement/AgreementButton.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VV207jMBB971f4BalINbJLgRJekPYT9gPQJJ4kA44d2Q6krPrvKzu9JF0Ky65Utak158zxnJnJ4wtuSgcNevYk60ZK2efs14wxKcRFemAsODC+tK7JhkcNAa/VXCxY/Fw+zBjbzrazqz2BSLgcipfK2c6ojL2Cm3NeWG0d950rocCnJ5nru+dnWSWCGqmqwzHSBGf1T3pH7g+R8j2F5rbnvgZl3/bhwz+OGl8hkDXyCFE7iFPouANFnf+rJA24ikzGbtqeCSbYqu0fxneU6Y571dAFG1FvpEKdMRmDz1dgUHPMGEs4ol4m6j2VEBeR67nzgcpNEo0mZKzU2HMfwIUJ+DqBU55pUiqsOaRspxlX50AB+3AArU5AWW1f0X0G/eEoUAH6QHE/pRAs70KwJivJ+cCLmrTKciytw6GFBtuCbbnGMnzHvx00tyHY5pvojxRq+EKgi53w7wq/CR9JvElKGuj5rmXWQpw0620KUeRbDZuMVY5UVBB/ecCmjQMdbesa4zPmsEUI8+sFk6VLUitoD6PWQkGm4s1RT5NiWlCKTHUad/RemqnuuySqtZ7iyGYMcm91FzCSufEy+JpqPb1frm3x8sHYk6nRUZiolSelup/M9X76ToaxIcMPITfrYdrj4S5uuTp1AP5Yia7KYS6X9wsmb5cLJu/WCyauxM30ZgW7Srfhw049s4lXbb9g6UucgS8/g/ME5Z8SiMWUCgw1MBgnr27HLw8yJRkKyEAHdAaioyNGNbTrqFhLMRSwtCZwT+8YCzg6KqEhvRnZN2LD/3T+D2unG32UqRwqiH3gce2VOr5+UGtqPfmIPJ7WpBSaRF5TwNTAmDFj3xy0D9NhPdz+REdKBJoqk7G4vaKWxwYVAZu3Dkt0njtUXYGKNzYacZkEfu3cxDtjTRq4SW/sD7ez7W9pmQZ8HwgAAA==\"}!../../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var container = '_1hm111x0';
export var divider = '_1hm111x1';
export var menuButton = '_1hm111x2';
export var icon = '_1hm111x3';
export var menuButtonText = '_1hm111x4';
export var popover = '_1hm111x5';
export var gridContainer = '_1hm111x6';
export var closeButton = '_1hm111x7';
export var image = '_1hm111x8';
export var buttonCard = _7a468({defaultClassName:'_1hm111x9',variantClassNames:{thumbnailLoaded:{false:'_1hm111xa'}},defaultVariants:{},compoundVariants:[]});
export var thumbnailPlaceholderImage = '_1hm111xc';
export var button = '_1hm111xd';
export var thumbnail = '_1hm111xe';
export var agreementCardLabel = '_1hm111xf';