"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TailoredPages = void 0;
var react_1 = __importStar(require("react"));
var CensusCollector_1 = require("Onboarding/CensusCollector/CensusCollector");
var styles = __importStar(require("./TailoredPages.css"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var Transitions_1 = require("Onboarding/CensusCollector/Common/Transitions");
var Types_1 = require("@CommonFrontendBackend/Onboarding/Types");
var SalesProposal_1 = require("Onboarding/CensusCollector/ChooseLandingPoint/PreviewCards/SalesProposal");
var SalesQuote_1 = require("Onboarding/CensusCollector/ChooseLandingPoint/PreviewCards/SalesQuote");
var DigitalMarketingProposal_1 = require("Onboarding/CensusCollector/ChooseLandingPoint/PreviewCards/DigitalMarketingProposal");
var inversify_react_1 = require("inversify-react");
var CommonUtils_1 = require("@CommonFrontendBackend/Utils/CommonUtils");
var Types_2 = require("TemplatesGallery/Types");
var CutScene_1 = require("Onboarding/CensusCollector/CutScene/CutScene");
var lodash_1 = require("lodash");
var react_transition_group_1 = require("react-transition-group");
var utils_1 = require("@qwilr/kaleidoscope/utils");
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var getTelemetryTailoredPagesOption = function (templateType) {
    switch (templateType) {
        case Types_1.OnboardingTemplateType.SalesProposal:
            return event_tracker_types_1.OnboardingTailoredPagesOption.SalesProposal;
        case Types_1.OnboardingTemplateType.SalesQuote:
            return event_tracker_types_1.OnboardingTailoredPagesOption.SalesQuote;
        case Types_1.OnboardingTemplateType.DigitalMarketingProposal:
            return event_tracker_types_1.OnboardingTailoredPagesOption.DigitalMarketingProposal;
        default:
            throw Error("Invalid template type");
    }
};
var TailoredPages = function (_a) {
    var onComplete = _a.onComplete;
    var onboardingTelemetryService = (0, inversify_react_1.useInjection)("onboardingTelemetryService");
    var dataStore = (0, inversify_react_1.useInjection)("censusCollectorStore");
    var apiService = (0, inversify_react_1.useInjection)("apiService");
    var _b = __read((0, react_1.useState)(false), 2), isLoading = _b[0], setIsLoading = _b[1];
    var _c = __read((0, react_1.useState)(["Hang tight, we're just", "spinning up your page..."]), 2), cutSceneLines = _c[0], setCutSceneLines = _c[1];
    var goToEditor = function (templateType) { return __awaiter(void 0, void 0, Promise, function () {
        var _a, createdPageId, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setIsLoading(true);
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 3, , 4]);
                    return [4, Promise.all([apiService.createTailoredPages([templateType]), (0, CommonUtils_1.delay)(3500)])];
                case 2:
                    _a = __read.apply(void 0, [_b.sent(), 1]), createdPageId = _a[0];
                    dataStore.completionData = { landingPoint: CensusCollector_1.ChosenLandingPoint.Editor, pageId: createdPageId[0] };
                    return [3, 4];
                case 3:
                    e_1 = _b.sent();
                    dataStore.completionData = { landingPoint: CensusCollector_1.ChosenLandingPoint.Editor };
                    return [3, 4];
                case 4:
                    onboardingTelemetryService.onboardingStepCompleted(event_tracker_types_1.OnboardingStepName.TailoredPages, {
                        tailoredPagesOptionChosen: getTelemetryTailoredPagesOption(templateType),
                    });
                    onComplete();
                    return [2];
            }
        });
    }); };
    var goToTemplateGallery = function () { return __awaiter(void 0, void 0, Promise, function () {
        var e_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setCutSceneLines(["Hang tight, we're just", "setting up your gallery..."]);
                    setIsLoading(true);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4, Promise.all([apiService.createTailoredPages([]), (0, CommonUtils_1.delay)(3500)])];
                case 2:
                    _a.sent();
                    dataStore.completionData = { landingPoint: CensusCollector_1.ChosenLandingPoint.TemplateGallery, tab: Types_2.TabName.Library };
                    return [3, 4];
                case 3:
                    e_2 = _a.sent();
                    dataStore.completionData = { landingPoint: CensusCollector_1.ChosenLandingPoint.TemplateGallery, tab: Types_2.TabName.Explore };
                    return [3, 4];
                case 4:
                    onboardingTelemetryService.onboardingStepCompleted(event_tracker_types_1.OnboardingStepName.TailoredPages, {
                        tailoredPagesOptionChosen: event_tracker_types_1.OnboardingTailoredPagesOption.TemplateGallery,
                    });
                    onComplete();
                    return [2];
            }
        });
    }); };
    var goToDashboard = function () {
        apiService.createTailoredPages([]);
        dataStore.completionData = { landingPoint: CensusCollector_1.ChosenLandingPoint.Dashboard };
        onboardingTelemetryService.onboardingStepCompleted(event_tracker_types_1.OnboardingStepName.TailoredPages, {
            tailoredPagesOptionChosen: event_tracker_types_1.OnboardingTailoredPagesOption.Dashboard,
        });
        onComplete();
    };
    return (react_1.default.createElement(react_1.default.Fragment, null,
        !isLoading && (react_1.default.createElement("div", { className: styles.root },
            react_1.default.createElement(Transitions_1.FadeInTransition, { timeout: 500 },
                react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xs" },
                    react_1.default.createElement(kaleidoscope_1.Heading, { level: "3", align: "center", strong: true }, "We've created some pages for you"),
                    react_1.default.createElement(kaleidoscope_1.Text, { size: "l", align: "center" }, "Choose one of the following pages to get started:"))),
            react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.grid, direction: "horizontal", gap: "l", wrap: true },
                react_1.default.createElement(Transitions_1.FadeInTransition, { timeout: 1000 },
                    react_1.default.createElement(SalesProposal_1.SalesProposalPreview, { onClick: goToEditor })),
                react_1.default.createElement(Transitions_1.FadeInTransition, { timeout: 1200 },
                    react_1.default.createElement(SalesQuote_1.SalesQuotePreview, { onClick: goToEditor })),
                react_1.default.createElement(Transitions_1.FadeInTransition, { timeout: 1400 },
                    react_1.default.createElement(DigitalMarketingProposal_1.DigitalMarketingProposalPreview, { onClick: goToEditor }))),
            react_1.default.createElement(Transitions_1.FadeInTransition, { timeout: 1700 },
                react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", className: styles.goToTemplates }, "We've also added these as re-usable templates in your ",
                    react_1.default.createElement(kaleidoscope_1.Link, { onClick: goToTemplateGallery }, "Templates gallery"))),
            react_1.default.createElement(Transitions_1.FadeInTransition, { timeout: 2000 },
                react_1.default.createElement(kaleidoscope_1.Button, { className: styles.goToDashboard, type: kaleidoscope_1.ButtonType.Tertiary, size: kaleidoscope_1.ButtonSize.Large, onClick: goToDashboard }, "Go straight to dashboard")))),
        react_1.default.createElement(react_transition_group_1.Transition, { appear: true, in: isLoading, timeout: 1000, onEnter: utils_1.forceReflow, mountOnEnter: true, unmountOnExit: true }, function (status) { return react_1.default.createElement(CutScene_1.CutScene, { onComplete: lodash_1.noop, status: status, cutsceneLines: cutSceneLines }); })));
};
exports.TailoredPages = TailoredPages;
