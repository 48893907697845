// This file was modified from the original Coffeescript source to:
// - add strikethrough support: https://github.com/qwilr/qwilr/pull/6893

/*
  Defines helpers that are used by tag event handlers
 */

(function () {
  var allBlocks,
    assert,
    clean,
    cleanStemNodes,
    cleanToLeft,
    cleanToRight,
    ensureOpen,
    fn,
    getCommonParentOverRange,
    getLeafBlocksInRange,
    getNearestCommonParentElement,
    inlineAttributes,
    invisibleWhitespace,
    isBlock,
    isBlockLevelElem,
    isInsertable,
    isLeafBlock,
    isList,
    isOrderedList,
    isSameNode,
    isTextNode,
    isUnorderedList,
    isUnwantedNode,
    joinDirection,
    leafBlocks,
    listSafeJoinLeft,
    moveLeftToText,
    name,
    nodeMatchesTag,
    nonLeafBlocks,
    removeSelection,
    setCaretInside,
    simpleStyleAttributes,
    sliceLeft,
    sliceRight,
    sliceToTop,
    splitAtEnds,
    textToLeft,
    textToRight,
    thisNode,
    upToBlockElem,
    whitelist,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  assert = function (truth, msg) {
    if (!truth) {
      msg || (msg = "assertion failure");
      throw new Error(msg);
    }
  };

  isUnwantedNode = function (node) {
    return node.nodeType === 3 || node.tagName === "INSERTABLE";
  };

  cleanToLeft = function (node) {
    var results;
    results = [];
    while (node.previousSibling != null && isUnwantedNode(node.previousSibling)) {
      results.push(node.parentElement.removeChild(node.previousSibling));
    }
    return results;
  };

  cleanToRight = function (node) {
    var results;
    results = [];
    while (node.nextSibling != null && isUnwantedNode(node.nextSibling)) {
      results.push(node.parentElement.removeChild(node.nextSibling));
    }
    return results;
  };

  clean = function (node) {
    var ref, ref1, ref2;
    while (
      ((ref = node.firstChild) != null ? ref.nodeType : void 0) === 3 &&
      node.firstChild.textContent.trim().length === 0
    ) {
      node.removeChild(node.firstChild);
    }
    while (
      ((ref1 = node.lastChild) != null ? ref1.nodeType : void 0) === 3 &&
      node.lastChild.textContent.trim().length === 0
    ) {
      node.removeChild(node.lastChild);
    }
    if (((ref2 = node.lastChild) != null ? ref2.tagName : void 0) != null && node.lastChild.tagName === "BR") {
      return node.removeChild(node.lastChild);
    }
  };

  textToLeft = function (point) {
    var currNode, foundText;
    foundText = false;
    if (
      point.offset != null &&
      point.offset > 0 &&
      point.node.textContent != null &&
      point.node.textContent.substr(0, point.offset).match(/^[\n\t ]+$/) == null
    ) {
      foundText = true;
    } else if ((point.type === "end" || point.type === "after") && point.node.textContent.trim().length > 0) {
      foundText = true;
    } else {
      currNode = point.node;
      while ((currNode.nodeType === 3 || !qed.util.isBlock(currNode)) && !foundText) {
        if (currNode.previousSibling == null) {
          currNode = currNode.parentNode;
        } else {
          currNode = currNode.previousSibling;
          if (currNode.textContent.trim().length > 0) {
            foundText = true;
          }
        }
      }
    }
    return foundText;
  };

  moveLeftToText = function (point) {
    point = point.leftNormalized();
    while (point.offset == null && (point.node.nodeType === 3 || !qed.util.isBlock(point.node))) {
      if (isTextNode(point.node)) {
        point.moveToTextEnd(point.node);
      } else if (point.type === "after") {
        point = qed.Point.end(point.node).leftNormalized();
      } else if (point.type === "start") {
        point = qed.Point.before(point.node).leftNormalized();
      }
    }
    if (point.offset != null) {
      return point;
    } else {
      return null;
    }
  };

  textToRight = function (point) {
    var curr, foundText;
    foundText = false;
    if (
      point.offset != null &&
      point.node.textContent.length > point.offset &&
      point.node.textContent.substr(point.offset).match(/^[\n\t ]+$/) == null
    ) {
      foundText = true;
    } else if ((point.type === "before" || point.type === "start") && point.node.textContent.trim().length > 0) {
      foundText = true;
    } else {
      curr = point.node;
      while ((curr.nodeType === 3 || !qed.util.isBlock(curr)) && !foundText) {
        if (curr.nextSibling == null) {
          curr = curr.parentNode;
        } else {
          curr = curr.nextSibling;
          if (curr.textContent.trim().length > 0) {
            foundText = true;
          }
        }
      }
    }
    return foundText;
  };

  joinDirection = function (point, deletionDirection) {
    var direction;
    direction = "";
    if (deletionDirection === "left") {
      if (point.node.textContent.trim().length !== 0 && point.node.previousSibling.textContent.trim().length === 0) {
        direction = "joinRight";
      } else {
        direction = "joinLeft";
      }
    } else {
      if (point.node.textContent.trim().length === 0 && point.node.nextSibling.textContent.trim().length !== 0) {
        direction = "joinRight";
      } else {
        direction = "joinLeft";
      }
    }
    return direction;
  };

  simpleStyleAttributes = {
    "font-weight": ["bold"],
    "font-style": ["italic"],
    "text-decoration-line": ["line-through"],
  };

  inlineAttributes = {
    link: {
      read: function (elem, computedStyle) {
        return elem.href || null;
      },
      write: function (elem, value) {
        return (elem.href = value);
      },
    },
  };

  fn = function (name, whitelist) {
    return (inlineAttributes[name] = {
      read: function (elem, computedStyle) {
        var val;
        val = computedStyle[name];
        if (indexOf.call(whitelist, val) >= 0) {
          return val;
        } else {
          return null;
        }
      },
      write: function (elem, value) {
        if (indexOf.call(whitelist, value) >= 0) {
          return (elem.style[name] = value);
        }
      },
    });
  };
  for (name in simpleStyleAttributes) {
    whitelist = simpleStyleAttributes[name];
    fn(name, whitelist);
  }

  leafBlocks = ["P", "H1", "H2", "LI", "BLOCKQUOTE"];

  nonLeafBlocks = ["UL", "OL"];

  allBlocks = leafBlocks.concat(nonLeafBlocks);

  isBlockLevelElem = function (node) {
    if (node.nodeType === 3) {
      return false;
    }
    return allBlocks.indexOf(node.tagName.toUpperCase()) >= 0;
  };

  isLeafBlock = function (node) {
    if (!isBlockLevelElem(node)) {
      return false;
    }
    return leafBlocks.indexOf(node.tagName.toUpperCase()) >= 0;
  };

  isBlock = function (node) {
    return node.nodeType !== 3 && qed.util.isBlock(node);
  };

  isTextNode = function (node) {
    return node.nodeType === 3;
  };

  thisNode = function (tag, point) {
    var node;
    node = point.node;
    while (node.tagName == null || node.tagName !== tag) {
      node = node.parentNode;
    }
    return node;
  };

  upToBlockElem = function (point) {
    var node;
    node = point.node;
    while (node != null && !isBlock(node)) {
      node = node.parentNode;
    }
    return node;
  };

  nodeMatchesTag = function (node, tag) {
    if (node.nodeType === 3) {
      return false;
    }
    return node.tagName === tag;
  };

  isUnorderedList = function (node) {
    return (node != null ? node.tagName : void 0) != null && node.tagName === "UL";
  };

  isOrderedList = function (node) {
    return (node != null ? node.tagName : void 0) != null && node.tagName === "OL";
  };

  isList = function (node) {
    return isUnorderedList(node) || isOrderedList(node);
  };

  setCaretInside = function (editor, point) {
    var curr;
    if (point.type === "before" && point.node.nodeType === 3) {
      point.moveToTextStart(point.node);
    } else if (point.type === "before") {
      curr = point.node;
      while (
        curr.firstChild != null &&
        curr.firstChild.nodeType !== 3 &&
        curr.firstChild.tagName.toUpperCase() !== "BR"
      ) {
        curr = curr.firstChild;
      }
      point = qed.Point.start(curr);
    }
    return editor.selection().setCaret(point);
  };

  isSameNode = function (nodeA, nodeB) {
    if ((nodeA != null ? nodeA.isSameNode : void 0) != null) {
      return nodeA.isSameNode(nodeB);
    } else {
      return nodeA === nodeB;
    }
  };

  splitAtEnds = function (editor, range) {
    var commonParent;
    commonParent = getCommonParentOverRange(editor, range);
    range = range.outwardNormalized();
    range.focus.ensureInsertable();
    range.anchor.ensureInsertable();
    while (!isSameNode(range.focus.containingElement(), commonParent)) {
      range.focus.splitRight(false);
    }
    while (!isSameNode(range.anchor.containingElement(), commonParent)) {
      range.anchor.splitLeft(false);
    }
    return range;
  };

  removeSelection = function (editor, anchor, focus) {
    var curr, elementToInsert, last, next, range, returnPoint, tagName;
    tagName = anchor.leftNormalized().node.tagName;
    range = splitAtEnds(editor, new qed.Range(anchor, focus));
    returnPoint = range.focus.rightNormalized();
    range = new qed.Range(range.anchor.rightNormalized(), range.focus.leftNormalized());
    assert(range.anchor.type === "before" && range.focus.type === "after", "Invalid endpoints after split");
    curr = range.anchor.node;
    last = range.focus.node;
    while (!isSameNode(curr, last)) {
      next = curr.nextSibling;
      curr.parentElement.removeChild(curr);
      curr = next;
    }
    last.parentElement.removeChild(last);
    if (editor.currentElem().children.length === 0) {
      elementToInsert = document.createElement(tagName || "p");
      editor.currentElem().appendChild(elementToInsert);
      returnPoint = qed.Point.start(elementToInsert);
    } else if (isList(returnPoint.node) && returnPoint.node.children.length === 0) {
      elementToInsert = document.createElement("li");
      returnPoint.node.appendChild(elementToInsert);
      returnPoint = qed.Point.start(elementToInsert);
    } else {
      assert(returnPoint.type === "end" || returnPoint.type === "before", "Point is not rightNormalized");
    }
    assert(editor.currentElem().children.length > 0, "we removed the last elem in the editor!");
    return returnPoint;
  };

  listSafeJoinLeft = function (point, editor) {
    var elem, joinPoint;
    assert(point.type === "before" || point.type === "after");
    if (point.containingElement() === editor.currentElem() && point.node === editor.currentElem().firstElementChild) {
      return point;
    }
    if (point.type === "after" && isList(point.node.nextElementSibling)) {
      point.node.parentElement.insertBefore(
        point.node.nextElementSibling.firstElementChild,
        point.node.nextElementSibling,
      );
    } else if (point.type === "before" && isList(point.node.previousElementSibling)) {
      point.node.parentElement.insertBefore(point.node.previousElementSibling.lastElementChild, point.node);
    }
    if (isList(point.node)) {
      if (point.type === "before") {
        joinPoint = qed.Point.before(point.node.firstElementChild);
        point.node.parentElement.insertBefore(point.node.firstElementChild, point.node);
      } else {
        joinPoint = qed.Point.after(point.node.lastElementChild);
        if (point.node.nextElementSibling != null) {
          point.node.parentElement.insertBefore(point.node.lastElementChild, point.node.nextElementSibling);
        } else {
          point.node.parentElement.appendChild(point.node.lastElementChild);
        }
      }
    }
    if (joinPoint == null) {
      joinPoint = point.copy();
    }
    joinPoint.joinLeft();
    elem = joinPoint.node;
    while (!isLeafBlock(elem)) {
      elem = elem.parentElement;
    }
    if (isList(elem.nextElementSibling) && elem.nextElementSibling.children.length === 0) {
      elem.parentElement.removeChild(elem.nextElementSibling);
    }
    if (elem.tagName === "LI" && !isList(elem.parentElement)) {
      elem.previousElementSibling.appendChild(elem);
    }
    return joinPoint;
  };

  sliceLeft = function (elem) {
    if (elem.previousSibling) {
      return qed.Point.before(elem).splitRight();
    }
  };

  sliceRight = function (elem) {
    if (elem.nextSibling) {
      return qed.Point.after(elem).splitLeft();
    }
  };

  sliceToTop = function (editor, elem) {
    if (elem.parentNode === editor.currentElem()) {
      return elem;
    }
    sliceLeft(elem);
    sliceRight(elem);
    return sliceToTop(editor, elem.parentNode);
  };

  getLeafBlocksInRange = function (editor, range) {
    var elem, elems, it;
    it = range.iterateRight();
    elems = [];
    while (!it.isAtEnd()) {
      it.skipText();
      elem = it.enterElement();
      if (elem && isInsertable(elem)) {
        it.leaveElement();
      }
      elem = it.leaveElement();
      if (elem && isLeafBlock(elem)) {
        assert(elem !== editor.currentElem());
        elems.push(elem);
      }
    }
    elem = range.getEnd().containingElement();
    while (elem !== editor.currentElem()) {
      if (isLeafBlock(elem)) {
        elems.push(elem);
        break;
      }
      elem = elem.parentNode;
    }
    return elems;
  };

  cleanStemNodes = function (editor) {
    var curr, results;
    curr = editor.currentElem().firstChild;
    results = [];
    while (curr != null) {
      if (curr.nodeType !== 3) {
        clean(curr);
        ensureOpen(curr);
      }
      results.push((curr = curr.nextSibling));
    }
    return results;
  };

  ensureOpen = function (elem) {
    if ((elem != null ? elem.tagName : void 0) != null && elem.tagName.toLowerCase() !== "insertable") {
      return qed.util.ensureOpen(elem);
    }
  };

  getNearestCommonParentElement = function (node1, node2) {
    var i, idx, parents, parents1, parents2, ref;
    parents = function (node) {
      var nodes;
      if (node.nodeType !== 1) {
        node = node.parentElement;
      }
      nodes = [];
      while (node != null) {
        nodes.unshift(node);
        node = node.parentElement;
      }
      return nodes;
    };
    parents1 = parents(node1);
    parents2 = parents(node2);
    if (!isSameNode(parents1[0], parents2[0])) {
      return null;
    }
    for (idx = i = 0, ref = parents1.length; 0 <= ref ? i < ref : i > ref; idx = 0 <= ref ? ++i : --i) {
      if (!isSameNode(parents1[idx], parents2[idx])) {
        return parents1[idx - 1];
      }
    }
    assert(parents1.length <= parents2.length);
    return parents1[parents1.length - 1];
  };

  getCommonParentOverRange = function (editor, range) {
    var commonParent, relation;
    commonParent = getNearestCommonParentElement(range.anchor.node, range.focus.node);
    relation = qed.util.compareNodes(commonParent, editor.currentElem());
    assert(relation === "child" || relation === "same", "Selection going outside editor");
    return commonParent;
  };

  isInsertable = function (node) {
    return node.tagName.toLowerCase() === "insertable";
  };

  invisibleWhitespace = "\\n\\t ";

  module.exports = {
    cleanToLeft: cleanToLeft,
    cleanToRight: cleanToRight,
    clean: clean,
    textToLeft: textToLeft,
    textToRight: textToRight,
    moveLeftToText: moveLeftToText,
    joinDirection: joinDirection,
    thisNode: thisNode,
    nodeMatchesTag: nodeMatchesTag,
    isUnorderedList: isUnorderedList,
    isOrderedList: isOrderedList,
    isList: isList,
    upToBlockElem: upToBlockElem,
    isBlock: isBlock,
    isBlockLevelElem: isBlockLevelElem,
    isLeafBlock: isLeafBlock,
    setCaretInside: setCaretInside,
    isSameNode: isSameNode,
    removeSelection: removeSelection,
    listSafeJoinLeft: listSafeJoinLeft,
    sliceLeft: sliceLeft,
    sliceRight: sliceRight,
    sliceToTop: sliceToTop,
    getLeafBlocksInRange: getLeafBlocksInRange,
    cleanStemNodes: cleanStemNodes,
    ensureOpen: ensureOpen,
    getNearestCommonParentElement: getNearestCommonParentElement,
    getCommonParentOverRange: getCommonParentOverRange,
    assert: assert,
    isInsertable: isInsertable,
    leafBlocks: leafBlocks,
    nonLeafBlocks: nonLeafBlocks,
    allBlocks: allBlocks,
    inlineAttributes: inlineAttributes,
    invisibleWhitespace: invisibleWhitespace,
    isTextNode: isTextNode,
  };
}.call(this));
