"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
var Types_1 = require("@CommonFrontendBackend/Tokens/Types");
var TokenSearch_1 = require("Project/Actions/Tokens/Search/TokenSearch");
var TokenSearchService = (function () {
    function TokenSearchService(tokenStore, projectStore) {
        this.tokenStore = tokenStore;
        this.projectStore = projectStore;
        this._tokenSearchLimit = 30;
    }
    TokenSearchService.prototype.createSearchFn = function (tokenSearchScope) {
        var _this = this;
        return function (_a) {
            var _b = _a === void 0 ? {} : _a, dataType = _b.dataType, searchTerm = _b.searchTerm;
            if (!tokenSearchScope) {
                return _this.searchAllTokens({ dataType: dataType, searchTerm: searchTerm });
            }
            return Promise.all([
                _this.searchListTokenProperties({
                    tokenSearchScope: tokenSearchScope,
                    searchTerm: searchTerm,
                }),
                _this.searchAllTokens({ dataType: Types_1.TokenDataType.String, searchTerm: searchTerm }),
            ]).then(function (_a) {
                var _b = __read(_a, 2), properties = _b[0], tokens = _b[1];
                return properties.concat(tokens);
            });
        };
    };
    TokenSearchService.prototype.searchAllTokens = function () {
        return __awaiter(this, arguments, Promise, function (filter) {
            var allTokens, crmTokens;
            if (filter === void 0) { filter = {}; }
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        allTokens = [];
                        if (!this.areCustomTokensAvailable) return [3, 2];
                        return [4, this.searchCustomTokens(filter)];
                    case 1:
                        allTokens = _a.sent();
                        _a.label = 2;
                    case 2:
                        if (!this.areCrmTokensAvailable) return [3, 4];
                        return [4, this.searchCrmTokens(filter)];
                    case 3:
                        crmTokens = _a.sent();
                        allTokens = allTokens.concat(crmTokens);
                        _a.label = 4;
                    case 4: return [2, allTokens];
                }
            });
        });
    };
    TokenSearchService.prototype.searchListTokenProperties = function (filter) {
        return __awaiter(this, void 0, Promise, function () {
            var tokenSearchScope, searchTerm, itemProperties, _a, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        tokenSearchScope = filter.tokenSearchScope, searchTerm = filter.searchTerm;
                        itemProperties = [];
                        if (!this.areCustomTokensAvailable) return [3, 2];
                        return [4, this.tokenStore.getTokens({
                                providerType: Types_1.ProviderType.Custom,
                                objectType: tokenSearchScope.childObjectType,
                            })];
                    case 1:
                        itemProperties = _c.sent();
                        _c.label = 2;
                    case 2:
                        if (!this.areCrmTokensAvailable) return [3, 4];
                        _b = (_a = itemProperties).concat;
                        return [4, this.tokenStore.getTokens({
                                providerType: tokenSearchScope.substitutionToken.providerType,
                                objectType: tokenSearchScope.childObjectType,
                                parentId: tokenSearchScope.substitutionToken.id,
                            })];
                    case 3:
                        itemProperties = _b.apply(_a, [_c.sent()]);
                        _c.label = 4;
                    case 4:
                        itemProperties = itemProperties.map(function (token) {
                            return __assign(__assign({}, token), { apiName: "".concat(tokenSearchScope.name, ".").concat(token.apiName), name: "".concat(tokenSearchScope.childPropertyLabel || tokenSearchScope.label, ".").concat(token.name) });
                        });
                        return [2, TokenSearch_1.TokenSearch.searchTokens(itemProperties, searchTerm).slice(0, this._tokenSearchLimit)];
                }
            });
        });
    };
    Object.defineProperty(TokenSearchService.prototype, "areCrmTokensAvailable", {
        get: function () {
            return this.projectStore.isCrmTemplate;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TokenSearchService.prototype, "areCustomTokensAvailable", {
        get: function () {
            return !this.projectStore.isCrmTemplate;
        },
        enumerable: false,
        configurable: true
    });
    TokenSearchService.prototype.searchCustomTokens = function (filter) {
        return __awaiter(this, void 0, Promise, function () {
            var searchTerm, dataType, customTokens;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        searchTerm = filter.searchTerm, dataType = filter.dataType;
                        return [4, this.tokenStore.getTokens({
                                providerType: Types_1.ProviderType.Custom,
                            })];
                    case 1:
                        customTokens = _a.sent();
                        if (dataType) {
                            customTokens = customTokens.filter(function (token) { return token.dataType === dataType; });
                        }
                        return [2, TokenSearch_1.TokenSearch.searchTokens(customTokens, searchTerm).slice(0, this._tokenSearchLimit)];
                }
            });
        });
    };
    TokenSearchService.prototype.searchCrmTokens = function (filter) {
        return __awaiter(this, void 0, Promise, function () {
            var searchTerm, crmInfo;
            return __generator(this, function (_a) {
                searchTerm = filter.searchTerm;
                crmInfo = this.projectStore.crmInfo;
                return [2, this.tokenStore.getTokens({
                        providerType: crmInfo.integration,
                        objectType: crmInfo.object,
                        query: searchTerm,
                        limit: this._tokenSearchLimit,
                    })];
            });
        });
    };
    return TokenSearchService;
}());
exports.default = TokenSearchService;
