// Generated by CoffeeScript 1.12.7

/*
  This module creates a video picker button.
 */

(function () {
  var Video, VideoPickerButton;

  Video = require("Common/Utils/ImageImports").Video;

  module.exports = VideoPickerButton = (function () {
    function VideoPickerButton(createUi, make, imageLocation, videoUrl, onSelected, skin, mediaBrowser, projectEvents) {
      this.ui = createUi(require("Common/MediaPicker/VideoPicker/Button/Button.html"), {
        icon: Video,
        skin: skin,
        showVideoPicker: function () {
          mediaBrowser.show("stockVideos").then(function (result) {
            return onSelected(result.data.url);
          });
          return projectEvents.emit("bigComponentOpened");
        },
      });
    }

    return VideoPickerButton;
  })();
}.call(this));
