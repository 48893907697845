"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PendingUsersDataStore = void 0;
var mobx_1 = require("mobx");
var PendingUsersDataStore = (function () {
    function PendingUsersDataStore(inviteService, angularAccountModel) {
        this.inviteService = inviteService;
        this.angularAccountModel = angularAccountModel;
        this.pendingUsers = [];
        this.refreshPendingUsers();
    }
    PendingUsersDataStore.prototype.getPendingUsers = function () {
        return this.pendingUsers;
    };
    PendingUsersDataStore.prototype.doesEmailExist = function (email) {
        return (this.pendingUsers.find(function (u) {
            return u.email === email;
        }) !== undefined);
    };
    PendingUsersDataStore.prototype.inviteNewUser = function (email, options) {
        return __awaiter(this, void 0, Promise, function () {
            var _a, additionalSeats, pendingInvitations;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.inviteService.inviteNewUser(email, options)];
                    case 1:
                        _a = _b.sent(), additionalSeats = _a.additionalSeats, pendingInvitations = _a.pendingInvitations;
                        this.angularAccountModel.pendingInvitations = pendingInvitations;
                        this.angularAccountModel.additionalSeats = additionalSeats;
                        this.refreshPendingUsers();
                        return [2];
                }
            });
        });
    };
    PendingUsersDataStore.prototype.modifyPendingUserPermissions = function (userIds, pageId, permission) {
        return __awaiter(this, void 0, Promise, function () {
            var pendingUsers, pendingUserEmails, pendingUsers_1, pendingUsers_1_1, pendingUser, permissions, pagePermission, newPermission;
            var e_1, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        pendingUsers = this.angularAccountModel.pendingInvitations.filter(function (p) {
                            return userIds.includes(p._id);
                        });
                        if (!(pendingUsers.length > 0)) return [3, 2];
                        pendingUserEmails = pendingUsers.map(function (u) {
                            return u.email;
                        });
                        return [4, this.inviteService.updatePagePermissions(pendingUserEmails, pageId, permission)];
                    case 1:
                        _b.sent();
                        try {
                            for (pendingUsers_1 = __values(pendingUsers), pendingUsers_1_1 = pendingUsers_1.next(); !pendingUsers_1_1.done; pendingUsers_1_1 = pendingUsers_1.next()) {
                                pendingUser = pendingUsers_1_1.value;
                                permissions = pendingUser.permissions || [];
                                pagePermission = permissions.find(function (p) {
                                    return p.projectId === pageId;
                                });
                                if (pagePermission) {
                                    pagePermission.permission = permission;
                                }
                                else {
                                    newPermission = {
                                        projectId: pageId,
                                        permission: permission,
                                    };
                                    if (!pendingUser.permissions) {
                                        pendingUser.permissions = [newPermission];
                                    }
                                    else {
                                        pendingUser.permissions.push(newPermission);
                                    }
                                }
                            }
                        }
                        catch (e_1_1) { e_1 = { error: e_1_1 }; }
                        finally {
                            try {
                                if (pendingUsers_1_1 && !pendingUsers_1_1.done && (_a = pendingUsers_1.return)) _a.call(pendingUsers_1);
                            }
                            finally { if (e_1) throw e_1.error; }
                        }
                        this.refreshPendingUsers();
                        _b.label = 2;
                    case 2: return [2];
                }
            });
        });
    };
    PendingUsersDataStore.prototype.refreshPendingUsers = function () {
        this.pendingUsers = this.angularAccountModel.pendingInvitations
            .map(function (u) {
            return {
                id: u._id,
                email: u.email,
                role: u.role,
                teams: u.teams ? u.teams : [],
                permissions: u.permissions ? u.permissions : [],
            };
        })
            .sort(function (a, b) {
            return a.email.localeCompare(b.email);
        });
    };
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Array)
    ], PendingUsersDataStore.prototype, "pendingUsers", void 0);
    return PendingUsersDataStore;
}());
exports.PendingUsersDataStore = PendingUsersDataStore;
