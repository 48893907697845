"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLoadMore = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
function useLoadMore(items, _a) {
    var initialItemCount = _a.initialItemCount, increment = _a.increment;
    var _b = __read((0, react_1.useState)(false), 2), isExpanded = _b[0], setIsExpanded = _b[1];
    var _c = __read((0, react_1.useState)(1), 2), loadMoreStepCount = _c[0], setLoadMoreStepCount = _c[1];
    var itemsToShow = isExpanded ? loadMoreStepCount * increment : initialItemCount;
    var showLoadMoreButton = items.length > itemsToShow;
    var showViewLessButton = isExpanded && items.length > initialItemCount;
    var loadMoreButtons = (react_1.default.createElement(LoadMoreButtons, { showLoadMoreButton: showLoadMoreButton, showViewLessButton: showViewLessButton, onLoadMoreClick: function () {
            if (!isExpanded) {
                setIsExpanded(true);
            }
            else {
                setLoadMoreStepCount(loadMoreStepCount + 1);
            }
        }, onViewLessClick: function () {
            setIsExpanded(false);
        } }));
    return { itemsToShow: itemsToShow, loadMoreButtons: loadMoreButtons };
}
exports.useLoadMore = useLoadMore;
var LoadMoreButtons = function (_a) {
    var showLoadMoreButton = _a.showLoadMoreButton, showViewLessButton = _a.showViewLessButton, onLoadMoreClick = _a.onLoadMoreClick, onViewLessClick = _a.onViewLessClick;
    return (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "s" },
        showViewLessButton && (react_1.default.createElement(kaleidoscope_1.Button, { onClick: onViewLessClick, type: kaleidoscope_1.ButtonType.Secondary },
            react_1.default.createElement(icons_1.ChevronUp, null),
            "View less")),
        showLoadMoreButton && (react_1.default.createElement(kaleidoscope_1.Button, { onClick: onLoadMoreClick, type: kaleidoscope_1.ButtonType.Secondary }, "Load more"))));
};
