// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = angular.module("qwilrRightClick", []).directive("ngRightClick", function ($parse) {
    return {
      restrict: "A",
      link: function (scope, element, attrs) {
        var fn;
        fn = $parse(attrs.ngRightClick);
        return element.on("contextmenu", function (event) {
          event.preventDefault(event);
          return scope.$apply(function () {
            return fn(scope, {
              $event: event,
            });
          });
        });
      },
    };
  });
}.call(this));
