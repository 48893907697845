// Generated by CoffeeScript 1.12.7
(function () {
  var ModalController;

  module.exports = ModalController = (function () {
    function ModalController(createUi, $timeout, domFrame) {
      this.createUi = createUi;
      this.$timeout = $timeout;
      this.ESCAPE_KEY_CODE = 27;
      this.ui = this.createUi(require("./Modal.html"), {
        visible: false,
        customClass: "",
        hide: (function (_this) {
          return function () {
            return _this.hide();
          };
        })(this),
        escape: (function (_this) {
          return function () {
            if (_this.enableEscape == null || _this.enableEscape()) {
              _this.hide();
              return typeof _this.onEscape === "function" ? _this.onEscape() : void 0;
            }
          };
        })(this),
      });
      domFrame.add(this.ui);
      this.show = (function (_this) {
        return function (childUi, options) {
          var ref;
          if (options == null) {
            options = {};
          }
          _this.ui.visible = true;
          _this.ui.content = childUi;
          if (options.onHide != null && _.isFunction(options.onHide)) {
            _this.onHide = options.onHide;
          }
          if (options.onEscape != null && _.isFunction(options.onEscape)) {
            _this.onEscape = options.onEscape;
          }
          if ((ref = options.customClass) != null ? ref.length : void 0) {
            _this.ui.customClass = options.customClass;
          }
          if (options.enableEscape != null && _.isFunction(options.enableEscape)) {
            _this.enableEscape = options.enableEscape;
          } else {
            _this.enableEscape = function () {
              return true;
            };
          }
          $(document).on("keyup.modal", function (e) {
            var code;
            code = e.keyCode || e.which;
            if (code === _this.ESCAPE_KEY_CODE) {
              if (_this.enableEscape == null || _this.enableEscape()) {
                return _this.$timeout(function () {
                  _this.hide();
                  return typeof _this.onEscape === "function" ? _this.onEscape() : void 0;
                });
              }
            }
          });
          return _this;
        };
      })(this);
      this.isShowing = (function (_this) {
        return function () {
          return _this.ui.visible;
        };
      })(this);
      this.setCustomClass = (function (_this) {
        return function (className) {
          return (_this.ui.customClass = className);
        };
      })(this);
      this.hide = (function (_this) {
        return function () {
          _this.ui.visible = false;
          $(document).off("keyup.modal");
          if (_this.onHide != null) {
            _this.onHide();
          }
          _this.ui.customClass = "";
          return (_this.onHide = null);
        };
      })(this);
      this.disableEscapeKey = (function (_this) {
        return function () {
          return $(document).off("keyup.modal");
        };
      })(this);
      this.close = this.hide;
      this.onHide = null;
    }

    return ModalController;
  })();
}.call(this));
