// Generated by CoffeeScript 1.12.7
(function () {
  var Summary;

  module.exports = Summary = (function () {
    function Summary(createUi, make, section, currency, sectionService, block) {
      this.ui = createUi(require("Project/Modules/Quote/Section/Summary/Summary.html"), {
        currency: currency,
        section: section,
        hasTaxes: function () {
          return block.taxes.length;
        },
      });
    }

    return Summary;
  })();
}.call(this));
