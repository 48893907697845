import "Application/Project/ContentSidebar/ContentSidebar.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/ContentSidebar/ContentSidebar.css.ts.vanilla.css\",\"source\":\"LnhndGU1NTAgewogIHdpZHRoOiAzNjBweDsKICBhbGlnbi1zZWxmOiBmbGV4LWVuZDsKICBib3JkZXItbGVmdDogMXB4IHNvbGlkIHZhcigtLWNvbG9yLWJvcmRlcl9fMWJsN2pqMTEwKTsKfQoueGd0ZTU1MSB7CiAgYm94LXNoYWRvdzogdmFyKC0tc2hhZG93LWVsZXZhdGlvbjFfXzFibDdqajExZCk7Cn0KLnhndGU1NTIgewogIGxlZnQ6IC0yNzhweDsKfQoueGd0ZTU1MyB7CiAgZGlzcGxheTogZmxleDsKICBmbGV4LWRpcmVjdGlvbjogY29sdW1uOwogIGxlZnQ6IDA7Cn0KLnhndGU1NTUgewogIHBvc2l0aW9uOiBhYnNvbHV0ZTsKfQoueGd0ZTU1NiB7CiAgbGVmdDogY2FsYygyNzhweCArIHZhcigtLXNwYWNpbmctbV9fMWJsN2pqMTFtKSk7Cn0KLnhndGU1NTcgewogIGxlZnQ6IHZhcigtLXNwYWNpbmctbV9fMWJsN2pqMTFtKTsKfQoueGd0ZTU1OCB7CiAgaGVpZ2h0OiBjYWxjKCgoKDEwMHZoIC0gNjBweCkgLSAoKDY1cHggKyB2YXIoLS1zcGFjaW5nLWxfXzFibDdqajExbikpICsgdmFyKC0teGd0ZTU1NCkpKSAtIHZhcigtLWhvcml6b250YWwtc2Nyb2xsYmFyLWhlaWdodCkpICsgdmFyKC0tc3BhY2luZy1sX18xYmw3amoxMW4pKTsKfQ==\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var foldersWidth = '278px';
export var sidebarWidth = '360px';
export var sidebarZIndex = 501;
export var sidebarHeader = 'xgte550';
export var sidebarInner = _7a468({defaultClassName:'xgte551',variantClassNames:{withFoldersOffset:{true:'xgte552',false:'xgte553'}},defaultVariants:{},compoundVariants:[]});
export var chooseBackgroundHeaderHeight = 'var(--xgte554)';
export var closeChooseBackground = _7a468({defaultClassName:'xgte555',variantClassNames:{withFoldersOffset:{true:'xgte556',false:'xgte557'}},defaultVariants:{},compoundVariants:[]});
export var editorNavigationHeight = '60px';
export var sidebarHeaderHeight = 'calc((65px + var(--spacing-l__1bl7jj11n)) + var(--xgte554))';
export var sidebarViewContainer = 'xgte558';