// Generated by CoffeeScript 1.12.7

/*
  Defines list of accept options available to the user.
  This list is feature limited (i.e. so we can differentiate on plans,
  as well as feature flags for new features).
 */

(function () {
  var AcceptButton, Form, PayNow, Projects, PushToCloud, ref;

  (ref = require("Common/Utils/ImageImports")),
    (AcceptButton = ref.AcceptButton),
    (PushToCloud = ref.PushToCloud),
    (Form = ref.Form),
    (PayNow = ref.PayNow),
    (Projects = ref.Projects);

  module.exports = function (make, settings, integrationService, account, imageLocation, quickBooksTaxMapChecker) {
    var customButton,
      customForm,
      freshbooksInvoice,
      internalName,
      options,
      payNow,
      pipedrive,
      quickbooksInvoice,
      ref1,
      ref2,
      ref3,
      ref4,
      ref5,
      ref6,
      xeroInvoice;
    customForm = make(require("./CustomForm/CustomForm"));
    customButton = make(require("./CustomButton/CustomButton"));
    payNow = make(require("./PayNow/PayNow").default);
    options = [
      {
        name: "Customize Accept Button",
        internalName: "withCustomButton",
        cssClass: "custom-button",
        description: "Change the standard text for the accept button.",
        icon: AcceptButton,
        ui: customButton.ui,
      },
      {
        name: "Payments",
        eventName: "Payment",
        internalName: "payNowEnabled",
        cssClass: "pay-now",
        description: "Get paid instantly through your Qwilr page.",
        icon: PayNow,
        ui: payNow.ui,
        upsellFlag: "payment integrations",
        customUpsellOptions: {
          customUpsellMessage: "Collect payment when your clients accept (full payment, deposits and % splits). ",
          customUpsellHelpLink: "https://help.qwilr.com/article/103-stripe",
        },
      },
      {
        name: "Custom Form",
        eventName: "Custom Form",
        internalName: "withCustomForm",
        cssClass: "custom-form",
        description: "Use a custom form to gather data when accepting.",
        icon: Form,
        ui: customForm.ui,
        upsellFlag: "acceptCustomForms",
        customUpsellOptions: {
          customUpsellMessage: "Collect extra information from your clients with customisable forms.",
          customUpsellHelpLink: "https://help.qwilr.com/article/176-custom-forms",
        },
      },
      {
        name: "Digital Signature",
        eventName: "E-Signature",
        internalName: "esignEnabled",
        cssClass: "esign",
        description: "Collect an electronic signature when accepting this quote.",
        icon: Projects,
        ui: null,
        upsellFlag: "esign",
        customUpsellOptions: {
          customUpsellMessage: "Allow your clients / customers / partners to electronically sign your documents.",
          customUpsellHelpLink: "https://help.qwilr.com/article/178-e-signatures",
        },
      },
    ];
    if (integrationService.isEnabled("freshbooks")) {
      freshbooksInvoice = make(require("./Invoicers/FreshBooksInvoicer"));
      internalName = "pushInvoice-freshbooks";
      if (
        settings[internalName] == null &&
        ((ref1 = account.integrations.freshbooks) != null
          ? (ref2 = ref1.settings) != null
            ? ref2.defaultAutoPush
            : void 0
          : void 0) != null
      ) {
        settings[internalName] = account.integrations.freshbooks.settings.defaultAutoPush;
      }
      options.push({
        name: "Send invoice to FreshBooks",
        internalName: internalName,
        cssClass: "freshbooks-invoice",
        description: "Have your quote automatically pushed to FreshBooks once accepted.",
        icon: PushToCloud,
        ui: freshbooksInvoice.ui,
      });
    }
    if (integrationService.isEnabled("quickbooks")) {
      quickbooksInvoice = make(require("./Invoicers/QuickBooksInvoicer"));
      internalName = "pushInvoice-quickbooks";
      if (
        settings[internalName] == null &&
        ((ref3 = account.integrations.quickbooks) != null
          ? (ref4 = ref3.settings) != null
            ? ref4.defaultAutoPush
            : void 0
          : void 0) != null
      ) {
        settings[internalName] = account.integrations.quickbooks.settings.defaultAutoPush;
      }
      options.push({
        name: "Send invoice to QuickBooks",
        internalName: internalName,
        cssClass: "quickbooks-invoice",
        description: "Have your quote automatically pushed to QuickBooks once accepted.",
        icon: PushToCloud,
        ui: quickbooksInvoice.ui,
        onEnabled: function () {
          return quickBooksTaxMapChecker.checkTaxMaps();
        },
      });
    }
    if (integrationService.isEnabled("xero")) {
      xeroInvoice = make(require("./Invoicers/XeroInvoicer"));
      internalName = "pushInvoice-xero";
      if (
        settings[internalName] == null &&
        ((ref5 = account.integrations.xero) != null
          ? (ref6 = ref5.settings) != null
            ? ref6.defaultAutoPush
            : void 0
          : void 0) != null
      ) {
        settings[internalName] = account.integrations.xero.settings.defaultAutoPush;
      }
      options.push({
        name: "Send invoice to Xero",
        internalName: internalName,
        cssClass: "xero-invoice",
        description: "Have your quote automatically pushed to Xero once accepted.",
        icon: PushToCloud,
        ui: xeroInvoice.ui,
      });
    }
    if (integrationService.isEnabled("pipedrive")) {
      pipedrive = make(require("./Pipedrive/Pipedrive"));
      options.push({
        name: "Pipedrive",
        internalName: "pipedrive-enabled",
        cssClass: "pipedrive-accept",
        description: "Define actions to perform in Pipedrive once your quote is accepted.",
        icon: PushToCloud,
        ui: pipedrive.ui,
      });
    }
    return options;
  };
}.call(this));
