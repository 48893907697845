"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SpecialProjectTag = exports.ProjectLinkPreviewData = exports.ProjectSearchResultStatus = exports.ProjectNavigationWidgetType = void 0;
var Types_1 = require("@CommonFrontendBackend/Project/Types");
Object.defineProperty(exports, "SpecialProjectTag", { enumerable: true, get: function () { return Types_1.SpecialProjectTag; } });
var ProjectNavigationWidgetType;
(function (ProjectNavigationWidgetType) {
    ProjectNavigationWidgetType["Disabled"] = "";
    ProjectNavigationWidgetType["Header"] = "header";
    ProjectNavigationWidgetType["Sidebar"] = "sidebar";
})(ProjectNavigationWidgetType || (exports.ProjectNavigationWidgetType = ProjectNavigationWidgetType = {}));
var ProjectSearchResultStatus;
(function (ProjectSearchResultStatus) {
    ProjectSearchResultStatus["Accepted"] = "accepted";
    ProjectSearchResultStatus["Pending"] = "pending";
    ProjectSearchResultStatus["Live"] = "live";
    ProjectSearchResultStatus["Draft"] = "draft";
    ProjectSearchResultStatus["Archived"] = "archived";
})(ProjectSearchResultStatus || (exports.ProjectSearchResultStatus = ProjectSearchResultStatus = {}));
var ProjectLinkPreviewData;
(function (ProjectLinkPreviewData) {
    ProjectLinkPreviewData["Image"] = "imageUrl";
    ProjectLinkPreviewData["Title"] = "title";
    ProjectLinkPreviewData["Description"] = "description";
})(ProjectLinkPreviewData || (exports.ProjectLinkPreviewData = ProjectLinkPreviewData = {}));
