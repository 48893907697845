"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.insertEmoji = exports.insertHorizontalRule = void 0;
var slate_1 = require("slate");
var Utils_1 = require("../Utils");
var HorizontalRule_1 = require("../WidgetRegistry/Widgets/HorizontalRule");
var Paragraph_1 = require("../WidgetRegistry/Widgets/Paragraph");
var insertHorizontalRule = function (editor) {
    if (!editor.selection) {
        return;
    }
    var startPath = slate_1.Range.start(editor.selection).path;
    var paragraphToChange = (0, Utils_1.getClosestBlock)(editor, startPath);
    if (!paragraphToChange) {
        return;
    }
    slate_1.Transforms.removeNodes(editor, { at: startPath, match: slate_1.Text.isText });
    slate_1.Transforms.setNodes(editor, (0, HorizontalRule_1.createHorizontalRuleNode)(), { at: paragraphToChange[1] });
    var nextText = (0, Utils_1.getNext)(editor, paragraphToChange[1], slate_1.Text.isText);
    if (!nextText) {
        slate_1.Transforms.insertNodes(editor, (0, Paragraph_1.createParagraphNode)(), { at: slate_1.Path.next(paragraphToChange[1]) });
        var newParagraphText = (0, Utils_1.getNext)(editor, paragraphToChange[1], slate_1.Text.isText);
        if (!newParagraphText) {
            return;
        }
        slate_1.Transforms.select(editor, newParagraphText[1]);
        return;
    }
    slate_1.Transforms.select(editor, { path: nextText[1], offset: 0 });
};
exports.insertHorizontalRule = insertHorizontalRule;
var insertEmoji = function (editor, finalLetter, emoji) {
    slate_1.Transforms.insertText(editor, finalLetter);
    slate_1.Transforms.delete(editor, { distance: 2, reverse: true });
    slate_1.Transforms.insertText(editor, emoji);
};
exports.insertEmoji = insertEmoji;
