"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSubtextForSettings = void 0;
function getSubtextForSettings(enabledSettings, textForSetting, textForNone) {
    if (enabledSettings.length === 0) {
        return textForNone;
    }
    if (enabledSettings.length === 1) {
        return textForSetting(enabledSettings[0], false);
    }
    var firstSettingText = textForSetting(enabledSettings[0], true);
    if (enabledSettings.length === 2) {
        return "".concat(firstSettingText, ", ").concat(textForSetting(enabledSettings[1], true));
    }
    return "".concat(firstSettingText, " +").concat(enabledSettings.length - 1, " more");
}
exports.getSubtextForSettings = getSubtextForSettings;
