// Generated by CoffeeScript 1.12.7
(function () {
  var FeatureFlag, LinkViewsLimit;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = LinkViewsLimit = (function () {
    function LinkViewsLimit(createUi, link, project, telemetry, projectSecurer, usingFeature, getDisabledMessage) {
      if (link.viewLimit == null) {
        link.viewLimit = {
          enabled: false,
          limit: 10,
        };
      }
      this.isEnabled = function () {
        return link.viewLimit.enabled;
      };
      this.ui = createUi(require("./ViewsLimit.html"), {
        featureAvailable: usingFeature(FeatureFlag.AdvancedSecurity) || this.isEnabled(),
        getDisabledMessage: getDisabledMessage,
        isLimited: link.viewLimit.enabled,
        enablingViewLimitToggled: (function (_this) {
          return function () {
            return (link.viewLimit.enabled = _this.ui.isLimited);
          };
        })(this),
        viewLimit: link.viewLimit.limit,
        viewLimitChanged: (function (_this) {
          return function () {
            return (link.viewLimit.limit = _this.ui.viewLimit);
          };
        })(this),
      });
    }

    return LinkViewsLimit;
  })();
}.call(this));
