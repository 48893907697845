// Generated by CoffeeScript 1.12.7
(function () {
  var ButtonTemplate, TotalsSettings, TotalsSettingsMenu;

  TotalsSettingsMenu = require("../SettingsMenus/TotalsSettingsMenu");

  ButtonTemplate = require("./TotalsSettingsButton.html");

  /*
  Button to bring up a Tooltip menu to change section settings
   */

  module.exports = TotalsSettings = (function () {
    function TotalsSettings(make, createUi, block, Tooltip, hint) {
      var settingsMenu, tooltip, totalSettingsHint;
      settingsMenu = make(TotalsSettingsMenu);
      tooltip = make(Tooltip, {
        targetSelector: "#" + block.$id + " .quote-total .total-settings",
        options: {
          container: "#" + block.$id + " .project-block",
          customClass: "item-settings-menu-tooltip",
          customTheme: "",
          position: "right",
          contents: settingsMenu.ui,
          spacing: 7,
          closeOnClickAway: true,
        },
      });
      totalSettingsHint = hint.small({
        targetSelector: "#" + block.$id + " .total-section .settings-container .total-settings",
        hint: "total settings",
      });
      tooltip.setOnShow(function () {
        return {};
      });
      this.ui = createUi(ButtonTemplate, {
        onClick: function () {
          totalSettingsHint.hide();
          if (!tooltip.isCurrentlyShowing()) {
            tooltip.show();
          }
        },
        isMenuOpen: function () {
          return tooltip.isCurrentlyShowing();
        },
      });
    }

    return TotalsSettings;
  })();
}.call(this));
