// Generated by CoffeeScript 1.12.7

/*
  Defines the Quote Block UI.
  @param block [Object] - the data model for this block.
 */

(function () {
  var SectionController;

  SectionController = require("./Section/Section");

  module.exports = function (
    block,
    di,
    make,
    invoke,
    Editor,
    createUi,
    viewState,
    mapControllers,
    $timeout,
    utils,
    mobileBreakpoint,
    project,
    blockEvents,
    quoteService,
    toggleDisplayMode,
  ) {
    var accept, discountWidget, display, headerText, headsUpDisplay, total;
    make(require("./BackwardsCompatibility"));
    $timeout(function () {
      return (viewState.displayMode = quoteService.hasItems());
    });
    if (!quoteService.hasSections()) {
      quoteService.addNewSection(0);
    }
    display = make(require("Quote/Display/Display"), {
      MOBILE_BREAKPOINT: mobileBreakpoint,
      projectState: {
        status: project.status,
      },
    });
    total = make(require("./Total/Total"));
    accept = make(require("./Accept/Accept"), {
      settings: block.settings.accept,
    });
    headerText = make(Editor, {
      model: block.contentAreas.header,
      options: {},
      configuration: "standard",
    });
    discountWidget = make(require("./Discount/TotalDiscount"));
    headsUpDisplay = make(require("./HeadsUpDisplay/HeadsUpDisplay"));
    this.ui = createUi(require("./Quote.html"), {
      block: block,
      discountWidget: discountWidget.ui,
      display: display.ui,
      toggleDisplayMode: toggleDisplayMode,
      total: total.ui,
      headerText: headerText.ui,
      accept: accept.ui,
      headsUpDisplay: headsUpDisplay.ui,
      acceptSettings: block.settings.accept,
      acceptButtonText: function () {
        if (block.settings.accept.withCustomButton) {
          return block.settings.accept.text;
        } else {
          return "Accept Quote";
        }
      },
      viewState: viewState,
      $init: function (scope) {
        var createSectionCtrl;
        createSectionCtrl = function (section) {
          section.$id = utils.uniqueId();
          return make(SectionController, {
            section: section,
          });
        };
        return (scope.ui.sections = mapControllers(scope, createSectionCtrl, block.sections));
      },
    });
    return blockEvents.on(
      "deleting",
      (function (_this) {
        return function () {
          return typeof headerText.remove === "function" ? headerText.remove() : void 0;
        };
      })(this),
    );
  };
}.call(this));
