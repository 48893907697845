"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var lodash_1 = require("lodash");
var Types_1 = require("DataStores/Account/Types");
var StylePermissionStore = (function () {
    function StylePermissionStore(account, isAdmin, featureEnabled, advancedCssEnabled) {
        if (featureEnabled === void 0) { featureEnabled = false; }
        if (advancedCssEnabled === void 0) { advancedCssEnabled = false; }
        var _this = this;
        this.stylePermissions = {
            editStyle: true,
            styleAccess: {
                block: true,
                text: true,
                motion: true,
                styleLibrary: true,
                css: true,
                toolbar: true,
                toolbarTable: true,
                toolbarQuote: true,
                toolbarAccept: true,
                accordion: true,
                blockCustomColors: true,
                contentCustomColors: true,
            },
        };
        this.toggleAccess = function (accessKey) {
            if (!_this.stylePermissionEnabled) {
                return;
            }
            _this.stylePermissions.styleAccess[accessKey] = !_this.stylePermissions.styleAccess[accessKey];
        };
        this.revertChanges = function () {
            _this.stylePermissions = __assign({}, _this.account.settings.stylePermissions);
        };
        this.account = account;
        this.isAdmin = isAdmin;
        this.stylePermissionEnabled = featureEnabled;
        this.advancedCssEnabled = advancedCssEnabled;
        if (featureEnabled && !!account.settings.stylePermissions) {
            this.stylePermissions = __assign({}, account.settings.stylePermissions);
        }
    }
    Object.defineProperty(StylePermissionStore.prototype, "styleAccess", {
        get: function () {
            return this.stylePermissions.styleAccess;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StylePermissionStore.prototype, "isStylePermissionEnabled", {
        get: function () {
            return this.stylePermissionEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StylePermissionStore.prototype, "isAdvancedStyleEnabled", {
        get: function () {
            return this.advancedCssEnabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StylePermissionStore.prototype, "allowStyleShortcut", {
        get: function () {
            if (this.shouldAlwaysAllowAccess()) {
                return true;
            }
            return this.isStyleShortcutAccessOn;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StylePermissionStore.prototype, "isStyleShortcutAccessOn", {
        get: function () {
            return (this.isStyleAccessOn(Types_1.StyleAccessType.Block) ||
                this.isStyleAccessOn(Types_1.StyleAccessType.Text) ||
                this.isStyleAccessOn(Types_1.StyleAccessType.Motion) ||
                (this.isAdvancedStyleEnabled && this.isStyleAccessOn(Types_1.StyleAccessType.CSS)));
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(StylePermissionStore.prototype, "hasChanges", {
        get: function () {
            return !(0, lodash_1.isEqual)(this.account.settings.stylePermissions, this.stylePermissions);
        },
        enumerable: false,
        configurable: true
    });
    StylePermissionStore.prototype.allowStyle = function (accessKey) {
        var _this = this;
        return (0, mobx_1.computed)(function () {
            if (_this.shouldAlwaysAllowAccess()) {
                return true;
            }
            return _this.isStyleAccessOn(accessKey);
        }).get();
    };
    StylePermissionStore.prototype.isStyleAccessOn = function (accessKey) {
        var _this = this;
        return (0, mobx_1.computed)(function () {
            return _this.stylePermissions.styleAccess[accessKey];
        }).get();
    };
    StylePermissionStore.prototype.savePermissionUpdate = function () {
        var e_1, _a;
        this.account.settings.stylePermissions.editStyle = this.stylePermissions.editStyle;
        try {
            for (var _b = __values(Object.values(Types_1.StyleAccessType)), _c = _b.next(); !_c.done; _c = _b.next()) {
                var accessType = _c.value;
                this.account.settings.stylePermissions.styleAccess[accessType] = this.stylePermissions.styleAccess[accessType];
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
    };
    StylePermissionStore.prototype.shouldHaveCssEditor = function (hasBlockCss) {
        var _this = this;
        if (hasBlockCss === void 0) { hasBlockCss = false; }
        return (0, mobx_1.computed)(function () {
            var hasCss = _this.advancedCssEnabled || hasBlockCss;
            return hasCss && _this.allowStyle(Types_1.StyleAccessType.CSS);
        }).get();
    };
    StylePermissionStore.prototype.shouldAlwaysAllowAccess = function () {
        return !this.stylePermissionEnabled || this.isAdmin;
    };
    StylePermissionStore.prototype.enablePermissions = function (accessTypes) {
        var e_2, _a;
        if (!this.stylePermissionEnabled) {
            return;
        }
        try {
            for (var accessTypes_1 = __values(accessTypes), accessTypes_1_1 = accessTypes_1.next(); !accessTypes_1_1.done; accessTypes_1_1 = accessTypes_1.next()) {
                var accessType = accessTypes_1_1.value;
                this.stylePermissions.styleAccess[accessType] = true;
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (accessTypes_1_1 && !accessTypes_1_1.done && (_a = accessTypes_1.return)) _a.call(accessTypes_1);
            }
            finally { if (e_2) throw e_2.error; }
        }
    };
    StylePermissionStore.prototype.disablePermissions = function (accessTypes) {
        var e_3, _a;
        if (!this.stylePermissionEnabled) {
            return;
        }
        try {
            for (var accessTypes_2 = __values(accessTypes), accessTypes_2_1 = accessTypes_2.next(); !accessTypes_2_1.done; accessTypes_2_1 = accessTypes_2.next()) {
                var accessType = accessTypes_2_1.value;
                this.stylePermissions.styleAccess[accessType] = false;
            }
        }
        catch (e_3_1) { e_3 = { error: e_3_1 }; }
        finally {
            try {
                if (accessTypes_2_1 && !accessTypes_2_1.done && (_a = accessTypes_2.return)) _a.call(accessTypes_2);
            }
            finally { if (e_3) throw e_3.error; }
        }
    };
    var _a, _b;
    __decorate([
        mobx_1.observable,
        __metadata("design:type", typeof (_a = typeof Types_1.IStylePermission !== "undefined" && Types_1.IStylePermission) === "function" ? _a : Object)
    ], StylePermissionStore.prototype, "stylePermissions", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_b = typeof Types_1.IStyleAccess !== "undefined" && Types_1.IStyleAccess) === "function" ? _b : Object),
        __metadata("design:paramtypes", [])
    ], StylePermissionStore.prototype, "styleAccess", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], StylePermissionStore.prototype, "allowStyleShortcut", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], StylePermissionStore.prototype, "isStyleShortcutAccessOn", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], StylePermissionStore.prototype, "hasChanges", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], StylePermissionStore.prototype, "toggleAccess", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], StylePermissionStore.prototype, "revertChanges", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], StylePermissionStore.prototype, "enablePermissions", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], StylePermissionStore.prototype, "disablePermissions", null);
    return StylePermissionStore;
}());
exports.default = StylePermissionStore;
