/*
  UI for Cloud Source selector.
  Essentially interface sugar to make clear to users
  which services we support for embeddable objects, to
  help alleviate the need for familiarity with "iframes".
 */

(function () {
  var CloudSourceSelector;

  module.exports = CloudSourceSelector = (function () {
    function CloudSourceSelector(createUi, make, viewState, block, imageLocation, keyboard, telemetry) {
      var columns,
        extraItems,
        hasUrlAndSource,
        httpsError,
        i,
        rawUrls,
        ref,
        results,
        sanitizeLink,
        setUrl,
        sources,
        urlParser;
      urlParser = require("url");
      sources = require("./CloudSources").createSourceTypes();
      sanitizeLink = require("@CommonFrontendBackend/Utils/URL").sanitizeLink;
      httpsError =
        'The URL you provided is served over HTTP. Since qwilrs are secured with SSL encryption, we cannot allow any insecure content to be served. See <a href="https://en.wikipedia.org/wiki/HTTPS">this article</a> for more details.';
      setUrl = function (url) {
        block.url = url;
        viewState.displayMode = true;
        return telemetry.trackOnly("Embed Block Embed Code Added");
      };
      hasUrlAndSource = block.url.length && ((ref = block.sourceType) != null ? ref.length : void 0);
      rawUrls = {};
      if (hasUrlAndSource) {
        rawUrls[block.sourceType] = block.url;
      }
      this.ui = createUi(require("Project/Modules/Source/SourceSelector/SourceSelector.html"), {
        sources: sources,
        selectedSource: null,
        error: null,
        rawUrls: rawUrls,
        imageLocation: imageLocation,
        selectSource: (function (_this) {
          return function (source) {
            block.sourceType = source.internal;
            _this.ui.selectedSource = source;
            return (_this.ui.error = "");
          };
        })(this),
        cancelSourceSelect: (function (_this) {
          return function () {
            _this.ui.error = "";
            return (_this.ui.selectedSource = null);
          };
        })(this),
        visible: function () {
          return !viewState.displayMode;
        },
        onKey: (function (_this) {
          return function (e, input, source) {
            if (keyboard.isKey(e, "ENTER")) {
              return _this.ui.updateUrl(input, source);
            }
          };
        })(this),
        toggleSelectedSource: (function (_this) {
          return function () {
            var sourceItem;
            if (!viewState.displayMode && hasUrlAndSource) {
              sourceItem = _.find(sources, {
                internal: block.sourceType,
              });
              if (sourceItem != null) {
                return _this.ui.selectSource(sourceItem);
              }
            }
          };
        })(this),
        updateUrl: (function (_this) {
          return function (input, source) {
            var parsedUrl, rawUrl, url;
            rawUrl = source.getUrl(input);
            telemetry.trackOnly("Embed source selected", {
              embedSource: source.internal,
            });
            if (!rawUrl) {
              return (_this.ui.error =
                "Sorry, that doesn't seem to be a valid embed code for this source. Double check the embed code and try again.");
            } else {
              if (source.internal === "iframeEmbed") {
                telemetry.trackOnly("Embed iframe updated", {
                  url: sanitizeLink(rawUrl),
                  source: "block",
                });
              }
              _this.ui.error = "";
              rawUrl = rawUrl.replace(/['"]+/g, "").replace(/['“]+/g, "");
              parsedUrl = urlParser.parse(rawUrl);
              if (parsedUrl.protocol == null) {
                parsedUrl.protocol = "https";
                parsedUrl.slashes = true;
                url = urlParser.format(parsedUrl);
                return setUrl(url);
              } else {
                if (parsedUrl.protocol !== "https" && parsedUrl.protocol !== "https:") {
                  return (_this.ui.error = httpsError);
                } else {
                  url = urlParser.format(parsedUrl);
                  return setUrl(url);
                }
              }
            }
          };
        })(this),
      });
      columns = 3;
      extraItems = columns - (sources.length % columns);
      if (extraItems > 0 && extraItems !== columns) {
        this.ui.phantomItems = function () {
          results = [];
          for (var i = 1; 1 <= extraItems ? i <= extraItems : i >= extraItems; 1 <= extraItems ? i++ : i--) {
            results.push(i);
          }
          return results;
        }.apply(this);
      } else {
        this.ui.phantomItems = [];
      }
      this.ui.toggleSelectedSource();
    }

    return CloudSourceSelector;
  })();
}.call(this));
