// Generated by CoffeeScript 1.12.7
(function () {
  var FeatureFlag, NotReadyForMobile, reaction;

  reaction = require("mobx").reaction;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  /*
    This module is responsible for displaying "Not Ready For Mobile"
    messages to the user.
   */

  module.exports = NotReadyForMobile = (function () {
    function NotReadyForMobile(
      createUi,
      publicApiBaseUrl,
      appEvents,
      projectCounter,
      isMobile,
      appViewState,
      onboardingStore,
      usingFeature,
    ) {
      var initUi, shouldShow;
      this.ui = null;
      initUi = (function (_this) {
        return function () {
          if (_this.ui != null) {
            return;
          }
          return (_this.ui = createUi(require("./NotReadyForMobile.html"), {
            logoutUrl: publicApiBaseUrl + "/logout",
            viewState: appViewState,
          }));
        };
      })(this);
      shouldShow = function () {
        var show;
        show = isMobile() && !projectCounter.hasProjects() && !onboardingStore.shouldShowCensusCollector;
        appViewState.showNotReadyForMobile = show;
        if (show) {
          return initUi();
        }
      };
      shouldShow();
      appEvents.on("changedFormFactor", shouldShow);
      reaction(function () {
        return onboardingStore.shouldShowCensusCollector;
      }, shouldShow);
    }

    return NotReadyForMobile;
  })();
}.call(this));
