"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTimelineSortButton = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var styles = __importStar(require("./SortButton.css"));
var sortOptions = [
    {
        icon: react_1.default.createElement(icons_1.SortDescending, null),
        description: "Most recent",
        sortFn: function (arr) {
            return arr;
        },
    },
    {
        icon: react_1.default.createElement(icons_1.SortAscending, null),
        description: "Earliest",
        sortFn: function (arr) {
            return __spreadArray([], __read(arr), false).reverse();
        },
    },
];
function useTimelineSortButton(timelineData) {
    var _a = __read((0, react_1.useState)(0), 2), sortOptionIndex = _a[0], setSortOptionIndex = _a[1];
    var sortOption = sortOptions[sortOptionIndex];
    var hasTimelineData = timelineData && timelineData.length > 0;
    var sortedData = hasTimelineData ? sortOption.sortFn(timelineData) : timelineData;
    return {
        sortedData: sortedData,
        sortButton: (react_1.default.createElement(kaleidoscope_1.Button, { className: styles.sortButton, onClick: function () { return setSortOptionIndex(sortOptionIndex === 0 ? 1 : 0); }, type: kaleidoscope_1.ButtonType.Tertiary, size: kaleidoscope_1.ButtonSize.Small, disabled: !hasTimelineData },
            sortOption.icon,
            sortOption.description)),
    };
}
exports.useTimelineSortButton = useTimelineSortButton;
