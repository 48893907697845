// Generated by CoffeeScript 1.12.7
(function () {
  var CreditMessage, MediaCarousel, MediaSource, MetricSource, UnsplashErrorTemplate;

  CreditMessage = require("./CreditMessage.html");

  UnsplashErrorTemplate = require("../MediaBrowser/UnsplashError.html");

  MediaSource = require("@CommonFrontendBackend/AssetLibrary/Types").MediaSource;

  MetricSource = require("@CommonFrontendBackend/AssetLibrary/Types").MetricSource;

  /*
    A MediaCarousel shows image content for a Splash Block
  
    @class MediaCarousel
   */

  module.exports = MediaCarousel = (function () {
    function MediaCarousel(
      block,
      createUi,
      make,
      $q,
      imageService,
      splashBlock,
      parentViewState,
      mediaBrowser,
      mediaUsageReporter,
      $timeout,
    ) {
      var buttonsLeft,
        buttonsRight,
        createImageBuffer,
        createUnsplashCreditMessage,
        elementRenderedHook,
        getSearchResults,
        nextPageAfterHook,
        onMediaBrowserSelect,
        onSelect,
        refreshButton,
        refreshCollection,
        reportUsage,
        searchButton,
        setResourceUrl,
        unsplashErrorUi,
        uploadButton,
        viewAllButton;
      this.imageService = imageService;
      this.mediaBrowser = mediaBrowser;
      this.q = $q;
      if (splashBlock == null) {
        return this.q.reject();
      }
      this.backgroundSetter = make(require("../../Project/Modules/Splash/Controls/BackgroundSetter"), {
        onAddedContent: function () {},
      });
      this.currentCollection = null;
      this.imageBuffer = null;
      this.viewState = {
        searchEnabled: false,
        searchQuery: "",
        carouselRefreshButton: true,
        viewAllButton: true,
        refreshingCollection: false,
      };
      this.message = make(require("./CarouselMessage"));

      /*
        Reports an image to the recently-used cache, and tells Unsplash that a user has used the image
       */
      reportUsage = (function (_this) {
        return function () {
          if (_this.recentlyUsedObject != null) {
            return mediaUsageReporter.reportUsage(_this.recentlyUsedObject.mediaSource, _this.recentlyUsedObject.data);
          }
        };
      })(this);

      /*
        @param url [String] - the URL of the image to be stored in the block
        @param recentlyUsedObject [Object] - store this object to be added to recently used
                                             when the carousel is closed
        @param $event [Object] - the JS event object
        @param source [String] - where this request is coming from, for passing to the
                                 backgroundSetter
       */
      setResourceUrl = (function (_this) {
        return function (url, recentlyUsedObject, $event, source) {
          if (recentlyUsedObject == null) {
            recentlyUsedObject = null;
          }
          if ($event == null) {
            $event = null;
          }
          if (source == null) {
            source = "unknown";
          }
          _this.backgroundSetter.setImage(url, source);
          return (_this.recentlyUsedObject = recentlyUsedObject);
        };
      })(this);

      /*
        Refreshes the collection in the carousel
      
        @param collectionId [String] - (optional) the collection identifier to show, if this is not
               specified, a random collection will be shown
       */
      refreshCollection = (function (_this) {
        return function (collectionId) {
          if (_this.viewState.refreshingCollection) {
            return;
          }
          _this.viewState.refreshingCollection = true;
          _this.carousel.ui.clear();
          return _this.q.resolve().then(function () {
            _this.carousel.ui.viewState.showPosition = true;
            _this.imageBuffer.cacheCollection(collectionId);
            return $timeout(function () {
              return (_this.viewState.refreshingCollection = false);
            }, 2000);
          });
        };
      })(this);

      /*
        Called when the user has searched for something
      
        @param queryText [String] - the query that the user has searched for
       */
      getSearchResults = (function (_this) {
        return function (queryText) {
          if (queryText == null) {
            return;
          }
          _this.viewState.searchQuery = queryText;
          return _this.imageBuffer.cacheQuery(queryText);
        };
      })(this);

      /*
        Creates a ui object for the Unsplash credit message
      
        @param data [Object] Our internal photo object
          userName [String] - the image owner's name
          unsplashProfile [String] - the URL of the photographer's Unsplash profile page
          userPortfolio [String] - the URL to the photographer's portfolio (optional)
            Many Unsplash contributors don't have a portfolio link
       */
      createUnsplashCreditMessage = (function (_this) {
        return function (data) {
          var profileLink, ui;
          if (data.userName != null) {
            profileLink = data.unsplashProfile + "?utm_source=qwilr&utm_medium=referral";
            ui = createUi(CreditMessage, {
              portfolio: data.userPortfolio,
              imageOwner: data.userName,
              unsplashProfile: profileLink,
            });
            return ui;
          }
        };
      })(this);

      /*
        Run when the Carousel is rendered
       */
      elementRenderedHook = (function (_this) {
        return function (elem) {
          if (_this.currentCollection == null) {
            refreshCollection();
          }
          $(window).on("beforeunload", function () {
            reportUsage();
            return void 0;
          });
          return elem.on("$destroy", reportUsage);
        };
      })(this);

      /*
        Hook for when a Carousel item is selected
       */
      onSelect = (function (_this) {
        return function (item, index, isFirst, prev, next, $event) {
          var metricSource, msg, recentlyUsedObject, ref, ref1;
          if (item == null || !((ref = item.data) != null ? ref.urls : void 0)) {
            return;
          }
          recentlyUsedObject = {
            mediaSource: MediaSource.Unsplash,
            data: item.data,
          };
          if (((ref1 = _this.viewState.searchQuery) != null ? ref1.length : void 0) > 0) {
            metricSource = MetricSource.SearchCarousel;
          } else {
            metricSource = MetricSource.Carousel;
          }
          setResourceUrl(item.data.urls.regular, recentlyUsedObject, $event, metricSource);
          msg = createUnsplashCreditMessage(item.data);
          return _this.message.setMessage(msg, "left");
        };
      })(this);
      onMediaBrowserSelect = (function (_this) {
        return function (response) {
          var collectionId, mediaSource, metricSource, msg;
          if (response != null) {
            mediaSource = response.mediaSource;
            metricSource = response.metricSource;
            collectionId = response.data.collectionId;
            if (collectionId != null) {
              refreshCollection(collectionId);
            }
            if (mediaSource === MediaSource.Unsplash) {
              msg = createUnsplashCreditMessage(response.data);
              _this.message.setMessage(msg, "left");
            } else {
              _this.message.setMessage(null, "left");
            }
            if (mediaSource !== MediaSource.UnsplashCollection) {
              setResourceUrl(null);
              return _this.imageService
                .completeUrl(response)
                .then(function () {
                  return setResourceUrl(response.data.url, null, null, metricSource);
                })
                ["catch"](function (err) {});
            }
          }
        };
      })(this);

      /*
        Hook for when the next page is triggered in the UI
        We use this opportunity to buffer the next page
       */
      nextPageAfterHook = (function (_this) {
        return function () {
          var collectionId, nextPage, options, query;
          if (_this.carousel.ui.pages[_this.carousel.ui.pageIndex + 2] == null) {
            nextPage = _this.carousel.ui.pageIndex + 2;
            collectionId = _this.currentCollection.collectionId;
            query = null;
            if (_this.viewState.searchEnabled && !(_this.viewState.searchQuery === "")) {
              collectionId = null;
              query = _this.viewState.searchQuery;
            }
            options = {
              active: true,
              isFirst: false,
              isNext: false,
              isPrev: false,
              isAfterNext: true,
            };
            return _this.imageBuffer.getAndAddPage(collectionId, query, nextPage, options);
          } else {
            return $q.resolve();
          }
        };
      })(this);

      /*
        Returns the Carousel instance
      
        @api public
       */
      this.getCarousel = (function (_this) {
        return function () {
          return _this.carousel.ui;
        };
      })(this);

      /*
        Sets a message in the Carousel
      
        @param dom [string] - a string representing some HTML that is to be injected in the message
        @param position [string] - enum, "left", "right" or "center" for where the string should be
                                positioned
        @api public
       */
      this.setMessage = (function (_this) {
        return function (msg, location) {
          return _this.message.setMessage(msg, location);
        };
      })(this);

      /*
        Clears the message on the Carousel
        @param position [string] - optionally we can clear a select area. If this is not passed in,
                                we clear everything
        @api public
       */
      this.clearMessage = (function (_this) {
        return function (location) {
          return _this.message.clear(location);
        };
      })(this);
      buttonsLeft = [];
      buttonsRight = [];
      searchButton = make(require("Common/AtomicUI/SearchButton/SearchButton"), {
        placeholder: "Search photos",
        getSearchResults: getSearchResults,
        searchQuery: this.viewState.searchQuery,
        onOpen: (function (_this) {
          return function () {
            _this.viewState.searchEnabled = true;
            _this.viewState.carouselRefreshButton = false;
            return (_this.viewState.viewAllButton = false);
          };
        })(this),
        onClose: (function (_this) {
          return function () {
            _this.viewState.searchEnabled = false;
            _this.viewState.carouselRefreshButton = true;
            return (_this.viewState.viewAllButton = true);
          };
        })(this),
      });
      viewAllButton = make(require("./CarouselButton/CarouselButton"), {
        name: "viewAllButton",
        text: "View All",
        icon: "Quad16",
        shouldShow: (function (_this) {
          return function () {
            return _this.viewState.viewAllButton;
          };
        })(this),
        onClick: (function (_this) {
          return function () {
            return _this.mediaBrowser
              .show("imageCollections", {
                carousel: true,
              })
              .then(function (response) {
                return onMediaBrowserSelect(response);
              });
          };
        })(this),
      });
      refreshButton = make(require("./CarouselButton/CarouselButton"), {
        name: "refreshButton",
        text: "Shuffle",
        icon: "Refresh16",
        shouldShow: (function (_this) {
          return function () {
            return _this.viewState.carouselRefreshButton;
          };
        })(this),
        onClick: refreshCollection,
      });
      buttonsLeft.push(searchButton);
      buttonsLeft.push(viewAllButton);
      buttonsLeft.push(refreshButton);
      uploadButton = make(require("Common/AtomicUI/FileUploader/FileUploader"), {
        file: null,
        onUploaded: (function (_this) {
          return function (imageUrl) {
            setResourceUrl(imageUrl, null, null, MetricSource.UploadCarousel);
            return _this.message.setMessage(null, "left");
          };
        })(this),
      });
      buttonsRight.push(uploadButton);
      unsplashErrorUi = createUi(UnsplashErrorTemplate, {
        onClick: (function (_this) {
          return function () {
            return _this.mediaBrowser
              .show("stockImages", {
                showStockImages: true,
              })
              .then(function (response) {
                return onMediaBrowserSelect(response);
              });
          };
        })(this),
      });
      this.carousel = make(require("./Carousel"), {
        options: {
          name: "Media",
          nextPageAfterHook: nextPageAfterHook,
          onSelect: onSelect,
          elementRenderedHook: elementRenderedHook,
          tileFactory: make(require("./Factories/MediaTileFactory")),
          buttonsLeft: buttonsLeft,
          buttonsRight: buttonsRight,
          message: this.message,
          customClass: "media-carousel",
          errorUi: unsplashErrorUi,
        },
      });

      /*
        Creates a new image cache that is used to interface with the ImageService
       */
      createImageBuffer = (function (_this) {
        return function () {
          return (_this.imageBuffer = make(require("./CarouselUtil/ImagePageBuffer"), {
            mediaCarousel: _this,
          }));
        };
      })(this);
      createImageBuffer();
    }

    return MediaCarousel;
  })();
}.call(this));
