// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (invoke, blockDisplaySettings) {
    return function (block) {
      var iconName;
      if (block.moduleName != null) {
        iconName = block.moduleName;
      } else if (block.__t != null) {
        iconName = block.__t;
      } else if (block.type != null) {
        if (block.type === "content") {
          iconName = "text";
        } else {
          iconName = block.type;
        }
      } else {
        iconName = "text";
      }
      return blockDisplaySettings.getIcon(iconName);
    };
  };
}.call(this));
