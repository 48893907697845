// Generated by CoffeeScript 1.12.7
(function () {
  var HotjarIntegration;

  module.exports = HotjarIntegration = (function () {
    function HotjarIntegration(createUi, genericIntegration) {
      genericIntegration.build.call(this, "hotjar", "Hotjar App ID", require("./Hotjar.html"));
    }

    return HotjarIntegration;
  })();
}.call(this));
