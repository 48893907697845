"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AgreementBlockSettings = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var styles = __importStar(require("./AgreementBlockSettings.css"));
var AgreementButton_1 = require("Project/Modules/Accept/Controls/Agreement/AgreementButton");
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var mobx_react_1 = require("mobx-react");
exports.AgreementBlockSettings = (0, mobx_react_1.observer)(function (_a) {
    var store = _a.store, acceptBlock = _a.acceptBlock, notifyUser = _a.notifyUser, blockCreator = _a.blockCreator, deleteBlock = _a.deleteBlock, agreementBlock = _a.agreementBlock;
    var _b = __read((0, react_1.useState)("options"), 2), screen = _b[0], setScreen = _b[1];
    var agreementBlockRef = (0, react_1.useRef)(null);
    return (react_1.default.createElement(kaleidoscope_1.Popover, { autoStack: true, size: kaleidoscope_1.PopoverSize.Small, padding: "none", button: function (buttonProps) { return (react_1.default.createElement(kaleidoscope_1.IconButton, __assign({}, buttonProps, { tooltip: { content: "Agreement settings" }, icon: react_1.default.createElement(icons_1.Settings, null), isIconOnly: true }))); } },
        screen === "options" && (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(kaleidoscope_1.Stack, { paddingTop: "s", paddingBottom: "xs", paddingRight: "m", paddingLeft: "m", gap: "xs" },
                react_1.default.createElement(kaleidoscope_1.TextInput, { label: "Button text", value: store.formButtonText, onChange: function (value) {
                        store.formButtonText = value;
                    } }),
                react_1.default.createElement(kaleidoscope_1.Toggle, { label: "Allow agreement download", value: store.showDownloadButton, onChange: function (value) {
                        store.showDownloadButton = value;
                    } }),
                react_1.default.createElement(kaleidoscope_1.Toggle, { label: "Headings nav menu", value: store.showNavigation, onChange: function (value) {
                        store.showNavigation = value;
                    } })),
            react_1.default.createElement("div", { className: styles.separator, role: "separator", "aria-orientation": "horizontal" }),
            react_1.default.createElement(kaleidoscope_1.Stack, { padding: "xxs" },
                react_1.default.createElement(kaleidoscope_1.Button, { isDestructive: true, type: kaleidoscope_1.ButtonType.Tertiary, onClick: function () { return setScreen("delete"); }, className: styles.deleteButton },
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "xs" },
                        react_1.default.createElement(icons_1.Delete, { size: icons_1.IconSize.Medium }),
                        react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "s" }, "Delete agreement")))))),
        screen === "delete" && (react_1.default.createElement(AgreementButton_1.AgreementConfirmDelete, { onConfirm: function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            agreementBlockRef.current = agreementBlock;
                            return [4, deleteBlock({ type: BlockTypes_1.BlockType.Agreement, _id: agreementBlock._id })];
                        case 1:
                            _a.sent();
                            acceptBlock.agreement = null;
                            notifyUser.success(react_1.default.createElement(react_1.default.Fragment, null,
                                "Agreement deleted",
                                " ",
                                react_1.default.createElement(kaleidoscope_1.Button, { key: "delete-agreement-notification", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        var clonedBlockId;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    if (!agreementBlockRef.current) return [3, 2];
                                                    return [4, blockCreator.cloneBlock(agreementBlockRef.current)];
                                                case 1:
                                                    clonedBlockId = _a.sent();
                                                    acceptBlock.agreement = clonedBlockId;
                                                    agreementBlockRef.current = null;
                                                    _a.label = 2;
                                                case 2: return [2];
                                            }
                                        });
                                    }); } }, "Undo")));
                            return [2];
                    }
                });
            }); }, onCancel: function () {
                setScreen("options");
            } }))));
});
