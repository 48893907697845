"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
var AngularSyncStore_1 = __importStar(require("@CommonFrontend/Utils/AngularSyncStore"));
var inversify_1 = require("inversify");
var mobx_1 = require("mobx");
var zod_1 = require("zod");
var acceptBlockSchema = zod_1.z.object({
    agreement: zod_1.z.string().optional().nullable(),
    savedAgreement: zod_1.z.string().optional().nullable(),
});
var AcceptBlockStore = (function (_super) {
    __extends(AcceptBlockStore, _super);
    function AcceptBlockStore(angularData, syncServices) {
        var _this = _super.call(this, angularData, syncServices, {
            extractDataFromAngular: function (block) {
                return acceptBlockSchema.parse(block);
            },
            skipDiffOnAngularChange: true,
        }) || this;
        _this.onAngularUpdated = function (newData) {
            _this.acceptBlockData = newData;
        };
        _this.onAngularUpdated(angularData);
        return _this;
    }
    Object.defineProperty(AcceptBlockStore.prototype, "agreementId", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.acceptBlockData.agreement) !== null && _a !== void 0 ? _a : this.acceptBlockData.savedAgreement) !== null && _b !== void 0 ? _b : null;
        },
        set: function (id) {
            this.acceptBlockData.agreement = id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AcceptBlockStore.prototype, "savedAgreement", {
        set: function (id) {
            this.acceptBlockData.savedAgreement = id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AcceptBlockStore.prototype, "dataForAngular", {
        get: function () {
            return { agreement: this.acceptBlockData.agreement, savedAgreement: this.acceptBlockData.savedAgreement };
        },
        enumerable: false,
        configurable: true
    });
    var _a, _b, _c;
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", typeof (_c = typeof AcceptBlockStoreType !== "undefined" && AcceptBlockStoreType) === "function" ? _c : Object)
    ], AcceptBlockStore.prototype, "acceptBlockData", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [Object])
    ], AcceptBlockStore.prototype, "agreementId", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], AcceptBlockStore.prototype, "onAngularUpdated", void 0);
    AcceptBlockStore = __decorate([
        (0, inversify_1.injectable)(),
        __param(0, (0, inversify_1.inject)("block")),
        __param(1, (0, inversify_1.inject)(AngularSyncStore_1.AngularSyncServices.$)),
        __metadata("design:paramtypes", [typeof (_a = typeof AcceptBlockStoreType !== "undefined" && AcceptBlockStoreType) === "function" ? _a : Object, typeof (_b = typeof AngularSyncStore_1.AngularSyncServices !== "undefined" && AngularSyncStore_1.AngularSyncServices) === "function" ? _b : Object])
    ], AcceptBlockStore);
    return AcceptBlockStore;
}(AngularSyncStore_1.default));
exports.default = AcceptBlockStore;
