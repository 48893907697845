// Generated by CoffeeScript 1.12.7

/*
  This module displays a pretty box allowing the user to select a grid size
  for use in other tools such as tables.
 */

(function () {
  var GridPicker;

  module.exports = GridPicker = (function () {
    function GridPicker(createUi, make, onSelectedCallback) {
      var inSelectionArea, range, selectedCols, selectedRows, tableCols, tableRows;
      tableRows = 5;
      tableCols = 5;
      selectedRows = 0;
      selectedCols = 0;
      inSelectionArea = false;
      range = function (num) {
        var i, results;
        return function () {
          results = [];
          for (var i = 0; 0 <= num ? i < num : i > num; 0 <= num ? i++ : i--) {
            results.push(i);
          }
          return results;
        }.apply(this);
      };
      this.ui = createUi(require("Common/GridPicker/GridPicker.html"), {
        select: (function (_this) {
          return function () {
            return onSelectedCallback({
              rows: selectedRows,
              cols: selectedCols,
            });
          };
        })(this),
        getRows: (function (_this) {
          return function () {
            return range(tableRows);
          };
        })(this),
        getCols: (function (_this) {
          return function () {
            return range(tableCols);
          };
        })(this),
        isSelected: (function (_this) {
          return function (r, c) {
            return r < selectedRows && c < selectedCols && inSelectionArea;
          };
        })(this),
        leaveSelection: function () {
          return (inSelectionArea = false);
        },
        showSelectionInfo: (function (_this) {
          return function () {
            return inSelectionArea;
          };
        })(this),
        setSelectedCell: (function (_this) {
          return function (r, c) {
            inSelectionArea = true;
            selectedRows = r + 1;
            return (selectedCols = c + 1);
          };
        })(this),
        getSelectedRows: (function (_this) {
          return function () {
            return selectedRows;
          };
        })(this),
        getSelectedCols: (function (_this) {
          return function () {
            return selectedCols;
          };
        })(this),
      });
    }

    return GridPicker;
  })();
}.call(this));
