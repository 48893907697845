"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DefaultSchema_1 = require("./DefaultSchema");
var QuoteSchema_1 = require("./QuoteSchema");
var QuoteLineItemSchema_1 = require("./QuoteLineItemSchema");
var QuotePlanCardSchema_1 = require("./QuotePlanCardSchema");
var QuotePlanCardFeatureListSchema_1 = require("./QuotePlanCardFeatureListSchema");
var AgreementSchema_1 = require("./AgreementSchema");
var schemas = new Map([
    ["standard", DefaultSchema_1.schema],
    ["quote", QuoteSchema_1.schema],
    ["quoteLineItem", QuoteLineItemSchema_1.schema],
    ["quotePlanCard", QuotePlanCardSchema_1.schema],
    ["quotePlanCardFeatureList", QuotePlanCardFeatureListSchema_1.schema],
    ["agreement", AgreementSchema_1.schema],
]);
var getSchema = function (name) {
    if (schemas.has(name)) {
        return schemas.get(name);
    }
    else {
        throw new Error("Unknown schema: ".concat(name));
    }
};
exports.default = getSchema;
