"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var slate_1 = require("slate");
var CursorValidatorUtils_1 = require("./CursorValidatorUtils");
var BehaviourBuilder_1 = require("../Behaviours/BehaviourBuilder");
var TextCursorValidator = {
    isIllegalCursor: function (editor) { return slate_1.Range.isCollapsed(editor.selection) && (0, CursorValidatorUtils_1.isAtStartOfInline)(editor); },
    makeCursorValid: function (editor, lastKeyPress) {
        switch (lastKeyPress) {
            case BehaviourBuilder_1.KeyType.ArrowUp:
            case BehaviourBuilder_1.KeyType.ArrowLeft:
                return (0, CursorValidatorUtils_1.moveUpwardsUntilValid)(editor, TextCursorValidator.isIllegalCursor);
            case BehaviourBuilder_1.KeyType.ArrowDown:
            case BehaviourBuilder_1.KeyType.ArrowRight:
                return (0, CursorValidatorUtils_1.moveDownwardsUntilValid)(editor, TextCursorValidator.isIllegalCursor);
            default:
                return (0, CursorValidatorUtils_1.moveDownwardsUntilValid)(editor, TextCursorValidator.isIllegalCursor);
        }
    },
};
exports.default = TextCursorValidator;
