// Generated by CoffeeScript 1.12.7

/*
  Compoment for infinite scrolling through paginated media

  @NOTE (som 2017-08-04): You can think of this as a base class for any view factory that uses
  infinite scroller (i.e ImageCollectionsFactory is an InfiniteScroller)

  Expects @viewFactory to implement:
  .fetchFromService - paginated media fetching
  .createTileData - factory function for creating media tiles
  .tileData - array of tiles that we can directly modify
  .createHeader - (optional) a ui to use at the top of the page
  .getCSSClass - (optional) any extra ng-class to set
 */

(function () {
  var GENERIC_ERROR_MSG, InfiniteScroller;

  GENERIC_ERROR_MSG = "Something went wrong :( Please try again in a few minutes";

  module.exports = InfiniteScroller = (function () {
    function InfiniteScroller(invoke, createUi, $q, viewFactory) {
      var fetch;
      this.viewFactory = viewFactory;
      this.ui = createUi(require("./InfiniteScroller.html"), {
        headerUi: null,
        tileData: null,
        currentClass: null,
        reset: (function (_this) {
          return function () {
            _this.ui.currentPage = -1;
            _this.ui.itemsPerPage = 10;
            _this.ui.fetchingPage = false;
            _this.ui.outOfTiles = false;
            _this.ui.tileData = null;
            _this.ui.error = false;
            return (_this.ui.errorMessage = GENERIC_ERROR_MSG);
          };
        })(this),
        getFetchingState: (function (_this) {
          return function () {
            if (_this.ui.error) {
              return "error";
            } else if (_this.ui.outOfTiles) {
              return "outOfTiles";
            } else if (_this.ui.fetchingPage) {
              return "fetching";
            }
            return "";
          };
        })(this),

        /*
          Load more tiles into the infinite scroller
          @return [Promise] that resolves when the new tiles have been added to the array
         */
        showMore: (function (_this) {
          return function () {
            if (!_this.ui.fetchingPage && !_this.ui.outOfTiles) {
              _this.ui.fetchingPage = true;
              ++_this.ui.currentPage;
              return fetch(_this.ui.currentPage)
                .then(function (arg) {
                  var outOfTiles, tileData;
                  (tileData = arg.tileData), (outOfTiles = arg.outOfTiles);
                  _this.ui.fetchingPage = false;
                  if (outOfTiles) {
                    _this.ui.outOfTiles = true;
                  } else {
                    _this.ui.tileData = tileData;
                  }
                  return $q.resolve();
                })
                ["catch"](function (err) {
                  return (_this.ui.error = true);
                });
            } else {
              return $q.resolve();
            }
          };
        })(this),
      });
      this.setErrorUi = (function (_this) {
        return function (errorUi) {
          return (_this.ui.errorUi = errorUi);
        };
      })(this);
      this.setup = (function (_this) {
        return function () {
          var base, base1;
          _this.ui.headerUi =
            typeof (base = _this.viewFactory).createHeader === "function" ? base.createHeader() : void 0;
          _this.ui.currentClass =
            typeof (base1 = _this.viewFactory).getCSSClass === "function" ? base1.getCSSClass() : void 0;
          _this.ui.reset();
          _this.ui.showMore().then(function () {
            var base2;
            return (_this.ui.headerUi =
              typeof (base2 = _this.viewFactory).createHeader === "function" ? base2.createHeader() : void 0);
          });
          return _this.ui;
        };
      })(this);

      /*
        Fetch the data for the next page and create the tiles for it
        @return [Promise] that resolves with:
          - `tileData` - all the tiles that the factory wants to show
          - `outOfTiles` - whether there are no more tiles to fetch
       */
      fetch = (function (_this) {
        return function (page) {
          return _this.viewFactory.fetchFromService(page).then(function (newTileData) {
            var i, len, newTile, tileData;
            for (i = 0, len = newTileData.length; i < len; i++) {
              tileData = newTileData[i];
              newTile = _this.viewFactory.createTileData(tileData);
              _this.viewFactory.tileData.push(newTile);
            }
            return {
              tileData: _this.viewFactory.tileData,
              outOfTiles: newTileData.length === 0,
            };
          });
        };
      })(this);
    }

    return InfiniteScroller;
  })();
}.call(this));
