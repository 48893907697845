// Generated by CoffeeScript 1.12.7

/*
  Transforms data items into MediaTile UI components, that can be placed into a Page in a Carousel

  @class MediaTileFactory
 */

(function () {
  var MediaTileFactory,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = MediaTileFactory = (function () {
    function MediaTileFactory(createUi) {
      this.createUi = createUi;
      this.updateTiles = bind(this.updateTiles, this);
      this.createTiles = bind(this.createTiles, this);
      this.createTile = bind(this.createTile, this);
    }

    /*
      Creates an individual tile by mapping a data object to a Tile UI component
    
      @param item [Object] - the data object to be transformed
     */

    MediaTileFactory.prototype.createTile = function (item) {
      var placeholder, ui;
      ui = this.createUi(require("./MediaTile.html"), {
        urls: item.urls || null,
        color: item.color || null,
      });
      placeholder = item.placeholder || false;
      return {
        ui: ui,
        data: item,
        placeholder: placeholder,
      };
    };

    /*
      Creates a set of Tile UI objects for a given list of data objects
    
      @param items [Array] - a list of data objects to transform
     */

    MediaTileFactory.prototype.createTiles = function (items) {
      return items.map(this.createTile);
    };

    /*
      Updates an existing page with new data
    
      @param page [Object] - the tile page to update
      @param data [Array] - a list of new data objects
     */

    MediaTileFactory.prototype.updateTiles = function (page, data) {
      var i, index, len, ref, tile;
      ref = page.tiles;
      for (index = i = 0, len = ref.length; i < len; index = ++i) {
        tile = ref[index];
        tile.ui.urls = data[index].urls;
        tile.ui.color = data[index].color;
        tile.data = data[index];
        tile.placeholder = false;
      }
      return page;
    };

    return MediaTileFactory;
  })();
}.call(this));
