"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImagePickerResultType = void 0;
var Types_1 = require("@CommonFrontendBackend/AssetLibrary/Types");
exports.default = (function (mediaBrowser, imageService) {
    return function () {
        return mediaBrowser.show("imageCollections", { earlyExit: true }).then(function (mediaObject) {
            if (mediaObject.mediaSource === Types_1.MediaSource.FastUpload) {
                return {
                    uploadId: mediaObject.data.uploadId,
                    type: ImagePickerResultType.FastUpload,
                    metricSource: Types_1.MetricSource.UploadGallery,
                    altDescription: mediaObject.data.altDescription,
                };
            }
            else {
                return imageService.completeUrl(mediaObject).then(function () {
                    return {
                        url: mediaObject.data.url,
                        metricSource: mediaObject.metricSource,
                        type: ImagePickerResultType.Standard,
                        altDescription: mediaObject.data.altDescription,
                    };
                });
            }
        });
    };
});
var ImagePickerResultType;
(function (ImagePickerResultType) {
    ImagePickerResultType["Standard"] = "standard";
    ImagePickerResultType["FastUpload"] = "fastUpload";
})(ImagePickerResultType || (exports.ImagePickerResultType = ImagePickerResultType = {}));
