"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MountImagePreview_1 = require("./MountImagePreview");
exports.default = (function (controller, createUi, EditableHeading, linkPreviewSettingsStore, di, $timeout) {
    var canEditProjectName = controller.canEditProjectName;
    var heading = canEditProjectName ? "Edit Title" : "Title";
    var onProjectNameChange = function (updatedName) {
        controller.onProjectNameChange();
        return controller.project.updateName(updatedName);
    };
    var editableHeadingComponent = new EditableHeading("Enter your title", canEditProjectName, onProjectNameChange, controller.project.name, heading, true);
    var ui = createUi(require("./ShareUI.html"), {
        editableHeading: editableHeadingComponent,
        selected: "",
        disableChangingLinkSettings: function () {
            return controller.project.status === "accepted" || controller.isSharingDisabled();
        },
        deselect: function () {
            return (ui.selected = "");
        },
        selectedNothing: function () {
            return ui.selected === "";
        },
        isSelected: function (itemName) {
            return ui.selected === itemName;
        },
        selectSecurity: function () {
            if (ui.disableChangingLinkSettings()) {
                return;
            }
            if (controller.canAccessSecuritySettings()) {
                return (ui.selected = "security");
            }
            else {
                return controller.showSecurityInaccessible();
            }
        },
        selectSharing: function () {
            if (ui.disableChangingLinkSettings()) {
                return;
            }
            return (ui.selected = "sharing");
        },
        selectLinkPreview: function () {
            if (ui.disableChangingLinkSettings()) {
                return;
            }
            return $timeout(function () {
                ui.selected = "linkPreview";
            });
        },
        close: controller.close,
        canEditProjectName: canEditProjectName,
        canEditSettings: controller.canEditSettings,
        externalClass: controller.externalClass,
        promote: controller.promoteUi,
        securitySettings: controller.securitySettingsUi,
        sharingSettings: controller.sharingSettingsUi,
        linkPreviewSettings: controller.linkPreviewSettingsUi,
        linkPreviewSection: controller.linkPreviewOptionsSectionUi,
        securitySection: controller.securityOptionsSectionUi,
        sharingSection: controller.sharingOptionsSectionUi,
        getProjectStatusProps: controller.getProjectStatusProps,
        draftLiveToggle: controller.draftLiveToggleUi,
        $init: controller.initialisePersist,
    });
    ui.imagePreviewInner = di.invoke(MountImagePreview_1.MountImagePreview)({
        linkPreviewSettingsStore: linkPreviewSettingsStore,
        selectLinkPreview: ui.selectLinkPreview,
        disableChangingLinkSettings: ui.disableChangingLinkSettings,
    });
    return ui;
});
