// Generated by CoffeeScript 1.12.7

/*
  An inline color-selector.

  @param currentColor [String] a hex color of the current value
  @param options [Object] optional args which can include:
    - `onChange` [Function] function to call when the selected color changes.
    - `toggleElemSelector` [String] the selector string for the element that
      shows the color-selector
 */

(function () {
  var ColorSelector;

  module.exports = ColorSelector = (function () {
    function ColorSelector(
      currentColor,
      anchorElem,
      tethering,
      options,
      createUi,
      domFrame,
      tether,
      $q,
      utils,
      noop,
      $timeout,
      OnClickOutside,
      make,
    ) {
      var clickOutToClose,
        close,
        domFrameId,
        ignoreElems,
        onEnter,
        onLeave,
        removeHash,
        selectorElem,
        setColor,
        showing,
        tetherHandle,
        ui;
      anchorElem = $(anchorElem);
      domFrameId = null;
      tetherHandle = null;
      selectorElem = null;
      showing = $q.defer();
      this.showing = showing.promise;
      if (options.onChange == null) {
        options.onChange = noop;
      }
      close = function () {
        return domFrame.remove(domFrameId);
      };
      ignoreElems = [".color-selector", ".color-tile"];
      if (options.toggleElemSelector.length) {
        ignoreElems.push(options.toggleElemSelector);
      }
      clickOutToClose = make(OnClickOutside, {
        ignoreElems: ignoreElems,
        onClickOutside: function () {
          close();
          return clickOutToClose.stopListening();
        },
      });
      onEnter = function () {
        return (tetherHandle = tether({
          element: selectorElem,
          target: anchorElem,
          attachment: tethering.attachment || "top left",
          targetAttachment: tethering.targetAttachment || "bottom left",
          offset: tethering.offset || "5px 0",
        }));
      };
      onLeave = function () {
        return tetherHandle.destroy();
      };
      setColor = function (color) {
        if (color.charAt(0) !== "#") {
          color = "#" + color;
        }
        ui.currentColor = color;
        ui.exactColorCode = removeHash(color);
        return options.onChange(color);
      };
      removeHash = function (code) {
        return code.replace("#", "");
      };
      ui = createUi(require("./ColorSelector.html"), {
        close: close,
        currentColor: currentColor,
        exactColorCode: removeHash(currentColor),
        colorList: require("./ColorList"),
        isValid: utils.isValidHex,
        trySetColor: function (color) {
          if (utils.isValidHex(color)) {
            return setColor(color);
          }
        },
        selectColor: function (color) {
          return setColor(color);
        },
        useColor: function (color) {
          showing.resolve(color);
          return close();
        },
        $onElementRendered: function (_selectorElem) {
          selectorElem = _selectorElem;
          selectorElem.on("$destroy", onLeave);
          return onEnter();
        },
      });
      domFrameId = domFrame.add(ui);
      this.close = close;
    }

    return ColorSelector;
  })();
}.call(this));
