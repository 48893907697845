// Generated by CoffeeScript 1.12.7
(function () {
  var StylesFilter;

  module.exports = StylesFilter = (function () {
    function StylesFilter(simpleDropdownFactory, createUi, blockType, initialValue, onSelect) {
      var dropdown, options, optionsFormatted;
      if (blockType === "Text" || blockType === "Video" || blockType === "Source") {
        options = ["Titles", "Paragraphs", "Highlights", "Custom"];
      } else if (blockType === "Splash") {
        options = ["Headers", "Pullouts", "Testimonials", "Custom"];
      } else {
        options = ["Custom"];
      }
      optionsFormatted = _.map(options, function (option, i) {
        return {
          name: option,
          id: i,
        };
      });
      dropdown = simpleDropdownFactory.create(
        optionsFormatted,
        {
          name: initialValue,
        },
        function (value) {
          return onSelect(value.name);
        },
      );
      this.ui = createUi(require("./StylesFilter.html"), {
        dropdown: dropdown,
      });
    }

    return StylesFilter;
  })();
}.call(this));
