"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlockTitle = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var styles = __importStar(require("./BlockTitle.css"));
var BlockTitle = function (_a) {
    var content = _a.content, onChange = _a.onChange;
    var _b = __read((0, react_1.useState)(false), 2), isEditing = _b[0], setIsEditing = _b[1];
    var _c = __read((0, react_1.useState)(content), 2), title = _c[0], setTitle = _c[1];
    var inputRef = (0, react_1.useRef)();
    (0, react_1.useEffect)(function () {
        var _a;
        if (isEditing) {
            (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        }
    }, [isEditing]);
    if (isEditing) {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(kaleidoscope_1.TextInput, { ref: inputRef, label: "Block name", labelHidden: true, value: title, placeholder: "Untitled", onBlur: function () {
                    setIsEditing(false);
                    onChange === null || onChange === void 0 ? void 0 : onChange(title);
                }, onKeyDown: function (e) {
                    if (e.key === kaleidoscope_1.KeyType.Enter) {
                        e.preventDefault();
                        setIsEditing(false);
                        onChange === null || onChange === void 0 ? void 0 : onChange(title);
                    }
                }, onChange: function (value) {
                    setTitle(value);
                } }),
            react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.Tick, null), onClick: function () {
                    setIsEditing(false);
                    onChange === null || onChange === void 0 ? void 0 : onChange(title);
                } })));
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(kaleidoscope_1.Text, { strong: true, className: styles.title }, title ? title : "Untitled"),
        react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.Edit, null), tooltip: { content: "Edit name" }, onClick: function () {
                setIsEditing(true);
            } })));
};
exports.BlockTitle = BlockTitle;
