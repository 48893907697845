"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SideBySideViewStore = void 0;
var DragManager_1 = require("./DragManager");
var SideBySideViewStore = (function () {
    function SideBySideViewStore() {
        this._dragManager = new DragManager_1.DragManager();
    }
    Object.defineProperty(SideBySideViewStore.prototype, "dragManager", {
        get: function () {
            return this._dragManager;
        },
        enumerable: false,
        configurable: true
    });
    return SideBySideViewStore;
}());
exports.SideBySideViewStore = SideBySideViewStore;
