// Generated by CoffeeScript 1.12.7

/*
  Manages a sequence.

  In many use-cases we have a "current" item concept and
  a need to cycle through the various items one at a time.
  This module abstracts the management of the "current item",
  and cycling through the list.

  @param items [Array] the items to manage as a sequence.
  @param options [Object] an options object which can include:
    - `onChange` [Function] invoked when the sequence moves a step
    - `onHitEnd` [Function] invoked when the sequence hits the end of the sequence
      i.e trying to move to an index that does not exist, beyond the boundaries of the items array
    - `onHitStart` [Function] invoked when the sequence hits the start of the sequence.
 */

(function () {
  var Sequencer;

  module.exports = Sequencer = (function () {
    function Sequencer(options, items, noop, $log) {
      var currentItemIndex, onChange, sequenceItems;
      if (options.onChange == null) {
        options.onChange = noop;
      }
      if (options.onHitEnd == null) {
        options.onHitEnd = noop;
      }
      if (options.onHitStart == null) {
        options.onHitStart = noop;
      }
      currentItemIndex = 0;
      sequenceItems = items;
      this.getCurrentItem = function () {
        return sequenceItems[currentItemIndex];
      };
      this.getCurrentItemIndex = function () {
        return currentItemIndex;
      };
      this.hasNext = function () {
        return currentItemIndex < sequenceItems.length - 1;
      };
      this.hasPrev = function () {
        return currentItemIndex > 0;
      };
      this.next = (function (_this) {
        return function () {
          if (_this.hasNext()) {
            currentItemIndex++;
            return onChange();
          } else {
            options.onHitEnd();
            return false;
          }
        };
      })(this);
      this.prev = (function (_this) {
        return function () {
          if (_this.hasPrev()) {
            currentItemIndex--;
            return onChange();
          } else {
            options.onHitStart();
            return false;
          }
        };
      })(this);
      this.gotoItem = function (index) {
        if (!_.isInteger(index)) {
          return $log.error("Attempting to use a non-Integer index");
        } else if (index < 0) {
          return $log.error("Attempting to use a negative index");
        } else if (index >= sequenceItems.length) {
          return $log.error("Attempting to go to an index beyond the item list");
        } else {
          currentItemIndex = index;
          return onChange();
        }
      };
      this.setItems = function (newItems) {
        if (newItems == null || !_.isArray(newItems)) {
          $log.error("Attempting to set sequencer items to undefined or null");
          return false;
        } else if (newItems.length === 0) {
          $log.error("Attempting to set sequencer items to zero-length array");
          return false;
        } else {
          sequenceItems = newItems;
          currentItemIndex = 0;
          return true;
        }
      };
      onChange = (function (_this) {
        return function () {
          return options.onChange(_this.getCurrentItem());
        };
      })(this);
      this.length = sequenceItems.length;
    }

    return Sequencer;
  })();
}.call(this));
