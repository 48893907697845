"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromptType = exports.CONTENT_SIDEBAR_TABS = exports.contentSidebarTabToSidebarTabTypeTelem = exports.ContentSidebarTab = void 0;
var SidebarViewStore_1 = require("Project/Stores/SidebarViewStore/SidebarViewStore");
var mobx_1 = require("mobx");
var TelemetryEnums_1 = require("Telemetry/TelemetryEnums");
var ContentSidebarTab;
(function (ContentSidebarTab) {
    ContentSidebarTab["Blocks"] = "contentsidebartab:blocks";
    ContentSidebarTab["Image"] = "contentsidebartab:image";
})(ContentSidebarTab || (exports.ContentSidebarTab = ContentSidebarTab = {}));
var contentSidebarTabToSidebarTabTypeTelem = function (tab) {
    var _a;
    return (_a = {},
        _a[ContentSidebarTab.Blocks] = TelemetryEnums_1.SidebarTabType.Blocks,
        _a[ContentSidebarTab.Image] = TelemetryEnums_1.SidebarTabType.Images,
        _a)[tab];
};
exports.contentSidebarTabToSidebarTabTypeTelem = contentSidebarTabToSidebarTabTypeTelem;
exports.CONTENT_SIDEBAR_TABS = [
    { key: ContentSidebarTab.Blocks, displayName: "Blocks" },
    { key: ContentSidebarTab.Image, displayName: "Images" },
];
var PromptType;
(function (PromptType) {
    PromptType["ClickOrDragToAddImage"] = "ClickOrDragToAddImage";
})(PromptType || (exports.PromptType = PromptType = {}));
var Prompts = (_a = {},
    _a[PromptType.ClickOrDragToAddImage] = {
        class: "click-or-drag-prompt",
        text: "Click or drag to add image",
    },
    _a);
var ContentSidebarLibraryViewStore = (function () {
    function ContentSidebarLibraryViewStore(sidebarViewStore, dropzonesStore) {
        var _this = this;
        this.sidebarViewStore = sidebarViewStore;
        this.dropzonesStore = dropzonesStore;
        this._showFolders = false;
        this._currentTab = ContentSidebarTab.Blocks;
        this._currentPrompt = Prompts[PromptType.ClickOrDragToAddImage];
        this._isShowingPrompt = false;
        this.hideFolders = function () {
            _this._showFolders = false;
        };
        this.toggleFolderTree = function () {
            _this._showFolders = !_this._showFolders;
        };
        this.showPrompt = function (type, timeInMS) {
            if (timeInMS === void 0) { timeInMS = 5000; }
            _this._currentPrompt = Prompts[type];
            _this._isShowingPrompt = true;
            setTimeout(function () {
                (0, mobx_1.runInAction)(function () {
                    _this.hidePrompt();
                });
            }, timeInMS);
        };
        this.hidePrompt = function () {
            _this._isShowingPrompt = false;
        };
        this.setCurrentTab = function (newTab) {
            if (_this._currentTab === ContentSidebarTab.Blocks && newTab !== ContentSidebarTab.Blocks) {
                _this.dropzonesStore.clearPersistent();
                _this.dropzonesStore.clearActiveDropzone();
            }
            _this._currentTab = newTab;
        };
        this.closeFolders = function () {
            _this._showFolders = false;
        };
    }
    Object.defineProperty(ContentSidebarLibraryViewStore.prototype, "currentTab", {
        get: function () {
            return this._currentTab;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ContentSidebarLibraryViewStore.prototype, "currentPrompt", {
        get: function () {
            return this._currentPrompt;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ContentSidebarLibraryViewStore.prototype, "isShowingPrompt", {
        get: function () {
            return this._isShowingPrompt;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(ContentSidebarLibraryViewStore.prototype, "isSidebarOpen", {
        get: function () {
            return this.sidebarViewStore.sidebarIsOpen(SidebarViewStore_1.SidebarType.ContentSidebar);
        },
        enumerable: false,
        configurable: true
    });
    ContentSidebarLibraryViewStore.prototype.isTabCurrentlyOpen = function (tab) {
        var _this = this;
        return (0, mobx_1.computed)(function () { return _this.isSidebarOpen && _this._currentTab === tab; }).get();
    };
    Object.defineProperty(ContentSidebarLibraryViewStore.prototype, "isShowingFolders", {
        get: function () {
            return this._showFolders;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], ContentSidebarLibraryViewStore.prototype, "_showFolders", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], ContentSidebarLibraryViewStore.prototype, "_currentTab", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], ContentSidebarLibraryViewStore.prototype, "_currentPrompt", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], ContentSidebarLibraryViewStore.prototype, "_isShowingPrompt", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ContentSidebarLibraryViewStore.prototype, "hideFolders", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ContentSidebarLibraryViewStore.prototype, "toggleFolderTree", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], ContentSidebarLibraryViewStore.prototype, "currentTab", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], ContentSidebarLibraryViewStore.prototype, "currentPrompt", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ContentSidebarLibraryViewStore.prototype, "isShowingPrompt", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ContentSidebarLibraryViewStore.prototype, "isSidebarOpen", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Object)
    ], ContentSidebarLibraryViewStore.prototype, "showPrompt", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Object)
    ], ContentSidebarLibraryViewStore.prototype, "hidePrompt", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ContentSidebarLibraryViewStore.prototype, "setCurrentTab", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], ContentSidebarLibraryViewStore.prototype, "isShowingFolders", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Object)
    ], ContentSidebarLibraryViewStore.prototype, "closeFolders", void 0);
    return ContentSidebarLibraryViewStore;
}());
exports.default = ContentSidebarLibraryViewStore;
