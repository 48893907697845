"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
module.exports = function (createUi, overlayStack, project, projectLinkRetriever, telemetry, Link, make, overlayOptions, $state) {
    var pdfUrl = projectLinkRetriever.getPdfUrlForProject(project);
    var pdfUrlPortrait = "".concat(pdfUrl, "?orientation=portrait&origin=hubspot");
    var pdfUrlLandscape = "".concat(pdfUrl, "?orientation=landscape&origin=hubspot");
    var ui = createUi(require("./PdfExport.html"), {
        export: function (orientation) {
            window.open(pdfUrl + "?orientation=".concat(orientation));
            telemetry.trackOnly("PDF Downloaded", {
                pdf_orientation: orientation,
                event_origin: "hubspot",
                pdf_download_type: "page",
            });
            if (!(overlayOptions === null || overlayOptions === void 0 ? void 0 : overlayOptions.keepOverlay)) {
                return overlayStack.closeCurrent();
            }
        },
    });
    var pdfUrlPortraitLink = make(Link, {
        linkUrl: pdfUrlPortrait,
        options: {},
    });
    ui.pdfUrlPortraitLink = pdfUrlPortraitLink.ui;
    var pdfUrlLandscapeLink = make(Link, {
        linkUrl: pdfUrlLandscape,
        options: {},
    });
    ui.pdfUrlLandscapeLink = pdfUrlLandscapeLink.ui;
    return overlayStack.add({
        contentUi: ui,
        options: overlayOptions,
    });
};
