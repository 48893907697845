"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SidebarType = exports.SidebarViewStore = void 0;
var pages_common_1 = require("@qwilr/pages-common");
var mobx_1 = require("mobx");
var SidebarType;
(function (SidebarType) {
    SidebarType["None"] = "none";
    SidebarType["ContentSidebar"] = "contentsidebar";
})(SidebarType || (exports.SidebarType = SidebarType = {}));
var SidebarViewStore = (function () {
    function SidebarViewStore() {
        var _this = this;
        this._sidebarType = SidebarType.None;
        this.sidebarIsOpen = function (sidebarType) {
            return (0, mobx_1.computed)(function () { return _this._sidebarType === sidebarType; }).get();
        };
        this.openSidebar = function (sidebarType) {
            if (sidebarType === SidebarType.ContentSidebar) {
                pages_common_1.PubSub.publish("qwilr:nav-drawer-toggled", { isOpen: false });
                pages_common_1.PubSub.publish("qwilr:content-sidebar-toggled", { isOpen: true });
            }
            _this._sidebarType = sidebarType;
        };
        this.closeSidebar = function () {
            pages_common_1.PubSub.publish("qwilr:content-sidebar-toggled", { isOpen: false });
            _this._sidebarType = SidebarType.None;
        };
    }
    __decorate([
        mobx_1.observable,
        __metadata("design:type", String)
    ], SidebarViewStore.prototype, "_sidebarType", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], SidebarViewStore.prototype, "openSidebar", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], SidebarViewStore.prototype, "closeSidebar", void 0);
    return SidebarViewStore;
}());
exports.SidebarViewStore = SidebarViewStore;
