"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MAX_DELAY = exports.calcAttemptDelay = exports.exponentialBackoffPromise = void 0;
function exponentialBackoffPromise(attemptCount) {
    var delay = calcAttemptDelay(attemptCount);
    return new Promise(function (resolve) {
        setTimeout(resolve, delay);
    });
}
exports.exponentialBackoffPromise = exponentialBackoffPromise;
function calcAttemptDelay(attemptCount) {
    return Math.min(2000 * attemptCount + (Math.pow(2, attemptCount + 4) - 16), exports.MAX_DELAY);
}
exports.calcAttemptDelay = calcAttemptDelay;
exports.MAX_DELAY = 60000;
