"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var _a = require("Common/Utils/ImageImports"), Accept = _a.Accept, Projects = _a.Projects, Name = _a.Name, Clone = _a.Clone, Share = _a.Share, Preview = _a.Preview, PayNow = _a.PayNow;
module.exports = (function () {
    function AuditTrailRetriever($http, $location, $log, imageLocation, apiBaseUrl, primaryDomain, getFullName) {
        this.retrieve = function (projectId) { return fetchAuditTrail(projectId).then(function (projectData) { return makeAuditTrail(projectData); }); };
        var fetchAuditTrail = function (projectId) {
            return $http.get("".concat(apiBaseUrl, "/projects/").concat(projectId, "/audit-trail")).then(function (result) { return result.data; });
        };
        var makeAuditTrail = function (data) {
            var accepterEvents;
            var events = [];
            var projectEvents = [publishedPageEvent(data), auditBackupEvent(data), emailedCopyEvent(data)];
            if (data.acceptanceData.payment) {
                projectEvents.push(paidEvent(data));
            }
            if (data.acceptanceData.accepters != null) {
                accepterEvents = [];
                var backupHash = data.acceptanceData.backupHash;
                for (var i = 0; i < data.acceptanceData.accepters.length; i++) {
                    var accepter = data.acceptanceData.accepters[i];
                    accepterEvents = accepterEvents.concat(getAccepterEvents(accepter, backupHash, i));
                }
            }
            else {
                accepterEvents = getAccepterEvents(data.acceptanceData);
            }
            events = accepterEvents.concat(projectEvents);
            return (0, lodash_1.sortBy)(events, "timestamp");
        };
        var getAccepterEvents = function (acceptanceData, backupHash, accepterNum) {
            var _a;
            var events = [
                arrivedOnPageEvent(acceptanceData),
                signedAndAcceptedEvent(acceptanceData, backupHash, accepterNum),
            ];
            if (((_a = acceptanceData.identification) === null || _a === void 0 ? void 0 : _a.data) != null) {
                events.push(identifiedAsEvent(acceptanceData));
            }
            return events;
        };
        var publishedPageEvent = function (data) { return ({
            name: "Published Page",
            cssClass: "published",
            icon: Projects,
            timestamp: data.publishedAt || data.createdAt,
            subtitle: getFullName(data.user),
            table: [
                {
                    label: "Email",
                    value: data.user.email,
                },
            ],
        }); };
        var arrivedOnPageEvent = function (acceptanceData) {
            var _a;
            var timestamp;
            if ((_a = acceptanceData.identification) === null || _a === void 0 ? void 0 : _a.time) {
                timestamp = acceptanceData.identification.time;
            }
            else {
                timestamp = acceptanceData.sessionStartedAt;
            }
            return {
                name: "Page viewed",
                cssClass: "session-start",
                icon: Preview,
                timestamp: timestamp,
                subtitle: acceptanceData.name,
                copy: "IP: " + acceptanceData.ip,
            };
        };
        var identifiedAsEvent = function (acceptanceData) {
            var idType;
            var identification = acceptanceData.identification;
            switch (identification.type) {
                case "GoogleSignIn":
                    idType = "Google";
                    break;
                case "EmailConfirmation":
                    idType = "Email Confirmation";
                    break;
                case "SpringboardConfirmation":
                    idType = "Qwilr Springboard";
                    break;
            }
            return {
                name: "Identified As",
                cssClass: "identified",
                icon: Name,
                timestamp: acceptanceData.sessionStartedAt,
                subtitle: acceptanceData.identification.email,
                table: [
                    { label: "IP", value: identification.ip },
                    { label: "Using", value: idType },
                    { label: "Name", value: identification.data.name || identification.data.username },
                ],
            };
        };
        var auditBackupEvent = function (data) {
            var jsonUrl = imageLocation.vault(data.acceptanceData.backupHash + "-audit-trail.json");
            var jsonLinkedText = "<a target=\"_blank\" href=\"".concat(jsonUrl, "\">JSON format</a>");
            var pdfUrl = imageLocation.vault(data.acceptanceData.backupHash + "-project.pdf");
            var pdfLinkedText = "<a target=\"_blank\" href=\"".concat(pdfUrl, "\">PDF format</a>");
            var agreement = data.acceptanceData.agreementPdfName;
            var agreementText = !!agreement
                ? "<a target=\"_blank\" href=\"".concat(imageLocation.vault(agreement), "\">Agreement</a>")
                : null;
            return {
                name: "Audit Backup",
                cssClass: "audit-backup",
                icon: Clone,
                timestamp: data.acceptanceData.backupCreatedAt,
                subtitle: "Record of accepted project:",
                copy: "".concat(pdfLinkedText, " / ").concat(jsonLinkedText).concat(!!agreementText ? " / ".concat(agreementText) : ""),
            };
        };
        var emailedCopyEvent = function (data) {
            var e_1, _a;
            var emails = [data.user.email];
            if (data.acceptanceData.accepters != null) {
                try {
                    for (var _b = __values(data.acceptanceData.accepters), _c = _b.next(); !_c.done; _c = _b.next()) {
                        var accepter = _c.value;
                        emails.push(accepter.email);
                    }
                }
                catch (e_1_1) { e_1 = { error: e_1_1 }; }
                finally {
                    try {
                        if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                    }
                    finally { if (e_1) throw e_1.error; }
                }
            }
            else {
                emails.push(data.acceptanceData.email);
            }
            return {
                name: "Emailed Copy",
                cssClass: "emailed-copy",
                icon: Share,
                timestamp: data.acceptanceData.backupCreatedAt,
                subtitle: "Delivered to all parties:",
                copy: emails.join("<br>"),
            };
        };
        var paidEvent = function (data) {
            var copy = "";
            var card = getCard(data);
            if (card) {
                var brand = card.brand, last4 = card.last4;
                copy = "By ".concat((0, lodash_1.capitalize)(brand), " (card ending in ").concat(last4, ")");
            }
            return {
                name: "Paid",
                cssClass: "paid",
                icon: PayNow,
                timestamp: data.acceptanceData.payment.timestamp,
                copy: copy,
                amount: data.acceptanceData.payment.decimalAmount,
                currency: data.acceptanceData.payment.currency,
            };
        };
        var getCard = function (data) {
            var _a;
            try {
                var paymentObject = data.acceptanceData.payment;
                if (paymentObject.intent) {
                    if (typeof paymentObject.intent.latest_charge === "object") {
                        return paymentObject.intent.latest_charge.payment_method_details.card;
                    }
                    return paymentObject.intent.charges.data[0].payment_method_details.card;
                }
                if (((_a = paymentObject.charge) === null || _a === void 0 ? void 0 : _a.source) != null) {
                    return paymentObject.charge.source;
                }
            }
            catch (e) {
                $log.error("Error retrieving card for audit overlay: ".concat(e));
            }
            return null;
        };
        var signedAndAcceptedEvent = function (acceptanceData, backupHash, accepterNum) {
            var _a, _b;
            if (backupHash === void 0) { backupHash = null; }
            if (accepterNum === void 0) { accepterNum = null; }
            if (backupHash == null) {
                (backupHash = acceptanceData.backupHash);
            }
            var event = {
                name: "Signed & Accepted",
                cssClass: "signed-accepted",
                icon: Accept,
                timestamp: acceptanceData.acceptedAt,
                table: [
                    { label: "IP", value: acceptanceData.ip },
                    { label: "Email", value: acceptanceData.email },
                    { label: "Company", value: acceptanceData.legalEntity || "N/A" },
                ],
            };
            if ((_a = acceptanceData.postAcceptance) === null || _a === void 0 ? void 0 : _a.url) {
                event.table.push({ label: "Post Acceptance", value: acceptanceData.postAcceptance.url });
            }
            if (acceptanceData.esigned) {
                var signatureImg = void 0;
                if (accepterNum != null) {
                    signatureImg = imageLocation.vault(backupHash + "-".concat(accepterNum, "-signature.png"));
                }
                else {
                    signatureImg = imageLocation.vault(backupHash + "-signature.png");
                }
                event.extra = "<div class=\"signature\">\n  <div class=\"title\">Electronic Signature</div>\n  <img src=\"".concat(signatureImg, "\" alt=\"Signature\">\n</div>");
            }
            if ((_b = acceptanceData.customForm) === null || _b === void 0 ? void 0 : _b.length) {
                var fields = (0, lodash_1.map)(acceptanceData.customForm, function (item) { return ({
                    label: item.field,
                    value: item.value,
                }); });
                event.table = event.table.concat(fields);
            }
            return event;
        };
    }
    return AuditTrailRetriever;
}());
