"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAssetBlock = exports.isAssetUnsplash = exports.isAssetVideo = void 0;
var Types_1 = require("Project/ContentSidebar/Types");
var isAssetVideo = function (asset) {
    return asset.type === Types_1.AssetType.Video;
};
exports.isAssetVideo = isAssetVideo;
var isAssetUnsplash = function (asset) {
    return asset.type === Types_1.AssetType.UnsplashImage;
};
exports.isAssetUnsplash = isAssetUnsplash;
var isAssetBlock = function (asset) {
    return asset.type === Types_1.AssetType.Block;
};
exports.isAssetBlock = isAssetBlock;
