"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetInvitePermissionController = void 0;
var SetInvitePermissionController = (function () {
    function SetInvitePermissionController(createUi, $q, AsyncButton, account, accountService, RadioButtons) {
        var _this = this;
        var selectedInvitePermissionType = null;
        var setDefaultSetting = function () {
            if (account.plan.displayName == "Enterprise") {
                return "admin";
            }
            return "domain";
        };
        var getUserInvitePermission = function () {
            var accountSettingPermissions = account.settings && account.settings.permissions;
            if (accountSettingPermissions && accountSettingPermissions.userInvitePermission) {
                return accountSettingPermissions.userInvitePermission;
            }
            else {
                return setDefaultSetting();
            }
        };
        var onClick = function () {
            _this.ui.error = "";
            return (accountService
                .setUserInvitePermission(selectedInvitePermissionType)
                .then(function () {
                _this.ui.error = "";
                return (account.settings.permissions.userInvitePermission = selectedInvitePermissionType);
            })
                .catch(function (response) {
                var responseData = response && response.data;
                var dataError = responseData && responseData.error;
                var errorMessage = dataError && dataError.message;
                var error = errorMessage ? errorMessage : "An error has occurred, please try again later.";
                _this.ui.error = error;
                return $q.reject(error);
            }));
        };
        var updateButton = new AsyncButton(onClick, "Update permissions");
        this.ui = createUi(require("./SetInvitePermission.html"), {
            updateButton: updateButton.ui,
            radioButtons: new RadioButtons([
                {
                    id: "creator",
                    label: "Creators can invite any user",
                },
                {
                    id: "domain",
                    label: "Creators can only invite users with same email domain",
                },
                {
                    id: "admin",
                    label: "Creators can’t invite other users",
                },
            ], function (_a) {
                var id = _a.id;
                if (id === getUserInvitePermission()) {
                    selectedInvitePermissionType = null;
                    if (updateButton.state === "idle") {
                        return (updateButton.state = "disabled");
                    }
                }
                else {
                    selectedInvitePermissionType = id;
                    if (updateButton.state === "disabled") {
                        return (updateButton.state = "idle");
                    }
                }
            }, getUserInvitePermission()),
        });
    }
    return SetInvitePermissionController;
}());
exports.SetInvitePermissionController = SetInvitePermissionController;
