// Generated by CoffeeScript 1.12.7
(function () {
  var WidgetSelection,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = WidgetSelection = (function () {
    function WidgetSelection() {
      this.isSelected = bind(this.isSelected, this);
      this.toggleSelection = bind(this.toggleSelection, this);
      this.deselect = bind(this.deselect, this);
      this.select = bind(this.select, this);
      this.selected = false;
    }

    WidgetSelection.prototype.select = function () {
      return (this.selected = true);
    };

    WidgetSelection.prototype.deselect = function () {
      return (this.selected = false);
    };

    WidgetSelection.prototype.toggleSelection = function () {
      return (this.selected = !this.selected);
    };

    WidgetSelection.prototype.isSelected = function () {
      return this.selected;
    };

    return WidgetSelection;
  })();
}.call(this));
