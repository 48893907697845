// Generated by CoffeeScript 1.12.7

/*
  This module defines the block types available in the block browser,
  with their associated icons, labels, colours and descriptions.
 */

(function () {
  var AcceptBlockDisabledColor,
    BlockAcceptColor75,
    BlockDisplaySettings,
    BlockEmbedColor75,
    BlockImageColor75,
    BlockQuoteColor75,
    BlockTextColor75,
    BlockVideoColor75,
    FeatureFlag,
    QuoteBlockDisabledColor,
    ref;

  (ref = require("Common/Utils/ImageImports")),
    (BlockTextColor75 = ref.BlockTextColor75),
    (BlockQuoteColor75 = ref.BlockQuoteColor75),
    (BlockVideoColor75 = ref.BlockVideoColor75),
    (BlockImageColor75 = ref.BlockImageColor75),
    (BlockEmbedColor75 = ref.BlockEmbedColor75),
    (BlockAcceptColor75 = ref.BlockAcceptColor75),
    (AcceptBlockDisabledColor = ref.AcceptBlockDisabledColor),
    (BlockQuoteColor75 = ref.BlockQuoteColor75),
    (QuoteBlockDisabledColor = ref.QuoteBlockDisabledColor);

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = BlockDisplaySettings = (function () {
    function BlockDisplaySettings(blockTypes, usingFeature) {
      var mapping;
      this.getAllSettings = function () {
        return mapping;
      };

      /*
        Get the icon for the given block type
        @param [String] blockType
       */
      this.getIcon = function (blockType) {
        var obj;
        obj = mapping[blockType.toLowerCase()];
        if (obj == null) {
          return;
        }
        if (obj.enabled) {
          return obj.icon;
        } else {
          return obj.disabledIcon;
        }
      };

      /*
        Get whether the block type is disabled
        @param [String] blockType
       */
      this.isEnabled = function (blockType) {
        var obj;
        obj = mapping[blockType.toLowerCase()];
        return obj != null ? obj.enabled : void 0;
      };

      /*
        Get the display name if it exists, or just return the parameter if it doesn't
        @param [String] blockType
       */
      this.getDisplayName = function (blockType) {
        var ref1;
        return ((ref1 = mapping[blockType.toLowerCase()]) != null ? ref1.displayName : void 0) || blockType;
      };
      mapping = {
        text: {
          icon: BlockTextColor75,
          cssClass: "text",
          customTooltipClass: "grey",
          displayName: "text",
          description: "Add text and choose from different formats",
          blockModel: blockTypes["Text"].model,
          enabled: true,
        },
        quote: {
          icon: BlockQuoteColor75,
          cssClass: "quote",
          customTooltipClass: "purple",
          displayName: "quote (legacy)",
          description: "Quotes generate cost estimates with ease",
          blockModel: blockTypes["Quote"].model,
          enabled: true,
        },
        video: {
          icon: BlockVideoColor75,
          cssClass: "video",
          customTooltipClass: "red",
          displayName: "video",
          description: "Drop in a video from the web",
          blockModel: blockTypes["Video"].model,
          enabled: true,
        },
        splash: {
          icon: BlockImageColor75,
          cssClass: "splash",
          customTooltipClass: "yellow",
          displayName: "splash",
          description: "Add a big and beautiful background image",
          blockModel: blockTypes["Splash"].model,
          enabled: true,
        },
        source: {
          icon: BlockEmbedColor75,
          cssClass: "cloud-source",
          customTooltipClass: "blue",
          displayName: "embed",
          description: "Embed an external widget or webpage",
          blockModel: blockTypes["Source"].model,
          enabled: true,
        },
        accept: {
          icon: BlockAcceptColor75,
          disabledIcon: AcceptBlockDisabledColor,
          cssClass: "accept",
          customTooltipClass: "emerald",
          displayName: "accept",
          description: "Add e-sign, payments, and an accept button",
          blockModel: blockTypes["Accept"].model,
          enabled: usingFeature(FeatureFlag.Accept),
          disabledMessage: "Accept, e-sign, and payments are only available on our paid plans.",
        },
        quotev2: {
          icon: BlockQuoteColor75,
          disabledIcon: QuoteBlockDisabledColor,
          cssClass: "quotev2",
          customTooltipClass: "purple",
          displayName: "quote",
          description: "Add interactive pricing and quotes",
          blockModel: blockTypes["QuoteV2"].model,
          enabled: usingFeature(FeatureFlag.Quote),
          disabledMessage: "Interactive pricing is only available on our paid plans.",
        },
      };
    }

    return BlockDisplaySettings;
  })();
}.call(this));
