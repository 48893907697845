// Generated by CoffeeScript 1.12.7
(function () {
  var PayNowOptions;

  module.exports = PayNowOptions = (function () {
    function PayNowOptions(createUi, settings, account, integrationService) {
      if (settings.payNow == null) {
        settings.payNow = {
          businessName: account.name,
          paymentInfo: "",
          payPercentEnabled: false,
          payPercent: 100,
        };
      }
      this.ui = createUi(require("./PayNow.html"), {
        accountName: account.name,
        settings: settings.payNow,
        showStripeIntegration: function () {
          return integrationService.openIntegrationSettings("stripe");
        },
        payNowEnabled: function () {
          var ref;
          return (ref = account.integrations.stripe) != null ? ref.enabled : void 0;
        },
      });
    }

    return PayNowOptions;
  })();
}.call(this));
