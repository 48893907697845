(function () {
  var StripeElements, UpdateCreditCard;

  StripeElements = require("CreditCardForm/StripeElements")["default"];

  module.exports = UpdateCreditCard = (function () {
    function UpdateCreditCard(
      createUi,
      make,
      $q,
      di,
      account,
      overlayStack,
      isUpdating,
      onComplete,
      paymentService,
      $log,
      QwilrStripe,
      telemetry,
    ) {
      var clearUi, getInitialUIData, init, savePaymentDetails, showError, submitCardDetails;
      init = (function (_this) {
        return function () {
          _this.ui = createUi(require("./UpdateCreditCard.html"), getInitialUIData());
          return clearUi();
        };
      })(this);
      clearUi = (function (_this) {
        return function () {
          var ccForm;
          ccForm = make(StripeElements, {
            stripeKey: QwilrStripe,
            stripeUserId: null,
            showEmailField: false,
            useUpdatedStyling: false,
            setErrorMsg: function (message) {
              return (_this.ui.error = message);
            },
          });
          ccForm.getEventEmitter().once("detailsChanged", function () {
            return telemetry.trackOnly("Card Details Entered");
          });
          _this.ui.ccForm = ccForm;
          _this.ui.coupon = "";
          return (_this.ui.error = null);
        };
      })(this);
      showError = (function (_this) {
        return function (message) {
          return (_this.ui.error = message);
        };
      })(this);
      savePaymentDetails = (function (_this) {
        return function (token) {
          var paymentDetails;
          if (di.has("paymentDetails")) {
            paymentDetails = di.get("paymentDetails");
            return (paymentDetails.token = token);
          } else {
            paymentDetails = {
              token: token,
            };
            return di.value("paymentDetails", paymentDetails);
          }
        };
      })(this);
      submitCardDetails = (function (_this) {
        return function () {
          overlayStack.hideCloseButton();
          return paymentService
            .submitCard(_this.ui.ccForm)
            .then(function (token) {
              savePaymentDetails(token);
              if (isUpdating) {
                return paymentService.updateCreditCard(account._id, token);
              } else {
                /*
                If we're adding a new credit card, then we are getting a new stripe
                token that we need to associate with the account
               */
                return paymentService.addCreditCard(account._id, token).then(function (customerToken) {
                  return (account.stripe.customerToken = customerToken);
                });
              }
            })
            .then(function () {
              return onComplete();
            })
            ["catch"](function (error) {
              overlayStack.showCloseButton();
              $log.error(error.message);
              return $q.reject(new Error(error.message));
            });
        };
      })(this);
      getInitialUIData = (function (_this) {
        return function () {
          return {
            loading: false,
            creditCard: null,
            ccForm: null,
            error: null,
            submit: function () {
              _this.ui.loading = true;
              if (!_this.ui.ccForm.validateForm()) {
                _this.ui.loading = false;
                return;
              }
              return submitCardDetails()
                .then(function () {
                  clearUi();
                  return (_this.ui.loading = false);
                })
                ["catch"](function (error) {
                  _this.ui.error = error.message;
                  _this.ui.loading = false;
                  return $q.resolve();
                });
            },
          };
        };
      })(this);
      init();
    }

    return UpdateCreditCard;
  })();
}.call(this));
