// Generated by CoffeeScript 1.12.7
(function () {
  var PipedriveIntegrationEnable,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = PipedriveIntegrationEnable = (function () {
    function PipedriveIntegrationEnable(createUi, $q, ActionButton, make, $timeout, $log) {
      this.show = bind(this.show, this);
      var onError, submitButton;
      this.q = $q;
      submitButton = make(ActionButton, {
        config: {
          standard: "Set Pipedrive API Key",
          waiting: "Setting Pipedrive API Key...",
          success: "Set Pipedrive API Key",
          error: "Error setting Pipedrive API Key",
        },
        onClick: (function (_this) {
          return function () {
            if (!_this.enabling) {
              $log.error("No promise defined during pipedrive enabling");
              overlayStack.closeAll();
              return;
            }
            if (!_this.ui.apiKey.length) {
              return onError("Please enter a value for your Pipedrive API Key");
            }
            return _this.q.resolve(_this.enabling.resolve(_this.ui.apiKey));
          };
        })(this),
      });
      this.ui = createUi(require("./PipedriveEnable.html"), {
        error: "",
        apiKey: "",
        submitButton: submitButton.ui,
      });
      onError = (function (_this) {
        return function (errorMessage) {
          _this.ui.error = errorMessage;
          $timeout(function () {
            return (_this.ui.error = "");
          }, 5000);
          return $q.reject(new Error("Error setting Pipedrive API Key"));
        };
      })(this);
    }

    PipedriveIntegrationEnable.prototype.show = function () {
      this.enabling = this.q.defer();
      this.ui.enabling = false;
      return this.enabling.promise.then(
        (function (_this) {
          return function (apiKey) {
            _this.ui.enabling = true;
            return apiKey;
          };
        })(this),
      );
    };

    return PipedriveIntegrationEnable;
  })();
}.call(this));
