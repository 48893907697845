"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateVerticalStrength = void 0;
var calculateVerticalStrength = function (_a, point, _buffer) {
    var y = _a.y, h = _a.h, x = _a.x, w = _a.w;
    var buffer = Math.min(h / 2, _buffer);
    var inRange = point.y >= y && point.y <= y + h;
    var inBox = inRange && point.x >= x && point.x <= x + w;
    if (inBox) {
        if (point.y < y + buffer) {
            return (point.y - y - buffer) / buffer;
        }
        else if (point.y > y + h - buffer) {
            return -(y + h - point.y - buffer) / buffer;
        }
    }
    return 0;
};
exports.calculateVerticalStrength = calculateVerticalStrength;
