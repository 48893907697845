(function () {
  var QuoteSectionService,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = QuoteSectionService = (function () {
    function QuoteSectionService(block, invoke, account, user, telemetry) {
      this.telemetry = telemetry;
      this.findSectionIndex = bind(this.findSectionIndex, this);
      this.addSavedItem = bind(this.addSavedItem, this);
      this.addItem = bind(this.addItem, this);
      this.quote = block;
      this.user = user;
      this.rates = account.settings.modules.quote.rates;
      this.itemTypes = invoke(require("@CommonFrontendBackend/Quote/Models/ItemTypes"));
      this.getTotal = invoke(require("Quote/Calculator/Section"));
      this.defaultLineItemType = "rate";
    }

    /*
      Adds a new item to a section.
      @param section [Object] The section to add an item to,
      @param type [String] A type name for the item, can be one of:
      `rate` - for a standard rate item
      `fixedCost` - for a fixed cost item
      `text` - for a text item
      @param scrollTo [Boolean] whether to scroll to this item, when its created.
     */

    QuoteSectionService.prototype.addItem = function (section, type, scrollTo) {
      var itemModel;
      if (scrollTo == null) {
        scrollTo = true;
      }
      if (type == null) {
        type = this.defaultLineItemType;
      }
      itemModel = this.itemTypes[type];
      if (itemModel == null) {
        throw new Error("Item type not found.");
      }
      if (itemModel.type === "rate") {
        itemModel = this.assignLastRate(itemModel);
      }
      itemModel.$scrollTo = scrollTo;
      return section.items.push(angular.copy(itemModel));
    };

    /*
      Track telemetry for adding a line item
      @param type [String] The type of line item added
     */

    QuoteSectionService.prototype.trackAddItemTelemetry = function (type) {
      var telemetryType;
      if (type == null) {
        type = this.defaultLineItemType;
      }
      telemetryType = type === "fixedCost" ? "fixed cost item" : type + " item";
      return this.telemetry.trackOnly("Quote Block Line Item Added", {
        quote_block_line_item_type: telemetryType,
      });
    };

    /*
      Add a pre-existing item to a section (i.e. delete undo; or saved sections from a library)
      @param section [Object]
     */

    QuoteSectionService.prototype.addSavedItem = function (section, item, atIndex, scrollTo) {
      if (scrollTo == null) {
        scrollTo = true;
      }
      if (atIndex == null) {
        atIndex = section.length - 1;
      }
      return section.items.splice(atIndex, 0, item);
    };

    /*
      Assign an item the last rate used.
      @param itemModel [Object] the model for the item.
     */

    QuoteSectionService.prototype.assignLastRate = function (itemModel) {
      var rate, ref;
      if ((ref = this.user.useHistory.quote.lastRateUsed) != null ? ref.length : void 0) {
        rate = _.find(this.rates, {
          name: this.user.useHistory.quote.lastRateUsed,
        });
        if (rate != null) {
          itemModel.rate = rate;
        }
      }
      return itemModel;
    };

    /*
      Find the index of a given section.
      @param section [Object] the section whose index you want to find.
     */

    QuoteSectionService.prototype.findSectionIndex = function (section) {
      return _.findIndex(this.quote.sections, section);
    };

    return QuoteSectionService;
  })();
}.call(this));
