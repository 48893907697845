"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BackgroundStyleButton = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var BackgroundStylePanel_1 = require("Project/BlockNew/Menu/Settings/StyleButton/BackgroundStyleButton/BackgroundStylePanel");
var inversify_react_1 = require("inversify-react");
var ColorSwatch_1 = require("Common/ColorSwatch/ColorSwatch");
var Configuration_1 = require("Styles/Configuration/Configuration");
var BackgroundStyleButton = function (_a) {
    var blockBackground = _a.blockBackground;
    var blockStore = (0, inversify_react_1.useInjection)("blockStore");
    var isSidebarOpened = (0, inversify_react_1.useInjection)("isSidebarOpened");
    var _b = __read((0, react_1.useState)(false), 2), isPopoverOpened = _b[0], setIsPopoverOpened = _b[1];
    var handleChangePopover = function (isOpen) {
        setIsPopoverOpened(isOpen);
    };
    var renderButtonIcon = function () {
        if (blockBackground.type === "video") {
            if (blockBackground.thumbnailUrl) {
                return react_1.default.createElement(ColorSwatch_1.ColorSwatch, { background: "url(".concat(blockBackground.thumbnailUrl, ")") });
            }
            else {
                return react_1.default.createElement(icons_1.Video, null);
            }
        }
        else if (blockBackground.type === "image") {
            return react_1.default.createElement(ColorSwatch_1.ColorSwatch, { background: "url(".concat(blockBackground.url, ")") });
        }
        else {
            return react_1.default.createElement(ColorSwatch_1.ColorSwatch, { background: blockBackground.color });
        }
    };
    return (react_1.default.createElement(kaleidoscope_1.Layer, { offset: blockStore.isSavedBlock ? Configuration_1.overlayStackZIndex - 1 : Configuration_1.blockMenuZIndex - 1 }, function () { return (react_1.default.createElement(kaleidoscope_1.Popover, { button: function (buttonProps) { return (react_1.default.createElement(kaleidoscope_1.IconButton, __assign({}, buttonProps, { isRound: true, isIconOnly: true, size: kaleidoscope_1.ButtonSize.Small, icon: renderButtonIcon(), tooltip: {
                content: "Background",
                position: kaleidoscope_1.TooltipPosition.Top,
            } }))); }, caret: true, padding: "none", isOpen: isPopoverOpened, size: kaleidoscope_1.PopoverSize.Medium, onChange: handleChangePopover, autoStack: true, outerMargin: { left: isSidebarOpened() ? 360 + 16 : 16, top: 16, bottom: 16, right: 16 } },
        react_1.default.createElement(BackgroundStylePanel_1.BackgroundStylePanel, { closeStylePopover: function () {
                setIsPopoverOpened(false);
            } }))); }));
};
exports.BackgroundStyleButton = BackgroundStyleButton;
