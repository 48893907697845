"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Status = exports.StyleAccessType = exports.TeamType = void 0;
var Types_1 = require("@CommonFrontendBackend/Account/Types");
Object.defineProperty(exports, "StyleAccessType", { enumerable: true, get: function () { return Types_1.StyleAccessType; } });
var TeamType;
(function (TeamType) {
    TeamType["Account"] = "account";
    TeamType["Normal"] = "normal";
})(TeamType || (exports.TeamType = TeamType = {}));
var Status;
(function (Status) {
    Status["Closed"] = "closed";
    Status["Open"] = "open";
    Status["Expired"] = "expired";
    Status["Blocked"] = "blocked";
})(Status || (exports.Status = Status = {}));
