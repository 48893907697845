// Generated by CoffeeScript 1.12.7

/*
  This module creates an image picker button.
  It has an intelligent label.
  i.e. When the file it refers to exists, it says "replace"
  And when it doesn't; it says "Upload" etc.
 */

(function () {
  var ImagePickerButton, Splash;

  Splash = require("Common/Utils/ImageImports").Splash;

  module.exports = ImagePickerButton = (function () {
    /*
      @param [Function] onUploadedCallback A callback to execute after image file has finished uploading.
      @param [String] skin - Can be either 'light' or 'dark' for different button skins.
      @param [Object] mediaCarousel An instance of a media carousel item that is attached to a block
     */
    function ImagePickerButton(
      createUi,
      make,
      imageFile,
      onUploadedCallback,
      skin,
      imageService,
      mediaBrowser,
      block,
      useImageCarousel,
    ) {
      this.ui = createUi(require("Common/MediaPicker/ImagePicker/Button/Button.html"), {
        icon: Splash,
        skin: skin,
        showImagePicker: (function (_this) {
          return function () {
            if (block != null && useImageCarousel) {
              block.$ctrl.theBlock.showMediaCarousel();
              return onUploadedCallback();
            } else {
              return mediaBrowser.show("imageCollections").then(function (mediaObject) {
                return imageService.completeUrl(mediaObject).then(function () {
                  return onUploadedCallback(mediaObject.data.url, mediaObject.metricSource);
                });
              });
            }
          };
        })(this),
      });
    }

    return ImagePickerButton;
  })();
}.call(this));
