"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FontService = (function () {
    function FontService(account, fontSelector, fontPairs, loadFontsIntoBrowser, loadFonts) {
        var _this = this;
        this.account = account;
        this.fontSelector = fontSelector;
        this.fontPairs = fontPairs;
        this.loadFontsIntoBrowser = loadFontsIntoBrowser;
        this.showSelection = function () {
            return _this.fontSelector.show().then(function (font) {
                _this.loadFontsIntoBrowser([font]);
                return font;
            });
        };
        this.showPairs = function () {
            return _this.fontPairs.show().then(function (fonts) {
                _this.loadFontsIntoBrowser(fonts);
                return fonts;
            });
        };
        this.loadFonts = function () {
            _this._loadFonts(_this.account.settings.brand.fonts);
        };
        this._loadFonts = loadFonts;
    }
    return FontService;
}());
exports.default = FontService;
