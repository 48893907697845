// Generated by CoffeeScript 1.12.7

/*
  Handles events for P tag
 */

(function () {
  var PHandler, Point, backspaceHandler, enterHandler, h;

  h = require("../Helpers");

  backspaceHandler = require("./Backspace");

  enterHandler = require("./GenericEnterKeyHandler")("P");

  Point = qed.Point;

  module.exports = PHandler = (function () {
    function PHandler(editor, undo) {
      this.onKey = function (e) {
        var ORDERED_LIST_TRIGGERS,
          UNORDERED_LIST_TRIGGERS,
          curr,
          cutPoint,
          dest,
          isList,
          leadingWhitespaceLength,
          leadingWhitespaceRegex,
          listTag,
          newList,
          orderedOffsetPoint,
          point,
          shouldConvertToList,
          unorderedOffsetPoint;
        unorderedOffsetPoint = 1;
        orderedOffsetPoint = 2;
        leadingWhitespaceRegex = new RegExp("^[" + h.invisibleWhitespace + "]+");

        /*
          Helpers
         */
        leadingWhitespaceLength = function (text) {
          var ref;
          return ((ref = text.match(leadingWhitespaceRegex)) != null ? ref[0].length : void 0) || 0;
        };
        ORDERED_LIST_TRIGGERS = ["1."];
        UNORDERED_LIST_TRIGGERS = ["-", "+", "*"];
        shouldConvertToList = function (point, triggers) {
          var testPoint, text, triggerLength;
          triggerLength = triggers[0].length;
          if (point.offset == null) {
            point = h.moveLeftToText(point);
          }
          if (point == null) {
            return false;
          }
          text = point.node.textContent;
          if (
            point.offset < triggerLength ||
            !_.includes(triggers, text.slice(point.offset - triggerLength, point.offset))
          ) {
            return false;
          }
          testPoint = Point.text(point.node, point.offset - triggerLength);
          return !h.textToLeft(testPoint);
        };

        /*
          Handling
         */
        point = editor.selection().getRange().anchor;
        if (e.keyCode === 32) {
          if (shouldConvertToList(point, UNORDERED_LIST_TRIGGERS)) {
            isList = h.isUnorderedList;
            listTag = "UL";
            cutPoint = unorderedOffsetPoint + leadingWhitespaceLength(point.node.textContent);
          } else if (shouldConvertToList(point, ORDERED_LIST_TRIGGERS)) {
            isList = h.isOrderedList;
            listTag = "OL";
            cutPoint = orderedOffsetPoint + leadingWhitespaceLength(point.node.textContent);
          } else {
            return false;
          }
          e.preventDefault();
          curr = h.thisNode("P", point);
          dest = document.createElement("LI");
          point.node.textContent = point.node.textContent.slice(cutPoint);
          if (isList(curr.previousSibling)) {
            curr.previousSibling.appendChild(dest);
          } else if (isList(curr.nextSibling)) {
            curr.nextSibling.insertBefore(dest, curr.nextSibling.firstChild);
          } else {
            newList = document.createElement(listTag);
            curr.parentElement.insertBefore(newList, curr);
            newList.appendChild(dest);
          }
          while (curr.childNodes.length > 0) {
            dest.appendChild(curr.firstChild);
          }
          curr.parentElement.removeChild(curr);
          h.ensureOpen(dest);
          dest.normalize();
          editor.selection().setCaret(qed.Point.start(dest));
          return true;
        }
        if (e.keyCode === 13) {
          enterHandler(editor, e);
          return true;
        }
        if (backspaceHandler(editor, "P", e)) {
          return true;
        }
        return false;
      };
    }

    return PHandler;
  })();
}.call(this));
