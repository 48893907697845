"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useChartMappings = void 0;
var react_1 = require("react");
var tokens_1 = require("@qwilr/kaleidoscope/tokens");
var MAX_INDIVIDUAL_PIE_SECTORS = 6;
var MIN_SECTOR_PERCENTAGE = 0.01;
var COLOURS = ["#EF6E85", "#FFAB6F", "#FFDF8B", "#58B8FD", "#9C97F5", "#81EDEC"];
var OTHER_COLOUR = tokens_1.palette.midnight200;
function useChartMappings(originalData) {
    var e_1, _a;
    var _b = __read((0, react_1.useState)(null), 2), activeChartIndex = _b[0], setActiveChartIndex = _b[1];
    var _c = __read((0, react_1.useState)([]), 2), activeTableIndices = _c[0], setActiveTableIndices = _c[1];
    var dataForChart = [];
    var tableToChartMap = new Map();
    var chartToTableIndexMap = new Map();
    var sorted = originalData.sort(function (a, b) { return b.value - a.value; });
    for (var i = 0; i < MAX_INDIVIDUAL_PIE_SECTORS && i < sorted.length; ++i) {
        var current = sorted[i];
        if (current.percentage < MIN_SECTOR_PERCENTAGE) {
            break;
        }
        var color = COLOURS[i % COLOURS.length];
        dataForChart.push({ name: current.name, value: current.value, color: color });
        tableToChartMap.set(current.index, { chartIndex: i, color: color });
        chartToTableIndexMap.set(i, [current.index]);
    }
    var indexForOtherSector = dataForChart.length;
    var otherEntries = sorted.slice(indexForOtherSector);
    if (otherEntries.length > 0) {
        var othersTotalValue = otherEntries.reduce(function (acc, curr) { return acc + curr.value; }, 0);
        dataForChart.push({ name: "Other", value: othersTotalValue, color: OTHER_COLOUR });
        chartToTableIndexMap.set(indexForOtherSector, otherEntries.map(function (_a) {
            var index = _a.index;
            return index;
        }));
        try {
            for (var otherEntries_1 = __values(otherEntries), otherEntries_1_1 = otherEntries_1.next(); !otherEntries_1_1.done; otherEntries_1_1 = otherEntries_1.next()) {
                var entry = otherEntries_1_1.value;
                tableToChartMap.set(entry.index, { chartIndex: indexForOtherSector, color: OTHER_COLOUR });
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (otherEntries_1_1 && !otherEntries_1_1.done && (_a = otherEntries_1.return)) _a.call(otherEntries_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
    }
    function onChartSegmentHover(chartIndex) {
        setActiveChartIndex(chartIndex);
        if (chartIndex != null) {
            var tableIndices = chartToTableIndexMap.get(chartIndex);
            if (tableIndices) {
                setActiveTableIndices(tableIndices);
                return;
            }
        }
        setActiveTableIndices([]);
    }
    function onTableHover(index) {
        var _a, _b;
        if (index == null) {
            setActiveTableIndices([]);
            setActiveChartIndex(null);
        }
        else {
            setActiveTableIndices([index]);
            setActiveChartIndex((_b = (_a = tableToChartMap.get(index)) === null || _a === void 0 ? void 0 : _a.chartIndex) !== null && _b !== void 0 ? _b : null);
        }
    }
    return {
        dataForChart: dataForChart,
        getColourForIndex: function (index) { var _a, _b; return (_b = (_a = tableToChartMap.get(index)) === null || _a === void 0 ? void 0 : _a.color) !== null && _b !== void 0 ? _b : OTHER_COLOUR; },
        onChartSegmentHover: onChartSegmentHover,
        onTableHover: onTableHover,
        activeChartIndex: activeChartIndex,
        activeTableIndices: activeTableIndices,
    };
}
exports.useChartMappings = useChartMappings;
