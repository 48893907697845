// Generated by CoffeeScript 1.12.7

/*
 * UI for QuickBooks' Accept settings
 */

(function () {
  var QuickBooksInvoicer,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = QuickBooksInvoicer = (function () {
    function QuickBooksInvoicer($http, $q, make, settings, apiBaseUrl, GenericInvoicer) {
      this.customers = bind(this.customers, this);
      this.createInvoice = bind(this.createInvoice, this);
      var invoicingUi;
      this.http = $http;
      this.q = $q;
      this.settings = settings;
      this.apiBaseUrl = apiBaseUrl;
      invoicingUi = make(GenericInvoicer, {
        integration: "quickbooks",
        getCustomers: this.customers,
        createInvoice: this.createInvoice,
      });
      this.ui = invoicingUi.ui;
    }

    QuickBooksInvoicer.prototype.createInvoice = function (blockId, contactName, linkSecret) {
      var err;
      err = null;
      if (blockId == null) {
        err = new Error("An error detecting the quote block occurred, please contact help@qwilr.com");
      }
      if (!(contactName != null ? contactName.length : void 0)) {
        err = "No contact specified, please provide a contact for the invoice";
      }
      if (!(linkSecret != null ? linkSecret.length : void 0)) {
        err = new Error("An error with the project occurred, please contact help@qwilr.com");
      }
      if (err != null) {
        return this.q.reject(err);
      }
      return this.http.post(this.apiBaseUrl + "/integration/quickbooks/invoice", {
        blockId: blockId,
        contactName: contactName,
        linkSecret: linkSecret,
        acceptSettings: this.settings,
      });
    };

    QuickBooksInvoicer.prototype.customers = function () {
      return this.http.get(this.apiBaseUrl + "/integration/quickbooks/customers").then(function (response) {
        return response.data.message;
      });
    };

    return QuickBooksInvoicer;
  })();
}.call(this));
