"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var Telemetry_1 = require("Telemetry");
var ProjectCreatedEvent_1 = require("@CommonFrontendBackend/Telemetry/ProjectCreatedEvent");
var Types_1 = require("Project/Types");
exports.default = (function (telemetry, serverTelemetry) {
    var hasProjectBeenEditedObj = {};
    return {
        projectCreated: function (telemetryData, context) {
            context = context || {};
            var extraTelemetryFields;
            if (!context.templateState) {
                extraTelemetryFields = {
                    projectScreenUsed: false,
                };
            }
            else if (context.templateState.dropdownItem === "my-templates") {
                extraTelemetryFields = {
                    projectScreenUsed: true,
                    projectScreenIndustry: "user template",
                    projectScreenCategory: "user template",
                    projectSource: "user template",
                };
            }
            else if (context.templateState.searchTerm) {
                extraTelemetryFields = {
                    projectScreenUsed: true,
                    projectScreenIndustry: "search",
                    projectScreenCategory: "search",
                    projectSource: context.templateState.searchTerm,
                };
            }
            else {
                extraTelemetryFields = {
                    projectScreenUsed: true,
                    projectScreenIndustry: context.templateState.dropdownItem,
                    projectScreenCategory: context.templateState.useCase,
                };
            }
            if (context.template && context.template.useCases) {
                extraTelemetryFields.projectSourceIvq = context.template.useCases.includes("Promote");
            }
            if (context.cloneOrigin) {
                extraTelemetryFields.projectClonedOrigin = context.cloneOrigin;
            }
            var source = telemetryData.source, projectCreationSource = telemetryData.projectCreationSource, projectCreationUiOrigin = telemetryData.projectCreationUiOrigin, projectCreationMethod = telemetryData.projectCreationMethod, isProjectCreatedUsingForm = telemetryData.isProjectCreatedUsingForm, projectContentOrigin = telemetryData.projectContentOrigin, templateId = telemetryData.templateId, templateLinkExpiryEnabled = telemetryData.templateLinkExpiryEnabled, templateSecurityEnabled = telemetryData.templateSecurityEnabled;
            extraTelemetryFields["projectCreationSource"] = projectCreationSource || ProjectCreatedEvent_1.ProjectCreationSource.Unknown;
            extraTelemetryFields["projectCreationUiOrigin"] = projectCreationUiOrigin || ProjectCreatedEvent_1.ProjectCreationUiOrigin.Unknown;
            extraTelemetryFields["projectCreationMethod"] = projectCreationMethod;
            extraTelemetryFields["projectCreationRequiredForm"] = String(isProjectCreatedUsingForm);
            extraTelemetryFields["projectCreationContentOrigin"] = projectContentOrigin || ProjectCreatedEvent_1.ProjectContentOrigin.Unknown;
            extraTelemetryFields["templateId"] = templateId;
            extraTelemetryFields["templateLinkExpirySettingEnable"] = templateLinkExpiryEnabled;
            extraTelemetryFields["templateSecuritySettingEnable"] = templateSecurityEnabled;
            serverTelemetry.sendProjectCreatedTelemetry({
                eventProperties: __assign({ projectSource: source }, extraTelemetryFields),
            });
        },
        projectEdited: function (project) {
            if (!hasProjectBeenEditedObj[project._id]) {
                telemetry.trackOnly("Project Edited", {
                    project_edited_id: project._id,
                    project_edited_name: project.name,
                });
                hasProjectBeenEditedObj[project._id] = true;
            }
        },
        projectRenamed: function () {
            telemetry.trackOnly("Project Renamed");
        },
        projectOpened: function (stateParams, attributes) {
            var _a;
            if (attributes === void 0) { attributes = {}; }
            var origin = stateParams.origin;
            if (!origin && stateParams.source === Types_1.COLLABORATOR_LINK_SOURCE) {
                origin = Telemetry_1.ProjectOpenedOrigin.CollaboratorLink;
            }
            telemetry.trackOnly(Telemetry_1.TelemetryEventName.ProjectOpened, __assign((_a = {}, _a[Telemetry_1.ProjectOpenedFields.Origin] = origin, _a.source = stateParams.source, _a), attributes));
        },
        addBlockButtonPressed: function (position) {
            telemetry.trackOnly("Block Create Menu Viewed", { block_create_menu_viewed_position: position }, { blockIntercom: true });
        },
        viewLimitApplied: (0, lodash_1.debounce)(function () { return telemetry.trackOnly("Project Security View Limit Applied"); }, 1000),
    };
});
