/*
  Update an account's domain.
 */

(function () {
  module.exports = function ($http, account, apiBaseUrl, telemetry, $q) {
    var setCustomDomain, validateDomain;
    validateDomain = function (domain) {
      var regex;
      regex = /^[a-z0-9][a-z0-9\-\.]*[a-z0-9]$/;
      return regex.test(domain || domain.length === 0);
    };
    setCustomDomain = (function (_this) {
      return function (domain) {
        if (!validateDomain(domain)) {
          return $q.reject("The domain is invalid.");
        }
        return $http
          .post(apiBaseUrl + "/accounts/" + account._id + "/settings/domain", {
            domain: domain,
          })
          .then(function () {
            var ref;
            if (((ref = account.customDomain) != null ? ref.length : void 0) > 0) {
              telemetry.trackOnly(
                "Account Domain Changed",
                {
                  custom_domain: domain,
                  custom_domain_old: account.customDomain,
                },
                {
                  blockIntercom: true,
                },
              );
            } else {
              telemetry.trackOnly("Account Domain Added", {
                custom_domain: domain,
              });
            }
            return (account.customDomain = domain);
          })
          ["catch"](function (response) {
            var errorMessage;
            errorMessage = response.data.message || "An error occurred trying to update your domain.";
            return $q.reject(errorMessage);
          });
      };
    })(this);
    return setCustomDomain;
  };
}.call(this));
