// Generated by CoffeeScript 1.12.7
(function () {
  var ImageController;

  module.exports = ImageController = (function () {
    function ImageController(
      $timeout,
      make,
      createUi,
      model,
      ImagePickerButton,
      Resizer,
      editor,
      widgetTracker,
      triggerContentEvent,
      selection,
      telemetry,
      noop,
    ) {
      var MAX_WIDTH_IN_PERCENT, imagePickerButton, imageWidthStyle, resizer;
      MAX_WIDTH_IN_PERCENT = 100;
      this.imageLoaded = false;
      this.canResize = (function (_this) {
        return function () {
          return (
            !_this.isBlank() &&
            !_this.ui.resizer.isActive &&
            _this.imageLoaded &&
            _this.ui.imageElement[0].clientWidth >= _this.ui.resizer.MINIMUM_RESIZE_WIDTH_IN_PIXELS
          );
        };
      })(this);
      if (model.imageFile != null) {
        model.url = model.imageFile;
        delete model.imageFile;
      }
      if (model.displayWidth != null) {
        imageWidthStyle = {
          width: model.displayWidth,
        };
      } else {
        imageWidthStyle = {};
      }
      imagePickerButton = make(ImagePickerButton, {
        imageFile: model.url,
        skin: "dark",
        useImageCarousel: false,
        onUploadedCallback: (function (_this) {
          return function (selectedImageUrl, metricSource) {
            if (metricSource == null) {
              metricSource = "old version";
            }
            _this.ui.model.url = selectedImageUrl;
            return $timeout(function () {
              return triggerContentEvent();
            });
          };
        })(this),
      });
      resizer = make(Resizer, {
        onEndResize: (function (_this) {
          return function (ratioChange) {
            if (_this.ui.model.displayWidth == null && ratioChange === 1) {
              return;
            }
            _this.ui.model.displayWidth = Math.min(
              MAX_WIDTH_IN_PERCENT,
              (_this.ui.model.displayWidth || 100) * ratioChange,
            );
            return triggerContentEvent();
          };
        })(this),
        getElement: (function (_this) {
          return function () {
            return _this.ui.imageElement;
          };
        })(this),
      });
      this.ui = createUi(require("./Image.html"), {
        model: model,
        url: (function (_this) {
          return function () {
            return _this.ui.model.url || "";
          };
        })(this),
        imageWidthStyle: (function (_this) {
          return function () {
            if (_this.ui.model.displayWidth != null) {
              return {
                width: _this.ui.model.displayWidth + "%",
                "max-width": (_this.ui.model.maxWidth || "100") + "%",
              };
            } else {
              return {};
            }
          };
        })(this),
        hovered: false,
        inResizeMode: false,
        imagePickerButton: imagePickerButton.ui,
        showControls: (function (_this) {
          return function () {
            return (_this.ui.isHovered || _this.isBlank()) && !resizer.isActive;
          };
        })(this),
        resizer: resizer,
        enterResizeMode: (function (_this) {
          return function () {
            resizer.enterResizeMode();
            return (_this.ui.isHovered = false);
          };
        })(this),
        onImageLoad: (function (_this) {
          return function (event) {
            var ref;
            if (
              _this.ui.model.maxWidth == null &&
              ((ref = event.currentTarget) != null ? ref.naturalWidth : void 0) != null
            ) {
              _this.ui.model.maxWidth = Math.min(
                MAX_WIDTH_IN_PERCENT,
                (event.currentTarget.naturalWidth / event.currentTarget.parentElement.clientWidth) * 100,
              );
            }
            triggerContentEvent();
            if (_this.ui.model.displayWidth == null) {
              _this.ui.model.displayWidth = _this.ui.model.maxWidth;
            }
            $timeout(noop);
            return (_this.imageLoaded = true);
          };
        })(this),
        isBlank: this.isBlank,
        select: (function (_this) {
          return function (e) {
            if (!e.currentTarget.className.includes("picker-button")) {
              if (selection.isSelected()) {
                selection.deselect();
                return editor.selection().setCaret();
              } else {
                selection.select();
                return editor.selection().setCaret(qed.Point.start(widgetTracker.get(_this.ui.model.id).placeholder));
              }
            }
          };
        })(this),
      });
      this.isBlank = (function (_this) {
        return function () {
          return _this.ui.model.url == null || _this.ui.model.url.length === 0;
        };
      })(this);
      this.remove = (function (_this) {
        return function () {};
      })(this);
    }

    return ImageController;
  })();
}.call(this));
