// Generated by CoffeeScript 1.12.7
(function () {
  var COLLABORATOR_LINK_SOURCE,
    GatekeeperRoute,
    GatekeeperRoutes,
    Sentry,
    ref,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  (ref = require("Gatekeeper/GatekeeperMount")),
    (GatekeeperRoute = ref.GatekeeperRoute),
    (GatekeeperRoutes = ref.GatekeeperRoutes);

  COLLABORATOR_LINK_SOURCE = require("@CommonFrontend/Project/Types").COLLABORATOR_LINK_SOURCE;

  Sentry = require("@sentry/browser");

  module.exports = angular
    .module("qwilr.errorCatcher", [])
    .config(function ($httpProvider) {
      return $httpProvider.interceptors.push("errorHttpInterceptor");
    })
    .factory("$exceptionHandler", function ($log) {
      var captureError;
      captureError = function (exception) {
        $log.error(exception.stack || exception);
        return Sentry.captureException(exception);
      };
      return _.debounce(captureError, 300);
    })
    .factory("errorHttpInterceptor", function ($q, $injector, $location, $window, $rootScope) {
      var captureError,
        clientUrl,
        debouncedCapture,
        isRetrievingIndividualProject,
        publicApiBaseUrl,
        ref1,
        responseError;
      captureError = function (response) {
        var errorText, ref1;
        errorText =
          (response != null ? ((ref1 = response.config) != null ? ref1.url : void 0) : void 0) || "HTTP response error";
        return Sentry.captureException(new Error(errorText), {
          extra: {
            config: response.config,
            status: response.status,
            data: response.data,
          },
        });
      };
      isRetrievingIndividualProject = function (response) {
        var isFindProjectUrl, isGet, pattern, ref1, ref2;
        pattern = /\/projects\/[0-9a-fA-F]{24}[\/]?$/;
        isFindProjectUrl = pattern.test(
          response != null ? ((ref1 = response.config) != null ? ref1.url : void 0) : void 0,
        );
        isGet = (response != null ? ((ref2 = response.config) != null ? ref2.method : void 0) : void 0) === "GET";
        return isFindProjectUrl && isGet;
      };
      debouncedCapture = _.debounce(captureError, 300);
      (ref1 = window.QwilrConfig), (clientUrl = ref1.clientUrl), (publicApiBaseUrl = ref1.publicApiBaseUrl);
      return {
        responseError: (responseError = function (response) {
          var createUi, isEditPage, loginType, path, ref2, ref3, ref4, source, sourceParam;
          if (response.status === 401) {
            if (
              _.includes(
                response != null ? ((ref2 = response.config) != null ? ref2.url : void 0) : void 0,
                "initial-data",
              )
            ) {
              (ref3 = $location.search()), (loginType = ref3.loginType), (source = ref3.source);
              $location.search("loginType", null);
              path = $location.path().replace(/\/$/, "");
              if (path !== "" && !(indexOf.call(GatekeeperRoutes, path) >= 0)) {
                sessionStorage.url = location.href;
              }
              isEditPage = /^\/page\/.+$/;
              if (loginType) {
                window.location = publicApiBaseUrl + "/auth/" + loginType;
              }
              if (
                indexOf.call(GatekeeperRoutes, path) < 0 &&
                (isEditPage.test(path) || source === COLLABORATOR_LINK_SOURCE)
              ) {
                if (path.includes("create-from-template")) {
                  window.location = "/#" + GatekeeperRoute.Login;
                } else {
                  sourceParam = source ? "?source=" + source : "";
                  window.location = "/#" + GatekeeperRoute.LoginOnly + sourceParam;
                }
              } else if (indexOf.call(GatekeeperRoutes, path) < 0) {
                window.location = "/#" + GatekeeperRoute.Login;
              }
            } else if (isRetrievingIndividualProject(response)) {
            } else {
              createUi = $rootScope.di.get("createUi");
              $rootScope.di
                .get("showAcknowledgeModal")({
                  title: "Your session has expired",
                  explainer: createUi(require("./UnauthorizedModalText.html")),
                  button: "Log back in",
                })
                .then(function (result) {
                  if (result) {
                    return window.location.reload();
                  }
                });
            }
            return $q.reject(response);
          } else if (
            _.includes(
              response != null ? ((ref4 = response.config) != null ? ref4.url : void 0) : void 0,
              "initial-data",
            )
          ) {
            if ($location.path().replace(/\/\?$/, "") !== GatekeeperRoute.Signup) {
              window.location = "/#" + GatekeeperRoute.Login;
            }
            return;
          }
          debouncedCapture(response);
          return $q.reject(response);
        }),
      };
    });
}.call(this));
