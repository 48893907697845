// Generated by CoffeeScript 1.12.7
(function () {
  var ProjectSecurer,
    slice = [].slice;

  module.exports = ProjectSecurer = (function () {
    function ProjectSecurer(make, showAcknowledgeModal) {
      /*
        Returns a list of enabled security measures
        for a given link.
       */
      var getLink, validate;
      this.getEnabledSecurityMeasures = function (link) {
        var enabled, ref, ref1;
        enabled = [];
        if (link.isPasswordProtected) {
          enabled.push("Password");
        }
        if (link.timeLimit != null && !link.timeLimit.forever) {
          enabled.push("Time Limit");
        }
        if ((ref = link.viewLimit) != null ? ref.enabled : void 0) {
          enabled.push("View Limit");
        }
        if ((ref1 = link.identityMapping) != null ? ref1.enabled : void 0) {
          enabled.push("Identify");
        }
        return enabled;
      };

      /*
        Returns whether the link is viewable by time limit
       */
      this.isWithinTimeLimit = function (linkOrProject) {
        var link, timeLimit;
        link = getLink(linkOrProject);
        timeLimit = link.timeLimit;
        if (timeLimit == null) {
          return true;
        } else if (timeLimit.forever) {
          return true;
        } else if (timeLimit.from != null && new Date(timeLimit.from) > Date.now()) {
          return false;
        } else if (timeLimit.until != null && new Date(timeLimit.until) < Date.now()) {
          return false;
        } else {
          return true;
        }
      };

      /*
        Returns whether a link is viewable by view limit
       */
      this.isWithinViewLimit = function (linkOrProject) {
        var limit, link, numVisits, ref, viewLimit;
        link = getLink(linkOrProject);
        viewLimit = link.viewLimit;
        limit = viewLimit != null ? viewLimit.limit : void 0;
        numVisits = (ref = link.receipt) != null ? ref.numberOfVisits : void 0;
        if (!(viewLimit != null ? viewLimit.enabled : void 0)) {
          return true;
        } else if (limit == null || !_.isNumber(limit)) {
          return true;
        } else if (limit === 0) {
          return false;
        } else if (numVisits == null) {
          return true;
        } else {
          return numVisits < limit;
        }
      };
      this.showTimeLimitWarning = function () {
        return showAcknowledgeModal({
          title: "Project is outside the time limit",
          explainer: "Please edit the time limit from the project's security settings.",
        });
      };
      this.isDisabled = function (project) {
        return project.status === "disabled";
      };
      validate = function () {
        var arg, args, i, len, results;
        args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
        results = [];
        for (i = 0, len = args.length; i < len; i++) {
          arg = args[i];
          if (arg == null) {
            throw new Error("Trying to modify security settings for a null project or link.");
          } else {
            results.push(void 0);
          }
        }
        return results;
      };

      /*
        When given a link or project, return the link
       */
      getLink = function (linkOrProject) {
        var ref;
        return ((ref = linkOrProject.links) != null ? ref[0] : void 0) || linkOrProject;
      };
    }

    return ProjectSecurer;
  })();
}.call(this));
