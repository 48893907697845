"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Splash = (function () {
    function Splash(block, carouselHandler, make, $timeout, createUi, account, Editor, VideoBackground, viewState, MediaCarousel, projectStore, projectEvents) {
        var _this = this;
        this.block = block;
        this.projectEvents = projectEvents;
        this.showMediaCarousel = function () {
            return _this.projectEvents.emit("bigComponentOpened", {
                type: "Media",
                blockId: _this.block._id,
            });
        };
        var hasResourceUrl = function () { var _a, _b; return ((_a = block.imageUrl) === null || _a === void 0 ? void 0 : _a.length) > 0 || ((_b = block.resourceUrl) === null || _b === void 0 ? void 0 : _b.length) > 0; };
        if (block.resourceType == null) {
            block.resourceType = "image";
        }
        if (block.resourceUrl == null) {
            block.resourceUrl = block.imageUrl || "";
        }
        if (!block.settings) {
            block.settings = {};
        }
        viewState.displayMode = hasResourceUrl();
        var ControlsCtrl = require("./Controls/Controls");
        var TextEditor = Editor;
        var BgLoader = require("./BackgroundImageLoader");
        var mainContent = null;
        var onDisplayMode = function () {
            return $timeout(function () {
                if (mainContent.placeholders != null) {
                    return mainContent.placeholders.places.map(function (placeholder) { return placeholder.reposition(); });
                }
            }, 200);
        };
        this.ui = createUi(require("./Splash.html"), {
            block: block,
            viewState: viewState,
            videoBackground: null,
            bgLoader: make(BgLoader),
            sizeClass: function () {
                if (!_this.ui.block.settings.withContent) {
                    return "fullpage";
                }
                else {
                    return _this.ui.block.settings.size;
                }
            },
            hasImage: function () {
                var _a;
                return _this.ui.block.imageUrl != null && ((_a = _this.ui.block.imageUrl) === null || _a === void 0 ? void 0 : _a.length) > 0;
            },
            showContent: function () {
                return _this.ui.block.settings.withContent && _this.ui.viewState.displayMode;
            },
            isVideo: function () {
                return block.resourceType === "video";
            },
            isImage: function () {
                return block.resourceType === "image";
            },
            isEmbed: function () {
                return block.resourceType === "embed";
            },
            shouldShowSplashControls: function () {
                return projectStore.isEditable && !_this.ui.showContent();
            },
            $init: function ($scope) {
                $scope.$watchGroup(["ui.block.resourceType", "ui.block.resourceUrl"], function () {
                    if (block.resourceType !== "image" && _this.mediaCarousel != null) {
                        _this.mediaCarousel.clearMessage();
                    }
                    if (block.resourceType === "embed" && block.resourceUrl) {
                        _this.ui.videoBackground = null;
                        $timeout(function () {
                            return (_this.ui.videoBackground = make(VideoBackground, { url: block.resourceUrl }));
                        }, 0);
                    }
                    if (block.resourceType === "image" && block.resourceUrl) {
                        _this.ui.bgLoader.ui.loadImage(block.resourceUrl, _this.mediaCarousel);
                    }
                    _this.ui.viewState.displayMode = hasResourceUrl();
                    if (_this.ui.viewState.displayMode && mainContent.placeholders != null) {
                        return onDisplayMode();
                    }
                });
                $scope.$watch("ui", function (ui) {
                    if (ui == null) {
                        return;
                    }
                    mainContent = make(TextEditor, {
                        model: block.contentAreas.main,
                        options: {},
                        configuration: "standard",
                    });
                    _this.ui.mainContent = mainContent.ui;
                    _this.remove = mainContent.remove;
                    if (_this.ui.showContent()) {
                        $timeout(function () { return onDisplayMode(); });
                    }
                    _this.ui.bgLoader.ui.loadImage(block.resourceUrl);
                    _this.mediaCarousel = make(MediaCarousel, {
                        block: _this.ui.block,
                        splashBlock: _this.ui,
                        parentViewState: viewState,
                    });
                    carouselHandler.addCarousel(_this.mediaCarousel.getCarousel());
                    var controls = make(ControlsCtrl, {
                        colors: account.settings.brand.colors,
                        onAddedContent: function () {
                            viewState.displayMode = true;
                            return onDisplayMode();
                        },
                    });
                    _this.ui.controls = controls.ui;
                });
            },
        });
    }
    return Splash;
}());
exports.default = Splash;
