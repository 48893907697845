// Generated by CoffeeScript 1.12.7
(function () {
  var TrialMessage, template;

  template = require("./TrialMessage.html");

  module.exports = TrialMessage = (function () {
    function TrialMessage(createUi, showMessage, amountRemaining, upgradeButtonOrigin, type, stringManipulater) {
      this.ui = createUi(template, {
        isShowingMessage: function () {
          return showMessage();
        },
        upgradeButtonOrigin: upgradeButtonOrigin,
        amountRemaining: (function (_this) {
          return function () {
            return stringManipulater.pluralise(amountRemaining, type);
          };
        })(this),
      });
    }

    return TrialMessage;
  })();
}.call(this));
