// Generated by CoffeeScript 1.12.7

/*
  Checks preview events to ensure that any preconditions are met. Before previewing the account's
  subdomain/domain needs to be set up, and we wish to notify the user if the project is disabled.

  @NOTE(blambo): Rather than opening the tab programmatically we rely on the preview event being
  triggered by clicking on an <a href> element. Some browsers and browser extensions attempt to
  block tabs being opened programmatically, so we set up previewing this way to help prevent those
  blocks.
 */

(function () {
  var FeatureFlag, ProjectPreviewer;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = ProjectPreviewer = (function () {
    function ProjectPreviewer(
      projectStatusChecker,
      projectLinkRetriever,
      accountService,
      make,
      overlayStack,
      user,
      showAcknowledgeModal,
      projectSecurer,
      usingFeature,
      rolesService,
      upseller,
      genericHost,
    ) {
      this.preview = (function (_this) {
        return function (project, event) {
          var shownMessaging;
          shownMessaging = _this.showAnyPreviewBlockingMessaging(project);
          if (!shownMessaging && event == null) {
            return window.open(_this.getPreviewLink(project));
          } else if (shownMessaging && event != null) {
            return event.preventDefault();
          }
        };
      })(this);
      this.showAnyPreviewBlockingMessaging = (function (_this) {
        return function (project) {
          if (!projectStatusChecker.isEnabled(project)) {
            projectStatusChecker.showDisabledWarning();
            return true;
          } else if (!projectSecurer.isWithinTimeLimit(project)) {
            projectSecurer.showTimeLimitWarning();
            return true;
          } else if (
            (!usingFeature(FeatureFlag.Quote) && project.quoteBlockCount > 0) ||
            (!usingFeature(FeatureFlag.Accept) && project.hasAcceptEnabled)
          ) {
            upseller.upsell({
              feature: "accept",
              featureLabel: "Pricing and accept blocks",
            });
            return true;
          } else {
            return false;
          }
        };
      })(this);
      this.getPreviewLink = function (project) {
        var urls;
        urls = projectLinkRetriever.getAllUrlsForProject(project);
        if (urls.subdomain) {
          return urls.subdomain;
        } else {
          return urls.primaryDomain;
        }
      };
      this.getPdfPreviewLink = function (project) {
        var urls;
        urls = projectLinkRetriever.getAllUrlsForProject(project);
        return urls.primaryDomain;
      };
    }

    return ProjectPreviewer;
  })();
}.call(this));
