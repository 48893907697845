// Generated by CoffeeScript 1.12.7
(function () {
  var isBlockBrowser;

  isBlockBrowser = require("../BlockNew/IsBlockBrowser").isBlockBrowser;

  module.exports = function (blockList, $log, project) {
    return function () {
      if (blockList.getLength() === 0) {
        $log.error("Got a zero length block list array for project: " + project._id);
        return true;
      } else {
        return blockList.getLength() <= 1 && isBlockBrowser(blockList.getBlockAtIndex(0));
      }
    };
  };
}.call(this));
