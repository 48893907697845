"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BehaviourBuilder_1 = require("../BehaviourBuilder");
var EditorTypes_1 = require("Project/QwilrEditor/EditorTypes");
var DragActions_1 = require("./DragActions");
exports.default = (function (di) {
    var dragStore = di.get("dragStore");
    var syntheticSelectionStore = di.get("syntheticSelectionStore");
    var dropLocationStore = di.get("dropLocationStore");
    var getConversionElem = di.get("getConversionElem");
    var router = di.get("router");
    return [
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Drag over widget")
            .isEvent(EditorTypes_1.EventType.DragOver)
            .doAction(function (event, editor) {
            var handled = (0, DragActions_1.maybeDragWidgets)({ editor: editor, event: event, dragStore: dragStore, dropLocationStore: dropLocationStore });
            if (handled) {
                event.preventDefault();
                event.stopPropagation();
                event.dataTransfer.dropEffect = "move";
            }
            return handled;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Drag enter widget")
            .isEvent(EditorTypes_1.EventType.DragEnter)
            .doAction(function (event, editor) {
            var handled = (0, DragActions_1.maybeDragWidgets)({ editor: editor, event: event, dragStore: dragStore, dropLocationStore: dropLocationStore });
            if (handled) {
                event.preventDefault();
                event.stopPropagation();
                event.dataTransfer.dropEffect = "move";
            }
            return handled;
        }),
        di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("Drop widget")
            .isEvent(EditorTypes_1.EventType.Drop)
            .doAction(function (event, editor) {
            var handled = (0, DragActions_1.maybeDropWidgets)({
                editor: editor,
                dragStore: dragStore,
                dropLocationStore: dropLocationStore,
                syntheticSelectionStore: syntheticSelectionStore,
                router: router,
                conversionElem: getConversionElem(),
            });
            if (handled) {
                event.preventDefault();
            }
        }),
    ];
});
