// Generated by CoffeeScript 1.12.7

/*
  This component is a vendor panel designed to appear in the new font
  selector. It accepts ttf files for upload and stores them in the
  `account.settings.brand.customFonts` array. That array is used to populate
  the font list.

  The customFonts array is just an array of qwilr font objects ready to be
  copied directly into `account.settings.brand.fonts`. Technically there's no
  reason they have to be `HostedWebfont`s, but that's all we add.

  It calls options.onSelected when a custom font is selected.
 */

(function () {
  var CustomFontPanel, WebFont;

  WebFont = require("webfontloader");

  module.exports = CustomFontPanel = (function () {
    function CustomFontPanel(createUi, make, $q, account, userFilesUrl, noop, options) {
      var fontList, fontMap, onSelected, updateFonts, uploader;
      onSelected = options.onSelected || noop;
      fontMap = {};
      fontList = make(require("../../FontList/FontList"), {
        options: {
          onSelected: function (font) {
            return onSelected(fontMap[font.family]);
          },
          onRemove: function (font) {
            var index;
            index = account.settings.brand.customFonts.indexOf(fontMap[font.family]);
            if (index > -1) {
              account.settings.brand.customFonts.splice(index, 1);
              return updateFonts();
            }
          },
          loadFont: function (fontFamily) {
            var deferred, font, url;
            font = fontMap[fontFamily];
            url = userFilesUrl + "/" + account.assetKey + "/fonts/" + font.filename + "/" + font.filename + ".css";
            deferred = $q.defer();
            WebFont.load({
              classes: false,
              custom: {
                families: [font.name],
                urls: [url],
              },
              fontactive: function () {
                return deferred.resolve();
              },
              fontinactive: function () {
                return deferred.reject(new Error("Font failed to load: " + fontFamily + " (" + url + ")"));
              },
            });
            return deferred.promise;
          },
        },
      });
      updateFonts = function () {
        var customFonts, font, i, len, name, uniqueFonts;
        customFonts = account.settings.brand.customFonts || [];
        fontMap = {};
        for (i = 0, len = customFonts.length; i < len; i++) {
          font = customFonts[i];
          fontMap[font.name] = font;
        }
        uniqueFonts = (function () {
          var results;
          results = [];
          for (name in fontMap) {
            font = fontMap[name];
            results.push({
              family: font.name,
              name: font.friendlyName,
            });
          }
          return results;
        })();
        return fontList.updateFonts(uniqueFonts);
      };
      uploader = make(require("./Uploader/Uploader"), {
        file: null,
        onUploaded: (function (_this) {
          return function (requestData) {
            var base, fontData;
            fontData = {
              friendlyName: requestData.data.friendlyName,
              name: requestData.data.name,
              filename: requestData.data.basename,
              vendor: "HostedWebfont",
            };
            if ((base = account.settings.brand).customFonts == null) {
              base.customFonts = [];
            }
            account.settings.brand.customFonts.push(fontData);
            return updateFonts();
          };
        })(this),
        options: {
          target: "font",
          nameInput: "Font name",
        },
      });
      this.ui = createUi(require("./Custom.html"), {
        uploader: uploader,
        fontList: fontList.ui,
      });
      this.activate = function () {
        return updateFonts();
      };
    }

    return CustomFontPanel;
  })();
}.call(this));
