"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (showDecideModal) {
    return function (_a) {
        var title = _a.title, explainer = _a.explainer, customHeader = _a.customHeader, cancelButton = _a.cancelButton, deleteButton = _a.deleteButton, deleting = _a.deleting, getErrorMessage = _a.getErrorMessage;
        return showDecideModal({
            title: title,
            explainer: explainer,
            customHeader: customHeader,
            getErrorMessage: getErrorMessage,
            noButton: cancelButton || "Cancel",
            yesButton: deleteButton || "Delete",
            yesButtonTheme: "delete",
            confirming: deleting,
        });
    };
});
