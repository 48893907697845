(function () {
  require("Common/AtomicUI/UpgradeButtonAngular/UpgradeButton.ng.html");

  module.exports = angular.module("qwilr.upgradebutton", []).directive("upgradeButton", function () {
    return {
      restrict: "EA",
      scope: {
        message: "@",
        origin: "@",
      },
      templateUrl: "Common/AtomicUI/UpgradeButtonAngular/UpgradeButton.ng.html",
      controller: function ($scope, $rootScope) {
        var di, ref;
        if (!((ref = $scope.message) != null ? ref.length : void 0)) {
          $scope.message = "Upgrade";
        }
        di = $rootScope.di.child();
        return di.invoke(function (initSubscription, trial, telemetry, account) {
          $scope.clickedUpgrade = function () {
            if ($scope.origin != null) {
              telemetry.trackOnly("Upgrade Prompt Viewed", {
                upgrade_prompt_view_origin: $scope.origin,
              });
            }
            return initSubscription.init();
          };
          $scope.canUpgrade = account.plan.displayName !== "Enterprise";
          if (trial.hasTimeLimit() && trial.daysRemaining() <= 5) {
            return ($scope.isInDanger = true);
          } else if (!trial.hasTimeLimit() && trial.hasProjectLimit() && trial.projectsRemaining <= 3) {
            return ($scope.isInDanger = true);
          } else {
            return ($scope.isInDanger = false);
          }
        });
      },
    };
  });
}.call(this));
