// Generated by CoffeeScript 1.12.7
(function () {
  var getFullName;

  getFullName = require("@CommonFrontendBackend/Utils/GetFullName")["default"];

  module.exports = function (style, user, newClassName) {
    var newStyle;
    newStyle = _.extend(_.cloneDeep(style), {
      $newTemporaryStyle: true,
      className: newClassName,
      name: "Untitled Style",
      deleted: false,
      createdAt: new Date().toString(),
      createdBy: user._id,
      author: getFullName(user),
    });
    delete newStyle.tag;
    delete newStyle.isFactoryStyle;
    return newStyle;
  };
}.call(this));
