// Generated by CoffeeScript 1.12.7
(function () {
  var Trial, TrialStore;

  TrialStore = require("../Trial/TrialStore").TrialStore;

  module.exports = Trial = (function () {
    function Trial(make, account, utils, projectCounter, user, invoke, telemetry) {
      this.trialStore = new TrialStore(account);
      this.isOnTrial = function () {
        return account.plan != null && account.plan.displayName === "Trial";
      };
      this.daysRemaining = (function (_this) {
        return function () {
          var ref, ref1;
          if (!_this.isOnTrial()) {
            return null;
          }
          if (!((ref = account.plan.timeTrial) != null ? ref.active : void 0)) {
            return null;
          }
          utils.assert(
            ((ref1 = account.timeTrial) != null ? ref1.endDate : void 0) != null,
            "Account does not have time trial set up, but is on a plan that uses one",
          );
          return utils.daysTillDate(new Date(_this.trialStore.timeTrial.endDate));
        };
      })(this);
      this.daysInTrialPeriod = (function (_this) {
        return function () {
          var ref, ref1, ref2;
          if (!_this.isOnTrial()) {
            return null;
          }
          if (!((ref = account.plan.timeTrial) != null ? ref.active : void 0)) {
            return null;
          }
          utils.assert(
            ((ref1 = account.timeTrial) != null ? ref1.startDate : void 0) != null,
            "Account does not have time trial set up, but is on a plan that uses one",
          );
          utils.assert(
            ((ref2 = account.timeTrial) != null ? ref2.endDate : void 0) != null,
            "Account does not have time trial set up, but is on a plan that uses one",
          );
          return utils.daysBetween(
            new Date(_this.trialStore.timeTrial.startDate),
            new Date(_this.trialStore.timeTrial.endDate),
          );
        };
      })(this);
      this.hasReachedProjectLimit = (function (_this) {
        return function () {
          if (!_this.hasProjectLimit()) {
            return false;
          }
          return _this.projectsRemaining() === 0;
        };
      })(this);
      this.projectsRemaining = (function (_this) {
        return function () {
          if (_this.hasProjectLimit()) {
            return Math.max(account.plan.maxProjects - projectCounter.projectCount, 0);
          } else {
            return 2e308;
          }
        };
      })(this);
      this.showTrialEndModal = function () {
        invoke(require("../Trial/TrialEndModal")["default"]);
      };
      this.shouldShowTrialStartModal = (function (_this) {
        return function () {
          var fromPlan, ref;
          fromPlan = (ref = user.useHistory.signup.entryPoint.metadata) != null ? ref.fromPlan : void 0;
          return _this.isOnTrial() && (fromPlan === "starter" || fromPlan === "pro");
        };
      })(this);
      this.showTrialStartModal = function () {
        var trialStartModal;
        trialStartModal = make(require("./TrialStartModal"));
        return trialStartModal.show();
      };
      this.isEndOfTrial = (function (_this) {
        return function () {
          var ref;
          return _this.isOnTrial() && ((ref = account.timeTrial) != null ? ref.inGracePeriod : void 0);
        };
      })(this);
      this.shouldShowTrialEndModal = (function (_this) {
        return function () {
          var ref;
          return _this.isEndOfTrial() || (ref = account.status) === "closed" || ref === "expired";
        };
      })(this);
      this.hasTimeLimit = function () {
        return account.plan.timeTrial != null && account.plan.timeTrial.active;
      };
      this.hasProjectLimit = function () {
        return account.plan.maxProjects != null && account.plan.maxProjects !== 0;
      };
    }

    return Trial;
  })();
}.call(this));
