(function () {
  var Upseller;

  module.exports = Upseller = (function () {
    function Upseller(
      createUi,
      user,
      account,
      imageLocation,
      projectLimit,
      projectLiveLimit,
      modalAngular,
      subscriptionService,
      $http,
      $log,
      apiBaseUrl,
      telemetry,
      utils,
      upsellerService,
      rolesService,
      $q,
    ) {
      var refresh, showModal, timeTrial, ui;
      this.upsell = function (options) {
        var featureLabel;
        if (options == null) {
          options = {};
        }
        if (options.feature) {
          telemetry.trackOnly("Upgrade Prompt Viewed", {
            upgrade_prompt_view_origin: options.feature,
          });
          featureLabel = options.featureLabel || options.feature;
          return $q
            .resolve()
            .then(function () {
              return upsellerService.getPlanNameForFeature(options.feature);
            })
            .then(function (planName) {
              if (planName != null) {
                ui.titleMessage = "Upgrade to the " + planName + " plan";
                if (options.customUpsellMessage != null) {
                  ui.customUpsellMessage = options.customUpsellMessage;
                  ui.upsellType = "custom";
                  if (options.customUpsellVideoLink != null) {
                    ui.customUpsellVideoLink = options.customUpsellVideoLink;
                  }
                  if (options.customUpsellHelpLink != null) {
                    ui.customUpsellHelpLink = options.customUpsellHelpLink;
                  }
                } else {
                  ui.upgradePlan = planName;
                  ui.upsellFeature = featureLabel;
                  ui.upsellType = "feature";
                }
                ui.upgradeMessaging = "View plan options";
                return showModal(options);
              } else {
                return $log.error('Looking for feature "' + options.feature + '" that is not contained in any plan.');
              }
            });
        } else if (options.showProjectUpsell) {
          telemetry.trackOnly("Upgrade Prompt Viewed", {
            upgrade_prompt_view_origin: "project limit",
          });
          ui.upgradePlan = null;
          ui.upsellFeature = null;
          ui.upsellType = "projectLimit";
          ui.upgradeMessaging = "Upgrade Now";
          if (projectLimit.hasProjectLimit()) {
            ui.limitBy = "projects";
            ui.callToAction = "Upgrade to create more projects.";
            ui.maxProjects = projectLimit.getMaxProjects();
            ui.currentProjects = projectLimit.getProjectCount();
          } else if (projectLiveLimit.hasProjectLimit()) {
            ui.limitBy = "live projects";
            ui.callToAction = "Switch some projects to draft or upgrade your plan in order to make your projects live";
            ui.maxProjects = projectLiveLimit.getMaxProjects();
            ui.currentProjects = projectLiveLimit.getProjectCount();
          }
          return showModal(options);
        } else {
          return $log.error("No upseller view option is provided.");
        }
      };
      timeTrial = account.timeTrial || {};
      ui = createUi(require("Common/Upseller/Upseller.html"), {
        currentPlan: account.plan.displayName,
        showCustomUpsell: function () {
          return ui.upsellType === "custom";
        },
        showFeatureUpsell: function () {
          return ui.upsellType === "feature";
        },
        showProjectUpsell: function () {
          return ui.upsellType === "projectLimit";
        },
        imageLocation: imageLocation,
        isAdmin: rolesService.isAdmin(user),
        startUpgrade: function () {
          modalAngular.hide();
          return subscriptionService.start();
        },
      });
      showModal = function (options) {
        if (options.blur) {
          $(options.blur).addClass("upsell-blurred");
        }
        return modalAngular.show(ui, {
          onHide: (function (_this) {
            return function () {
              refresh();
              if (options.blur) {
                $(options.blur).removeClass("upsell-blurred");
              }
              if (options.onClose) {
                return options.onClose();
              }
            };
          })(this),
        });
      };
      refresh = function () {
        ui.upgradePlan = null;
        ui.upsellFeature = null;
        ui.upgradeMessaging = "";
        ui.upsellType = null;
        ui.customUpsellHelpLink = null;
        ui.customUpsellVideoLink = null;
        return (ui.customUpsellMessage = null);
      };
    }

    return Upseller;
  })();
}.call(this));
