// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = [
    {
      name: "should leave state unchanged on checkpoint with a collapsed selection",
      content: "<p>abc</p><p>a|bc</p>",
    },
    {
      name: "should leave state unchanged on checkpoint with a selection",
      content: "<p>abc</p><p>a[bc]</p>",
    },
  ];
}.call(this));
