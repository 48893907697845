"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ToggleWithLabel = require("../../SettingsMenus/ToggleWithLabel");
var RecurringCosts = (function () {
    function RecurringCosts(createUi, block, make, telemetry) {
        var _this = this;
        this.block = block;
        this.telemetry = telemetry;
        this.getIncludeRecurringInDueToday = function () {
            var _a;
            return (_a = _this.block.includeRecurringInDueToday) !== null && _a !== void 0 ? _a : false;
        };
        this.setIncludeRecurringInDueToday = function (value) {
            _this.block.includeRecurringInDueToday = value;
            _this.telemetry.trackOnly("Quote Block Show Recurring In Total ".concat(value ? "Enabled" : "Disabled"), {}, { blockIntercom: true });
        };
        var includeRecurringInDueTodayToggle = make(ToggleWithLabel, {
            description: "Include in total",
            getter: this.getIncludeRecurringInDueToday,
            setter: this.setIncludeRecurringInDueToday,
        });
        this.ui = createUi(require("./RecurringCosts.html"), {
            includeRecurringInDueTodayToggle: includeRecurringInDueTodayToggle.ui,
        });
    }
    return RecurringCosts;
}());
exports.default = RecurringCosts;
