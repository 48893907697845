"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var DashboardFoldersViewStore_1 = require("./DashboardFoldersViewStore");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var DashboardTypes_1 = require("@CommonFrontendBackend/Dashboard/DashboardTypes");
var DashboardViewStore = (function () {
    function DashboardViewStore(permissionsDataStore, modalService, dashboardFoldersDataStore) {
        this.permissionsDataStore = permissionsDataStore;
        this._isShowingAllSearchResults = false;
        this._isSearchDropdownOpen = false;
        this._isMobileSidebarOpen = false;
        this._folderSelectionViewStore = new kaleidoscope_1.FolderSelectionViewStore(DashboardTypes_1.ProjectCollection.All);
        if (!!dashboardFoldersDataStore) {
            this._dashboardFoldersViewStore = new DashboardFoldersViewStore_1.DashboardFoldersViewStore(this._folderSelectionViewStore, dashboardFoldersDataStore, modalService);
        }
    }
    Object.defineProperty(DashboardViewStore.prototype, "folderSelectionViewStore", {
        get: function () {
            return this._folderSelectionViewStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardViewStore.prototype, "dashboardFoldersViewStore", {
        get: function () {
            return this._dashboardFoldersViewStore;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardViewStore.prototype, "selectedFolder", {
        get: function () {
            return this._folderSelectionViewStore.selectedFolder;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardViewStore.prototype, "openProjectActionMenuId", {
        get: function () {
            return this._openProjectActionMenuId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardViewStore.prototype, "openSharePopoverId", {
        get: function () {
            return this._openSharePopoverId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardViewStore.prototype, "openEngagementPopoverId", {
        get: function () {
            return this._openEngagementPopoverId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardViewStore.prototype, "isShowingAllSearchResults", {
        get: function () {
            return this._isShowingAllSearchResults;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardViewStore.prototype, "isSearchDropdownOpen", {
        get: function () {
            return this._isSearchDropdownOpen;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DashboardViewStore.prototype, "isMobileSidebarOpen", {
        get: function () {
            return this._isMobileSidebarOpen;
        },
        enumerable: false,
        configurable: true
    });
    DashboardViewStore.prototype.setIsShowingAllSearchResults = function (isShowingAllSearchResults) {
        this._isShowingAllSearchResults = isShowingAllSearchResults;
        this.setIsSearchDropdownOpen(false);
    };
    DashboardViewStore.prototype.setOpenProjectActionMenuId = function (projectId) {
        this.closeAllMenus();
        this._openProjectActionMenuId = projectId;
    };
    DashboardViewStore.prototype.closeProjectActionMenu = function () {
        this.closeAllMenus();
        this._openProjectActionMenuId = undefined;
    };
    DashboardViewStore.prototype.setOpenSharePopoverId = function (projectId) {
        this.permissionsDataStore.getPagePermissions(projectId).fetchData();
        this.closeAllMenus();
        this._openSharePopoverId = projectId;
    };
    DashboardViewStore.prototype.setOpenEngagementPopoverId = function (projectId) {
        this.closeAllMenus();
        this._openEngagementPopoverId = projectId;
    };
    DashboardViewStore.prototype.setIsSearchDropdownOpen = function (isSearchDropdownOpen) {
        this._isSearchDropdownOpen = isSearchDropdownOpen;
    };
    DashboardViewStore.prototype.setIsMobileSidebarOpen = function (isMobileSidebarOpen) {
        this._isMobileSidebarOpen = isMobileSidebarOpen;
    };
    DashboardViewStore.prototype.closeAllMenus = function () {
        this._openProjectActionMenuId = undefined;
        this._openSharePopoverId = undefined;
        this._openEngagementPopoverId = undefined;
        this._isSearchDropdownOpen = false;
        this._isMobileSidebarOpen = false;
    };
    var _a;
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], DashboardViewStore.prototype, "_isShowingAllSearchResults", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", String)
    ], DashboardViewStore.prototype, "_openProjectActionMenuId", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", String)
    ], DashboardViewStore.prototype, "_openSharePopoverId", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", String)
    ], DashboardViewStore.prototype, "_openEngagementPopoverId", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], DashboardViewStore.prototype, "_isSearchDropdownOpen", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], DashboardViewStore.prototype, "_isMobileSidebarOpen", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_a = typeof kaleidoscope_1.FolderSelectionState !== "undefined" && kaleidoscope_1.FolderSelectionState) === "function" ? _a : Object),
        __metadata("design:paramtypes", [])
    ], DashboardViewStore.prototype, "selectedFolder", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], DashboardViewStore.prototype, "openProjectActionMenuId", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DashboardViewStore.prototype, "isShowingAllSearchResults", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DashboardViewStore.prototype, "isSearchDropdownOpen", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], DashboardViewStore.prototype, "isMobileSidebarOpen", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], DashboardViewStore.prototype, "setIsShowingAllSearchResults", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], DashboardViewStore.prototype, "setOpenProjectActionMenuId", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DashboardViewStore.prototype, "closeProjectActionMenu", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], DashboardViewStore.prototype, "setOpenSharePopoverId", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], DashboardViewStore.prototype, "setOpenEngagementPopoverId", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], DashboardViewStore.prototype, "setIsSearchDropdownOpen", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], DashboardViewStore.prototype, "setIsMobileSidebarOpen", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], DashboardViewStore.prototype, "closeAllMenus", null);
    return DashboardViewStore;
}());
exports.default = DashboardViewStore;
