// Generated by CoffeeScript 1.12.7

/*
  Tax mapping settings for Xero
 */

(function () {
  var XeroTaxMappingSettings;

  module.exports = XeroTaxMappingSettings = (function () {
    function XeroTaxMappingSettings($http, $log, $q, createUi, account, apiBaseUrl, xero) {
      var ref, ref1, ref2, ref3;
      this.account = account;
      this.apiBaseUrl = apiBaseUrl;
      this.xero = xero;
      this.http = $http;
      this.log = $log;
      this.q = $q;
      this.ui = createUi(require("SettingsAngular/Integrations/Xero/XeroTaxMapping.html"), {
        viewState: {
          taxesLoading: false,
        },
        hasTaxes:
          (ref = this.account.settings) != null
            ? (ref1 = ref.modules) != null
              ? (ref2 = ref1.quote) != null
                ? (ref3 = ref2.taxes) != null
                  ? ref3.length
                  : void 0
                : void 0
              : void 0
            : void 0,
        settings: this.account.integrations.xero.settings,
        taxes: (function (_this) {
          return function () {
            var base, displayTaxes, ref4, ref5, ref6, taxMaps, taxes;
            taxes =
              ((ref4 = _this.account.settings) != null
                ? (ref5 = ref4.modules) != null
                  ? (ref6 = ref5.quote) != null
                    ? ref6.taxes
                    : void 0
                  : void 0
                : void 0) || [];
            if ((base = _this.account.integrations.xero).settings == null) {
              base.settings = {};
            }
            taxMaps = _this.account.integrations.xero.settings.taxMaps;
            if (taxMaps == null) {
              taxMaps = {};
            }
            displayTaxes = _.map(taxes, function (tax) {
              tax.$xeroId = taxMaps[tax.id];
              if (tax.$xeroId != null && tax.$selected == null) {
                tax.$selected = _.find(_this.ui.xeroTaxes, {
                  TaxType: tax.$xeroId,
                });
              }
              if (tax.$xeroId == null) {
                tax.$xeroId = "";
              }
              if (tax.$selected == null) {
                tax.$selected = {
                  TaxType: "",
                };
              }
              return tax;
            });
            return displayTaxes;
          };
        })(this),
        taxIsMapped: (function (_this) {
          return function (tax) {
            var ref4;
            return ((ref4 = tax.$xeroId) != null ? ref4.length : void 0) && tax.$xeroId === tax.$selected.TaxType;
          };
        })(this),
        mismatchedTax: (function (_this) {
          return function () {
            var i, len, tax, taxes;
            taxes = _this.ui.taxes();
            for (i = 0, len = taxes.length; i < len; i++) {
              tax = taxes[i];
              if (!_this.ui.selectedTaxMatches(tax)) {
                return tax;
              }
            }
            return null;
          };
        })(this),
        selectedTaxMatches: (function (_this) {
          return function (tax) {
            var ratesExist, ratesMatch;
            ratesExist = tax.$selected.EffectiveRate != null;
            ratesMatch = tax.percent === parseFloat(tax.$selected.EffectiveRate);
            return !ratesExist || ratesMatch;
          };
        })(this),
        setTaxMap: (function (_this) {
          return function (tax) {
            var obj;
            return _this.http
              .post(
                _this.apiBaseUrl + "/accounts/" + _this.account._id,
                ((obj = {}), (obj["integrations.xero.settings.taxMaps." + tax.id] = tax.$selected.TaxType), obj),
              )
              .then(function (response) {
                var base;
                if ((base = _this.ui.settings).taxMaps == null) {
                  base.taxMaps = {};
                }
                return (_this.ui.settings.taxMaps[tax.id] = tax.$selected.TaxType);
              })
              ["catch"](function (err) {
                return _this.log.error(err);
              });
          };
        })(this),
        getXeroTaxes: (function (_this) {
          return function () {
            var ref4;
            if ((ref4 = _this.ui.xeroTaxes) != null ? ref4.length : void 0) {
              return _this.q.when(_this.ui.xeroTaxes);
            }
            _this.ui.viewState.taxesLoading = true;
            return _this.http
              .get(_this.apiBaseUrl + "/" + _this.account._id + "/integration/xero?type=taxes")
              .then(function (response) {
                var xeroTaxes;
                xeroTaxes = _.map(response.data.Response.TaxRates.TaxRate, function (rate) {
                  rate.$display = rate.Name + " - " + rate.EffectiveRate + "%";
                  return rate;
                });
                _this.ui.viewState.taxesLoading = false;
                return xeroTaxes;
              })
              ["catch"](function (err) {
                if (err.message === "REAUTH") {
                  _this.xero.enable();
                } else {
                  _this.log.error(err);
                }
                _this.ui.viewState.taxesLoading = false;
                return [];
              });
          };
        })(this),
        $init: (function (_this) {
          return function () {
            return _this.ui.getXeroTaxes().then(function (taxes) {
              return (_this.ui.xeroTaxes = taxes);
            });
          };
        })(this),
      });
    }

    return XeroTaxMappingSettings;
  })();
}.call(this));
