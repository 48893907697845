// Generated by CoffeeScript 1.12.7
(function () {
  var OptionalQuantity, ToggleWithLabel, template;

  template = require("./OptionalQuantity.html");

  ToggleWithLabel = require("./ToggleWithLabel");

  module.exports = OptionalQuantity = (function () {
    function OptionalQuantity(createUi, make, description, itemAdapter, itemView) {
      var changeMax, changeMin, init, setQuantityOptional, toggleWithLabel, updateView;
      toggleWithLabel = null;
      this.ui = null;
      init = (function (_this) {
        return function () {
          toggleWithLabel = make(ToggleWithLabel, {
            description: description,
            getter: itemAdapter.isQuantityOptional,
            setter: setQuantityOptional,
          });
          _this.ui = createUi(template, {
            toggleUi: toggleWithLabel.ui,

            /*
            Instead of directly modifying the 'min' and 'max' in the item model, we use the setter
            functions on the ItemAdapter to validate the range inputs.
             */
            min: null,
            max: null,
            isQuantityOptional: function () {
              return itemAdapter.isQuantityOptional();
            },
            minChanged: function () {
              return changeMin(_this.ui.min);
            },
            maxChanged: function () {
              return changeMax(_this.ui.max);
            },
          });
          return updateView();
        };
      })(this);
      setQuantityOptional = function (isOptional) {
        itemAdapter.setQuantityOptional(isOptional);
        return updateView();
      };
      changeMin = (function (_this) {
        return function (newMin) {
          try {
            itemAdapter.setQuantityRangeMin(newMin);
          } catch (error) {}
          return updateView();
        };
      })(this);
      changeMax = (function (_this) {
        return function (newMax) {
          try {
            itemAdapter.setQuantityRangeMax(newMax);
          } catch (error) {}
          return updateView();
        };
      })(this);
      updateView = (function (_this) {
        return function () {
          _this.ui.min = itemAdapter.getQuantityRangeMin();
          _this.ui.max = itemAdapter.getQuantityRangeMax();
          return itemView.refreshQuantity();
        };
      })(this);
      init();
    }

    return OptionalQuantity;
  })();
}.call(this));
