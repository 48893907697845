// Generated by CoffeeScript 1.12.7
(function () {
  var colorUtils;

  colorUtils = require("@CommonFrontendBackend/ColorUtils/ColorUtils");

  module.exports = function (uniqueId, $q, $timeout) {
    return {
      randomString: require("@CommonFrontendBackend/Utils/RandomString")["default"],
      getNested: function (object, path) {
        var index;
        index = function (obj, key) {
          return obj[key];
        };
        return path.split(".").reduce(index, obj);
      },
      uniqueId: uniqueId,
      isValidHex: colorUtils.isValidHex,
      shouldUseDarkText: colorUtils.shouldUseDarkText,
      mixColors: colorUtils.mixColors,
      areMatchingColors: colorUtils.areColorsEqual,
      daysTillDate: function (endDate) {
        var differenceInMilliseconds;
        differenceInMilliseconds = endDate.getTime() - Date.now();
        if (differenceInMilliseconds >= 0) {
          return Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        } else {
          return 0;
        }
      },
      daysBetween: function (startDate, endDate) {
        var differenceInMilliseconds;
        differenceInMilliseconds = endDate.getTime() - startDate.getTime();
        if (differenceInMilliseconds >= 0) {
          return Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
        } else {
          return 0;
        }
      },
      assert: function (truth, msg) {
        if (!truth) {
          msg || (msg = "assertion failure");
          throw new Error(msg);
        }
      },
      getRandomElement: function (array) {
        return array[Math.floor(Math.random() * array.length)];
      },
      extractNames: require("@CommonFrontendBackend/Utils/ExtractNames"),
      getFullName: require("@CommonFrontendBackend/Utils/GetFullName")["default"],
      isValidEmail: function (emailString) {
        var emailFormat;
        emailFormat = /^((?!\.)[a-z0-9._%+-]+(?!\.)\w)@[a-z0-9-\.]+\.[a-z.]{1,5}(?!\.)\w$/i;
        if (emailString == null || !emailString.length || !emailFormat.test(emailString)) {
          return false;
        }
        return true;
      },
      isEmailOnDomain: function (email, domain) {
        var emailDomain;
        emailDomain = email.replace(/.*@/, "");
        return emailDomain === domain;
      },
      isValidPhoneNumber: function (phoneNumberString) {
        var numberFormat;
        numberFormat = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
        if (!phoneNumberString.length) {
          return true;
        }
        if (!numberFormat.test(phoneNumberString)) {
          return false;
        }
        return true;
      },

      /*
        Promise that resolves after a given duration
       */
      delay: function (duration) {
        var delaying;
        delaying = $q.defer();
        $timeout(function () {
          return delaying.resolve();
        }, duration);
        return delaying.promise;
      },
      isValidIpAddress: function (ipString) {
        var ipFormat;
        ipFormat = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
        if (ipString == null || !ipString.length || !ipFormat.test(ipString)) {
          return false;
        }
        return true;
      },
    };
  };
}.call(this));
