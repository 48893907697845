"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setDragImage = void 0;
var isPreChromiumEdge = !!window.StyleMedia;
function setDragImage(event, dragImageNode, positionForEdge) {
    if (positionForEdge === void 0) { positionForEdge = {
        top: "0",
        left: "0",
    }; }
    var offsetX = 30;
    var offsetY = 40;
    if (isPreChromiumEdge) {
        dragImageNode.style.position = "fixed";
        var top = positionForEdge.top, left = positionForEdge.left;
        dragImageNode.style.top = top;
        dragImageNode.style.left = left;
        offsetX = 5;
        offsetY = 5;
    }
    else {
        dragImageNode.style.position = "absolute";
        dragImageNode.style.top = "-1000px";
    }
    document.body.appendChild(dragImageNode);
    event.dataTransfer.setDragImage(dragImageNode, offsetX, offsetY);
    setTimeout(function () {
        document.body.removeChild(dragImageNode);
    });
}
exports.setDragImage = setDragImage;
