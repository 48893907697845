// Generated by CoffeeScript 1.12.7
(function () {
  var AuditInspector, AuditTrail;

  AuditTrail = require("./AuditTrail");

  module.exports = AuditInspector = (function () {
    function AuditInspector(make) {
      this.inspect = function (project) {
        var inspector;
        inspector = make(AuditTrail, {
          project: project,
        });
        return inspector.show();
      };
    }

    return AuditInspector;
  })();
}.call(this));
