"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var StylingActions = __importStar(require("./StylingActions"));
var TransformActions = __importStar(require("./TransformActions"));
var slate_1 = require("slate");
var ShortcutActions_1 = require("./ShortcutActions");
var slate_react_1 = require("slate-react");
exports.default = (function (di) {
    return __assign(__assign({}, colonPack(di)), { arrow: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("shortcutArrow")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.AngleBracketClose)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(/-\s*$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            slate_1.Transforms.insertText(editor, ">");
            slate_1.Transforms.delete(editor, { distance: 2, reverse: true });
            slate_1.Transforms.insertText(editor, "→");
            return true;
        }), smiley: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("smiley")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.CloseBracket)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(/:$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, ShortcutActions_1.insertEmoji)(editor, ")", "🙂");
            return true;
        }), frown: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("frown")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.OpenBracket)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(/:$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, ShortcutActions_1.insertEmoji)(editor, "(", "🙁");
            return true;
        }), tongue: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("tongue")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.CapitalP)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(/:$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, ShortcutActions_1.insertEmoji)(editor, "P", "😛");
            return true;
        }), grin: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("grin")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.CapitalD)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(/:$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, ShortcutActions_1.insertEmoji)(editor, "D", "😀");
            return true;
        }), gasp: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("gasp")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.CapitalO)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(/:$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, ShortcutActions_1.insertEmoji)(editor, "O", "😲");
            return true;
        }), wink: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("wink")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.CloseBracket)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(/;$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, ShortcutActions_1.insertEmoji)(editor, ")", "😉");
            return true;
        }), confused: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("confused")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Slash)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(/:$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, ShortcutActions_1.insertEmoji)(editor, "/", "😕");
            return true;
        }), glasses: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("glasses")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.CloseBracket)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(/B-$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            slate_1.Transforms.insertText(editor, ")");
            slate_1.Transforms.delete(editor, { distance: 3, reverse: true });
            slate_1.Transforms.insertText(editor, "🤓");
            return true;
        }), sunglasses: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("sunglasses")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Line)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(/B$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, ShortcutActions_1.insertEmoji)(editor, "|", "😎");
            return true;
        }), heart: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("heart")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Three)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(/<$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            (0, ShortcutActions_1.insertEmoji)(editor, "3", "❤️");
            return true;
        }), makeLink: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("makeLink")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.K)
            .withModifier(BehaviourBuilder_1.KeyModifier.CtrlCmd)
            .selectionIsExpanded()
            .doAction(function (event, editor) {
            event.preventDefault();
            slate_react_1.ReactEditor.blur(editor);
            TransformActions.toggleLink(editor);
            return true;
        }), ctrl_b: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("ctrlB")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.B)
            .withModifier(BehaviourBuilder_1.KeyModifier.CtrlCmd)
            .doAction(function (event, editor) {
            event.preventDefault();
            StylingActions.makeBold(editor);
            return true;
        }), ctrl_i: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("ctrlI")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.I)
            .withModifier(BehaviourBuilder_1.KeyModifier.CtrlCmd)
            .doAction(function (event, editor) {
            event.preventDefault();
            StylingActions.makeItalic(editor);
            return true;
        }), blockquote: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("blockquoteShortcut")
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Space)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .inBlockType(["paragraph", "heading2", "heading1"])
            .isNotOfAncestry("accordionHeading")
            .notInTable()
            .hasTextSequence(/^>$/)
            .doAction(function (event, editor) {
            event.preventDefault();
            slate_1.Transforms.insertText(editor, " ");
            slate_1.Transforms.delete(editor, { distance: 2, reverse: true });
            TransformActions.setValidBlocks(editor, "blockQuote", { clearStyleOverrides: true });
            return true;
        }) });
});
var colonMap = [
    {
        name: "poop",
        emoji: "💩",
    },
    {
        name: "joy",
        emoji: "😂",
    },
    {
        name: "fire",
        emoji: "🔥",
    },
    {
        name: "thumbsUp",
        shortcut: "thumbsup",
        emoji: "👍",
    },
    {
        name: "thumbsDown",
        shortcut: "thumbsdown",
        emoji: "👎",
    },
    {
        name: "rocket",
        emoji: "🚀",
    },
    {
        name: "hundred",
        shortcut: "100",
        emoji: "💯",
    },
    {
        name: "raisedHands",
        shortcut: "raised_hands",
        emoji: "🙌",
    },
    {
        name: "pray",
        emoji: "🙏",
    },
    {
        name: "flex",
        emoji: "💪",
    },
    {
        name: "clap",
        emoji: "👏",
    },
    {
        name: "ok",
        emoji: "👌",
    },
    {
        name: "tada",
        emoji: "🎉",
    },
    {
        name: "wave",
        emoji: "👋",
    },
    {
        name: "sparkles",
        emoji: "✨",
    },
    {
        name: "shrug",
        emoji: "¯\\_(ツ)_/¯",
    },
];
var colonPack = function (di) {
    var e_1, _a;
    var colonShortcuts = {};
    var _loop_1 = function (name, emoji, shortcut) {
        colonShortcuts[name] = di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled(name)
            .isEvent(EditorTypes_1.EventType.Key)
            .withKey(BehaviourBuilder_1.KeyType.Colon)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .hasTextSequence(new RegExp(":".concat(shortcut || name)))
            .doAction(function (event, editor) {
            event.preventDefault();
            slate_1.Transforms.insertText(editor, ":");
            slate_1.Transforms.delete(editor, { distance: (shortcut || name).length + 2, reverse: true });
            slate_1.Transforms.insertText(editor, emoji);
            return true;
        });
    };
    try {
        for (var colonMap_1 = __values(colonMap), colonMap_1_1 = colonMap_1.next(); !colonMap_1_1.done; colonMap_1_1 = colonMap_1.next()) {
            var _b = colonMap_1_1.value, name = _b.name, emoji = _b.emoji, shortcut = _b.shortcut;
            _loop_1(name, emoji, shortcut);
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (colonMap_1_1 && !colonMap_1_1.done && (_a = colonMap_1.return)) _a.call(colonMap_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
    return colonShortcuts;
};
