import "Application/SettingsAngular/Notifications/V2Notifications.css.ts.vanilla.css!=!../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/SettingsAngular/Notifications/V2Notifications.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA8WU3Y7bIBCF7/MUI1WVEilEgE38s1etqj5AXyDCNrHJYrCAtF5V++6VsS3HWWVvutJewpwZ+M4wHE6E9rLgCsPfDcAfWfkmB4Lx16fN6+YwR0mItrxHkyJO065/2gBwJWuNpBety8F5K3zZrFLpbeHoOGY1QtaNX9Ydryqp6xymdWFsJSyyvJJXl8NvbrcIjZu/wh4qjfbWqNOJFCq5XAipd0NiaZSxOXzB+PuPbz9DKV4+19ZcdYWmoK0LvsV7IGm2B5KwPeADxSG95baWGhXGe9PO57qOl1LXqHfLac+7FWQUIL3oPQqG5KDE2a/I7mrd3Nysa8VLLW+5dmdj2xy00WIlY0F2VqJHBXfS5cCGpgG0Us9dIgke/LzJOoasR5d6DJjcdjGmd13M/rNroyYH0vXgjJLV0KNtmgJJY6As2j1s5JZGBGjMgDIWVJV0neIveXDmzQMthfbCDtuXq/Py/BJuJLRfQjfQ6SdAkywFkh2Bxtl71PFAjYGy+MOps0+gpowBxRmk70IPIoaBRumHQ/Ppgwvjb0dWdjc7xaiRGs1uMHonKYNkMmf+RRbU0aaANJm12IPXA1dBDR33zTjlUqnRgwQIwUDx4MDrP1LVFiq9BQAA\"}!../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var wrapper = '_12xibal0';
export var content = '_12xibal1';
export var framedNotificationIcon = '_12xibal2';
export var v2NotificationCard = '_12xibal3';
export var toggleStyle = '_12xibal4';
export var dropDownStyle = '_12xibal5';
export var customNumberSelect = '_12xibal6';
export var engagementIconStyle = '_12xibal7';
export var viewershipIconStyle = '_12xibal8';
export var transactionIconStyle = '_12xibal9';
export var inputUnitText = '_12xibala';
export var settingsRow = _7a468({defaultClassName:'_12xibalb',variantClassNames:{hasDivider:{true:'_12xibalc'}},defaultVariants:{},compoundVariants:[]});
export var blueSvg = '_12xibald';