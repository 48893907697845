// Generated by CoffeeScript 1.12.7

/*
  Simple paginated wrapper around the existing 'cdn' that the old VideoPicker was using
  Modelled off the ImageService paginated get functions
 */

(function () {
  var StockVideoService,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = StockVideoService = (function () {
    function StockVideoService($q, imageLocation, utils) {
      this.getVideos = bind(this.getVideos, this);
      this.q = $q;
      this.imageLocation = imageLocation;
      this.utils = utils;
      this.list = require("./../../MediaPicker/StockVideos").VIDEOS;
    }

    StockVideoService.prototype.getVideos = function (page, itemsPerPage) {
      var pageVideos, response, startIndex;
      startIndex = page * itemsPerPage;
      pageVideos = this.list.slice(startIndex, startIndex + itemsPerPage);
      response = _.map(
        pageVideos,
        (function (_this) {
          return function (videoFile) {
            var fileName;
            fileName = videoFile.replace(".mp4", "");
            return {
              videoUrl: _this.imageLocation.stockVideo(videoFile),
              thumbnailUrl: _this.imageLocation.stockVideo(fileName + ".jpg"),
              id: _this.utils.uniqueId(12),
            };
          };
        })(this),
      );
      return this.q.resolve(response);
    };

    return StockVideoService;
  })();
}.call(this));
