// Generated by CoffeeScript 1.12.7

/*
 * We need to grab the user's Freshbooks subdomain before going through Oauth process
 * so we get them to enter it here.
 */

(function () {
  var FreshBooksEnable;

  module.exports = FreshBooksEnable = (function () {
    function FreshBooksEnable($q, $log, $http, $timeout, createUi, overlayStack, account, ActionButton, make) {
      var base, base1, onError, submitButton;
      if (account.integrations == null) {
        account.integrations = {};
      }
      if ((base = account.integrations).freshbooks == null) {
        base.freshbooks = {};
      }
      if ((base1 = account.integrations.freshbooks).settings == null) {
        base1.settings = {};
      }
      submitButton = make(ActionButton, {
        config: {
          standard: "Set FreshBooks subdomain",
          waiting: "Setting FreshBooks subdomain...",
          success: "Set FreshBooks subdomain",
          error: "Error setting FreshBooks subdomain",
        },
        onClick: (function (_this) {
          return function () {
            if (!_this.enabling) {
              $log.error("No promise defined during freshbooks enabling");
              overlayStack.closeAll();
              return;
            }
            if (!_this.ui.subdomain.length) {
              return onError("Please enter a value for your FreshBooks Subdomain");
            }
            return $q.resolve(_this.enabling.resolve(_this.ui.subdomain));
          };
        })(this),
      });
      this.ui = createUi(require("./FreshBooksEnable.html"), {
        error: "",
        subdomain: account.integrations.freshbooks.settings.subdomain || "",
        submitButton: submitButton.ui,
      });
      this.show = (function (_this) {
        return function () {
          _this.enabling = $q.defer();
          _this.ui.enabling = false;
          return _this.enabling.promise.then(function (site) {
            _this.ui.enabling = true;
            return site;
          });
        };
      })(this);
      onError = (function (_this) {
        return function (errorMessage) {
          _this.ui.error = errorMessage;
          $timeout(function () {
            return (_this.ui.error = "");
          }, 5000);
          return $q.reject(new Error("Error setting FreshBooks subdomain"));
        };
      })(this);
    }

    return FreshBooksEnable;
  })();
}.call(this));
