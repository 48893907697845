// Generated by CoffeeScript 1.12.7
(function () {
  var StyleCarousel,
    StyleEditor,
    cloneBlockStyleObject,
    getBlockStyleObject,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  StyleEditor = require("Project/BlockNew/Menu/StyleSelector/StyleEditor/StyleEditor.js");

  getBlockStyleObject = require("Project/BlockNew/Menu/StyleSelector/GetBlockStyleObject.js");

  cloneBlockStyleObject = require("Project/BlockNew/Menu/StyleSelector/CloneBlockStyleObject");

  /*
    Carousel for showing styles and opening the style editor

    @NOTE (som 2017-08-03): We use the projectEvents EventEmitter to report when a new style
    has been created, since all style carousels need to refresh when that happens. We have
    discussed possibly changing this to a Redux model in the future
   */

  module.exports = StyleCarousel = (function () {
    function StyleCarousel(block, make, invoke, createUi, account, user, utils, projectEvents, $timeout, styleService) {
      var buttonsLeft,
        buttonsRight,
        createNewStyle,
        createNewStyleButtonUi,
        elementRenderedHook,
        getCurrentStyle,
        getFilter,
        getStyles,
        isAccountStyleIncluded,
        isCurrentStyle,
        isFactoryStyleIncluded,
        isSearchQueryInStyleName,
        onSelect,
        ref,
        searchButton,
        selectStyleInCarousel,
        stylesAppliedCounter,
        stylesFilter,
        stylesTelemetry,
        v1DefaultStyles,
        viewState;
      v1DefaultStyles = styleService.getDefaultStyles(block.type);
      isCurrentStyle = function (style) {
        return style.className === block.settings.style;
      };
      getCurrentStyle = function () {
        var currentStyle;
        currentStyle = _.find(account.styles, isCurrentStyle);
        if (currentStyle == null) {
          currentStyle = _.find(v1DefaultStyles, isCurrentStyle);
        }
        return currentStyle;
      };
      getFilter = function () {
        var ref, style;
        style = getCurrentStyle();
        if ((style != null ? style.tag : void 0) != null) {
          return style.tag;
        } else if (style != null) {
          return "Custom";
        } else if (block.type === "Splash") {
          return "Headers";
        } else if ((ref = block.type) === "Text" || ref === "Video" || ref === "Source") {
          return "Titles";
        } else {
          return "Custom";
        }
      };
      viewState = {
        createNewStyleButton: true,
        searchQuery: null,
        filter: getFilter(),
      };
      stylesTelemetry = invoke(require("./StylesTelemetry"));
      getStyles = function () {
        var reversedStyles;
        if (viewState.filter === "Custom") {
          reversedStyles = _.orderBy(account.styles, "createdAt", "desc");
          return _.filter(reversedStyles, isAccountStyleIncluded);
        } else {
          return _.filter(v1DefaultStyles, isFactoryStyleIncluded);
        }
      };
      isSearchQueryInStyleName = function (style) {
        var ref;
        if (((ref = viewState.searchQuery) != null ? ref.length : void 0) > 0) {
          return _.includes(style.name.toLowerCase(), viewState.searchQuery.toLowerCase());
        } else {
          return true;
        }
      };
      isAccountStyleIncluded = function (style) {
        var ref, shouldInclude;
        if (style.deleted) {
          return false;
        }
        if (style.version === 1) {
          shouldInclude = ((ref = block.type), indexOf.call(style.blockTypes, ref) >= 0);
        } else {
          shouldInclude = block.type === style.blockType;
        }
        return shouldInclude && isSearchQueryInStyleName(style);
      };
      isFactoryStyleIncluded = function (style) {
        var ref, shouldInclude;
        shouldInclude =
          ((ref = block.type), indexOf.call(style.blockTypes, ref) >= 0) && style.tag === viewState.filter;
        return shouldInclude && isSearchQueryInStyleName(style);
      };

      /*
        Refresh the carousel data and selected state
       */
      this.refreshData = (function (_this) {
        return function () {
          var carouselData;
          if (_this.carousel != null) {
            carouselData = getStyles();
            _this.carousel.ui.clear();
            _this.carousel.ui.setData(carouselData);
            if (!_this.carousel.ui.viewState.hidden) {
              return selectStyleInCarousel();
            }
          }
        };
      })(this);
      selectStyleInCarousel = (function (_this) {
        return function () {
          var ref;
          return (ref = _this.carousel) != null
            ? ref.ui.setState(function (styleObject) {
                if (!styleObject.data.deleted && styleObject.data.className === block.settings.style) {
                  _this.carousel.ui.setTitle(styleObject.data.name);
                  return true;
                } else {
                  return false;
                }
              })
            : void 0;
        };
      })(this);
      stylesAppliedCounter = 0;
      onSelect = (function (_this) {
        return function (arg) {
          var data, thisStyleCounter, ui;
          (ui = arg.ui), (data = arg.data);
          if (block.settings.style !== data.className) {
            block.settings.style = data.className;
            _this.carousel.ui.setTitle(data.name);
            ++stylesAppliedCounter;
            thisStyleCounter = stylesAppliedCounter;
            if (block.settings.tint != null) {
              delete block.settings.tint;
            }
            return $timeout(function () {
              if (thisStyleCounter === stylesAppliedCounter) {
                return stylesTelemetry.trackStyleApplied(data.className);
              }
            }, 4000);
          }
        };
      })(this);
      elementRenderedHook = function () {
        return selectStyleInCarousel();
      };
      createNewStyle = (function (_this) {
        return function () {
          var currentStyle, styleObject;
          currentStyle = getCurrentStyle();
          if ((currentStyle != null ? currentStyle.version : void 0) === 1) {
            styleObject = cloneBlockStyleObject(currentStyle, user, "style-" + utils.uniqueId(8));
          } else {
            styleObject = getBlockStyleObject(block.type, user, "style-" + utils.uniqueId(8));
          }
          account.styles.push(styleObject);
          return make(StyleEditor, {
            style: styleObject,
            onSaveHook: function (style) {
              stylesTelemetry.trackStyleCreate();
              stylesTelemetry.trackStyleApplied();
              _this.carousel.ui.setTitle(style.name);
              return projectEvents.emit("stylesModified");
            },
          });
        };
      })(this);
      projectEvents.on(
        "stylesModified",
        (function (_this) {
          return function () {
            return _this.refreshData();
          };
        })(this),
      );

      /*
        The rest of the constructor
       */
      buttonsRight = [];
      searchButton = make(require("Common/AtomicUI/SearchButton/SearchButton"), {
        placeholder: "Search styles",
        getSearchResults: (function (_this) {
          return function (searchQuery) {
            viewState.searchQuery = searchQuery;
            return _this.refreshData();
          };
        })(this),
        searchQuery: viewState.searchQuery,
        onOpen: (function (_this) {
          return function () {
            _this.carousel.ui.setEmptyContentMessage("Oops! There was no content found for your search");
            return (viewState.searchEnabled = true);
          };
        })(this),
        onClose: (function (_this) {
          return function () {
            _this.carousel.ui.setEmptyContentMessage("Click 'Create New' to create a style");
            viewState.searchEnabled = false;
            viewState.searchQuery = null;
            return _this.refreshData();
          };
        })(this),
      });
      buttonsLeft = [searchButton];
      if ((ref = block.type) !== "Quote" && ref !== "QuoteV2") {
        createNewStyleButtonUi = createUi(require("./CarouselButton/CreateStyleButton.html"), {
          name: "createNewStyleButton",
          text: "Create New",
          icon: "Quad16",
          viewState: viewState,
          onClick: (function (_this) {
            return function () {
              return createNewStyle();
            };
          })(this),
        });
        buttonsRight.push({
          ui: createNewStyleButtonUi,
        });
        stylesFilter = make(require("./CarouselButton/StylesFilter"), {
          blockType: block.type,
          initialValue: viewState.filter,
          onSelect: (function (_this) {
            return function (filter) {
              viewState.filter = filter;
              return _this.refreshData();
            };
          })(this),
        });
        buttonsLeft.push(stylesFilter);
      }
      this.carousel = make(require("./Carousel"), {
        options: {
          name: "Style",
          onSelect: onSelect,
          elementRenderedHook: elementRenderedHook,
          tileFactory: make(require("./Factories/StyleTileFactory")),
          buttonsRight: buttonsRight,
          buttonsLeft: buttonsLeft,
          emptyContentMessage: "Click 'Create New' to create a style",
          customClass: "style-carousel",
        },
      });
      this.refreshData();
    }

    return StyleCarousel;
  })();
}.call(this));
