"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var currenciesCreator = require("Utils/Currencies");
var CurrenciesList = (function () {
    function CurrenciesList(imageLocation) {
        var _this = this;
        this.get = function (code) {
            return (0, lodash_1.find)(_this.currencies, { code: code });
        };
        this.getByCountry = function (country) {
            return (0, lodash_1.find)(_this.currencies, { country: country });
        };
        this.search = function (searchValue, maxResults) {
            var e_1, _a;
            if (maxResults === void 0) { maxResults = Infinity; }
            var searchRegExp = new RegExp("".concat(searchValue), "i");
            var results = [];
            try {
                for (var _b = __values(_this.currencies), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var currency = _c.value;
                    if (_this.matchesCurrency(currency, searchRegExp)) {
                        results.push(currency);
                        if (results.length >= maxResults) {
                            break;
                        }
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return results;
        };
        this.matchesCurrency = function (currency, searchRegExp) {
            return (currency.name.search(searchRegExp) >= 0 ||
                currency.code.search(searchRegExp) >= 0 ||
                currency.country.search(searchRegExp) >= 0);
        };
        this.currencies = currenciesCreator(imageLocation);
    }
    return CurrenciesList;
}());
exports.default = CurrenciesList;
