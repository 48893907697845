// Generated by CoffeeScript 1.12.7
(function () {
  var ProjectCounter,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = ProjectCounter = (function () {
    function ProjectCounter(initialProjectCount) {
      this.hasProjects = bind(this.hasProjects, this);
      this.remove = bind(this.remove, this);
      this.add = bind(this.add, this);
      this.projectCount = initialProjectCount;
    }

    ProjectCounter.prototype.add = function (number) {
      if (number == null) {
        number = 1;
      }
      return (this.projectCount += number);
    };

    ProjectCounter.prototype.remove = function (number) {
      if (number == null) {
        number = 1;
      }
      return (this.projectCount -= number);
    };

    ProjectCounter.prototype.hasProjects = function () {
      return this.projectCount > 0;
    };

    return ProjectCounter;
  })();
}.call(this));
