// Generated by CoffeeScript 1.12.7

/*
  Does management of an undo stack. Checkpointing works similar to a stack, except we can be at a
  position other than the 'head' of the stack. If that is the case, a checkpoint will remove stack
  items between the current item and the head item before pushing the new item.

  Reading from the stack works similar to a bi-directional iterator, where redo moves towards the
  head and undo moves towards the first item. Unlike a stack, reading from the structure does not
  affect it.
 */

(function () {
  var UndoStack;

  module.exports = UndoStack = (function () {
    function UndoStack(shouldCheckpoint, maxSize) {
      var decrement, increment, me;
      if (maxSize == null) {
        maxSize = 100;
      }
      me = this;
      this.stack = [];
      this.earliest = null;
      this.latest = null;
      this.current = null;
      this.maxSize = maxSize;
      if (shouldCheckpoint == null) {
        shouldCheckpoint = function () {
          return true;
        };
      }
      this.peek = function () {
        return me.stack[me.current];
      };
      this.canUndo = function () {
        return me.current !== me.earliest;
      };
      this.canRedo = function () {
        return me.current !== me.latest;
      };
      this.undo = function () {
        var state;
        if (!me.canUndo()) {
          return null;
        }
        me.current = decrement(me.current);
        state = me.stack[me.current];
        return state;
      };
      this.redo = function () {
        var state;
        if (!me.canRedo()) {
          return null;
        }
        me.current = increment(me.current);
        state = me.stack[me.current];
        return state;
      };
      this.checkpoint = function (state) {
        if (me.size() > 0 && !shouldCheckpoint(me.stack[me.current])) {
          return false;
        }
        if (me.current == null) {
          me.current = 0;
          me.earliest = 0;
          me.latest = 0;
        } else {
          if (me.current !== me.latest) {
            me.latest = me.current;
          }
          me.current = increment(me.current);
          me.latest = increment(me.latest);
          if (me.latest === me.earliest) {
            me.earliest = increment(me.earliest);
          }
        }
        me.stack[me.current] = state;
        return true;
      };
      this.shouldCheckpoint = function () {
        return me.size() === 0 || shouldCheckpoint(me.stack[me.current]);
      };
      this.size = function () {
        if (me.latest != null) {
          return ((me.latest + me.maxSize - me.earliest) % me.maxSize) + 1;
        } else {
          return 0;
        }
      };
      increment = function (a) {
        return (a + 1) % me.maxSize;
      };
      decrement = function (a) {
        return (a + me.maxSize - 1) % me.maxSize;
      };
    }

    return UndoStack;
  })();
}.call(this));
