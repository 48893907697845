"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableRowToolbar = void 0;
var Types_1 = require("@CommonFrontend/Tokens/Types");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var react_1 = __importStar(require("react"));
var TableWidgetDimensionToolbar_1 = require("./TableWidgetDimensionToolbar");
var TableWidgetContext_1 = require("./TableWidgetContext");
var inversify_react_1 = require("inversify-react");
var WidgetManager_1 = require("Project/QwilrEditor/WidgetManager");
var lodash_1 = require("lodash");
var TableRowToolbar = function (_a) {
    var element = _a.element, inActivationZone = _a.inActivationZone, setHoverDelete = _a.setHoverDelete;
    var tableContext = (0, react_1.useContext)(TableWidgetContext_1.TableWidgetContext);
    if (!tableContext) {
        throw new Error("Rows can't exist outside of table");
    }
    var tableContextActions = tableContext.actions, enableRepeatingTableRows = tableContext.enableRepeatingTableRows;
    var widgetManager = (0, inversify_react_1.useInjection)(WidgetManager_1.WidgetManager);
    var getRowIndex = function () {
        var rowIndex = (0, lodash_1.last)(widgetManager.getPath(element));
        if (typeof rowIndex !== "number") {
            throw new Error("Couldn't get index of row");
        }
        return rowIndex;
    };
    var addRow = function (direction, type) {
        var referenceRow = getRowIndex();
        tableContextActions.addRow({ direction: direction, type: type, referenceRow: referenceRow });
    };
    var deleteRow = function () {
        var rowIndex = getRowIndex();
        tableContextActions.deleteRow(rowIndex);
    };
    return (react_1.default.createElement(TableWidgetDimensionToolbar_1.TableWidgetDimensionToolbar, { onDelete: deleteRow, setHoverDelete: setHoverDelete, size: enableRepeatingTableRows !== Types_1.TokensEnableState.Disabled ? "large" : "medium", inActivationZone: inActivationZone, mode: "row", canAdd: true, canDelete: tableContext.data.rows > 1 },
        react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { icon: react_1.default.createElement(icons_1.ArrowUp, null), onClick: function () {
                addRow("above", "regular");
            } }, "Add row above"),
        react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { icon: react_1.default.createElement(icons_1.ArrowDown, null), onClick: function () {
                addRow("below", "regular");
            } }, "Add row below"),
        enableRepeatingTableRows !== Types_1.TokensEnableState.Disabled ? (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { icon: react_1.default.createElement(icons_1.Loop, null), onClick: function () {
                    addRow("above", "repeating");
                } }, "Add repeating row above"),
            react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { icon: react_1.default.createElement(icons_1.Loop, null), onClick: function () {
                    addRow("below", "repeating");
                } }, "Add repeating row below"))) : undefined));
};
exports.TableRowToolbar = TableRowToolbar;
