// Generated by CoffeeScript 1.12.7
(function () {
  var FeatureFlag, ProjectActionsExcluder;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = ProjectActionsExcluder = (function () {
    function ProjectActionsExcluder(projectStatusChecker, projectTemplater, usingFeature, user, rolesService) {
      this.addExclusions = function (project, exclude) {
        var exclusionList, i, item, len;
        exclusionList = [
          {
            condition: !projectStatusChecker.isDeletable(project),
            exclude: ["delete"],
          },
          {
            condition: !usingFeature(FeatureFlag.UseRightToLeftBeta),
            exclude: ["settings"],
          },
          {
            condition: project.status === "accepted",
            exclude: ["disable"],
          },
          {
            condition: !rolesService.isAdmin(user),
            exclude: ["markTemplate"],
          },
          {
            condition: projectTemplater.isTemplate(project),
            exclude: ["tag", "analytics", "clone", "archive"],
          },
          {
            condition: project.isCrmTemplate,
            exclude: ["markTemplate"],
          },
        ];
        for (i = 0, len = exclusionList.length; i < len; i++) {
          item = exclusionList[i];
          if (item.condition) {
            exclude = exclude.concat(item.exclude);
          }
        }
        return exclude;
      };
      this.getSubset = function (allItems, subset) {
        return _.filter(subset, function (item) {
          return _.includes(allItems, item);
        });
      };
      this.getAllExcept = function (allItems, exclude) {
        return _.filter(allItems, function (item) {
          return !_.includes(exclude, item);
        });
      };
    }

    return ProjectActionsExcluder;
  })();
}.call(this));
