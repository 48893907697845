"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.duplicateWidget = exports.deleteEmptyAfterWidget = exports.newParagraphBelowWidget = exports.insertImagesFromDragEvent = exports.moveSelectedWidgetType = exports.handleFastUploadResult = exports.pickAndInsertImage = exports.chooseImage = exports.closeWidgetAdder = exports.widgetShortcutOptionIsHandled = exports.closeWidgetAdderAndAddNewLine = exports.addWidgetShortcut = exports.togglePlus = exports.updateIframeWidget = exports.updateGoogleMapsWidget = exports.updateVideoWidget = exports.addImageWidget = exports.updateImageWidget = exports.updateWidget = exports.removeWidget = exports.addWidget = void 0;
var WidgetManager_1 = require("../WidgetManager");
var EditorTypes_1 = require("./../EditorTypes");
var ShowImagePicker_1 = __importStar(require("../ShowImagePicker"));
var CommonActions_1 = require("./CommonActions");
var lodash_1 = require("lodash");
var slate_1 = require("slate");
var slate_react_1 = require("slate-react");
var Utils_1 = require("../Utils");
var DropLocationStore_1 = require("Project/Stores/DropLocationStore/DropLocationStore");
var ImageActions_1 = require("./ImageActions");
var EditorWidgetRegistry_1 = require("Project/QwilrEditor/WidgetRegistry/EditorWidgetRegistry");
var ImageNodeFactory_1 = require("../Widgets/ImageWidget/ImageNodeFactory");
var Paragraph_1 = require("../WidgetRegistry/Widgets/Paragraph");
var addWidget = function (_a) {
    var editor = _a.editor, widgetData = _a.widgetData, pathToAddWidget = _a.pathToAddWidget;
    pathToAddWidget = pathToAddWidget !== null && pathToAddWidget !== void 0 ? pathToAddWidget : editor.selection;
    slate_1.Transforms.setNodes(editor, __assign({ style: undefined }, widgetData), { at: pathToAddWidget });
    (0, lodash_1.defer)(function () {
        slate_react_1.ReactEditor.focus(editor);
    });
};
exports.addWidget = addWidget;
var removeWidget = function (editor, path) {
    slate_1.Transforms.removeNodes(editor, { at: path });
    (0, lodash_1.defer)(function () {
        slate_react_1.ReactEditor.focus(editor);
    });
};
exports.removeWidget = removeWidget;
var updateWidget = function (editor, path, newData) {
    var widgetEntry = slate_1.Editor.node(editor, path);
    if (!slate_1.Element.isElement(widgetEntry[0])) {
        return;
    }
    var existingData = (0, lodash_1.cloneDeep)(widgetEntry[0].data);
    var updatedData = (0, lodash_1.merge)(existingData, newData);
    slate_1.Transforms.setNodes(editor, { data: updatedData }, { at: path });
};
exports.updateWidget = updateWidget;
var updateImageWidget = function (editor, path, newData) {
    (0, exports.updateWidget)(editor, path, newData);
};
exports.updateImageWidget = updateImageWidget;
var addImageWidget = function (editor, imageData, currentPath) {
    var imageProps = (0, ImageNodeFactory_1.createImageNode)(imageData.src, imageData);
    (0, exports.addWidget)({
        editor: editor,
        widgetData: imageProps,
        pathToAddWidget: currentPath,
    });
};
exports.addImageWidget = addImageWidget;
var updateVideoWidget = function (editor, path, newData) {
    (0, exports.updateWidget)(editor, path, newData);
};
exports.updateVideoWidget = updateVideoWidget;
var updateGoogleMapsWidget = function (editor, path, newData) {
    (0, exports.updateWidget)(editor, path, newData);
};
exports.updateGoogleMapsWidget = updateGoogleMapsWidget;
var updateIframeWidget = function (editor, path, newData) {
    (0, exports.updateWidget)(editor, path, newData);
};
exports.updateIframeWidget = updateIframeWidget;
var togglePlus = function (editor, location, widgetAdderStore, editorTelemetry) {
    if (!editor.selection) {
        return;
    }
    var trackOpen = function () {
        editorTelemetry.viewedAdderMenu(widgetAdderStore.isParagraphCentered, "click");
    };
    if (location === EditorTypes_1.WidgetAdderPosition.Selection) {
        var startBlock = (0, Utils_1.getClosestBlock)(editor, slate_1.Range.start(editor.selection).path);
        if (!startBlock) {
            return;
        }
        var startBlockText = slate_1.Node.string(startBlock[0]);
        if (!startBlockText) {
            widgetAdderStore.shouldForceCloseMenu = false;
            slate_1.Transforms.insertText(editor, "+");
            trackOpen();
        }
        else if (startBlockText === "+") {
            slate_1.Transforms.select(editor, slate_1.Editor.end(editor, startBlock[1]));
            slate_1.Transforms.delete(editor, { distance: 1, reverse: true });
        }
        return;
    }
    else {
        if (location === EditorTypes_1.WidgetAdderPosition.AfterLastLine) {
            slate_1.Transforms.insertNodes(editor, (0, Paragraph_1.createParagraphNode)(), { at: [editor.children.length] });
            slate_1.Transforms.select(editor, slate_1.Editor.end(editor, []));
        }
        else {
            slate_1.Transforms.select(editor, slate_1.Editor.end(editor, []));
            slate_1.Transforms.setNodes(editor, (0, Paragraph_1.createParagraphNode)());
        }
        widgetAdderStore.shouldForceCloseMenu = false;
        slate_1.Transforms.insertText(editor, "+");
        slate_react_1.ReactEditor.focus(editor);
        trackOpen();
        return;
    }
};
exports.togglePlus = togglePlus;
var addWidgetShortcut = function (editor, di, router, widgetAdderStore, selectedWidgetType, modificationContext, sendTelemetryContentAdded) {
    if (!(0, exports.widgetShortcutOptionIsHandled)(selectedWidgetType)) {
        widgetAdderStore.shouldForceCloseMenu = false;
        return false;
    }
    slate_1.Transforms.splitNodes(editor, {
        always: true,
    });
    (0, CommonActions_1.deletePreviousBlock)(editor);
    var config = (0, EditorWidgetRegistry_1.getGenericConfigByType)(selectedWidgetType);
    if (config) {
        config.onAddWidget({ editor: editor, di: di, modificationContext: modificationContext, router: router });
        if (config.contentAddedType) {
            sendTelemetryContentAdded(config.contentAddedType);
        }
    }
    widgetAdderStore.shouldForceCloseMenu = false;
    return true;
};
exports.addWidgetShortcut = addWidgetShortcut;
var closeWidgetAdderAndAddNewLine = function (widgetAdderStore, editor) {
    widgetAdderStore.shouldForceCloseMenu = false;
    slate_1.Transforms.splitNodes(editor, { always: true });
};
exports.closeWidgetAdderAndAddNewLine = closeWidgetAdderAndAddNewLine;
var widgetShortcutOptionIsHandled = function (type) {
    var _a;
    return !!((_a = (0, EditorWidgetRegistry_1.getGenericConfigByType)(type)) === null || _a === void 0 ? void 0 : _a.onAddWidget);
};
exports.widgetShortcutOptionIsHandled = widgetShortcutOptionIsHandled;
var closeWidgetAdder = function (event, widgetAdderStore) {
    if (widgetAdderStore.shouldForceCloseMenu) {
        return false;
    }
    else {
        event.preventDefault();
        widgetAdderStore.shouldForceCloseMenu = true;
        return true;
    }
};
exports.closeWidgetAdder = closeWidgetAdder;
var chooseImage = function (editor, di, path) { return __awaiter(void 0, void 0, void 0, function () {
    var _a, url, type, uploadId, altDescription, selectedImageData;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0: return [4, di.invoke(ShowImagePicker_1.default)()];
            case 1:
                _a = _b.sent(), url = _a.url, type = _a.type, uploadId = _a.uploadId, altDescription = _a.altDescription;
                selectedImageData = {};
                (0, exports.handleFastUploadResult)(type, selectedImageData, uploadId, url, altDescription);
                (0, exports.updateImageWidget)(editor, path, selectedImageData);
                return [2];
        }
    });
}); };
exports.chooseImage = chooseImage;
var pickAndInsertImage = function (editor, di, router, modificationContext) { return __awaiter(void 0, void 0, Promise, function () {
    var currentPath, _a, url, type, uploadId, metricSource, altDescription, updateData;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                currentPath = (0, lodash_1.cloneDeep)(editor.selection);
                return [4, di.invoke(ShowImagePicker_1.default)()];
            case 1:
                _a = _b.sent(), url = _a.url, type = _a.type, uploadId = _a.uploadId, metricSource = _a.metricSource, altDescription = _a.altDescription;
                updateData = {
                    action: "addImage",
                    modificationContext: __assign(__assign({}, modificationContext), { imageSource: metricSource }),
                    currentPath: currentPath,
                };
                (0, exports.handleFastUploadResult)(type, updateData, uploadId, url, altDescription);
                router.onEvent(new WidgetManager_1.WidgetEvent("widgetAdder", updateData), editor);
                return [2];
        }
    });
}); };
exports.pickAndInsertImage = pickAndInsertImage;
var handleFastUploadResult = function (type, updateData, uploadId, url, altDescription) {
    updateData.alt = altDescription !== null && altDescription !== void 0 ? altDescription : "";
    if (type === ShowImagePicker_1.ImagePickerResultType.FastUpload) {
        updateData.uploadId = uploadId;
    }
    else {
        updateData.src = url;
    }
};
exports.handleFastUploadResult = handleFastUploadResult;
var moveSelectedWidgetType = function (event, widgetAdderStore, keyType, editorTelemetry, editor) {
    widgetAdderStore.shouldForceCloseMenu = false;
    if (widgetAdderStore.moveSelectedWidgetType(keyType, editor)) {
        event.preventDefault();
        editorTelemetry.navigatedAdderMenuWithKeyboard();
        return true;
    }
    else {
        return false;
    }
};
exports.moveSelectedWidgetType = moveSelectedWidgetType;
var startImageUploadAndGetSlateBlock = function (file, uploadStore) {
    var uploadId = uploadStore.upload(file);
    var node = (0, ImageNodeFactory_1.createImageNode)(undefined, { uploadId: uploadId });
    return node;
};
var insertImagesFromDragEvent = function (event, editor, uploadStore, dropLocationStore, usingDropIndicators) {
    var _a;
    var slateBlocks = (0, lodash_1.map)(event.dataTransfer.files, function (file) { return startImageUploadAndGetSlateBlock(file, uploadStore); });
    var location = usingDropIndicators
        ?
            dropLocationStore.dropLocation.path
        : slate_react_1.ReactEditor.findEventRange(editor, event);
    slateBlocks.reverse().forEach(function (slateJson) {
        slate_1.Transforms.insertNodes(editor, slateJson, {
            at: location,
        });
    });
    if (((_a = dropLocationStore.dropLocation) === null || _a === void 0 ? void 0 : _a.type) === DropLocationStore_1.DropLocationType.TwoColumnPlaceholder) {
        (0, ImageActions_1.dismissSideBySidePlaceholderFromDrop)(editor, location);
    }
    dropLocationStore.clearDropLocation();
};
exports.insertImagesFromDragEvent = insertImagesFromDragEvent;
var newParagraphBelowWidget = function (editor) {
    slate_1.Transforms.insertNodes(editor, (0, Paragraph_1.createParagraphNode)());
};
exports.newParagraphBelowWidget = newParagraphBelowWidget;
var deleteEmptyAfterWidget = function (editor) {
    if (!editor.selection) {
        return;
    }
    var parentPath = slate_1.Path.parent(editor.selection.anchor.path);
    var prevNodeRef = undefined;
    if (slate_1.Path.hasPrevious(parentPath)) {
        var prevNodePath_1 = slate_1.Path.previous(parentPath);
        prevNodeRef = slate_1.Editor.pathRef(editor, prevNodePath_1);
    }
    slate_1.Transforms.removeNodes(editor);
    var prevNodePath = prevNodeRef === null || prevNodeRef === void 0 ? void 0 : prevNodeRef.unref();
    if (prevNodePath != null) {
        slate_1.Transforms.select(editor, prevNodePath);
    }
};
exports.deleteEmptyAfterWidget = deleteEmptyAfterWidget;
var duplicateWidget = function (editor, path) {
    var _a = __read(slate_1.Editor.node(editor, path), 1), nodeToDuplicate = _a[0];
    var duplicatedNode = (0, lodash_1.cloneDeep)(nodeToDuplicate);
    var duplicatePath = slate_1.Path.next(path);
    slate_1.Transforms.insertNodes(editor, duplicatedNode, { at: duplicatePath });
};
exports.duplicateWidget = duplicateWidget;
