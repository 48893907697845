// Generated by CoffeeScript 1.12.7
(function () {
  var CrispIntegration;

  module.exports = CrispIntegration = (function () {
    function CrispIntegration(createUi, genericIntegration, $q) {
      var extractWebsiteId;
      genericIntegration.build.call(this, "crisp", "Crisp Website ID", require("./Crisp.html"), function (ui) {
        var newWebsiteId;
        newWebsiteId = extractWebsiteId(ui.chatboxCode);
        ui.settings.websiteId = newWebsiteId;
        if (newWebsiteId == null || !newWebsiteId.length) {
          return $q.reject("Whoops, looks like you left this field blank. Please try again.");
        } else {
          return $q.resolve();
        }
      });
      extractWebsiteId = function (chatboxCode) {
        var endIndex, endMarker, id, startIndex, startMarker;
        if (_.includes(chatboxCode, "<script")) {
          startMarker = 'CRISP_WEBSITE_ID="';
          startIndex = chatboxCode.lastIndexOf(startMarker) + startMarker.length;
          endMarker = '";(function(){d=document;s=d';
          endIndex = chatboxCode.lastIndexOf(endMarker);
          id = chatboxCode.substring(startIndex, endIndex);
          return id;
        } else {
          return chatboxCode;
        }
      };
    }

    return CrispIntegration;
  })();
}.call(this));
