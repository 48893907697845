// Generated by CoffeeScript 1.12.7
(function () {
  var SlackMessenger;

  module.exports = SlackMessenger = (function () {
    function SlackMessenger(webhookUrl, defaultChannel, userName, $http) {
      this.ping = function (message, channel) {
        if (message === "" || message == null) {
          return;
        }
        if (channel == null) {
          channel = defaultChannel;
        }
        if (userName == null) {
          userName = userName;
        }
        return $http({
          method: "POST",
          url: webhookUrl,
          withCredentials: false,
          headers: {
            "X-XSRF-TOKEN": void 0,
            "X-Requested-With": void 0,
            "X-Pusher-Socket-ID": void 0,
            "Content-Type": "application/x-www-form-urlencoded",
          },
          data: {
            text: message,
            channel: channel,
            username: userName,
          },
        });
      };
    }

    return SlackMessenger;
  })();
}.call(this));
