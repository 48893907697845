"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var lodash_1 = require("lodash");
var ProjectTypes_1 = require("Project/ProjectTypes");
var lodash_2 = require("lodash");
var Archived = ProjectTypes_1.SpecialProjectTag.Archived, Starred = ProjectTypes_1.SpecialProjectTag.Starred, Template = ProjectTypes_1.SpecialProjectTag.Template;
var specialTags = [Archived, Starred, Template];
var isSpecialTag = function (tag) {
    return specialTags.includes(tag);
};
var ProjectTagDataStore = (function () {
    function ProjectTagDataStore(_projectTags) {
        var _this = this;
        this.doesTagExist = function (tag) {
            return (0, mobx_1.computed)(function () {
                return _this._projectTags.includes(tag);
            }).get();
        };
        this._projectTags = (0, lodash_2.difference)(_projectTags, specialTags);
    }
    ProjectTagDataStore.prototype.createTag = function (tag) {
        if (isSpecialTag(tag)) {
            throw new Error("This is a reserved tag name");
        }
        else if (!this.doesTagExist(tag)) {
            this._projectTags.splice((0, lodash_1.sortedIndex)(this._projectTags, tag), 0, tag);
        }
    };
    ProjectTagDataStore.prototype.removeTag = function (tag) {
        if (isSpecialTag(tag)) {
            throw new Error("This is a reserved tag name");
        }
        else if (this.doesTagExist(tag)) {
            this._projectTags.splice((0, lodash_1.sortedIndex)(this._projectTags, tag), 1);
        }
    };
    Object.defineProperty(ProjectTagDataStore.prototype, "projectTags", {
        get: function () {
            return this._projectTags;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", Array)
    ], ProjectTagDataStore.prototype, "_projectTags", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ProjectTagDataStore.prototype, "createTag", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [String]),
        __metadata("design:returntype", void 0)
    ], ProjectTagDataStore.prototype, "removeTag", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], ProjectTagDataStore.prototype, "projectTags", null);
    return ProjectTagDataStore;
}());
exports.default = ProjectTagDataStore;
