"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectAccordion = exports.AccordionSelectionType = void 0;
var slate_react_1 = require("slate-react");
var AccordionSelectionType;
(function (AccordionSelectionType) {
    AccordionSelectionType["None"] = "None";
    AccordionSelectionType["Around"] = "Around";
    AccordionSelectionType["Inside"] = "Inside";
    AccordionSelectionType["Full"] = "Full";
})(AccordionSelectionType || (exports.AccordionSelectionType = AccordionSelectionType = {}));
var selectAccordion = function (editor, accordionPath, syntheticSelectionStore, router, conversionElem) {
    syntheticSelectionStore.setSelection([
        {
            editor: editor,
            path: accordionPath,
            router: router,
            conversionElem: conversionElem,
        },
    ]);
    slate_react_1.ReactEditor.deselect(editor);
    slate_react_1.ReactEditor.blur(editor);
};
exports.selectAccordion = selectAccordion;
