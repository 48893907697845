// Generated by CoffeeScript 1.12.7
(function () {
  var PlaceholderBlockFactory;

  module.exports = PlaceholderBlockFactory = (function () {
    function PlaceholderBlockFactory(createUi, account, $timeout) {
      /*
        Create a placeholder block
        @param classNames (optional) the class names to be applied to the top-level element
        @param blockType (optional)
       */
      this.create = function (classNames, blockType) {
        var view;
        if ((blockType != null ? blockType.toLowerCase() : void 0) === "splash") {
          view = require("./PlaceholderBlockSplash.html");
        } else {
          view = require("./PlaceholderBlock.html");
        }
        return createUi(view, {
          classNames: classNames,
          $onElementRendered: function (elem) {
            return $timeout(function () {
              return _.each(["h1", "h2", "p"], function (selector) {
                var placeholder;
                placeholder = $(elem).find(selector + ".placeholder");
                placeholder.css("height", placeholder.css("font-size"));
                if (placeholder.css("text-align") === "center") {
                  placeholder.css("margin-left", "auto").css("margin-right", "auto");
                }
                if (parseInt(placeholder.css("margin-bottom")) < 25) {
                  return placeholder.css("margin-bottom", 25);
                }
              });
            });
          },
        });
      };
    }

    return PlaceholderBlockFactory;
  })();
}.call(this));
