// Generated by CoffeeScript 1.12.7
(function () {
  var Actions, AddPanel, ItemController, Summary;

  AddPanel = require("./AddPanel/AddPanel");

  Summary = require("./Summary/Summary");

  Actions = require("./Actions/Actions");

  ItemController = require("./../Item/Item");

  module.exports = function (
    di,
    make,
    createUi,
    block,
    section,
    mapControllers,
    quoteService,
    utils,
    sectionService,
    undoNotifier,
  ) {
    var actions, addPanel, createItemCtrl, summary;
    addPanel = make(AddPanel);
    summary = make(Summary);
    actions = make(Actions, {
      closeActionsPanel: (function (_this) {
        return function () {
          return (_this.ui.showActions = false);
        };
      })(this),
    });
    createItemCtrl = function (item) {
      item.$id = utils.uniqueId();
      return make(ItemController, {
        item: item,
        section: section,
        itemViewState: {
          showRateSelector: false,
          showReadOut: false,
        },
      });
    };
    return (this.ui = createUi(require("Project/Modules/Quote/Section/Section.html"), {
      section: section,
      summary: summary.ui,
      addPanel: addPanel.ui,
      actions: actions.ui,
      showActions: false,
      isLastSection: function () {
        return block.sections.length === 1;
      },
      deleteSection: (function (_this) {
        return function () {
          if (_this.ui.isLastSection()) {
            return;
          }
          return quoteService.deleteSection(section);
        };
      })(this),
      toggleEdit: (function (_this) {
        return function () {
          return (_this.ui.showActions = !_this.ui.showActions);
        };
      })(this),
      $init: function (scope) {
        return (scope.ui.items = mapControllers(scope, createItemCtrl, section.items));
      },
    }));
  };
}.call(this));
