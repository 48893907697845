// Generated by CoffeeScript 1.12.7
(function () {
  var Discount, MenuTemplate, SectionSettingsMenu, ToggleWithLabel;

  MenuTemplate = require("./TotalsSettingsMenu.html");

  ToggleWithLabel = require("./ToggleWithLabel");

  Discount = require("./Discount").Discount;

  module.exports = SectionSettingsMenu = (function () {
    function SectionSettingsMenu(createUi, make, quoteAdapter) {
      var addToggleWithLabel, discountSetting, settings;
      settings = [];
      discountSetting = null;
      addToggleWithLabel = function (description, getter, setter) {
        var toggle;
        toggle = make(ToggleWithLabel, {
          description: description,
          getter: getter,
          setter: setter,
        });
        settings.push(toggle);
        return toggle;
      };
      this.refresh = function () {
        return discountSetting != null ? discountSetting.refresh() : void 0;
      };
      this.ui = createUi(MenuTemplate, {
        getSettings: function () {
          return settings;
        },
      });
      addToggleWithLabel("Show total discounts", quoteAdapter.showTotalDiscount, quoteAdapter.setShowTotalDiscount);
      addToggleWithLabel("Show tax totals", quoteAdapter.showTaxTotals, quoteAdapter.setShowTaxTotals);
      addToggleWithLabel("Show total excluding tax", quoteAdapter.showExTax, quoteAdapter.setShowExTax);
      discountSetting = make(Discount, {
        description: "Apply discount",
        discountAdapter: quoteAdapter.getDiscountAdapter(),
      });
      settings.push(discountSetting);
    }

    return SectionSettingsMenu;
  })();
}.call(this));
