"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrialStore = void 0;
var mobx_1 = require("mobx");
var TrialStore = (function () {
    function TrialStore(account) {
        this.timeTrial = account.timeTrial;
    }
    TrialStore.prototype.setEndDate = function (endDate) {
        this.timeTrial.endDate = endDate;
    };
    TrialStore.prototype.setInGracePeriod = function (inGracePeriod) {
        this.timeTrial.inGracePeriod = inGracePeriod;
    };
    var _a;
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", Object)
    ], TrialStore.prototype, "timeTrial", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_a = typeof Date !== "undefined" && Date) === "function" ? _a : Object]),
        __metadata("design:returntype", void 0)
    ], TrialStore.prototype, "setEndDate", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], TrialStore.prototype, "setInGracePeriod", null);
    return TrialStore;
}());
exports.TrialStore = TrialStore;
