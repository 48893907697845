// Generated by CoffeeScript 1.12.7

/*
 * Defines a start subscription button and ties together
  requisite screens into a sequence of overlays
 */

(function () {
  var StartSubscription,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = StartSubscription = (function () {
    var attributionScreen, confirmationScreen, creditCardScreen, selectPlanScreen;

    function StartSubscription($timeout, $http, account, apiBaseUrl, make, SubscriptionSequencer) {
      this.$timeout = $timeout;
      this.$http = $http;
      this.account = account;
      this.apiBaseUrl = apiBaseUrl;
      this.make = make;
      this.SubscriptionSequencer = SubscriptionSequencer;
      this.start = bind(this.start, this);
    }

    selectPlanScreen = require("SettingsAngular/Subscription/PlanExplorer/PlanExplorer")["default"];

    creditCardScreen = require("SettingsAngular/Subscription/CreditCard/CreditCard");

    confirmationScreen = require("SettingsAngular/Subscription/Confirmation/Confirmation");

    attributionScreen = require("SettingsAngular/Subscription/Attribution/attach")["default"];

    StartSubscription.prototype.start = function () {
      var ref, sequence, steps;
      if (((ref = this.account.stripe) != null ? ref.customerToken : void 0) == null) {
        steps = [selectPlanScreen, creditCardScreen, confirmationScreen, attributionScreen];
      } else {
        steps = [selectPlanScreen, confirmationScreen, attributionScreen];
      }
      return (sequence = this.make(this.SubscriptionSequencer, {
        steps: steps,
        allowCoupon: true,
        businessTrial: false,
        isNewSubscription: true,
        options: {
          onHitEnd: (function (_this) {
            return function (subscriptionDi) {
              return _this.$timeout(function () {
                return window.location.reload();
              }, 2500);
            };
          })(this),
        },
      }));
    };

    return StartSubscription;
  })();
}.call(this));
