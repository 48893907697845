// Generated by CoffeeScript 1.12.7

/*
  This class watches an object and issues a save request to the server if the properties of the
  object are mutated (other than properties prefixed with a $)

  The separation of concerns between `Persist` and `PersistLogic` is:
  - `Persist` is about figuring out *when* to trigger a save on a watched object
  - `PersistLogic` is about *how* a save actually occurs
 */

(function () {
  var Persist, PersistLogic, cleanJson, makeSendActivity;

  cleanJson = require("CleanJson/CleanJson")["default"];

  PersistLogic = require("./PersistLogic")["default"];

  makeSendActivity = require("./ActivityTracker")["default"];

  module.exports = Persist = (function () {
    function Persist($rootScope, make, options) {
      var DEBOUNCE_DELAY,
        apiRoute,
        dataTransformer,
        objectKey,
        payloadName,
        persistLogic,
        scope,
        sendActivity,
        update,
        validation,
        watch,
        watchObjectTransformer;
      persistLogic = make(PersistLogic);
      sendActivity = make.invoke(makeSendActivity);
      objectKey = options.watchObject;
      scope = options.scope || $rootScope;
      apiRoute = options.apiRoute;
      DEBOUNCE_DELAY = options.throttling || 500;
      payloadName = options.payloadName || "content";
      dataTransformer = options.dataTransformer;
      watchObjectTransformer = options.watchObjectTransformer;
      validation = options.validation;
      update = (function (_this) {
        return function (watchObject, oldVal) {
          var data, originalObject, watchObjectString;
          if (watchObject === oldVal) {
            return;
          }
          try {
            watchObject = JSON.parse(watchObject);
          } catch (error) {}
          if (_.isString(watchObject)) {
            watchObjectString = watchObject;
            watchObject = {};
            watchObject[payloadName] = watchObjectString;
          }
          if (watchObject == null || !_.isObject(watchObject)) {
            return;
          }
          originalObject = scope.$eval(objectKey);
          if (originalObject.$skipPersistenceCycle) {
            delete originalObject.$skipPersistenceCycle;
            return;
          }
          if (validation != null && !validation(watchObject)) {
            return;
          }
          if (dataTransformer != null) {
            watchObject = dataTransformer(watchObject);
          }
          data = cleanJson(watchObject);
          sendActivity();
          return persistLogic.makeHttpRequest(apiRoute, data);
        };
      })(this);
      watch = (function (_this) {
        return function () {
          var debounced;
          debounced = _.debounce(update, DEBOUNCE_DELAY, {
            trailing: true,
            leading: false,
            maxWait: 1000,
          });
          scope.$watch(
            function () {
              var watchObject;
              watchObject = scope.$eval(objectKey);
              if (watchObjectTransformer != null) {
                watchObject = watchObjectTransformer(watchObject);
              }
              return JSON.stringify(cleanJson(watchObject));
            },
            debounced,
            true,
          );
          return scope.$on("$destroy", function () {
            return persistLogic.onDestroy();
          });
        };
      })(this);
      watch();
    }

    return Persist;
  })();
}.call(this));
