// Generated by CoffeeScript 1.12.7

/*
  As the Quote module has evolved over time, and new functionality
  has been added, so the requirements of the data model have shifted.

  This module brings old quote modules up to date with the newest requirements
  of the data model.

  It also updates the account and user history to have sensible defaults, for old
  accounts and users.
 */

(function () {
  module.exports = function (block, account, user, currencies, QuoteModel) {
    var base, base1, base2, base3, base4, defaultRate, discount, i, item, len, ref, results, section;
    if (block.settings == null) {
      block.settings = {};
    }
    if ((base = block.settings).accept == null) {
      base.accept = QuoteModel.settings.accept;
    }
    if ((base1 = block.settings).currency == null) {
      base1.currency = _.find(currencies, {
        name: "Australian Dollar",
      });
    }
    if (block.settings.customLabels == null) {
      block.settings.customLabels = QuoteModel.settings.customLabels;
    }
    if (block.settings.displayConfig == null) {
      block.settings.displayConfig = QuoteModel.settings.displayConfig;
    }
    if (_.isNumber(block.discount)) {
      discount = block.discount;
      block.discount = {
        amount: discount,
        type: "fixed",
      };
    }
    if ((base2 = account.settings).modules == null) {
      base2.modules = {};
    }
    if ((base3 = account.settings.modules).quote == null) {
      base3.quote = {
        rates: [],
        taxes: [],
      };
    }
    if (account.settings.modules.quote.rates == null || account.settings.modules.quote.rates.length === 0) {
      account.settings.modules.quote.rates.push({
        name: "Default",
        rate: 100,
        type: "rate",
        unit: "Hour",
      });
    }
    if (account.settings.modules.quote.taxes == null) {
      account.settings.modules.quote.taxes = [];
    }
    defaultRate = account.settings.modules.quote.rates[0];
    if ((base4 = user.useHistory).quote == null) {
      base4.quote = {
        lastRateUsed: null,
        defaultRate: defaultRate.name,
      };
    }
    ref = block.sections;
    results = [];
    for (i = 0, len = ref.length; i < len; i++) {
      section = ref[i];
      results.push(
        (function () {
          var j, len1, ref1, results1;
          ref1 = section.items;
          results1 = [];
          for (j = 0, len1 = ref1.length; j < len1; j++) {
            item = ref1[j];
            if (item.units != null && _.isString(item.units)) {
              item.units = parseInt(item.units, 10);
            }
            if (!(item.type != null && item.type.length)) {
              item.type = item.rate == null ? "text" : item.rate.unit === "Fixed Cost" ? "fixedCost" : "rate";
            }
            if (item.type === "rate" && item.rate == null) {
              results1.push((item.rate = defaultRate));
            } else {
              results1.push(void 0);
            }
          }
          return results1;
        })(),
      );
    }
    return results;
  };
}.call(this));
