"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("./../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
exports.default = (function (di) {
    return {
        recordKeyPress: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("recordKeyPress")
            .isEvent(EditorTypes_1.EventType.Key)
            .withModifier(BehaviourBuilder_1.KeyModifier.Any)
            .doAction(function (event, editor) {
            if (di.has("cursorValidator")) {
                var validator = di.get("cursorValidator");
                validator.recordKeyPress(event);
            }
            return;
        }),
    };
});
