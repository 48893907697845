"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CancelSubscription = void 0;
var attach_1 = require("../Cancel/attach");
var CancelSubscription = (function () {
    function CancelSubscription(make, telemetry, SubscriptionSequencer) {
        var _this = this;
        this.make = make;
        this.telemetry = telemetry;
        this.SubscriptionSequencer = SubscriptionSequencer;
        this.cancel = function () {
            _this.telemetry.trackOnly("Subscription Cancellation Flow Started");
            return _this.make(_this.SubscriptionSequencer, {
                steps: [attach_1.CancelScreen],
                options: {
                    onHitEnd: function () {
                        window.location.reload();
                    },
                },
            });
        };
    }
    return CancelSubscription;
}());
exports.CancelSubscription = CancelSubscription;
