"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EnterEmbedCode = void 0;
var react_1 = __importStar(require("react"));
var EmbedPresetsWidgetContext_1 = require("./EmbedPresetsWidgetContext");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var styles = __importStar(require("./EnterEmbedCode.css"));
var icons_1 = require("@qwilr/kaleidoscope/icons");
var lodash_1 = require("lodash");
var EmbedPresetOptions_1 = require("./EmbedPresetOptions");
var ValidateStrings_1 = require("@CommonFrontendBackend/Utils/ValidateStrings");
var validator_1 = require("validator");
var react_resize_detector_1 = require("react-resize-detector");
var EmbedError;
(function (EmbedError) {
    EmbedError["EmptyValue"] = "Embed code input is empty";
    EmbedError["NotUrl"] = "Embed code is not a url";
    EmbedError["ContainsScript"] = "Embed code contains scripts";
    EmbedError["HtmlNotIframe"] = "Embed code contains html after extracting iframe tag";
    EmbedError["QwilrEditorUrl"] = "Embed code is a qwilr editor url";
})(EmbedError || (EmbedError = {}));
var EnterEmbedCode = function (_a) {
    var embedUrl = _a.embedUrl, closeButton = _a.closeButton;
    var _b = (0, EmbedPresetsWidgetContext_1.useEmbedPresetsWidgetContext)(), handleDisplayUrlChange = _b.handleDisplayUrlChange, selectedPreset = _b.selectedPreset, setStep = _b.setStep, setShowPresetsOverlay = _b.setShowPresetsOverlay;
    var _c = __read(react_1.default.useState(embedUrl !== null && embedUrl !== void 0 ? embedUrl : ""), 2), textInputValue = _c[0], setTextInputValue = _c[1];
    var _d = __read((0, react_1.useState)(false), 2), showEmbedPopover = _d[0], setShowEmbedPopover = _d[1];
    var _e = __read((0, react_1.useState)(undefined), 2), embedErrorMessage = _e[0], setEmbedErrorMessage = _e[1];
    var _f = (0, react_resize_detector_1.useResizeDetector)(), width = _f.width, ref = _f.ref;
    (0, react_1.useEffect)(function () {
        setShowEmbedPopover(false);
        setEmbedErrorMessage(undefined);
    }, [textInputValue]);
    if (!selectedPreset) {
        return null;
    }
    var onSubmit = function () {
        try {
            if (!textInputValue) {
                throw new Error(EmbedError.EmptyValue);
            }
            var embedUrl_1 = maybeExtractUrlFromIframe(textInputValue);
            if (embedUrl_1.includes("<script")) {
                throw new Error(EmbedError.ContainsScript);
            }
            else if ((0, ValidateStrings_1.containsHtml)(embedUrl_1)) {
                throw new Error(EmbedError.HtmlNotIframe);
            }
            else if (embedUrl_1.includes("app.qwilr")) {
                throw new Error(EmbedError.QwilrEditorUrl);
            }
            if ((0, EmbedPresetOptions_1.embedUrlMatchesPreset)(embedUrl_1, selectedPreset.key)) {
                var decoratedEmbedUrl = decorateWithPresetEmbedConfiguration(embedUrl_1, selectedPreset);
                handleDisplayUrlChange(decoratedEmbedUrl, selectedPreset);
                setShowPresetsOverlay(false);
                return;
            }
            if (!(0, validator_1.isURL)(embedUrl_1)) {
                throw new Error(EmbedError.NotUrl);
            }
            setShowEmbedPopover(true);
        }
        catch (err) {
            var embedError = void 0;
            switch (err.message) {
                case EmbedError.ContainsScript: {
                    embedError = "Embed code cannot contain <script> tags. Please use an iframe embed code if available.";
                    break;
                }
                case EmbedError.HtmlNotIframe: {
                    embedError = "Embed code cannot contain HTML tags";
                    break;
                }
                case EmbedError.QwilrEditorUrl: {
                    embedError = (react_1.default.createElement(react_1.default.Fragment, null,
                        "Please use the collaborator or public page link. Instructions on where to source this link can be found",
                        react_1.default.createElement(kaleidoscope_1.Link, { href: "https://help.qwilr.com/article/87-sharing" }, " here")));
                    break;
                }
                case EmbedError.EmptyValue || EmbedError.NotUrl: {
                    embedError = "Please enter a valid embed code";
                    break;
                }
                default: {
                    console.error(err);
                    embedError = "Please enter a valid embed code";
                    break;
                }
            }
            setEmbedErrorMessage(embedError);
        }
    };
    return (react_1.default.createElement("div", { className: styles.container, ref: ref }, width < 312 ? (react_1.default.createElement(kaleidoscope_1.Stack, { align: "center", justify: "center", padding: "xxs", className: styles.resizeNotification },
        react_1.default.createElement("div", { className: styles.resizeNotificationCloseButton }, closeButton && closeButton),
        react_1.default.createElement(kaleidoscope_1.Text, { size: "s", align: "center" }, "Please resize the widget larger to change source"))) : (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { className: styles.buttonContainer },
            react_1.default.createElement(kaleidoscope_1.IconButton, { isIconOnly: true, icon: react_1.default.createElement(icons_1.ChevronLeft, null), type: kaleidoscope_1.ButtonType.Secondary, size: kaleidoscope_1.ButtonSize.Small, className: styles.backButton, onClick: function () {
                    setStep(EmbedPresetsWidgetContext_1.EmbedStep.ChooseSource);
                } }),
            closeButton && closeButton),
        react_1.default.createElement(kaleidoscope_1.Stack, { justify: "spaceBetween", padding: "xxl", gap: "m", className: styles.contentContainer },
            react_1.default.createElement(kaleidoscope_1.Stack, { align: "center", gap: "xxl" },
                react_1.default.createElement(kaleidoscope_1.Stack, { gap: "xxs", align: "center" },
                    react_1.default.createElement(kaleidoscope_1.SVG, { src: selectedPreset.iconPath }),
                    react_1.default.createElement(kaleidoscope_1.Text, { strong: true, size: "xl", align: "center" }, "Paste your ".concat(selectedPreset.name, " embed code")),
                    react_1.default.createElement(kaleidoscope_1.Text, { size: "m", align: "center" }, "Copy the embed code from ".concat(selectedPreset.name, " and paste it below. "),
                        react_1.default.createElement(kaleidoscope_1.Link, { href: selectedPreset.instructions }, "View the instructions"),
                        " to see how.")),
                react_1.default.createElement("div", { "data-interactive-zone": true },
                    react_1.default.createElement(kaleidoscope_1.TextInput, { label: "Embed code", value: textInputValue, onChange: function (e) { return setTextInputValue(e); }, size: kaleidoscope_1.TextInputSize.Large, className: styles.textInput, onKeyDown: function (e) {
                            if (e.key === kaleidoscope_1.KeyType.Enter) {
                                onSubmit();
                            }
                        }, error: embedErrorMessage, endElement: react_1.default.createElement(kaleidoscope_1.Popover, { isOpen: showEmbedPopover, onChange: setShowEmbedPopover, position: kaleidoscope_1.PopoverPosition.Top, caret: true, button: function (_a) {
                                var _ = _a.onClick, buttonProps = __rest(_a, ["onClick"]);
                                return (react_1.default.createElement(kaleidoscope_1.Button, __assign({ onClick: function () {
                                        onSubmit();
                                    } }, buttonProps), "Embed"));
                            } },
                            react_1.default.createElement(kaleidoscope_1.Stack, { gap: "m", align: "center" },
                                react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", secondary: true, align: "center" },
                                    "Your embed code doesn't look like it's from ",
                                    selectedPreset.name,
                                    ". Would you like to try embedding it anyway?"),
                                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "xs", align: "center", justify: "center" },
                                    react_1.default.createElement(kaleidoscope_1.Button, { onClick: function () { return setShowEmbedPopover(false); }, type: kaleidoscope_1.ButtonType.Secondary }, "Cancel"),
                                    react_1.default.createElement(kaleidoscope_1.Button, { onClick: function () {
                                            setShowEmbedPopover(false);
                                            try {
                                                var embedUrl_2 = maybeExtractUrlFromIframe(textInputValue);
                                                handleDisplayUrlChange(embedUrl_2, (0, EmbedPresetOptions_1.getEmbedPresetByKey)("iframe"));
                                                setShowPresetsOverlay(false);
                                            }
                                            catch (_a) {
                                                setEmbedErrorMessage("Please enter a valid embed code");
                                            }
                                        } }, "Continue")))) }))),
            react_1.default.createElement(kaleidoscope_1.Stack, { align: "center" },
                react_1.default.createElement(kaleidoscope_1.Text, { size: "s", align: "center" },
                    "Having trouble getting an embed to work?",
                    " ",
                    react_1.default.createElement(kaleidoscope_1.Link, { href: "https://help.qwilr.com/category/168-embedding-content" }, "Check the docs"))))))));
};
exports.EnterEmbedCode = EnterEmbedCode;
var maybeExtractUrlFromIframe = function (value) {
    if (value.includes("<iframe")) {
        var parser = new DOMParser();
        var parsedIframe = parser.parseFromString(value, "text/html");
        var iframeElements = parsedIframe.getElementsByTagName("iframe");
        var firstIframe = (0, lodash_1.first)(iframeElements);
        if (!firstIframe) {
            throw new Error("No iframe found in HTML with an iframe");
        }
        if (firstIframe) {
            var src = firstIframe.src;
            return src;
        }
    }
    return value;
};
var decorateWithPresetEmbedConfiguration = function (embedUrl, preset) {
    var embedUrlAsUrl = new URL(embedUrl);
    if (preset.key === "typeform") {
        if (!embedUrlAsUrl.searchParams.has("disable-auto-focus")) {
            embedUrlAsUrl.searchParams.append("disable-auto-focus", "true");
        }
        return embedUrlAsUrl.toString();
    }
    if (embedUrlAsUrl.hostname.includes("dropbox")) {
        embedUrlAsUrl.searchParams.append("raw", "1");
        return embedUrlAsUrl.toString();
    }
    return embedUrl;
};
exports.default = exports.EnterEmbedCode;
