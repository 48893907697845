"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Discount = void 0;
var Discount_util_1 = require("@CommonFrontendBackend/Discount/Discount.util");
var DiscountEditor_1 = require("./DiscountEditor/DiscountEditor");
var Discount = (function () {
    function Discount(createUi, make, description, discountAdapter, simpleDropdownFactory, accountQuoteSettings, discountService, quoteCurrencyFormatter) {
        var _this = this;
        this.oneOffDiscountName = "One Off Discount";
        this.oneOffDiscountClassName = "one-off-discount";
        this.onDiscountSelected = function (selected) {
            var isOneOffDiscountSelected = _this.isOneOffDiscount(selected);
            _this.ui.oneOffDiscount.isEnabled = isOneOffDiscountSelected;
            if (isOneOffDiscountSelected) {
                _this.setOneOffDiscount(_this.discountAdapter.getModel(), selected);
            }
            else {
                _this.applyCustomizedDiscount(_this.discountAdapter.getModel(), selected);
            }
        };
        this.getSelectedDiscount = function () {
            return _this.isOneOffDiscountApplied()
                ? _this.oneOffDiscount
                : _this.discountService.findDiscountById(_this.discountAdapter.getModel().id);
        };
        this.isCurrentlyAppliedDiscount = function (id) {
            return _this.discountAdapter.getModel().id === id;
        };
        this.createUiComponent = function () {
            var discountEditor = _this.make(DiscountEditor_1.DiscountEditor, {
                onDiscountUpdated: function (updatedDiscount) {
                    if (_this.isCurrentlyAppliedDiscount) {
                        _this.discountAdapter.getModel().units = updatedDiscount.amount;
                        _this.discountAdapter.getModel().type = updatedDiscount.type;
                    }
                },
            });
            _this.ui = _this.getUi(_this.getToggleWithLabel(), _this.oneOffDiscount);
            _this.ui.openDiscountEditor = function () {
                discountEditor.show(_this.quoteCurrencyFormatter);
            };
        };
        this.getUi = function (toggleWithLabel, oneOffDiscount) {
            return _this.createUi(require("./Discount.html"), {
                toggleUi: toggleWithLabel.ui,
                isDiscountEnabled: function () {
                    return toggleWithLabel.ui.flag;
                },
                onDiscountTypeClick: function (type) {
                    _this.ui.oneOffDiscount.type = type;
                    _this.discountAdapter.getModel().type = type;
                },
                currencySymbol: _this.quoteCurrencyFormatter.getSymbol(),
                oneOffDiscount: oneOffDiscount,
                onOneOffInputChange: function (val) {
                    _this.discountAdapter.getModel().units = val;
                },
                discountDropdown: _this.getDropdown(oneOffDiscount),
            });
        };
        this.getOneOffDiscount = function () {
            if (_this.isOneOffDiscountApplied()) {
                return __assign(__assign({}, (0, Discount_util_1.createNewDiscountItem)()), { type: _this.discountAdapter.getModel().type, amount: _this.discountAdapter.getModel().units, name: _this.oneOffDiscountName, isEnabled: true });
            }
            return __assign(__assign({}, (0, Discount_util_1.createNewDiscountItem)()), { name: _this.oneOffDiscountName, isEnabled: !_this.discountAdapter.getModel().id });
        };
        this.getToggleWithLabel = function () {
            return _this.make(require("./ToggleWithLabel"), {
                description: _this.description,
                getter: _this.discountAdapter.hasDiscount,
                setter: _this.discountAdapter.setDiscountEnabled,
            });
        };
        this.applyCustomizedDiscount = function (model, selected) {
            model.id = selected.id;
            delete model.units;
            delete model.type;
        };
        this.setOneOffDiscount = function (model, selected) {
            delete model.id;
            model.units = selected.amount;
            model.type = selected.type;
            model.enabled = true;
        };
        this.createUi = createUi;
        this.make = make;
        this.description = description;
        this.discountAdapter = discountAdapter;
        this.simpleDropdownFactory = simpleDropdownFactory;
        this.oneOffDiscount = this.getOneOffDiscount();
        this.discountService = discountService;
        this.quoteCurrencyFormatter = quoteCurrencyFormatter;
        this.createUiComponent();
    }
    Discount.prototype.isOneOffDiscountApplied = function () {
        return !this.discountAdapter.getModel().id;
    };
    Discount.prototype.isOneOffDiscount = function (item) {
        return item.className === this.oneOffDiscountClassName;
    };
    Discount.prototype.getDropdown = function (oneOffDiscount) {
        var _this = this;
        return this.simpleDropdownFactory.create(this.getAllDiscounts(oneOffDiscount), this.getSelectedDiscount(), this.onDiscountSelected, {
            searchFn: function (item, searchTerm) {
                if (_this.isOneOffDiscount(item)) {
                    return true;
                }
                else {
                    return item.name.toLowerCase().includes(searchTerm);
                }
            },
            getUpdatedItems: function () {
                return _this.getAllDiscounts(oneOffDiscount);
            },
        });
    };
    Discount.prototype.getAllDiscounts = function (oneOffDiscount) {
        return __spreadArray(__spreadArray([], __read(this.discountService.getAllLiveDiscounts()), false), [
            __assign(__assign({}, oneOffDiscount), { className: this.oneOffDiscountClassName }),
        ], false);
    };
    return Discount;
}());
exports.Discount = Discount;
