/*
  Shares a given project.
 */

(function () {
  var ProjectSharer;

  module.exports = ProjectSharer = (function () {
    function ProjectSharer(make, $http, apiBaseUrl, telemetry, $state) {
      var ShareOverlayCtrl, ShareWithTeamOverlayCtrl;
      ShareOverlayCtrl = require("Share/Share");
      ShareWithTeamOverlayCtrl = require("ShareWithTeam/Team");
      this.share = function (project, origin, overlayOptions) {
        var ref, ref1;
        if (((ref = project.links[0]) != null ? ref.secret : void 0) == null) {
          throw new Error("Trying to share a project with no secret link.");
        }
        return make(ShareOverlayCtrl, {
          project: project,
          linkSecret: ((ref1 = project.links[0]) != null ? ref1.secret : void 0) || null,
          overlayOptions: overlayOptions || {},
          origin: origin || "",
        });
      };
      this.shareWithTeam = function (project) {
        var ref;
        if (((ref = project.links[0]) != null ? ref.secret : void 0) == null) {
          throw new Error("Trying to share a project with no secret link.");
        }
        return make(ShareWithTeamOverlayCtrl, {
          project: project,
        });
      };
      this.setIndexing = function (project, allowIndexing) {
        return project.setAllowIndexing(allowIndexing).then(function () {
          if (allowIndexing) {
            return telemetry.trackOnly("Project Share Via Search");
          }
        });
      };
      this.setSocialBadge = function (project, enableSocialBadge) {
        return project.setEnableSocialBadge(enableSocialBadge);
      };
      this.trackSocialShare = function (platform) {
        return telemetry.trackOnly("Project Share Via Social", {
          project_share_via_social_target: platform,
        });
      };
    }

    return ProjectSharer;
  })();
}.call(this));
