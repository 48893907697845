"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PagePermissionsDataStore = void 0;
var mobx_1 = require("mobx");
var Types_1 = require("../Account/Types");
var Types_2 = require("@CommonFrontendBackend/Permissions/Types");
var PermissionTypes_1 = require("./PermissionTypes");
var PagePermissionsDataStore = (function () {
    function PagePermissionsDataStore(pageId, permissionsService, allAccountUsers, accountDataStore) {
        var _this = this;
        this.pageId = pageId;
        this.permissionsService = permissionsService;
        this.allAccountUsers = allAccountUsers;
        this.loading = true;
        this.errorFetching = false;
        this.isFetching = false;
        this.hasEditPermission = function (userId) {
            return (0, mobx_1.computed)(function () {
                var userWithEditPermission = _this.permittedUsers.find(function (permittedUser) {
                    return (permittedUser.id === userId && (permittedUser.role === Types_2.Role.Admin || permittedUser.permission !== Types_2.Action.View));
                });
                var userTeamWithEditPermissions = !!_this.allTeamPermissions.find(function (team) {
                    var isEditPermission = team.permission === Types_2.Action.Edit;
                    var isUserMember = team.members.includes(userId);
                    var isAccountTeam = team.type === Types_1.TeamType.Account;
                    return isEditPermission && (isUserMember || isAccountTeam);
                });
                return !!userWithEditPermission || userTeamWithEditPermissions;
            }).get();
        };
        this.isUserPermitted = function (user) {
            return user.role === Types_2.Role.Admin || user.permission !== Types_2.Action.None;
        };
        this.teamDataStore = accountDataStore.getTeamDataStore();
        this.pendingUsersDataStore = accountDataStore.getPendingUsersDataStore();
    }
    PagePermissionsDataStore.prototype.fetchData = function () {
        return __awaiter(this, arguments, Promise, function (forceFetch) {
            var _a, _teamData, pagePermissions_1, e_1;
            var _this = this;
            if (forceFetch === void 0) { forceFetch = false; }
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!((!this.enrichedExistingUserPermissions || forceFetch) && !this.isFetching)) return [3, 5];
                        this.isFetching = true;
                        _b.label = 1;
                    case 1:
                        _b.trys.push([1, 3, 4, 5]);
                        return [4, Promise.all([
                                this.teamDataStore.fetchData(),
                                this.permissionsService.getPagePermissions(this.pageId),
                            ])];
                    case 2:
                        _a = __read.apply(void 0, [_b.sent(), 2]), _teamData = _a[0], pagePermissions_1 = _a[1];
                        (0, mobx_1.runInAction)(function () {
                            _this.enrichedExistingUserPermissions = _this.enrichUserPermissions(pagePermissions_1);
                            _this.rawTeamPermissions = pagePermissions_1.teamPermissions;
                            _this.loading = false;
                        });
                        return [3, 5];
                    case 3:
                        e_1 = _b.sent();
                        (0, mobx_1.runInAction)(function () {
                            _this.errorFetching = true;
                        });
                        return [3, 5];
                    case 4:
                        this.isFetching = false;
                        return [7];
                    case 5: return [2];
                }
            });
        });
    };
    Object.defineProperty(PagePermissionsDataStore.prototype, "isLoading", {
        get: function () {
            return this.loading || this.teamDataStore.isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PagePermissionsDataStore.prototype, "erroredFetching", {
        get: function () {
            return this.errorFetching || this.teamDataStore.erroredFetching;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PagePermissionsDataStore.prototype, "permittedUsers", {
        get: function () {
            var pendingUsers = this.pendingUsers.filter(this.isUserPermitted);
            if (this.enrichedExistingUserPermissions) {
                return __spreadArray(__spreadArray([], __read(this.enrichedExistingUserPermissions.filter(this.isUserPermitted)), false), __read(pendingUsers), false);
            }
            return pendingUsers;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PagePermissionsDataStore.prototype, "unpermittedUsers", {
        get: function () {
            var _this = this;
            var pendingUsers = this.pendingUsers
                .filter(function (u) {
                return !_this.isUserPermitted(u);
            })
                .slice(0);
            if (this.enrichedExistingUserPermissions) {
                return __spreadArray(__spreadArray([], __read(this.enrichedExistingUserPermissions.filter(function (u) {
                    return !_this.isUserPermitted(u);
                })), false), __read(pendingUsers), false);
            }
            return pendingUsers;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PagePermissionsDataStore.prototype, "permittedTeams", {
        get: function () {
            return this.allTeamPermissions.filter(function (t) {
                return t.permission !== Types_2.Action.None;
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(PagePermissionsDataStore.prototype, "unpermittedTeams", {
        get: function () {
            return this.allTeamPermissions.filter(function (t) {
                return t.permission === Types_2.Action.None;
            });
        },
        enumerable: false,
        configurable: true
    });
    PagePermissionsDataStore.prototype.updateTeamPermissions = function (teamId, permission) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.permissionsService.updateTeamPermissions(this.pageId, teamId, permission)];
                    case 1:
                        _a.sent();
                        (0, mobx_1.runInAction)(function () {
                            if (!_this.rawTeamPermissions) {
                                throw new Error("Need to fetch permissions before updating them");
                            }
                            var team = _this.rawTeamPermissions.find(function (t) {
                                return t.teamId === teamId;
                            });
                            if (team) {
                                team.action = permission;
                            }
                            else {
                                _this.rawTeamPermissions.push({ teamId: teamId, action: permission });
                            }
                        });
                        return [2];
                }
            });
        });
    };
    PagePermissionsDataStore.prototype.updateUserPermissions = function (userIds, permission) {
        return __awaiter(this, void 0, Promise, function () {
            var pendingUsersIds, existingUserIds, pendingUsersPromise, existingUsersPromise;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.enrichedExistingUserPermissions) {
                            throw new Error("Need to fetch permissions before updating them");
                        }
                        pendingUsersIds = this.pendingUsers
                            .filter(function (p) {
                            return userIds.includes(p.id);
                        })
                            .map(function (p) {
                            return p.id;
                        });
                        existingUserIds = this.enrichedExistingUserPermissions
                            .filter(function (u) {
                            return userIds.includes(u.id);
                        })
                            .map(function (u) {
                            return u.id;
                        });
                        pendingUsersPromise = this.pendingUsersDataStore.modifyPendingUserPermissions(pendingUsersIds, this.pageId, permission);
                        existingUsersPromise = this.updateExistingUserPermissions(existingUserIds, permission);
                        return [4, Promise.all([pendingUsersPromise, existingUsersPromise])];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        });
    };
    PagePermissionsDataStore.prototype.getUserPermission = function (userId) {
        if (!this.enrichedExistingUserPermissions) {
            throw new Error("Need to fetch permissions before getting them");
        }
        var user = this.enrichedExistingUserPermissions.find(function (u) {
            return u.id === userId;
        }) ||
            this.pendingUsers.find(function (u) {
                return u.id === userId;
            });
        if (!!user) {
            return user.permission;
        }
        return Types_2.Action.None;
    };
    PagePermissionsDataStore.prototype.enrichUserPermissions = function (fetchedPermissions) {
        var e_2, _a;
        var userPermissions = [];
        var _loop_1 = function (user) {
            var userPermission = Types_2.Action.None;
            var permission = fetchedPermissions.userPermissions.find(function (u) {
                return u.userId === user.id;
            });
            if (permission) {
                userPermission = permission.action;
            }
            userPermissions.push(__assign(__assign({}, user), { permission: userPermission, type: PermissionTypes_1.UserType.Existing }));
        };
        try {
            for (var _b = __values(this.allAccountUsers.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var user = _c.value;
                _loop_1(user);
            }
        }
        catch (e_2_1) { e_2 = { error: e_2_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_2) throw e_2.error; }
        }
        return userPermissions.sort(function (a, b) {
            return a.firstName.localeCompare(b.firstName);
        });
    };
    Object.defineProperty(PagePermissionsDataStore.prototype, "pendingUsers", {
        get: function () {
            var _this = this;
            return this.pendingUsersDataStore.getPendingUsers().map(function (u) {
                return {
                    id: u.id,
                    email: u.email,
                    role: u.role,
                    permission: _this.getPagePermission(u),
                    type: PermissionTypes_1.UserType.Pending,
                };
            });
        },
        enumerable: false,
        configurable: true
    });
    PagePermissionsDataStore.prototype.getPagePermission = function (pendingUser) {
        var _this = this;
        if (pendingUser.permissions) {
            var pagePermission = pendingUser.permissions.find(function (p) {
                return p.projectId === _this.pageId;
            });
            if (!!pagePermission) {
                return pagePermission.permission;
            }
        }
        return Types_2.Action.None;
    };
    Object.defineProperty(PagePermissionsDataStore.prototype, "allTeamPermissions", {
        get: function () {
            var e_3, _a;
            if (!this.teamDataStore.getTeams()) {
                return [];
            }
            var teamPermissions = [];
            var teamAccount;
            var _loop_2 = function (team) {
                var teamPermission = Types_2.Action.None;
                if (!!this_1.rawTeamPermissions) {
                    var permission = this_1.rawTeamPermissions.find(function (t) {
                        return t.teamId === team.id;
                    });
                    if (permission) {
                        teamPermission = permission.action;
                    }
                }
                var enrichedTeam = {
                    id: team.id,
                    name: team.name,
                    type: team.teamType,
                    permission: teamPermission,
                    members: team.members,
                };
                if (team.teamType === Types_1.TeamType.Account) {
                    teamAccount = enrichedTeam;
                }
                else {
                    teamPermissions.push(enrichedTeam);
                }
            };
            var this_1 = this;
            try {
                for (var _b = __values(this.teamDataStore.getTeams().values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var team = _c.value;
                    _loop_2(team);
                }
            }
            catch (e_3_1) { e_3 = { error: e_3_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_3) throw e_3.error; }
            }
            teamPermissions.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
            if (teamAccount) {
                teamPermissions.unshift(teamAccount);
            }
            return teamPermissions;
        },
        enumerable: false,
        configurable: true
    });
    PagePermissionsDataStore.prototype.updateExistingUserPermissions = function (userIds, permission) {
        return __awaiter(this, void 0, Promise, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.permissionsService.updateUserPermissions(userIds, this.pageId, permission)];
                    case 1:
                        _a.sent();
                        (0, mobx_1.runInAction)(function () {
                            var e_4, _a;
                            if (!_this.enrichedExistingUserPermissions) {
                                throw new Error("Need to fetch permissions before getting them");
                            }
                            var _loop_3 = function (userId) {
                                var user = _this.enrichedExistingUserPermissions.find(function (u) {
                                    return u.id === userId;
                                });
                                if (user != undefined) {
                                    user.permission = permission;
                                }
                            };
                            try {
                                for (var userIds_1 = __values(userIds), userIds_1_1 = userIds_1.next(); !userIds_1_1.done; userIds_1_1 = userIds_1.next()) {
                                    var userId = userIds_1_1.value;
                                    _loop_3(userId);
                                }
                            }
                            catch (e_4_1) { e_4 = { error: e_4_1 }; }
                            finally {
                                try {
                                    if (userIds_1_1 && !userIds_1_1.done && (_a = userIds_1.return)) _a.call(userIds_1);
                                }
                                finally { if (e_4) throw e_4.error; }
                            }
                        });
                        return [2];
                }
            });
        });
    };
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", Object)
    ], PagePermissionsDataStore.prototype, "enrichedExistingUserPermissions", void 0);
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", Object)
    ], PagePermissionsDataStore.prototype, "rawTeamPermissions", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], PagePermissionsDataStore.prototype, "loading", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], PagePermissionsDataStore.prototype, "errorFetching", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], PagePermissionsDataStore.prototype, "isLoading", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], PagePermissionsDataStore.prototype, "erroredFetching", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], PagePermissionsDataStore.prototype, "permittedUsers", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], PagePermissionsDataStore.prototype, "unpermittedUsers", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], PagePermissionsDataStore.prototype, "permittedTeams", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], PagePermissionsDataStore.prototype, "unpermittedTeams", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], PagePermissionsDataStore.prototype, "pendingUsers", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], PagePermissionsDataStore.prototype, "allTeamPermissions", null);
    return PagePermissionsDataStore;
}());
exports.PagePermissionsDataStore = PagePermissionsDataStore;
