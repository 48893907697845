"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SharePageViewStore = void 0;
var mobx_1 = require("mobx");
var lodash_1 = require("lodash");
var Types_1 = require("@CommonFrontendBackend/Permissions/Types");
var Types_2 = require("DataStores/Account/Types");
var SharePageViewStore = (function () {
    function SharePageViewStore(rootDataStore, projectStore) {
        var _this = this;
        this.rootDataStore = rootDataStore;
        this.fetchData = function () { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.pagePermissionsDataStore.fetchData()];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        }); };
        this.updateTeamPermissions = function (teamId, permission) { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.pagePermissionsDataStore.updateTeamPermissions(teamId, permission)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        }); };
        this.updateUserPermissions = function (userId, permission) { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.pagePermissionsDataStore.updateUserPermissions(userId, permission)];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        }); };
        this.pageFoldersDataStore = rootDataStore.pageFoldersDataStore;
        this.pagePermissionsDataStore = rootDataStore.getPermissionsDataStore().getPagePermissions(projectStore._id);
        this.projectStore = projectStore;
    }
    Object.defineProperty(SharePageViewStore.prototype, "erroredFetching", {
        get: function () {
            return this.pagePermissionsDataStore.erroredFetching;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SharePageViewStore.prototype, "isLoading", {
        get: function () {
            return this.pagePermissionsDataStore.isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SharePageViewStore.prototype, "parentFolderId", {
        get: function () {
            return this.projectStore.parentFolder;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SharePageViewStore.prototype, "permittedTeams", {
        get: function () {
            return this.teamsWithInheritedPermissions.filter(function (t) {
                return t.permission !== Types_1.Action.None || t.inheritedPermission;
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SharePageViewStore.prototype, "permittedUsers", {
        get: function () {
            return this.pagePermissionsDataStore.permittedUsers;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SharePageViewStore.prototype, "unpermittedTeams", {
        get: function () {
            return this.teamsWithInheritedPermissions.filter(function (t) {
                return t.permission === Types_1.Action.None && !t.inheritedPermission;
            });
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SharePageViewStore.prototype, "unpermittedUsers", {
        get: function () {
            return this.pagePermissionsDataStore.unpermittedUsers;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SharePageViewStore.prototype, "teamsWithInheritedPermissions", {
        get: function () {
            var teamPermissions = (0, lodash_1.cloneDeep)((0, mobx_1.toJS)(this.pagePermissionsDataStore.allTeamPermissions));
            var inheritedPermissions = this.inheritedFolderPermissions;
            if (inheritedPermissions === null || inheritedPermissions === void 0 ? void 0 : inheritedPermissions.account) {
                var accountPermission = teamPermissions.find(function (t) { return t.type === Types_2.TeamType.Account; });
                accountPermission.inheritedPermission = {
                    folderName: inheritedPermissions.account.folderName,
                    permission: inheritedPermissions.account.action,
                };
            }
            return teamPermissions;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SharePageViewStore.prototype, "inheritedFolderPermissions", {
        get: function () {
            if (this.parentFolderId) {
                return this.pageFoldersDataStore.getEffectiveFolderPermissions(this.parentFolderId);
            }
        },
        enumerable: false,
        configurable: true
    });
    var _a;
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], SharePageViewStore.prototype, "erroredFetching", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], SharePageViewStore.prototype, "isLoading", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], SharePageViewStore.prototype, "parentFolderId", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], SharePageViewStore.prototype, "permittedTeams", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], SharePageViewStore.prototype, "permittedUsers", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], SharePageViewStore.prototype, "unpermittedTeams", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], SharePageViewStore.prototype, "unpermittedUsers", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Array),
        __metadata("design:paramtypes", [])
    ], SharePageViewStore.prototype, "teamsWithInheritedPermissions", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], SharePageViewStore.prototype, "inheritedFolderPermissions", null);
    return SharePageViewStore;
}());
exports.SharePageViewStore = SharePageViewStore;
