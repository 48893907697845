/*
  This module displays the audit trail for a project
 */

(function () {
  var AuditTrail, FeatureFlag, ProjectSubStatus, Retriever, generateManuallyAcceptedAuditTrail;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  ProjectSubStatus = require("@CommonFrontendBackend/Projects/Types").ProjectSubStatus;

  Retriever = require("./Retriever");

  generateManuallyAcceptedAuditTrail = require("./generateManuallyAcceptedAuditTrail")
    .generateManuallyAcceptedAuditTrail;

  module.exports = AuditTrail = (function () {
    function AuditTrail(
      make,
      createUi,
      overlayStack,
      project,
      upseller,
      telemetry,
      usingFeature,
      createLoadingController,
      $rootScope,
    ) {
      var createAuditTrailUi, loadAuditTrail, projectAuditRetriever;
      projectAuditRetriever = make(Retriever);
      createAuditTrailUi = function (auditTrail) {
        telemetry.trackOnly("Audit Trail Viewed");
        return createUi(require("./AuditTrail.html"), {
          auditTrail: auditTrail,
          project: project,
          $onElementRendered: (function (_this) {
            return function (elem, elemName) {
              if (elemName === "containerElem" && !usingFeature(FeatureFlag.AuditTrail)) {
                return upseller.upsell({
                  feature: "audit trail",
                  featureLabel: "Audit Trail",
                  blur: $(".overlay-stack .current-overlay"),
                  onClose: function () {
                    return overlayStack.closeCurrent();
                  },
                });
              }
            };
          })(this),
        });
      };
      loadAuditTrail = function () {
        if (project.subStatus === ProjectSubStatus.AcceptedManual) {
          return generateManuallyAcceptedAuditTrail(project)
            .then(function (data) {
              return createAuditTrailUi(data);
            })
            .then(function (ui) {
              try {
                $rootScope.$apply();
              } catch (error) {}
              return ui;
            });
        } else {
          return projectAuditRetriever.retrieve(project._id).then(function (data) {
            return createAuditTrailUi(data);
          });
        }
      };
      this.show = (function (_this) {
        return function () {
          var ui;
          ui = createLoadingController(loadAuditTrail());
          return overlayStack.add({
            contentUi: ui,
            options: {
              className: "audit-trail-overlay",
            },
          });
        };
      })(this);
    }

    return AuditTrail;
  })();
}.call(this));
