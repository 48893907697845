// Generated by CoffeeScript 1.12.7

/*
  Defines the Source Block UI.
  @param block [Object] - the data model for this block.
 */

(function () {
  var isUsingTCDC, url;

  url = require("url");

  isUsingTCDC = require("@CommonFrontendBackend/FeatureFlag").isUsingTCDC;

  module.exports = function (block, make, createUi, viewState, utils, $timeout, usingFeature, projectStore, $log) {
    var id, onDisplayMode, refreshIframe, sourceSelector, usingTCDC;
    usingTCDC = isUsingTCDC(usingFeature);
    viewState.displayMode = block.url != null && block.url.length > 0;
    id = utils.uniqueId();
    refreshIframe = (function (_this) {
      return function () {
        return $("#" + id).replaceWith("<iframe id='" + id + "' src='" + block.url + "'></iframe>");
      };
    })(this);
    onDisplayMode = (function (_this) {
      return function () {
        /*
        @NOTE (som, 2021-07-08):
        For reasons unknown, the iframe's `onload` callback sometimes does not trigger when in the project editor,
        even though the content inside seems to be fully loaded. As a workaround, we will assume that the iframe
        is loaded after a long timeout of `FALLBACK_TIMEOUT_MS`
        
        This timeout duration of 12 seconds is fairly arbitrary - we just want it to be long enough to allow a
        normal `onload` to trigger during a page load before this timeout is reached. 12 seconds was chosen as
        it is approximately the P90 soft-load time for the project editor.
         */
        var FALLBACK_TIMEOUT_MS, onloadTriggered;
        FALLBACK_TIMEOUT_MS = 12000;
        _this.ui.loading = true;
        _this.ui.showError = false;
        onloadTriggered = false;
        $timeout(function () {
          var iframe;
          iframe = document.getElementById(id);
          if (iframe != null) {
            return (iframe.onload = function () {
              onloadTriggered = true;

              /*
              @HACK (som, 2018-07-02): a little while after the iframe finishes loading, hide the
              loading spinner and allow the iframe to show.
              See `Server/Source/Public/Compiler/Blocks/Source.html` for an explaination
               */
              $timeout(function () {
                return (_this.ui.loading = false);
              }, 2000);

              /*
              Only show the error message a few seconds after loading, to give time for the
              iframe content to load and cover it up.
              (Since there is no way to detect if iframe content has loaded *successfully*, we always
              show the error message and rely on the iframe content to display in front of it)
               */
              return $timeout(function () {
                return (_this.ui.showError = true);
              }, 4000);
            });
          }
        });
        return $timeout(function () {
          if (!onloadTriggered) {
            $log.warn(
              "iframe onload did not trigger after " + FALLBACK_TIMEOUT_MS / 1000 + " seconds: " + _this.ui.getUrl(),
            );
            return (_this.ui.loading = false);
          }
        }, FALLBACK_TIMEOUT_MS);
      };
    })(this);
    sourceSelector = make(require("./SourceSelector/SourceSelector"));
    return (this.ui = createUi(require("./Source.html"), {
      block: block,
      viewState: viewState,
      iframeId: id,
      sourceSelector: sourceSelector.ui,
      hasEditAccess: projectStore.isEditable,
      isFixedSize: (function (_this) {
        return function () {
          return !usingTCDC || !_this.ui.viewState.displayMode;
        };
      })(this),
      loading: false,
      showError: false,
      editSource: (function (_this) {
        return function () {
          var sourceItem;
          _this.ui.viewState.displayMode = false;
          sourceItem = _.find(_this.ui.sourceSelector.sources, {
            internal: _this.ui.block.sourceType,
          });
          return _this.ui.sourceSelector.selectSource(sourceItem);
        };
      })(this),
      toDisplayMode: (function (_this) {
        return function () {
          _this.ui.viewState.displayMode = true;
          return onDisplayMode();
        };
      })(this),
      getUrl: (function (_this) {
        return function () {
          var parsed;
          parsed = url.parse(_this.ui.block.url);
          if (parsed.protocol == null) {
            parsed.protocol = "https";
            parsed.slashes = true;
            _this.ui.block.url = url.format(parsed);
          } else {
            switch (parsed.protocol) {
              case "https":
              case "https:":
                _this.ui.block.url;
                break;
              default:
                _this.ui.block.url = "";
            }
          }
          return _this.ui.block.url;
        };
      })(this),
      $onElementRendered: (function (_this) {
        return function (elem, domName) {
          if (domName === "iframe") {
            return onDisplayMode();
          }
        };
      })(this),
    }));
  };
}.call(this));
