"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCommentTokens = void 0;
var EditorTypes_1 = require("./../EditorTypes");
var GetCommonConfigByType_1 = require("@CommonFrontend/WidgetRegistry/Utils/GetCommonConfigByType");
var getCommentTokens = function (tokens) {
    var flattenedTokens = [];
    tokens.forEach(function (token) {
        var _a;
        var widgetFlattenFn = (_a = (0, GetCommonConfigByType_1.getGenericCommonConfigByType)(token.subType)) === null || _a === void 0 ? void 0 : _a.flattenForComments;
        if (widgetFlattenFn) {
            widgetFlattenFn(token, flattenedTokens);
            return;
        }
        switch (token.subType) {
            default: {
                flattenedTokens.push(token);
            }
        }
    });
    return flattenedTokens.filter(function (token) { return token.type == EditorTypes_1.MarkType.Comment; });
};
exports.getCommentTokens = getCommentTokens;
