"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TemplateAlgorithmButton = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var EngagementCalculatingAnimated_1 = require("Common/Engagement/EngagementCalculatingAnimated");
var AssignAlgorithmModal_1 = require("./AssignAlgorithmModal");
var UseTemplateEngagementAlgorithm_1 = require("./UseTemplateEngagementAlgorithm");
var TemplateAlgorithmButton = function (_a) {
    var templateId = _a.templateId, templateName = _a.templateName;
    var _b = (0, UseTemplateEngagementAlgorithm_1.useTemplateEngagementAlgorithm)(templateId), algorithmId = _b.algorithmId, isEngagementAlgorithmLoading = _b.isLoading, assignAlgorithm = _b.assignAlgorithm, unassignAlgorithm = _b.unassignAlgorithm;
    var _c = __read((0, react_1.useState)(null), 2), algorithmModalState = _c[0], setAlgorithmModalState = _c[1];
    function openAlgorithmModal(initialAlgorithmId) {
        setAlgorithmModalState({
            initialAlgorithmId: initialAlgorithmId,
        });
    }
    function applyAlgorithm(algorithmId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!algorithmId) return [3, 2];
                        return [4, assignAlgorithm(algorithmId)];
                    case 1:
                        _a.sent();
                        return [3, 4];
                    case 2: return [4, unassignAlgorithm()];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4: return [2];
                }
            });
        });
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        algorithmModalState && (react_1.default.createElement("div", { style: { cursor: "default" } },
            react_1.default.createElement(AssignAlgorithmModal_1.AssignAlgorithmModal, { templateId: templateId, templateName: templateName, algorithmId: algorithmModalState.initialAlgorithmId, onApply: applyAlgorithm, onClose: function () { return setAlgorithmModalState(null); } }))),
        react_1.default.createElement(kaleidoscope_1.IconButton, { icon: getEngagementIcon(algorithmId, isEngagementAlgorithmLoading), tooltip: { content: "Engagement algorithm" }, onClick: function (e) {
                e.stopPropagation();
                openAlgorithmModal(algorithmId);
            }, disabled: isEngagementAlgorithmLoading })));
};
exports.TemplateAlgorithmButton = TemplateAlgorithmButton;
function getEngagementIcon(algorithmId, isLoading) {
    if (isLoading)
        return react_1.default.createElement(EngagementCalculatingAnimated_1.EngagementCalculatingAnimated, null);
    if (!algorithmId)
        return react_1.default.createElement(icons_1.EngagementUnmonitored, null);
    return react_1.default.createElement(icons_1.EngagementColor, null);
}
