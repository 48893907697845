// Generated by CoffeeScript 1.12.7

/*
  Defines some special behaviour for key events on placeholders
 */

(function () {
  var PlaceholderHandler, h;

  h = require("../Helpers.js");

  module.exports = PlaceholderHandler = (function () {
    function PlaceholderHandler(editor) {
      var types;
      types = qed.keycodes.types;
      this.onKey = function (e) {
        var curr, elem, handled, keyType, placeholderElem;
        handled = false;
        elem = h.upToBlockElem(e.point);
        if (!elem.hasAttribute("placeholder")) {
          return false;
        }
        keyType = qed.keycodes.computeKeyType(e);
        if (keyType === types.ENTER) {
          e.preventDefault();
          curr = elem;
          placeholderElem = null;
          while (curr.nextSibling != null) {
            curr = curr.nextSibling;
            if (curr.hasAttribute != null && curr.hasAttribute("placeholder")) {
              placeholderElem = curr;
              break;
            }
          }
          if (placeholderElem != null) {
            editor.selection().setCaret(qed.Point.start(placeholderElem));
            handled = true;
          }
        }
        switch (keyType) {
          case types.ENTER:
          case types.INPUT:
            elem.removeAttribute("placeholder");
        }
        return handled;
      };
    }

    return PlaceholderHandler;
  })();
}.call(this));
