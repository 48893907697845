"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NewFeatureIndicatorName = exports.anytimeSteps = exports.PagesOnboardingBannerAction = exports.ROIStepId = exports.TelemetryStepId = void 0;
var PromptTypes_1 = require("Prompt/PromptTypes");
var TelemetryStepId;
(function (TelemetryStepId) {
    TelemetryStepId["Comments"] = "comments tooltip";
    TelemetryStepId["ContentLibrary"] = "content library tooltip";
    TelemetryStepId["EditText"] = "editor highlight text 001";
    TelemetryStepId["Styles"] = "editor block styles 001";
    TelemetryStepId["AddBlock"] = "editor add block 001";
    TelemetryStepId["Share"] = "editor share 001";
})(TelemetryStepId || (exports.TelemetryStepId = TelemetryStepId = {}));
var ROIStepId;
(function (ROIStepId) {
    ROIStepId["StartTour"] = "startTour";
    ROIStepId["Formula"] = "formula";
    ROIStepId["FormulaEditor"] = "editFormula";
    ROIStepId["ShowVariables"] = "showVariables";
    ROIStepId["AddEditVariables"] = "addEditVariables";
    ROIStepId["EditSlider"] = "editSlider";
})(ROIStepId || (exports.ROIStepId = ROIStepId = {}));
var PagesOnboardingBannerAction;
(function (PagesOnboardingBannerAction) {
    PagesOnboardingBannerAction["EditorExperience"] = "pages_editorExperience";
    PagesOnboardingBannerAction["BrandSetup"] = "pages_brandSetup";
    PagesOnboardingBannerAction["GetHelp"] = "pages_getHelp";
    PagesOnboardingBannerAction["DocConversion"] = "pages_docConversion";
    PagesOnboardingBannerAction["CreateFromTemplate"] = "pages_createFromTemplate";
})(PagesOnboardingBannerAction || (exports.PagesOnboardingBannerAction = PagesOnboardingBannerAction = {}));
var onboardingSteps = [PromptTypes_1.PromptId.EditText, PromptTypes_1.PromptId.Styles, PromptTypes_1.PromptId.AddBlock, PromptTypes_1.PromptId.Share];
exports.anytimeSteps = [PromptTypes_1.PromptId.AddAgreement, PromptTypes_1.PromptId.ContentSidebarExploreTab];
var NewFeatureIndicatorName;
(function (NewFeatureIndicatorName) {
    NewFeatureIndicatorName["PipelineReport"] = "pipelineReport";
    NewFeatureIndicatorName["TemplatesDashboard"] = "templatesDashboard";
    NewFeatureIndicatorName["AutomationsLibrary"] = "automationsLibrary";
})(NewFeatureIndicatorName || (exports.NewFeatureIndicatorName = NewFeatureIndicatorName = {}));
