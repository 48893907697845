"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClickToAddStore = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var WidgetManager_1 = require("../WidgetManager");
var InvalidReason_1 = require("./InvalidReason");
var lodash_1 = require("lodash");
var Types_1 = require("Project/ContentSidebar/Types");
var mobx_1 = require("mobx");
var ClickToAddStore = (function () {
    function ClickToAddStore(notifyUser, mediaUsageReporter) {
        var _this = this;
        this.notifyUser = notifyUser;
        this.mediaUsageReporter = mediaUsageReporter;
        this.updateLastKnownSelection = function (data) {
            _this.clearCurrentEntry();
            if (data.type === "editor") {
                var editor = data.widgetManager.editor;
                var selection = editor.selection;
                _this.lastKnownEntry = {
                    type: data.type,
                    widgetManager: data.widgetManager,
                    selection: selection,
                };
            }
            else {
                _this.lastKnownEntry = data;
            }
        };
        this.clickToAdd = function (_a) {
            var mediaData = _a.mediaData, mediaSource = _a.mediaSource, telemetryData = _a.telemetryData;
            var mediaType = mediaData.type === Types_1.AssetType.Video ? "video" : "image";
            if ((0, lodash_1.isNil)(_this.lastKnownEntry)) {
                _this.notifyForInvalidClickToAdd(InvalidReason_1.InvalidReason.EditorNoLongerExists, mediaType);
                return;
            }
            if (_this.lastKnownEntry.type === "editor") {
                var _b = _this.lastKnownEntry, selection = _b.selection, widgetManager = _b.widgetManager;
                widgetManager.triggerEvent(new WidgetManager_1.WidgetEvent(mediaType, {
                    action: "clickToAdd",
                    selection: selection,
                    mediaData: mediaData,
                    mediaSource: mediaSource,
                    telemetryData: telemetryData,
                }));
            }
            else {
                _this.lastKnownEntry.updateBlockBackground("type", mediaType);
                _this.lastKnownEntry.updateBlockBackground("url", mediaData.url);
                if (mediaType === "video" && mediaData.thumbnailUrl) {
                    _this.lastKnownEntry.updateBlockBackground("videoThumbnail", mediaData.thumbnailUrl);
                }
                _this.mediaUsageReporter.reportUsage(mediaSource, mediaData);
            }
        };
        this.deregisterEditor = function (editor) {
            if ((0, lodash_1.isNil)(_this.lastKnownEntry)) {
                return;
            }
            if (_this.lastKnownEntry.type === "editor") {
                if (_this.lastKnownEntry.widgetManager.editor === editor) {
                    _this.clearCurrentEntry();
                }
            }
        };
        this.clearBlockEntry = function () {
            var _a;
            if (((_a = _this.lastKnownEntry) === null || _a === void 0 ? void 0 : _a.type) === "block") {
                _this.clearCurrentEntry();
            }
        };
        this.notifyForInvalidClickToAdd = function (invalidReason, assetType) {
            if (assetType === void 0) { assetType = "image"; }
            _this.notifyUser.showNotification("Select a location in the editor to add this ".concat(assetType, "."), kaleidoscope_1.NotificationType.Info);
            console.error("Click to add failed because of reason: ", invalidReason);
        };
        this.clearCurrentEntry = function () {
            _this.lastKnownEntry = null;
        };
    }
    Object.defineProperty(ClickToAddStore.prototype, "targetLocation", {
        get: function () {
            var _a, _b;
            return (_b = (_a = this.lastKnownEntry) === null || _a === void 0 ? void 0 : _a.type) !== null && _b !== void 0 ? _b : null;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], ClickToAddStore.prototype, "lastKnownEntry", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], ClickToAddStore.prototype, "targetLocation", null);
    return ClickToAddStore;
}());
exports.ClickToAddStore = ClickToAddStore;
