// Generated by CoffeeScript 1.12.7
(function () {
  var bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    },
    extend = function (child, parent) {
      for (var key in parent) {
        if (hasProp.call(parent, key)) child[key] = parent[key];
      }
      function ctor() {
        this.constructor = child;
      }
      ctor.prototype = parent.prototype;
      child.prototype = new ctor();
      child.__super__ = parent.prototype;
      return child;
    },
    hasProp = {}.hasOwnProperty;

  module.exports = function (EditorModel) {
    var TableEditorModel;
    return (TableEditorModel = (function (superClass) {
      extend(TableEditorModel, superClass);

      function TableEditorModel(table, row, col) {
        this.updateWidgets = bind(this.updateWidgets, this);
        this.getWidgets = bind(this.getWidgets, this);
        this.getContent = bind(this.getContent, this);
        this.updateHtml = bind(this.updateHtml, this);
        this.getHtml = bind(this.getHtml, this);
        this.table = table;
        this.row = row;
        this.col = col;
      }

      TableEditorModel.prototype.getHtml = function () {
        return this.table[this.row][this.col].content;
      };

      TableEditorModel.prototype.updateHtml = function (content) {
        return (this.table[this.row][this.col].content = content);
      };

      TableEditorModel.prototype.getContent = function () {
        return this.table[this.row][this.col];
      };

      TableEditorModel.prototype.getWidgets = function () {
        return {};
      };

      TableEditorModel.prototype.updateWidgets = function () {};

      return TableEditorModel;
    })(EditorModel));
  };
}.call(this));
