"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RolesService = void 0;
var RolesService = (function () {
    function RolesService($http, apiBaseUrl, account) {
        var _this = this;
        this.$http = $http;
        this.apiBaseUrl = apiBaseUrl;
        this.account = account;
        this.getRole = function (user) { var _a; return ((_a = user.roles[0]) === null || _a === void 0 ? void 0 : _a.name) || "creator"; };
        this.setRole = function (user, role) {
            return _this.$http
                .post("".concat(_this.apiBaseUrl, "/accounts/").concat(_this.account._id, "/roles"), {
                userRoles: [
                    {
                        userId: user._id,
                        roles: [{ name: role }],
                    },
                ],
            })
                .then(function () {
                var _a;
                if ((_a = user.roles) === null || _a === void 0 ? void 0 : _a.length) {
                    user.roles[0].name = role;
                }
                else {
                    user.roles.push({ name: role });
                }
            });
        };
        this.isAdmin = function (user) {
            return _this.getRole(user) === "admin";
        };
    }
    return RolesService;
}());
exports.RolesService = RolesService;
