// Generated by CoffeeScript 1.12.7
(function () {
  var FoldableWidgetController;

  module.exports = FoldableWidgetController = (function () {
    function FoldableWidgetController(createUi, make, viewState, block) {
      var base;
      if ((base = block.settings).foldable == null) {
        base.foldable = {};
      }
      this.ui = createUi(require("Project/BlockNew/FoldableButton/Foldable.html"), {
        settings: block.settings.foldable,
        viewState: viewState,
        visible: function () {
          return block.settings.foldable.isFoldable;
        },
        toggleFold: function () {
          return (block.settings.foldable.isFolded = !block.settings.foldable.isFolded);
        },
        blockId: block.$id,
      });
    }

    return FoldableWidgetController;
  })();
}.call(this));
