"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SavedBlockFilterViewStore = exports.EditingLockedToAdminFilter = exports.blockTypeFilterList = void 0;
var mobx_1 = require("mobx");
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var StringManipulater_1 = require("Utils/StringManipulater");
exports.blockTypeFilterList = [
    {
        label: "Text",
        type: BlockTypes_1.BlockType.Text,
    },
    {
        label: "Splash",
        type: BlockTypes_1.BlockType.Splash,
    },
    {
        label: "Video",
        type: BlockTypes_1.BlockType.Video,
    },
    {
        label: "Embed",
        type: BlockTypes_1.BlockType.Source,
    },
    {
        label: "Quote",
        type: BlockTypes_1.BlockType.Quote,
    },
    {
        label: "Accept",
        type: BlockTypes_1.BlockType.Accept,
    },
];
var EditingLockedToAdminFilter;
(function (EditingLockedToAdminFilter) {
    EditingLockedToAdminFilter["ShowAllBlocks"] = "ShowAllBlocks";
    EditingLockedToAdminFilter["EditableByAdminsOnly"] = "EditableByAdminsOnly";
    EditingLockedToAdminFilter["EditableByEveryone"] = "EditableByEveryone";
})(EditingLockedToAdminFilter || (exports.EditingLockedToAdminFilter = EditingLockedToAdminFilter = {}));
var SavedBlockFilterViewStore = (function () {
    function SavedBlockFilterViewStore(viewStore) {
        var _this = this;
        this.viewStore = viewStore;
        this.areAnyFiltersSelectedOnOpen = false;
        this.clearAllFilters = function () {
            _this.viewStore.clearAllFilters();
        };
        this.handlePopoverChange = function (isOpen) {
            if (isOpen) {
                _this.areAnyFiltersSelectedOnOpen =
                    _this.viewStore.selectedBlockTypeFilterList.length > 0 ||
                        _this.viewStore.editingLockedToAdminFilter !== EditingLockedToAdminFilter.ShowAllBlocks;
            }
        };
        this.isBlockTypeSelected = function (type) {
            return (0, mobx_1.computed)(function () {
                return _this.viewStore.selectedBlockTypeFilterList.includes(type);
            }).get();
        };
        this.toggleBlockType = function (type) {
            var selected = _this.viewStore.selectedBlockTypeFilterList.includes(type);
            if (selected) {
                _this.viewStore.removeFilterEntry(type);
            }
            else {
                _this.viewStore.addFilterEntry(type);
            }
        };
        this.setEditingLockedToAdminFilter = function (selected) {
            _this.viewStore.setEditingLockedToAdminFilter(selected);
        };
    }
    Object.defineProperty(SavedBlockFilterViewStore.prototype, "filterLabel", {
        get: function () {
            var numFilters = this.numFilters;
            if (numFilters > 0) {
                return (0, StringManipulater_1.pluralise)(numFilters, "filter");
            }
            else {
                return "Filters";
            }
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SavedBlockFilterViewStore.prototype, "numFilters", {
        get: function () {
            var numFilters = 0;
            numFilters += this.viewStore.selectedBlockTypeFilterList.length;
            var editingLockedToAdminFilter = this.viewStore.editingLockedToAdminFilter;
            if (editingLockedToAdminFilter !== EditingLockedToAdminFilter.ShowAllBlocks) {
                numFilters += 1;
            }
            return numFilters;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(SavedBlockFilterViewStore.prototype, "editingLockedToAdminFilter", {
        get: function () {
            return this.viewStore.editingLockedToAdminFilter;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], SavedBlockFilterViewStore.prototype, "isFilterSelectorOpen", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], SavedBlockFilterViewStore.prototype, "areAnyFiltersSelectedOnOpen", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], SavedBlockFilterViewStore.prototype, "filterLabel", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], SavedBlockFilterViewStore.prototype, "numFilters", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], SavedBlockFilterViewStore.prototype, "editingLockedToAdminFilter", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Object)
    ], SavedBlockFilterViewStore.prototype, "clearAllFilters", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Object)
    ], SavedBlockFilterViewStore.prototype, "handlePopoverChange", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Object)
    ], SavedBlockFilterViewStore.prototype, "toggleBlockType", void 0);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Object)
    ], SavedBlockFilterViewStore.prototype, "setEditingLockedToAdminFilter", void 0);
    return SavedBlockFilterViewStore;
}());
exports.SavedBlockFilterViewStore = SavedBlockFilterViewStore;
