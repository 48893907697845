/*
  Provides interface for sharing qwilrs.
 */

(function () {
  var ProjectShareViewedFields, ShareController, TelemetryEventName;

  ShareController = require("./ShareController")["default"];

  ProjectShareViewedFields = require("Telemetry").ProjectShareViewedFields;

  TelemetryEventName = require("@CommonFrontendBackend/Telemetry").TelemetryEventName;

  module.exports = function (accountService, make, overlayStack, telemetry, user, overlayOptions, origin) {
    var obj, shareControls;
    overlayOptions.centerContent = false;
    shareControls = make(ShareController, {
      close: overlayStack.closeCurrent,
      externalClass: overlayOptions.externalClass || "",
    });
    overlayStack.add({
      contentUi: shareControls.ui,
      options: overlayOptions,
    });
    overlayStack.usingCustomCloseButton();
    return telemetry.trackOnly(
      TelemetryEventName.ProjectShareViewed,
      ((obj = {}), (obj["" + ProjectShareViewedFields.Origin] = origin), obj),
    );
  };
}.call(this));
