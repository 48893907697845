"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewLimit = void 0;
var react_1 = __importStar(require("react"));
var mobx_react_1 = require("mobx-react");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var IconToggle_1 = require("Common/IconToggle/IconToggle");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var ViewLimit = (function (_super) {
    __extends(ViewLimit, _super);
    function ViewLimit() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.viewLimitInputRef = (0, react_1.createRef)();
        _this.linkViewLimitToggled = function () {
            _this.props.linkStore.setViewLimitEnabled(!_this.isViewLimitEnabled);
            if (_this.isViewLimitEnabled) {
                setTimeout(function () {
                    var inputElem = _this.viewLimitInputRef.current;
                    if (inputElem) {
                        inputElem.focus({ preventScroll: true });
                        inputElem.select();
                    }
                });
            }
        };
        _this.updateNewViewLimit = function (newLimit) {
            _this.props.linkStore.setViewLimitNumber(newLimit);
        };
        return _this;
    }
    Object.defineProperty(ViewLimit.prototype, "isViewLimitEnabled", {
        get: function () {
            return this.props.linkStore.viewLimit.enabled;
        },
        enumerable: false,
        configurable: true
    });
    ViewLimit.prototype.render = function () {
        var _this = this;
        var viewLimit = this.props.linkStore.viewLimit;
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(IconToggle_1.IconToggle, { icon: react_1.default.createElement(icons_1.Preview, null), label: "Restrict number of views", value: this.isViewLimitEnabled, onChange: this.linkViewLimitToggled, disabled: this.props.disableSetting, disabledMessage: this.props.advancedSecurityUpsellMessage }),
            this.isViewLimitEnabled && (react_1.default.createElement(kaleidoscope_1.TextInput, { label: "view-limit-input", id: "view-limit-input", labelHidden: true, value: "".concat(viewLimit.limit), type: kaleidoscope_1.TextInputType.Number, onChange: function (val) { return _this.updateNewViewLimit(parseInt(val)); }, ref: this.viewLimitInputRef, error: !viewLimit.limit ? react_1.default.createElement(react_1.default.Fragment, null, "Please enter a valid view limit number") : undefined }))));
    };
    ViewLimit = __decorate([
        mobx_react_1.observer
    ], ViewLimit);
    return ViewLimit;
}(react_1.default.Component));
exports.ViewLimit = ViewLimit;
