(function () {
  var ConfirmationController, StripeElements;

  StripeElements = require("CreditCardForm/StripeElements")["default"];

  /*
    @NOTE(ravi, 2018-03-07)
    Controller & UI design notes
    For testability - there should be a move towards separation of controller and UI
    The ideal case would be to keep any reference to ui functions or variables
    out of controller functions
    This means that you can fully test the behaviour of the UI with a stubbed out controller
    And it means you can unit test the controller without stubbing out a UI
   */

  module.exports = ConfirmationController = (function () {
    function ConfirmationController(
      createUi,
      di,
      $q,
      account,
      userList,
      planInfo,
      isNewSubscription,
      onComplete,
      goBack,
      overlayStack,
      paymentService,
      subscribeToPlan,
      $log,
      seatsService,
      telemetry,
      QwilrStripe,
      make,
    ) {
      var calSeats,
        changeToStarter,
        coupon,
        couponId,
        fetchCardNum,
        fetchUpgradeCosts,
        getBillingCycleStartDate,
        getInitialUIData,
        init,
        nth,
        planValue,
        prorationDate,
        selectedPlan,
        sendTelemetry,
        startBusinessTrial,
        submitNewSubscription,
        submitUpdatedSubscription,
        toFetchUpgradeCosts,
        toPayToday,
        token,
        turnOnState,
        upgrading;
      prorationDate = null;
      selectedPlan = null;
      couponId = null;
      token = null;
      coupon = null;
      upgrading = null;
      init = (function (_this) {
        return function () {
          var initialLoad, paymentDetails;
          sendTelemetry();
          overlayStack.hideCloseButton();
          if (di.has("paymentDetails")) {
            paymentDetails = di.get("paymentDetails");
            token = paymentDetails.token;
            if (paymentDetails.coupon) {
              coupon = paymentDetails.coupon;
              couponId = coupon.id;
            }
          }
          selectedPlan = planInfo.isMonthly ? planInfo.monthly : planInfo.yearly;
          if (
            account.status === "open" &&
            account.plan.displayName !== "Trial" &&
            account.plan.displayName !== "Starter"
          ) {
            upgrading = true;
          } else {
            upgrading = false;
          }
          initialLoad = [fetchCardNum(token)];
          if (upgrading) {
            initialLoad.push(fetchUpgradeCosts());
          }
          $q.all(initialLoad)
            .then(function () {
              return turnOnState("content");
            })
            ["catch"](function (err) {
              $log.error(err.message);
              _this.ui.customErrorMessage = "Error: could not load initial data";
              return turnOnState("error");
            });
          _this.ui = createUi(require("./Confirmation.html"), getInitialUIData());
          return turnOnState("loading");
        };
      })(this);
      sendTelemetry = (function (_this) {
        return function () {
          var eventName, isUpgrading, telemetryProperties;
          (isUpgrading = planInfo.isUpgrading), (telemetryProperties = planInfo.telemetryProperties);
          eventName = isUpgrading ? "Upgrade Form Summary Viewed" : "Downgrade Form Viewed";
          return telemetry.trackOnly(eventName, telemetryProperties);
        };
      })(this);
      planValue = (function (_this) {
        return function () {
          return selectedPlan.price + (calSeats() - selectedPlan.seats.included) * selectedPlan.seats.price;
        };
      })(this);
      toPayToday = (function (_this) {
        return function () {
          var amountToPay;
          amountToPay = planValue();
          if (couponId != null) {
            return (amountToPay -= (coupon.percent_off / 100) * amountToPay);
          }
        };
      })(this);
      turnOnState = (function (_this) {
        return function (view) {
          var currState, ref, viewName;
          ref = _this.ui.viewState;
          for (viewName in ref) {
            currState = ref[viewName];
            _this.ui.viewState[viewName] = false;
          }
          return (_this.ui.viewState[view] = true);
        };
      })(this);
      calSeats = (function (_this) {
        return function () {
          var newPlanSeats, numUsers;
          newPlanSeats = account.minimumSeats || selectedPlan.seats.min || selectedPlan.seats.included;
          numUsers = seatsService.currentlyOccupied();
          return Math.max(numUsers, newPlanSeats);
        };
      })(this);
      fetchUpgradeCosts = (function (_this) {
        return function () {
          return paymentService.getInvoicePreview(selectedPlan.internalName, calSeats()).then(function (invoices) {
            var costs, i, invoice, len, results;
            _this.ui.originalPlanName = account.plan.displayName;
            prorationDate = invoices[0].subscription_proration_date;
            _this.ui.payToday = 0;
            _this.ui.newPlanCost = 0;
            _this.ui.unusedTimeCredit = 0;
            _this.ui.remainingTimeCost = 0;
            results = [];
            for (i = 0, len = invoices.length; i < len; i++) {
              invoice = invoices[i];
              costs = require("./CalculateUpgradeCost")(invoice);
              _this.ui.payToday += costs.payToday || 0;
              _this.ui.unusedTimeCredit += costs.unusedTimeCredit || 0;
              if (costs.newPlanCost > 0) {
                _this.ui.newPlanCost += costs.newPlanCost;
              }
              if (costs.remainingTimeCost > 0) {
                results.push((_this.ui.remainingTimeCost += costs.remainingTimeCost));
              } else {
                results.push(void 0);
              }
            }
            return results;
          });
        };
      })(this);
      fetchCardNum = (function (_this) {
        return function (ccToken) {
          var detailFinder;
          if (ccToken != null) {
            detailFinder = paymentService.getCardDetailsToken(ccToken);
          } else {
            detailFinder = paymentService.getCardDetails(account._id);
          }
          return detailFinder.then(function (cardDetails) {
            return (_this.ui.cardNumber = "•••• " + cardDetails.last4);
          });
        };
      })(this);

      /*
        Function for submitting a subscription, saving it to the user account.
        @param [string] accountId
        @param [string] cctoken - credit card token
       */
      submitNewSubscription = (function (_this) {
        return function (planId, cctoken) {
          var subDetails;
          subDetails = {
            plan: planId,
            token: cctoken,
            coupon: couponId,
          };
          return paymentService
            .startSubscription(account._id, subDetails)
            .then(function (data) {
              var stripeElements;
              if (data.status === "requires_action" && data.paymentIntentSecret) {
                account.stripe = data.stripe;
                stripeElements = make(StripeElements, {
                  stripeKey: QwilrStripe,
                  stripeUserId: null,
                  showEmailField: false,
                  useUpdatedStyling: false,
                  setErrorMsg: function (message) {
                    return (_this.ui.error = message);
                  },
                });
                return stripeElements.handleCardPayment(data.paymentIntentSecret).then(function (result) {
                  if (result.error) {
                    throw new Error("Authentication failed: " + result.error.message);
                  } else {
                    return paymentService.paymentSuccessfullyVerified(account._id, {
                      paymentIntentId: result.paymentIntent.id,
                    });
                  }
                });
              } else if (data.status === "requires_payment_method" && data.message) {
                account.stripe = data.stripe;
                throw new Error(data.message);
              } else {
                return data;
              }
            })
            .then(function (data) {
              var plan, stripe;
              (stripe = data.stripe), (plan = data.plan);
              account.plan = plan;
              return (account.stripe = stripe);
            })
            ["catch"](function (error) {
              $log.error(error.message);
              return $q.reject(error);
            });
        };
      })(this);

      /*
        Function for submitting a subscription, saving it to the user account.
        @param [string] accountId
        @param [string] cctoken - credit card token
       */
      submitUpdatedSubscription = (function (_this) {
        return function (planId, cctoken) {
          var subDetails;
          subDetails = {
            plan: planId,
            coupon: couponId,
            prorationDate: prorationDate,
          };
          return paymentService
            .updateSubscription(account._id, subDetails)
            .then(function (data) {
              var plan, stripe;
              (stripe = data.stripe), (plan = data.plan);
              account.plan = plan;
              return (account.stripe = stripe);
            })
            ["catch"](function (error) {
              $log.error(error.message);
              return $q.reject(new Error("Could not update subscription"));
            });
        };
      })(this);

      /*
        Function for starting the business trial
        @return [promise] - business trial plan object
       */
      startBusinessTrial = (function (_this) {
        return function () {
          return paymentService
            .startBusinessTrial(account._id)
            .then(function (plan) {
              return (account.plan = plan);
            })
            ["catch"](function (error) {
              $log.error(error.message);
              return $q.reject(new Error("Could not change to business trial"));
            });
        };
      })(this);
      changeToStarter = (function (_this) {
        return function () {
          return paymentService
            .changeToStarter(account._id)
            .then(function (plan) {
              return (account.plan = plan);
            })
            ["catch"](function (error) {
              $log.error(error.message);
              return $q.reject(new Error("Could not change to starter"));
            });
        };
      })(this);
      toFetchUpgradeCosts = (function (_this) {
        return function () {
          if (upgrading || selectedPlan.displayName !== "Trial") {
            return false;
          } else {
            return true;
          }
        };
      })(this);
      nth = (function (_this) {
        return function (d) {
          if (d > 3 && d < 21) {
            return "th";
          }
          switch (d % 10) {
            case 1:
              return "st";
            case 2:
              return "nd";
            case 3:
              return "rd";
            default:
              return "th";
          }
        };
      })(this);
      getBillingCycleStartDate = (function (_this) {
        return function () {
          var month, today, todayDate;
          today = new Date();
          todayDate = today.getDate();
          month = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ][today.getMonth()];
          if (planInfo.isMonthly) {
            return todayDate + nth(todayDate);
          } else {
            return todayDate + " " + month;
          }
        };
      })(this);
      getInitialUIData = (function (_this) {
        return function () {
          return {
            viewState: {
              content: false,
              loading: false,
              error: false,
              success: false,
            },
            plan: selectedPlan,
            token: token,
            payToday: toPayToday(),
            payFrequency: planInfo.isMonthly ? "monthly" : "yearly",
            couponDiscount: couponId != null ? coupon.percent_off : null,
            planValue: planValue(),
            planName: selectedPlan.displayName,
            upgrading: upgrading,
            unusedTimeCredit: null,
            remainingTimeCost: null,
            newPlanCost: null,
            originalPlanName: null,
            startDate: getBillingCycleStartDate(),
            hasCoupon: function () {
              return (couponId != null ? couponId.length : void 0) > 0;
            },
            getPlan: function () {
              return _this.ui.plan.displayName;
            },
            seats: function () {
              return calSeats();
            },
            isMonthly: function () {
              return _this.ui.plan.unit === "month";
            },
            cardNumber: "",
            ccNumber: function () {
              return _this.ui.cardNumber;
            },
            tryAgain: function () {
              /*
                @TODO (som, 2018-04-19): handle the case where a customer token is created on the
                server after a failed subscription. We would need to either:
                1. get the server to send something in `additionalData` to indicate this happened, OR
                2. get the latest account data from the server and update `account.stripe.customerToken`
                This is only known to happen on fraudulent credit cards, which is why it's not a high
                priority edge case to deal with
               */
              var ref;
              overlayStack.closeCurrent();
              if (((ref = account.stripe) != null ? ref.customerToken : void 0) == null) {
                return subscribeToPlan.subscribeWithPlanInfo(planInfo);
              } else {
                /*
                  If we are here, that means an error happened when upgrading/downgrading an existing
                  subscription. There is no known way of reaching here - updating the subcription does
                  not trigger any credit card errors. Because of this, we just log this as an error and
                  close the overlay as a 'good enough' solution
                 */
                return $log.error("Customer without customerToken wants to try the subscription flow again");
              }
            },
            confirm: function () {
              var subSender;
              di.value("selectedPlan", _this.ui.plan);
              turnOnState("loading");
              if (selectedPlan.displayName === "Trial") {
                subSender = startBusinessTrial();
              } else if (selectedPlan.displayName === "Starter") {
                subSender = changeToStarter();
              } else if (isNewSubscription) {
                subSender = submitNewSubscription(_this.ui.plan._id, _this.ui.token);
              } else {
                subSender = submitUpdatedSubscription(_this.ui.plan._id, _this.ui.token);
              }
              return subSender
                .then(function () {
                  /*
                @HACK(ravi, 2018-03-07) - currently there is not 'success' screen so the html file
                just shows the loading div (otherwise there will be a period of blank screen)
                 */
                  turnOnState("success");
                  return onComplete();
                })
                ["catch"](function (err) {
                  _this.ui.customErrorMessage = err.message;
                  return turnOnState("error");
                });
            },
            onClickBack: function () {
              return goBack();
            },
            close: function () {
              return overlayStack.closeCurrent();
            },
          };
        };
      })(this);
      init();
    }

    return ConfirmationController;
  })();
}.call(this));
