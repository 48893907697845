(function () {
  var ChangeEmailController;

  module.exports = ChangeEmailController = (function () {
    function ChangeEmailController(
      $http,
      $timeout,
      user,
      createUi,
      apiBaseUrl,
      make,
      AsyncButton,
      telemetry,
      $q,
      utils,
      readOnly,
    ) {
      var onClick, submitButton;
      onClick = (function (_this) {
        return function () {
          var newEmail;
          newEmail = _this.ui.email;
          if (!utils.isValidEmail(newEmail)) {
            return $q.reject(new Error("Mhm that email doesn't look right. Please ensure you use a valid email."));
          }
          newEmail = newEmail.toLowerCase();
          return $http
            .post(apiBaseUrl + "/users/update", {
              email: newEmail,
            })
            .then(function (response) {
              return (user.email = newEmail);
            });
        };
      })(this);
      submitButton = new AsyncButton(
        onClick,
        "Update Email",
        null,
        function (response) {
          var ref, ref1;
          if (
            ((ref = response.data) != null ? ((ref1 = ref.error) != null ? ref1.message : void 0) : void 0) != null ||
            response.message
          ) {
            return "#change-email-form input";
          }
        },
        function (response) {
          var ref, ref1;
          return (
            ((ref = response.data) != null ? ((ref1 = ref.error) != null ? ref1.message : void 0) : void 0) ||
            response.message ||
            "An error has occurred while trying to update your email, please try again later."
          );
        },
      );
      this.ui = createUi(require("./ChangeEmail.html"), {
        submitButton: submitButton.ui,
        email: user.email,
        readOnly: readOnly,
        isFieldDisabled: function () {
          return submitButton.state === "waiting";
        },
        unlockButton: (function (_this) {
          return function () {
            var ref;
            if (user.email !== _this.ui.email && ((ref = submitButton.state) === "disabled" || ref === "success")) {
              return (submitButton.state = "idle");
            }
          };
        })(this),
      });
    }

    return ChangeEmailController;
  })();
}.call(this));
