// Generated by CoffeeScript 1.12.7
(function () {
  let Dropdown, FormEditor, Text, ref;

  (ref = require("Common/Utils/ImageImports")), (Dropdown = ref.Dropdown), (Text = ref.Text);

  module.exports = FormEditor = (function () {
    function FormEditor(createUi, make, form, utils, account, mapControllers, onDelete, closeOverlay, showDecideModal) {
      let DropdownField, Field, TextField, fieldTypes, makeField;
      Field = require("./Field/Field");
      TextField = require("./Field/Text/Text");
      DropdownField = require("./Field/Dropdown/Dropdown");
      fieldTypes = {
        text: {
          name: "Text",
          controller: TextField,
          icon: Text,
          model: {
            name: "",
            type: "text",
            isRequired: false,
          },
        },
        dropdown: {
          name: "Dropdown",
          controller: DropdownField,
          icon: Dropdown,
          model: {
            items: [""],
            name: "",
            type: "dropdown",
            isRequired: false,
          },
        },
      };
      makeField = (function (_this) {
        return function (model) {
          let fieldType, fieldUi;
          if (form == null) {
            return;
          }
          fieldType = fieldTypes[model.type];
          if (fieldType == null) {
            throw new Error(`Unknown form-field type: ${model.type}`);
          }
          fieldUi = make(fieldType.controller, {
            model: model,
          });
          return make(Field, {
            model: model,
            fields: form.fields,
            typeIcon: fieldType.icon,
            fieldUi: fieldUi.ui,
          });
        };
      })(this);
      this.ui = createUi(require("Common/FormBuilder/FormEditor/FormEditor.html"), {
        form: form,
        fieldTypes: fieldTypes,
        newField: (function (_this) {
          return function (model) {
            model = _.cloneDeep(model);
            model.id = utils.uniqueId(12);
            return form.fields.push(model);
          };
        })(this),
        deleteForm: (function (_this) {
          return function (form) {
            return showDecideModal({
              title: "Delete Form?",
              explainer: "This action will also impact any automation that is associated with this custom form.",
            }).then(function (confirmed) {
              if (confirmed) {
                _.remove(account.forms, {
                  id: form.id,
                });
                return onDelete();
              }
            });
          };
        })(this),
        closeOverlay: closeOverlay,
        $init: (function (_this) {
          return function (scope) {
            scope.fields = form.fields;
            return (scope.ui.fieldCtrls = mapControllers(scope, makeField, scope.fields));
          };
        })(this),
      });
    }

    return FormEditor;
  })();
}.call(this));
