"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasValidAutomationSelections = void 0;
var SyncAccepterResponses_1 = require("./SyncAccepterResponses");
var ActionFormMultiselect_1 = require("Library/AutomationLibrary/ActionFormMultiselect");
var hasValidAutomationSelections = function (automation, _a) {
    var actions = _a.actions;
    if (automation.actions.length < 1) {
        return false;
    }
    return !!automation.name && !!automation.triggerId && hasValidActions(automation, actions);
};
exports.hasValidAutomationSelections = hasValidAutomationSelections;
var hasValidActions = function (automation, actions) {
    return automation.actions.every(function (action) {
        var foundActionForm = actions.find(function (actionForm) {
            return actionForm.id === action.actionId;
        });
        return (!!foundActionForm &&
            (!foundActionForm.form ||
                foundActionForm.form.every(function (formField) { return hasValidActionConfig(formField, action.config); })));
    });
};
var hasValidActionConfig = function (formField, config) {
    if (formField.type === "syncAccepterResponses") {
        return (0, SyncAccepterResponses_1.isValidAccepterResponses)(config);
    }
    else if (formField.type === "dropdown") {
        if (!formField.hasSubField) {
            return Object.keys(config).includes(formField.name) && !!config[formField.name];
        }
        else {
            return (Object.keys(config).includes(formField.name) &&
                formField.options.every(function (option) { return Object.keys(config).includes(option.subField.name); }));
        }
    }
    else if (formField.type === "multiselect") {
        if (!Object.keys(config).includes(formField.name) || !config[formField.name]) {
            return false;
        }
        var values = config[formField.name];
        if (!(0, ActionFormMultiselect_1.isValidActionConfigValue)(values)) {
            return false;
        }
        return values.length > 0;
    }
    return false;
};
