(function () {
  var TotalDiscount;

  module.exports = TotalDiscount = (function () {
    function TotalDiscount(createUi, make, block, QuoteModel, telemetry, telemetryDebounceInterval) {
      var debouncedTrackChange;
      if (block.discount == null) {
        block.discount = QuoteModel.discount;
      }
      debouncedTrackChange = _.debounce(function () {
        return telemetry.trackOnly("Quote Block Total Discounted", {
          quote_block_total_discount_type: block.discount.type,
        });
      }, telemetryDebounceInterval);
      this.ui = createUi(require("Project/Modules/Quote/Discount/TotalDiscount.html"), {
        discount: block.discount,
        currencySymbol: function () {
          return block.settings.currency.symbol;
        },
        isPercent: function () {
          return block.discount.type === "percent";
        },
        toggleType: function () {
          if (block.discount.type === "percent") {
            block.discount.type = "fixed";
          } else {
            block.discount.type = "percent";
          }
          return debouncedTrackChange();
        },
        discountChanged: debouncedTrackChange,
      });
    }

    return TotalDiscount;
  })();
}.call(this));
