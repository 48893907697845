"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MentionOrigin = void 0;
var MentionOrigin;
(function (MentionOrigin) {
    MentionOrigin["Icon"] = "icon";
    MentionOrigin["Keyboard"] = "keyboard";
    MentionOrigin["Both"] = "both";
    MentionOrigin["None"] = "none";
})(MentionOrigin || (exports.MentionOrigin = MentionOrigin = {}));
