(function () {
  var ChangePhoneNumberController;

  module.exports = ChangePhoneNumberController = (function () {
    function ChangePhoneNumberController(account, accountService, createUi, make, AsyncButton, telemetry, $q, utils) {
      var button, changePhoneNumber;
      changePhoneNumber = (function (_this) {
        return function () {
          var number;
          _this.ui.error = false;
          number = _this.ui.number;
          if (!utils.isValidPhoneNumber(number)) {
            return $q.reject(
              new Error("That phone number doesn't look right. Please ensure you use a valid phone number."),
            );
          }
          if (number === account.phoneNumber) {
            return $q.resolve();
          }
          return accountService
            .setPhoneNumber(number)
            .then(function () {
              if (account.phoneNumber != null) {
                // we do want to use a track (including identify call) here.
                // TODO(alton,2024-03-27) Move this telemetry to Server side, after DB has been updated.
                telemetry.track("Phone Number Changed", {
                  phone: number,
                  phone_old: account.phoneNumber,
                });
                telemetry.flushHubspot();
              } else {
                telemetry.track("Phone Number Added", {
                  phone: number,
                });
              }
              return (account.phoneNumber = number);
            })
            ["catch"](function () {
              _this.ui.number = account.phoneNumber;
              return $q.reject(new Error("Error updating phone number"));
            });
        };
      })(this);
      button = new AsyncButton(
        changePhoneNumber,
        "Update Phone Number",
        null,
        function (response) {
          var ref, ref1;
          if (((ref = response.data) != null ? ((ref1 = ref.error) != null ? ref1.message : void 0) : void 0) != null) {
            return "#change-number-form input";
          }
        },
        function (response) {
          var ref, ref1;
          return (
            ((ref = response.data) != null ? ((ref1 = ref.error) != null ? ref1.message : void 0) : void 0) ||
            response.message ||
            "An error has occurred while trying to update your number, please try again later."
          );
        },
      );
      this.ui = createUi(require("./ChangePhoneNumber.html"), {
        error: false,
        number: account.phoneNumber,
        button: button.ui,
        isFieldDisabled: function () {
          return button.state === "waiting";
        },
        unlockButton: (function (_this) {
          return function () {
            var ref;
            if (account.phoneNumber !== _this.ui.number && ((ref = button.state) === "disabled" || ref === "success")) {
              return (button.state = "idle");
            }
          };
        })(this),
      });
    }

    return ChangePhoneNumberController;
  })();
}.call(this));
