// Generated by CoffeeScript 1.12.7
(function () {
  var GlobalDisplays, ToggleWithLabel;

  ToggleWithLabel = require("../../SettingsMenus/ToggleWithLabel");

  module.exports = GlobalDisplays = (function () {
    function GlobalDisplays(createUi, make, quoteAdapter) {
      var quoteDecimalsToggle;
      quoteDecimalsToggle = make(ToggleWithLabel, {
        description: "Display decimals",
        getter: quoteAdapter.showDecimalPlaces,
        setter: quoteAdapter.setShowDecimalPlaces,
      });
      this.ui = createUi(require("./Formatting.html"), {
        quoteDecimalsToggle: quoteDecimalsToggle.ui,
      });
    }

    return GlobalDisplays;
  })();
}.call(this));
