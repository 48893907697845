// Generated by CoffeeScript 1.12.7

/*
  A simple piece of UI that shows an "Undo" message.
  Similiar to the Google Inbox approach this is a "temporally unstable" undo.
  i.e. It only shows the option once.
  This is a pragmatic shortcut, to provide the user with an undo option;
  Without having to build a big sophisticated app-universal undo stack.
 */

(function () {
  var UndoController,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = UndoController = (function () {
    function UndoController(createUi, $timeout, domFrame) {
      this.$timeout = $timeout;
      this.show = bind(this.show, this);
      this.ui = createUi(require("./Undo.html"), {
        visible: false,
        undo: (function (_this) {
          return function () {
            _this.onUndo();
            return _this.ui.close();
          };
        })(this),
        close: (function (_this) {
          return function () {
            return (_this.ui.visible = false);
          };
        })(this),
      });
      domFrame.add(this.ui);
    }

    /*
      Show the Undo notifier.
      @param message [String] the message for the undo note i.e. "Undo delete block"
      @param onUndo [Function] callback to execute when undo is clicked
      @param persistent [Boolean] (optional) - whether this notification should hide itself, or persist on the UI until clicked.
     */

    UndoController.prototype.show = function (message, onUndo, persistent) {
      if (persistent == null) {
        persistent = false;
      }
      if (onUndo == null || !(message != null ? message.length : void 0)) {
        throw new Error("Trying to create an Undo notification, with malformed inputs.");
      }
      if (!_.isFunction(onUndo)) {
        throw new Error("Got a non-function for the onUndo event handler.");
      }
      this.ui.message = message;
      this.onUndo = onUndo;
      this.ui.visible = true;
      if (!persistent) {
        return this.$timeout(this.ui.close, 7000);
      }
    };

    return UndoController;
  })();
}.call(this));
