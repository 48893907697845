// Generated by CoffeeScript 1.12.7
(function () {
  var DraftLiveToggle;

  module.exports = DraftLiveToggle = (function () {
    function DraftLiveToggle(
      $q,
      createUi,
      projectStatusChecker,
      projectStatusSetter,
      projectLiveLimit,
      project,
      user,
      permissionsService,
      makeUpsellTooltip,
      make,
      Tooltip,
      utils,
      options,
      projectService,
    ) {
      var activeTooltipObj,
        canToggleStatus,
        getDisabledReason,
        getUpsellTooltip,
        targetSelector,
        toggleId,
        tooltipOffset;
      toggleId = utils.randomString();
      targetSelector = ".draft-live-toggle.toggle-" + toggleId;
      tooltipOffset = options.tooltipOffset;
      project.$isLive = project.status === "live";
      canToggleStatus = function () {
        return projectStatusChecker.canToggleLive(project);
      };
      this.canToggleStatus = canToggleStatus;
      activeTooltipObj = {};
      getUpsellTooltip = function () {
        var reason, ref, upsell;
        (ref = getDisabledReason()), (reason = ref.reason), (upsell = ref.upsell);
        if (activeTooltipObj.reason === reason) {
          return;
        } else if (activeTooltipObj.reason != null) {
          activeTooltipObj.tooltip.remove();
        }
        return (activeTooltipObj = {
          reason: reason,
          tooltip: upsell
            ? makeUpsellTooltip({
                message: reason,
                targetSelector: targetSelector,
                upgradePosition: "end",
                options: {
                  spacing: tooltipOffset,
                  hideAfter: 100,
                  showOnHover: true,
                },
              })
            : make(Tooltip, {
                targetSelector: targetSelector,
                options: {
                  customTheme: "top-helper-theme",
                  position: "bottom",
                  spacing: tooltipOffset,
                  hideAfter: 100,
                  showOnHover: true,
                  stringContent: reason,
                },
              }),
        });
      };
      getDisabledReason = function () {
        if (!permissionsService.isAllowed(project.$userAction, "edit")) {
          return {
            reason: "Only team members with edit access can change live status",
            upsell: false,
          };
        } else if (projectService.shouldDisableAcceptAndQuoteAccess(project)) {
          return {
            reason: "Pages with a Quote or Accept block can only be set live on our paid plans.",
            upsell: true,
          };
        } else if (projectLiveLimit.hasHitLimit()) {
          return {
            reason: "You’ve reached your live project limit.",
            upsell: true,
          };
        } else if (!projectStatusChecker.canToggleLive(project)) {
          if (project.status === "accepted") {
            return {
              reason:
                "Accepted pages cannot be edited, or switched to live or draft. Contact us if you’d like to disable this page.",
            };
          } else {
            return {
              reason:
                "Disabled Qwilr pages cannot be switched to live or draft. Head back to your dashboard to enable this page.",
            };
          }
        } else {
        }
      };
      if (!canToggleStatus()) {
        getUpsellTooltip();
      }
      this.ui = createUi(require("./DraftLiveToggle.html"), {
        project: project,
        toggleId: toggleId,
        canToggleStatus: canToggleStatus,
        showDisabledMessage: (function (_this) {
          return function () {
            if (canToggleStatus()) {
              return;
            }
            getUpsellTooltip();
          };
        })(this),
        toggleLive: (function (_this) {
          return function () {
            if (!canToggleStatus()) {
              return _this.ui.showDisabledMessage();
            }
            if (project.status === "") {
              projectStatusSetter.makeLive(project);
              project.$isLive = true;
              if (options != null ? options.toggleHandle : void 0) {
                options.toggleHandle("live");
              }
            } else if (project.status === "live") {
              projectStatusSetter.makeDraft(project);
              project.$isLive = false;
              if (options != null ? options.toggleHandle : void 0) {
                options.toggleHandle("draft");
              }
            }
            if (!canToggleStatus()) {
              getUpsellTooltip();
            }
          };
        })(this),
      });
    }

    return DraftLiveToggle;
  })();
}.call(this));
