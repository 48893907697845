// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = angular.module("qwilr.onFileChange", []).directive("onFileChange", function () {
    return {
      link: function (scope, element, attrs) {
        var onChangeHandler;
        onChangeHandler = scope.$eval(attrs.onFileChange);
        return element.on("change", onChangeHandler);
      },
    };
  });
}.call(this));
