"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.END_OF_UPLOAD_PERCENT = void 0;
exports.default = (function (i) {
    return Math.floor(getHyperbolaFunction(i));
});
exports.END_OF_UPLOAD_PERCENT = 70;
var FIRST_INC_AFTER_UPLOAD = 2;
var c = (exports.END_OF_UPLOAD_PERCENT + FIRST_INC_AFTER_UPLOAD - 100) / FIRST_INC_AFTER_UPLOAD;
var b = ((exports.END_OF_UPLOAD_PERCENT + FIRST_INC_AFTER_UPLOAD - 100) * exports.END_OF_UPLOAD_PERCENT) / FIRST_INC_AFTER_UPLOAD;
var getHyperbolaFunction = function (i) {
    return (100 * i - b) / (i - c);
};
