// Generated by CoffeeScript 1.12.7

/*
  Creates an "action" button - which changes its appearance
  to reflect the status of an action. i.e. For an async action
  that makes an HTTP request.

  @param onClick [Function] - event handler for clicking on the action button.
  This is expected to return a Promise.
  NOTE: If you have a .catch() handler, you must remember to return a rejected Promise.
  Otherwise, the .catch() will swallow the error, and it won't propagate up to the ActionButton

  i.e:
    $http
      .post "#{apiBaseUrl}/some/endpoint",
        data: "awesome data"
      .then (data) =>
        // ... do some actions
      .catch (response) ->
        onError response.data.message
        return $q.reject()


  @param config [Object] - an object describing each state of the button.
  - `standardIcon` [String] (optional) icon for this button.
  - `standard` [String] text for button, before an action starts
  - `waiting` [String] text for button doing some async action / awaiting response
  - `success` [String] text for button after a successfull action
  - `error` [String] text for button if the action had an error

  Usage example:

  actionButton = make ActionButton, {
    onClick: -> (... Promise returning function)
    config:
      icon: "icon-download"
      standard: "Save Block"
      waiting: "Saving Block..."
      success:"Block Saved"
      error: "Error Saving Block"
  }
 */

(function () {
  var ActionButton;

  module.exports = ActionButton = (function () {
    function ActionButton(createUi, make, config, onClick, $timeout) {
      var RETURN_TO_STANDARD_TIMEOUT, icons;
      icons = {
        standard: config.standardIcon,
        waiting: "icon-repeat",
        success: "icon-done",
        error: "icon-warning",
      };
      RETURN_TO_STANDARD_TIMEOUT = 3500;
      this.ui = createUi(require("./ActionButton.html"), {
        currentState: "standard",
        getIcon: (function (_this) {
          return function () {
            return icons[_this.ui.currentState];
          };
        })(this),
        getText: (function (_this) {
          return function () {
            return config[_this.ui.currentState];
          };
        })(this),
        onClick: (function (_this) {
          return function () {
            var ref;
            if (!((ref = _this.ui.currentState) === "waiting" || ref === "disabled")) {
              _this.ui.currentState = "waiting";
              return onClick()
                .then(function () {
                  return (_this.ui.currentState = "success");
                })
                ["catch"](function () {
                  return (_this.ui.currentState = "error");
                })
                ["finally"](function () {
                  return $timeout(function () {
                    return (_this.ui.currentState = "standard");
                  }, RETURN_TO_STANDARD_TIMEOUT);
                });
            }
          };
        })(this),
      });

      /*
        Set the state of the button
        @param state [String] - can be any of the Action Button states.
        @api public
       */
      this.setState = (function (_this) {
        return function (state) {
          return (_this.ui.currentState = state);
        };
      })(this);
    }

    return ActionButton;
  })();
}.call(this));
