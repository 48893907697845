"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.loadComments = void 0;
var CommentThreadURL_1 = require("@CommonFrontendBackend/Comments/Linking/CommentThreadURL");
var loadComments = function (commentTokens, commentThreadStore) {
    var loaded = {};
    commentTokens.forEach(function (comment) {
        if (!loaded[comment.id]) {
            var isShowing = isCommentShowing(comment.id);
            commentThreadStore.load(comment.id, isShowing, getShowingSource());
            loaded[comment.id] = true;
        }
    });
};
exports.loadComments = loadComments;
var isCommentShowing = function (id) {
    return !!window.location && (0, CommentThreadURL_1.isCommentThreadInURL)(window.location.href, id);
};
var getShowingSource = function () {
    return (0, CommentThreadURL_1.getCommentThreadSource)(window.location.href) || undefined;
};
