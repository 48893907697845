// Generated by CoffeeScript 1.12.7
(function () {
  var FileUploadController, MetricSource;

  MetricSource = require("@CommonFrontendBackend/AssetLibrary/Types").MetricSource;

  module.exports = FileUploadController = (function () {
    function FileUploadController(createUi, make, file, onUploaded, showAcknowledgeModal, apiBaseUrl, uploadStore) {
      var label;
      label = (file != null ? file.length : void 0) ? "Replace" : "Upload";
      this.ui = createUi(require("./FileUploader.html"), {
        file: file,
        viewState: {
          uploading: false,
          done: false,
        },
        label: label,
        labelText: (function (_this) {
          return function () {
            var ref;
            if (_this.ui.viewState.uploading === true) {
              return "Uploading...";
            }
            if ((ref = _this.ui.file) != null ? ref.length : void 0) {
              return "Replace";
            } else {
              return "Upload";
            }
          };
        })(this),
        failed: (function (_this) {
          return function () {
            _this.ui.viewState.uploading = false;
            return showAcknowledgeModal({
              title: "Whoops, something broke.",
              explainer: createUi(require("./UnknownFailFileUploaderModal.html")),
            });
          };
        })(this),
        unknownFileType: (function (_this) {
          return function () {
            return showAcknowledgeModal({
              title: "Unrecognised file type",
              explainer: "We couldn't recognise that type of file.",
            });
          };
        })(this),
        upload: (function (_this) {
          return function (file) {
            var uploadId;
            if (file == null) {
              return;
            }
            _this.ui.viewState.uploading = true;
            uploadId = uploadStore.upload(file);
            return uploadStore
              .waitForUpload(uploadId)
              .then(function (arg) {
                var url;
                url = arg.url;
                _this.ui.viewState.uploading = false;
                _this.ui.viewState.done = true;
                if (onUploaded != null) {
                  return onUploaded(url);
                }
              })
              ["catch"](function (response) {
                _this.ui.viewState.uploading = false;
                if (response.data.error.message === "Could not determine the file extension") {
                  return _this.ui.unknownFileType();
                } else {
                  return _this.ui.failed();
                }
              });
          };
        })(this),
      });
    }

    return FileUploadController;
  })();
}.call(this));
