"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var UseHistoryTypes_1 = require("DataStores/User/UseHistory/UseHistoryTypes");
var mobx_1 = require("mobx");
var ROIStepOrder = Object.freeze([
    UseHistoryTypes_1.ROIStepId.StartTour,
    UseHistoryTypes_1.ROIStepId.Formula,
    UseHistoryTypes_1.ROIStepId.FormulaEditor,
    UseHistoryTypes_1.ROIStepId.ShowVariables,
    UseHistoryTypes_1.ROIStepId.AddEditVariables,
    UseHistoryTypes_1.ROIStepId.EditSlider,
]);
var ROIStepsOrchestrator = (function () {
    function ROIStepsOrchestrator(rootDataStore) {
        var _this = this;
        this.debouncingBetweenSteps = false;
        this.stepCompleted = function (step) {
            return (0, mobx_1.computed)(function () { return _this.useHistoryDataStore.roiCalculatorCompletedSteps.includes(step); }).get();
        };
        this.useHistoryDataStore = rootDataStore.userDataStore.useHistoryDataStore;
    }
    ROIStepsOrchestrator.prototype.shouldShowStep = function (id) {
        var _this = this;
        return (0, mobx_1.computed)(function () {
            var e_1, _a;
            if (_this.debouncingBetweenSteps) {
                return false;
            }
            try {
                for (var ROIStepOrder_1 = __values(ROIStepOrder), ROIStepOrder_1_1 = ROIStepOrder_1.next(); !ROIStepOrder_1_1.done; ROIStepOrder_1_1 = ROIStepOrder_1.next()) {
                    var orderId = ROIStepOrder_1_1.value;
                    if (id === orderId) {
                        return !_this.stepCompleted(id);
                    }
                    else if (!_this.stepCompleted(orderId)) {
                        return false;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (ROIStepOrder_1_1 && !ROIStepOrder_1_1.done && (_a = ROIStepOrder_1.return)) _a.call(ROIStepOrder_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return false;
        }).get();
    };
    ROIStepsOrchestrator.prototype.completeStep = function (id) {
        var _this = this;
        if (this.stepCompleted(id)) {
            return;
        }
        if (!this.shouldShowStep(id)) {
            return;
        }
        clearTimeout(this.debounceTimeout);
        this.debouncingBetweenSteps = true;
        this.debounceTimeout = window.setTimeout((0, mobx_1.action)(function () {
            _this.debouncingBetweenSteps = false;
        }), 2000);
        this.useHistoryDataStore.completeRoiCalculatorStep(id);
    };
    ROIStepsOrchestrator.prototype.completeAllSteps = function () {
        clearTimeout(this.debounceTimeout);
        this.useHistoryDataStore.completeRoiCalculatorSteps(ROIStepOrder);
    };
    var _a;
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], ROIStepsOrchestrator.prototype, "debouncingBetweenSteps", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_a = typeof UseHistoryTypes_1.ROIStepId !== "undefined" && UseHistoryTypes_1.ROIStepId) === "function" ? _a : Object]),
        __metadata("design:returntype", void 0)
    ], ROIStepsOrchestrator.prototype, "completeStep", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], ROIStepsOrchestrator.prototype, "completeAllSteps", null);
    return ROIStepsOrchestrator;
}());
exports.default = ROIStepsOrchestrator;
