// Generated by CoffeeScript 1.12.7

/*
  This module is a thin wrapper around Tether.

  This encapsulates some repeated behaviour we want to implement, such as:
  - Registration of the tether object with our tether tracker.
  - Stitching together with Angular world (i.e positioning after $timeout)
  - Graceful DOM cleanup after removing a tethered DOM object

  Usage:
  ```
  tetherHandle = tether
    element: popoutElement
    target: anchor
    attachment: tethering.attachment or "top left"
    targetAttachment: tethering.targetAttachment or "bottom left"
    offset: tethering.offset or "5px 0"
  ```
 */

(function () {
  var Tether;

  Tether = require("tether");

  module.exports = function (tetherTracker, $timeout, $log, utils) {
    return function (tetherConstructorArgs) {
      var error, tetherObject;
      try {
        tetherObject = new Tether(tetherConstructorArgs);
      } catch (error1) {
        error = error1;
        $log.error("Error creating Tether object", error);
        return false;
      }
      tetherObject.$id = utils.uniqueId();
      tetherTracker.register(tetherObject);
      $timeout(tetherObject.position);
      return tetherObject;
    };
  };
}.call(this));
