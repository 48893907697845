"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IpFilterStore = void 0;
var lodash_1 = require("lodash");
var mobx_1 = require("mobx");
var IpFilterStore = (function () {
    function IpFilterStore(projectId, link, projectApiService) {
        var _this = this;
        this.link = link;
        this.debouncedSave = (0, lodash_1.debounce)(function () { return projectApiService.saveIpFilter(projectId, link.secret, _this.ipFilter); }, 1000, {
            leading: true,
        });
    }
    Object.defineProperty(IpFilterStore.prototype, "enabled", {
        get: function () {
            return this.ipFilter.enabled;
        },
        enumerable: false,
        configurable: true
    });
    IpFilterStore.prototype.setEnabled = function (enabled) {
        this.ipFilter.enabled = enabled;
        this.debouncedSave();
    };
    IpFilterStore.prototype.selectAddresses = function (filters) {
        this.ipFilter.filters = filters;
        this.debouncedSave();
    };
    IpFilterStore.prototype.unselectAll = function () {
        this.selectAddresses([]);
    };
    IpFilterStore.prototype.isAddressSelected = function (id) {
        return this.addressesSet.has(id);
    };
    IpFilterStore.prototype.toggleAddress = function (id) {
        if (this.isAddressSelected(id)) {
            this.selectAddresses(this.ipFilter.filters.filter(function (selectedId) { return selectedId !== id; }));
        }
        else {
            this.selectAddresses(__spreadArray(__spreadArray([], __read(this.ipFilter.filters), false), [id], false));
        }
    };
    Object.defineProperty(IpFilterStore.prototype, "ipFilter", {
        get: function () {
            if (this.link.ipFilter == undefined) {
                this.link.ipFilter = { enabled: false, filters: [] };
            }
            return this.link.ipFilter;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(IpFilterStore.prototype, "addressesSet", {
        get: function () {
            return new Set(this.ipFilter.filters);
        },
        enumerable: false,
        configurable: true
    });
    var _a;
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Boolean]),
        __metadata("design:returntype", void 0)
    ], IpFilterStore.prototype, "setEnabled", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Array]),
        __metadata("design:returntype", void 0)
    ], IpFilterStore.prototype, "selectAddresses", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_a = typeof Set !== "undefined" && Set) === "function" ? _a : Object),
        __metadata("design:paramtypes", [])
    ], IpFilterStore.prototype, "addressesSet", null);
    return IpFilterStore;
}());
exports.IpFilterStore = IpFilterStore;
