// Generated by CoffeeScript 1.12.7
(function () {
  var InvoiceSettingsMenu,
    ItemSelect,
    SettingsTemplate,
    defaultIntegrationSettings,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  SettingsTemplate = require("./InvoiceSettingsMenu.html");

  ItemSelect = require("./ItemSelect");

  defaultIntegrationSettings = {
    selectedCustomer: null,
    selectedAccount: null,
    sendInvoiceOnAccept: false,
  };

  module.exports = InvoiceSettingsMenu = (function () {
    function InvoiceSettingsMenu(
      $timeout,
      $q,
      $log,
      createUi,
      make,
      invoicerService,
      project,
      block,
      integration,
      ActionButton,
      account,
    ) {
      this.invoiceHandler = bind(this.invoiceHandler, this);
      this.generateEstimate = bind(this.generateEstimate, this);
      var base, defaultSettings, generateEstimateButton, ref, ref1;
      if (block.settings == null) {
        block.settings = {};
      }
      if ((base = block.settings).integrationSettings == null) {
        base.integrationSettings = {};
      }
      if (block.settings.integrationSettings[integration] == null) {
        defaultSettings = _.cloneDeep(defaultIntegrationSettings);
        defaultSettings.sendInvoiceOnAccept =
          ((ref = account.integrations[integration]) != null
            ? (ref1 = ref.settings) != null
              ? ref1.defaultAutoPush
              : void 0
            : void 0) || false;
        block.settings.integrationSettings[integration] = defaultSettings;
      }
      this.invoicerService = invoicerService;
      this.integration = integration;
      this.timeout = $timeout;
      this.q = $q;
      this.log = $log;
      generateEstimateButton = make(ActionButton, {
        config: {
          standardIcon: "icon-document2",
          standard: "Generate estimate",
          waiting: "Generating estimate...",
          success: "Estimate generated",
          error: "Error generating estimate",
        },
        onClick: (function (_this) {
          return function () {
            var invoice;
            invoice = _this.generateEstimate(
              block._id,
              block.settings.integrationSettings[integration],
              project.links[0].secret,
            );
            return _this.invoiceHandler(invoice);
          };
        })(this),
      });
      this.ui = createUi(SettingsTemplate, {
        integrationName: integration,
        integrationSettings: block.settings.integrationSettings[integration],
        hasAccounts: function () {
          return integration === "xero";
        },
        generateEstimateButton: generateEstimateButton.ui,
        customerSelect: make(ItemSelect, {
          title: "Customers",
          setSelected: function (item) {
            return (block.settings.integrationSettings[integration].selectedCustomer = item);
          },
          getSelected: function () {
            return block.settings.integrationSettings[integration].selectedCustomer;
          },
          getItems: function () {
            return invoicerService.getCustomers(integration);
          },
        }),
        accountSelect: make(ItemSelect, {
          title: "Accounts",
          setSelected: function (item) {
            return (block.settings.integrationSettings[integration].selectedAccount = item);
          },
          getSelected: function () {
            var ref2, ref3;
            return (
              block.settings.integrationSettings[integration].selectedAccount ||
              ((ref2 = account.integrations[integration]) != null
                ? (ref3 = ref2.settings) != null
                  ? ref3.defaultAccount
                  : void 0
                : void 0)
            );
          },
          getItems: function () {
            return invoicerService.getAccounts(integration);
          },
        }),
      });
    }

    InvoiceSettingsMenu.prototype.generateEstimate = function (blockId, integrationSettings, projectLink) {
      return this.invoicerService.generateEstimate(this.integration, blockId, integrationSettings, projectLink);
    };

    InvoiceSettingsMenu.prototype.invoiceHandler = function (promise) {
      return promise["catch"](
        (function (_this) {
          return function (err) {
            if (err == null) {
              _this.ui.error = "An unknown error occurred, please contact help@qwilr.com";
              _this.log.error(
                "An unknown error occurred in manually pushing invoice to " + integration + " for block " + block._id,
              );
            } else if (err.message != null) {
              _this.ui.error = err.message;
              _this.log.error(err);
            } else {
              _this.ui.error = err;
            }
            _this.timeout(function () {
              return (_this.ui.error = "");
            }, 5000);
            return _this.q.reject(err);
          };
        })(this),
      );
    };

    return InvoiceSettingsMenu;
  })();
}.call(this));
