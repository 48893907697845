"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.maybeInsertVideoFromSidebar = void 0;
var lodash_1 = require("lodash");
var slate_1 = require("slate");
var slate_react_1 = require("slate-react");
var Utils_1 = require("../Utils");
var DragStore_1 = require("Project/Stores/DragStore/DragStore");
var DropLocationStore_1 = require("Project/Stores/DropLocationStore/DropLocationStore");
var Video_1 = require("../WidgetRegistry/Widgets/Video");
var ImageActions_1 = require("./ImageActions");
var maybeInsertVideoFromSidebar = function (editor, dragStore, dropLocationStore, mediaUsageReporter, blockType, editorTelemetry) {
    var currentDragItem = dragStore.findDragItemFromCurrentDrag(DragStore_1.DragType.SidebarVideo);
    var dropLocation = dropLocationStore.dropLocation;
    if (currentDragItem && (dropLocation === null || dropLocation === void 0 ? void 0 : dropLocation.editorId) === editor.uniqueId) {
        var mediaData = currentDragItem.mediaData, mediaSource = currentDragItem.mediaSource, telemetryData = currentDragItem.telemetryData;
        if (mediaData.type === "video") {
            var videoNode = (0, Video_1.createVideoNode)({ displayUrl: mediaData.url, embedUrl: mediaData.embedUrl });
            var insertionPath = dropLocation.path;
            if (dropLocation.type === DropLocationStore_1.DropLocationType.TwoColumnPlaceholder) {
                (0, ImageActions_1.dismissSideBySidePlaceholderFromDrop)(editor, insertionPath);
            }
            slate_1.Transforms.insertNodes(editor, videoNode, { at: insertionPath });
            slate_1.Transforms.select(editor, insertionPath);
            (0, lodash_1.defer)(function () {
                slate_react_1.ReactEditor.focus(editor);
            });
            mediaUsageReporter.reportUsage(mediaSource, mediaData);
            dropLocationStore.clearDropLocation();
            var originWasFiltered = telemetryData.originWasFiltered;
            editorTelemetry.sidebarImageDraggedInto(blockType, (0, Utils_1.isInsideSideBySide)(editor), originWasFiltered);
            return true;
        }
    }
};
exports.maybeInsertVideoFromSidebar = maybeInsertVideoFromSidebar;
