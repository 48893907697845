/*
  Provides utility functions for an account's fonts and typography.
 */

(function () {
  var FontService;

  module.exports = FontService = (function () {
    function FontService(account, utils, telemetry, $log, loadFonts) {
      /*
        Set the font for a given typographic element
        @param element [String] The element to assign the font to, either h1, h2 or p
        @param font [Object] The font object to be assigned to the element
       */
      this.setFont = (function (_this) {
        return function (element, font) {
          if (account.settings.brand.fonts == null) {
            account.settings.brand.fonts = {};
          }
          if (!_.includes(["h1", "h2", "p"], element)) {
            return $log.error("Trying to set an unknown typographic element", element);
          } else {
            return (account.settings.brand.fonts[element] = font);
          }
        };
      })(this);

      /*
        Set all the typography for an account.
       */
      this.setFonts = function (fonts) {
        var font, typographicElement;
        loadFonts(fonts);
        for (typographicElement in fonts) {
          font = fonts[typographicElement];
          this.setFont(typographicElement, font);
        }
        return this.trackFontChangeEvent();
      };
      this.trackFontChangeEvent = function () {
        var currFonts;
        currFonts = account.settings.brand.fonts;
        return telemetry.trackOnly("Account Fonts Changed", {
          font_selected_h1: currFonts.h1.friendlyName || currFonts.h1.name,
          font_selected_h2: currFonts.h2.friendlyName || currFonts.h2.name,
          font_selected_p: currFonts.p.friendlyName || currFonts.p.name,
        });
      };
    }

    return FontService;
  })();
}.call(this));
