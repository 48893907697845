"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isValidName = void 0;
exports.default = (function (createUi, modalAngular, $q, $timeout, Button, speechBubble, Input) {
    return function (_a) {
        var customHeader = _a.customHeader, yesButton = _a.yesButton, confirming = _a.confirming, style = _a.style;
        var showing = $q.defer();
        var noButtonAction = function () {
            if (noButtonObj.state === "disabled") {
                return;
            }
            modalAngular.hide();
            showing.resolve(false);
        };
        var yesButtonAction = function () { return __awaiter(void 0, void 0, void 0, function () {
            var newStyle_1, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (["waiting", "success"].includes(yesButtonObj.state)) {
                            return [2];
                        }
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        if (!(0, exports.isValidName)(input.value, style.name)) {
                            throw new Error("Try something like, ‘standard quote’ or ‘impact paragraph’");
                        }
                        yesButtonObj.state = "waiting";
                        noButtonObj.state = "disabled";
                        return [4, confirming(__assign(__assign({}, style), { name: input.value }))];
                    case 2:
                        newStyle_1 = _a.sent();
                        yesButtonObj.state = "success";
                        $timeout(function () {
                            showing.resolve(newStyle_1);
                            modalAngular.hide();
                        }, 200);
                        return [3, 4];
                    case 3:
                        error_1 = _a.sent();
                        if (yesButtonObj.state === "waiting") {
                            yesButtonObj.state = "idle";
                        }
                        noButtonObj.state = "idle";
                        speechBubble.explain(getExplainObject(error_1, input, yesButtonObj));
                        return [3, 4];
                    case 4: return [2];
                }
            });
        }); };
        var input = Input({
            type: "text",
            variation: "pencil",
            label: "Name your style...",
            value: style.name,
            onChange: function (value) {
                if (value && yesButtonObj.state === "disabled") {
                    yesButtonObj.state = "idle";
                }
                else if (!(0, exports.isValidName)(value, style.name) && yesButtonObj.state === "idle") {
                    yesButtonObj.state = "disabled";
                }
            },
        });
        var yesButtonObj = new Button(yesButtonAction, yesButton, {
            size: "large",
            width: "full",
            initialState: "disabled",
        });
        var noButtonObj = new Button(noButtonAction, "Cancel", {
            theme: "cancel",
            size: "large",
            width: "full",
        });
        modalAngular.show(createUi(require("./InputModal.html"), {
            header: customHeader,
            yesButton: yesButtonObj,
            noButton: noButtonObj,
            input: input,
        }), {
            onEscape: function () {
                showing.resolve(null);
            },
            enableEscape: function () {
                return yesButtonObj.state !== "waiting";
            },
        });
        return showing.promise;
    };
});
var isValidName = function (newName, oldName) {
    oldName = oldName || "";
    if (!newName || !newName.toLowerCase().trim()) {
        return false;
    }
    else if (newName.toLowerCase().trim() === oldName.toLowerCase().trim()) {
        return false;
    }
    else if (newName.toLowerCase().trim() === "default style") {
        return false;
    }
    else {
        return true;
    }
};
exports.isValidName = isValidName;
var getExplainObject = function (error, input, yesButtonObj) {
    if (typeof error.message === "string") {
        return {
            targetSelector: ".input--id-".concat(input.id),
            message: error.message,
        };
    }
    else {
        return {
            targetSelector: ".button--id-".concat(yesButtonObj.id),
            message: "Looks like something's gone wrong. Try again?",
        };
    }
};
