// Generated by CoffeeScript 1.12.7
(function () {
  var SlaaskIntegration;

  module.exports = SlaaskIntegration = (function () {
    function SlaaskIntegration(createUi, genericIntegration) {
      genericIntegration.build.call(this, "slaask", "Slaask widget key", require("./Slaask.html"));
    }

    return SlaaskIntegration;
  })();
}.call(this));
