"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getEngagementLevelTelemetryName = exports.getEngagementLevelDisplayName = void 0;
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var namesMapping = Object.freeze({
    "highly engaged": "highly engaged",
    engaged: "engaged",
    neutral: "neutral",
    disengaged: "disengaged",
    "highly disengaged": "highly disengaged",
});
var namesMappingTelemetry = Object.freeze({
    "highly engaged": event_tracker_types_1.EngagementLevel.HighlyEngaged,
    engaged: event_tracker_types_1.EngagementLevel.Engaged,
    neutral: event_tracker_types_1.EngagementLevel.Neutral,
    disengaged: event_tracker_types_1.EngagementLevel.Disengaged,
    "highly disengaged": event_tracker_types_1.EngagementLevel.HighlyDisengaged,
});
function getEngagementLevelDisplayName(engagementLevel, isCalculating) {
    var _a;
    if (isCalculating)
        return "Calculating";
    if (engagementLevel == undefined)
        return "Unmonitored";
    return (_a = namesMapping[engagementLevel]) !== null && _a !== void 0 ? _a : "Unmonitored";
}
exports.getEngagementLevelDisplayName = getEngagementLevelDisplayName;
function getEngagementLevelTelemetryName(engagementLevel) {
    var _a;
    return (_a = namesMappingTelemetry[engagementLevel]) !== null && _a !== void 0 ? _a : event_tracker_types_1.EngagementLevel.Unmonitored;
}
exports.getEngagementLevelTelemetryName = getEngagementLevelTelemetryName;
