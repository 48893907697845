// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (block) {
    var backwardsCompatibilityModule, isFoldable, label, state;
    backwardsCompatibilityModule = require("../Modules/BackwardsCompatibility");
    block = backwardsCompatibilityModule(block);
    if (block.settings == null) {
      block.settings = {};
    }
    if (block.type == null) {
      block.type = block.__t;
    }
    if (block.type === "module") {
      block.type = block.moduleName;
    }
    if (_.isBoolean(block.settings.foldable)) {
      isFoldable = block.settings.foldable;
      label = block.settings.foldableLabel;
      state = block.settings.isFolded;
      delete block.settings.foldable;
      delete block.settings.foldableLabel;
      delete block.settings.isFolded;
      block.settings.foldable = {
        isFoldable: isFoldable,
        label: label || "Show / Hide",
        isFolded: state,
      };
    }
    return block;
  };
}.call(this));
