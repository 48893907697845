"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (createUi, $sce) {
    return (function () {
        function Previewer(url, options) {
            this.ui = createUi(require("./Previewer.html"), {
                showMobile: options.showMobile || false,
                url: $sce.trustAsResourceUrl(url),
                mobileUrl: $sce.trustAsResourceUrl(options.mobileUrl || url),
            });
        }
        return Previewer;
    }());
});
