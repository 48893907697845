// Generated by CoffeeScript 1.12.7
(function () {
  var ItemFactory, ItemSettings, ItemSettingsButton, ItemSettingsMenu, itemFactory;

  ItemSettingsButton = require("./ItemSettingsButton.html");

  ItemSettingsMenu = require("../SettingsMenus/ItemSettingsMenu")["default"];

  ItemFactory = require("QuoteV2/Models/ItemTypes").ItemFactory;

  itemFactory = new ItemFactory();

  /*
  Button to bring up a Tooltip menu to change line item settings
   */

  module.exports = ItemSettings = (function () {
    function ItemSettings(
      createUi,
      make,
      block,
      project,
      Tooltip,
      itemAdapter,
      sectionView,
      savedLineItemsLibrary,
      itemView,
    ) {
      var deleteItem, itemSettingsMenu, saveItem;
      this.itemView = null;
      deleteItem = (function (_this) {
        return function () {
          _this.tooltip.hide();
          return sectionView.deleteItem(itemView);
        };
      })(this);
      saveItem = (function (_this) {
        return function () {
          var savedLineItem;
          savedLineItem = _.cloneDeep(itemAdapter.getModel());
          return savedLineItemsLibrary.saveLineItem(block.settings.currency, savedLineItem);
        };
      })(this);
      itemSettingsMenu = make(ItemSettingsMenu, {
        itemAdapter: itemAdapter,
        deleter: deleteItem,
        saveImpl: saveItem,
      });
      this.tooltip = make(Tooltip, {
        targetSelector: "#" + block.$id + " #" + itemView.ui.uniqueId + " .item-settings-button",
        options: {
          container: "#" + block.$id + " .project-block",
          customClass: "item-settings-menu-tooltip",
          customTheme: "",
          position: "bottom",
          contents: itemSettingsMenu.ui,
          spacing: 7,
          closeOnClickAway: true,
        },
      });
      this.tooltip.setOnShow(function () {
        return {};
      });
      this.ui = createUi(ItemSettingsButton, {
        onClick: (function (_this) {
          return function () {
            if (!_this.tooltip.isCurrentlyShowing()) {
              return _this.tooltip.show();
            } else {
              return _this.tooltip.hide();
            }
          };
        })(this),
        isMenuOpen: (function (_this) {
          return function () {
            return _this.tooltip.isCurrentlyShowing();
          };
        })(this),
      });
    }

    return ItemSettings;
  })();
}.call(this));
