// Generated by CoffeeScript 1.12.7

/*
  The Qwilr implementation of the text editor.
 */

(function () {
  var FeatureFlag, QwilrEditor, Undo;

  Undo = require("./Undo/Undo");

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = QwilrEditor = (function () {
    function QwilrEditor(di, usingFeature, createUi, model, block, EditorModel, $log, $timeout, options, environment) {
      var defaultAutoFocus,
        defaultOptions,
        editor,
        editorFocus,
        getEditorHtml,
        make,
        me,
        rightToLeftSupport,
        updateContent;
      di = di.child();
      make = di.make;
      me = this;
      if (block.$collabCreated) {
        defaultAutoFocus = false;
      } else {
        defaultAutoFocus = true;
      }
      defaultOptions = {
        enableWidgets: true,
        autoFocus: defaultAutoFocus,
        buttonNames: ["*"],
      };
      options = _.extend(defaultOptions, options);
      this.editor = editor = new qed.Editor();
      if (!model.isEditorModelClass) {
        model = new EditorModel(model, editor);
      }
      model.updateHtml(this.cleanModelContent(model.getHtml()));
      this.model = model;
      di.value("triggerContentEvent", function () {
        return editor.registry.defaultHandler().post("content");
      });
      editorFocus = function () {
        var child, elem, i, len, ref, results;
        elem = editor.currentElem();
        if (elem != null) {
          ref = elem.children;
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            child = ref[i];
            if (child.tagName.toLowerCase() !== "insertable") {
              editor.selection().setCaret(qed.Point.start(child));
              break;
            } else {
              results.push(void 0);
            }
          }
          return results;
        }
      };
      di.value("editorFocus", editorFocus);
      getEditorHtml = function () {
        var cloned;
        cloned = me.ui.element.clone();
        cloned.find("insertable").each(function () {
          var elem;
          elem = $(this);
          elem[0].innerHTML = "";
          return elem[0].removeAttribute("contenteditable");
        });
        return cloned.html();
      };
      updateContent = function () {
        var html;
        if (model.getHtml() === getEditorHtml()) {
          return;
        }
        html = model.getHtml();
        return me.ui.element.html(html);
      };
      rightToLeftSupport = function (elem) {
        var localisation;
        if (usingFeature(FeatureFlag.UseRightToLeftBeta)) {
          localisation = di.get("localisationSettings");
          if (localisation.direction === "rtl") {
            return elem.attr("dir", "rtl");
          }
        }
      };
      this.remove = (function (_this) {
        return function () {
          try {
            _this.editor.toolbar.remove();
          } catch (error) {}
          _this.undo.remove();
          return _this.editor.detach();
        };
      })(this);
      this.getStartPoint = (function (_this) {
        return function () {
          return Point.start(_this.elem);
        };
      })(this);
      this.getEndPoint = (function (_this) {
        return function () {
          return Point.end(_this.elem);
        };
      })(this);
      this.updateContentTo = function (newContent) {
        throw new Error("Not implemented");
      };
      this.focus = editorFocus;
      this.getEditorElem = (function (_this) {
        return function () {
          return _this.elem;
        };
      })(this);
      this.hasSelection = (function (_this) {
        return function () {
          return editor.selection().getRange() != null;
        };
      })(this);
      this.setMarkers = editor.setMarkers;
      this.loadFromMarkers = editor.loadFromMarkers;
      this.saveToMarkers = editor.saveToMarkers;
      this.ui = createUi(require("./QwilrEditor.html"), {
        $onElementRendered: (function (_this) {
          return function (elem, propertyName, scope) {
            var debug, e;
            _this.elem = elem;
            elem.on("$destroy", _this.remove);
            try {
              _this.undo = di.invoke(Undo.create, null, {
                editor: editor,
                block: model,
                element: elem,
                getEditorHtml: getEditorHtml,
              });
              if (options.enableWidgets) {
                editor = di.convoke(require("./Widgets/Widgets.js"), null, {
                  editor: editor,
                  qed: qed,
                  model: model,
                  undo: _this.undo,
                  scope: scope,
                });
              }
              editor = di.convoke(require("./Text/Editor.js"), null, {
                scope: scope,
                qed: qed,
                element: elem,
                editor: editor,
                undo: _this.undo,
                buttonNames: options.buttonNames,
                allowTab: options.allowTab || false,
              });
              di.invoke(require("./Selection/Selection"), null, {
                editor: editor,
              });
              make(require("./CopyPaste/CopyPaste"), {
                editor: editor,
                model: model,
                undo: _this.undo,
              });
              editor.addListener({
                onAttached: function (e) {
                  $timeout(function () {
                    var placeholders;
                    placeholders = new qed.Placeholders(editor);
                    placeholders.addPlaceholder("P", "Add Some Text...");
                    placeholders.addPlaceholder("H1", "Heading...");
                    placeholders.addPlaceholder("H2", "Sub Heading...");
                    placeholders.startTracking();
                    _this.placeholders = placeholders;
                    if (options.autoFocus) {
                      editorFocus();
                    }
                    return _this.undo.checkpoint();
                  });
                  return false;
                },
              });
              editor.attach(elem[0]);
              if (environment === "dogfood" && window.qwilr == null) {
                debug = require("./QwilrEditorDebug");
                _this.debug = debug;
                window.qwilr = debug;
                elem[0].qwilrEditor = editor;
              }
              _this.editor = editor;
              di.invoke(require("./Text/EnvSetup"), null, {
                elem: elem[0],
              });
            } catch (error) {
              e = error;
              $log.error(e);
            }
            rightToLeftSupport(elem);
            scope.$watch(model.getHtml, function (content) {
              return updateContent();
            });
            return scope.$watch(
              function () {
                var ref;
                return (ref = me.ui.element) != null ? ref.html() : void 0;
              },
              function (html) {
                if (html == null) {
                  return;
                }
                return model.updateHtml(getEditorHtml());
              },
            );
          };
        })(this),
      });
    }

    QwilrEditor.prototype.cleanModelContent = function (content) {
      content = content.replace(/<p><\/p>/g, "");
      content = content.replace(/<h1><\/h1>/g, "");
      content = content.replace(/<h2><\/h2>/g, "");
      if (content.trim().length === 0) {
        content = "<p><br></p>";
      }
      return content;
    };

    return QwilrEditor;
  })();
}.call(this));
