"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionView = void 0;
var react_1 = __importStar(require("react"));
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var UseSessionDetails_1 = require("./UseSessionDetails");
var UseViewerSessions_1 = require("./UseViewerSessions");
var Utils_1 = require("../Utils");
var TimeSpentAndInteractions_1 = require("../TimeSpentAndInteractions/TimeSpentAndInteractions");
var ViewerDetails_1 = require("./ViewerDetails");
var SessionInfoCard_1 = require("./SessionInfoCard");
var SessionViewTimeline_1 = require("./Timeline/SessionViewTimeline");
var commonStyles = __importStar(require("../CommonStyles.css"));
var styles = __importStar(require("./SessionView.css"));
var SessionView = function (props) {
    var pageId = props.pageId, viewer = props.viewer;
    var completeViewer = (0, UseViewerSessions_1.useViewerSessions)(pageId, { id: viewer.id, idType: viewer.idType }).viewer;
    if (!completeViewer)
        return (react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.loadingSpinner, justify: "center" },
            react_1.default.createElement(kaleidoscope_1.Spinner, { primary: true, size: "l" })));
    return react_1.default.createElement(LoadedSessionView, __assign({}, props, { viewer: completeViewer }));
};
exports.SessionView = SessionView;
var LoadedSessionView = function (_a) {
    var viewer = _a.viewer, initialPageViewId = _a.initialPageViewId, pageId = _a.pageId, isAdmin = _a.isAdmin, isPageEditable = _a.isPageEditable, timeZone = _a.timeZone, renderSharePopover = _a.renderSharePopover, closeModal = _a.closeModal, openAuditTrailOverlay = _a.openAuditTrailOverlay;
    var sessionsList = viewer.sessions;
    var _b = __read((0, react_1.useState)(function () {
        if (initialPageViewId != undefined) {
            var initialSessionIndex = sessionsList.findIndex(function (s) { return s.pageViewId === initialPageViewId; });
            if (initialSessionIndex >= 0)
                return initialSessionIndex;
        }
        return sessionsList.length - 1;
    }), 2), sessionIndex = _b[0], setSessionIndex = _b[1];
    var basicSession = sessionsList[sessionIndex];
    var pageViewId = basicSession.pageViewId;
    var sessionDetails = (0, UseSessionDetails_1.useSessionDetails)(pageId, pageViewId).sessionDetails;
    return (react_1.default.createElement("div", { className: commonStyles.scrollContainer },
        react_1.default.createElement("div", { className: commonStyles.wrapper },
            react_1.default.createElement(kaleidoscope_1.Stack, { className: commonStyles.rootLayoutStack, gap: { default: "m", tablet: "xxl" } },
                react_1.default.createElement(kaleidoscope_1.Stack, { className: styles.headerRow, direction: "horizontal" },
                    react_1.default.createElement(kaleidoscope_1.Heading, { className: styles.sessionViewHeading, strong: true, level: "3" }, "Session View"),
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", gap: "s", justify: "end", align: "center" },
                        react_1.default.createElement(kaleidoscope_1.Button, { className: styles.displayNameButton, type: kaleidoscope_1.ButtonType.Secondary }, viewer.displayName),
                        react_1.default.createElement(SessionSelector, { sessionIndex: sessionIndex, sessionsList: sessionsList, timeZone: timeZone, setSessionIndex: setSessionIndex }))),
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "center", wrap: true, gap: { default: "m", tablet: "l" } },
                    react_1.default.createElement(ViewerDetails_1.ViewerDetails, { viewer: viewer, renderSharePopover: renderSharePopover, closeModal: closeModal, isAdmin: isAdmin, isPageEditable: isPageEditable }),
                    react_1.default.createElement(SessionInfoCard_1.SessionInfoCard, { basicSession: sessionsList[sessionIndex], timeZone: timeZone })),
                react_1.default.createElement(SessionViewTimeline_1.SessionViewTimeline, { timeZone: timeZone, pageId: pageId, viewId: pageViewId, sessionTimestamp: basicSession.timestamp, openAuditTrailOverlay: openAuditTrailOverlay }),
                !!sessionDetails ? (react_1.default.createElement(TimeSpentAndInteractions_1.TimeSpentAndInteractions, { blockViewDurations: sessionDetails.blockViewDurations, interactionsTableProps: { pageId: pageId, viewId: pageViewId } })) : (react_1.default.createElement(TimeSpentAndInteractions_1.TimeSpentAndInteractionsLoading, null))))));
};
var SessionSelector = function (_a) {
    var sessionIndex = _a.sessionIndex, sessionsList = _a.sessionsList, timeZone = _a.timeZone, setSessionIndex = _a.setSessionIndex;
    return (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal" },
        react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.ChevronLeft, null), type: kaleidoscope_1.ButtonType.Secondary, disabled: sessionIndex <= 0, onClick: function () { return setSessionIndex(sessionIndex - 1); } }),
        react_1.default.createElement(kaleidoscope_1.OptionMenu, { className: styles.sessionsMenu, button: react_1.default.createElement(kaleidoscope_1.Button, { className: styles.sessionSelectorMiddleButton, type: kaleidoscope_1.ButtonType.Secondary },
                react_1.default.createElement(icons_1.Preview, null),
                " Session",
                react_1.default.createElement("div", { className: styles.sessionCounterPill },
                    toUserFacingSessionNumber(sessionIndex),
                    " / ",
                    sessionsList.length)), autoStack: true }, renderMenuItems(sessionIndex, sessionsList, timeZone, setSessionIndex)),
        react_1.default.createElement(kaleidoscope_1.IconButton, { icon: react_1.default.createElement(icons_1.ChevronRight, null), type: kaleidoscope_1.ButtonType.Secondary, disabled: sessionIndex >= sessionsList.length - 1, onClick: function () { return setSessionIndex(sessionIndex + 1); } })));
};
function renderMenuItems(selectedIndex, sessionsList, timeZone, setSessionIndex) {
    var menuItems = [];
    var _loop_1 = function (i) {
        var _a = sessionsList[i], pageViewId = _a.pageViewId, timestamp = _a.timestamp, duration = _a.duration;
        menuItems.push(react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { key: pageViewId, onClick: function () { return setSessionIndex(i); } },
            react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "spaceBetween", align: "center", gap: "s" },
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "xs" },
                    react_1.default.createElement("div", { className: styles.sessionCounterPill }, toUserFacingSessionNumber(i)),
                    (0, Utils_1.formatDatetime)(timestamp, timeZone)),
                react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", align: "center", gap: "xs" },
                    (0, Utils_1.formatCompactViewDuration)(duration),
                    i === selectedIndex && react_1.default.createElement(icons_1.Tick, { className: styles.selectedTick })))));
    };
    for (var i = sessionsList.length - 1; i >= 0; --i) {
        _loop_1(i);
    }
    return menuItems;
}
function toUserFacingSessionNumber(sessionIndex) {
    return sessionIndex + 1;
}
