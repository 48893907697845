// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (project) {
    return function (type) {
      var block, i, len, ref;
      ref = project.blocks;
      for (i = 0, len = ref.length; i < len; i++) {
        block = ref[i];
        if (block.type != null && block.type.toLowerCase() === type.toLowerCase()) {
          return true;
        }
      }
      return false;
    };
  };
}.call(this));
