// Generated by CoffeeScript 1.12.7
(function () {
  var IpFilter;

  module.exports = IpFilter = (function () {
    function IpFilter(link, account, project, createUi, overlayStack, $window, make, persist, apiBaseUrl) {
      var filters, officeIpAddresses, ref, ref1;
      filters = ((ref = link.ipFilter) != null ? ref.filters : void 0) || [];
      officeIpAddresses = _.filter(account.settings.officeIpAddresses, function (ip) {
        return ip.active;
      });
      this.ui = createUi(require("./IpFilter.html"), {
        officeIpAddresses: officeIpAddresses,
        filterAll: false,
        ipFilter: {
          isIpFilterEnabled: ((ref1 = link.ipFilter) != null ? ref1.enabled : void 0) || false,
          filterFlags: _.reduce(
            officeIpAddresses,
            function (result, value, key) {
              var officeIpId;
              officeIpId = value._id;
              if (_.includes(filters, officeIpId.toString())) {
                result[officeIpId] = true;
              } else {
                result[officeIpId] = false;
              }
              return result;
            },
            {},
          ),
        },
        ipFilterToggled: (function (_this) {
          return function () {
            return (link.ipFilter.enabled = _this.ui.ipFilter.isIpFilterEnabled);
          };
        })(this),
        ipFilterSettings: (function (_this) {
          return function () {
            $window.location.href = "#/settings/account";
            return overlayStack.closeCurrent();
          };
        })(this),
        isEmpty: (function (_this) {
          return function () {
            return _this.ui.officeIpAddresses.length <= 0;
          };
        })(this),
        toggleFilterAll: (function (_this) {
          return function () {
            if (_this.ui.filterAll) {
              _this.ui.ipFilter.filterFlags = _.mapValues(_this.ui.ipFilter.filterFlags, function () {
                return true;
              });
              return (_this.ui.filterAll = true);
            } else {
              _this.ui.ipFilter.filterFlags = _.mapValues(_this.ui.ipFilter.filterFlags, function () {
                return false;
              });
              return (_this.ui.filterAll = false);
            }
          };
        })(this),
        updateChanges: (function (_this) {
          return function () {
            _this.ui.filterAll = Object.values(_this.ui.ipFilter.filterFlags).every(function (filterFlag) {
              return !!filterFlag;
            });
            link.ipFilter.enabled = _this.ui.ipFilter.isIpFilterEnabled;
            return (link.ipFilter.filters = Object.keys(
              _.pickBy(_this.ui.ipFilter.filterFlags, function (officeIp) {
                return officeIp;
              }),
            ));
          };
        })(this),
        $init: function (scope) {
          make(persist, {
            options: {
              watchObject: "ui.ipFilter",
              scope: scope,
              apiRoute: apiBaseUrl + "/projects/" + project._id + "/links/" + link.secret + "/ipFilter",
              dataTransformer: function (ipFilter) {
                return {
                  ipFilter: {
                    enabled: ipFilter.isIpFilterEnabled,
                    filters: Object.keys(
                      _.pickBy(ipFilter.filterFlags, function (officeIp) {
                        return officeIp;
                      }),
                    ),
                  },
                };
              },
            },
          });
          return setTimeout(this.updateChanges(), 100);
        },
      });
    }

    return IpFilter;
  })();
}.call(this));
