"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var IntegrationsTileset = (function () {
    function IntegrationsTileset(createUi, isAdmin, integrations, integrationService, openIntegrationSettings, options) {
        var _this = this;
        this.ui = createUi(require("./IntegrationsTileset.html"), {
            showResultsCount: options.showResultsCount || false,
            resultsCategory: options.resultsCategory || "",
            resultsCount: function () {
                return _this.ui.integrations.length;
            },
            resultsWord: function () {
                return _this.ui.integrations.length == 1 ? "result" : "results";
            },
            integrations: integrations,
            openIntegrationSettings: function (item) {
                if (isAdmin) {
                    return openIntegrationSettings(item);
                }
                else {
                    return;
                }
            },
            getUserHasAccess: function () {
                if (!isAdmin) {
                    return "integrations-tile--blocked";
                }
            },
            getEnabledClass: function (integrationName) {
                if (integrationService.isEnabled(integrationName)) {
                    return "integrations-tile__enabled--show";
                }
                else {
                    return "integrations-tile__enabled--hide";
                }
            },
        });
    }
    Object.defineProperty(IntegrationsTileset.prototype, "integrations", {
        set: function (integrations) {
            this.ui.integrations = integrations;
        },
        enumerable: false,
        configurable: true
    });
    return IntegrationsTileset;
}());
exports.default = IntegrationsTileset;
