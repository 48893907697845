"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var Types_1 = require("@CommonFrontendBackend/Tokens/Types");
var Types_2 = require("Tokens/Types");
var TokenApiErrors_1 = require("../Common/TokenApiErrors");
var TokenStore = (function () {
    function TokenStore(tokenApiService, tokensTelemetry, projectStore) {
        this.tokenApiService = tokenApiService;
        this.tokensTelemetry = tokensTelemetry;
        this.projectStore = projectStore;
        this._tokensCache = new Map();
        this._tokens = [];
        this._isLoading = true;
    }
    Object.defineProperty(TokenStore.prototype, "tokens", {
        get: function () {
            return this._tokens;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TokenStore.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(TokenStore.prototype, "areCrmTokensAvailable", {
        get: function () {
            return this.projectStore.isCrmTemplate;
        },
        enumerable: false,
        configurable: true
    });
    TokenStore.prototype.getTokens = function (filter) {
        return __awaiter(this, void 0, Promise, function () {
            var tokens;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this._isLoading = true;
                        return [4, this.fetchOrGetTokensFromCache(this.createCacheKey(filter), function () {
                                return _this.tokenApiService.fetchTokens(filter);
                            })];
                    case 1:
                        tokens = _a.sent();
                        this.onFetchTokensSuccess(tokens);
                        return [2, tokens];
                }
            });
        });
    };
    TokenStore.prototype.createToken = function (toCreate) {
        return __awaiter(this, void 0, Promise, function () {
            var token;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.tokenApiService.createToken(toCreate)];
                    case 1:
                        token = _a.sent();
                        this.onCreateTokenSuccess(token);
                        return [2];
                }
            });
        });
    };
    TokenStore.prototype.updateToken = function (token, toUpdate) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.tokenApiService.updateToken(token.id, toUpdate)];
                    case 1:
                        _a.sent();
                        this.onUpdateTokenSuccess(token, toUpdate);
                        return [2];
                }
            });
        });
    };
    TokenStore.prototype.deleteToken = function (token) {
        return __awaiter(this, void 0, Promise, function () {
            var e_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.tokenApiService.deleteToken(token.id)];
                    case 1:
                        _a.sent();
                        this.tokensTelemetry.tokenDeleted();
                        return [3, 3];
                    case 2:
                        e_1 = _a.sent();
                        if (!(0, TokenApiErrors_1.isTokenNotFoundError)(e_1)) {
                            throw e_1;
                        }
                        return [3, 3];
                    case 3:
                        this.onDeleteTokenSuccess(token);
                        return [2];
                }
            });
        });
    };
    TokenStore.prototype.syncTokens = function (_a) {
        return __awaiter(this, arguments, Promise, function (_b) {
            var providerType = _b.providerType, objectType = _b.objectType;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.tokenApiService.syncTokens({ providerType: providerType, objectType: objectType })];
                    case 1:
                        _c.sent();
                        this._tokensCache.clear();
                        return [2];
                }
            });
        });
    };
    TokenStore.prototype.onFetchTokensSuccess = function (tokens) {
        this._isLoading = false;
        this._tokens = tokens;
    };
    TokenStore.prototype.onFetchTokensError = function () {
        this._isLoading = false;
        this._tokens = [];
    };
    TokenStore.prototype.onCreateTokenSuccess = function (token) {
        this._tokens.push(token);
        this.tokensTelemetry.tokenCreated();
        this._tokensCache.clear();
    };
    TokenStore.prototype.onUpdateTokenSuccess = function (token, toUpdate) {
        if (!!toUpdate.childPropertyLabel && (0, Types_2.isListToken)(token)) {
            token.childPropertyLabel = toUpdate.childPropertyLabel;
        }
        this._tokensCache.clear();
    };
    TokenStore.prototype.onDeleteTokenSuccess = function (token) {
        this._tokens = this._tokens.filter(function (_a) {
            var id = _a.id;
            return id !== token.id;
        });
        this._tokensCache.clear();
    };
    TokenStore.prototype.fetchOrGetTokensFromCache = function (cacheKey, fetchTokens) {
        return __awaiter(this, void 0, Promise, function () {
            var tokens, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tokens = [];
                        if (!this._tokensCache.has(cacheKey)) return [3, 1];
                        tokens = this._tokensCache.get(cacheKey);
                        return [3, 5];
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, fetchTokens()];
                    case 2:
                        tokens = _a.sent();
                        return [3, 4];
                    case 3:
                        err_1 = _a.sent();
                        this.onFetchTokensError();
                        throw new Error((0, TokenApiErrors_1.getFetchTokensErrorMessage)(err_1));
                    case 4:
                        this._tokensCache.set(cacheKey, tokens);
                        _a.label = 5;
                    case 5: return [2, tokens];
                }
            });
        });
    };
    TokenStore.prototype.createCacheKey = function (tokenListFilter) {
        return JSON.stringify(tokenListFilter);
    };
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t;
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", Array)
    ], TokenStore.prototype, "_tokens", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], TokenStore.prototype, "_isLoading", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_a = typeof Array !== "undefined" && Array) === "function" ? _a : Object),
        __metadata("design:paramtypes", [])
    ], TokenStore.prototype, "tokens", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], TokenStore.prototype, "isLoading", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], TokenStore.prototype, "areCrmTokensAvailable", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_b = typeof Types_1.TokenListFilter !== "undefined" && Types_1.TokenListFilter) === "function" ? _b : Object]),
        __metadata("design:returntype", typeof (_c = typeof Promise !== "undefined" && Promise) === "function" ? _c : Object)
    ], TokenStore.prototype, "getTokens", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_d = typeof Types_2.CreateTokenParams !== "undefined" && Types_2.CreateTokenParams) === "function" ? _d : Object]),
        __metadata("design:returntype", typeof (_e = typeof Promise !== "undefined" && Promise) === "function" ? _e : Object)
    ], TokenStore.prototype, "createToken", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, typeof (_h = typeof Types_2.UpdateTokenParams !== "undefined" && Types_2.UpdateTokenParams) === "function" ? _h : Object]),
        __metadata("design:returntype", typeof (_j = typeof Promise !== "undefined" && Promise) === "function" ? _j : Object)
    ], TokenStore.prototype, "updateToken", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_k = typeof Types_2.Token !== "undefined" && Types_2.Token) === "function" ? _k : Object]),
        __metadata("design:returntype", typeof (_l = typeof Promise !== "undefined" && Promise) === "function" ? _l : Object)
    ], TokenStore.prototype, "deleteToken", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", typeof (_m = typeof Promise !== "undefined" && Promise) === "function" ? _m : Object)
    ], TokenStore.prototype, "syncTokens", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_o = typeof Array !== "undefined" && Array) === "function" ? _o : Object]),
        __metadata("design:returntype", void 0)
    ], TokenStore.prototype, "onFetchTokensSuccess", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], TokenStore.prototype, "onFetchTokensError", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_p = typeof Types_2.Token !== "undefined" && Types_2.Token) === "function" ? _p : Object]),
        __metadata("design:returntype", void 0)
    ], TokenStore.prototype, "onCreateTokenSuccess", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object, typeof (_s = typeof Types_2.UpdateTokenParams !== "undefined" && Types_2.UpdateTokenParams) === "function" ? _s : Object]),
        __metadata("design:returntype", void 0)
    ], TokenStore.prototype, "onUpdateTokenSuccess", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [typeof (_t = typeof Types_2.Token !== "undefined" && Types_2.Token) === "function" ? _t : Object]),
        __metadata("design:returntype", void 0)
    ], TokenStore.prototype, "onDeleteTokenSuccess", null);
    return TokenStore;
}());
exports.default = TokenStore;
