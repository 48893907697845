// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = {
    Enter: {
      key: "enter",
      tests: [
        {
          name: "should split if in same block element",
          startContent: "<p>as[dfas]df</p>",
          selectionResult: "<p>as</p><p>|df</p>",
        },
        {
          name: "should split if in same block element but over inline elements",
          startContent: "<p>a[s<b>dfas</b>d]f</p>",
          selectionResult: "<p>a</p><p>|f</p>",
        },
        {
          name: "should delete selection and insert newline if selection over two block elements",
          startContent: "<p>as[df</p><p>as]df</p>",
          selectionResult: "<p>as</p><p>|df</p>",
        },
        {
          name: "should delete intermediary elements if selection goes over them completely",
          startContent: "<p>as[df</p><p>asdf</p><p>as]df</p>",
          selectionResult: "<p>as</p><p>|df</p>",
        },
        {
          name: "should behave like enter key press after removing selection",
          startContent: "<h1>as[df</h1><p>as]df</p>",
          selectionResult: "<h1>as</h1><h1>|df</h1>",
        },
        {
          name: "should behave like enter key press after removing selection 2",
          startContent: "<h1>[asdf</h1><p>a]sdf</p>",
          selectionResult: "<p><br></p><p>|sdf</p>",
        },
        {
          name: "should behave like enter key press after removing selection (with inline elems)",
          startContent: "<p><b>[asdf</b></p><p><i>a]sdf</i></p>",
          selectionResult: "<p><br></p><p><i>|sdf</i></p>",
        },
        {
          name:
            "should behave like enter key press after removing selection (with inline elems and alternate selection)",
          startContent: "<p><b>as[df</b></p><p><i>asdf]</i></p>",
          selectionResult: "<p><b>as</b></p><p>|<br></p>",
        },
        {
          name: "should work over list elements",
          startContent: "<ul><li>as[df</li><li>as]df</li></ul>",
          selectionResult: "<ul><li>as</li><li>|df</li></ul>",
        },
        {
          name: "should work between list elements and other elements (lists first)",
          startContent: "<ul><li>as[df</li></ul><p>as]df</p>",
          selectionResult: "<ul><li>as</li><li>|df</li></ul>",
        },
        {
          name: "should work between list elements and other elements (lists last)",
          startContent: "<p>as[df</p><ul><li>as]df</li></ul>",
          selectionResult: "<p>as</p><p>|df</p>",
        },
        {
          name: "should remove list if selection covers all of it",
          startContent: "<p>as[df</p><ul><li>asdf</li></ul><p>as]df</p>",
          selectionResult: "<p>as</p><p>|df</p>",
        },
        {
          name: "should leave two open list element if entire list contents selected",
          startContent: "<ul><li>[asdf</li><li>asdf]</li></ul>",
          selectionResult: "<ul><li><br></li><li>|<br></li></ul>",
        },
      ],
    },
    Backspace: {
      key: "backspace",
      tests: [
        {
          name: "should work if in same block element",
          startContent: "<p>as[dfas]df</p>",
          domResult: "<p>asdf</p>",
          selectionResult: "<p>as|df</p>",
        },
        {
          name: "should work if in same block element but over inline elements",
          startContent: "<p>a[s<b>dfas</b>d]f</p>",
          domResult: "<p>af</p>",
          selectionResult: "<p>a|f</p>",
        },
        {
          name: "should delete selection and join elements",
          startContent: "<p>as[df</p><p>as]df</p>",
          selectionResult: "<p>as|df</p>",
        },
        {
          name: "should delete intermediary elements if selection goes over them completely",
          startContent: "<p>as[df</p><p>asdf</p><p>as]df</p>",
          selectionResult: "<p>as|df</p>",
        },
        {
          name: "should join left if elements are different",
          startContent: "<h1>as[df</h1><p>as]df</p>",
          selectionResult: "<h1>as|df</h1>",
        },
        {
          name: "should not join left if first element's content is removed",
          startContent: "<p>asdf</p><h1>[asdf</h1><p>a]sdf</p>",
          selectionResult: "<p>asdf</p><p>|sdf</p>",
        },
        {
          name: "should handle deleting first element's content correctly",
          startContent: "<h1>[asdf</h1><p>a]sdf</p>",
          selectionResult: "<p>|sdf</p>",
        },
        {
          name: "should keep first element's tagname if last elements content is removed",
          startContent: "<h1>asd[f</h1><p>asdf]</p>",
          selectionResult: "<h1>asd|</h1>",
        },
        {
          name: "should leave an open P element if content is completely selected",
          startContent: "<p>[asdf</p><p>asdf]</p>",
          selectionResult: "<p>|<br></p>",
        },
        {
          name: "should leave open element of left-most selection if elements are completely selected",
          startContent: "<h1>a</h1><p>[asdf</p><p>asdf]</p><h1>b</h1>",
          selectionResult: "<h1>a</h1><p>|<br></p><h1>b</h1>",
        },
        {
          name: "should work over list elements",
          startContent: "<ol><li>as[df</li><li>as]df</li></ol>",
          selectionResult: "<ol><li>as|df</li></ol>",
        },
        {
          name: "shouldn't mangle when not used on all list item elements",
          startContent: "<ol><li>a</li><li>qu[er</li><li>r]ack</li><li>b</li></ol>",
          selectionResult: "<ol><li>a</li><li>qu|ack</li><li>b</li></ol>",
        },
        {
          name: "should work between list elements and other blocks (lists first)",
          startContent: "<ul><li>as[df</li></ul><p>as]df</p>",
          selectionResult: "<ul><li>as|df</li></ul>",
        },
        {
          name: "should work between list elements and other elements (lists last)",
          startContent: "<p>as[df</p><ul><li>as]df</li></ul>",
          selectionResult: "<p>as|df</p>",
        },
        {
          name: "should not join all list elements if they're not selected",
          startContent: "<p>as[df</p><ul><li>as]df</li><li>asdf</li></ul>",
          selectionResult: "<p>as|df</p><ul><li>asdf</li></ul>",
        },
        {
          name: "should remove list if selection covers it entirely",
          startContent: "<h1>as[df</h1><ul><li>asdf</li></ul><p>as]df</p>",
          selectionResult: "<h1>as|df</h1>",
        },
        {
          name: "should leave one open list element if entire list contents selected",
          startContent: "<ul><li>[asdf</li><li>asdf]</li></ul>",
          selectionResult: "<ul><li>|<br></li></ul>",
        },
        {
          name: "should work between lists",
          startContent: "<ul><li>as[df</li></ul><ol><li>as]df</li></ol>",
          selectionResult: "<ul><li>as|df</li></ul>",
        },
        {
          name: "should merge lists if removing the middle element",
          startContent: "<ul><li>a</li></ul>[<p>b</p><ul><li>]c</li></ul>",
          selectionResult: "<ul><li>a</li><li>|c</li></ul>",
        },
      ],
    },
    Delete: {
      key: "delete",
      tests: [
        {
          name: "should work if in same block element",
          startContent: "<p>as[dfas]df</p>",
          domResult: "<p>asdf</p>",
          selectionResult: "<p>as|df</p>",
        },
        {
          name: "should work if in same block element but over inline elements",
          startContent: "<p>a[s<b>dfas</b>d]f</p>",
          domResult: "<p>af</p>",
          selectionResult: "<p>a|f</p>",
        },
        {
          name: "should delete selection and join elements",
          startContent: "<p>as[df</p><p>as]df</p>",
          selectionResult: "<p>as|df</p>",
        },
        {
          name: "should delete intermediary elements if selection goes over them completely",
          startContent: "<p>as[df</p><p>asdf</p><p>as]df</p>",
          selectionResult: "<p>as|df</p>",
        },
        {
          name: "should join left if elements are different",
          startContent: "<h1>as[df</h1><p>as]df</p>",
          selectionResult: "<h1>as|df</h1>",
        },
        {
          name: "should not join left if first element's content is removed",
          startContent: "<p>asdf</p><h1>[asdf</h1><p>a]sdf</p>",
          selectionResult: "<p>asdf</p><p>|sdf</p>",
        },
        {
          name: "should handle deleting first element's content correctly",
          startContent: "<h1>[asdf</h1><p>a]sdf</p>",
          selectionResult: "<p>|sdf</p>",
        },
        {
          name: "should keep first element's tagname if last elements content is removed",
          startContent: "<h1>asd[f</h1><p>asdf]</p>",
          selectionResult: "<h1>asd|</h1>",
        },
        {
          name: "should leave an open P element if content is completely selected",
          startContent: "<p>[asdf</p><p>asdf]</p>",
          selectionResult: "<p>|<br></p>",
        },
        {
          name: "should leave open element of left-most selection if elements are completely selected",
          startContent: "<h1>a</h1><p>[asdf</p><p>asdf]</p><h1>b</h1>",
          selectionResult: "<h1>a</h1><p>|<br></p><h1>b</h1>",
        },
        {
          name: "should work over list elements",
          startContent: "<ol><li>as[df</li><li>as]df</li></ol>",
          selectionResult: "<ol><li>as|df</li></ol>",
        },
        {
          name: "shouldn't mangle when not used on all list item elements",
          startContent: "<ol><li>a</li><li>qu[er</li><li>r]ack</li><li>b</li></ol>",
          selectionResult: "<ol><li>a</li><li>qu|ack</li><li>b</li></ol>",
        },
        {
          name: "should work between list elements and other blocks (lists first)",
          startContent: "<ul><li>as[df</li></ul><p>as]df</p>",
          selectionResult: "<ul><li>as|df</li></ul>",
        },
        {
          name: "should work between list elements and other elements (lists last)",
          startContent: "<p>as[df</p><ul><li>as]df</li></ul>",
          selectionResult: "<p>as|df</p>",
        },
        {
          name: "should not join all list elements if they're not selected",
          startContent: "<p>as[df</p><ul><li>as]df</li><li>asdf</li></ul>",
          selectionResult: "<p>as|df</p><ul><li>asdf</li></ul>",
        },
        {
          name: "should remove list if selection covers it entirely",
          startContent: "<h1>as[df</h1><ul><li>asdf</li></ul><p>as]df</p>",
          selectionResult: "<h1>as|df</h1>",
        },
        {
          name: "should leave one open list element if entire list contents selected",
          startContent: "<ul><li>[asdf</li><li>asdf]</li></ul>",
          selectionResult: "<ul><li>|<br></li></ul>",
        },
        {
          name: "should work between lists",
          startContent: "<ul><li>as[df</li></ul><ol><li>as]df</li></ol>",
          selectionResult: "<ul><li>as|df</li></ul>",
        },
      ],
    },
    Input: {
      key: "space",
      tests: [
        {
          name: "should remove selection if in same block element",
          startContent: "<p>as[dfas]df</p>",
          domResult: "<p>asdf</p>",
          selectionResult: "<p>as|df</p>",
        },
        {
          name: "should remove selection if in same block element but over inline elements",
          startContent: "<p>a[s<b>dfas</b>d]f</p>",
          domResult: "<p>af</p>",
          selectionResult: "<p>a|f</p>",
        },
        {
          name: "should delete selection and join elements",
          startContent: "<p>as[df</p><p>as]df</p>",
          selectionResult: "<p>as|df</p>",
        },
        {
          name: "should delete intermediary elements if selection goes over them completely",
          startContent: "<p>as[df</p><p>asdf</p><p>as]df</p>",
          selectionResult: "<p>as|df</p>",
        },
        {
          name: "should join left if elements are different",
          startContent: "<h1>as[df</h1><p>as]df</p>",
          selectionResult: "<h1>as|df</h1>",
        },
        {
          name: "should not join left if first element's content is removed",
          startContent: "<p>asdf</p><h1>[asdf</h1><p>a]sdf</p>",
          selectionResult: "<p>asdf</p><p>|sdf</p>",
        },
        {
          name: "should handle deleting first element's content correctly",
          startContent: "<h1>[asdf</h1><p>a]sdf</p>",
          selectionResult: "<p>|sdf</p>",
        },
        {
          name: "should keep first element's tagname if last elements content is removed",
          startContent: "<h1>asd[f</h1><p>asdf]</p>",
          selectionResult: "<h1>asd|</h1>",
        },
        {
          name: "should leave an open P element if content is completely selected",
          startContent: "<p>[asdf</p><p>asdf]</p>",
          selectionResult: "<p>|<br></p>",
        },
        {
          name: "should leave open element of left-most selection if elements are completely selected",
          startContent: "<h1>a</h1><p>[asdf</p><p>asdf]</p><h1>b</h1>",
          selectionResult: "<h1>a</h1><p>|<br></p><h1>b</h1>",
        },
        {
          name: "should work over list elements",
          startContent: "<ol><li>as[df</li><li>as]df</li></ol>",
          selectionResult: "<ol><li>as|df</li></ol>",
        },
        {
          name: "shouldn't mangle when not used on all list item elements",
          startContent: "<ol><li>a</li><li>qu[er</li><li>r]ack</li><li>b</li></ol>",
          selectionResult: "<ol><li>a</li><li>qu|ack</li><li>b</li></ol>",
        },
        {
          name: "should work between list elements and other blocks (lists first)",
          startContent: "<ul><li>as[df</li></ul><p>as]df</p>",
          selectionResult: "<ul><li>as|df</li></ul>",
        },
        {
          name: "should work between list elements and other elements (lists last)",
          startContent: "<p>as[df</p><ul><li>as]df</li></ul>",
          selectionResult: "<p>as|df</p>",
        },
        {
          name: "should not join all list elements if they're not selected",
          startContent: "<p>as[df</p><ul><li>as]df</li><li>asdf</li></ul>",
          selectionResult: "<p>as|df</p><ul><li>asdf</li></ul>",
        },
        {
          name: "should remove list if selection covers it entirely",
          startContent: "<h1>as[df</h1><ul><li>asdf</li></ul><p>as]df</p>",
          selectionResult: "<h1>as|df</h1>",
        },
        {
          name: "should leave one open list element if entire list contents selected",
          startContent: "<ul><li>[asdf</li><li>asdf]</li></ul>",
          selectionResult: "<ul><li>|<br></li></ul>",
        },
        {
          name: "should work between lists",
          startContent: "<ul><li>as[df</li></ul><ol><li>as]df</li></ol>",
          selectionResult: "<ul><li>as|df</li></ul>",
        },
      ],
    },
  };
}.call(this));
