"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var MountLinkPreviewAccountDefaults_1 = require("./MountLinkPreviewAccountDefaults");
var LinkPreviewSettings = (function () {
    function LinkPreviewSettings(createUi, di, rootDataStore, accountService, assetLibraryOverlay, imageService, usingFeature, $timeout, telemetry) {
        var accountStore = rootDataStore.getAccountDataStore();
        var linkPreviewAccountDefaultsInner = di.invoke(MountLinkPreviewAccountDefaults_1.MountLinkPreviewAccountDefaults)({
            accountStore: accountStore,
            accountService: accountService,
            assetLibraryOverlay: assetLibraryOverlay,
            imageService: imageService,
            usingFeature: usingFeature,
            telemetry: telemetry,
        });
        this.ui = createUi(require("./LinkPreviewAccountDefaults.html"), { linkPreviewAccountDefaultsInner: linkPreviewAccountDefaultsInner });
    }
    return LinkPreviewSettings;
}());
exports.default = LinkPreviewSettings;
