// Generated by CoffeeScript 1.12.7
(function () {
  var slice = [].slice;

  module.exports = function (
    make,
    createUi,
    colors,
    block,
    viewState,
    ImagePickerButton,
    onAddedContent,
    $timeout,
    telemetry,
  ) {
    var VideoButton, backgroundSetter, imagePickerButton, imageUrl, setEmbed, videoPickerButton;
    backgroundSetter = make(require("./BackgroundSetter"), {
      onAddedContent: onAddedContent,
    });
    setEmbed = function (embedUrl) {
      return backgroundSetter.setEmbed(embedUrl);
    };
    if (block.resourceUrl != null && block.resourceType === "image") {
      imageUrl = block.resourceUrl;
    } else if (block.imageUrl != null) {
      imageUrl = block.imageUrl;
    } else {
      imageUrl = "";
    }
    imagePickerButton = make(ImagePickerButton, {
      imageFile: imageUrl,
      skin: "dark",
      useImageCarousel: true,
      onUploadedCallback: (function (_this) {
        return function (selectedImageUrl) {
          if (selectedImageUrl == null) {
            return;
          }
          block.imageUrl = selectedImageUrl;
          return backgroundSetter.setImage(selectedImageUrl);
        };
      })(this),
    });
    VideoButton = require("Common/MediaPicker/VideoPicker/Button/Button");
    videoPickerButton = make(VideoButton, {
      skin: "dark",
      videoUrl: block.videoUrl || "",
      onSelected: function () {
        var args;
        args = 1 <= arguments.length ? slice.call(arguments, 0) : [];
        return backgroundSetter.setVideo.apply(backgroundSetter, args);
      },
    });
    return (this.ui = createUi(require("Project/Modules/Splash/Controls/Controls.html"), {
      block: block,
      viewState: viewState,
      imagePickerButton: imagePickerButton.ui,
      videoPickerButton: videoPickerButton.ui,
      hasResource: function () {
        var ref, ref1;
        return (
          ((ref = block.imageUrl) != null ? ref.length : void 0) > 0 ||
          ((ref1 = block.resourceUrl) != null ? ref1.length : void 0) > 0
        );
      },
      sizes: [
        {
          name: "Full Page",
          cssClassName: "fullpage",
        },
        {
          name: "Half Page",
          cssClassName: "half",
        },
        {
          name: "One Third",
          cssClassName: "third",
        },
      ],
      tints: [
        {
          name: "Brand",
          value: colors.brand,
        },
        {
          name: "Complementary",
          value: colors.complementary,
        },
        {
          name: "Dark",
          value: "#111",
        },
        {
          name: "Light",
          value: "#fff",
        },
      ],
    }));
  };
}.call(this));
