// Generated by CoffeeScript 1.12.7
(function () {
  var BackgroundColorPicker,
    buildCssToAdd,
    getColor,
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  getColor = require("@CommonFrontendBackend/StyleService/GetColor");

  buildCssToAdd = function (account, style) {
    return (
      "&.project-block .image {\nbackground-blend-mode: multiply;\nbackground-color: " +
      getColor(account.settings.brand.colors, style.background, null) +
      ";\n}"
    );
  };

  module.exports = BackgroundColorPicker = (function () {
    function BackgroundColorPicker(make, MenuDropdown, account, createUi, styleService, style, isCard, onChange, di) {
      var backgroundItem,
        backgroundKey,
        backgroundOptions,
        className,
        containerStyle,
        cssToAdd,
        dropdown,
        forSplash,
        getElementDescription,
        needsPlaceholderImage,
        optionUis;
      backgroundItem = require("./BackgroundItem.html");
      forSplash = indexOf.call(style.blockTypes, "Splash") >= 0;
      backgroundOptions = styleService.getBackgrounds(account.settings.brand.colors, forSplash);
      needsPlaceholderImage = !isCard && forSplash;
      if (isCard) {
        backgroundKey = "backgroundCard";
        className = "background-card";
      } else if (forSplash) {
        if (style.background === "white") {
          style.tint = "white";
          style.background = "none";
        }
        if (style.background !== "none") {
          if (style.styleModel == null) {
            style.styleModel = [];
          }
          cssToAdd = buildCssToAdd(account, style);
          containerStyle = _.find(style.styleModel, function (arg) {
            var name;
            name = arg.name;
            return element === "";
          });
          if (containerStyle != null) {
            containerStyle.rawStyles.push(cssToAdd);
          } else {
            getElementDescription = di.get("getElementDescription");
            style.styleModel.push({
              name: getElementDescription(""),
              element: "",
              rawStyles: cssToAdd,
            });
          }
          style.tint = "none";
          style.background = "none";
          onChange();
        }
        backgroundKey = "tint";
        className = "background";
      } else {
        backgroundKey = "background";
        className = "background";
      }
      optionUis = _.map(
        backgroundOptions,
        (function (_this) {
          return function (option) {
            return createUi(backgroundItem, {
              name: option.name,
              hex: option.hex,
              borderStyle: option.borderStyle,
              needsPlaceholderImage: needsPlaceholderImage,
              onClick: function () {
                if (_this.ui.selected.name === option.name) {
                  return;
                }
                _this.ui.selected = {
                  name: option.name,
                  hex: option.hex,
                  borderStyle: option.borderStyle,
                };
                style[backgroundKey] = option.name;
                return onChange();
              },
            });
          };
        })(this),
      );
      dropdown = make(MenuDropdown, {
        toggleButtonElem: ".background-selected",
        items: optionUis,
        options: {
          hideOnClick: true,
          onHide: (function (_this) {
            return function () {
              return (_this.ui.toggled = false);
            };
          })(this),
        },
      });
      this.ui = createUi(require("./BackgroundPicker.html"), {
        selected: _.find(backgroundOptions, function (option) {
          return option.name === style[backgroundKey];
        }),
        needsPlaceholderImage: needsPlaceholderImage,
        toggled: false,
        dropdown: dropdown.ui,
        className: className,
        toggle: (function (_this) {
          return function () {
            if (_this.ui.toggled) {
              _this.ui.toggled = false;
            } else {
              _this.ui.toggled = true;
            }
            return dropdown.toggle();
          };
        })(this),
      });
    }

    return BackgroundColorPicker;
  })();
}.call(this));
