// Generated by CoffeeScript 1.12.7

/*
 * UI for FreshBooks' Accept settings
 */

(function () {
  var FreshBooksInvoicer,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = FreshBooksInvoicer = (function () {
    function FreshBooksInvoicer($http, $q, make, apiBaseUrl, GenericInvoicer) {
      this.customers = bind(this.customers, this);
      this.createInvoice = bind(this.createInvoice, this);
      var invoicingUi;
      this.http = $http;
      this.q = $q;
      this.apiBaseUrl = apiBaseUrl;
      invoicingUi = make(GenericInvoicer, {
        integration: "freshbooks",
        getCustomers: this.customers,
        createInvoice: this.createInvoice,
      });
      this.ui = invoicingUi.ui;
    }

    FreshBooksInvoicer.prototype.createInvoice = function (blockId, contact, linkSecret) {
      var err;
      err = null;
      if (blockId == null) {
        err = new Error("An error detecting the quote block occurred, please contact help@qwilr.com");
      }
      if (contact == null) {
        err = "No contact specified, please provide a contact for the invoice";
      }
      if (!(linkSecret != null ? linkSecret.length : void 0)) {
        err = new Error("An error with the project occurred, please contact help@qwilr.com");
      }
      if (err != null) {
        return this.q.reject(err);
      }
      return this.http.post(this.apiBaseUrl + "/integration/freshbooks/invoice", {
        blockId: blockId,
        linkSecret: linkSecret,
        contact: contact,
      });
    };

    FreshBooksInvoicer.prototype.customers = function () {
      return this.http.get(this.apiBaseUrl + "/integration/freshbooks/customers").then(
        (function (_this) {
          return function (response) {
            var customers, data, i, item, len;
            data = response.data.message;
            customers = [];
            for (i = 0, len = data.length; i < len; i++) {
              item = data[i];
              customers.push({
                name: item.first_name + " " + item.last_name,
                id: item.client_id,
                email: item.email,
              });
            }
            return customers;
          };
        })(this),
      );
    };

    return FreshBooksInvoicer;
  })();
}.call(this));
