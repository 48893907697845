// Generated by CoffeeScript 1.12.7
(function () {
  var Delete, template;

  template = require("./Delete.html");

  module.exports = Delete = (function () {
    function Delete(createUi, deleter, isOnlyItem) {
      this.ui = createUi(template, {
        delete: function () {
          if (!isOnlyItem()) {
            return deleter();
          }
        },
        isLastItem: function () {
          return isOnlyItem();
        },
      });
    }

    return Delete;
  })();
}.call(this));
