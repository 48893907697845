// Generated by CoffeeScript 1.12.7

/*
 * Used to initialise a subscription to a plan without using the plan Explorer.
 * This must only be used when the account does not have an existing subscription
 */

(function () {
  var SubscribeToPlan;

  module.exports = SubscribeToPlan = (function () {
    function SubscribeToPlan(make, $timeout, account, apiBaseUrl, SubscriptionSequencer, planService) {
      var attributionScreen, confirmationScreen, createPlanInfo, creditCardScreen, selectPlanScreen;
      this.make = make;
      this.$timeout = $timeout;
      this.account = account;
      this.apiBaseUrl = apiBaseUrl;
      this.SubscriptionSequencer = SubscriptionSequencer;
      selectPlanScreen = require("SettingsAngular/Subscription/PlanExplorer/PlanExplorer")["default"];
      creditCardScreen = require("SettingsAngular/Subscription/CreditCard/CreditCard");
      confirmationScreen = require("SettingsAngular/Subscription/Confirmation/Confirmation");
      attributionScreen = require("SettingsAngular/Subscription/Attribution/Attribution");

      /*
        Start the subscription flow using the provided planInfo object
       */
      this.subscribeWithPlanInfo = (function (_this) {
        return function (planInfo) {
          var sequence;
          return (sequence = _this.make(_this.SubscriptionSequencer, {
            steps: [creditCardScreen, confirmationScreen, attributionScreen],
            allowCoupon: true,
            planInfo: planInfo,
            businessTrial: false,
            isNewSubscription: true,
            options: {
              onHitEnd: function (subscriptionDi) {
                return _this.$timeout(function () {
                  return window.location.reload();
                }, 2500);
              },
            },
          }));
        };
      })(this);

      /*
        Start the subscription flow using a plan name
        @param planName [String] A lowercase name used to find the matching internal plan names
        e.g "business"
       */
      this.subscribe = (function (_this) {
        return function (planName, planIsMonthly) {
          var originalPlan;
          if (planIsMonthly == null) {
            planIsMonthly = true;
          }
          originalPlan = _.cloneDeep(_this.account.plan);
          return createPlanInfo(planName, planIsMonthly).then(function (planInfo) {
            return _this.subscribeWithPlanInfo(planInfo);
          });
        };
      })(this);
      createPlanInfo = function (planName, planIsMonthly) {
        return planService.fetchPlans().then(
          (function (_this) {
            return function (internalPlans) {
              var planInfo;
              return (planInfo = {
                isMonthly: planIsMonthly,
                monthly: planService.getInternalPlan(internalPlans, planName, true),
                yearly: planService.getInternalPlan(internalPlans, planName, false),
              });
            };
          })(this),
        );
      };
    }

    return SubscribeToPlan;
  })();
}.call(this));
