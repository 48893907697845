"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ROIFormulaEditor = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var icons_2 = require("@qwilr/kaleidoscope/icons");
var icons_3 = require("@qwilr/kaleidoscope/icons");
var icons_4 = require("@qwilr/kaleidoscope/icons");
var icons_5 = require("@qwilr/kaleidoscope/icons");
var lodash_1 = require("lodash");
var mobx_1 = require("mobx");
var mobx_react_1 = require("mobx-react");
var react_1 = __importStar(require("react"));
var FormulaErrorUtils_1 = require("./FormulaErrorUtils");
var ROIFormulaEditor = (function (_super) {
    __extends(ROIFormulaEditor, _super);
    function ROIFormulaEditor() {
        var _this = _super.apply(this, __spreadArray([], __read(arguments), false)) || this;
        _this.formulaEditorRef = (0, react_1.createRef)();
        _this.variableMenuOpen = false;
        _this.toolbarVisible = false;
        _this.handleKeydown = function (event) {
            if (event.key === kaleidoscope_1.KeyType.Enter && !event.shiftKey) {
                _this.formulaEditorRef.current.blur();
            }
            if (event.key === kaleidoscope_1.KeyType.Escape) {
                _this.props.onCommitFormula();
            }
        };
        _this.setVariableMenuOpen = function (value) {
            _this.variableMenuOpen = value;
        };
        _this.setToolbarVisible = function (visibility) {
            _this.toolbarVisible = visibility;
        };
        _this.handleBlur = function () {
            if (!_this.variableMenuOpen) {
                _this.toolbarVisibilityTimeoutId = window.setTimeout(function () {
                    (0, mobx_1.runInAction)(function () {
                        _this.setToolbarVisible(false);
                        _this.props.onCommitFormula();
                    });
                }, 100);
            }
        };
        _this.handleFocus = function () {
            clearTimeout(_this.toolbarVisibilityTimeoutId);
            _this.setToolbarVisible(true);
        };
        _this.makeHandleInsertText = function (text) { return function () {
            var formulaEditor = _this.formulaEditorRef.current;
            var selectionStart = formulaEditor.selectionStart, selectionEnd = formulaEditor.selectionEnd;
            formulaEditor.setRangeText("".concat(text, " "), selectionStart, selectionEnd, "end");
            var selectionToRestore = {
                start: formulaEditor.selectionStart,
                end: formulaEditor.selectionEnd,
                direction: formulaEditor.selectionDirection,
            };
            _this.props.onChangeFormula(formulaEditor.value);
            (0, lodash_1.defer)(function () {
                _this.formulaEditorRef.current.focus();
                _this.formulaEditorRef.current.setSelectionRange(selectionToRestore.start, selectionToRestore.end, selectionToRestore.direction);
            });
        }; };
        return _this;
    }
    ROIFormulaEditor.prototype.render = function () {
        var _this = this;
        var _a = this.props, onChangeFormula = _a.onChangeFormula, formulaErrorDetail = _a.formulaErrorDetail, formula = _a.formula, sliderLabel = _a.sliderLabel, variableInputs = _a.variableInputs;
        return (react_1.default.createElement("div", { className: "roi-formula-editor" },
            react_1.default.createElement(kaleidoscope_1.TextInput, { multiline: true, label: "ROI formula", value: formula, onChange: onChangeFormula, placeholder: "Enter a formula", onBlur: this.handleBlur, onFocus: this.handleFocus, error: !!formulaErrorDetail ? (0, FormulaErrorUtils_1.getFormulaErrorCopy)(formulaErrorDetail) : undefined, onKeyDown: this.handleKeydown, ref: this.formulaEditorRef }),
            react_1.default.createElement(kaleidoscope_1.Toolbar, { className: "roi-formula-editor__toolbar", positionType: kaleidoscope_1.ToolbarPositionType.Element, visible: this.toolbarVisible, element: this.formulaEditorRef.current, autoStack: true, onMouseDown: function (ev) {
                    ev.nativeEvent.stopImmediatePropagation();
                } },
                react_1.default.createElement(kaleidoscope_1.OptionMenu, { autoStack: true, className: "roi-formula-editor__variable-menu", onClose: function () {
                        _this.setVariableMenuOpen(false);
                        setTimeout(function () {
                            _this.formulaEditorRef.current.focus();
                        });
                    }, button: react_1.default.createElement(kaleidoscope_1.ToolbarButton, { icon: react_1.default.createElement(icons_1.Variables, null), tooltip: { content: "Variables" }, onMouseDown: function () {
                            _this.setVariableMenuOpen(true);
                        } }) },
                    react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { onClick: this.makeHandleInsertText("{{slider}}") }, "(Slider) ".concat(sliderLabel)),
                    variableInputs.map(function (_a) {
                        var _b = __read(_a, 2), variableId = _b[0], input = _b[1];
                        return (react_1.default.createElement(kaleidoscope_1.OptionMenuItem, { key: variableId, onClick: _this.makeHandleInsertText("{{".concat(variableId, "}}")) }, "(".concat(variableId, ") ").concat(input.label)));
                    })),
                react_1.default.createElement(kaleidoscope_1.ToolbarSeparator, null),
                react_1.default.createElement(kaleidoscope_1.ToolbarButton, { icon: react_1.default.createElement(icons_1.Add, null), "aria-label": "Add", onClick: this.makeHandleInsertText("+") }),
                react_1.default.createElement(kaleidoscope_1.ToolbarButton, { icon: react_1.default.createElement(icons_5.Subtract, null), "aria-label": "Subtract", onClick: this.makeHandleInsertText("-") }),
                react_1.default.createElement(kaleidoscope_1.ToolbarButton, { icon: react_1.default.createElement(icons_4.Multiply, null), "aria-label": "Multiply", onClick: this.makeHandleInsertText("*") }),
                react_1.default.createElement(kaleidoscope_1.ToolbarButton, { icon: react_1.default.createElement(icons_2.Divide, null), "aria-label": "Divide", onClick: this.makeHandleInsertText("/") })),
            react_1.default.createElement("div", { className: "roi-formula-editor__help" },
                react_1.default.createElement(kaleidoscope_1.IconButton, { type: kaleidoscope_1.ButtonType.Tertiary, isRound: true, icon: react_1.default.createElement(icons_3.Help, { size: icons_1.IconSize.Small }), size: kaleidoscope_1.ButtonSize.Small, href: function () { return "https://help.qwilr.com/article/706-writing-a-formula-for-the-roi-calculator"; }, tooltip: { content: "How to write formulas" } }))));
    };
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], ROIFormulaEditor.prototype, "variableMenuOpen", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], ROIFormulaEditor.prototype, "toolbarVisible", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ROIFormulaEditor.prototype, "setVariableMenuOpen", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ROIFormulaEditor.prototype, "setToolbarVisible", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ROIFormulaEditor.prototype, "handleBlur", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ROIFormulaEditor.prototype, "handleFocus", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], ROIFormulaEditor.prototype, "makeHandleInsertText", void 0);
    ROIFormulaEditor = __decorate([
        mobx_react_1.observer
    ], ROIFormulaEditor);
    return ROIFormulaEditor;
}(react_1.default.Component));
exports.ROIFormulaEditor = ROIFormulaEditor;
