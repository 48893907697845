"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldFetchNextPage = void 0;
var shouldFetchNextPage = function (_a) {
    var pauseDataFetching = _a.pauseDataFetching, hasMoreData = _a.hasMoreData, loadedContentHeight = _a.loadedContentHeight, scrollableTargetHeight = _a.scrollableTargetHeight;
    if (pauseDataFetching) {
        return false;
    }
    if (!hasMoreData) {
        return false;
    }
    return loadedContentHeight < scrollableTargetHeight;
};
exports.shouldFetchNextPage = shouldFetchNextPage;
