"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = (function (createUi, modalAngular, $q, invoke, $timeout, Button, speechBubble) {
    var showModal = function (_a) {
        var title = _a.title, explainer = _a.explainer, customHeader = _a.customHeader, noButton = _a.noButton, yesButton = _a.yesButton, yesButtonTheme = _a.yesButtonTheme, confirming = _a.confirming, getErrorMessage = _a.getErrorMessage, disableEscape = _a.disableEscape;
        var showing = $q.defer();
        var hasConfirming = confirming != null;
        disableEscape = disableEscape || false;
        var runConfirming = confirming || (function () { return $q.resolve(); });
        var noButtonAction = function () {
            if (noButtonObj.state === "disabled") {
                return;
            }
            modalAngular.hide();
            showing.resolve(false);
        };
        var yesButtonAction = function () {
            if (["waiting", "success"].includes(yesButtonObj.state)) {
                return;
            }
            if (hasConfirming) {
                yesButtonObj.state = "waiting";
                noButtonObj.state = "disabled";
            }
            runConfirming()
                .then(function () {
                var timeoutLength;
                if (hasConfirming) {
                    yesButtonObj.state = "success";
                    timeoutLength = 200;
                }
                else {
                    timeoutLength = 0;
                }
                $timeout(function () {
                    showing.resolve(true);
                    modalAngular.hide();
                }, timeoutLength);
            })
                .catch(function (error) {
                yesButtonObj.state = "idle";
                noButtonObj.state = "idle";
                var message = error.toString() || "Looks like something's gone wrong. Try again?";
                if (getErrorMessage) {
                    message = getErrorMessage(error);
                }
                else if (error.message) {
                    message = error.message;
                }
                speechBubble.alert({
                    targetSelector: ".decide-modal .yes-button",
                    message: message,
                });
            });
        };
        var yesButtonObj = new Button(yesButtonAction, yesButton || "Yes", {
            theme: yesButtonTheme,
            size: "large",
            width: "full",
        });
        var noButtonObj = new Button(noButtonAction, noButton || "No", {
            theme: "cancel",
            size: "large",
            width: "full",
        });
        var header = customHeader ||
            createUi(require("./DecideModalHeader.html"), {
                title: title,
                explainer: getExplainer(explainer),
            });
        modalAngular.show(createUi(require("./DecideModal.html"), {
            header: header,
            yesButton: yesButtonObj,
            noButton: noButtonObj,
        }), {
            onEscape: function () {
                showing.resolve(null);
            },
            enableEscape: function () {
                return yesButtonObj.state !== "waiting" && !disableEscape;
            },
        });
        return showing.promise;
    };
    var getExplainer = invoke(require("../ExplainerText/ExplainerText"));
    return showModal;
});
