(function () {
  var QuoteConfiguration;

  module.exports = QuoteConfiguration = (function () {
    function QuoteConfiguration(createUi, make, block, toggleDisplayMode, viewState, telemetry, $log) {
      var trackConfigChange;
      trackConfigChange = _.once(function () {
        return telemetry.trackOnly(
          "Quote Block Configuration Changed",
          {},
          {
            blockIntercom: true,
          },
        );
      });
      this.ui = createUi(require("./Configuration.html"), {
        settings: block.settings.displayConfig,
        onClick: function () {
          trackConfigChange();
          if (!viewState.displayMode) {
            return toggleDisplayMode();
          }
        },
        ensurePreviewMode: toggleDisplayMode,
        options: [
          {
            name: "Quote Total",
            items: [
              {
                label: "Show a quote total?",
                modelProperty: "total",
              },
              {
                label: "Show quote sub total (i.e. excluding tax)?",
                modelProperty: "subTotal",
              },
              {
                label: "Show taxes (i.e. GST / VAT)?",
                modelProperty: "taxes",
              },
              {
                label: "Show total including tax?",
                modelProperty: "taxTotal",
              },
            ],
          },
          {
            name: "Line Items",
            items: [
              {
                label: "Show item units (i.e. hours / days etc)?",
                modelProperty: "units",
              },
              {
                label: "Show unit price?",
                modelProperty: "unitPrice",
              },
              {
                label: "Show section totals only (i.e no line item costs)?",
                modelProperty: "hideItems",
              },
            ],
          },
          {
            name: "Sections",
            items: [
              {
                label: "Show section subtotals?",
                modelProperty: "sectionSubTotals",
              },
              {
                label: "Show discounts per section",
                modelProperty: "sectionDiscount",
              },
            ],
          },
        ],
      });
    }

    return QuoteConfiguration;
  })();
}.call(this));
