// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (createUi) {
    return function (text) {
      if (_.isString(text)) {
        return createUi(require("./ExplainerText.html"), {
          text: text,
        });
      } else {
        return text;
      }
    };
  };
}.call(this));
