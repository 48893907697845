"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.areColorsEqual = exports.styleablePropertiesAreEqual = void 0;
var lodash_1 = require("lodash");
var styleablePropertiesAreEqual = function (a, b, propertyDefinition) {
    var comparable = [a, b, propertyDefinition];
    if (isColorProperty(comparable)) {
        return areColorsEqual(comparable[0], comparable[1]);
    }
    return (0, lodash_1.isEqual)(a, b);
};
exports.styleablePropertiesAreEqual = styleablePropertiesAreEqual;
var isColorProperty = function (comparable) {
    return comparable[2].type === "color";
};
var areColorsEqual = function (a, b) {
    if (a.themeIndex !== b.themeIndex) {
        return false;
    }
    if (a.opacity !== b.opacity) {
        return false;
    }
    if (a.themeIndex == null) {
        if (a.abs !== b.abs) {
            return false;
        }
    }
    return true;
};
exports.areColorsEqual = areColorsEqual;
