// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (block) {
    var migrate, migrateQuote, migrateSplash, migrateText, migrateVideo, oldBlockTypes, textContentUpdate, type;
    type = block.__t || block.type;
    if (type === "module") {
      type = block.moduleName;
    }
    if (type == null) {
      throw new Error("Got an undefined block type");
    }
    migrateVideo = function (block) {
      if (block.type === "Video") {
        return block;
      }
      if (block.embedUrl == null) {
        block.embedUrl = block.url;
      }
      block.type = "Video";
      if (block.settings == null) {
        block.settings = {};
      }
      if (_.isString(block.contentAreas.header)) {
        block.contentAreas.header = {
          content: block.contentAreas.header,
        };
      }
      return block;
    };
    migrateSplash = function (block) {
      var newMain;
      if (block.type === "Splash") {
        return block;
      }
      block.type = "Splash";
      if (_.isString(block.contentAreas.main)) {
        newMain = textContentUpdate(block.contentAreas.main);
        block.contentAreas.main = newMain;
        block.settings.withContent = true;
        block.settings.withContentBackground = true;
      }
      block.type = "Splash";
      return block;
    };
    migrateQuote = function (block) {
      if (block.type === "Quote") {
        return block;
      }
      block.type = "Quote";
      block.contentAreas.header = {
        content: block.contentAreas.header,
      };
      if (block.settings == null) {
        block.settings = {};
      }
      block.settings.displayConfig = {
        units: true,
        total: true,
      };
      return block;
    };
    textContentUpdate = function (content) {
      var finalString, i, id, ids, img, imgs, insertables, j, len, len1, reducer, results, temp, widgets, zipped;
      imgs = content.match(/<img.*/g);
      ids = [];
      if (imgs == null) {
        return {
          content: content,
        };
      }
      for (i = 0, len = imgs.length; i < len; i++) {
        img = imgs[i];
        temp = img.split("qwilr-aus/");
        if (temp.length > 1) {
          ids.push(temp[1].split('"')[0]);
        }
      }
      content = content.replace(/<\/figure>/g, "");
      content = content.replace(/<figure>/g, "");
      content = content.replace(/<\/insertable>/g, "");
      content = content.replace(/<img.*/g, "");
      insertables = [];
      widgets = {};
      for (j = 0, len1 = ids.length; j < len1; j++) {
        id = ids[j];
        insertables.push('<insertable widget-id="' + id + '"></insertable>');
        widgets[id] = {
          type: "image",
          url: "",
          imageFile: "https://s3-ap-southeast-2.amazonaws.com/qwilr-aus/" + id,
        };
      }
      results = content.split(/<insertable.*/g);
      zipped = _.zip(results, insertables);
      reducer = function (currString, currElement) {
        if (currElement[1] == null) {
          currElement[1] = "";
        }
        return currString + currElement[0] + currElement[1];
      };
      finalString = _.reduce(zipped, reducer, "");
      return {
        content: finalString,
        widgets: widgets,
      };
    };
    migrateText = function (block) {
      var content;
      block.savedBlockName = "Text";
      block.type = "Text";
      block.cssBlockName = "text-block";
      if (_.isString(block.contentAreas.content)) {
        content = textContentUpdate(block.contentAreas.content);
        block.contentAreas = {
          mainContent: content,
        };
      }
      return block;
    };
    oldBlockTypes = {
      Video: migrateVideo,
      Splash: migrateSplash,
      Quote: migrateQuote,
      content: migrateText,
    };
    migrate = oldBlockTypes[type];
    if (migrate == null) {
      migrate = function () {
        return block;
      };
    }
    return migrate(block);
  };
}.call(this));
