// Generated by CoffeeScript 1.12.7

/*
 * Used to initialise the subscription flow with the business trial
 * flag set. This means users can only start the business trial from
 * this flow
 */

(function () {
  var StartBusinessTrial,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = StartBusinessTrial = (function () {
    var selectPlanScreen, startingTrialScreen;

    function StartBusinessTrial(make, $timeout, account, apiBaseUrl, SubscriptionSequencer) {
      this.make = make;
      this.$timeout = $timeout;
      this.account = account;
      this.apiBaseUrl = apiBaseUrl;
      this.SubscriptionSequencer = SubscriptionSequencer;
      this.update = bind(this.update, this);
    }

    selectPlanScreen = require("SettingsAngular/Subscription/PlanExplorer/PlanExplorer")["default"];

    startingTrialScreen = require("SettingsAngular/Subscription/StartingTrialScreen/StartingTrialScreen");

    StartBusinessTrial.prototype.update = function () {
      var originalPlan, sendTelemetry, sequence;
      originalPlan = _.cloneDeep(this.account.plan);
      sendTelemetry = function (selectedPlan) {};
      return (sequence = this.make(this.SubscriptionSequencer, {
        steps: [selectPlanScreen, startingTrialScreen],
        allowCoupon: false,
        canEditFrequency: true,
        isNewSubscription: false,
        businessTrial: true,
        options: {
          onHitEnd: (function (_this) {
            return function (subscriptionDi) {
              subscriptionDi.invoke(sendTelemetry, _this);
              return _this.$timeout(function () {
                return window.location.reload();
              }, 2500);
            };
          })(this),
        },
      }));
    };

    return StartBusinessTrial;
  })();
}.call(this));
