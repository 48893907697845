/*
  Provides UI for updating an account's colors.

  @param options [Object] includes:
  - `includeIcon` [Boolean] whether to include an SVG icon in the header.
  - `title` [String] the title text for the widget.
  - `description` [String] the description for the widget.
 */

(function () {
  var BrandColors;

  module.exports = BrandColors = (function () {
    function BrandColors(
      options,
      createUi,
      account,
      make,
      invoke,
      telemetry,
      ColorSelector,
      ActionButton,
      $q,
      styleWorker,
      brandStore,
    ) {
      var currentColorSelector, saveColorsButton;
      if (options.includeIcon == null) {
        options.includeIcon = true;
      }
      if (options.title == null) {
        options.title = "Colors";
      }
      if (options.description == null) {
        options.description = "Choose the colors for your Qwilr pages.";
      }

      /*
        We want to allow our user to experiment with colors without actually changing the account data
        (i.e. so that public pages don't change randomly as they test different color combinations),
        so we create a cloned object of the account's brand colors data, and only copy it across
        the account model when we "save" the colors.
      
        We expose this publicly so that callers can have access to the interim color selection.
       */
      this.colors = {
        brand: account.settings.brand.colors.brand,
        complementary: account.settings.brand.colors.complementary,
      };
      saveColorsButton = make(ActionButton, {
        onClick: (function (_this) {
          return function () {
            telemetry.trackOnly(
              "Account Colors Changed",
              {},
              {
                blockIntercom: true,
              },
            );
            account.settings.brand.colors.brand = _this.colors.brand;
            account.settings.brand.colors.complementary = _this.colors.complementary;
            brandStore.refreshColors();
            return styleWorker.modify(_this.colors);
          };
        })(this),
        config: {
          standard: "Save Colors",
          waiting: "Saving Colors...",
          success: "Saved Colors",
          error: "Error Saving Colors",
        },
      });
      currentColorSelector = null;
      this.ui = createUi(require("./Colors.html"), {
        colors: this.colors,
        options: options,
        saveColorsButton: saveColorsButton.ui,
        openColorSelector: (function (_this) {
          return function (colorName, event) {
            var elem, tethering;
            if (currentColorSelector) {
              currentColorSelector.close();
            }
            elem = event.currentTarget;
            if (colorName === "brand") {
              tethering = {
                attachment: "center left",
                targetAttachment: "top right",
              };
            } else {
              tethering = {
                attachment: "center left",
                targetAttachment: "bottom right",
              };
            }
            return (currentColorSelector = make(ColorSelector, {
              currentColor: _this.colors[colorName],
              anchorElem: elem,
              tethering: tethering,
              options: {
                toggleElemSelector: ".color-selector-tile",
                onChange: function (color) {
                  return (_this.colors[colorName] = color);
                },
              },
            }));
          };
        })(this),
      });
    }

    return BrandColors;
  })();
}.call(this));
