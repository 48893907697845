"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSelectedTextColor = void 0;
var EditorMarks_1 = require("Project/QwilrEditor/Marks/EditorMarks");
var Text_1 = require("ContentTokens/Models/Text");
var GetBlockStyle_1 = require("@CommonFrontendBackend/StyleServiceV2/GetBlockStyle");
var StyleServiceV2_1 = require("@CommonFrontendBackend/StyleServiceV2/StyleServiceV2");
var getSelectedTextColor = function (editor, blockStyleStore, selectedElementTypes) {
    var selectionContainsColorFromText = (0, EditorMarks_1.getCurrentMarks)(editor, { match: "some" }).some(Text_1.isColorMark);
    if (selectionContainsColorFromText) {
        var color = (0, EditorMarks_1.getCurrentMarks)(editor).find(Text_1.isColorMark);
        return {
            color: color ? (0, GetBlockStyle_1.convertColorObjToColor)(color.data.color) : undefined,
            containsOverrides: true,
        };
    }
    else {
        var color = (0, StyleServiceV2_1.getCombinedColor)(blockStyleStore.model, selectedElementTypes);
        return {
            color: color
                ? {
                    value: color,
                    themeIndex: (0, StyleServiceV2_1.getCombinedColorThemeIndex)(blockStyleStore.model, selectedElementTypes),
                }
                : undefined,
            containsOverrides: false,
        };
    }
};
exports.getSelectedTextColor = getSelectedTextColor;
