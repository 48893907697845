// Generated by CoffeeScript 1.12.7
(function () {
  var FixedCost, Rate, Text, ref;

  (ref = require("Common/Utils/ImageImports")), (Rate = ref.Rate), (FixedCost = ref.FixedCost), (Text = ref.Text);

  module.exports = function (createUi, imageLocation, quoteService, sectionService, section) {
    return (this.ui = createUi(require("Project/Modules/Quote/Section/AddPanel/AddPanel.html"), {
      showItemsPanel: false,
      toggleItemsPanel: (function (_this) {
        return function () {
          return (_this.ui.showItemsPanel = !_this.ui.showItemsPanel);
        };
      })(this),
      addItem: (function (_this) {
        return function (type) {
          sectionService.addItem(section, type);
          return sectionService.trackAddItemTelemetry(type);
        };
      })(this),
      addSection: function () {
        return quoteService.addSectionAfter(section);
      },
      types: [
        {
          name: "Rate",
          internal: "rate",
          cssClass: "rate-item",
          icon: Rate,
        },
        {
          name: "Fixed Cost",
          internal: "fixedCost",
          cssClass: "fixed-cost-item",
          icon: FixedCost,
        },
        {
          name: "Text",
          internal: "text",
          cssClass: "text-item",
          icon: Text,
        },
      ],
    }));
  };
}.call(this));
