"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DragDataType = exports.SortBy = exports.SortDirection = void 0;
var SortDirection;
(function (SortDirection) {
    SortDirection["Ascending"] = "ascending";
    SortDirection["Descending"] = "descending";
})(SortDirection || (exports.SortDirection = SortDirection = {}));
var SortBy;
(function (SortBy) {
    SortBy["Views"] = "$totalLinkViews";
    SortBy["Name"] = "name";
    SortBy["CreatedAt"] = "createdAt";
    SortBy["LastEditedAt"] = "lastEditedAt";
    SortBy["LastViewedAt"] = "lastViewedAt";
    SortBy["Relevance"] = "score";
})(SortBy || (exports.SortBy = SortBy = {}));
var DragDataType;
(function (DragDataType) {
    DragDataType["ResourceCards"] = "ResourceCards";
    DragDataType["SidebarFolder"] = "SidebarFolder";
})(DragDataType || (exports.DragDataType = DragDataType = {}));
