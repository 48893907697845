"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isShowingErrorMessages = exports.isShowingStockImages = exports.isShowingCollections = exports.isUploadedImage = exports.isImageLibraryViewStore = exports.isImageLibraryDataStore = exports.toApiFolderId = exports.isCustomFolder = exports.isAnUploadsFolder = exports.isUnsplashFolder = exports.isRecentlyUsedImage = exports.isRecentlyUsedFolder = exports.isSystemFolderViewStoreWithSearch = exports.isSystemFolderViewStoreWithCollection = exports.isSystemFolderDataStoreWithSearch = exports.isSystemFolderDataStoreWithMove = exports.isSystemFolderDataStoreWithRename = exports.isSystemFolderDataStoreWithCollection = void 0;
var ObjectId_1 = require("Utils/ObjectId");
var Types_1 = require("Common/AssetLibrary/ImageLibrary/Types");
var Types_2 = require("Common/AssetLibrary/Types");
var isSystemFolderDataStoreWithCollection = function (store) {
    return store && "fetchCollections" in store;
};
exports.isSystemFolderDataStoreWithCollection = isSystemFolderDataStoreWithCollection;
var isSystemFolderDataStoreWithRename = function (store) {
    return store && "renameImage" in store;
};
exports.isSystemFolderDataStoreWithRename = isSystemFolderDataStoreWithRename;
var isSystemFolderDataStoreWithMove = function (store) {
    return store && "moveImages" in store;
};
exports.isSystemFolderDataStoreWithMove = isSystemFolderDataStoreWithMove;
var isSystemFolderDataStoreWithSearch = function (store) {
    return store && "searchImages" in store;
};
exports.isSystemFolderDataStoreWithSearch = isSystemFolderDataStoreWithSearch;
var isSystemFolderViewStoreWithCollection = function (store) {
    return store && "selectCollection" in store;
};
exports.isSystemFolderViewStoreWithCollection = isSystemFolderViewStoreWithCollection;
var isSystemFolderViewStoreWithSearch = function (store) {
    return store && "searchTerm" in store;
};
exports.isSystemFolderViewStoreWithSearch = isSystemFolderViewStoreWithSearch;
var isRecentlyUsedFolder = function (folder) {
    return folder === Types_1.ImageLibrarySystemFolder.RecentlyUsed;
};
exports.isRecentlyUsedFolder = isRecentlyUsedFolder;
var isRecentlyUsedImage = function (image) {
    return image && "mediaSource" in image;
};
exports.isRecentlyUsedImage = isRecentlyUsedImage;
var isUnsplashFolder = function (folder) {
    return folder === Types_1.ImageLibrarySystemFolder.Unsplash;
};
exports.isUnsplashFolder = isUnsplashFolder;
var isAnUploadsFolder = function (folder) {
    return folder === Types_1.ImageLibrarySystemFolder.Uploads || (0, exports.isCustomFolder)(folder);
};
exports.isAnUploadsFolder = isAnUploadsFolder;
var isCustomFolder = function (folder) {
    return (0, ObjectId_1.isObjectId)(folder);
};
exports.isCustomFolder = isCustomFolder;
var toApiFolderId = function (folder) {
    if (folder === Types_1.ImageLibrarySystemFolder.Uploads) {
        return null;
    }
    else if ((0, exports.isCustomFolder)(folder)) {
        return folder;
    }
    else {
        throw new Error("Unexpected folder id: ".concat(folder));
    }
};
exports.toApiFolderId = toApiFolderId;
var isImageLibraryDataStore = function (dataStore) {
    return (dataStore === null || dataStore === void 0 ? void 0 : dataStore.type) === Types_2.AssetType.Image;
};
exports.isImageLibraryDataStore = isImageLibraryDataStore;
var isImageLibraryViewStore = function (viewStore) {
    return (viewStore === null || viewStore === void 0 ? void 0 : viewStore.type) === Types_2.AssetType.Image;
};
exports.isImageLibraryViewStore = isImageLibraryViewStore;
var isUploadedImage = function (image) {
    return image && "name" in image;
};
exports.isUploadedImage = isUploadedImage;
var isShowingCollections = function (viewStore) {
    return viewStore.currentPanelView === Types_1.ImageLibraryViewTypes.Collections;
};
exports.isShowingCollections = isShowingCollections;
var isShowingStockImages = function (viewStore) {
    return viewStore.currentPanelView === Types_1.ImageLibraryViewTypes.StockImages;
};
exports.isShowingStockImages = isShowingStockImages;
var isShowingErrorMessages = function (viewStore) {
    return viewStore.currentPanelView === Types_1.ImageLibraryViewTypes.ErrorMessages;
};
exports.isShowingErrorMessages = isShowingErrorMessages;
