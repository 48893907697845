"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var PersistenceTracker = (function () {
    function PersistenceTracker(savingIndicator, notifyUser) {
        this.savingIndicator = savingIndicator;
        this.notifyUser = notifyUser;
        this.persistStates = new Map();
    }
    PersistenceTracker.prototype.saveStarted = function (persistObject) {
        if (!this.persistStates.has(persistObject)) {
            this.persistStates.set(persistObject, {
                isSaving: true,
                hasError: false,
            });
        }
        else {
            var persistState = this.persistStates.get(persistObject);
            if (persistState != undefined) {
                persistState.isSaving = true;
            }
        }
        this.savingIndicator.saving();
    };
    PersistenceTracker.prototype.saveDone = function (persistObject, customMessage) {
        var persistState = this.persistStates.get(persistObject);
        if (persistState) {
            persistState.isSaving = false;
            persistState.hasError = false;
            this.maybeHideIndicator();
            if (customMessage) {
                this.notifyUser.success(customMessage);
            }
        }
    };
    PersistenceTracker.prototype.warning = function (persistObject, message) {
        this.notifyUser.warn(message);
    };
    PersistenceTracker.prototype.saveErrored = function (persistObject, customErrorMessage) {
        var persistState = this.persistStates.get(persistObject);
        if (persistState) {
            persistState.hasError = true;
            this.notifyUser.error(customErrorMessage || "Sorry, an unexpected error occurred. Please try again.");
        }
    };
    PersistenceTracker.prototype.persistDestroyed = function (persistObject) {
        this.persistStates.delete(persistObject);
        this.maybeHideIndicator();
    };
    PersistenceTracker.prototype.getSavesInProgress = function () {
        var inProgress = [];
        this.persistStates.forEach(function (value, key) {
            if (value.isSaving) {
                inProgress.push(key);
            }
        });
        return inProgress;
    };
    PersistenceTracker.prototype.getErrors = function () {
        var persistErrors = [];
        this.persistStates.forEach(function (value, key) {
            if (value.hasError) {
                persistErrors.push(key);
            }
        });
        return persistErrors;
    };
    PersistenceTracker.prototype.maybeHideIndicator = function () {
        var e_1, _a;
        try {
            for (var _b = __values(this.persistStates.values()), _c = _b.next(); !_c.done; _c = _b.next()) {
                var persistState = _c.value;
                if (persistState.isSaving) {
                    return;
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
            }
            finally { if (e_1) throw e_1.error; }
        }
        this.savingIndicator.done();
    };
    return PersistenceTracker;
}());
exports.default = PersistenceTracker;
