"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentResolveOutcome = exports.CommentSaveOutcome = void 0;
var CommentSaveOutcome;
(function (CommentSaveOutcome) {
    CommentSaveOutcome["None"] = "none";
    CommentSaveOutcome["Success"] = "success";
    CommentSaveOutcome["NetworkError"] = "networkerror";
    CommentSaveOutcome["ThreadDeleted"] = "threaddeleted";
    CommentSaveOutcome["CommentDeleted"] = "commentdeleted";
    CommentSaveOutcome["LoggedOut"] = "loggedout";
    CommentSaveOutcome["UnknownFailure"] = "unknownfailure";
})(CommentSaveOutcome || (exports.CommentSaveOutcome = CommentSaveOutcome = {}));
var CommentResolveOutcome;
(function (CommentResolveOutcome) {
    CommentResolveOutcome["None"] = "none";
    CommentResolveOutcome["Success"] = "success";
    CommentResolveOutcome["NetworkError"] = "networkerror";
    CommentResolveOutcome["ThreadDeleted"] = "threaddeleted";
    CommentResolveOutcome["LoggedOut"] = "loggedout";
    CommentResolveOutcome["UnknownFailure"] = "unknownfailure";
})(CommentResolveOutcome || (exports.CommentResolveOutcome = CommentResolveOutcome = {}));
