"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectOpener = void 0;
var Types_1 = require("@CommonFrontendBackend/Projects/Types");
var ProjectOpener = (function () {
    function ProjectOpener($state) {
        var _this = this;
        this.$state = $state;
        this.open = function (project, origin, tour) {
            var stateParams = { id: project._id, origin: origin, tour: tour };
            if (project.status === Types_1.ProjectStatus.Accepted) {
                _this.$state.go("projectView", stateParams);
            }
            else if (project.status === Types_1.ProjectStatus.Declined) {
                _this.$state.go("projectView", stateParams);
            }
            else {
                _this.$state.go("project", stateParams);
            }
        };
    }
    return ProjectOpener;
}());
exports.ProjectOpener = ProjectOpener;
