const { FeatureFlag } = require("@CommonFrontendBackend/FeatureFlag");

(function () {
  var SavingIndicator,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = SavingIndicator = (function () {
    function SavingIndicator(createUi, make, $timeout, notifyUser, domFrame, usingFeature) {
      this.$timeout = $timeout;
      this.notifyUser = notifyUser;
      this.done = bind(this.done, this);
      this.saving = bind(this.saving, this);
      this.ui = createUi(require("./SavingIndicator.html"), {
        saving: false,
        visible: false,
        disable: usingFeature(FeatureFlag.DisableEditorSavingIndicator),
      });
      domFrame.add(this.ui);
    }

    SavingIndicator.prototype.SHOW_SAVING_MSG_FOR = 800;

    SavingIndicator.prototype.saving = function () {
      this.ui.visible = true;
      this.ui.saving = true;
      return (this.lastSaveCall = Date.now());
    };

    SavingIndicator.prototype.done = function () {
      var throttledDone;
      throttledDone = _.throttle(
        (function (_this) {
          return function () {};
        })(this),
        this.$timeout(
          (function (_this) {
            return function () {
              return (_this.ui.saving = false);
            };
          })(this),
          this.SHOW_SAVING_MSG_FOR,
        ),
        this.$timeout(
          (function (_this) {
            return function () {
              return (_this.ui.visible = false);
            };
          })(this),
          this.SHOW_SAVING_MSG_FOR * 3,
          500,
        ),
      );
      return throttledDone();
    };

    SavingIndicator.prototype.lastSaveCall = Date.now();

    return SavingIndicator;
  })();
}.call(this));
