// Generated by CoffeeScript 1.12.7

/*
  The bar at the top of the MediaBrowser when browsing images
 */

(function () {
  var VideoTopBar;

  module.exports = VideoTopBar = (function () {
    function VideoTopBar(changeViewFunction, createUi) {
      this.setSelectedView = (function (_this) {
        return function (viewName) {
          return (_this.ui.view = viewName);
        };
      })(this);
      this.ui = createUi(require("./VideoTopBar.html"), {
        changeView: (function (_this) {
          return function (viewName) {
            _this.ui.view = viewName;
            return changeViewFunction(viewName);
          };
        })(this),
      });
    }

    return VideoTopBar;
  })();
}.call(this));
