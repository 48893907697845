import "Application/Project/ContentSidebar/Explore/SharedComponents/DraggableThumbnail.css.ts.vanilla.css!=!../../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Project/ContentSidebar/Explore/SharedComponents/DraggableThumbnail.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61U227bMAx9z1dwDwNioEqtdk075TP2AYVs044yWfIo2nE29N8HKWlitxk6DIMfDIm3w3NIrZ7lj6KjepDwawHQ+WDYeKeA0Go2A24WADp0WLIgzcYrkGu4BZlHgx+Qauv3CgYTTGGTd6HL7w353lUKBk1LIUpvPYnQU61LfH6WhX3c7WSTJW9PFZIgXZk+KFh342bxsli9wrr7T7AmKe9TysqEzuqDgoZMFYPiXzC2ndWMEXLfuqBA5vnn92by+4lNW9M4YRjboCAwIZfbzQy2LoK3PSfYxgVkBfmx+1GEra4i1jx9shtnrB35OZMm8ww+mbbzxNrxnwgEYNLutfgxXdUnopxoL8mG7GREHYxrRK0D+56/Wb837uJG2Tyl6Mh3SHxQkwZujg61p/YGfKdLw4cYVvYUPEWedTHXQW2jUEmNc6iCUGqLS7nK77O3DB2xHk8CLQ6pIXkBWr0l56cwrsJRgZyV/nJtBCb2h2SfBp8GQBNqBTJO2ixgfSXgnL62mDQ5kfIq/XlNjkoroKZYyie4y+H+AW4hXz1k1wvP5T2lfStzuNDSZ4A64LtRLdExUrze9YFNfRCld4yOp6YPFnQ9UfHc4Jycx2S8Pqcfr4gvdnHLaxNRxVLxcoum2fJlA/em4u3l2HkT4Qsc0HFQ4LybvwFPCdI/ZZk/cNY41CSa2BY6XiZhOk3o+GYmaPY3r93XBKvV1Jjz3oZIqmvEOI4TSU22Wbz8BmFIb6S9BQAA\"}!../../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var imageSrc = 'var(--_1qbprfv0)';
export var thumbnailBorderRadius = '6px';
export var dragContainer = '_1qbprfv1';
export var thumbnail = '_1qbprfv2';
export var button = '_1qbprfv3';
export var layeredStack = '_1qbprfv4';
export var layerChildren = '_1qbprfv5';
export var innerButton = '_1qbprfv6';
export var thumbnailImage = '_1qbprfv7';
export var thumbnailMetadata = '_1qbprfv8';
export var iconSpacing = '_1qbprfv9';