(function () {
  var QuoteService,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = QuoteService = (function () {
    function QuoteService(block, invoke, sectionService, $timeout, projectScrollTo, undoNotifier, telemetry) {
      this.sectionService = sectionService;
      this.$timeout = $timeout;
      this.projectScrollTo = projectScrollTo;
      this.undoNotifier = undoNotifier;
      this.telemetry = telemetry;
      this.hasSections = bind(this.hasSections, this);
      this.hasItems = bind(this.hasItems, this);
      this.move = bind(this.move, this);
      this.moveSectionDown = bind(this.moveSectionDown, this);
      this.moveSectionUp = bind(this.moveSectionUp, this);
      this.deleteSection = bind(this.deleteSection, this);
      this.addSectionAfter = bind(this.addSectionAfter, this);
      this.insertSection = bind(this.insertSection, this);
      this.addNewSection = bind(this.addNewSection, this);
      this.quote = block;
      this.getTotal = invoke(require("Quote/Calculator/Total"));
    }

    /*
      Adds a new section to a quote.
      @param atIndex [Integer] the index to insert the section at.
      @param name [String] (optional) a name for the section.
      @param items [Array] (optional) an array of items for the section.
     */

    QuoteService.prototype.addNewSection = function (atIndex, name, items) {
      var section;
      if (name == null) {
        name = "";
      }
      if (items == null) {
        items = [];
      }
      this.insertSection(atIndex, {
        name: name,
        items: items,
      });
      if (!items.length) {
        section = this.quote.sections[atIndex];
        return this.sectionService.addItem(section);
      }
    };

    /*
      Insert a section object at a given index.
      @param atIndex [Integer] the index to insert the section at.
      @param sectionObj [Object] the section object to insert.
     */

    QuoteService.prototype.insertSection = function (atIndex, sectionObj) {
      if (atIndex == null) {
        atIndex = this.quote.sections.length - 1;
      }
      return this.quote.sections.splice(atIndex, 0, sectionObj);
    };

    /*
      Adds a new section after a given a section.
      @param section [Object] the section AFTER which you want to add a new section.
      @param name [String] (optional) a name for the new section.
      @param items [Array] (optional) an array of items for the new section.
     */

    QuoteService.prototype.addSectionAfter = function (section, name, items) {
      var index;
      if (name == null) {
        name = "";
      }
      index = this.sectionService.findSectionIndex(section) + 1;
      this.addNewSection(index, name, items);
      return this.telemetry.trackOnly("Quote Block Section Added");
    };

    /*
      Remove a section from a quote.
      @param section [Object] the section you want to remove.
     */

    QuoteService.prototype.deleteSection = function (section) {
      var index;
      index = this.sectionService.findSectionIndex(section);
      this.quote.sections.splice(index, 1);
      this.telemetry.trackOnly(
        "Quote Block Section Deleted",
        {},
        {
          blockIntercom: true,
        },
      );
      return this.undoNotifier.show(
        "Undo delete section",
        (function (_this) {
          return function () {
            return _this.insertSection(index, section);
          };
        })(this),
      );
    };

    /*
      Move a section a section up in a quote.
      @param section [Object] the section to be moved up.
     */

    QuoteService.prototype.moveSectionUp = function (section) {
      var index;
      if (this.quote.sections.length === 1) {
        return;
      }
      index = this.sectionService.findSectionIndex(section);
      this.move(section, index, index - 1);
      return this.telemetry.trackOnly(
        "Quote Block Section Moved",
        {},
        {
          blockIntercom: true,
        },
      );
    };

    /*
      Move a section a section down in a quote.
      @param section [Object] the section to be moved down.
     */

    QuoteService.prototype.moveSectionDown = function (section) {
      var index;
      index = this.sectionService.findSectionIndex(section);
      if (this.quote.sections.length - 1 === index) {
        return;
      }
      this.move(section, index, index + 1);
      return this.telemetry.trackOnly(
        "Quote Block Section Moved",
        {},
        {
          blockIntercom: true,
        },
      );
    };

    /*
      Move a section to a given index in the sections array.
      @param section [Object] the section to be moved.
      @param index [Integer] the current index of the section to be moved.
      @param moveTo [Integer] the index to move the section to.
     */

    QuoteService.prototype.move = function (section, index, moveTo) {
      this.quote.sections.splice(index, 1);
      this.quote.sections.splice(moveTo, 0, section);
      return this.projectScrollTo($("#quote-section-" + section.$id));
    };

    /*
      Determine if a quote has any items.
     */

    QuoteService.prototype.hasItems = function () {
      var hasItems, i, len, ref, section;
      hasItems = false;
      ref = this.quote.sections;
      for (i = 0, len = ref.length; i < len; i++) {
        section = ref[i];
        if (section.items.length) {
          hasItems = true;
          break;
        }
      }
      return hasItems;
    };

    /*
      Determine if a quote has any sections.
     */

    QuoteService.prototype.hasSections = function () {
      return this.quote.sections.length;
    };

    return QuoteService;
  })();
}.call(this));
