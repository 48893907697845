"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.moveToSiblingWidgetFromSelection = exports.removeWidgetsAtSelection = exports.newParagraphBelowWidgetAtSelection = void 0;
var slate_1 = require("slate");
var Utils_1 = require("../Utils");
var EditorWidgetRegistry_1 = require("../WidgetRegistry/EditorWidgetRegistry");
var Paragraph_1 = require("../WidgetRegistry/Widgets/Paragraph");
var lodash_1 = require("lodash");
var newParagraphBelowWidgetAtSelection = function (selection) {
    var orderedSelection = __spreadArray([], __read(selection), false).sort(function (a, b) {
        return slate_1.Path.compare(a.path, b.path);
    });
    var lastSelection = (0, lodash_1.last)(orderedSelection);
    if (!lastSelection)
        return;
    var editor = lastSelection.editor, path = lastSelection.path;
    var paragraphLocation = slate_1.Path.next(path);
    slate_1.Transforms.insertNodes(editor, (0, Paragraph_1.createParagraphNode)(), { at: paragraphLocation });
    var textLocation = __spreadArray(__spreadArray([], __read(paragraphLocation), false), [0], false);
    slate_1.Transforms.select(editor, { path: textLocation, offset: 0 });
};
exports.newParagraphBelowWidgetAtSelection = newParagraphBelowWidgetAtSelection;
var removeWidgetsAtSelection = function (selection, direction) {
    var e_1, _a;
    var orderedSelection = __spreadArray([], __read(selection), false).sort(function (a, b) {
        return slate_1.Path.compare(a.path, b.path);
    });
    if (direction === "backward") {
        var firstNode = orderedSelection[0];
        if (!firstNode)
            return;
        var editor = firstNode.editor, path = firstNode.path;
        var previousTextNode = slate_1.Editor.previous(editor, {
            at: path,
            mode: "all",
            voids: true,
            match: slate_1.Text.isText,
        });
        if (previousTextNode) {
            slate_1.Transforms.select(editor, slate_1.Editor.end(editor, previousTextNode[1]));
        }
        else {
            slate_1.Transforms.select(editor, slate_1.Editor.start(editor, []));
        }
    }
    else if (direction === "forward") {
        var lastNode = (0, lodash_1.last)(orderedSelection);
        if (!lastNode)
            return;
        var editor = lastNode.editor, path = lastNode.path;
        var nextTextNode = slate_1.Editor.next(editor, {
            at: path,
            mode: "all",
            voids: true,
            match: slate_1.Text.isText,
        });
        if (nextTextNode) {
            slate_1.Transforms.select(editor, slate_1.Editor.start(editor, nextTextNode[1]));
        }
        else {
            slate_1.Transforms.select(editor, slate_1.Editor.end(editor, []));
        }
    }
    var itemsToDelete = orderedSelection.map(function (item) { return ({
        editor: item.editor,
        path: slate_1.Editor.pathRef(item.editor, item.path),
    }); });
    try {
        for (var itemsToDelete_1 = __values(itemsToDelete), itemsToDelete_1_1 = itemsToDelete_1.next(); !itemsToDelete_1_1.done; itemsToDelete_1_1 = itemsToDelete_1.next()) {
            var item = itemsToDelete_1_1.value;
            var path = item.path.unref();
            if (!path)
                continue;
            slate_1.Transforms.removeNodes(item.editor, { at: path });
        }
    }
    catch (e_1_1) { e_1 = { error: e_1_1 }; }
    finally {
        try {
            if (itemsToDelete_1_1 && !itemsToDelete_1_1.done && (_a = itemsToDelete_1.return)) _a.call(itemsToDelete_1);
        }
        finally { if (e_1) throw e_1.error; }
    }
};
exports.removeWidgetsAtSelection = removeWidgetsAtSelection;
var moveToSiblingWidgetFromSelection = function (selection, direction) {
    var orderedSelection = __spreadArray([], __read(selection), false).sort(function (a, b) {
        return slate_1.Path.compare(a.path, b.path);
    });
    var pivotSelection;
    switch (direction) {
        case "forwards":
            pivotSelection = (0, lodash_1.last)(orderedSelection);
            break;
        case "backwards":
            pivotSelection = (0, lodash_1.first)(orderedSelection);
            break;
    }
    if (!pivotSelection) {
        return;
    }
    var editor = pivotSelection.editor, path = pivotSelection.path;
    var selectedNodeEntry = slate_1.Editor.node(editor, path);
    if (!selectedNodeEntry) {
        return;
    }
    var _a = __read(selectedNodeEntry, 1), selectedNode = _a[0];
    if (!slate_1.Element.isElement(selectedNode)) {
        return;
    }
    var config = (0, EditorWidgetRegistry_1.getGenericConfigByType)(selectedNode.type);
    if (!config) {
        throw new Error("Cannot move to sibling widget on a non-widget-registry node");
    }
    if (config.nodeType === "layoutElement") {
        if (direction === "forwards") {
            var nextTextNode = Array.from(slate_1.Editor.nodes(editor, { at: path, match: slate_1.Text.isText, voids: true }))[0];
            if (!nextTextNode) {
                return;
            }
            var beginningOfNode = slate_1.Editor.start(editor, nextTextNode[1]);
            slate_1.Transforms.select(editor, beginningOfNode);
        }
        else {
            var prevTextNode = (0, Utils_1.getPrevious)(editor, path, slate_1.Text.isText);
            if (!prevTextNode) {
                return;
            }
            var endOfNode = slate_1.Editor.end(editor, prevTextNode[1]);
            slate_1.Transforms.select(editor, endOfNode);
        }
    }
    else {
        slate_1.Transforms.select(editor, path);
        slate_1.Transforms.move(editor, { unit: "line", reverse: direction === "backwards" });
    }
};
exports.moveToSiblingWidgetFromSelection = moveToSiblingWidgetFromSelection;
