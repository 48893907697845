"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useIsFirstRender = void 0;
var react_1 = require("react");
var useIsFirstRender = function () {
    var isFirstRender = (0, react_1.useRef)(true);
    (0, react_1.useEffect)(function () {
        isFirstRender.current = false;
    }, []);
    return isFirstRender.current;
};
exports.useIsFirstRender = useIsFirstRender;
