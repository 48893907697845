"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getIsTopDropzoneFromContainerDrag = void 0;
var getIsTopDropzoneFromContainerDrag = function (event) {
    var container = event.currentTarget, clientY = event.clientY;
    var containerDimensions = container.getBoundingClientRect();
    var containerTop = containerDimensions.top, containerHeight = containerDimensions.height;
    var currentYInContainer = clientY - containerTop;
    var isTopDropzone = currentYInContainer / containerHeight < 0.5;
    return isTopDropzone;
};
exports.getIsTopDropzoneFromContainerDrag = getIsTopDropzoneFromContainerDrag;
