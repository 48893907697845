"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapAutomationError = void 0;
var zod_1 = require("zod");
var knownErrorsScema = zod_1.z.object({
    error: zod_1.z.object({
        message: zod_1.z.literal("ASSOCIATION_LIMIT_REACHED"),
        additionalInfo: zod_1.z.object({
            resourceIds: zod_1.z.array(zod_1.z.string()),
        }),
    }),
});
var apiErrorMessageToUiMap = {
    ASSOCIATION_LIMIT_REACHED: "One or more templates have reached the maximum number of automations.",
};
var apiErrorMessageToFieldMap = {
    ASSOCIATION_LIMIT_REACHED: "associations",
};
var defaultErrorMessage = "There was an error saving your automation";
var isAxiosError = function (error) {
    return !!(error === null || error === void 0 ? void 0 : error.isAxiosError);
};
var mapAutomationError = function (error) {
    var _a, _b;
    if (isAxiosError(error)) {
        var parsedError = knownErrorsScema.safeParse((_a = error.response) === null || _a === void 0 ? void 0 : _a.data);
        if (parsedError.success) {
            return {
                message: (_b = apiErrorMessageToUiMap[parsedError.data.error.message]) !== null && _b !== void 0 ? _b : defaultErrorMessage,
                field: apiErrorMessageToFieldMap[parsedError.data.error.message],
                templateIds: parsedError.data.error.additionalInfo.resourceIds,
            };
        }
    }
    return {
        message: defaultErrorMessage,
        templateIds: [],
    };
};
exports.mapAutomationError = mapAutomationError;
