"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.preventHandledEvent = void 0;
var preventHandledEvent = function (onEvent) { return function (event) {
    var wasHandled = onEvent(event);
    if (wasHandled) {
        event.preventDefault();
    }
}; };
exports.preventHandledEvent = preventHandledEvent;
