"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AbstractSystemFolderViewStore = void 0;
var mobx_1 = require("mobx");
var Types_1 = require("Common/AssetLibrary/ImageLibrary/Types");
var AbstractSystemFolderViewStore = (function () {
    function AbstractSystemFolderViewStore() {
        this.imagePageIndex = 0;
        this.isLoading = false;
    }
    Object.defineProperty(AbstractSystemFolderViewStore.prototype, "isLoadingOn", {
        get: function () {
            return this.isLoading;
        },
        set: function (newState) {
            this.isLoading = newState;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AbstractSystemFolderViewStore.prototype, "currentImagePage", {
        get: function () {
            return this.imagePageIndex;
        },
        set: function (newValue) {
            this.imagePageIndex = newValue;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AbstractSystemFolderViewStore.prototype, "currentPanelView", {
        get: function () {
            return this.currentView;
        },
        set: function (newView) {
            this.currentView = newView;
        },
        enumerable: false,
        configurable: true
    });
    AbstractSystemFolderViewStore.prototype.useImage = function (image, useImageCallback) {
        return;
    };
    AbstractSystemFolderViewStore.prototype.resetAll = function () {
        this.imagePageIndex = 0;
    };
    var _a, _b, _c;
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], AbstractSystemFolderViewStore.prototype, "imagePageIndex", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Object)
    ], AbstractSystemFolderViewStore.prototype, "isLoading", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", typeof (_a = typeof Types_1.ImageLibraryViewTypes !== "undefined" && Types_1.ImageLibraryViewTypes) === "function" ? _a : Object)
    ], AbstractSystemFolderViewStore.prototype, "currentView", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [Boolean])
    ], AbstractSystemFolderViewStore.prototype, "isLoadingOn", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [Number])
    ], AbstractSystemFolderViewStore.prototype, "currentImagePage", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_b = typeof Types_1.ImageLibraryViewTypes !== "undefined" && Types_1.ImageLibraryViewTypes) === "function" ? _b : Object),
        __metadata("design:paramtypes", [typeof (_c = typeof Types_1.ImageLibraryViewTypes !== "undefined" && Types_1.ImageLibraryViewTypes) === "function" ? _c : Object])
    ], AbstractSystemFolderViewStore.prototype, "currentPanelView", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", []),
        __metadata("design:returntype", void 0)
    ], AbstractSystemFolderViewStore.prototype, "resetAll", null);
    return AbstractSystemFolderViewStore;
}());
exports.AbstractSystemFolderViewStore = AbstractSystemFolderViewStore;
