"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SelectModal_1 = require("./SelectModal");
var TeamSelectBar = (function () {
    function TeamSelectBar(createUi, hasUsersSelected, showDecideModal, showDeleteModal, userTeamsManager, invoke) {
        this._ui = createUi(require("./SelectBar.html"), {
            hasUsersSelected: function () {
                return hasUsersSelected();
            },
            widgetVisible: function () {
                return true;
            },
            deselectUsers: function () {
                userTeamsManager.deselectUsers();
            },
            selectTeams: function () {
                return invoke(SelectModal_1.selectTeams, {
                    createUi: createUi,
                    showDecideModal: showDecideModal,
                    userTeamsManager: userTeamsManager,
                });
            },
            removeFromTeams: function () {
                return (0, SelectModal_1.removeFromTeams)(createUi, showDeleteModal, userTeamsManager);
            },
        });
    }
    Object.defineProperty(TeamSelectBar.prototype, "ui", {
        get: function () {
            return this._ui;
        },
        enumerable: false,
        configurable: true
    });
    return TeamSelectBar;
}());
exports.default = TeamSelectBar;
