// Generated by CoffeeScript 1.12.7
(function () {
  var ReplaceBlock;

  ReplaceBlock = require("./ReplaceBlock");

  /*
    This module defines the DI wiring for the Browser module.
   */

  module.exports = {
    replaceBlock: ReplaceBlock["default"],
    getBlockTypeIcon: function (invoke) {
      return invoke(require("./GetBlockTypeIcon"));
    },
    resetBrowserStateOnPrecedingBlock: require("./resetBrowserStateOnPrecedingBlock.js"),
  };
}.call(this));
