// Generated by CoffeeScript 1.12.7
(function () {
  var StyleEditorController,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = StyleEditorController = (function () {
    function StyleEditorController(
      createUi,
      make,
      blockUtils,
      style,
      notifyUser,
      styleWorker,
      refreshSwatchStyles,
      viewState,
      account,
      block,
    ) {
      var CodeEditorController, buildRawStyles, checkValidity, element, fn, i, len, lessCompiler, ref;
      this.refreshSwatchStyles = refreshSwatchStyles;
      this.viewState = viewState;
      this.block = block;
      this.hide = bind(this.hide, this);
      this.addIdTag = bind(this.addIdTag, this);
      this.show = bind(this.show, this);
      this.DOM_ID = "style-creator-" + this.block._id;
      lessCompiler = make(require("Common/StyleWorker/LESSCompiler.js"));
      CodeEditorController = require("Common/CodeEditor/CodeEditor.js");
      buildRawStyles = (function (_this) {
        return function (precedeWith) {
          var brandVariables, i, len, ref, section, styles;
          brandVariables = function () {
            var brandColor, complementaryColor;
            brandColor = "@brand:" + account.settings.brand.colors.brand + ";";
            complementaryColor = "@complementary:" + account.settings.brand.colors.complementary + ";";
            return brandColor + complementaryColor;
          };
          styles = brandVariables();
          ref = _this.ui.style.styleModel;
          for (i = 0, len = ref.length; i < len; i++) {
            section = ref[i];
            if (section.element.length) {
              styles += section.element + " {" + section.rawStyles + "} ";
            } else {
              styles += section.rawStyles;
            }
          }
          if (precedeWith != null) {
            styles = precedeWith + " {" + styles + "}";
          }
          return styles;
        };
      })(this);
      ref = style.styleModel;
      fn = (function (_this) {
        return function (element) {
          return (element.$editor = make(CodeEditorController, {
            model: element.rawStyles,
            language: "css",
            onChange: function (rawStyles) {
              var item, persistStyles, previewStyles;
              item = _.find(_this.ui.style.styleModel, {
                name: element.name,
              });
              item.rawStyles = rawStyles;
              previewStyles = buildRawStyles("#" + _this.DOM_ID);
              lessCompiler.render(previewStyles).then(function (css) {
                if (css == null) {
                  return;
                }
                return styleWorker.addToHead(css, _this.DOM_ID);
              });
              persistStyles = buildRawStyles(".project-block." + _this.ui.style.className);
              return lessCompiler.render(persistStyles).then(function (renderedCss) {
                return (_this.ui.style.css = renderedCss);
              });
            },
          }));
        };
      })(this);
      for (i = 0, len = ref.length; i < len; i++) {
        element = ref[i];
        element.$open = false;
        fn(element);
      }
      checkValidity = make(require("./CheckValid.js"));
      this.ui = createUi(require("./StyleEditor.html"), {
        visible: false,
        hide: this.hide,
        style: style,
        cancel: (function (_this) {
          return function () {
            _.remove(account.styles, {
              $newTemporaryStyle: true,
            });
            return _this.hide();
          };
        })(this),
        makeCssName: (function (_this) {
          return function (name) {
            var cssClassName;
            cssClassName = blockUtils.makeSafeForCss(name);
            _this.ui.style.className = cssClassName;
            return (_this.block.settings.style = cssClassName);
          };
        })(this),
        saveStyle: (function (_this) {
          return function () {
            var styles;
            if (!checkValidity.check(_this.ui.style)) {
              return;
            }
            styles = buildRawStyles(".project-block." + _this.ui.style.className);
            return lessCompiler.render(styles).then(function (renderedCss) {
              if (renderedCss == null) {
                return;
              }
              _this.ui.style.css = renderedCss;
              delete _this.ui.style.$newTemporaryStyle;
              styleWorker.addToHead(renderedCss, _this.ui.style.className);
              $("head #" + _this.DOM_ID).remove();
              _this.block.settings.style = _this.ui.style.className;
              _this.hide();
              return notifyUser.success("Style saved");
            });
          };
        })(this),
      });
    }

    StyleEditorController.prototype.show = function () {
      this.viewState.showStyleCreator = true;
      this.addIdTag();
      this.ui.visible = true;
      return this;
    };

    StyleEditorController.prototype.addIdTag = function () {
      var element;
      element = this.block.$ctrl.ui.blockElement;
      return $(element).attr("id", this.DOM_ID);
    };

    StyleEditorController.prototype.hide = function () {
      this.refreshSwatchStyles();
      this.viewState.showStyleCreator = false;
      return (this.ui.visible = false);
    };

    return StyleEditorController;
  })();
}.call(this));
