"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var CurrencySetter = (function () {
    function CurrencySetter(project, projectCurrencies, payNowDisabler, $http, apiBaseUrl) {
        this.project = project;
        this.projectCurrencies = projectCurrencies;
        this.payNowDisabler = payNowDisabler;
        this.$http = $http;
        this.apiBaseUrl = apiBaseUrl;
    }
    CurrencySetter.prototype.currenciesChanged = function () {
        this.updateProjectCurrencies();
        return this.payNowDisabler.recalculatePayNowState();
    };
    CurrencySetter.prototype.setCurrency = function (block, currencyCode) {
        var e_1, _a;
        if (this.projectCurrencies.allowingMultipleCurrencies()) {
            this.setBlockCurrency(block, currencyCode);
        }
        else {
            try {
                for (var _b = __values(this.project.blocks), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var projectBlock = _c.value;
                    if (projectBlock.type === "QuoteV2") {
                        this.setBlockCurrency(projectBlock, currencyCode);
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            this.project.currency = currencyCode;
            this.$http.post("".concat(this.apiBaseUrl, "/projects/").concat(this.project._id, "/currency"), { currency: currencyCode });
        }
        return this.currenciesChanged();
    };
    CurrencySetter.prototype.updateProjectCurrencies = function () {
        var currencies = this.projectCurrencies.getProjectCurrencies();
        if (!(0, lodash_1.isEqual)(this.project.currencies, currencies)) {
            this.project.currencies = currencies;
            this.$http.post("".concat(this.apiBaseUrl, "/projects/").concat(this.project._id), { currencies: currencies });
        }
    };
    CurrencySetter.prototype.setBlockCurrency = function (block, currencyCode) {
        block.settings.currency = currencyCode;
    };
    return CurrencySetter;
}());
exports.default = CurrencySetter;
