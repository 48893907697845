// Generated by CoffeeScript 1.12.7
(function () {
  var PipedriveService, PipedriveSettingsMenu, SettingsTemplate, ToggleWithLabel;

  SettingsTemplate = require("./PipedriveSettingsMenu.html");

  ToggleWithLabel = require("../../../QuoteV2/SettingsMenus/ToggleWithLabel.js");

  PipedriveService = require("./PipedriveService");

  /*
    Superpower menu for configuring Pipedrive settings for an Accept block
    Based off the Pipedrive settings section of the legacy quote block:
    `Client/Application/Project/Modules/Quote/Accept/Pipedrive/Pipedrive.js`
   */

  module.exports = PipedriveSettingsMenu = (function () {
    function PipedriveSettingsMenu(createUi, make, $http, $timeout, apiBaseUrl, simpleDropdownFactory) {
      var dealToDropdown,
        deals,
        init,
        loadDealsAndStages,
        loadPipelines,
        onDealSelect,
        onPipelineSelect,
        onStageSelect,
        pipedriveService,
        pipedriveToggle,
        pipelineToDropdown,
        pipelines,
        selectedPipelineId,
        setEnabled,
        stageToDropdown,
        stages;
      pipedriveService = make(PipedriveService);
      this.ui = null;
      pipedriveToggle = null;
      pipelines = [];
      deals = [];
      stages = [];
      selectedPipelineId = null;
      init = (function (_this) {
        return function () {
          pipedriveToggle = make(ToggleWithLabel, {
            description: "Enable",
            getter: pipedriveService.isEnabled,
            setter: setEnabled,
          });
          _this.ui = createUi(SettingsTemplate, {
            pipedriveToggle: pipedriveToggle.ui,
            pipelineDropdown: null,
            dealsDropdown: null,
            stagesDropdown: null,
            isEnabled: function () {
              return pipedriveService.isEnabled();
            },
            pipelinesLoaded: function () {
              return _this.ui.pipelineDropdown != null;
            },
            dealsAndStagesLoaded: function () {
              return _this.ui.dealsDropdown != null;
            },
          });
          if (pipedriveService.isEnabled()) {
            return loadPipelines();
          }
        };
      })(this);
      setEnabled = (function (_this) {
        return function (enabled) {
          pipedriveService.setEnabled(enabled);
          if (enabled) {
            return loadPipelines();
          } else {
            _this.ui.pipelineDropdown = null;
            _this.ui.dealsDropdown = null;
            return (_this.ui.stagesDropdown = null);
          }
        };
      })(this);

      /*
        'On select' functions for when an option is chosen from the dropdowns
       */
      onPipelineSelect = (function (_this) {
        return function (selectedPipeline) {
          selectedPipelineId = selectedPipeline.id;
          pipedriveService.setPipeline(
            _.find(pipelines, {
              id: selectedPipeline.id,
            }),
          );
          return loadDealsAndStages(selectedPipeline.id);
        };
      })(this);
      onDealSelect = function (selectedDeal) {
        return pipedriveService.setDeal(
          _.find(deals, {
            id: selectedDeal.id,
          }),
        );
      };
      onStageSelect = function (selectedStage) {
        return pipedriveService.setStage(
          _.find(stages, {
            id: selectedStage.id,
          }),
        );
      };

      /*
        Fetch the Pipelines, and load them into memory and into the dropdown
       */
      loadPipelines = (function (_this) {
        return function () {
          return pipedriveService.getPipelines().then(function (result) {
            var dropdownItems, selectedPipeline;
            pipelines = result;
            dropdownItems = _.map(pipelines, pipelineToDropdown);
            selectedPipeline = pipelineToDropdown(pipedriveService.getCurrentPipeline());
            _this.ui.pipelineDropdown = simpleDropdownFactory.create(dropdownItems, selectedPipeline, onPipelineSelect);
            return onPipelineSelect(_this.ui.pipelineDropdown.selected);
          });
        };
      })(this);

      /*
        Fetch the deals and stages for a Pipeline, and load them into memory and into the dropdowns
       */
      loadDealsAndStages = (function (_this) {
        return function (pipelineId) {
          _this.ui.dealsDropdown = null;
          _this.ui.stagesDropdown = null;
          return pipedriveService.getDealsAndStages(pipelineId).then(function (result) {
            var dealItems, stageItems;
            if (selectedPipelineId !== pipelineId) {
              return;
            }
            deals = result.deals;
            stages = result.stages;
            dealItems = _.map(deals, dealToDropdown);
            _this.ui.dealsDropdown = simpleDropdownFactory.create(
              dealItems,
              dealToDropdown(pipedriveService.getCurrentDeal()),
              onDealSelect,
              {
                theme: "short",
              },
            );
            stageItems = _.map(stages, stageToDropdown);
            _this.ui.stagesDropdown = simpleDropdownFactory.create(
              stageItems,
              stageToDropdown(pipedriveService.getCurrentStage()),
              onStageSelect,
              {
                theme: "short",
              },
            );
            onDealSelect(_this.ui.dealsDropdown.selected);
            return onStageSelect(_this.ui.stagesDropdown.selected);
          });
        };
      })(this);

      /*
        Helper functions for converting between raw Pipedrive data to id, name
        objects for the dropdowns
       */
      pipelineToDropdown = function (pipelineData) {
        if (pipelineData != null) {
          return {
            id: pipelineData.id,
            name: pipelineData.name,
          };
        } else {
          return null;
        }
      };
      dealToDropdown = function (deal) {
        if (deal != null) {
          return {
            id: deal.id,
            name: deal.title,
          };
        } else {
          return null;
        }
      };
      stageToDropdown = function (stage) {
        if (stage != null) {
          return {
            id: stage.id,
            name: stage.name,
          };
        } else {
          return null;
        }
      };
      init();
    }

    return PipedriveSettingsMenu;
  })();
}.call(this));
