"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductLandingLocation = void 0;
var Telemetry_1 = require("@CommonFrontendBackend/Telemetry");
var GetFullName_1 = require("@CommonFrontendBackend/Utils/GetFullName");
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var AccountTelemetryHelper_1 = require("@CommonFrontendBackend/Telemetry/AccountTelemetryHelper");
var ProductLandingLocation;
(function (ProductLandingLocation) {
    ProductLandingLocation["Editor"] = "editor";
    ProductLandingLocation["Dashboard"] = "dashboard";
    ProductLandingLocation["TemplateGallery"] = "templates";
    ProductLandingLocation["Integrations"] = "integrations";
})(ProductLandingLocation || (exports.ProductLandingLocation = ProductLandingLocation = {}));
var OnboardingTelemetryService = (function () {
    function OnboardingTelemetryService(telemetry, serverTelemetry, userList, isMobile) {
        var _this = this;
        this.telemetry = telemetry;
        this.serverTelemetry = serverTelemetry;
        this.isMobile = isMobile;
        this.userPositionTelemetry = {};
        this.userDataSubmitted = function (_a) { return __awaiter(_this, [_a], Promise, function (_b) {
            var firstName = _b.firstName, lastName = _b.lastName;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0: return [4, this.serverTelemetry.sendTelemetryUpdateViaServer({
                            first_name: firstName,
                            last_name: lastName,
                            name: (0, GetFullName_1.buildName)(firstName, lastName),
                        })];
                    case 1:
                        _c.sent();
                        this.telemetry.trackOnly(Telemetry_1.TelemetryEventName.OnboardingUserDataSubmitted);
                        return [2];
                }
            });
        }); };
        this.accountDataSubmitted = function (accountDetails) { return __awaiter(_this, void 0, Promise, function () {
            var crmTelemetry;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        crmTelemetry = {
                            crm_technology: accountDetails.crmTechnology,
                        };
                        if (accountDetails.crmTechnologyOther) {
                            crmTelemetry.crm_technology_other = accountDetails.crmTechnologyOther;
                        }
                        return [4, this.serverTelemetry.sendTelemetryUpdateViaServer(__assign({ sales_team_size: accountDetails.salesTeamSize, how_did_you_hear_abt_us: accountDetails.howDidYouHearAboutUs }, crmTelemetry))];
                    case 1:
                        _a.sent();
                        return [4, this.serverTelemetry.sendTelemetryGroupViaServer(__assign({ name: accountDetails.name, company_size: accountDetails.companySize, sales_team_size: accountDetails.salesTeamSize }, crmTelemetry))];
                    case 2:
                        _a.sent();
                        return [2];
                }
            });
        }); };
        this.flowSkipped = function (step) {
            _this.telemetry.trackOnly(Telemetry_1.TelemetryEventName.OnboardingSkipped, __assign({ onboarding_step_name: step }, _this.userPositionTelemetry), {
                blockIntercom: true,
            });
        };
        this.stepReached = function (step) { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                this.telemetry.trackOnly(Telemetry_1.TelemetryEventName.OnboardingStepReached, __assign(__assign({ onboarding_step_name: step }, this.userPositionTelemetry), { is_mobile: this.isMobile() }), {
                    blockIntercom: true,
                });
                return [2];
            });
        }); };
        this.onboardingStepCompleted = function (stepName_1) {
            var args_1 = [];
            for (var _i = 1; _i < arguments.length; _i++) {
                args_1[_i - 1] = arguments[_i];
            }
            return __awaiter(_this, __spreadArray([stepName_1], __read(args_1), false), Promise, function (stepName, telemetryProperties) {
                if (telemetryProperties === void 0) { telemetryProperties = {}; }
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, this.serverTelemetry.sendOnboardingStepCompletedTelemetry({
                                eventProperties: __assign(__assign({ onboardingStepName: stepName }, (0, AccountTelemetryHelper_1.convertCaseKeys)(telemetryProperties, "camel")), { foundingUser: this.userPositionTelemetry.user_position === "first", isMobile: this.isMobile() }),
                            })];
                        case 1:
                            _a.sent();
                            return [2];
                    }
                });
            });
        };
        this.brandConfigured = function () { return __awaiter(_this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.serverTelemetry.sendBrandConfiguredTelemetry({
                            eventProperties: {},
                            eventOrigin: event_tracker_types_1.EventOrigin.QwilrOnboarding,
                        })];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        }); };
        this.trackProductLanding = function (productLandingLocation) {
            _this.telemetry.trackOnly("New User Product Landing", __assign(__assign({}, _this.userPositionTelemetry), { product_landing_location: productLandingLocation }), { blockIntercom: true });
        };
        this.userPositionTelemetry = { user_position: userList.length === 1 ? "first" : "nth" };
    }
    OnboardingTelemetryService.prototype.sendUserInvitedTelemetry = function (payload) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                this.serverTelemetry.sendUserInvitedTelemetry(payload);
                return [2];
            });
        });
    };
    return OnboardingTelemetryService;
}());
exports.default = OnboardingTelemetryService;
