// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (make, Tooltip, createUi, initSubscription) {
    return function (arg) {
      var message, options, targetSelector, tooltip, tooltipInner, upgradePosition;
      (message = arg.message),
        (upgradePosition = arg.upgradePosition),
        (targetSelector = arg.targetSelector),
        (options = arg.options);
      if (options == null) {
        options = {};
      }
      tooltipInner = createUi(require("./UpsellTooltip.html"), {
        message: message,
        upgradePosition: upgradePosition,
        upgrade: function () {
          tooltip.hide();
          return initSubscription.init();
        },
      });
      tooltip = make(Tooltip, {
        targetSelector: targetSelector,
        options: _.extend(
          {
            customTheme: "top-helper-theme",
            position: "bottom",
            contents: tooltipInner,
          },
          options,
        ),
      });
      return tooltip;
    };
  };
}.call(this));
