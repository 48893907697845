const { createParagraphToken } = require("WidgetRegistry/CommonWidgets/Paragraph");

(function () {
  var ESignatureMenu, FeatureFlag, paragraph, ref, text;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  (ref = require("ContentTokens/TokenModels")["default"]), (paragraph = ref.paragraph), (text = ref.text);

  module.exports = ESignatureMenu = (function () {
    function ESignatureMenu(createUi, block, $q, usingFeature, upseller, telemetry, Editor, make) {
      var base, disclaimerEditor, usingInlineForm;
      if ((base = block.settings).disclaimer == null) {
        base.disclaimer = {
          tokens: [
            createParagraphToken(),
            text.createToken(
              "I agree that my electronic signature is as valid and legally binding as a handwritten signature.",
            ),
          ],
        };
      }
      usingInlineForm = !usingFeature(FeatureFlag.OptOutNewEditor);
      disclaimerEditor = make(Editor, {
        model: block.settings.disclaimer,
        options: {},
        configuration: "quoteLineItem",
      });
      this.ui = createUi(require("./ESignatureMenu.html"), {
        settings: block.settings,
        increment: function () {
          return telemetry.trackOnly(
            "E-Signature Signee Added",
            {},
            {
              blockIntercom: true,
            },
          );
        },
        decrement: function () {
          const nextAccepterCount = block.settings.accepters.number - 1;

          // If there are too many prefilled names, we need to clear the excess out
          if (block.settings.accepters.prefilledNames.length > nextAccepterCount) {
            block.settings.accepters.prefilledNames = block.settings.accepters.prefilledNames.slice(
              0,
              nextAccepterCount,
            );
          }
          return telemetry.trackOnly(
            "E-Signature Signee Removed",
            {},
            {
              blockIntercom: true,
            },
          );
        },
        onPrefilledNameChange: function () {
          var enabled, eventName;
          enabled = block.settings.accepters.enablePrefilledNames;
          eventName = enabled ? "E-Signature Prefilled Names Enabled" : "E-Signature Prefilled Names Disabled";
          return telemetry.trackOnly(
            eventName,
            {},
            {
              blockIntercom: true,
            },
          );
        },
        disclaimerEditor: disclaimerEditor.ui,
        enableEsign: function () {
          if (!block.settings.esignEnabled) {
            block.settings.accepters.number = 1;
            block.settings.accepters.enablePrefilledNames = false;
            return telemetry.trackOnly(
              "E-Signature Disabled",
              {},
              {
                blockIntercom: true,
              },
            );
          } else if (usingFeature(FeatureFlag.Esign)) {
            return telemetry.trackOnly(
              "E-Signature Enabled",
              {
                esign_enabled_source: "config",
              },
              {
                blockIntercom: true,
              },
            );
          } else {
            upseller.upsell({
              feature: "esign",
              featureLabel: "e-signature",
              customUpsellMessage: "Upgrade to collect signatures from all relevant stakeholders.",
            });
            return (block.settings.esignEnabled = false);
          }
        },
        showDisclaimerBox: function () {
          return usingInlineForm && usingFeature(FeatureFlag.Esign) && block.settings.esignEnabled === true;
        },
      });
    }

    return ESignatureMenu;
  })();
}.call(this));
