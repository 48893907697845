"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
var slate_react_1 = require("slate-react");
var EditorTypes_1 = require("../EditorTypes");
var BehaviourBuilder_1 = require("./BehaviourBuilder");
var WidgetActions = __importStar(require("./WidgetActions"));
var slate_1 = require("slate");
var Utils_1 = require("../Utils");
exports.default = (function (di) {
    var editorTelemetry = di.get("editorTelemetry");
    return {
        onUpdateButton: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("updateButtonWidget")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "button", action: "update" })
            .doAction(function (event, editor) {
            var buttonBlockEntry = slate_1.Editor.node(editor, event.data.path);
            var existingData = buttonBlockEntry[0].data;
            var data = __assign(__assign({}, existingData), event.data.toUpdate);
            slate_1.Transforms.setNodes(editor, { data: data }, { at: event.data.path });
            return true;
        }),
        onManualButtonSelect: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("manualButtonSelect")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "button", action: "select" })
            .doAction(function (event, editor) {
            var path = event.data.path;
            slate_1.Transforms.select(editor, { path: path, offset: 0 });
            return true;
        }),
        onEnterInLabel: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("buttonEnterInLabel")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "button", action: "addParagraphBelow" })
            .doAction(function (event, editor) {
            slate_1.Transforms.select(editor, event.data.path);
            WidgetActions.newParagraphBelowWidget(editor);
            slate_react_1.ReactEditor.focus(editor);
            return true;
        }),
        onArrowDownInLabel: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("buttonArrowDownInLabel")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "button", action: "arrowDownInLabel" })
            .doAction(function (event, editor) {
            var path = event.data.path;
            slate_1.Transforms.select(editor, { path: path, offset: 0 });
            (0, Utils_1.moveToStartOfNextText)(editor);
            slate_react_1.ReactEditor.focus(editor);
            return true;
        }),
        onArrowUpInLabel: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("buttonArrowUpInLabel")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "button", action: "arrowUpInLabel" })
            .doAction(function (event, editor) {
            var path = event.data.path;
            slate_1.Transforms.select(editor, { path: path, offset: 0 });
            slate_react_1.ReactEditor.focus(editor);
            return true;
        }),
    };
});
