// Generated by CoffeeScript 1.12.7
(function () {
  var ButtonTemplate, SectionSettings, SectionSettingsMenu;

  SectionSettingsMenu = require("../SettingsMenus/SectionSettingsMenu");

  ButtonTemplate = require("./SectionSettingsButton.html");

  /*
  Button to bring up a Tooltip menu to change section settings
   */

  module.exports = SectionSettings = (function () {
    function SectionSettings(make, createUi, block, Tooltip, sectionView, hint) {
      var sectionSettingsHint, sectionSettingsMenu, tooltip;
      sectionSettingsMenu = make(SectionSettingsMenu);
      tooltip = make(Tooltip, {
        targetSelector: "#" + block.$id + " #" + sectionView.ui.uniqueId + " .settings",
        options: {
          container: "#" + block.$id + " .project-block",
          customClass: "item-settings-menu-tooltip",
          customTheme: "",
          position: "bottom",
          contents: sectionSettingsMenu.ui,
          spacing: 7,
          closeOnClickAway: true,
        },
      });
      sectionSettingsHint = hint.small({
        targetSelector: "#" + sectionView.ui.uniqueId + " .section-header .settings-bar .section-button.settings",
        hint: "section settings",
      });
      tooltip.setOnShow(function () {
        return {};
      });
      this.ui = createUi(ButtonTemplate, {
        onClick: function () {
          sectionSettingsHint.hide();
          if (!tooltip.isCurrentlyShowing()) {
            tooltip.show();
          } else {
            tooltip.hide();
          }
        },
        isMenuOpen: function () {
          return tooltip.isCurrentlyShowing();
        },
      });
    }

    return SectionSettings;
  })();
}.call(this));
