import "Application/Onboarding/CensusCollector/MakeQwilrYourOwn/MakeQwilrYourOwn.css.ts.vanilla.css!=!../../../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"Application/Onboarding/CensusCollector/MakeQwilrYourOwn/MakeQwilrYourOwn.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VUzY7bIBi85ymQqkrxgchOsomXXCr1EXrooaoiDNghweAC3iSq8u6VcX4Ae7OterIMM/MNfMM322ZcK9G8puD3BADKTSPwGYFSsNNmAtwXUq4ZsVxJBIgSbS27HSx4JSG3rDYIECYt05vJZTK7CWZOsMa64hIWylpVI/CG9RRC02DCZQXFdpsVYr3fZ5lMAvLckY+c2h0CWZp+3oyZ27fG8vIMiZKWSTtqY9Hb4BJe1RZZ2jh2gynlsopNnTxXKgFpoLZ0aoXSlGkEpJKsU9oxXu0sAlkvfC1EsCDTLE3fjgDGNfwiTZJ0rAKTQ6VVKykkSiiNwKc8w/NinocWXpyF98yfzEN4n8S73ubBFb22x6omVvKgwoeOd7J+oGuHtuxkoYvIaFdW/T0Ojtyruh9oWl1iwr61hDDzsHMIk7L+e6WvuO1CfFcSoRLuM7fjlrlzMQQazeBR4ybAFQ43UqY7c+x2HdYgz7ixvzzk0vCFcil4H7+oM3DZxdAjsn81DECppIXHa6x7Srf03a1Aw2peKEHvpMU8tFr6b1+w0iIAX9LIVvUsyMFoiB7h7ukLCDIeMfn/zCQOZoXGt4SZ7ba/RIgp9aYCNPYsGAJGCU4D/t4vPvLi3q8skVR2+oNygwvB6M+kb2irTddRykrcCtsxvtSMcgym3rhbp2lz6gnRTAynYn6dip3D02D14tOXN/oDmK/GgPkdGFz4ajmCfQ2x7n7SzRg9HXDLkNsHbgjj43aGwMOIl3jO+hP8VzKQEB9KDAbsZXL5Az/5Im6OBwAA\"}!../../../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
import { createRuntimeFn as _7a468 } from '@vanilla-extract/recipes/createRuntimeFn';
export var root = '_1irolp90';
export var heading = '_1irolp91';
export var urlInputContainer = '_1irolp92';
export var urlInput = '_1irolp93';
export var separator = '_1irolp94';
export var informationSection = _7a468({defaultClassName:'_1irolp95',variantClassNames:{status:{success:'_1irolp96',failed:'_1irolp97'},style:{relaxed:'_1irolp98',compact:'_1irolp99'}},defaultVariants:{},compoundVariants:[]});
export var informationSectionText = _7a468({defaultClassName:'_1irolp9a',variantClassNames:{status:{success:'_1irolp9b',failed:'_1irolp9c'}},defaultVariants:{},compoundVariants:[]});
export var informationSectionIcon = '_1irolp9d';
export var tryItAgainLink = '_1irolp9e';
export var logoUploader = '_1irolp9f';
export var brandSetup = '_1irolp9g';
export var brandColorsAndSubdomain = '_1irolp9h';
export var brandColors = '_1irolp9i';
export var buttonGroup = _7a468({defaultClassName:'_1irolp9j',variantClassNames:{style:{relaxed:'_1irolp9k',compact:'_1irolp9l'}},defaultVariants:{},compoundVariants:[]});
export var submitButton = _7a468({defaultClassName:'_1irolp9m',variantClassNames:{status:{disabled:'_1irolp9n',enabled:'_1irolp9o'}},defaultVariants:{},compoundVariants:[]});