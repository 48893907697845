// Generated by CoffeeScript 1.12.7

/*
  Initialises widgets in the Qwilr editor
 */

(function () {
  module.exports = function (make, editor, model, $timeout, $compile, scope, undo, block) {
    var WidgetFactory, WidgetTracker, createWidgets, widgetFactory, widgetTracker;
    WidgetTracker = require("./WidgetTracker.js");
    widgetTracker = make(WidgetTracker, {
      model: model.getWidgets(),
      editor: editor,
    });
    editor.widgetTracker = widgetTracker;
    WidgetFactory = require("./WidgetFactory");
    widgetFactory = make(WidgetFactory, {
      onDelete: widgetTracker.remove,
      widgetTracker: widgetTracker,
    });
    editor.insertWidget = function (model, point) {
      var dummy;
      dummy = document.createElement("span");
      point.insert(dummy);
      point = qed.Point.after(dummy).rightNormalized();
      editor.replaceElemWithWidget(dummy, model);
      return point;
    };
    editor.replaceElemWithWidget = function (blockElemToReplace, model) {
      var widget;
      widget = widgetFactory.create(model);
      return widgetTracker.insertNew(widget, blockElemToReplace);
    };
    editor.addListener({
      onAttached: function () {
        $timeout(function () {
          var widgets;
          widgetTracker.initialise();
          widgets = createWidgets(model.getWidgets());
          return widgetTracker.startTracking(widgets);
        });
        new qed.StemTracker(editor, ["li", "ul", "ol", "insertable"], function (e, stem) {
          undo.checkpoint();
          editor.replaceElemWithWidget(stem.blockElem, null);
          return stem.remove();
        });
        return false;
      },
      onContent: function () {
        var newWidgets, untracked;
        if (!editor.attached()) {
          return false;
        }
        widgetTracker.cleanRemovedWidgets();
        untracked = widgetTracker.getUntrackedWidgets();
        if (untracked.length > 0) {
          newWidgets = widgetFactory.createMultiple(untracked);
          widgetTracker.startTracking(newWidgets);
        }
        return false;
      },
    });
    createWidgets = function (widgets) {
      var created, id, widgetModel;
      created = [];
      for (id in widgets) {
        widgetModel = widgets[id];
        if (widgetModel.type == null || widgetModel.type === "creator" || widgetModel.type === "selector") {
          continue;
        }
        if (widgetModel.id != null) {
          widgetModel.id = id;
        }
        created.push(widgetFactory.create(widgetModel));
      }
      return created;
    };
    editor.reinitialiseWidgets = function (widgets) {
      return $timeout(function () {
        var active, id, ref, toBeTracked, widget;
        ref = widgetTracker.activeWidgets;
        for (id in ref) {
          active = ref[id];
          if (widgets[id] == null) {
            widgetTracker.removeTracking(id);
          }
        }
        toBeTracked = {};
        for (id in widgets) {
          widget = widgets[id];
          if (widgetTracker.activeWidgets[widget.id] == null) {
            toBeTracked[id] = widget;
          }
        }
        return widgetTracker.startTracking(createWidgets(toBeTracked));
      });
    };
    return editor;
  };
}.call(this));
