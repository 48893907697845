// Generated by CoffeeScript 1.12.7
(function () {
  var InsertItem, InsertItemButton, InsertItemMenu;

  InsertItemButton = require("./InsertItemButton.html");

  InsertItemMenu = require("../SettingsMenus/InsertItemMenu");

  /*
  Button to bring up a Tooltip menu to choose what type of item to insert
   */

  module.exports = InsertItem = (function () {
    function InsertItem(createUi, make, block, Tooltip, sectionView, itemView) {
      var insertItem, insertItemMenu, insertSavedLineItems, tooltip;
      insertItem = (function (_this) {
        return function (type) {
          tooltip.hide();
          return sectionView.insertItem(itemView, type);
        };
      })(this);
      insertSavedLineItems = (function (_this) {
        return function (itemsToInsert) {
          return sectionView.insertSavedLineItems(itemsToInsert, {
            prevItem: itemView,
          });
        };
      })(this);
      insertItemMenu = make(InsertItemMenu, {
        insertItem: insertItem,
        insertSavedLineItems: insertSavedLineItems,
      });
      tooltip = make(Tooltip, {
        targetSelector: "#" + block.$id + " #" + itemView.ui.uniqueId + " .insert-item-button",
        options: {
          container: "#" + block.$id + " .project-block",
          customClass: "insert-item-menu-tooltip",
          customTheme: "",
          position: "right",
          contents: insertItemMenu.ui,
          spacing: 7,
          closeOnClickAway: true,
        },
      });
      this.ui = createUi(InsertItemButton, {
        onClick: (function (_this) {
          return function () {
            if (!tooltip.isCurrentlyShowing()) {
              return tooltip.show();
            }
          };
        })(this),
        isMenuOpen: (function (_this) {
          return function () {
            return tooltip.isCurrentlyShowing();
          };
        })(this),
      });
    }

    return InsertItem;
  })();
}.call(this));
