// Generated by CoffeeScript 1.12.7

/*
  Does some hacky setup for the editor environment to prevent bad
  behaviour with old content
 */

(function () {
  module.exports = function (elem) {
    var oldPlaceholderSetup;
    oldPlaceholderSetup = (function (_this) {
      return function (elem) {
        return $(elem)
          .find("span.default-value")
          .each(function () {
            var br, el, parent;
            el = $(this);
            parent = el[0].parentNode;
            parent.removeChild(el[0]);
            parent.setAttribute("placeholder", "true");
            br = document.createElement("br");
            return parent.appendChild(br);
          });
      };
    })(this);
    return oldPlaceholderSetup(elem);
  };
}.call(this));
