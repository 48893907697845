"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectDimensionStore = void 0;
var mobx_1 = require("mobx");
var ProjectDimensionStore = (function () {
    function ProjectDimensionStore() {
        this._pageContentWidth = 0;
    }
    Object.defineProperty(ProjectDimensionStore.prototype, "pageContentWidth", {
        get: function () {
            return this._pageContentWidth;
        },
        set: function (pageContentWidth) {
            this._pageContentWidth = pageContentWidth;
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], ProjectDimensionStore.prototype, "_pageContentWidth", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [Number])
    ], ProjectDimensionStore.prototype, "pageContentWidth", null);
    return ProjectDimensionStore;
}());
exports.ProjectDimensionStore = ProjectDimensionStore;
