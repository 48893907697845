// Generated by CoffeeScript 1.12.7

/*
  Pulls together the clipboard event handlers
 */

(function () {
  var Clipboard, CopyPaste;

  Clipboard = require("./Clipboard");

  module.exports = CopyPaste = (function () {
    function CopyPaste(make, editor, undo, model) {
      var clipboard;
      clipboard = null;
      editor.addListener({
        onAttached: function (e) {
          clipboard = make(Clipboard);
          return false;
        },
        onCopy: function (e) {
          var currWidgetModel, elem, i, len, models, ref;
          undo.checkpoint();
          models = model.getWidgets();
          ref = $(editor.currentElem()).find("insertable");
          for (i = 0, len = ref.length; i < len; i++) {
            elem = ref[i];
            currWidgetModel = models[elem.getAttribute("widget-id")] || "";
            elem.setAttribute("qwilr-widget-model", angular.toJson(currWidgetModel));
          }
          return true;
        },
        onPaste: function (e) {
          undo.checkpoint();
          if (e.target.hasAttribute("placeholder")) {
            e.target.removeAttribute("placeholder");
          }
          clipboard.pasteIn();
          return true;
        },
      });
    }

    return CopyPaste;
  })();
}.call(this));
