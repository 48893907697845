"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UploadUtils_1 = require("Common/Upload/UploadUtils");
var Image_1 = require("@CommonFrontend/WidgetRegistry/CommonWidgets/Image");
exports.default = (function (transfer, uploadStore) {
    var files = transfer.files;
    return files
        .map(function (file) {
        if ((0, UploadUtils_1.isImage)(file)) {
            return getImageToken(uploadStore, file);
        }
        else {
            return;
        }
    })
        .filter(function (token) { return !!token; });
});
var getImageToken = function (uploadStore, file) {
    var uploadId = uploadStore.upload(file);
    return (0, Image_1.createImageToken)("", { uploadId: uploadId });
};
