// Generated by CoffeeScript 1.12.7

/*
  Defines some helper functions that are used between the Xero account settings and
  invoicing
 */

(function () {
  var FeatureFlag,
    XERO_INTEGRATION_NAME,
    XeroCommon,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  XERO_INTEGRATION_NAME = "xero";

  module.exports = XeroCommon = (function () {
    function XeroCommon($log, $q, $http, account, utils, apiBaseUrl, usingFeature) {
      this.account = account;
      this.utils = utils;
      this.apiBaseUrl = apiBaseUrl;
      this.usingFeature = usingFeature;
      this.buildAccountSpecificXeroApiPath = bind(this.buildAccountSpecificXeroApiPath, this);
      this.buildXeroApiPath = bind(this.buildXeroApiPath, this);
      this.getXeroAccountSettings = bind(this.getXeroAccountSettings, this);
      this.getContacts = bind(this.getContacts, this);
      this.getAccounts = bind(this.getAccounts, this);
      this.getAccountsAndContacts = bind(this.getAccountsAndContacts, this);
      this.disable = bind(this.disable, this);
      this.isEnabled = bind(this.isEnabled, this);
      this.enable = bind(this.enable, this);
      this.log = $log;
      this.q = $q;
      this.http = $http;
    }

    XeroCommon.prototype.enable = function () {
      var callbackUrl, enabling, i, left, len, popupWindow, postData, ref, tax, top;
      enabling = this.q.defer();
      callbackUrl = this.buildXeroApiPath() + "/callback";
      left = screen.width / 2 - 450;
      top = screen.height / 2 - 250;
      popupWindow = (function (_this) {
        return function () {
          var polling, win;
          win = window.open(
            _this.buildXeroApiPath() + "/authorize",
            "_blank",
            "top=" + top + ",left=" + left + ",width=900,height=500",
          );
          return (polling = window.setInterval(function () {
            var e, url;
            try {
              if (win.QWILR_SUCCESS) {
                window.clearInterval(polling);
                url = win.document.URL;
                enabling.resolve(url.split("?")[1]);
                return win.close();
              }
            } catch (error) {
              e = error;
            }
          }, 100));
        };
      })(this);
      if (this.account.settings.modules.quote.taxes != null) {
        ref = this.account.settings.modules.quote.taxes;
        for (i = 0, len = ref.length; i < len; i++) {
          tax = ref[i];
          if (tax.id == null) {
            tax.id = this.utils.uniqueId();
          }
        }
        postData = {
          "settings.modules.quote.taxes": this.account.settings.modules.quote.taxes,
        };
        this.http
          .post(this.apiBaseUrl + "/accounts/" + this.account._id, postData)
          .then(
            (function (_this) {
              return function (response) {
                return (_this.popup = popupWindow());
              };
            })(this),
          )
          ["catch"](
            (function (_this) {
              return function (err) {
                _this.log.error("Updating tax ids failed", err);
                return (_this.popup = popupWindow());
              };
            })(this),
          );
      } else {
        this.popup = popupWindow();
      }
      return enabling.promise
        .then(
          (function (_this) {
            return function (queryParams) {
              return _this.http.get(callbackUrl + "?" + queryParams);
            };
          })(this),
        )
        .then(
          (function (_this) {
            return function () {
              _this.getXeroAccountSettings().enabled = true;
            };
          })(this),
        );
    };

    XeroCommon.prototype.isEnabled = function () {
      return this.getXeroAccountSettings().enabled;
    };

    XeroCommon.prototype.disable = function () {
      return this.http
        .post(this.apiBaseUrl + "/integration/xero/disable")
        .then(
          (function (_this) {
            return function (response) {
              return (_this.getXeroAccountSettings().enabled = false);
            };
          })(this),
        )
        ["catch"](
          (function (_this) {
            return function (err) {
              return _this.log.error(err);
            };
          })(this),
        );
    };

    XeroCommon.prototype.getAccountsAndContacts = function () {
      return this.http
        .get(this.apiBaseUrl + "/" + this.account._id + "/integration/xero?type=accountsAndContacts")
        .then(
          (function (_this) {
            return function (response) {
              return response.data;
            };
          })(this),
        )
        ["catch"](
          (function (_this) {
            return function (err) {
              if (err != null) {
                if (err.message === "REAUTH") {
                  return _this.enable();
                } else {
                  _this.log.error(err);
                  return _this.q.reject(new Error("Could not retrieve Xero Accounts and Contacts"));
                }
              }
            };
          })(this),
        );
    };

    XeroCommon.prototype.getAccounts = function () {
      return this.http
        .get(this.apiBaseUrl + "/" + this.account._id + "/integration/xero?type=accounts")
        .then(
          (function (_this) {
            return function (response) {
              return response.data;
            };
          })(this),
        )
        ["catch"](
          (function (_this) {
            return function (err) {
              if (err != null) {
                if (err.message === "REAUTH") {
                  return _this.enable();
                } else {
                  _this.log.error(err);
                  return _this.q.reject("Could not retrieve Xero accounts");
                }
              } else {
                return [];
              }
            };
          })(this),
        );
    };

    XeroCommon.prototype.getContacts = function () {
      if (this.isEnabled()) {
        return this.http
          .get(this.apiBaseUrl + "/" + this.account._id + "/integration/xero?type=contacts")
          .then(
            (function (_this) {
              return function (response) {
                return response.data;
              };
            })(this),
          )
          ["catch"](
            (function (_this) {
              return function (err) {
                _this.log.error(err);
                return [];
              };
            })(this),
          );
      } else {
        return this.q.resolve([]);
      }
    };

    XeroCommon.prototype.getXeroAccountSettings = function () {
      var ref;
      return (ref = this.account.integrations) != null ? ref[XERO_INTEGRATION_NAME] : void 0;
    };

    XeroCommon.prototype.buildXeroApiPath = function () {
      return this.apiBaseUrl + "/integration/" + XERO_INTEGRATION_NAME;
    };

    XeroCommon.prototype.buildAccountSpecificXeroApiPath = function () {
      return this.apiBaseUrl + "/" + this.account._id + "/integration/" + XERO_INTEGRATION_NAME;
    };

    return XeroCommon;
  })();
}.call(this));
