// Generated by CoffeeScript 1.12.7
(function () {
  var FontStyleSliderFactory;

  module.exports = FontStyleSliderFactory = (function () {
    function FontStyleSliderFactory(make, createUi, Slider, styleService) {
      /*
        Create all the font sliders. It'll also make any transformations needed on initialisation
        @param style [Object] the style object on the account model
        @param onChange [Function] this function gets called every time a slider is changed
        @param isGrouped [Boolean] (Optional) whether the styles for h1, h2, and p are grouped
        @param isToggled [Boolean] (Optional) whether this initialisation was triggered by the toggle
       */
      var create,
        getPercentFromRatio,
        getPercentFromScale,
        getPercentFromSpacing,
        getRatioFromPercent,
        getScaleFromPercent,
        getSpacingFromPercent;
      this.createAll = function (style, onChange, isGrouped, isToggled) {
        var fields, fontStyles;
        if (isGrouped == null) {
          isGrouped = true;
        }
        if (isToggled == null) {
          isToggled = false;
        }
        fontStyles = style.fontStyles;
        if (isToggled) {
          if (isGrouped) {
            if (!_.isEmpty(style.fontStyles.p)) {
              style.fontStyles.scale = style.fontStyles.p.scale;
              style.fontStyles.ratio = style.fontStyles.p.ratio;
            }
            delete style.fontStyles.h1;
            delete style.fontStyles.h2;
            delete style.fontStyles.p;
          } else {
            style.fontStyles.h1 = {
              scale: style.fontStyles.scale,
              ratio: getRatioFromPercent(getPercentFromScale(style.fontStyles.scale)),
            };
            style.fontStyles.h2 = {
              scale: style.fontStyles.scale,
              ratio: getRatioFromPercent(getPercentFromScale(style.fontStyles.scale)),
            };
            style.fontStyles.p = {
              scale: style.fontStyles.scale,
              ratio: getRatioFromPercent(getPercentFromScale(style.fontStyles.scale)),
            };
          }
          onChange();
        }
        if (isGrouped) {
          fields = [
            {
              name: "scale",
              icon: "scale",
              get: function () {
                return getPercentFromScale(fontStyles.scale);
              },
              set: function (newPercent) {
                return (fontStyles.scale = getScaleFromPercent(newPercent));
              },
            },
            {
              name: "ratio",
              icon: "ratio",
              get: function () {
                return getPercentFromRatio(fontStyles.ratio);
              },
              set: function (newPercent) {
                return (fontStyles.ratio = getRatioFromPercent(newPercent));
              },
            },
            {
              name: "spacing",
              icon: "spacing",
              get: function () {
                return getPercentFromSpacing(fontStyles.spacing);
              },
              set: function (newPercent) {
                return (fontStyles.spacing = getSpacingFromPercent(newPercent));
              },
            },
          ];
        } else {
          fields = [
            {
              name: "heading",
              icon: "scale",
              get: function () {
                return getPercentFromScale(fontStyles.h1.scale);
              },
              set: function (newPercent) {
                fontStyles.h1.scale = getScaleFromPercent(newPercent);
                return (fontStyles.h1.ratio = getRatioFromPercent(newPercent));
              },
            },
            {
              name: "subheading",
              icon: "scale",
              get: function () {
                return getPercentFromScale(fontStyles.h2.scale);
              },
              set: function (newPercent) {
                fontStyles.h2.scale = getScaleFromPercent(newPercent);
                return (fontStyles.h2.ratio = getRatioFromPercent(newPercent));
              },
            },
            {
              name: "paragraph",
              icon: "scale",
              get: function () {
                return getPercentFromScale(fontStyles.p.scale);
              },
              set: function (newPercent) {
                fontStyles.p.scale = getScaleFromPercent(newPercent);
                return (fontStyles.p.ratio = getRatioFromPercent(newPercent));
              },
            },
            {
              name: "spacing",
              icon: "spacing",
              get: function () {
                return getPercentFromSpacing(fontStyles.spacing);
              },
              set: function (newPercent) {
                return (fontStyles.spacing = getSpacingFromPercent(newPercent));
              },
            },
          ];
        }
        return _.map(fields, function (arg) {
          var get, icon, iconsList, name, set;
          (name = arg.name), (icon = arg.icon), (get = arg.get), (set = arg.set);
          iconsList = {
            small: "Assets/icons/large/" + icon + "-small-32.svg",
            medium: "Assets/icons/large/" + icon + "-medium-32.svg",
            large: "Assets/icons/large/" + icon + "-large-32.svg",
          };
          return {
            title: name,
            ui: create(get(), iconsList, function (newPercent) {
              set(newPercent);
              return onChange();
            }),
          };
        });
      };
      create = function (defaultValue, iconsList, onChange) {
        var slider;
        slider = make(Slider, {
          defaultValue: defaultValue,
          onChange: onChange,
          readout: null,
          getMin: function () {
            return 0;
          },
          getMax: function () {
            return 100;
          },
        });
        return createUi(require("./FontStyleSlider.html"), {
          slider: slider.ui,
          iconsList: iconsList,
        });
      };
      (getScaleFromPercent = styleService.getScaleFromPercent),
        (getRatioFromPercent = styleService.getRatioFromPercent),
        (getSpacingFromPercent = styleService.getSpacingFromPercent),
        (getPercentFromScale = styleService.getPercentFromScale),
        (getPercentFromRatio = styleService.getPercentFromRatio),
        (getPercentFromSpacing = styleService.getPercentFromSpacing);
    }

    return FontStyleSliderFactory;
  })();
}.call(this));
