"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeInline = void 0;
var slate_1 = require("slate");
var NormalizeVoidNode_1 = require("./NormalizeVoidNode");
var normalizeInline = function (entry, editor, schema) {
    var _a = __read(entry, 2), node = _a[0], path = _a[1];
    var inlineConfig = schema.inlines[node.type];
    if (!inlineConfig) {
        slate_1.Transforms.unwrapNodes(editor, { at: path });
        return true;
    }
    if (inlineConfig.isVoid) {
        return (0, NormalizeVoidNode_1.normalizeVoidNode)(node, path, editor);
    }
    return false;
};
exports.normalizeInline = normalizeInline;
