(function () {
  module.exports = function (account, telemetry) {
    var _, countStyles;
    _ = require("lodash");
    countStyles = function () {
      return _.sumBy(account.styles, function (s) {
        if (s.deleted) {
          return 0;
        } else {
          return 1;
        }
      });
    };
    return {
      trackStyleCreate: function () {
        return telemetry.trackOnly("Block Styles Created", {
          block_styles_number: countStyles(),
        });
      },
      trackStyleDelete: function () {
        return telemetry.trackOnly(
          "Block Styles Deleted",
          {
            block_styles_number: countStyles(),
          },
          {
            blockIntercom: true,
          },
        );
      },
      trackStyleApplied: function () {
        return telemetry.trackOnly(
          "Block Styles Applied",
          {},
          {
            blockIntercom: true,
          },
        );
      },
    };
  };
}.call(this));
