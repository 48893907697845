"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stopPropagation = void 0;
var stopPropagation = function (event) {
    if (event) {
        event.stopPropagation();
    }
    return event;
};
exports.stopPropagation = stopPropagation;
