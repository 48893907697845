// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = {
    Enter: {
      key: "enter",
      tests: [
        {
          name: "should insert a newline on Enter keypress",
          startContent: "<p>a|b</p>",
          selectionResult: "<p>a</p><p>|b</p>",
        },
        {
          name: "should insert a newline at the start of a paragraph with an open paragraph above",
          startContent: "<p>|ab</p>",
          selectionResult: "<p><br></p><p>|ab</p>",
        },
        {
          name: "should insert a newline at the end of a paragraph with an open paragraph below",
          startContent: "<p>ab|</p>",
          selectionResult: "<p>ab</p><p>|<br></p>",
        },
        {
          name: "should split styling tags when making newline",
          startContent: "<p>a<b>b<i>c|d</i>e</b>f</p>",
          selectionResult: "<p>a<b>b<i>c</i></b></p><p><b><i>|d</i>e</b>f</p>",
        },
        {
          name: "should split empty paragraph but keep resulting two paragraphs open",
          startContent: "<p><br>|</p>",
          selectionResult: "<p><br></p><p>|<br></p>",
        },
        {
          name: "should split empty paragraph but keep resulting two paragraphs open",
          startContent: "<p>|<br></p>",
          selectionResult: "<p><br></p><p>|<br></p>",
        },
        {
          name: "should split at end of text content but inside style block",
          startContent: "<p><b>asdf</b>|</p>",
          selectionResult: "<p><b>asdf</b></p><p>|<br></p>",
        },
        {
          name: "should split at end of text content but inside style block (different point)",
          startContent: "<p><b>asdf|</b></p>",
          selectionResult: "<p><b>asdf</b></p><p>|<br></p>",
        },
      ],
    },
    Backspace: {
      key: "backspace",
      tests: [
        {
          name: "should join two paragraphs together if at start of second paragraph",
          startContent: "<p>ab</p><p>|cd</p>",
          selectionResult: "<p>ab|cd</p>",
        },
        {
          name: "should join paragraph content to end of other block type",
          startContent: "<h1>ab</h1><p>|cd</p>",
          selectionResult: "<h1>ab|cd</h1>",
        },
        {
          name: "should do nothing if first paragraph",
          startContent: "<p>|ab</p>",
          domResult: "<p>ab</p>",
          selectionResult: "<p>|ab</p>",
        },
        {
          name: "should not handle event if not at start of line",
          startContent: "<p>ab|cd</p>",
          domResult: "<p>abcd</p>",
          selectionResult: "<p>ab|cd</p>",
        },
        {
          name: "should not handle event if visible whitespace before",
          startContent: "<p>ab</p><p>&nbsp;|cd</p>",
          domResult: "<p>ab</p><p>&nbsp;cd</p>",
          selectionResult: "<p>ab</p><p>&nbsp;|cd</p>",
        },
        {
          name: "should not handle if at start of style tag with more content before",
          startContent: "<p>abc<b>|def</b></p>",
          domResult: "<p>abc<b>def</b></p>",
          selectionResult: "<p>abc<b>|def</b></p>",
        },
        {
          name: "should not handle if after style tag with content in style",
          startContent: "<p><b>def</b>|abc</p>",
          domResult: "<p><b>def</b>abc</p>",
          selectionResult: "<p><b>def</b>|abc</p>",
        },
        {
          name: "should join onto list correctly if there is content in paragraph",
          startContent: "<ul><li>asdf</li></ul><p>|asdf</p>",
          selectionResult: "<ul><li>asdf|asdf</li></ul>",
        },
        {
          name: "should join onto list correctly if there is no content in paragraph",
          startContent: "<ul><li>asdf</li></ul><p>|<br></p>",
          selectionResult: "<ul><li>asdf|</li></ul>",
        },
        {
          name: "should join onto list correctly if there is no content in paragraph (modified caret location)",
          startContent: "<ul><li>asdf</li></ul><p><br>|</p>",
          selectionResult: "<ul><li>asdf|</li></ul>",
        },
        {
          name:
            "should join onto list correctly if there is content in paragraph and merge with the next list (if there is one of the same type)",
          startContent: "<ul><li>asdf</li></ul><p>|zxcv</p><ul><li>qw</li><li>er</li></ul>",
          selectionResult: "<ul><li>asdf|zxcv</li><li>qw</li><li>er</li></ul>",
        },
        {
          name:
            "should join onto list correctly if there is no content in paragraph and merge with the next list (if there is one of the same type)",
          startContent: "<ul><li>asdf</li></ul><p>|<br></p><ul><li>qw</li><li>er</li></ul>",
          selectionResult: "<ul><li>asdf|</li><li>qw</li><li>er</li></ul>",
        },
        {
          name:
            "should join onto list correctly if there is no content in paragraph and not merge with an ordered list",
          startContent: "<ul><li>asdf</li></ul><p>|zxcv</p><ol><li>qwer</li></ol>",
          selectionResult: "<ul><li>asdf|zxcv</li></ul><ol><li>qwer</li></ol>",
        },
        {
          name: "should join onto list correctly if there is content in paragraph and not merge with an ordered list",
          startContent: "<ul><li>asdf</li></ul><p>|<br></p><ol><li>qwer</li></ol>",
          selectionResult: "<ul><li>asdf|</li></ul><ol><li>qwer</li></ol>",
        },
        {
          name: "should join together similar style inline tags on joining",
          startContent: "<p>a<b>b<i>c</i></b></p><p><b><i>|d</i>e</b>f</p>",
          selectionResult: "<p>a<b>b<i>c|d</i>e</b>f</p>",
        },
        {
          name: "should join together similar style inline tags on joining including matching A elements",
          startContent: '<p>a<b>b<a href="abc">c</a></b></p><p><b><a href="abc">|d</a>e</b>f</p>',
          selectionResult: '<p>a<b>b<a href="abc">c|d</a>e</b>f</p>',
        },
        {
          name: "should join together similar style inline tags on joining excluding A elements with different hrefs",
          startContent: '<p>a<b>b<a href="abc">c</a></b></p><p><b><a href="123">|d</a>e</b>f</p>',
          selectionResult: '<p>a<b>b<a href="abc">c</a>|<a href="123">d</a>e</b>f</p>',
        },
        {
          name: "should not merge into an block with insertable tag",
          startContent: "<insertable widget-id='asdf1234'></insertable><p>|asdf</p>",
          domResult: '<insertable widget-id="asdf1234"></insertable><p>asdf</p>',
          selectionResult: '<insertable widget-id="asdf1234"></insertable><p>|asdf</p>',
        },
      ],
    },
    Delete: {
      key: "delete",
      tests: [
        {
          name: "should join next paragraph to this one if at end of line",
          startContent: "<p>ab|</p><p>cd</p>",
          selectionResult: "<p>ab|cd</p>",
        },
        {
          name: "should join next block's content to this paragraph if at end of line",
          startContent: "<p>ab|</p><h1>cd</h1>",
          selectionResult: "<p>ab|cd</p>",
        },
        {
          name: "should not handle event if visible whitespace afterwards",
          startContent: "<p>ab|&nbsp;</p><p>cd</p>",
          domResult: "<p>ab&nbsp;</p><p>cd</p>",
          selectionResult: "<p>ab|&nbsp;</p><p>cd</p>",
        },
        {
          name: "should not handle event if content afterwards",
          startContent: "<p>ab|cd</p><p>ef</p>",
          domResult: "<p>abcd</p><p>ef</p>",
          selectionResult: "<p>ab|cd</p><p>ef</p>",
        },
        {
          name: "should not handle if just before style tag with more content after",
          startContent: "<p>abc|<b>def</b></p>",
          domResult: "<p>abc<b>def</b></p>",
          selectionResult: "<p>abc|<b>def</b></p>",
        },
        {
          name: "should not handle if at end of style tag with more content before",
          startContent: "<p>abc<b>def|</b>ghi</p>",
          domResult: "<p>abc<b>def</b>ghi</p>",
          selectionResult: "<p>abc<b>def|</b>ghi</p>",
        },
        {
          name: "should join list element onto paragraph correctly",
          startContent: "<p>ab|</p><ol><li>cd</li></ol>",
          selectionResult: "<p>ab|cd</p>",
        },
        {
          name: "should join only first list element onto paragraph if multiple",
          startContent: "<p>ab|</p><ol><li>cd</li><li>ef</li></ol>",
          selectionResult: "<p>ab|cd</p><ol><li>ef</li></ol>",
        },
        {
          name: "should join together similar style inline tags on joining",
          startContent: "<p>a<b>b<i>c|</i></b></p><p><b><i>d</i>e</b>f</p>",
          selectionResult: "<p>a<b>b<i>c|d</i>e</b>f</p>",
        },
        {
          name: "should not remove insertable after block",
          startContent: "<p>asdf|</p><insertable widget-id='asdf1234'></insertable>",
          domResult: '<p>asdf</p><insertable widget-id="asdf1234"></insertable>',
          selectionResult: '<p>asdf|</p><insertable widget-id="asdf1234"></insertable>',
        },
      ],
    },
    "List triggering": {
      key: "space",
      tests: [
        {
          name: "should not trigger a list if in first position",
          startContent: "<p>|<br></p>",
          domResult: "<p><br></p>",
          selectionResult: "<p>|<br></p>",
        },
        {
          name: "should not trigger a list if in first position (alternative caret position)",
          startContent: "<p><br>|</p>",
          domResult: "<p><br></p>",
          selectionResult: "<p><br>|</p>",
        },
        {
          name: "should trigger an unordered list if space after '-' character",
          startContent: "<p>-|</p>",
          selectionResult: "<ul><li>|<br></li></ul>",
        },
        {
          name: "should trigger an unordered list if space after '+' character",
          startContent: "<p>+|</p>",
          selectionResult: "<ul><li>|<br></li></ul>",
        },
        {
          name: "should trigger an ordered list if space after '1.' string",
          startContent: "<p>1.|</p>",
          selectionResult: "<ol><li>|<br></li></ol>",
        },
        {
          name: "should merge into an existing unordered list",
          startContent: "<ul><li><br></li></ul><p>-|</p>",
          selectionResult: "<ul><li><br></li><li>|<br></li></ul>",
        },
        {
          name: "should merge into an existing ordered list",
          startContent: "<ol><li><br></li></ol><p>1.|</p>",
          selectionResult: "<ol><li><br></li><li>|<br></li></ol>",
        },
        {
          name: "should not merge into an existing ordered list if triggering unordered list",
          startContent: "<ol><li><br></li></ol><p>+|</p>",
          selectionResult: "<ol><li><br></li></ol><ul><li>|<br></li></ul>",
        },
        {
          name: "should not merge into an existing unordered list if triggering ordered list",
          startContent: "<ul><li><br></li></ul><p>1.|</p>",
          selectionResult: "<ul><li><br></li></ul><ol><li>|<br></li></ol>",
        },
        {
          name: "should not trigger list if there is style node with content before trigger characters",
          startContent: "<p><b>a</b>1.|</p>",
          domResult: "<p><b>a</b>1.</p>",
          selectionResult: "<p><b>a</b>1.|</p>",
        },
      ],
    },
  };
}.call(this));
