(function () {
  var Preview, copy;

  copy = require("Common/Utils/CopyToClipboard")["default"];

  Preview = require("Common/Utils/ImageImports").Preview;

  module.exports = function (createUi, linkUrl, imageLocation, $timeout, utils, options, telemetry) {
    var elemId, getLinkUrl, ref, settings;
    if (_.isFunction(linkUrl)) {
      getLinkUrl = linkUrl;
    } else {
      getLinkUrl = function () {
        return linkUrl;
      };
    }
    settings = _.extend(
      {},
      {
        isDisabled: function () {
          return false;
        },
        onDisabledClick: function () {},
      },
      options,
    );
    elemId = utils.uniqueId();
    return (this.ui = createUi(require("./Link.html"), {
      linkUrl: getLinkUrl,
      previewIcon: Preview,
      viewState: {
        copied: false,
      },
      isDisabled: settings.isDisabled,
      onDisabledClick: settings.onDisabledClick,
      telemetryData: (ref = settings.telemetryData) != null ? ref : null,
      elemId: elemId,
      copy: (function (_this) {
        return function () {
          if (_this.ui.telemetryData) {
            telemetry.trackOnly(
              _this.ui.telemetryData.event,
              _this.ui.telemetryData.attributes,
              _this.ui.telemetryData.options,
            );
          }
          if (_this.ui.isDisabled()) {
            return _this.ui.onDisabledClick();
          } else {
            copy(_this.ui.linkUrl());
            _this.ui.viewState.copied = true;
            return $timeout(function () {
              return (_this.ui.viewState.copied = false);
            }, 3000);
          }
        };
      })(this),
    }));
  };
}.call(this));
