// Generated by CoffeeScript 1.12.7
(function () {
  var SettingsController,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    };

  module.exports = SettingsController = (function () {
    function SettingsController(createUi, make, viewState, block, blockList, $timeout, settingItems) {
      this.toggleItem = bind(this.toggleItem, this);
      this.useItems = bind(this.useItems, this);
      this.registerItems = bind(this.registerItems, this);
      var access, ref, ref1, ref2;
      if (block.$userAction === "view") {
        access = "view";
      } else {
        access = "edit";
      }
      this.commonSettingItems = {
        foldable: {
          name: "Foldable",
          description: "Add an expand and minimise button with a foldable block.",
          ctrl: make(require("./Foldable/Foldable")),
        },
        navigationMenu: {
          name: "Navigation",
          description: "Configure navigation options for this block",
          ctrl: make(require("./NavigationMenu/NavigationMenu")["default"]),
        },
      };
      this.commonSuperpowers = [];
      this.ui = createUi(require("./Settings.html"), {
        items: [],
        viewState: viewState,
        toggleItem: this.toggleItem,
        expandedItem: {},
        onClick: (function (_this) {
          return function (item) {
            var base, ref, ref1;
            if (item.onClick != null && _.isFunction(item.onClick)) {
              return item.onClick();
            } else {
              if ((ref = _this.ui.expandedItem) != null) {
                if (typeof ref.onClose === "function") {
                  ref.onClose();
                }
              }
              if (item.name === ((ref1 = _this.ui.expandedItem) != null ? ref1.name : void 0)) {
                return (_this.ui.expandedItem = {});
              } else {
                _this.ui.expandedItem = item;
                return typeof (base = _this.ui.expandedItem).onOpen === "function" ? base.onOpen() : void 0;
              }
            }
          };
        })(this),
      });
      if ((ref = settingItems.useItems) != null ? ref.length : void 0) {
        this.useItems(settingItems.useItems);
      }
      if (((ref1 = settingItems.items) != null ? ref1.length : void 0) && access === "edit") {
        this.registerItems(settingItems.items);
      }
      if (settingItems.superpowers == null) {
        settingItems.superpowers = [];
      }
      (ref2 = settingItems.superpowers).unshift.apply(ref2, this.commonSuperpowers);
      this.ui.expandedItem = this.ui.items[0];
    }

    /*
      Register setting items specific to a block type.
      @param [Array] items Array of setting items, of the form:
        - `name` The name of the setting
        - `description` A short description of what the setting controls
        - `ctrl` The instantiated UI controller (i.e. ctrl: make(TheCtrl) )
     */

    SettingsController.prototype.registerItems = function (items) {
      return (this.ui.items = this.ui.items.concat(items));
    };

    /*
      Allow specific block types to use shared / common setting items (i.e. foldable)
      @param [Array] itemNames An array of strings, which are names of the generic block settings
      i.e. 'foldable'
     */

    SettingsController.prototype.useItems = function (itemNames) {
      var i, item, len, name, results;
      results = [];
      for (i = 0, len = itemNames.length; i < len; i++) {
        name = itemNames[i];
        item = this.commonSettingItems[name];
        if (item == null) {
          throw new Error("Trying to use an unknown block setting item.");
        } else {
          results.push(this.ui.items.push(item));
        }
      }
      return results;
    };

    /*
      Toggle a given item in the setting menu.
      @param item [Object] the item to toggle.
     */

    SettingsController.prototype.toggleItem = function (item) {
      item = _.find(this.ui.items, item);
      if (item == null) {
        throw new Error("Could not find matching item.");
      }
      return this.ui.onClick(item);
    };

    return SettingsController;
  })();
}.call(this));
