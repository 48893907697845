"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AssetLibraryOverlay = void 0;
var AssetLibrary_1 = require("./AssetLibrary");
var OverlayMode_1 = require("OverlayStack/OverlayMode");
var commonOverlayOptions = {
    className: "assetlibrary-close-button",
    overlayMode: OverlayMode_1.OverlayMode.Modal,
};
var AssetLibraryOverlay = (function () {
    function AssetLibraryOverlay(mountReactComponent, uploadStore, recentlyUsedService, uploadsService, imageService, imageResourceApiService, stockImageService, usingFeature, modal, notifyUser, rootDataStore, overlayStack, mediaUsageReporter, $timeout) {
        this.imageService = imageService;
        this.overlayStack = overlayStack;
        this.mediaUsageReporter = mediaUsageReporter;
        this.$timeout = $timeout;
        this.assetLibraryLauncher = (0, AssetLibrary_1.attachAssetLibrary)(mountReactComponent, uploadStore, recentlyUsedService, uploadsService, imageService, imageResourceApiService, stockImageService, usingFeature, modal, notifyUser, rootDataStore);
    }
    AssetLibraryOverlay.prototype.launchBasicOverlay = function (callback) {
        var _this = this;
        this.$timeout(function () {
            _this.overlayStack.add({
                contentUi: _this.assetLibraryLauncher.launch(function (mediaSource, data, metricSource) { return __awaiter(_this, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4, this.imageService.completeUrl({ mediaSource: mediaSource, data: data })];
                            case 1:
                                _a.sent();
                                callback(mediaSource, data, metricSource);
                                this.mediaUsageReporter.reportUsage(mediaSource, data);
                                this.overlayStack.closeCurrent();
                                return [2];
                        }
                    });
                }); }),
                options: commonOverlayOptions,
            });
        });
    };
    AssetLibraryOverlay.prototype.launchCustomOverlay = function (callback, launchOptions, overlayStackOptions) {
        var _this = this;
        if (launchOptions === void 0) { launchOptions = {}; }
        if (overlayStackOptions === void 0) { overlayStackOptions = {}; }
        this.overlayStack.add({
            contentUi: this.assetLibraryLauncher.launch(function (mediaSource, data, metricSource) {
                callback(mediaSource, data, metricSource);
                _this.overlayStack.closeCurrent();
            }, launchOptions),
            options: __assign(__assign({}, commonOverlayOptions), overlayStackOptions),
        });
    };
    return AssetLibraryOverlay;
}());
exports.AssetLibraryOverlay = AssetLibraryOverlay;
