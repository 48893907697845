"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var UploadUtils_1 = require("Common/Upload/UploadUtils");
var DocUploaderStore = (function () {
    function DocUploaderStore() {
    }
    Object.defineProperty(DocUploaderStore.prototype, "selectedFile", {
        get: function () {
            return this._selectedFile;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(DocUploaderStore.prototype, "errorMessage", {
        get: function () {
            return this._errorMessage;
        },
        enumerable: false,
        configurable: true
    });
    DocUploaderStore.prototype.setSelectedFile = function (selectedFile) {
        this._selectedFile = selectedFile;
    };
    DocUploaderStore.prototype.setErrorMessage = function (eMessage) {
        this._errorMessage = eMessage;
    };
    Object.defineProperty(DocUploaderStore.prototype, "isFileValid", {
        get: function () {
            if (!this.selectedFile) {
                this._errorMessage = "No file selected";
                return false;
            }
            if (!(0, UploadUtils_1.isDocx)(this.selectedFile)) {
                this._errorMessage = "File type not supported";
                return false;
            }
            if ((0, UploadUtils_1.islargeFile)(this.selectedFile, 1024 * 20)) {
                this._errorMessage = "File too large (maximum 20Mb)";
                return false;
            }
            return true;
        },
        enumerable: false,
        configurable: true
    });
    var _a, _b, _c;
    __decorate([
        mobx_1.observable,
        __metadata("design:type", typeof (_a = typeof File !== "undefined" && File) === "function" ? _a : Object)
    ], DocUploaderStore.prototype, "_selectedFile", void 0);
    __decorate([
        mobx_1.observable,
        __metadata("design:type", String)
    ], DocUploaderStore.prototype, "_errorMessage", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], DocUploaderStore.prototype, "selectedFile", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], DocUploaderStore.prototype, "errorMessage", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DocUploaderStore.prototype, "setSelectedFile", null);
    __decorate([
        mobx_1.action.bound,
        __metadata("design:type", Function),
        __metadata("design:paramtypes", [Object]),
        __metadata("design:returntype", void 0)
    ], DocUploaderStore.prototype, "setErrorMessage", null);
    return DocUploaderStore;
}());
exports.default = DocUploaderStore;
