"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getSavedBlockEditStatus = void 0;
var BlockTypes_1 = require("@CommonFrontendBackend/BlockModels/BlockTypes");
var getSavedBlockEditStatus = function (isEditable, canEditQuoteV1, blockType) {
    if (!isEditable) {
        return {
            canEdit: false,
            noEditExplainer: "Only admins can edit this block",
        };
    }
    if (blockType === BlockTypes_1.BlockType.OldQuote && !canEditQuoteV1) {
        return {
            canEdit: false,
            noEditExplainer: "Cannot edit the old version of Quote blocks",
        };
    }
    return {
        canEdit: true,
    };
};
exports.getSavedBlockEditStatus = getSavedBlockEditStatus;
