"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Types_1 = require("@CommonFrontendBackend/Tokens/Types");
var Text_1 = require("ContentTokens/DataModels/Text");
var TokenReplacerService = (function () {
    function TokenReplacerService(replacers) {
        this.replacers = replacers;
    }
    TokenReplacerService.prototype.processToken = function (contentToken, tokenIndexMap) {
        var potentialReplacer = this.findReplacerForToken(contentToken);
        if (potentialReplacer) {
            var currentSubstitutionToken = potentialReplacer.extractSubstitutionToken(contentToken);
            if (currentSubstitutionToken && tokenIndexMap.has(currentSubstitutionToken.index)) {
                return potentialReplacer.createReplacement(contentToken, tokenIndexMap.get(currentSubstitutionToken.index));
            }
            else if ((currentSubstitutionToken === null || currentSubstitutionToken === void 0 ? void 0 : currentSubstitutionToken.apiName) === Types_1.ProductToken) {
                return Text_1.text.createToken("");
            }
        }
        return contentToken;
    };
    TokenReplacerService.prototype.extractSubstitutionToken = function (contentToken) {
        var _a;
        return (_a = this.findReplacerForToken(contentToken)) === null || _a === void 0 ? void 0 : _a.extractSubstitutionToken(contentToken);
    };
    TokenReplacerService.prototype.findReplacerForToken = function (contentToken) {
        return this.replacers.find(function (tokenReplacer) { return tokenReplacer.supports(contentToken); });
    };
    return TokenReplacerService;
}());
exports.default = TokenReplacerService;
