"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var PageAnalytics_1 = require("Analytics/Page/PageAnalytics");
var ProjectAnalyser = (function () {
    function ProjectAnalyser(di) {
        var _this = this;
        this.di = di;
        this.analyse = function (project, overlayOptions, eventOrigin, pageViewId) {
            (0, PageAnalytics_1.openPageAnalyticsModal)(_this.di, project, overlayOptions, eventOrigin, pageViewId);
            return;
        };
    }
    return ProjectAnalyser;
}());
exports.default = ProjectAnalyser;
