"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageEngagementPopoverContent = exports.useCommonPageEngagementPopoverState = void 0;
var react_1 = __importStar(require("react"));
var luxon_1 = require("luxon");
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var Types_1 = require("@CommonFrontendBackend/Projects/Types");
var Utils_1 = require("@CommonFrontendBackend/Projects/Utils");
var Engagement_1 = require("Common/Engagement");
var UseEngagementAlgorithms_1 = require("Library/EngagementLibrary/UseEngagementAlgorithms");
var UseAnalyticsAccountSettings_1 = require("SettingsAngular/Reports/UseAnalyticsAccountSettings");
var inversify_react_1 = require("inversify-react");
var Utils_2 = require("Common/Engagement/Utils");
var event_tracker_types_1 = require("@qwilr/event-tracker-types");
var UsePageEngagement_1 = require("./UsePageEngagement");
var ExponentialBackoff_1 = require("Common/Persist/ExponentialBackoff");
var useCommonPageEngagementPopoverState = function (pageId, initialEngagement) {
    var _a = (0, UsePageEngagement_1.usePageEngagement)(pageId, { initialEngagement: initialEngagement }), engagement = _a.engagement, refresh = _a.refresh, isRefreshing = _a.isRefreshing;
    var _b = usePollForUpdatedEngagement(engagement, refresh), startPolling = _b.startPolling, isPolling = _b.isPolling;
    return { engagement: engagement, refresh: refresh, startPolling: startPolling, isCalculating: isPolling, isRefreshing: isRefreshing };
};
exports.useCommonPageEngagementPopoverState = useCommonPageEngagementPopoverState;
var MAX_RETRIES = 5;
function usePollForUpdatedEngagement(engagement, refresh) {
    var pollingLoopId = (0, react_1.useRef)(0);
    var _a = __read((0, react_1.useState)(false), 2), isPolling = _a[0], setIsPolling = _a[1];
    function startPolling() {
        setIsPolling(true);
        ++pollingLoopId.current;
        pollingLoop(pollingLoopId.current, new Date(engagement.lastCalculated));
    }
    function pollingLoop(myId, lastCalculatedAtStartOfPoll) {
        return __awaiter(this, void 0, Promise, function () {
            var i, result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        i = 0;
                        _a.label = 1;
                    case 1:
                        if (!(i <= MAX_RETRIES)) return [3, 5];
                        return [4, (0, ExponentialBackoff_1.exponentialBackoffPromise)(i + 1)];
                    case 2:
                        _a.sent();
                        if (myId !== pollingLoopId.current)
                            return [2];
                        return [4, refresh()];
                    case 3:
                        result = _a.sent();
                        if (myId !== pollingLoopId.current)
                            return [2];
                        if (new Date(result.lastCalculated).getTime() !== lastCalculatedAtStartOfPoll.getTime()) {
                            setIsPolling(false);
                            return [2];
                        }
                        _a.label = 4;
                    case 4:
                        ++i;
                        return [3, 1];
                    case 5:
                        setIsPolling(false);
                        return [2];
                }
            });
        });
    }
    return { startPolling: startPolling, isPolling: isPolling };
}
var PageEngagementPopoverContent = function (_a) {
    var pageId = _a.pageId, pageStatus = _a.pageStatus, canEditProject = _a.canEditProject, isAdmin = _a.isAdmin, onAnalyticsClick = _a.onAnalyticsClick, engagement = _a.engagement, refresh = _a.refresh, isRefreshing = _a.isRefreshing, isCalculating = _a.isCalculating, startPolling = _a.startPolling;
    var algorithms = (0, UseEngagementAlgorithms_1.useEngagementAlgorithms)().algorithms;
    var _b = (0, Engagement_1.usePageAlgorithmRelations)(pageId), relatedAlgorithms = _b.relatedAlgorithms, updatePageOverride = _b.updatePageOverride, unassignPageOverride = _b.unassignPageOverride;
    var analyticsAccountSettings = (0, UseAnalyticsAccountSettings_1.useAnalyticsAccountSettings)().data;
    var serverTelemetry = (0, inversify_react_1.useInjection)("serverTelemetry");
    var _c = __read((0, react_1.useState)(false), 2), isAlgorithmUpdating = _c[0], setIsAlgorithmUpdating = _c[1];
    var allLoaded = Boolean(algorithms && relatedAlgorithms && analyticsAccountSettings);
    var algorithmForPage = allLoaded && relatedAlgorithms.length > 0
        ?
            algorithms.find(function (_a) {
                var _id = _a._id;
                return _id === relatedAlgorithms[0].algorithmId;
            })
        : undefined;
    var lastCalculatedAlgorithm = allLoaded && algorithms.find(function (_a) {
        var _id = _a._id;
        return _id === engagement.algorithmId;
    });
    var canAssignAlgorithm = isAdmin || ((analyticsAccountSettings === null || analyticsAccountSettings === void 0 ? void 0 : analyticsAccountSettings.engagement.allowCreatorsToAssignPages) && canEditProject);
    (0, react_1.useEffect)(function () {
        serverTelemetry.sendPageEngagementPopoverViewedTelemetry({
            eventProperties: {
                engagementLevel: (0, Utils_2.getEngagementLevelTelemetryName)(engagement.level),
                pageId: pageId,
            },
            eventOrigin: event_tracker_types_1.EventOrigin.SinglePage,
        });
    }, [engagement.level]);
    function onAlgorithmSelect(selectedAlgorithmId) {
        return __awaiter(this, void 0, Promise, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        setIsAlgorithmUpdating(true);
                        if (!(selectedAlgorithmId == null)) return [3, 2];
                        return [4, unassignPageOverride()];
                    case 1:
                        _a.sent();
                        return [3, 4];
                    case 2: return [4, updatePageOverride(selectedAlgorithmId)];
                    case 3:
                        _a.sent();
                        _a.label = 4;
                    case 4:
                        setIsAlgorithmUpdating(false);
                        if (pageStatus === Types_1.ProjectStatus.Live) {
                            startPolling();
                        }
                        return [2];
                }
            });
        });
    }
    return (react_1.default.createElement(kaleidoscope_1.Stack, { direction: "vertical", gap: "m", style: { alignItems: "stretch" } },
        react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal", justify: "center", align: "center", gap: "xxs" },
            react_1.default.createElement(Engagement_1.EngagementIcon, { engagementLevel: engagement.level, isCalculating: isCalculating || isRefreshing }),
            react_1.default.createElement(kaleidoscope_1.Label, null, (0, Engagement_1.getEngagementLevelDisplayName)(engagement.level, isCalculating))),
        allLoaded ? (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement(kaleidoscope_1.Text, { element: "div", size: "xs", align: "center" }, engagementLevelReason(pageStatus, isCalculating, engagement.level, lastCalculatedAlgorithm, engagement.lastCalculated, algorithmForPage)),
            pageStatus === Types_1.ProjectStatus.Live && (react_1.default.createElement(kaleidoscope_1.Button, { onClick: refresh, type: kaleidoscope_1.ButtonType.Secondary, width: "full", buttonState: isCalculating ? kaleidoscope_1.ButtonState.Waiting : undefined, disabled: isCalculating },
                react_1.default.createElement(icons_1.Retry, null),
                " Refresh")),
            react_1.default.createElement(kaleidoscope_1.Button, { width: "full", onClick: onAnalyticsClick },
                react_1.default.createElement(icons_1.Analytics, null),
                " View analytics"),
            canAssignAlgorithm && (react_1.default.createElement(Engagement_1.EngagementSelect, { algorithms: algorithms, value: algorithmForPage ? algorithmForPage._id : null, onChange: onAlgorithmSelect, disabled: isAlgorithmUpdating })),
            isAdmin && (react_1.default.createElement(kaleidoscope_1.Text, { size: "s", align: "center" },
                react_1.default.createElement(kaleidoscope_1.Link, { href: "/#/engagement-library", target: "_blank" }, "Create and manage algorithms"))))) : (react_1.default.createElement(kaleidoscope_1.Spinner, { primary: true }))));
};
exports.PageEngagementPopoverContent = PageEngagementPopoverContent;
function engagementLevelReason(pageStatus, isCalculating, engagementLevel, lastCalculatedAlgorithm, lastCalculated, assignedAlgorithm) {
    var engagementName = (react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", strong: true }, (0, Engagement_1.getEngagementLevelDisplayName)(engagementLevel, isCalculating)));
    if (isCalculating) {
        return react_1.default.createElement(react_1.default.Fragment, null,
            "This page is ",
            engagementName,
            " because its engagement algorithm has changed.");
    }
    if (engagementLevel == undefined) {
        if (assignedAlgorithm == undefined) {
            return react_1.default.createElement(react_1.default.Fragment, null,
                "This page is ",
                engagementName,
                " because it hasn\u2019t been assigned an engagement algorithm.");
        }
        if (!assignedAlgorithm.enabled) {
            return react_1.default.createElement(react_1.default.Fragment, null,
                "This page is ",
                engagementName,
                " because it's engagement algorithm is turned off.");
        }
        if (pageStatus === Types_1.ProjectStatus.Draft) {
            return react_1.default.createElement(react_1.default.Fragment, null,
                "This page is ",
                engagementName,
                " because it is in draft.");
        }
        if (pageStatus !== Types_1.ProjectStatus.Live) {
            return (react_1.default.createElement(react_1.default.Fragment, null,
                "This page is ",
                engagementName,
                " because it is ",
                (0, Utils_1.pageStatusDisplayText)(pageStatus)));
        }
        return (react_1.default.createElement(react_1.default.Fragment, null,
            "This page is ",
            engagementName,
            " because its engagement level has not been calculated yet. Refresh to see if it has changed."));
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        "Algorithm last calculated to ",
        engagementName,
        lastAlgorithmPhase(lastCalculatedAlgorithm),
        lastCalculatedPhrase(lastCalculated),
        ". Refresh to see if it has changed."));
}
function lastCalculatedPhrase(lastCalculated) {
    if (!lastCalculated)
        return undefined;
    var dt = luxon_1.DateTime.fromISO(lastCalculated);
    return " on ".concat(dt.toLocaleString(__assign(__assign({}, luxon_1.DateTime.DATETIME_FULL), { timeZoneName: undefined, hourCycle: "h12" })));
}
function lastAlgorithmPhase(algorithm) {
    if (!algorithm)
        return undefined;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        " from the ",
        react_1.default.createElement(kaleidoscope_1.Text, { size: "xs", strong: true }, algorithm.name),
        " algorithm"));
}
