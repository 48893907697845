// Generated by CoffeeScript 1.12.7
(function () {
  var ToggleWithLabel, template;

  template = require("./ToggleWithLabel.html");

  /*
    A wrapper around q-toggle-button that allows it to interact with a getter and setter function,
    instead of directly manipulating a model.
  
    It currently only works with a synchronous getter and setter
   */

  module.exports = ToggleWithLabel = (function () {
    function ToggleWithLabel(createUi, description, getter, setter) {
      this.refresh = (function (_this) {
        return function () {
          return (_this.ui.flag = getter());
        };
      })(this);
      this.ui = createUi(template, {
        description: description,
        flag: getter(),
        toggle: (function (_this) {
          return function () {
            setter(_this.ui.flag);
            return (_this.ui.flag = getter());
          };
        })(this),
      });
    }

    return ToggleWithLabel;
  })();
}.call(this));
