// Generated by CoffeeScript 1.12.7
(function () {
  var CodeEditorController;

  module.exports = CodeEditorController = (function () {
    function CodeEditorController(createUi, make, model, language, onChange) {
      require("codemirror/mode/css/css");
      if (language == null) {
        language = "css";
      }
      this.ui = createUi(require("Common/CodeEditor/CodeEditor.html"), {
        rawStyles: model,
        $init: function ($scope) {
          return ($scope.editorOptions = {
            mode: language,
            onLoad: (function (_this) {
              return function (editor) {
                var doc;
                doc = editor.getDoc();
                editor.focus();
                return editor.on("change", function (editorInstance) {
                  var value;
                  value = doc.getValue();
                  model = value;
                  return onChange(value);
                });
              };
            })(this),
          });
        },
      });
    }

    return CodeEditorController;
  })();
}.call(this));
