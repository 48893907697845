(function () {
  module.exports = function (createUi, item, account, currency, itemViewState, ratesPanel, user, telemetry) {
    return (this.ui = createUi(require("Project/Modules/Quote/Item/Rate/RateSelector/RateSelector.html"), {
      item: item,
      rates: account.settings.modules.quote.rates,
      itemViewState: itemViewState,
      currency: currency,
      selectRate: (function (_this) {
        return function (rate) {
          item.rate = rate;
          itemViewState.showRateSelector = false;
          user.useHistory.quote.lastRateUsed = rate.name;
          return telemetry.trackOnly(
            "Quote Block Rate Applied",
            {},
            {
              blockIntercom: true,
            },
          );
        };
      })(this),
      openRatesPanel: (function (_this) {
        return function () {
          return ratesPanel.show();
        };
      })(this),
      isActive: (function (_this) {
        return function (rate) {
          return _this.ui.item.rate.name === rate.name;
        };
      })(this),
      sortRatesBy: "name",
      showSortables: false,
      sortables: [
        {
          property: "name",
          name: "Name",
        },
        {
          property: "rate",
          name: "Price",
        },
      ],
      setSortRatesBy: (function (_this) {
        return function (sortBy) {
          return (_this.ui.sortRatesBy = sortBy);
        };
      })(this),
      isActiveSortable: (function (_this) {
        return function (sortBy) {
          return _this.ui.sortRatesBy === sortBy;
        };
      })(this),
    }));
  };
}.call(this));
