// Generated by CoffeeScript 1.12.7
(function () {
  require("Common/AtomicUI/NumberInput/NumberInput.ng.html");

  module.exports = angular.module("qwilr.qNumberInput", []).directive("qNumberInput", function () {
    return {
      restrict: "EA",
      scope: {
        model: "=",
        disabled: "=?",
        min: "=?",
        max: "=?",
        onIncrement: "=",
        onDecrement: "=",
      },
      templateUrl: "Common/AtomicUI/NumberInput/NumberInput.ng.html",
      controller: function ($scope, $tooltip, $element) {
        if ($scope.disabled == null) {
          $scope.disabled = false;
        }
        $scope.increment = function () {
          if ($scope.max && $scope.model >= $scope.max) {
            return;
          }
          $scope.model++;
          if ($scope.onIncrement != null) {
            return $scope.onIncrement();
          }
        };
        return ($scope.decrement = function () {
          if ($scope.min && $scope.model <= $scope.min) {
            return;
          }
          $scope.model--;
          if ($scope.onDecrement != null) {
            return $scope.onDecrement();
          }
        });
      },
    };
  });
}.call(this));
