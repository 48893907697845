// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (createUi, section, quoteService, closeActionsPanel) {
    return (this.ui = createUi(require("Project/Modules/Quote/Section/Actions/Actions.html"), {
      close: closeActionsPanel,
      moveUp: function () {
        return quoteService.moveSectionUp(section);
      },
      moveDown: function () {
        return quoteService.moveSectionDown(section);
      },
    }));
  };
}.call(this));
