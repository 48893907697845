"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = {
    Quote: {
        controller: require("Project/Modules/Quote/Quote"),
        menu: require("Project/Modules/Quote/QuoteMenu"),
        wiring: require("Project/Modules/Quote/QuoteWiring"),
        model: require("@CommonFrontendBackend/BlockModels/Quote"),
    },
    Video: {
        controller: require("Project/Modules/Video/Video"),
        menu: require("Project/Modules/Video/VideoMenu").default,
        model: require("@CommonFrontendBackend/BlockModels/Video"),
    },
    Splash: {
        controller: require("Project/Modules/Splash/Splash").default,
        menu: require("Project/Modules/Splash/SplashMenu").default,
        model: require("@CommonFrontendBackend/BlockModels/Splash"),
    },
    Text: {
        controller: require("Project/Modules/Text/Text").default,
        menu: require("Project/Modules/Text/TextMenu").default,
        model: require("@CommonFrontendBackend/BlockModels/Text"),
    },
    Source: {
        controller: require("Project/Modules/Source/Source"),
        menu: require("Project/Modules/Source/SourceMenu").default,
        model: require("@CommonFrontendBackend/BlockModels/Source"),
    },
    Accept: {
        controller: require("Project/Modules/Accept/Accept").default,
        menu: require("Project/Modules/Accept/AcceptMenu").default,
        model: require("@CommonFrontendBackend/BlockModels/Accept").default,
        wiring: require("Project/Modules/Accept/AcceptWiring").default,
    },
    QuoteV2: {
        controller: require("Project/Modules/QuoteV2/QuoteV2").default,
        menu: require("Project/Modules/QuoteV2/Menu/Settings"),
        wiring: require("Project/Modules/QuoteV2/QuoteV2Wiring"),
        model: require("@CommonFrontendBackend/BlockModels/QuoteV2").default,
    },
    Browser: {
        controller: require("Project/Browser/Browser"),
        model: require("Project/Browser/Model").default,
        wiring: require("Project/Browser/BrowserWiring"),
    },
    Agreement: {
        controller: require("Project/Modules/Agreement/Agreement").default,
        model: require("@CommonFrontendBackend/BlockModels/Agreement").default,
    },
};
