"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var UserSignupEntryPoints = require("@CommonFrontendBackend/User/types").UserSignupEntryPoints;
function default_1($rootScope, $state, $location, $urlRouter, user, isMobile, projectLiveLimit, $timeout) {
    var _a, _b, _c;
    $rootScope.$on("$stateChangeStart", function (e, toState, $stateParams) {
        if (isMobile()) {
            if (toState.name !== "dashboard" &&
                !(toState.name === "dashboardProjectAction" && $stateParams.action === "analytics") &&
                !(toState.name === "projectAction" && $stateParams.action === "analytics")) {
                e.preventDefault();
                $state.go("dashboard", { nav: "pages" });
            }
        }
        if (projectLiveLimit.getOverLimitAmount() > 0) {
            if (!["dashboard", "settings"].includes(toState.name)) {
                e.preventDefault();
                $state.go("dashboard", { nav: "pages" });
            }
        }
    });
    if (user.useHistory.sessionCount <= 1) {
        if (((_c = (_b = (_a = user.useHistory) === null || _a === void 0 ? void 0 : _a.signup) === null || _b === void 0 ? void 0 : _b.entryPoint) === null || _c === void 0 ? void 0 : _c.type) === UserSignupEntryPoints.AdminPanelDocConversion) {
            $timeout(function () { return $location.path("/templates"); });
            $state.go("templates");
        }
        else {
            $timeout(function () { return $location.path("/pages"); });
            $state.go("dashboard", { nav: "pages" });
        }
    }
    else if (["/", ""].includes($location.path())) {
        $timeout(function () { return $location.path("/pages"); });
        $state.go("dashboard", { nav: "pages" });
    }
    return $urlRouter.sync();
}
exports.default = default_1;
