// Generated by CoffeeScript 1.12.7
(function () {
  var HeapAnalyticsIntegration;

  module.exports = HeapAnalyticsIntegration = (function () {
    function HeapAnalyticsIntegration(createUi, genericIntegration) {
      genericIntegration.build.call(this, "heapAnalytics", "Heap Analytics App ID", require("./HeapAnalytics.html"));
    }

    return HeapAnalyticsIntegration;
  })();
}.call(this));
