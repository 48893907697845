"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
var Tour_1 = require("@CommonFrontendBackend/Tour");
var types_1 = require("@CommonFrontendBackend/User/types");
var mobx_1 = require("mobx");
var TourTypes_1 = require("./TourTypes");
var CreateAndEditTour = (function (_super) {
    __extends(CreateAndEditTour, _super);
    function CreateAndEditTour(rootDataStore, overlayStack) {
        var _this = _super.call(this) || this;
        _this.rootDataStore = rootDataStore;
        _this.overlayStack = overlayStack;
        _this.tourId = Tour_1.TourId.CreateAndEditTemplate;
        _this.title = "Create web-based proposals that stand out";
        _this.telemetryId = "standout proposals";
        _this.image = "tour-standout-proposals.png";
        _this.welcomeDescription = "Take a quick tour to learn some of the basics of how to create your first Qwilr page";
        _this.stepsConfig = [
            {
                step: Tour_1.CreateAndEditTemplateTourStepsId.ChooseATemplate,
                title: "Choose a template",
                description: "Explore templates and pick one that works for you. Click “Create page” when you’re ready",
                stepType: TourTypes_1.TourStepConfigurationType.Regular,
                video: "create-edit-template/new-choose-template.mp4",
                videoLarge: "create-edit-template/new-choose-template-large.mp4",
            },
            {
                step: Tour_1.CreateAndEditTemplateTourStepsId.EditText,
                title: "Edit text",
                description: "Highlight text and start typing to edit. Use the toolbar to change its style",
                stepType: TourTypes_1.TourStepConfigurationType.Regular,
                video: "create-edit-template/edit-text.mp4",
                videoLarge: "create-edit-template/edit-text-large.mp4",
            },
            {
                step: Tour_1.CreateAndEditTemplateTourStepsId.AddAnImage,
                title: "Add an image",
                description: "Create a new line within a block and click the plus button. You’ll see a list of widgets you can add. For now let’s add an image",
                stepType: TourTypes_1.TourStepConfigurationType.Regular,
                video: "create-edit-template/add-image.mp4",
                videoLarge: "create-edit-template/add-image-large.mp4",
            },
            {
                step: Tour_1.CreateAndEditTemplateTourStepsId.AddABlock,
                title: "Add a block",
                description: "Qwilr pages are made up of “blocks” of content. Add a new block by clicking the plus between two blocks and select a block type",
                stepType: TourTypes_1.TourStepConfigurationType.Regular,
                video: "create-edit-template/add-block.mp4",
                videoLarge: "create-edit-template/add-block-large.mp4",
            },
            {
                step: Tour_1.CreateAndEditTemplateTourStepsId.ShareWithClients,
                title: "Share with clients",
                description: "When you’re ready to send your page, click share and change the page from draft to live. Once it’s live you can copy the shareable link",
                stepType: TourTypes_1.TourStepConfigurationType.Regular,
                video: "create-edit-template/share-page.mp4",
                videoLarge: "create-edit-template/share-page-large.mp4",
            },
            {
                step: Tour_1.CreateAndEditTemplateTourStepsId.ExploreTheDashboard,
                title: "Explore the dashboard",
                description: "The dashboard is where you create and manage pages. Click the Qwilr logo in the top left to return to the dashboard",
                stepType: TourTypes_1.TourStepConfigurationType.Regular,
                video: "create-edit-template/dashboard.mp4",
                videoLarge: "create-edit-template/dashboard-large.mp4",
            },
            {
                step: Tour_1.CreateAndEditTemplateTourStepsId.CheckTheReports,
                title: "Check the reports",
                description: "The reports are always accessible from the top menu. Improve your pipeline’s health with Sales velocity and performance metrics",
                stepType: TourTypes_1.TourStepConfigurationType.Regular,
                video: "create-edit-template/pipeline-report.mp4",
                videoLarge: "create-edit-template/pipeline-report.mp4",
                enabled: function () { return true; },
            },
            {
                step: Tour_1.CreateAndEditTemplateTourStepsId.IntroductionComplete,
                title: "🎉 Standout proposals tour completed!",
                description: "Now you've got the basics down you're all set to start more guides or explore on your own",
                stepType: TourTypes_1.TourStepConfigurationType.Completion,
            },
        ];
        return _this;
    }
    Object.defineProperty(CreateAndEditTour.prototype, "priority", {
        get: function () {
            var order = 100;
            return {
                order: order,
                startingStep: this.rootDataStore.userDataStore.useHistoryDataStore.userEntryPoint === types_1.UserSignupEntryPoints.Template
                    ? Tour_1.CreateAndEditTemplateTourStepsId.EditText
                    : Tour_1.CreateAndEditTemplateTourStepsId.ChooseATemplate,
            };
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CreateAndEditTour.prototype, "steps", {
        get: function () {
            return this.stepsConfig.filter(function (step) { return step.enabled === undefined || step.enabled(); });
        },
        enumerable: false,
        configurable: true
    });
    CreateAndEditTour.prototype.onTourStart = function () {
        this.overlayStack.closeAll();
        var templatesUrl = "#/templates";
        if (!window.location.hash.startsWith(templatesUrl)) {
            window.location.href = templatesUrl;
        }
    };
    var _a;
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_a = typeof TourTypes_1.TourPriority !== "undefined" && TourTypes_1.TourPriority) === "function" ? _a : Object),
        __metadata("design:paramtypes", [])
    ], CreateAndEditTour.prototype, "priority", null);
    return CreateAndEditTour;
}(TourTypes_1.Tour));
exports.default = CreateAndEditTour;
