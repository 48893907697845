"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AutoLinkExpiryController = void 0;
var luxon_1 = require("luxon");
var mobx_1 = require("mobx");
var Types_1 = require("@CommonFrontendBackend/Projects/Types");
var AutoLinkExpiry_1 = require("@CommonFrontendBackend/Project/AutoLinkExpiry");
var AutoLinkExpiryController = (function () {
    function AutoLinkExpiryController(projectStore) {
        this.projectStore = projectStore;
        this.DEFAULT_EXPIRE_AFTER_DAYS = 30;
        this.timeLimitDisabledThisSession = false;
    }
    Object.defineProperty(AutoLinkExpiryController.prototype, "daysUntilExpiry", {
        get: function () {
            var _a = this.autoLinkExpiryStore, startDate = _a.startDate, expireAfterDays = _a.expireAfterDays;
            return (0, AutoLinkExpiry_1.daysUntilLinkExpiry)(startDate, expireAfterDays);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoLinkExpiryController.prototype, "expiryDate", {
        get: function () {
            var _a = this.autoLinkExpiryStore, startDate = _a.startDate, expireAfterDays = _a.expireAfterDays;
            return (0, AutoLinkExpiry_1.calcExpiryDate)(startDate, expireAfterDays);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoLinkExpiryController.prototype, "isTemplate", {
        get: function () {
            return this.projectStore.isTemplate;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoLinkExpiryController.prototype, "enabled", {
        get: function () {
            return this.autoLinkExpiryStore.enabled;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoLinkExpiryController.prototype, "projectStatus", {
        get: function () {
            return this.projectStore.status;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoLinkExpiryController.prototype, "startDate", {
        get: function () {
            return this.autoLinkExpiryStore.startDate;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoLinkExpiryController.prototype, "timeLimit", {
        get: function () {
            return this.projectStore.linkStore.timeLimit;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoLinkExpiryController.prototype, "wasTimeLimitDisabledThisSession", {
        get: function () {
            return this.timeLimitDisabledThisSession;
        },
        enumerable: false,
        configurable: true
    });
    AutoLinkExpiryController.prototype.setEnabled = function (enabled) {
        if (enabled) {
            if (this.autoLinkExpiryStore.expireAfterDays == undefined) {
                this.autoLinkExpiryStore.setExpireAfterDays(this.DEFAULT_EXPIRE_AFTER_DAYS);
            }
            if (this.canStartExpiryTimer && this.autoLinkExpiryStore.startDate == undefined) {
                this.autoLinkExpiryStore.startExpiryTimer();
            }
            if (this.timeLimit.forever === false) {
                this.timeLimitDisabledThisSession = true;
                this.projectStore.linkStore.disableTimeLimit();
            }
        }
        else {
            this.autoLinkExpiryStore.setExpireAfterDays(this.daysUntilExpiry);
            this.autoLinkExpiryStore.clearStartDate();
        }
        this.autoLinkExpiryStore.setEnabled(enabled);
    };
    AutoLinkExpiryController.prototype.setDaysUntilExpiry = function (days) {
        if (this.autoLinkExpiryStore.startDate == undefined) {
            this.autoLinkExpiryStore.setExpireAfterDays(days);
        }
        else {
            var timeSinceStart = Date.now() - this.autoLinkExpiryStore.startDate.getTime();
            this.autoLinkExpiryStore.setExpireAfterDays(Math.floor(luxon_1.Duration.fromMillis(timeSinceStart).as("days")) + days);
        }
    };
    Object.defineProperty(AutoLinkExpiryController.prototype, "canStartExpiryTimer", {
        get: function () {
            return this.projectStore.status === Types_1.ProjectStatus.Live && !this.isTemplate;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AutoLinkExpiryController.prototype, "autoLinkExpiryStore", {
        get: function () {
            return this.projectStore.linkStore.autoLinkExpiryStore;
        },
        enumerable: false,
        configurable: true
    });
    var _a;
    __decorate([
        mobx_1.observable,
        __metadata("design:type", Object)
    ], AutoLinkExpiryController.prototype, "timeLimitDisabledThisSession", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Number),
        __metadata("design:paramtypes", [])
    ], AutoLinkExpiryController.prototype, "daysUntilExpiry", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", typeof (_a = typeof Date !== "undefined" && Date) === "function" ? _a : Object),
        __metadata("design:paramtypes", [])
    ], AutoLinkExpiryController.prototype, "expiryDate", null);
    return AutoLinkExpiryController;
}());
exports.AutoLinkExpiryController = AutoLinkExpiryController;
