"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mobx_1 = require("mobx");
var Serialise_1 = require("./Tokenization/Serialise");
var Saver = (function () {
    function Saver(model, $timeout) {
        this.model = model;
        this.$timeout = $timeout;
    }
    Saver.prototype.save = function (value) {
        this.model.tokens = (0, Serialise_1.serialise)(value);
        this.$timeout();
    };
    Saver.prototype.tokeniseWithSelection = function (value, currentSelection) {
        return (0, Serialise_1.serialise)((0, mobx_1.toJS)(value), (0, mobx_1.toJS)(currentSelection), true);
    };
    return Saver;
}());
exports.default = Saver;
