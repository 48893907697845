// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (blockList) {
    return function (index) {
      var blockBefore;
      if (index > 0) {
        blockBefore = blockList.getBlockAtIndex(index - 1);
        return (blockBefore.$blockBrowserOpen = false);
      }
    };
  };
}.call(this));
