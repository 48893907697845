// Generated by CoffeeScript 1.12.7
(function () {
  var SubscriptionSequencer, maybeDisplaySubscriptionMobileMessaging;

  maybeDisplaySubscriptionMobileMessaging = require("./MobileMessaging").maybeDisplaySubscriptionMobileMessaging;

  module.exports = SubscriptionSequencer = (function () {
    var template;

    template = require("./SubscriptionSequencer.html");

    function SubscriptionSequencer(di, createUi, Sequencer, steps, overlayStack, options) {
      var goBack, makeCurrentStep, onComplete, sequence, subscriptionDI;
      if (options.onHitEnd == null) {
        options.onHitEnd = function () {
          return overlayStack.closeCurrent();
        };
      }
      if (options.onCancel == null) {
        options.onCancel = function () {
          return overlayStack.closeCurrent();
        };
      }
      subscriptionDI = di.child();
      subscriptionDI.value("onCancel", options.onCancel);
      sequence = subscriptionDI.instantiate(Sequencer, {
        items: steps,
        options: {
          onChange: (function (_this) {
            return function (item) {
              return makeCurrentStep();
            };
          })(this),
          onHitEnd: function () {
            return options.onHitEnd(subscriptionDI);
          },
        },
      });
      onComplete = function () {
        return sequence.next();
      };
      goBack = function () {
        return sequence.prev();
      };
      makeCurrentStep = (function (_this) {
        return function () {
          var step;
          step = subscriptionDI.instantiate(sequence.getCurrentItem(), {
            onComplete: onComplete,
            goBack: goBack,
          });
          return (_this.ui.currentStep = step);
        };
      })(this);
      this.ui = createUi(template, {
        currentStep: null,
      });
      overlayStack.add({
        contentUi: this.ui,
        options: {},
      });
      di.invoke(maybeDisplaySubscriptionMobileMessaging);

      /*
      This needs to be done after the card gets added otherwise
      the constructor for the first step does not have access to
      change the overlay settings (e.g. closebutton)
       */
      makeCurrentStep();
    }

    return SubscriptionSequencer;
  })();
}.call(this));
