// Generated by CoffeeScript 1.12.7
(function () {
  var FeatureFlag;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  module.exports = function (createUi, domFrame, $state, usingFeature) {
    var ui;
    ui = createUi(require("./MarkedTemplateExplainer.html"), {
      visible: true,
      explainer: "Your page has been converted into a template. Your templates can be found under Library > Templates.",
      hide: function () {
        return (ui.visible = false);
      },
      goToTemplates: function () {
        ui.visible = false;
        return $state.go("dashboard", {
          nav: "templates",
        });
      },
    });
    return domFrame.add(ui);
  };
}.call(this));
