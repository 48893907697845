"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var EditorTypes_1 = require("../../EditorTypes");
var BehaviourBuilder_1 = require("../BehaviourBuilder");
var ROICalculatorActions_1 = require("./ROICalculatorActions");
exports.default = (function (di, router) {
    var telemetry = di.get("editorTelemetry");
    return {
        onUpdateROICalculatorVariableInputs: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("updateRoiCalculatorVariableInputs")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "updateVariableInputs" })
            .doAction(function (event, editor) {
            var _a = event.data, path = _a.path, toUpdate = _a.toUpdate;
            (0, ROICalculatorActions_1.updateVariableInputs)(editor, path, toUpdate);
            return true;
        }),
        onUpdateROICalculatorOptions: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("updateRoiOptions")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "updateOptions" })
            .doAction(function (event, editor) {
            (0, ROICalculatorActions_1.updateROICalculatorWidget)(editor, event.data.path, event.data.toUpdate);
            return true;
        }),
        onUpdateROICalculatorRichText: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("updateRoiCalculatorRichText")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "updateRichText" })
            .doAction(function (event, editor) {
            var _a = event.data, path = _a.path, richTextKey = _a.richTextKey, value = _a.value;
            (0, ROICalculatorActions_1.updateRoiCalculatorRichText)(editor, path, richTextKey, value);
            return true;
        }),
        onAddROICalculatorVariableInput: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("addROICalculatorVariableInput")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "addVariableInput" })
            .doAction(function (event, editor) {
            var _a = event.data, path = _a.path, count = _a.count;
            (0, ROICalculatorActions_1.addROICalculatorVariableInput)(editor, path);
            telemetry.roiCalculatorAddVariableInput(count);
            return true;
        }),
        onRemoveROICalculatorVariableInput: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("removeROICalculatorVariableInput")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "removeVariableInput" })
            .doAction(function (event, editor) {
            var _a = event.data, path = _a.path, count = _a.count;
            (0, ROICalculatorActions_1.updateROICalculatorWidget)(editor, path, event.data.toUpdate);
            telemetry.roiCalculatorDeleteVariableInput(count);
            return true;
        }),
        onPickROICalculatorImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("pickROICalculatorImage")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "pickImage" })
            .doAction(function (event, editor) {
            var path = event.data.path;
            (0, ROICalculatorActions_1.pickBackgroundImage)(di, editor, path, router);
            return true;
        }),
        onAddROICalculatorImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("addROICalculatorImage")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "addImage" })
            .doAction(function (event, editor) {
            var _a = event.data, path = _a.path, image = _a.image;
            (0, ROICalculatorActions_1.addBackgroundImage)(editor, path, image, telemetry);
            return true;
        }),
        onUploadROICalculatorImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("uploadROICalculatorImage")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "uploadImage" })
            .doAction(function (event, editor) {
            var _a = event.data, path = _a.path, url = _a.url;
            (0, ROICalculatorActions_1.updateROICalculatorWidget)(editor, path, { backgroundImageUrl: url, backgroundImageUploadId: "" });
            telemetry.roiCalculatorBackgroundImageAdded();
            return true;
        }),
        onRemoveROICalculatorImage: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("removeROICalculatorImage")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "removeImage" })
            .doAction(function (event, editor) {
            var path = event.data.path;
            (0, ROICalculatorActions_1.updateROICalculatorWidget)(editor, path, { backgroundImageUrl: "", backgroundImageUploadId: "" });
            telemetry.roiCalculatorBackgroundImageRemoved();
            return true;
        }),
        onSelectROICalculatorPreset: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("applyROICalculatorPresetSettings")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "selectPreset" })
            .doAction(function (event, editor) {
            var _a = event.data, path = _a.path, id = _a.id;
            (0, ROICalculatorActions_1.applyRoiPreset)(editor, path, id);
            telemetry.roiPresetSelected(id);
            return true;
        }),
        trackEditROICalculatorOption: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("editROICalculatorOption")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "editOption" })
            .doAction(function (event) {
            var _a = event.data, element = _a.element, key = _a.key, value = _a.value;
            (0, ROICalculatorActions_1.trackEditOption)(telemetry, element, key, value);
            return true;
        }),
        trackROICalculatorSliderInteraction: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("trackROICalculatorSliderInteraction")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "trackSliderInteraction" })
            .doAction(function () {
            telemetry.roiCalculatorSliderInteraction();
            return true;
        }),
        trackROICalculatorVariableInputInteraction: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("trackROICalculatorVariableInteraction")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "trackVariableInteraction" })
            .doAction(function (event) {
            var _a = event.data, count = _a.count, position = _a.position;
            telemetry.roiCalculatorVariableInputInteraction(count, position);
            return true;
        }),
        trackROITourModalClosed: di
            .make(BehaviourBuilder_1.BehaviourBuilder)
            .isCalled("trackROITourModalClosed")
            .isEvent(EditorTypes_1.EventType.Widget)
            .withData({ widget: "roiCalculator", action: "tourModalClosed" })
            .doAction(function (event) {
            if (event.data.tourStarted) {
                telemetry.roiCalculatorTourStarted();
            }
            else {
                telemetry.roiCalculatorTourSkipped();
            }
            return true;
        }),
    };
});
