// Generated by CoffeeScript 1.12.7

/*
  This module provides a frame for universal DOM.

  Originally, to get something rendered into the DOM in a "global" context
  (i.e. overlays, modals, popouts etc. etc.) we needed to add an entry in our app.html,
  and then add the UI object to the scope of the top-level app, and then thread the UI object through
  to the HTML. A lot of work!

  This module simplifies all that, by allowing for registering these "global" DOM
  objects in code. Making the expectations more explicit and removing clutter from the DOM.
 */

(function () {
  var DomFrame;

  module.exports = DomFrame = (function () {
    function DomFrame(createUi, utils, modal) {
      this.ui = createUi(require("./DomFrame.html"), {
        frames: [],
      });

      /*
        Add a UI to the DOM Frame list.
        @api public
       */
      this.add = (function (_this) {
        return function (ui) {
          var id;
          id = utils.uniqueId();
          _this.ui.frames.push({
            ui: ui,
            id: id,
          });
          return id;
        };
      })(this);

      /*
        Removes a UI from the DOM Frame list.
        @api public
       */
      this.remove = (function (_this) {
        return function (id) {
          return _.remove(_this.ui.frames, {
            id: id,
          });
        };
      })(this);
      this.add(modal.container);
    }

    return DomFrame;
  })();
}.call(this));
