(function () {
  var ChangeNameController;

  module.exports = ChangeNameController = (function () {
    function ChangeNameController(
      $http,
      $timeout,
      user,
      createUi,
      apiBaseUrl,
      make,
      AsyncButton,
      telemetry,
      $q,
      utils,
      getFullName,
    ) {
      var name, oldName, onClick, submitButton;
      name = getFullName(user);
      oldName = name;
      onClick = (function (_this) {
        return function () {
          var names, newName;
          newName = _this.ui.name;
          names = utils.extractNames(_this.ui.name);
          return $http
            .post(apiBaseUrl + "/users/update", {
              firstName: names.firstName,
              lastName: names.lastName,
            })
            .then(function (response) {
              // we do want to use a track (including identify call) here.
              // TODO(alton,2024-03-27) Move this telemetry to Server side, after DB has been updated.
              telemetry.track("Name Changed", {
                name: newName,
                name_old: oldName,
                first_name: names.firstName,
                last_name: names.lastName,
              });
              user.firstName = names.firstName;
              user.lastName = names.lastName;
              telemetry.flushHubspot();
              return (oldName = newName);
            });
        };
      })(this);
      submitButton = new AsyncButton(
        onClick,
        "Update Name",
        null,
        function (response) {
          var ref, ref1;
          if (((ref = response.data) != null ? ((ref1 = ref.error) != null ? ref1.message : void 0) : void 0) != null) {
            return "#change-name-form input";
          }
        },
        function (response) {
          var ref, ref1;
          return (
            ((ref = response.data) != null ? ((ref1 = ref.error) != null ? ref1.message : void 0) : void 0) ||
            response.message ||
            "An error has occurred while trying to update your name, please try again later."
          );
        },
      );
      this.ui = createUi(require("./ChangeName.html"), {
        submitButton: submitButton.ui,
        name: name,
        isFieldDisabled: function () {
          return submitButton.state === "waiting";
        },
        unlockButton: (function (_this) {
          return function () {
            var ref;
            if (oldName !== _this.ui.name && ((ref = submitButton.state) === "disabled" || ref === "success")) {
              return (submitButton.state = "idle");
            }
          };
        })(this),
      });
    }

    return ChangeNameController;
  })();
}.call(this));
