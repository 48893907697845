// Generated by CoffeeScript 1.12.7

/*
 * When starting a trial via the Plan Explorer, we want to show a loading screen while the request
 * is being made to the server.
 */

(function () {
  var StartingTrialScreen, template;

  template = require("./StartingTrialScreen.html");

  module.exports = StartingTrialScreen = (function () {
    function StartingTrialScreen($q, $log, createUi, paymentService, account, onComplete) {
      paymentService
        .startBusinessTrial(account._id)
        .then(
          (function (_this) {
            return function (plan) {
              return (account.plan = plan);
            };
          })(this),
        )
        .then(function () {
          return onComplete();
        })
        ["catch"](
          (function (_this) {
            return function (error) {
              $log.error(error.message);
              return $q.reject(new Error("Could not change to business trial"));
            };
          })(this),
        );
      this.ui = createUi(template, {});
    }

    return StartingTrialScreen;
  })();
}.call(this));
