"use strict";
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
function default_1(account) {
    var _a, _b;
    var hasV8Plan = (_b = (_a = account.plan) === null || _a === void 0 ? void 0 : _a.internalName) === null || _b === void 0 ? void 0 : _b.includes("_v8");
    var plans = {
        displayPlans: {
            starter: {
                displayName: "Starter",
                tier: 0,
                monthlyPrice: 0,
                annualPrice: 0,
                currency: "USD",
                seats: 1,
                minSeat: 1,
                explainer: "",
                features: {
                    general: __spreadArray(__spreadArray(["pages"], __read((!hasV8Plan ? ["quote and accept blocks"] : [])), false), ["payments"], false),
                    collaboration: ["editing permissions"],
                    analytics: ["notifications", "view counts"],
                    support: ["help centre"],
                },
                alternativeIcons: {
                    pages: !hasV8Plan
                        ? "/Assets/icons/features/projects-limit.svg"
                        : "/Assets/icons/features/projects-limit-10.svg",
                    payments: "/Assets/icons/features/payment.svg",
                },
            },
            pro: {
                displayName: "Pro",
                tier: 1,
                monthlyPrice: 15,
                annualPrice: 12,
                currency: "USD",
                seats: 1,
                minSeat: 1,
                explainer: "For individuals",
                features: {
                    general: ["pages", "quote and accept blocks", "payments"],
                    collaboration: ["editing permissions"],
                    analytics: ["notifications", "view counts"],
                    support: ["help centre"],
                },
                alternativeIcons: {
                    pages: "/Assets/icons/features/projects.svg",
                    payments: "/Assets/icons/features/payment.svg",
                },
            },
            business: {
                displayName: "Business",
                tier: 2,
                monthlyPrice: 39,
                annualPrice: 35,
                currency: "USD",
                seats: 1,
                minSeat: 1,
                explainer: "For teams",
                features: {
                    general: ["pages", "quote and accept blocks", "payments", "forms", "e-sign", "audit trail"],
                    collaboration: ["editing permissions"],
                    sharing: ["custom subdomain", "typekit fonts", "qwilr badge removed"],
                    analytics: ["notifications", "view counts", "advanced"],
                    security: ["password", "time limits", "view limits"],
                    integrations: ["hubspot", "integrations", "zapier"],
                    support: ["help centre", "priority email support"],
                },
                alternativeIcons: {
                    pages: "/Assets/icons/features/projects.svg",
                    payments: "/Assets/icons/features/payment.svg",
                },
            },
            enterprise: {
                displayName: "Enterprise",
                tier: 3,
                annualPrice: 59,
                currency: "USD",
                seats: 1,
                minSeat: 10,
                explainer: "For organizations",
                features: {
                    general: ["pages", "quote and accept blocks", "payments", "forms", "e-sign", "audit trail"],
                    collaboration: ["editing permissions", "team permissions", "brand permissions"],
                    sharing: ["custom subdomain", "typekit fonts", "qwilr badge removed", "custom domain", "custom fonts"],
                    analytics: ["notifications", "view counts", "advanced"],
                    security: ["password", "time limits", "view limits", "identity verification"],
                    integrations: ["hubspot", "integrations", "zapier", "salesforce"],
                    support: ["help centre", "priority email support", "account manager", "template conversion"],
                },
                alternativeIcons: {
                    pages: "/Assets/icons/features/projects.svg",
                    payments: "/Assets/icons/features/payment.svg",
                },
            },
        },
        featureCategories: {
            general: {
                pages: {
                    icon: "/Assets/icons/features/projects.svg",
                    tooltip: {
                        normal: "Unlimited, easy-to-create web pages you can share anywhere using a link",
                    },
                },
                "quote and accept blocks": {
                    icon: "/Assets/icons/features/business-blocks.svg",
                    tooltip: {
                        normal: "Create interactive pricing and allow your clients to accept in one smooth flow",
                    },
                },
                payments: {
                    icon: "/Assets/icons/features/payment.svg",
                    tooltip: {
                        normal: "Accept clients to instantly pay you for your quotes via Stripe",
                    },
                },
                forms: {
                    icon: "/Assets/icons/features/forms.svg",
                    tooltip: {
                        normal: "Collect relevant information from your clients after they accept your page using custom forms",
                    },
                },
                "e-sign": {
                    icon: "/Assets/icons/features/esign.svg",
                    tooltip: {
                        normal: "Formalize agreements with electronic signatures from multiple clients",
                    },
                },
                "audit trail": {
                    icon: "/Assets/icons/features/audit-trail.svg",
                    tooltip: {
                        normal: "Keep track of your proposal process, including accept, sign-off, payment, and confirmation",
                    },
                },
            },
            collaboration: {
                "editing permissions": {
                    icon: "/Assets/icons/features/editing-permissions.svg",
                    tooltip: {
                        normal: "Give your team edit or view-only access to protect important documents",
                    },
                },
                "team permissions": {
                    icon: "/Assets/icons/features/team-permissions.svg",
                    tooltip: {
                        normal: "Set up teams to easily share pages and change permissions",
                    },
                },
                "brand permissions": {
                    icon: "/Assets/icons/features/brand-permissions.svg",
                    tooltip: {
                        normal: "Protect your brand colors and fonts from accidental changes",
                    },
                },
            },
            sharing: {
                "custom subdomain": {
                    icon: "/Assets/icons/features/domain.svg",
                    tooltip: {
                        normal: "Your Qwilr Pages will be accessible from a branded URL (yourbusiness.qwilr.com)",
                        downgrade: "You’ll lose your subdomain and won’t be able to view any existing page links",
                    },
                },
                "typekit fonts": {
                    icon: "/Assets/icons/features/typekit.svg",
                    tooltip: {
                        normal: "Add Typekit fonts for use in Qwilr Pages across your account",
                    },
                },
                "qwilr badge removed": {
                    icon: "/Assets/icons/features/badge.svg",
                    tooltip: {
                        normal: "Your audience won’t see any Qwilr branding on your pages",
                    },
                },
                "custom domain": {
                    icon: "/Assets/icons/features/domain.svg",
                    tooltip: {
                        normal: "Your Qwilr Pages will be accessible from a branded URL that doesn’t contain any reference to qwilr (documents.yourbusiness.com)",
                    },
                },
                "custom fonts": {
                    icon: "/Assets/icons/features/custom-fonts.svg",
                    tooltip: {
                        normal: "Upload your custom fonts for use in Qwilr Pages across your account",
                    },
                },
            },
            analytics: {
                notifications: {
                    icon: "/Assets/icons/features/notifications.svg",
                    tooltip: {
                        normal: "Receive instant email or Slack notifications whenever your pages are viewed or accepted",
                    },
                },
                "view counts": {
                    icon: "/Assets/icons/features/views.svg",
                    tooltip: {
                        normal: "See how many times your page was viewed",
                    },
                },
                advanced: {
                    icon: "/Assets/icons/features/analytics.svg",
                    tooltip: {
                        normal: "Get insights into which sections of your page performed best, and other demographic info on your audience",
                    },
                },
            },
            security: {
                password: {
                    icon: "/Assets/icons/features/security.svg",
                    tooltip: {
                        normal: "Protect your pages with custom passwords",
                    },
                },
                "time limits": {
                    icon: "/Assets/icons/features/limit-time.svg",
                    tooltip: {
                        normal: "Protect your pages from being viewed beyond specified dates",
                    },
                },
                "view limits": {
                    icon: "/Assets/icons/features/limit-view.svg",
                    tooltip: {
                        normal: "Protect your pages from being viewed more than a limited number of times",
                    },
                },
                "identity verification": {
                    icon: "/Assets/icons/features/identity-verification.svg",
                    tooltip: {
                        normal: "Request your page viewers identify themselves through email verification",
                    },
                },
            },
            integrations: {
                hubspot: {
                    icon: "/Assets/icons/features/hubspot.svg",
                    tooltip: {
                        normal: "Automatically produce personalized pages for clients and update clients’ CRM records",
                    },
                },
                integrations: {
                    icon: "/Assets/icons/features/integrations.svg",
                    tooltip: {
                        normal: "Connect with other accounting, live chat, analytics, and remarketing tools",
                    },
                },
                zapier: {
                    icon: "/Assets/icons/features/zapier.svg",
                    tooltip: {
                        normal: "Integrate with 1,000+ other apps via Zapier to automate your custom workflows",
                    },
                },
                salesforce: {
                    icon: "/Assets/icons/features/salesforce.svg",
                    tooltip: {
                        normal: "Automatically produce personalized Qwilr Pages for clients and update clients’ CRM records",
                    },
                },
            },
            support: {
                "help centre": {
                    icon: "/Assets/icons/features/help-centre.svg",
                    tooltip: {
                        normal: "A comprehensive set of guides to help you and your team make the most of Qwilr",
                    },
                },
                "priority email support": {
                    icon: "/Assets/icons/features/priority-email.svg",
                    tooltip: {
                        normal: "Get faster responses from our Support team",
                    },
                },
                "account manager": {
                    icon: "/Assets/icons/features/manager.svg",
                    tooltip: {
                        normal: "An assigned account manager will help you and your team get the most out of Qwilr",
                    },
                },
                "template conversion": {
                    icon: "/Assets/icons/features/conversion.svg",
                    tooltip: {
                        normal: "Our internal designers will convert an existing document into a Qwilr page",
                    },
                },
            },
        },
    };
    if (account.plan.downgradePlan === null) {
        delete plans.displayPlans["starter"];
    }
    return plans;
}
exports.default = default_1;
