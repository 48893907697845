"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var SharingSettings = (function () {
    function SharingSettings(createUi, project, projectSharer) {
        this.allowIndexing = project.allowIndexing;
        this.enableSocialBadge = project.enableSocialBadge;
        var sharingSettings = [
            {
                label: "Findable on search engines",
                model: this.allowIndexing,
                clickHandle: function (allowIndexing) {
                    return projectSharer.setIndexing(project, allowIndexing);
                },
            },
            {
                label: "Enable social share buttons",
                model: this.enableSocialBadge,
                clickHandle: function (enableSocialBadge) {
                    return projectSharer.setSocialBadge(project, enableSocialBadge);
                },
            },
        ];
        this.hasSharingSettingsEnabled = function () {
            var e_1, _a;
            try {
                for (var sharingSettings_1 = __values(sharingSettings), sharingSettings_1_1 = sharingSettings_1.next(); !sharingSettings_1_1.done; sharingSettings_1_1 = sharingSettings_1.next()) {
                    var setting = sharingSettings_1_1.value;
                    if (setting.model) {
                        return true;
                    }
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (sharingSettings_1_1 && !sharingSettings_1_1.done && (_a = sharingSettings_1.return)) _a.call(sharingSettings_1);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return false;
        };
        this.ui = createUi(require("./SharingSettings.html"), { sharingSettings: sharingSettings });
    }
    return SharingSettings;
}());
exports.default = SharingSettings;
