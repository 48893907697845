// Generated by CoffeeScript 1.12.7

/*

  This module is a generic FontList component designed to be reused across the
  different font selector vendor panels. It displays a list of fonts with
  infinite scrolling and lets the user select one.

  It takes an options object with the following callbacks, all optional:
    - onSelected: called when the font is selected
    - onRemove: called when the X button is clicked (no X buttons if not set)
    - loadFont: called with a font when that font should be loaded
                (if this is specified it must return a promise)
    - filter: called with a font to determine whether that font should be displayed

  The font object should have at least the following properties:
    - family: the font family (ie, something you could pass to CSS font-family)
    - name: the friendly name of that font for use in display, defaults to the family

  To initialise the font list, you need to call .updateFonts on it, which will
  remove the loading spinner and populate the list.
 */

(function () {
  var FontList;

  module.exports = FontList = (function () {
    function FontList(createUi, make, $log, account, noop, options) {
      var currentFonts, filter, fonts, loadFont, onSelected;
      onSelected = options.onSelected || noop;
      loadFont = options.loadFont;
      filter =
        options.filter ||
        function () {
          return true;
        };
      currentFonts = account.settings.brand.fonts;
      fonts = [];
      this.ui = createUi(require("./FontList.html"), {
        fonts: [],
        loaded: false,
        loadedFonts: {},
        inProgressFonts: {},
        limit: 20,
        addItems: (function (_this) {
          return function () {
            if (!_this.ui.loaded) {
              return;
            }
            _this.ui.limit = Math.max(20, Math.min(_this.ui.limit + 10, fonts.length));
            return _this.updateUiFonts();
          };
        })(this),
        uiLoadFont: (function (_this) {
          return function (family) {
            if (!_this.ui.loaded || _this.ui.loadedFonts[family] || _this.ui.inProgressFonts[family]) {
              return;
            }
            if (loadFont) {
              _this.ui.inProgressFonts[family] = true;
              return loadFont(family)
                .then(function () {
                  _this.ui.loadedFonts[family] = true;
                  return (_this.ui.inProgressFonts[family] = false);
                })
                ["catch"](function (err) {
                  $log.warn(err);
                  return setTimeout(function () {
                    return (_this.ui.inProgressFonts[family] = false);
                  }, 500);
                });
            } else {
              return (_this.ui.loadedFonts[family] = true);
            }
          };
        })(this),
        selectFont: function (font) {
          return onSelected(font);
        },
        removeFont: options.onRemove,
        getFontUsage: (function (_this) {
          return function (family) {
            var descriptor;
            if (!currentFonts) {
              return null;
            }
            descriptor = [];
            if (family === currentFonts.h1.name) {
              descriptor.push("heading");
            }
            if (family === currentFonts.h2.name) {
              descriptor.push("subheading");
            }
            if (family === currentFonts.p.name) {
              descriptor.push("paragraph");
            }
            if (descriptor.length === 0) {
              return null;
            } else if (descriptor.length === 3) {
              return "current";
            } else {
              return descriptor.join(" & ");
            }
          };
        })(this),
      });
      this.updateUiFonts = (function (_this) {
        return function () {
          var font, i, len, ref, results;
          _this.ui.fonts = fonts.filter(filter).slice(0, _this.ui.limit);
          ref = _this.ui.fonts;
          results = [];
          for (i = 0, len = ref.length; i < len; i++) {
            font = ref[i];
            results.push(_this.ui.uiLoadFont(font.family));
          }
          return results;
        };
      })(this);
      this.updateFonts = (function (_this) {
        return function (_fonts) {
          _this.ui.loaded = true;
          fonts = _fonts;
          _this.ui.limit = 20;
          return _this.updateUiFonts();
        };
      })(this);
      this.updateFilter = (function (_this) {
        return function (_filter) {
          filter = _filter;
          _this.ui.fonts = fonts.filter(filter);
          _this.ui.limit = 20;
          return _this.updateUiFonts();
        };
      })(this);
      this.reset = (function (_this) {
        return function () {
          _this.ui.loaded = false;
          _this.ui.limit = 20;
          fonts = [];
          return (_this.ui.fonts = []);
        };
      })(this);
    }

    return FontList;
  })();
}.call(this));
