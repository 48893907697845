/*
  @deprecated with the "Sidebar" feature flag turned on for all accounts, this module is no
  longer being used.

  This module provides UI for searching through a saved block list,
  as well as initiating actions like: delete, clone and edit.

  @TODO (som, 2019-02-07): this is built on top of some very old code, and it really should be
  re-written from scratch. Some things I've noticed while working on this:
  - The saved block 'cards' should ideally be abstracted out into individual UI components. What
    makes it difficult right now is the tight binding between the model and view - e.g this module
    renders and *modifies* the `savedBlockList` directly without any abstraction
  - The 'decoration' applied to blocks is only correct for the last instance opened. e.g if you open
    an instance of this, the 'preview' and 'edit block' hints stop working on previous instances
  - Some optimisations had to be added to make the interface not grind to a halt with large numbers
    of saved blocks. The optimisations themselves are not hacky, but it makes this module more
    confusing due to the lack of good abstractions here
 */

(function () {
  var DEFAULT_LIST_LIMIT, SavedBlockNavigator, SavedBlockOrigin, cloneDeep, getSavedBlockEditStatus, omit;

  cloneDeep = require("lodash").cloneDeep;

  omit = require("lodash").omit;

  SavedBlockOrigin = require("../../../../../CommonFrontend/Telemetry/TelemetryEnums.ts").SavedBlockOrigin;

  getSavedBlockEditStatus = require("../../../SavedBlock/EditingRules").getSavedBlockEditStatus;

  DEFAULT_LIST_LIMIT = 20;

  module.exports = SavedBlockNavigator = (function () {
    function SavedBlockNavigator(
      createUi,
      savedBlockDataStore,
      hasBlockType,
      hint,
      make,
      invoke,
      blockDisplaySettings,
      savedBlockService,
      savedBlockOpener,
      account,
      notifyUser,
      permissionsService,
      rolesService,
      user,
      showDeleteModal,
      getBlockTypeIcon,
      $timeout,
      telemetry,
      replaceBlock,
      $log,
      Search,
      uniqueId,
      blockList,
      block,
      resetBrowserStateOnPrecedingBlock,
    ) {
      var actions,
        actionsDropdown,
        activeBlockTypes,
        allBlockTypes,
        buildTooltip,
        decorateBlock,
        deleteSavedBlock,
        domId,
        filterByName,
        filterByType,
        increaseLimit,
        initialDecorateBlock,
        lastFilter,
        lazyCreateActionsDropdown,
        order,
        resetResults,
        scrollingElement,
        searchComponent;
      actionsDropdown = require("./ActionsDropdown/Dropdown");
      domId = "saved-blocks-" + uniqueId();
      lastFilter = null;
      scrollingElement = null;
      if (!hasBlockType("Quote")) {
        order = ["text", "splash", "video", "source", "quotev2", "accept"];
      } else {
        order = ["text", "quote", "video", "splash", "source"];
      }
      allBlockTypes = blockDisplaySettings.getAllSettings();
      activeBlockTypes = {};
      order.forEach(function (item) {
        var blockType;
        blockType = allBlockTypes[item];
        activeBlockTypes[item] = blockType;
        return hint.small({
          targetSelector: ".saved-blocks__block-type-" + blockType.cssClass,
          hint: "View " + blockType.displayName + " blocks",
          position: "top",
        });
      });
      actions = [
        {
          name: "Preview",
          icon: "/Assets/icons/large/preview-32.svg",
          class: "preview",
          onClick: function (block, $event) {
            $event.stopPropagation();
            return savedBlockOpener.preview(block, block.$editable, "editor");
          },
        },
        {
          name: "Edit",
          icon: "/Assets/icons/pencil.svg",
          class: "edit",
          onClick: function (block, $event) {
            var canEdit, noEditExplainer, ref;
            $event.stopPropagation();
            (ref = getSavedBlockEditStatus(block.$editable, false, block.type)),
              (canEdit = ref.canEdit),
              (noEditExplainer = ref.noEditExplainer);
            if (!canEdit) {
              return notifyUser.error(noEditExplainer);
            } else {
              return savedBlockOpener.edit(block, "editor");
            }
          },
          isHidden: function (block) {
            return block.type === "Quote";
          },
        },
      ];
      buildTooltip = function (selector, text) {
        var tooltip;
        tooltip = hint.small({
          targetSelector: ".saved-block " + selector,
          position: "top",
          hint: text,
        });
        return tooltip;
      };

      /*
        The bare-minimum decoratation of the saved block, to calculate just the things the UI needs
        when the user hasn't hovered over the saved block card yet

        @NOTE (som, 2019-02-07): this was separated out from `decorateBlock()` to allow us to:
        - Offload expensive actions like creating the hint tooltips until they are needed
        - Allow us to use `ng-if="block.$isFullyDecorated"` on parts of the DOM to delay their
          creation until they are needed. `<ng-include>` is incredibly expensive when done many times
          inside an ng-repeat!
        This is just an optimisation, but a very necessary one - the app was becoming unusable with
        large numbers of saved blocks
       */
      initialDecorateBlock = (function (_this) {
        return function (block) {
          return (block.$isFullyDecorated = false);
        };
      })(this);

      /*
        Completes the decoration of a block with the action buttons and tooltips, when the use
        hovers over the block
        @private
        @param block - block to decorate
       */
      decorateBlock = (function (_this) {
        return function (block) {
          var editAction, editTooltipText;
          block.$isFullyDecorated = true;
          block.$actions = _.cloneDeep(actions);
          if (!block.$editable) {
            editAction = _.find(block.$actions, {
              name: "Edit",
            });
            editAction.icon = "/Assets/icons/pencil-disabled.svg";
            editAction.disabled = true;
          }
          block.$addTooltip = buildTooltip(".add-" + block.id, "Add block");
          block.$previewTooltip = buildTooltip(".preview-" + block.id, "Preview");
          block.$lockTooltip = buildTooltip(".lock-" + block.id, "Only admins can edit");
          if (block.$editable) {
            editTooltipText = "Edit block";
          } else {
            editTooltipText = "Only admins can edit";
          }
          return (block.$editTooltip = buildTooltip(".edit-" + block.id, editTooltipText));
        };
      })(this);
      lazyCreateActionsDropdown = (function (_this) {
        return function (savedBlock) {
          if (savedBlock.$actionsDropdown == null) {
            return (savedBlock.$actionsDropdown = invoke(actionsDropdown, null, {
              selector: ".more-actions-" + savedBlock.id,
              onDropdownHide: function (blockHiddenOn) {
                var ref;
                if (((ref = _this.ui.activeBlock) != null ? ref.id : void 0) === blockHiddenOn.id) {
                  return (_this.ui.activeBlock = null);
                }
              },
              block: savedBlock,
              deleteSavedBlock: deleteSavedBlock,
            }));
          }
        };
      })(this);
      deleteSavedBlock = (function (_this) {
        return function (block) {
          return savedBlockService["delete"](block.id);
        };
      })(this);
      filterByType = (function (_this) {
        return function (blockType) {
          var blockFilterType;
          lastFilter = [filterByType, blockType];
          resetResults();
          _this.ui.activeBlockType = blockType;
          if (blockType == null) {
            return (_this.ui.filteredUserBlocks = _this.ui.userBlocks);
          } else {
            blockFilterType = blockType.blockModel.type;
            telemetry.trackOnly(
              "Saved Block Library Filtered",
              {
                saved_block_origin: SavedBlockOrigin.Editor,
                saved_block_library_filtered_by: blockFilterType,
              },
              {},
            );
            return (_this.ui.filteredUserBlocks = _.filter(_this.ui.userBlocks, function (block) {
              return block.type === blockFilterType;
            }));
          }
        };
      })(this);
      filterByName = (function (_this) {
        return function (searchTerm, searchAction) {
          if (searchAction === "focus" || searchAction === "blur") {
            return;
          }
          lastFilter = [filterByName, searchTerm];
          resetResults();
          _this.ui.activeBlockType = null;
          return (_this.ui.filteredUserBlocks = _.filter(_this.ui.userBlocks, function (block) {
            var blockName;
            telemetry.debouncedTrack(
              "Saved Block Library Filtered",
              {
                saved_block_origin: SavedBlockOrigin.Editor,
                saved_block_library_filtered_by: "search",
              },
              {},
              5000,
            );
            blockName = block.savedBlockName || block.name || "";
            return blockName.toUpperCase().includes(searchTerm != null ? searchTerm.toUpperCase() : void 0);
          }));
        };
      })(this);
      searchComponent = new Search("Search saved blocks", filterByName, {
        theme: "fit-container",
      });
      increaseLimit = (function (_this) {
        return function () {
          return (_this.ui.listLimitTo += DEFAULT_LIST_LIMIT);
        };
      })(this);
      resetResults = (function (_this) {
        return function () {
          _this.ui.listLimitTo = DEFAULT_LIST_LIMIT;
          return scrollingElement.scrollTop(0);
        };
      })(this);
      this.ui = createUi(require("./SavedBlockNavigator.html"), {
        domId: domId,
        activeBlockType: null,
        blockTypes: activeBlockTypes,
        userBlocks: savedBlockDataStore.savedBlocks,
        filteredUserBlocks: savedBlockDataStore.savedBlocks,
        showingBlockSearch: false,
        getIcon: getBlockTypeIcon,
        searchComponent: searchComponent.ui,
        listLimitTo: DEFAULT_LIST_LIMIT,
        loadingMoreResults: false,
        filterByType: (function (_this) {
          return function (blockType) {
            if (blockType === _this.ui.activeBlockType) {
              blockType = null;
            }
            return filterByType(blockType);
          };
        })(this),
        insertSavedBlock: function (savedBlock) {
          var errorMessage;
          if (savedBlock != null) {
            replaceBlock(savedBlock);
            return resetBrowserStateOnPrecedingBlock(blockList.getIndex(block));
          } else {
            errorMessage = "An error occurred trying to insert that block";
            return notifyUser.error(errorMessage);
          }
        },
        toggleActionsDropdown: (function (_this) {
          return function (block, $event) {
            var ref, ref1, ref2;
            $event.stopPropagation();
            if ((ref = block.$actionsDropdown) != null ? ref.ui.visible : void 0) {
              block.$actionsDropdown.hide();
              return (_this.ui.activeBlock = null);
            } else {
              if ((ref1 = _this.ui.activeBlock) != null) {
                if ((ref2 = ref1.$actionsDropdown) != null) {
                  ref2.hide();
                }
              }
              _this.ui.activeBlock = block;
              lazyCreateActionsDropdown(block);
              return block.$actionsDropdown.show();
            }
          };
        })(this),
        mouseEnter: (function (_this) {
          return function (hoveredBlock) {
            if (!hoveredBlock.$isFullyDecorated) {
              return decorateBlock(hoveredBlock);
            }
          };
        })(this),

        /*
          Show a very quick loading indicator when the user hits infinite scroll point.
          This is a purely artificial loading time to make it less jarring when a bunch of new
          cards are suddenly added
         */
        loadMoreResults: (function (_this) {
          return function () {
            if (!_this.ui.loadingMoreResults) {
              _this.ui.loadingMoreResults = true;
              return $timeout(function () {
                _this.ui.loadingMoreResults = false;
                return increaseLimit();
              }, 500);
            }
          };
        })(this),
        $onElementRendered: (function (_this) {
          return function (element, property) {
            if (property === "saved-blocks-list") {
              return (scrollingElement = element);
            }
          };
        })(this),
        $init: (function (_this) {
          return function ($scope) {
            $scope.savedBlocks = _this.ui.userBlocks;
            return $scope.$watchCollection("savedBlocks", function () {
              var _block, arg, filterFunc, i, len, ref;
              ref = _this.ui.userBlocks;
              for (i = 0, len = ref.length; i < len; i++) {
                _block = ref[i];
                initialDecorateBlock(_block);
              }
              if (lastFilter != null) {
                (filterFunc = lastFilter[0]), (arg = lastFilter[1]);
                return filterFunc(arg);
              }
            });
          };
        })(this),
      });
    }

    return SavedBlockNavigator;
  })();
}.call(this));
