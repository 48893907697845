// Generated by CoffeeScript 1.12.7

/*
  Attaches 'key' event handlers to tags
 */

(function () {
  var KeyEventHandlers, h;

  h = require("./Helpers");

  module.exports = KeyEventHandlers = (function () {
    function KeyEventHandlers(make, editor, undo) {
      /*
        Tag Specific Handlers
       */
      var blockQuoteHandler,
        headingHandler,
        i,
        insertableHandler,
        len,
        liHandler,
        pHandler,
        placeholderHandler,
        rangedKeyHandler,
        ref,
        tag,
        undoHandler;
      pHandler = make(require("./Handlers/Paragraph"));
      liHandler = make(require("./Handlers/List"));
      headingHandler = make(require("./Handlers/Headings"));
      blockQuoteHandler = make(require("./Handlers/BlockQuote"));
      placeholderHandler = make(require("./Handlers/Placeholders"));
      rangedKeyHandler = make(require("./Handlers/RangedKey"));
      undoHandler = make(require("./Handlers/Undo"));
      insertableHandler = make(require("./Handlers/Insertable"));

      /*
        Placeholder handlers
       */
      ref = ["P", "H1", "H2"];
      for (i = 0, len = ref.length; i < len; i++) {
        tag = ref[i];
        editor.registry.addTagHandler(tag, placeholderHandler);
      }

      /*
        Handlers for each tag type
       */
      editor.registry.addTagHandler("P", pHandler);
      editor.registry.addTagHandler("LI", liHandler);
      editor.registry.addTagHandler("H1", {
        onKey: _.curry(headingHandler.onKey)("H1"),
      });
      editor.registry.addTagHandler("H2", {
        onKey: _.curry(headingHandler.onKey)("H2"),
      });
      editor.registry.addTagHandler("BLOCKQUOTE", blockQuoteHandler);
      editor.registry.addTagHandler("INSERTABLE", insertableHandler);
      editor.addListener({
        onRangedKey: undoHandler.onKey,
      });
      editor.addListener({
        onKey: undoHandler.onKey,
        onContent: undoHandler.onContent,
        onRangedKey: rangedKeyHandler.onRangedKey,
      });
    }

    return KeyEventHandlers;
  })();
}.call(this));
