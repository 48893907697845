"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableWidgetDimensionToolbar = exports.TABLE_WIDGET_DIMENSION_TOOLBAR_ACTIVATION_BUFFER = exports.TABLE_WIDGET_DIMENSION_TOOLBAR_HIDE_DELAY_MS = void 0;
var kaleidoscope_1 = require("@qwilr/kaleidoscope");
var icons_1 = require("@qwilr/kaleidoscope/icons");
var react_1 = __importStar(require("react"));
var styles = __importStar(require("./TableWidgetDimensionToolbar.css"));
var react_transition_group_1 = require("react-transition-group");
var utils_1 = require("@qwilr/kaleidoscope/utils");
exports.TABLE_WIDGET_DIMENSION_TOOLBAR_HIDE_DELAY_MS = 200;
exports.TABLE_WIDGET_DIMENSION_TOOLBAR_ACTIVATION_BUFFER = 64;
var TableWidgetDimensionToolbar = function (_a) {
    var children = _a.children, onDelete = _a.onDelete, setHoverDelete = _a.setHoverDelete, _b = _a.size, size = _b === void 0 ? "medium" : _b, inActivationZone = _a.inActivationZone, mode = _a.mode, canAdd = _a.canAdd, canDelete = _a.canDelete;
    var _c = __read((0, react_1.useState)(false), 2), inToolbar = _c[0], setInToolbar = _c[1];
    var _d = __read((0, react_1.useState)(false), 2), showPopover = _d[0], onChangePopover = _d[1];
    return (react_1.default.createElement(react_transition_group_1.Transition, { in: inActivationZone || showPopover || inToolbar, mountOnEnter: true, unmountOnExit: true, onEnter: utils_1.forceReflow, onExit: utils_1.forceReflow, timeout: kaleidoscope_1.AnimationDuration.Short }, function (status) { return (react_1.default.createElement("div", { contentEditable: false, className: styles.wrapper({ status: status }) },
        react_1.default.createElement(kaleidoscope_1.Layer, { offset: 20 }, function (_a) {
            var zIndex = _a.zIndex;
            return (react_1.default.createElement("div", { onMouseEnter: function () {
                    setInToolbar(true);
                }, onMouseLeave: function () {
                    setInToolbar(false);
                }, style: {
                    zIndex: zIndex,
                }, className: styles.hoverableIsland({ mode: mode }) },
                react_1.default.createElement(kaleidoscope_1.Panel, { className: styles.panel },
                    react_1.default.createElement(kaleidoscope_1.Stack, { direction: "horizontal" },
                        react_1.default.createElement(kaleidoscope_1.IconButton, { size: kaleidoscope_1.ButtonSize.XSmall, isDestructive: true, icon: react_1.default.createElement(icons_1.Delete, null), onClick: onDelete, tooltip: { content: "Delete ".concat(mode) }, onMouseEnter: function () { return setHoverDelete(true); }, onMouseLeave: function () { return setHoverDelete(false); }, disabled: !canDelete, disabledReason: "Can't delete last ".concat(mode) }),
                        canAdd && (react_1.default.createElement(kaleidoscope_1.OptionMenu, { size: size, isOpen: showPopover, onClose: function () { return onChangePopover(false); }, button: react_1.default.createElement(kaleidoscope_1.IconButton, { size: kaleidoscope_1.ButtonSize.XSmall, icon: react_1.default.createElement(icons_1.Add, null), onClick: function () {
                                    onChangePopover(!showPopover);
                                } }), autoStack: true }, children))))));
        }))); }));
};
exports.TableWidgetDimensionToolbar = TableWidgetDimensionToolbar;
