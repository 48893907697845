// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = function (createUi, modalAngular, $q, invoke, Button) {
    /*
      Acknowledge a modal
      @param options
        - title [String] the title of the modal
        - explainer [String|UiObject] the explainer of the modal. It can either be a string or a UI
          object. The preference is for string elements but if you need to add HTML, use the UI
          object.
        - button [String] the text of the button. Defaults to "Ok"
      @return modalResult [Promise] resolves to:
        - true if clicking the ok button
        - null if escaping or clicking away
     */
    var getExplainer, showModal;
    showModal = function (arg) {
      var button, explainer, showing, title;
      (title = arg.title), (explainer = arg.explainer), (button = arg.button);
      showing = $q.defer();
      modalAngular.show(
        createUi(require("./AcknowledgeModal.html"), {
          title: title,
          explainer: getExplainer(explainer),
          button: new Button(
            function () {
              modalAngular.hide();
              return showing.resolve(true);
            },
            button || "Ok",
            {
              size: "large",
              width: "half",
            },
          ).ui,
        }),
        {
          onEscape: function () {
            return showing.resolve(null);
          },
        },
      );
      return showing.promise;
    };
    getExplainer = invoke(require("../ExplainerText/ExplainerText"));
    return showModal;
  };
}.call(this));
