// Generated by CoffeeScript 1.12.7
(function () {
  var FeatureFlag,
    HubspotObjectType,
    ProjectTagger,
    ProviderType,
    SalesforceObjectType,
    observe,
    ref,
    bind = function (fn, me) {
      return function () {
        return fn.apply(me, arguments);
      };
    },
    indexOf =
      [].indexOf ||
      function (item) {
        for (var i = 0, l = this.length; i < l; i++) {
          if (i in this && this[i] === item) return i;
        }
        return -1;
      };

  observe = require("mobx").observe;

  FeatureFlag = require("@CommonFrontendBackend/FeatureFlag").FeatureFlag;

  (ref = require("@CommonFrontendBackend/Tokens/Types")),
    (ProviderType = ref.ProviderType),
    (SalesforceObjectType = ref.SalesforceObjectType),
    (HubspotObjectType = ref.HubspotObjectType);

  module.exports = ProjectTagger = (function () {
    function ProjectTagger($http, $log, apiBaseUrl, notifyUser, rootDataStore, usingFeature) {
      this.apiBaseUrl = apiBaseUrl;
      this.notifyUser = notifyUser;
      this.removeTag = bind(this.removeTag, this);
      this.tag = bind(this.tag, this);
      this.http = $http;
      this.log = $log;
      this.projectTagDataStore = rootDataStore.projectTagDataStore;
      this.reservedTag = function (tag) {
        switch (tag) {
          case "archived":
          case "starred":
          case "templateqwilr":
            return true;
          default:
            return false;
        }
      };
      this.isTemplateProject = function (project) {
        return indexOf.call(project.tags, "templateqwilr") >= 0;
      };
      this.isCrmTemplate = function (project) {
        return !project.templateParent && this.getCrmType(project) && this.getCrmObjectType(project);
      };
      this.getCrmType = function (project) {
        return project.crmInfo.integration;
      };
      this.getCrmObjectType = function (project) {
        return project.crmInfo.object;
      };
      this.getCrmObjectTypeForV1Token = function (project) {
        var crmType;
        crmType = this.getCrmType(project);
        if (crmType === ProviderType.Salesforce) {
          if (_.includes(project.tags, CrmObject.Opportunity)) {
            return CrmObject.Opportunity;
          } else if (_.includes(project.tags, CrmObject.Contact)) {
            return CrmObject.Contact;
          } else if (_.includes(project.tags, CrmObject.Lead)) {
            return CrmObject.Lead;
          } else if (_.includes(project.tags, CrmObject.Account)) {
            return CrmObject.Account;
          }
        }
        if (crmType === ProviderType.Hubspot) {
          if (_.includes(project.tags, CrmObject.Contact)) {
            return CrmObject.Contact;
          } else if (_.includes(project.tags, CrmObject.Company)) {
            return CrmObject.Company;
          } else if (_.includes(project.tags, CrmObject.Deal)) {
            return CrmObject.Deal;
          }
        }
      };
      this.getTagMessage = function (projectString, tagName) {
        if (tagName === "archived" || tagName === "starred") {
          return projectString + " " + tagName;
        } else if (tagName === "templateqwilr") {
          return projectString + " marked as template";
        } else {
          return projectString + " tagged";
        }
      };
      this.getUntagMessage = function (projectString, tagName) {
        if (tagName === "archived" || tagName === "starred") {
          return projectString + " un" + tagName;
        } else if (tagName === "templateqwilr") {
          return projectString + " removed as template";
        } else {
          return projectString + " tag removed";
        }
      };
      observe(
        this.projectTagDataStore.projectTags,
        (function (_this) {
          return function (change) {
            return (_this.allTags = _.filter(_this.projectTagDataStore.projectTags, function (tag) {
              return !_this.reservedTag(tag);
            }));
          };
        })(this),
        {
          invokeImmediately: true,
        },
      );
    }

    ProjectTagger.prototype.tag = function (projects, tagName) {
      var projectIds;
      if (!_.isArray(projects)) {
        projects = [projects];
      }
      projectIds = _.map(projects, function (project) {
        return project._id;
      });
      return this.http
        .post(this.apiBaseUrl + "/projects/tag", {
          projects: projectIds,
          tag: tagName,
        })
        .then(
          (function (_this) {
            return function () {
              var projectString;
              if (projects.length === 1) {
                projectString = "Project";
              } else {
                projectString = "Projects";
              }
              _this.notifyUser.success(_this.getTagMessage(projectString, tagName));
              projects.forEach(function (project) {
                return project.tags.push(tagName);
              });
              if (!_.includes(_this.allTags, tagName) && !_this.reservedTag(tagName)) {
                return _this.projectTagDataStore.createTag(tagName);
              }
            };
          })(this),
        )
        ["catch"](
          (function (_this) {
            return function (err) {
              return _this.notifyUser.error("Couldn't add that tag");
            };
          })(this),
        );
    };

    ProjectTagger.prototype.removeTag = function (projects, tagName) {
      var projectIds;
      if (!_.isArray(projects)) {
        projects = [projects];
      }
      projectIds = _.map(projects, function (project) {
        return project._id;
      });
      return this.http["delete"](this.apiBaseUrl + "/projects/tag", {
        data: {
          projects: projectIds,
          tag: tagName,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then(
          (function (_this) {
            return function () {
              var projectString;
              if (projects.length === 1) {
                projectString = "Project";
              } else {
                projectString = "Projects";
              }
              _this.notifyUser.success(_this.getUntagMessage(projectString, tagName));
              return projects.forEach(function (project) {
                return _.pull(project.tags, tagName);
              });
            };
          })(this),
        )
        ["catch"](
          (function (_this) {
            return function (err) {
              return _this.notifyUser.error("Couldn't remove that tag");
            };
          })(this),
        );
    };

    return ProjectTagger;
  })();
}.call(this));
