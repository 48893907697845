// Generated by CoffeeScript 1.12.7
(function () {
  var Discount, MenuTemplate, SectionSettingsMenu, ToggleWithLabel;

  MenuTemplate = require("./SectionSettingsMenu.html");

  ToggleWithLabel = require("./ToggleWithLabel");

  Discount = require("./Discount").Discount;

  module.exports = SectionSettingsMenu = (function () {
    function SectionSettingsMenu(createUi, make, quoteAdapter, sectionView, sectionAdapter) {
      var addToggleWithLabel, discountSetting, itemsViewable, makeMandatory, settings;
      settings = [];
      discountSetting = null;
      itemsViewable = null;
      makeMandatory = null;
      addToggleWithLabel = function (description, getter, setter) {
        var toggle;
        toggle = make(ToggleWithLabel, {
          description: description,
          getter: getter,
          setter: setter,
        });
        settings.push(toggle);
        return toggle;
      };
      this.refresh = function () {
        itemsViewable.refresh();
        makeMandatory.refresh();
        return (makeMandatory.hideThis = quoteAdapter.getSelectionType() === "combined");
      };
      this.ui = createUi(MenuTemplate, {
        getSettings: function () {
          return settings;
        },
      });
      makeMandatory = addToggleWithLabel("Make mandatory", sectionAdapter.isMandatory, function (flag) {
        return sectionAdapter.setMandatory(flag);
      });
      discountSetting = make(Discount, {
        description: "Apply discount",
        discountAdapter: sectionAdapter.getDiscountAdapter(),
      });
      settings.push(discountSetting);
      addToggleWithLabel("Show subtotal", sectionAdapter.showSubtotal, sectionAdapter.setShowSubtotal);
    }

    return SectionSettingsMenu;
  })();
}.call(this));
