"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
var CommentIdService_1 = require("./CommentIdService");
var lodash_1 = require("lodash");
var mobx_1 = require("mobx");
var CommentTypes_1 = require("@CommonFrontendBackend/Comments/Types/CommentTypes");
var CommentServiceTypes_1 = require("./CommentServiceTypes");
var EditorTypes_1 = require("Project/QwilrEditor/EditorTypes");
var CommentGroupStore = (function () {
    function CommentGroupStore(commentService, activeCommentThreadStore, user, telemetry, blockType, blockList, projectStore, startBlockElement, options, comments) {
        var _this = this;
        this.commentService = commentService;
        this.activeCommentThreadStore = activeCommentThreadStore;
        this.user = user;
        this.telemetry = telemetry;
        this.blockType = blockType;
        this.blockList = blockList;
        this.projectStore = projectStore;
        this.startBlockElement = startBlockElement;
        this.setResolved = function (isResolved, resolveOrigin) { return __awaiter(_this, void 0, Promise, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.commentService.toggleCommentThreadResolved(this._id, isResolved)];
                    case 1:
                        result = _a.sent();
                        if (result === CommentServiceTypes_1.CommentResolveOutcome.Success) {
                            this.updateCommentThreadStatus(isResolved);
                            if (this._isResolved) {
                                this.trackCommentThreadResolved(resolveOrigin);
                            }
                            else {
                                this.trackOnly("Comment Thread Unresolved", {});
                            }
                        }
                        return [2, result];
                }
            });
        }); };
        this.updateCommentThreadStatus = function (isResolved) {
            _this._isResolved = isResolved;
        };
        this.toggleShowing = function () {
            var shouldShow = !_this.activeCommentThreadStore.isActiveThread(_this);
            _this.activeCommentThreadStore.clearActiveThread();
            if (!_this.hasComment && !_this._isCreatingThread) {
                _this.deleteThreadLocally();
                return;
            }
            if (shouldShow) {
                _this.activeCommentThreadStore.setActiveThread(_this);
                _this.trackCommentThreadOpened(CommentTypes_1.CommentThreadOpenedOrigin.AppEditor);
            }
        };
        this.setActive = function (isActive) {
            _this._isActive = isActive;
        };
        this.sendReply = function (commentMessage, onSuccess) { return __awaiter(_this, void 0, Promise, function () {
            var message, mentions, mentionOrigin, tempId, comment, _a, finalCommentId, outcome, _b;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        message = commentMessage.message, mentions = commentMessage.mentions, mentionOrigin = commentMessage.mentionOrigin;
                        tempId = "temp".concat(this._comments.length);
                        comment = {
                            id: tempId,
                            message: message,
                            createdAt: new Date(),
                            lastEditedAt: new Date(),
                            user: this.user,
                            mentions: mentions,
                        };
                        this._isCreatingThread = (0, CommentIdService_1.isTempId)(this._id);
                        if (!this._isCreatingThread) return [3, 2];
                        return [4, this.createCommentThread(commentMessage)];
                    case 1:
                        _b = _c.sent();
                        return [3, 4];
                    case 2: return [4, this.replyToCommentThread(commentMessage)];
                    case 3:
                        _b = _c.sent();
                        _c.label = 4;
                    case 4:
                        _a = _b, finalCommentId = _a.finalCommentId, outcome = _a.outcome;
                        if (!(outcome === CommentServiceTypes_1.CommentSaveOutcome.Success)) return [3, 8];
                        if (finalCommentId) {
                            comment.id = finalCommentId;
                        }
                        return [4, onSuccess()];
                    case 5:
                        _c.sent();
                        this.addCommentLocally(comment);
                        if (!this._isCreatingThread) return [3, 6];
                        this.trackCommentCreated(comment, mentionOrigin);
                        this.trackCommentThreadCreated();
                        return [3, 8];
                    case 6:
                        this.trackCommentCreated(comment, mentionOrigin);
                        if (!this.isResolved) return [3, 8];
                        return [4, this.setResolved(false, CommentTypes_1.CommentThreadResolvedOrigin.Replied)];
                    case 7:
                        _c.sent();
                        _c.label = 8;
                    case 8:
                        if (this._isCreatingThread) {
                            this._isCreatingThread = false;
                        }
                        return [2, outcome];
                }
            });
        }); };
        this.addCommentLocally = function (comment) {
            _this._comments.push(comment);
        };
        this.cancelReply = function () {
            if (_this._comments.length === 0 && (0, CommentIdService_1.isTempId)(_this._id)) {
                _this.deleteThread();
            }
        };
        this.deleteComment = function (commentId) { return __awaiter(_this, void 0, Promise, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4, this.commentService.deleteCommentInThread(commentId)];
                    case 1:
                        result = _a.sent();
                        if (result === CommentServiceTypes_1.CommentSaveOutcome.Success) {
                            this.deleteCommentLocally(commentId);
                            this.trackCommentDeleted();
                        }
                        return [2, result];
                }
            });
        }); };
        this.deleteCommentLocally = function (commentId) {
            var commentIndex = _this.getCommentIndex(commentId);
            if (commentIndex < 0) {
                return;
            }
            _this._comments.splice(commentIndex, 1);
        };
        this.deleteThread = function () { return __awaiter(_this, void 0, Promise, function () {
            var result, _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!(0, CommentIdService_1.isTempId)(this._id)) return [3, 1];
                        _a = CommentServiceTypes_1.CommentSaveOutcome.Success;
                        return [3, 3];
                    case 1: return [4, this.commentService.deleteCommentThread(this._id)];
                    case 2:
                        _a = _b.sent();
                        _b.label = 3;
                    case 3:
                        result = _a;
                        if (result === CommentServiceTypes_1.CommentSaveOutcome.Success) {
                            this.deleteThreadLocally();
                            this.trackOnly("Comment Thread Deleted", {});
                        }
                        return [2, result];
                }
            });
        }); };
        this.deleteThreadLocally = function () {
            if (_this.activeCommentThreadStore.isActiveThread(_this)) {
                _this.activeCommentThreadStore.clearActiveThread();
            }
            _this._isDeleted = true;
        };
        this.editComment = function (commentId, newMessage, mentions, onSuccess) { return __awaiter(_this, void 0, Promise, function () {
            var outcome;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.isCommentMessageUnchanged(commentId, newMessage, mentions)) {
                            return [2, CommentServiceTypes_1.CommentSaveOutcome.Success];
                        }
                        return [4, this.commentService.updateCommentInThread(commentId, newMessage, mentions)];
                    case 1:
                        outcome = _a.sent();
                        if (!(outcome === CommentServiceTypes_1.CommentSaveOutcome.Success)) return [3, 3];
                        return [4, onSuccess()];
                    case 2:
                        _a.sent();
                        this.editCommentLocally(commentId, newMessage, mentions);
                        this.trackOnly("Comment Edited", {});
                        _a.label = 3;
                    case 3: return [2, outcome];
                }
            });
        }); };
        this.editCommentLocally = function (commentId, newMessage, mentions) {
            var commentIndex = _this.getCommentIndex(commentId);
            _this._comments[commentIndex].message = newMessage;
            _this._comments[commentIndex].mentions = mentions;
            _this._comments[commentIndex].lastEditedAt = new Date();
        };
        this.isCommentMessageUnchanged = function (commentId, newMessage, mentions) {
            var commentIndex = _this.getCommentIndex(commentId);
            var comment = _this._comments[commentIndex];
            return comment.message === newMessage && (0, lodash_1.isEqual)(comment.mentions, mentions);
        };
        this.getCommentIndex = function (commentId) {
            return (0, lodash_1.findIndex)(_this._comments, { id: commentId });
        };
        this.createCommentThread = function (commentMessage) { return __awaiter(_this, void 0, Promise, function () {
            var _a, outcome, data;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.commentService.createCommentThread(commentMessage)];
                    case 1:
                        _a = _b.sent(), outcome = _a.outcome, data = _a.data;
                        if (outcome === CommentServiceTypes_1.CommentSaveOutcome.Success && !!data) {
                            this._id = data.commentThreadId;
                        }
                        return [2, {
                                outcome: outcome,
                                finalCommentId: data && data.commentId,
                            }];
                }
            });
        }); };
        this.replyToCommentThread = function (commentMessage) { return __awaiter(_this, void 0, Promise, function () {
            var _a, data, outcome;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0: return [4, this.commentService.replyToCommentThread(this._id, commentMessage)];
                    case 1:
                        _a = _b.sent(), data = _a.data, outcome = _a.outcome;
                        return [2, {
                                outcome: outcome,
                                finalCommentId: data && data.commentId,
                            }];
                }
            });
        }); };
        this.trackCommentDeleted = function () {
            var telemetryProps = {
                comment_thread_commenters_number: _this.numCommenters(),
                comment_thread_comments_number: _this.numComments(),
            };
            _this.trackOnly("Comment Deleted", telemetryProps);
        };
        this.trackCommentCreated = function (comment, mentionOrigin) {
            var telemetryProps = {
                comment_thread_commenters_number: _this.numCommenters(),
                comment_thread_comments_number: _this.numComments(),
                comment_contains_mention: _this.hasMention(comment),
                comment_contains_mention_origin: mentionOrigin,
                comment_contains_emoji: false,
                comment_contains_loom_video: false,
                comment_created_project_blocks_number: _this.blockList.getLength(),
                comment_created_project_created_duration: _this.projectStore.timeSinceCreationInHours,
            };
            _this.trackOnly("Comment Created", telemetryProps);
        };
        this.trackCommentThreadOpened = function (origin) {
            _this.trackOnly("Comment Thread Opened", { comment_thread_opened_origin: origin });
        };
        this.trackCommentThreadResolved = function (origin) {
            _this.trackOnly("Comment Thread Resolved", { comment_thread_resolved_origin: origin });
        };
        this.trackCommentThreadCreated = function () {
            _this.trackOnly("Comment Thread Created", {
                comment_type_block_type: _this.blockType,
                comment_type_block_content: _this.getCreatedContentType(),
            });
        };
        this.trackOnly = function (eventName, eventProps) {
            _this.telemetry.trackOnly(eventName, eventProps, { blockIntercom: true });
        };
        this.numCommenters = function () {
            var e_1, _a;
            var userIds = {};
            try {
                for (var _b = __values(_this._comments), _c = _b.next(); !_c.done; _c = _b.next()) {
                    var comment = _c.value;
                    userIds[comment.user._id] = true;
                }
            }
            catch (e_1_1) { e_1 = { error: e_1_1 }; }
            finally {
                try {
                    if (_c && !_c.done && (_a = _b.return)) _a.call(_b);
                }
                finally { if (e_1) throw e_1.error; }
            }
            return Object.keys(userIds).length;
        };
        this.numComments = function () {
            return _this._comments.length;
        };
        this.hasMention = function (comment) {
            return !!comment.mentions && comment.mentions.length > 0;
        };
        this.getCreatedContentType = function () {
            var _a;
            if (!_this.startBlockElement || _this.startBlockElement === EditorTypes_1.BlockType.Any) {
                return "";
            }
            var typeMappings = (_a = {
                    paragraph: "text body",
                    heading1: "text h1",
                    heading2: "text h2",
                    listItem: "text body",
                    blockQuote: "text body"
                },
                _a[EditorTypes_1.InlineType.Link] = "text body",
                _a[EditorTypes_1.InlineType.LineBreak] = "text body",
                _a);
            return typeMappings[_this.startBlockElement];
        };
        options = options || {};
        this._id = options.id || (0, CommentIdService_1.getTempId)();
        this._isRead = (0, lodash_1.isBoolean)(options.isRead) ? options.isRead : false;
        this._isResolved = (0, lodash_1.isBoolean)(options.isResolved) ? options.isResolved : false;
        this._isDeleted = false;
        this._isActive = false;
        this._isCreatingThread = false;
        this._comments = comments || [];
        var shouldShow = (0, lodash_1.isBoolean)(options.isShowing) ? options.isShowing : false;
        if (shouldShow) {
            this.activeCommentThreadStore.setActiveThread(this);
            if (!(0, CommentIdService_1.isTempId)(this._id)) {
                this.trackCommentThreadOpened(options.showingSource);
            }
        }
    }
    Object.defineProperty(CommentGroupStore.prototype, "id", {
        get: function () {
            return this._id;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommentGroupStore.prototype, "isRead", {
        get: function () {
            return this._isRead;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommentGroupStore.prototype, "isResolved", {
        get: function () {
            return this._isResolved;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommentGroupStore.prototype, "isShowing", {
        get: function () {
            return this.activeCommentThreadStore.isActiveThread(this);
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommentGroupStore.prototype, "isActive", {
        get: function () {
            return this._isActive;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommentGroupStore.prototype, "isDeleted", {
        get: function () {
            return this._isDeleted;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommentGroupStore.prototype, "allComments", {
        get: function () {
            return this._comments;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommentGroupStore.prototype, "hasComment", {
        get: function () {
            return this._comments.length > 0;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(CommentGroupStore.prototype, "url", {
        get: function () {
            return this.commentService.getCommentThreadURL(this.id, CommentTypes_1.CommentThreadOpenedOrigin.SharedLink);
        },
        enumerable: false,
        configurable: true
    });
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", String)
    ], CommentGroupStore.prototype, "_id", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], CommentGroupStore.prototype, "_isRead", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], CommentGroupStore.prototype, "_isResolved", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], CommentGroupStore.prototype, "_isDeleted", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], CommentGroupStore.prototype, "_isActive", void 0);
    __decorate([
        mobx_1.observable.ref,
        __metadata("design:type", Boolean)
    ], CommentGroupStore.prototype, "_isCreatingThread", void 0);
    __decorate([
        mobx_1.observable.deep,
        __metadata("design:type", Array)
    ], CommentGroupStore.prototype, "_comments", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], CommentGroupStore.prototype, "id", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], CommentGroupStore.prototype, "isRead", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], CommentGroupStore.prototype, "isResolved", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "setResolved", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "updateCommentThreadStatus", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], CommentGroupStore.prototype, "isShowing", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "toggleShowing", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], CommentGroupStore.prototype, "isActive", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "setActive", void 0);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], CommentGroupStore.prototype, "isDeleted", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Object),
        __metadata("design:paramtypes", [])
    ], CommentGroupStore.prototype, "allComments", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", Boolean),
        __metadata("design:paramtypes", [])
    ], CommentGroupStore.prototype, "hasComment", null);
    __decorate([
        mobx_1.computed,
        __metadata("design:type", String),
        __metadata("design:paramtypes", [])
    ], CommentGroupStore.prototype, "url", null);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "sendReply", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "addCommentLocally", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "cancelReply", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "deleteCommentLocally", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "deleteThread", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "deleteThreadLocally", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "editComment", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "editCommentLocally", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "createCommentThread", void 0);
    __decorate([
        mobx_1.action,
        __metadata("design:type", Object)
    ], CommentGroupStore.prototype, "replyToCommentThread", void 0);
    return CommentGroupStore;
}());
exports.default = CommentGroupStore;
