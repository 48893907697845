"use strict";
var __values = (this && this.__values) || function(o) {
    var s = typeof Symbol === "function" && Symbol.iterator, m = s && o[s], i = 0;
    if (m) return m.call(o);
    if (o && typeof o.length === "number") return {
        next: function () {
            if (o && i >= o.length) o = void 0;
            return { value: o && o[i++], done: !o };
        }
    };
    throw new TypeError(s ? "Object is not iterable." : "Symbol.iterator is not defined.");
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.assembleBrandColorsWithThemeIndex = exports.getRgba = exports.getTelemetryColor = exports.filterContrastingColors = exports.replaceOpacity = exports.areColorsContrasting = void 0;
var ColorUtils_1 = require("@CommonFrontendBackend/StyleServiceV2/ColorUtils");
exports.areColorsContrasting = ColorUtils_1.areColorsContrasting;
exports.replaceOpacity = ColorUtils_1.replaceOpacity;
var filterContrastingColors = function (colors, baseColorValue) {
    var e_1, _a;
    var filteredColors = colors.filter(function (color) {
        try {
            return (0, exports.areColorsContrasting)(color.value, baseColorValue);
        }
        catch (error) {
            return true;
        }
    });
    if (filteredColors.length === 0) {
        var bestOption = void 0;
        try {
            for (var colors_1 = __values(colors), colors_1_1 = colors_1.next(); !colors_1_1.done; colors_1_1 = colors_1.next()) {
                var color = colors_1_1.value;
                var diff = (0, ColorUtils_1.getColorContrast)(color.value, baseColorValue);
                if (!bestOption || diff > bestOption.diff) {
                    bestOption = { color: color, diff: diff };
                }
            }
        }
        catch (e_1_1) { e_1 = { error: e_1_1 }; }
        finally {
            try {
                if (colors_1_1 && !colors_1_1.done && (_a = colors_1.return)) _a.call(colors_1);
            }
            finally { if (e_1) throw e_1.error; }
        }
        return [bestOption.color];
    }
    return filteredColors;
};
exports.filterContrastingColors = filterContrastingColors;
var getTelemetryColor = function (color) {
    switch (color.themeIndex) {
        case 0: {
            return "brand primary";
        }
        case 1: {
            return "brand secondary";
        }
        case -1: {
            return "white";
        }
        case -2: {
            return "grey";
        }
        case -3: {
            return "black";
        }
        default: {
            return "custom";
        }
    }
};
exports.getTelemetryColor = getTelemetryColor;
exports.getRgba = ColorUtils_1.getRgba;
exports.assembleBrandColorsWithThemeIndex = ColorUtils_1.assembleBrandColors;
