// Generated by CoffeeScript 1.12.7
(function () {
  var AudioController;

  module.exports = AudioController = (function () {
    function AudioController(make, createUi, model) {
      var AudioPlayerController, makePlayer, player;
      AudioPlayerController = require("./AudioPlayer/AudioPlayer.js");
      makePlayer = function () {
        return make(AudioPlayerController, {
          model: model,
          options: {
            showWaveform: true,
          },
        });
      };
      player = makePlayer();
      this.ui = createUi(require("./Audio.html"), {
        model: model,
        player: player.ui,
        refreshPlayer: (function (_this) {
          return function () {
            delete _this.ui.player;
            return (_this.ui.player = makePlayer().ui);
          };
        })(this),
      });
      this.isBlank = (function (_this) {
        return function () {
          var ref;
          return (ref = _this.ui.model.url) != null ? ref.length : void 0;
        };
      })(this);
    }

    return AudioController;
  })();
}.call(this));
