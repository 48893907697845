"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HyperlinkAddedSource = exports.SideBySideResizeOrigin = exports.InlineContentPlaceholderType = exports.VariableFormatType = void 0;
var lodash_1 = require("lodash");
var Image_1 = require("ContentTokens/DataModels/Image");
var TelemetryParameterValue_1 = require("Telemetry/TelemetryParameterValue");
var TelemetryTypes_1 = require("@CommonFrontendBackend/Tokens/TelemetryTypes");
var Utils_1 = require("@CommonFrontendBackend/Tokens/Utils");
var BlockUtils_1 = require("@CommonFrontendBackend/Telemetry/BlockUtils");
var Types_1 = require("@CommonFrontendBackend/AssetLibrary/Types");
var URL_1 = require("@CommonFrontendBackend/Utils/URL");
var BlockStyleTypes_1 = require("@CommonFrontendBackend/StyleServiceV2/BlockStyleTypes");
var TelemetryEnums_1 = require("Telemetry/TelemetryEnums");
var crypto_1 = require("crypto");
var CommonUtils_1 = require("@CommonFrontendBackend/Utils/CommonUtils");
var VariableFormatType;
(function (VariableFormatType) {
    VariableFormatType["Bold"] = "bold";
    VariableFormatType["Italic"] = "italic";
    VariableFormatType["Strikethrough"] = "strikethrough";
    VariableFormatType["Delete"] = "delete";
})(VariableFormatType || (exports.VariableFormatType = VariableFormatType = {}));
var QwilrEditorTelemetry = (function () {
    function QwilrEditorTelemetry(serverTelemetry, telemetry) {
        var _this = this;
        this.serverTelemetry = serverTelemetry;
        this.telemetry = telemetry;
        this.imageDraggedInto = function (blockType, isInsideSideBySide) {
            _this.inlineContentAdded({
                contentType: TelemetryParameterValue_1.ContentAddedType.Image,
                origin: TelemetryParameterValue_1.ContentAddedOrigin.Drag,
                blockType: blockType,
                source: Types_1.MetricSource.Drag,
                isInsideSideBySide: isInsideSideBySide,
            });
        };
        this.sidebarImageDraggedInto = function (blockType, isInsideSideBySide, originWasFiltered, assetFolderType) {
            _this.inlineContentAdded({
                contentType: TelemetryParameterValue_1.ContentAddedType.Image,
                origin: TelemetryParameterValue_1.ContentAddedOrigin.Drag,
                blockType: blockType,
                source: Types_1.MetricSource.Sidebar,
                isInsideSideBySide: isInsideSideBySide,
                originWasFiltered: originWasFiltered,
                assetFolderType: assetFolderType,
            });
        };
        this.imagePastedInto = function (blockType, isInsideSideBySide) {
            _this.inlineContentAdded({
                contentType: TelemetryParameterValue_1.ContentAddedType.Image,
                origin: TelemetryParameterValue_1.ContentAddedOrigin.Paste,
                blockType: blockType,
                source: Types_1.MetricSource.Clipboard,
                isInsideSideBySide: isInsideSideBySide,
            });
        };
        this.tableAddedInto = function (blockType) {
            _this.inlineContentAdded({
                contentType: TelemetryParameterValue_1.ContentAddedType.Table,
                origin: TelemetryParameterValue_1.ContentAddedOrigin.Click,
                blockType: blockType,
                isInsideSideBySide: false,
            });
        };
        this.inlineContentAdded = function (_a) {
            var contentType = _a.contentType, origin = _a.origin, blockType = _a.blockType, source = _a.source, isInsideSideBySide = _a.isInsideSideBySide, originWasFiltered = _a.originWasFiltered, assetFolderType = _a.assetFolderType;
            if (isInsideSideBySide) {
                _this.sideBySideContentAdded(contentType);
            }
            else {
                _this.telemetry.trackOnly("Inline Content Added", __assign(__assign(__assign({ inline_content_type: (0, BlockUtils_1.getContentTypeForTelemetry)(contentType), inline_content_block_type: blockType, inline_content_origin: origin }, (source && contentType === "image" ? { image_source: source } : {})), ((0, lodash_1.isNil)(originWasFiltered) ? {} : { image_content_origin_filtered: originWasFiltered })), (assetFolderType ? { asset_folder_type: assetFolderType } : {})), {
                    blockIntercom: true,
                });
            }
        };
        this.sideBySideContentAdded = function (contentType) {
            _this.telemetry.trackOnly("Inline Content Columns Content Added", {
                inline_content_columns_content_type: (0, BlockUtils_1.getContentTypeForTelemetry)(contentType),
            }, {
                blockIntercom: true,
            });
        };
        this.inlineContentVideoAdded = function (videoSource) {
            _this.telemetry.trackOnly("Inline Content Video Added", {
                video_source: videoSource,
            }, {
                blockIntercom: true,
            });
        };
        this.inlineContentDeleted = function (contentType) {
            var telemetryContentType = (0, BlockUtils_1.getContentTypeForTelemetry)(contentType);
            _this.telemetry.trackOnly("Inline Content Deleted", {
                inline_content_type: telemetryContentType,
            }, {
                blockIntercom: true,
            });
        };
        this.inlineContentResized = function (contentType, options) {
            if (options === void 0) { options = {}; }
            var telemetryContentType = (0, BlockUtils_1.getContentTypeForTelemetry)(contentType);
            var telemetryProps = {
                inline_content_type: telemetryContentType,
            };
            if (contentType === "sideBySide") {
                telemetryProps.inline_content_columns_size = columnResizeForTelemetry(options.columnsSize);
            }
            _this.telemetry.trackOnly("Inline Content Resized", telemetryProps, {
                blockIntercom: true,
            });
        };
        this.viewedAdderMenu = function (isCentered, trigger) {
            _this.telemetry.debouncedTrack("Inline Content Menu Viewed", {
                inline_content_menu_trigger: trigger,
                inline_content_menu_alignment: isCentered ? "center" : "left",
            }, {
                blockIntercom: true,
                blockIdentify: true,
            });
        };
        this.navigatedAdderMenuWithKeyboard = function () {
            _this.telemetry.debouncedTrack("Inline Content Menu Keyboard Navigation", {}, {
                blockIntercom: true,
                blockIdentify: true,
            });
        };
        this.filteredAdderMenu = function () {
            _this.telemetry.debouncedTrack("Inline Content Menu Filtered", {}, {
                blockIntercom: true,
                blockIdentify: true,
            });
        };
        this.blockStylesContextualViewed = function () {
            _this.telemetry.trackOnly("Block Styles Contextual Viewed", {}, {
                blockIntercom: true,
            });
        };
        this.blockStylesContextualChanged = function (parameters) {
            if (parameters === void 0) { parameters = {}; }
            _this.telemetry.trackOnly("Block Styles Contextual Changed", parameters, {
                blockIntercom: true,
            });
        };
        this.inlineContentPlaceholderDeleted = function (contentType) {
            _this.telemetry.trackOnly("Inline Content Placeholder Deleted", {
                inline_content_type: contentType,
            }, {
                blockIntercom: true,
            });
        };
        this.columnsSwappedInSideBySide = function () {
            _this.telemetry.trackOnly("Inline Content Columns Swapped", {}, {
                blockIntercom: true,
            });
        };
        this.variableInserted = function (variable, insertionContext) { return __awaiter(_this, void 0, Promise, function () {
            var properties;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        properties = (0, Utils_1.isCrmToken)(variable)
                            ? __assign({ tokenInsertedName: variable.name, tokenInsertedType: "crm", tokenInsertedCrm: variable.providerType, tokenInsertedCrmObject: variable.objectType }, insertionContext) : __assign({ tokenInsertedName: variable.name, tokenInsertedType: "custom" }, insertionContext);
                        return [4, this.serverTelemetry.sendTokenInsertedTelemetry({ eventProperties: properties })];
                    case 1:
                        _a.sent();
                        return [2];
                }
            });
        }); };
        this.variableFormatted = function (formatActionType) {
            _this.telemetry.trackOnly(TelemetryTypes_1.TokensEvents.TokenFormatted, {
                token_formatted_type: formatActionType,
            }, {
                blockIntercom: true,
            });
        };
        this.inlineContentCouldNotBeAdded = function (errorName) {
            _this.telemetry.trackOnly("Inline Content Added Error", {
                inline_content_added_error_name: errorName,
            }, {
                blockIntercom: true,
            });
        };
        this.buttonUrlEdited = function (link) {
            _this.telemetry.trackOnly(TelemetryEnums_1.ButtonWidgetInteractions.ButtonUrlEdited, {
                button_url_domain: (0, URL_1.sanitizeLink)(link),
            }, {
                blockIntercom: true,
            });
        };
        this.buttonTextEdited = function (newText) {
            _this.telemetry.trackOnly(TelemetryEnums_1.ButtonWidgetInteractions.ButtonTextEdited, {
                button_text_num_chars: newText.length,
            }, {
                blockIntercom: true,
            });
        };
        this.buttonCustomTokenUpdated = function (newToken) {
            var tokenNameHash = (0, crypto_1.createHash)("md5").update(newToken.name).digest("hex");
            _this.telemetry.trackOnly(TelemetryEnums_1.ButtonWidgetInteractions.ButtonUrlCustomTokenUpdated, {
                token_name_hash: tokenNameHash,
            }, {
                blockIntercom: true,
            });
        };
        this.hyperlinkURLAdded = function (link, source) {
            _this.telemetry.trackOnly("Hyperlink URL Added", {
                hyperlink_url_domain: (0, URL_1.sanitizeLink)(link),
                hyperlink_added_source: source,
            }, {
                blockIntercom: true,
            });
        };
        this.iframeWidgetURLUpdated = function (url, preset) {
            _this.telemetry.trackOnly("Embed iframe updated", __assign({ source: "widget", url: (0, URL_1.sanitizeLink)(url) }, (0, CommonUtils_1.includeIf)(!!preset, { category: preset === null || preset === void 0 ? void 0 : preset.category, embedPreset: preset === null || preset === void 0 ? void 0 : preset.name })), {
                blockIntercom: true,
            });
        };
        this.accordionSelectedStyle = function (style) {
            _this.telemetry.trackOnly("Accordion Selected Style", {
                accordion_style_selected: style,
            }, {
                blockIntercom: true,
            });
        };
        this.accordionSelectedButtonAlignment = function (alignment) {
            _this.telemetry.trackOnly("Accordion Selected Button Alignment", {
                accordion_button_alignment_selected: alignment,
            }, {
                blockIntercom: true,
            });
        };
        this.accordionDuplicated = function () {
            _this.telemetry.trackOnly("Accordion Duplicated", {}, {
                blockIntercom: true,
            });
        };
        this.accordionToggledHeaderColour = function (on) {
            _this.telemetry.trackOnly("Accordion Toggled Header Colour", {
                accordion_header_colour_toggled: on ? "on" : "off",
            }, {
                blockIntercom: true,
            });
        };
        this.accordionToggledBackgroundColour = function (on) {
            _this.telemetry.trackOnly("Accordion Toggled Background Colour", {
                accordion_background_colour_toggled: on ? "on" : "off",
            }, {
                blockIntercom: true,
            });
        };
        this.accordionChangedBackgroundImage = function () {
            _this.telemetry.trackOnly("Accordion Changed Background Image", {}, {
                blockIntercom: true,
            });
        };
        this.accordionChangedImageHeight = function (imageHeight) {
            _this.telemetry.trackOnly("Accordion Changed Image Height", {
                accordion_image_height_selected: imageHeight,
            }, {
                blockIntercom: true,
            });
        };
        this.accordionSelectedButtonStyle = function (style) {
            var _a;
            var mappedStyle = (_a = {},
                _a[BlockStyleTypes_1.AccordionWidgetButtonStyle.Arrow] = "triangle",
                _a[BlockStyleTypes_1.AccordionWidgetButtonStyle.Expand] = "plus",
                _a[BlockStyleTypes_1.AccordionWidgetButtonStyle.Label] = "button",
                _a)[style];
            if (!mappedStyle) {
                console.error("Couldn't map button style ".concat(style, " for telemetry"));
                return;
            }
            _this.telemetry.trackOnly("Accordion Selected Button Style", {
                accordion_button_style_selected: mappedStyle,
            }, {
                blockIntercom: true,
            });
        };
        this.accordionChangedExpandButtonText = function (text) {
            _this.telemetry.trackOnly("Accordion Changed Expand Button Text", {
                accordion_button_text_value: text,
            }, {
                blockIntercom: true,
            });
        };
        this.accordionChangedCloseButtonText = function (text) {
            _this.telemetry.trackOnly("Accordion Changed Close Button Text", {
                accordion_button_text_value: text,
            }, {
                blockIntercom: true,
            });
        };
        this.accordionToggled = function (open) {
            _this.telemetry.trackOnly("Accordion Toggled", {
                accordion_toggled_location: TelemetryEnums_1.TelemetryContext.Client,
                accordion_toggled_source: "button",
                accordion_toggled_state: open ? "expand" : "collapse",
            }, {
                blockIntercom: true,
            });
        };
        this.roiCalculatorBackgroundImageAdded = function () {
            _this.telemetry.trackOnly(TelemetryEnums_1.ROICalculatorInteractions.BackgroundAdded, {}, {
                blockIntercom: true,
            });
        };
        this.roiCalculatorBackgroundImageRemoved = function () {
            _this.telemetry.trackOnly(TelemetryEnums_1.ROICalculatorInteractions.BackgroundRemoved, {}, {
                blockIntercom: true,
            });
        };
        this.roiCalculatorAddVariableInput = function (count) {
            _this.telemetry.trackOnly(TelemetryEnums_1.ROICalculatorInteractions.VariableAdded, {
                roi_input_variable_count: count,
            }, {
                blockIntercom: true,
            });
        };
        this.roiCalculatorDeleteVariableInput = function (count) {
            _this.telemetry.trackOnly(TelemetryEnums_1.ROICalculatorInteractions.VariableDeleted, {
                roi_input_variable_count: count,
            }, {
                blockIntercom: true,
            });
        };
        this.roiPresetSelected = function (id) {
            _this.telemetry.trackOnly(TelemetryEnums_1.ROICalculatorInteractions.ROIPresetSelected, {
                roi_preset_selected: id,
            }, {
                blockIntercom: true,
            });
        };
        this.roiCalculatorEditROIOption = function (key, value) {
            _this.telemetry.trackOnly(TelemetryEnums_1.ROICalculatorInteractions.ResultOptionEdited, {
                roi_options_edited_key: key,
                roi_options_edited_value: value,
            }, {
                blockIntercom: true,
            });
        };
        this.roiCalculatorEditVariableInput = function (key, value) {
            _this.telemetry.trackOnly(TelemetryEnums_1.ROICalculatorInteractions.VariableOptionEdited, {
                roi_input_variable_edited_key: key,
                roi_input_variable_edited_value: value,
            }, {
                blockIntercom: true,
            });
        };
        this.roiCalculatorEditSliderOption = function (key, value) {
            _this.telemetry.trackOnly(TelemetryEnums_1.ROICalculatorInteractions.SliderOptionsEdited, {
                roi_slider_options_edited_key: key,
                roi_slider_options_edited_value: value,
            }, {
                blockIntercom: true,
            });
        };
        this.roiCalculatorSliderInteraction = function () {
            _this.telemetry.trackOnly(TelemetryEnums_1.ROICalculatorInteractions.SliderMoved, {
                roi_interaction_location: TelemetryEnums_1.TelemetryContext.Client,
            }, {
                blockIntercom: true,
            });
        };
        this.roiCalculatorVariableInputInteraction = function (count, position) {
            _this.telemetry.trackOnly(TelemetryEnums_1.ROICalculatorInteractions.VariableChanged, {
                roi_interaction_location: TelemetryEnums_1.TelemetryContext.Client,
                roi_input_variable_count: count,
                roi_input_variable_value_position: position,
            }, {
                blockIntercom: true,
            });
        };
        this.roiCalculatorTourSkipped = function () {
            _this.telemetry.trackOnly(TelemetryEnums_1.ROICalculatorInteractions.TourSkipped, {}, {
                blockIntercom: true,
            });
        };
        this.roiCalculatorTourStarted = function () {
            _this.telemetry.trackOnly(TelemetryEnums_1.ROICalculatorInteractions.TourStarted, {}, {
                blockIntercom: true,
            });
        };
        this.tableRepeatingRowAdded = function () {
            _this.telemetry.trackOnly(TelemetryEnums_1.TableWidgetInteractions.RepeatingRowAdded, {}, {
                blockIntercom: true,
            });
        };
        this.tableRepeatingRowTokenSet = function (newToken) {
            var tokenNameHash = (0, crypto_1.createHash)("md5").update(newToken.name).digest("hex");
            _this.telemetry.trackOnly(TelemetryEnums_1.TableWidgetInteractions.RepeatingRowTokenSet, { token_name_hash: tokenNameHash }, {
                blockIntercom: true,
            });
        };
        this.layoutTypeAdded = function (_a) {
            var layoutType = _a.layoutType;
            _this.telemetry.trackOnly(TelemetryEnums_1.LayoutWidgetInteractions.LayoutTypeSelected, {
                layout_type: layoutType,
            }, { blockIntercom: true });
        };
        this._changedImageFrame = function (frame) {
            var frameName = frame === Image_1.ImageFrame.None ? "none" : frame;
            _this.telemetry.trackOnly("Block Styles Contextual Changed", {
                block_styles_contextual_image_frame: frameName,
            }, {
                blockIntercom: true,
            });
        };
        this._repositionedImageInFrame = function () {
            _this.telemetry.trackOnly("Block Styles Contextual Changed", {
                block_styles_contextual_image_frame_positioning: true,
            }, {
                blockIntercom: true,
            });
        };
        this._changedImageAlignment = function (alignment) {
            _this.telemetry.trackOnly("Block Styles Contextual Changed", {
                block_styles_contextual_image_alignment: alignment,
            }, {
                blockIntercom: true,
            });
        };
        this.changedImageFrame = (0, lodash_1.debounce)(this._changedImageFrame, 60000, { leading: true });
        this.repositionedImageInFrame = (0, lodash_1.debounce)(this._repositionedImageInFrame, 60000, { leading: true });
        this.changedImageAlignment = (0, lodash_1.debounce)(this._changedImageAlignment, 60000, { leading: true });
    }
    return QwilrEditorTelemetry;
}());
var columnResizeForTelemetry = function (val) {
    if (val === void 0) { val = 25; }
    return ({
        25: "left column 1/4",
        50: "left column 1/2",
        75: "left column 3/4",
    }[val]);
};
var InlineContentPlaceholderType;
(function (InlineContentPlaceholderType) {
    InlineContentPlaceholderType["Columns"] = "columns";
})(InlineContentPlaceholderType || (exports.InlineContentPlaceholderType = InlineContentPlaceholderType = {}));
var SideBySideResizeOrigin;
(function (SideBySideResizeOrigin) {
    SideBySideResizeOrigin["Mouse"] = "mouse";
    SideBySideResizeOrigin["Keyboard"] = "keyboard";
})(SideBySideResizeOrigin || (exports.SideBySideResizeOrigin = SideBySideResizeOrigin = {}));
var HyperlinkAddedSource;
(function (HyperlinkAddedSource) {
    HyperlinkAddedSource["CollapsedSelectionPaste"] = "collapsed_selection_paste";
    HyperlinkAddedSource["ExpandedSelectionPaste"] = "expanded_selection_paste";
    HyperlinkAddedSource["Toolbar"] = "toolbar";
})(HyperlinkAddedSource || (exports.HyperlinkAddedSource = HyperlinkAddedSource = {}));
exports.default = QwilrEditorTelemetry;
