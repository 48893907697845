"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var BluePrintCtrl = require("Project/Actions/BluePrint/BluePrint");
var FeatureFlag_1 = require("@CommonFrontendBackend/FeatureFlag");
exports.default = (function (make, $rootScope, usingFeature, upseller, telemetry, $state) {
    return function (project, viewChildren) {
        if (!usingFeature(FeatureFlag_1.FeatureFlag.BluePrintApi)) {
            upseller.upsell({
                feature: "bluePrintApi",
                featureLabel: "Blueprint",
                customUpsellMessage: "Upgrade to mass produce personalized documents via integrations or a CSV upload.",
            });
            return;
        }
        var bluePrint = make(BluePrintCtrl, {
            project: project,
            viewChildren: viewChildren || (function () { return $state.go("dashboard", { nav: "pages" }); }),
        });
        bluePrint.show();
        telemetry.trackOnly("Project Blueprint Viewed");
        try {
            $rootScope.$apply();
        }
        catch (e) {
        }
    };
});
