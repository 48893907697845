// Generated by CoffeeScript 1.12.7
(function () {
  var getBlockStyle;

  getBlockStyle = require("./GetBlockStyleObject");

  module.exports = function (style, user) {
    var newStyle;
    if (style.version === 1) {
      return style;
    }
    newStyle = getBlockStyle(style.blockType, user, style.className);
    newStyle.styleModel = style.styleModel;
    newStyle.name = style.name;
    return newStyle;
  };
}.call(this));
