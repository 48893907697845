// Generated by CoffeeScript 1.12.7
(function () {
  var OfficeIpFilters;

  module.exports = OfficeIpFilters = (function () {
    function OfficeIpFilters(account, createUi, AsyncButton, $log, $http, $q, accountService, utils) {
      var cleanUpIpAddress, filterActiveIps, getOfficeIps, onUpdateClick, pointTo, updateButton;
      this.account = account;
      this.accountService = accountService;
      filterActiveIps = function (ips) {
        return _.filter(ips, function (ip) {
          return ip.active;
        });
      };
      getOfficeIps = function () {
        var officeIps, ref;
        officeIps = ((ref = account.settings) != null ? ref.officeIpAddresses : void 0) || [];
        return _.cloneDeep(filterActiveIps(officeIps));
      };
      cleanUpIpAddress = function (ip) {
        if (ip["new"]) {
          return {
            name: ip.name,
            ip: ip.ip,
          };
        } else {
          return ip;
        }
      };
      onUpdateClick = function () {
        var cleanedUpAddresses, err, i, ipAddress, len, ref;
        ref = this.ui.officeIpAddresses;
        for (i = 0, len = ref.length; i < len; i++) {
          ipAddress = ref[i];
          if (!utils.isValidIpAddress(ipAddress.ip)) {
            err = new Error("Please enter a valid IP Address.");
            err.pointTo = ipAddress._id;
            return $q.reject(err);
          }
        }
        cleanedUpAddresses = _.map(JSON.parse(angular.toJson(this.ui.officeIpAddresses)), cleanUpIpAddress);
        return this.accountService
          .setOfficeIpAddress(cleanedUpAddresses)
          .then(
            (function (_this) {
              return function (result) {
                updateButton.ui.error = "";
                _this.ui.officeIpAddresses = filterActiveIps(result.data.settings.officeIpAddresses);
                return (account.settings.officeIpAddresses = result.data.settings.officeIpAddresses);
              };
            })(this),
          )
          ["catch"](
            (function (_this) {
              return function (err) {
                updateButton.ui.error = "" + err;
                return $q.reject(err);
              };
            })(this),
          );
      };
      pointTo = function (err) {
        if (err.pointTo) {
          return "#ip-" + err.pointTo;
        } else {
          return null;
        }
      };
      updateButton = new AsyncButton(
        onUpdateClick.bind(this),
        "Update IP filters",
        {
          initialState: "disabled",
        },
        pointTo,
        function () {
          return "Oops… This doesn’t look like a valid IP address.";
        },
      );
      this.ui = createUi(require("./OfficeIpFilters.html"), {
        error: "",
        officeIpAddresses: getOfficeIps(),
        updateButton: updateButton.ui,
        onAddClick: function () {
          return this.officeIpAddresses.push({
            name: "",
            ip: "",
            _id: Math.random().toString(24).substring(2),
            new: true,
          });
        },
        deleteIp: function (ipId) {
          this.officeIpAddresses = _.filter(
            this.officeIpAddresses,
            (function (_this) {
              return function (ip) {
                return ip._id !== ipId;
              };
            })(this),
          );
          account.settings.officeIpAddresses = this.officeIpAddresses;
          return accountService.deleteOfficeIpAddress(ipId);
        },
        changesMade: function () {
          if (updateButton.state === "disabled") {
            return (updateButton.state = "idle");
          }
        },
      });
    }

    return OfficeIpFilters;
  })();
}.call(this));
