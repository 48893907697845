"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSelectionState = void 0;
var slate_react_1 = require("slate-react");
var Utils_1 = require("../Utils");
var SyntheticSelection_1 = require("Project/SyntheticSelection");
var lodash_1 = require("lodash");
var useSelectionState = function (element) {
    var slate = (0, slate_react_1.useSlate)();
    var isFocussed = (0, slate_react_1.useFocused)();
    var elementPath = slate_react_1.ReactEditor.findPath(slate, element);
    var selection = (0, SyntheticSelection_1.useSelectionStore)().selection;
    var withinSelection = selection.find(function (selected) { return (selected === null || selected === void 0 ? void 0 : selected.editor) === slate && (0, lodash_1.isEqual)(selected.path, elementPath); });
    if (withinSelection) {
        return { widgetSelectionState: selection.length > 1 ? "multi" : "circle" };
    }
    if (!isFocussed) {
        return {
            widgetSelectionState: "defocussed",
        };
    }
    if (!slate.selection) {
        return {
            widgetSelectionState: "none",
        };
    }
    if (element.isVoid) {
        return (0, Utils_1.isSelected)(slate, elementPath) ? { widgetSelectionState: "around" } : { widgetSelectionState: "none" };
    }
    return {
        widgetSelectionState: "none",
    };
};
exports.useSelectionState = useSelectionState;
