// Generated by CoffeeScript 1.12.7
(function () {
  module.exports = {
    Enter: {
      key: "enter",
      tests: [
        {
          name: "should insert a new heading on Enter keypress",
          tagName: "H1",
          startContent: "<h1>a|b</h1>",
          selectionResult: "<h1>a</h1><h1>|b</h1>",
        },
        {
          name: "should insert a newline at the start of a heading with an open heading above",
          tagName: "H2",
          startContent: "<h2>|ab</h2>",
          selectionResult: "<h2><br></h2><h2>|ab</h2>",
        },
        {
          name: "should insert a new paragraph at the end of a heading",
          tagName: "H1",
          startContent: "<h1>ab|</h1>",
          selectionResult: "<h1>ab</h1><p>|<br></p>",
        },
        {
          name: "should split styling tags when making newline",
          tagName: "H1",
          startContent: "<h1>a<b>b<i>c|d</i>e</b>f</h1>",
          selectionResult: "<h1>a<b>b<i>c</i></b></h1><h1><b><i>|d</i>e</b>f</h1>",
        },
        {
          name: "should insert new paragraph from empty headings and keep resulting elements open",
          tagName: "H2",
          startContent: "<h2><br>|</h2>",
          selectionResult: "<h2><br></h2><p>|<br></p>",
        },
        {
          name: "should insert new paragraph from empty headings and keep resulting elements open",
          tagName: "H2",
          startContent: "<h2>|<br></h2>",
          selectionResult: "<h2><br></h2><p>|<br></p>",
        },
      ],
    },
    Backspace: {
      key: "backspace",
      tests: [
        {
          name: "should join two headings together if at start of second heading",
          tagName: "H1",
          startContent: "<h1>ab</h1><h1>|cd</h1>",
          selectionResult: "<h1>ab|cd</h1>",
        },
        {
          name: "should join heading's content to end of other heading type",
          tagName: "H2",
          startContent: "<h1>ab</h1><h2>|cd</h2>",
          selectionResult: "<h1>ab|cd</h1>",
        },
        {
          name: "should do nothing if first heading",
          tagName: "H2",
          startContent: "<h2>|ab</h2>",
          domResult: "<h2>ab</h2>",
          selectionResult: "<h2>|ab</h2>",
        },
        {
          name: "should not handle event if not at start of line",
          tagName: "H1",
          startContent: "<h1>ab|cd</h1>",
          domResult: "<h1>abcd</h1>",
          selectionResult: "<h1>ab|cd</h1>",
        },
        {
          name: "should not handle event if only whitespace beforehand",
          tagName: "H2",
          startContent: "<h2>ab</h2><h2>&nbsp;|cd</h2>",
          domResult: "<h2>ab</h2><h2>&nbsp;cd</h2>",
          selectionResult: "<h2>ab</h2><h2>&nbsp;|cd</h2>",
        },
        {
          name: "should not handle if at start of style tag with more content before",
          tagName: "H1",
          startContent: "<h1>abc<b>|def</b></h1>",
          domResult: "<h1>abc<b>def</b></h1>",
          selectionResult: "<h1>abc<b>|def</b></h1>",
        },
        {
          name: "should not handle if after style tag with content in style",
          tagName: "H2",
          startContent: "<h2><b>def</b>|abc</h2>",
          domResult: "<h2><b>def</b>abc</h2>",
          selectionResult: "<h2><b>def</b>|abc</h2>",
        },
        {
          name: "should join onto list correctly if there is no content in heading",
          tagName: "H1",
          startContent: "<ul><li>asdf</li></ul><h1>|<br></h1>",
          selectionResult: "<ul><li>asdf|</li></ul>",
        },
        {
          name: "should join onto list correctly if there is no content in heading (modified caret location)",
          tagName: "H2",
          startContent: "<ul><li>asdf</li></ul><h2><br>|</h2>",
          selectionResult: "<ul><li>asdf|</li></ul>",
        },
        {
          name: "should join together similar style inline tags on joining",
          tagName: "H2",
          startContent: "<h2>a<b>b<i>c</i></b></h2><h2><b><i>|d</i>e</b>f</h2>",
          selectionResult: "<h2>a<b>b<i>c|d</i>e</b>f</h2>",
        },
      ],
    },
    Delete: {
      key: "delete",
      tests: [
        {
          name: "should not merge next element into heading if it is empty",
          tagName: "H2",
          startContent: "<h2>|<br></h2><p>fluff</p>",
          selectionResult: "<p>|fluff</p>",
        },
        {
          name: "should join next heading to this one if at end of line",
          tagName: "H1",
          startContent: "<h1>ab|</h1><h1>cd</h1>",
          selectionResult: "<h1>ab|cd</h1>",
        },
        {
          name: "should join next block's content to this heading if at end of line",
          tagName: "H2",
          startContent: "<h2>ab|</h2><h1>cd</h1>",
          selectionResult: "<h2>ab|cd</h2>",
        },
        {
          name: "should not handle event if visible whitespace afterwards",
          tagName: "H1",
          startContent: "<h1>ab|&nbsp;</h1><h1>cd</h1>",
          domResult: "<h1>ab&nbsp;</h1><h1>cd</h1>",
          selectionResult: "<h1>ab|&nbsp;</h1><h1>cd</h1>",
        },
        {
          name: "should not handle event if content afterwards",
          tagName: "H2",
          startContent: "<h2>ab|cd</h2><h2>ef</h2>",
          domResult: "<h2>abcd</h2><h2>ef</h2>",
          selectionResult: "<h2>ab|cd</h2><h2>ef</h2>",
        },
        {
          name: "should not handle if just before style tag with more content after",
          tagName: "H1",
          startContent: "<h1>abc|<b>def</b></h1>",
          domResult: "<h1>abc<b>def</b></h1>",
          selectionResult: "<h1>abc|<b>def</b></h1>",
        },
        {
          name: "should not handle if at end of style tag with more content before",
          tagName: "H2",
          startContent: "<h2>abc<b>def|</b>ghi</h2>",
          domResult: "<h2>abc<b>def</b>ghi</h2>",
          selectionResult: "<h2>abc<b>def|</b>ghi</h2>",
        },
        {
          name: "should join list element onto heading correctly",
          tagName: "H2",
          startContent: "<h2>ab|</h2><ol><li>cd</li></ol>",
          selectionResult: "<h2>ab|cd</h2>",
        },
        {
          name: "should join only first list element onto heading if multiple",
          tagName: "H1",
          startContent: "<h1>ab|</h1><ol><li>cd</li><li>ef</li></ol>",
          selectionResult: "<h1>ab|cd</h1><ol><li>ef</li></ol>",
        },
        {
          name: "should join together similar style inline tags on joining",
          tagName: "H1",
          startContent: "<h1>a<b>b<i>c|</i></b></h1><h1><b><i>d</i>e</b>f</h1>",
          selectionResult: "<h1>a<b>b<i>c|d</i>e</b>f</h1>",
        },
      ],
    },
  };
}.call(this));
